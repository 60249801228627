/* eslint-disable react/display-name */
///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			example component description

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card } from '@mui/material/'
import { tableColumns_AdditionalWorkInvoices, tableSettings_AdditionalWorkInvoices } from 'app/pages/invoices/tables/additional_work_invoices'
import React, { forwardRef, useContext, useEffect, useReducer, useState } from 'react'
import { DatabaseRef_AdditionalWorkInvoicesForProject_Query } from 'rfbp_aux/services/database_endpoints/finances/project_additional_work_invoices'
import { DatabaseRef_Project_Document } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { Icon } from 'rfbp_core/components/icons'
import { TableBasic } from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_CustomDialog } from 'rfbp_core/services/context'
import { DatabaseGetLiveCollection, DatabaseGetLiveDocument } from 'rfbp_core/services/database_management'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { NewAdditionalWorkQuoteDialog } from '../v2_dialogs/dialog_new_additional_work_quote'
import { NewNonBillableTaskDialog } from '../v2_dialogs/dialog_new_non_billable_task'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ProjectView_QuotesTab {
  projectKey: string
  readOrWrite: 'read' | 'write'
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const ProjectViewQuotesTab = forwardRef((props: TsInterface_ProjectView_QuotesTab, ref: React.ForwardedRef<unknown>): JSX.Element => {
  // Props
  let pr_projectKey: TsInterface_ProjectView_QuotesTab['projectKey'] = getProp(props, 'projectKey', null)
  let pr_readOrWrite: TsInterface_ProjectView_QuotesTab['readOrWrite'] = getProp(props, 'readOrWrite', 'read')

  // Hooks - useContext, useState, useReducer, other
  const [us_projectAdditionalWorkInvoices, us_setProjectAdditionalWorkInvoices] = useState<TsInterface_UnspecifiedObject>({})
  const [us_rootProject, us_setRootProject] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)

  // Hooks - useEffect
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRootProject(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setProjectAdditionalWorkInvoices(newData)
      }
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        if (pr_projectKey != null) {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_AdditionalWorkInvoicesForProject_Query(res_GCK.clientKey, pr_projectKey), updateLiveData)
        } else {
          us_setProjectAdditionalWorkInvoices({})
        }
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [pr_projectKey, ur_forceRerender, uc_RootData_ClientKey, uc_setRootData_ClientKey])

  // Functions
  const openNewQuoteDialog = () => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        uc_setUserInterface_CustomDialogDisplay({
          display: true,
          dialog: {
            dialog_jsx: (
              <NewAdditionalWorkQuoteDialog
                clientKey={res_GCK.clientKey}
                projectKey={pr_projectKey}
                project={us_rootProject}
                addToExistingInvoice={false}
                existingInvoiceKey={null}
              />
            ),
            settings: {
              max_width: 'xl',
            },
          },
        })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }

  const openNewNonBillableTaskDialog = () => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        uc_setUserInterface_CustomDialogDisplay({
          display: true,
          dialog: {
            dialog_jsx: (
              <NewNonBillableTaskDialog
                clientKey={res_GCK.clientKey}
                projectKey={pr_projectKey}
                project={us_rootProject}
              />
            ),
            settings: {
              max_width: 'xl',
            },
          },
        })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }

  // JSX Generation
  const rJSX_NewQuoteButton = (): JSX.Element => {
    return (
      <Button
        variant="contained"
        color="success"
        className="tw-mb-2 tw-mr-2"
        startIcon={<Icon icon="circle-plus" />}
        // disabled={pr_readOrWrite === 'read'} // TODO: FIX
        onClick={() => {
          openNewQuoteDialog()
        }}
      >
        {rLIB('New Quote')}
      </Button>
    )
  }

  const rJSX_NewNonBillableTaskButton = (): JSX.Element => {
    return (
      <Button
        variant="contained"
        color="success"
        className="tw-mb-2 tw-mr-2"
        startIcon={<Icon icon="circle-plus" />}
        // disabled={pr_readOrWrite === 'read'} // TODO: FIX
        onClick={() => {
          openNewNonBillableTaskDialog()
        }}
      >
        {rLIB('New Non-billable Task')}
      </Button>
    )
  }

  const rJSX_Tab = (): JSX.Element => {
    let tabJSX = (
      <Box>
        {rJSX_NewQuoteButton()}
        {rJSX_NewNonBillableTaskButton()}
        <Card>
          <TableBasic
            tableAdditionalData={{
              pr_projectKey: pr_projectKey,
            }}
            tableColumns={tableColumns_AdditionalWorkInvoices}
            tableData={objectToArray(us_projectAdditionalWorkInvoices)}
            tableSettings={tableSettings_AdditionalWorkInvoices}
          />
        </Card>
      </Box>
    )
    return tabJSX
  }

  // Render
  return <>{rJSX_Tab()}</>
})
