/* eslint-disable react/jsx-no-undef */
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Slider as MuiSlider,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { cloudFunctionUnauthenticatedRequests } from 'app/services/external_requests/external_requests'
import ReactCardFlip from 'react-card-flip'
import Slider from 'react-slick'
import { DatabaseRef_SalesOpportunity_v2_Document } from 'rfbp_aux/services/database_endpoints/sales/opportunities_v2'
import { DatabaseRef_Proposal_Document } from 'rfbp_aux/services/database_endpoints/sales/proposals'
import { Icon } from 'rfbp_core/components/icons'
import { MapBasic, TsInterface_MapSettings } from 'rfbp_core/components/map'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseUpdateDocument } from 'rfbp_core/services/database_management'
import { getProp } from 'rfbp_core/services/helper_functions'
import { setPageLocalStorage } from 'rfbp_core/services/local_storage'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { TsInterface_EditDialogState, TsInterface_Proposal } from '../authenticated_sales_opportunity_v3'
import { formatNumberToDollar } from '../components/helper'
import { submitOffsetChange, submitSystemChange, updateSurveyResponse } from '../functions/sales_opportunity_v3_functions'
import { TsInterface_FinancingCardSubsections } from '../interfaces/i_sales_tools'

//system building page sections
export const rJSX_SystemSizeSection = (stateGettersSetters: any, palette: any, opportunityKey: string, clientKey: string): JSX.Element => {
  const mapSettings: TsInterface_MapSettings = {
    center_lat: getProp(stateGettersSetters.us_leadData?.opportunityData, 'location_latitude', stateGettersSetters.us_latitude),
    center_lng: getProp(stateGettersSetters.us_leadData?.opportunityData, 'location_longitude', stateGettersSetters.us_longitude),
    map_type_id: 'satellite',
    height: '300px',
    width: 'calc(90vw)',
    zoom: 20,
    tilt: 0,
    render_heatmap: false,
    additional_map_options: {
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
    },
  }

  // const houseMarker: TsInterface_MapMarker = {
  //   label: '',
  //   position: {
  //     lat: mapSettings.center_lat,
  //     lng: mapSettings.center_lng,
  //   },
  //   icon: {
  //     path: 'M399.999,0C240.037,0 110.867,129.17 110.867,289.132C110.867,344.905 128.684,398.161 153.472,441.926L341.902,768.044C353.715,789.347 377.535,799.998 399.999,799.998C422.465,799.998 445.121,789.347 458.096,768.044L646.526,442.12C671.508,398.351 689.131,346.063 689.131,289.321C689.131,129.364 559.961,0 399.999,0ZM399.999,374.535C341.902,374.535 294.455,327.089 294.455,268.991C294.455,210.894 341.902,163.448 399.999,163.448C458.096,163.448 505.543,210.894 505.543,268.991C505.543,327.282 458.096,374.535 399.999,374.535Z',
  //     fillColor: palette.aqua,
  //     fillOpacity: 1,
  //     strokeWeight: 0.5,
  //     strokeColor: 'white',
  //     rotation: 0,
  //     scale: 0.1,
  //     anchor: new google.maps.Point(0, 0),
  //   },
  // }

  // let mapMarkers: TsInterface_MapMarkers = { houseMarker }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      {/* <Box
        component="img"
        sx={{
          height: 233,
          width: 350,
          maxHeight: { xs: 233, md: 167 },
          maxWidth: { xs: 350, md: 250 },
          marginBottom: '20px',
          borderRadius: '8px',
        }}
        src={'https://via.placeholder.com/350x233'}
      /> */}
      <MapBasic
        mapMarkers={{}}
        mapSettings={mapSettings}
      ></MapBasic>
      <Typography
        variant="h6"
        className={`tw-text-gray_600 tw-font-bold`}
        sx={{ fontFamily: 'Poppins', alignSelf: 'flex-start' }}
      >
        {rLIB('Select System Size')}
      </Typography>
      <Typography
        className="tw-text-gray_500"
        sx={{ fontFamily: 'Poppins', fontSize: '12px', marginBottom: '10px' }}
      >
        {rLIB('Move the slider to change the estimated percentage of the utility bill that your system will cover.')}
      </Typography>
      <Typography sx={{ fontFamily: 'Poppins', color: 'gray', fontWeight: 'bold' }}>Offset: {stateGettersSetters.us_offset}%</Typography>
      <MuiSlider
        sx={{ width: '95%', marginX: 'auto', color: palette.aqua, marginTop: '10px' }}
        value={stateGettersSetters.us_offset}
        valueLabelDisplay="off"
        slotProps={{
          rail: { className: 'tw-bg-gray_400 tw-h-4' },
          track: { className: 'tw-bg-gray_400 tw-h-4 tw-border-0' },
          thumb: { className: 'tw-size-8' },
        }}
        // marks
        step={1}
        min={80}
        max={130}
        onChange={(event, value) => {
          submitOffsetChange(stateGettersSetters, value as number, false, opportunityKey, clientKey)
          //setPageLocalStorage('go_solar_' + opportunityKey, 'offset', value)
        }}
        // onChangeCommitted={(event, value) => {
        //   // saveSessionData(
        //   //   clientKey,
        //   //   opportunityKey,
        //   //   us_analyticsSessionKey,
        //   //   {
        //   //     actions_taken: {
        //   //       committed_utility_confirmation_slider_change: true,
        //   //     },
        //   //   },
        //   //   !(analyticsData.queryParams.get('tracking') == 'false'),
        //   // )
        // }}
      />
      <Divider />
      {rJSX_SystemDetailsSubsection(stateGettersSetters, palette, opportunityKey, clientKey)}
    </Box>
  )
}

export const rJSX_LunarSystemSection = (stateGettersSetters: any, palette: any, opportunityKey: string, clientKey: string): JSX.Element => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <Box
        component="img"
        sx={{
          height: 233,
          width: 350,
          maxHeight: { xs: 233, md: 167 },
          maxWidth: { xs: 350, md: 250 },
          marginBottom: '20px',
          borderRadius: '8px',
        }}
        src={'https://via.placeholder.com/350x233'}
      />
      <Typography
        variant="h6"
        className={`tw-text-gray_600 tw-font-bold`}
        sx={{ fontFamily: 'Poppins', alignSelf: 'flex-start' }}
      >
        {rLIB('Battery Details')}
      </Typography>
      {/* <Typography
        className="tw-text-gray_500"
        sx={{ fontFamily: 'Poppins', fontSize: '12px', marginBottom: '10px' }}
      >
        {rLIB('Move the slider to change the estimated percentage of the utility bill that your system will cover.')}
      </Typography> */}
      {/* <Typography sx={{ fontFamily: 'Poppins', color: 'gray', fontWeight: 'bold' }}>Offset: {stateGettersSetters.us_offset}%</Typography> */}
      {/* <MuiSlider
        sx={{ width: '95%', marginX: 'auto', color: palette.aqua, marginTop: '10px' }}
        value={stateGettersSetters.us_offset}
        valueLabelDisplay="off"
        slotProps={{
          rail: { className: 'tw-bg-gray_400 tw-h-4' },
          track: { className: 'tw-bg-gray_400 tw-h-4 tw-border-0' },
          thumb: { className: 'tw-size-8' },
        }}
        // marks
        step={1}
        min={80}
        max={130}
        onChange={(event, value) => {
          //submitOffsetChange(stateGettersSetters, value as number, false, opportunityKey, clientKey)
          //setPageLocalStorage('go_solar_' + opportunityKey, 'offset', value)
        }}
        // onChangeCommitted={(event, value) => {
        //   // saveSessionData(
        //   //   clientKey,
        //   //   opportunityKey,
        //   //   us_analyticsSessionKey,
        //   //   {
        //   //     actions_taken: {
        //   //       committed_utility_confirmation_slider_change: true,
        //   //     },
        //   //   },
        //   //   !(analyticsData.queryParams.get('tracking') == 'false'),
        //   // )
        // }}
      /> */}
      <Divider />
      {rJSX_LunarBatteryDetailsSubsection(stateGettersSetters, palette, opportunityKey, clientKey)}
    </Box>
  )
}

export const rJSX_SystemDetailsSubsection = (stateGettersSetters: any, palette: any, opportunityKey: string, clientKey: string): JSX.Element => {
  const subsectionStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '12px',
    padding: '15px',
  }

  const subsectionGroupingStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  }

  const handleEditClick = (field: string, value: number) => {
    stateGettersSetters.us_setEditingField(field)
    stateGettersSetters.us_setEditValue(value.toString())
  }

  const handleEditClose = () => {
    stateGettersSetters.us_setEditingField(null)
    stateGettersSetters.us_setEditValue('')
  }

  const handleEditSave = () => {
    const newValue = parseFloat(stateGettersSetters.us_editValue)
    if (!isNaN(newValue)) {
      switch (stateGettersSetters.us_editingField) {
        case 'systemSize':
          submitSystemChange(stateGettersSetters, 'systemSize', newValue, opportunityKey, clientKey)
          break
        case 'numPanels':
          submitSystemChange(stateGettersSetters, 'numPanels', newValue, opportunityKey, clientKey)
          break
        case 'annualProduction':
          submitSystemChange(stateGettersSetters, 'annualProduction', newValue, opportunityKey, clientKey)
          break
      }
    }
    handleEditClose()
  }

  return (
    <Box className="tw-text-gray_500 tw-flex-col tw-w-full tw-mt-5">
      <Divider />
      <Box sx={{ padding: '8px' }}>
        <Typography
          className="tw-text-gray_600 tw-font-bold"
          sx={{ paddingLeft: '5px', fontFamily: 'Poppins', fontSize: '15px' }}
        >
          System Details
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ ...subsectionStyle }}>
        <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px' }}>We use top tier modules and Tesla Solar inverters</Typography>
      </Box>
      <Divider />
      <Box sx={{ ...subsectionStyle }}>
        <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px' }}>Estimated System Size</Typography>
        <Box sx={{ ...subsectionGroupingStyle }}>
          <Typography sx={{ marginRight: '10px', fontFamily: 'Poppins', fontSize: '12px' }}>
            {stateGettersSetters.us_systemSizeInWatts.toFixed(2)} kW
          </Typography>
          <IconButton onClick={() => handleEditClick('systemSize', stateGettersSetters.us_systemSizeInWatts)}>
            <Icon
              sx={{ fontSize: '14px' }}
              icon={'pen'}
            ></Icon>
          </IconButton>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ ...subsectionStyle }}>
        <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px' }}>Estimated Number of Panels</Typography>
        <Box sx={{ ...subsectionGroupingStyle }}>
          <Typography sx={{ marginRight: '10px', fontFamily: 'Poppins', fontSize: '12px' }}>{Math.floor(stateGettersSetters.us_numPanels)}</Typography>
          <IconButton onClick={() => handleEditClick('numPanels', stateGettersSetters.us_numPanels)}>
            <Icon
              sx={{ fontSize: '14px' }}
              icon={'pen'}
            ></Icon>
          </IconButton>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ ...subsectionStyle }}>
        <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px' }}>Estimated Annual Production</Typography>
        <Box sx={{ ...subsectionGroupingStyle }}>
          <Typography sx={{ marginRight: '10px', fontFamily: 'Poppins', fontSize: '12px' }}>
            {stateGettersSetters.us_systemAnnualProductionInKWh.toFixed(3)} kWh
          </Typography>
          <IconButton onClick={() => handleEditClick('annualProduction', stateGettersSetters.us_systemAnnualProductionInKWh)}>
            <Icon
              sx={{ fontSize: '14px' }}
              icon={'pen'}
            ></Icon>
          </IconButton>
        </Box>
      </Box>
      <Dialog
        open={stateGettersSetters.us_editingField !== null}
        onClose={handleEditClose}
      >
        <DialogTitle>Edit {stateGettersSetters.us_editingField}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="New Value"
            type="number"
            fullWidth
            value={stateGettersSetters.us_editValue}
            onChange={(e) => stateGettersSetters.us_setEditValue(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}>Cancel</Button>
          <Button onClick={handleEditSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export const rJSX_FinancingSection = (stateGettersSetters: any, palette: any, clientKey: string, opportunityKey: string, proposalKey?: string): JSX.Element => {
  const settings = {
    className: 'center',
    centerMode: true,
    infinite: false,
    centerPadding: '20px',
    slidesToShow: 1,
    speed: 500,
    dots: false,
  }

  return (
    <Box>
      <Typography
        variant="h6"
        className={`tw-text-gray_600 tw-font-bold`}
        sx={{ alignSelf: 'flex-start' }}
      >
        Select Financing
      </Typography>
      <Typography
        className="tw-text-gray_500"
        sx={{ fontSize: '12px', marginBottom: '15px' }}
      >
        You can buy your system outright or finance it.
      </Typography>
      <Box className="tw-w-full">
        <Slider {...settings}>
          <Box>
            <ReactCardFlip
              isFlipped={stateGettersSetters.us_isCashCardFlipped}
              flipDirection="horizontal"
            >
              <Card
                sx={{
                  width: '95%',
                  backgroundColor: stateGettersSetters.us_selectedFinancingType === 'cash' ? 'aqua' : 'lightGray',
                  cursor: 'pointer',
                  position: 'relative', // Add this
                }}
                //onClick={() => stateGettersSetters.us_setIsCashCardFlipped(!stateGettersSetters.us_isCashCardFlipped)}
              >
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    zIndex: 1,
                  }}
                  onClick={(e) => {
                    e.stopPropagation() // Prevent card flip when clicking the icon
                    stateGettersSetters.us_setIsCashCardFlipped(true)
                  }}
                >
                  <Icon
                    icon="info-circle"
                    sx={{ color: palette.gray_600 }}
                  />
                </IconButton>
                {proposalKey
                  ? rJSX_CashCard(stateGettersSetters, palette, clientKey, opportunityKey, proposalKey)
                  : rJSX_CashCard(stateGettersSetters, palette, clientKey, opportunityKey)}
              </Card>
              <Card
                sx={{
                  width: '95%',
                  backgroundColor: 'gray',
                  color: palette.white,
                  cursor: 'pointer',
                  position: 'relative', // Add this
                }}
                onClick={() => stateGettersSetters.us_setIsCashCardFlipped(!stateGettersSetters.us_isCashCardFlipped)}
              >
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    zIndex: 1,
                  }}
                  onClick={(e) => {
                    e.stopPropagation() // Prevent card flip when clicking the icon
                    stateGettersSetters.us_setIsCashCardFlipped(false)
                  }}
                ></IconButton>
                {rJSX_CashPriceNoteCard(stateGettersSetters, palette)}
              </Card>
            </ReactCardFlip>
          </Box>
          {stateGettersSetters.us_state !== 'CA' && (
            <Box>
              <ReactCardFlip
                isFlipped={stateGettersSetters.us_isLeaseCardFlipped}
                flipDirection="horizontal"
              >
                <Card
                  sx={{
                    width: '95%',
                    backgroundColor: stateGettersSetters.us_selectedFinancingType === 'lease' ? 'aqua' : 'lightGray',
                    cursor: 'pointer',
                    position: 'relative', // Add this
                  }}
                  //onClick={() => stateGettersSetters.us_setIsLeaseCardFlipped(!stateGettersSetters.us_isLeaseCardFlipped)}
                >
                  <IconButton
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      zIndex: 1,
                    }}
                    onClick={(e) => {
                      e.stopPropagation() // Prevent card flip when clicking the icon
                      stateGettersSetters.us_setIsLeaseCardFlipped(true)
                    }}
                  >
                    <Icon
                      icon="info-circle"
                      sx={{ color: palette.gray_600 }}
                    />
                  </IconButton>
                  {proposalKey
                    ? rJSX_FinancingCard(stateGettersSetters, palette, clientKey, opportunityKey, proposalKey)
                    : rJSX_FinancingCard(stateGettersSetters, palette, clientKey, opportunityKey)}
                </Card>
                <Card
                  sx={{
                    width: '95%',
                    backgroundColor: 'gray',
                    color: palette.white,
                    cursor: 'pointer',
                    position: 'relative', // Add this
                  }}
                  onClick={() => stateGettersSetters.us_setIsLeaseCardFlipped(!stateGettersSetters.us_isLeaseCardFlipped)}
                >
                  <IconButton
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      zIndex: 1,
                    }}
                    onClick={(e) => {
                      e.stopPropagation() // Prevent card flip when clicking the icon
                      stateGettersSetters.us_setIsLeaseCardFlipped(false)
                    }}
                  ></IconButton>
                  {rJSX_SolarLeaseNoteCard(stateGettersSetters, palette)}
                </Card>
              </ReactCardFlip>
            </Box>
          )}
        </Slider>
      </Box>
    </Box>
  )
}

export const rJSX_LunarBatteryDetailsSubsection = (stateGettersSetters: any, palette: any, opportunityKey: string, clientKey: string): JSX.Element => {
  const subsectionStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '12px',
    padding: '15px',
  }

  const subsectionGroupingStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  }

  return (
    <Box className="tw-text-gray_500 tw-flex-col tw-w-full tw-mt-5">
      <Divider />
      <Box sx={{ padding: '8px' }}>
        <Typography
          className="tw-text-gray_600 tw-font-bold"
          sx={{ paddingLeft: '5px', fontFamily: 'Poppins', fontSize: '15px' }}
        >
          {rLIB('Lunar Battery Details')}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ ...subsectionStyle }}>
        <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px' }}>
          'We are partnered with Lunar Battery to offer the best battery storage solution for your home.
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ ...subsectionStyle }}>
        <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px' }}>Estimated Battery Capacity</Typography>
        <Box sx={{ ...subsectionGroupingStyle }}>
          <Typography sx={{ marginRight: '10px', fontFamily: 'Poppins', fontSize: '12px' }}>10 kWh - 30 kWh</Typography>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ ...subsectionStyle }}>
        <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px' }}>Estimated Output Power</Typography>
        <Box sx={{ ...subsectionGroupingStyle }}>
          <Typography sx={{ marginRight: '10px', fontFamily: 'Poppins', fontSize: '12px' }}>9.6 kW</Typography>
        </Box>
      </Box>
    </Box>
  )
}

export const rJSX_LunarBatteryFinancingSection = (stateGettersSetters: any, palette: any, clientKey: string, opportunityKey: string): JSX.Element => {
  const settings = {
    className: 'center',
    centerMode: true,
    infinite: false,
    centerPadding: '20px',
    slidesToShow: 1,
    speed: 500,
    dots: false,
  }

  return (
    <Box sx={{ marginTop: '-20px' }}>
      <Typography
        variant="h6"
        className={`tw-text-gray_600 tw-font-bold`}
        sx={{ alignSelf: 'flex-start', marginBottom: '20px' }}
      >
        Financing
      </Typography>
      {/* <Typography
        className="tw-text-gray_500"
        sx={{ fontSize: '12px', marginBottom: '15px' }}
      >
        W
      </Typography> */}
      <Box className="tw-w-full">
        <Slider {...settings}>
          <Box>
            <ReactCardFlip
              isFlipped={stateGettersSetters.us_isCashCardFlipped}
              flipDirection="horizontal"
            >
              <Card
                sx={{
                  width: '95%',
                  backgroundColor: stateGettersSetters.us_selectedFinancingType === 'cash' ? 'aqua' : 'lightGray',
                  cursor: 'pointer',
                  position: 'relative', // Add this
                }}
                //onClick={() => stateGettersSetters.us_setIsCashCardFlipped(!stateGettersSetters.us_isCashCardFlipped)}
              >
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    zIndex: 1,
                  }}
                  onClick={(e) => {
                    e.stopPropagation() // Prevent card flip when clicking the icon
                    stateGettersSetters.us_setIsCashCardFlipped(true)
                  }}
                >
                  <Icon
                    icon="info-circle"
                    sx={{ color: palette.gray_600 }}
                  />
                </IconButton>
                {rJSX_CashCard(stateGettersSetters, palette, clientKey, opportunityKey)}
              </Card>
              <Card
                sx={{
                  width: '95%',
                  backgroundColor: 'gray',
                  color: palette.white,
                  cursor: 'pointer',
                  position: 'relative', // Add this
                }}
                onClick={() => stateGettersSetters.us_setIsCashCardFlipped(!stateGettersSetters.us_isCashCardFlipped)}
              >
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    zIndex: 1,
                  }}
                  onClick={(e) => {
                    e.stopPropagation() // Prevent card flip when clicking the icon
                    stateGettersSetters.us_setIsCashCardFlipped(false)
                  }}
                ></IconButton>
                {rJSX_CashPriceNoteCard(stateGettersSetters, palette)}
              </Card>
            </ReactCardFlip>
          </Box>
        </Slider>
      </Box>
    </Box>
  )
}

//system details page sections
export const rJSX_SystemDetails = (stateGettersSetters: any, palette: any): JSX.Element => {
  const subsectionStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '12px',
    padding: '15px',
  }

  const subsectionGroupingStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  }

  return (
    <Box className="tw-text-gray_500 tw-flex-col tw-w-full tw-mt-5">
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
        <Typography
          className="tw-text-gray_600 tw-font-bold"
          sx={{ fontFamily: 'Poppins', fontSize: '16px' }}
        >
          {rLIB('System Details')}
        </Typography>
        <Button
          className="tw-text-gray_600"
          endIcon={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Icon
                icon="pen"
                sx={{ fontFamily: 'Poppins', fontSize: '10px' }}
              />
            </Box>
          }
          onClick={() => {
            stateGettersSetters.us_setCurrentView('buildSystem')
            stateGettersSetters.us_setSelectedTab('systemDetails')

            setTimeout(() => {
              const element = document.getElementById(stateGettersSetters.us_section1Ref)
              if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' })
              }
            }, 100)
          }}
          sx={{
            'fontFamily': 'Poppins',
            'textTransform': 'none',
            '&:hover': {
              backgroundColor: 'transparent',
            },
            'fontSize': '8px',
          }}
        >
          Edit
        </Button>
      </Box>
      <Divider />
      <Box sx={{ ...subsectionStyle }}>
        <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px' }}>{rLIB('System Size')}</Typography>
        <Box sx={{ ...subsectionGroupingStyle }}>
          <Typography sx={{ fontFamily: 'Poppins', marginRight: '10px', fontSize: '12px' }}>
            {stateGettersSetters.us_systemSizeInWatts.toFixed(2)} kW
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ ...subsectionStyle }}>
        <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px' }}>{rLIB('Estimated Number of Panels')}</Typography>
        <Box sx={{ ...subsectionGroupingStyle }}>
          <Typography sx={{ fontFamily: 'Poppins', marginRight: '10px', fontSize: '12px' }}>{Math.floor(stateGettersSetters.us_numPanels)}</Typography>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ ...subsectionStyle }}>
        <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px' }}>{rLIB('Estimated Annual Production')}</Typography>
        <Box sx={{ ...subsectionGroupingStyle }}>
          <Typography sx={{ fontFamily: 'Poppins', marginRight: '10px', fontSize: '12px' }}>
            {stateGettersSetters.us_systemAnnualProductionInKWh.toFixed(3)} kWh
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ ...subsectionStyle }}>
        <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px' }}>{rLIB('Mount Choice')}</Typography>
        <Box sx={{ ...subsectionGroupingStyle }}>
          <Typography sx={{ fontFamily: 'Poppins', marginRight: '10px', fontSize: '12px' }}>{stateGettersSetters.us_selectedMountType}</Typography>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ ...subsectionStyle }}>
        <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px' }}>{rLIB('Reroof')}</Typography>
        <Box sx={{ ...subsectionGroupingStyle }}>
          <Typography sx={{ fontFamily: 'Poppins', marginRight: '10px', fontSize: '12px' }}>{stateGettersSetters.us_roofReplacement ? 'Yes' : 'No'}</Typography>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ ...subsectionStyle }}>
        <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px' }}>{rLIB('Batteries')}</Typography>
        <Box sx={{ ...subsectionGroupingStyle }}>
          <Typography sx={{ fontFamily: 'Poppins', marginRight: '10px', fontSize: '12px' }}>{stateGettersSetters.us_numBatteries}</Typography>
        </Box>
      </Box>
    </Box>
  )
}

export const rJSX_FinancingDetails = (stateGettersSetters: any, palette: any): JSX.Element => {
  const subsectionStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '12px',
    padding: '15px',
  }

  const subsectionGroupingStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  }

  const handleLooksGoodClick = () => {
    stateGettersSetters.us_setLooksGood(true)

    setTimeout(() => {
      const userDetailsElement = document.getElementById('userDetailsSection')
      if (userDetailsElement) {
        userDetailsElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }, 200)
  }

  return (
    <Box className="tw-text-gray_500 tw-flex-col tw-w-full tw-mt-5">
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
        <Typography
          className="tw-text-gray_600 tw-font-bold"
          sx={{ fontFamily: 'Poppins', fontSize: '16px' }}
        >
          Financing
        </Typography>
        <Button
          className="tw-text-gray_600"
          endIcon={
            <Box sx={{ fontFamily: 'Poppins', display: 'flex', alignItems: 'center' }}>
              <Icon
                icon="pen"
                sx={{ fontFamily: 'Poppins', fontSize: '10px' }}
              />
            </Box>
          }
          onClick={() => {
            stateGettersSetters.us_setCurrentView('buildSystem')
            stateGettersSetters.us_setSelectedTab('financingDetails')

            setTimeout(() => {
              const element = document.getElementById(stateGettersSetters.us_section2Ref)
              if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' })
              }
            }, 100)
          }}
          sx={{
            'fontFamily': 'Poppins',
            'textTransform': 'none',
            '&:hover': {
              backgroundColor: 'transparent',
            },
            'fontSize': '8px',
          }}
        >
          Edit
        </Button>
      </Box>
      <Divider />
      <Box sx={{ ...subsectionStyle }}>
        <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px' }}>Selected Option</Typography>
        <Box sx={{ ...subsectionGroupingStyle }}>
          <Typography sx={{ fontFamily: 'Poppins', marginRight: '10px', fontSize: '12px' }}>{stateGettersSetters.us_selectedFinancingType}</Typography>
        </Box>
      </Box>
      <Box sx={{ ...subsectionStyle }}>
        <Typography
          className="tw-font-bold tw-text-gray_600"
          sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
        >
          Est. System Cost
        </Typography>
        <Box sx={{ ...subsectionGroupingStyle }}>
          <Typography sx={{ fontFamily: 'Poppins', marginRight: '10px', fontSize: '12px' }}>
            {formatNumberToDollar(Math.floor(stateGettersSetters.us_baseCashCost))}
          </Typography>
        </Box>
      </Box>

      <Divider />
      <Box sx={{ ...subsectionStyle }}>
        <Typography
          className="tw-font-bold tw-text-gray_600"
          sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
        >
          Potential Incentives
        </Typography>
        <Box sx={{ ...subsectionGroupingStyle }}>
          <Typography sx={{ fontFamily: 'Poppins', marginRight: '10px', fontSize: '12px' }}>
            -{formatNumberToDollar(Math.floor(stateGettersSetters.us_federalIncentive + stateGettersSetters.us_srecIncentive))}
          </Typography>
          <IconButton
            size="medium"
            onClick={() => stateGettersSetters.us_setShowIncentives(!stateGettersSetters.us_showIncentives)}
            sx={{
              fontFamily: 'Poppins',
              transform: stateGettersSetters.us_showIncentives ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s',
            }}
          >
            <Icon
              icon={'chevron-up'}
              className="tw-text-gray_600 tw-font-bold"
              sx={{ fontFamily: 'Poppins', fontSize: '10px' }}
            />
          </IconButton>
        </Box>
      </Box>
      <Collapse in={stateGettersSetters.us_showIncentives}>
        <Box sx={{ paddingLeft: '20px' }}>
          <Box sx={{ ...subsectionStyle, borderTop: '1px dashed #E0E0E0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px' }}>{rLIB('Federal Tax Credit (30%)')}</Typography>
              <IconButton
                size="small"
                sx={{ fontFamily: 'Poppins', color: 'gray', marginLeft: '4px' }} // Adjust margin to control spacing
                onClick={() => {
                  // TODO: Add functionality for the info button
                }}
              >
                <Icon icon="circle-info" />
              </IconButton>
            </Box>
            <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px', marginRight: '10px' }}>
              -{formatNumberToDollar(Math.floor(stateGettersSetters.us_federalIncentive))}
            </Typography>
          </Box>
          {stateGettersSetters.us_srecIncentive > 0 && (
            <Box sx={{ ...subsectionStyle, borderTop: '1px dashed #E0E0E0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px' }}>{rLIB('State Incentives')}</Typography>
                <IconButton
                  size="small"
                  sx={{ fontFamily: 'Poppins', color: 'gray', marginLeft: '4px' }} // Adjust margin to control spacing
                  onClick={() => {
                    // TODO: Add functionality for the info button
                  }}
                >
                  <Icon icon="circle-info" />
                </IconButton>
              </Box>
              <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px', marginRight: '10px' }}>
                -{formatNumberToDollar(Math.floor(stateGettersSetters.us_srecIncentive))}
              </Typography>
            </Box>
          )}
        </Box>
      </Collapse>
      <Divider />
      <Divider />
      <Box sx={{ ...subsectionStyle }}>
        <Typography
          className="tw-font-bold tw-text-gray_600"
          sx={{ fontFamily: 'Poppins', fontSize: '12px', paddingTop: '10px' }}
        >
          Price after potential incentives:
        </Typography>
        <Box sx={{ ...subsectionGroupingStyle }}>
          <Typography sx={{ fontFamily: 'Poppins', marginRight: '10px', fontSize: '12px' }}>
            {formatNumberToDollar(Math.floor(stateGettersSetters.us_currentCashCost))}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          className="tw-w-1/2 tw-mt-4"
          onClick={handleLooksGoodClick}
          sx={{
            'fontFamily': 'Poppins',
            'textTransform': 'none',
            'backgroundColor': '#42deff',
            'color': palette.white,
            '&:hover': {
              backgroundColor: palette.aqua,
            },
            'borderRadius': '20px',
            'fontSize': '15px',
            'padding': '10px 10px',
          }}
        >
          Looks Good
        </Button>
      </Box>
    </Box>
  )
}

export const rJSX_UserDetails = (stateGettersSetters: any, palette: any, opportunityKey: string, clientKey: string): JSX.Element => {
  const subsectionStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '12px',
    padding: '5px',
  }

  const textFieldStyle = {
    marginBottom: '10px',
    width: '100%',
    fontFamily: 'Poppins',
  }

  const subsectionGroupingStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
    marginBottom: '0px',
  }

  function isValidEmail(email: string) {
    // Regular expression pattern for validating email address
    let emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    // Test the email against the pattern
    return emailPattern.test(email)
  }

  function isValidPhone(phone: string) {
    // Regular expression pattern for validating phone number
    let phonePattern = /^\d{10}$/
    // Test the phone number against the pattern
    return phonePattern.test(phone)
  }

  return (
    <Box
      className="tw-text-gray_500 tw-flex-col tw-w-full tw-mt-8"
      id="userDetailsSection"
      sx={{
        marginTop: '50px',
      }}
    >
      <Typography
        variant="h4"
        sx={{ fontFamily: 'Poppins', marginLeft: '5px', marginBottom: '5px', fontWeight: 'bold', fontSize: '20px' }}
        className="tw-text-gray_600 tw-font-bold"
      >
        {rLIB('Enter Account Information')}
      </Typography>
      <Typography
        variant="body1"
        sx={{ fontFamily: 'Poppins', marginLeft: '5px', marginBottom: '5px', color: palette.darkGray, fontSize: '12px' }}
      >
        {rLIB('Enter your account information below to complete your order.')}
      </Typography>
      <Box sx={{ ...subsectionStyle }}>
        <TextField
          label="First Name"
          variant="filled"
          size="small"
          value={stateGettersSetters.us_firstName}
          onChange={(e) => stateGettersSetters.us_setFirstName(e.target.value)}
          onBlur={() => {
            if (!stateGettersSetters.us_isDatabaseMode) {
              setPageLocalStorage('go_solar', 'associated_customer_first_name', stateGettersSetters.us_firstName)
            } else {
              cloudFunctionUnauthenticatedRequests({
                function: 'updateOpportunity_v2',
                client_key: clientKey,
                opportunity_key: opportunityKey,
                opportunity_data: { associated_customer_first_name: stateGettersSetters.us_firstName },
                trigger_page_refresh: false,
              }).catch((error) => {
                console.error('Error updating opportunity:', error)
              })
            }
          }}
          sx={textFieldStyle}
        />
      </Box>
      <Box sx={{ ...subsectionStyle }}>
        <TextField
          label="Last Name"
          variant="filled"
          size="small"
          value={stateGettersSetters.us_lastName}
          onChange={(e) => {
            stateGettersSetters.us_setLastName(e.target.value)
          }}
          onBlur={() => {
            if (!stateGettersSetters.us_isDatabaseMode) {
              setPageLocalStorage('go_solar', 'associated_customer_last_name', stateGettersSetters.us_lastName)
              setPageLocalStorage('go_solar', 'associated_customer_name', stateGettersSetters.us_firstName + ' ' + stateGettersSetters.us_lastName)
            } else {
              cloudFunctionUnauthenticatedRequests({
                function: 'updateOpportunity_v2',
                client_key: clientKey,
                opportunity_key: opportunityKey,
                opportunity_data: {
                  associated_customer_last_name: stateGettersSetters.us_lastName,
                  associated_customer_name: stateGettersSetters.us_firstName + ' ' + stateGettersSetters.us_lastName,
                },
                trigger_page_refresh: false,
              }).catch((error) => {
                console.error('Error updating opportunity:', error)
              })
            }
          }}
          sx={textFieldStyle}
        />
      </Box>
      <Box sx={{ ...subsectionStyle }}>
        <TextField
          label="Email"
          variant="filled"
          size="small"
          value={stateGettersSetters.us_email}
          onChange={(e) => {
            stateGettersSetters.us_setEmail(e.target.value)
            if (isValidEmail(e.target.value)) {
              stateGettersSetters.us_setEmailError(false)
            } else {
              stateGettersSetters.us_setEmailError('Please enter a valid email address.')
            }
          }}
          onBlur={() => {
            if (!stateGettersSetters.us_isDatabaseMode) {
              setPageLocalStorage('go_solar', 'associated_customer_email', stateGettersSetters.us_email)
            } else {
              cloudFunctionUnauthenticatedRequests({
                function: 'updateOpportunity_v2',
                client_key: clientKey,
                opportunity_key: opportunityKey,
                opportunity_data: { associated_customer_email: stateGettersSetters.us_email },
                trigger_page_refresh: false,
              }).catch((error) => {
                console.error('Error updating opportunity:', error)
              })
            }
          }}
          error={!!stateGettersSetters.us_emailError}
          helperText={stateGettersSetters.us_emailError}
          sx={textFieldStyle}
        />
      </Box>
      <Box sx={{ ...subsectionStyle }}>
        <TextField
          label="Phone Number"
          variant="filled"
          size="small"
          value={stateGettersSetters.us_phone}
          onChange={(e) => {
            stateGettersSetters.us_setPhone(e.target.value)
            if (isValidPhone(e.target.value)) {
              stateGettersSetters.us_setPhoneError(false)
            } else {
              stateGettersSetters.us_setPhoneError('Please enter a valid phone number.')
            }
          }}
          onBlur={() => {
            if (!stateGettersSetters.us_isDatabaseMode) {
              setPageLocalStorage('go_solar', 'associated_customer_phone', stateGettersSetters.us_phone)
            } else {
              cloudFunctionUnauthenticatedRequests({
                function: 'updateOpportunity_v2',
                client_key: clientKey,
                opportunity_key: opportunityKey,
                opportunity_data: { associated_customer_phone: stateGettersSetters.us_phone },
                trigger_page_refresh: false,
              }).catch((error) => {
                console.error('Error updating opportunity:', error)
              })
            }
          }}
          error={!!stateGettersSetters.us_phoneError}
          helperText={stateGettersSetters.us_phoneError}
          sx={textFieldStyle}
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '5px', marginTop: '20px', marginBottom: '10px' }}>
        <Typography
          className="tw-text-gray_600 tw-font-bold"
          sx={{ fontFamily: 'Poppins', fontSize: '20px' }}
        >
          {rLIB('Payment Details')}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ ...subsectionStyle }}>
        <Box sx={{ ...subsectionGroupingStyle, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography sx={{ fontFamily: 'Poppins', fontColor: '#000000', fontSize: '16px' }}>{rLIB('Due Today')}</Typography>
          <IconButton
            size="small"
            sx={{ paddingLeft: '10px', color: 'black' }} // Set the color to black
            onClick={() => {
              stateGettersSetters.us_setDepositText(true)
            }}
          >
            <Icon icon="circle-info" />
          </IconButton>
        </Box>
        <Box sx={{ ...subsectionGroupingStyle }}>
          <Typography sx={{ fontFamily: 'Poppins', fontSize: '16px', textDecoration: stateGettersSetters.us_depositRequired ? 'none' : 'line-through' }}>
            $500
          </Typography>
        </Box>
      </Box>
      <Box sx={{ ...subsectionStyle }}>
        {!stateGettersSetters.us_depositRequired && stateGettersSetters.us_depositText && (
          <Typography sx={{ fontFamily: 'Poppins', fontSize: '16px', color: palette.darkGray, fontWeight: 'bold', marginBottom: '10px', textAlign: 'center' }}>
            {rLIB("No deposit required! We're currently waiving this and look forward to having you as our customer!")}
          </Typography>
        )}
      </Box>
      {!stateGettersSetters.us_depositRequired && stateGettersSetters.us_depositText && <Divider />}
      <Box sx={{ ...subsectionStyle, marginBottom: '20px' }}>
        {stateGettersSetters.us_depositRequired && stateGettersSetters.us_depositText && (
          <Typography sx={{ fontFamily: 'Poppins', fontSize: '10.5px', textAlign: 'center', paddingTop: '0px' }}>
            {rLIB(
              'Fully refundable until we finalize your design and your contract is signed. Your pricing may change based on a final review of your design. By submitting this order, I am agreeing to ETWs Order Terms, Payment Terms, and Privacy Policy.',
            )}
          </Typography>
        )}
      </Box>
      {stateGettersSetters.us_depositRequired && stateGettersSetters.us_depositText && <Divider />}
      {!stateGettersSetters.us_depositText && <Divider />}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginTop: '20px', paddingLeft: '5px' }}>
        <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px', flex: 1 }}>
          {rLIB(
            'I authorize ETW Energy, LLC ("ETW") to call me and send pre-recorded messages and text messages to me about ETW products and services via the contact information I provide, even if I am on a national or state "Do Not Call" list. Messages and data rates may apply. Maximum 10 texts per month. Consent for calls & texts is optional. I can opt out anytime.',
          )}
        </Typography>
        <Checkbox
          checked={stateGettersSetters.us_agreeTerms}
          onChange={(e) => stateGettersSetters.us_setAgreeTerms(e.target.checked)} // Update state on change
          color="default"
        />
      </Box>
    </Box>
  )
}

// card carousel cards

const rJSX_CashPriceNoteCard = (stateGettersSetters: any, palette: any): JSX.Element => {
  return (
    <Box sx={{ textAlign: 'center', padding: '10px', height: '425px' }}>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'right', paddingX: '10px', paddingTop: '10px' }}>
        <Icon
          icon={'x'}
          onClick={() => {
            stateGettersSetters.us_setShowCashPriceNoteCard(false)
          }}
        ></Icon>
      </Box>
      <Typography
        variant="h6"
        className="tw-mt-4"
        sx={{ fontFamily: 'Poppins' }}
      >
        {rLIB('Cash Price Note')}
      </Typography>
      <Typography sx={{ fontFamily: 'Poppins', fontSize: '14px', marginBottom: '15px' }}>The system is the amount that is due at install.</Typography>
      <Typography sx={{ fontFamily: 'Poppins', fontSize: '14px', marginBottom: '15px' }}>
        The Federal Tax Credit (as the title implies) is a credit against your federal taxes that you get when you file your taxes. Please talk with your tax
        advisor about claiming this credit.
      </Typography>
      <Typography sx={{ fontFamily: 'Poppins', fontSize: '14px', marginBottom: '15px' }}>
        The IL Shines SREC comes back as a check to you, though it is typically delayed by a few months. For more information, visit the IL Shines website. Once
        we install your system, you'll be able to track your credit there.
      </Typography>
    </Box>
  )
}

const rJSX_SolarLeaseNoteCard = (stateGettersSetters: any, palette: any): JSX.Element => {
  return (
    <Box sx={{ textAlign: 'center', padding: '10px', height: '425px' }}>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'right', paddingX: '10px', paddingTop: '10px' }}>
        <Icon
          icon={'x'}
          onClick={() => {
            stateGettersSetters.us_setShowSolarLeaseNoteCard(false)
          }}
        ></Icon>
      </Box>
      <Typography
        variant="h6"
        className="tw-mt-4"
        sx={{ fontFamily: 'Poppins' }}
      >
        {rLIB('Solar Lease Note')}
      </Typography>
      <Typography sx={{ fontFamily: 'Poppins', fontSize: '15px', marginBottom: '15px' }}>
        Our solar leases are offered by Lightreach, a premiere financing company.
      </Typography>
      <Typography sx={{ fontFamily: 'Poppins', fontSize: '15px', marginBottom: '15px' }}>Payments will start 60 days from the day of install.</Typography>
      <Typography sx={{ fontFamily: 'Poppins', fontSize: '15px', marginBottom: '15px' }}>
        The system will be maintained by the leasing company. This means that any issues with the system will be fixed by the leasing company at their expense.
      </Typography>
    </Box>
  )
}

const rJSX_CashCard = (stateGettersSetters: any, palette: any, clientKey: string, opportunityKey: string, proposalKey?: string) => {
  let selectedFinancingType: string = ''
  let baseCashCost: number = 0
  let federalIncentive: number = 0
  let srecIncentive: number = 0
  let currentCashCost: number = 0

  if (stateGettersSetters.us_activeProposal === 'original') {
    selectedFinancingType = stateGettersSetters.us_selectedFinancingType
    baseCashCost = stateGettersSetters.us_baseCashCost
    federalIncentive = stateGettersSetters.us_federalIncentive
    srecIncentive = stateGettersSetters.us_srecIncentive
    currentCashCost = stateGettersSetters.us_currentCashCost
  } else if (stateGettersSetters.us_activeProposal === 'secondary') {
    selectedFinancingType = stateGettersSetters.us_secondarySelectedFinancingType
    baseCashCost = stateGettersSetters.us_secondaryBaseCashCost
    federalIncentive = stateGettersSetters.us_secondaryFederalIncentive
    srecIncentive = stateGettersSetters.us_secondarySrecIncentive
    currentCashCost = stateGettersSetters.us_secondaryCurrentCashCost
  } else if (stateGettersSetters.us_activeProposal === 'tertiary') {
    selectedFinancingType = stateGettersSetters.us_tertiarySelectedFinancingType
    baseCashCost = stateGettersSetters.us_tertiaryBaseCashCost
    federalIncentive = stateGettersSetters.us_tertiaryFederalIncentive
    srecIncentive = stateGettersSetters.us_tertiarySrecIncentive
    currentCashCost = stateGettersSetters.us_tertiaryCurrentCashCost
  }

  const cashCardSubsections: TsInterface_FinancingCardSubsections = {
    subsections: [
      {
        sectionTitle: 'Est. System Cost',
        switch: false,
        displayInfo: formatNumberToDollar(baseCashCost),
        bottomBorder: 'solid',
      },
      {
        sectionTitle: 'Potential Incentives',
        switch: true,
        switchCaptionLeft: 'Off',
        switchCaptionRight: 'On',
        displayInfo: formatNumberToDollar(federalIncentive + srecIncentive),
        bottomBorder: 'dashed',
      },
      {
        sectionTitle: 'Federal Tax Credit (30%)',
        switch: false,
        displayInfo: formatNumberToDollar(federalIncentive),
        bottomBorder: 'dashed',
      },
      {
        sectionTitle: 'SREC Value',
        switch: false,
        displayInfo: formatNumberToDollar(srecIncentive),
        bottomBorder: 'solid',
      },
      {
        sectionTitle: 'Price After Potential Incentives',
        switch: false,
        displayInfo: formatNumberToDollar(currentCashCost),
        bottomBorder: 'none',
      },
    ],
  }
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '425px' }}>
      <Typography
        variant="h6"
        className="tw-font-bold tw-mt-2"
        sx={{ fontFamily: 'Poppins', color: selectedFinancingType === 'cash' ? 'black' : 'gray' }}
      >
        Cash
      </Typography>
      <Typography
        sx={{
          fontFamily: 'Poppins',
          fontSize: '12px',
          textAlign: 'center',
          width: '60%',
          color: selectedFinancingType === 'cash' ? 'black' : 'gray',
        }}
      >
        Pay for your system upfront and collect all incentives.
      </Typography>
      <Box
        className="tw-flex tw-justify-center tw-items-center"
        sx={{ marginTop: '20px', marginBottom: '20px' }}
      >
        {proposalKey
          ? rJSX_CardSubsections(stateGettersSetters, palette, cashCardSubsections, 'cash', clientKey, opportunityKey, proposalKey)
          : rJSX_CardSubsections(stateGettersSetters, palette, cashCardSubsections, 'cash', clientKey, opportunityKey)}
      </Box>
    </Box>
  )
}

const rJSX_FinancingCard = (stateGettersSetters: any, palette: any, clientKey: string, opportunityKey: string, proposalKey?: string) => {
  let selectedFinancingType: string = ''
  let leaseCost: number = 0
  let electricityBill: number = stateGettersSetters.us_electricityBill

  if (stateGettersSetters.us_activeProposal === 'original') {
    selectedFinancingType = stateGettersSetters.us_selectedFinancingType
    leaseCost = stateGettersSetters.us_leaseCost
  } else if (stateGettersSetters.us_activeProposal === 'secondary') {
    selectedFinancingType = stateGettersSetters.us_secondarySelectedFinancingType
    leaseCost = stateGettersSetters.us_secondaryLeaseCost
  } else if (stateGettersSetters.us_activeProposal === 'tertiary') {
    selectedFinancingType = stateGettersSetters.us_tertiarySelectedFinancingType
    leaseCost = stateGettersSetters.us_tertiaryLeaseCost
  }

  const financingCardSubsections: TsInterface_FinancingCardSubsections = {
    subsections: [
      {
        sectionTitle: 'Est. Monthly Payment',
        switch: false,
        displayInfo: formatNumberToDollar(leaseCost),
        bottomBorder: 'solid',
      },
      {
        sectionTitle: 'Est. New Utility Bill',
        switch: false,
        displayInfo: formatNumberToDollar(electricityBill),
        bottomBorder: 'dashed',
      },
      {
        sectionTitle: 'Total Monthly Cost',
        switch: false,
        displayInfo: formatNumberToDollar(leaseCost + electricityBill),
        bottomBorder: 'dashed',
      },
      {
        sectionTitle: '',
        sectionTitleDetails: '$0 down, 25-year lease, 2.99% escalator',
        switch: false,
        displayInfo: <Icon icon={'edit'}></Icon>,
        bottomBorder: 'none',
      },
    ],
  }
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '425px' }}>
      <Typography
        variant="h6"
        className=" tw-font-bold tw-mt-2"
        sx={{ fontFamily: 'Poppins', color: selectedFinancingType === 'lease' ? 'black' : 'gray' }}
      >
        Solar Lease
      </Typography>
      <Typography
        sx={{
          fontFamily: 'Poppins',
          fontSize: '12px',
          textAlign: 'center',
          width: '60%',
          color: selectedFinancingType === 'lease' ? 'black' : 'gray',
        }}
      >
        List a system from a reputed provider for a low monthly payment.
      </Typography>
      <Box
        className="tw-flex tw-justify-center tw-items-center"
        sx={{ marginTop: '20px', marginBottom: '20px' }}
      >
        {proposalKey
          ? rJSX_CardSubsections(stateGettersSetters, palette, financingCardSubsections, 'lease', clientKey, opportunityKey, proposalKey)
          : rJSX_CardSubsections(stateGettersSetters, palette, financingCardSubsections, 'lease', clientKey, opportunityKey)}
      </Box>
    </Box>
  )
}

const rJSX_CardSubsections = (
  stateGettersSetters: any,
  palette: any,
  subsections: TsInterface_FinancingCardSubsections,
  financingType: string,
  clientKey: string,
  opportunityKey: string,
  proposalKey?: string,
): JSX.Element => {
  const isSelected = stateGettersSetters.us_selectedFinancingType === financingType
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {subsections.subsections.map((subsection, index) => {
        return (
          <Box
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              // alignItems: 'center',
              justifyContent: 'space-between',
              borderBottom: `1px ${subsection.bottomBorder} ${isSelected ? 'black' : '#E5E7EB'}`,
              padding: '10px',
            }}
          >
            <Box>
              <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px', fontWeight: 'bold', color: isSelected ? 'black' : 'gray' }}>
                {subsection.sectionTitle}
              </Typography>
              <Typography sx={{ fontFamily: 'Poppins', fontSize: '10px', color: isSelected ? 'black' : 'gray' }}>{subsection.sectionTitleDetails}</Typography>
            </Box>
            {subsection.switch && (
              <Box sx={{ marginX: '6px' }}>
                <Typography
                  variant="caption"
                  sx={{ fontFamily: 'Poppins', fontSize: '8px', color: isSelected ? 'black' : 'gray' }}
                >
                  {subsection?.switchCaptionLeft}
                </Typography>
                <Switch defaultChecked={true}></Switch>
                <Typography
                  variant="caption"
                  sx={{ fontFamily: 'Poppins', fontSize: '8px', color: isSelected ? 'black' : 'gray' }}
                >
                  {subsection?.switchCaptionRight}
                </Typography>
              </Box>
            )}
            <Typography sx={{ fontFamily: 'Poppins', fontSize: '10px', color: isSelected ? 'black' : 'gray' }}>{subsection.displayInfo}</Typography>
          </Box>
        )
      })}
      <Button
        sx={{
          'backgroundColor': isSelected ? palette.white : palette.aqua,
          'color': isSelected ? palette.black : palette.white,
          'fontSize': '15px',
          'borderRadius': '18px',
          'marginTop': '10px',
          'width': '40%',
          'marginX': 'auto',
          '&.Mui-disabled': {
            backgroundColor: palette.white,
            color: palette.black,
          },
          '&.MuiButton-root': {
            backgroundColor: isSelected ? palette.white : palette.aqua,
          },
        }}
        disabled={isSelected ? true : false}
        onClick={() => {
          stateGettersSetters.us_setSelectedFinancingType(financingType)

          if (stateGettersSetters.us_isDatabaseMode) {
            cloudFunctionUnauthenticatedRequests({
              function: 'updateOpportunity_v2',
              client_key: clientKey,
              opportunity_key: opportunityKey,
              opportunity_data: { associated_financing_type: financingType },
              trigger_page_refresh: false,
            })
            // setPageLocalStorage('go_solar_' + stateGettersSetters.us_leadData.opportunityKey, 'financingType', financingType)
          } else if (clientKey && proposalKey) {
            DatabaseUpdateDocument(DatabaseRef_Proposal_Document(clientKey, proposalKey), {
              associated_financing_type: financingType,
            })
          } else {
            setPageLocalStorage('go_solar', 'associated_financing_type', financingType)
          }
        }}
      >
        {isSelected ? 'Selected' : 'Select'}
      </Button>
      <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px', marginX: 'auto', marginTop: '15px', color: isSelected ? 'black' : 'gray' }}>
        {rLIB(`Not sure what's best? See our`)} <u style={{ color: isSelected ? palette.black : palette.teal }}>{rLIB('guide below')}</u>
      </Typography>
    </Box>
  )
}

export const rJSX_HelpMeChooseSection = (stateGettersSetters: any, palette: any): JSX.Element => {
  const settings = {
    className: 'center',
    centerMode: true,
    infinite: false,
    centerPadding: '20px',
    slidesToShow: 1,
    speed: 500,
    dots: false,
  }
  return (
    <Box>
      <Accordion
        elevation={0}
        defaultExpanded={stateGettersSetters.us_url === 'go_lunar' ? true : false}
      >
        <AccordionSummary
          expandIcon={<KeyboardArrowDownIcon sx={{ color: palette.darkGray, fontSize: '50px' }} />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography
            variant="h6"
            className="tw-text-gray_600 tw-font-bold"
            sx={{ fontFamily: 'Poppins' }}
          >
            {rLIB('Help Me Choose')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Slider {...settings}>
            <Box sx={{ height: '500px' }}>{rJSX_FinancingInfoSliderCard(stateGettersSetters, palette)}</Box>
            <Box sx={{ height: '500px' }}>{rJSX_WarrantySliderCard(stateGettersSetters, palette)}</Box>
            <Box sx={{ height: '500px' }}>{rJSX_EquipmentSliderCard(stateGettersSetters, palette)}</Box>
          </Slider>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

const rJSX_FinancingInfoSliderCard = (stateGettersSetters: any, palette: any): JSX.Element => {
  return (
    <Card
      className="tw-bg-gray_300"
      sx={{ height: '100%', width: '95%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}
    >
      <Box
        component="img"
        sx={{
          height: 200,
          width: 280,
          maxHeight: { xs: 233, md: 167 },
          maxWidth: { xs: 350, md: 250 },
          marginBottom: '10px',
        }}
        src={'/financing.png'}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '15px' }}>
        <Typography
          variant="subtitle1"
          className="tw-text-gray_600 tw-font-bold"
          sx={{ fontFamily: 'Poppins' }}
        >
          {rLIB('What Financing is Best?')}
        </Typography>
        <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px', marginBottom: '15px' }}>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Distinctio suscipit vitae harum omnis temporibus nobis quae, officia totam natus id quasi
          consectetur deleniti nulla labore, voluptatibus in perferendis delectus similique.
        </Typography>
        <Button
          sx={{
            'backgroundColor': 'aqua',
            'color': 'white',
            'fontSize': '15px',
            'borderRadius': '18px',
            'marginTop': '10px',
            'width': '50%',
            'marginX': 'auto',
            '&.MuiButton-root': {
              backgroundColor: palette.aqua,
            },
          }}
        >
          {rLIB('Read More')}
        </Button>
      </Box>
    </Card>
  )
}

const rJSX_WarrantySliderCard = (stateGettersSetters: any, palette: any): JSX.Element => {
  return (
    <Card
      className="tw-bg-gray_300"
      sx={{ height: '100%', width: '95%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}
    >
      <Box
        component="img"
        sx={{
          height: 200,
          width: 280,
          maxHeight: { xs: 233, md: 167 },
          maxWidth: { xs: 350, md: 250 },
          marginBottom: '10px',
        }}
        src={'https://via.placeholder.com/280x200'}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '15px' }}>
        <Typography
          variant="subtitle1"
          className="tw-text-gray_600 tw-font-bold"
          sx={{ fontFamily: 'Poppins' }}
        >
          {rLIB('What About Warranties?')}
        </Typography>
        <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px', marginBottom: '15px' }}>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Distinctio suscipit vitae harum omnis temporibus nobis quae, officia totam natus id quasi
          consectetur deleniti nulla labore, voluptatibus in perferendis delectus similique.
        </Typography>
        <Button
          sx={{
            'backgroundColor': 'aqua',
            'color': 'white',
            'fontSize': '15px',
            'borderRadius': '18px',
            'marginTop': '10px',
            'width': '50%',
            'marginX': 'auto',
            '&.MuiButton-root': {
              backgroundColor: palette.aqua,
            },
          }}
        >
          {rLIB('Read More')}
        </Button>
      </Box>
    </Card>
  )
}

const rJSX_EquipmentSliderCard = (stateGettersSetters: any, palette: any): JSX.Element => {
  return (
    <Card
      className="tw-bg-gray_300"
      sx={{ height: '100%', width: '95%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}
    >
      <Box
        component="img"
        sx={{
          height: 200,
          width: 280,
          maxHeight: { xs: 233, md: 167 },
          maxWidth: { xs: 350, md: 250 },
          marginBottom: '10px',
        }}
        src={'https://via.placeholder.com/280x200'}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '15px' }}>
        <Typography
          variant="subtitle1"
          className="tw-text-gray_600 tw-font-bold tw-justify-center"
          sx={{ fontFamily: 'Poppins' }}
        >
          {rLIB('Should I Worry About What Equipment Will Be Installed?')}
        </Typography>
        <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px', marginBottom: '15px' }}>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Distinctio suscipit vitae harum omnis temporibus nobis quae, officia totam natus id quasi
          consectetur deleniti nulla labore, voluptatibus in perferendis delectus similique.
        </Typography>
        <Button
          sx={{
            'backgroundColor': 'aqua',
            'color': 'white',
            'fontSize': '15px',
            'borderRadius': '18px',
            'width': '50%',
            'marginX': 'auto',
            '&.MuiButton-root': {
              backgroundColor: palette.aqua,
            },
          }}
        >
          {rLIB('Read More')}
        </Button>
      </Box>
    </Card>
  )
}

export const rJSX_AdditionalOptionsSection = (
  stateGettersSetters: any,
  palette: any,
  opportunityKey: string,
  clientKey?: string,
  proposalKey?: string,
  welcome?: boolean,
) => {
  const settings = {
    className: 'center',
    centerMode: true,
    infinite: false,
    centerPadding: '20px',
    slidesToShow: 1,
    speed: 500,
    dots: false,
  }
  return (
    <Box>
      <Accordion
        elevation={0}
        defaultExpanded={true}
      >
        <AccordionSummary
          expandIcon={<KeyboardArrowDownIcon sx={{ color: palette.darkGray, fontSize: '50px' }} />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography
            variant="h6"
            className="tw-text-gray_600 tw-font-bold"
            sx={{ fontFamily: 'Poppins' }}
          >
            {rLIB('Additional Options')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Slider {...settings}>
            <Box sx={{ height: '600px' }}>
              {welcome
                ? rJSX_AdditionalOptionsMountSliderCard(stateGettersSetters, palette, opportunityKey, clientKey, proposalKey, welcome)
                : rJSX_AdditionalOptionsMountSliderCard(stateGettersSetters, palette, opportunityKey)}
            </Box>
            <Box sx={{ height: '600px' }}>
              {welcome
                ? rJSX_AdditionalOptionsBatteriesSliderCard(stateGettersSetters, palette, opportunityKey, clientKey, proposalKey, welcome)
                : rJSX_AdditionalOptionsBatteriesSliderCard(stateGettersSetters, palette, opportunityKey)}
            </Box>
            <Box sx={{ height: '600px' }}>
              {welcome
                ? rJSX_AdditionalOptionsRoofSliderCard(stateGettersSetters, palette, opportunityKey, clientKey, proposalKey, welcome)
                : rJSX_AdditionalOptionsRoofSliderCard(stateGettersSetters, palette, opportunityKey)}
            </Box>
          </Slider>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

const rJSX_AdditionalOptionsMountSliderCard = (
  stateGettersSetters: any,
  palette: any,
  opportunityKey: string,
  clientKey?: string,
  proposalKey?: string,
  welcome?: boolean,
): JSX.Element => {
  let selectedMountType = ''

  if (stateGettersSetters.us_activeProposal === 'original') {
    selectedMountType = stateGettersSetters.us_selectedMountType
  } else if (stateGettersSetters.us_activeProposal === 'secondary') {
    selectedMountType = stateGettersSetters.us_secondarySelectedMountType
  } else if (stateGettersSetters.us_activeProposal === 'tertiary') {
    selectedMountType = stateGettersSetters.us_tertiarySelectedMountType
  }

  return (
    <Card
      className="tw-bg-gray_300"
      sx={{
        height: '100%',
        width: '95%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start', // Changed from 'center' to 'flex-start'
        alignItems: 'center',
      }}
    >
      <Box
        component="img"
        sx={{
          height: 200,
          width: 280,
          maxHeight: { xs: 233, md: 167 },
          maxWidth: { xs: 350, md: 250 },
          marginBottom: '10px',
        }}
        src={'/roof_mount.png'}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '15px' }}>
        <Typography
          variant="subtitle1"
          className="tw-text-gray_600 tw-font-bold tw-justify-center"
          sx={{ fontFamily: 'Poppins' }}
        >
          {rLIB('Change Mount Setup')}
        </Typography>
        <Box className="tw-flex-row tw-justify-between">
          <Typography
            variant="caption"
            className="tw-text-gray_600"
            sx={{ fontFamily: 'Poppins' }}
          >
            {rLIB('Roof Mount')}
          </Typography>
          <Switch
            size={'medium'}
            disableRipple
            checked={selectedMountType === 'ground'}
            color={palette.white}
            sx={{
              '.MuiSwitch-thumb': { backgroundColor: '#42deff' },
              '.MuiSwitch-track': { backgroundColor: 'white' },
              '.MuiSwitch-checked': {
                'backgroundColor': 'white',
                '.MuiSwitch-track': { backgroundColor: 'white' },
              },
            }}
            onChange={(event: any) => {
              const mount = selectedMountType === 'roof' ? 'ground' : 'roof'

              stateGettersSetters.us_setSelectedMountType(mount)

              if (stateGettersSetters.us_isDatabaseMode) {
                cloudFunctionUnauthenticatedRequests({
                  function: 'updateOpportunity_v2',
                  client_key: stateGettersSetters.us_leadData.clientKey,
                  opportunity_key: stateGettersSetters.us_leadData.opportunityKey,
                  opportunity_data: { system_mount_type: mount },
                  trigger_page_refresh: false,
                })
              } else if (welcome && clientKey && proposalKey) {
                DatabaseUpdateDocument(DatabaseRef_Proposal_Document(clientKey, proposalKey), {
                  system_mount_type: mount,
                })
              } else {
                setPageLocalStorage('go_solar', 'system_mount_type', mount)
              }
              //setPageLocalStorage('go_solar_' + stateGettersSetters.us_leadData.opportunityKey, 'selectedMountType', mount)
            }}
          ></Switch>
          <Typography
            variant="caption"
            className="tw-text-gray_600"
            sx={{ fontFamily: 'Poppins' }}
          >
            {rLIB('Ground Mount')}
          </Typography>
        </Box>
        <Typography
          variant="subtitle1"
          className="tw-self-start tw-font-bold tw-text-gray_600"
          sx={{ fontFamily: 'Poppins' }}
        >
          {rLIB('Select Roof Type')}
        </Typography>
        <Typography
          className="tw-self-start tw-text-gray_500 tw-mb-4"
          sx={{ fontFamily: 'Poppins', fontSize: '10px' }}
        >
          {rLIB('Select the type of roofing on your property below')}
        </Typography>
        {rJSX_MountTypesField(stateGettersSetters, palette, opportunityKey, clientKey, welcome)}
      </Box>
      <Typography
        className="tw-self-start tw-text-gray_500 tw-mb-4"
        sx={{ fontFamily: 'Poppins', fontSize: '10px', paddingX: '20px' }}
      >
        {rLIB(
          "We currently support four types of roofs. If you're not sure about your roof type, call us or text us a picture of your roof and we can identify it for you.",
        )}
      </Typography>
    </Card>
  )
}

const rJSX_MountTypesField = (stateGettersSetters: any, palette: any, opportunityKey: string, clientKey?: string, welcome?: boolean): JSX.Element => {
  let selectedMountType = ''
  let selectedRoofType = stateGettersSetters.us_selectedRoofType

  if (stateGettersSetters.us_activeProposal === 'original') {
    selectedMountType = stateGettersSetters.us_selectedMountType
  } else if (stateGettersSetters.us_activeProposal === 'secondary') {
    selectedMountType = stateGettersSetters.us_secondarySelectedMountType
  } else if (stateGettersSetters.us_activeProposal === 'tertiary') {
    selectedMountType = stateGettersSetters.us_tertiarySelectedMountType
  }

  const mountTypes = [
    {
      label: 'Asphalt Shingle',
      value: 'shingle',
    },
    {
      label: 'Clay Tile',
      value: 'clay_tile',
    },
    {
      label: 'Concrete Tile',
      value: 'concrete_tile',
    },
    {
      label: 'Standing-seam Metal',
      value: 'standing_seam_metal',
    },
    {
      label: 'Corrugated Metal',
      value: 'corrugated_metal',
    },
    {
      label: 'Other',
      value: 'other',
    },
  ]
  const isRoofMount = selectedMountType === 'roof'

  return (
    <Box sx={{ marginBottom: '20px', width: '100%' }}>
      {mountTypes.map((mount, index) => {
        return (
          <Box
            key={index}
            sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
          >
            <Typography
              className="tw-text-gray_600"
              sx={{ fontFamily: 'Poppins', fontSize: '12px', paddingTop: '4px' }}
            >
              {mount.label}
            </Typography>
            <Switch
              size={'small'}
              disableRipple
              // defaultChecked={stateGettersSetters.us_selectedRoofType === mount.value}
              checked={selectedRoofType === mount.value && isRoofMount}
              disabled={selectedRoofType === mount.value || !isRoofMount}
              color={palette.white}
              sx={{
                '.MuiSwitch-thumb': { backgroundColor: selectedRoofType === mount.value && isRoofMount ? '#42deff' : 'darkGray' },
                '.MuiSwitch-track': { backgroundColor: 'white' },
                '.MuiSwitch-checked': { backgroundColor: '#42deff' },
              }}
              onChange={(event: any) => {
                if (stateGettersSetters.us_activeProposal === 'original') {
                  stateGettersSetters.us_setSelectedRoofType(mount.value)
                } else if (stateGettersSetters.us_activeProposal === 'secondary') {
                  stateGettersSetters.us_setSecondarySelectedRoofType(mount.value)
                } else if (stateGettersSetters.us_activeProposal === 'tertiary') {
                  stateGettersSetters.us_setTertiarySelectedRoofType(mount.value)
                }

                if (stateGettersSetters.us_isDatabaseMode) {
                  cloudFunctionUnauthenticatedRequests({
                    function: 'updateOpportunity_v2',
                    client_key: stateGettersSetters.us_leadData.clientKey,
                    opportunity_key: stateGettersSetters.us_leadData.opportunityKey,
                    opportunity_data: { home_roof_type: mount.value },
                    trigger_page_refresh: false,
                  })
                } else if (welcome && clientKey) {
                  DatabaseUpdateDocument(DatabaseRef_SalesOpportunity_v2_Document(clientKey, opportunityKey), {
                    home_roof_type: mount.value,
                  })
                } else {
                  setPageLocalStorage('go_solar', 'home_roof_type', mount.value)
                }
                //setPageLocalStorage('go_solar_' + stateGettersSetters.us_leadData.opportunityKey, 'selectedRoofType', mount.value)
              }}
            ></Switch>
          </Box>
        )
      })}
    </Box>
  )
}

const rJSX_AdditionalOptionsBatteriesSliderCard = (
  stateGettersSetters: any,
  palette: any,
  opportunityKey: string,
  clientKey?: string,
  proposalKey?: string,
  welcome?: boolean,
): JSX.Element => {
  let numBatteries = 0

  if (stateGettersSetters.us_activeProposal === 'original') {
    numBatteries = stateGettersSetters.us_numBatteries
  } else if (stateGettersSetters.us_activeProposal === 'secondary') {
    numBatteries = stateGettersSetters.us_secondaryNumBatteries
  } else if (stateGettersSetters.us_activeProposal === 'tertiary') {
    numBatteries = stateGettersSetters.us_tertiaryNumBatteries
  }

  const marks = [
    { value: 0, label: '0' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
  ]
  return (
    <Card
      className="tw-bg-gray_300"
      sx={{
        height: '100%',
        width: '95%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start', // Changed from 'center' to 'flex-start'
        alignItems: 'center',
      }}
    >
      <Box
        component="img"
        sx={{
          height: 200,
          width: 280,
          maxHeight: { xs: 233, md: 167 },
          maxWidth: { xs: 350, md: 250 },
          marginBottom: '10px',
        }}
        src={'/batteries.png'}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '15px' }}>
        <Typography
          variant="subtitle1"
          className="tw-text-gray_600 tw-font-bold tw-justify-center"
          sx={{ fontFamily: 'Poppins' }}
        >
          {rLIB('Add Batteries To Your System')}
        </Typography>
        <Typography
          className="tw-text-gray_500"
          sx={{ fontFamily: 'Poppins', fontSize: '11px' }}
        >
          {rLIB("Select how many batteries you'd like to add")}
        </Typography>
        <MuiSlider
          min={0}
          max={4}
          step={1}
          marks={marks}
          value={numBatteries}
          onChangeCommitted={(event: any, value: any) => {
            if (stateGettersSetters.us_activeProposal === 'original') {
              stateGettersSetters.us_setNumBatteries(value)
            } else if (stateGettersSetters.us_activeProposal === 'secondary') {
              stateGettersSetters.us_setSecondaryNumBatteries(value)
            } else if (stateGettersSetters.us_activeProposal === 'tertiary') {
              stateGettersSetters.us_setTertiaryNumBatteries(value)
            }

            if (stateGettersSetters.us_isDatabaseMode) {
              cloudFunctionUnauthenticatedRequests({
                function: 'updateOpportunity_v2',
                client_key: stateGettersSetters.us_leadData.clientKey,
                opportunity_key: stateGettersSetters.us_leadData.opportunityKey,
                opportunity_data: { system_storage_quantity: value },
                trigger_page_refresh: false,
              })
            } else if (welcome && clientKey && proposalKey) {
              DatabaseUpdateDocument(DatabaseRef_Proposal_Document(clientKey, proposalKey), {
                system_storage_quantity: value,
              })
            } else {
              setPageLocalStorage('go_solar', 'system_storage_quantity', value)
            }
          }}
          sx={{ width: '85%', marginX: 'auto', color: palette.aqua, marginTop: '10px' }}
          slotProps={{
            track: { className: 'tw-bg-white tw-h-4 tw-border-0' },
            thumb: { className: 'tw-size-8' },
            rail: { className: 'tw-bg-white tw-h-4' },
            root: { className: 'tw-px-2' },
          }}
        />
        <Typography
          className="tw-text-gray_500"
          sx={{ fontFamily: 'Poppins', fontSize: '10px' }}
        >
          Batteries help keep your lights on in the event of an outage. The number of batteries you need depends on how much of your home you want to back up.
          For more information, read our guide.
        </Typography>
        <br />
        <Typography
          className="tw-text-gray_500"
          sx={{ fontFamily: 'Poppins', fontSize: '10px' }}
        >
          If you're looking for resiliency (e.g. protecting against a multi-day outage), a solar system coupled with batteries is a great option and often
          outperforms other options such as a gas-powered generator.
        </Typography>
      </Box>
    </Card>
  )
}

const rJSX_AdditionalOptionsRoofSliderCard = (
  stateGettersSetters: any,
  palette: any,
  opportunityKey: string,
  clientKey?: string,
  proposalKey?: string,
  welcome?: boolean,
): JSX.Element => {
  let roofReplacement = false

  if (stateGettersSetters.us_activeProposal === 'original') {
    roofReplacement = stateGettersSetters.us_roofReplacement
  } else if (stateGettersSetters.us_activeProposal === 'secondary') {
    roofReplacement = stateGettersSetters.us_secondaryRoofReplacement
  } else if (stateGettersSetters.us_activeProposal === 'tertiary') {
    roofReplacement = stateGettersSetters.us_tertiaryRoofReplacement
  }

  return (
    <Card
      className="tw-bg-gray_300"
      sx={{
        height: '100%',
        width: '95%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start', // Changed from 'center' to 'flex-start'
        alignItems: 'center',
      }}
    >
      <Box
        component="img"
        sx={{
          height: 200,
          width: 280,
          maxHeight: { xs: 233, md: 167 },
          maxWidth: { xs: 350, md: 250 },
          marginBottom: '10px',
        }}
        src={'https://via.placeholder.com/280x200'}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '15px' }}>
        <Typography
          variant="subtitle1"
          className="tw-text-gray_600 tw-font-bold tw-justify-center"
          sx={{ fontFamily: 'Poppins' }}
        >
          {rLIB('Roof Replacement')}
        </Typography>
        <Typography
          className="tw-text-gray_500"
          sx={{ fontFamily: 'Poppins', fontSize: '11px' }}
        >
          {rLIB('You can replace your roof as part of the solar install')}
        </Typography>
        <Box className="tw-flex-row tw-justify-between">
          <Typography
            variant="caption"
            className="tw-text-gray_600"
          >
            {rLIB('No Replacement')}
          </Typography>

          <Switch
            size={'medium'}
            disableRipple
            color={palette.white}
            checked={roofReplacement}
            sx={{
              '.MuiSwitch-thumb': { backgroundColor: '#42deff' },
              '.MuiSwitch-track': { backgroundColor: 'white' },
            }}
            onChange={(event: any) => {
              const newValue = !roofReplacement
              if (stateGettersSetters.us_activeProposal === 'original') {
                stateGettersSetters.us_setRoofReplacement(newValue)
              } else if (stateGettersSetters.us_activeProposal === 'secondary') {
                stateGettersSetters.us_setSecondaryRoofReplacement(newValue)
              } else if (stateGettersSetters.us_activeProposal === 'tertiary') {
                stateGettersSetters.us_setTertiaryRoofReplacement(newValue)
              }

              if (stateGettersSetters.us_isDatabaseMode) {
                cloudFunctionUnauthenticatedRequests({
                  function: 'updateOpportunity_v2',
                  client_key: stateGettersSetters.us_leadData.clientKey,
                  opportunity_key: stateGettersSetters.us_leadData.opportunityKey,
                  opportunity_data: { home_roof_quality: newValue ? 'Needs Repair' : 'Good' },
                  trigger_page_refresh: false,
                })
              } else if (welcome && clientKey && proposalKey) {
                DatabaseUpdateDocument(DatabaseRef_SalesOpportunity_v2_Document(clientKey, opportunityKey), {
                  home_roof_quality: newValue ? 'Needs Repair' : 'Good',
                })
                DatabaseUpdateDocument(DatabaseRef_Proposal_Document(clientKey, proposalKey), {
                  home_roof_quality: newValue ? 'Needs Repair' : 'Good',
                })
              } else {
                setPageLocalStorage('go_solar', 'home_roof_quality', newValue ? 'Needs Repair' : 'Good')
              }
            }}
          />
          <Typography
            variant="caption"
            className="tw-text-gray_600"
          >
            {rLIB('Replace Roof')}
          </Typography>
        </Box>
        <Typography
          className="tw-text-gray_500"
          sx={{ fontFamily: 'Poppins', fontSize: '10px' }}
        >
          If your roof is due for a replacement within the next 3-5 years, we recommend that you replace your roof now. Replacing your roof with an installed
          system will require the system to be removed and replaced at your expense.
        </Typography>
        <Typography
          className="tw-text-gray_500"
          sx={{ fontFamily: 'Poppins', fontSize: '10px' }}
        >
          If you select a roof replacement, your quote will not adjust at this time.Once you submit your order, we will engage a reputed roofer to quote your
          roof and review the updated numbers with you prior to finalizing your order.
        </Typography>
        <Typography
          className="tw-text-gray_500"
          sx={{ fontFamily: 'Poppins', fontSize: '10px' }}
        >
          We partner with reputed roofers in your area to replace your roof.
        </Typography>
      </Box>
    </Card>
  )
}

//welcome page
export const rJSX_welcomeHeader = (palette: any): JSX.Element => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography
        variant="h4"
        sx={{ fontFamily: 'Poppins', marginBottom: '0px', fontWeight: 'bold', fontSize: '35px' }}
        className="tw-text-white tw-font-bold"
      >
        {rLIB('Welcome!')}
      </Typography>
      <Typography
        variant="body1"
        sx={{ fontFamily: 'Poppins', marginTop: '5px', color: palette.white, fontSize: '18px', textAlign: 'center' }}
      >
        {rLIB("We're super happy to be part of your journey towards clean energy!")}
      </Typography>
      <Typography
        variant="body1"
        sx={{ fontFamily: 'Poppins', marginTop: '15px', color: palette.white, fontSize: '18px', textAlign: 'center' }}
      >
        {rLIB(
          'You should hear from us shortly about next steps. In the meantime, please review the information below, answer a few questions about your home and start collecting photos.',
        )}
      </Typography>
    </Box>
  )
}

export const rJSX_welcomeSystemDetails = (stateGettersSetters: any, palette: any, opportunityKey: string, clientKey: string): JSX.Element => {
  const subsectionStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '12px',
    padding: '15px',
  }

  const subsectionGroupingStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  }

  let systemSizeInWatts: number = stateGettersSetters.us_systemSizeInWatts
  let numPanels: number = stateGettersSetters.us_numPanels
  let annualProductionInKWh: number = stateGettersSetters.us_systemAnnualProductionInKWh

  const handleEditClick = (field: string, value: number) => {
    stateGettersSetters.us_setEditingField(field)
    stateGettersSetters.us_setEditValue(value.toString())
  }

  const handleEditClose = () => {
    stateGettersSetters.us_setEditingField(null)
    stateGettersSetters.us_setEditValue('')
  }

  const handleEditSave = () => {
    const newValue = parseFloat(stateGettersSetters.us_editValue)
    if (!isNaN(newValue)) {
      switch (stateGettersSetters.us_editingField) {
        case 'systemSize':
          submitSystemChange(stateGettersSetters, 'systemSize', newValue, opportunityKey, clientKey, 'welcome', stateGettersSetters.us_activeProposalId)
          break
        case 'numPanels':
          submitSystemChange(stateGettersSetters, 'numPanels', newValue, opportunityKey, clientKey, 'welcome', stateGettersSetters.us_activeProposalId)
          break
        case 'annualProduction':
          submitSystemChange(stateGettersSetters, 'annualProduction', newValue, opportunityKey, clientKey, 'welcome', stateGettersSetters.us_activeProposalId)
          break
      }
    }
    handleEditClose()
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography
        variant="h5"
        sx={{ fontFamily: 'Poppins', marginTop: '0px', marginLeft: '10px', fontWeight: 'bold', fontSize: '23px' }}
        className="tw-text-gray_600 tw-font-bold"
      >
        {rLIB('Your System')}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography
          variant="h5"
          sx={{ fontFamily: 'Poppins', marginTop: '15px', marginLeft: '10px', fontWeight: 'bold', fontSize: '16px' }}
          className="tw-text-gray_600 tw-font-bold"
        >
          {rLIB('System Details')}
        </Typography>
        {stateGettersSetters.us_proposals.length > 1 && (
          <Button
            variant="outlined"
            aria-label="edit"
            color="inherit"
            size="small"
            sx={{
              'fontFamily': 'Poppins',
              'fontSize': '12px',
              'color': 'gray',
              'marginRight': '15px',
              'borderColor': 'gray',
              '&:hover': {
                borderColor: 'gray',
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            }}
            onClick={() => {
              if (stateGettersSetters.us_compareMode) {
                stateGettersSetters.us_setCompareMode(false)
              } else {
                stateGettersSetters.us_setCompareMode(true)
              }
            }}
          >
            {stateGettersSetters.us_compareMode ? rLIB('Done') : rLIB('Compare')}
          </Button>
        )}
      </Box>
      <Divider sx={{ width: '95%', marginLeft: '2.5%', marginTop: '5px' }} />
      <Box sx={{ ...subsectionStyle }}>
        {stateGettersSetters.us_productType === 'solar' && (
          <Typography
            className="tw-text-gray_500"
            sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
          >
            {rLIB('We use top tier modules and Tesla Solar Inverters')}
          </Typography>
        )}
        {stateGettersSetters.us_productType === 'lunar_battery' && (
          <Typography
            className="tw-text-gray_500"
            sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
          >
            {rLIB('We use top tier modules and Lunar Inverters')}
          </Typography>
        )}
      </Box>
      <Divider sx={{ width: '95%', marginLeft: '2.5%', marginTop: '5px' }} />
      {stateGettersSetters.us_compareMode && rJSX_compareSection(stateGettersSetters, palette, opportunityKey, clientKey)}
      {!stateGettersSetters.us_compareMode && (
        <>
          <Box sx={{ ...subsectionStyle }}>
            <Typography
              className="tw-text-gray_500"
              sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
            >
              {rLIB('System Size')}
            </Typography>
            <Box sx={{ ...subsectionGroupingStyle }}>
              <Typography
                className="tw-text-gray_600"
                sx={{ fontFamily: 'Poppins', marginRight: '5px', fontSize: '12px' }}
              >
                {systemSizeInWatts.toFixed(2)} kW
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ width: '95%', marginLeft: '2.5%', marginTop: '5px' }} />
          <Box sx={{ ...subsectionStyle }}>
            <Typography
              className="tw-text-gray_500"
              sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
            >
              {rLIB('Estimated Number of Panels')}
            </Typography>
            <Box sx={{ ...subsectionGroupingStyle }}>
              <Typography
                className="tw-text-gray_600"
                sx={{ fontFamily: 'Poppins', marginRight: '5px', fontSize: '12px' }}
              >
                {Math.floor(numPanels)}
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ width: '95%', marginLeft: '2.5%', marginTop: '5px' }} />
          <Box sx={{ ...subsectionStyle }}>
            <Typography
              className="tw-text-gray_500"
              sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
            >
              {rLIB('Estimated Annual Production')}
            </Typography>
            <Box sx={{ ...subsectionGroupingStyle }}>
              <Typography
                className="tw-text-gray_600"
                sx={{ fontFamily: 'Poppins', marginRight: '5px', fontSize: '12px' }}
              >
                {annualProductionInKWh.toFixed(3)} kWh
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ width: '95%', marginLeft: '2.5%', marginTop: '5px' }} />
        </>
      )}
      <Dialog
        open={stateGettersSetters.us_editingField !== null}
        onClose={handleEditClose}
      >
        <DialogTitle>
          Edit{' '}
          {stateGettersSetters.us_editingField === 'systemSize'
            ? 'System Size'
            : stateGettersSetters.us_editingField === 'numPanels'
              ? 'Number of Panels'
              : 'Annual Production'}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="New Value"
            type="number"
            fullWidth
            value={stateGettersSetters.us_editValue}
            onChange={(e) => stateGettersSetters.us_setEditValue(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}>Cancel</Button>
          <Button onClick={handleEditSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export const rJSX_welcomeOptionsDetails = (stateGettersSetters: any, palette: any, clientKey: string, opportunityKey: string): JSX.Element => {
  const subsectionStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '12px',
    padding: '15px',
  }

  const subsectionGroupingStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  }

  let selectedMountType: string = stateGettersSetters.us_selectedMountType
  let roofReplacement: boolean = stateGettersSetters.us_roofReplacement
  let numBatteries: number = stateGettersSetters.us_numBatteries

  return (
    <>
      {!stateGettersSetters.us_compareMode && (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '7px' }}>
            <Box sx={{ ...subsectionStyle }}>
              <Typography
                className="tw-text-gray_500"
                sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
              >
                {rLIB('Mount Choice')}
              </Typography>
              <Box sx={{ ...subsectionGroupingStyle }}>
                <Typography
                  className="tw-text-gray_600"
                  sx={{ fontFamily: 'Poppins', marginRight: '10px', fontSize: '12px' }}
                >
                  {selectedMountType}
                </Typography>
              </Box>
            </Box>
            <Divider sx={{ width: '95%', marginLeft: '2.5%', marginTop: '5px' }} />
            <Box sx={{ ...subsectionStyle }}>
              <Typography
                className="tw-text-gray_500"
                sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
              >
                {rLIB('Reroof')}
              </Typography>
              <Box sx={{ ...subsectionGroupingStyle }}>
                <Typography
                  className="tw-text-gray_600"
                  sx={{ fontFamily: 'Poppins', marginRight: '10px', fontSize: '12px' }}
                >
                  {roofReplacement ? 'Yes' : 'No'}
                </Typography>
              </Box>
            </Box>
            <Divider sx={{ width: '95%', marginLeft: '2.5%', marginTop: '5px' }} />
            <Box sx={{ ...subsectionStyle }}>
              <Typography
                className="tw-text-gray_500"
                sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
              >
                {rLIB('Batteries')}
              </Typography>
              <Box sx={{ ...subsectionGroupingStyle }}>
                <Typography
                  className="tw-text-gray_600"
                  sx={{ fontFamily: 'Poppins', marginRight: '10px', fontSize: '12px' }}
                >
                  {numBatteries}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px', width: '100%' }}>
              <Typography
                variant="h5"
                sx={{ fontFamily: 'Poppins', marginTop: '20px', marginLeft: '10px', fontSize: '12px', textAlign: 'center' }}
                className="tw-text-gray_500"
              >
                {rLIB('Click below to request edits to your system')}
              </Typography>
              <Button
                variant="contained"
                className="tw-w-1/2 tw-mt-4"
                onClick={() => {
                  // stateGettersSetters.us_setCurrentView('edit-panel-layout')
                  // window.scrollTo(0, 0)
                  stateGettersSetters.us_setEditRequestDialogOpen(true)
                }}
                sx={{
                  'fontFamily': 'Poppins',
                  'textTransform': 'none',
                  'backgroundColor': '#42deff',
                  'color': palette.white,
                  '&:hover': {
                    backgroundColor: palette.aqua,
                  },
                  'borderRadius': '30px',
                  'fontSize': '15px',
                  'padding': '10px 10px',
                  'width': 'auto', // Adjust width as needed
                  'marginBottom': '20px',
                }}
              >
                {rLIB('Request Edits')}
              </Button>
              <Dialog
                open={stateGettersSetters.us_editRequestDialogOpen}
                onClose={() => stateGettersSetters.us_setEditRequestDialogOpen(false)}
              >
                <DialogTitle sx={{ fontFamily: 'Poppins', textAlign: 'center' }}>{rLIB('Request System Edits')}</DialogTitle>
                <DialogContent>
                  <Typography sx={{ fontFamily: 'Poppins', mb: 2 }}>{rLIB('Please describe what changes you would like made to your system:')}</Typography>
                  <TextField
                    autoFocus
                    multiline
                    rows={4}
                    fullWidth
                    value={stateGettersSetters.us_editRequestText}
                    onChange={(e) => stateGettersSetters.us_setEditRequestText(e.target.value)}
                    placeholder={'Example: I would like to add more panels to increase production...'}
                    sx={{ mb: 2 }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => stateGettersSetters.us_setEditRequestDialogOpen(false)}
                    sx={{
                      'fontFamily': 'Poppins',
                      'textTransform': 'none',
                      'color': palette.gray_600,
                      '&:hover': {
                        backgroundColor: 'transparent',
                        opacity: 0.8,
                      },
                    }}
                  >
                    {rLIB('Cancel')}
                  </Button>
                  <Button
                    onClick={() => {
                      // Send email with edit request
                      cloudFunctionUnauthenticatedRequests({
                        function: 'requestSupportAndEditsSubmission',
                        client_key: clientKey,
                        opportunity_key: opportunityKey,
                        first_name: stateGettersSetters.us_firstName,
                        last_name: stateGettersSetters.us_lastName,
                        email: stateGettersSetters.us_email,
                        phone: stateGettersSetters.us_phone,
                        notes: stateGettersSetters.us_editRequestText,
                        support_type: 'EDITREQUEST',
                      })

                      // Close dialog and show confirmation
                      stateGettersSetters.us_setEditRequestDialogOpen(false)
                      stateGettersSetters.us_setEditRequestText('')
                      stateGettersSetters.us_setShowEditRequestConfirmation(true)
                    }}
                    sx={{
                      'fontFamily': 'Poppins',
                      'textTransform': 'none',
                      'backgroundColor': palette.aqua,
                      'color': palette.white,
                      '&:hover': {
                        backgroundColor: palette.aqua,
                        opacity: 0.8,
                      },
                    }}
                  >
                    {rLIB('Submit Request')}
                  </Button>
                </DialogActions>
              </Dialog>
              <Snackbar
                open={stateGettersSetters.us_showEditRequestConfirmation}
                autoHideDuration={6000}
                onClose={() => stateGettersSetters.us_setShowEditRequestConfirmation(false)}
                message={rLIB("Your edit request has been sent. We'll contact you shortly.")}
              />
            </Box>
          </Box>
        </>
      )}
    </>
  )
}

//welcome compare section
export const EditDialog = ({
  state,
  onClose,
  onSave,
  stateGettersSetters,
}: {
  state: TsInterface_EditDialogState
  onClose: () => void
  onSave: (value: string | number) => void
  stateGettersSetters: any
}) => (
  <Dialog
    open={state.open}
    onClose={onClose}
    maxWidth="sm"
    fullWidth
  >
    {(() => {
      switch (state.field) {
        case 'mountChoice':
          return (
            <DialogContent>
              <Select
                value={stateGettersSetters.us_selectedMountOption ? stateGettersSetters.us_selectedMountOption : state.value}
                fullWidth
                onChange={(e) => {
                  stateGettersSetters.us_setSelectedMountOption(e.target.value)
                }}
              >
                <MenuItem value="roof">Roof</MenuItem>
                <MenuItem value="ground">Ground</MenuItem>
              </Select>
              <DialogActions>
                <Button onClick={onClose}>Close</Button>
                <Button
                  onClick={() => {
                    stateGettersSetters.us_setSelectedMountOption('')
                    onSave(stateGettersSetters.us_selectedMountOption)
                  }}
                >
                  Save
                </Button>
              </DialogActions>
            </DialogContent>
          )

        case 'reroof':
          return (
            <DialogContent>
              <Typography
                variant="h6"
                sx={{ mb: 2 }}
              >
                Roof Replacement Options
              </Typography>
              <FormControl component="fieldset">
                <RadioGroup
                  value={stateGettersSetters.us_selectedReroofOption ? stateGettersSetters.us_selectedReroofOption : state.value}
                  onChange={(e) => {
                    stateGettersSetters.us_setSelectedReroofOption(e.target.value)
                  }}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Yes, replace roof"
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio />}
                    label="No replacement needed"
                  />
                </RadioGroup>
              </FormControl>
              <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                  onClick={() => {
                    stateGettersSetters.us_setSelectedReroofOption('')
                    onSave(stateGettersSetters.us_selectedReroofOption)
                  }}
                >
                  Save
                </Button>
              </DialogActions>
            </DialogContent>
          )

        case 'batteries':
          return (
            <DialogContent>
              <Typography
                variant="h6"
                sx={{ mb: 2 }}
              >
                Number of Batteries
              </Typography>
              <MuiSlider
                value={stateGettersSetters.us_selectedNumBatteriesOption != null ? stateGettersSetters.us_selectedNumBatteriesOption : Number(state.value)}
                min={0}
                max={4}
                step={1}
                marks={[
                  { value: 0, label: '0' },
                  { value: 1, label: '1' },
                  { value: 2, label: '2' },
                  { value: 3, label: '3' },
                  { value: 4, label: '4' },
                ]}
                onChange={(_, value) => {
                  stateGettersSetters.us_setSelectedNumBatteriesOption(value)
                }}
              />
              <Typography sx={{ mt: 2 }}>
                Selected: {stateGettersSetters.us_selectedNumBatteriesOption ? stateGettersSetters.us_selectedNumBatteriesOption : state.value}{' '}
                {Number(stateGettersSetters.us_selectedNumBatteriesOption ? stateGettersSetters.us_selectedNumBatteriesOption : state.value) === 1
                  ? 'battery'
                  : 'batteries'}
              </Typography>
              <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                  onClick={() => {
                    stateGettersSetters.us_setSelectedNumBatteriesOption(0)
                    onSave(stateGettersSetters.us_selectedNumBatteriesOption)
                  }}
                >
                  Save
                </Button>
              </DialogActions>
            </DialogContent>
          )

        case 'type':
          return (
            <DialogContent>
              <Select
                value={stateGettersSetters.us_selectedFinancingOption ? stateGettersSetters.us_selectedFinancingOption : state.value}
                fullWidth
                onChange={(e) => {
                  stateGettersSetters.us_setSelectedFinancingOption(e.target.value)
                }}
              >
                <MenuItem value="Cash">Cash</MenuItem>
                <MenuItem value="Lease">Lease</MenuItem>
              </Select>
              <DialogActions>
                <Button onClick={onClose}>Close</Button>
                <Button
                  onClick={() => {
                    stateGettersSetters.us_setSelectedFinancingOption('')
                    onSave(stateGettersSetters.us_selectedFinancingOption.toLowerCase())
                  }}
                >
                  Save
                </Button>
              </DialogActions>
            </DialogContent>
          )

        default:
          // Regular edit dialog for other fields
          return (
            <>
              <DialogTitle>Edit {state.field.charAt(0).toUpperCase() + state.field.slice(1).replace(/([A-Z])/g, ' $1')}</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  label="New Value"
                  type="text"
                  fullWidth
                  defaultValue={state.value}
                  onChange={(e) => (state.value = e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={() => onSave(state.value)}>Save</Button>
              </DialogActions>
            </>
          )
      }
    })()}
  </Dialog>
)

export const SystemDetailsComparisonColumn = ({
  proposal,
  onEdit,
}: {
  proposal: TsInterface_Proposal
  onEdit: (field: string, value: string | number, proposalId: number) => void
}) => {
  const EditableField = ({ label, value, field }: { label: string; value: string | number; field: string }) => (
    <Box>
      <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px', color: '#939598', mb: 1 }}>{label}</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography sx={{ fontFamily: 'Poppins', fontSize: '14px', fontWeight: 'bold', color: '#939598' }}>{value}</Typography>
        {/* <IconButton
          size="small"
          onClick={() => {
            onEdit(field, value, proposal.id)
          }}
          sx={{ padding: '4px' }}
        >
          <Icon
            icon="pen"
            sx={{ fontSize: '12px', color: '#939598' }}
          />
        </IconButton> */}
      </Box>
    </Box>
  )

  return (
    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 3 }}>
      <EditableField
        label="Size"
        value={`${proposal.systemSize} kW`}
        field="systemSize"
      />
      <EditableField
        label="Panels"
        value={proposal.numPanels}
        field="numPanels"
      />
      <EditableField
        label="Production"
        value={`${proposal.annualProduction} kWh`}
        field="annualProduction"
      />
      <EditableField
        label="Mount"
        value={proposal.mountChoice}
        field="mountChoice"
      />
      <EditableField
        label="Reroof"
        value={proposal.reroof}
        field="reroof"
      />
      <EditableField
        label="Batteries"
        value={proposal.batteries}
        field="batteries"
      />
    </Box>
  )
}

export const FinancingComparisonColumn = ({
  proposal,
  onEdit,
  stateGettersSetters,
  palette,
  clientKey,
}: {
  proposal: TsInterface_Proposal
  onEdit: (field: string, value: string | number, proposalId: number) => void
  stateGettersSetters: any
  palette: any
  clientKey: string
}) => {
  const EditableField = ({ label, value, field }: { label: string; value: string | number; field: string }) => (
    <Box>
      <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px', color: '#939598', mb: 1 }}>{label}</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography sx={{ fontFamily: 'Poppins', fontSize: '14px', fontWeight: 'bold', color: '#939598' }}>
          {typeof value === 'number' ? formatNumberToDollar(value) : value}
        </Typography>
        {/* <IconButton
          size="small"
          onClick={() => onEdit(field, value, proposal.id)}
          sx={{ padding: '4px' }}
        >
          <Icon
            icon="pen"
            sx={{ fontSize: '12px', color: '#939598' }}
          />
        </IconButton> */}
      </Box>
    </Box>
  )

  const ReadOnlyField = ({ label, value }: { label: string; value: string | number }) => (
    <Box>
      <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px', color: '#939598', mb: 1 }}>{label}</Typography>
      <Typography sx={{ fontFamily: 'Poppins', fontSize: '14px', fontWeight: 'bold', color: '#939598' }}>
        {typeof value === 'number' ? formatNumberToDollar(value) : value}
      </Typography>
    </Box>
  )

  return (
    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 3 }}>
      {stateGettersSetters.us_state !== 'CA' && (
        <EditableField
          label="Type"
          value={proposal.type}
          field="type"
        />
      )}
      {stateGettersSetters.us_state === 'CA' && (
        <ReadOnlyField
          label="Type"
          value={proposal.type}
        />
      )}
      <ReadOnlyField
        label="System Cost"
        value={proposal.systemCost}
      />
      <ReadOnlyField
        label="Lunar Battery Cost"
        value={proposal.lunarBatteryCost}
      />
      <ReadOnlyField
        label="Adders"
        value={proposal.adders.reduce((sum: number, adder: any) => sum + adder.amount, 0)}
      />
      <ReadOnlyField
        label="Discounts"
        value={proposal.discounts.reduce((sum: number, discount: any) => sum + discount.amount, 0)}
      />
      <ReadOnlyField
        label="Net Cost"
        // value={proposal.netCost}
        value={
          proposal.systemCost +
          proposal.lunarBatteryCost +
          proposal.adders.reduce((sum: number, adder: any) => sum + adder.amount, 0) -
          proposal.discounts.reduce((sum: number, discount: any) => sum + discount.amount, 0)
        }
      />
      <ReadOnlyField
        label="Total Incentives"
        value={proposal.totalIncentives}
      />
      {proposal.fedIncentives > 0 && (
        <ReadOnlyField
          label="Fed. Incentives"
          value={proposal.fedIncentives}
        />
      )}
      {proposal.stateIncentives > 0 && (
        <ReadOnlyField
          label="State Incentives"
          value={proposal.stateIncentives}
        />
      )}
      {stateGettersSetters.us_activeProposalId !== proposal.id && (
        <Button
          variant="contained"
          onClick={() => {
            // Set current active proposal to inactive
            DatabaseUpdateDocument(DatabaseRef_Proposal_Document(clientKey, stateGettersSetters.us_activeProposalId), {
              active: false,
            })

            // Set selected proposal to active
            DatabaseUpdateDocument(DatabaseRef_Proposal_Document(clientKey, proposal.id.toString()), {
              active: true,
            })

            // Update active proposal in state
            stateGettersSetters.us_setActiveProposalId(proposal.id)
          }}
          sx={{
            'textTransform': 'none',
            'backgroundColor': '#42deff',
            'color': palette.white,
            '&:hover': {
              backgroundColor: palette.aqua,
            },
            'borderRadius': '30px',
            'fontSize': '15px',
            'padding': '10px 10px',
            'fontFamily': 'Poppins',
            'width': '90%',
            'marginTop': '10px',
          }}
        >
          {rLIB('Set Active')}
        </Button>
      )}
      {/* {stateGettersSetters.us_activeProposalId === proposal.id && (
        <Typography
          sx={{
            fontFamily: 'Poppins',
            color: '#6d6e71',
            textAlign: 'center',
            fontWeight: 'bold',
            marginTop: '10px',
          }}
        >
          {rLIB('Active Proposal')}
        </Typography>
      )} */}
    </Box>
  )
}

export const rJSX_compareSection = (stateGettersSetters: any, palette: any, opportunityKey: string, clientKey: string): JSX.Element => {
  const proposals = stateGettersSetters.us_proposals

  const handleEdit = (field: string, value: string | number, proposalId: number) => {
    if (field === 'mountChoice') {
      // Open mount options dialog
      stateGettersSetters.us_setEditDialog({
        open: true,
        field,
        value,
        proposalId,
        isOptionsCard: true,
      })
    } else {
      // Regular edit dialog
      stateGettersSetters.us_setEditDialog({
        open: true,
        field,
        value,
        proposalId,
        isOptionsCard: false,
      })
    }
  }

  const handleSave = (newValue: string | number) => {
    // save size changes - which updates panels, production, and cost. update submitOffsetChange function to work with this new state.
    if (
      stateGettersSetters.us_editDialog.field === 'systemSize' ||
      stateGettersSetters.us_editDialog.field === 'numPanels' ||
      stateGettersSetters.us_editDialog.field === 'annualProduction'
    ) {
      submitSystemChange(
        stateGettersSetters,
        stateGettersSetters.us_editDialog.field,
        Number(newValue),
        opportunityKey,
        clientKey,
        'welcome',
        stateGettersSetters.us_activeProposalId,
      )
    }
    //save mount changes
    stateGettersSetters.us_setSelectedMountType(newValue)
    DatabaseUpdateDocument(DatabaseRef_Proposal_Document(clientKey, stateGettersSetters.us_activeProposalId), {
      system_mount_type: newValue,
    })

    //save reroof changes
    stateGettersSetters.us_setSelectedReroofOption(newValue)
    DatabaseUpdateDocument(DatabaseRef_Proposal_Document(clientKey, stateGettersSetters.us_activeProposalId), {
      home_roof_quality: newValue === 'Yes' ? 'Needs Repair' : 'Good',
    })

    //save battery changes
    stateGettersSetters.us_setSelectedNumBatteriesOption(newValue)
    DatabaseUpdateDocument(DatabaseRef_Proposal_Document(clientKey, stateGettersSetters.us_activeProposalId), {
      system_storage_quantity: Number(newValue),
    })

    //save financing changes
    stateGettersSetters.us_setSelectedFinancingOption(newValue)
    DatabaseUpdateDocument(DatabaseRef_Proposal_Document(clientKey, stateGettersSetters.us_activeProposalId), {
      associated_financing_type: newValue,
    })

    // Close the dialog
    stateGettersSetters.us_setEditDialog((prev: any) => ({ ...prev, open: false }))
  }

  return (
    <Box>
      {/* Sticky Header with Dropdowns */}
      <Box
        sx={{
          position: 'sticky',
          top: 1,
          backgroundColor: palette.white,
          zIndex: 1000,
          display: 'flex',
          gap: 2,
          mb: 2,
          pb: 1,
          pt: 1,
          borderBottom: `1px solid ${palette.gray_300}`,
          padding: '10px',
        }}
      >
        <FormControl sx={{ flex: 1 }}>
          <Select
            value={stateGettersSetters.us_selectedLeft}
            onChange={(e) => stateGettersSetters.us_setSelectedLeft(e.target.value)}
            sx={{
              'fontFamily': 'Poppins',
              'fontSize': '14px',
              'color': palette.gray_600,
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: palette.aqua,
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: palette.aqua,
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: palette.aqua,
              },
            }}
          >
            {proposals.map((proposal: any, index: number) => (
              <MenuItem
                key={proposal.name}
                value={index}
                disabled={index === stateGettersSetters.us_selectedRight}
                sx={{ fontFamily: 'Poppins' }}
              >
                {proposal.id === stateGettersSetters.us_activeProposalId && ' ★'} {proposal.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ flex: 1 }}>
          <Select
            value={stateGettersSetters.us_selectedRight}
            onChange={(e) => stateGettersSetters.us_setSelectedRight(e.target.value)}
            sx={{
              'fontFamily': 'Poppins',
              'fontSize': '14px',
              'color': palette.gray_600,
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: palette.aqua,
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: palette.aqua,
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: palette.aqua,
              },
            }}
          >
            {proposals.map((proposal: any, index: number) => (
              <MenuItem
                key={proposal.name}
                value={index}
                disabled={index === stateGettersSetters.us_selectedLeft}
                sx={{ fontFamily: 'Poppins' }}
              >
                {proposal.id === stateGettersSetters.us_activeProposalId && ' ★'} {proposal.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Split View Content */}
      <Box
        sx={{
          'display': 'flex',
          'gap': 2,
          '& > *:first-of-type': {
            borderRight: `1px solid ${palette.gray_300}`,
          },
          'padding': '10px',
        }}
      >
        <SystemDetailsComparisonColumn
          proposal={proposals[stateGettersSetters.us_selectedLeft]}
          onEdit={handleEdit}
        />
        <SystemDetailsComparisonColumn
          proposal={proposals[stateGettersSetters.us_selectedRight]}
          onEdit={handleEdit}
        />
      </Box>
      <Divider sx={{ width: '95%', marginLeft: '2.5%', marginTop: '20px' }} />
      <Typography
        variant="h6"
        className="tw-text-gray_600 tw-font-bold"
        sx={{ fontFamily: 'Poppins', marginLeft: '2.5%', marginTop: '10px' }}
      >
        {rLIB('Financing: ')}
      </Typography>
      <Box
        sx={{
          'display': 'flex',
          'gap': 2,
          '& > *:first-of-type': {
            borderRight: `1px solid ${palette.gray_300}`,
          },
          'padding': '10px',
        }}
      >
        <FinancingComparisonColumn
          proposal={proposals[stateGettersSetters.us_selectedLeft]}
          onEdit={handleEdit}
          stateGettersSetters={stateGettersSetters}
          palette={palette}
          clientKey={clientKey}
        />
        <FinancingComparisonColumn
          proposal={proposals[stateGettersSetters.us_selectedRight]}
          onEdit={handleEdit}
          stateGettersSetters={stateGettersSetters}
          palette={palette}
          clientKey={clientKey}
        />
      </Box>
      <EditDialog
        state={stateGettersSetters.us_editDialog}
        onClose={() => stateGettersSetters.us_setEditDialog((prev: any) => ({ ...prev, open: false }))}
        onSave={handleSave}
        stateGettersSetters={stateGettersSetters}
      />
    </Box>
  )
}

// export const rJSX_welcomeHomeDetails = (
//   stateGettersSetters: any,
//   palette: any,
//   photoInputRef: React.RefObject<HTMLInputElement>,
//   clientKey: string,
//   opportunityKey: string,
// ): JSX.Element => {
//   const handlePhotoInfoDialogOpen = () => {
//     stateGettersSetters.us_setPhotoInfoDialogOpen(true)
//   }

//   const handlePhotoInfoDialogClose = () => {
//     stateGettersSetters.us_setPhotoInfoDialogOpen(false)
//   }

//   return (
//     <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '40px' }}>
//       {/* <Divider sx={{ width: '95%', marginLeft: '2.5%', marginTop: '10px' }} /> */}
//       <Accordion
//         elevation={0}
//         defaultExpanded
//       >
//         <AccordionSummary
//           // expandIcon={
//           //   <KeyboardArrowDownIcon
//           //     sx={{
//           //       color: palette.darkGray,
//           //       fontSize: '40px',
//           //       fontWeight: 'bold',
//           //       transform: stateGettersSetters.us_homeDetailsExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
//           //       transition: 'transform 0.3s',
//           //     }}
//           //   />
//           // }
//           aria-controls="home-details-content"
//           id="home-details-header"
//         >
//           <Typography
//             variant="h6"
//             className="tw-text-gray_600 tw-font-bold"
//             sx={{ fontFamily: 'Poppins' }}
//           >
//             {rLIB('Home Details')}
//           </Typography>
//         </AccordionSummary>
//         <AccordionDetails>
//           <Typography
//             className="tw-text-gray_500"
//             sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
//           >
//             {rLIB("Let's start collecting some information about your home that will help us finalize your system")}
//           </Typography>
//           <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
//             <Typography
//               className="tw-text-gray_600"
//               sx={{ fontFamily: 'Poppins', marginTop: '15px', fontSize: '16px', fontWeight: 'bold' }}
//             >
//               {rLIB('Questionnaire')}
//             </Typography>
//           </Box>
//           <Divider sx={{ width: '100%', marginTop: '5px' }} />
//         </AccordionDetails>
//         <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
//           <Typography
//             className="tw-text-gray_500"
//             sx={{ fontFamily: 'Poppins', marginLeft: '20px', fontSize: '12px' }}
//           >
//             {rLIB('Please fill out this questionnaire about your home and your preferences for the install')}
//           </Typography>
//           <Button
//             variant="contained"
//             className="tw-w-1/2 tw-mt-4"
//             onClick={() => {
//               stateGettersSetters.us_setCurrentView('survey')
//               window.scrollTo({ top: 0, behavior: 'smooth' })
//             }}
//             sx={{
//               'fontFamily': 'Poppins',
//               'textTransform': 'none',
//               'backgroundColor': '#42deff',
//               'color': palette.white,
//               '&:hover': {
//                 backgroundColor: palette.aqua,
//               },
//               'borderRadius': '30px',
//               'fontSize': '15px',
//               'padding': '10px 10px',
//               'width': 'auto', // Adjust width as needed
//             }}
//           >
//             {rLIB('Questionnaire')}
//           </Button>
//         </Box>
//         <Box sx={{ marginTop: '20px', display: 'flex', flexDirection: 'column' }}>
//           <Typography
//             className="tw-text-gray_600 "
//             sx={{ fontFamily: 'Poppins', marginLeft: '20px', fontSize: '16px', fontWeight: 'bold' }}
//           >
//             {rLIB('Photos')}
//           </Typography>
//           <Divider sx={{ fontFamily: 'Poppins', width: '90%', marginLeft: '5%', marginTop: '5px' }} />
//           <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '20px', marginTop: '20px' }}>
//             <Typography
//               className="tw-text-gray_500"
//               sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
//             >
//               {rLIB('Please upload some pictures of your home')}
//             </Typography>
//             <IconButton
//               sx={{ marginLeft: '8px' }}
//               onClick={handlePhotoInfoDialogOpen}
//             >
//               <InfoIcon sx={{ fontSize: '20px', color: '#939598' }} />
//             </IconButton>
//             <Dialog
//               open={stateGettersSetters.us_photoInfoDialogOpen}
//               onClose={handlePhotoInfoDialogClose}
//             >
//               <DialogTitle>{rLIB('Photo Upload Instructions')}</DialogTitle>
//               <DialogContent
//                 sx={{
//                   'maxHeight': '400px',
//                   'overflowY': 'auto',
//                   '&::-webkit-scrollbar': {
//                     width: '8px', // Width of the scrollbar
//                   },
//                   '&::-webkit-scrollbar-track': {
//                     backgroundColor: '#fff', // Background of the scrollbar track
//                   },
//                   '&::-webkit-scrollbar-thumb': {
//                     backgroundColor: '#ccc', // Color of the scrollbar thumb
//                     borderRadius: '4px', // Rounded corners for the thumb
//                   },
//                 }}
//               >
//                 <Typography>Please take pictures of the following:</Typography>
//                 <Typography>1. Front of the Home</Typography>
//                 <Typography>2. Back of the Home</Typography>
//                 <Typography>3. Left Side of the Home</Typography>
//                 <Typography>4. Right Side of the Home</Typography>
//                 <Typography>5. Front of the Home with Roof</Typography>
//                 <Typography>6. Back of the Home with Roof</Typography>
//                 <Typography>7. Left Side of the Home with Roof</Typography>
//                 <Typography>8. Right Side of the Home with Roof</Typography>
//                 <Typography>9. Roof Closeup</Typography>
//                 <Typography>10. Attic Inside</Typography>
//                 <Typography>11. Attic Rafters</Typography>
//                 <Typography>12. Attic Plywood</Typography>
//                 <Typography>13. Main Service Panel</Typography>
//                 <Typography>14. Main Service Panel Closeup</Typography>
//                 <Typography>15. Main Service Panel Doors</Typography>
//                 <Typography>16. Sub Panel Wideshot</Typography>
//                 <Typography>17. Sub Panel Closeup</Typography>
//                 <Typography>18. Meter Location Wide</Typography>
//                 <Typography>19. Meter Number Closeup</Typography>
//                 <Typography>20. Existing Solar System (if applicable)</Typography>
//                 <Typography>21. Battery Storage (if applicable)</Typography>
//                 <Typography>22. Generator (if applicable)</Typography>
//                 <Typography>23. Pool (if applicable)</Typography>
//                 <Typography>24. Hot Tub (if applicable)</Typography>
//                 <Typography>25. Electric Heating (if applicable)</Typography>
//                 <Typography>26. Electric Water Heater (if applicable)</Typography>
//                 <Typography>27. Electric Dryer (if applicable)</Typography>
//                 <Typography>28. EV Charger (if applicable)</Typography>
//                 <Divider sx={{ width: '100%', marginTop: '5px', marginBottom: '5px' }} />
//                 <Typography>Click the "Upload Photos" to upload multiple photos at once</Typography>
//                 <Typography>Else, you can upload one photo at a time</Typography>
//               </DialogContent>
//               <DialogActions>
//                 <Button onClick={handlePhotoInfoDialogClose}>{rLIB('Close')}</Button>
//               </DialogActions>
//             </Dialog>
//           </Box>
//         </Box>
//         <Box>
//           <Box
//             className="tw-w-full"
//             sx={{ marginTop: '20px' }}
//           >
//             <PhotoGalleryUploader
//               stateGettersSetters={stateGettersSetters}
//               palette={palette}
//               photoInputRef={photoInputRef}
//               clientKey={clientKey}
//               opportunityKey={opportunityKey}
//             />
//           </Box>
//         </Box>
//       </Accordion>
//     </Box>
//   )
// }

export const rJSX_welcomeSolarFinanceDetails = (stateGettersSetters: any, palette: any): JSX.Element => {
  const subsectionStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '12px',
    padding: '15px',
  }

  let selectedFinancingType: string = stateGettersSetters.us_selectedFinancingType
  let baseCashCost: number = stateGettersSetters.us_baseCashCost
  let currentCashCost: number = stateGettersSetters.us_currentCashCost
  let federalIncentive: number = stateGettersSetters.us_federalIncentive
  let srecIncentive: number = stateGettersSetters.us_srecIncentive

  return (
    <>
      {/* {!stateGettersSetters.us_compareMode && (
        <> */}
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {/* <Divider sx={{ width: '95%', marginLeft: '2.5%', marginTop: '10px' }} /> */}
        <Accordion
          elevation={0}
          defaultExpanded
        >
          <AccordionSummary
            // expandIcon={
            //   <KeyboardArrowDownIcon
            //     sx={{
            //       color: palette.darkGray,
            //       fontSize: '40px',
            //       fontWeight: 'bold',
            //       transform: stateGettersSetters.us_homeDetailsExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
            //       transition: 'transform 0.3s',
            //     }}
            //   />
            // }
            aria-controls="home-details-content"
            id="home-details-header"
          >
            <Typography
              variant="h6"
              className="tw-text-gray_600 tw-font-bold"
              sx={{ fontFamily: 'Poppins' }}
            >
              {rLIB('Financing: ')} {selectedFinancingType}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ ...subsectionStyle }}>
              <Typography
                className="tw-text-gray_600"
                sx={{ fontFamily: 'Poppins', fontSize: '12px', fontWeight: 'bold' }}
              >
                Est. System Cost
              </Typography>
              <Typography
                className="tw-text-gray_600"
                sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
              >
                {formatNumberToDollar(baseCashCost)}
              </Typography>
            </Box>
            <Divider />
            <Box sx={{ ...subsectionStyle, flexDirection: 'column', alignItems: 'flex-start' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '10px' }}>
                <Typography
                  className="tw-text-gray_600"
                  sx={{ fontFamily: 'Poppins', fontSize: '12px', fontWeight: 'bold' }}
                >
                  Potential Incentives
                </Typography>
                <Typography
                  className="tw-text-gray_600"
                  sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
                >
                  -{formatNumberToDollar(federalIncentive + srecIncentive)}
                </Typography>
              </Box>
              <Box sx={{ paddingLeft: '20px', width: '100%' }}>
                {federalIncentive > 0 && (
                  <Box sx={{ ...subsectionStyle, borderTop: '1px dashed #E0E0E0' }}>
                    <Typography
                      className="tw-text-gray_600"
                      sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
                    >
                      Federal Tax Credit (30%)
                    </Typography>
                    <Typography
                      className="tw-text-gray_600"
                      sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
                    >
                      -{formatNumberToDollar(federalIncentive)}
                    </Typography>
                  </Box>
                )}
                {srecIncentive > 0 && (
                  <Box sx={{ ...subsectionStyle, borderTop: '1px dashed #E0E0E0' }}>
                    <Typography
                      className="tw-text-gray_600"
                      sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
                    >
                      State Tax Incentive
                    </Typography>
                    <Typography
                      className="tw-text-gray_600"
                      sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
                    >
                      -{formatNumberToDollar(srecIncentive)}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
            <Divider />
            <Box sx={{ ...subsectionStyle }}>
              <Typography
                className="tw-text-gray_600"
                sx={{ fontFamily: 'Poppins', fontSize: '12px', fontWeight: 'bold' }}
              >
                Price after potential incentives:
              </Typography>
              <Typography
                className="tw-text-gray_600"
                sx={{ fontFamily: 'Poppins', fontSize: '12px', fontWeight: 'bold' }}
              >
                {/* {formatNumberToDollar(currentCashCost)} */}
                {formatNumberToDollar(baseCashCost - (federalIncentive + srecIncentive))}
              </Typography>
            </Box>
            {!stateGettersSetters.us_showFinanceDetailsInWelcome && (
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', paddingBottom: '20px', marginTop: '25px' }}>
                {/* <Button
                      variant="contained"
                      className="tw-w-1/2 tw-mt-2"
                      onClick={() => {
                        // TODO: Add functionality for the edit layout button
                        stateGettersSetters.us_setShowFinanceDetailsInWelcome(true)
                        window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' })
                      }}
                      sx={{
                        'textTransform': 'none',
                        'backgroundColor': '#42deff',
                        'color': palette.white,
                        '&:hover': {
                          backgroundColor: palette.aqua,
                        },
                        'borderRadius': '30px',
                        'fontSize': '15px',
                        'padding': '10px 10px',
                        'fontFamily': 'Poppins',
                        'width': 'auto',
                      }}
                    >
                      {rLIB('Edit')}
                    </Button> */}
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
      {/* </>
      )} */}
    </>
  )
}

export const rJSX_welcomeLunarFinanceDetails = (stateGettersSetters: any, palette: any): JSX.Element => {
  const subsectionStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '12px',
    padding: '15px',
  }

  let selectedFinancingType: string = stateGettersSetters.us_selectedFinancingType
  let baseCashCost: number = stateGettersSetters.us_baseCashCost
  let currentCashCost: number = stateGettersSetters.us_currentCashCost
  let federalIncentive: number = stateGettersSetters.us_federalIncentive
  let srecIncentive: number = stateGettersSetters.us_srecIncentive
  let lunarBatteryCost: number = stateGettersSetters.us_lunarBatteryCost
  let adders: Array<{ name: string; amount: number }> = stateGettersSetters.us_adders
  let discounts: Array<{ name: string; amount: number }> = stateGettersSetters.us_discounts

  return (
    <>
      {/* {!stateGettersSetters.us_compareMode && (
        <> */}
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {/* <Divider sx={{ width: '95%', marginLeft: '2.5%', marginTop: '10px' }} /> */}
        <Accordion
          elevation={0}
          defaultExpanded
        >
          <AccordionSummary
            // expandIcon={
            //   <KeyboardArrowDownIcon
            //     sx={{
            //       color: palette.darkGray,
            //       fontSize: '40px',
            //       fontWeight: 'bold',
            //       transform: stateGettersSetters.us_homeDetailsExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
            //       transition: 'transform 0.3s',
            //     }}
            //   />
            // }
            aria-controls="home-details-content"
            id="home-details-header"
          >
            <Typography
              variant="h6"
              className="tw-text-gray_600 tw-font-bold"
              sx={{ fontFamily: 'Poppins' }}
            >
              {rLIB('Financing: ')} {selectedFinancingType}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ ...subsectionStyle }}>
              <Typography
                className="tw-text-gray_600"
                sx={{ fontFamily: 'Poppins', fontSize: '12px', fontWeight: 'bold' }}
              >
                {rLIB('Est. System Cost')}
              </Typography>
              <Typography
                className="tw-text-gray_600"
                sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
              >
                {formatNumberToDollar(baseCashCost)}
              </Typography>
            </Box>
            <Divider />
            <Box sx={{ ...subsectionStyle }}>
              <Typography
                className="tw-text-gray_600"
                sx={{ fontFamily: 'Poppins', fontSize: '12px', fontWeight: 'bold' }}
              >
                {rLIB('Est. Lunar Battery Cost')}
              </Typography>
              <Typography
                className="tw-text-gray_600"
                sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
              >
                {formatNumberToDollar(lunarBatteryCost)}
              </Typography>
            </Box>
            <Divider />
            <Box sx={{ ...subsectionStyle, flexDirection: 'column', alignItems: 'flex-start' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '10px' }}>
                <Typography
                  className="tw-text-gray_600"
                  sx={{ fontFamily: 'Poppins', fontSize: '12px', fontWeight: 'bold' }}
                >
                  {rLIB('Total Adders')}
                </Typography>
                <Typography
                  className="tw-text-gray_600"
                  sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
                >
                  {adders && adders.length > 0 ? formatNumberToDollar(adders.reduce((sum: number, adder: { amount: number }) => sum + adder.amount, 0)) : '$0'}
                </Typography>
              </Box>
              <Box sx={{ paddingLeft: '20px', width: '100%' }}>
                {adders && adders.length > 0 ? (
                  adders.map((adder, index) => (
                    <Box
                      key={index}
                      sx={{ ...subsectionStyle, borderTop: '1px dashed #E0E0E0' }}
                    >
                      <Typography
                        className="tw-text-gray_600"
                        sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
                      >
                        {adder.name}
                      </Typography>
                      <Typography
                        className="tw-text-gray_600"
                        sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
                      >
                        {formatNumberToDollar(adder.amount)}
                      </Typography>
                    </Box>
                  ))
                ) : (
                  <Typography
                    className="tw-text-gray_600"
                    sx={{ fontFamily: 'Poppins', fontSize: '12px', fontStyle: 'italic' }}
                  >
                    {rLIB('No adders')}
                  </Typography>
                )}
              </Box>
            </Box>
            <Divider />
            <Box sx={{ ...subsectionStyle, flexDirection: 'column', alignItems: 'flex-start' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '10px' }}>
                <Typography
                  className="tw-text-gray_600"
                  sx={{ fontFamily: 'Poppins', fontSize: '12px', fontWeight: 'bold' }}
                >
                  {rLIB('Total Discounts')}
                </Typography>
                <Typography
                  className="tw-text-gray_600"
                  sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
                >
                  {discounts && discounts.length > 0
                    ? formatNumberToDollar(-discounts.reduce((sum: number, discount: { amount: number }) => sum + discount.amount, 0))
                    : '$0'}
                </Typography>
              </Box>
              <Box sx={{ paddingLeft: '20px', width: '100%' }}>
                {discounts && discounts.length > 0 ? (
                  discounts.map((discount, index) => (
                    <Box
                      key={index}
                      sx={{ ...subsectionStyle, borderTop: '1px dashed #E0E0E0' }}
                    >
                      <Typography
                        className="tw-text-gray_600"
                        sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
                      >
                        {discount.name}
                      </Typography>
                      <Typography
                        className="tw-text-gray_600"
                        sx={{ fontFamily: 'Poppins', fontSize: '12px', whiteSpace: 'nowrap' }}
                      >
                        -{formatNumberToDollar(discount.amount)}
                      </Typography>
                    </Box>
                  ))
                ) : (
                  <Typography
                    className="tw-text-gray_600"
                    sx={{ fontFamily: 'Poppins', fontSize: '12px', fontStyle: 'italic' }}
                  >
                    {rLIB('No discounts')}
                  </Typography>
                )}
              </Box>
            </Box>
            <Divider />
            <Box sx={{ ...subsectionStyle }}>
              <Typography
                className="tw-text-gray_600"
                sx={{ fontFamily: 'Poppins', fontSize: '12px', fontWeight: 'bold' }}
              >
                {rLIB('Total Cash Price:')}
              </Typography>
              <Typography
                className="tw-text-gray_600"
                sx={{ fontFamily: 'Poppins', fontSize: '12px', fontWeight: 'bold' }}
              >
                {formatNumberToDollar(
                  baseCashCost +
                    lunarBatteryCost +
                    adders.reduce((sum: number, adder: { amount: number }) => sum + adder.amount, 0) -
                    discounts.reduce((sum: number, discount: { amount: number }) => sum + discount.amount, 0),
                )}
              </Typography>
            </Box>
            {(federalIncentive > 0 || srecIncentive > 0) && (
              <>
                <Box sx={{ ...subsectionStyle, flexDirection: 'column', alignItems: 'flex-start' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '10px' }}>
                    <Typography
                      className="tw-text-gray_600"
                      sx={{ fontFamily: 'Poppins', fontSize: '12px', fontWeight: 'bold' }}
                    >
                      {rLIB('Potential Incentives')}
                    </Typography>
                    <Typography
                      className="tw-text-gray_600"
                      sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
                    >
                      -{formatNumberToDollar(federalIncentive + srecIncentive)}
                    </Typography>
                  </Box>
                  <Box sx={{ paddingLeft: '20px', width: '100%' }}>
                    {federalIncentive > 0 && (
                      <Box sx={{ ...subsectionStyle, borderTop: '1px dashed #E0E0E0' }}>
                        <Typography
                          className="tw-text-gray_600"
                          sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
                        >
                          {rLIB('Federal Tax Credit')}
                        </Typography>
                        <Typography
                          className="tw-text-gray_600"
                          sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
                        >
                          -{formatNumberToDollar(federalIncentive)}
                        </Typography>
                      </Box>
                    )}
                    {srecIncentive > 0 && (
                      <Box sx={{ ...subsectionStyle, borderTop: '1px dashed #E0E0E0' }}>
                        <Typography
                          className="tw-text-gray_600"
                          sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
                        >
                          {rLIB('State Tax Incentive')}
                        </Typography>
                        <Typography
                          className="tw-text-gray_600"
                          sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
                        >
                          -{formatNumberToDollar(srecIncentive)}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
                <Divider />
              </>
            )}
            {!stateGettersSetters.us_showFinanceDetailsInWelcome && (
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', paddingBottom: '20px', marginTop: '25px' }}>
                {/* <Button
                      variant="contained"
                      className="tw-w-1/2 tw-mt-2"
                      onClick={() => {
                        // TODO: Add functionality for the edit layout button
                        stateGettersSetters.us_setShowFinanceDetailsInWelcome(true)
                        window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' })
                      }}
                      sx={{
                        'textTransform': 'none',
                        'backgroundColor': '#42deff',
                        'color': palette.white,
                        '&:hover': {
                          backgroundColor: palette.aqua,
                        },
                        'borderRadius': '30px',
                        'fontSize': '15px',
                        'padding': '10px 10px',
                        'fontFamily': 'Poppins',
                        'width': 'auto',
                      }}
                    >
                      {rLIB('Edit')}
                    </Button> */}
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
      {/* </>
      )} */}
    </>
  )
}

export const rJSX_welcomeDocumentsDetails = (stateGettersSetters: any, palette: any): JSX.Element => {
  const proposals = stateGettersSetters.us_proposals
  return (
    <Box sx={{ padding: '20px' }}>
      {stateGettersSetters.us_opportunityFiles && (
        <Box sx={{ marginBottom: '20px' }}>
          <Typography
            variant="h6"
            sx={{ fontFamily: 'Poppins', fontWeight: 'bold', color: '#939598' }}
          >
            General
          </Typography>
          <Divider sx={{ marginBottom: '10px' }} />
          {stateGettersSetters.us_opportunityFiles.filter((file: any) => file.visible_to_customer).length > 0 ? (
            <Box>
              {stateGettersSetters.us_opportunityFiles
                .filter((file: any) => file.visible_to_customer)
                .map((file: any, index: number) => (
                  <Typography
                    key={index}
                    sx={{ fontFamily: 'Poppins', color: '#56d3ff' }}
                  >
                    <a
                      href={file.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#56d3ff', textDecoration: 'underline' }}
                    >
                      {file.file_name}
                    </a>
                  </Typography>
                ))}
            </Box>
          ) : (
            <Typography sx={{ fontFamily: 'Poppins', color: '#939598' }}>No files available.</Typography>
          )}
        </Box>
      )}
      {proposals.map((proposal: any) => {
        // Convert files to an array if it's an object
        const filesArray = proposal.files ? Object.values(proposal.files) : []
        // Filter files to only include those visible to the customer
        const visibleFiles = filesArray.filter((file: any) => file.visible_to_customer)

        return (
          <Box
            key={proposal.id}
            sx={{ marginBottom: '20px' }}
          >
            <Typography
              variant="h6"
              sx={{ fontFamily: 'Poppins', fontWeight: 'bold', color: '#939598' }}
            >
              {proposal.name}
            </Typography>
            <Divider sx={{ marginBottom: '10px' }} />
            {visibleFiles.length > 0 ? (
              <Box>
                {visibleFiles.map((file: any, index: number) => (
                  <Typography
                    key={index}
                    sx={{ fontFamily: 'Poppins', color: '#56d3ff' }}
                  >
                    <a
                      href={file.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#56d3ff', textDecoration: 'underline' }}
                    >
                      {file.file_name}
                    </a>
                  </Typography>
                ))}
              </Box>
            ) : (
              <Typography sx={{ fontFamily: 'Poppins', color: '#939598' }}>No files available.</Typography>
            )}
          </Box>
        )
      })}
    </Box>
  )
}

// welcome edit panel layout page
export const rJSX_panelLayoutHeader = (palette: any): JSX.Element => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography
        variant="h4"
        sx={{ fontFamily: 'Poppins', marginTop: '10px', fontWeight: 'bold', fontSize: '40px' }}
        className="tw-text-white tw-font-bold"
      >
        {rLIB('Edit Layout')}
      </Typography>
      <Typography
        variant="body1"
        sx={{ fontFamily: 'Poppins', marginTop: '15px', color: palette.white, fontSize: '16px', textAlign: 'center' }}
      >
        {rLIB(
          'Tap panels to turn them on or off. White panels have the most production and dark blue panels have the least production. In general, selecting panels with the most production gives the best economics.',
        )}
      </Typography>
    </Box>
  )
}

export const rJSX_panelLayoutSystemDetails = (
  stateGettersSetters: any,
  palette: any,
  opportunityKey: string,
  clientKey: string,
  // proposalKeysRef: any,
): JSX.Element => {
  const proposalKey = stateGettersSetters.us_activeProposalId

  const subsectionStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '12px',
    padding: '15px',
  }

  const subsectionGroupingStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  }

  let systemSizeInWatts: number = stateGettersSetters.us_systemSizeInWatts
  let numPanels: number = stateGettersSetters.us_numPanels
  let annualProductionInKWh: number = stateGettersSetters.us_systemAnnualProductionInKWh

  const handleEditClick = (field: string, value: number) => {
    stateGettersSetters.us_setEditingField(field)
    stateGettersSetters.us_setEditValue(value.toString())
  }

  const handleEditClose = () => {
    stateGettersSetters.us_setEditingField(null)
    stateGettersSetters.us_setEditValue('')
  }

  const handleEditSave = () => {
    const newValue = parseFloat(stateGettersSetters.us_editValue)
    if (!isNaN(newValue)) {
      switch (stateGettersSetters.us_editingField) {
        case 'systemSize':
          submitSystemChange(stateGettersSetters, 'systemSize', newValue, opportunityKey, clientKey, 'welcome', stateGettersSetters.us_activeProposalId)
          break
        case 'numPanels':
          submitSystemChange(stateGettersSetters, 'numPanels', newValue, opportunityKey, clientKey, 'welcome', stateGettersSetters.us_activeProposalId)
          break
        case 'annualProduction':
          submitSystemChange(stateGettersSetters, 'annualProduction', newValue, opportunityKey, clientKey, 'welcome', stateGettersSetters.us_activeProposalId)
          break
      }
    }
    handleEditClose()
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography
        variant="h5"
        sx={{ fontFamily: 'Poppins', marginTop: '0px', marginLeft: '10px', fontWeight: 'bold', fontSize: '23px' }}
        className="tw-text-gray_600 tw-font-bold"
      >
        {rLIB('Your System')}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography
          variant="h5"
          sx={{ fontFamily: 'Poppins', marginTop: '15px', marginLeft: '10px', fontWeight: 'bold', fontSize: '16px' }}
          className="tw-text-gray_600 tw-font-bold"
        >
          {rLIB('System Details')}
        </Typography>
        {/* <Button
          variant="outlined"
          aria-label="edit"
          color="inherit"
          size="small"
          sx={{
            'fontFamily': 'Poppins',
            'fontSize': '12px',
            'color': 'gray',
            'marginRight': '15px',
            'borderColor': 'gray',
            '&:hover': {
              borderColor: 'gray',
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
          }}
          onClick={() => {
            if (stateGettersSetters.us_compareMode) {
              stateGettersSetters.us_setCompareMode(false)
            } else {
              stateGettersSetters.us_setCompareMode(true)
            }
          }}
        >
          {stateGettersSetters.us_compareMode ? rLIB('Done') : rLIB('Compare')}
        </Button> */}
      </Box>
      <Divider sx={{ width: '95%', marginLeft: '2.5%', marginTop: '5px' }} />
      <Box sx={{ ...subsectionStyle }}>
        <Typography
          className="tw-text-gray_500"
          sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
        >
          {rLIB('We use top tier modules and Tesla Solar Inverters')}
        </Typography>
      </Box>
      <Divider sx={{ width: '95%', marginLeft: '2.5%', marginTop: '5px' }} />
      <Box sx={{ ...subsectionStyle }}>
        <Typography
          className="tw-text-gray_500"
          sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
        >
          {rLIB('System Size')}
        </Typography>
        <Box sx={{ ...subsectionGroupingStyle }}>
          <Typography
            className="tw-text-gray_600"
            sx={{ fontFamily: 'Poppins', marginRight: '5px', fontSize: '12px' }}
          >
            {systemSizeInWatts.toFixed(2)} kW
          </Typography>
          <IconButton
            aria-label="edit"
            color="inherit"
            size="small"
            sx={{ fontFamily: 'Poppins', fontSize: '12px', color: 'gray' }}
            onClick={() => {
              handleEditClick('systemSize', systemSizeInWatts)
            }}
          >
            <Icon icon="pen" />
          </IconButton>
        </Box>
      </Box>
      <Divider sx={{ width: '95%', marginLeft: '2.5%', marginTop: '5px' }} />
      <Box sx={{ ...subsectionStyle }}>
        <Typography
          className="tw-text-gray_500"
          sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
        >
          {rLIB('Estimated Number of Panels')}
        </Typography>
        <Box sx={{ ...subsectionGroupingStyle }}>
          <Typography
            className="tw-text-gray_600"
            sx={{ fontFamily: 'Poppins', marginRight: '5px', fontSize: '12px' }}
          >
            {Math.floor(numPanels)}
          </Typography>
          <IconButton
            aria-label="edit"
            color="inherit"
            size="small"
            sx={{ fontFamily: 'Poppins', fontSize: '12px', color: 'gray' }}
            onClick={() => {
              handleEditClick('numPanels', numPanels)
            }}
          >
            <Icon icon="pen" />
          </IconButton>
        </Box>
      </Box>
      <Divider sx={{ width: '95%', marginLeft: '2.5%', marginTop: '5px' }} />
      <Box sx={{ ...subsectionStyle }}>
        <Typography
          className="tw-text-gray_500"
          sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
        >
          {rLIB('Estimated Annual Production')}
        </Typography>
        <Box sx={{ ...subsectionGroupingStyle }}>
          <Typography
            className="tw-text-gray_600"
            sx={{ fontFamily: 'Poppins', marginRight: '5px', fontSize: '12px' }}
          >
            {annualProductionInKWh.toFixed(3)} kWh
          </Typography>
          <IconButton
            aria-label="edit"
            color="inherit"
            size="small"
            sx={{ fontFamily: 'Poppins', fontSize: '12px', color: 'gray' }}
            onClick={() => {
              handleEditClick('annualProduction', annualProductionInKWh)
            }}
          >
            <Icon icon="pen" />
          </IconButton>
        </Box>
      </Box>
      <Divider sx={{ width: '95%', marginLeft: '2.5%', marginTop: '5px' }} />
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px', width: '100%' }}>
        <Typography
          className="tw-text-gray_500"
          sx={{ fontFamily: 'Poppins', fontSize: '14px', marginTop: '15px', textAlign: 'left', width: '92%' }}
        >
          {rLIB('% of current utility bill covered')}
        </Typography>
        <MuiSlider
          sx={{ width: '92%', marginX: 'auto', color: palette.aqua, marginTop: '10px' }}
          value={stateGettersSetters.us_offset}
          valueLabelDisplay="off"
          slotProps={{
            rail: { className: 'tw-bg-gray_400 tw-h-4' },
            track: { className: 'tw-bg-gray_400 tw-h-4 tw-border-0' },
            thumb: { className: 'tw-size-8' },
          }}
          // marks
          step={1}
          min={80}
          max={130}
          onChange={(event, value) => {
            //submitOffsetChange(stateGettersSetters, value as number, false, opportunityKey, clientKey)
            //setPageLocalStorage('go_solar_' + opportunityKey, 'offset', value)
            //TODO: Add functionality for the slider
          }}
          // onChangeCommitted={(event, value) => {
          //   // saveSessionData(
          //   //   clientKey,
          //   //   opportunityKey,
          //   //   us_analyticsSessionKey,
          //   //   {
          //   //     actions_taken: {
          //   //       committed_utility_confirmation_slider_change: true,
          //   //     },
          //   //   },
          //   //   !(analyticsData.queryParams.get('tracking') == 'false'),
          //   // )
          // }}
        />
        <Dialog
          open={stateGettersSetters.us_editingField !== null}
          onClose={handleEditClose}
        >
          <DialogTitle>
            Edit{' '}
            {stateGettersSetters.us_editingField === 'systemSize'
              ? 'System Size'
              : stateGettersSetters.us_editingField === 'numPanels'
                ? 'Number of Panels'
                : 'Annual Production'}
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="New Value"
              type="number"
              fullWidth
              value={stateGettersSetters.us_editValue}
              onChange={(e) => stateGettersSetters.us_setEditValue(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditClose}>Cancel</Button>
            <Button onClick={handleEditSave}>Save</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  )
}

export const rJSX_panelLayoutFinanceDetails = (stateGettersSetters: any, palette: any): JSX.Element => {
  const subsectionStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '12px',
    padding: '15px',
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Accordion
        elevation={0}
        defaultExpanded
      >
        <AccordionSummary
          expandIcon={
            <KeyboardArrowDownIcon
              sx={{
                color: palette.darkGray,
                fontSize: '40px',
                fontWeight: 'bold',
                transform: stateGettersSetters.us_homeDetailsExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s',
              }}
            />
          }
          aria-controls="home-details-content"
          id="home-details-header"
        >
          <Typography
            variant="h6"
            className="tw-text-gray_600 tw-font-bold"
            sx={{ fontFamily: 'Poppins' }}
          >
            {rLIB('Financing: ')} {stateGettersSetters.us_selectedFinancingType}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ ...subsectionStyle }}>
            <Typography
              className="tw-text-gray_600"
              sx={{ fontFamily: 'Poppins', fontSize: '12px', fontWeight: 'bold', textAlign: 'left' }}
            >
              Est. System Cost
            </Typography>
            <Typography
              className="tw-text-gray_600"
              sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
            >
              {formatNumberToDollar(stateGettersSetters.us_baseCashCost)}
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ ...subsectionStyle, flexDirection: 'column', alignItems: 'flex-start' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '10px' }}>
              <Typography
                className="tw-text-gray_600"
                sx={{ fontFamily: 'Poppins', fontSize: '12px', fontWeight: 'bold' }}
              >
                Potential Incentives
              </Typography>
              <Typography
                className="tw-text-gray_600"
                sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
              >
                -{formatNumberToDollar(stateGettersSetters.us_federalIncentive + stateGettersSetters.us_srecIncentive)}
              </Typography>
            </Box>
            <Box sx={{ paddingLeft: '20px', width: '100%' }}>
              <Box sx={{ ...subsectionStyle, borderTop: '1px dashed #E0E0E0' }}>
                <Typography
                  className="tw-text-gray_600"
                  sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
                >
                  Federal Tax Credit (30%)
                </Typography>
                <Typography
                  className="tw-text-gray_600"
                  sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
                >
                  -{formatNumberToDollar(stateGettersSetters.us_federalIncentive)}
                </Typography>
              </Box>
              <Box sx={{ ...subsectionStyle, borderTop: '1px dashed #E0E0E0' }}>
                <Typography
                  className="tw-text-gray_600"
                  sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
                >
                  State Tax Incentive
                </Typography>
                <Typography
                  className="tw-text-gray_600"
                  sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
                >
                  -{formatNumberToDollar(stateGettersSetters.us_srecIncentive)}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box sx={{ ...subsectionStyle }}>
            <Typography
              className="tw-text-gray_600"
              sx={{ fontFamily: 'Poppins', fontSize: '12px', fontWeight: 'bold' }}
            >
              Price after potential incentives:
            </Typography>
            <Typography
              className="tw-text-gray_600"
              sx={{ fontFamily: 'Poppins', fontSize: '12px', fontWeight: 'bold' }}
            >
              {formatNumberToDollar(stateGettersSetters.us_currentCashCost)}
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', paddingBottom: '40px' }}>
        <Button
          variant="contained"
          className="tw-w-1/2 tw-mt-2"
          onClick={() => {
            // TODO: Add functionality for the edit layout button
          }}
          sx={{
            'textTransform': 'none',
            'backgroundColor': '#42deff',
            'color': palette.white,
            '&:hover': {
              backgroundColor: palette.aqua,
            },
            'borderRadius': '30px',
            'fontSize': '15px',
            'padding': '10px 10px',
            'fontFamily': 'Poppins',
            'width': 'auto',
          }}
        >
          {rLIB('Save Config')}
        </Button>
      </Box>
    </Box>
  )
}

//welcome photo cards
// const rJSX_HomeExteriorCard = (stateGettersSetters: any, palette: any, photoInputRef: React.RefObject<HTMLInputElement>): JSX.Element => {
//   const triggerFileInput = () => {
//     photoInputRef.current?.click()
//   }
//   return (
//     <Box sx={{ paddingLeft: '15px', paddingTop: '10px', height: '425px', backgroundColor: palette.lightAqua, color: palette.gray_600 }}>
//       <Typography
//         sx={{ fontFamily: 'Poppins', fontSize: '20px', fontWeight: 'bold' }}
//         variant="h6"
//       >
//         Home Exterior
//       </Typography>
//       <Card
//         sx={{
//           'width': '90%',
//           'height': '250px',
//           'backgroundColor': stateGettersSetters.us_homeExteriorImage ? 'transparent' : palette.gray_400,
//           'border': `2px dashed ${palette.gray_400}`,
//           'borderRadius': '8px',
//           'display': 'flex',
//           'justifyContent': 'center',
//           'alignItems': 'center',
//           'margin': '20px auto',
//           'marginLeft': '5px',
//           'cursor': 'pointer',
//           'position': 'relative', // Added for overlay positioning
//           'overflow': 'hidden', // Ensure image doesn't overflow
//           '&:hover': {
//             backgroundColor: palette.gray_400,
//             borderColor: palette.gray_500,
//           },
//         }}
//         onClick={() => {
//           stateGettersSetters.us_setPhotoKey('home_exterior')
//           triggerFileInput()
//         }}
//       >
//         {stateGettersSetters.us_homeExteriorImage ? (
//           <>
//             <Box
//               component="img"
//               src={URL.createObjectURL(stateGettersSetters.us_homeExteriorImage)}
//               sx={{
//                 width: '100%',
//                 height: '100%',
//                 objectFit: 'cover',
//               }}
//               alt="Uploaded home exterior"
//             />
//             {/* Optional: Add an overlay with edit/remove buttons */}
//             <Box
//               sx={{
//                 'position': 'absolute',
//                 'top': 0,
//                 'left': 0,
//                 'right': 0,
//                 'bottom': 0,
//                 'backgroundColor': 'rgba(0,0,0,0.3)',
//                 'display': 'flex',
//                 'justifyContent': 'center',
//                 'alignItems': 'center',
//                 'opacity': 0,
//                 'transition': 'opacity 0.2s',
//                 '&:hover': {
//                   opacity: 1,
//                 },
//               }}
//             >
//               <Typography sx={{ color: 'white', fontFamily: 'Poppins' }}>Click to change image</Typography>
//             </Box>
//           </>
//         ) : (
//           <CardContent sx={{ textAlign: 'center' }}>
//             <Typography sx={{ color: palette.gray_500, fontFamily: 'Poppins' }}>{rLIB('Click to upload or Upload straight from your camera')}</Typography>
//           </CardContent>
//         )}
//       </Card>
//       <Button
//         sx={{
//           'fontFamily': 'Poppins',
//           'backgroundColor': palette.lightAqua,
//           'color': palette.black,
//           'fontSize': '15px',
//           'borderRadius': '18px',
//           'marginTop': '10px',
//           'width': '40%',
//           'marginX': '70px',
//           '&.Mui-disabled': {
//             color: palette.gray_600,
//           },
//           '&.MuiButton-root': {
//             backgroundColor: palette.white,
//           },
//         }}
//         onClick={() => {
//           stateGettersSetters.us_setPhotoKey('home_exterior')
//           triggerFileInput()
//         }}
//       >
//         {rLIB('Upload')}
//       </Button>
//       <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px', marginX: 'auto', marginTop: '15px', color: palette.gray_600 }}>
//         {rLIB(`Not sure what's best? See our`)} <u style={{ color: palette.teal }}>{rLIB('guide below')}</u>
//       </Typography>
//     </Box>
//   )
// }

// const rJSX_RoofCard = (stateGettersSetters: any, palette: any, photoInputRef: React.RefObject<HTMLInputElement>): JSX.Element => {
//   const triggerFileInput = () => {
//     photoInputRef.current?.click()
//   }
//   return (
//     <Box sx={{ paddingLeft: '15px', paddingTop: '10px', height: '425px', backgroundColor: palette.lightAqua, color: palette.gray_600 }}>
//       <Typography
//         sx={{ fontFamily: 'Poppins', fontSize: '20px', fontWeight: 'bold' }}
//         variant="h6"
//       >
//         Roof
//       </Typography>
//       <Card
//         sx={{
//           'width': '90%',
//           'height': '250px',
//           'backgroundColor': stateGettersSetters.us_roofImage ? 'transparent' : palette.gray_400,
//           'border': `2px dashed ${palette.gray_400}`,
//           'borderRadius': '8px',
//           'display': 'flex',
//           'justifyContent': 'center',
//           'alignItems': 'center',
//           'margin': '20px auto',
//           'marginLeft': '5px',
//           'cursor': 'pointer',
//           'position': 'relative', // Added for overlay positioning
//           'overflow': 'hidden', // Ensure image doesn't overflow
//           '&:hover': {
//             backgroundColor: palette.gray_400,
//             borderColor: palette.gray_500,
//           },
//         }}
//         onClick={() => {
//           stateGettersSetters.us_setPhotoKey('roof')
//           triggerFileInput()
//         }}
//       >
//         {stateGettersSetters.us_roofImage ? (
//           <>
//             <Box
//               component="img"
//               src={URL.createObjectURL(stateGettersSetters.us_roofImage)}
//               sx={{
//                 width: '100%',
//                 height: '100%',
//                 objectFit: 'cover',
//               }}
//               alt="Uploaded roof"
//             />
//             {/* Optional: Add an overlay with edit/remove buttons */}
//             <Box
//               sx={{
//                 'position': 'absolute',
//                 'top': 0,
//                 'left': 0,
//                 'right': 0,
//                 'bottom': 0,
//                 'backgroundColor': 'rgba(0,0,0,0.3)',
//                 'display': 'flex',
//                 'justifyContent': 'center',
//                 'alignItems': 'center',
//                 'opacity': 0,
//                 'transition': 'opacity 0.2s',
//                 '&:hover': {
//                   opacity: 1,
//                 },
//               }}
//             >
//               <Typography sx={{ color: 'white', fontFamily: 'Poppins' }}>Click to change image</Typography>
//             </Box>
//           </>
//         ) : (
//           <CardContent sx={{ textAlign: 'center' }}>
//             <Typography sx={{ color: palette.gray_500, fontFamily: 'Poppins' }}>{rLIB('Click to upload or Upload straight from your camera')}</Typography>
//           </CardContent>
//         )}
//       </Card>
//       <Button
//         sx={{
//           'fontFamily': 'Poppins',
//           'backgroundColor': palette.lightAqua,
//           'color': palette.black,
//           'fontSize': '15px',
//           'borderRadius': '18px',
//           'marginTop': '10px',
//           'width': '40%',
//           'marginX': '70px',
//           '&.Mui-disabled': {
//             color: palette.gray_600,
//           },
//           '&.MuiButton-root': {
//             backgroundColor: palette.white,
//           },
//         }}
//         onClick={() => {
//           stateGettersSetters.us_setPhotoKey('roof')
//           triggerFileInput()
//         }}
//       >
//         {rLIB('Upload')}
//       </Button>
//       <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px', marginX: 'auto', marginTop: '15px', color: palette.gray_600 }}>
//         {rLIB(`Not sure what's best? See our`)} <u style={{ color: palette.teal }}>{rLIB('guide below')}</u>
//       </Typography>
//     </Box>
//   )
// }

// const rJSX_AtticCard = (stateGettersSetters: any, palette: any, photoInputRef: React.RefObject<HTMLInputElement>): JSX.Element => {
//   const triggerFileInput = () => {
//     photoInputRef.current?.click()
//   }
//   return (
//     <Box sx={{ paddingLeft: '15px', paddingTop: '10px', height: '425px', backgroundColor: palette.lightAqua, color: palette.gray_600 }}>
//       <Typography
//         sx={{ fontFamily: 'Poppins', fontSize: '20px', fontWeight: 'bold' }}
//         variant="h6"
//       >
//         Attic - Rafters and Plywood
//       </Typography>
//       <Card
//         sx={{
//           'width': '90%',
//           'height': '250px',
//           'backgroundColor': stateGettersSetters.us_atticImage ? 'transparent' : palette.gray_400,
//           'border': `2px dashed ${palette.gray_400}`,
//           'borderRadius': '8px',
//           'display': 'flex',
//           'justifyContent': 'center',
//           'alignItems': 'center',
//           'margin': '20px auto',
//           'marginLeft': '5px',
//           'cursor': 'pointer',
//           'position': 'relative', // Added for overlay positioning
//           'overflow': 'hidden', // Ensure image doesn't overflow
//           '&:hover': {
//             backgroundColor: palette.gray_400,
//             borderColor: palette.gray_500,
//           },
//         }}
//         onClick={() => {
//           stateGettersSetters.us_setPhotoKey('attic')
//           triggerFileInput()
//         }}
//       >
//         {stateGettersSetters.us_atticImage ? (
//           <>
//             <Box
//               component="img"
//               src={URL.createObjectURL(stateGettersSetters.us_atticImage)}
//               sx={{
//                 width: '100%',
//                 height: '100%',
//                 objectFit: 'cover',
//               }}
//               alt="Uploaded attic"
//             />
//             {/* Optional: Add an overlay with edit/remove buttons */}
//             <Box
//               sx={{
//                 'position': 'absolute',
//                 'top': 0,
//                 'left': 0,
//                 'right': 0,
//                 'bottom': 0,
//                 'backgroundColor': 'rgba(0,0,0,0.3)',
//                 'display': 'flex',
//                 'justifyContent': 'center',
//                 'alignItems': 'center',
//                 'opacity': 0,
//                 'transition': 'opacity 0.2s',
//                 '&:hover': {
//                   opacity: 1,
//                 },
//               }}
//             >
//               <Typography sx={{ color: 'white', fontFamily: 'Poppins' }}>Click to change image</Typography>
//             </Box>
//           </>
//         ) : (
//           <CardContent sx={{ textAlign: 'center' }}>
//             <Typography sx={{ color: palette.gray_500, fontFamily: 'Poppins' }}>{rLIB('Click to upload or Upload straight from your camera')}</Typography>
//           </CardContent>
//         )}
//       </Card>
//       <Button
//         sx={{
//           'fontFamily': 'Poppins',
//           'backgroundColor': palette.lightAqua,
//           'color': palette.black,
//           'fontSize': '15px',
//           'borderRadius': '18px',
//           'marginTop': '-5px',
//           'width': '40%',
//           'marginX': '70px',
//           '&.Mui-disabled': {
//             color: palette.gray_600,
//           },
//           '&.MuiButton-root': {
//             backgroundColor: palette.white,
//           },
//         }}
//         onClick={() => {
//           stateGettersSetters.us_setPhotoKey('attic')
//           triggerFileInput()
//         }}
//       >
//         {rLIB('Upload')}
//       </Button>
//       <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px', marginX: 'auto', marginTop: '0px', color: palette.gray_600 }}>
//         {rLIB(`Not sure what's best? See our`)} <u style={{ color: palette.teal }}>{rLIB('guide below')}</u>
//       </Typography>
//     </Box>
//   )
// }

//welcome survery sections
export const rJSX_generalInformation = (
  stateGettersSetters: any,
  palette: any,
  fileInputRef: React.RefObject<HTMLInputElement>,
  clientKey: string,
  opportunityKey: string,
): JSX.Element => {
  const triggerFileUpload = () => {
    fileInputRef.current?.click()
    stateGettersSetters.us_setFileKey('electric_bill')
  }
  return (
    <Box>
      <Typography
        variant="h6"
        sx={{
          fontFamily: 'Poppins',
          color: '#939598',
          marginBottom: '20px',
          textAlign: 'left',
          fontWeight: 'bold',
        }}
      >
        {rLIB('General Information')}
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <FormControl fullWidth>
          <InputLabel>{rLIB('What is your purchase decision timeline?')}</InputLabel>
          <Select
            value={stateGettersSetters.us_selectedPurchaseTimeline || ''}
            onChange={(e) => stateGettersSetters.us_setSelectedPurchaseTimeline(e.target.value)}
            label={rLIB('What is your purchase decision timeline?')}
            onBlur={() => {
              updateSurveyResponse(clientKey, opportunityKey, 'person_purchase_timeframe', stateGettersSetters.us_selectedPurchaseTimeline)
            }}
          >
            <MenuItem value="immediately">{rLIB('Immediately')}</MenuItem>
            <MenuItem value="0-30">{rLIB('0-30 Days')}</MenuItem>
            <MenuItem value="30-60">{rLIB('30-60 Days')}</MenuItem>
            <MenuItem value="60+">{rLIB('60+ Days')}</MenuItem>
          </Select>
        </FormControl>
        {/* need to figure out how to save this file differently from the other pictures*/}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 2,
            marginBottom: '20px',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1 }}>
            <Checkbox
              checked={stateGettersSetters.us_utilityBillUploaded}
              disabled
              sx={{
                'color': palette.gray_500,
                '&.Mui-checked': {
                  color: palette.aqua,
                },
              }}
            />
            <Typography
              className="tw-text-gray_500"
              sx={{ fontFamily: 'Poppins', fontSize: '12px' }}
            >
              {rLIB('Please upload your most recent copy of your utility bill and 12 months of usage if available')}
            </Typography>
          </Box>
          <Button
            variant="contained"
            component="label"
            sx={{
              'textTransform': 'none',
              'backgroundColor': palette.aqua,
              'color': palette.white,
              'minWidth': 'auto',
              'padding': '8px 16px',
              '&:hover': {
                backgroundColor: palette.gray_500,
              },
            }}
            onClick={() => {
              triggerFileUpload()
            }}
          >
            {rLIB('Choose File')}
          </Button>
        </Box>

        <FormControl
          fullWidth
          sx={{ marginBottom: '20px' }}
        >
          <InputLabel>{rLIB('Are you part of an HOA?')}</InputLabel>
          <Select
            value={stateGettersSetters.us_partOfHOA || ''}
            onChange={(e) => stateGettersSetters.us_setPartOfHOA(e.target.value)}
            label={rLIB('Are you part of an HOA?')}
            onBlur={() => {
              updateSurveyResponse(clientKey, opportunityKey, 'contact_part_of_hoa', stateGettersSetters.us_partOfHOA)
            }}
          >
            <MenuItem value="yes">{rLIB('Yes')}</MenuItem>
            <MenuItem value="no">{rLIB('No')}</MenuItem>
          </Select>
        </FormControl>

        {stateGettersSetters.us_partOfHOA === 'yes' && (
          <>
            <TextField
              fullWidth
              label={rLIB('HOA Name')}
              value={stateGettersSetters.us_hoaName || ''}
              onChange={(e) => stateGettersSetters.us_setHoaName(e.target.value)}
              onBlur={() => {
                updateSurveyResponse(clientKey, opportunityKey, 'associated_hoa_name', stateGettersSetters.us_hoaName)
              }}
              sx={{ marginBottom: '20px' }}
            />

            <TextField
              fullWidth
              label={rLIB('HOA Phone Number')}
              value={stateGettersSetters.us_hoaPhone || ''}
              onChange={(e) => stateGettersSetters.us_setHoaPhone(e.target.value)}
              onBlur={() => {
                updateSurveyResponse(clientKey, opportunityKey, 'associated_hoa_phone', stateGettersSetters.us_hoaPhone)
              }}
              sx={{ marginBottom: '20px' }}
            />
          </>
        )}
      </Box>
    </Box>
  )
}

export const rJSX_homeDetails = (stateGettersSetters: any, palette: any, clientKey: string, opportunityKey: string): JSX.Element => {
  return (
    <Box>
      <Typography sx={{ fontFamily: 'Poppins', color: '#939598', marginBottom: '20px', textAlign: 'left', fontWeight: 'bold', fontSize: '20px' }}>
        {rLIB('Home Details')}
      </Typography>
      <FormControl
        fullWidth
        sx={{ marginBottom: '20px' }}
      >
        <InputLabel>{rLIB('Which best describes your home?')}</InputLabel>
        <Select
          value={stateGettersSetters.us_selectedHomeType || ''}
          onChange={(e) => stateGettersSetters.us_setSelectedHomeType(e.target.value)}
          onBlur={() => {
            updateSurveyResponse(clientKey, opportunityKey, 'home_house_type', stateGettersSetters.us_selectedHomeType)
          }}
          label={rLIB('What best describes your home?')}
        >
          <MenuItem value="single_family">{rLIB('Single Family Home')}</MenuItem>
          <MenuItem value="condo">{rLIB('Condo')}</MenuItem>
          <MenuItem value="duplex">{rLIB('Duplex')}</MenuItem>
          <MenuItem value="mobile_home">{rLIB('Mobile Home')}</MenuItem>
        </Select>
      </FormControl>

      <FormControl
        fullWidth
        sx={{ marginBottom: '20px' }}
      >
        <InputLabel>{rLIB('When was your home built?')}</InputLabel>
        <Select
          value={stateGettersSetters.us_selectedHomeAge || ''}
          onChange={(e) => stateGettersSetters.us_setSelectedHomeAge(e.target.value)}
          label={rLIB('When was your home built?')}
          onBlur={() => {
            updateSurveyResponse(clientKey, opportunityKey, 'home_house_age', stateGettersSetters.us_selectedHomeAge)
          }}
        >
          <MenuItem value="1960's or older">{rLIB("1960's or older")}</MenuItem>
          <MenuItem value="1970 to 1985">{rLIB('1970 to 1985')}</MenuItem>
          <MenuItem value="1985 to 1999">{rLIB('1985 to 1999')}</MenuItem>
          <MenuItem value="2000 to 2015">{rLIB('2000 to 2015')}</MenuItem>
          <MenuItem value="2016 or newer">{rLIB('2016 or newer')}</MenuItem>
        </Select>
      </FormControl>

      <FormControl
        fullWidth
        sx={{ marginBottom: '20px' }}
      >
        <InputLabel>{rLIB('Do you have access to all exterior walls of your home?')}</InputLabel>
        <Select
          value={stateGettersSetters.us_accessToAllExteriorWalls ? 'yes' : 'no'}
          onChange={(e) => stateGettersSetters.us_setAccessToAllExteriorWalls(e.target.value === 'yes')}
          label={rLIB('Do you have access to all exterior walls of your home?')}
          onBlur={() => {
            updateSurveyResponse(clientKey, opportunityKey, 'home_outside_wall_access', stateGettersSetters.us_accessToAllExteriorWalls)
          }}
        >
          <MenuItem value="yes">{rLIB('Yes')}</MenuItem>
          <MenuItem value="no">{rLIB('No')}</MenuItem>
        </Select>
      </FormControl>

      {!stateGettersSetters.us_accessToAllExteriorWalls && (
        <>
          <TextField
            fullWidth
            label={rLIB('Please explain why you do not have access to all exterior walls of your home')}
            value={stateGettersSetters.us_noAccessReason || ''}
            onChange={(e) => stateGettersSetters.us_setNoAccessReason(e.target.value)}
            onBlur={() => {
              updateSurveyResponse(clientKey, opportunityKey, 'home_outside_wall_access_description', stateGettersSetters.us_noAccessReason)
            }}
            sx={{ marginBottom: '20px' }}
          />
        </>
      )}
    </Box>
  )
}

export const rJSX_roofCondition = (stateGettersSetters: any, palette: any, clientKey: string, opportunityKey: string): JSX.Element => {
  return (
    <Box>
      <Typography sx={{ fontFamily: 'Poppins', color: '#939598', marginBottom: '20px', textAlign: 'left', fontWeight: 'bold', fontSize: '20px' }}>
        {rLIB('Roof Condition')}
      </Typography>
      <FormControl
        fullWidth
        sx={{ marginBottom: '0px' }}
      >
        <InputLabel>{rLIB('How old is your roof?')}</InputLabel>
        <Select
          value={stateGettersSetters.us_selectedRoofAge || ''}
          onChange={(e) => stateGettersSetters.us_setSelectedRoofAge(e.target.value)}
          label={rLIB('How old is your roof?')}
          onBlur={() => {
            updateSurveyResponse(clientKey, opportunityKey, 'home_roof_age', stateGettersSetters.us_selectedRoofAge)
          }}
        >
          <MenuItem value="original_roof_with_home">{rLIB('Original roof with the home')}</MenuItem>
          <MenuItem value="20_years_or_more">{rLIB('20 years or more')}</MenuItem>
          <MenuItem value="15_to_20_years">{rLIB('15-20 years')}</MenuItem>
          <MenuItem value="10_to_15_years">{rLIB('10-15 years')}</MenuItem>
          <MenuItem value="less_than_10_years">{rLIB('Less than 10 years')}</MenuItem>
        </Select>
      </FormControl>
      <Typography sx={{ fontFamily: 'Poppins', color: '#939598', marginBottom: '20px', textAlign: 'left', fontWeight: 'bold', fontSize: '8px' }}>
        {rLIB('Note: Understating the age of the roof may incur a costly removal/reinstall later.')}
      </Typography>

      <FormControl
        fullWidth
        sx={{ marginBottom: '20px' }}
      >
        <InputLabel>{rLIB('Does the home have an attic?')}</InputLabel>
        <Select
          value={stateGettersSetters.us_hasAttic ? 'yes' : 'no'}
          onChange={(e) => stateGettersSetters.us_setHasAttic(e.target.value === 'yes')}
          label={rLIB('Does the home have an attic?')}
          onBlur={() => {
            updateSurveyResponse(clientKey, opportunityKey, 'home_has_attic', stateGettersSetters.us_hasAttic)
          }}
        >
          <MenuItem value="yes">{rLIB('Yes')}</MenuItem>
          <MenuItem value="no">{rLIB('No')}</MenuItem>
        </Select>
      </FormControl>

      {stateGettersSetters.us_hasAttic && (
        <TextField
          fullWidth
          label={rLIB('Where is the access to the attic?')}
          value={stateGettersSetters.us_atticAccess || ''}
          onChange={(e) => stateGettersSetters.us_setAtticAccess(e.target.value)}
          onBlur={() => {
            updateSurveyResponse(clientKey, opportunityKey, 'home_attic_access', stateGettersSetters.us_atticAccess)
          }}
          sx={{ marginBottom: '20px' }}
        />
      )}
    </Box>
  )
}

export const rJSX_mainPanel = (stateGettersSetters: any, palette: any, clientKey: string, opportunityKey: string): JSX.Element => {
  return (
    <Box>
      <Typography sx={{ fontFamily: 'Poppins', color: '#939598', marginBottom: '20px', textAlign: 'left', fontWeight: 'bold', fontSize: '20px' }}>
        {rLIB('Main Panel (Electrical)')}
      </Typography>
      <Typography sx={{ fontFamily: 'Poppins', color: '#939598', marginBottom: '0px', textAlign: 'left', fontSize: '10px' }}>
        {rLIB('What is the rating of the Main Breaker at the top of the panel(e.g, 100, 150, 200, etc.)?')}
      </Typography>
      <TextField
        fullWidth
        value={stateGettersSetters.us_mainBreakerMainPanelRating || ''}
        onChange={(e) => {
          const value = parseInt(e.target.value)
          if (!isNaN(value)) {
            stateGettersSetters.us_setMainBreakerMainPanelRating(value)
          } else if (e.target.value === '') {
            stateGettersSetters.us_setMainBreakerMainPanelRating('')
          }
        }}
        onBlur={() => {
          updateSurveyResponse(clientKey, opportunityKey, 'electrical_main_breaker_rating', stateGettersSetters.us_mainBreakerMainPanelRating)
        }}
        sx={{ marginBottom: '20px' }}
      />
      <Typography sx={{ fontFamily: 'Poppins', color: '#939598', marginBottom: '0px', textAlign: 'left', fontSize: '10px' }}>
        {rLIB('How many breakers are in your main panel?')}
      </Typography>
      <TextField
        fullWidth
        value={stateGettersSetters.us_breakerMainPanelCount || ''}
        onChange={(e) => {
          const value = parseInt(e.target.value)
          if (!isNaN(value)) {
            stateGettersSetters.us_setBreakerMainPanelCount(value)
          } else if (e.target.value === '') {
            stateGettersSetters.us_setBreakerMainPanelCount('')
          }
        }}
        onBlur={() => {
          updateSurveyResponse(clientKey, opportunityKey, 'electrical_main_panel_num_breakers', stateGettersSetters.us_breakerMainPanelCount)
        }}
        sx={{ marginBottom: '20px' }}
      />
      <Typography sx={{ fontFamily: 'Poppins', color: '#939598', marginBottom: '0px', textAlign: 'left', fontSize: '10px' }}>
        {rLIB('What is the sum of all the breaker numbers in the main panel(e.g., 30+30+20+15...)?')}
      </Typography>
      <TextField
        fullWidth
        value={stateGettersSetters.us_breakerMainPanelSum || ''}
        onChange={(e) => {
          const value = parseInt(e.target.value)
          if (!isNaN(value)) {
            stateGettersSetters.us_setBreakerMainPanelSum(value)
          } else if (e.target.value === '') {
            stateGettersSetters.us_setBreakerMainPanelSum('')
          }
        }}
        onBlur={() => {
          updateSurveyResponse(clientKey, opportunityKey, 'electrical_main_panel_sum_breakers', stateGettersSetters.us_breakerMainPanelSum)
        }}
        sx={{ marginBottom: '20px' }}
      />
    </Box>
  )
}

export const rJSX_subPanel = (stateGettersSetters: any, palette: any, clientKey: string, opportunityKey: string): JSX.Element => {
  return (
    <Box>
      <Typography sx={{ fontFamily: 'Poppins', color: '#939598', marginBottom: '20px', textAlign: 'left', fontWeight: 'bold', fontSize: '20px' }}>
        {rLIB('Sub Panel(s) (Electrical)')}
      </Typography>
      {/* <Typography sx={{ fontFamily: 'Poppins', color: '#939598', marginBottom: '0px', textAlign: 'left', fontSize: '10px' }}>
          {rLIB('How many sub-panels does the home have?')}
        </Typography> */}
      <TextField
        fullWidth
        label={rLIB('How many sub-panels does the home have?')}
        value={stateGettersSetters.us_subPanelCount || ''}
        onChange={(e) => {
          const value = parseInt(e.target.value)
          if (!isNaN(value)) {
            stateGettersSetters.us_setSubPanelCount(value)
          } else if (e.target.value === '') {
            stateGettersSetters.us_setSubPanelCount('')
          }
        }}
        onBlur={() => {
          updateSurveyResponse(clientKey, opportunityKey, 'electrical_sub_panel_number', stateGettersSetters.us_subPanelCount)
        }}
        sx={{ marginBottom: '20px' }}
      />
      <TextField
        fullWidth
        label={rLIB('Where are the sub panel(s) located?')}
        value={stateGettersSetters.us_subPanelLocation || ''}
        onChange={(e) => stateGettersSetters.us_setSubPanelLocation(e.target.value)}
        onBlur={() => {
          updateSurveyResponse(clientKey, opportunityKey, 'electrical_sub_panel_location', stateGettersSetters.us_subPanelLocation)
        }}
        sx={{ marginBottom: '20px' }}
      />
    </Box>
  )
}

export const rJSX_otherGenerationAndHighLoadItems = (stateGettersSetters: any, palette: any, clientKey: string, opportunityKey: string): JSX.Element => {
  return (
    <Box>
      <Typography sx={{ fontFamily: 'Poppins', color: '#939598', marginBottom: '20px', textAlign: 'left', fontWeight: 'bold', fontSize: '20px' }}>
        {rLIB('Other Generation and High Load Items (Electrical)')}
      </Typography>

      <FormControl
        component="fieldset"
        sx={{ marginBottom: '20px', width: '100%' }}
      >
        <FormLabel
          component="legend"
          sx={{ fontFamily: 'Poppins', color: '#939598', fontSize: '14px', marginBottom: '10px' }}
        >
          {rLIB('Does the home have an existing solar system?')}
        </FormLabel>
        <RadioGroup
          value={stateGettersSetters.us_hasSolarSystem ? 'yes' : 'no'}
          onChange={(e) => {
            const newValue = e.target.value === 'yes'
            stateGettersSetters.us_setHasSolarSystem(newValue)
            updateSurveyResponse(clientKey, opportunityKey, 'home_existing_solar_system', newValue)
          }}
          row
        >
          <FormControlLabel
            value="yes"
            control={<Radio />}
            label={rLIB('Yes')}
            sx={{ '& .MuiTypography-root': { fontFamily: 'Poppins', color: '#939598' } }}
          />
          <FormControlLabel
            value="no"
            control={<Radio />}
            label={rLIB('No')}
            sx={{ '& .MuiTypography-root': { fontFamily: 'Poppins', color: '#939598' } }}
          />
        </RadioGroup>
      </FormControl>
      <FormControl
        component="fieldset"
        sx={{ marginBottom: '20px', width: '100%' }}
      >
        <FormLabel
          component="legend"
          sx={{ fontFamily: 'Poppins', color: '#939598', fontSize: '14px', marginBottom: '10px' }}
        >
          {rLIB('Does the home have battery storage?')}
        </FormLabel>
        <RadioGroup
          value={stateGettersSetters.us_hasBatteryStorage ? 'yes' : 'no'}
          onChange={(e) => {
            const newValue = e.target.value === 'yes'
            stateGettersSetters.us_setHasBatteryStorage(newValue)
            updateSurveyResponse(clientKey, opportunityKey, 'home_battery_storage', newValue)
          }}
          row
        >
          <FormControlLabel
            value="yes"
            control={<Radio />}
            label={rLIB('Yes')}
            sx={{ '& .MuiTypography-root': { fontFamily: 'Poppins', color: '#939598' } }}
          />
          <FormControlLabel
            value="no"
            control={<Radio />}
            label={rLIB('No')}
            sx={{ '& .MuiTypography-root': { fontFamily: 'Poppins', color: '#939598' } }}
          />
        </RadioGroup>
      </FormControl>
      <FormControl
        component="fieldset"
        sx={{ marginBottom: '20px', width: '100%' }}
      >
        <FormLabel
          component="legend"
          sx={{ fontFamily: 'Poppins', color: '#939598', fontSize: '14px', marginBottom: '10px' }}
        >
          {rLIB('Does the home have a generator?')}
        </FormLabel>
        <RadioGroup
          value={stateGettersSetters.us_hasGenerator ? 'yes' : 'no'}
          onChange={(e) => {
            const newValue = e.target.value === 'yes'
            stateGettersSetters.us_setHasGenerator(newValue)
            updateSurveyResponse(clientKey, opportunityKey, 'home_has_generator', newValue)
          }}
          row
        >
          <FormControlLabel
            value="yes"
            control={<Radio />}
            label={rLIB('Yes')}
            sx={{ '& .MuiTypography-root': { fontFamily: 'Poppins', color: '#939598' } }}
          />
          <FormControlLabel
            value="no"
            control={<Radio />}
            label={rLIB('No')}
            sx={{ '& .MuiTypography-root': { fontFamily: 'Poppins', color: '#939598' } }}
          />
        </RadioGroup>
      </FormControl>
      <FormControl
        component="fieldset"
        sx={{ marginBottom: '20px', width: '100%' }}
      >
        <FormLabel
          component="legend"
          sx={{ fontFamily: 'Poppins', color: '#939598', fontSize: '14px', marginBottom: '10px' }}
        >
          {rLIB('Does the home have any of the following? (check all that apply)')}
        </FormLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={stateGettersSetters.us_hasEVCharger || false}
                onChange={(e) => {
                  const newValue = e.target.checked
                  stateGettersSetters.us_setHasEVCharger(newValue)
                  updateSurveyResponse(clientKey, opportunityKey, 'home_ev_charger', newValue)
                }}
              />
            }
            label={rLIB('EV Charger')}
            sx={{ '& .MuiTypography-root': { fontFamily: 'Poppins', color: '#939598' } }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={stateGettersSetters.us_hasPool || false}
                onChange={(e) => {
                  const newValue = e.target.checked
                  stateGettersSetters.us_setHasPool(newValue)
                  updateSurveyResponse(clientKey, opportunityKey, 'home_has_pool', newValue)
                }}
              />
            }
            label={rLIB('Pool')}
            sx={{ '& .MuiTypography-root': { fontFamily: 'Poppins', color: '#939598' } }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={stateGettersSetters.us_hasHotTub || false}
                onChange={(e) => {
                  const newValue = e.target.checked
                  stateGettersSetters.us_setHasHotTub(newValue)
                  updateSurveyResponse(clientKey, opportunityKey, 'home_has_hot_tub', newValue)
                }}
              />
            }
            label={rLIB('Hot Tub')}
            sx={{ '& .MuiTypography-root': { fontFamily: 'Poppins', color: '#939598' } }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={stateGettersSetters.us_hasElectricWaterHeater || false}
                onChange={(e) => {
                  const newValue = e.target.checked
                  stateGettersSetters.us_setHasElectricWaterHeater(newValue)
                  updateSurveyResponse(clientKey, opportunityKey, 'electric_water_heater', newValue)
                }}
              />
            }
            label={rLIB('Electric Water Heater')}
            sx={{ '& .MuiTypography-root': { fontFamily: 'Poppins', color: '#939598' } }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={stateGettersSetters.us_hasElectricDryer || false}
                onChange={(e) => {
                  const newValue = e.target.checked
                  stateGettersSetters.us_setHasElectricDryer(newValue)
                  updateSurveyResponse(clientKey, opportunityKey, 'electric_dryer', newValue)
                }}
              />
            }
            label={rLIB('Electric Dryer')}
            sx={{ '& .MuiTypography-root': { fontFamily: 'Poppins', color: '#939598' } }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={stateGettersSetters.us_hasElectricHeating || false}
                onChange={(e) => {
                  const newValue = e.target.checked
                  stateGettersSetters.us_setHasElectricHeating(newValue)
                  updateSurveyResponse(clientKey, opportunityKey, 'electric_heating', newValue)
                }}
              />
            }
            label={rLIB('Electric Heating(e.g., a heat pump)')}
            sx={{ '& .MuiTypography-root': { fontFamily: 'Poppins', color: '#939598' } }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={stateGettersSetters.us_hasSpecializedElectricalEquipment || false}
                onChange={(e) => {
                  const newValue = e.target.checked
                  stateGettersSetters.us_setHasSpecializedElectricalEquipment(newValue)
                  updateSurveyResponse(clientKey, opportunityKey, 'electric_specialized_equipment', newValue)
                }}
              />
            }
            label={rLIB('Specialized Electrical Equipment(e.g., welders, electric furnace)')}
            sx={{ '& .MuiTypography-root': { fontFamily: 'Poppins', color: '#939598' } }}
          />
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={stateGettersSetters.us_hasOtherHighLoadItem || false}
                  onChange={(e) => {
                    const newValue = e.target.checked
                    stateGettersSetters.us_setHasOtherHighLoadItem(newValue)
                    updateSurveyResponse(clientKey, opportunityKey, 'has_home_electric_other', newValue)
                  }}
                />
              }
              label={rLIB('Other')}
              sx={{ '& .MuiTypography-root': { fontFamily: 'Poppins', color: '#939598' } }}
            />
            {stateGettersSetters.us_hasOtherHighLoadItem && (
              <TextField
                size="small"
                label={rLIB('Please specify')}
                value={stateGettersSetters.us_otherHighLoadItem || ''}
                onChange={(e) => stateGettersSetters.us_setOtherHighLoadItem(e.target.value)}
                onBlur={() => {
                  updateSurveyResponse(clientKey, opportunityKey, 'home_electric_other', stateGettersSetters.us_otherHighLoadItem)
                }}
                sx={{ flex: 1 }}
              />
            )}
          </Box>
        </FormGroup>
      </FormControl>
    </Box>
  )
}

export const rJSX_designPreferences = (stateGettersSetters: any, palette: any, clientKey: string, opportunityKey: string): JSX.Element => {
  return (
    <Box>
      <Typography sx={{ fontFamily: 'Poppins', color: '#939598', marginBottom: '20px', textAlign: 'left', fontWeight: 'bold', fontSize: '20px' }}>
        {rLIB('Design Preferences')}
      </Typography>

      <FormControl
        component="fieldset"
        sx={{ marginBottom: '20px', width: '100%' }}
      >
        <FormLabel
          component="legend"
          sx={{ fontFamily: 'Poppins', color: '#939598', fontSize: '14px', marginBottom: '10px' }}
        >
          {rLIB('What is your preferred contact method?')}
        </FormLabel>
        <RadioGroup
          value={stateGettersSetters.us_preferredContactMethod || ''}
          onChange={(e) => {
            const newValue = e.target.value
            stateGettersSetters.us_setPreferredContactMethod(newValue)
            updateSurveyResponse(clientKey, opportunityKey, 'contact_preferred_method', newValue)
          }}
          row
        >
          <FormControlLabel
            value="phone"
            control={<Radio />}
            label={rLIB('Phone')}
            sx={{ '& .MuiTypography-root': { fontFamily: 'Poppins', color: '#939598' } }}
          />
          <FormControlLabel
            value="text"
            control={<Radio />}
            label={rLIB('Text')}
            sx={{ '& .MuiTypography-root': { fontFamily: 'Poppins', color: '#939598' } }}
          />
          <FormControlLabel
            value="email"
            control={<Radio />}
            label={rLIB('Email')}
            sx={{ '& .MuiTypography-root': { fontFamily: 'Poppins', color: '#939598' } }}
          />
        </RadioGroup>
      </FormControl>

      <FormControl
        component="fieldset"
        sx={{ marginBottom: '20px', width: '100%' }}
      >
        <FormLabel
          component="legend"
          sx={{ fontFamily: 'Poppins', color: '#939598', fontSize: '14px', marginBottom: '10px' }}
        >
          {rLIB('What are your preferred contact times?')}
        </FormLabel>
        <RadioGroup
          value={stateGettersSetters.us_preferredContactTimes || ''}
          onChange={(e) => {
            const newValue = e.target.value
            stateGettersSetters.us_setPreferredContactTimes(newValue)
            updateSurveyResponse(clientKey, opportunityKey, 'contact_preferred_contact_time', newValue)
          }}
          row
        >
          <FormControlLabel
            value="Morning"
            control={<Radio />}
            label={rLIB('Morning')}
            sx={{ '& .MuiTypography-root': { fontFamily: 'Poppins', color: '#939598' } }}
          />
          <FormControlLabel
            value="Afternoon"
            control={<Radio />}
            label={rLIB('Afternoon')}
            sx={{ '& .MuiTypography-root': { fontFamily: 'Poppins', color: '#939598' } }}
          />
          <FormControlLabel
            value="Evening"
            control={<Radio />}
            label={rLIB('Evening')}
            sx={{ '& .MuiTypography-root': { fontFamily: 'Poppins', color: '#939598' } }}
          />
          <FormControlLabel
            value="Weekends"
            control={<Radio />}
            label={rLIB('Weekends')}
            sx={{ '& .MuiTypography-root': { fontFamily: 'Poppins', color: '#939598' } }}
          />
        </RadioGroup>
      </FormControl>
      <FormControl
        component="fieldset"
        sx={{ marginBottom: '20px', width: '100%' }}
      >
        <FormLabel
          component="legend"
          sx={{ fontFamily: 'Poppins', color: '#939598', fontSize: '14px', marginBottom: '10px' }}
        >
          {rLIB('Do you have any pets that need to be secured when we visit the home?')}
        </FormLabel>
        <RadioGroup
          value={stateGettersSetters.us_hasPets ? 'yes' : 'no'}
          onChange={(e) => {
            const newValue = e.target.value === 'yes'
            stateGettersSetters.us_setHasPets(newValue)
            updateSurveyResponse(clientKey, opportunityKey, 'home_has_pets', newValue)
          }}
          row
        >
          <FormControlLabel
            value="yes"
            control={<Radio />}
            label={rLIB('Yes')}
            sx={{ '& .MuiTypography-root': { fontFamily: 'Poppins', color: '#939598' } }}
          />
          <FormControlLabel
            value="no"
            control={<Radio />}
            label={rLIB('No')}
            sx={{ '& .MuiTypography-root': { fontFamily: 'Poppins', color: '#939598' } }}
          />
        </RadioGroup>
      </FormControl>
      <FormControl
        component="fieldset"
        sx={{ marginBottom: '20px', width: '100%' }}
      >
        <FormLabel
          component="legend"
          sx={{ fontFamily: 'Poppins', color: '#939598', fontSize: '14px', marginBottom: '10px' }}
        >
          {rLIB('Is there a gate code to access the property or community?')}
        </FormLabel>
        <RadioGroup
          value={stateGettersSetters.us_hasGateCode ? 'yes' : 'no'}
          onChange={(e) => {
            const newValue = e.target.value === 'yes'
            stateGettersSetters.us_setHasGateCode(newValue)
            updateSurveyResponse(clientKey, opportunityKey, 'home_has_gate_code', newValue)
          }}
          row
        >
          <FormControlLabel
            value="yes"
            control={<Radio />}
            label={rLIB('Yes')}
            sx={{ '& .MuiTypography-root': { fontFamily: 'Poppins', color: '#939598' } }}
          />
          <FormControlLabel
            value="no"
            control={<Radio />}
            label={rLIB('No')}
            sx={{ '& .MuiTypography-root': { fontFamily: 'Poppins', color: '#939598' } }}
          />
        </RadioGroup>
      </FormControl>
      {stateGettersSetters.us_hasGateCode && (
        <TextField
          label={rLIB('Please enter the gate code')}
          value={stateGettersSetters.us_gateCode || ''}
          sx={{ marginBottom: '20px' }}
          onChange={(e) => {
            const newValue = e.target.value
            stateGettersSetters.us_setGateCode(newValue)
            updateSurveyResponse(clientKey, opportunityKey, 'home_gate_code', newValue)
          }}
        />
      )}

      <Typography sx={{ fontFamily: 'Poppins', color: '#939598', marginBottom: '0px', textAlign: 'left', fontSize: '10px' }}>
        {rLIB("Preferences on which roof section / plane you'd like your solar array to be installed. South-facing with the most sun are best by far.")}
      </Typography>
      <TextField
        fullWidth
        value={stateGettersSetters.us_panelPlacementPreference || ''}
        onChange={(e) => {
          const newValue = e.target.value
          stateGettersSetters.us_setPanelPlacementPreference(newValue)
          updateSurveyResponse(clientKey, opportunityKey, 'system_placement_preferences', newValue)
        }}
        sx={{ marginBottom: '20px' }}
      />

      <Typography sx={{ fontFamily: 'Poppins', color: '#939598', marginBottom: '0px', textAlign: 'left', fontSize: '10px' }}>
        {rLIB('Preferences on where you would like the inverter to be installed. Usually goes close to your meter and main panel.')}
      </Typography>
      <TextField
        fullWidth
        value={stateGettersSetters.us_inverterPlacementPreference || ''}
        onChange={(e) => {
          const newValue = e.target.value
          stateGettersSetters.us_setInverterPlacementPreference(newValue)
          updateSurveyResponse(clientKey, opportunityKey, 'system_inverter_preferences', newValue)
        }}
        sx={{ marginBottom: '20px' }}
      />
    </Box>
  )
}
