import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

export const defaultPagePermissions: TsInterface_UnspecifiedObject = {
  contact_log: 'read',
  design: 'read',
  details: 'read',
  documents: 'read',
  finance: 'read',
  logs: 'read',
  messages: 'read',
  notes: 'read',
  photos: 'read',
  quotes: 'read',
  sow: 'read',
  tasks: 'read',
  team: 'read',
}

const pageRolePermissions: TsInterface_UnspecifiedObject = {
  api_user: {
    // finance: 'read',
    contact_log: 'read',
    design: 'read',
    details: 'read',
    documents: 'read',
    logs: 'read',
    messages: 'read',
    notes: 'read',
    photos: 'read',
    sow: 'read',
    tasks: 'read',
    team: 'read',
  },
  accounts_payable: {
    contact_log: 'write',
    design: 'write',
    details: 'write',
    documents: 'write',
    finance: 'write',
    logs: 'write',
    messages: 'write',
    notes: 'write',
    photos: 'write',
    sow: 'write',
    tasks: 'write',
    team: 'write',
  },
  accounts_receivable: {
    contact_log: 'write',
    design: 'write',
    details: 'write',
    documents: 'write',
    finance: 'write',
    logs: 'write',
    messages: 'write',
    notes: 'write',
    photos: 'write',
    sow: 'write',
    tasks: 'write',
    team: 'write',
  },
  admin: {
    contact_log: 'write',
    design: 'write',
    details: 'write',
    documents: 'write',
    finance: 'write',
    logs: 'write',
    messages: 'write',
    notes: 'write',
    photos: 'write',
    sow: 'write',
    tasks: 'write',
    team: 'write',
  },
  construction_lead: {
    // finance: 'read',
    contact_log: 'write',
    design: 'write',
    details: 'write',
    documents: 'write',
    logs: 'write',
    messages: 'write',
    notes: 'write',
    photos: 'write',
    sow: 'write',
    tasks: 'write',
    team: 'write',
  },
  css_manager: {
    // finance: 'read',
    contact_log: 'write',
    design: 'write',
    details: 'write',
    documents: 'write',
    logs: 'write',
    messages: 'write',
    notes: 'write',
    photos: 'write',
    sow: 'write',
    tasks: 'write',
    team: 'write',
  },
  css_rep: {
    // finance: 'read',
    contact_log: 'write',
    design: 'write',
    details: 'write',
    documents: 'write',
    logs: 'write',
    messages: 'write',
    notes: 'write',
    photos: 'write',
    sow: 'write',
    tasks: 'write',
    team: 'write',
  },
  design: {
    // finance: 'read',
    contact_log: 'write',
    design: 'write',
    details: 'write',
    documents: 'write',
    logs: 'write',
    messages: 'write',
    notes: 'write',
    photos: 'write',
    sow: 'write',
    tasks: 'write',
    team: 'write',
  },
  electrical_foreman: {
    // finance: 'read',
    contact_log: 'read',
    design: 'read',
    details: 'read',
    documents: 'read',
    logs: 'read',
    messages: 'read',
    notes: 'read',
    photos: 'read',
    sow: 'read',
    tasks: 'read',
    team: 'read',
  },
  field_service: {
    // finance: 'read',
    contact_log: 'read',
    design: 'read',
    details: 'read',
    documents: 'read',
    logs: 'read',
    messages: 'read',
    notes: 'read',
    photos: 'read',
    sow: 'read',
    tasks: 'read',
    team: 'read',
  },
  general_manager: {
    // finance: 'read',
    contact_log: 'write',
    design: 'write',
    details: 'write',
    documents: 'write',
    logs: 'write',
    messages: 'write',
    notes: 'write',
    photos: 'write',
    sow: 'write',
    tasks: 'write',
    team: 'write',
  },
  home_upgrades_electrician: {
    // finance: 'read',
    contact_log: 'read',
    design: 'read',
    details: 'read',
    documents: 'read',
    logs: 'read',
    messages: 'read',
    notes: 'read',
    photos: 'read',
    sow: 'read',
    tasks: 'read',
    team: 'read',
  },
  home_upgrades_electrician_trainee: {
    // finance: 'read',
    contact_log: 'read',
    design: 'read',
    details: 'read',
    documents: 'read',
    logs: 'read',
    messages: 'read',
    notes: 'read',
    photos: 'read',
    sow: 'read',
    tasks: 'read',
    team: 'read',
  },
  inspection: {
    // finance: 'read',
    contact_log: 'read',
    design: 'read',
    details: 'read',
    documents: 'read',
    logs: 'read',
    messages: 'read',
    notes: 'read',
    photos: 'read',
    sow: 'read',
    tasks: 'read',
    team: 'read',
  },
  install_coordinator: {
    // finance: 'read',
    contact_log: 'write',
    design: 'write',
    details: 'write',
    documents: 'write',
    logs: 'write',
    messages: 'write',
    notes: 'write',
    photos: 'write',
    sow: 'write',
    tasks: 'write',
    team: 'write',
  },
  install_lead: {
    // finance: 'read',
    contact_log: 'read',
    design: 'read',
    details: 'read',
    documents: 'read',
    logs: 'read',
    messages: 'read',
    notes: 'read',
    photos: 'read',
    sow: 'read',
    tasks: 'read',
    team: 'read',
  },
  installer: {
    // finance: 'read',
    contact_log: 'read',
    design: 'read',
    details: 'read',
    documents: 'read',
    logs: 'read',
    messages: 'read',
    notes: 'read',
    photos: 'read',
    sow: 'read',
    tasks: 'read',
    team: 'read',
  },
  interconnection: {
    // finance: 'read',
    contact_log: 'write',
    design: 'write',
    details: 'write',
    documents: 'write',
    logs: 'write',
    messages: 'write',
    notes: 'write',
    photos: 'write',
    sow: 'write',
    tasks: 'write',
    team: 'write',
  },
  permitting: {
    // finance: 'read',
    contact_log: 'write',
    design: 'write',
    details: 'write',
    documents: 'write',
    logs: 'write',
    messages: 'write',
    notes: 'write',
    photos: 'write',
    sow: 'write',
    tasks: 'write',
    team: 'write',
  },
  repair: {
    // finance: 'read',
    contact_log: 'read',
    design: 'read',
    details: 'read',
    documents: 'read',
    logs: 'read',
    messages: 'read',
    notes: 'read',
    photos: 'read',
    sow: 'read',
    tasks: 'read',
    team: 'read',
  },
  scheduling: {
    // finance: 'read',
    contact_log: 'read',
    design: 'read',
    details: 'read',
    documents: 'read',
    logs: 'read',
    messages: 'read',
    notes: 'read',
    photos: 'read',
    sow: 'read',
    tasks: 'read',
    team: 'read',
  },
  site_auditor: {
    // finance: 'read',
    contact_log: 'read',
    design: 'read',
    details: 'read',
    documents: 'read',
    logs: 'read',
    messages: 'read',
    notes: 'read',
    photos: 'read',
    sow: 'read',
    tasks: 'read',
    team: 'read',
  },
  warehouse: {
    // finance: 'read',
    contact_log: 'read',
    design: 'read',
    details: 'read',
    documents: 'read',
    logs: 'read',
    messages: 'read',
    notes: 'read',
    photos: 'read',
    sow: 'read',
    tasks: 'read',
    team: 'read',
  },
}

export const determinePageSpecificPermissions = (clientUser: TsInterface_UnspecifiedObject): TsInterface_UnspecifiedObject => {
  let pageSpecificPermissions: TsInterface_UnspecifiedObject = { ...defaultPagePermissions }
  if (clientUser != null && clientUser.user_role != null) {
    for (let loopPermissionKey in defaultPagePermissions) {
      if (
        pageRolePermissions != null &&
        pageRolePermissions[clientUser.user_role] != null &&
        pageRolePermissions[clientUser.user_role][loopPermissionKey] === 'write'
      ) {
        pageSpecificPermissions[loopPermissionKey] = 'write'
      }
    }
  }
  if (clientUser != null && clientUser.task_roles != null) {
    for (let loopTaskRole in clientUser.task_roles) {
      let loopRoleValue = clientUser.task_roles[loopTaskRole]
      if (loopRoleValue === true) {
        for (let loopPermissionKey in defaultPagePermissions) {
          if (pageRolePermissions != null && pageRolePermissions[loopTaskRole] != null && pageRolePermissions[loopTaskRole][loopPermissionKey] === 'write') {
            pageSpecificPermissions[loopPermissionKey] = 'write'
          }
        }
      }
    }
  }
  return pageSpecificPermissions
}
