///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Chip, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material/'
import { pdf } from '@react-pdf/renderer'
import saveAs from 'file-saver'
import JSZip from 'jszip'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_ActiveSalesPartners_Query } from 'rfbp_aux/services/database_endpoints/directory/sales_partners'
import { DatabaseRef_TaskWorkflowsActive_Query } from 'rfbp_aux/services/database_endpoints/directory/task_workflows'
import { DatabaseRef_ProjectAdditionalWorkInvoices_Collection } from 'rfbp_aux/services/database_endpoints/finances/project_additional_work_invoices'
import { DatabaseRef_ProjectBaseInvoices_Collection } from 'rfbp_aux/services/database_endpoints/finances/project_base_invoices'
import { DatabaseRef_Projects_Collection } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { DatePicker } from 'rfbp_core/components/date_picker'
import { Icon } from 'rfbp_core/components/icons'
import { BasicImportButtonAndDialog } from 'rfbp_core/components/imports/basic_import_button_and_dialog'
import { generatePdfFromData } from 'rfbp_core/components/pdf/custom'
import { TableDatabase, TsInterface_TableAdditionalData, TsInterface_TableDatabaseEndpointQueryObject } from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
} from 'rfbp_core/services/context'
import {
  DatabaseGetCollection,
  DatabaseGetLiveCollection,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { downloadCSV, dynamicSort, formatCurrency, getProp, objectToArray, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { getPageLocalStorage, setPageLocalStorage } from 'rfbp_core/services/local_storage'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { ReconcileBaseInvoicePaymentsDialog } from './dialogs/reconcile_base_invoice_payment'
import { commissionPdfTemplate, detailedInvoicePdfTemplate, invoicePdfTemplate, quotePdfTemplate } from './services/invoice_pdf_templates'
import { tableColumns_AdditionalWorkIndexInvoices, tableSettings_AdditionalWorkIndexInvoices } from './tables/additional_work_invoices'
import {
  tableColumns_ApprovedInvoices,
  tableColumns_BilledInvoices,
  tableColumns_CancelledInvoices,
  tableColumns_CompletedInvoices,
  tableColumns_PaidInvoices,
  tableColumns_ProjectsMissingInvoiceApproval,
  tableColumns_ProjectsPendingInvoiceApproval,
  tableSettings_BaseInvoices,
  tableSettings_InvoiceProjects,
} from './tables/base_invoices'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminInvoiceIndexPage']['key']

// Table

// ViewBaseInvoiceDialog

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  const [us_downloadFilterEndDate, us_setDownloadFilterEndDate] = useState<Date>(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1))
  const [us_downloadFilterSalesPartner, us_setDownloadFilterSalesPartner] = useState<string>('ALL_SALES_PARTNERS')
  const [us_downloadFilterStartDate, us_setDownloadFilterStartDate] = useState<Date>(new Date(new Date().getFullYear(), new Date().getMonth(), 1))
  const [us_downloadingReport, us_setDownloadingReport] = useState<boolean>(false)
  const [us_salesPartners, us_setSalesPartners] = useState<TsInterface_UnspecifiedObject>({})
  const [us_taskWorkflows, us_setTaskWorkflows] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedInvoiceType, us_setSelectedInvoiceType] = useState<string>('projects') // additional_work
  const [us_selectedWorkflowKey, us_setSelectedWorkflowKey] = useState<string>('ALL')
  const un_routerNavigation = useNavigate()
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Invoicing', false) as string
  }, [])

  useEffect(() => {
    // Set Region from Local Storage
    let pageLocalStorageData = getPageLocalStorage(pageKey)
    if (getProp(pageLocalStorageData, 'us_selectedInvoiceType', null) != null) {
      us_setSelectedInvoiceType(getProp(pageLocalStorageData, 'us_selectedInvoiceType', 'projects'))
    }
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        // Nothing
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setSalesPartners(newData)
    }
    if (!uc_RootData_ClientKey) {
      return
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveSalesPartners_Query(res_GCK.clientKey, 'name', true, null, {}), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      let filteredWorkflows: TsInterface_UnspecifiedObject = {}
      for (let workflowKey in newData) {
        if (newData[workflowKey].projects_using_invoicing === true) {
          filteredWorkflows[workflowKey] = newData[workflowKey]
        }
      }
      us_setTaskWorkflows(filteredWorkflows)
    }
    if (!uc_RootData_ClientKey) {
      return
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_TaskWorkflowsActive_Query(res_GCK.clientKey, 'name', true, null, {}), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  // Functions
  const onChange = async (data: TsInterface_UnspecifiedObject) => {
    return new Promise((resolve, reject) => {
      resolve({ success: true })
    })
  }

  function serialToDate(serial: number) {
    // Base date in JavaScript (January 1, 1900)
    let baseDate = new Date(1900, 0, 1)

    // Adjust for the fact that Excel treats 1900 as a leap year incorrectly
    let daysToAdd = serial - 1 // Excel serial number 1 corresponds to Jan 1, 1900

    // Create a new date by adding the serial number as days to the base date
    baseDate.setDate(baseDate.getDate() + daysToAdd)

    return baseDate
  }

  const downloadInvoiceZip = (spreadsheetData: TsInterface_UnspecifiedObject) => {
    return new Promise((resolve, reject) => {
      const zip = new JSZip()
      // Group Rows by Invoice Number
      let groupedInvoices: TsInterface_UnspecifiedObject = {}
      for (let loopRowIndex in spreadsheetData) {
        let loopRow = spreadsheetData[loopRowIndex]
        let loopKey = loopRow.invoice_number
        if (loopRow != null && loopRow['download_type'] != null && loopRow['download_type'].toLowerCase().indexOf('quote') > -1) {
          loopKey = loopRow.invoice_number + '_quote'
        } else if (loopRow != null && loopRow['download_type'] != null && loopRow['download_type'].toLowerCase().indexOf('detail') > -1) {
          loopKey = loopRow.invoice_number + '_detailed'
        } else {
          loopKey = loopRow.invoice_number + '_invoice'
        }
        if (groupedInvoices[loopKey] == null) {
          groupedInvoices[loopKey] = []
        }
        groupedInvoices[loopKey].push(loopRow)
      }
      // Generate Formatted Data with combined line items
      let formattedDataList: TsInterface_UnspecifiedObject[] = []
      for (let invoiceNumber in groupedInvoices) {
        let invoiceData = groupedInvoices[invoiceNumber]
        let formattedData: TsInterface_UnspecifiedObject = {
          download_type: invoiceData[0].download_type,
          invoice_number: invoiceData[0].invoice_number,
          permitting_fees: null,
          // permitting_fees: invoiceData[0].permitting_fees,
          invoice_date: returnFormattedDate(serialToDate(invoiceData[0].invoice_date), 'MM/DD/YYYY'),
          client_name: invoiceData[0].client_name,
          location_address: invoiceData[0].location_address,
          location_city: invoiceData[0].location_city,
          location_state: invoiceData[0].location_state,
          location_zip: invoiceData[0].location_zip,
          location_city_plus_state: invoiceData[0].location_city + ', ' + invoiceData[0].location_state,
          job_code: invoiceData[0].job_code,
          line_items: [],
          line_item_total: 0,
          line_item_notes: '',
        }
        for (let lineItem of invoiceData) {
          // Add first permitting fee found
          if (
            lineItem.permitting_fees != null &&
            !isNaN(parseFloat(lineItem.permitting_fees)) &&
            lineItem.permitting_fees > 0 &&
            formattedData.permitting_fees == null
          ) {
            formattedData.permitting_fees = formatCurrency(parseFloat(lineItem.permitting_fees))
            formattedData.line_item_total += parseFloat(lineItem.permitting_fees)
          }
          formattedData.line_items.push({
            item_job_code: lineItem.job_code,
            item_description: lineItem.description,
            item_total: formatCurrency(lineItem.amount),
            // NEW
            item_rate: formatCurrency(lineItem.rate),
            item_adders: formatCurrency(lineItem.adders),
          })
          if (!isNaN(parseFloat(lineItem.amount))) {
            formattedData.line_item_total += parseFloat(lineItem.amount)
          }
          if (lineItem.notes) {
            formattedData.line_item_notes += lineItem.notes + '\n'
          }
        }
        formattedData.line_item_total = formatCurrency(formattedData.line_item_total)
        formattedDataList.push(formattedData)
      }
      // Download Zip
      let promiseArray: Promise<any>[] = []
      for (let i = 0; i < formattedDataList.length; i++) {
        let invoiceNumber = formattedDataList[i].invoice_number
        let downloadType = formattedDataList[i].download_type
        let template = invoicePdfTemplate
        if (downloadType != null && downloadType.toLowerCase().indexOf('quote') > -1) {
          template = quotePdfTemplate
        } else if (downloadType != null && downloadType.toLowerCase().indexOf('detail') > -1) {
          template = detailedInvoicePdfTemplate
        }
        // template = detailedInvoicePdfTemplate // TESTING
        let dataForDownload = []
        dataForDownload.push(formattedDataList[i])
        let pdfJSX = generatePdfFromData(template, formattedDataList[i], 'download', uc_setUserInterface_FormDialogDisplay, onChange, {})
        // downloadPdfFromData({ name: 'test' + i + '.pdf' }, pdfContent, dataForDownload)
        const pdfBlob = pdf(pdfJSX).toBlob()
        zip.file(invoiceNumber + `.pdf`, pdfBlob)
      }
      Promise.all(promiseArray).finally(() => {
        zip
          .generateAsync({ type: 'blob' })
          .then((blob) => {
            saveAs(blob, 'invoices.zip')
            resolve({ success: true })
          })
          .catch((error) => {
            console.error(error)
            resolve({ success: false })
          })
      })
    })
  }

  const downloadCommissionZip = (spreadsheetData: TsInterface_UnspecifiedObject) => {
    return new Promise((resolve, reject) => {
      const zip = new JSZip()

      // Group Rows by Job Code
      let groupedCommissions: TsInterface_UnspecifiedObject = {}
      for (let loopRowIndex in spreadsheetData) {
        let loopRow = spreadsheetData[loopRowIndex]
        let loopKey = loopRow.job_code || 'unknown_job'

        if (!groupedCommissions[loopKey]) {
          groupedCommissions[loopKey] = []
        }
        groupedCommissions[loopKey].push(loopRow)
      }

      // Generate Formatted Data
      let formattedDataList: TsInterface_UnspecifiedObject[] = []
      for (let jobCode in groupedCommissions) {
        let commissionData = groupedCommissions[jobCode]
        let formattedData: TsInterface_UnspecifiedObject = {
          job_code: commissionData[0].job_code,
          customer: commissionData[0].customer,
          contract_amount: formatCurrency(commissionData[0].contract_amount),
          status: commissionData[0].status,
          zip_code: commissionData[0].zip_code,
          financing: commissionData[0].financing,
          y1_production: commissionData[0].y1_production,
          finance_escalator: commissionData[0].finance_escalator,
          solar_rate: commissionData[0].solar_rate,
          steepest_pitch: commissionData[0].steepest_pitch,
          roof_type: commissionData[0].roof_type,
          system_size: commissionData[0].system_size,
          inverter_mfr: commissionData[0].inverter_mfr,
          panel_mfr: commissionData[0].panel_mfr,
          num_batteries: commissionData[0].num_batteries,
          sow_1: commissionData[0].sow_1,
          sow_2: commissionData[0].sow_2,
          sow_3: commissionData[0].sow_3,
          distance: commissionData[0].distance,
          pitch: commissionData[0].pitch,
          adders: formatCurrency(commissionData[0].adders),
          redline: formatCurrency(commissionData[0].redline),
          total_adders: commissionData[0].total_adders,
          gross_commissions: commissionData[0].gross_commissions,
          statement_date: commissionData[0].statement_date,
          sales_name: commissionData[0].sales_name,
          street: commissionData[0].street,
          city: commissionData[0].city,
          state: commissionData[0].state,
          city_state: `${commissionData[0].city}, ${commissionData[0].state}`,
          zip: commissionData[0].zip,
          total: formatCurrency(commissionData[0].total),
          statement_sent: commissionData[0].statement_sent,
          line_items: [],
          line_item_total: 0,
        }

        for (let lineItem of commissionData) {
          formattedData.line_items.push({
            item_job_code: lineItem.job_code,
            item_description: lineItem.description || 'N/A',
            item_total: formatCurrency(lineItem.contract_amount),
          })

          if (!isNaN(parseFloat(lineItem.contract_amount))) {
            formattedData.line_item_total += parseFloat(lineItem.contract_amount)
          }
        }
        formattedData.line_item_total = formatCurrency(formattedData.line_item_total)
        formattedDataList.push(formattedData)
      }

      // Download Zip
      let promiseArray: Promise<any>[] = []
      for (let i = 0; i < formattedDataList.length; i++) {
        let jobCode = formattedDataList[i].job_code
        let template = commissionPdfTemplate
        let pdfJSX = generatePdfFromData(template, formattedDataList[i], 'download', uc_setUserInterface_FormDialogDisplay, onChange, {})
        const pdfBlob = pdf(pdfJSX).toBlob()
        zip.file(jobCode + `.pdf`, pdfBlob)
      }

      Promise.all(promiseArray).finally(() => {
        zip
          .generateAsync({ type: 'blob' })
          .then((blob) => {
            saveAs(blob, 'commissions.zip')
            resolve({ success: true })
          })
          .catch((error) => {
            console.error(error)
            resolve({ success: false })
          })
      })
    })
  }

  const tableDatabaseEndpoint_InvoiceProjects = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
      { prop: 'invoice_status', comparator: '==', value: getProp(tableAdditionalData, 'invoice_status', '') },
    ]
    if (us_selectedWorkflowKey !== 'ALL') {
      queryOperatorsArray.push({ prop: 'associated_task_workflow_key', comparator: '==', value: us_selectedWorkflowKey })
    }
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'id_number', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 5)
    return generateDatabaseQuery(DatabaseRef_Projects_Collection(uc_RootData_ClientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
  }

  const tableDatabaseEndpoint_BaseInvoices = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: getProp(tableAdditionalData, 'status', '') }]
    if (us_selectedWorkflowKey !== 'ALL') {
      queryOperatorsArray.push({ prop: 'associated_task_workflow_key', comparator: '==', value: us_selectedWorkflowKey })
    }
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_created', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 5)
    return generateDatabaseQuery(
      DatabaseRef_ProjectBaseInvoices_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const tableDatabaseEndpoint_AdditionalWorkInvoices = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: getProp(tableAdditionalData, 'status', '') }]
    if (us_selectedWorkflowKey !== 'ALL') {
      queryOperatorsArray.push({ prop: 'associated_task_workflow_key', comparator: '==', value: us_selectedWorkflowKey })
    }
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_created', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 5)
    return generateDatabaseQuery(
      DatabaseRef_ProjectAdditionalWorkInvoices_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const calendarDownloadFilterStartDateOnChange = (newCalendarDate: Date): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      us_setDownloadFilterStartDate(newCalendarDate)
      resolve({ success: true })
    })
  }

  const calendarReminderEndDateOnChange = (newCalendarDate: Date): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      us_setDownloadFilterEndDate(newCalendarDate)
      resolve({ success: true })
    })
  }

  const convertProjectToInvoiceDataFormat = (projects: TsInterface_UnspecifiedObject) => {
    let invoiceData: TsInterface_UnspecifiedObject = {}
    for (let loopProjectKey in projects) {
      let loopProject = projects[loopProjectKey]
      invoiceData[loopProjectKey] = { ...loopProject }
      invoiceData[loopProjectKey]['status'] = loopProject.invoice_status
      invoiceData[loopProjectKey]['associated_project_job_code'] = loopProject.id_number
      invoiceData[loopProjectKey]['timestamp_created'] = loopProject.timestamp_invoice_generated
    }
    return invoiceData
  }

  const downloadInvoiceReports = (reportType: string, filterStatus: string) => {
    return new Promise((resolve, reject) => {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let promiseArray: Promise<any>[] = []
          let baseProjects: TsInterface_UnspecifiedObject = {}
          let baseProjectInvoices: TsInterface_UnspecifiedObject = {}
          let additionalWorkInvoices: TsInterface_UnspecifiedObject = {}
          let queryCursorsObject: TsInterface_QueryCursorsObject = {}
          if (filterStatus === 'paid' || filterStatus === 'cancelled') {
            queryCursorsObject['startAt'] = us_downloadFilterStartDate
            queryCursorsObject['endAt'] = us_downloadFilterEndDate
          }
          if (reportType === 'projects' || reportType === 'all') {
            if (filterStatus === 'pending') {
              let endpoint = generateDatabaseQuery(
                DatabaseRef_Projects_Collection(res_GCK.clientKey),
                [{ prop: 'invoice_status', comparator: '==', value: 'unapproved' }],
                [{ prop: 'id_number', desc: false }],
                {},
                null,
              )
              promiseArray.push(
                DatabaseGetCollection(endpoint).then((res_DGC) => {
                  let baseProjectsAsInvoices = convertProjectToInvoiceDataFormat(res_DGC.data)
                  let filteredBaseProjects: TsInterface_UnspecifiedObject = {}
                  for (let loopProjectKey in baseProjectsAsInvoices) {
                    let loopProject = { ...baseProjectsAsInvoices[loopProjectKey] }
                    loopProject['TEMP_invoice_type'] = 'Base Project'
                    if (us_downloadFilterSalesPartner === 'ALL_SALES_PARTNERS' || loopProject.associated_sales_partner_key === us_downloadFilterSalesPartner) {
                      filteredBaseProjects[loopProjectKey] = loopProject
                    }
                  }
                  baseProjects = filteredBaseProjects
                }),
              )
            } else {
              let endpoint = generateDatabaseQuery(
                DatabaseRef_ProjectBaseInvoices_Collection(res_GCK.clientKey),
                [{ prop: 'status', comparator: '==', value: filterStatus }],
                [{ prop: 'timestamp_created', desc: false }],
                queryCursorsObject,
                null,
              )
              promiseArray.push(
                DatabaseGetCollection(endpoint).then((res_DGC) => {
                  let filteredBaseProjectInvoices: TsInterface_UnspecifiedObject = {}
                  for (let loopInvoiceKey in res_DGC.data) {
                    let loopInvoice = { ...res_DGC.data[loopInvoiceKey] }
                    loopInvoice['TEMP_invoice_type'] = 'Base Project'
                    if (us_downloadFilterSalesPartner === 'ALL_SALES_PARTNERS' || loopInvoice.associated_sales_partner_key === us_downloadFilterSalesPartner) {
                      filteredBaseProjectInvoices[loopInvoiceKey] = loopInvoice
                    }
                  }
                  baseProjectInvoices = filteredBaseProjectInvoices
                }),
              )
            }
          }
          if (reportType === 'additional_work' || reportType === 'all') {
            let endpoint = generateDatabaseQuery(
              DatabaseRef_ProjectAdditionalWorkInvoices_Collection(res_GCK.clientKey),
              [{ prop: 'status', comparator: '==', value: filterStatus }],
              [{ prop: 'timestamp_created', desc: false }],
              queryCursorsObject,
              null,
            )
            promiseArray.push(
              DatabaseGetCollection(endpoint).then((res_DGC) => {
                let filteredAdditionalWorkInvoices: TsInterface_UnspecifiedObject = {}
                for (let loopInvoiceKey in res_DGC.data) {
                  let loopInvoice = { ...res_DGC.data[loopInvoiceKey] }
                  loopInvoice['TEMP_invoice_type'] = 'Additional Work'
                  if (us_downloadFilterSalesPartner === 'ALL_SALES_PARTNERS' || loopInvoice.associated_sales_partner_key === us_downloadFilterSalesPartner) {
                    filteredAdditionalWorkInvoices[loopInvoiceKey] = loopInvoice
                  }
                }
                additionalWorkInvoices = filteredAdditionalWorkInvoices
              }),
            )
          }
          Promise.all(promiseArray).finally(() => {
            let mergedData: TsInterface_UnspecifiedObject = {}
            mergedData = { ...baseProjects, ...baseProjectInvoices, ...additionalWorkInvoices }
            // If there is data to download, continue with the download process
            if (objectToArray(mergedData).length > 0) {
              const columnMapping = [
                { key: 'invoice_id_number', header: 'Billable Reference Number' },
                { key: 'associated_project_job_code', header: 'Job Code' },
                { key: 'TEMP_invoice_type', header: 'Invoice Type' },
                { key: 'status', header: 'Status' },
                { key: 'bill_to', header: 'Bill To' },
                { key: 'associated_sales_partner_name', header: 'Sales Partner' },
                { key: 'total_price', header: 'Total Price' },
                { key: 'timestamp_created', header: 'Invoice Generated', dateFormat: 'MM/DD/YYYY' },
                { key: 'timestamp_invoice_billed', header: 'Invoice Emailed', dateFormat: 'MM/DD/YYYY' },
                { key: 'timestamp_invoice_paid', header: 'Invoice Paid', dateFormat: 'MM/DD/YYYY' },
                { key: 'timestamp_cancelled', header: 'Invoice Cancelled', dateFormat: 'MM/DD/YYYY' },
              ]
              // Filter by sales partner if specified
              if (us_downloadFilterSalesPartner !== 'ALL_SALES_PARTNERS') {
                mergedData = Object.fromEntries(
                  Object.entries(mergedData).filter(([_, value]) => value.associated_sales_partner_key === us_downloadFilterSalesPartner),
                )
              }
              // Create CSV data structure
              let csvData = [
                // Headers
                columnMapping.map((col) => col.header),
                // Data rows
                ...Object.values(mergedData).map((row) => {
                  return columnMapping.map((col) => {
                    const value = row[col.key]
                    if (!value) return ''

                    // Format dates if specified
                    if (col.dateFormat) {
                      return returnFormattedDate(value, col.dateFormat)
                    }
                    // Format currency for total price
                    if (col.key === 'total_price') {
                      return formatCurrency(value)
                    }
                    return value
                  })
                }),
              ]
              // Generate filename with date range
              const startDate = returnFormattedDate(us_downloadFilterStartDate, 'MM-DD-YYYY')
              const endDate = returnFormattedDate(us_downloadFilterEndDate, 'MM-DD-YYYY')
              const filename = `InvoiceReport_${startDate}_to_${endDate}`
              downloadCSV(filename, csvData)
                .then((res_DC) => {
                  resolve(res_DC)
                })
                .catch((rej_DC) => {
                  console.error('Error downloading CSV:', rej_DC)
                  reject(rej_DC)
                })
            } else {
              reject({
                success: false,
                error: {
                  message: rLIB('Failed to download file'),
                  details: rLIB('No Invoices found for the selected filters'),
                  code: 'ER-D-II-DIR-01',
                },
              })
            }
          })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
          reject(rej_GCK)
        })
    })
  }

  // JSX Generation
  const rJSX_SettingsButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        color="inherit"
        variant="outlined"
        onClick={(event) => {
          onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminInvoiceSettingsPage.url())
        }}
        disableElevation
        className="tw-mr-2 tw-align-top"
        sx={{ height: '36px' }}
      >
        <Icon
          icon="gear"
          sx={{ fontSize: '18px' }}
        />
      </Button>
    )
    return buttonJSX
  }

  // const rJSX_Pdf2Content = (): JSX.Element => {
  //   let tabJSX = (
  //     <>
  //       {LayeredPdfEditor({
  //         pdfUrl:
  //           'https://firebasestorage.googleapis.com/v0/b/etw-energy.appspot.com/o/temp%2Fpdf%20test.pdf?alt=media&token=b7a9ea87-166d-466d-a65f-40a1337623d1',
  //         initialAnnotations: [
  //           {
  //             x: 100,
  //             y: 400,
  //             text: 'Test Annotation 1',
  //             page: 1,
  //           },
  //           {
  //             x: 170,
  //             y: 142,
  //             text: 'Test Annotation 2a',
  //             page: 2,
  //           },
  //           {
  //             x: 10,
  //             y: 10,
  //             text: 'Test Annotation 2b',
  //             page: 2,
  //           },
  //         ],
  //       })}
  //     </>
  //   )

  //   return tabJSX
  // }

  // const rJSX_Pdf2Tab = (): JSX.Element => {
  //   let tabJSX = <>{rJSX_Pdf2Content()}</>

  //   return tabJSX
  // }

  const rJSX_BulkInvoicesTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Card className="tw-text-center tw-p-4">
        <Typography>{rLIB('Use the import button above to upload a CSV with your invoices.')}</Typography>
      </Card>
    )
    return tabJSX
  }

  const rJSX_ImportInvoicesButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = (
      <BasicImportButtonAndDialog
        importAdditionalData={{}}
        importButtonColor={'info'}
        importButtonShrink={shrink}
        importButtonText={rLIB('Import Invoices')}
        importDialogHeader={rLIB('Confirm Invoices Import')}
        importMappingOptions={{
          invoice_number: {
            key: 'invoice_number',
            required: false,
            label: rLIB('Invoice Number'),
            automatch_properties: ['Invoice Number'],
          },
          invoice_date: {
            key: 'invoice_date',
            required: false,
            label: rLIB('Invoice Date'),
            automatch_properties: ['Invoice Date'],
          },
          client_name: {
            key: 'client_name',
            required: false,
            label: rLIB('Client Name'),
            automatch_properties: ['Client Name'],
          },
          location_address: {
            key: 'location_address',
            required: false,
            label: rLIB('Street'),
            automatch_properties: ['Street'],
          },
          location_city: {
            key: 'location_city',
            required: false,
            label: rLIB('City'),
            automatch_properties: ['City'],
          },
          location_state: {
            key: 'location_state',
            required: false,
            label: rLIB('State'),
            automatch_properties: ['State'],
          },
          location_zip: {
            key: 'location_zip',
            required: false,
            label: rLIB('Zip Code'),
            automatch_properties: ['ZIP Code'],
          },
          job_code: {
            key: 'job_code',
            required: false,
            label: rLIB('Project'),
            automatch_properties: ['Project'],
          },
          description: {
            key: 'description',
            required: false,
            label: rLIB('Description'),
            automatch_properties: ['Description'],
          },
          rate: {
            key: 'rate',
            required: false,
            label: rLIB('Rate'),
            automatch_properties: ['Rate'],
          },
          adders: {
            key: 'adders',
            required: false,
            label: rLIB('Adders'),
            automatch_properties: ['Adders'],
          },
          amount: {
            key: 'amount',
            required: false,
            label: rLIB('Amount'),
            automatch_properties: ['Amount'],
          },
          permitting_fees: {
            key: 'permitting_fees',
            required: false,
            label: rLIB('Permitting Fees'),
            automatch_properties: ['Permitting Fees'],
          },
          notes: {
            key: 'notes',
            required: false,
            label: rLIB('Notes'),
            automatch_properties: ['Notes'],
          },
          download_type: {
            key: 'download_type',
            required: false,
            label: rLIB('Download Type'),
            automatch_properties: ['Download Type'],
          },
        }}
        importSubmission={(spreadsheetData, importAdditionalData, importHooks) => {
          return new Promise((resolve, reject) => {
            downloadInvoiceZip(spreadsheetData)
              .then((res) => {
                resolve({ success: true })
              })
              .catch((err) => {
                reject({ success: false })
              })
          })
        }}
      />
    )
    return buttonJSX
  }

  const rJSX_ImportCommissionButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = (
      <BasicImportButtonAndDialog
        importAdditionalData={{}}
        importButtonColor={'info'}
        importButtonShrink={shrink}
        importButtonText={rLIB('Import Commission')}
        importDialogHeader={rLIB('Confirm Commission Import')}
        importMappingOptions={{
          job_code: {
            key: 'job_code',
            required: false,
            label: rLIB('Job Code'),
            automatch_properties: ['Job Code'],
          },
          customer: {
            key: 'customer',
            required: false,
            label: rLIB('Customer'),
            automatch_properties: ['Customer'],
          },
          status: {
            key: 'status',
            required: false,
            label: rLIB('Status'),
            automatch_properties: ['Status'],
          },
          contract_amount: {
            key: 'contract_amount',
            required: false,
            label: rLIB('Contract Amount'),
            automatch_properties: ['Contract Amount ($)'],
          },
          zip_code: {
            key: 'zip_code',
            required: false,
            label: rLIB('Zip Code'),
            automatch_properties: ['Zip Code'],
          },
          financing: {
            key: 'financing',
            required: false,
            label: rLIB('Financing'),
            automatch_properties: ['Financing'],
          },
          y1_production: {
            key: 'y1_production',
            required: false,
            label: rLIB('Y1 Production'),
            automatch_properties: ['Y1 Production'],
          },
          finance_escalator: {
            key: 'finance_escalator',
            required: false,
            label: rLIB('Finance Escalator'),
            automatch_properties: ['Finance Escalator (%)'],
          },
          solar_rate: {
            key: 'solar_rate',
            required: false,
            label: rLIB('Solar Rate'),
            automatch_properties: ['Solar Rate ($/W)'],
          },
          steepest_pitch: {
            key: 'steepest_pitch',
            required: false,
            label: rLIB('Steepest Pitch'),
            automatch_properties: ['Steepest Pitch (Slope)'],
          },
          roof_type: {
            key: 'roof_type',
            required: false,
            label: rLIB('Roof Type'),
            automatch_properties: ['Roof Type'],
          },
          system_size: {
            key: 'system_size',
            required: false,
            label: rLIB('System Size'),
            automatch_properties: ['System Size kW'],
          },
          inverter_mfr: {
            key: 'inverter_mfr',
            required: false,
            label: rLIB('Inverter Manufacturer'),
            automatch_properties: ['Inverter Mfr'],
          },
          panel_mfr: {
            key: 'panel_mfr',
            required: false,
            label: rLIB('Panel Manufacturer'),
            automatch_properties: ['Panel Mfr'],
          },
          num_batteries: {
            key: 'num_batteries',
            required: false,
            label: rLIB('Number of Batteries'),
            automatch_properties: ['Num Batteries'],
          },
          sow_1: {
            key: 'sow_1',
            required: false,
            label: rLIB('SOW 1'),
            automatch_properties: ['SOW 1'],
          },
          sow_2: {
            key: 'sow_2',
            required: false,
            label: rLIB('SOW 2'),
            automatch_properties: ['SOW 2'],
          },
          sow_3: {
            key: 'sow_3',
            required: false,
            label: rLIB('SOW 3'),
            automatch_properties: ['SOW 3'],
          },
          distance: {
            key: 'distance',
            required: false,
            label: rLIB('Distance'),
            automatch_properties: ['Distance'],
          },
          pitch: {
            key: 'pitch',
            required: false,
            label: rLIB('Pitch'),
            automatch_properties: ['Pitch'],
          },
          roof: {
            key: 'roof',
            required: false,
            label: rLIB('Roof'),
            automatch_properties: ['Roof'],
          },
          size: {
            key: 'size',
            required: false,
            label: rLIB('Size'),
            automatch_properties: ['Size'],
          },
          inverter: {
            key: 'inverter',
            required: false,
            label: rLIB('Inverter'),
            automatch_properties: ['Inverter'],
          },
          panel: {
            key: 'panel',
            required: false,
            label: rLIB('Panel'),
            automatch_properties: ['Panel'],
          },
          batteries: {
            key: 'batteries',
            required: false,
            label: rLIB('Batteries'),
            automatch_properties: ['Batteries'],
          },

          redline: {
            key: 'redline',
            required: false,
            label: rLIB('Redline'),
            automatch_properties: ['Redline $'],
          },
          gross_commissions: {
            key: 'gross_commissions',
            required: false,
            label: rLIB('Gross Commissions'),
            automatch_properties: ['Gross Commissions ($)'],
          },
          statement_date: {
            key: 'statement_date',
            required: false,
            label: rLIB('Statement Date'),
            automatch_properties: ['Statement Date'],
          },
          sales_name: {
            key: 'sales_name',
            required: false,
            label: rLIB('Sales Name'),
            automatch_properties: ['Sales Name'],
          },
          street: {
            key: 'street',
            required: false,
            label: rLIB('Street'),
            automatch_properties: ['Street'],
          },
          city: {
            key: 'city',
            required: false,
            label: rLIB('City'),
            automatch_properties: ['City'],
          },
          state: {
            key: 'state',
            required: false,
            label: rLIB('State'),
            automatch_properties: ['State'],
          },
          adders: {
            key: 'adders',
            required: false,
            label: rLIB('Adders'),
            automatch_properties: ['Adders $'],
          },
          total: {
            key: 'total',
            required: false,
            label: rLIB('Total'),
            automatch_properties: ['Total $'],
          },
          statement_sent: {
            key: 'statement_sent',
            required: false,
            label: rLIB('Statement Sent'),
            automatch_properties: ['Statement Sent'],
          },
        }}
        importSubmission={(spreadsheetData, importAdditionalData, importHooks) => {
          return new Promise((resolve, reject) => {
            downloadCommissionZip(spreadsheetData)
              .then((res) => {
                resolve({ success: true })
              })
              .catch((err) => {
                reject({ success: false })
              })
          })
        }}
      />
    )
    return buttonJSX
  }

  const rJSX_InvoiceTypeDropdown = (): JSX.Element => {
    let dropdownJSX = (
      <Box className="tw-inline-block tw-align-top tw-mr-2">
        <FormControl className="bp_thin_select_input">
          <Select
            color="primary"
            value={us_selectedInvoiceType}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                us_setSelectedInvoiceType(event.target.value)
                setPageLocalStorage(pageKey, 'us_selectedInvoiceType', event.target.value)
              }
            }}
            variant="outlined"
          >
            <MenuItem value={'projects'}>{rLIB('Projects')} </MenuItem>
            <MenuItem value={'additional_work'}>{rLIB('Additional Work')}</MenuItem>
          </Select>
        </FormControl>
      </Box>
    )
    return dropdownJSX
  }

  const rJSX_ProjectWorkflowTypeDropdown = (): JSX.Element => {
    let dropdownJSX = (
      <Box className="tw-inline-block tw-align-top tw-mr-2">
        <FormControl className="bp_thin_select_input">
          <Select
            color="primary"
            value={us_selectedWorkflowKey}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                us_setSelectedWorkflowKey(event.target.value)
              }
            }}
            variant="outlined"
          >
            <MenuItem
              value={'ALL'}
              disabled
            >
              {rLIB('Select a Workflow')}{' '}
            </MenuItem>
            {objectToArray(us_taskWorkflows)
              .sort(dynamicSort('name', 'asc'))
              .map((taskWorkflow: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={taskWorkflow.key}
                >
                  {taskWorkflow.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    )
    return dropdownJSX
  }

  const rJSX_InvoiceWorkflowTypeDropdown = (): JSX.Element => {
    let dropdownJSX = (
      <Box className="tw-inline-block tw-align-top tw-mr-2">
        <FormControl className="bp_thin_select_input">
          <Select
            color="primary"
            value={us_selectedWorkflowKey}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                us_setSelectedWorkflowKey(event.target.value)
              }
            }}
            variant="outlined"
          >
            <MenuItem value={'ALL'}>{rLIB('All Workflows')} </MenuItem>
            {objectToArray(us_taskWorkflows)
              .sort(dynamicSort('name', 'asc'))
              .map((taskWorkflow: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={taskWorkflow.key}
                >
                  {taskWorkflow.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    )
    return dropdownJSX
  }

  const rJSX_ImportBaseInvoiceBillingButton = (): JSX.Element => {
    let buttonJSX = (
      <BasicImportButtonAndDialog
        importAdditionalData={{}}
        importButtonColor={'info'}
        importButtonShrink={false}
        importButtonText={rLIB('Import Base Invoices')}
        importDialogHeader={rLIB('Confirm Base Invoices Import')}
        importMappingOptions={{
          unique_identifier: {
            key: 'unique_identifier',
            required: true,
            label: rLIB('Invoice Number'),
            automatch_properties: ['Invoice Number'],
          },
          amount: {
            key: 'amount',
            required: true,
            label: rLIB('Amount'),
            automatch_properties: ['Amount'],
          },
          date: {
            key: 'date',
            required: true,
            label: rLIB('Date'),
            automatch_properties: ['Date'],
          },
        }}
        importSubmission={(spreadsheetData, importAdditionalData, importHooks) => {
          return new Promise((resolve, reject) => {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                uc_setUserInterface_CustomDialogDisplay({
                  display: true,
                  dialog: {
                    dialog_jsx: (
                      <ReconcileBaseInvoicePaymentsDialog
                        clientKey={res_GCK.clientKey}
                        pendingPayments={spreadsheetData}
                      />
                    ),
                    settings: {
                      max_width: 'lg',
                    },
                  },
                })
              })
              .catch((err) => {
                reject({ success: false })
              })
          })
        }}
      />
    )
    return buttonJSX
  }

  const rJSX_ProjectsMissingBaseInvoiceTab = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      if (us_selectedInvoiceType === 'projects') {
        if (us_selectedWorkflowKey === 'ALL') {
          tabJSX = (
            <Card className="tw-text-center tw-p-4">
              <Typography className="tw-mb-2">{rLIB('Please select a workflow')}</Typography>
              {rJSX_ProjectWorkflowTypeDropdown()}
            </Card>
          )
        } else {
          tabJSX = (
            <Box>
              <Card>
                <TableDatabase
                  tableAdditionalData={{ invoice_status: 'missing' }}
                  tableColumns={tableColumns_ProjectsMissingInvoiceApproval}
                  tableDatabaseEndpoint={tableDatabaseEndpoint_InvoiceProjects}
                  tableSettings={tableSettings_InvoiceProjects}
                />
              </Card>
              <Typography
                variant="body1"
                className="tw-opacity-30"
              >
                {rLIB('Projects missing base invoice')}
              </Typography>
            </Box>
          )
        }
      } else if (us_selectedInvoiceType === 'additional_work') {
        tabJSX = (
          <Card className="tw-text-center tw-p-4">
            <Typography>{rLIB('Not relevant for additional work')}</Typography>
          </Card>
        )
      }
    }
    return tabJSX
  }

  const rJSX_InvoicesPendingApprovalTab = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      if (us_selectedInvoiceType === 'projects') {
        tabJSX = (
          <Box>
            <Card>
              <TableDatabase
                tableAdditionalData={{ invoice_status: 'unapproved' }}
                tableColumns={tableColumns_ProjectsPendingInvoiceApproval}
                tableDatabaseEndpoint={tableDatabaseEndpoint_InvoiceProjects}
                tableSettings={tableSettings_InvoiceProjects}
              />
            </Card>
            <Typography
              variant="body1"
              className="tw-opacity-30"
            >
              {rLIB('Projects pending base invoice approval')}
            </Typography>
          </Box>
        )
      } else if (us_selectedInvoiceType === 'additional_work') {
        tabJSX = (
          <Box>
            <Card>
              <TableDatabase
                tableAdditionalData={{ status: 'unapproved' }}
                tableColumns={tableColumns_AdditionalWorkIndexInvoices}
                tableDatabaseEndpoint={tableDatabaseEndpoint_AdditionalWorkInvoices}
                tableSettings={tableSettings_AdditionalWorkIndexInvoices}
              />
            </Card>
            <Typography
              variant="body1"
              className="tw-opacity-30"
            >
              {rLIB('Invoices waiting for task completion')}
            </Typography>
          </Box>
        )
      }
    }
    return tabJSX
  }

  const rJSX_ApprovedInvoicesTab = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      if (us_selectedInvoiceType === 'projects') {
        tabJSX = (
          <Box>
            <Card>
              <TableDatabase
                tableAdditionalData={{ status: 'approved' }}
                tableColumns={tableColumns_ApprovedInvoices}
                tableDatabaseEndpoint={tableDatabaseEndpoint_BaseInvoices}
                tableSettings={tableSettings_BaseInvoices}
              />
            </Card>
            <Typography
              variant="body1"
              className="tw-opacity-30"
            >
              {rLIB('Invoices waiting for task completion')}
            </Typography>
          </Box>
        )
      } else if (us_selectedInvoiceType === 'additional_work') {
        tabJSX = (
          <Box>
            <Card>
              <TableDatabase
                tableAdditionalData={{ status: 'approved' }}
                tableColumns={tableColumns_AdditionalWorkIndexInvoices}
                tableDatabaseEndpoint={tableDatabaseEndpoint_AdditionalWorkInvoices}
                tableSettings={tableSettings_AdditionalWorkIndexInvoices}
              />
            </Card>
            <Typography
              variant="body1"
              className="tw-opacity-30"
            >
              {rLIB('Invoices waiting for task completion')}
            </Typography>
          </Box>
        )
      }
    }
    return tabJSX
  }

  const rJSX_CompletedInvoicesTab = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      if (us_selectedInvoiceType === 'projects') {
        tabJSX = (
          <Box>
            <Card>
              <TableDatabase
                tableAdditionalData={{ status: 'completed' }}
                tableColumns={tableColumns_CompletedInvoices}
                tableDatabaseEndpoint={tableDatabaseEndpoint_BaseInvoices}
                tableSettings={tableSettings_BaseInvoices}
              />
            </Card>
            <Typography
              variant="body1"
              className="tw-opacity-30"
            >
              {rLIB('Invoices linked to completed tasks that have not been billed')}
            </Typography>
          </Box>
        )
      } else if (us_selectedInvoiceType === 'additional_work') {
        tabJSX = (
          <Box>
            <Card>
              <TableDatabase
                tableAdditionalData={{ status: 'completed' }}
                tableColumns={tableColumns_AdditionalWorkIndexInvoices}
                tableDatabaseEndpoint={tableDatabaseEndpoint_AdditionalWorkInvoices}
                tableSettings={tableSettings_AdditionalWorkIndexInvoices}
              />
            </Card>
            <Typography
              variant="body1"
              className="tw-opacity-30"
            >
              {rLIB('Invoices waiting for task completion')}
            </Typography>
          </Box>
        )
      }
    }
    return tabJSX
  }

  const rJSX_BilledInvoicesTab = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      if (us_selectedInvoiceType === 'projects') {
        tabJSX = (
          <Box>
            <Card>
              <TableDatabase
                tableAdditionalData={{ status: 'billed' }}
                tableColumns={tableColumns_BilledInvoices}
                tableDatabaseEndpoint={tableDatabaseEndpoint_BaseInvoices}
                tableSettings={tableSettings_BaseInvoices}
              />
            </Card>
            <Typography
              variant="body1"
              className="tw-opacity-30"
            >
              {rLIB('Invoices that have been billed but not paid')}
            </Typography>
          </Box>
        )
      } else if (us_selectedInvoiceType === 'additional_work') {
        tabJSX = (
          <Box>
            <Card>
              <TableDatabase
                tableAdditionalData={{ status: 'billed' }}
                tableColumns={tableColumns_AdditionalWorkIndexInvoices}
                tableDatabaseEndpoint={tableDatabaseEndpoint_AdditionalWorkInvoices}
                tableSettings={tableSettings_AdditionalWorkIndexInvoices}
              />
            </Card>
            <Typography
              variant="body1"
              className="tw-opacity-30"
            >
              {rLIB('Invoices waiting for task completion')}
            </Typography>
          </Box>
        )
      }
    }
    return tabJSX
  }

  const rJSX_PaidInvoicesTab = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      if (us_selectedInvoiceType === 'projects') {
        tabJSX = (
          <Box>
            <Card>
              <TableDatabase
                tableAdditionalData={{ status: 'paid' }}
                tableColumns={tableColumns_PaidInvoices}
                tableDatabaseEndpoint={tableDatabaseEndpoint_BaseInvoices}
                tableSettings={tableSettings_BaseInvoices}
              />
            </Card>
            <Typography
              variant="body1"
              className="tw-opacity-30"
            >
              {rLIB('Invoices that have been paid')}
            </Typography>
          </Box>
        )
      } else if (us_selectedInvoiceType === 'additional_work') {
        tabJSX = (
          <Box>
            <Card>
              <TableDatabase
                tableAdditionalData={{ status: 'paid' }}
                tableColumns={tableColumns_AdditionalWorkIndexInvoices}
                tableDatabaseEndpoint={tableDatabaseEndpoint_AdditionalWorkInvoices}
                tableSettings={tableSettings_AdditionalWorkIndexInvoices}
              />
            </Card>
            <Typography
              variant="body1"
              className="tw-opacity-30"
            >
              {rLIB('Invoices waiting for task completion')}
            </Typography>
          </Box>
        )
      }
    }
    return tabJSX
  }

  const rJSX_CancelledInvoicesTab = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      if (us_selectedInvoiceType === 'projects') {
        tabJSX = (
          <Box>
            <Card>
              <TableDatabase
                tableAdditionalData={{ status: 'cancelled' }}
                tableColumns={tableColumns_CancelledInvoices}
                tableDatabaseEndpoint={tableDatabaseEndpoint_BaseInvoices}
                tableSettings={tableSettings_BaseInvoices}
              />
            </Card>
            <Typography
              variant="body1"
              className="tw-opacity-30"
            >
              {rLIB('Invoices that have been cancelled')}
            </Typography>
          </Box>
        )
      } else if (us_selectedInvoiceType === 'additional_work') {
        tabJSX = (
          <Box>
            <Card>
              <TableDatabase
                tableAdditionalData={{ status: 'cancelled' }}
                tableColumns={tableColumns_AdditionalWorkIndexInvoices}
                tableDatabaseEndpoint={tableDatabaseEndpoint_AdditionalWorkInvoices}
                tableSettings={tableSettings_AdditionalWorkIndexInvoices}
              />
            </Card>
            <Typography
              variant="body1"
              className="tw-opacity-30"
            >
              {rLIB('Invoices waiting for task completion')}
            </Typography>
          </Box>
        )
      }
    }
    return tabJSX
  }

  const rJSX_DownloadFilterStartDateSelection = (): JSX.Element => {
    return (
      <DatePicker
        key={'start_date'}
        datePickerText={rLIB('Start Date')}
        datePickerDate={us_downloadFilterStartDate}
        datePickerDisabled={false}
        datePickerDateOnChange={calendarDownloadFilterStartDateOnChange}
        datePickerSettings={{ thin_input: true }}
      />
    )
  }

  const rJSX_DownloadFilterEndDateSelection = (): JSX.Element => {
    return (
      <DatePicker
        key={'end_date'}
        datePickerText={rLIB('End Date')}
        datePickerDate={us_downloadFilterEndDate}
        datePickerDisabled={false}
        datePickerDateOnChange={calendarReminderEndDateOnChange}
        datePickerSettings={{ thin_input: true }}
      />
    )
  }

  const rJSX_DownloadReportButton = (text: JSX.Element | string, reportType: string, filterStatus: string): JSX.Element => {
    return (
      <Button
        variant="contained"
        color="info"
        startIcon={<Icon icon="file-arrow-down" />}
        disabled={us_downloadingReport === true}
        onClick={() => {
          // TODO:
          us_setDownloadingReport(true)
          downloadInvoiceReports(reportType, filterStatus)
            .then((res_DIR) => {
              us_setDownloadingReport(false)
            })
            .catch((rej_DIR) => {
              us_setDownloadingReport(false)
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DIR.error })
            })
        }}
      >
        {text}
      </Button>
    )
  }

  const rJSX_DownloadFilterSalesPartnerDropdown = (): JSX.Element => {
    return (
      <FormControl
        className="bp_thin_select_input bp_thin_select_multiple_input"
        sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
      >
        <InputLabel>{rLIB('Sales Partner')}</InputLabel>
        <Select
          autoWidth={true}
          onChange={(event, value) => {
            us_setDownloadFilterSalesPartner(event.target.value)
          }}
          value={us_downloadFilterSalesPartner || ''}
          label={rLIB('Sales Partner')}
        >
          <MenuItem value={'ALL_SALES_PARTNERS'}>{rLIB('All Sales Partners')}</MenuItem>
          {objectToArray(us_salesPartners)
            .sort(dynamicSort('name', 'asc'))
            .map((option: any) => (
              <MenuItem
                key={option.key}
                value={option.key}
              >
                {option.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    )
  }

  const rJSX_DownloadsTab = (): JSX.Element => {
    let tabJSX = (
      <Card className="tw-p-4">
        <Stack direction="row">
          <Typography
            variant="h6"
            className="tw-font-black tw-mb-1"
          >
            {rLIB('Filters')}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          className="tw-mb-4 tw-ml-6"
        >
          {rJSX_DownloadFilterSalesPartnerDropdown()}
          {rJSX_DownloadFilterStartDateSelection()}
          {rJSX_DownloadFilterEndDateSelection()}
        </Stack>
        <Stack direction="row">
          <Typography
            variant="h6"
            className="tw-font-black tw-mr-1"
          >
            {rLIB('Pending')} -
          </Typography>
          <Typography
            variant="h6"
            className="tw-font-light tw-opacity-50"
          >
            {rLIB('Invoices waiting for task completion')}
          </Typography>
          <Chip
            label={rLIB('All')}
            color="secondary"
            variant="outlined"
            className="tw-ml-2 tw-mb-2"
          />
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          className="tw-mb-4 tw-ml-6"
        >
          {rJSX_DownloadReportButton(rLIB('Base'), 'projects', 'pending')}
          {rJSX_DownloadReportButton(rLIB('Additional Work'), 'additional_work', 'pending')}
          {rJSX_DownloadReportButton(rLIB('All Invoices'), 'all', 'pending')}
        </Stack>
        <Stack direction="row">
          <Typography
            variant="h6"
            className="tw-font-black tw-mr-1"
          >
            {rLIB('Approved')} -
          </Typography>
          <Typography
            variant="h6"
            className="tw-font-light tw-opacity-50"
          >
            {rLIB('Invoices waiting for task completion')}
          </Typography>
          <Chip
            label={rLIB('All')}
            color="secondary"
            variant="outlined"
            className="tw-ml-2 tw-mb-2"
          />
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          className="tw-mb-4 tw-ml-6"
        >
          {rJSX_DownloadReportButton(rLIB('Base'), 'projects', 'approved')}
          {rJSX_DownloadReportButton(rLIB('Additional Work'), 'additional_work', 'approved')}
          {rJSX_DownloadReportButton(rLIB('All Invoices'), 'all', 'approved')}
        </Stack>
        <Stack direction="row">
          <Typography
            variant="h6"
            className="tw-font-black tw-mr-1"
          >
            {rLIB('Completed')} -
          </Typography>
          <Typography
            variant="h6"
            className="tw-font-light tw-opacity-50"
          >
            {rLIB('Invoices linked to completed tasks that have not been billed')}
          </Typography>
          <Chip
            label={rLIB('All')}
            color="secondary"
            variant="outlined"
            className="tw-ml-2 tw-mb-2"
          />
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          className="tw-mb-4 tw-ml-6"
        >
          {rJSX_DownloadReportButton(rLIB('Base'), 'projects', 'completed')}
          {rJSX_DownloadReportButton(rLIB('Additional Work'), 'additional_work', 'completed')}
          {rJSX_DownloadReportButton(rLIB('All Invoices'), 'all', 'completed')}
        </Stack>
        <Stack direction="row">
          <Typography
            variant="h6"
            className="tw-font-black tw-mr-1"
          >
            {rLIB('Billed')} -
          </Typography>
          <Typography
            variant="h6"
            className="tw-font-light tw-opacity-50"
          >
            {rLIB('Invoices that have been billed but not paid')}
          </Typography>
          <Chip
            label={rLIB('All')}
            color="secondary"
            variant="outlined"
            className="tw-ml-2 tw-mb-2"
          />
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          className="tw-mb-4 tw-ml-6"
        >
          {rJSX_DownloadReportButton(rLIB('Base'), 'projects', 'billed')}
          {rJSX_DownloadReportButton(rLIB('Additional Work'), 'additional_work', 'billed')}
          {rJSX_DownloadReportButton(rLIB('All Invoices'), 'all', 'billed')}
        </Stack>
        <Stack direction="row">
          <Typography
            variant="h6"
            className="tw-font-black tw-mr-1"
          >
            {rLIB('Paid')} -
          </Typography>
          <Typography
            variant="h6"
            className="tw-font-light tw-opacity-50"
          >
            {rLIB('Invoices that have been paid')}
          </Typography>
          <Chip
            label={rLIB('Date Range')}
            color="warning"
            variant="outlined"
            className="tw-ml-2 tw-mb-2"
          />
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          className="tw-mb-4 tw-ml-6"
        >
          {rJSX_DownloadReportButton(rLIB('Base'), 'projects', 'paid')}
          {rJSX_DownloadReportButton(rLIB('Additional Work'), 'additional_work', 'paid')}
          {rJSX_DownloadReportButton(rLIB('All Invoices'), 'all', 'paid')}
        </Stack>
        <Stack direction="row">
          <Typography
            variant="h6"
            className="tw-font-black tw-mr-1"
          >
            {rLIB('Cancelled')} -
          </Typography>
          <Typography
            variant="h6"
            className="tw-font-light tw-opacity-50"
          >
            {rLIB('Invoices that have been cancelled')}
          </Typography>
          <Chip
            label={rLIB('Date Range')}
            color="warning"
            variant="outlined"
            className="tw-ml-2 tw-mb-2"
          />
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          className="tw-mb-4 tw-ml-6"
        >
          {rJSX_DownloadReportButton(rLIB('Base'), 'projects', 'cancelled')}
          {rJSX_DownloadReportButton(rLIB('Additional Work'), 'additional_work', 'cancelled')}
          {rJSX_DownloadReportButton(rLIB('All Invoices'), 'all', 'cancelled')}
        </Stack>
      </Card>
    )
    return tabJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Invoicing')}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabsSettings={{
                baseUrl: ApplicationPages.AdminInvoiceIndexPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Invoicing', false) as string,
              }}
              tabs={[
                {
                  tabUrlKey: 'Bulk_Invoices',
                  tabOnChange: () => {},
                  tabHeader: rLIB('Bulk Invoice and Quotes'),
                  tabContent: <Box>{rJSX_BulkInvoicesTab()}</Box>,
                  tabButtons: [
                    { fullJSX: rJSX_SettingsButton(), minJSX: rJSX_SettingsButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_ImportInvoicesButton(false), minJSX: rJSX_ImportInvoicesButton(true), sizeCutoff: 0 },
                    { fullJSX: rJSX_ImportCommissionButton(false), minJSX: rJSX_ImportCommissionButton(true), sizeCutoff: 0 },
                  ],
                },
                {
                  tabUrlKey: 'missing',
                  tabOnChange: () => {},
                  tabHeader: rLIB('New Projects'),
                  tabContent: rJSX_ProjectsMissingBaseInvoiceTab(),
                  tabButtons: [
                    { fullJSX: rJSX_SettingsButton(), minJSX: rJSX_SettingsButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_InvoiceTypeDropdown(), minJSX: rJSX_InvoiceTypeDropdown(), sizeCutoff: 0 },
                    { fullJSX: rJSX_ProjectWorkflowTypeDropdown(), minJSX: rJSX_ProjectWorkflowTypeDropdown(), sizeCutoff: 0 },
                  ],
                },
                {
                  tabUrlKey: 'pending',
                  tabOnChange: () => {},
                  tabHeader: rLIB('Pending'),
                  tabContent: rJSX_InvoicesPendingApprovalTab(),
                  tabButtons: [
                    { fullJSX: rJSX_SettingsButton(), minJSX: rJSX_SettingsButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_InvoiceTypeDropdown(), minJSX: rJSX_InvoiceTypeDropdown(), sizeCutoff: 0 },
                    { fullJSX: rJSX_InvoiceWorkflowTypeDropdown(), minJSX: rJSX_InvoiceWorkflowTypeDropdown(), sizeCutoff: 0 },
                  ],
                },
                {
                  tabUrlKey: 'approved',
                  tabOnChange: () => {},
                  tabHeader: rLIB('Approved'),
                  tabContent: rJSX_ApprovedInvoicesTab(),
                  tabButtons: [
                    { fullJSX: rJSX_SettingsButton(), minJSX: rJSX_SettingsButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_InvoiceTypeDropdown(), minJSX: rJSX_InvoiceTypeDropdown(), sizeCutoff: 0 },
                    { fullJSX: rJSX_InvoiceWorkflowTypeDropdown(), minJSX: rJSX_InvoiceWorkflowTypeDropdown(), sizeCutoff: 0 },
                  ],
                },
                {
                  tabUrlKey: 'completed',
                  tabOnChange: () => {},
                  tabHeader: rLIB('Completed'),
                  tabContent: rJSX_CompletedInvoicesTab(),
                  tabButtons: [
                    { fullJSX: rJSX_SettingsButton(), minJSX: rJSX_SettingsButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_InvoiceTypeDropdown(), minJSX: rJSX_InvoiceTypeDropdown(), sizeCutoff: 0 },
                    { fullJSX: rJSX_InvoiceWorkflowTypeDropdown(), minJSX: rJSX_InvoiceWorkflowTypeDropdown(), sizeCutoff: 0 },
                  ],
                },
                {
                  tabUrlKey: 'billed',
                  tabOnChange: () => {},
                  tabHeader: rLIB('Billed'),
                  tabContent: rJSX_BilledInvoicesTab(),
                  tabButtons: [
                    { fullJSX: rJSX_SettingsButton(), minJSX: rJSX_SettingsButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_InvoiceTypeDropdown(), minJSX: rJSX_InvoiceTypeDropdown(), sizeCutoff: 0 },
                    { fullJSX: rJSX_InvoiceWorkflowTypeDropdown(), minJSX: rJSX_InvoiceWorkflowTypeDropdown(), sizeCutoff: 0 },
                    { fullJSX: rJSX_ImportBaseInvoiceBillingButton(), minJSX: rJSX_ImportBaseInvoiceBillingButton(), sizeCutoff: 0 },
                  ],
                },
                {
                  tabUrlKey: 'paid',
                  tabOnChange: () => {},
                  tabHeader: rLIB('Paid'),
                  tabContent: rJSX_PaidInvoicesTab(),
                  tabButtons: [
                    { fullJSX: rJSX_SettingsButton(), minJSX: rJSX_SettingsButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_InvoiceTypeDropdown(), minJSX: rJSX_InvoiceTypeDropdown(), sizeCutoff: 0 },
                    { fullJSX: rJSX_InvoiceWorkflowTypeDropdown(), minJSX: rJSX_InvoiceWorkflowTypeDropdown(), sizeCutoff: 0 },
                  ],
                },
                {
                  tabUrlKey: 'cancelled',
                  tabOnChange: () => {},
                  tabHeader: rLIB('Cancelled'),
                  tabContent: rJSX_CancelledInvoicesTab(),
                  tabButtons: [
                    { fullJSX: rJSX_SettingsButton(), minJSX: rJSX_SettingsButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_InvoiceTypeDropdown(), minJSX: rJSX_InvoiceTypeDropdown(), sizeCutoff: 0 },
                    { fullJSX: rJSX_InvoiceWorkflowTypeDropdown(), minJSX: rJSX_InvoiceWorkflowTypeDropdown(), sizeCutoff: 0 },
                  ],
                },
                {
                  tabUrlKey: 'downloads',
                  tabOnChange: () => {},
                  tabHeader: rLIB('Downloads'),
                  tabContent: rJSX_DownloadsTab(),
                  tabButtons: [
                    { fullJSX: rJSX_SettingsButton(), minJSX: rJSX_SettingsButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_InvoiceTypeDropdown(), minJSX: rJSX_InvoiceTypeDropdown(), sizeCutoff: 0 },
                  ],
                },
                // {
                //   tabUrlKey: 'PDF2',
                //   tabOnChange: () => {},
                //   tabHeader: 'PDF2',
                //   tabContent: <Box>{rJSX_Pdf2Tab()}</Box>,
                //   tabButtons: [{ fullJSX: rJSX_SettingsButton(), minJSX: rJSX_SettingsButton(), sizeCutoff: 0 }],
                // },
              ]}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
