///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Avatar, Box, Button, Divider, FormControl, Stack, TextField, Typography } from '@mui/material'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import ReactTimeAgo from 'react-time-ago'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatabaseRef_ProjectNoteThreadNotes_Document, DatabaseRef_ProjectNoteThreads_Document } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { stringAvatar, stringToColor } from 'rfbp_core/components/chat'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseBatchUpdate, DatabaseSetMergeDocument, TsInterface_DatabaseBatchUpdatesArray } from 'rfbp_core/services/database_management'
import {
  dynamicSort,
  generateRandomString,
  getProp,
  objectToArray,
  returnDateFromUnknownDateFormat,
  returnFormattedDate,
} from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Forms
const noteFilterOptions: TsInterface_UnspecifiedObject = {
  all: {
    key: 'all',
    value: rLIB('All Notes'),
  },
  deleted: {
    key: 'deleted',
    value: rLIB('Archived Notes'),
  },
  not_deleted: {
    key: 'not_deleted',
    value: rLIB('Hide Archived Notes'),
  },
  starred: {
    key: 'starred',
    value: rLIB('Starred Notes'),
  },
}

const formInputs_NoteTableFilter: TsInterface_FormInputs = {
  table_filter: {
    key: 'table_filter',
    label: rLIB('Table Filter'),
    input_type: 'multiple_choice_radio',
    required: true,
    data_type: 'string',
    options: objectToArray(noteFilterOptions),
  },
}

const noteSortOptions: TsInterface_UnspecifiedObject = {
  timestamp_created_asc: { key: 'timestamp_created_asc', value: rLIB('Oldest') },
  timestamp_created_desc: { key: 'timestamp_created_desc', value: rLIB('Newest') },
  timestamp_last_updated_asc: { key: 'timestamp_last_updated_asc', value: rLIB('Oldest Update') },
  timestamp_last_updated_desc: { key: 'timestamp_last_updated_desc', value: rLIB('Most Recent Update') },
}

const formInputs_NoteSort: TsInterface_FormInputs = {
  sort_by: {
    key: 'sort_by',
    label: rLIB('Sort By'),
    input_type: 'multiple_choice_radio',
    required: true,
    data_type: 'string',
    options: objectToArray(noteSortOptions),
  },
}

///////////////////////////////
// Functions
///////////////////////////////

function truncateString(str: string, num: number) {
  if (str.length <= num) {
    return str
  } else {
    return str.slice(0, num) + '...'
  }
}

function removeMarkupFromRichText(content: any) {
  const tempElement = document.createElement('div')
  tempElement.innerHTML = content
  return tempElement.textContent || tempElement.innerText
}

const openNoteSortSelectionDialog = (noteSortOrder: any, uc_setUserInterface_FormDialogDisplay: any, setNoteSetOrder: any): void => {
  let formData = {
    sort_by: noteSortOrder,
  }
  uc_setUserInterface_FormDialogDisplay({
    display: true,
    form: {
      form: {
        formAdditionalData: {},
        formData: formData,
        formInputs: formInputs_NoteSort,
        formOnChange: (
          formAdditionalData: TsInterface_FormAdditionalData,
          formData: TsInterface_FormData,
          formInputs: TsInterface_FormInputs,
          formSettings: TsInterface_FormSettings,
        ) => {},
        formSettings: {},
        formSubmission: (
          formSubmittedData: TsInterface_FormSubmittedData,
          formAdditionalData: TsInterface_FormAdditionalData,
          formHooks: TsInterface_FormHooksObject,
        ) => {
          return new Promise((resolve, reject) => {
            setNoteSetOrder(formSubmittedData.sort_by)
            resolve({ success: true })
          })
        },
      },
      dialog: {
        formDialogHeaderColor: 'success',
        formDialogHeaderText: rLIB('Note sort order'),
        formDialogIcon: (
          <Icon
            type="solid"
            icon="pen-to-square"
          />
        ),
      },
    },
  })
}

const saveNewProjectNote = (
  noteContent: string,
  uc_RootData_ClientUser: any,
  noteSubjectValue: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  projectKey: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  setNoteRichContentEditorValue: any,
  setNoteSubjectValue: any,
  setNewNoteEditorVisibility: any,
): void => {
  let timestampEvent = new Date()
  let noteThreadKey = timestampEvent.getTime().toString() + '_' + generateRandomString(6, null)
  let noteKey = timestampEvent.getTime().toString() + '_' + generateRandomString(6, null)
  let noteThreadUpdateObject = {
    associated_creator_user_key: getProp(uc_RootData_ClientUser, 'key', null),
    associated_creator_user_name: getProp(uc_RootData_ClientUser, 'name', null),
    associated_last_note_user_key: getProp(uc_RootData_ClientUser, 'key', null),
    associated_last_note_user_name: getProp(uc_RootData_ClientUser, 'name', null),
    thread_name: noteSubjectValue,
    timestamp_created: timestampEvent,
    timestamp_last_updated: timestampEvent,
    total_notes: 1,
    key: noteThreadKey,
    last_note: noteContent,
  }
  let noteUpdateObject = {
    timestamp_created: timestampEvent,
    associated_user_key: getProp(uc_RootData_ClientUser, 'key', null),
    associated_user_name: getProp(uc_RootData_ClientUser, 'name', null),
    note: noteContent,
    key: noteKey,
  }
  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    .then((res_GCK) => {
      let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
        { type: 'setMerge', ref: DatabaseRef_ProjectNoteThreads_Document(res_GCK.clientKey, projectKey, noteThreadKey), data: noteThreadUpdateObject },
        { type: 'setMerge', ref: DatabaseRef_ProjectNoteThreadNotes_Document(res_GCK.clientKey, projectKey, noteThreadKey, noteKey), data: noteUpdateObject },
      ]
      DatabaseBatchUpdate(updateArray)
        .then((res_DBU) => {
          closeNewNoteEditor(setNoteRichContentEditorValue, setNoteSubjectValue, setNewNoteEditorVisibility)
        })
        .catch((rej_DBU) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
        })
    })
    .catch((rej_GCK) => {
      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
    })
}

const saveAdditionalProjectNote = (
  noteThreadKey: string,
  noteContent: string,
  notesCount: number,
  uc_RootData_ClientUser: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  projectKey: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  setNoteReplyRichContentEditorValue: any,
  setReplyEditorVisibility: any,
): void => {
  let timestampEvent = new Date()
  let noteKey = timestampEvent.getTime().toString() + '_' + generateRandomString(6, null)
  let noteThreadUpdateObject = {
    associated_last_note_user_key: getProp(uc_RootData_ClientUser, 'key', null),
    associated_last_note_user_name: getProp(uc_RootData_ClientUser, 'name', null),
    timestamp_last_updated: timestampEvent,
    total_notes: notesCount + 1,
    last_note: noteContent,
  }
  let noteUpdateObject = {
    timestamp_created: timestampEvent,
    associated_user_key: getProp(uc_RootData_ClientUser, 'key', null),
    associated_user_name: getProp(uc_RootData_ClientUser, 'name', null),
    note: noteContent,
    key: noteKey,
  }
  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    .then((res_GCK) => {
      let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
        { type: 'setMerge', ref: DatabaseRef_ProjectNoteThreads_Document(res_GCK.clientKey, projectKey, noteThreadKey), data: noteThreadUpdateObject },
        { type: 'setMerge', ref: DatabaseRef_ProjectNoteThreadNotes_Document(res_GCK.clientKey, projectKey, noteThreadKey, noteKey), data: noteUpdateObject },
      ]
      DatabaseBatchUpdate(updateArray)
        .then((res_DBU) => {
          closeNoteReplyEditor(setNoteReplyRichContentEditorValue, setReplyEditorVisibility)
        })
        .catch((rej_DBU) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
        })
    })
    .catch((rej_GCK) => {
      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
    })
}

const openNewNoteEditor = (setNoteRichContentEditorValue: any, setNoteSubjectValue: any, setNewNoteEditorVisibility: any): void => {
  setNoteRichContentEditorValue('')
  setNoteSubjectValue('')
  setNewNoteEditorVisibility(true)
}

const openNoteReplyEditor = (setNoteReplyRichContentEditorValue: any, setReplyEditorVisibility: any): void => {
  setNoteReplyRichContentEditorValue('')
  setReplyEditorVisibility(true)
}

const closeNewNoteEditor = (setNoteRichContentEditorValue: any, setNoteSubjectValue: any, setNewNoteEditorVisibility: any): void => {
  setNoteRichContentEditorValue('')
  setNoteSubjectValue('')
  setNewNoteEditorVisibility(false)
}

const closeNoteReplyEditor = (setNoteReplyRichContentEditorValue: any, setReplyEditorVisibility: any): void => {
  setNoteReplyRichContentEditorValue('')
  setReplyEditorVisibility(false)
}

const returnFilteredNotes = (noteTableFilter: any, projectNoteThreads: any, noteSortOrder: any): TsInterface_UnspecifiedObject[] => {
  let filteredNotes: TsInterface_UnspecifiedObject[] = []
  // Filter
  if (noteTableFilter === 'all') {
    filteredNotes = objectToArray(projectNoteThreads)
  } else if (noteTableFilter === 'deleted') {
    for (let loopNoteKey in projectNoteThreads) {
      let loopNote = projectNoteThreads[loopNoteKey]
      if (loopNote['status'] === 'deleted') {
        filteredNotes.push(loopNote)
      }
    }
  } else if (noteTableFilter === 'not_deleted') {
    for (let loopNoteKey in projectNoteThreads) {
      let loopNote = projectNoteThreads[loopNoteKey]
      if (loopNote['status'] !== 'deleted') {
        filteredNotes.push(loopNote)
      }
    }
  } else if (noteTableFilter === 'starred') {
    for (let loopNoteKey in projectNoteThreads) {
      let loopNote = projectNoteThreads[loopNoteKey]
      if (loopNote['status'] !== 'deleted' && loopNote['starred'] === true) {
        filteredNotes.push(loopNote)
      }
    }
  } else {
    filteredNotes = objectToArray(projectNoteThreads)
  }
  // Sort
  if (noteSortOrder === 'timestamp_created_asc') {
    filteredNotes.sort(dynamicSort('timestamp_created', 'asc'))
  } else if (noteSortOrder === 'timestamp_created_desc') {
    filteredNotes.sort(dynamicSort('timestamp_created', 'desc'))
  } else if (noteSortOrder === 'timestamp_last_updated_asc') {
    filteredNotes.sort(dynamicSort('timestamp_last_updated', 'asc'))
  } else if (noteSortOrder === 'timestamp_last_updated_desc') {
    filteredNotes.sort(dynamicSort('timestamp_last_updated', 'desc'))
  }
  // Return
  return filteredNotes
}

///////////////////////////////
// JSX
///////////////////////////////

const rJSX_NoteSortButtonLabel = (noteSortOrder: any): JSX.Element => {
  let labelJSX = <></>
  if (noteSortOrder != null && noteSortOptions != null && noteSortOptions[noteSortOrder] != null && noteSortOptions[noteSortOrder].value != null) {
    labelJSX = <>{noteSortOptions[noteSortOrder].value}</>
  } else {
    labelJSX = <>{rLIB('Sort Order')}</>
  }
  return labelJSX
}

const rJSX_ThreadStar = (
  readOrWrite: 'read' | 'write',
  noteThread: TsInterface_UnspecifiedObject,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  projectKey: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
): JSX.Element => {
  let starJSX = <></>
  if (noteThread != null && noteThread['status'] === 'deleted') {
    starJSX = (
      <Icon
        icon="trash"
        className="tw-inline-block tw-opacity-30 tw-mr-2"
      />
    )
  } else if (noteThread.starred === true) {
    starJSX = (
      <Box
        className="tw-inline-block tw-cursor-pointer"
        sx={{ color: themeVariables.warning_main }}
        onClick={(event) => {
          event.stopPropagation()
          if (readOrWrite === 'write') {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                DatabaseSetMergeDocument(DatabaseRef_ProjectNoteThreads_Document(res_GCK.clientKey, projectKey, noteThread.key), { starred: false })
              })
              .catch((rej_GCK) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
              })
          }
        }}
      >
        <Icon
          icon="star"
          className="tw-mr-1"
        />
      </Box>
    )
  } else {
    starJSX = (
      <Box
        className="tw-inline-block tw-opacity-30 tw-cursor-pointer"
        onClick={(event) => {
          event.stopPropagation()
          if (readOrWrite === 'write') {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                DatabaseSetMergeDocument(DatabaseRef_ProjectNoteThreads_Document(res_GCK.clientKey, projectKey, noteThread.key), { starred: true })
              })
              .catch((rej_GCK) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
              })
          }
        }}
      >
        <Icon
          icon="star"
          className="tw-mr-1"
          type="thin"
        />
      </Box>
    )
  }
  return starJSX
}

const rJSX_NewNoteEditor = (
  readOrWrite: 'read' | 'write',
  newNoteEditorVisibility: any,
  noteSubjectValue: any,
  noteRichContentEditorValue: any,
  uc_RootData_ClientUser: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  projectKey: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  setNoteSubjectValue: any,
  setNoteRichContentEditorValue: any,
  uc_setUserInterface_FormDialogDisplay: any,
  noteTableFilter: any,
  setNoteTableFilter: any,
  noteSortOrder: any,
  setNoteSetOrder: any,
  setNewNoteEditorVisibility: any,
): JSX.Element => {
  let newNoteEditor = <></>
  if (newNoteEditorVisibility === true) {
    newNoteEditor = (
      <Box>
        <Stack
          direction="row"
          spacing={1}
          className="tw-mb-2"
        >
          <Button
            className=""
            color="info"
            variant="contained"
            startIcon={<Icon icon="floppy-disk"></Icon>}
            disabled={noteSubjectValue == null || noteSubjectValue === '' || noteRichContentEditorValue == null || noteRichContentEditorValue === ''}
            onClick={() => {
              saveNewProjectNote(
                noteRichContentEditorValue,
                uc_RootData_ClientUser,
                noteSubjectValue,
                uc_RootData_ClientKey,
                uc_setRootData_ClientKey,
                projectKey,
                uc_setUserInterface_ErrorDialogDisplay,
                setNoteRichContentEditorValue,
                setNoteSubjectValue,
                setNewNoteEditorVisibility,
              )
            }}
          >
            {rLIB('Save Note')}
          </Button>
          <Button
            color="error"
            variant="contained"
            startIcon={<Icon icon="circle-xmark"></Icon>}
            onClick={() => {
              uc_setUserInterface_ConfirmDialogDisplay({
                display: true,
                confirm: {
                  color: 'error',
                  header: rLIB('Close note editor without saving'),
                  icon: (
                    <Icon
                      icon="trash"
                      type="solid"
                    />
                  ),
                  submit_text: rLIB('Close'),
                  text: (
                    <>
                      {rLIB('Are you sure that you want to close this note without saving it?')} {rLIB("Anything you've typed will be lost.")}
                    </>
                  ),
                  submit_callback: () => {
                    return new Promise((resolve, reject) => {
                      closeNewNoteEditor(setNoteRichContentEditorValue, setNoteSubjectValue, setNewNoteEditorVisibility)
                      resolve({ success: true })
                    })
                  },
                },
              })
            }}
          >
            {rLIB('Cancel Note')}
          </Button>
        </Stack>
        <Box>
          <Box>
            <FormControl fullWidth>
              <TextField
                color="primary"
                value={noteSubjectValue}
                label={rLIB('Note Subject')}
                margin="normal"
                onChange={(event: any) => {
                  if (event != null && event.target != null && event.target.value != null) {
                    setNoteSubjectValue(event.target.value)
                  }
                }}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Box>
          <Box className="tw-mb-2">
            <ReactQuill
              theme="snow"
              value={noteRichContentEditorValue}
              onChange={setNoteRichContentEditorValue}
            />
          </Box>
        </Box>
      </Box>
    )
  } else {
    newNoteEditor = (
      <Box>
        <Stack
          direction="row"
          spacing={1}
          className="tw-mb-2"
        >
          <Button
            color="success"
            variant="contained"
            disabled={readOrWrite === 'read'}
            startIcon={<Icon icon="circle-plus"></Icon>}
            onClick={() => {
              openNewNoteEditor(setNoteRichContentEditorValue, setNoteSubjectValue, setNewNoteEditorVisibility)
            }}
          >
            {rLIB('Create a new note')}
          </Button>
          <Button
            className="tw-m-auto tw-opacity-30 tw-ml-2"
            color="inherit"
            variant="outlined"
            startIcon={
              <Icon
                icon="filter"
                type="light"
              ></Icon>
            }
            onClick={() => {
              uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {},
                    formData: {
                      table_filter: noteTableFilter,
                    },
                    formInputs: formInputs_NoteTableFilter,
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {},
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        setNoteTableFilter(formSubmittedData.table_filter)
                        resolve({ success: true })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: rLIB('Select a Filter'),
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="filter"
                      />
                    ),
                  },
                },
              })
            }}
          >
            {noteFilterOptions[noteTableFilter]['value']}
          </Button>
          <Button
            color="inherit"
            variant="outlined"
            startIcon={<Icon icon="sort"></Icon>}
            onClick={() => {
              openNoteSortSelectionDialog(noteSortOrder, uc_setUserInterface_FormDialogDisplay, setNoteSetOrder)
            }}
          >
            {rJSX_NoteSortButtonLabel(noteSortOrder)}
          </Button>
        </Stack>
      </Box>
    )
  }
  return newNoteEditor
}

const rJSX_NoteCountLabel = (countNumber: number, lastNote: string): JSX.Element => {
  let noteCountJSX = <></>
  let notePreviewJSX = <></>
  if (lastNote != null && lastNote !== '') {
    let strippedLastNote = removeMarkupFromRichText(lastNote)
    notePreviewJSX = <>- {truncateString(strippedLastNote, 50)}</>
  }
  if (countNumber === 1) {
    noteCountJSX = rLIB('Note') as JSX.Element
  } else {
    noteCountJSX = rLIB('Notes') as JSX.Element
  }
  return (
    <>
      {noteCountJSX} {notePreviewJSX}
    </>
  )
}

const rJSX_IndividualNote = (note: TsInterface_UnspecifiedObject): JSX.Element => {
  let noteJSX = (
    <Box sx={{ background: themeVariables.background_default }}>
      <Box className="tw-p-2">
        <Stack
          direction="row"
          spacing={1}
        >
          <Box>
            <Avatar
              key={getProp(note, 'associated_user_name', '')}
              {...stringAvatar(getProp(note, 'associated_user_name', ''))}
              sx={{ bgcolor: stringToColor(getProp(note, 'associated_user_name', '')) }}
            />
          </Box>
          <Box>
            <Stack
              className="tw-justify-between"
              direction="row"
              spacing={1}
            >
              <Box>{getProp(note, 'associated_user_name', '')}</Box>
              <Typography
                variant="body1"
                className="tw-opacity-50"
              >
                {returnFormattedDate(note.timestamp_created.toDate(), 'D MMM YYYY - h:mm a')}
              </Typography>
            </Stack>
            <Box className="tw-px-1">
              <Box
                className="ql-scroll-html-render"
                dangerouslySetInnerHTML={{ __html: note.note }}
              />
            </Box>
          </Box>
        </Stack>
      </Box>
      <Divider />
    </Box>
  )
  return noteJSX
}

const rJSX_ThreadTimestamp = (noteThread: TsInterface_UnspecifiedObject, noteSortOrder: any): JSX.Element => {
  let threadTimestampJSX = <></>
  if (noteSortOrder === 'timestamp_created_asc' || noteSortOrder === 'timestamp_created_desc') {
    threadTimestampJSX = (
      <Typography
        variant="body1"
        className="tw-opacity-80"
      >
        {returnFormattedDate(noteThread.timestamp_created.toDate(), 'D MMM YYYY - h:mm a ')}({rLIB('created')})
      </Typography>
    )
  } else if (noteSortOrder === 'timestamp_last_updated_asc' || noteSortOrder === 'timestamp_last_updated_desc') {
    threadTimestampJSX = (
      <Typography
        variant="body1"
        className="tw-opacity-80"
      >
        {returnFormattedDate(noteThread.timestamp_last_updated.toDate(), 'D MMM YYYY - h:mm a ')}({rLIB('updated')})
      </Typography>
    )
  }
  return threadTimestampJSX
}

const rJSX_ThreadTimeAgo = (noteThread: TsInterface_UnspecifiedObject, noteSortOrder: any): JSX.Element => {
  let timeAgoJSX = <></>
  if (noteSortOrder === 'timestamp_created_asc' || noteSortOrder === 'timestamp_created_desc') {
    timeAgoJSX = (
      <Typography
        variant="body1"
        className="tw-opacity-50"
      >
        <ReactTimeAgo date={returnDateFromUnknownDateFormat(noteThread.timestamp_created)} />
      </Typography>
    )
  } else if (noteSortOrder === 'timestamp_last_updated_asc' || noteSortOrder === 'timestamp_last_updated_desc') {
    timeAgoJSX = (
      <Typography
        variant="body1"
        className="tw-opacity-50"
      >
        <ReactTimeAgo date={returnDateFromUnknownDateFormat(noteThread.timestamp_last_updated)} />
      </Typography>
    )
  }
  return timeAgoJSX
}

const rJSX_ArchiveThreadButton = (
  readOrWrite: 'read' | 'write',
  noteThread: TsInterface_UnspecifiedObject,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  projectKey: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  setSelectedNoteThreadKey: any,
) => {
  let buttonJSX = <></>
  if (noteThread != null && noteThread['status'] === 'deleted') {
    buttonJSX = (
      <Button
        className=""
        color="secondary"
        variant="outlined"
        disabled={readOrWrite === 'read'}
        startIcon={<Icon icon="wand-magic-sparkles"></Icon>}
        onClick={() => {
          uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'secondary',
              header: rLIB('Unarchive Note'),
              icon: (
                <Icon
                  icon="wand-magic-sparkles"
                  type="solid"
                />
              ),
              submit_text: rLIB('Unarchive'),
              text: <>{rLIB('Are you sure that you want to unarchive this note')}?</>,
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseSetMergeDocument(DatabaseRef_ProjectNoteThreads_Document(res_GCK.clientKey, projectKey, noteThread.key), { status: 'active' })
                        .then((res_DSMD) => {
                          resolve(res_DSMD)
                        })
                        .catch((rej_DSMD) => {
                          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                          resolve({ success: false })
                        })
                    })
                    .catch((rej_GCK) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      resolve({ success: false })
                    })
                })
              },
            },
          })
        }}
      >
        {rLIB('Unarchive')}
      </Button>
    )
  } else {
    buttonJSX = (
      <Button
        className=""
        color="error"
        variant="outlined"
        disabled={readOrWrite === 'read'}
        startIcon={<Icon icon="box-archive"></Icon>}
        onClick={() => {
          uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              header: rLIB('Archive Note'),
              icon: (
                <Icon
                  icon="box-archive"
                  type="solid"
                />
              ),
              submit_text: rLIB('Archive'),
              text: (
                <>
                  {rLIB('Are you sure that you want to archive this note')}? {rLIB('This will hide it from the default list')}
                </>
              ),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseSetMergeDocument(DatabaseRef_ProjectNoteThreads_Document(res_GCK.clientKey, projectKey, noteThread.key), { status: 'deleted' })
                        .then((res_DSMD) => {
                          setSelectedNoteThreadKey(null)
                          resolve(res_DSMD)
                        })
                        .catch((rej_DSMD) => {
                          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                          resolve({ success: false })
                        })
                    })
                    .catch((rej_GCK) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      resolve({ success: false })
                    })
                })
              },
            },
          })
        }}
      >
        {rLIB('Archive')}
      </Button>
    )
  }
  return buttonJSX
}

const rJSX_NoteThreadReply = (
  readOrWrite: 'read' | 'write',
  noteThread: TsInterface_UnspecifiedObject,
  replyEditorVisibility: any,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  projectKey: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  setSelectedNoteThreadKey: any,
  noteReplyRichContentEditorValue: any,
  setNoteReplyRichContentEditorValue: any,
  uc_RootData_ClientUser: any,
  setReplyEditorVisibility: any,
): JSX.Element => {
  let noteThreadReplyJSX = <></>
  if (replyEditorVisibility === false) {
    noteThreadReplyJSX = (
      <Stack
        className="tw-justify-between tw-p-2"
        direction="row"
        spacing={1}
      >
        {rJSX_ArchiveThreadButton(
          readOrWrite,
          noteThread,
          uc_setUserInterface_ConfirmDialogDisplay,
          uc_RootData_ClientKey,
          uc_setRootData_ClientKey,
          projectKey,
          uc_setUserInterface_ErrorDialogDisplay,
          setSelectedNoteThreadKey,
        )}
        <Button
          className=""
          color="info"
          variant="contained"
          startIcon={<Icon icon="reply"></Icon>}
          disabled={readOrWrite === 'read'}
          onClick={() => {
            openNoteReplyEditor(setNoteReplyRichContentEditorValue, setReplyEditorVisibility)
          }}
        >
          {rLIB('Reply')}
        </Button>
      </Stack>
    )
  } else {
    noteThreadReplyJSX = (
      <Box>
        <Box sx={{ background: themeVariables.background_default }}>
          <ReactQuill
            theme="snow"
            value={noteReplyRichContentEditorValue}
            onChange={setNoteReplyRichContentEditorValue}
          />
        </Box>
        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={1}
          className="tw-m-2"
        >
          <Button
            className=""
            color="info"
            variant="contained"
            startIcon={<Icon icon="floppy-disk"></Icon>}
            disabled={noteReplyRichContentEditorValue == null || noteReplyRichContentEditorValue === ''}
            onClick={() => {
              saveAdditionalProjectNote(
                noteThread.key,
                noteReplyRichContentEditorValue,
                getProp(noteThread, 'total_notes', 1),
                uc_RootData_ClientUser,
                uc_RootData_ClientKey,
                uc_setRootData_ClientKey,
                projectKey,
                uc_setUserInterface_ErrorDialogDisplay,
                setNoteReplyRichContentEditorValue,
                setReplyEditorVisibility,
              )
            }}
          >
            {rLIB('Save Reply')}
          </Button>
          <Button
            color="error"
            variant="contained"
            startIcon={<Icon icon="circle-xmark"></Icon>}
            onClick={() => {
              uc_setUserInterface_ConfirmDialogDisplay({
                display: true,
                confirm: {
                  color: 'error',
                  header: rLIB('Close note editor without saving'),
                  icon: (
                    <Icon
                      icon="trash"
                      type="solid"
                    />
                  ),
                  submit_text: rLIB('Close'),
                  text: (
                    <>
                      {rLIB('Are you sure that you want to close this note without saving it?')} {rLIB("Anything you've typed will be lost.")}
                    </>
                  ),
                  submit_callback: () => {
                    return new Promise((resolve, reject) => {
                      closeNoteReplyEditor(setNoteReplyRichContentEditorValue, setReplyEditorVisibility)
                      resolve({ success: true })
                    })
                  },
                },
              })
            }}
          >
            {rLIB('Cancel Reply')}
          </Button>
        </Stack>
      </Box>
    )
  }
  return noteThreadReplyJSX
}

const rJSX_NoteThreadHeader = (noteThread: TsInterface_UnspecifiedObject): JSX.Element => {
  let headerJSX = <></>
  if (noteThread != null && noteThread['status'] === 'deleted') {
    headerJSX = <Box className="tw-inline-block tw-line-through tw-opacity-40">{noteThread.thread_name}</Box>
  } else {
    headerJSX = <Box className="tw-inline-block">{noteThread.thread_name}</Box>
  }
  return headerJSX
}

const rJSX_NoteThread = (
  readOrWrite: 'read' | 'write',
  noteKey: string,
  noteThread: TsInterface_UnspecifiedObject,
  selectedNoteThreadKey: any,
  setSelectedNoteThreadKey: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  projectKey: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  noteSortOrder: any,
  selectedThreadNotes: any,
  replyEditorVisibility: any,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  noteReplyRichContentEditorValue: any,
  setNoteReplyRichContentEditorValue: any,
  uc_RootData_ClientUser: any,
  setReplyEditorVisibility: any,
): JSX.Element => {
  let noteThreadJSX = <></>
  if (noteKey === selectedNoteThreadKey) {
    noteThreadJSX = (
      <Box
        className="tw-my-2"
        sx={{ background: themeVariables.background_paper, border: '1px solid ' + themeVariables.gray_700 }}
      >
        <Box>
          <Box
            className="tw-px-2 tw-py-1 tw-cursor-pointer"
            sx={{ borderBottom: '1px solid ' + themeVariables.gray_700 }}
            onClick={() => {
              setSelectedNoteThreadKey(null)
            }}
          >
            <Stack
              className="tw-justify-between"
              direction="row"
              spacing={1}
            >
              <Typography
                variant="h6"
                className=""
              >
                {rJSX_ThreadStar(readOrWrite, noteThread, uc_RootData_ClientKey, uc_setRootData_ClientKey, projectKey, uc_setUserInterface_ErrorDialogDisplay)}
                {rJSX_NoteThreadHeader(noteThread)}
              </Typography>
              {rJSX_ThreadTimestamp(noteThread, noteSortOrder)}
            </Stack>
            <Stack
              className="tw-justify-between"
              direction="row"
              spacing={1}
            >
              <Typography
                variant="body1"
                className="tw-ml-7 tw-opacity-30"
              >
                {noteThread.total_notes} {rJSX_NoteCountLabel(noteThread.total_notes, noteThread.last_note)}
              </Typography>
              {rJSX_ThreadTimeAgo(noteThread, noteSortOrder)}
            </Stack>
          </Box>
          <Box>
            {objectToArray(selectedThreadNotes)
              .sort(dynamicSort('timestamp_created', 'asc'))
              .map((note, noteIndex) => (
                <Box key={noteIndex}>{rJSX_IndividualNote(note)}</Box>
              ))}
            <Divider />
            <Box>
              {rJSX_NoteThreadReply(
                readOrWrite,
                noteThread,
                replyEditorVisibility,
                uc_setUserInterface_ConfirmDialogDisplay,
                uc_RootData_ClientKey,
                uc_setRootData_ClientKey,
                projectKey,
                uc_setUserInterface_ErrorDialogDisplay,
                setSelectedNoteThreadKey,
                noteReplyRichContentEditorValue,
                setNoteReplyRichContentEditorValue,
                uc_RootData_ClientUser,
                setReplyEditorVisibility,
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    )
  } else {
    noteThreadJSX = (
      <Box
        sx={{ background: themeVariables.background_paper, border: '1px solid ' + themeVariables.gray_700 }}
        onClick={() => {
          setSelectedNoteThreadKey(noteKey)
        }}
      >
        <Box className="tw-cursor-pointer tw-px-2 tw-py-1">
          <Stack
            className="tw-justify-between"
            direction="row"
            spacing={1}
          >
            <Typography
              variant="h6"
              className=""
            >
              {rJSX_ThreadStar(readOrWrite, noteThread, uc_RootData_ClientKey, uc_setRootData_ClientKey, projectKey, uc_setUserInterface_ErrorDialogDisplay)}
              {rJSX_NoteThreadHeader(noteThread)}
            </Typography>
            {rJSX_ThreadTimestamp(noteThread, noteSortOrder)}
          </Stack>
          <Stack
            className="tw-justify-between"
            direction="row"
            spacing={1}
          >
            <Typography
              variant="body1"
              className="tw-ml-7 tw-opacity-30"
            >
              {noteThread.total_notes} {rJSX_NoteCountLabel(noteThread.total_notes, noteThread.last_note)}
            </Typography>
            {rJSX_ThreadTimeAgo(noteThread, noteSortOrder)}
          </Stack>
        </Box>
      </Box>
    )
  }
  return noteThreadJSX
}

const rJSX_NotesTable = (
  readOrWrite: 'read' | 'write',
  noteTableFilter: any,
  projectNoteThreads: any,
  noteSortOrder: any,
  selectedNoteThreadKey: any,
  setSelectedNoteThreadKey: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  projectKey: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  selectedThreadNotes: any,
  replyEditorVisibility: any,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  noteReplyRichContentEditorValue: any,
  setNoteReplyRichContentEditorValue: any,
  uc_RootData_ClientUser: any,
  setReplyEditorVisibility: any,
): JSX.Element => {
  let tableJSX = <></>
  if (returnFilteredNotes(noteTableFilter, projectNoteThreads, noteSortOrder).length === 0) {
    tableJSX = (
      <Box className="tw-text-center tw-p-4">
        <Typography variant="h6">{rLIB('No notes on this project yet')}</Typography>
      </Box>
    )
  } else {
    tableJSX = (
      <Box>
        {returnFilteredNotes(noteTableFilter, projectNoteThreads, noteSortOrder).map((noteThread, noteThreadIndex) => (
          <Box key={noteThreadIndex}>
            {rJSX_NoteThread(
              readOrWrite,
              noteThread.key,
              noteThread,
              selectedNoteThreadKey,
              setSelectedNoteThreadKey,
              uc_RootData_ClientKey,
              uc_setRootData_ClientKey,
              projectKey,
              uc_setUserInterface_ErrorDialogDisplay,
              noteSortOrder,
              selectedThreadNotes,
              replyEditorVisibility,
              uc_setUserInterface_ConfirmDialogDisplay,
              noteReplyRichContentEditorValue,
              setNoteReplyRichContentEditorValue,
              uc_RootData_ClientUser,
              setReplyEditorVisibility,
            )}
          </Box>
        ))}
      </Box>
    )
  }
  return tableJSX
}

///////////////////////////////
// JSX Exports
///////////////////////////////

export const rJSX_NotesTab = (
  readOrWrite: 'read' | 'write',
  uc_RootData_ClientKey: any,
  uc_RootData_ClientUser: any,
  newNoteEditorVisibility: any,
  noteReplyRichContentEditorValue: any,
  noteRichContentEditorValue: any,
  noteSortOrder: any,
  noteSubjectValue: any,
  noteTableFilter: any,
  projectKey: any,
  projectNoteThreads: any,
  replyEditorVisibility: any,
  selectedNoteThreadKey: any,
  selectedThreadNotes: any,
  setNewNoteEditorVisibility: any,
  setNoteReplyRichContentEditorValue: any,
  setNoteRichContentEditorValue: any,
  setNoteSetOrder: any,
  setNoteSubjectValue: any,
  setNoteTableFilter: any,
  setReplyEditorVisibility: any,
  uc_setRootData_ClientKey: any,
  setSelectedNoteThreadKey: any,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  uc_setUserInterface_FormDialogDisplay: any,
): JSX.Element => {
  let tabJSX = (
    <Box>
      {rJSX_NewNoteEditor(
        readOrWrite,
        newNoteEditorVisibility,
        noteSubjectValue,
        noteRichContentEditorValue,
        uc_RootData_ClientUser,
        uc_RootData_ClientKey,
        uc_setRootData_ClientKey,
        projectKey,
        uc_setUserInterface_ErrorDialogDisplay,
        uc_setUserInterface_ConfirmDialogDisplay,
        setNoteSubjectValue,
        setNoteRichContentEditorValue,
        uc_setUserInterface_FormDialogDisplay,
        noteTableFilter,
        setNoteTableFilter,
        noteSortOrder,
        setNoteSetOrder,
        setNewNoteEditorVisibility,
      )}
      {rJSX_NotesTable(
        readOrWrite,
        noteTableFilter,
        projectNoteThreads,
        noteSortOrder,
        selectedNoteThreadKey,
        setSelectedNoteThreadKey,
        uc_RootData_ClientKey,
        uc_setRootData_ClientKey,
        projectKey,
        uc_setUserInterface_ErrorDialogDisplay,
        selectedThreadNotes,
        replyEditorVisibility,
        uc_setUserInterface_ConfirmDialogDisplay,
        noteReplyRichContentEditorValue,
        setNoteReplyRichContentEditorValue,
        uc_RootData_ClientUser,
        setReplyEditorVisibility,
      )}
    </Box>
  )
  return tabJSX
}
