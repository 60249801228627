///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, FormControl, MenuItem, Select } from '@mui/material/'
import { useContext, useEffect, useReducer, useState } from 'react'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_TaskWorkflowsActive_Query } from 'rfbp_aux/services/database_endpoints/directory/task_workflows'
import { TabsUrl, TsInterface_TabContentUrlArray } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey } from 'rfbp_core/services/context'
import { DatabaseGetCollection } from 'rfbp_core/services/database_management'
import { dynamicSort, getProp } from 'rfbp_core/services/helper_functions'
import { getPageLocalStorage, setPageLocalStorage } from 'rfbp_core/services/local_storage'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { PacingProjectPhasesTab } from './tabs/tab_project_phases'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminProjectPacingPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  // const [ us_items, us_setItems ] = 		useState<TsInterface_UnspecifiedObject>({})
  // const ur_forceRerender = 				useReducer(() => ({}), {})[1] as () => void
  // const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = 		useContext( Context_RootData_ClientKey )
  const [us_selectedTaskWorkflowKey, us_setSelectedTaskWorkflowKey] = useState<string>('')
  const [us_taskWorkflowsArray, us_setTaskWorkflowsArray] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_taskWorkflowsObject, us_setTaskWorkflowsObject] = useState<TsInterface_UnspecifiedObject>({})
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Pacing', false) as string
  }, [])

  useEffect(() => {
    let pageLocalStorageData = getPageLocalStorage(pageKey)
    if (getProp(pageLocalStorageData, 'selected_task_workflow_key', null) != null) {
      us_setSelectedTaskWorkflowKey(getProp(pageLocalStorageData, 'selected_task_workflow_key', null))
    }
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetCollection(DatabaseRef_TaskWorkflowsActive_Query(res_GCK.clientKey, 'name', false, null, {}))
          .then((res_DGC) => {
            us_setTaskWorkflowsObject(res_DGC.data)
            let workflows: TsInterface_UnspecifiedObject[] = []
            for (let workflowKey in res_DGC.data) {
              let loopWorkflow = res_DGC.data[workflowKey]
              workflows.push({ key: loopWorkflow.key, value: loopWorkflow.name })
            }
            us_setTaskWorkflowsArray(workflows)
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Functions

  // JSX Generation
  const rJSX_WorkflowSelection = (): JSX.Element => {
    return (
      <FormControl className="bp_thin_select_input">
        <Select
          color="primary"
          value={us_selectedTaskWorkflowKey}
          onChange={(event: any) => {
            if (event != null && event.target != null && event.target.value != null) {
              us_setSelectedTaskWorkflowKey(event.target.value)
              setPageLocalStorage(pageKey, 'selected_task_workflow_key', event.target.value)
            }
          }}
          variant="outlined"
        >
          {us_taskWorkflowsArray.sort(dynamicSort('value', null)).map((option: TsInterface_UnspecifiedObject, index: number) => (
            <MenuItem
              key={index}
              value={option['key']}
              disabled={option['disabled'] === true}
            >
              {option['value']}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }
  const rJSX_Tabs = (): JSX.Element => {
    let pageTabs: TsInterface_TabContentUrlArray = [
      {
        tabUrlKey: 'Project_Phases',
        tabHeader: rLIB('Project Phases'),
        tabContent: (
          <PacingProjectPhasesTab
            selectedTaskWorkflowKey={us_selectedTaskWorkflowKey}
            taskWorkflows={us_taskWorkflowsObject}
            readOrWrite="write"
            projectViewPageKey={'AdminActiveProjectViewPage'}
          />
        ),
        tabButtons: [{ fullJSX: rJSX_WorkflowSelection(), minJSX: rJSX_WorkflowSelection(), sizeCutoff: 0 }],
      },
      // {
      //   tabUrlKey: 'Delays',
      //   tabHeader: rLIB('Delays'),
      //   tabContent: (
      //     <TaskDelaysTab
      //       selectedTaskWorkflowKey={us_selectedTaskWorkflowKey}
      //       readOrWrite="write"
      //       projectViewPageKey={'AdminActiveProjectViewPage'}
      //     />
      //   ),
      //   tabButtons: [{ fullJSX: rJSX_WorkflowSelection(), minJSX: rJSX_WorkflowSelection(), sizeCutoff: 0 }],
      // },
      // {
      //   tabUrlKey: 'Extensions',
      //   tabHeader: rLIB('Extensions'),
      //   tabContent: (
      //     <TaskExtensionsTab
      //       selectedTaskWorkflowKey={us_selectedTaskWorkflowKey}
      //       readOrWrite="write"
      //       projectViewPageKey={'AdminActiveProjectViewPage'}
      //     />
      //   ),
      //   tabButtons: [{ fullJSX: rJSX_WorkflowSelection(), minJSX: rJSX_WorkflowSelection(), sizeCutoff: 0 }],
      // },
      // {
      //   tabUrlKey: 'Watchlist',
      //   tabHeader: rLIB('Watchlist'),
      //   tabContent: (
      //     <TaskWatchlistTab
      //       selectedTaskWorkflowKey={us_selectedTaskWorkflowKey}
      //       readOrWrite="write"
      //       projectViewPageKey={'AdminActiveProjectViewPage'}
      //     />
      //   ),
      //   tabButtons: [{ fullJSX: rJSX_WorkflowSelection(), minJSX: rJSX_WorkflowSelection(), sizeCutoff: 0 }],
      // },
      // {
      //   tabUrlKey: 'Cycle_Times',
      //   tabHeader: rLIB('Cycle Times'),
      //   tabContent: (
      //     <TaskCycleTimesTab
      //       selectedTaskWorkflowKey={us_selectedTaskWorkflowKey}
      //       readOrWrite="write"
      //       projectViewPageKey={'AdminActiveProjectViewPage'}
      //     />
      //   ),
      //   tabButtons: [{ fullJSX: rJSX_WorkflowSelection(), minJSX: rJSX_WorkflowSelection(), sizeCutoff: 0 }],
      // },
    ]
    let tabsJSX = (
      <TabsUrl
        tabs={pageTabs}
        tabsSettings={{
          tabQueryParam: 'tab',
          baseUrl: ApplicationPages['AdminProjectPacingPage'].url(),
          overridePageTitle: true,
          basePageTitle: rLIB('Pacing', false) as string,
        }}
      />
    )
    return tabsJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Pacing')}
        pageKey={pageKey}
        content={<Box>{rJSX_Tabs()}</Box>}
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
