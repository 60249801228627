///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

			Publish to Staging

			Mapping to project fields at root?
	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material/'
import Grid2 from '@mui/material/Unstable_Grid2'
import { returnProjectDocumentFolders } from 'app/models/projects/project_document_folders'
import { returnImageTags } from 'app/models/projects/project_image_tags'
import { returnTaskFormConfigFields, returnTaskFormConfigFormInputs } from 'app/models/projects/project_task_form_mapping'
import { returnBoilerplateFormFromTaskFormQuestions } from 'app/models/tasks'
import { pageTypeOptions, questionTypeOptions } from 'app/models/tasks/task_form_services'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { useNavigate, useParams } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_TaskFormConfig_Document,
  DatabaseRef_TaskFormInstructions_Document,
  DatabaseRef_TaskFormProdPagesHistory_Document,
  DatabaseRef_TaskFormProdPages_Document,
  DatabaseRef_TaskFormStagingPages_Collection,
  DatabaseRef_TaskFormStagingPages_Document,
  DatabaseRef_TaskForm_Document,
} from 'rfbp_aux/services/database_endpoints/directory/task_forms'
import { DatabaseRef_ActiveMLEvaluationProcedures_Query } from 'rfbp_aux/services/database_endpoints/machine_learning/evaluation_procedures'
import { Json } from 'rfbp_core/components/code_display'
import {
  Form,
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
  TsType_FormOnChange,
  TsType_FormSubmission,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { TabsBasic, TabsUrl, TsInterface_TabContent, TsInterface_TabsSettingsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientUser,
  Context_UserInterface_AlertDialog,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  Context_UserInterface_PromptDialog,
  TsInterface_PromptDialogObject,
} from 'rfbp_core/services/context'
import {
  DatabaseAddDocument,
  DatabaseBatchUpdate,
  DatabaseDeleteDocument,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
  DatabaseUpdateDocument,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, keyFromString, objectToArray } from 'rfbp_core/services/helper_functions'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import {
  TsInterface_UnspecifiedObject,
  TsType_MuiButtonVariants,
  TsType_MuiComponentColors,
  TsType_UnknownPromise,
  TsType_VoidFunction,
} from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminDatabaseTaskFormsViewPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_ADDITIONAL_CONFIG: JSX.Element = <Trans>Additional Config</Trans>
const s_ADD_FILE_FOLDER: JSX.Element = <Trans>Add File Folder</Trans>
const s_ADD_FORM_PAGE: JSX.Element = <Trans>Add Form Page</Trans>
const s_ADD_OPTION: JSX.Element = <Trans>Add Option</Trans>
const s_ADD_PAGE: JSX.Element = <Trans>Add Page</Trans>
const s_ADD_QUESTION: JSX.Element = <Trans>Add Question</Trans>
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_FILE_FOLDER: JSX.Element = <Trans>Are you sure that you want to delete this file folder?</Trans>
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_OPTION: JSX.Element = <Trans>Are you sure that you want to delete this option?</Trans>
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_PAGE: JSX.Element = <Trans>Are you sure that you want to delete this page?</Trans>
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_QUESTION: JSX.Element = <Trans>Are you sure that you want to delete this question?</Trans>
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_PUBLISH_THE_CURRENT_STAGING_FORM_TO_PRODUCTION: JSX.Element = (
  <Trans>Are you sure that you want to publish the current staging form to production?</Trans>
)
const s_BACK_TO_ALL_TASK_FORMS: JSX.Element = <Trans>Back to all task forms</Trans>
const s_DEFAULT_IMAGE_TAGS: JSX.Element = <Trans>Default Image Tags</Trans>
const s_DELETE: JSX.Element = <Trans>Delete</Trans>
const s_DELETE_FILE_FOLDER: JSX.Element = <Trans>Delete File Folder</Trans>
const s_DELETE_OPTION: JSX.Element = <Trans>Delete Option</Trans>
const s_DELETE_PAGE: JSX.Element = <Trans>Delete Page</Trans>
const s_DELETE_QUESTION: JSX.Element = <Trans>Delete Question</Trans>
const s_EDIT_PAGE_TYPE: JSX.Element = <Trans>Edit Page Type</Trans>
const s_ENTER_A_NAME_FOR_THIS_OPTION: JSX.Element = <Trans>Enter a name for this option</Trans>
const s_ENTER_A_NAME_FOR_THIS_PAGE: JSX.Element = <Trans>Enter a name for this page</Trans>
const s_ENTER_A_NAME_FOR_YOUR_NEW_OPTION: JSX.Element = <Trans>Enter a name for your new option</Trans>
const s_ENTER_A_NAME_FOR_YOUR_NEW_PAGE: JSX.Element = <Trans>Enter a name for your new page</Trans>
const s_ERROR_MINIMUM_UPLOAD_LIMIT_CANNOT_EXCEED_MAXIMUM_LIMIT: JSX.Element = <Trans>Error: Minimum upload limit cannot exceed maximum limit</Trans>
const s_FOLDER_NAME: JSX.Element = <Trans>Folder Name</Trans>
const s_FORM_CONFIG: JSX.Element = <Trans>Form Config</Trans>
const s_LINK_UPLOADS_TO_PROJECT_DOCUMENT_SYSTEM: JSX.Element = <Trans>Link Uploads to project document system</Trans>
const s_MAXIMUM_FILE_UPLOADS: JSX.Element = <Trans>Maximum File Uploads</Trans>
const s_MINIMUM_FILE_UPLOADS: JSX.Element = <Trans>Minimum File Uploads</Trans>
const s_ML_EVALUATION_PROCEDURE: JSX.Element = <Trans>ML Evaluation Procedure</Trans>
const s_NO_PAGES_ADDED_TO_FORM_YET: JSX.Element = <Trans>No pages added to form yet</Trans>
const s_NO_PAGE_TYPE_SELECTED: JSX.Element = <Trans>No page type selected</Trans>
const s_NO_PRODUCTION_TASK_FORM: JSX.Element = <Trans>No production task form</Trans>
const s_NO_QUESTIONS_ADDED_YET: JSX.Element = <Trans>No questions added yet</Trans>
const s_OPTION_NAME: JSX.Element = <Trans>Option Name</Trans>
const s_PAGE_NAME: JSX.Element = <Trans>Page Name</Trans>
const s_PAGE_TYPE: JSX.Element = <Trans>Page Type</Trans>
const s_PREVIEW: JSX.Element = <Trans>Preview</Trans>
const s_PREVIEW_FORM: JSX.Element = <Trans>Preview Form</Trans>
const s_PRODUCTION_VIEW: JSX.Element = <Trans>Production View</Trans>
const s_PROJECT_FOLDER: JSX.Element = <Trans>Project Folder</Trans>
const s_PUBLISH: JSX.Element = <Trans>Publish</Trans>
const s_PUBLISH_STAGING_TO_PROD: JSX.Element = <Trans>Publish staging to prod</Trans>
const s_QUESTION_CAPTION: JSX.Element = <Trans>Question Caption</Trans>
const s_QUESTION_LABEL: JSX.Element = <Trans>Question Label</Trans>
const s_QUESTION_PLACEHOLDER: JSX.Element = <Trans>Question Placeholder</Trans>
const s_RENAME_PAGE: JSX.Element = <Trans>Rename Page</Trans>
const s_REQUIRE_MAXIMUM_FILE_UPLOADS: JSX.Element = <Trans>Require Maximum File Uploads</Trans>
const s_REQUIRE_MINIMUM_FILE_UPLOADS: JSX.Element = <Trans>Require Minimum File Uploads</Trans>
const s_RUN_ML_EVALUATION_PROCECURE_ON_IMAGES: JSX.Element = <Trans>Run ML Evaluation Procedure on images</Trans>
const s_SCREENSHOTS_NOT_ALLOWED: JSX.Element = <Trans>Screenshots not allowed</Trans>
const s_SAVE_INSTRUCTIONS: JSX.Element = <Trans>Save Instructions</Trans>
const s_STAGING_AREA: JSX.Element = <Trans>Staging Area</Trans>
const s_STAGING_FORM_PUBLISHED: JSX.Element = <Trans>Staging Form Published</Trans>
const s_SUCCESS: JSX.Element = <Trans>Success</Trans>
const s_TASK_FORM: JSX.Element = <Trans>Task Form</Trans>
const s_TASK_INSTRUCTIONS: JSX.Element = <Trans>Task Instructions</Trans>
const s_UPDATE_OPTION: JSX.Element = <Trans>Update Option</Trans>
// { sort-end } - displayed text

// Form
const formInputs_TaskFormPageType: TsInterface_FormInputs = {
  page_type: {
    data_type: 'string',
    input_type: 'multiple_choice_radio',
    key: 'page_type',
    label: s_PAGE_TYPE,
    options: objectToArray(pageTypeOptions),
    required: true,
  },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const pr_formKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_activeMLEvaluationProcedures, us_setActiveMLEvaluationProcedures] = useState<TsInterface_UnspecifiedObject>({})
  const [us_availableImageTags, us_setAvailableImageTags] = useState<string[]>([])
  const [us_availableTaskFormConfigFields, us_setAvailableTaskFormConfigFields] = useState<TsInterface_UnspecifiedObject>({})
  const [us_formInputs_TaskFormAdditionalConfig, us_setFormInputs_TaskFormAdditionalConfig] = useState<TsInterface_FormInputs>({})
  const [us_initialProdDataLoaded, us_setInitialProdDataLoaded] = useState<boolean>(false)
  const [us_initialStagingDataLoaded, us_setInitialStagingDataLoaded] = useState<boolean>(false)
  const [us_loadingPage, us_setLoadingPage] = useState<boolean>(false)
  const [us_prodTaskFormPages, us_setProdTaskFormPages] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectDocumentFolders, us_setProjectDocumentFolders] = useState<TsInterface_UnspecifiedObject>({})
  const [us_richContentEditorButtonColor, us_setRichContentEditorButtonColor] = useState<TsType_MuiComponentColors>('info')
  const [us_richContentEditorButtonVariant, us_setRichContentEditorButtonVariant] = useState<TsType_MuiButtonVariants>('outlined')
  const [us_richContentEditorValue, us_setRichContentEditorValue] = useState<string>('')
  const [us_stagingConfig, us_setStagingConfig] = useState<TsInterface_UnspecifiedObject>({})
  const [us_stagingInstructions, us_setStagingInstructions] = useState<TsInterface_UnspecifiedObject>({})
  const [us_stagingInstructionsLoaded, us_setStagingInstructionsLoaded] = useState<boolean>(false)
  const [us_stagingTaskFormPages, us_setStagingTaskFormPages] = useState<TsInterface_UnspecifiedObject>({})
  const [us_taskForm, us_setTaskForm] = useState<TsInterface_UnspecifiedObject>({})
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_setUserInterface_AlertDialogDisplay } = useContext(Context_UserInterface_AlertDialog)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setTaskForm(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_TaskForm_Document(res_GCK.clientKey, pr_formKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_formKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setStagingTaskFormPages(newData)
      us_setInitialStagingDataLoaded(true)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_TaskFormStagingPages_Collection(res_GCK.clientKey, pr_formKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_formKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveMLEvaluationProcedures(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveMLEvaluationProcedures_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_formKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setProdTaskFormPages(newData)
      us_setInitialProdDataLoaded(true)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_TaskFormProdPages_Document(res_GCK.clientKey, pr_formKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_formKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setStagingInstructions(newData)
      }
      us_setStagingInstructionsLoaded(true)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_TaskFormInstructions_Document(res_GCK.clientKey, pr_formKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_formKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setStagingConfig(newData)
      }
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_TaskFormConfig_Document(res_GCK.clientKey, pr_formKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_formKey])

  useEffect(() => {
    us_setRichContentEditorValue(us_stagingInstructions.instructions)
  }, [us_stagingInstructions])

  useEffect(() => {
    // let unsubscribeLiveData: TsType_VoidFunction
    // const updateLiveData = ( newData: TsInterface_UnspecifiedObject ) => {
    // 	if( newData != null) {
    // 		setStagingInstructions( newData )
    // 	}
    // 	setStagingInstructionsLoaded( true )
    // 	ur_forceRerender()
    // }
    // getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
    // 	unsubscribeLiveData = DatabaseGetLiveDocument( DatabaseRef_TaskFormInstructions_Document( res_GCK.clientKey, formKey ), updateLiveData )
    // }).catch(( rej_GCK ) => {
    // 	console.error( rej_GCK )
    // })
    return () => {}
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_formKey])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        us_setProjectDocumentFolders(returnProjectDocumentFolders(res_GCK.clientKey))
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        us_setAvailableImageTags(returnImageTags(res_GCK.clientKey))
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let allConfigFieldOptions = returnTaskFormConfigFields(res_GCK.clientKey)
        let filteredConfigFieldOptions: TsInterface_UnspecifiedObject = {}
        for (let loopOptionKey in allConfigFieldOptions) {
          let loopOption = allConfigFieldOptions[loopOptionKey]
          if (
            loopOption != null &&
            loopOption['required_config'] != null &&
            us_stagingConfig != null &&
            us_stagingConfig[loopOption['required_config']] === true
          ) {
            filteredConfigFieldOptions[loopOptionKey] = loopOption
          } else {
            loopOption.disabled = true
            filteredConfigFieldOptions[loopOptionKey] = loopOption
          }
        }
        us_setAvailableTaskFormConfigFields(filteredConfigFieldOptions)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, us_stagingConfig])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        us_setFormInputs_TaskFormAdditionalConfig(returnTaskFormConfigFormInputs(res_GCK.clientKey))
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  // Other Variables
  const tabsSettings: TsInterface_TabsSettingsUrl = {
    baseUrl: ApplicationPages.AdminDatabaseTaskFormsViewPage.url(pr_formKey),
    tabQueryParam: 'tab',
    overridePageTitle: true,
    basePageTitle: getProp(us_taskForm, 'name', 'Task Form'),
  }

  const addFormPagePromptObject: TsInterface_PromptDialogObject = {
    color: 'info',
    confirm_text: s_ADD_FORM_PAGE,
    default_value: null,
    header: s_ADD_FORM_PAGE,
    icon: (
      <Icon
        icon="sitemap"
        type="solid"
      />
    ),
    input_label: s_PAGE_NAME,
    input_type: 'text',
    text: s_ENTER_A_NAME_FOR_YOUR_NEW_PAGE,
    submit_callback: (promptValue: string) => {
      return new Promise((resolve, reject) => {
        let updateObject = {
          name: promptValue,
          timestamp_created: new Date().getTime(),
        }
        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
          .then((res_GCK) => {
            DatabaseAddDocument(DatabaseRef_TaskFormStagingPages_Collection(res_GCK.clientKey, pr_formKey), updateObject, true)
              .then((res_DAD) => {
                ur_forceRerender()
                resolve(res_DAD)
              })
              .catch((rej_DAD) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
              })
          })
          .catch((rej_GCK) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          })
      })
    },
  }

  const formAdditionalData_TaskFormAdditionalConfig: TsInterface_FormAdditionalData = {}

  const formSettings_TaskFormAdditionalConfig: TsInterface_FormSettings = {
    // submit_button_theme: "primary",
    // submit_button_alignment: "center",
    // submit_button_hide: false,
    // submit_button_icon: <Icon icon="right-to-bracket"/>,
    // submit_button_saving_icon: true,
    // submit_button_text: <>{s_LOG_IN}</>,
  }

  // Functions
  const previewForm = (page: TsInterface_UnspecifiedObject): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: returnBoilerplateFormFromTaskFormQuestions(page.questions, false),
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              resolve({ success: true })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: s_PREVIEW_FORM,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="magnifying-glass"
            />
          ),
        },
      },
    })
  }

  const returnTaskFormPages = (taskFormPages: TsInterface_UnspecifiedObject, editable: boolean): TsInterface_TabContent[] => {
    let taskPages: TsInterface_TabContent[] = []
    let sortedTaskPages = objectToArray(taskFormPages).sort(dynamicSort('timestamp_created', null))
    if (editable === false) {
      sortedTaskPages = objectToArray(taskFormPages).sort(dynamicSort('order', null))
    }
    for (let loopFormPageIndex in sortedTaskPages) {
      let loopFormPage = sortedTaskPages[loopFormPageIndex]
      taskPages.push({
        tabHeader: loopFormPage.name,
        tabContent: (
          <Box
            sx={{ maxWidth: '800px' }}
            className="tw-mx-auto tw-mt-2"
          >
            <Box>
              {rJSX_PageTypeButton(loopFormPage, editable)}
              {rJSX_AddFormPage(editable)}
              {rJSX_RenamePageButton(loopFormPage, editable)}
              {rJSX_DeletePageButton(loopFormPage, editable)}
              {rJSX_PublishToProdButton(editable)}
            </Box>
            <Box>{rJSX_FormPageContent(loopFormPage, editable)}</Box>
          </Box>
        ),
      })
    }
    return taskPages
  }

  // PAGE
  const rJSX_PageHeader = (): JSX.Element => {
    let pageHeader = <>{s_TASK_FORM}</>
    if (us_taskForm != null && us_taskForm.name != null) {
      pageHeader = (
        <>
          {s_TASK_FORM} - {us_taskForm.name}
        </>
      )
    }
    return pageHeader
  }

  const renamePage = (page: TsInterface_UnspecifiedObject): void => {
    uc_setUserInterface_PromptDialogDisplay({
      display: true,
      prompt: {
        color: 'info',
        confirm_text: s_RENAME_PAGE,
        default_value: page.name,
        header: s_RENAME_PAGE,
        icon: (
          <Icon
            icon="pen-to-square"
            type="solid"
          />
        ),
        input_label: s_PAGE_NAME,
        input_type: 'text',
        text: s_ENTER_A_NAME_FOR_THIS_PAGE,
        submit_callback: (promptValue: string) => {
          return new Promise((resolve, reject) => {
            let updateObject = {
              name: promptValue,
            }
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                DatabaseSetMergeDocument(DatabaseRef_TaskFormStagingPages_Document(res_GCK.clientKey, pr_formKey, page.key), updateObject)
                  .then((res_DSMD) => {
                    ur_forceRerender()
                    resolve({ success: true })
                  })
                  .catch((rej_DSMD) => {
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                  })
              })
              .catch((rej_GCK) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
              })
          })
        },
      },
    })
  }

  const updateTaskInstructionsProperty = (page: TsInterface_UnspecifiedObject, property: string, value: string | number | boolean): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      let updateObject: TsInterface_UnspecifiedObject = {}
      updateObject[property] = value
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseSetMergeDocument(DatabaseRef_TaskFormInstructions_Document(res_GCK.clientKey, pr_formKey), updateObject)
            .then((res_DSMD) => {
              resolve(res_DSMD)
              ur_forceRerender()
            })
            .catch((rej_DSMD) => {
              reject(rej_DSMD)
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
            })
        })
        .catch((rej_GCK) => {
          reject(rej_GCK)
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        })
    })
  }

  const deletePage = (page: TsInterface_UnspecifiedObject): void => {
    uc_setUserInterface_ConfirmDialogDisplay({
      display: true,
      confirm: {
        color: 'error',
        header: s_DELETE_PAGE,
        icon: (
          <Icon
            icon="trash"
            type="solid"
          />
        ),
        submit_text: s_DELETE,
        text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_PAGE,
        submit_callback: () => {
          return new Promise((resolve, reject) => {
            us_setLoadingPage(true)
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                DatabaseDeleteDocument(DatabaseRef_TaskFormStagingPages_Document(res_GCK.clientKey, pr_formKey, page.key))
                  .then((res_DDD) => {
                    us_setLoadingPage(false)
                    resolve(res_DDD)
                  })
                  .catch((rej_DDD) => {
                    us_setLoadingPage(false)
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DDD.error })
                  })
              })
              .catch((rej_GCK) => {
                us_setLoadingPage(false)
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
              })
          })
        },
      },
    })
  }

  // CONFIG
  const formOnChange_TaskFormAdditionalConfig: TsType_FormOnChange = (formAdditionalData, formData, formInputs, formSettings, formHooks) => {
    // Nothing
  }

  const formSubmission_TaskFormAdditionalConfig: TsType_FormSubmission = (formSubmittedData, formAdditionalData) => {
    return new Promise((resolve, reject) => {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseSetMergeDocument(DatabaseRef_TaskFormConfig_Document(res_GCK.clientKey, pr_formKey), formSubmittedData)
            .then((res_DSMD) => {
              resolve(res_DSMD)
            })
            .catch((rej_DSMD) => {
              reject(rej_DSMD)
            })
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          reject(rej_GCK)
        })
    })
  }

  // QUESTIONS
  const addQuestionToPage = (page: TsInterface_UnspecifiedObject): void => {
    let timestamp = new Date().getTime()
    let updateObject: TsInterface_UnspecifiedObject = { questions: {} }
    updateObject['questions'][timestamp.toString()] = {
      key: timestamp.toString(),
      order: timestamp,
      input_type: 'short_answer',
      label: '',
      caption: '',
      placeholder: '',
      required: false,
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseSetMergeDocument(DatabaseRef_TaskFormStagingPages_Document(res_GCK.clientKey, pr_formKey, page.key), updateObject)
          .then((res_DSMD) => {
            // Nothing
          })
          .catch((rej_DSMD) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
          })
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  }

  const editQuestionProperty = (
    page: TsInterface_UnspecifiedObject,
    question: TsInterface_UnspecifiedObject,
    property: string,
    value: string | number | boolean,
  ): void => {
    let updateObject: TsInterface_UnspecifiedObject = { questions: {} }
    updateObject['questions'][question.key] = {}
    updateObject['questions'][question.key][property] = value
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseSetMergeDocument(DatabaseRef_TaskFormStagingPages_Document(res_GCK.clientKey, pr_formKey, page.key), updateObject)
          .then((res_DSMD) => {
            // Nothing
          })
          .catch((rej_DSMD) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
          })
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  }

  const deleteQuestion = (page: TsInterface_UnspecifiedObject, question: TsInterface_UnspecifiedObject): void => {
    uc_setUserInterface_ConfirmDialogDisplay({
      display: true,
      confirm: {
        color: 'error',
        header: s_DELETE_QUESTION,
        icon: (
          <Icon
            icon="trash"
            type="solid"
          />
        ),
        submit_text: s_DELETE,
        text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_QUESTION,
        submit_callback: () => {
          return new Promise((resolve, reject) => {
            us_setLoadingPage(true)
            let updateObject: TsInterface_UnspecifiedObject = { questions: page.questions }
            delete updateObject['questions'][question.key]
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                DatabaseUpdateDocument(DatabaseRef_TaskFormStagingPages_Document(res_GCK.clientKey, pr_formKey, page.key), updateObject)
                  .then((res_DUD) => {
                    us_setLoadingPage(false)
                    resolve(res_DUD)
                  })
                  .catch((rej_DUD) => {
                    us_setLoadingPage(false)
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DUD.error })
                  })
              })
              .catch((rej_GCK) => {
                us_setLoadingPage(false)
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
              })
          })
        },
      },
    })
  }

  const moveQuestionUp = (page: TsInterface_UnspecifiedObject, question: TsInterface_UnspecifiedObject): void => {
    let sortedQuestionArray = objectToArray(page['questions']).sort(dynamicSort('order', null))
    let matchIndex: number = -1
    for (let loopIndex = 0; loopIndex < sortedQuestionArray.length; loopIndex++) {
      let loopQuestion = sortedQuestionArray[loopIndex]
      if (loopQuestion.key === question.key) {
        matchIndex = loopIndex
      }
    }
    if (matchIndex !== -1 && matchIndex !== 0) {
      let key1 = sortedQuestionArray[matchIndex]['key']
      let key2 = sortedQuestionArray[matchIndex - 1]['key']
      let order1 = sortedQuestionArray[matchIndex]['order']
      let order2 = sortedQuestionArray[matchIndex - 1]['order']
      let updateObject: TsInterface_UnspecifiedObject = { questions: {} }
      updateObject['questions'][key1] = { order: order2 }
      updateObject['questions'][key2] = { order: order1 }
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseSetMergeDocument(DatabaseRef_TaskFormStagingPages_Document(res_GCK.clientKey, pr_formKey, page.key), updateObject)
            .then((res_DSMD) => {
              // Nothing
            })
            .catch((rej_DSMD) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
            })
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        })
    } else {
      // Nothing
    }
  }

  const moveQuestionDown = (page: TsInterface_UnspecifiedObject, question: TsInterface_UnspecifiedObject): void => {
    let sortedQuestionArray = objectToArray(page['questions']).sort(dynamicSort('order', null))
    let matchIndex: number = -1
    for (let loopIndex = 0; loopIndex < sortedQuestionArray.length; loopIndex++) {
      let loopQuestion = sortedQuestionArray[loopIndex]
      if (loopQuestion.key === question.key) {
        matchIndex = loopIndex
      }
    }
    if (matchIndex !== -1 && matchIndex < sortedQuestionArray.length - 1) {
      let key1 = sortedQuestionArray[matchIndex]['key']
      let key2 = sortedQuestionArray[matchIndex + 1]['key']
      let order1 = sortedQuestionArray[matchIndex]['order']
      let order2 = sortedQuestionArray[matchIndex + 1]['order']
      let updateObject: TsInterface_UnspecifiedObject = { questions: {} }
      updateObject['questions'][key1] = { order: order2 }
      updateObject['questions'][key2] = { order: order1 }
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseSetMergeDocument(DatabaseRef_TaskFormStagingPages_Document(res_GCK.clientKey, pr_formKey, page.key), updateObject)
            .then((res_DSMD) => {
              // Nothing
            })
            .catch((rej_DSMD) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
            })
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        })
    } else {
      // Nothing
    }
  }

  const editQuestionState = (
    page: TsInterface_UnspecifiedObject,
    question: TsInterface_UnspecifiedObject,
    property: string,
    value: string | number | boolean,
  ): void => {
    // Nested Controlled Input State Management Magic
    let newState = Object.assign({}, us_stagingTaskFormPages)
    newState[page.key]['questions'][question.key][property] = value
    us_setStagingTaskFormPages(newState)
  }

  // FILE FOLDERS
  const addFileFolderToPage = (page: TsInterface_UnspecifiedObject): void => {
    let timestamp = new Date().getTime()
    let updateObject: TsInterface_UnspecifiedObject = { folders: {} }
    updateObject['folders'][timestamp.toString()] = {
      key: timestamp.toString(),
      order: timestamp,
      max_file_upload_count: 1,
      min_file_upload_count: 0,
      name: '',
      require_max_file_upload: false,
      require_min_file_upload: false,
      link_documents_to_project_document_system: false,
      associated_project_document_folder_key: null,
      available_image_tags: [],
      default_image_tags: [],
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseSetMergeDocument(DatabaseRef_TaskFormStagingPages_Document(res_GCK.clientKey, pr_formKey, page.key), updateObject)
          .then((res_DSMD) => {
            // Nothing
          })
          .catch((rej_DSMD) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
          })
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  }

  const editFileFolderProperty = (
    page: TsInterface_UnspecifiedObject,
    folder: TsInterface_UnspecifiedObject,
    property: string,
    value: string | number | boolean | string[],
  ): void => {
    const updateObject: TsInterface_UnspecifiedObject = { folders: {} }
    updateObject.folders[folder.key] = { [property]: value }

    console.log('Updating database with:', updateObject)

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseSetMergeDocument(DatabaseRef_TaskFormStagingPages_Document(res_GCK.clientKey, pr_formKey, page.key), updateObject)
          .then(() => {
            console.log('Database update successful:', folder.key, property, value)
          })
          .catch((error) => {
            console.error('Database update failed:', error)
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error.error })
          })
      })
      .catch((error) => {
        console.error('Client key retrieval failed:', error)
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error.error })
      })
  }

  const deleteFileFolder = (page: TsInterface_UnspecifiedObject, folder: TsInterface_UnspecifiedObject): void => {
    uc_setUserInterface_ConfirmDialogDisplay({
      display: true,
      confirm: {
        color: 'error',
        header: s_DELETE_FILE_FOLDER,
        icon: (
          <Icon
            icon="trash"
            type="solid"
          />
        ),
        submit_text: s_DELETE,
        text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_FILE_FOLDER,
        submit_callback: () => {
          return new Promise((resolve, reject) => {
            us_setLoadingPage(true)
            let updateObject: TsInterface_UnspecifiedObject = { folders: page.folders }
            delete updateObject['folders'][folder.key]
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                DatabaseUpdateDocument(DatabaseRef_TaskFormStagingPages_Document(res_GCK.clientKey, pr_formKey, page.key), updateObject)
                  .then((res_DUD) => {
                    us_setLoadingPage(false)
                    resolve(res_DUD)
                  })
                  .catch((rej_DUD) => {
                    us_setLoadingPage(false)
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DUD.error })
                  })
              })
              .catch((rej_GCK) => {
                us_setLoadingPage(false)
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
              })
          })
        },
      },
    })
  }

  const moveFileFolderUp = (page: TsInterface_UnspecifiedObject, folder: TsInterface_UnspecifiedObject): void => {
    const sortedFolderArray = objectToArray(page['folders']).sort(dynamicSort('order', null))
    let matchIndex: number = -1

    for (let loopIndex = 0; loopIndex < sortedFolderArray.length; loopIndex++) {
      const loopFolder = sortedFolderArray[loopIndex]
      if (loopFolder.key === folder.key) {
        matchIndex = loopIndex
      }
    }

    if (matchIndex !== -1 && matchIndex !== 0) {
      const key1 = sortedFolderArray[matchIndex]['key']
      const key2 = sortedFolderArray[matchIndex - 1]['key']
      const order1 = sortedFolderArray[matchIndex]['order']
      const order2 = sortedFolderArray[matchIndex - 1]['order']
      const updateObject: TsInterface_UnspecifiedObject = { folders: {} }

      updateObject['folders'][key1] = { order: order2 }
      updateObject['folders'][key2] = { order: order1 }

      console.log('Moving up:', key1, key2)

      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseSetMergeDocument(DatabaseRef_TaskFormStagingPages_Document(res_GCK.clientKey, pr_formKey, page.key), updateObject)
            .then(() => {
              console.log('Move up successful')
            })
            .catch((rej_DSMD) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
            })
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        })
    }
  }
  const moveFileFolderDown = (page: TsInterface_UnspecifiedObject, folder: TsInterface_UnspecifiedObject): void => {
    const sortedFolderArray = objectToArray(page['folders']).sort(dynamicSort('order', null))
    let matchIndex: number = -1

    for (let loopIndex = 0; loopIndex < sortedFolderArray.length; loopIndex++) {
      const loopFolder = sortedFolderArray[loopIndex]
      if (loopFolder.key === folder.key) {
        matchIndex = loopIndex
      }
    }

    if (matchIndex !== -1 && matchIndex < sortedFolderArray.length - 1) {
      const key1 = sortedFolderArray[matchIndex]['key']
      const key2 = sortedFolderArray[matchIndex + 1]['key']
      const order1 = sortedFolderArray[matchIndex]['order']
      const order2 = sortedFolderArray[matchIndex + 1]['order']
      const updateObject: TsInterface_UnspecifiedObject = { folders: {} }

      updateObject['folders'][key1] = { order: order2 }
      updateObject['folders'][key2] = { order: order1 }

      console.log('Moving down:', key1, key2)

      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseSetMergeDocument(DatabaseRef_TaskFormStagingPages_Document(res_GCK.clientKey, pr_formKey, page.key), updateObject)
            .then(() => {
              console.log('Move down successful')
            })
            .catch((rej_DSMD) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
            })
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        })
    }
  }

  const editFileFolderState = (
    page: TsInterface_UnspecifiedObject,
    folder: TsInterface_UnspecifiedObject,
    property: string,
    value: string | number | boolean,
  ): void => {
    console.log('Before update:', us_stagingTaskFormPages[page.key]?.folders[folder.key])
    let newState = { ...us_stagingTaskFormPages } // Create a shallow copy of the state
    if (!newState[page.key]?.folders[folder.key]) {
      console.error('Folder not found in state')
      return
    }

    // Update the property
    newState[page.key].folders[folder.key][property] = value
    console.log('After update:', newState[page.key]?.folders[folder.key])

    // Update the state
    us_setStagingTaskFormPages(newState)
  }

  // PUBLISH
  const publishStagingToProduction = (): void => {
    uc_setUserInterface_ConfirmDialogDisplay({
      display: true,
      confirm: {
        color: 'primary',
        header: s_PUBLISH_STAGING_TO_PROD,
        icon: (
          <Icon
            icon="paper-plane"
            type="solid"
          />
        ),
        submit_text: s_PUBLISH,
        text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_PUBLISH_THE_CURRENT_STAGING_FORM_TO_PRODUCTION,
        submit_callback: () => {
          return new Promise((resolve, reject) => {
            let newProductionTaskFormPages: TsInterface_UnspecifiedObject = {}
            let sortedStagingTaskFormPagesArray: TsInterface_UnspecifiedObject[] = objectToArray(us_stagingTaskFormPages).sort(
              dynamicSort('timestamp_created', null),
            )
            for (let loopPageIndex in sortedStagingTaskFormPagesArray) {
              let loopPage = sortedStagingTaskFormPagesArray[loopPageIndex]
              if (loopPage != null && loopPage['key'] != null && loopPage['name'] != null && loopPage['page_type'] != null) {
                let pageKey = keyFromString(loopPage['name']) + '_' + loopPageIndex.toString()
                let newProductionPage: TsInterface_UnspecifiedObject = {
                  key: pageKey,
                  name: loopPage['name'],
                  page_type: loopPage['page_type'],
                  order: parseInt(loopPageIndex),
                  // page_instructions: getProp( loopPage, "page_instructions", null )
                }
                switch (loopPage['page_type']) {
                  case 'form':
                    if (loopPage['questions'] != null && objectToArray(loopPage['questions']).length > 0) {
                      newProductionPage['questions'] = {}
                      let sortedQuestionsArray: TsInterface_UnspecifiedObject[] = objectToArray(loopPage['questions']).sort(dynamicSort('order', null))
                      for (let loopQuestionIndex in sortedQuestionsArray) {
                        let loopQuestion = sortedQuestionsArray[loopQuestionIndex]
                        if (loopQuestion != null && loopQuestion['label'] != null && loopQuestion['input_type'] != null) {
                          let questionKey = keyFromString(loopQuestion['label']) + '_' + loopQuestionIndex.toString()
                          newProductionPage['questions'][questionKey] = {
                            key: questionKey,
                            label: loopQuestion['label'],
                            placeholder: getProp(loopQuestion, 'placeholder', null),
                            caption: getProp(loopQuestion, 'caption', null),
                            input_type: loopQuestion['input_type'],
                            order: parseInt(loopQuestionIndex),
                            required: getProp(loopQuestion, 'required', false),
                            additional_task_config_option: getProp(loopQuestion, 'additional_task_config_option', null),
                            instructions: getProp(loopQuestion, 'instructions', ''), // Add this line
                          }
                          if (
                            questionTypeOptions[loopQuestion['input_type']] != null &&
                            questionTypeOptions[loopQuestion['input_type']]['has_options'] === true
                          ) {
                            newProductionPage['questions'][questionKey]['options'] = {}
                            if (loopQuestion['options'] != null) {
                              let sortedQuestionOptionsArray: TsInterface_UnspecifiedObject[] = objectToArray(loopQuestion['options']).sort(
                                dynamicSort('order', null),
                              )
                              for (let loopOptionIndex in sortedQuestionOptionsArray) {
                                let loopOption = sortedQuestionOptionsArray[loopOptionIndex]
                                let optionKey = keyFromString(loopOption['option_label'])
                                newProductionPage['questions'][questionKey]['options'][optionKey] = {
                                  key: optionKey,
                                  value: loopOption.option_label,
                                  order: parseInt(loopOptionIndex),
                                }
                              }
                            }
                            newProductionPage['questions'][questionKey]['options'] = objectToArray(newProductionPage['questions'][questionKey]['options']).sort(
                              dynamicSort('order', null),
                            )
                          }
                        }
                      }
                    }
                    break
                  case 'file_uploads':
                    if (loopPage['folders'] != null && objectToArray(loopPage['folders']).length > 0) {
                      newProductionPage['folders'] = {}
                      let sortedFoldersArray: TsInterface_UnspecifiedObject[] = objectToArray(loopPage['folders']).sort(dynamicSort('order', null))
                      for (let loopFolderIndex in sortedFoldersArray) {
                        let loopFolder = sortedFoldersArray[loopFolderIndex]
                        if (loopFolder != null && loopFolder['name'] != null) {
                          let folderKey = keyFromString(loopFolder['name']) + '_' + loopFolderIndex.toString()
                          newProductionPage['folders'][folderKey] = {
                            key: folderKey,
                            name: loopFolder['name'],
                            order: parseInt(loopFolderIndex),
                            type: loopFolder.type, // Include type for instructions
                            text: loopFolder.type === 'instruction' ? loopFolder.text || '' : undefined, // Include `text` for instructions
                            max_file_upload_count: getProp(loopFolder, 'max_file_upload_count', null),
                            min_file_upload_count: getProp(loopFolder, 'min_file_upload_count', null),
                            require_max_file_upload: getProp(loopFolder, 'require_max_file_upload', false),
                            require_min_file_upload: getProp(loopFolder, 'require_min_file_upload', false),
                            link_documents_to_project_document_system: getProp(loopFolder, 'link_documents_to_project_document_system', false),
                            associated_project_document_folder_key: getProp(loopFolder, 'associated_project_document_folder_key', null),
                            available_image_tags: [],
                            default_image_tags: [],
                          }
                        }
                      }
                    }
                    break
                  case 'image_uploads':
                    if (loopPage['folders'] != null && objectToArray(loopPage['folders']).length > 0) {
                      newProductionPage['folders'] = {}
                      let sortedFoldersArray: TsInterface_UnspecifiedObject[] = objectToArray(loopPage['folders']).sort(dynamicSort('order', null))

                      for (let loopFolderIndex in sortedFoldersArray) {
                        let loopFolder = sortedFoldersArray[loopFolderIndex]
                        console.log('loopFolder:', loopFolder)

                        if (loopFolder != null && loopFolder['name'] != null) {
                          let folderKey = keyFromString(loopFolder['name']) + '_' + loopFolderIndex.toString()

                          // Build folder object dynamically to omit `type` if not an instruction
                          let folderObject: TsInterface_UnspecifiedObject = {
                            key: folderKey,
                            name: loopFolder['name'],
                            order: parseInt(loopFolderIndex),
                            max_file_upload_count: getProp(loopFolder, 'max_file_upload_count', null),
                            min_file_upload_count: getProp(loopFolder, 'min_file_upload_count', null),
                            require_max_file_upload: getProp(loopFolder, 'require_max_file_upload', false),
                            require_min_file_upload: getProp(loopFolder, 'require_min_file_upload', false),
                            link_documents_to_project_document_system: false,
                            associated_project_document_folder_key: null,
                            available_image_tags: us_availableImageTags,
                            default_image_tags: getProp(loopFolder, 'default_image_tags', []),
                            associated_ml_evaluation_procedure_key: getProp(loopFolder, 'associated_ml_evaluation_procedure_key', null),
                            run_ml_evaluation_procedure_on_images: getProp(loopFolder, 'run_ml_evaluation_procedure_on_images', null),
                            screenshots_not_allowed: getProp(loopFolder, 'screenshots_not_allowed', false),
                          }

                          // Add `type` and `text` fields only if it's an instruction
                          if (loopFolder.type === 'instruction') {
                            folderObject.type = 'instruction'
                            folderObject.text = loopFolder.text || '' // Ensure `text` has a fallback value
                          }

                          newProductionPage['folders'][folderKey] = folderObject
                        }
                      }
                    }
                    break
                }
                newProductionTaskFormPages[pageKey] = newProductionPage
              }
            }
            let publishTimestamp = new Date().getTime()
            let updateObject = {
              instructions: getProp(us_stagingInstructions, 'instructions', null),
              config: us_stagingConfig,
              pages: newProductionTaskFormPages,
              timestamp_published: publishTimestamp,
              associated_prod_history_key: publishTimestamp.toString(),
              associated_publisher_key: getProp(uc_RootData_ClientUser, 'key', null),
              associated_publisher_name: getProp(uc_RootData_ClientUser, 'name', null),
            }
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                  { type: 'setOverwrite', ref: DatabaseRef_TaskFormProdPages_Document(res_GCK.clientKey, pr_formKey), data: updateObject },
                  {
                    type: 'setOverwrite',
                    ref: DatabaseRef_TaskFormProdPagesHistory_Document(res_GCK.clientKey, pr_formKey, publishTimestamp.toString()),
                    data: updateObject,
                  },
                ]
                if (us_taskForm == null || us_taskForm['published_to_prod'] !== true) {
                  updateArray.push({
                    type: 'setMerge',
                    ref: DatabaseRef_TaskForm_Document(res_GCK.clientKey, pr_formKey),
                    data: { published_to_prod: true, available_for_use: false },
                  })
                }
                DatabaseBatchUpdate(updateArray)
                  .then((res_DBU) => {
                    uc_setUserInterface_AlertDialogDisplay({
                      display: true,
                      alert: {
                        color: 'primary',
                        header: s_SUCCESS,
                        icon: (
                          <Icon
                            icon="circle-ok"
                            type="solid"
                          />
                        ),
                        text: s_STAGING_FORM_PUBLISHED,
                      },
                    })
                    resolve({ success: true })
                  })
                  .catch((rej_DBU) => {
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
                  })
              })
              .catch((rej_GCK) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
              })
          })
        },
      },
    })
  }

  const checkIfTagIsChecked = (tag: string, folder: TsInterface_UnspecifiedObject): boolean => {
    let checked = false
    if (tag != null && folder != null && folder['default_image_tags'] != null) {
      if (folder['default_image_tags'].indexOf(tag) > -1) {
        checked = true
      }
    }
    return checked
  }

  // JSX Generation
  const rJSX_FormPageContent = (page: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let formPageJSX = <></>
    if (page != null && page['page_type'] === 'form') {
      formPageJSX = <Box>{rJSX_TaskForm(page, editable)}</Box>
    } else if (page != null && page['page_type'] === 'file_uploads') {
      formPageJSX = <Box className="tw-text-left tw-mt-2">{rJSX_TaskFileUploadConfig(page, editable)}</Box>
    } else if (page != null && page['page_type'] === 'image_uploads') {
      formPageJSX = <Box className="tw-text-left tw-mt-2">{rJSX_TaskFileUploadConfig(page, editable)}</Box>
    } else {
      formPageJSX = (
        <Card className="tw-p-4 tw-text-center tw-mt-2">
          <Typography variant="h6">{s_NO_PAGE_TYPE_SELECTED}</Typography>
          <Box className="tw-mt-4">{rJSX_PageTypeButton(page, editable)}</Box>
        </Card>
      )
    }
    return formPageJSX
  }

  const rJSX_MLConfigurationInputs = (page: TsInterface_UnspecifiedObject, folder: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let inputsJSX = <></>
    let mlTextCssClass = ''
    let mlEvaluationProcedureInputJSX = <></>
    if (folder.run_ml_evaluation_procedure_on_images === true) {
      mlEvaluationProcedureInputJSX = (
        <Box sx={{ marginLeft: '70px !important' }}>
          <FormControl className="tw-mt-2">
            <InputLabel
              shrink={true}
              id={folder.key + '_config'}
            >
              {s_ML_EVALUATION_PROCEDURE}
            </InputLabel>
            <Select
              disabled={!editable}
              notched={true}
              sx={{ minWidth: '180px' }}
              autoWidth={true}
              id={folder.key + '_config'}
              label={s_ML_EVALUATION_PROCEDURE}
              labelId={folder.key + '_config'}
              onChange={(event: any) => {
                if (event != null && event.target != null && event.target.value != null) {
                  editFileFolderProperty(page, folder, 'associated_ml_evaluation_procedure_key', event.target.value)
                }
              }}
              value={folder.associated_ml_evaluation_procedure_key || ''}
            >
              {objectToArray(us_activeMLEvaluationProcedures)
                .sort(dynamicSort('name', 'asc'))
                .map((option: TsInterface_UnspecifiedObject, index: number) => (
                  <MenuItem
                    key={option['key']}
                    value={option['key']}
                    disabled={option['disabled']}
                  >
                    {option['name']}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
      )
    } else {
      mlTextCssClass = ' tw-opacity-30'
    }
    // Input JSX
    inputsJSX = (
      <Box className="tw-mt-2">
        <Divider />
        <Stack
          direction="row"
          className="tw-mt-2"
          spacing={1}
        >
          <Switch
            className=""
            disabled={!editable}
            checked={folder.run_ml_evaluation_procedure_on_images === true}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.checked != null) {
                editFileFolderProperty(page, folder, 'run_ml_evaluation_procedure_on_images', event.target.checked)
              }
            }}
          />
          <Box className="tw-mt-1.5">
            <Typography
              variant="body1"
              className={mlTextCssClass}
            >
              {s_RUN_ML_EVALUATION_PROCECURE_ON_IMAGES}
            </Typography>
          </Box>
        </Stack>
        {mlEvaluationProcedureInputJSX}
      </Box>
    )
    return inputsJSX
  }

  const rJSX_AllowScreenshots = (page: TsInterface_UnspecifiedObject, folder: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let inputsJSX = <></>

    // Input JSX
    inputsJSX = (
      <Box className="tw-mt-2">
        <Divider />
        <Stack
          direction="row"
          className="tw-mt-2"
          spacing={1}
        >
          <Switch
            className=""
            disabled={!editable}
            checked={folder.screenshots_not_allowed === true}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.checked != null) {
                editFileFolderProperty(page, folder, 'screenshots_not_allowed', event.target.checked)
              }
            }}
          />
          <Box className="tw-mt-1.5">
            <Typography variant="body1">{s_SCREENSHOTS_NOT_ALLOWED}</Typography>
          </Box>
        </Stack>
      </Box>
    )
    return inputsJSX
  }

  const rJSX_AdditionalFolderInputs = (page: TsInterface_UnspecifiedObject, folder: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let additionalFolderInputsJSX = <></>
    // Create Dropdown Options
    let optionsArray: TsInterface_UnspecifiedObject[] = []
    for (let loopFolderKey in us_projectDocumentFolders) {
      let loopFolder = us_projectDocumentFolders[loopFolderKey]
      optionsArray.push({ key: loopFolder.key, value: loopFolder.name })
    }
    if (page['page_type'] === 'image_uploads') {
      additionalFolderInputsJSX = (
        <Box>
          <FormControl>
            <FormLabel component="legend">{s_DEFAULT_IMAGE_TAGS}</FormLabel>
            <FormGroup className="tw-block">
              {us_availableImageTags.map((tag: string, index: number) => (
                <FormControlLabel
                  className="tw-inline-block"
                  key={index}
                  control={
                    <Checkbox
                      disabled={!editable}
                      checked={checkIfTagIsChecked(tag, folder)}
                      onChange={() => {
                        let isTagChecked = checkIfTagIsChecked(tag, folder)
                        let updateArray: string[] = []
                        if (folder.default_image_tags != null) {
                          updateArray = folder.default_image_tags
                        } else {
                          updateArray = []
                        }
                        if (isTagChecked === true) {
                          let searchIndex = updateArray.indexOf(tag)
                          if (index > -1) {
                            updateArray.splice(searchIndex, 1)
                          }
                        } else {
                          updateArray.push(tag)
                        }
                        editFileFolderProperty(page, folder, 'default_image_tags', updateArray)
                      }}
                    />
                  }
                  label={tag}
                />
              ))}
            </FormGroup>
          </FormControl>
          <Box>{rJSX_MLConfigurationInputs(page, folder, editable)}</Box>
          <Box>{rJSX_AllowScreenshots(page, folder, editable)}</Box>
        </Box>
      )
    } else if (page['page_type'] === 'file_uploads') {
      let minTextCssClass = ''
      let dropdownJSX = <></>
      if (folder.link_documents_to_project_document_system === true) {
        dropdownJSX = (
          <Box className={minTextCssClass}>
            <FormControl>
              <InputLabel
                shrink={true}
                id={folder['key']}
              >
                {s_PROJECT_FOLDER}
              </InputLabel>
              <Select
                disabled={!editable}
                notched={true}
                sx={{ minWidth: '180px' }}
                autoWidth={true}
                id={folder['key']}
                label={s_PROJECT_FOLDER}
                labelId={folder['key']}
                onChange={(event: any) => {
                  if (event != null && event.target != null && event.target.value != null) {
                    editFileFolderProperty(page, folder, 'associated_project_document_folder_key', event.target.value)
                  }
                }}
                value={folder.associated_project_document_folder_key || ''}
              >
                {optionsArray.map((option: TsInterface_UnspecifiedObject, index: number) => (
                  <MenuItem
                    key={option['key']}
                    value={option['key']}
                    disabled={option['disabled']}
                  >
                    {option['value']}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )
      } else {
        minTextCssClass = 'tw-opacity-30'
        dropdownJSX = (
          <Box className={minTextCssClass}>
            <FormControl>
              <InputLabel
                shrink={true}
                id={folder['key']}
              >
                {s_PROJECT_FOLDER}
              </InputLabel>
              <Select
                notched={true}
                sx={{ minWidth: '180px' }}
                autoWidth={true}
                id={folder['key']}
                label={s_PROJECT_FOLDER}
                labelId={folder['key']}
                disabled={true}
                value={folder.associated_project_document_folder_key || ''}
              >
                {optionsArray.map((option: TsInterface_UnspecifiedObject, index: number) => (
                  <MenuItem
                    key={option['key']}
                    value={option['key']}
                    disabled={option['disabled']}
                  >
                    {option['value']}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )
      }
      additionalFolderInputsJSX = (
        <Box>
          <Stack
            direction="row"
            spacing={1}
          >
            <Switch
              className=""
              disabled={!editable}
              checked={folder.link_documents_to_project_document_system}
              onChange={(event: any) => {
                if (event != null && event.target != null && event.target.checked != null) {
                  editFileFolderProperty(page, folder, 'link_documents_to_project_document_system', event.target.checked)
                }
              }}
            />
            <Box className="tw-mt-1.5">
              <Typography
                variant="body1"
                className={minTextCssClass}
              >
                {s_LINK_UPLOADS_TO_PROJECT_DOCUMENT_SYSTEM}
              </Typography>
            </Box>
          </Stack>
          <Box sx={{ marginLeft: '70px !important' }}>{dropdownJSX}</Box>
        </Box>
      )
    }
    return additionalFolderInputsJSX
  }

  const rJSX_FileUploadFolder = (
    page: TsInterface_UnspecifiedObject,
    folder: TsInterface_UnspecifiedObject,
    editable: boolean,
    type: 'folder' | 'instruction' = 'folder',
  ): JSX.Element => {
    let fullFolderJSX = <></>
    let folderJSX = <></>
    let minUploadCountInputJSX = <></>
    let maxUploadCountInputJSX = <></>
    let warningBannerJSX = <></>
    let minTextCssClass = ''
    let maxTextCssClass = ''

    if (type === 'folder' && folder.require_min_file_upload === true) {
      minUploadCountInputJSX = (
        <Box>
          <FormControl>
            <TextField
              color="primary"
              value={folder.min_file_upload_count}
              label={s_MINIMUM_FILE_UPLOADS}
              margin="normal"
              type="number"
              // @ts-expect-error - TODO: reason for error
              onWheel={(event) => event.target.blur()}
              onChange={(event: any) => {
                if (event?.target?.value != null) {
                  editFileFolderState(page, folder, 'min_file_upload_count', event.target.value)
                }
              }}
              onBlur={(event: any) => {
                if (event?.target?.value != null) {
                  const updateValue = isNaN(parseInt(event.target.value)) ? 0 : parseInt(event.target.value)
                  editFileFolderProperty(page, folder, 'min_file_upload_count', updateValue)
                }
              }}
              disabled={!editable}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
        </Box>
      )
    } else if (type === 'instruction') {
      // Render input for instructions
      minUploadCountInputJSX = (
        <Box>
          <FormControl>
            <TextField
              color="primary"
              value={folder.text} // Use `text` for instructions
              label="Instruction Text"
              margin="normal"
              type="text"
              onChange={(event: any) => {
                if (event?.target?.value != null) {
                  console.log('Typing in instruction:', folder.key, 'Value:', event.target.value)
                  editFileFolderState(page, folder, 'text', event.target.value) // Update `text` locally
                }
              }}
              onBlur={(event: any) => {
                if (event?.target?.value != null) {
                  console.log('Blurred instruction field:', folder.key, 'Final Value:', event.target.value)
                  editFileFolderProperty(page, folder, 'text', event.target.value) // Persist `text` in the database
                }
              }}
              disabled={!editable}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              placeholder="Enter your instruction here"
            />
          </FormControl>
        </Box>
      )
    } else {
      minTextCssClass = ' tw-opacity-30'
      minUploadCountInputJSX = (
        <Box className={minTextCssClass}>
          <FormControl>
            <TextField
              color="primary"
              value={folder.min_file_upload_count}
              label={s_MINIMUM_FILE_UPLOADS}
              margin="normal"
              type="number"
              // @ts-expect-error - TODO: reason for error
              onWheel={(event) => event.target.blur()}
              disabled={true}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
        </Box>
      )
    }

    if (type === 'folder' && folder.require_max_file_upload === true) {
      maxUploadCountInputJSX = (
        <Box>
          <FormControl>
            <TextField
              color="primary"
              value={folder.max_file_upload_count}
              label={s_MAXIMUM_FILE_UPLOADS}
              margin="normal"
              type="number"
              // @ts-expect-error - TODO: reason for error
              onWheel={(event) => event.target.blur()}
              onChange={(event: any) => {
                if (event?.target?.value != null) {
                  editFileFolderState(page, folder, 'max_file_upload_count', event.target.value)
                }
              }}
              onBlur={(event: any) => {
                if (event?.target?.value != null) {
                  const updateValue = isNaN(parseInt(event.target.value)) ? 0 : parseInt(event.target.value)
                  editFileFolderProperty(page, folder, 'max_file_upload_count', updateValue)
                }
              }}
              disabled={!editable}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
        </Box>
      )
    } else if (type === 'folder') {
      maxTextCssClass = ' tw-opacity-30'
      maxUploadCountInputJSX = (
        <Box className={maxTextCssClass}>
          <FormControl>
            <TextField
              color="primary"
              value={folder.max_file_upload_count}
              label={s_MAXIMUM_FILE_UPLOADS}
              margin="normal"
              type="number"
              // @ts-expect-error - TODO: reason for error
              onWheel={(event) => event.target.blur()}
              disabled={true}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
        </Box>
      )
    }

    // Warning Banner JSX
    if (
      type === 'folder' &&
      folder.require_min_file_upload === true &&
      folder.require_max_file_upload === true &&
      folder.min_file_upload_count > folder.max_file_upload_count
    ) {
      warningBannerJSX = (
        <Box
          className="tw-p-2"
          sx={{ backgroundColor: themeVariables.error_main }}
        >
          <Typography
            variant="body1"
            sx={{ color: themeVariables.white }}
          >
            <Icon
              icon="triangle-exclamation"
              className="tw-mr-2"
            />
            {s_ERROR_MINIMUM_UPLOAD_LIMIT_CANNOT_EXCEED_MAXIMUM_LIMIT}
          </Typography>
        </Box>
      )
    }

    folderJSX = (
      <Card>
        {warningBannerJSX}
        <Box className="tw-py-4 tw-p-4">
          <Stack
            direction="row"
            className="tw-width-100"
            spacing={1}
          >
            <FormControl
              className="tw-w-1/2"
              sx={{ marginTop: '0px !important' }}
            >
              <TextField
                id={page.key + '_' + folder.key}
                disabled={!editable}
                sx={{ marginTop: '0px !important' }}
                color="primary"
                value={type === 'instruction' ? folder.text || '' : folder.name} // Use `text` for instructions, fallback to empty string
                label={type === 'instruction' ? 'Instructions' : s_FOLDER_NAME}
                margin="normal"
                onChange={(event: any) => {
                  if (event?.target?.value != null) {
                    if (type === 'instruction') {
                      editFileFolderState(page, folder, 'text', event.target.value) // Update state for instructions
                    } else {
                      editFileFolderState(page, folder, 'name', event.target.value) // Update state for folders
                    }
                  }
                }}
                onBlur={(event: any) => {
                  if (event?.target?.value != null) {
                    if (type === 'instruction') {
                      editFileFolderProperty(page, folder, 'text', event.target.value) // Persist instructions in database
                    } else {
                      editFileFolderProperty(page, folder, 'name', event.target.value) // Persist folders in database
                    }
                  }
                }}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Stack>
          {/* <Divider className="tw-mb-5 tw-mt-1" /> */}
          {type === 'folder' && (
            <>
              <Stack
                direction="row"
                spacing={1}
              >
                <Switch
                  disabled={!editable}
                  checked={folder.require_min_file_upload}
                  onChange={(event: any) => {
                    if (event?.target?.checked != null) {
                      editFileFolderProperty(page, folder, 'require_min_file_upload', event.target.checked)
                    }
                  }}
                />
                <Box className="tw-mt-1.5">
                  <Typography
                    variant="body1"
                    className={minTextCssClass}
                  >
                    {s_REQUIRE_MINIMUM_FILE_UPLOADS}
                  </Typography>
                </Box>
              </Stack>
              <Box sx={{ marginLeft: '70px !important' }}>{minUploadCountInputJSX}</Box>
              <Stack
                direction="row"
                spacing={1}
              >
                <Switch
                  disabled={!editable}
                  checked={folder.require_max_file_upload}
                  onChange={(event: any) => {
                    if (event?.target?.checked != null) {
                      editFileFolderProperty(page, folder, 'require_max_file_upload', event.target.checked)
                    }
                  }}
                />
                <Box className="tw-mt-1.5">
                  <Typography
                    variant="body1"
                    className={maxTextCssClass}
                  >
                    {s_REQUIRE_MAXIMUM_FILE_UPLOADS}
                  </Typography>
                </Box>
              </Stack>
              <Box sx={{ marginLeft: '70px !important' }}>{maxUploadCountInputJSX}</Box>
            </>
          )}
          {type === 'folder' && <Box>{rJSX_AdditionalFolderInputs(page, folder, editable)}</Box>}
        </Box>
      </Card>
    )

    fullFolderJSX = (
      <Box className="tw-my-2">
        <Stack
          direction="row"
          sx={{ width: '100% !important' }}
        >
          <Box sx={{ width: '100% !important' }}>{folderJSX}</Box>
          <Stack
            direction="column"
            spacing={1}
            className="tw-m-2"
          >
            {/* Move Up */}
            <Box
              className="tw-text-center tw-opacity-10 hover:tw-opacity-100 tw-cursor-pointer"
              onClick={() => {
                if (editable) {
                  moveFileFolderUp(page, folder) // Works for both folders and instructions
                }
              }}
            >
              <Icon
                icon="up"
                size="xl"
                type="solid"
              />
            </Box>

            {/* Delete */}
            <Box
              className="tw-text-center tw-opacity-10 hover:tw-opacity-100 tw-cursor-pointer"
              onClick={() => {
                if (editable) {
                  deleteFileFolder(page, folder) // Works for both folders and instructions
                }
              }}
            >
              <Icon
                icon="trash"
                size="xl"
                type="solid"
              />
            </Box>

            {/* Move Down */}
            <Box
              className="tw-text-center tw-opacity-10 hover:tw-opacity-100 tw-cursor-pointer"
              onClick={() => {
                if (editable) {
                  moveFileFolderDown(page, folder) // Works for both folders and instructions
                }
              }}
            >
              <Icon
                icon="down"
                size="xl"
                type="solid"
              />
            </Box>
          </Stack>
        </Stack>
      </Box>
    )

    return fullFolderJSX
  }

  const rJSX_TaskFileUploadConfig = (page: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let taskFileUploadConfigJSX = <></>

    taskFileUploadConfigJSX = (
      <Box>
        {/* Render Folders and Instructions */}
        <Box>
          {objectToArray(page.folders)
            .sort(dynamicSort('order', null))
            .map((folder, folderIndex) => (
              <Box key={`folder-${folderIndex}`}>{rJSX_FileUploadFolder(page, folder, editable, folder.type === 'instruction' ? 'instruction' : 'folder')}</Box>
            ))}
        </Box>

        {/* Action Buttons */}
        <Box className="tw-mt-2">
          <Stack
            direction="row"
            spacing={1}
          >
            {/* Add Folder Button */}
            <Button
              color="success"
              variant="contained"
              startIcon={<Icon icon="plus-circle"></Icon>}
              disabled={!editable}
              onClick={() => {
                if (editable === true) {
                  addFileFolderToPage(page)
                }
              }}
            >
              {s_ADD_FILE_FOLDER}
            </Button>

            {/* Add Instructions Button */}
            <Button
              color="info"
              variant="contained"
              startIcon={<Icon icon="plus-circle"></Icon>}
              disabled={!editable}
              onClick={() => {
                if (editable === true) {
                  addInstructionsQuestionToPage(page)
                }
              }}
            >
              {'Add Instructions'}
            </Button>

            {/* Preview Button */}
            <Button
              color="warning"
              variant="contained"
              disabled={true}
              startIcon={<Icon icon="magnifying-glass"></Icon>}
              onClick={() => {
                // previewForm(page);
              }}
            >
              {s_PREVIEW}
            </Button>
          </Stack>
        </Box>
      </Box>
    )

    return taskFileUploadConfigJSX
  }

  // INSTRUCTIONS QUESTION
  const addInstructionsQuestionToPage = (page: TsInterface_UnspecifiedObject): void => {
    const timestamp = new Date().getTime()
    const updateObject: TsInterface_UnspecifiedObject = { folders: {} }

    // Add an instruction to the `folders` section
    updateObject['folders'][timestamp.toString()] = {
      key: timestamp.toString(),
      order: timestamp,
      type: 'instruction', // Mark as instruction
      name: 'New Instruction', // Default name for the instruction
      text: '', // Instruction text, can be updated by the user
      require_min_file_upload: false, // Set folder-specific fields to false
      require_max_file_upload: false,
      min_file_upload_count: 0,
      max_file_upload_count: 0,
    }

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseSetMergeDocument(DatabaseRef_TaskFormStagingPages_Document(res_GCK.clientKey, pr_formKey, page.key), updateObject)
          .then(() => {
            // Success logic (optional)
          })
          .catch((error) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error.error })
          })
      })
      .catch((error) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error.error })
      })
  }

  // const rJSX_PageInstructions = ( page: TsInterface_UnspecifiedObject, editable: boolean ): JSX.Element => {
  // let instructionsJSX =
  // <FormControl fullWidth >
  // 	<TextField
  // 		color="primary"
  // 		value={ page.page_instructions }
  // 		label={ s_INSTRUCTIONS }
  // 		margin="normal"
  // 		multiline
  // 		type="text"
  // 		disabled={ !editable }
  // 		// @ts-expect-error - TODO: reason for error
  // 		onWheel={ ( event ) => event.target.blur() }
  // 		onChange={ (event: any) => {
  // 			if ( event != null && event.target != null && event.target.value != null ){
  // 				editPageState( page, "page_instructions", event.target.value )
  // 			}
  // 		} }
  // 		onBlur={ ( event: any ) => {
  // 			if ( event != null && event.target != null && event.target.value != null ){
  // 				updatePageProperty( page, "page_instructions", event.target.value )
  // 			}
  // 		} }
  // 		variant="outlined"
  // 		InputLabelProps={{ shrink: true }}
  // 	/>
  // </FormControl>
  // return instructionsJSX
  // }

  // availableTaskFormConfigFields

  const rJSX_TaskForm = (page: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let taskFormJSX = <></>
    let taskQuestionsJSX = <></>
    if (page != null && page.questions != null && objectToArray(page.questions).length > 0) {
      taskQuestionsJSX = (
        <Box>
          {objectToArray(page.questions)
            .sort(dynamicSort('order', null))
            .map((question, questionIndex) => (
              <Box key={questionIndex}>{rJSX_TaskQuestion(page, question, editable)}</Box>
            ))}
        </Box>
      )
    } else {
      taskQuestionsJSX = (
        <Box>
          <Card className="tw-text-center tw-py-4 tw-p-4 tw-my-2">
            <Typography variant="h6">{s_NO_QUESTIONS_ADDED_YET}</Typography>
          </Card>
        </Box>
      )
    }
    taskFormJSX = (
      <Box>
        {taskQuestionsJSX}
        <Box className="tw-mt-2">
          <Stack
            direction="row"
            spacing={1}
          >
            <Button
              color="success"
              variant="contained"
              startIcon={<Icon icon="plus-circle"></Icon>}
              disabled={!editable}
              onClick={() => {
                if (editable === true) {
                  addQuestionToPage(page)
                }
              }}
            >
              {s_ADD_QUESTION}
            </Button>
            <Button
              color="warning"
              variant="contained"
              startIcon={<Icon icon="magnifying-glass"></Icon>}
              onClick={() => {
                previewForm(page)
              }}
            >
              {s_PREVIEW}
            </Button>
          </Stack>
        </Box>
      </Box>
    )

    return taskFormJSX
  }

  const rJSX_TaskQuestion = (page: TsInterface_UnspecifiedObject, question: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let taskQuestionFullJSX = <></>
    let taskQuestionJSX = <></>
    // Top Inputs JSX
    let topInputsJSX = question.input_type !== 'instructions' && (
      <Box>
        <Stack
          direction="row"
          className="tw-width-100"
          spacing={1}
        >
          <FormControl
            className="tw-w-1/2"
            sx={{ marginTop: '0px !important' }}
          >
            <TextField
              id={page.key + '_' + question.key}
              disabled={!editable}
              sx={{ marginTop: '0px !important' }}
              color="primary"
              value={question.label}
              label={s_QUESTION_LABEL}
              margin="normal"
              onChange={(event: any) => {
                if (event != null && event.target != null && event.target.value != null) {
                  editQuestionState(page, question, 'label', event.target.value)
                }
              }}
              onBlur={(event: any) => {
                if (event != null && event.target != null && event.target.value != null) {
                  editQuestionProperty(page, question, 'label', event.target.value)
                }
              }}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
          <Select
            disabled={!editable}
            className="tw-w-1/2"
            color="primary"
            value={question.input_type || ''}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                editQuestionProperty(page, question, 'input_type', event.target.value)
              }
            }}
            variant="outlined"
            notched={true}
            sx={{ height: '56px' }}
          >
            {objectToArray(questionTypeOptions).map((option) => (
              <MenuItem
                key={option['key']}
                value={option['key']}
                disabled={option['disabled'] === true}
              >
                <ListItemIcon className="tw-inline-block">{option['icon']}</ListItemIcon>
                <ListItemText className="tw-inline-block">{option['value']}</ListItemText>
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </Box>
    )
    // Addtional Inputs JSX
    let captionInputJSX = question.input_type !== 'instructions' && (
      <FormControl
        className="tw-w-1/2"
        sx={{ marginTop: '0px !important' }}
      >
        <TextField
          id={page.key + '_' + question.key}
          disabled={!editable}
          sx={{ marginTop: '0px !important' }}
          color="primary"
          value={question.caption}
          label={s_QUESTION_CAPTION}
          margin="normal"
          onChange={(event: any) => {
            if (event != null && event.target != null && event.target.value != null) {
              editQuestionState(page, question, 'caption', event.target.value)
            }
          }}
          onBlur={(event: any) => {
            if (event != null && event.target != null && event.target.value != null) {
              editQuestionProperty(page, question, 'caption', event.target.value)
            }
          }}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
        />
      </FormControl>
    )
    let placeholderInputJSX = (
      <FormControl
        className="tw-w-1/2"
        sx={{ marginTop: '0px !important' }}
      >
        <TextField
          id={page.key + '_' + question.key}
          disabled={!editable}
          sx={{ marginTop: '0px !important' }}
          color="primary"
          value={question.placeholder}
          label={s_QUESTION_PLACEHOLDER}
          margin="normal"
          onChange={(event: any) => {
            if (event != null && event.target != null && event.target.value != null) {
              editQuestionState(page, question, 'placeholder', event.target.value)
            }
          }}
          onBlur={(event: any) => {
            if (event != null && event.target != null && event.target.value != null) {
              editQuestionProperty(page, question, 'placeholder', event.target.value)
            }
          }}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
        />
      </FormControl>
    )
    // Add Option Button JSX
    let addOptionButtonJSX = (
      <Button
        color="inherit"
        variant="outlined"
        startIcon={<Icon icon="circle-plus"></Icon>}
        className="tw-mb-4"
        disabled={!editable}
        onClick={() => {
          uc_setUserInterface_PromptDialogDisplay({
            display: true,
            prompt: {
              color: 'info',
              confirm_text: s_ADD_OPTION,
              default_value: null,
              header: s_ADD_OPTION,
              icon: (
                <Icon
                  icon="circle-plus"
                  type="solid"
                />
              ),
              input_label: s_OPTION_NAME,
              input_type: 'text',
              text: s_ENTER_A_NAME_FOR_YOUR_NEW_OPTION,
              submit_callback: (promptValue: string) => {
                return new Promise((resolve, reject) => {
                  let optionTimestamp = new Date().getTime()
                  let updateObject: TsInterface_UnspecifiedObject = { questions: {} }
                  updateObject['questions'][question.key] = { options: {} }
                  updateObject['questions'][question.key]['options'] = {}
                  updateObject['questions'][question.key]['options'][optionTimestamp.toString()] = {
                    key: optionTimestamp.toString(),
                    order: optionTimestamp,
                    option_label: promptValue,
                  }
                  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseSetMergeDocument(DatabaseRef_TaskFormStagingPages_Document(res_GCK.clientKey, pr_formKey, page.key), updateObject)
                        .then((res_DSMD) => {
                          ur_forceRerender()
                          resolve(res_DSMD)
                        })
                        .catch((rej_DSMD) => {
                          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                })
              },
            },
          })
        }}
      >
        {s_ADD_OPTION}
      </Button>
    )
    // Required Button
    let requiredToggleJSX = (
      <Box>
        <Grid2
          container
          spacing={1}
        >
          <Grid2
            xs={6}
            md={6}
            lg={6}
          >
            <FormControl sx={{ width: '100%' }}>
              <InputLabel
                shrink={true}
                id={question.key + '_config'}
              >
                {s_ADDITIONAL_CONFIG}
              </InputLabel>
              <Select
                disabled={!editable}
                notched={true}
                sx={{ minWidth: '180px' }}
                autoWidth={true}
                id={question.key + '_config'}
                label={s_ADDITIONAL_CONFIG}
                labelId={question.key + '_config'}
                onChange={(event: any) => {
                  if (event != null && event.target != null && event.target.value != null) {
                    editQuestionProperty(page, question, 'additional_task_config_option', event.target.value)
                  }
                }}
                value={question.additional_task_config_option || ''}
              >
                {objectToArray(us_availableTaskFormConfigFields).map((option: TsInterface_UnspecifiedObject, index: number) => (
                  <MenuItem
                    key={option['key']}
                    value={option['key']}
                    disabled={option['disabled']}
                  >
                    {option['value']}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid2>
          <Grid2
            xs={6}
            md={6}
            lg={6}
          >
            <Box className="tw-text-right">
              {rLIB('Required')}
              <Switch
                disabled={!editable}
                checked={question.required}
                onChange={(event: any) => {
                  if (event != null && event.target != null && event.target.checked != null) {
                    editQuestionProperty(page, question, 'required', event.target.checked)
                  }
                }}
              />
            </Box>
          </Grid2>
        </Grid2>
      </Box>
    )
    // Rename Option Button JSX
    const rJSX_OptionEditButton = (option: TsInterface_UnspecifiedObject) => {
      let editOptionButtonJSX = <></>
      if (editable === true) {
        editOptionButtonJSX = (
          <Box
            className="tw-inline-block tw-cursor-pointer tw-mx-2 tw-opacity-10 hover:tw-opacity-100"
            onClick={() => {
              uc_setUserInterface_PromptDialogDisplay({
                display: true,
                prompt: {
                  color: 'info',
                  confirm_text: s_UPDATE_OPTION,
                  default_value: option.option_label,
                  header: s_UPDATE_OPTION,
                  icon: (
                    <Icon
                      icon="pen-to-square"
                      type="solid"
                    />
                  ),
                  input_label: s_OPTION_NAME,
                  input_type: 'text',
                  text: s_ENTER_A_NAME_FOR_THIS_OPTION,
                  submit_callback: (promptValue: string) => {
                    return new Promise((resolve, reject) => {
                      let updateObject: TsInterface_UnspecifiedObject = { questions: {} }
                      updateObject['questions'][question.key] = { options: {} }
                      updateObject['questions'][question.key]['options'] = {}
                      updateObject['questions'][question.key]['options'][option.key] = {
                        option_label: promptValue,
                      }
                      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          DatabaseSetMergeDocument(DatabaseRef_TaskFormStagingPages_Document(res_GCK.clientKey, pr_formKey, page.key), updateObject)
                            .then((res_DSMD) => {
                              ur_forceRerender()
                              resolve(res_DSMD)
                            })
                            .catch((rej_DSMD) => {
                              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            })
                        })
                        .catch((rej_GCK) => {
                          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        })
                    })
                  },
                },
              })
            }}
          >
            <Icon
              icon="pen-to-square"
              type="solid"
            />
          </Box>
        )
      }
      return editOptionButtonJSX
    }
    // Delete Option Button JSX
    const rJSX_OptionDeleteButton = (option: TsInterface_UnspecifiedObject) => {
      let deleteOptionButtonJSX = <></>
      if (editable === true) {
        deleteOptionButtonJSX = (
          <Box
            className="tw-inline-block tw-cursor-pointer tw-ml-2 tw-opacity-10 hover:tw-opacity-100"
            onClick={() => {
              uc_setUserInterface_ConfirmDialogDisplay({
                display: true,
                confirm: {
                  color: 'error',
                  header: s_DELETE_OPTION,
                  icon: (
                    <Icon
                      icon="trash"
                      type="solid"
                    />
                  ),
                  submit_text: s_DELETE,
                  text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_OPTION,
                  submit_callback: () => {
                    return new Promise((resolve, reject) => {
                      let updateObject: TsInterface_UnspecifiedObject = { questions: page.questions }
                      delete updateObject['questions'][question.key]['options'][option.key]
                      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          DatabaseUpdateDocument(DatabaseRef_TaskFormStagingPages_Document(res_GCK.clientKey, pr_formKey, page.key), updateObject)
                            .then((res_DUD) => {
                              ur_forceRerender()
                              resolve(res_DUD)
                            })
                            .catch((rej_DUD) => {
                              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DUD.error })
                            })
                        })
                        .catch((rej_GCK) => {
                          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        })
                    })
                  },
                },
              })
            }}
          >
            <Icon
              icon="trash"
              type="solid"
            />
          </Box>
        )
      }
      return deleteOptionButtonJSX
    }
    // Option
    const rJSX_OptionLabel = (icon: string, option: TsInterface_UnspecifiedObject, editable: boolean) => {
      let optionLabelJSX = <></>
      let optionLabel = ''
      let className = 'tw-inline-block'
      if (option != null && option.option_label != null) {
        optionLabel = option.option_label
      } else if (option != null && option.value != null) {
        optionLabel = option.value
      }
      if (editable === false) {
        className = 'tw-inline-block tw-opacity-30'
      }

      optionLabelJSX = (
        <Box className={className}>
          <Icon
            icon={icon}
            type="solid"
            className="tw-mr-2 tw-inline-block"
          />
          <Typography
            className="tw-inline-block"
            variant="body1"
          >
            {optionLabel}
          </Typography>
        </Box>
      )

      return optionLabelJSX
    }
    // Individual Questions
    if (question.input_type == null) {
      taskQuestionJSX = (
        <Box className="tw-p-4 tw-my-2">
          {topInputsJSX}
          <Box>
            <Stack
              direction="row"
              className="tw-width-100"
              spacing={1}
            >
              {captionInputJSX}
            </Stack>
          </Box>
          <Divider className="tw-mb-5 tw-mt-1" />
        </Box>
      )
    } else if (question.input_type === 'short_answer') {
      taskQuestionJSX = (
        <Box className="tw-p-4 tw-my-2">
          {topInputsJSX}
          <Box>
            <Stack
              direction="row"
              className="tw-width-100"
              spacing={1}
            >
              {captionInputJSX}
              {placeholderInputJSX}
            </Stack>
          </Box>
          <Divider className="tw-mb-5 tw-mt-1" />
          {requiredToggleJSX}
        </Box>
      )
    } else if (question.input_type === 'paragraph') {
      taskQuestionJSX = (
        <Box className="tw-p-4 tw-my-2">
          {topInputsJSX}
          <Box>
            <Stack
              direction="row"
              className="tw-width-100"
              spacing={1}
            >
              {captionInputJSX}
              {placeholderInputJSX}
            </Stack>
          </Box>
          <Divider className="tw-mb-5 tw-mt-1" />
          {requiredToggleJSX}
        </Box>
      )
    } else if (question.input_type === 'multiple_choice') {
      taskQuestionJSX = (
        <Box className="tw-p-4 tw-my-2">
          {topInputsJSX}
          <Box>
            <Stack
              direction="row"
              className="tw-width-100"
              spacing={1}
            >
              {captionInputJSX}
            </Stack>
          </Box>
          <Divider className="tw-mb-5 tw-mt-1" />
          <Box>
            {objectToArray(question.options)
              .sort(dynamicSort('order', null))
              .map((option, index) => (
                <Box
                  key={index}
                  className="tw-my-1"
                >
                  {rJSX_OptionLabel('circle-dot', option, editable)}
                  {rJSX_OptionEditButton(option)}
                  {rJSX_OptionDeleteButton(option)}
                </Box>
              ))}
            {addOptionButtonJSX}
          </Box>
          <Divider className="tw-mb-5 tw-mt-1" />
          {requiredToggleJSX}
        </Box>
      )
    } else if (question.input_type === 'checkboxes') {
      taskQuestionJSX = (
        <Box className="tw-p-4 tw-my-2">
          {topInputsJSX}
          <Box>
            <Stack
              direction="row"
              className="tw-width-100"
              spacing={1}
            >
              {captionInputJSX}
            </Stack>
          </Box>
          <Divider className="tw-mb-5 tw-mt-1" />
          <Box>
            {objectToArray(question.options)
              .sort(dynamicSort('order', null))
              .map((option, index) => (
                <Box
                  key={index}
                  className="tw-my-1"
                >
                  {rJSX_OptionLabel('square-check', option, editable)}
                  {rJSX_OptionEditButton(option)}
                  {rJSX_OptionDeleteButton(option)}
                </Box>
              ))}
            {addOptionButtonJSX}
          </Box>
          <Divider className="tw-mb-5 tw-mt-1" />
          {requiredToggleJSX}
        </Box>
      )
    } else if (question.input_type === 'dropdown') {
      taskQuestionJSX = (
        <Box className="tw-p-4 tw-my-2">
          {topInputsJSX}
          <Box>
            <Stack
              direction="row"
              className="tw-width-100"
              spacing={1}
            >
              {captionInputJSX}
              {placeholderInputJSX}
            </Stack>
          </Box>
          <Divider className="tw-mb-5 tw-mt-1" />
          <Box>
            {objectToArray(question.options)
              .sort(dynamicSort('order', null))
              .map((option, index) => (
                <Box
                  key={index}
                  className="tw-my-1"
                >
                  {rJSX_OptionLabel('circle-caret-down', option, editable)}
                  {rJSX_OptionEditButton(option)}
                  {rJSX_OptionDeleteButton(option)}
                </Box>
              ))}
            {addOptionButtonJSX}
          </Box>
          <Divider className="tw-mb-5 tw-mt-1" />
          {requiredToggleJSX}
        </Box>
      )
    } else if (question.input_type === 'date') {
      taskQuestionJSX = (
        <Box className="tw-p-4 tw-my-2">
          {topInputsJSX}
          <Box>
            <Stack
              direction="row"
              className="tw-width-100"
              spacing={1}
            >
              {captionInputJSX}
              {placeholderInputJSX}
            </Stack>
          </Box>
          <Divider className="tw-mb-5 tw-mt-1" />
          {requiredToggleJSX}
        </Box>
      )
    } else if (question.input_type === 'time') {
      taskQuestionJSX = (
        <Box className="tw-p-4 tw-my-2">
          {topInputsJSX}
          <Box>
            <Stack
              direction="row"
              className="tw-width-100"
              spacing={1}
            >
              {captionInputJSX}
              {placeholderInputJSX}
            </Stack>
          </Box>
          <Divider className="tw-mb-5 tw-mt-1" />
          {requiredToggleJSX}
        </Box>
      )
    } else if (question.input_type === 'datetime') {
      taskQuestionJSX = (
        <Box className="tw-p-4 tw-my-2">
          {topInputsJSX}
          <Box>
            <Stack
              direction="row"
              className="tw-width-100"
              spacing={1}
            >
              {captionInputJSX}
              {placeholderInputJSX}
            </Stack>
          </Box>
          <Divider className="tw-mb-5 tw-mt-1" />
          {requiredToggleJSX}
        </Box>
      )
    } else if (question.input_type === 'toggle') {
      taskQuestionJSX = (
        <Box className="tw-p-4 tw-my-2">
          {topInputsJSX}
          <Box>
            <Stack
              direction="row"
              className="tw-width-100"
              spacing={1}
            >
              {captionInputJSX}
            </Stack>
          </Box>
          <Divider className="tw-mb-5 tw-mt-1" />
          {requiredToggleJSX}
        </Box>
      )
    } else if (question.input_type === 'instructions') {
      taskQuestionJSX = (
        <Box className="tw-p-4 tw-my-2">
          {topInputsJSX} {/* Render the top inputs like label and input type selector */}
          <Box>
            <Stack
              direction="row"
              className="tw-width-100"
              spacing={1}
            ></Stack>
          </Box>
          <Box>
            <FormControl
              className="tw-w-full"
              sx={{ marginTop: '0px !important' }}
            >
              <TextField
                id={page.key + '_' + question.key}
                disabled={!editable} // Editable in setup, read-only in the form
                sx={{ marginTop: '0px !important' }}
                color="primary"
                value={question.instructions}
                label={'Instructions'} // Use a constant like 'Instructions Text'
                multiline
                rows={4}
                margin="normal"
                onChange={(event: any) => {
                  if (event && event.target && event.target.value != null) {
                    editQuestionState(page, question, 'instructions', event.target.value)
                  }
                }}
                onBlur={(event: any) => {
                  if (event && event.target && event.target.value != null) {
                    editQuestionProperty(page, question, 'instructions', event.target.value)
                  }
                }}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Box>
        </Box>
      )
    } else {
      taskQuestionJSX = (
        <Box className="tw-p-4 tw-my-2">
          <Json data={question} />
        </Box>
      )
    }
    // Full Question
    taskQuestionFullJSX = (
      <Box className="tw-my-2">
        <Stack
          direction="row"
          sx={{ width: '100% !important' }}
        >
          <Card sx={{ width: '100% !important' }}>{taskQuestionJSX}</Card>
          <Stack
            direction="column"
            spacing={1}
            className="tw-m-2"
          >
            <Box
              className="tw-text-center tw-opacity-10 hover:tw-opacity-100 tw-cursor-pointer"
              onClick={() => {
                if (editable === true) {
                  moveQuestionUp(page, question)
                }
              }}
            >
              <Icon
                icon="up"
                size="xl"
                type="solid"
              />
            </Box>
            <Box
              className="tw-text-center tw-opacity-10 hover:tw-opacity-100 tw-cursor-pointer"
              onClick={() => {
                if (editable === true) {
                  deleteQuestion(page, question)
                }
              }}
            >
              <Icon
                icon="trash"
                size="xl"
                type="solid"
              />
            </Box>
            <Box
              className="tw-text-center tw-opacity-10 hover:tw-opacity-100 tw-cursor-pointer"
              onClick={() => {
                if (editable === true) {
                  moveQuestionDown(page, question)
                }
              }}
            >
              <Icon
                icon="down"
                size="xl"
                type="solid"
              />
            </Box>
          </Stack>
        </Stack>
      </Box>
    )
    return taskQuestionFullJSX
  }

  const rJSX_RenamePageButton = (page: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let buttonJSX = (
      <Button
        className="tw-mr-2 tw-mt-2"
        color="info"
        variant="contained"
        startIcon={<Icon icon="pen-to-square"></Icon>}
        disabled={!editable}
        onClick={() => {
          if (editable === true) {
            renamePage(page)
          }
        }}
      >
        {s_RENAME_PAGE}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_DeletePageButton = (page: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let buttonJSX = (
      <Button
        className="tw-mr-2 tw-mt-2"
        color="error"
        variant="contained"
        startIcon={<Icon icon="trash"></Icon>}
        disabled={!editable}
        onClick={() => {
          if (editable === true) {
            deletePage(page)
          }
        }}
      >
        {s_DELETE_PAGE}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_PageTypeButton = (page: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let buttonTextJSX = <></>
    let buttonIcon = (
      <Icon
        type="solid"
        icon="list-radio"
      />
    )
    if (
      page != null &&
      page['page_type'] != null &&
      pageTypeOptions != null &&
      pageTypeOptions[page['page_type']] != null &&
      pageTypeOptions[page['page_type']]['value'] != null
    ) {
      buttonTextJSX = <>{pageTypeOptions[page['page_type']]['value']}</>
    } else {
      buttonTextJSX = <>{s_PAGE_TYPE}</>
    }
    if (
      page != null &&
      page['page_type'] != null &&
      pageTypeOptions != null &&
      pageTypeOptions[page['page_type']] != null &&
      pageTypeOptions[page['page_type']]['icon'] != null
    ) {
      buttonIcon = <>{pageTypeOptions[page['page_type']]['icon']}</>
    }
    let buttonJSX = (
      <Button
        className="tw-mr-2 tw-mt-2"
        color="inherit"
        variant="outlined"
        startIcon={buttonIcon}
        disabled={!editable}
        onClick={() => {
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: page,
                formInputs: formInputs_TaskFormPageType,
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        DatabaseSetMergeDocument(DatabaseRef_TaskFormStagingPages_Document(res_GCK.clientKey, pr_formKey, page.key), formSubmittedData)
                          .then((res_DSMD) => {
                            ur_forceRerender()
                            resolve(res_DSMD)
                          })
                          .catch((rej_DSMD) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                          })
                      })
                      .catch((rej_GCK) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: s_EDIT_PAGE_TYPE,
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="list-radio"
                  />
                ),
              },
            },
          })
        }}
      >
        {buttonTextJSX}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_AddFormPage = (editable: boolean): JSX.Element => {
    let addPageButtonJSX = (
      <Button
        className="tw-mr-2 tw-mt-2"
        color="success"
        variant="contained"
        startIcon={<Icon icon="circle-plus"></Icon>}
        disabled={!editable}
        onClick={() => {
          uc_setUserInterface_PromptDialogDisplay({
            display: true,
            prompt: addFormPagePromptObject,
          })
        }}
      >
        {s_ADD_PAGE}
      </Button>
    )
    return addPageButtonJSX
  }

  const rJSX_StagingFormTabs = (): JSX.Element => {
    let formTabsJSX = <></>
    if (us_initialStagingDataLoaded === true) {
      if (us_stagingTaskFormPages == null || objectToArray(us_stagingTaskFormPages).length === 0) {
        formTabsJSX = (
          <Box className="tw-text-center tw-my-10">
            <Typography
              variant="h6"
              className="tw-mb-2"
            >
              {s_NO_PAGES_ADDED_TO_FORM_YET}
            </Typography>
            {rJSX_AddFormPage(true)}
          </Box>
        )
      } else if (us_loadingPage === true) {
        formTabsJSX = (
          <Box className="tw-p-4 tw-text-center">
            <CircularProgress
              size="100px"
              variant="indeterminate"
              color="secondary"
              className="tw-m-auto"
            />
          </Box>
        )
      } else {
        formTabsJSX = (
          <Box>
            <TabsBasic
              tabs={returnTaskFormPages(us_stagingTaskFormPages, true)}
              tabsSettings={{ color: 'secondary' }}
            />
          </Box>
        )
      }
    } else {
      formTabsJSX = (
        <Box className="tw-p-4 tw-text-center">
          <CircularProgress
            size="100px"
            variant="indeterminate"
            color="secondary"
            className="tw-m-auto"
          />
        </Box>
      )
    }
    return formTabsJSX
  }

  const rJSX_TaskInstructionsTabs = (taskInstructions: TsInterface_UnspecifiedObject): JSX.Element => {
    let tabsJSX = <></>
    let editable = true
    if (us_stagingInstructionsLoaded === true) {
      tabsJSX = (
        <Box
          className="tw-m-auto"
          sx={{ maxWidth: '800px' }}
        >
          <Card className="tw-p-4">
            {/* <ReactQuill theme="snow" value={richContentEditorValue} onChange={setRichContentEditorValue} /> */}
            <ReactQuill
              theme="snow"
              value={us_richContentEditorValue}
              onChange={(value) => {
                us_setRichContentEditorButtonColor('error')
                us_setRichContentEditorButtonVariant('contained')
                us_setRichContentEditorValue(value)
              }}
            />
            <Box className="tw-text-right tw-mt-2">
              <Button
                className=""
                color={us_richContentEditorButtonColor}
                variant={us_richContentEditorButtonVariant}
                startIcon={<Icon icon="floppy-disk"></Icon>}
                disabled={!editable}
                onClick={() => {
                  updateTaskInstructionsProperty(taskInstructions, 'instructions', us_richContentEditorValue)
                    .then((res_UTIP) => {
                      us_setRichContentEditorButtonColor('info')
                      us_setRichContentEditorButtonVariant('outlined')
                    })
                    .catch((rej_UTIP) => {})
                }}
              >
                {s_SAVE_INSTRUCTIONS}
              </Button>
            </Box>
          </Card>
        </Box>
      )
    } else {
      tabsJSX = (
        <Box className="tw-p-4 tw-text-center">
          <CircularProgress
            size="100px"
            variant="indeterminate"
            color="secondary"
            className="tw-m-auto"
          />
        </Box>
      )
    }
    return tabsJSX
  }

  const rJSX_PublishToProdButton = (editable: boolean): JSX.Element => {
    let publishButtonJSX = (
      <Button
        className="tw-mr-2 tw-mt-2"
        color="primary"
        variant="outlined"
        disabled={!editable}
        startIcon={<Icon icon="paper-plane"></Icon>}
        onClick={() => {
          publishStagingToProduction()
        }}
      >
        {s_PUBLISH}
      </Button>
    )
    return publishButtonJSX
  }

  const rJSX_FormConfigTab = (): JSX.Element => {
    let tabJSX = (
      <Box
        className="tw-m-auto"
        sx={{ maxWidth: '800px' }}
      >
        <Card className="tw-p-4">
          <Box
            className="tw-m-auto"
            sx={{ maxWidth: '1000px' }}
          >
            <Form
              formAdditionalData={formAdditionalData_TaskFormAdditionalConfig}
              formData={us_stagingConfig}
              formInputs={us_formInputs_TaskFormAdditionalConfig}
              formOnChange={formOnChange_TaskFormAdditionalConfig}
              formSettings={formSettings_TaskFormAdditionalConfig}
              formSubmission={formSubmission_TaskFormAdditionalConfig}
            />
          </Box>
        </Card>
      </Box>
    )
    return tabJSX
  }

  const rJSX_ProdFormTabs = (): JSX.Element => {
    let formTabsJSX = <></>
    if (us_initialProdDataLoaded === true) {
      if (us_prodTaskFormPages == null || us_prodTaskFormPages.pages == null || objectToArray(us_prodTaskFormPages.pages).length === 0) {
        formTabsJSX = (
          <Box className="tw-text-center tw-my-10">
            <Typography
              variant="h6"
              className="tw-mb-2"
            >
              {s_NO_PRODUCTION_TASK_FORM}
            </Typography>
            {/* { rJSX_PublishToProdButton() } */}
          </Box>
        )
      } else {
        formTabsJSX = (
          <Box>
            <TabsBasic
              tabs={returnTaskFormPages(us_prodTaskFormPages.pages, false)}
              tabsSettings={{ color: 'secondary' }}
            />
          </Box>
        )
      }
    } else {
      formTabsJSX = (
        <Box className="tw-p-4 tw-text-center">
          <CircularProgress
            size="100px"
            variant="indeterminate"
            color="secondary"
            className="tw-m-auto"
          />
        </Box>
      )
    }
    return formTabsJSX
  }

  const rJSX_TopTabs = (): JSX.Element => {
    let topTabsJSX = (
      <TabsUrl
        tabs={[
          {
            tabUrlKey: 'Staging',
            tabHeader: s_STAGING_AREA,
            tabContent: <Box>{rJSX_StagingFormTabs()}</Box>,
          },
          {
            tabUrlKey: 'Instructions',
            tabHeader: s_TASK_INSTRUCTIONS,
            tabContent: <Box>{rJSX_TaskInstructionsTabs(us_stagingInstructions)}</Box>,
          },
          {
            tabUrlKey: 'Config',
            tabHeader: s_FORM_CONFIG,
            tabContent: <Box>{rJSX_FormConfigTab()}</Box>,
          },
          {
            tabUrlKey: 'Production',
            tabHeader: s_PRODUCTION_VIEW,
            tabContent: <Box>{rJSX_ProdFormTabs()}</Box>,
          },
        ]}
        tabsSettings={tabsSettings}
      />
    )
    return topTabsJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rJSX_PageHeader()}
        pageKey={pageKey}
        content={
          <Box>
            <Stack
              direction="row"
              spacing={1}
            >
              <Button
                color="inherit"
                variant="outlined"
                startIcon={<Icon icon="chevron-left"></Icon>}
                onClick={(event) => {
                  onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminDatabaseTaskFormsListPage.url())
                }}
              >
                {s_BACK_TO_ALL_TASK_FORMS}
              </Button>
            </Stack>
            <Box className="">{rJSX_TopTabs()}</Box>
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
