/* eslint-disable react/display-name */
///////////////////////////////
// Description
///////////////////////////////

/*
DESCRIPTION / USAGE:
example component description

TODO:

*/

///////////////////////////////
// Imports
///////////////////////////////
import { Box, Card } from '@mui/material'
import { forwardRef, useContext } from 'react'
import { DatabaseRef_ProjectContactLogs_Collection } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { LogTable } from 'rfbp_core/components/logs/log_table'
import { Context_RootData_ClientKey } from 'rfbp_core/services/context'
import { getProp } from 'rfbp_core/services/helper_functions'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ProjectView_LogsTab {
  projectKey: string
  readOrWrite: 'read' | 'write'
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const ProjectViewLogsTab = forwardRef((props: TsInterface_ProjectView_LogsTab, ref: React.ForwardedRef<unknown>): JSX.Element => {
  // Props
  let pr_projectKey: TsInterface_ProjectView_LogsTab['projectKey'] = getProp(props, 'projectKey', null)
  let pr_readOrWrite: TsInterface_ProjectView_LogsTab['readOrWrite'] = getProp(props, 'readOrWrite', 'read')
  const { uc_RootData_ClientKey } = useContext(Context_RootData_ClientKey)

  // Hooks - useContext, useState, useReducer, other

  // Hooks - useEffect

  // Functions

  // JSX Generation
  const rJSX_Tab = (): JSX.Element => {
    let tabJSX = (
      <Box className="tw-m-auto">
        <Card>
          <LogTable
            logType="project"
            logEndpoint={DatabaseRef_ProjectContactLogs_Collection(uc_RootData_ClientKey as string, pr_projectKey)}
          />
        </Card>
      </Box>
    )
    return tabJSX
  }

  // Render

  return <>{rJSX_Tab()}</>
})
