///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Badge, Box, Button, Stack, Typography } from '@mui/material/'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_SalesPartner_Document } from 'rfbp_aux/services/database_endpoints/directory/sales_partners'
import { DatabaseRef_TaskWorkflow_Document } from 'rfbp_aux/services/database_endpoints/directory/task_workflows'
import {
  DatabaseRef_ProjectFinances_Collection,
  DatabaseRef_ProjectTaskWorkflow_Document,
  DatabaseRef_Project_Document,
} from 'rfbp_aux/services/database_endpoints/operations/projects'
import { DatabaseRef_AllProjectTasks_Query, DatabaseRef_Task_Document } from 'rfbp_aux/services/database_endpoints/operations/tasks'
import { Icon } from 'rfbp_core/components/icons'
import { TabsUrl, TsInterface_TabContentUrlArray } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientUser,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  Context_UserInterface_PromptDialog,
} from 'rfbp_core/services/context'
import {
  DatabaseBatchUpdate,
  DatabaseGetDocument,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { generateProjectInvoice, verifyProjectHasRequiredData } from '../invoices/services/invoice_progress_functions'
import { rJSX_ContactLogsTabHeader } from './project_view/tab_contact_log'
import { rJSX_TaskTabHeader } from './project_view/tab_tasks'
import { rJSX_SelectProjectCodeButton, rJSX_SelectTaskWorkflowButton } from './project_view/tab_team'
import { defaultPagePermissions, determinePageSpecificPermissions } from './v2_config/view_permissions'
import { ProjectViewAdditionalWorkTab } from './v2_view_tabs/tab_additional_work'
import { ProjectViewCalendarTab } from './v2_view_tabs/tab_calendar'
import { ProjectViewContactLogsTab } from './v2_view_tabs/tab_contact_log'
import { ProjectViewDetailsTab } from './v2_view_tabs/tab_details'
import { ProjectViewDocumentsTab } from './v2_view_tabs/tab_documents'
import { ProjectViewFinanceTab } from './v2_view_tabs/tab_finance'
import { ProjectViewLogsTab } from './v2_view_tabs/tab_logs'
import { ProjectViewNotesTab } from './v2_view_tabs/tab_notes'
import { ProjectViewPhotosTab } from './v2_view_tabs/tab_photos'
import { ProjectViewQuotesTab } from './v2_view_tabs/tab_quotes'
import { ProjectViewTasksTab } from './v2_view_tabs/tab_tasks'
import { ProjectViewTeamTab } from './v2_view_tabs/tab_team'

///////////////////////////////
// Typescript
///////////////////////////////

export type TsType_DocumentTableFilterOptions = 'all' | 'archived' | 'not_archived' | 'starred' | 'visible_to_customer' | 'hidden_from_customer'

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminActiveProjectViewPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

const potentiallyGeocodeAddressForProject = (clientKey: string, projectKey: string, project: TsInterface_UnspecifiedObject) => {
  return new Promise((resolve, reject) => {
    if (project != null && project['location_latitude'] != null && project['location_longitude'] != null) {
      resolve({ success: true })
    } else if (
      project != null &&
      project['location_address'] != null &&
      project['location_city'] != null &&
      project['location_state'] != null &&
      project['location_zip'] != null
    ) {
      // Get Coordinates
      let address = ''
      if (project['location_address'] != null) {
        address += project['location_address'] + ' '
      }
      if (project['location_city'] != null) {
        address += project['location_city'] + ' '
      }
      if (project['location_state'] != null) {
        address += project['location_state'] + ' '
      }
      if (project['location_zip'] != null) {
        address += project['location_zip'] + ' '
      }
      // Have Google Maps Geocode it
      let geocoder = new google.maps.Geocoder()
      geocoder.geocode({ address: address }, (results: any, status: any) => {
        // If there is a result
        if (status === 'OK') {
          // Set Latitude and Longitude
          let updateObject = {
            location_latitude: results[0].geometry.location.lat(),
            location_longitude: results[0].geometry.location.lng(),
          }
          let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
            { type: 'setMerge', ref: DatabaseRef_Project_Document(clientKey, projectKey), data: updateObject },
          ]
          DatabaseBatchUpdate(updateArray)
            .then((res_DBU) => {
              resolve(res_DBU)
            })
            .catch((rej_DBU) => {
              reject(rej_DBU)
            })
        } else {
          reject({
            success: false,
            error: {
              message: rLIB('Failed to Calculate Coordinates'),
              details: status,
              code: 'ER-D-TDI-ACTT-01',
            },
          })
        }
      })
    } else {
      // Not enough data to generate GPS Coordinates
      reject({ success: false })
    }
  })
}

const potentiallyCopyCoordinatesToTasks = (
  clientKey: string,
  projectKey: string,
  project: TsInterface_UnspecifiedObject,
  tasks: TsInterface_UnspecifiedObject,
) => {
  return new Promise((resolve, reject) => {
    let hasTasksToUpdate = false
    let tasksToUpdate: TsInterface_UnspecifiedObject = {}
    if (project.location_latitude != null && project.location_longitude != null) {
      for (let loopTaskKey in tasks) {
        let loopTask = tasks[loopTaskKey]
        if (loopTask.location_latitude == null || loopTask.location_longitude == null) {
          hasTasksToUpdate = true
          tasksToUpdate[loopTaskKey] = {
            location_latitude: project.location_latitude,
            location_longitude: project.location_longitude,
          }
        }
      }
      if (hasTasksToUpdate === true) {
        let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
        for (let loopTaskKey in tasksToUpdate) {
          tasksToUpdate[loopTaskKey]['timestamp_last_updated'] = new Date()
          updateArray.push({ type: 'setMerge', ref: DatabaseRef_Task_Document(clientKey, loopTaskKey), data: tasksToUpdate[loopTaskKey] })
        }
        DatabaseBatchUpdate(updateArray)
          .then((res_DBU) => {
            resolve(res_DBU)
          })
          .catch((rej_DBU) => {
            reject(rej_DBU)
          })
      } else {
        resolve({ success: true })
      }
    } else {
      resolve({ success: true })
    }
  })
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const pr_projectKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  const [us_alreadyAttemptedInvoiceCreation, us_setAlreadyAttemptedInvoiceCreation] = useState<boolean>(false)
  const [us_attemptedAddressGeocode, us_setAttemptedAddressGeocode] = useState<boolean>(false)
  const [us_attemptedTaskCoordinateCopy, us_setAttemptedTaskCoordinateCopy] = useState<boolean>(false)
  const [us_attemptingSalesPartnerFix, us_setAttemptingSalesPartnerFix] = useState<boolean>(false)
  const [us_pageSpecificUserRolePermission, us_setPageSpecificUserRolePermission] = useState<TsInterface_UnspecifiedObject>(defaultPagePermissions)
  const [us_projectFinances, us_setProjectFinances] = useState<TsInterface_UnspecifiedObject>({}) // TODO: Header...
  const [us_projectRequiredInvoicingDataCheckResults, us_setProjectRequiredInvoicingDataCheckResults] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectTasks, us_setProjectTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectUsingInvoicingModule, us_setProjectUsingInvoicingModule] = useState<boolean>(false)
  const [us_reloadTaskWorkflow, us_setReloadTaskWorkflow] = useState<number>(0)
  const [us_rootProject, us_setRootProject] = useState<TsInterface_UnspecifiedObject>({})
  const [us_rootProjectLoaded, us_setRootProjectLoaded] = useState<boolean>(false)
  const [us_usersWithDirectOrScheduledTasksRoles, us_setUsersWithDirectOrScheduledTasksRoles] = useState<TsInterface_UnspecifiedObject>({})
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)

  // Hooks - useEffect

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setProjectTasks(newData)
      }
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_AllProjectTasks_Query(res_GCK.clientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setProjectTasks({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey])

  useEffect(() => {
    if (uc_RootData_ClientUser != null) {
      us_setPageSpecificUserRolePermission(determinePageSpecificPermissions(uc_RootData_ClientUser))
    }
  }, [uc_RootData_ClientUser])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRootProject(newData)
      us_setRootProjectLoaded(true)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetDocument(DatabaseRef_ProjectTaskWorkflow_Document(res_GCK.clientKey, pr_projectKey))
          .then((res_DGD) => {
            let taskWorkflowUserRolesList: TsInterface_UnspecifiedObject = {}
            let taskWorkflowUserRolesWithDirectOrScheduledTasksList: TsInterface_UnspecifiedObject = {}
            if (res_DGD.data != null && res_DGD.data['tasks'] != null) {
              for (let loopTaskKey in res_DGD.data['tasks']) {
                let loopTask = res_DGD.data['tasks'][loopTaskKey]
                if (us_reloadTaskWorkflow >= 0) {
                  // Nothing - just used for reloads
                }
                if (loopTask != null && loopTask['associated_owner_type'] != null) {
                  taskWorkflowUserRolesList[loopTask['associated_owner_type']] = loopTask['associated_owner_type']
                  if (loopTask['task_completion_type'] === 'direct' || loopTask['task_completion_type'] === 'dispatcher') {
                    taskWorkflowUserRolesWithDirectOrScheduledTasksList[loopTask['associated_owner_type']] = loopTask['associated_owner_type']
                  }
                }
              }
            }
            us_setUsersWithDirectOrScheduledTasksRoles(taskWorkflowUserRolesWithDirectOrScheduledTasksList)
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [uc_RootData_ClientKey, pr_projectKey, uc_setRootData_ClientKey, us_reloadTaskWorkflow])

  useEffect(() => {
    if (us_rootProject != null && us_rootProject.associated_task_workflow_key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseGetDocument(DatabaseRef_TaskWorkflow_Document(res_GCK.clientKey, us_rootProject.associated_task_workflow_key))
            .then((res_DGD) => {
              if (res_DGD.data != null && res_DGD.data['projects_using_invoicing'] === true) {
                us_setProjectUsingInvoicingModule(true)
              } else {
                us_setProjectUsingInvoicingModule(false)
              }
            })
            .catch((rej_DGD) => {
              console.error(rej_DGD)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
  }, [uc_RootData_ClientKey, pr_projectKey, uc_setRootData_ClientKey, us_rootProject])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setProjectFinances(newData)
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ProjectFinances_Collection(res_GCK.clientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setProjectFinances({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey])

  useEffect(() => {
    // Generate List of Tasks with bad prerequisite data structure
    if (pr_projectKey != null && us_rootProject != null && us_rootProject['key'] != null && us_attemptedAddressGeocode === false) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          potentiallyGeocodeAddressForProject(res_GCK.clientKey, pr_projectKey, us_rootProject).finally(() => {
            us_setAttemptedAddressGeocode(true)
          })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, pr_projectKey, us_rootProject, us_attemptedAddressGeocode])

  useEffect(() => {
    // Generate List of Tasks with bad prerequisite data structure
    if (
      pr_projectKey != null &&
      us_rootProject != null &&
      us_rootProject['key'] != null &&
      us_attemptedTaskCoordinateCopy === false &&
      us_projectTasks != null &&
      objectToArray(us_projectTasks).length > 0
    ) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          potentiallyCopyCoordinatesToTasks(res_GCK.clientKey, pr_projectKey, us_rootProject, us_projectTasks).finally(() => {
            us_setAttemptedTaskCoordinateCopy(true)
          })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, pr_projectKey, us_rootProject, us_attemptedTaskCoordinateCopy, us_projectTasks])

  // Cleanup Tasks
  useEffect(() => {
    // REPAIR - If invoice status is null, set it to missing
    if (us_rootProject != null && us_rootProject.key != null && us_rootProject.invoice_status == null) {
      let updateObject: TsInterface_UnspecifiedObject = {
        invoice_status: 'missing', // missing, unapproved, approved, billed, paid
      }
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, us_rootProject.key), updateObject)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
      // TODO: Update Project
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_rootProject])

  useEffect(() => {
    if (
      us_rootProject != null &&
      us_rootProject.key != null &&
      us_rootProject.associated_sales_partner_key != null &&
      us_rootProject.associated_sales_partner_name == null &&
      us_attemptingSalesPartnerFix === false
    ) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
        DatabaseGetDocument(DatabaseRef_SalesPartner_Document(res_GCK.clientKey, us_rootProject.associated_sales_partner_key)).then((res_DGD) => {
          if (res_DGD.data != null && res_DGD.data.name != null) {
            let updateObject: TsInterface_UnspecifiedObject = {
              associated_sales_partner_name: res_DGD.data.name,
            }
            DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, us_rootProject.key), updateObject)
          }
        })
      })
      us_setAttemptingSalesPartnerFix(true)
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_rootProject, us_attemptingSalesPartnerFix])

  useEffect(() => {
    if (
      us_projectUsingInvoicingModule === true &&
      us_rootProject != null &&
      us_rootProject.key != null &&
      us_rootProject.associated_sales_partner_key != null &&
      us_rootProject.associated_task_workflow_key != null &&
      us_rootProject.status != 'on_hold' &&
      us_rootProject.status != 'cancelled' &&
      us_rootProject.status != 'completed' &&
      us_alreadyAttemptedInvoiceCreation === false
    ) {
      let verifyProjectHasRequiredDataResult = verifyProjectHasRequiredData(us_rootProject)
      us_setProjectRequiredInvoicingDataCheckResults(verifyProjectHasRequiredDataResult)
      if (verifyProjectHasRequiredDataResult.success === true) {
        generateProjectInvoice(us_rootProject, uc_RootData_ClientKey, pr_projectKey, uc_setRootData_ClientKey, uc_setUserInterface_ErrorDialogDisplay)
        us_setAlreadyAttemptedInvoiceCreation(true)
      }
    }
  }, [
    uc_RootData_ClientKey,
    uc_setRootData_ClientKey,
    us_rootProject,
    us_projectUsingInvoicingModule,
    us_alreadyAttemptedInvoiceCreation,
    pr_projectKey,
    uc_setUserInterface_ErrorDialogDisplay,
  ])

  // Functions
  const rJSX_PageHeader = (): JSX.Element => {
    let pageHeader = <></>
    let jobCodeJSX = <></>
    if (us_rootProject != null && us_rootProject.id_number != null) {
      jobCodeJSX = <>{us_rootProject.id_number}</>
    } else {
      jobCodeJSX = <>{rLIB('New Project')}</>
    }
    if (us_rootProject != null && us_rootProject.associated_customer_name != null) {
      pageHeader = (
        <>
          {jobCodeJSX}: {us_rootProject.associated_customer_name}
        </>
      )
    } else {
      pageHeader = (
        <>
          {jobCodeJSX}: {rLIB('Customer Details')}
        </>
      )
    }
    return pageHeader
  }

  const returnProjectTabs = (): TsInterface_TabContentUrlArray => {
    let tabs: TsInterface_TabContentUrlArray = []
    // DETAILS TAB
    tabs.push({
      tabUrlKey: 'details',
      tabHeader: rLIB('Details'),
      tabOnChange: () => {},
      tabContent: (
        <Box>
          {rJSX_AlertBanner()}
          <ProjectViewDetailsTab
            projectKey={pr_projectKey}
            readOrWrite={getProp(us_pageSpecificUserRolePermission, 'details', 'read')}
          />
        </Box>
      ),
    })
    // TEAM TAB
    tabs.push({
      tabUrlKey: 'team',
      tabHeader: rJSX_TeamTabHeader(),
      tabOnChange: () => {},
      tabContent: (
        <Box>
          {rJSX_AlertBanner()}
          <ProjectViewTeamTab
            projectKey={pr_projectKey}
            readOrWrite={getProp(us_pageSpecificUserRolePermission, 'team', 'read')}
          />
        </Box>
      ),
    })
    // TASKS TAB
    tabs.push({
      tabUrlKey: 'tasks',
      tabHeader: rJSX_TaskTabHeader(us_rootProject),
      tabOnChange: () => {},
      tabContent: (
        <Box className="tw-m-auto">
          {rJSX_AlertBanner()}
          <ProjectViewTasksTab
            projectKey={pr_projectKey}
            readOrWrite={getProp(us_pageSpecificUserRolePermission, 'tasks', 'read')}
            levelOfAccess={'full'}
          />
        </Box>
      ),
    })
    // CALENDAR TAB
    tabs.push({
      tabUrlKey: 'calendar',
      tabHeader: rLIB('Calendar'),
      tabOnChange: () => {},
      tabContent: (
        <Box className="tw-m-auto">
          {rJSX_AlertBanner()}
          <ProjectViewCalendarTab
            projectKey={pr_projectKey}
            readOrWrite={getProp(us_pageSpecificUserRolePermission, 'calendar', 'read')}
          />
        </Box>
      ),
    })
    // ADDITIONAL WORK TAB
    // TODO: Uncomment this later
    // if (us_projectUsingInvoicingModule === false) {
    tabs.push({
      tabUrlKey: 'sow',
      tabHeader: rLIB('Additional Work'),
      tabOnChange: () => {},
      tabContent: (
        <Box className="tw-m-auto">
          {rJSX_AlertBanner()}
          <ProjectViewAdditionalWorkTab
            projectKey={pr_projectKey}
            readOrWrite={getProp(us_pageSpecificUserRolePermission, 'sow', 'read')}
          />
        </Box>
      ),
    })
    // }
    // QUOTES TAB
    if (us_projectUsingInvoicingModule === true) {
      tabs.push({
        tabUrlKey: 'quotes',
        tabHeader: rLIB('Quotes'),
        tabOnChange: () => {},
        tabContent: (
          <Box>
            {rJSX_AlertBanner()}
            <ProjectViewQuotesTab
              projectKey={pr_projectKey}
              readOrWrite={getProp(us_pageSpecificUserRolePermission, 'quotes', 'read')}
            />
          </Box>
        ),
      })
    }
    // FINANCE TAB
    if (us_projectUsingInvoicingModule === true) {
      tabs.push({
        tabUrlKey: 'finance',
        tabHeader: rJSX_FinanceTabHeader(us_rootProject, us_projectFinances, us_projectTasks),
        tabOnChange: () => {},
        tabContent: (
          <Box>
            {rJSX_AlertBanner()}
            <ProjectViewFinanceTab
              projectKey={pr_projectKey}
              readOrWrite={getProp(us_pageSpecificUserRolePermission, 'finance', 'read')}
            />
          </Box>
        ),
      })
    }
    // CONTACT LOG TAB
    tabs.push({
      tabUrlKey: 'contact_log',
      tabHeader: rJSX_ContactLogsTabHeader(us_rootProject),
      tabOnChange: () => {},
      tabContent: (
        <Box className="tw-m-auto">
          {rJSX_AlertBanner()}
          <ProjectViewContactLogsTab
            projectKey={pr_projectKey}
            readOrWrite={getProp(us_pageSpecificUserRolePermission, 'contact_log', 'read')}
          />
        </Box>
      ),
    })
    // NOTES TAB
    tabs.push({
      tabUrlKey: 'notes',
      tabHeader: rLIB('Notes'),
      tabOnChange: () => {},
      tabContent: (
        <Box className="tw-m-auto">
          {rJSX_AlertBanner()}
          <ProjectViewNotesTab
            projectKey={pr_projectKey}
            readOrWrite={getProp(us_pageSpecificUserRolePermission, 'notes', 'read')}
          />
        </Box>
      ),
    })
    // DOCUMENTS TAB
    tabs.push({
      tabUrlKey: 'documents',
      tabHeader: rLIB('Documents'),
      tabOnChange: () => {},
      tabContent: (
        <Box className="tw-m-auto">
          {rJSX_AlertBanner()}
          <ProjectViewDocumentsTab
            projectKey={pr_projectKey}
            readOrWrite={getProp(us_pageSpecificUserRolePermission, 'documents', 'read')}
          />
        </Box>
      ),
    })
    // PHOTOS TAB
    tabs.push({
      tabUrlKey: 'photos',
      tabHeader: rLIB('Photos'),
      tabOnChange: () => {},
      tabContent: (
        <Box className="tw-m-auto">
          {rJSX_AlertBanner()}
          <ProjectViewPhotosTab
            projectKey={pr_projectKey}
            readOrWrite={getProp(us_pageSpecificUserRolePermission, 'photos', 'read')}
          />
        </Box>
      ),
    })
    // LOGS TAB
    tabs.push({
      tabUrlKey: 'logs',
      tabHeader: rLIB('Logs'),
      tabOnChange: () => {},
      tabContent: (
        <Box className="tw-m-auto">
          {rJSX_AlertBanner()}
          <ProjectViewLogsTab
            projectKey={pr_projectKey}
            readOrWrite={getProp(us_pageSpecificUserRolePermission, 'logs', 'read')}
          />
        </Box>
      ),
    })
    return tabs
  }

  // JSX Generation

  // ALL - Alert
  const rJSX_AlertBanner = (): JSX.Element => {
    let missingAssignedRoles = false
    for (let loopRoleKey in us_usersWithDirectOrScheduledTasksRoles) {
      if (us_rootProject != null && us_rootProject['associated_' + loopRoleKey + '_key'] == null) {
        missingAssignedRoles = true
      }
    }
    let primaryBannerJSX = <></>
    if (us_rootProjectLoaded === true && us_rootProject != null && us_rootProject['id_number'] == null) {
      primaryBannerJSX = (
        <Box
          sx={{ background: themeVariables.error_main }}
          className="tw-p-2 tw-mb-2 tw-rounded-lg"
        >
          <Stack
            direction="row"
            className="tw-justify-between"
          >
            <Typography
              variant="body1"
              className="tw-inline-block tw-mr-2 tw-mt-0.5"
            >
              <Icon
                icon="triangle-exclamation"
                className="tw-mr-2"
              />
              {rLIB('No Project ID Generated Yet')}
            </Typography>
            {rJSX_SelectProjectCodeButton(
              uc_RootData_ClientKey,
              pr_projectKey,
              us_rootProject,
              uc_setRootData_ClientKey,
              uc_setUserInterface_ErrorDialogDisplay,
              uc_setUserInterface_PromptDialogDisplay,
            )}
          </Stack>
        </Box>
      )
    } else if (us_rootProjectLoaded === true && us_rootProject != null && us_rootProject['associated_task_workflow_key'] == null) {
      primaryBannerJSX = (
        <Box
          sx={{ background: themeVariables.error_main }}
          className="tw-p-2 tw-mb-2 tw-rounded-lg"
        >
          <Stack
            direction="row"
            className="tw-justify-between"
          >
            <Typography
              variant="body1"
              className="tw-inline-block tw-mr-2 tw-mt-0.5"
            >
              <Icon
                icon="triangle-exclamation"
                className="tw-mr-2"
              />
              {rLIB('No task workflow selected for project')}
            </Typography>
            {rJSX_SelectTaskWorkflowButton(
              uc_RootData_ClientKey,
              uc_RootData_ClientUser,
              pr_projectKey,
              us_setReloadTaskWorkflow,
              uc_setRootData_ClientKey,
              uc_setUserInterface_ErrorDialogDisplay,
              uc_setUserInterface_FormDialogDisplay,
            )}
          </Stack>
        </Box>
      )
    } else if (us_rootProject['status'] === 'on_hold') {
      primaryBannerJSX = (
        <Box
          sx={{ background: themeVariables.secondary_main }}
          className="tw-p-2 tw-mb-2 tw-rounded-lg"
        >
          <Stack
            direction="row"
            className="tw-justify-between"
          >
            <Typography
              variant="body1"
              className="tw-inline-block tw-mr-2 tw-mt-0.5"
            >
              <Icon
                icon="circle-pause"
                className="tw-mr-2"
              />
              {rLIB('Project On Hold')}
            </Typography>
          </Stack>
        </Box>
      )
    } else if (us_rootProject['status'] === 'cancelled') {
      primaryBannerJSX = (
        <Box
          sx={{ background: themeVariables.error_main }}
          className="tw-p-2 tw-mb-2 tw-rounded-lg"
        >
          <Stack
            direction="row"
            className="tw-justify-between"
          >
            <Typography
              variant="body1"
              className="tw-inline-block tw-mr-2 tw-mt-0.5"
            >
              <Icon
                icon="skull"
                className="tw-mr-2"
              />
              {rLIB('Project Cancelled')}
            </Typography>
          </Stack>
        </Box>
      )
    } else if (us_rootProject['status'] === 'completed') {
      primaryBannerJSX = (
        <Box
          sx={{ background: themeVariables.info_main }}
          className="tw-p-2 tw-mb-2 tw-rounded-lg"
        >
          <Stack
            direction="row"
            className="tw-justify-between"
          >
            <Typography
              variant="body1"
              className="tw-inline-block tw-mr-2 tw-mt-0.5"
            >
              <Icon
                icon="badge-check"
                className="tw-mr-2"
              />
              {rLIB('Project Complete')}
            </Typography>
          </Stack>
        </Box>
      )
    } else if (missingAssignedRoles === true) {
      primaryBannerJSX = (
        <Box
          sx={{ background: themeVariables.warning_main }}
          className="tw-p-2 tw-mb-2 tw-rounded-lg"
        >
          <Stack
            direction="row"
            className="tw-justify-between"
          >
            <Typography
              variant="body1"
              className="tw-inline-block tw-mr-2 tw-mt-0.5"
            >
              <Icon
                icon="triangle-exclamation"
                className="tw-mr-2"
              />
              {rLIB('Missing team member assignments')}
            </Typography>
          </Stack>
        </Box>
      )
    } else if (us_rootProject == null || us_rootProject.current_active_tasks == null || objectToArray(us_rootProject.current_active_tasks).length === 0) {
      primaryBannerJSX = (
        <Box
          sx={{ background: themeVariables.error_main }}
          className="tw-p-2 tw-mb-2 tw-rounded-lg"
        >
          <Stack
            direction="row"
            className="tw-justify-between"
          >
            <Typography
              variant="body1"
              className="tw-inline-block tw-mr-2 tw-mt-0.5"
            >
              <Icon
                icon="triangle-exclamation"
                className="tw-mr-2"
              />
              {rLIB('No Active Tasks')}. {rLIB('Check task page to see why this project is stalled')}
            </Typography>
          </Stack>
        </Box>
      )
    }

    let secondaryBannerJSX = <></>
    if (
      us_projectUsingInvoicingModule === true &&
      us_projectRequiredInvoicingDataCheckResults != null &&
      us_projectRequiredInvoicingDataCheckResults.missing_fields != null &&
      us_projectRequiredInvoicingDataCheckResults.missing_fields.length > 0
    ) {
      secondaryBannerJSX = (
        <Box
          sx={{ background: themeVariables.warning_main }}
          className="tw-p-2 tw-mb-2 tw-rounded-lg"
        >
          <Stack
            direction="row"
            className="tw-justify-between"
          >
            <Typography
              variant="body1"
              className="tw-inline-block tw-mr-2 tw-mt-0.5"
            >
              <Icon
                icon="triangle-exclamation"
                className="tw-mr-2"
              />
              {rLIB('Missing required data to generate invoice')}: {us_projectRequiredInvoicingDataCheckResults.missing_fields.join(', ')}
            </Typography>
          </Stack>
        </Box>
      )
    }

    return (
      <>
        {primaryBannerJSX}
        {secondaryBannerJSX}
      </>
    )
  }

  const rJSX_FinanceTabHeader = (
    rootProject: TsInterface_UnspecifiedObject,
    projectFinances: TsInterface_UnspecifiedObject,
    tasks: TsInterface_UnspecifiedObject,
  ): JSX.Element => {
    let headerJSX = <>{rLIB('Finance')}</>
    // Get list of deleted tasks
    let hasDeletedBillingTasks = false
    if (
      tasks != null &&
      projectFinances != null &&
      projectFinances.base != null &&
      projectFinances.base.billing_times != null &&
      rootProject != null &&
      rootProject.key != null
    ) {
      for (let loopTaskKey in projectFinances.base.billing_times) {
        if (loopTaskKey != null && tasks[rootProject.key + '_' + loopTaskKey] != null && tasks[rootProject.key + '_' + loopTaskKey].status == 'deleted') {
          hasDeletedBillingTasks = true
        }
      }
    }
    if (rootProject != null && (rootProject.invoice_status == null || rootProject.invoice_status == 'missing' || hasDeletedBillingTasks === true)) {
      headerJSX = (
        <Stack
          direction="row"
          alignItems="center"
        >
          <Icon
            className="tw-mr-2"
            sx={{ color: themeVariables.error_main }}
            icon="triangle-exclamation"
          />
          {rLIB('Finance')}
        </Stack>
      )
    }
    return headerJSX
  }

  const rJSX_TeamTabHeader = (): JSX.Element => {
    let headerJSX = <>{rLIB('Team')}</>
    let missingAssignedRoles = false
    for (let loopRoleKey in us_usersWithDirectOrScheduledTasksRoles) {
      if (us_rootProject != null && us_rootProject['associated_' + loopRoleKey + '_key'] == null) {
        missingAssignedRoles = true
      }
    }
    if (missingAssignedRoles === true) {
      headerJSX = (
        <>
          <Badge
            badgeContent={<Icon icon="triangle-exclamation" />}
            color="error"
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <>{rLIB('Team')}</>
          </Badge>
        </>
      )
    }
    return headerJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rJSX_PageHeader()}
        pageKey={pageKey}
        content={
          <Box>
            <Box>
              <Button
                color="inherit"
                variant="outlined"
                startIcon={<Icon icon="chevron-left"></Icon>}
                onClick={(event) => {
                  onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminActiveProjectsListPage.url())
                }}
              >
                {rLIB('Back to all active projects')}
              </Button>
            </Box>
            <TabsUrl
              tabs={returnProjectTabs()}
              tabsSettings={{
                baseUrl: ApplicationPages.AdminActiveProjectViewPage.url(pr_projectKey),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: getProp(us_rootProject, 'id_number', 'Project'),
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
