//======================================//
//		  ooOOOO USED BY BOILERPLATE    //
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//======================================//

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Used to set default theme for application

		TODO:
			[ ] Feature - Somehow use this page to populate className .form_highlight_invalid_input
			[ ] Feature - Dark Mode toggle
			[ ] Typescript - 2 instances of any
	*/

///////////////////////////////
// Imports
///////////////////////////////

import { createTheme } from '@mui/material/styles'

///////////////////////////////
// Variables
///////////////////////////////

// Google Maps
const googleMapsLightTheme: any[] = []
const rootElement = document.getElementById('root')

// const googleMapsDarkTheme = [
// 	{ "featureType": "all",								"elementType": "geometry",				"stylers": [ { "color": "#242f3e" } ] },
// 	{ "featureType": "all",								"elementType": "labels.text.fill",		"stylers": [ { "color": "#746855" } ] },
// 	{ "featureType": "all",								"elementType": "labels.text.stroke",	"stylers": [ { "color": "#242f3e" } ] },
// 	{ "featureType": "administrative.locality",			"elementType": "labels.text.fill",		"stylers": [ { "color": "#d59563" } ] },
// 	{ "featureType": "poi",								"elementType": "labels.text.fill",		"stylers": [ { "color": "#d59563" } ] },
// 	{ "featureType": "poi.park",						"elementType": "geometry",				"stylers": [ { "color": "#263c3f" } ] },
// 	{ "featureType": "poi.park",						"elementType": "labels.text.fill",		"stylers": [ { "color": "#6b9a76" } ] },
// 	{ "featureType": "road",							"elementType": "geometry",				"stylers": [ { "color": "#38414e" } ] },
// 	{ "featureType": "road",							"elementType": "geometry.stroke",		"stylers": [ { "color": "#212a37" } ] },
// 	{ "featureType": "road",							"elementType": "labels.text.fill",		"stylers": [ { "color": "#9ca5b3" } ] },
// 	{ "featureType": "road.highway",					"elementType": "geometry",				"stylers": [ { "color": "#746855" } ] },
// 	{ "featureType": "road.highway",					"elementType": "geometry.stroke",		"stylers": [ { "color": "#1f2835" } ] },
// 	{ "featureType": "road.highway",					"elementType": "labels.text.fill",		"stylers": [ { "color": "#f3d19c" } ] },
// 	{ "featureType": "transit",							"elementType": "geometry",				"stylers": [ { "color": "#2f3948" } ] },
// 	{ "featureType": "transit.station",					"elementType": "labels.text.fill",		"stylers": [ { "color": "#d59563" } ] },
// 	{ "featureType": "water",							"elementType": "geometry",				"stylers": [ { "color": "#17263c" } ] },
// 	{ "featureType": "water",							"elementType": "labels.text.fill",		"stylers": [ { "color": "#515c6d" } ] },
// 	{ "featureType": "water",							"elementType": "labels.text.stroke",	"stylers": [ { "color": "#17263c" } ]}
// ]

//=========================//
//   USED BY BOILERPLATE   //
//=========================//

// Default Theme

const palettes = {
  primary: {
    '50': '#796784',
    '100': '#6c5c76',
    '200': '#5f5168',
    '300': '#52465a',
    '400': '#453b4c',
    '500': '#38303e',
    '600': '#2b2530',
    '700': '#1e1a22',
    '800': '#110f14',
    '900': '#050405',
  },
  secondary: {
    '50': '#f4e6f8',
    '100': '#e2c1ee',
    '200': '#d097e4',
    '300': '#bd6dd8',
    '400': '#af4dcf',
    '500': '#a02fc6', //
    '600': '#912cbf',
    '700': '#7c27b7',
    '800': '#6a23af',
    '900': '#451da1',
  },
  accent: {
    '50': '#e2f8fc',
    '100': '#b7edf8',
    '200': '#8be2f3',
    '300': '#61d5ec',
    '400': '#49cce6',
    '500': '#41c3e0', //
    '600': '#3cb3cc',
    '700': '#359db2',
    '800': '#2f8999',
    '900': '#25666d',
  },
  blue: {
    '50': '#e3f3ff',
    '100': '#bae1ff',
    '200': '#8bceff',
    '300': '#55bbff',
    '400': '#1babff',
    '500': '#009bff',
    '600': '#008cff',
    '700': '#0079f7', //
    '800': '#0067e5',
    '900': '#1346c6',
    // "50":  "#e7eaf8",
    // "100": "#c3cbee",
    // "200": "#9aaae3",
    // "300": "#7088d7",
    // "400": "#4d6ecf",
    // "500": "#2054c6",
    // "600": "#184cbc",
    // "700": "#0442b0", //
    // "800": "#0037a4",
    // "900": "#00258f",
  },
  green: {
    '50': '#e5f5eb',
    '100': '#c1e6ce',
    '200': '#99d6af',
    '300': '#6dc690',
    '400': '#4aba78',
    '500': '#1dae61',
    '600': '#149f57',
    '700': '#068d4b', //
    '800': '#007c40',
    '900': '#005c2c',
  },
  yellow: {
    '50': '#fffde8',
    '100': '#fff9c5',
    '200': '#fff4a0',
    '300': '#fff07a',
    '400': '#fdeb5c',
    '500': '#fbe540',
    '600': '#fdd73f',
    '700': '#fabf37', //
    '800': '#f7a830',
    '900': '#f28023',
  },
  red: {
    '50': '#ffebef',
    '100': '#ffcdd4',
    '200': '#f79a9c',
    '300': '#f07276',
    '400': '#fc4f52',
    '500': '#ff3a37',
    '600': '#f33037',
    '700': '#e12431', //
    '800': '#d41b29',
    '900': '#c6011c',
  },
  orange: {
    '50': '#fff7ed',
    '100': '#ffedd5',
    '200': '#fed7aa',
    '300': '#fdba74',
    '400': '#fb923c',
    '500': '#f97316',
    '600': '#ea580c',
    '700': '#c2410c', //
    '800': '#9a3412',
    '900': '#7c2d12',
  },
  teal: {
    '50': '#f0fdfa',
    '100': '#ccfbf1',
    '200': '#99f6e4',
    '300': '#5eead4',
    '400': '#2dd4bf',
    '500': '#14b8a6',
    '600': '#0d9488',
    '700': '#0f766e',
    '800': '#115e59',
    '900': '#134e4a',
  },
  purple: {
    '500': '#9b59b6',
  },
  // custom_dark_red: {
  // 	main: "#5c1010",
  // 	dark: "#460c0c",
  // }
}

const themeVariables = {
  // Background
  mode: 'dark',
  background_paper: '#272b33', // 2D313B 272b33
  background_default: '#191e21', // 13181B 191e21
  background_highlight: '#121212',
  background_hover: '#383C42',
  contrast_text: '#ffffff',
  map_styles: googleMapsLightTheme,

  logo_blue: '#55D3FF',
  logo_gray: '#939598',

  // 1B2125 - background black
  // 2D313B - input dark gray
  // 939598 - logo gray
  // 55D3FF - logo blue
  // FFFFFF - white
  // EE1E83 - pink
  // 2DBA79 - green
  // D92E2D - red
  // 2B70D0 - Blue

  // Common
  black: '#000',
  white: '#fff',
  transparent: 'rgba(0, 0, 0, 0)',
  // Primary
  // primary_dark:				palettes["primary"]["900"],
  // primary_main:				palettes["primary"]["700"],
  // primary_light:				palettes["primary"]["500"],
  // primary_wash:				palettes["primary"]["100"],
  primary_dark: '#08bfff',
  primary_main: '#22c6ff',
  primary_light: '#3cccff',
  primary_wash: '#55D3FF',
  // Secondary
  secondary_dark: '#c90f6a',
  secondary_main: '#e11176',
  secondary_light: '#ee1e83',
  secondary_wash: '#f03690',
  // Accent
  accent_dark: palettes['accent']['800'],
  accent_main: palettes['accent']['700'],
  accent_light: palettes['accent']['600'],
  accent_wash: palettes['accent']['100'],
  // Info - Blue
  info_dark: '#235aa6',
  info_main: '#2765bb',
  info_light: '#2B70D0',
  info_wash: '#3e7ed7',
  // Success - Green
  success_dark: '#23905f',
  success_main: '#28a56c',
  success_light: '#2DBA79',
  success_wash: '#33ce86',
  // Warning - Yellow
  warning_dark: palettes['yellow']['900'],
  warning_main: palettes['yellow']['800'],
  warning_light: palettes['yellow']['600'],
  warning_wash: palettes['yellow']['100'],
  // Error - Red
  error_dark: '#b22020',
  error_main: '#c82424',
  error_light: '#d92d2d',
  error_wash: '#dd4343',
  // Orange
  orange_300: palettes['orange']['300'],
  orange_500: palettes['orange']['500'],
  orange_700: palettes['orange']['700'],
  teal_500: palettes['teal']['500'],
  teal_700: palettes['teal']['700'],
  purple_500: palettes['purple']['500'],
  // // Primary
  // primary_main:				"#1976d2",
  // primary_light:				"#42a5f5",
  // primary_dark:				"#1565c0",
  // // Secondary
  // secondary_main:				"#9c27b0",
  // secondary_light:			"#ba68c8",
  // secondary_dark:				"#7b1fa2",
  // // Error - Red
  // error_main:					"#d32f2f",
  // error_light:				"#ef5350",
  // error_dark:					"#c62828",
  // // Warning - Yellow
  // warning_main:				"#ED6C02",
  // warning_light:				"#ff9800",
  // warning_dark:				"#e65100",
  // // Info - Blue
  // info_main:					"#0288d1",
  // info_light:					"#03a9f4",
  // info_dark:					"#01579b",
  // // Success - Green
  // success_main:				"#2e7d32",
  // success_light:				"#4caf50",
  // success_dark:				"#1b5e20",
  // Gray
  gray_50: '#fafafa',
  gray_100: '#f5f5f5',
  gray_200: '#eeeeee',
  gray_300: '#e0e0e0',
  gray_400: '#bdbdbd',
  gray_500: '#9e9e9e',
  gray_600: '#757575',
  gray_700: '#616161',
  gray_800: '#424242',
  gray_900: '#212121',
  // Custom
  background_json: '#121212',
  // All
  palettes: palettes,
}

const themeOptions: any = {
  palette: {
    mode: themeVariables.mode,
    primary: {
      main: themeVariables.primary_main,
      light: themeVariables.primary_light,
      dark: themeVariables.primary_dark,
      contrastText: themeVariables.white,
    },
    secondary: {
      main: themeVariables.secondary_main,
      light: themeVariables.secondary_light,
      dark: themeVariables.secondary_dark,
      contrastText: themeVariables.white,
    },
    error: {
      main: themeVariables.error_main,
      light: themeVariables.error_light,
      dark: themeVariables.error_dark,
      contrastText: themeVariables.white,
    },
    warning: {
      main: themeVariables.warning_main,
      light: themeVariables.warning_light,
      dark: themeVariables.warning_dark,
      contrastText: themeVariables.white,
    },
    info: {
      main: themeVariables.info_main,
      light: themeVariables.info_light,
      dark: themeVariables.info_dark,
      contrastText: themeVariables.white,
    },
    success: {
      main: themeVariables.success_main,
      light: themeVariables.success_light,
      dark: themeVariables.success_dark,
      contrastText: themeVariables.white,
    },
    grey: {
      gray_50: themeVariables.gray_50,
      gray_100: themeVariables.gray_100,
      gray_200: themeVariables.gray_200,
      gray_300: themeVariables.gray_300,
      gray_400: themeVariables.gray_400,
      gray_500: themeVariables.gray_500,
      gray_600: themeVariables.gray_600,
      gray_700: themeVariables.gray_700,
      gray_800: themeVariables.gray_800,
      gray_900: themeVariables.gray_900,
      gray_A100: themeVariables.gray_100,
      gray_A200: themeVariables.gray_200,
      gray_A400: themeVariables.gray_400,
      gray_A700: themeVariables.gray_700,
    },
    background: {
      paper: themeVariables.background_paper,
      default: themeVariables.background_default,
    },
  },
  components: {
    MuiDialog: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          maxWidth: '500px',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: '#272b33',
          color: 'white',
          border: 'none',
        },
        columnHeader: {
          backgroundColor: '#272b33',
          color: 'white',
        },
        row: {
          'backgroundColor': '#272b33',
          '&:hover': {
            backgroundColor: '#343942',
          },
          '&.Mui-selected': {
            'backgroundColor': '#343942',
            '&:hover': {
              backgroundColor: '#3e444f',
            },
          },
        },
        cell: {
          borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
        },
        columnSeparator: {
          color: 'rgba(255, 255, 255, 0.2)',
        },
        toolbar: {
          color: 'white',
        },
        pagination: {
          color: 'white',
        },
      },
    },
  },
  typography: {
    fontFamily: `"Manrope", "Roboto", "Helvetica", "Arial", sans-serif`,
    // "fontFamily": 				`"Sofia Pro", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeightExtraLight: 200,
    fontWeightLight: 300,
    // "fontWeightRegular": 		400,
    fontWeightRegular: 500,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    fontWeightExtraBold: 800,
  },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

export const DefaultAppTheme = createTheme(themeOptions)
export { themeVariables }
