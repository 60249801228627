/* eslint-disable react/display-name */
///////////////////////////////
// Description
///////////////////////////////

/*
DESCRIPTION / USAGE:
example component description

TODO:

*/

///////////////////////////////
// Imports
///////////////////////////////

import { returnTaskRows } from 'app/models/tasks'
import { findRecursiveTasks, returnTaskPrerequisiteAnalysisObject } from 'app/models/tasks/task_workflow_services'
import { dynamicSort, getProp, objectToArray, returnTimestampFromUnknownDateFormat } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

export const returnSortedWorkflowPhasesArray = (
  workflowTasks: TsInterface_UnspecifiedObject,
  workflow: TsInterface_UnspecifiedObject,
): TsInterface_UnspecifiedObject[] => {
  // Order Tasks
  let sortedMilestoneTasks: TsInterface_UnspecifiedObject[] = []
  let tasksThatReferenceThemselves = findRecursiveTasks(returnTaskPrerequisiteAnalysisObject(workflowTasks))
  let sortedTaskRows = returnTaskRows(workflowTasks, {}, tasksThatReferenceThemselves, ['Active Projects List useEffect'])
  // Loop through grouped rows
  let loopIndex = 0
  let previousMilestoneRowIndex = -1
  for (let loopRowKey in sortedTaskRows) {
    let loopRow = sortedTaskRows[loopRowKey]
    if (loopRow != null && loopRow['tasks'] != null) {
      // Sort Tasks Alphabetically - mostly important for milestone tasks on the same row to keep things consistent
      for (let loopTaskIndex in loopRow['tasks'].sort(dynamicSort('name', 'asc'))) {
        let loopTask = loopRow['tasks'][loopTaskIndex]
        if (loopTask != null && loopTask.key != null && workflow['filter_milestones'][loopTask.key] === true) {
          // Get all the tasks that occurred after the previous milestone task
          let phaseTasks: TsInterface_UnspecifiedObject = {}
          for (let loopSearchIndex = previousMilestoneRowIndex; loopSearchIndex < parseInt(loopRowKey); loopSearchIndex++) {
            // Loop through tasks on the rows
            let loopSearchRow = sortedTaskRows[loopSearchIndex]
            if (loopSearchRow != null && loopSearchRow['tasks'] != null) {
              for (let loopSearchTaskIndex in loopSearchRow['tasks']) {
                let loopSearchTask = loopSearchRow['tasks'][loopSearchTaskIndex]
                // Add non-milestone tasks to the phase tasks
                if (loopSearchTask != null && loopSearchTask.key != null && workflow['filter_milestones'][loopSearchTask.key] !== true) {
                  phaseTasks[loopSearchTask.key] = loopSearchTask
                }
              }
            }
          }
          // Add milestone task to phaseTasks
          phaseTasks[loopTask.key] = loopTask
          // Add the milestone task to the sorted milestone tasks array
          sortedMilestoneTasks.push({
            name: getProp(loopTask, 'name', null),
            key: getProp(loopTask, 'key', null),
            index: loopIndex,
            phase_tasks: phaseTasks,
          })
          loopIndex++
          previousMilestoneRowIndex = parseInt(loopRowKey)
        }
      }
    }
  }
  return sortedMilestoneTasks
}

export const returnProjectsSortedByMilestonePhase = (
  sortedWorkflowPhasesArray: TsInterface_UnspecifiedObject[],
  activeProjects: TsInterface_UnspecifiedObject,
  activeProjectTimestamps: TsInterface_UnspecifiedObject,
): TsInterface_UnspecifiedObject => {
  let projectsSortedByMilestone: TsInterface_UnspecifiedObject = {}
  // Loop through milestones and create starting project milestone object
  for (let loopMilestoneIndex in sortedWorkflowPhasesArray) {
    let loopMilestone = sortedWorkflowPhasesArray[loopMilestoneIndex]
    projectsSortedByMilestone[loopMilestone.key] = {}
  }
  let firstMilestoneTaskKey = sortedWorkflowPhasesArray[0].key
  // Loop through projects and determine where they fall in the milestone array
  for (let loopProjectKey in activeProjects) {
    let loopProject = activeProjects[loopProjectKey]
    let projectLeastFarMilestoneIndex = sortedWorkflowPhasesArray.length
    let projectLeastFarMilestoneKey = null
    if (loopProject != null && loopProject.task_statuses != null) {
      let foundMilestoneLocation = false
      for (let loopMilestoneTaskIndex in sortedWorkflowPhasesArray) {
        let loopMilestoneTask = getProp(sortedWorkflowPhasesArray, loopMilestoneTaskIndex, {})
        let loopMilestoneTaskKey = getProp(loopMilestoneTask, 'key', '')
        if (loopProject != null && loopProject['task_statuses'] != null && loopProject['task_statuses'][loopMilestoneTaskKey] !== true) {
          if (parseInt(loopMilestoneTaskIndex) <= projectLeastFarMilestoneIndex) {
            projectLeastFarMilestoneIndex = parseInt(loopMilestoneTaskIndex)
            projectLeastFarMilestoneKey = loopMilestoneTaskKey
            foundMilestoneLocation = true
          }
        }
      }
      if (foundMilestoneLocation === true && projectLeastFarMilestoneKey != null) {
        projectsSortedByMilestone[projectLeastFarMilestoneKey][loopProjectKey] = activeProjects[loopProjectKey]
      } else {
        projectsSortedByMilestone[firstMilestoneTaskKey][loopProjectKey] = activeProjects[loopProjectKey]
      }
    } else {
      projectsSortedByMilestone[firstMilestoneTaskKey][loopProjectKey] = activeProjects[loopProjectKey]
    }
  }
  // Determine Days spent in phase
  let currentDate = new Date()
  for (let loopMilestoneKey in projectsSortedByMilestone) {
    let loopMilestone = projectsSortedByMilestone[loopMilestoneKey]
    for (let loopProjectKey in loopMilestone) {
      let loopProjectTimestamps = getProp(activeProjectTimestamps, loopProjectKey, {})
      let loopProjectDaysInPhase: number | null = null
      let lastMilestoneTimestamp: number | null = null
      // Get the index of the milestone
      let milestoneIndex = sortedWorkflowPhasesArray.findIndex((milestone) => milestone.key === loopMilestoneKey)
      if (loopProjectTimestamps != null && objectToArray(loopProjectTimestamps).length > 0) {
        if (milestoneIndex > 1) {
          // Get the index of the previous milestone
          let previousMilestoneIndex = milestoneIndex - 1
          let previousMilestoneKey = sortedWorkflowPhasesArray[previousMilestoneIndex].key
          // Use the last updated date of the previous milestone
          if (
            loopProjectTimestamps != null &&
            loopProjectTimestamps['task_completion_timestamps'] != null &&
            loopProjectTimestamps['key'] != null &&
            loopProjectTimestamps['task_completion_timestamps'][loopProjectTimestamps['key'] + '_' + previousMilestoneKey] != null
          ) {
            let previousMilestoneDate = returnTimestampFromUnknownDateFormat(
              loopProjectTimestamps['task_completion_timestamps'][loopProjectTimestamps['key'] + '_' + previousMilestoneKey],
            )
            if (previousMilestoneDate != null) {
              loopProjectDaysInPhase = Math.floor((currentDate.getTime() - previousMilestoneDate) / 86400000)
              lastMilestoneTimestamp = previousMilestoneDate
            }
          }
        }
        if (milestoneIndex === -1 || milestoneIndex === 0 || loopProjectDaysInPhase == null) {
          // Use Oldest Task Date
          let oldestTaskDate = null
          if (loopProjectTimestamps != null && loopProjectTimestamps['task_completion_timestamps'] != null) {
            for (let loopTaskKey in loopProjectTimestamps['task_completion_timestamps']) {
              if (
                oldestTaskDate == null ||
                (loopProjectTimestamps['task_completion_timestamps'][loopTaskKey] != null &&
                  returnTimestampFromUnknownDateFormat(loopProjectTimestamps['task_completion_timestamps'][loopTaskKey]) < oldestTaskDate)
              ) {
                oldestTaskDate = returnTimestampFromUnknownDateFormat(loopProjectTimestamps['task_completion_timestamps'][loopTaskKey])
              }
            }
          }
          // Number of days between oldest task date and now
          if (oldestTaskDate != null) {
            loopProjectDaysInPhase = Math.floor((currentDate.getTime() - oldestTaskDate) / 86400000)
            lastMilestoneTimestamp = oldestTaskDate
          }
        }
      }
      if (
        projectsSortedByMilestone != null &&
        projectsSortedByMilestone[loopMilestoneKey] != null &&
        projectsSortedByMilestone[loopMilestoneKey][loopProjectKey]
      ) {
        projectsSortedByMilestone[loopMilestoneKey][loopProjectKey]['TEMP_days_in_phase'] = loopProjectDaysInPhase
        projectsSortedByMilestone[loopMilestoneKey][loopProjectKey]['TEMP_last_milestone_timestamp'] = lastMilestoneTimestamp
      }
    }
  }
  return projectsSortedByMilestone
}
