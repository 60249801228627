/* eslint-disable react/prop-types */
//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:


		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Switch, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography } from '@mui/material/'
import { rejects } from 'assert'
import { CollectionReference, DocumentData, DocumentReference } from 'firebase/firestore'
import { StorageReference } from 'firebase/storage'
import { memo, useContext, useEffect, useReducer, useState } from 'react'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientUser,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_FormDialog,
  Context_UserInterface_PromptDialog,
} from 'rfbp_core/services/context'
import {
  DatabaseAddDocument,
  DatabaseBatchUpdate,
  DatabaseDeleteDocument,
  DatabaseGetLiveCollection,
  DatabaseSetMergeDocument,
  StorageUploadFile,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import { cloneObjectWithoutReference, dynamicSort, getProp, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { v4 as uuidv4 } from 'uuid'
import { FileUploadButton } from '../file_upload'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from '../form'
import { Icon } from '../icons'
import { TsInterface_TableAdditionalData, TsInterface_TableDataRow, TsInterface_TableHooks, TsInterface_TableManageActionsObject } from '../table'
import { TableCellManageProper } from '../table/cells/table_cell_manage'
import { TabButtons } from '../tabs/tab_buttons'

///////////////////////////////
// Typescript
///////////////////////////////

export type TsType_FileSystemCollectionDatabaseEndpoint = (clientKey: string) => CollectionReference<DocumentData>
export type TsType_FileSystemDocumentDatabaseEndpoint = (clientKey: string, fileKey: string) => DocumentReference<DocumentData>
export type TsType_FileSystemStorageEndpoint = (clientKey: string, fileName: string) => StorageReference

export interface TsInterface_FileSystemSettings {
  allow_file_archiving: boolean
  allow_file_movement: boolean
  allow_file_unarchiving: boolean
  allow_file_uploads: boolean
  allow_folder_creation: boolean
  allow_folder_deletion: boolean
  allow_folder_movement: boolean
  allow_folder_rename: boolean
  allow_link_creation?: boolean
  archive_filter_visible: boolean
}

interface TsInterface_FileSystemHardcodedFolder {
  key: string
  associated_parent_folder_key: string | null
  file_system_type: 'hardcoded_folder'
  folder_name: string
  status: 'active'
}

export interface TsInterface_FileSystemHardcodedFolders {
  [$folderKey: string]: TsInterface_FileSystemHardcodedFolder
}

interface TsInterface_ComponentProps {
  fileSystemCollectionDatabaseEndpoint: TsType_FileSystemCollectionDatabaseEndpoint
  fileSystemDocumentDatabaseEndpoint: TsType_FileSystemDocumentDatabaseEndpoint
  fileSystemStorageEndpoint: TsType_FileSystemStorageEndpoint
  fileSystemHardCodedFolders: TsInterface_UnspecifiedObject
  fileSystemSettings: TsInterface_FileSystemSettings
}

interface TsInterface_FilesToUpload {
  [$fileKey: string]: {
    file_name: string
    file: any
    data_url: any
    storage_url?: string | null
  }
}

interface TsInterface_DraggableFileOrFolder {
  file: TsInterface_UnspecifiedObject
  children: JSX.Element
}

interface TsInterface_DroppableFolder {
  folder: TsInterface_UnspecifiedObject
  moveFileOrFolder: any
  children: JSX.Element
}

///////////////////////////////
// Variables
///////////////////////////////

// Defaults
const defaultFileSystemSettings: TsInterface_FileSystemSettings = {
  allow_file_archiving: true,
  allow_file_movement: true,
  allow_file_unarchiving: true,
  allow_file_uploads: true, // DONE
  allow_folder_creation: true, // DONE
  allow_folder_deletion: true,
  allow_folder_movement: true,
  allow_folder_rename: true,
  allow_link_creation: true,
  archive_filter_visible: true, // DONE
}

const returnIconKeyForFileType = (fileType: string): JSX.Element => {
  let icon = (
    <Icon
      icon="file"
      sx={{ color: themeVariables.white }}
    />
  )
  switch (fileType) {
    case 'pdf':
      icon = (
        <Icon
          icon="file-pdf"
          sx={{ color: themeVariables.error_main }}
        />
      )
      break
    case 'doc':
      icon = (
        <Icon
          icon="file-word"
          sx={{ color: themeVariables.info_main }}
        />
      )
      break
    case 'docx':
      icon = (
        <Icon
          icon="file-word"
          sx={{ color: themeVariables.info_main }}
        />
      )
      break
    case 'ppt':
      icon = (
        <Icon
          icon="file-powerpoint"
          sx={{ color: themeVariables.error_main }}
        />
      )
      break
    case 'pptx':
      icon = (
        <Icon
          icon="file-powerpoint"
          sx={{ color: themeVariables.error_main }}
        />
      )
      break
    case 'xlsx':
      icon = (
        <Icon
          icon="file-excel"
          sx={{ color: themeVariables.success_main }}
        />
      )
      break
    case 'csv':
      icon = (
        <Icon
          icon="file-csv"
          sx={{ color: themeVariables.success_main }}
        />
      )
      break
    case 'jpg':
      icon = (
        <Icon
          icon="file-image"
          sx={{ color: themeVariables.primary_light }}
        />
      )
      break
    case 'jpeg':
      icon = (
        <Icon
          icon="file-image"
          sx={{ color: themeVariables.primary_light }}
        />
      )
      break
    case 'png':
      icon = (
        <Icon
          icon="file-image"
          sx={{ color: themeVariables.primary_light }}
        />
      )
      break
    case 'zip':
      icon = (
        <Icon
          icon="file-zipper"
          sx={{ color: themeVariables.gray_400 }}
        />
      )
      break
    case 'link':
      icon = (
        <Icon
          icon="link"
          sx={{ color: themeVariables.info_main }}
        />
      )
      break
    // file-audio
    // file-video
  }
  return icon
}

///////////////////////////////
// Functions
///////////////////////////////

const DraggableFileOrFolder: React.FC<TsInterface_DraggableFileOrFolder> = memo(function DraggableFileOrFolder({ file, children }) {
  // Variables
  const [{ isDragging }, drag] = useDrag({
    type: 'file', // Define your custom item type
    item: { ...file }, // Pass the item as a prop
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  })

  // Return JSX
  return (
    <Box
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: 'pointer',
      }}
      data-filekey={file.key}
      className="tw-inline-block tw-p-1 tw-rounded"
    >
      {children}
    </Box>
  )
})

const DroppableFolder: React.FC<TsInterface_DroppableFolder> = memo(function DroppableFolder({ folder, moveFileOrFolder, children }) {
  // Variables
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: 'file',
    drop: (file: TsInterface_UnspecifiedObject) => {
      moveFileOrFolder(file.key, folder.key)
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  // Return JSX
  const isActive = isOver && canDrop
  let backgroundColor = 'rgba(0,0,0,0)'
  if (isActive) {
    backgroundColor = themeVariables.info_light
  } else if (canDrop) {
    backgroundColor = themeVariables.warning_dark
  }
  // Return JSX
  return (
    <Box
      ref={drop}
      style={{ backgroundColor }}
      data-folderkey={folder.key}
      sx={{ background: backgroundColor }}
      className="tw-inline-block tw-p-1 tw-rounded"
    >
      {children}
    </Box>
  )
})

///////////////////////////////
// Component
///////////////////////////////

export const FileSystemBasic = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props
  let pr_fileSystemCollectionDatabaseEndpoint: TsType_FileSystemCollectionDatabaseEndpoint = getProp(
    props,
    'fileSystemCollectionDatabaseEndpoint',
    (clientKey: string) => {
      return null
    },
  )
  let pr_fileSystemDocumentDatabaseEndpoint: TsType_FileSystemDocumentDatabaseEndpoint = getProp(
    props,
    'fileSystemDocumentDatabaseEndpoint',
    (clientKey: string, fileKey: string) => {
      return null
    },
  )
  let pr_fileSystemStorageEndpoint: TsType_FileSystemStorageEndpoint = getProp(props, 'fileSystemStorageEndpoint', (clientKey: string, fileName: string) => {
    return null
  })
  let pr_fileSystemHardCodedFolders: TsInterface_UnspecifiedObject = getProp(props, 'fileSystemHardCodedFolders', {})
  let pr_fileSystemSettings: TsInterface_FileSystemSettings = getProp(props, 'fileSystemSettings', defaultFileSystemSettings)

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  // const { uc_setUserInterface_ErrorDialogDisplay } = 						useContext( Context_UserInterface_ErrorDialog )
  const [us_activeFolderKey, us_setActiveFolderKey] = useState<string | null>(null)
  const [us_allFilesAndFolders, us_setAllFilesAndFolders] = useState<TsInterface_UnspecifiedObject>({})
  const [us_visibleFileStatus, us_setVisibleFileStatus] = useState<'active' | 'archived'>('active')
  const [us_visibleFiles, us_setVisibleFiles] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_visibleFolders, us_setVisibleFolders] = useState<TsInterface_UnspecifiedObject[]>([])
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAllFilesAndFolders(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(pr_fileSystemCollectionDatabaseEndpoint(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_fileSystemCollectionDatabaseEndpoint])

  useEffect(() => {
    let visibleFiles: TsInterface_UnspecifiedObject[] = []
    let visibleFolders: TsInterface_UnspecifiedObject[] = []
    for (let loopHardcodedFolderKey in pr_fileSystemHardCodedFolders) {
      let loopHardcodedFolder = cloneObjectWithoutReference(pr_fileSystemHardCodedFolders[loopHardcodedFolderKey])
      if (loopHardcodedFolder) {
        if (
          loopHardcodedFolder['associated_parent_folder_key'] === us_activeFolderKey ||
          (us_activeFolderKey == null &&
            pr_fileSystemHardCodedFolders[loopHardcodedFolder['associated_parent_folder_key']] == null &&
            us_allFilesAndFolders[loopHardcodedFolder['associated_parent_folder_key']] == null) ||
          (us_activeFolderKey == null && loopHardcodedFolder['associated_parent_folder_key'] === loopHardcodedFolder['key'])
        ) {
          visibleFolders.push(loopHardcodedFolder)
        }
      }
    }
    for (let loopFileOrFolderKey in us_allFilesAndFolders) {
      let loopFileOrFolder = cloneObjectWithoutReference(us_allFilesAndFolders[loopFileOrFolderKey])
      if (loopFileOrFolder.file_system_type === 'file') {
        if (
          loopFileOrFolder['associated_parent_folder_key'] === us_activeFolderKey ||
          (us_activeFolderKey == null &&
            pr_fileSystemHardCodedFolders[loopFileOrFolder['associated_parent_folder_key']] == null &&
            us_allFilesAndFolders[loopFileOrFolder['associated_parent_folder_key']] == null)
        ) {
          if (us_visibleFileStatus === loopFileOrFolder.status) {
            visibleFiles.push(loopFileOrFolder)
          }
        }
      } else if (loopFileOrFolder.file_system_type === 'folder') {
        if (
          loopFileOrFolder['associated_parent_folder_key'] === us_activeFolderKey ||
          (us_activeFolderKey == null &&
            pr_fileSystemHardCodedFolders[loopFileOrFolder['associated_parent_folder_key']] == null &&
            us_allFilesAndFolders[loopFileOrFolder['associated_parent_folder_key']] == null) ||
          (us_activeFolderKey == null && loopFileOrFolder['associated_parent_folder_key'] === loopFileOrFolder['key'])
        ) {
          visibleFolders.push(loopFileOrFolder)
        }
      }
    }
    us_setVisibleFiles(visibleFiles.sort(dynamicSort('file_name', 'asc')))
    us_setVisibleFolders(visibleFolders.sort(dynamicSort('folder_name', 'asc')))
  }, [us_visibleFileStatus, us_allFilesAndFolders, pr_fileSystemHardCodedFolders, us_activeFolderKey])

  // Functions

  // File Uploads
  const readAsDataURL = (file: any) => {
    return new Promise((resolve, reject) => {
      const fr = new FileReader()
      fr.onerror = reject
      fr.onload = () => {
        resolve(fr.result)
      }
      fr.readAsDataURL(file)
    })
  }

  const uploadFilesProper = (files: TsInterface_FilesToUpload): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      let promiseArray: TsType_UnknownPromise[] = []
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          for (let fileIndex in files) {
            let file = files[fileIndex]
            let dateString = returnFormattedDate(new Date(), 'MM-DD-YYYY HH:mm:ss')
            let nameWithoutType = file.file_name.substring(0, file.file_name.lastIndexOf('.'))
            let fileType = file.file_name.substring(file.file_name.lastIndexOf('.') + 1, file.file_name.length)
            let fileNameWithTimestamp = nameWithoutType + ' (' + dateString + ').' + fileType
            promiseArray.push(
              StorageUploadFile(
                pr_fileSystemStorageEndpoint(res_GCK.clientKey, fileNameWithTimestamp),
                // file.data_url,
                file.file,
                {}, // No metadata for now
              )
                .then((res_SUF: any) => {
                  files[fileIndex]['storage_url'] = getProp(res_SUF, 'url', null)
                })
                .catch((rej_SUF) => {
                  console.error(rej_SUF)
                }),
            )
          }
          Promise.all(promiseArray).finally(() => {
            let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
            for (let fileIndex in files) {
              let file = files[fileIndex]
              let dateString = returnFormattedDate(new Date(), 'MM-DD-YYYY HH:mm:ss')
              let nameWithoutType = file.file_name.substring(0, file.file_name.lastIndexOf('.'))
              let fileType = file.file_name.substring(file.file_name.lastIndexOf('.') + 1, file.file_name.length)
              let fileNameWithTimestamp = nameWithoutType + ' (' + dateString + ').' + fileType
              let fileKey = uuidv4()
              updateArray.push({
                type: 'setMerge',
                ref: pr_fileSystemDocumentDatabaseEndpoint(res_GCK.clientKey, fileKey),
                data: {
                  associated_parent_folder_key: us_activeFolderKey,
                  associated_uploader_name: getProp(uc_RootData_ClientUser, 'name', null),
                  file_name: file.file_name,
                  file_name_with_timestamp: fileNameWithTimestamp,
                  file_system_type: 'file',
                  file_type: fileType,
                  key: fileKey,
                  status: 'active',
                  timestamp_uploaded: new Date(),
                  url: getProp(file, 'storage_url', null),
                },
              })
            }
            DatabaseBatchUpdate(updateArray)
              .then((res_DBU) => {
                resolve(res_DBU)
              })
              .catch((rej_DBU) => {
                reject(rej_DBU)
              })
          })
        })
        .catch((rej_GCK) => {
          reject(rej_GCK)
        })
    })
  }

  const uploadFiles = (event: React.ChangeEvent<HTMLInputElement>, additionalFileUploadParams: TsInterface_UnspecifiedObject): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      if (event != null && event.target != null && event.target.files !== null && event.target?.files?.length > 0) {
        let promiseArray: TsType_UnknownPromise[] = []
        let files = event.target.files
        let readFiles: TsInterface_UnspecifiedObject = {}
        for (let fileIndex in files) {
          let file = files[fileIndex]
          if (file != null && typeof file === 'object') {
            promiseArray.push(
              readAsDataURL(file)
                .then((res_RADURL) => {
                  readFiles[fileIndex] = {
                    file_name: file.name,
                    file: file,
                    data_url: res_RADURL,
                  }
                })
                .catch((rej_RADURL) => {
                  // Nothing
                }),
            )
          }
        }
        Promise.all(promiseArray).finally(() => {
          promiseArray.push(
            uploadFilesProper(readFiles)
              .then((res_ITF) => {
                resolve(res_ITF)
              })
              .catch((rej_ITF) => {
                reject(rej_ITF)
              }),
          )
        })
      } else {
        reject({
          success: false,
          error: {
            message: rLIB('Failed to Upload File'),
            details: rLIB('Invalid File Selection'),
            code: 'ER-D-TFS-FOS-02',
          },
        })
      }
    })
  }

  // File Management
  const viewFile = (fileUrl: string): void => {
    if (fileUrl != null) {
      window.open(fileUrl as string, '_blank')
    }
  }

  const archiveFile = (fileKey: string): void => {
    uc_setUserInterface_ConfirmDialogDisplay({
      display: true,
      confirm: {
        color: 'error',
        header: <>{rLIB('Archive File')}</>,
        icon: (
          <Icon
            icon="box-archive"
            type="solid"
          />
        ),
        submit_text: rLIB('Archive'),
        text: <>{rLIB('Are you sure that you want to archive this document?')}</>,
        submit_callback: () => {
          return new Promise((resolve, reject) => {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                console.log(fileKey)

                DatabaseSetMergeDocument(pr_fileSystemDocumentDatabaseEndpoint(res_GCK.clientKey, fileKey), { status: 'archived' })
                  .then((res_DDD) => {
                    resolve({ close_dialog: true })
                  })
                  .catch((rej_DDD) => {
                    reject(rej_DDD)
                  })
              })
              .catch((rej_GCK) => {
                reject(rej_GCK)
              })
          })
        },
      },
    })
  }

  const unarchiveFile = (fileKey: string): void => {
    uc_setUserInterface_ConfirmDialogDisplay({
      display: true,
      confirm: {
        color: 'warning',
        header: <>{rLIB('Unarchive File')}</>,
        icon: (
          <Icon
            icon="wand-magic-sparkles"
            type="solid"
          />
        ),
        submit_text: rLIB('Unarchive'),
        text: <>{rLIB('Are you sure that you want to unarchive this document?')}</>,
        submit_callback: () => {
          return new Promise((resolve, reject) => {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                DatabaseSetMergeDocument(pr_fileSystemDocumentDatabaseEndpoint(res_GCK.clientKey, fileKey), { status: 'active' })
                  .then((res_DDD) => {
                    resolve({ close_dialog: true })
                  })
                  .catch((rej_DDD) => {
                    reject(rej_DDD)
                  })
              })
              .catch((rej_GCK) => {
                reject(rej_GCK)
              })
          })
        },
      },
    })
  }

  const moveFileOrFolder = (fileOrFolderKey: string, newParentFolderKey: string): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      if (fileOrFolderKey !== newParentFolderKey) {
        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
          .then((res_GCK) => {
            DatabaseSetMergeDocument(pr_fileSystemDocumentDatabaseEndpoint(res_GCK.clientKey, fileOrFolderKey), {
              associated_parent_folder_key: newParentFolderKey,
            })
              .then((res_DSMD) => {
                resolve({ close_dialog: true })
              })
              .catch((rej_DSMD) => {
                reject(rej_DSMD)
              })
          })
          .catch((rej_GCK) => {
            reject(rej_GCK)
          })
      }
    })
  }

  // Folder Management
  function returnFolderPathArray(selectedFolderKey: string | null, allFilesAndFolders: TsInterface_UnspecifiedObject) {
    let pathArray: TsInterface_UnspecifiedObject[] = []
    // Recursive function to find and add parent folders to pathArray
    function findFolderPath(key: string | null) {
      if (!key || !allFilesAndFolders[key]) {
        return
      }
      let item = allFilesAndFolders[key]
      // Recursively get parent folders first, so our pathArray gets ordered correctly
      findFolderPath(item.associated_parent_folder_key)
      // Add the current folder to the pathArray
      pathArray.push(item)
    }
    findFolderPath(selectedFolderKey)
    return pathArray
  }

  const selectFolder = (folderKey: string): void => {
    us_setActiveFolderKey(folderKey)
  }

  const createFolder = (): void => {
    // TODO - make sure that there isn't a folder with the same name in the same parent

    uc_setUserInterface_PromptDialogDisplay({
      display: true,
      prompt: {
        color: 'success',
        confirm_text: rLIB('Create Folder') as JSX.Element,
        default_value: '',
        header: rLIB('New Folder'),
        icon: (
          <Icon
            icon="circle-plus"
            type="solid"
          />
        ),
        input_label: rLIB('Folder Name') as JSX.Element,
        input_type: 'text',
        text: <>{rLIB('Enter a name for this folder')}</>,
        submit_callback: (promptValue: string) => {
          return new Promise((resolve, reject) => {
            if (promptValue !== '' && promptValue != null) {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let folderUpdateObject = {
                    associated_creator_name: getProp(uc_RootData_ClientUser, 'name', null),
                    associated_parent_folder_key: us_activeFolderKey,
                    file_system_type: 'folder',
                    folder_name: promptValue,
                    status: 'active',
                    timestamp_created: new Date(),
                  }
                  DatabaseAddDocument(pr_fileSystemCollectionDatabaseEndpoint(res_GCK.clientKey), folderUpdateObject, true)
                    .then((res_DAD) => {
                      resolve({ close_dialog: true })
                    })
                    .catch((rej_DAD) => {
                      reject(rej_DAD)
                    })
                })
                .catch((rej_GCK) => {
                  reject(rej_GCK)
                })
            } else {
              resolve({ close_dialog: false })
            }
          })
        },
      },
    })
  }

  const createLink = (): void => {
    // TODO - make sure that there isn't a folder with the same name in the same parent

    // allow_link_creation

    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: {
            file_name: {
              key: 'file_name',
              label: rLIB('Linked File Name'),
              input_type: 'text_basic',
              required: true,
              data_type: 'string',
            },
            url: {
              key: 'url',
              label: rLIB('URL'),
              input_type: 'text_basic',
              required: true,
              data_type: 'string',
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let folderUpdateObject = {
                    associated_parent_folder_key: us_activeFolderKey,
                    associated_uploader_name: getProp(uc_RootData_ClientUser, 'name', null),
                    file_name: formSubmittedData.file_name,
                    file_name_with_timestamp: formSubmittedData.file_name,
                    file_system_type: 'file',
                    file_type: 'link',
                    status: 'active',
                    timestamp_uploaded: new Date(),
                    url: formSubmittedData.url,
                  }
                  DatabaseAddDocument(pr_fileSystemCollectionDatabaseEndpoint(res_GCK.clientKey), folderUpdateObject, true)
                    .then((res_DAD) => {
                      resolve({ close_dialog: true })
                    })
                    .catch((rej_DAD) => {
                      reject(rej_DAD)
                    })
                })
                .catch((rej_GCK) => {
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'info',
          formDialogHeaderText: <>{rLIB('Add Linked File')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="link"
            />
          ),
        },
      },
    })
  }

  const renameFolder = (folderKey: string, folderName: string): void => {
    uc_setUserInterface_PromptDialogDisplay({
      display: true,
      prompt: {
        color: 'success',
        confirm_text: rLIB('Create Folder') as JSX.Element,
        default_value: folderName || '',
        header: rLIB('New Folder'),
        icon: (
          <Icon
            icon="pen-to-square"
            type="solid"
          />
        ),
        input_label: rLIB('Folder Name') as JSX.Element,
        input_type: 'text',
        text: <>{rLIB('Enter a name for this folder')}</>,
        submit_callback: (promptValue: string) => {
          return new Promise((resolve, reject) => {
            if (promptValue !== '' && promptValue != null) {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(pr_fileSystemDocumentDatabaseEndpoint(res_GCK.clientKey, folderKey), { folder_name: promptValue })
                    .then((res_DSMD) => {
                      resolve({ close_dialog: true })
                    })
                    .catch((rej_DSMD) => {
                      reject(rej_DSMD)
                    })
                })
                .catch((rej_GCK) => {
                  reject(rej_GCK)
                })
            } else {
              resolve({ close_dialog: false })
            }
          })
        },
      },
    })
  }

  const deleteFolder = (folderKey: string): void => {
    uc_setUserInterface_ConfirmDialogDisplay({
      display: true,
      confirm: {
        color: 'error',
        header: <>{rLIB('Delete Folder')}</>,
        icon: (
          <Icon
            icon="trash"
            type="solid"
          />
        ),
        submit_text: rLIB('Delete'),
        text: <>{rLIB('Are you sure that you want to delete this folder?')}</>,
        submit_callback: () => {
          return new Promise((resolve, reject) => {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                DatabaseDeleteDocument(pr_fileSystemDocumentDatabaseEndpoint(res_GCK.clientKey, folderKey))
                  .then((res_DDD) => {
                    resolve({ close_dialog: true })
                  })
                  .catch((rej_DDD) => {
                    reject(rej_DDD)
                  })
              })
              .catch((rej_GCK) => {
                reject(rej_GCK)
              })
          })
        },
      },
    })
  }

  const toggleVisibleToCustomer = (fileKey: string, visibleToCustomer: boolean): void => {
    // TODO - make sure that there isn't a folder with the same name in the same parent
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseSetMergeDocument(pr_fileSystemDocumentDatabaseEndpoint(res_GCK.clientKey, fileKey), { visible_to_customer: !visibleToCustomer })
      })
      .catch((rej_GCK) => {
        rejects(rej_GCK)
      })
  }

  // JSX Generation
  const rJSX_ToggleArchivedFilesButton = (shrinkButton: boolean): JSX.Element => {
    let buttonTextJSX = <></>
    if (us_visibleFileStatus === 'active') {
      buttonTextJSX = rLIB('Active Files') as JSX.Element
    } else {
      buttonTextJSX = rLIB('Archived Files') as JSX.Element
    }
    let buttonJSX = <></>
    if (pr_fileSystemSettings.archive_filter_visible === true) {
      if (shrinkButton === true) {
        buttonJSX = (
          <Tooltip
            title={buttonTextJSX}
            placement="top"
          >
            <Button
              color="inherit"
              variant="outlined"
              onClick={() => {
                if (us_visibleFileStatus === 'active') {
                  us_setVisibleFileStatus('archived')
                } else {
                  us_setVisibleFileStatus('active')
                }
              }}
              disableElevation
              className="tw-mr-2 bp_icon_only_button"
              startIcon={<Icon icon="filter" />}
            ></Button>
          </Tooltip>
        )
      } else {
        buttonJSX = (
          <Button
            color="inherit"
            variant="outlined"
            onClick={() => {
              if (us_visibleFileStatus === 'active') {
                us_setVisibleFileStatus('archived')
              } else {
                us_setVisibleFileStatus('active')
              }
            }}
            disableElevation
            className="tw-mr-2"
            startIcon={<Icon icon="filter" />}
          >
            {buttonTextJSX}
          </Button>
        )
      }
    }
    return buttonJSX
  }

  const rJSX_UploadFileButton = (shrinkButton: boolean): JSX.Element => {
    let buttonJSX = <></>
    if (pr_fileSystemSettings.allow_file_uploads === true) {
      if (shrinkButton === true) {
        buttonJSX = (
          <Tooltip
            title={rLIB('Upload File')}
            placement="top"
          >
            <FileUploadButton
              multiple={true}
              button={{
                text: <></>,
                icon: (
                  <Icon
                    icon="cloud-arrow-up"
                    sx={{ fontSize: '20px' }}
                  />
                ),
                color: 'info',
                className: 'tw-mr-2 bp_icon_only_button',
                variant: 'contained',
                disabled: false,
              }}
              accept="*"
              onChange={uploadFiles}
              additionalFileUploadParams={{}}
            />
          </Tooltip>
        )
      } else {
        buttonJSX = (
          <FileUploadButton
            multiple={true}
            button={{
              text: rLIB('Upload File') as JSX.Element,
              icon: (
                <Icon
                  icon="cloud-arrow-up"
                  className="tw-mr-2"
                  sx={{ fontSize: '20px' }}
                />
              ),
              color: 'info',
              className: 'tw-mr-2',
              variant: 'contained',
              disabled: false,
            }}
            accept="*"
            onChange={uploadFiles}
            additionalFileUploadParams={{}}
          />
        )
      }
    }
    return buttonJSX
  }

  const rJSX_CreateFolderButton = (shrinkButton: boolean): JSX.Element => {
    let buttonJSX = <></>
    if (pr_fileSystemSettings.allow_folder_creation === true) {
      if (shrinkButton === true) {
        buttonJSX = (
          <Tooltip
            title={rLIB('Create Folder')}
            placement="top"
          >
            <Button
              color="success"
              variant="contained"
              onClick={() => {
                createFolder()
              }}
              disableElevation
              className="tw-mr-2 bp_icon_only_button"
            >
              <Icon
                icon="circle-plus"
                className="tw-mr-2"
              />
            </Button>
          </Tooltip>
        )
      } else {
        buttonJSX = (
          <Button
            color="success"
            variant="contained"
            onClick={() => {
              createFolder()
            }}
            disableElevation
            className="tw-mr-2"
            startIcon={<Icon icon="circle-plus" />}
          >
            {rLIB('Create Folder')}
          </Button>
        )
      }
    }
    return buttonJSX
  }

  const rJSX_CreateLinkButton = (shrinkButton: boolean): JSX.Element => {
    let buttonJSX = <></>
    if (pr_fileSystemSettings.allow_link_creation === true) {
      if (shrinkButton === true) {
        buttonJSX = (
          <Tooltip
            title={rLIB('Add Linked File')}
            placement="top"
          >
            <Button
              color="info"
              variant="contained"
              onClick={() => {
                createLink()
              }}
              disableElevation
              className="tw-mr-2 bp_icon_only_button"
              startIcon={<Icon icon="link" />}
            ></Button>
          </Tooltip>
        )
      } else {
        buttonJSX = (
          <Button
            color="info"
            variant="contained"
            onClick={() => {
              createLink()
            }}
            disableElevation
            className="tw-mr-2"
            startIcon={<Icon icon="link" />}
          >
            {rLIB('Add Linked File')}
          </Button>
        )
      }
    }
    return buttonJSX
  }

  const rJSX_FolderPath = (): JSX.Element => {
    let pathJSX = <></>
    let filePath = returnFolderPathArray(us_activeFolderKey, {
      ...pr_fileSystemHardCodedFolders,
      ...us_allFilesAndFolders,
    })
    const rJSX_AngleIcon = (index: number) => {
      let iconJSX = <></>
      if (index < filePath.length - 1) {
        iconJSX = (
          <Icon
            icon="angle-right"
            className="tw-mx-2 tw-opacity-50"
          />
        )
      }
      return iconJSX
    }
    if (filePath.length > 0) {
      pathJSX = (
        <Box className="tw-p-1">
          <Box
            component="span"
            className="tw-cursor-pointer"
            onClick={() => {
              us_setActiveFolderKey(null)
            }}
          >
            <DroppableFolder
              folder={{ key: null }}
              moveFileOrFolder={moveFileOrFolder}
              // eslint-disable-next-line react/no-children-prop
              children={
                <Icon
                  icon="home"
                  className="tw-mx-2"
                />
              }
            />
          </Box>
          <Icon
            icon="angle-right"
            className="tw-mx-2 tw-opacity-50"
          />
          {filePath.map((pathFolder: TsInterface_UnspecifiedObject, pathFolderIndex: number) => (
            <Box
              component="span"
              key={pathFolderIndex}
              onClick={() => {
                us_setActiveFolderKey(pathFolder.key)
              }}
              className="tw-cursor-pointer"
            >
              <DroppableFolder
                folder={pathFolder}
                moveFileOrFolder={moveFileOrFolder}
                // eslint-disable-next-line react/no-children-prop
                children={<>{pathFolder.folder_name}</>}
              />
              {rJSX_AngleIcon(pathFolderIndex)}
            </Box>
          ))}
        </Box>
      )
    } else {
      pathJSX = (
        <Box className="tw-p-1">
          <Box
            component="span"
            className="tw-cursor-pointer tw-inline-block tw-p-1 tw-rounded"
            onClick={() => {
              us_setActiveFolderKey(null)
            }}
          >
            <Icon
              icon="home"
              className="tw-ml-2"
            />
          </Box>
        </Box>
      )
    }
    return pathJSX
  }

  const folderActions: TsInterface_TableManageActionsObject = {
    open: {
      icon: (
        <Icon
          type="solid"
          icon="arrow-up-right-from-square"
        />
      ),
      label: rLIB('Open'),
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        selectFolder(rowData.key as string)
      },
    },
    rename: {
      icon: (
        <Icon
          type="solid"
          icon="pen-to-square"
        />
      ),
      label: rLIB('Rename Folder'),
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'file_system_type',
        comparator: '!=',
        value: 'hardcoded_folder',
        conditions: [],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        renameFolder(rowData.key as string, rowData.folder_name as string)
      },
    },
    delete: {
      icon: (
        <Icon
          type="solid"
          icon="trash"
        />
      ),
      label: rLIB('Delete'),
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'file_system_type',
        comparator: '!=',
        value: 'hardcoded_folder',
        conditions: [],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        deleteFolder(rowData.key as string)
      },
    },
  }

  const fileActions: TsInterface_TableManageActionsObject = {
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: rLIB('View File'),
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        viewFile(rowData.url as string)
      },
    },
    archive: {
      icon: <Icon icon="box-archive" />,
      label: <>{rLIB('Archive')}</>,
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '!=',
        value: 'archived',
        conditions: [],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        archiveFile(rowData.key as string)
      },
    },
    unarchive: {
      icon: <Icon icon="wand-magic-sparkles" />,
      label: <>{rLIB('Unarchive')}</>,
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'archived',
        conditions: [],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        unarchiveFile(rowData.key as string)
      },
    },
  }

  if (getProp(pr_fileSystemSettings, 'allow_file_archiving', false) === false) {
    delete fileActions.archive
  }

  if (getProp(pr_fileSystemSettings, 'allow_file_unarchiving', false) === false) {
    delete fileActions.unarchive
  }

  const rJSX_DraggableFolder = (folder: TsInterface_UnspecifiedObject): JSX.Element => {
    let folderJSX = <></>
    if (folder.file_system_type === 'hardcoded_folder') {
      folderJSX = (
        <Box className="tw-p-1">
          <Box sx={{ minWidth: '24px', display: 'inline-block' }}>
            <Icon
              icon="folder"
              sx={{ color: themeVariables.white, fontSize: '16px' }}
            />
          </Box>
          {folder.folder_name}
        </Box>
      )
    } else {
      folderJSX = (
        <DraggableFileOrFolder
          file={folder}
          // eslint-disable-next-line react/no-children-prop
          children={
            <Box>
              <Box sx={{ minWidth: '24px', display: 'inline-block' }}>
                <Icon
                  icon="folder"
                  type="regular"
                  sx={{ color: themeVariables.white, fontSize: '16px' }}
                />
              </Box>
              {folder.folder_name}
            </Box>
          }
        />
      )
    }
    return folderJSX
  }

  const rJSX_FileAndFolderTable = (): JSX.Element => {
    let tableJSX = <></>
    if (us_visibleFolders.length > 0 || us_visibleFiles.length > 0) {
      tableJSX = (
        <Box>
          <Card>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell>{rLIB('Name')}</TableCell>
                    <TableCell>{rLIB('Uploaded')}</TableCell>
                    <TableCell>{rLIB('Visible to Customer')}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  {us_visibleFolders.map((folder: TsInterface_UnspecifiedObject, folderIndex: number) => (
                    <TableRow
                      key={folderIndex}
                      className="tw-cursor-pointer"
                    >
                      <TableCell
                        sx={{ fontSize: '16px' }}
                        onDoubleClick={() => {
                          selectFolder(folder.key)
                        }}
                      >
                        <DroppableFolder
                          folder={folder}
                          moveFileOrFolder={moveFileOrFolder}
                          // eslint-disable-next-line react/no-children-prop
                          children={rJSX_DraggableFolder(folder)}
                        />
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: '16px' }}
                        onDoubleClick={() => {
                          selectFolder(folder.key)
                        }}
                      ></TableCell>
                      <TableCell sx={{ width: '40px' }}>
                        <TableCellManageProper
                          actions={folderActions}
                          rowData={folder}
                          tableAdditionalData={{}}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                  {us_visibleFiles.map((file: TsInterface_UnspecifiedObject, fileIndex: number) => (
                    <TableRow
                      key={fileIndex}
                      className="tw-cursor-pointer"
                    >
                      <TableCell
                        sx={{ fontSize: '16px' }}
                        onDoubleClick={() => {
                          viewFile(file.url as string)
                        }}
                      >
                        <DraggableFileOrFolder
                          file={file}
                          // eslint-disable-next-line react/no-children-prop
                          children={
                            <Box>
                              <Box sx={{ minWidth: '24px', display: 'inline-block' }}>{returnIconKeyForFileType(file.file_type)}</Box>
                              <Box
                                sx={{
                                  display: 'inline-block',
                                  opacity: file.status === 'archived' ? 0.3 : 1,
                                  textDecoration: file.status === 'archived' ? 'line-through' : 'none',
                                }}
                              >
                                {file.file_name}
                              </Box>
                            </Box>
                          }
                        />
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: '16px' }}
                        onDoubleClick={() => {
                          viewFile(file.url as string)
                        }}
                      >
                        <Box
                          sx={{
                            display: 'inline-block',
                            opacity: file.status === 'archived' ? 0.3 : 1,
                            textDecoration: file.status === 'archived' ? 'line-through' : 'none',
                          }}
                        >
                          {returnFormattedDate(file.timestamp_uploaded, 'MMM D, YYYY - HH:mm')}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Switch
                          checked={file.visible_to_customer}
                          onChange={() => toggleVisibleToCustomer(file.key as string, file.visible_to_customer as boolean)}
                          color="primary"
                        />
                      </TableCell>
                      <TableCell sx={{ width: '40px' }}>
                        <TableCellManageProper
                          actions={fileActions}
                          rowData={file}
                          tableAdditionalData={{}}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Box>
      )
    } else {
      tableJSX = (
        <Card className="tw-text-center tw-p-1">
          <Typography className="tw-opacity-50">{rLIB('No files or folders')}</Typography>
        </Card>
      )
    }
    return tableJSX
  }

  const rJSX_Component = (): JSX.Element => {
    let componentJSX = (
      <Box>
        <DndProvider backend={HTML5Backend}>
          <Box className="tw-mb-2">
            <TabButtons
              tabButtons={[
                { fullJSX: rJSX_CreateFolderButton(false), minJSX: rJSX_CreateFolderButton(true), sizeCutoff: 415 },
                { fullJSX: rJSX_UploadFileButton(false), minJSX: rJSX_UploadFileButton(true), sizeCutoff: 505 },
                { fullJSX: rJSX_CreateLinkButton(false), minJSX: rJSX_CreateLinkButton(true), sizeCutoff: 625 },
                { fullJSX: rJSX_ToggleArchivedFilesButton(false), minJSX: rJSX_ToggleArchivedFilesButton(true), sizeCutoff: 745 },
              ]}
            />
          </Box>
          <Box>{rJSX_FolderPath()}</Box>
          <Box>{rJSX_FileAndFolderTable()}</Box>
        </DndProvider>
      </Box>
    )
    return componentJSX
  }
  // Render
  return <>{rJSX_Component()}</>
}
