///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Badge, Box, Button, Tooltip } from '@mui/material/'
import { formSettings_Project } from 'app/models/projects/project_forms'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_ActiveProtoProjects_Query, DatabaseRef_UnassignedProjects_Query } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { DatabaseRef_PowerwallOrders_Query } from 'rfbp_aux/services/database_endpoints/webstore/orders'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { TabsUrl, TsInterface_TabContentUrlArray } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientUser,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
} from 'rfbp_core/services/context'
import { DatabaseGetCollection, DatabaseGetLiveCollection } from 'rfbp_core/services/database_management'
import { cloneObjectWithoutReference, downloadCSV, getProp, objectToArray, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { ProjectListAllProjectsTab } from './v2_list_tabs/tab_all_projects'
import { ProjectListMyProjectsTab } from './v2_list_tabs/tab_my_projects'
import { ProjectListPowerwallOrdersTab } from './v2_list_tabs/tab_powerwall'
import { ProjectListProjectSearchTab } from './v2_list_tabs/tab_project_search'
import { ProjectListUnassignedProjectsTab } from './v2_list_tabs/tab_unassigned_projects'
import { ProjectListUnlinkedProjectsTab } from './v2_list_tabs/tab_unlinked_projects'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminActiveProjectsListPage']['key']

// Form
const formInputs_ProtoProjectNew: TsInterface_FormInputs = {
  unique_identifier: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'unique_identifier',
    label: rLIB('Job Code'),
    required: true,
  },
  associated_customer_name: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'associated_customer_name',
    label: rLIB('Customer Name'),
    required: true,
  },
  associated_customer_email: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'associated_customer_email',
    label: rLIB('Customer Email'),
    required: true,
  },
  associated_customer_phone: {
    data_type: 'string',
    // input_type: "phone_number_usa",
    input_type: 'text_basic',
    key: 'associated_customer_phone',
    label: rLIB('Customer Phone'),
    required: true,
  },
  location_address: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'location_address',
    label: rLIB('Street Address'),
    required: true,
  },
  location_city: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'location_city',
    label: rLIB('City'),
    required: true,
  },
  location_state: {
    data_type: 'string',
    input_type: 'multiple_choice_select_state_usa',
    key: 'location_state',
    label: rLIB('State'),
    required: true,
  },
  location_zip: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'location_zip',
    label: rLIB('Zip'),
    required: true,
  },
  project_notes: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'project_notes',
    label: rLIB('Notes'),
    required: true,
  },
}

let projectStatusOptions: TsInterface_UnspecifiedObject = {
  active: { key: 'active', value: rLIB('Active'), disabled: false },
  on_hold: { key: 'on_hold', value: rLIB('On Hold'), disabled: false },
  cancelled: { key: 'cancelled', value: rLIB('Cancelled'), disabled: false },
  completed: { key: 'completed', value: rLIB('Completed'), disabled: false },
}

const formInputs_TableStatusFilter: TsInterface_FormInputs = {
  status: {
    key: 'status',
    label: rLIB('Project Status'),
    input_type: 'multiple_choice_radio',
    required: true,
    data_type: 'string',
    options: objectToArray(projectStatusOptions),
  },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Page Specific Permissions
///////////////////////////////

const defaultPagePermissions: TsInterface_UnspecifiedObject = {
  new_project_button: false,
  new_proto_button: false,
  scheduling_button: false,
  import_button: false,
  stats_button: false,
  my_projects: true,
  all_projects: true,
  unassigned_projects: false,
  unlinked_projects: false,
  project_phases: false,
  project_search: true,
  powerwall_orders: false,
}

const pageRolePermissions: TsInterface_UnspecifiedObject = {
  api_user: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: false,
    import_button: false,
    stats_button: false,
    my_projects: false,
    all_projects: false,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: false,
    project_search: false,
    powerwall_orders: false,
  },
  accounts_payable: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: true,
    import_button: false,
    stats_button: false,
    my_projects: false,
    all_projects: true,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: true,
    project_search: true,
    powerwall_orders: false,
  },
  accounts_receivable: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: true,
    import_button: false,
    stats_button: false,
    my_projects: false,
    all_projects: true,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: true,
    project_search: true,
    powerwall_orders: false,
  },
  admin: {
    new_project_button: false,
    new_proto_button: true,
    scheduling_button: true,
    import_button: true,
    stats_button: true,
    my_projects: true,
    all_projects: true,
    unassigned_projects: true,
    unlinked_projects: true,
    project_phases: true,
    project_search: true,
    powerwall_orders: true,
  },
  construction_lead: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: true,
    import_button: false,
    stats_button: false,
    my_projects: false,
    all_projects: true,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: true,
    project_search: true,
    powerwall_orders: false,
  },
  css_manager: {
    new_project_button: false,
    new_proto_button: true,
    scheduling_button: true,
    import_button: true,
    stats_button: true,
    my_projects: true,
    all_projects: true,
    unassigned_projects: true,
    unlinked_projects: true,
    project_phases: true,
    project_search: true,
    powerwall_orders: true,
  },
  css_rep: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: true,
    import_button: false,
    stats_button: true,
    my_projects: true,
    all_projects: true,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: true,
    project_search: true,
    powerwall_orders: false,
  },
  design: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: false,
    import_button: false,
    stats_button: false,
    my_projects: false,
    all_projects: true,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: false,
    project_search: true,
    powerwall_orders: false,
  },
  electrical_foreman: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: false,
    import_button: false,
    stats_button: false,
    my_projects: false,
    all_projects: true,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: false,
    project_search: true,
    powerwall_orders: false,
  },
  field_service: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: false,
    import_button: false,
    stats_button: false,
    my_projects: false,
    all_projects: true,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: false,
    project_search: true,
    powerwall_orders: false,
  },
  general_manager: {
    new_project_button: false,
    new_proto_button: true,
    scheduling_button: true,
    import_button: false,
    stats_button: true,
    my_projects: false,
    all_projects: true,
    unassigned_projects: true,
    unlinked_projects: true,
    project_phases: true,
    project_search: true,
    powerwall_orders: true,
  },
  home_upgrades_electrician: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: false,
    import_button: false,
    stats_button: false,
    my_projects: false,
    all_projects: true,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: false,
    project_search: true,
    powerwall_orders: false,
  },
  home_upgrades_electrician_trainee: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: false,
    import_button: false,
    stats_button: false,
    my_projects: false,
    all_projects: true,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: false,
    project_search: true,
    powerwall_orders: false,
  },
  inspection: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: false,
    import_button: false,
    stats_button: false,
    my_projects: false,
    all_projects: true,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: false,
    project_search: true,
    powerwall_orders: false,
  },
  install_coordinator: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: true,
    import_button: false,
    stats_button: false,
    my_projects: false,
    all_projects: true,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: true,
    project_search: true,
    powerwall_orders: false,
  },
  install_lead: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: false,
    import_button: false,
    stats_button: false,
    my_projects: false,
    all_projects: true,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: false,
    project_search: true,
    powerwall_orders: false,
  },
  installer: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: false,
    import_button: false,
    stats_button: false,
    my_projects: false,
    all_projects: true,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: false,
    project_search: true,
    powerwall_orders: false,
  },
  interconnection: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: true,
    import_button: false,
    stats_button: false,
    my_projects: false,
    all_projects: true,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: true,
    project_search: true,
    powerwall_orders: false,
  },
  permitting: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: true,
    import_button: false,
    stats_button: false,
    my_projects: false,
    all_projects: true,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: true,
    project_search: true,
    powerwall_orders: false,
  },
  repair: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: false,
    import_button: false,
    stats_button: false,
    my_projects: false,
    all_projects: true,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: false,
    project_search: true,
    powerwall_orders: false,
  },
  scheduling: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: true,
    import_button: false,
    stats_button: false,
    my_projects: false,
    all_projects: false,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: false,
    project_search: false,
    powerwall_orders: false,
  },
  site_auditor: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: false,
    import_button: false,
    stats_button: false,
    my_projects: false,
    all_projects: true,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: false,
    project_search: true,
    powerwall_orders: false,
  },
  warehouse: {
    new_project_button: false,
    new_proto_button: false,
    scheduling_button: false,
    import_button: false,
    stats_button: false,
    my_projects: false,
    all_projects: true,
    unassigned_projects: false,
    unlinked_projects: false,
    project_phases: false,
    project_search: true,
    powerwall_orders: false,
  },
}

const determinePageSpecificPermissions = (clientUser: TsInterface_UnspecifiedObject): TsInterface_UnspecifiedObject => {
  let pageSpecificPermissions: TsInterface_UnspecifiedObject = { ...defaultPagePermissions }
  if (clientUser != null && clientUser.user_role != null) {
    for (let loopPermissionKey in defaultPagePermissions) {
      if (
        pageRolePermissions != null &&
        pageRolePermissions[clientUser.user_role] != null &&
        pageRolePermissions[clientUser.user_role][loopPermissionKey] === true
      ) {
        pageSpecificPermissions[loopPermissionKey] = true
      }
    }
  }
  if (clientUser != null && clientUser.task_roles != null) {
    for (let loopTaskRole in clientUser.task_roles) {
      let loopRoleValue = clientUser.task_roles[loopTaskRole]
      if (loopRoleValue === true) {
        for (let loopPermissionKey in defaultPagePermissions) {
          if (pageRolePermissions != null && pageRolePermissions[loopTaskRole] != null && pageRolePermissions[loopTaskRole][loopPermissionKey] === true) {
            pageSpecificPermissions[loopPermissionKey] = true
          }
        }
      }
    }
  }
  return pageSpecificPermissions
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  const [us_pageSpecificUserRolePermission, us_setPageSpecificUserRolePermission] = useState<TsInterface_UnspecifiedObject>(defaultPagePermissions)
  const [us_powerwallTableViewType, us_setPowerwallTableViewType] = useState<string>('active')
  const [us_tableFilterStatus, us_setTableFilterStatus] = useState<string>('active')
  const [us_unassignedProjects, us_setUnassignedProjects] = useState<TsInterface_UnspecifiedObject>({})
  const [us_unlinkedProjects, us_setUnlinkedProjects] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)

  // Hooks - useEffect
  useEffect(() => {
    if (uc_RootData_ClientUser != null) {
      us_setPageSpecificUserRolePermission(determinePageSpecificPermissions(uc_RootData_ClientUser))
    }
  }, [uc_RootData_ClientUser])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setUnassignedProjects(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_UnassignedProjects_Query(res_GCK.clientKey, 10), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setUnlinkedProjects(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveProtoProjects_Query(res_GCK.clientKey, 10), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Functions

  const openNewProtoProjectDialog = () => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: formInputs_ProtoProjectNew,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: formSettings_Project,
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let projectUpdateObject = cloneObjectWithoutReference(formSubmittedData)
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  cloudFunctionManageRequest('manageTasks', {
                    function: 'createProtoProjectAndUnassignedSiteAudit',
                    // function: "createProtoProjectAndAssignedSiteAudit",
                    // selected_date: "2023-01-19",
                    // selected_time: "10am to 2pm",
                    client_key: res_GCK.clientKey,
                    unique_identifier: getProp(projectUpdateObject, 'unique_identifier', null),
                    associated_customer_name: getProp(projectUpdateObject, 'associated_customer_name', null),
                    associated_customer_email: getProp(projectUpdateObject, 'associated_customer_email', null),
                    associated_customer_phone: getProp(projectUpdateObject, 'associated_customer_phone', null),
                    location_address: getProp(projectUpdateObject, 'location_address', null),
                    location_city: getProp(projectUpdateObject, 'location_city', null),
                    location_state: getProp(projectUpdateObject, 'location_state', null),
                    location_zip: getProp(projectUpdateObject, 'location_zip', null),
                    project_notes: getProp(projectUpdateObject, 'project_notes', null),
                  })
                    .then((res_CFMTR) => {
                      resolve(res_CFMTR)
                    })
                    .catch((rej_CFMTR) => {
                      formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_CFMTR.error })
                    })
                })
                .catch((rej_GCK) => {
                  formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: rLIB('New Unlinked Project'),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const downloadPowerwallConversionStats = () => {
    let today = new Date()
    let firstDay = new Date(today.getFullYear(), today.getMonth(), 1)
    let lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0)
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {
            start_date: returnFormattedDate(firstDay, 'YYYY-MM-DDThh:mm'),
            end_date: returnFormattedDate(lastDay, 'YYYY-MM-DDThh:mm'),
          },
          formInputs: {
            start_date: {
              key: 'start_date',
              label: rLIB('Start Date'),
              input_type: 'timestamp_datetime',
              required: true,
              data_type: 'string',
            },
            end_date: {
              key: 'end_date',
              label: rLIB('End Date'),
              input_type: 'timestamp_datetime',
              required: true,
              data_type: 'string',
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: formSettings_Project,
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              downloadPowerwallConversionStatsProper(new Date(formSubmittedData.start_date), new Date(formSubmittedData.end_date))
                .then((res_DPCSP) => {
                  resolve(res_DPCSP)
                })
                .catch((rej_DPCSP) => {
                  reject(rej_DPCSP)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: rLIB('Download Powerwall Conversion Stats'),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const downloadPowerwallConversionStatsProper = (startDate: Date, endDate: Date) => {
    return new Promise((resolve, reject) => {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseGetCollection(DatabaseRef_PowerwallOrders_Query(res_GCK.clientKey, startDate, endDate))
            .then((res_DGC) => {
              let exportData: string[][] = [
                [
                  'ID',
                  'First Name',
                  'Last Name',
                  'Email',
                  'Phone',
                  'Number of Powerwalls',
                  'Status',
                  'Page 1 (Contact info) status',
                  'Page 2 (Home info) status',
                  'Page 3 (Backup loads)',
                  'Page 4 (Order)',
                  'Start Timstamp',
                  'Agreed Timstamp',
                  'Deposit Timstamp',
                ],
              ]
              for (let loopUserOrderKey in res_DGC.data) {
                let loopUserOrder = res_DGC.data[loopUserOrderKey]
                if (loopUserOrder != null) {
                  let row: string[] = []
                  if (loopUserOrder.key != null) {
                    row.push(loopUserOrder.key)
                  } else {
                    row.push('')
                  }
                  if (loopUserOrder.first_name != null) {
                    row.push(loopUserOrder.first_name)
                  } else {
                    row.push('')
                  }
                  if (loopUserOrder.last_name != null) {
                    row.push(loopUserOrder.last_name)
                  } else {
                    row.push('')
                  }
                  if (loopUserOrder.email != null) {
                    row.push(loopUserOrder.email)
                  } else {
                    row.push('')
                  }
                  if (loopUserOrder.phone != null) {
                    row.push(loopUserOrder.phone)
                  } else {
                    row.push('')
                  }
                  if (loopUserOrder.number_of_powerwalls_to_install != null) {
                    row.push(loopUserOrder.number_of_powerwalls_to_install)
                  } else {
                    row.push('')
                  }
                  if (loopUserOrder.status != null) {
                    row.push(loopUserOrder.status)
                  } else {
                    row.push('')
                  }
                  // Page Progress
                  if (loopUserOrder.step > 0) {
                    row.push('100%')
                  } else {
                    let totalContactItems = 7
                    let contactInfoItemsComplete = 0
                    if (loopUserOrder['email'] != null) {
                      contactInfoItemsComplete++
                    }
                    if (loopUserOrder['first_name'] != null) {
                      contactInfoItemsComplete++
                    }
                    if (loopUserOrder['last_name'] != null) {
                      contactInfoItemsComplete++
                    }
                    if (loopUserOrder['phone'] != null) {
                      contactInfoItemsComplete++
                    }
                    if (loopUserOrder['best_time_to_contact'] != null) {
                      contactInfoItemsComplete++
                    }
                    if (loopUserOrder['best_time_to_contact_other'] != null) {
                      contactInfoItemsComplete++
                    }
                    if (loopUserOrder['best_contact_method'] != null) {
                      contactInfoItemsComplete++
                    }
                    let percentComplete = Math.round((contactInfoItemsComplete / totalContactItems) * 100).toFixed(0)
                    row.push(percentComplete + '%')
                  }
                  if (loopUserOrder.step > 1) {
                    row.push('100%')
                  } else {
                    let totalHomeItems = 9
                    let homeInfoItemsComplete = 0
                    if (loopUserOrder['install_location'] != null) {
                      homeInfoItemsComplete++
                    }
                    if (loopUserOrder['utility_company'] != null) {
                      homeInfoItemsComplete++
                    }
                    if (loopUserOrder['home_square_footage'] != null) {
                      homeInfoItemsComplete++
                    }
                    if (loopUserOrder['home_year_built'] != null) {
                      homeInfoItemsComplete++
                    }
                    if (loopUserOrder['is_home_owner'] != null) {
                      homeInfoItemsComplete++
                    }
                    if (loopUserOrder['home_type'] != null) {
                      homeInfoItemsComplete++
                    }
                    if (loopUserOrder['include_batteries_in_order'] != null) {
                      homeInfoItemsComplete++
                    }
                    if (loopUserOrder['has_solar'] != null) {
                      homeInfoItemsComplete++
                    }
                    if (loopUserOrder['expanding_powerwall'] != null) {
                      homeInfoItemsComplete++
                    }
                    let percentComplete = Math.round((homeInfoItemsComplete / totalHomeItems) * 100).toFixed(0)
                    row.push(percentComplete + '%')
                  }
                  if (loopUserOrder.step > 2) {
                    row.push('100%')
                  } else {
                    let totalBackupItems = 2
                    let backupLoadsItemsComplete = 0
                    if (loopUserOrder['number_of_powerwalls_to_install'] != null) {
                      backupLoadsItemsComplete++
                    }
                    if (loopUserOrder['include_batteries_in_order'] != null) {
                      backupLoadsItemsComplete++
                    }
                    let percentComplete = Math.round((backupLoadsItemsComplete / totalBackupItems) * 100).toFixed(0)
                    row.push(percentComplete + '%')
                  }
                  if (loopUserOrder.timestamp_deposit_checkout_session_created != null) {
                    row.push('100%')
                  } else if (loopUserOrder.timestamp_agreed_to_terms != null) {
                    row.push('100%')
                  } else {
                    row.push('0%')
                  }
                  if (loopUserOrder.timestamp_created != null) {
                    row.push(returnFormattedDate(loopUserOrder.timestamp_created, 'D MMM YYYY h:mma'))
                  } else {
                    row.push('')
                  }
                  if (loopUserOrder.timestamp_agreed_to_terms != null) {
                    row.push(returnFormattedDate(loopUserOrder.timestamp_agreed_to_terms, 'D MMM YYYY h:mma'))
                  } else {
                    row.push('')
                  }
                  if (loopUserOrder.timestamp_deposit_placed != null) {
                    row.push(returnFormattedDate(loopUserOrder.timestamp_deposit_placed, 'D MMM YYYY h:mma'))
                  } else {
                    row.push('')
                  }
                  exportData.push(row)
                }
              }
              // Download
              let fileName =
                'Powerwall Conversion Stats (' + returnFormattedDate(startDate, 'D MMM YYYY') + ' to ' + returnFormattedDate(endDate, 'D MMM YYYY') + ').csv'
              downloadCSV(fileName, exportData)
                .then((res_DCSV) => {
                  resolve(res_DCSV)
                })
                .catch((rej_DCSV) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DCSV.error })
                  reject(rej_DCSV)
                })
            })
            .catch((rej_DGC) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DGC.error })
              reject(rej_DGC)
            })
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          reject(rej_GCK)
        })
    })
  }

  // JSX Generation
  const rJSX_UnassignedCountBadgeLabel = (count: number): JSX.Element => {
    let labelJSX = <>{count}</>
    if (count >= 10) {
      labelJSX = <>10+</>
    }
    return labelJSX
  }

  const rJSX_UnassignedProjectsTabHeader = (): JSX.Element => {
    let labelJSX = <></>
    if (objectToArray(us_unassignedProjects).length > 0) {
      labelJSX = (
        <Box>
          <Badge
            badgeContent={rJSX_UnassignedCountBadgeLabel(objectToArray(us_unassignedProjects).length)}
            color="error"
          >
            {rLIB('Unassigned Projects')}
          </Badge>
        </Box>
      )
    } else {
      labelJSX = <Box>{rLIB('Unassigned Projects')}</Box>
    }
    return labelJSX
  }

  const rJSX_UnlinkedProjectsTabHeader = (): JSX.Element => {
    let labelJSX = <></>
    if (objectToArray(us_unlinkedProjects).length > 0) {
      labelJSX = (
        <Box>
          <Badge
            badgeContent={rJSX_UnassignedCountBadgeLabel(objectToArray(us_unlinkedProjects).length)}
            color="error"
          >
            {rLIB('Unlinked Projects')}
          </Badge>
        </Box>
      )
    } else {
      labelJSX = <Box>{rLIB('Unlinked Projects')}</Box>
    }
    return labelJSX
  }

  const rJSX_NewProtoProjectButton = (shrinkButton: boolean): JSX.Element => {
    let buttonJSX = <></>
    // if( us_pageSpecificUserRolePermission != null && us_pageSpecificUserRolePermission.new_proto_button === true ){
    if (shrinkButton === true) {
      buttonJSX = (
        <Tooltip
          title={rLIB('New Unlinked Project')}
          placement="top"
        >
          <Button
            variant="contained"
            color="success"
            startIcon={
              <Icon
                icon="circle-plus"
                type="solid"
              />
            }
            onClick={() => {
              openNewProtoProjectDialog()
            }}
            className="bp_icon_only_button"
            disabled={us_pageSpecificUserRolePermission == null || us_pageSpecificUserRolePermission.new_proto_button !== true}
          ></Button>
        </Tooltip>
      )
    } else {
      buttonJSX = (
        <Button
          variant="contained"
          color="success"
          startIcon={
            <Icon
              icon="circle-plus"
              type="solid"
            />
          }
          onClick={() => {
            openNewProtoProjectDialog()
          }}
          disabled={us_pageSpecificUserRolePermission == null || us_pageSpecificUserRolePermission.new_proto_button !== true}
        >
          {rLIB('New Unlinked Project')}
        </Button>
      )
    }
    // }
    return buttonJSX
  }

  const rJSX_TableStatusFilterButton = (shrinkButton: boolean): JSX.Element => {
    let buttonJSX = <></>
    if (shrinkButton === true) {
      buttonJSX = (
        <Tooltip
          title={
            <>
              {getProp(projectStatusOptions[us_tableFilterStatus], 'value', '')} {rLIB('Projects')}
            </>
          }
          placement="top"
        >
          <Button
            variant="contained"
            color="secondary"
            className="bp_icon_only_button"
            startIcon={
              <Icon
                icon="filters"
                type="solid"
              />
            }
            onClick={() => {
              uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {},
                    formData: { status: us_tableFilterStatus },
                    formInputs: formInputs_TableStatusFilter,
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {},
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        us_setTableFilterStatus(formSubmittedData.status)
                        resolve({ success: true })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: rLIB('Table Status Filter'),
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="filters"
                      />
                    ),
                  },
                },
              })
            }}
          ></Button>
        </Tooltip>
      )
    } else {
      buttonJSX = (
        <Button
          variant="contained"
          color="secondary"
          startIcon={
            <Icon
              icon="filters"
              type="solid"
            />
          }
          onClick={() => {
            uc_setUserInterface_FormDialogDisplay({
              display: true,
              form: {
                form: {
                  formAdditionalData: {},
                  formData: { status: us_tableFilterStatus },
                  formInputs: formInputs_TableStatusFilter,
                  formOnChange: (
                    formAdditionalData: TsInterface_FormAdditionalData,
                    formData: TsInterface_FormData,
                    formInputs: TsInterface_FormInputs,
                    formSettings: TsInterface_FormSettings,
                  ) => {},
                  formSettings: {},
                  formSubmission: (
                    formSubmittedData: TsInterface_FormSubmittedData,
                    formAdditionalData: TsInterface_FormAdditionalData,
                    formHooks: TsInterface_FormHooksObject,
                  ) => {
                    return new Promise((resolve, reject) => {
                      us_setTableFilterStatus(formSubmittedData.status)
                      resolve({ success: true })
                    })
                  },
                },
                dialog: {
                  formDialogHeaderColor: 'success',
                  formDialogHeaderText: rLIB('Table Status Filter'),
                  formDialogIcon: (
                    <Icon
                      type="solid"
                      icon="filters"
                    />
                  ),
                },
              },
            })
          }}
        >
          {getProp(projectStatusOptions[us_tableFilterStatus], 'value', '')} {rLIB('Projects')}
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_ArchivedPowerwallOrdersButton = () => {
    let buttonJSX = <></>
    if (us_powerwallTableViewType === 'archived') {
      buttonJSX = (
        <Button
          variant="contained"
          color="secondary"
          className="tw-mr-2"
          startIcon={
            <Icon
              icon="filter"
              type="solid"
            />
          }
          onClick={() => {
            us_setPowerwallTableViewType('active')
          }}
        >
          {rLIB('View Active Orders')}
        </Button>
      )
    } else {
      buttonJSX = (
        <Button
          variant="contained"
          color="secondary"
          className="tw-mr-2"
          startIcon={
            <Icon
              icon="filter"
              type="solid"
            />
          }
          onClick={() => {
            us_setPowerwallTableViewType('archived')
          }}
        >
          {rLIB('View Archived Orders')}
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_DownloadPowerwallConversionStatsButton = () => {
    let buttonJSX = (
      <Button
        variant="contained"
        color="info"
        startIcon={
          <Icon
            icon="cloud-arrow-down"
            type="solid"
          />
        }
        onClick={() => {
          downloadPowerwallConversionStats()
        }}
      >
        {rLIB('Download Conversion Stats')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageTabs: TsInterface_TabContentUrlArray = []
    if (us_pageSpecificUserRolePermission != null && us_pageSpecificUserRolePermission.my_projects === true) {
      pageTabs.push({
        tabUrlKey: 'My_Projects',
        tabHeader: (
          <>
            {rLIB('My')} {getProp(projectStatusOptions[us_tableFilterStatus], 'value', '')} {rLIB('Projects')}
          </>
        ),
        tabContent: <ProjectListMyProjectsTab us_tableFilterStatus={us_tableFilterStatus} />,
        tabButtons: [{ fullJSX: rJSX_TableStatusFilterButton(false), minJSX: rJSX_TableStatusFilterButton(true), sizeCutoff: 0 }],
      })
    }
    if (us_pageSpecificUserRolePermission != null && us_pageSpecificUserRolePermission.all_projects === true) {
      pageTabs.push({
        tabUrlKey: 'All_Projects',
        tabHeader: (
          <>
            {rLIB('All')} {getProp(projectStatusOptions[us_tableFilterStatus], 'value', '')} {rLIB('Projects')}
          </>
        ),
        tabContent: <ProjectListAllProjectsTab us_tableFilterStatus={us_tableFilterStatus} />,
        tabButtons: [{ fullJSX: rJSX_TableStatusFilterButton(false), minJSX: rJSX_TableStatusFilterButton(true), sizeCutoff: 0 }],
      })
    }
    if (us_pageSpecificUserRolePermission != null && us_pageSpecificUserRolePermission.unassigned_projects === true) {
      pageTabs.push({
        tabUrlKey: 'Unassigned_Projects',
        tabHeader: rJSX_UnassignedProjectsTabHeader(),
        tabContent: <ProjectListUnassignedProjectsTab />,
        tabButtons: [{ fullJSX: rJSX_NewProtoProjectButton(false), minJSX: rJSX_NewProtoProjectButton(true), sizeCutoff: 0 }],
      })
    }
    if (us_pageSpecificUserRolePermission != null && us_pageSpecificUserRolePermission.unlinked_projects === true) {
      pageTabs.push({
        tabUrlKey: 'Unlinked_Projects',
        tabHeader: rJSX_UnlinkedProjectsTabHeader(),
        tabContent: <ProjectListUnlinkedProjectsTab />,
        tabButtons: [{ fullJSX: rJSX_NewProtoProjectButton(false), minJSX: rJSX_NewProtoProjectButton(true), sizeCutoff: 730 }],
      })
    }
    // if (us_pageSpecificUserRolePermission != null && us_pageSpecificUserRolePermission.project_phases === true) {
    //   pageTabs.push({
    //     tabUrlKey: 'Project_Phases',
    //     tabHeader: rLIB('Project Phases'),
    //     tabContent: <PacingProjectPhasesTab />,
    //     tabButtons: [],
    //   })
    // }
    if (us_pageSpecificUserRolePermission != null && us_pageSpecificUserRolePermission.project_search === true) {
      pageTabs.push({
        tabUrlKey: 'Project_Search',
        tabHeader: rLIB('Project Search'),
        tabContent: <ProjectListProjectSearchTab />,
        tabButtons: [],
      })
    }
    if (us_pageSpecificUserRolePermission != null && us_pageSpecificUserRolePermission.powerwall_orders === true) {
      pageTabs.push({
        tabUrlKey: 'Powerwall_Orders',
        tabHeader: rLIB('Powerwall Orders'),
        tabContent: <ProjectListPowerwallOrdersTab us_powerwallTableViewType={us_powerwallTableViewType} />,
        tabButtons: [
          { fullJSX: rJSX_ArchivedPowerwallOrdersButton(), minJSX: rJSX_ArchivedPowerwallOrdersButton(), sizeCutoff: 0 },
          { fullJSX: rJSX_DownloadPowerwallConversionStatsButton(), minJSX: rJSX_DownloadPowerwallConversionStatsButton(), sizeCutoff: 0 },
        ],
      })
    }
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Active Projects')}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={pageTabs}
              tabsSettings={{
                tabQueryParam: 'tab',
                baseUrl: ApplicationPages['AdminActiveProjectsListPage'].url(),
                overridePageTitle: true,
                basePageTitle: rLIB('Active Projects', false) as string,
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
