///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card } from '@mui/material/'
import { createUser } from 'app/models/users/user_functions'
import { useContext, useEffect } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_SalesOpportunities_v2_Collection } from 'rfbp_aux/services/database_endpoints/sales/opportunities_v2'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TableCellManage,
  TableCellTimestamp,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseEndpointQueryObject,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { TableDatabase } from 'rfbp_core/components/table/table_database'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import { Context_RootData_ClientKey, Context_UserInterface_FormDialog } from 'rfbp_core/services/context'
import {
  DatabaseAddDocument,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { generateCustomerEmailTemplateObject, generateHtmlForEmailFromTemplateObject } from 'rfbp_core/services/emails'
import { generateRandomString, getProp } from 'rfbp_core/services/helper_functions'
import { directAppNavigation, onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['SalesOpportunitiesIndexPage']['key']

const formInputs_NewOpportunity: TsInterface_FormInputs = {
  associated_customer_name: {
    key: 'associated_customer_name',
    label: rLIB('Customer Name'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
  associated_customer_phone: {
    key: 'associated_customer_phone',
    label: rLIB('Customer Phone Number'),
    input_type: 'phone_number_usa',
    required: true,
    data_type: 'string',
    background_color: '#4A4D53',
  },
  associated_customer_email: {
    key: 'associated_customer_email',
    label: rLIB('Customer Email Address'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
  product_type: {
    key: 'product_type',
    label: rLIB('Product Type'),
    input_type: 'multiple_choice_select',
    options: [
      { key: 'solar', value: rLIB('Solar') },
      { key: 'lunar_battery', value: rLIB('Lunar Battery') },
    ],
    required: true,
    data_type: 'string',
  },
}

const tableColumns_UnassignedOpportunities: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: rLIB('View'),
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          directAppNavigation(tableHooks.un_routerNavigation, ApplicationPages.SalesOpportunityPage.url(rowData.key as string))
        }
      },
    },
    view_unauthenticated_customer_page: {
      icon: (
        <Icon
          type="solid"
          icon="mobile-screen-button"
        />
      ),
      label: <>{rLIB('View Customer Page')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          if (rowData.product_type === 'solar') {
            let url = ApplicationPages.UnauthenticatedSalesOpportunityPage.url(rowData.key as string) + '?tracking=false'
            window.open(url, '_blank')
          } else if (rowData.product_type === 'lunar_battery') {
            let url = ApplicationPages.UnauthenticatedLunarSalesOpportunityPage_v2.url(rowData.key as string) + '?tracking=false'
            window.open(url, '_blank')
          } else {
            let url = ApplicationPages.UnauthenticatedSalesOpportunityPage.url(rowData.key as string) + '?tracking=false'
            window.open(url, '_blank')
          }
        }
      },
    },
    create_customer_account: {
      icon: (
        <Icon
          type="solid"
          icon="file-user"
        />
      ),
      label: <>{rLIB('Create Customer Account')}</>,
      onClick(rowData, tableAdditionalData, tableHooks) {
        //create random password and account
        let randomPassword = generateRandomString(16, null)
        let userData: any = []
        userData['name'] = rowData.associated_customer_name
        userData['email'] = rowData.associated_customer_email
        userData['phone'] = rowData.associated_customer_phone
        userData['password'] = randomPassword
        userData['user_role'] = 'customer'
        userData['associated_organization_type'] = 'external'
        userData['associated_opportunity_key'] = rowData.key

        createUser(
          formInputs_NewOpportunity,
          userData,
          tableHooks.uc_RootData_ClientPermissions,
          tableHooks.uc_RootData_ClientKey,
          tableHooks.uc_setRootData_ClientKey,
          tableHooks.uc_RootData_ClientUser,
        ).then((res: any) => {
          //send email with password
          const templateObject = generateCustomerEmailTemplateObject(rowData.associated_customer_name as string, false, randomPassword)
          const htmlContent = generateHtmlForEmailFromTemplateObject(templateObject, {})
          return cloudFunctionManageRequest('manageEmails', {
            function: 'sendSendgridHtmlEmail',
            to: [rowData.associated_customer_email],
            subject: 'Welcome to ETW Energy!',
            html: htmlContent,
            cc: [],
            bcc: [],
          })
        })
      },
    },
  }),
  associated_customer_name: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        return (
          <Box
            className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
            sx={{ 'background': themeVariables.background_default, '&:hover': { background: themeVariables.background_json } }}
            onClick={(event) => {
              if (rowData.key != null) {
                onClickAppNavigation(event, tableHooks.un_routerNavigation, ApplicationPages.SalesOpportunityPage.url(rowData.key as string))
              }
            }}
          >
            {rowData.associated_customer_name}
          </Box>
        )
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Customer Name')
      },
      header_sort_by: 'associated_customer_name',
    },
  },
  timestamp_created: TableCellTimestamp('timestamp_created', rLIB('Timestamp Created'), 'timestamp_created', 'YYYY-MM-DD HH:mm', true),
  associated_customer_phone: TableCellBasic('associated_customer_phone', rLIB('Customer Phone Number'), 'associated_customer_phone'),
  associated_customer_email: TableCellBasic('associated_customer_email', rLIB('Customer Email Address'), 'associated_customer_email'),
}

const tableSettings_UnassignedOpportunities: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'desc',
  sort_property: 'timestamp_created',
  use_live_data: true,
}
///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  // const [ us_items, us_setItems ] = 		useState<TsInterface_UnspecifiedObject>({})
  // const ur_forceRerender = 				useReducer(() => ({}), {})[1] as () => void
  // const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = 		useContext( Context_RootData_ClientKey )
  // { sort-end } - hooks
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Sales Opportunities', false) as string
  }, [])

  // Functions
  const tableDatabaseEndpoint_UnassignedOpportunities = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_created', desc: true }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(
      DatabaseRef_SalesOpportunities_v2_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const createNewOpportunity = () => {
    function isValidEmail(email: string) {
      // Regular expression pattern for validating email address
      let emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      // Test the email against the pattern
      return emailPattern.test(email)
    }

    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: formInputs_NewOpportunity,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              if (!isValidEmail(formSubmittedData.associated_customer_email)) {
                formHooks.uc_setUserInterface_AlertDialogDisplay({ display: true, error: rLIB('Invalid email address') })
                reject(new Error('Invalid email address'))
                return
              }
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let updateObject = formSubmittedData
                  updateObject['timestamp_created'] = new Date()
                  updateObject['status'] = 'unassigned'
                  DatabaseAddDocument(DatabaseRef_SalesOpportunities_v2_Collection(res_GCK.clientKey), updateObject, true)
                    .then((res_DAD) => {
                      resolve(res_DAD)
                    })
                    .catch((rej_DAD) => {
                      reject(rej_DAD)
                    })
                })
                .catch((rej_GCK) => {
                  formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: rLIB('Create New Opportunity'),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  // JSX Generation

  const rJSX_UnassignedOpportunities = (): JSX.Element => {
    let tableJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Card className="">
          <TableDatabase
            tableAdditionalData={{}}
            tableColumns={tableColumns_UnassignedOpportunities}
            tableDatabaseEndpoint={tableDatabaseEndpoint_UnassignedOpportunities}
            tableSettings={tableSettings_UnassignedOpportunities}
          />
        </Card>
      )
    }
    return tableJSX
  }

  const rJSX_NewOpportunityButton = (): JSX.Element => {
    return (
      <Button
        variant="contained"
        color="success"
        startIcon={<Icon icon="circle-plus" />}
        onClick={createNewOpportunity}
      >
        {rLIB('New Opportunity', false)}
      </Button>
    )
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Sales Opportunities')}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={[
                {
                  tabUrlKey: 'opportunities',
                  tabHeader: rLIB('My Opportunities'),
                  tabContent: rJSX_UnassignedOpportunities(),
                  tabButtons: [{ fullJSX: rJSX_NewOpportunityButton(), minJSX: rJSX_NewOpportunityButton(), sizeCutoff: 0 }],
                },
              ]}
              tabsSettings={{
                baseUrl: ApplicationPages.SalesOpportunitiesIndexPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Sales Opportunities', false) as string,
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
