///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Return folders for documents page in project view page

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import Masonry from '@mui/lab/Masonry'
import { Card, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import {
  DatabaseRef_ProjectDesignPreferences_Document,
  DatabaseRef_ProtoProjectDesignPreferences_Document,
} from 'rfbp_aux/services/database_endpoints/operations/projects'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form/form_types'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

const editProjectDesignPreferences = (
  projectType: string,
  projectKey: string,
  uc_setUserInterface_FormDialogDisplay: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  projectDesignPreferences: TsInterface_UnspecifiedObject,
  header: JSX.Element,
  propsArray: string[],
): void => {
  let formInputs: TsInterface_FormInputs = {}
  let formData: TsInterface_UnspecifiedObject = {}
  // Generate Form Inputs and Form Data
  for (let loopIndex in propsArray) {
    let loopProp = propsArray[loopIndex]
    if (projectDesignPreferences != null && projectDesignPreferences[loopProp] != null && projectDesignPreferences[loopProp]['name'] != null) {
      formInputs[loopProp] = {
        data_type: 'string',
        input_type: 'text_basic',
        key: loopProp,
        label: projectDesignPreferences[loopProp]['name'],
        required: false,
      }
    }
    if (projectDesignPreferences != null && projectDesignPreferences[loopProp] != null && projectDesignPreferences[loopProp]['value'] != null) {
      formData[loopProp] = projectDesignPreferences[loopProp]['value']
    }
  }
  uc_setUserInterface_FormDialogDisplay({
    display: true,
    form: {
      form: {
        formAdditionalData: {},
        formData: formData,
        formInputs: formInputs,
        formOnChange: (
          formAdditionalData: TsInterface_FormAdditionalData,
          formData: TsInterface_FormData,
          formInputs: TsInterface_FormInputs,
          formSettings: TsInterface_FormSettings,
        ) => {},
        formSettings: {},
        formSubmission: (
          formSubmittedData: TsInterface_FormSubmittedData,
          formAdditionalData: TsInterface_FormAdditionalData,
          formHooks: TsInterface_FormHooksObject,
        ) => {
          return new Promise((resolve, reject) => {
            let updateObject: TsInterface_UnspecifiedObject = {}
            for (let loopProp in formSubmittedData) {
              updateObject[loopProp] = {
                value: formSubmittedData[loopProp],
              }
            }
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                if (projectType === 'project') {
                  DatabaseSetMergeDocument(DatabaseRef_ProjectDesignPreferences_Document(res_GCK.clientKey, projectKey), updateObject)
                    .then((res_DSMD) => {
                      resolve(res_DSMD)
                    })
                    .catch((rej_DSMD) => {
                      reject(rej_DSMD)
                    })
                } else if (projectType === 'proto') {
                  DatabaseSetMergeDocument(DatabaseRef_ProtoProjectDesignPreferences_Document(res_GCK.clientKey, projectKey), updateObject)
                    .then((res_DSMD) => {
                      resolve(res_DSMD)
                    })
                    .catch((rej_DSMD) => {
                      reject(rej_DSMD)
                    })
                } else {
                  reject({ success: false })
                }
              })
              .catch((rej_GCK) => {
                console.error(rej_GCK)
                reject(rej_GCK)
              })
          })
        },
      },
      dialog: {
        formDialogHeaderColor: 'success',
        formDialogHeaderText: <>{header}</>,
        formDialogIcon: (
          <Icon
            type="solid"
            icon="pen-to-square"
          />
        ),
      },
    },
  })
}

const returnProjectDesignPreferenceDataItem = (projectDesignPreferences: TsInterface_UnspecifiedObject, propKey: string): JSX.Element => {
  let dataItemJSX = <></>
  let propName: string | null | JSX.Element = null
  let propValue: string | null | JSX.Element = null
  if (propKey != null && projectDesignPreferences != null && projectDesignPreferences[propKey] != null && projectDesignPreferences[propKey]['name'] != null) {
    propName = projectDesignPreferences[propKey]['name']
  }
  if (propKey != null && projectDesignPreferences != null && projectDesignPreferences[propKey] != null && projectDesignPreferences[propKey]['value'] != null) {
    propValue = <Box className="tw-inline-block tw-ml-1 tw-opacity-60">{projectDesignPreferences[propKey]['value']}</Box>
  } else {
    propValue = <Box className="tw-inline-block tw-ml-1 tw-italic tw-opacity-30">{rLIB('Missing')}</Box>
  }
  if (propName != null) {
    dataItemJSX = (
      <Typography variant="subtitle1">
        {propName}: {propValue}
      </Typography>
    )
  }
  return dataItemJSX
}

const returnProjectDesignPreferencesItemList = (
  projectType: string,
  projectKey: string,
  projectDesignPreferences: TsInterface_UnspecifiedObject,
  uc_setUserInterface_FormDialogDisplay: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  header: JSX.Element,
  propsArray: string[],
): JSX.Element => {
  let itemListJSX = (
    <Box>
      <Typography
        variant="h6"
        className="tw-inline-block tw-opacity-50"
      >
        {header}
        <Box
          className="tw-inline-block tw-ml-2 tw-cursor-pointer"
          onClick={() => {
            editProjectDesignPreferences(
              projectType,
              projectKey,
              uc_setUserInterface_FormDialogDisplay,
              uc_RootData_ClientKey,
              uc_setRootData_ClientKey,
              projectDesignPreferences,
              header,
              propsArray,
            )
          }}
        >
          <Icon icon="pen-to-square"></Icon>
        </Box>
      </Typography>
      <Card className="tw-p-2">
        {propsArray.map((prop, index) => (
          <Box key={index}>{returnProjectDesignPreferenceDataItem(projectDesignPreferences, prop)}</Box>
        ))}
      </Card>
    </Box>
  )
  return itemListJSX
}

export const returnProjectDesignPreferences = (
  projectType: string,
  clientKey: string | null,
  projectKey: string,
  projectDesignPreferences: TsInterface_UnspecifiedObject,
  uc_setUserInterface_FormDialogDisplay: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
): JSX.Element => {
  let designPreferencesJSX: JSX.Element = <></>
  switch (clientKey) {
    // case "clientKey":
    default:
      designPreferencesJSX = (
        <Box>
          <Masonry
            columns={{ xs: 1, sm: 2, md: 3 }}
            spacing={2}
          >
            {returnProjectDesignPreferencesItemList(
              projectType,
              projectKey,
              projectDesignPreferences,
              uc_setUserInterface_FormDialogDisplay,
              uc_RootData_ClientKey,
              uc_setRootData_ClientKey,
              rLIB('Home Address') as JSX.Element,
              ['location_address', 'location_city', 'state', 'zip'],
            )}
            {returnProjectDesignPreferencesItemList(
              projectType,
              projectKey,
              projectDesignPreferences,
              uc_setUserInterface_FormDialogDisplay,
              uc_RootData_ClientKey,
              uc_setRootData_ClientKey,
              rLIB('Contract Preferences') as JSX.Element,
              [
                'associated_customer_name',
                'associated_customer_email',
                'associated_customer_phone',
                'secondary_customer_name',
                'secondary_customer_email',
                'secondary_customer_phone',
                'best_way_to_reach',
                'best_time_to_reach',
              ],
            )}
            {returnProjectDesignPreferencesItemList(
              projectType,
              projectKey,
              projectDesignPreferences,
              uc_setUserInterface_FormDialogDisplay,
              uc_RootData_ClientKey,
              uc_setRootData_ClientKey,
              rLIB('Home Characteristics') as JSX.Element,
              ['home_type', 'home_number_of_stories', 'roof_age', 'install_planes', 'roof_damage', 'roof_ceilings'],
            )}
            {returnProjectDesignPreferencesItemList(
              projectType,
              projectKey,
              projectDesignPreferences,
              uc_setUserInterface_FormDialogDisplay,
              uc_RootData_ClientKey,
              uc_setRootData_ClientKey,
              rLIB('Access') as JSX.Element,
              ['has_gate_code', 'gate_code', 'access_dogs', 'access_obstructions'],
            )}
            {returnProjectDesignPreferencesItemList(
              projectType,
              projectKey,
              projectDesignPreferences,
              uc_setUserInterface_FormDialogDisplay,
              uc_RootData_ClientKey,
              uc_setRootData_ClientKey,
              rLIB('Design and Install Notes') as JSX.Element,
              ['hoa_approval', 'site_audit_notes', 'design_preferences_first', 'design_preferences_second', 'design_notes'],
            )}
            {returnProjectDesignPreferencesItemList(
              projectType,
              projectKey,
              projectDesignPreferences,
              uc_setUserInterface_FormDialogDisplay,
              uc_RootData_ClientKey,
              uc_setRootData_ClientKey,
              rLIB('Battery Preferences') as JSX.Element,
              ['circuits_preference', 'battery_location', 'battery_location_secondary'],
            )}
            {returnProjectDesignPreferencesItemList(
              projectType,
              projectKey,
              projectDesignPreferences,
              uc_setUserInterface_FormDialogDisplay,
              uc_RootData_ClientKey,
              uc_setRootData_ClientKey,
              rLIB('Ev Charger') as JSX.Element,
              ['chargerDescription'],
            )}
            {returnProjectDesignPreferencesItemList(
              projectType,
              projectKey,
              projectDesignPreferences,
              uc_setUserInterface_FormDialogDisplay,
              uc_RootData_ClientKey,
              uc_setRootData_ClientKey,
              rLIB('Overall Goals') as JSX.Element,
              ['motivation_primary', 'motivation_secondary', 'shine'],
            )}
          </Masonry>
        </Box>
      )
  }
  return designPreferencesJSX
}

// TODO - edits (should be pretty easy hopefully... )
