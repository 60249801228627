///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Masonry } from '@mui/lab'
import { AppBar, Box, Button, Card, Chip, Dialog, DialogContent, Divider, Grid, IconButton, Skeleton, Stack, Toolbar, Tooltip, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import { rJSX_ActiveTasks, rJSX_ProjectProgressBar } from 'app/models/projects/project_table'
import { createScheduledReminder } from 'app/models/reminders/reminder_forms'
import dayjs from 'dayjs'
import ReactTimeAgo from 'react-time-ago'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatabaseRef_FinancePartner_Collection } from 'rfbp_aux/services/database_endpoints/directory/finance_partners'
import { DatabaseRef_ActiveRegions_Query } from 'rfbp_aux/services/database_endpoints/directory/regions'
import { DatabaseRef_SalesPartner_Collection } from 'rfbp_aux/services/database_endpoints/directory/sales_partners'
import {
  DatabaseRef_SunrunLeadPayment_Document,
  DatabaseRef_SunrunLead_Document,
  DatabaseRef_SunrunProposalAssetsByProspectId_Document,
  DatabaseRef_SunrunProposalByProspectId_Document,
  DatabaseRef_SunrunProposalDocsByProspectId_Document,
  DatabaseRef_SunrunServiceContact_Document,
} from 'rfbp_aux/services/database_endpoints/integrations/sunrun'
import { DatabaseRef_Project_Document } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { DatabaseRef_Reminder_Document } from 'rfbp_aux/services/database_endpoints/operations/reminders'
import { Json } from 'rfbp_core/components/code_display'
import { FeedbackNpsComments, TsInterface_FeedbackCommentsSettings, TsInterface_FeedbackGraphSettings } from 'rfbp_core/components/feedback'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { TabsBasic } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import { UserInterface_Default_CustomDialogDisplayState } from 'rfbp_core/services/context'
import { DatabaseGetCollection, DatabaseGetDocument, DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import {
  getProp,
  objectToArray,
  replaceNewlinesWithHtmlBreaks,
  returnDateFromUnknownDateFormat,
  returnFormattedDate,
  returnTimestampFromUnknownDateFormat,
  returnTimezoneAbbreviation,
} from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'
import { formInputs_EstimatedTimeline } from '../forms/estimated_timeline'
import { formInputs_ProjectCustomerDetails } from '../forms/project_customer_details'
import { formInputs_ProjectHomeDetails } from '../forms/project_home_details'
import { formInputs_ProjectSystemDetails } from '../forms/project_system_details'
import { formInputs_StickyNote, formSettings_StickyNote } from '../forms/sticky_notes'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Settings

const feedbackGraphSettings: TsInterface_FeedbackGraphSettings = {
  show_graph_score: true,
}

const feedbackCommentsSettings: TsInterface_FeedbackCommentsSettings = {
  sort_order: 'timestamp_created',
  mapping: {
    rating: 'feedback_rating',
    name: 'associated_user_name',
    text: 'feedback_notes',
    date: 'timestamp_created',
    chips: {
      feedback_topic: {
        key: 'feedback_topic',
        // color: "inherit"
      },
    },
  },
  display_buttons: {
    associated_project_key: false,
  },
}

// Defaults
const defaultDetailsMissingJSX: JSX.Element = <Box className="tw-italic tw-inline-block tw-opacity-30">{rLIB('Missing')}</Box>

const returnFormattedDetailTimestamps = (timestamp: any): JSX.Element => {
  let formattedTimestampJSX = <></>
  if (timestamp != null) {
    formattedTimestampJSX = (
      <>
        {returnFormattedDate(timestamp, 'D MMM YYYY - h:mm a')} {returnTimezoneAbbreviation(timestamp)}
      </>
    )
  } else {
    formattedTimestampJSX = defaultDetailsMissingJSX
  }
  return formattedTimestampJSX
}

const formInputs_SalesforceTimestamps: TsInterface_FormInputs = {
  // timestamp_fic_date
  // timestamp_construction_complete: {
  // 	key: "timestamp_construction_complete",
  // 	label: <>{ rLIB("Construction Complete") } / { rLIB("FIC Date") }</>,
  // 	input_type: "timestamp_datetime",
  // 	required: false,
  // 	data_type: "string",
  // },
  timestamp_fic_override_date: {
    key: 'timestamp_fic_override_date',
    label: rLIB('FIC Override Date'),
    input_type: 'timestamp_datetime',
    required: false,
    data_type: 'string',
  },
}

///////////////////////////////
// Functions
///////////////////////////////

const returnDetailsPropValue = (source: TsInterface_UnspecifiedObject, prop: string, missingFieldJSX: JSX.Element): string | JSX.Element => {
  let propValueDisplay = getProp(source, prop, missingFieldJSX)
  if (propValueDisplay === '' || propValueDisplay === ' ') {
    propValueDisplay = missingFieldJSX
  }
  return propValueDisplay
}

const returnBooleanDetailsPropValue = (source: TsInterface_UnspecifiedObject, prop: string, missingFieldJSX: JSX.Element): string | JSX.Element => {
  let propValueDisplay = getProp(source, prop, missingFieldJSX)
  if (propValueDisplay === '' || propValueDisplay === ' ') {
    propValueDisplay = missingFieldJSX
  }
  if (propValueDisplay === true) {
    propValueDisplay = 'Yes'
  }
  if (propValueDisplay === false) {
    propValueDisplay = 'No'
  }
  return propValueDisplay
}

const returnDetailsData = (
  listKey: string,
  rootProject: TsInterface_UnspecifiedObject,
  additionalSalesforceData: TsInterface_UnspecifiedObject,
): TsInterface_UnspecifiedObject => {
  // Detail Objects
  const customerDetailsObject: TsInterface_UnspecifiedObject = {
    associated_customer_risk_case_number: {
      key: 'associated_customer_risk_case_number',
      name: rLIB('ARC Case Number'),
      value: returnDetailsPropValue(rootProject, 'associated_customer_risk_case_number', defaultDetailsMissingJSX),
    },
    timestamp_customer_at_risk: {
      key: 'timestamp_customer_at_risk',
      name: rLIB('ARC Created Date'),
      value: returnFormattedDetailTimestamps(getProp(additionalSalesforceData, 'timestamp_customer_at_risk', null)),
    },
    associated_customer_risk_status_details: {
      key: 'associated_customer_risk_status_details',
      name: rLIB('ARC Status'),
      value: returnDetailsPropValue(additionalSalesforceData, 'associated_customer_risk_status_details', defaultDetailsMissingJSX),
    },
  }
  const homeDetailsObject: TsInterface_UnspecifiedObject = {
    associated_hoa_name: {
      key: 'associated_hoa_name',
      name: rLIB('HOA'),
      value: returnDetailsPropValue(rootProject, 'associated_hoa_name', defaultDetailsMissingJSX),
    },
    home_building_type: {
      key: 'home_building_type',
      name: rLIB('Type'),
      value: returnDetailsPropValue(additionalSalesforceData, 'home_building_type', defaultDetailsMissingJSX),
    },
    home_installation_type: {
      key: 'home_installation_type',
      name: rLIB('Installation Type'),
      value: returnDetailsPropValue(additionalSalesforceData, 'home_installation_type', defaultDetailsMissingJSX),
    },
    home_roof_age: {
      key: 'home_roof_age',
      name: rLIB('Roof Age'),
      value: returnDetailsPropValue(additionalSalesforceData, 'home_roof_age', defaultDetailsMissingJSX),
    },
    home_roof_type: {
      key: 'home_roof_type',
      name: rLIB('Roof Type'),
      value: returnDetailsPropValue(rootProject, 'home_roof_type', defaultDetailsMissingJSX),
      // value: returnDetailsPropValue( additionalSalesforceData, "home_roof_type", defaultDetailsMissingJSX )
    },
    home_shading_type: {
      key: 'home_shading_type',
      name: rLIB('Shading Type'),
      value: returnDetailsPropValue(additionalSalesforceData, 'home_shading_type', defaultDetailsMissingJSX),
    },
    home_square_footage: {
      key: 'home_square_footage',
      name: rLIB('Square Footage'),
      value: returnDetailsPropValue(additionalSalesforceData, 'home_square_footage', defaultDetailsMissingJSX),
    },
    home_year_built: {
      key: 'home_year_built',
      name: rLIB('Year Built'),
      value: returnDetailsPropValue(additionalSalesforceData, 'home_year_built', defaultDetailsMissingJSX),
    },
    home_number_of_stories: {
      key: 'home_number_of_stories',
      name: rLIB('Number of Stories'),
      value: returnDetailsPropValue(additionalSalesforceData, 'home_number_of_stories', defaultDetailsMissingJSX),
    },
  }
  const systemDetailsObject: TsInterface_UnspecifiedObject = {
    system_size_dc: {
      key: 'system_size_dc',
      name: rLIB('System Size'),
      value: returnDetailsPropValue(rootProject, 'system_size_dc', defaultDetailsMissingJSX),
    },
    system_estimated_annual_production: {
      key: 'system_estimated_annual_production',
      name: rLIB('Est Annual Production'),
      value: returnDetailsPropValue(rootProject, 'system_estimated_annual_production', defaultDetailsMissingJSX),
    },
    system_usage_offset: {
      key: 'system_usage_offset',
      name: rLIB('Usage Offset'),
      value: returnDetailsPropValue(rootProject, 'system_usage_offset', defaultDetailsMissingJSX),
    },
    system_meter_type: {
      key: 'system_meter_type',
      name: rLIB('Meter Type'),
      // value: returnDetailsPropValue( rootProject, "system_meter_type", defaultDetailsMissingJSX )
      value: returnDetailsPropValue(additionalSalesforceData, 'system_meter_type', defaultDetailsMissingJSX),
    },
    system_storage_total_kwh: {
      key: 'system_storage_total_kwh',
      name: rLIB('Total Storage (KWH)'),
      value: returnDetailsPropValue(rootProject, 'system_storage_total_kwh', defaultDetailsMissingJSX),
    },
    system_number_of_arrays: {
      key: 'system_number_of_arrays',
      name: rLIB('Number of Arrays'),
      value: returnDetailsPropValue(rootProject, 'system_number_of_arrays', defaultDetailsMissingJSX),
    },
    system_number_of_strings: {
      key: 'system_number_of_strings',
      name: rLIB('Number of Strings'),
      value: returnDetailsPropValue(rootProject, 'system_number_of_strings', defaultDetailsMissingJSX),
    },
    system_max_roof_pitch: {
      key: 'system_max_roof_pitch',
      name: rLIB('Max Roof Pitch'),
      value: returnDetailsPropValue(rootProject, 'system_max_roof_pitch', defaultDetailsMissingJSX),
    },
    system_has_attic_run: {
      key: 'system_has_attic_run',
      name: rLIB('Attic Run'),
      value: returnBooleanDetailsPropValue(rootProject, 'system_has_attic_run', defaultDetailsMissingJSX),
    },
    // system_failed_first_inspection: {
    // key: "system_failed_first_inspection",
    // name: s_FAILED_FIRST_INSPECTION,
    // value: returnBooleanDetailsPropValue( rootProject, "system_failed_first_inspection", defaultDetailsMissingJSX )
    // },
    // timestamp_fic_date: {
    // 	key: "timestamp_fic_date",
    // 	name: rLIB("FIC Date"),
    // 	value: returnFormattedDetailTimestamps( getProp( rootProject, "timestamp_fic_date", null ) )
    // },
  }
  const timestampDetailsObject: TsInterface_UnspecifiedObject = {
    timestamp_lead_created: {
      key: 'timestamp_lead_created',
      name: rLIB('Lead Created'),
      value: returnFormattedDetailTimestamps(getProp(additionalSalesforceData, 'timestamp_lead_created', null)),
    },
    timestamp_opportunity_created: {
      key: 'timestamp_opportunity_created',
      name: rLIB('Opportunity Created'),
      value: returnFormattedDetailTimestamps(getProp(additionalSalesforceData, 'timestamp_opportunity_created', null)),
    },
    timestamp_customer_signed: {
      key: 'timestamp_customer_signed',
      name: rLIB('Customer Signed'),
      value: returnFormattedDetailTimestamps(getProp(rootProject, 'timestamp_customer_signed', null)),
    },
    timestamp_sales_signoff: {
      key: 'timestamp_sales_signoff',
      name: rLIB('Sales Signoff'),
      value: returnFormattedDetailTimestamps(getProp(additionalSalesforceData, 'timestamp_sales_signoff', null)),
    },
    timestamp_site_audit_complete: {
      key: 'timestamp_site_audit_complete',
      name: rLIB('Site Audit Complete 1'),
      value: returnFormattedDetailTimestamps(getProp(additionalSalesforceData, 'timestamp_site_audit_complete', null)),
    },
    timestamp_site_audit_complete_opty: {
      key: 'timestamp_site_audit_complete_opty',
      name: rLIB('Site Audit Complete 2'),
      value: returnFormattedDetailTimestamps(getProp(additionalSalesforceData, 'timestamp_site_audit_complete_opty', null)),
    },
    timestamp_site_audit_complete_sc: {
      key: 'timestamp_site_audit_complete_sc',
      name: rLIB('Site Audit Complete 3'),
      value: returnFormattedDetailTimestamps(getProp(additionalSalesforceData, 'timestamp_site_audit_complete_sc', null)),
    },
    timestamp_customer_proposal_initiated: {
      key: 'timestamp_customer_proposal_initiated',
      name: rLIB('CAP Prepared'),
      value: returnFormattedDetailTimestamps(getProp(additionalSalesforceData, 'timestamp_customer_proposal_initiated', null)),
    },
    timestamp_customer_proposal_approved: {
      key: 'timestamp_customer_proposal_approved',
      name: rLIB('CAP Approved'),
      value: returnFormattedDetailTimestamps(getProp(rootProject, 'timestamp_customer_proposal_approved', null)),
    },
    timestamp_install_completed: {
      key: 'timestamp_install_completed',
      name: rLIB('Install Complete'),
      value: returnFormattedDetailTimestamps(getProp(rootProject, 'timestamp_install_completed', null)),
    },
    timestamp_inspection_complete: {
      key: 'timestamp_inspection_complete',
      name: rLIB('Inspection Complete'),
      value: returnFormattedDetailTimestamps(getProp(additionalSalesforceData, 'timestamp_inspection_complete', null)),
    },
    timestamp_permission_to_operate: {
      key: 'timestamp_permission_to_operate',
      name: rLIB('Permission to Operate'),
      value: returnFormattedDetailTimestamps(getProp(additionalSalesforceData, 'timestamp_permission_to_operate', null)),
    },
    timestamp_system_activation: {
      key: 'timestamp_system_activation',
      name: rLIB('System Activation'),
      value: returnFormattedDetailTimestamps(getProp(rootProject, 'timestamp_system_activation', null)),
    },
    timestamp_project_cancelled: {
      key: 'timestamp_project_cancelled',
      name: rLIB('Project Cancelled'),
      value: returnFormattedDetailTimestamps(getProp(rootProject, 'timestamp_project_cancelled', null)),
    },
    timestamp_verification_call: {
      key: 'timestamp_verification_call',
      name: rLIB('Verification Call'),
      value: returnFormattedDetailTimestamps(getProp(additionalSalesforceData, 'timestamp_verification_call', null)),
    },
    timestamp_welcome_call: {
      key: 'timestamp_welcome_call',
      name: rLIB('Welcome Call'),
      value: returnFormattedDetailTimestamps(getProp(additionalSalesforceData, 'timestamp_welcome_call', null)),
    },
    timestamp_project_handoff: {
      key: 'timestamp_project_handoff',
      name: rLIB('Project Handoff'),
      value: returnFormattedDetailTimestamps(getProp(additionalSalesforceData, 'timestamp_project_handoff', null)),
    },
    timestamp_cap_approved_lt: {
      key: 'timestamp_cap_approved_lt',
      name: rLIB('CAP Approved 2'),
      value: returnFormattedDetailTimestamps(getProp(additionalSalesforceData, 'timestamp_cap_approved_lt', null)),
    },
    timestamp_permit_submitted: {
      key: 'timestamp_permit_submitted',
      name: rLIB('Permit Submitted'),
      value: returnFormattedDetailTimestamps(getProp(additionalSalesforceData, 'timestamp_permit_submitted', null)),
    },
    timestamp_permit_approval: {
      key: 'timestamp_permit_approval',
      name: rLIB('Permit Approval'),
      value: returnFormattedDetailTimestamps(getProp(additionalSalesforceData, 'timestamp_permit_approval', null)),
    },
    timestamp_construction_start: {
      key: 'timestamp_construction_start',
      name: rLIB('Construction Start'),
      value: returnFormattedDetailTimestamps(getProp(additionalSalesforceData, 'timestamp_construction_start', null)),
    },
    timestamp_construction_complete: {
      key: 'timestamp_construction_complete',
      name: (
        <>
          {rLIB('Construction Complete')} / {rLIB('FIC Date')}
        </>
      ),
      value: returnFormattedDetailTimestamps(getProp(rootProject, 'timestamp_construction_complete', null)),
    },
    timestamp_fic_override_date: {
      key: 'timestamp_fic_override_date',
      name: rLIB('FIC Override Date'),
      value: returnFormattedDetailTimestamps(getProp(rootProject, 'timestamp_fic_override_date', null)),
    },
    timestamp_interconnection_submission: {
      key: 'timestamp_interconnection_submission',
      name: rLIB('Submit Interconnection'),
      value: returnFormattedDetailTimestamps(getProp(additionalSalesforceData, 'timestamp_interconnection_submission', null)),
    },
    timestamp_final_inspection_signoff: {
      key: 'timestamp_final_inspection_signoff',
      name: rLIB('Final Inspection Signoff'),
      value: returnFormattedDetailTimestamps(getProp(additionalSalesforceData, 'timestamp_final_inspection_signoff', null)),
    },
    timestamp_meter_registration: {
      key: 'timestamp_meter_registration',
      name: rLIB('Meter Registration'),
      value: returnFormattedDetailTimestamps(getProp(additionalSalesforceData, 'timestamp_meter_registration', null)),
    },
    timestamp_pto: {
      key: 'timestamp_pto',
      name: rLIB('PTO'),
      value: returnFormattedDetailTimestamps(getProp(rootProject, 'timestamp_pto', null)),
    },
    timestamp_ready_to_go: {
      key: 'timestamp_ready_to_go',
      name: rLIB('RTG'),
      value: returnFormattedDetailTimestamps(getProp(additionalSalesforceData, 'timestamp_ready_to_go', null)),
    },
    timestamp_electrical_signoff: {
      key: 'timestamp_electrical_signoff',
      name: rLIB('Electrical Signoff'),
      value: returnFormattedDetailTimestamps(getProp(additionalSalesforceData, 'timestamp_electrical_signoff', null)),
    },
    timestamp_salesforce_last_modified: {
      key: 'timestamp_salesforce_last_modified',
      name: rLIB('Last Modified'),
      value: returnFormattedDetailTimestamps(getProp(rootProject, 'timestamp_salesforce_last_modified', null)),
    },
  }
  const otherSalesforceDetailsObject: TsInterface_UnspecifiedObject = {
    status_service_contract_status: {
      key: 'status_service_contract_status',
      name: rLIB('Service Contract Status'),
      value: returnDetailsPropValue(rootProject, 'status_service_contract_status', defaultDetailsMissingJSX),
    },
    status_design_status: {
      key: 'status_design_status',
      name: rLIB('Site Design Status'),
      value: returnDetailsPropValue(additionalSalesforceData, 'status_design_status', defaultDetailsMissingJSX),
    },
    status_sales_opportunity_stage: {
      key: 'status_sales_opportunity_stage',
      name: rLIB('Opportunity Stage'),
      value: returnDetailsPropValue(rootProject, 'status_sales_opportunity_stage', defaultDetailsMissingJSX),
    },
    associated_sales_opportunity_status: {
      key: 'associated_sales_opportunity_status',
      name: rLIB('Opportunity Status'),
      value: returnDetailsPropValue(additionalSalesforceData, 'associated_sales_opportunity_status', defaultDetailsMissingJSX),
    },
    associated_sales_channel: {
      key: 'associated_sales_channel',
      name: rLIB('Sales Channel'),
      value: returnDetailsPropValue(rootProject, 'associated_sales_channel', defaultDetailsMissingJSX),
    },
    associated_install_partner_name: {
      key: 'associated_install_partner_name',
      name: rLIB('Install Partner Name'),
      value: returnDetailsPropValue(additionalSalesforceData, 'associated_install_partner_name', defaultDetailsMissingJSX),
    },
    associated_build_partner_name: {
      key: 'associated_build_partner_name',
      name: rLIB('Build Partner Name'),
      value: returnDetailsPropValue(rootProject, 'associated_build_partner_name', defaultDetailsMissingJSX),
    },
    associated_install_channel: {
      key: 'associated_install_channel',
      name: rLIB('Install Channel'),
      value: returnDetailsPropValue(additionalSalesforceData, 'associated_install_channel', defaultDetailsMissingJSX),
    },
    associated_install_branch_name: {
      key: 'associated_install_branch_name',
      name: rLIB('Install Branch'),
      value: returnDetailsPropValue(rootProject, 'associated_install_branch_name', defaultDetailsMissingJSX),
    },
    associated_metro_market_name: {
      key: 'associated_metro_market_name',
      name: rLIB('Metro Market'),
      value: returnDetailsPropValue(additionalSalesforceData, 'associated_metro_market_name', defaultDetailsMissingJSX),
    },
    associated_fulfillment_partner_name: {
      key: 'associated_fulfillment_partner_name',
      name: rLIB('Fulfillment Partner'),
      value: returnDetailsPropValue(additionalSalesforceData, 'associated_fulfillment_partner_name', defaultDetailsMissingJSX),
    },
    project_has_active_sow: {
      key: 'project_has_active_sow',
      name: rLIB('Has Active Additional Work'),
      value: returnDetailsPropValue(additionalSalesforceData, 'project_has_active_sow', defaultDetailsMissingJSX),
    },
    project_has_any_sow: {
      key: 'project_has_any_sow',
      name: rLIB('Has any Additional Work'),
      value: returnDetailsPropValue(additionalSalesforceData, 'project_has_any_sow', defaultDetailsMissingJSX),
    },
    project_has_misc_work: {
      key: 'project_has_misc_work',
      name: rLIB('Has Misc Work'),
      value: returnDetailsPropValue(additionalSalesforceData, 'project_has_misc_work', defaultDetailsMissingJSX),
    },
    project_has_electrical_work: {
      key: 'project_has_electrical_work',
      name: rLIB('Has electrical work'),
      value: returnDetailsPropValue(additionalSalesforceData, 'project_has_electrical_work', defaultDetailsMissingJSX),
    },
    project_has_post_install_repair_work: {
      key: 'project_has_post_install_repair_work',
      name: rLIB('Has repair work'),
      value: returnDetailsPropValue(additionalSalesforceData, 'project_has_post_install_repair_work', defaultDetailsMissingJSX),
    },
    project_has_roofing_work: {
      key: 'project_has_roofing_work',
      name: rLIB('Has roofing work'),
      value: returnDetailsPropValue(additionalSalesforceData, 'project_has_roofing_work', defaultDetailsMissingJSX),
    },
    project_has_structural_work: {
      key: 'project_has_structural_work',
      name: rLIB('Has structural work'),
      value: returnDetailsPropValue(additionalSalesforceData, 'project_has_structural_work', defaultDetailsMissingJSX),
    },
    project_vip_customer: {
      key: 'project_vip_customer',
      name: rLIB('VIP Customer'),
      value: returnDetailsPropValue(additionalSalesforceData, 'project_vip_customer', defaultDetailsMissingJSX),
    },
    project_site_design_priority: {
      key: 'project_site_design_priority',
      name: rLIB('Site Design Priority'),
      value: returnDetailsPropValue(additionalSalesforceData, 'project_site_design_priority', defaultDetailsMissingJSX),
    },
    project_flag_powewall: {
      key: 'project_flag_powewall',
      name: rLIB('Powerwall'),
      value: returnDetailsPropValue(additionalSalesforceData, 'project_flag_powewall', defaultDetailsMissingJSX),
    },
    project_flag_storage: {
      key: 'project_flag_storage',
      name: rLIB('LG Storage'),
      value: returnDetailsPropValue(additionalSalesforceData, 'project_flag_storage', defaultDetailsMissingJSX),
    },
  }
  const salesforceFinancialData: TsInterface_UnspecifiedObject = {
    financials_financing_type: {
      key: 'financials_financing_type',
      name: rLIB('Financing Type 1'),
      value: returnDetailsPropValue(additionalSalesforceData, 'financials_financing_type', defaultDetailsMissingJSX),
    },
    financials_financing_subtype: {
      key: 'financials_financing_subtype',
      name: rLIB('Financing Type 2'),
      value: returnDetailsPropValue(additionalSalesforceData, 'financials_financing_subtype', defaultDetailsMissingJSX),
    },
    financials_billing_type: {
      key: 'financials_billing_type',
      name: rLIB('Billing Type'),
      value: returnDetailsPropValue(additionalSalesforceData, 'financials_billing_type', defaultDetailsMissingJSX),
    },
    financials_year_1_solar_price_per_kwh: {
      key: 'financials_year_1_solar_price_per_kwh',
      name: rLIB('Year 1 Solar Price per KWH'),
      value: returnDetailsPropValue(additionalSalesforceData, 'financials_year_1_solar_price_per_kwh', defaultDetailsMissingJSX),
    },
    financials_annual_monthly_payment_escalator: {
      key: 'financials_annual_monthly_payment_escalator',
      name: rLIB('Annual Monthly Payment Escalator'),
      value: returnDetailsPropValue(additionalSalesforceData, 'financials_annual_monthly_payment_escalator', defaultDetailsMissingJSX),
    },
    financials_gross_price_eligible_for_itc: {
      key: 'financials_gross_price_eligible_for_itc',
      name: rLIB('Gross Price Eligible for ITC'),
      value: returnDetailsPropValue(additionalSalesforceData, 'financials_gross_price_eligible_for_itc', defaultDetailsMissingJSX),
    },
    financials_current_net_price_to_customer: {
      key: 'financials_current_net_price_to_customer',
      name: rLIB('Current Net Price to Customer'),
      value: returnDetailsPropValue(additionalSalesforceData, 'financials_current_net_price_to_customer', defaultDetailsMissingJSX),
    },
    financials_gross_price_not_eligible_for_itc: {
      key: 'financials_gross_price_not_eligible_for_itc',
      name: rLIB('Gross price not eligible for ITC'),
      value: returnDetailsPropValue(additionalSalesforceData, 'financials_gross_price_not_eligible_for_itc', defaultDetailsMissingJSX),
    },
    financials_gross_price_electrical: {
      key: 'financials_gross_price_electrical',
      name: rLIB('Gross price of SRM Electrical'),
      value: returnDetailsPropValue(additionalSalesforceData, 'financials_gross_price_electrical', defaultDetailsMissingJSX),
    },
    financials_gross_price_misc: {
      key: 'financials_gross_price_misc',
      name: rLIB('Gross price of SRM MISC'),
      value: returnDetailsPropValue(additionalSalesforceData, 'financials_gross_price_misc', defaultDetailsMissingJSX),
    },
    financials_gross_price_post_install: {
      key: 'financials_gross_price_post_install',
      name: rLIB('Gross price of SRM Post Install'),
      value: returnDetailsPropValue(additionalSalesforceData, 'financials_gross_price_post_install', defaultDetailsMissingJSX),
    },
    financials_gross_price_roofing: {
      key: 'financials_gross_price_roofing',
      name: rLIB('Gross price of SRM Roofing'),
      value: returnDetailsPropValue(additionalSalesforceData, 'financials_gross_price_roofing', defaultDetailsMissingJSX),
    },
    financials_gross_price_structural: {
      key: 'financials_gross_price_structural',
      name: rLIB('Gross price of SRM Structural'),
      value: returnDetailsPropValue(additionalSalesforceData, 'financials_gross_price_structural', defaultDetailsMissingJSX),
    },
    financials_total_mli_number: {
      key: 'financials_total_mli_number',
      name: rLIB('Total MLI Number'),
      value: returnDetailsPropValue(additionalSalesforceData, 'financials_total_mli_number', defaultDetailsMissingJSX),
    },
    financials_price_per_kwh: {
      key: 'financials_price_per_kwh',
      name: rLIB('Price per KWH'),
      value: returnDetailsPropValue(additionalSalesforceData, 'financials_price_per_kwh', defaultDetailsMissingJSX),
    },
  }
  // Return
  let listDetails = {}
  switch (listKey) {
    case 'customerDetailsObject':
      listDetails = customerDetailsObject
      break
    case 'homeDetailsObject':
      listDetails = homeDetailsObject
      break
    case 'systemDetailsObject':
      listDetails = systemDetailsObject
      break
    case 'timestampDetailsObject':
      listDetails = timestampDetailsObject
      break
    case 'otherSalesforceDetailsObject':
      listDetails = otherSalesforceDetailsObject
      break
    case 'salesforceFinancialData':
      listDetails = salesforceFinancialData
      break
  }
  return listDetails
}

const openDetailsEditDialog = (
  formInputs: TsInterface_FormInputs,
  uc_setUserInterface_FormDialogDisplay: any,
  rootProject: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  projectKey: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
): void => {
  // Copy of Form Data
  let copyOfFormData = { ...rootProject }
  // Correct Timestamp Formats for Form
  for (let loopInputKey in formInputs) {
    if (formInputs[loopInputKey].input_type === 'timestamp_datetime' && copyOfFormData[loopInputKey] != null) {
      copyOfFormData[loopInputKey] = returnFormattedDate(copyOfFormData[loopInputKey], 'YYYY-MM-DD HH:mm:ss')
    }
  }
  // Open Dialog
  uc_setUserInterface_FormDialogDisplay({
    display: true,
    form: {
      form: {
        formAdditionalData: {},
        formData: copyOfFormData,
        formInputs: formInputs,
        formOnChange: (
          formAdditionalData: TsInterface_FormAdditionalData,
          formData: TsInterface_FormData,
          formInputs: TsInterface_FormInputs,
          formSettings: TsInterface_FormSettings,
        ) => {},
        formSettings: {},
        formSubmission: (
          formSubmittedData: TsInterface_FormSubmittedData,
          formAdditionalData: TsInterface_FormAdditionalData,
          formHooks: TsInterface_FormHooksObject,
        ) => {
          return new Promise((resolve, reject) => {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                // Correct Timestamp Formats for Database
                for (let loopInputKey in formInputs) {
                  if (formInputs[loopInputKey].input_type === 'timestamp_datetime') {
                    formSubmittedData[loopInputKey] = new Date(formSubmittedData[loopInputKey])
                  }
                }
                // Save to database
                DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, projectKey), formSubmittedData)
                  .then((res_DSMD) => {
                    resolve(res_DSMD)
                  })
                  .catch((rej_DSMD) => {
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    reject(rej_DSMD)
                  })
              })
              .catch((rej_GCK) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                reject(rej_GCK)
              })
          })
        },
      },
      dialog: {
        formDialogHeaderColor: 'success',
        formDialogHeaderText: rLIB('Edit Project Details'),
        formDialogIcon: (
          <Icon
            type="solid"
            icon="pen-to-square"
          />
        ),
      },
    },
  })
}

const updateCustomerAtRiskStatus = (
  newStatus: number,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  projectKey: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
): void => {
  uc_setUserInterface_ConfirmDialogDisplay({
    display: true,
    confirm: {
      color: 'success',
      header: <>{rLIB('Update Customer Status')}</>,
      icon: <Icon icon="pen-to-square" />,
      submit_callback: () => {
        return new Promise((resolve, reject) => {
          getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
            .then((res_GCK) => {
              let updateObject = { associated_customer_risk_status: newStatus }

              // TODO - log

              DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, projectKey), updateObject)
                .then((res_DSMD) => {
                  resolve(res_DSMD)
                })
                .catch((rej_DSMD) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                  reject(rej_DSMD)
                })
            })
            .catch((rej_GCK) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
              reject(rej_GCK)
            })
        })
      },
      submit_text: rLIB('Update Status'),
      text: rLIB("Are you sure that you want to update this customer's risk status?"),
    },
  })
}

const changeReminderStatus = (
  reminderKey: string,
  status: string,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  ur_forceRerender: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
): void => {
  uc_setUserInterface_ConfirmDialogDisplay({
    display: true,
    confirm: {
      color: 'success',
      header: <>{rLIB('Mark reminder as read')}</>,
      icon: <Icon icon="bookmark" />,
      submit_callback: () => {
        return new Promise((resolve, reject) => {
          getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
            .then((res_GCK) => {
              let updateObject = {
                status: status,
              }
              DatabaseSetMergeDocument(DatabaseRef_Reminder_Document(res_GCK.clientKey, reminderKey), updateObject)
                .then((res_DSMD) => {
                  ur_forceRerender()
                  resolve(res_DSMD)
                })
                .catch((rej_DSMD) => {
                  reject(rej_DSMD)
                  console.error(rej_DSMD)
                })
            })
            .catch((rej_GCK) => {
              reject(rej_GCK)
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
            })
        })
      },
      submit_text: rLIB('Mark as read'),
      text: rLIB('Are you sure that you want to mark this reminder as read'),
    },
  })
}

// Salesforce
const viewRawSalesforceData = (
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  projectKey: any,
  uc_setUserInterface_CustomDialogDisplay: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
): void => {
  let promiseArray: TsType_UnknownPromise[] = []
  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    .then((res_GCK) => {
      // Instantiate Variables
      let opportunity = {}
      let proposal = {}
      let proposalAssets = {}
      let proposalDocs = {}
      let serviceContract = {}
      let payment = {}
      // Get Data
      promiseArray.push(
        DatabaseGetDocument(DatabaseRef_SunrunLead_Document(res_GCK.clientKey, projectKey, 'prod')).then((res_DGD) => {
          opportunity = res_DGD.data
        }),
      )
      promiseArray.push(
        DatabaseGetDocument(DatabaseRef_SunrunLeadPayment_Document(res_GCK.clientKey, projectKey, 'prod')).then((res_DGD) => {
          payment = res_DGD.data
        }),
      )
      promiseArray.push(
        DatabaseGetDocument(DatabaseRef_SunrunProposalByProspectId_Document(res_GCK.clientKey, projectKey, 'prod')).then((res_DGD) => {
          proposal = res_DGD.data
        }),
      )
      promiseArray.push(
        DatabaseGetDocument(DatabaseRef_SunrunServiceContact_Document(res_GCK.clientKey, projectKey, 'prod')).then((res_DGD) => {
          serviceContract = res_DGD.data
        }),
      )
      promiseArray.push(
        DatabaseGetDocument(DatabaseRef_SunrunProposalAssetsByProspectId_Document(res_GCK.clientKey, projectKey, 'prod')).then((res_DGD) => {
          proposalAssets = res_DGD.data
        }),
      )
      promiseArray.push(
        DatabaseGetDocument(DatabaseRef_SunrunProposalDocsByProspectId_Document(res_GCK.clientKey, projectKey, 'prod')).then((res_DGD) => {
          proposalDocs = res_DGD.data
        }),
      )
      // After Data Loaded
      Promise.all(promiseArray).finally(() => {
        setTimeout(() => {
          // Build Dialog
          let dialogJSX = (
            <Box>
              <Dialog
                // TransitionComponent={ Transition }
                className="bp_dialog_xl_width"
                keepMounted
                onClose={() => {
                  uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                }}
                open={true}
              >
                <AppBar
                  position="static"
                  color="inherit"
                >
                  <Toolbar>
                    <IconButton
                      aria-label="menu"
                      color="inherit"
                      disabled
                      edge="start"
                      size="large"
                      sx={{ mr: 2, color: '#fff !important' }}
                    >
                      <Icon icon="code" />
                    </IconButton>
                    <Typography
                      component={'span'}
                      variant={'h6'}
                      sx={{ flexGrow: 1 }}
                    >
                      <Box className="tw-inline-block">{rLIB('Raw Salesforce Data')}</Box>
                    </Typography>
                  </Toolbar>
                </AppBar>
                <DialogContent sx={{ padding: '0px' }}>
                  <TabsBasic
                    tabs={[
                      {
                        tabHeader: rLIB('Opportunity'),
                        tabOnChange: () => {},
                        tabContent: (
                          <Box className="tw-m-auto tw-p-2">
                            <Json
                              alphabetized={true}
                              data={opportunity}
                            />
                          </Box>
                        ),
                      },
                      {
                        tabHeader: rLIB('Proposal'),
                        tabOnChange: () => {},
                        tabContent: (
                          <Box className="tw-m-auto tw-p-2">
                            <Json
                              alphabetized={true}
                              data={proposal}
                            />
                          </Box>
                        ),
                      },
                      {
                        tabHeader: rLIB('Proposal Assets'),
                        tabOnChange: () => {},
                        tabContent: (
                          <Box className="tw-m-auto tw-p-2">
                            <Json
                              alphabetized={true}
                              data={proposalAssets}
                            />
                          </Box>
                        ),
                      },
                      {
                        tabHeader: rLIB('Proposal Docs'),
                        tabOnChange: () => {},
                        tabContent: (
                          <Box className="tw-m-auto tw-p-2">
                            <Json
                              alphabetized={true}
                              data={proposalDocs}
                            />
                          </Box>
                        ),
                      },
                      {
                        tabHeader: rLIB('Service Contract'),
                        tabOnChange: () => {},
                        tabContent: (
                          <Box className="tw-m-auto tw-p-2">
                            <Json
                              alphabetized={true}
                              data={serviceContract}
                            />
                          </Box>
                        ),
                      },
                      {
                        tabHeader: rLIB('Payment'),
                        tabOnChange: () => {},
                        tabContent: (
                          <Box className="tw-m-auto tw-p-2">
                            <Json
                              alphabetized={true}
                              data={payment}
                            />
                          </Box>
                        ),
                      },
                    ]}
                    tabsSettings={{}}
                  />
                </DialogContent>
              </Dialog>
            </Box>
          )
          // Open Dialog
          uc_setUserInterface_CustomDialogDisplay({
            display: true,
            dialog: {
              dialog_jsx: dialogJSX,
              settings: {
                max_width: 'lg',
              },
            },
          })
        }, 1)
      })
    })
    .catch((rej_GCK) => {
      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
    })
}

const refreshRawSalesforceData = (
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  setRefreshingData: any,
  projectKey: any,
  ur_forceRerender: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
) => {
  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    .then((res_GCK) => {
      setRefreshingData(true)
      cloudFunctionManageRequest('manageIntegrations', {
        function: 'sunrunProdFullProjectRefresh',
        client_key: res_GCK.clientKey,
        prospect_id: projectKey,
      })
        .then((res_CFMIR) => {
          setRefreshingData(false)
          ur_forceRerender()
        })
        .catch((rej_CFMIR) => {
          setRefreshingData(false)
          ur_forceRerender()
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_CFMIR.error })
        })
    })
    .catch((rej_GCK) => {
      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
    })
}

const refreshTaskProgressBar = (
  setRefreshingProgressBar: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  projectKey: any,
  ur_forceRerender: any,
): void => {
  setRefreshingProgressBar(true)
  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
    cloudFunctionManageRequest('manageTasks', {
      function: 'refreshProjectTaskProgressBar',
      client_key: res_GCK.clientKey,
      project_key: projectKey,
    }).finally(() => {
      setRefreshingProgressBar(false)
      ur_forceRerender()
    })
  })
  // refreshingProgressBar
}

///////////////////////////////
// JSX
///////////////////////////////

const rJSX_CustomerHappinessMeter = (
  readOrWrite: 'read' | 'write',
  uc_setUserInterface_ConfirmDialogDisplay: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  projectKey: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  rootProject: any,
): JSX.Element => {
  let customerStatusJSX = <></>
  let blueStatusIcon = (
    <Tooltip
      title={rLIB('Promoter')}
      placement="top"
    >
      <Box
        sx={{ fontSize: '38px' }}
        className="tw-inline-block tw-opacity-20 tw-cursor-pointer"
        onClick={() => {
          if (readOrWrite === 'write') {
            updateCustomerAtRiskStatus(
              5,
              uc_setUserInterface_ConfirmDialogDisplay,
              uc_RootData_ClientKey,
              uc_setRootData_ClientKey,
              projectKey,
              uc_setUserInterface_ErrorDialogDisplay,
            )
          }
        }}
      >
        <Icon
          icon="face-party"
          type="thin"
          className="tw-mx-1"
        ></Icon>
      </Box>
    </Tooltip>
  )
  let greenStatusIcon = (
    <Tooltip
      title={rLIB('Happy Customer')}
      placement="top"
    >
      <Box
        sx={{ fontSize: '38px' }}
        className="tw-inline-block tw-opacity-20 tw-cursor-pointer"
        onClick={() => {
          if (readOrWrite === 'write') {
            updateCustomerAtRiskStatus(
              4,
              uc_setUserInterface_ConfirmDialogDisplay,
              uc_RootData_ClientKey,
              uc_setRootData_ClientKey,
              projectKey,
              uc_setUserInterface_ErrorDialogDisplay,
            )
          }
        }}
      >
        <Icon
          icon="face-smile-beam"
          type="thin"
          className="tw-mx-1"
        ></Icon>
      </Box>
    </Tooltip>
  )
  let yellowStatusIcon = (
    <Tooltip
      title={rLIB('Neutral Customer')}
      placement="top"
    >
      <Box
        sx={{ fontSize: '38px' }}
        className="tw-inline-block tw-opacity-20 tw-cursor-pointer"
        onClick={() => {
          if (readOrWrite === 'write') {
            updateCustomerAtRiskStatus(
              3,
              uc_setUserInterface_ConfirmDialogDisplay,
              uc_RootData_ClientKey,
              uc_setRootData_ClientKey,
              projectKey,
              uc_setUserInterface_ErrorDialogDisplay,
            )
          }
        }}
      >
        <Icon
          icon="face-meh"
          type="thin"
          className="tw-mx-1"
        ></Icon>
      </Box>
    </Tooltip>
  )
  let orangeStatusIcon = (
    <Tooltip
      title={rLIB('Unhappy Customer')}
      placement="top"
    >
      <Box
        sx={{ fontSize: '38px' }}
        className="tw-inline-block tw-opacity-20 tw-cursor-pointer"
        onClick={() => {
          if (readOrWrite === 'write') {
            updateCustomerAtRiskStatus(
              2,
              uc_setUserInterface_ConfirmDialogDisplay,
              uc_RootData_ClientKey,
              uc_setRootData_ClientKey,
              projectKey,
              uc_setUserInterface_ErrorDialogDisplay,
            )
          }
        }}
      >
        <Icon
          icon="face-worried"
          type="thin"
          className="tw-mx-1"
        ></Icon>
      </Box>
    </Tooltip>
  )
  let redStatusIcon = (
    <Tooltip
      title={rLIB('Highly Escalated Customer')}
      placement="top"
    >
      <Box
        sx={{ fontSize: '38px' }}
        className="tw-inline-block tw-opacity-20 tw-cursor-pointer"
        onClick={() => {
          if (readOrWrite === 'write') {
            updateCustomerAtRiskStatus(
              1,
              uc_setUserInterface_ConfirmDialogDisplay,
              uc_RootData_ClientKey,
              uc_setRootData_ClientKey,
              projectKey,
              uc_setUserInterface_ErrorDialogDisplay,
            )
          }
        }}
      >
        <Icon
          icon="face-pouting"
          type="thin"
          className="tw-mx-1"
        ></Icon>
      </Box>
    </Tooltip>
  )
  let grayStatusIcon = (
    <Tooltip
      title={rLIB('Cancelled')}
      placement="top"
    >
      <Box
        sx={{ fontSize: '38px' }}
        className="tw-inline-block tw-opacity-20 tw-cursor-pointer"
        onClick={() => {
          if (readOrWrite === 'write') {
            updateCustomerAtRiskStatus(
              0,
              uc_setUserInterface_ConfirmDialogDisplay,
              uc_RootData_ClientKey,
              uc_setRootData_ClientKey,
              projectKey,
              uc_setUserInterface_ErrorDialogDisplay,
            )
          }
        }}
      >
        <Icon
          icon="skull"
          type="thin"
          className="tw-mx-1"
        ></Icon>
      </Box>
    </Tooltip>
  )
  switch (rootProject['associated_customer_risk_status']) {
    case 5:
      blueStatusIcon = (
        <Tooltip
          title={rLIB('Promoter')}
          placement="top"
        >
          <Box
            className="tw-inline-block"
            sx={{ fontSize: '38px', color: themeVariables.info_main }}
          >
            <Icon
              icon="face-party"
              type="solid"
              className="tw-mx-1"
            ></Icon>
          </Box>
        </Tooltip>
      )
      customerStatusJSX = (
        <>
          {rLIB('Promoter')}: {rLIB('Has Referred Others')}
        </>
      )
      break
    case 4:
      greenStatusIcon = (
        <Tooltip
          title={rLIB('Happy Customer')}
          placement="top"
        >
          <Box
            className="tw-inline-block"
            sx={{ fontSize: '38px', color: themeVariables.success_main }}
          >
            <Icon
              icon="face-smile-beam"
              type="solid"
              className="tw-mx-1"
            ></Icon>
          </Box>
        </Tooltip>
      )
      customerStatusJSX = rLIB('Happy Customer') as JSX.Element
      break
    case 3:
      yellowStatusIcon = (
        <Tooltip
          title={rLIB('Neutral Customer')}
          placement="top"
        >
          <Box
            className="tw-inline-block"
            sx={{ fontSize: '38px', color: themeVariables.warning_main }}
          >
            <Icon
              icon="face-meh"
              type="solid"
              className="tw-mx-1"
            ></Icon>
          </Box>
        </Tooltip>
      )
      customerStatusJSX = rLIB('Neutral Customer') as JSX.Element
      break
    case 2:
      orangeStatusIcon = (
        <Tooltip
          title={rLIB('Unhappy Customer')}
          placement="top"
        >
          <Box
            className="tw-inline-block"
            sx={{ fontSize: '38px', color: themeVariables.orange_500 }}
          >
            <Icon
              icon="face-worried"
              type="solid"
              className="tw-mx-1"
            ></Icon>
          </Box>
        </Tooltip>
      )
      customerStatusJSX = (
        <>
          {rLIB('Unhappy Customer')}: {rLIB('Brand Risk')}
        </>
      )
      break
    case 1:
      redStatusIcon = (
        <Tooltip
          title={rLIB('Highly Escalated Customer')}
          placement="top"
        >
          <Box
            className="tw-inline-block"
            sx={{ fontSize: '38px', color: themeVariables.error_main }}
          >
            <Icon
              icon="face-pouting"
              type="solid"
              className="tw-mx-1"
            ></Icon>
          </Box>
        </Tooltip>
      )
      customerStatusJSX = (
        <>
          {rLIB('Highly Escalated Customer')}: {rLIB('Work to get back on track')}
        </>
      )
      break
    case 0:
      grayStatusIcon = (
        <Tooltip
          title={rLIB('Cancelled')}
          placement="top"
        >
          <Box
            className="tw-inline-block"
            sx={{ fontSize: '38px', color: themeVariables.gray_700 }}
          >
            <Icon
              icon="skull"
              type="solid"
              className="tw-mx-1"
            ></Icon>
          </Box>
        </Tooltip>
      )
      customerStatusJSX = rLIB('Cancelled') as JSX.Element
      break
    // case -1:
    // ghostStatusIcon =
    // <Tooltip title={ s_GHOSTED } placement="top">
    // 	<Box className="tw-inline-block" sx={{ fontSize: "38px", color: themeVariables.purple_500 }}>
    // 		<Icon icon="ghost" type="solid" className="tw-mx-1"></Icon>
    // 	</Box>
    // </Tooltip>
    // customerStatusJSX = s_GHOSTED
    // break
  }
  let meterJSX = (
    <Box>
      <Box className="tw-text-center">
        {blueStatusIcon}
        {greenStatusIcon}
        {yellowStatusIcon}
        {orangeStatusIcon}
        {redStatusIcon}
        {grayStatusIcon}
        {/* { ghostStatusIcon } */}
      </Box>
      <Box className="tw-text-center tw-mt-3 tw-opacity-50">{customerStatusJSX}</Box>
    </Box>
  )
  return meterJSX
}

const rJSX_SystemDetailsRow = (quantity: string, manufacturer: string, model: string): JSX.Element => {
  let rowJSX = <></>
  if (manufacturer != null && manufacturer !== '' && model != null && model !== '') {
    rowJSX = (
      <Box className="tw-inline-block tw-opacity-50">
        {quantity} {manufacturer.substring(0, manufacturer.indexOf(' '))} {model}
      </Box>
    )
  } else if (!isNaN(parseFloat(quantity)) && parseFloat(quantity) > 0) {
    rowJSX = (
      <Box
        className="tw-inline-block tw-px-2 tw-rounded"
        sx={{ background: themeVariables.warning_main }}
      >
        {quantity} - {rLIB('Missing Manufacturer and Model')}
      </Box>
    )
  } else {
    rowJSX = <Box className="tw-inline-block tw-opacity-50">{rLIB('Missing')}</Box>
  }
  return rowJSX
}

const rJSX_FeedbackDetails = (projectFeedback: any, feedbackGraphSettings: any, feedbackCommentsSettings: any): JSX.Element => {
  let feedbackDetailsJSX = <></>
  if (projectFeedback != null && objectToArray(projectFeedback).length > 0) {
    feedbackDetailsJSX = (
      <Box>
        {/* <FeedbackNpsGraph
					feedbackData={ projectFeedback }
					feedbackSettings={ feedbackGraphSettings }
				/> */}
        <FeedbackNpsComments
          feedbackData={projectFeedback}
          feedbackSettings={feedbackCommentsSettings}
        />
      </Box>
    )
  } else {
    feedbackDetailsJSX = (
      <Card className="tw-p-2">
        <Box className="tw-text-center">
          <Typography
            variant="subtitle1"
            className="tw-opacity-30 tw-italic"
          >
            {rLIB('No feedback for project')}
          </Typography>
        </Box>
      </Card>
    )
  }
  return feedbackDetailsJSX
}

const rJSX_Reminder = (
  readOrWrite: 'read' | 'write',
  reminder: TsInterface_UnspecifiedObject,
  projectUsersFlatObject: TsInterface_UnspecifiedObject,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  ur_forceRerender: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
): JSX.Element => {
  let reminderJSX = <></>
  let iconColor = themeVariables.primary_main
  let reminderIcon = <></>
  let cutoffDate = new Date().getTime()
  let userNameJSX = <></>
  if (
    reminder != null &&
    reminder.associated_user_key != null &&
    projectUsersFlatObject != null &&
    projectUsersFlatObject[reminder.associated_user_key] != null
  ) {
    userNameJSX = (
      <Typography
        variant="body1"
        className="tw-italic tw-opacity-30 tw-inline-block tw-ml-2"
      >
        ({rLIB('reminder for')} {projectUsersFlatObject[reminder.associated_user_key]})
      </Typography>
    )
  }

  if (reminder.status === 'read') {
    iconColor = themeVariables.info_main
    reminderIcon = (
      <Icon
        icon="circle-check"
        type="solid"
        size="lg"
      />
    )
  } else if (cutoffDate < returnTimestampFromUnknownDateFormat(reminder.timestamp_reminder)) {
    iconColor = themeVariables.warning_main
    reminderIcon = (
      <Icon
        icon="clock"
        type="solid"
        size="lg"
      />
    )
  } else {
    iconColor = themeVariables.error_main
    reminderIcon = (
      <Icon
        icon="alarm-clock"
        type="solid"
        size="lg"
      />
    )
  }
  let actionIconJSX = <></>
  if (readOrWrite === 'write') {
    if (reminder.status === 'unread') {
      actionIconJSX = (
        <Tooltip title={rLIB('Dismiss')}>
          <Box
            onClick={() => {
              changeReminderStatus(
                reminder.key,
                'read',
                uc_setUserInterface_ConfirmDialogDisplay,
                uc_RootData_ClientKey,
                uc_setRootData_ClientKey,
                ur_forceRerender,
                uc_setUserInterface_ErrorDialogDisplay,
              )
            }}
          >
            <Icon
              icon="circle-x"
              type="solid"
              className="tw-cursor-pointer"
            ></Icon>
          </Box>
        </Tooltip>
      )
    } else {
      // actionIconJSX =
      // <Tooltip title={ s_MARK_AS_UNREAD }>
      // 	<Box onClick={ () => {
      // 		changeReminderStatus( reminder.key, "unread" )
      // 	} }>
      // 		<Icon icon="bell-on" type="solid" className="tw-cursor-pointer"></Icon>
      // 	</Box>
      // </Tooltip>
    }
  }
  let actionJSX = <></>
  reminderJSX = (
    <Box
      sx={{ background: themeVariables.background_paper, width: '100%' }}
      className="tw-p-2 tw-rounded-lg tw-my-2 tw-mx-auto"
    >
      <Stack
        direction="row"
        spacing={1}
        className="tw-justify-between"
      >
        <Box className="tw-text-left">
          <Box>
            <Box
              className="tw-inline-block tw-mr-3"
              sx={{ color: iconColor }}
            >
              {reminderIcon}
            </Box>
            <Typography
              variant="body1"
              className="tw-inline-block tw-font-black"
            >
              {reminder.title}
            </Typography>
            {userNameJSX}
          </Box>
          <Box>
            <Typography
              variant="body1"
              className="tw-inline-block tw-ml-8"
            >
              {reminder.text}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}>{actionIconJSX}</Box>
      </Stack>
      <Stack
        direction="row"
        spacing={1}
        className="tw-justify-between tw-mt-2"
      >
        <Box className="tw-pl-8">{actionJSX}</Box>
        <Box
          className="tw-opacity-30"
          sx={{ alignSelf: 'flex-end' }}
        >
          <Typography variant="body1">
            {returnFormattedDate(reminder.timestamp_reminder, 'h:mm a')} · {returnFormattedDate(reminder.timestamp_reminder, 'MMM D, YYYY')}
          </Typography>
        </Box>
      </Stack>
    </Box>
  )
  return reminderJSX
}

const rJSX_ProjectReminders = (
  readOrWrite: 'read' | 'write',
  projectOpenReminders: any,
  projectUsersFlatObject: TsInterface_UnspecifiedObject,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  ur_forceRerender: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
): JSX.Element => {
  let remindersJSX = <></>
  if (projectOpenReminders != null && objectToArray(projectOpenReminders).length > 0) {
    remindersJSX = (
      <Box>
        {objectToArray(projectOpenReminders).map((reminder: TsInterface_UnspecifiedObject, index: number) => (
          <Box key={index}>
            {rJSX_Reminder(
              readOrWrite,
              reminder,
              projectUsersFlatObject,
              uc_setUserInterface_ConfirmDialogDisplay,
              uc_RootData_ClientKey,
              uc_setRootData_ClientKey,
              ur_forceRerender,
              uc_setUserInterface_ErrorDialogDisplay,
            )}
          </Box>
        ))}
      </Box>
    )
  } else {
    remindersJSX = (
      <Card className="tw-p-2">
        <Box className="tw-text-center">
          <Typography
            variant="subtitle1"
            className="tw-opacity-30 tw-italic"
          >
            {rLIB('No reminders for project')}
          </Typography>
        </Box>
      </Card>
    )
  }
  return remindersJSX
}

const rJSX_StickyNoteGrid = (
  readOrWrite: 'read' | 'write',
  minimizedStickyNote: any,
  setMinimizedStickyNote: any,
  uc_setUserInterface_FormDialogDisplay: any,
  rootProject: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  projectKey: any,
): JSX.Element => {
  let gridJSX = <></>
  if (minimizedStickyNote === true) {
    gridJSX = (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Sticky Note')}</Box>
          <Box
            className="tw-inline-block tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer"
            onClick={() => {
              setMinimizedStickyNote(false)
            }}
          >
            <Icon
              icon="square-caret-down"
              className="tw-ml-2"
            />
          </Box>
        </Typography>
        <Box
          className="tw-p-2 tw-text-center"
          sx={{ border: '1px solid ' + themeVariables.background_paper }}
        >
          <Button
            color="inherit"
            className="tw-opacity-30"
            onClick={() => {
              setMinimizedStickyNote(false)
            }}
          >
            {rLIB('View')}
          </Button>
        </Box>
      </Box>
    )
  } else {
    let stickyNoteContent = <></>
    let editIcon = <></>
    if (readOrWrite === 'write') {
      editIcon = (
        <Tooltip title={rLIB('Edit Sticky Note')}>
          <Box
            className="tw-inline-block tw-opacity-40 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2"
            onClick={() => {
              uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {},
                    formData: rootProject,
                    formInputs: formInputs_StickyNote,
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: formSettings_StickyNote,
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
                          DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, projectKey), formSubmittedData)
                            .then((res_DSMD) => {
                              resolve(res_DSMD)
                            })
                            .catch((rej_DSMD) => {
                              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                              reject(rej_DSMD)
                            })
                        })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'warning',
                    formDialogHeaderText: rLIB('Edit Sticky Note'),
                    formDialogIcon: (
                      <Icon
                        icon="notes"
                        type="solid"
                      />
                    ),
                  },
                },
              })
            }}
          >
            <Icon icon="pen-to-square"></Icon>
          </Box>
        </Tooltip>
      )
    }
    if (rootProject != null && rootProject.sticky_note != null && rootProject.sticky_note !== '') {
      stickyNoteContent = (
        <Box>
          {replaceNewlinesWithHtmlBreaks(rootProject.sticky_note)}
          {editIcon}
        </Box>
      )
    } else {
      stickyNoteContent = (
        <Box>
          <Typography className="tw-italic tw-opacity-50 tw-inline-block">{rLIB('No Sticky Note')}</Typography>
          {editIcon}
        </Box>
      )
    }
    gridJSX = (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Sticky Note')}</Box>
          <Box
            className="tw-inline-block tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer"
            onClick={() => {
              setMinimizedStickyNote(true)
            }}
          >
            <Icon
              icon="square-caret-up"
              className="tw-ml-2 tw-cursor-pointer"
            />
          </Box>
        </Typography>
        <Card className="tw-p-2">{stickyNoteContent}</Card>
      </Box>
    )
  }
  return gridJSX
}

const rJSX_MostRecentContactGrid = (
  readOrWrite: 'read' | 'write',
  minimizedMostRecentContact: any,
  setMinimizedMostRecentContact: any,
  rootProject: any,
): JSX.Element => {
  let gridJSX = <></>
  if (minimizedMostRecentContact === true) {
    gridJSX = (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Last Contact')}</Box>
          <Box
            className="tw-inline-block tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer"
            onClick={() => {
              setMinimizedMostRecentContact(false)
            }}
          >
            <Icon
              icon="square-caret-down"
              className="tw-ml-2"
            />
          </Box>
        </Typography>
        <Box
          className="tw-p-2 tw-text-center"
          sx={{ border: '1px solid ' + themeVariables.background_paper }}
        >
          <Button
            color="inherit"
            className="tw-opacity-30"
            onClick={() => {
              setMinimizedMostRecentContact(false)
            }}
          >
            {rLIB('View')}
          </Button>
        </Box>
      </Box>
    )
  } else {
    // Date
    let dateJSX = <></>
    if (rootProject != null && rootProject.timestamp_last_contact_log != null) {
      dateJSX = (
        <Box className="tw-inline-block">
          {returnFormattedDate(rootProject.timestamp_last_contact_log, 'D MMM YYYY h:mm a')}
          <Box className="tw-inline-block tw-ml-1 tw-opacity-50">
            ( <ReactTimeAgo date={returnDateFromUnknownDateFormat(rootProject.timestamp_last_contact_log)} /> )
          </Box>
        </Box>
      )
    } else {
      dateJSX = <Box className="tw-italic tw-inline-block tw-opacity-30">{rLIB('Missing')}</Box>
    }
    // JSX
    gridJSX = (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Last Contact')}</Box>
          <Box
            className="tw-inline-block tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer"
            onClick={() => {
              setMinimizedMostRecentContact(true)
            }}
          >
            <Icon
              icon="square-caret-up"
              className="tw-ml-2 tw-cursor-pointer"
            />
          </Box>
        </Typography>
        <Card className="tw-p-2">
          <Box>
            <Typography variant="subtitle1">
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.warning_dark }}
              >
                <Icon
                  icon="calendar-clock"
                  className="tw-mr-2"
                />
                {rLIB('Last Contact Date')}:
              </Box>
              {dateJSX}
            </Typography>
            <Typography variant="subtitle1">
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.warning_dark }}
              >
                <Icon
                  icon="user"
                  className="tw-mr-2"
                />
                {rLIB('Last Contactor')}:
              </Box>
              {returnDetailsPropValue(rootProject, 'last_contact_log_person', <Box className="tw-italic tw-inline-block tw-opacity-30">{rLIB('Missing')}</Box>)}
            </Typography>
            <Typography variant="subtitle1">
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.warning_dark }}
              >
                <Icon
                  icon="mobile-screen"
                  className="tw-mr-2"
                />
                {rLIB('Last Contact Method')}:
              </Box>
              {returnDetailsPropValue(rootProject, 'last_contact_log_type', <Box className="tw-italic tw-inline-block tw-opacity-30">{rLIB('Missing')}</Box>)}
            </Typography>
            <Typography variant="subtitle1">
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.warning_dark }}
              >
                <Icon
                  icon="comment"
                  className="tw-mr-2"
                />
                {rLIB('Notes')}:
              </Box>
              {returnDetailsPropValue(rootProject, 'last_contact_log_notes', <Box className="tw-italic tw-inline-block tw-opacity-30">{rLIB('Missing')}</Box>)}
            </Typography>
          </Box>
          {/* { rootProject.timestamp_last_contact_log } */}
        </Card>
      </Box>
    )
  }
  return gridJSX
}

const rJSX_Coordinates = (rootProject: any): JSX.Element => {
  let coordinatesJSX = <></>
  if (rootProject.location_latitude != null && rootProject.location_longitude != null) {
    coordinatesJSX = (
      <>
        {rootProject.location_latitude}, {rootProject.location_longitude}
      </>
    )
  } else {
    coordinatesJSX = <Box className="tw-italic tw-inline-block tw-opacity-30">{rLIB('Missing')} </Box>
  }
  return coordinatesJSX
}

const rJSX_EditSalesPartnerIcon = (
  readOrWrite: 'read' | 'write',
  uc_setUserInterface_FormDialogDisplay: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  projectKey: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  rootProject: any,
): JSX.Element => {
  let iconJSX = <></>
  if (readOrWrite === 'write') {
    iconJSX = (
      <Box className="tw-inline-block">
        <Icon
          className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100"
          icon="pen-to-square"
          onClick={() => {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                let promiseArray = []
                let salesPartners: TsInterface_UnspecifiedObject = {}
                promiseArray.push(
                  DatabaseGetCollection(DatabaseRef_SalesPartner_Collection(res_GCK.clientKey))
                    .then((res_GD) => {
                      salesPartners = res_GD.data
                    })
                    .catch((rej_GD) => {
                      console.error(rej_GD)
                    }),
                )
                Promise.all(promiseArray).finally(() => {
                  let salesPartnerOptions: TsInterface_UnspecifiedObject = {}
                  for (let loopSalesPartnerKey in salesPartners) {
                    if (salesPartners[loopSalesPartnerKey] != null && salesPartners[loopSalesPartnerKey]['status'] === 'active') {
                      salesPartnerOptions[loopSalesPartnerKey] = {
                        key: salesPartners[loopSalesPartnerKey].key,
                        value: salesPartners[loopSalesPartnerKey].name,
                      }
                    }
                  }
                  uc_setUserInterface_FormDialogDisplay({
                    display: true,
                    form: {
                      form: {
                        formAdditionalData: {},
                        formData: rootProject,
                        formInputs: {
                          associated_sales_partner_key: {
                            data_type: 'string',
                            input_type: 'multiple_choice_radio',
                            key: 'associated_sales_partner_key',
                            label: rLIB('Sales Partner'),
                            required: false,
                            options: objectToArray(salesPartnerOptions),
                          },
                          TEMP_Confirmation: {
                            data_type: 'string',
                            input_type: 'text_basic',
                            key: 'TEMP_Confirmation',
                            label: rLIB('Type CONFIRM to proceed'),
                            required: true,
                          },
                        },
                        formOnChange: (
                          formAdditionalData: TsInterface_FormAdditionalData,
                          formData: TsInterface_FormData,
                          formInputs: TsInterface_FormInputs,
                          formSettings: TsInterface_FormSettings,
                        ) => {},
                        formSettings: {},
                        formSubmission: (
                          formSubmittedData: TsInterface_FormSubmittedData,
                          formAdditionalData: TsInterface_FormAdditionalData,
                          formHooks: TsInterface_FormHooksObject,
                        ) => {
                          return new Promise((resolve, reject) => {
                            let updateObject = {
                              associated_sales_partner_key: formSubmittedData.associated_sales_partner_key,
                              associated_sales_partner_name: salesPartnerOptions[formSubmittedData.associated_sales_partner_key].value,
                            }
                            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                              .then((res_GCK) => {
                                if (formSubmittedData['TEMP_Confirmation'] === 'CONFIRM') {
                                  // Save to database
                                  DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, projectKey), updateObject)
                                    .then((res_DSMD) => {
                                      resolve(res_DSMD)
                                    })
                                    .catch((rej_DSMD) => {
                                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                      reject(rej_DSMD)
                                    })
                                } else {
                                  let error = {
                                    message: rLIB('Failed to update sales partner'),
                                    details: rLIB('You need to type CONFIRM to proceed'),
                                    code: 'ER-D-TDT-ESP-01',
                                  }
                                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                                  reject({ success: false, error: error })
                                }
                              })
                              .catch((rej_GCK) => {
                                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                                reject(rej_GCK)
                              })
                          })
                        },
                      },
                      dialog: {
                        formDialogHeaderColor: 'success',
                        formDialogHeaderText: rLIB('Edit Project Details'),
                        formDialogIcon: (
                          <Icon
                            type="solid"
                            icon="pen-to-square"
                          />
                        ),
                      },
                    },
                  })
                })
              })
              .catch((rej_GCK) => {
                console.error(rej_GCK)
              })
          }}
        />
      </Box>
    )
  }
  return iconJSX
}

const rJSX_EditFinancePartnerIcon = (
  readOrWrite: 'read' | 'write',
  uc_setUserInterface_FormDialogDisplay: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  projectKey: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  rootProject: any,
): JSX.Element => {
  let iconJSX = <></>
  if (readOrWrite === 'write') {
    iconJSX = (
      <Box className="tw-inline-block">
        <Icon
          className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100"
          icon="pen-to-square"
          onClick={() => {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                let promiseArray = []
                let financePartners: TsInterface_UnspecifiedObject = {}
                promiseArray.push(
                  DatabaseGetCollection(DatabaseRef_FinancePartner_Collection(res_GCK.clientKey))
                    .then((res_GD) => {
                      financePartners = res_GD.data
                    })
                    .catch((rej_GD) => {
                      console.error(rej_GD)
                    }),
                )
                Promise.all(promiseArray).finally(() => {
                  let financePartnerOptions: TsInterface_UnspecifiedObject = {}
                  for (let loopFinancePartnerKey in financePartners) {
                    if (financePartners[loopFinancePartnerKey] != null && financePartners[loopFinancePartnerKey]['status'] === 'active') {
                      financePartnerOptions[loopFinancePartnerKey] = {
                        key: financePartners[loopFinancePartnerKey].key,
                        value: financePartners[loopFinancePartnerKey].name,
                      }
                    }
                  }
                  uc_setUserInterface_FormDialogDisplay({
                    display: true,
                    form: {
                      form: {
                        formAdditionalData: {},
                        formData: rootProject,
                        formInputs: {
                          associated_finance_partner_key: {
                            data_type: 'string',
                            input_type: 'multiple_choice_radio',
                            key: 'associated_finance_partner_key',
                            label: rLIB('Finance Partner'),
                            required: false,
                            options: objectToArray(financePartnerOptions),
                          },
                          TEMP_Confirmation: {
                            data_type: 'string',
                            input_type: 'text_basic',
                            key: 'TEMP_Confirmation',
                            label: rLIB('Type CONFIRM to proceed'),
                            required: true,
                          },
                        },
                        formOnChange: (
                          formAdditionalData: TsInterface_FormAdditionalData,
                          formData: TsInterface_FormData,
                          formInputs: TsInterface_FormInputs,
                          formSettings: TsInterface_FormSettings,
                        ) => {},
                        formSettings: {},
                        formSubmission: (
                          formSubmittedData: TsInterface_FormSubmittedData,
                          formAdditionalData: TsInterface_FormAdditionalData,
                          formHooks: TsInterface_FormHooksObject,
                        ) => {
                          return new Promise((resolve, reject) => {
                            let updateObject = {
                              associated_finance_partner_key: formSubmittedData.associated_finance_partner_key,
                              associated_finance_partner_name: financePartnerOptions[formSubmittedData.associated_finance_partner_key].value,
                            }
                            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                              .then((res_GCK) => {
                                if (formSubmittedData['TEMP_Confirmation'] === 'CONFIRM') {
                                  // Save to database
                                  DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, projectKey), updateObject)
                                    .then((res_DSMD) => {
                                      resolve(res_DSMD)
                                    })
                                    .catch((rej_DSMD) => {
                                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                      reject(rej_DSMD)
                                    })
                                } else {
                                  let error = {
                                    message: rLIB('Failed to update finance partner'),
                                    details: rLIB('You need to type CONFIRM to proceed'),
                                    code: 'ER-D-TDT-ESP-01',
                                  }
                                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                                  reject({ success: false, error: error })
                                }
                              })
                              .catch((rej_GCK) => {
                                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                                reject(rej_GCK)
                              })
                          })
                        },
                      },
                      dialog: {
                        formDialogHeaderColor: 'success',
                        formDialogHeaderText: rLIB('Edit Project Details'),
                        formDialogIcon: (
                          <Icon
                            type="solid"
                            icon="pen-to-square"
                          />
                        ),
                      },
                    },
                  })
                })
              })
              .catch((rej_GCK) => {
                console.error(rej_GCK)
              })
          }}
        />
      </Box>
    )
  }
  return iconJSX
}

const rJSX_EditFinancingTypeIcon = (
  readOrWrite: 'read' | 'write',
  uc_setUserInterface_FormDialogDisplay: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  projectKey: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  rootProject: any,
): JSX.Element => {
  let iconJSX = <></>
  if (readOrWrite === 'write') {
    iconJSX = (
      <Box className="tw-inline-block">
        <Icon
          className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100"
          icon="pen-to-square"
          onClick={() => {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                uc_setUserInterface_FormDialogDisplay({
                  display: true,
                  form: {
                    form: {
                      formAdditionalData: {},
                      formData: rootProject,
                      formInputs: {
                        associated_financing_type: {
                          data_type: 'string',
                          input_type: 'multiple_choice_radio',
                          key: 'associated_financing_type',
                          label: rLIB('Financing Type'),
                          required: false,
                          options: [
                            { key: 'cash', value: rLIB('Cash') },
                            { key: 'loan', value: rLIB('Loan') },
                            { key: 'lease', value: rLIB('Lease') },
                          ],
                        },
                        TEMP_Confirmation: {
                          data_type: 'string',
                          input_type: 'text_basic',
                          key: 'TEMP_Confirmation',
                          label: rLIB('Type CONFIRM to proceed'),
                          required: true,
                        },
                      },
                      formOnChange: (
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formData: TsInterface_FormData,
                        formInputs: TsInterface_FormInputs,
                        formSettings: TsInterface_FormSettings,
                      ) => {},
                      formSettings: {},
                      formSubmission: (
                        formSubmittedData: TsInterface_FormSubmittedData,
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formHooks: TsInterface_FormHooksObject,
                      ) => {
                        return new Promise((resolve, reject) => {
                          let updateObject = {
                            associated_financing_type: formSubmittedData.associated_financing_type,
                          }
                          getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                            .then((res_GCK) => {
                              if (formSubmittedData['TEMP_Confirmation'] === 'CONFIRM') {
                                // Save to database
                                DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, projectKey), updateObject)
                                  .then((res_DSMD) => {
                                    resolve(res_DSMD)
                                  })
                                  .catch((rej_DSMD) => {
                                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                    reject(rej_DSMD)
                                  })
                              } else {
                                let error = {
                                  message: rLIB('Failed to update finance partner'),
                                  details: rLIB('You need to type CONFIRM to proceed'),
                                  code: 'ER-D-TDT-ESP-01',
                                }
                                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                                reject({ success: false, error: error })
                              }
                            })
                            .catch((rej_GCK) => {
                              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                              reject(rej_GCK)
                            })
                        })
                      },
                    },
                    dialog: {
                      formDialogHeaderColor: 'success',
                      formDialogHeaderText: rLIB('Edit Project Details'),
                      formDialogIcon: (
                        <Icon
                          type="solid"
                          icon="pen-to-square"
                        />
                      ),
                    },
                  },
                })
              })
              .catch((rej_GCK) => {
                console.error(rej_GCK)
              })
          }}
        />
      </Box>
    )
  }
  return iconJSX
}

const rJSX_EditRegionIcon = (
  readOrWrite: 'read' | 'write',
  uc_setUserInterface_FormDialogDisplay: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  projectKey: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  rootProject: any,
): JSX.Element => {
  let iconJSX = <></>
  if (readOrWrite === 'write') {
    iconJSX = (
      <Icon
        className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100"
        icon="pen-to-square"
        onClick={() => {
          getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
            .then((res_GCK) => {
              let promiseArray = []
              let regions: TsInterface_UnspecifiedObject = {}
              promiseArray.push(
                DatabaseGetCollection(DatabaseRef_ActiveRegions_Query(res_GCK.clientKey))
                  .then((res_GD) => {
                    regions = res_GD.data
                  })
                  .catch((rej_GD) => {
                    console.error(rej_GD)
                  }),
              )
              Promise.all(promiseArray).finally(() => {
                let regionOptions: TsInterface_UnspecifiedObject = {}
                for (let loopRegionKey in regions) {
                  regionOptions[loopRegionKey] = {
                    key: regions[loopRegionKey].key,
                    value: regions[loopRegionKey].name,
                  }
                }
                uc_setUserInterface_FormDialogDisplay({
                  display: true,
                  form: {
                    form: {
                      formAdditionalData: {},
                      formData: rootProject,
                      formInputs: {
                        associated_region_key: {
                          data_type: 'string',
                          input_type: 'multiple_choice_radio',
                          key: 'associated_region_key',
                          label: rLIB('Region'),
                          required: false,
                          options: objectToArray(regionOptions),
                        },
                      },
                      formOnChange: (
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formData: TsInterface_FormData,
                        formInputs: TsInterface_FormInputs,
                        formSettings: TsInterface_FormSettings,
                      ) => {},
                      formSettings: {},
                      formSubmission: (
                        formSubmittedData: TsInterface_FormSubmittedData,
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formHooks: TsInterface_FormHooksObject,
                      ) => {
                        return new Promise((resolve, reject) => {
                          let updateObject = {
                            associated_region_key: formSubmittedData.associated_region_key,
                            associated_region_name: regionOptions[formSubmittedData.associated_region_key].value,
                          }
                          getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                            .then((res_GCK) => {
                              // Save to database
                              DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, projectKey), updateObject)
                                .then((res_DSMD) => {
                                  resolve(res_DSMD)
                                })
                                .catch((rej_DSMD) => {
                                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                  reject(rej_DSMD)
                                })
                            })
                            .catch((rej_GCK) => {
                              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                              reject(rej_GCK)
                            })
                        })
                      },
                    },
                    dialog: {
                      formDialogHeaderColor: 'success',
                      formDialogHeaderText: rLIB('Edit Project Details'),
                      formDialogIcon: (
                        <Icon
                          type="solid"
                          icon="pen-to-square"
                        />
                      ),
                    },
                  },
                })
              })
            })
            .catch((rej_GCK) => {
              console.error(rej_GCK)
            })
        }}
      />
    )
  }
  return iconJSX
}

const rJSX_CustomerDetailsGrid = (
  readOrWrite: 'read' | 'write',
  minimizedCustomerDetails: any,
  setMinimizedCustomerDetails: any,
  uc_setUserInterface_FormDialogDisplay: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  projectKey: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  rootProject: any,
): JSX.Element => {
  let gridJSX = <></>
  let editIconJSX = <></>
  if (readOrWrite === 'write') {
    editIconJSX = (
      <Box
        className="tw-inline-block tw-ml-2 tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer"
        onClick={() => {
          openDetailsEditDialog(
            formInputs_ProjectCustomerDetails,
            uc_setUserInterface_FormDialogDisplay,
            rootProject,
            uc_RootData_ClientKey,
            uc_setRootData_ClientKey,
            projectKey,
            uc_setUserInterface_ErrorDialogDisplay,
          )
        }}
      >
        <Icon icon="pen-to-square" />
      </Box>
    )
  }
  if (minimizedCustomerDetails === true) {
    gridJSX = (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Customer Details')}</Box>
          <Box
            className="tw-inline-block tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer"
            onClick={() => {
              setMinimizedCustomerDetails(false)
            }}
          >
            <Icon
              icon="square-caret-down"
              className="tw-ml-2"
            />
          </Box>
          {editIconJSX}
        </Typography>
        <Box
          className="tw-p-2 tw-text-center"
          sx={{ border: '1px solid ' + themeVariables.background_paper }}
        >
          <Button
            color="inherit"
            className="tw-opacity-30"
            onClick={() => {
              setMinimizedCustomerDetails(false)
            }}
          >
            {rLIB('View')}
          </Button>
        </Box>
      </Box>
    )
  } else {
    // Phone and Date
    // let phoneValue = <></>
    // if( rootProject != null && rootProject["associated_customer_phone"] != null ){
    // phoneValue = <>{ returnFormattedPhoneNumberString( rootProject["associated_customer_phone"] ) }</>
    // } else {
    // phoneValue = <Box className="tw-italic tw-inline-block tw-opacity-30">{ rLIB("Missing") } { rLIB("Phone") }</Box>
    // }
    let lastLoggedInDate = <></>
    if (rootProject != null && rootProject.timestamp_customer_last_logged_in != null) {
      lastLoggedInDate = (
        <Box className="tw-inline-block">
          {returnFormattedDate(rootProject.timestamp_customer_last_logged_in, 'D MMM YYYY h:mm a')}
          <Box className="tw-inline-block tw-ml-1 tw-opacity-50">
            ( <ReactTimeAgo date={returnDateFromUnknownDateFormat(rootProject.timestamp_customer_last_logged_in)} /> )
          </Box>
        </Box>
      )
    } else {
      lastLoggedInDate = defaultDetailsMissingJSX
    }
    // JSX
    gridJSX = (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Customer Details')}</Box>
          <Box
            className="tw-inline-block tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer"
            onClick={() => {
              setMinimizedCustomerDetails(true)
            }}
          >
            <Icon
              icon="square-caret-up"
              className="tw-ml-2 tw-cursor-pointer"
            />
          </Box>
          {editIconJSX}
        </Typography>
        <Card className="tw-p-2">
          <Box>
            <Typography variant="subtitle1">
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.primary_main }}
              >
                <Icon
                  icon="user"
                  className="tw-mr-2"
                />
                {rLIB('Customer Name')}:
              </Box>
              {returnDetailsPropValue(rootProject, 'associated_customer_name', defaultDetailsMissingJSX)}
            </Typography>
            <Typography variant="subtitle1">
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.primary_main }}
              >
                <Icon
                  icon="phone"
                  className="tw-mr-2"
                />
                {rLIB('Phone')}:
              </Box>
              {/* { phoneValue } */}
              {rootProject['associated_customer_phone']}
            </Typography>

            <Typography variant="subtitle1">
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.primary_main }}
              >
                <Icon
                  icon="phone"
                  className="tw-mr-2"
                />
                {rLIB('Additional Phone')}:
              </Box>
              {/* { phoneValue } */}

              {returnDetailsPropValue(
                rootProject,
                'associated_customer_additional_phone',
                <Box className="tw-italic tw-inline-block tw-opacity-30">{rLIB('Missing')} </Box>,
              )}
            </Typography>

            <Typography variant="subtitle1">
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.primary_main }}
              >
                <Icon
                  icon="envelope"
                  className="tw-mr-2"
                />
                {rLIB('Email')}:
              </Box>
              {returnDetailsPropValue(
                rootProject,
                'associated_customer_email',
                <Box className="tw-italic tw-inline-block tw-opacity-30">{rLIB('Missing')} </Box>,
              )}
            </Typography>
            <Divider />
            <Typography variant="subtitle1">
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.primary_main }}
              >
                <Icon
                  icon="location-dot"
                  className="tw-mr-2"
                />
                {rLIB('Distance from warehouse')}:
              </Box>
              {returnDetailsPropValue(
                rootProject,
                'location_distance_from_warehouse',
                <Box className="tw-italic tw-inline-block tw-opacity-30">{rLIB('Missing')} </Box>,
              )}
            </Typography>
            <Typography variant="subtitle1">
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.primary_main }}
              >
                <Icon
                  icon="location-dot"
                  className="tw-mr-2"
                />
                {rLIB('Region')}:
              </Box>
              {returnDetailsPropValue(rootProject, 'associated_region_name', <Box className="tw-italic tw-inline-block tw-opacity-30">{rLIB('Missing')} </Box>)}
              <Box className="tw-inline-block">
                {rJSX_EditRegionIcon(
                  readOrWrite,
                  uc_setUserInterface_FormDialogDisplay,
                  uc_RootData_ClientKey,
                  uc_setRootData_ClientKey,
                  projectKey,
                  uc_setUserInterface_ErrorDialogDisplay,
                  rootProject,
                )}
              </Box>
            </Typography>
            <Typography variant="subtitle1">
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.primary_main }}
              >
                <Icon
                  icon="location-dot"
                  className="tw-mr-2"
                />
                {rLIB('Address')}:
              </Box>
              {returnDetailsPropValue(rootProject, 'location_address', <Box className="tw-italic tw-inline-block tw-opacity-30">{rLIB('Missing')} </Box>)}
            </Typography>
            <Typography variant="subtitle1">
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.primary_main }}
              >
                <Icon
                  icon="location-dot"
                  className="tw-mr-2"
                />
                {rLIB('City')}:
              </Box>
              {returnDetailsPropValue(rootProject, 'location_city', <Box className="tw-italic tw-inline-block tw-opacity-30">{rLIB('Missing')} </Box>)}
            </Typography>
            <Typography variant="subtitle1">
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.primary_main }}
              >
                <Icon
                  icon="location-dot"
                  className="tw-mr-2"
                />
                {rLIB('County')}:
              </Box>
              {returnDetailsPropValue(rootProject, 'location_county', <Box className="tw-italic tw-inline-block tw-opacity-30">{rLIB('Missing')} </Box>)}
            </Typography>
            <Typography variant="subtitle1">
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.primary_main }}
              >
                <Icon
                  icon="location-dot"
                  className="tw-mr-2"
                />
                {rLIB('Zip')}:
              </Box>
              {returnDetailsPropValue(rootProject, 'location_zip', <Box className="tw-italic tw-inline-block tw-opacity-30">{rLIB('Missing')} </Box>)}
            </Typography>
            <Typography variant="subtitle1">
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.primary_main }}
              >
                <Icon
                  icon="location-dot"
                  className="tw-mr-2"
                />
                {rLIB('State')}:
              </Box>
              {returnDetailsPropValue(rootProject, 'location_state', <Box className="tw-italic tw-inline-block tw-opacity-30">{rLIB('Missing')} </Box>)}
            </Typography>
            <Typography variant="subtitle1">
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.primary_main }}
              >
                <Icon
                  icon="location-dot"
                  className="tw-mr-2"
                />
                {rLIB('Jurisdiction')}:
              </Box>
              {returnDetailsPropValue(rootProject, 'location_jurisdiction', <Box className="tw-italic tw-inline-block tw-opacity-30">{rLIB('Missing')} </Box>)}
            </Typography>
            <Typography variant="subtitle1">
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.primary_main }}
              >
                <Icon
                  icon="location-dot"
                  className="tw-mr-2"
                />
                {rLIB('Coordinates')}:
              </Box>
              {rJSX_Coordinates(rootProject)}
            </Typography>
            <Typography variant="subtitle1">
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.primary_main }}
              >
                <Icon
                  icon="location-dot"
                  className="tw-mr-2"
                />
                {rLIB('Gate Code / Access Instructions')}:
              </Box>
              {returnDetailsPropValue(
                rootProject,
                'location_access_instructions',
                <Box className="tw-italic tw-inline-block tw-opacity-30">{rLIB('Missing')} </Box>,
              )}
            </Typography>
            <Divider />
            <Typography variant="subtitle1">
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.primary_main }}
              >
                <Icon
                  icon="building"
                  className="tw-mr-2"
                />
                {rLIB('Sales Partner')}:
              </Box>
              {getProp(
                rootProject,
                'associated_sales_partner_name',
                getProp(rootProject, 'associated_sales_partner_key', <Box className="tw-italic tw-inline-block tw-opacity-30">{rLIB('Missing')} </Box>),
              )}

              {rJSX_EditSalesPartnerIcon(
                readOrWrite,
                uc_setUserInterface_FormDialogDisplay,
                uc_RootData_ClientKey,
                uc_setRootData_ClientKey,
                projectKey,
                uc_setUserInterface_ErrorDialogDisplay,
                rootProject,
              )}
            </Typography>
            <Typography variant="subtitle1">
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.primary_main }}
              >
                <Icon
                  icon="user"
                  className="tw-mr-2"
                />
                {rLIB('Sales Rep Name')}:
              </Box>
              {returnDetailsPropValue(
                rootProject,
                'associated_sales_rep_name',
                <Box className="tw-italic tw-inline-block tw-opacity-30">{rLIB('Missing')} </Box>,
              )}
            </Typography>
            <Typography variant="subtitle1">
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.primary_main }}
              >
                <Icon
                  icon="phone"
                  className="tw-mr-2"
                />
                {rLIB('Sales Rep Phone')}:
              </Box>
              {returnDetailsPropValue(
                rootProject,
                'associated_sales_rep_phone',
                <Box className="tw-italic tw-inline-block tw-opacity-30">{rLIB('Missing')} </Box>,
              )}
            </Typography>
            <Typography variant="subtitle1">
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.primary_main }}
              >
                <Icon
                  icon="envelope"
                  className="tw-mr-2"
                />
                {rLIB('Sales Rep Email')}:
              </Box>
              {returnDetailsPropValue(
                rootProject,
                'associated_sales_rep_email',
                <Box className="tw-italic tw-inline-block tw-opacity-30">{rLIB('Missing')} </Box>,
              )}
            </Typography>
            <Typography variant="subtitle1">
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.primary_main }}
              >
                <Icon
                  icon="people-group"
                  className="tw-mr-2"
                />
                {rLIB('Sales Rep Team')}:
              </Box>
              {returnDetailsPropValue(
                rootProject,
                'associated_sales_rep_team_name',
                <Box className="tw-italic tw-inline-block tw-opacity-30">{rLIB('Missing')} </Box>,
              )}
            </Typography>
            <Divider />
            <Typography variant="subtitle1">
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.primary_main }}
              >
                <Icon
                  icon="credit-card"
                  className="tw-mr-2"
                />
                {rLIB('Finance Partner')}:
              </Box>
              {getProp(
                rootProject,
                'associated_finance_partner_name',
                getProp(rootProject, 'associated_finance_partner_key', <Box className="tw-italic tw-inline-block tw-opacity-30">{rLIB('Missing')} </Box>),
              )}
              {rJSX_EditFinancePartnerIcon(
                readOrWrite,
                uc_setUserInterface_FormDialogDisplay,
                uc_RootData_ClientKey,
                uc_setRootData_ClientKey,
                projectKey,
                uc_setUserInterface_ErrorDialogDisplay,
                rootProject,
              )}
            </Typography>
            <Typography variant="subtitle1">
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.primary_main }}
              >
                <Icon
                  icon="list"
                  className="tw-mr-2"
                />
                {rLIB('Financing Type')}:
              </Box>
              <Box className="tw-capitalize tw-inline-block">
                {getProp(
                  rootProject,
                  'associated_financing_type',
                  getProp(rootProject, 'associated_financing_type', <Box className="tw-italic tw-inline-block tw-opacity-30">{rLIB('Missing')} </Box>),
                )}
              </Box>
              {rJSX_EditFinancingTypeIcon(
                readOrWrite,
                uc_setUserInterface_FormDialogDisplay,
                uc_RootData_ClientKey,
                uc_setRootData_ClientKey,
                projectKey,
                uc_setUserInterface_ErrorDialogDisplay,
                rootProject,
              )}
            </Typography>
            <Divider />
            <Typography variant="subtitle1">
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.primary_main }}
              >
                <Icon
                  icon="right-to-bracket"
                  className="tw-mr-2"
                />
                {rLIB('Last Customer Login Date')}:
              </Box>
              {lastLoggedInDate}
            </Typography>
            <Divider />
            <Typography variant="subtitle1">
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.primary_main }}
              >
                <Icon
                  icon="utility-pole"
                  className="tw-mr-2"
                />
                {rLIB('Utility')}:
              </Box>
              {returnDetailsPropValue(rootProject, 'associated_utility_company_name', defaultDetailsMissingJSX)}
            </Typography>
            <Typography variant="subtitle1">
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.primary_main }}
              >
                <Icon
                  icon="store"
                  className="tw-mr-2"
                />
                {rLIB('Vendor')}:
              </Box>
              {returnDetailsPropValue(rootProject, 'associated_vendor_name', defaultDetailsMissingJSX)}
            </Typography>
            <Typography variant="subtitle1">
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.primary_main }}
              >
                <Icon
                  icon="cart-shopping"
                  className="tw-mr-2"
                />
                {rLIB('Product Package')}:
              </Box>
              {returnDetailsPropValue(rootProject, 'associated_product_name', defaultDetailsMissingJSX)}
            </Typography>
            {/* <Divider />
						<Typography variant="subtitle1">
							<Box className="tw-inline-block tw-mr-2" sx={{ color: themeVariables.primary_main }}>
								<Icon icon="barcode" className="tw-mr-2"/>
								{ s_JOB_CODE }:
							</Box>
							{ returnDetailsPropValue(
								rootProject,
								"id_number",
								defaultDetailsMissingJSX
							) }
						</Typography> */}
          </Box>
        </Card>
      </Box>
    )
  }
  return gridJSX
}

const rJSX_CustomerHappinesssGrid = (
  readOrWrite: 'read' | 'write',
  minimizedCustomerHappiness: any,
  setMinimizedCustomerHappiness: any,
  rootProject: any,
  additionalSalesforceData: any,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  projectKey: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
): JSX.Element => {
  let gridJSX = <></>
  if (minimizedCustomerHappiness === true) {
    gridJSX = (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Customer Happiness')}</Box>
          <Box
            className="tw-inline-block tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer"
            onClick={() => {
              setMinimizedCustomerHappiness(false)
            }}
          >
            <Icon
              icon="square-caret-down"
              className="tw-ml-2"
            />
          </Box>
        </Typography>
        <Box
          className="tw-p-2 tw-text-center"
          sx={{ border: '1px solid ' + themeVariables.background_paper }}
        >
          <Button
            color="inherit"
            className="tw-opacity-30"
            onClick={() => {
              setMinimizedCustomerHappiness(false)
            }}
          >
            {rLIB('View')}
          </Button>
        </Box>
      </Box>
    )
  } else {
    gridJSX = (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Customer Happiness')}</Box>
          <Box
            className="tw-inline-block tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer"
            onClick={() => {
              setMinimizedCustomerHappiness(true)
            }}
          >
            <Icon
              icon="square-caret-up"
              className="tw-ml-2 tw-cursor-pointer"
            />
          </Box>
        </Typography>
        <Card className="tw-p-2">
          <Box>
            <Box className="tw-mt-2">
              {rJSX_CustomerHappinessMeter(
                readOrWrite,
                uc_setUserInterface_ConfirmDialogDisplay,
                uc_RootData_ClientKey,
                uc_setRootData_ClientKey,
                projectKey,
                uc_setUserInterface_ErrorDialogDisplay,
                rootProject,
              )}
            </Box>
          </Box>
        </Card>
      </Box>
    )
  }
  return gridJSX
}

const rJSX_TaskProgressRefreshButton = (
  refreshingProgressBar: any,
  setRefreshingProgressBar: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  projectKey: any,
  ur_forceRerender: any,
): JSX.Element => {
  let buttonJSX = <></>
  if (refreshingProgressBar === true) {
    buttonJSX = (
      <Box
        className="tw-inline-block tw-opacity-30 tw-ml-2"
        onClick={() => {}}
      >
        <Icon
          icon="arrows-rotate"
          className="bp_spin"
        />
      </Box>
    )
  } else {
    buttonJSX = (
      <Box
        className="tw-inline-block tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2"
        onClick={() => {
          refreshTaskProgressBar(setRefreshingProgressBar, uc_RootData_ClientKey, uc_setRootData_ClientKey, projectKey, ur_forceRerender)
        }}
      >
        <Icon
          icon="arrows-rotate"
          className=""
        />
      </Box>
    )
  }
  return buttonJSX
}

const rJSX_ProgressDetailsGrid = (
  readOrWrite: 'read' | 'write',
  minimizedProgressDetails: any,
  setMinimizedProgressDetails: any,
  refreshingProgressBar: any,
  rootProject: any,
  uc_setUserInterface_CustomDialogDisplay: any,
  setRefreshingProgressBar: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  projectKey: any,
  ur_forceRerender: any,
): JSX.Element => {
  let gridJSX = <></>
  let progressRefreshIcon = <></>
  if (readOrWrite === 'write') {
    progressRefreshIcon = rJSX_TaskProgressRefreshButton(
      refreshingProgressBar,
      setRefreshingProgressBar,
      uc_RootData_ClientKey,
      uc_setRootData_ClientKey,
      projectKey,
      ur_forceRerender,
    )
  }
  if (minimizedProgressDetails === true) {
    gridJSX = (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Progress')}</Box>
          <Box
            className="tw-inline-block tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer"
            onClick={() => {
              setMinimizedProgressDetails(false)
            }}
          >
            <Icon
              icon="square-caret-down"
              className="tw-ml-2"
            />
          </Box>
          {progressRefreshIcon}
        </Typography>
        <Box
          className="tw-p-2 tw-text-center"
          sx={{ border: '1px solid ' + themeVariables.background_paper }}
        >
          <Button
            color="inherit"
            className="tw-opacity-30"
            onClick={() => {
              setMinimizedProgressDetails(false)
            }}
          >
            {rLIB('View')}
          </Button>
        </Box>
      </Box>
    )
  } else {
    gridJSX = (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Progress')}</Box>
          <Box
            className="tw-inline-block tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer"
            onClick={() => {
              setMinimizedProgressDetails(true)
            }}
          >
            <Icon
              icon="square-caret-up"
              className="tw-ml-2 tw-cursor-pointer"
            />
          </Box>
          {progressRefreshIcon}
        </Typography>
        <Card className="tw-p-2">
          <Box>
            <Typography variant="subtitle1">
              <Box
                className="tw-inline-block tw-mr-1"
                sx={{ color: themeVariables.warning_main }}
              >
                <Icon icon="clipboard-list-check" />
              </Box>
              {rLIB('Progress')}
            </Typography>
            <Box className="tw-mb-3">{rJSX_ProjectProgressBar(rootProject, '8px', uc_setUserInterface_CustomDialogDisplay, false)}</Box>
            <Typography variant="subtitle1">
              <Box
                className="tw-inline-block tw-mr-1"
                sx={{ color: themeVariables.warning_main }}
              >
                <Icon icon="clipboard-list-check" />
              </Box>
              {rLIB('Active Tasks')}
            </Typography>
            <Box className="tw-pl-6">{rJSX_ActiveTasks(rootProject, true)}</Box>
          </Box>
        </Card>
      </Box>
    )
  }
  return gridJSX
}

const rJSX_HomeDetailsGrid = (
  readOrWrite: 'read' | 'write',
  minimizedHomeDetails: any,
  setMinimizedHomeDetails: any,
  rootProject: any,
  additionalSalesforceData: any,
  uc_setUserInterface_FormDialogDisplay: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  projectKey: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
): JSX.Element => {
  let gridJSX = <></>
  let homeDetailsEditIcon = <></>
  if (readOrWrite === 'write') {
    homeDetailsEditIcon = (
      <Box
        className="tw-inline-block tw-ml-2 tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer"
        onClick={() => {
          openDetailsEditDialog(
            formInputs_ProjectHomeDetails,
            uc_setUserInterface_FormDialogDisplay,
            rootProject,
            uc_RootData_ClientKey,
            uc_setRootData_ClientKey,
            projectKey,
            uc_setUserInterface_ErrorDialogDisplay,
          )
        }}
      >
        <Icon icon="pen-to-square" />
      </Box>
    )
  }
  if (minimizedHomeDetails === true) {
    gridJSX = (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Home Details')}</Box>
          <Box
            className="tw-inline-block tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer"
            onClick={() => {
              setMinimizedHomeDetails(false)
            }}
          >
            <Icon
              icon="square-caret-down"
              className="tw-ml-2"
            />
          </Box>
          {homeDetailsEditIcon}
        </Typography>
        <Box
          className="tw-p-2 tw-text-center"
          sx={{ border: '1px solid ' + themeVariables.background_paper }}
        >
          <Button
            color="inherit"
            className="tw-opacity-30"
            onClick={() => {
              setMinimizedHomeDetails(false)
            }}
          >
            {rLIB('View')}
          </Button>
        </Box>
      </Box>
    )
  } else {
    gridJSX = (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Home Details')}</Box>
          <Box
            className="tw-inline-block tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer"
            onClick={() => {
              setMinimizedHomeDetails(true)
            }}
          >
            <Icon
              icon="square-caret-up"
              className="tw-ml-2 tw-cursor-pointer"
            />
          </Box>
          {homeDetailsEditIcon}
        </Typography>
        <Card className="tw-p-2">
          {objectToArray(returnDetailsData('homeDetailsObject', rootProject, additionalSalesforceData)).map(
            (detailObject: TsInterface_UnspecifiedObject, index: number) => (
              <Box key={index}>
                <Typography variant="subtitle1">
                  <Box
                    className="tw-inline-block tw-mr-2"
                    sx={{ color: themeVariables.error_main }}
                  >
                    <Icon
                      icon="home"
                      className="tw-mr-2"
                    />
                    {detailObject.name}:
                  </Box>
                  <Box className="tw-inline-block">{detailObject.value}</Box>
                </Typography>
              </Box>
            ),
          )}
        </Card>
      </Box>
    )
  }
  return gridJSX
}

const rJSX_SystemDetailsGrid = (
  readOrWrite: 'read' | 'write',
  minimizedSystemDetails: any,
  setMinimizedSystemDetails: any,
  uc_setUserInterface_FormDialogDisplay: any,
  rootProject: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  projectKey: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  additionalSalesforceData: any,
): JSX.Element => {
  let gridJSX = <></>
  let systemDetailsEditIcon = <></>
  if (readOrWrite === 'write') {
    systemDetailsEditIcon = (
      <Box
        className="tw-inline-block tw-ml-2 tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer"
        onClick={() => {
          openDetailsEditDialog(
            formInputs_ProjectSystemDetails,
            uc_setUserInterface_FormDialogDisplay,
            rootProject,
            uc_RootData_ClientKey,
            uc_setRootData_ClientKey,
            projectKey,
            uc_setUserInterface_ErrorDialogDisplay,
          )
        }}
      >
        <Icon icon="pen-to-square" />
      </Box>
    )
  }
  if (minimizedSystemDetails === true) {
    gridJSX = (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('System Details')}</Box>
          <Box
            className="tw-inline-block tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer"
            onClick={() => {
              setMinimizedSystemDetails(false)
            }}
          >
            <Icon
              icon="square-caret-down"
              className="tw-ml-2"
            />
          </Box>
          {systemDetailsEditIcon}
        </Typography>
        <Box
          className="tw-p-2 tw-text-center"
          sx={{ border: '1px solid ' + themeVariables.background_paper }}
        >
          <Button
            color="inherit"
            className="tw-opacity-30"
            onClick={() => {
              setMinimizedSystemDetails(false)
            }}
          >
            {rLIB('View')}
          </Button>
        </Box>
      </Box>
    )
  } else {
    gridJSX = (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('System Details')}</Box>
          <Box
            className="tw-inline-block tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer"
            onClick={() => {
              setMinimizedSystemDetails(true)
            }}
          >
            <Icon
              icon="square-caret-up"
              className="tw-ml-2 tw-cursor-pointer"
            />
          </Box>
          {systemDetailsEditIcon}
        </Typography>
        <Card className="tw-p-2">
          <Typography variant="subtitle1">
            <Box
              className="tw-inline-block tw-mr-2"
              sx={{ color: themeVariables.info_main }}
            >
              <Icon
                icon="solar-panel"
                className="tw-mr-2"
              />
              {rLIB('Panels')}:
            </Box>
            {rJSX_SystemDetailsRow(rootProject.system_panel_quantity, rootProject.system_panel_manufacturer, rootProject.system_panel_model)}
          </Typography>
          <Typography variant="subtitle1">
            <Box
              className="tw-inline-block tw-mr-2"
              sx={{ color: themeVariables.info_main }}
            >
              <Icon
                icon="transformer-bolt"
                className="tw-mr-2"
              />
              {rLIB('Inverters')}:
            </Box>
            {rJSX_SystemDetailsRow(rootProject.system_inverter_quantity, rootProject.system_inverter_manufacturer, rootProject.system_inverter_model)}
          </Typography>
          <Typography variant="subtitle1">
            <Box
              className="tw-inline-block tw-mr-2"
              sx={{ color: themeVariables.info_main }}
            >
              <Icon
                icon="battery-bolt"
                className="tw-mr-2"
              />
              {rLIB('Storage')}
            </Box>
            {rJSX_SystemDetailsRow(rootProject.system_storage_quantity, rootProject.system_storage_manufacturer, rootProject.system_storage_model)}
          </Typography>
          <Divider />
          {objectToArray(returnDetailsData('systemDetailsObject', rootProject, additionalSalesforceData)).map(
            (detailObject: TsInterface_UnspecifiedObject, index: number) => (
              <Box key={index}>
                <Typography variant="subtitle1">
                  <Box
                    className="tw-inline-block tw-mr-2"
                    sx={{ color: themeVariables.info_main }}
                  >
                    <Icon
                      icon="solar-panel"
                      className="tw-mr-2"
                    />
                    {detailObject.name}:
                  </Box>
                  <Box className="tw-inline-block">{detailObject.value}</Box>
                </Typography>
              </Box>
            ),
          )}
        </Card>
      </Box>
    )
  }
  return gridJSX
}

const rJSX_SalesforceTimestampsGrid = (
  readOrWrite: 'read' | 'write',
  minimizedProjectTimestampsData: any,
  setMinimizedProjectTimestampsData: any,
  uc_setUserInterface_FormDialogDisplay: any,
  rootProject: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  projectKey: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  additionalSalesforceData: any,
): JSX.Element => {
  let gridJSX = <></>
  let salesforceTimestampsEditIcon = <></>
  if (readOrWrite === 'write') {
    salesforceTimestampsEditIcon = (
      <Box
        className="tw-inline-block tw-ml-2 tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer"
        onClick={() => {
          openDetailsEditDialog(
            formInputs_SalesforceTimestamps,
            uc_setUserInterface_FormDialogDisplay,
            rootProject,
            uc_RootData_ClientKey,
            uc_setRootData_ClientKey,
            projectKey,
            uc_setUserInterface_ErrorDialogDisplay,
          )
        }}
      >
        <Icon icon="pen-to-square" />
      </Box>
    )
  }
  if (minimizedProjectTimestampsData === true) {
    gridJSX = (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Timestamps')}</Box>
          <Box
            className="tw-inline-block tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer"
            onClick={() => {
              setMinimizedProjectTimestampsData(false)
            }}
          >
            <Icon
              icon="square-caret-down"
              className="tw-ml-2"
            />
          </Box>
          {salesforceTimestampsEditIcon}
        </Typography>
        <Box
          className="tw-p-2 tw-text-center"
          sx={{ border: '1px solid ' + themeVariables.background_paper }}
        >
          <Button
            color="inherit"
            className="tw-opacity-30"
            onClick={() => {
              setMinimizedProjectTimestampsData(false)
            }}
          >
            {rLIB('View')}
          </Button>
        </Box>
      </Box>
    )
  } else {
    gridJSX = (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Timestamps')}</Box>
          <Box
            className="tw-inline-block tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer"
            onClick={() => {
              setMinimizedProjectTimestampsData(true)
            }}
          >
            <Icon
              icon="square-caret-up"
              className="tw-ml-2 tw-cursor-pointer"
            />
          </Box>
          {salesforceTimestampsEditIcon}
        </Typography>
        <Card className="tw-p-2">
          {objectToArray(returnDetailsData('timestampDetailsObject', rootProject, additionalSalesforceData)).map(
            (timestampObject: TsInterface_UnspecifiedObject, index: number) => (
              <Box
                className=""
                key={index}
              >
                <Typography variant="subtitle1">
                  <Box
                    className="tw-inline-block tw-mr-2"
                    sx={{ color: themeVariables.secondary_main }}
                  >
                    <Icon
                      icon="clock"
                      type="regular"
                      className="tw-mr-2"
                    />
                    {timestampObject.name}:
                  </Box>
                  <Box className="tw-inline-block">{timestampObject.value}</Box>
                </Typography>
              </Box>
            ),
          )}
        </Card>
      </Box>
    )
  }
  return gridJSX
}

const rJSX_SunrunOtherDataGrid = (
  readOrWrite: 'read' | 'write',
  minimizedSalesPartnerSpecificData: any,
  setMinimizedSalesPartnerSpecificData: any,
  rootProject: any,
  additionalSalesforceData: any,
  refreshingData: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  projectKey: any,
  uc_setUserInterface_CustomDialogDisplay: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  setRefreshingData: any,
  ur_forceRerender: any,
): JSX.Element => {
  let gridJSX = <></>
  if (minimizedSalesPartnerSpecificData === true) {
    gridJSX = (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Sunrun Specific Data')}</Box>
          <Box
            className="tw-inline-block tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer"
            onClick={() => {
              setMinimizedSalesPartnerSpecificData(false)
            }}
          >
            <Icon
              icon="square-caret-down"
              className="tw-ml-2"
            />
          </Box>
        </Typography>
        <Box
          className="tw-p-2 tw-text-center"
          sx={{ border: '1px solid ' + themeVariables.background_paper }}
        >
          <Button
            color="inherit"
            className="tw-opacity-30"
            onClick={() => {
              setMinimizedSalesPartnerSpecificData(false)
            }}
          >
            {rLIB('View')}
          </Button>
        </Box>
      </Box>
    )
  } else {
    let salesforceButtonsJSX = <></>
    if (readOrWrite === 'write') {
      salesforceButtonsJSX = (
        <Box>
          <Box className="tw-mt-4 tw-text-center">
            <Button
              color="success"
              disableElevation
              disabled={refreshingData}
              onClick={() => {
                viewRawSalesforceData(
                  uc_RootData_ClientKey,
                  uc_setRootData_ClientKey,
                  projectKey,
                  uc_setUserInterface_CustomDialogDisplay,
                  uc_setUserInterface_ErrorDialogDisplay,
                )
              }}
              type="submit"
              variant="outlined"
            >
              <Icon
                icon="magnifying-glass"
                className="tw-mr-2"
              />
              {rLIB('View Raw Salesforce Data')}
            </Button>
          </Box>
          <Box className="tw-mt-2 tw-text-center">
            <Button
              color="success"
              disableElevation
              disabled={refreshingData}
              onClick={() => {
                refreshRawSalesforceData(
                  uc_RootData_ClientKey,
                  uc_setRootData_ClientKey,
                  setRefreshingData,
                  projectKey,
                  ur_forceRerender,
                  uc_setUserInterface_ErrorDialogDisplay,
                )
              }}
              type="submit"
              variant="outlined"
            >
              <Icon
                icon="calculator"
                className="tw-mr-2"
              />
              {rLIB('Refresh Salesforce Data')}
            </Button>
          </Box>
        </Box>
      )
    }
    gridJSX = (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Sunrun Specific Data')}</Box>
          <Box
            className="tw-inline-block tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer"
            onClick={() => {
              setMinimizedSalesPartnerSpecificData(true)
            }}
          >
            <Icon
              icon="square-caret-up"
              className="tw-ml-2 tw-cursor-pointer"
            />
          </Box>
        </Typography>
        <Card className="tw-p-2">
          <Box>
            <Typography variant="subtitle1">
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.teal_700 }}
              >
                <Icon
                  icon="square-info"
                  className="tw-mr-2"
                />
                {rLIB('Prospect ID')}:
              </Box>
              {returnDetailsPropValue(rootProject, 'key', defaultDetailsMissingJSX)}
            </Typography>
            <Typography variant="subtitle1">
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.teal_700 }}
              >
                <Icon
                  icon="square-info"
                  className="tw-mr-2"
                />
                {rLIB('Opportunity ID')}:
              </Box>
              {returnDetailsPropValue(rootProject, 'associated_opportunity_id', defaultDetailsMissingJSX)}
            </Typography>
            <Typography variant="subtitle1">
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.teal_700 }}
              >
                <Icon
                  icon="square-info"
                  className="tw-mr-2"
                />
                {rLIB('Proposal of Record ID')}:
              </Box>
              {returnDetailsPropValue(rootProject, 'associated_proposal_of_record_id', defaultDetailsMissingJSX)}
            </Typography>
            <Divider />
          </Box>
          {objectToArray(returnDetailsData('customerDetailsObject', rootProject, additionalSalesforceData)).map(
            (detailObject: TsInterface_UnspecifiedObject, index: number) => (
              <Box key={index}>
                <Typography variant="subtitle1">
                  <Box
                    className="tw-inline-block tw-mr-2"
                    sx={{ color: themeVariables.teal_700 }}
                  >
                    <Icon
                      icon="square-info"
                      className="tw-mr-2"
                    />
                    {detailObject.name}:
                  </Box>
                  <Box className="tw-inline-block">{detailObject.value}</Box>
                </Typography>
              </Box>
            ),
          )}
          <Divider />
          {objectToArray(returnDetailsData('otherSalesforceDetailsObject', rootProject, additionalSalesforceData)).map(
            (detailObject: TsInterface_UnspecifiedObject, index: number) => (
              <Box
                className=""
                key={index}
              >
                <Typography variant="subtitle1">
                  <Box
                    className="tw-inline-block tw-mr-2"
                    sx={{ color: themeVariables.teal_700 }}
                  >
                    <Icon
                      icon="square-info"
                      className="tw-mr-2"
                    />
                    {detailObject.name}
                  </Box>
                  <Box className="tw-inline-block">{detailObject.value}</Box>
                </Typography>
              </Box>
            ),
          )}
          {salesforceButtonsJSX}
        </Card>
      </Box>
    )
  }
  return gridJSX
}

const rJSX_SalesforceFinancialDataGrid = (
  readOrWrite: 'read' | 'write',
  minimizedProjectFinancialData: any,
  setMinimizedProjectFinancialData: any,
  rootProject: any,
  additionalSalesforceData: any,
): JSX.Element => {
  let gridJSX = <></>
  if (minimizedProjectFinancialData === true) {
    gridJSX = (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Finances')}</Box>
          <Box
            className="tw-inline-block tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer"
            onClick={() => {
              setMinimizedProjectFinancialData(false)
            }}
          >
            <Icon
              icon="square-caret-down"
              className="tw-ml-2"
            />
          </Box>
        </Typography>
        <Box
          className="tw-p-2 tw-text-center"
          sx={{ border: '1px solid ' + themeVariables.background_paper }}
        >
          <Button
            color="inherit"
            className="tw-opacity-30"
            onClick={() => {
              setMinimizedProjectFinancialData(false)
            }}
          >
            {rLIB('View')}
          </Button>
        </Box>
      </Box>
    )
  } else {
    gridJSX = (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Finances')}</Box>
          <Box
            className="tw-inline-block tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer"
            onClick={() => {
              setMinimizedProjectFinancialData(true)
            }}
          >
            <Icon
              icon="square-caret-up"
              className="tw-ml-2 tw-cursor-pointer"
            />
          </Box>
        </Typography>
        <Card className="tw-p-2">
          {objectToArray(returnDetailsData('salesforceFinancialData', rootProject, additionalSalesforceData)).map(
            (detailObject: TsInterface_UnspecifiedObject, index: number) => (
              <Box
                className=""
                key={index}
              >
                <Typography variant="subtitle1">
                  <Box
                    className="tw-inline-block tw-mr-2"
                    sx={{ color: themeVariables.success_main }}
                  >
                    <Icon
                      icon="sack-dollar"
                      className="tw-mr-2"
                    />
                    {detailObject.name}
                  </Box>
                  <Box className="tw-inline-block">{detailObject.value}</Box>
                </Typography>
              </Box>
            ),
          )}
        </Card>
      </Box>
    )
  }
  return gridJSX
}

const rJSX_ProjectTasksGrid = (
  projectTasks: Record<string, any>,
  minimizedProjectTasks: boolean,
  setMinimizedProjectTasks: React.Dispatch<React.SetStateAction<boolean>>,
): JSX.Element => {
  const taskArray = projectTasks
    ? Object.values(projectTasks).map((task) => ({
        ...task,
        team_members: task.task_completion_scheduled_team_names ? getUniqueTeamMembers(task.task_completion_scheduled_team_names) : [],
        last_scheduled_date: Array.isArray(task.task_completion_scheduled_dates)
          ? task.task_completion_scheduled_dates[task.task_completion_scheduled_dates.length - 1]
            ? dayjs(new Date(task.task_completion_scheduled_dates[task.task_completion_scheduled_dates.length - 1])).format('DD MMM YYYY')
            : 'N/A'
          : 'N/A',
        completed_date: task.timestamp_completed ? dayjs(task.timestamp_completed.seconds * 1000).format('DD MMM YYYY') : 'N/A',
      }))
    : []
  const filteredTasks = taskArray.filter((task) => task.associated_team_name)

  const sortedTasks = filteredTasks.sort((a, b) => {
    const dateA = a.last_scheduled_date === 'N/A' ? 0 : dayjs(a.last_scheduled_date, 'DD MMM YYYY').valueOf()
    const dateB = b.last_scheduled_date === 'N/A' ? 0 : dayjs(b.last_scheduled_date, 'DD MMM YYYY').valueOf()
    return dateA - dateB
  })

  return (
    <Box>
      <Typography variant="h6">
        <Box className="tw-inline-block tw-opacity-50">{rLIB('Field Tasks')}</Box>
        <Box
          className="tw-inline-block tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer"
          onClick={() => {
            console.log(`Toggling Project Tasks Grid: ${minimizedProjectTasks ? 'Expand' : 'Minimize'}`)
            setMinimizedProjectTasks(!minimizedProjectTasks)
          }}
        >
          <Icon
            icon={minimizedProjectTasks ? 'square-caret-down' : 'square-caret-up'}
            className="tw-ml-2 tw-cursor-pointer"
          />
        </Box>
      </Typography>
      {minimizedProjectTasks ? (
        <Box
          className="tw-p-2 tw-text-center"
          sx={{ border: '1px solid ' + themeVariables.background_paper }}
        >
          <Button
            color="inherit"
            className="tw-opacity-30"
            onClick={() => setMinimizedProjectTasks(false)}
          >
            {rLIB('View')}
          </Button>
        </Box>
      ) : (
        <Box className="tw-mt-4">
          {sortedTasks.map((task, index) => (
            <Card
              key={index}
              className="tw-p-4 tw-mb-2 tw-rounded-md"
              sx={{
                boxShadow: 'none',
                width: '100%',
                maxWidth: '800px',
              }}
            >
              <Grid
                container
                spacing={2}
                alignItems="center"
              >
                {/* Task Name, Status, and Team Name (First Row) */}
                <Grid
                  item
                  xs={12}
                  md={4}
                >
                  <Typography
                    variant="subtitle2"
                    className="tw-font-semibold"
                  >
                    {rLIB('Task Name')}
                  </Typography>
                  <Typography variant="body2">{task.name || 'N/A'}</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                >
                  <Typography
                    variant="subtitle2"
                    className="tw-font-semibold"
                  >
                    {rLIB('Status')}
                  </Typography>
                  <Typography
                    variant="body2"
                    className="tw-capitalize"
                    sx={{
                      color: task.status === 'completed' ? themeVariables.success_main : task.status === 'assigned' ? themeVariables.warning_main : 'inherit',
                    }}
                  >
                    {task.status || 'N/A'}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                >
                  <Typography
                    variant="subtitle2"
                    className="tw-font-semibold"
                  >
                    {rLIB('Team Name')}
                  </Typography>
                  <Typography variant="body2">{task.associated_team_name || 'N/A'}</Typography>
                </Grid>

                {/* Team Members, Scheduled Date, and Completed Date (Second Row) */}
                <Grid
                  item
                  xs={12}
                  md={4}
                >
                  <Typography
                    variant="subtitle2"
                    className="tw-font-semibold"
                  >
                    {rLIB('Team Members')}
                  </Typography>
                  <Box className="tw-flex tw-flex-wrap tw-gap-1">
                    {task.team_members.length > 0 ? (
                      task.team_members.map((member: string, idx: number) => (
                        <Chip
                          key={idx}
                          label={member}
                          size="small"
                          className="tw-mr-1 tw-mb-1"
                          sx={{
                            height: '24px',
                            fontWeight: 500,
                          }}
                        />
                      ))
                    ) : (
                      <Typography
                        variant="body2"
                        className="tw-opacity-50"
                      >
                        {rLIB('No team members assigned.')}
                      </Typography>
                    )}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                >
                  <Typography
                    variant="subtitle2"
                    className={`tw-font-semibold ${task.last_scheduled_date === 'N/A' ? 'tw-opacity-50' : ''}`}
                    sx={{ color: task.last_scheduled_date === 'N/A' ? 'gray' : 'inherit' }}
                  >
                    {rLIB('Scheduled Date')}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: task.last_scheduled_date === 'N/A' ? 'gray' : 'inherit' }}
                  >
                    {task.last_scheduled_date}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                >
                  <Typography
                    variant="subtitle2"
                    className={`tw-font-semibold ${task.completed_date === 'N/A' ? 'tw-opacity-50' : ''}`}
                    sx={{ color: task.completed_date === 'N/A' ? 'gray' : 'inherit' }}
                  >
                    {rLIB('Completed Date')}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: task.completed_date === 'N/A' ? 'gray' : 'inherit' }}
                  >
                    {task.completed_date}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          ))}
        </Box>
      )}
    </Box>
  )
}

export default rJSX_ProjectTasksGrid

const getUniqueTeamMembers = (teamNamesMap: Record<string, Record<string, string>>): string[] => {
  const uniqueNamesSet = new Set<string>()

  if (teamNamesMap && typeof teamNamesMap === 'object') {
    Object.values(teamNamesMap).forEach((dailyMap) => {
      if (dailyMap && typeof dailyMap === 'object') {
        Object.values(dailyMap).forEach((memberName) => {
          uniqueNamesSet.add(memberName)
        })
      }
    })
  }

  return Array.from(uniqueNamesSet)
}

const rJSX_ReminderDetails = (
  readOrWrite: 'read' | 'write',
  minimizedReminderDetails: any,
  setMinimizedReminderDetails: any,
  uc_setUserInterface_FormDialogDisplay: any,
  uc_RootData_GlobalUser: any,
  projectKey: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  projectOpenReminders: any,
  projectUsersFlatObject: any,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  ur_forceRerender: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
): JSX.Element => {
  let gridJSX = <></>
  let createIconJSX = <></>
  if (readOrWrite === 'write') {
    createIconJSX = (
      <Box
        className="tw-inline-block tw-ml-2 tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer"
        onClick={() => {
          createScheduledReminder(uc_setUserInterface_FormDialogDisplay, uc_RootData_GlobalUser, projectKey, uc_RootData_ClientKey, uc_setRootData_ClientKey)
        }}
      >
        <Icon icon="circle-plus" />
      </Box>
    )
  }
  if (minimizedReminderDetails === true) {
    gridJSX = (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Reminders')}</Box>
          <Box
            className="tw-inline-block tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer"
            onClick={() => {
              setMinimizedReminderDetails(false)
            }}
          >
            <Icon
              icon="square-caret-down"
              className="tw-ml-2"
            />
          </Box>
          {createIconJSX}
        </Typography>
        <Box
          className="tw-p-2 tw-text-center"
          sx={{ border: '1px solid ' + themeVariables.background_paper }}
        >
          <Button
            color="inherit"
            className="tw-opacity-30"
            onClick={() => {
              setMinimizedReminderDetails(false)
            }}
          >
            {rLIB('View')}
          </Button>
        </Box>
      </Box>
    )
  } else {
    gridJSX = (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Reminders')}</Box>
          <Box
            className="tw-inline-block tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer"
            onClick={() => {
              setMinimizedReminderDetails(true)
            }}
          >
            <Icon
              icon="square-caret-up"
              className="tw-ml-2 tw-cursor-pointer"
            />
          </Box>
          {createIconJSX}
        </Typography>
        <Box>
          {rJSX_ProjectReminders(
            readOrWrite,
            projectOpenReminders,
            projectUsersFlatObject,
            uc_setUserInterface_ConfirmDialogDisplay,
            uc_RootData_ClientKey,
            uc_setRootData_ClientKey,
            ur_forceRerender,
            uc_setUserInterface_ErrorDialogDisplay,
          )}
        </Box>
      </Box>
    )
  }
  return gridJSX
}

const rJSX_CustomerFeedbackGrid = (
  readOrWrite: 'read' | 'write',
  minimizedFeedbackDetails: any,
  setMinimizedFeedbackDetails: any,
  uc_setUserInterface_FormDialogDisplay: any,
  uc_RootData_GlobalUser: any,
  projectKey: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  projectFeedback: any,
): JSX.Element => {
  let gridJSX = <></>
  // let createIconJSX = <></>
  if (readOrWrite === 'write') {
    // createIconJSX =
    // <Box className="tw-inline-block tw-ml-2 tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer" onClick={ () => {
    // 	createCustomerFeedback(
    // 		uc_setUserInterface_FormDialogDisplay,
    // 		uc_RootData_GlobalUser,
    // 		projectKey,
    // 		uc_RootData_ClientKey,
    // 		uc_setRootData_ClientKey
    // 	)
    // }}>
    // 	<Icon icon="circle-plus" />
    // </Box>
  }
  if (minimizedFeedbackDetails === true) {
    gridJSX = (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Customer Feedback')}</Box>
          <Box
            className="tw-inline-block tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer"
            onClick={() => {
              setMinimizedFeedbackDetails(false)
            }}
          >
            <Icon
              icon="square-caret-down"
              className="tw-ml-2"
            />
          </Box>
          {/* { createIconJSX } */}
        </Typography>
        <Box
          className="tw-p-2 tw-text-center"
          sx={{ border: '1px solid ' + themeVariables.background_paper }}
        >
          <Button
            color="inherit"
            className="tw-opacity-30"
            onClick={() => {
              setMinimizedFeedbackDetails(false)
            }}
          >
            {rLIB('View')}
          </Button>
        </Box>
      </Box>
    )
  } else {
    gridJSX = (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Customer Feedback')}</Box>
          <Box
            className="tw-inline-block tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer"
            onClick={() => {
              setMinimizedFeedbackDetails(true)
            }}
          >
            <Icon
              icon="square-caret-up"
              className="tw-ml-2 tw-cursor-pointer"
            />
          </Box>
          {/* { createIconJSX } */}
        </Typography>
        <Box>{rJSX_FeedbackDetails(projectFeedback, feedbackGraphSettings, feedbackCommentsSettings)}</Box>
      </Box>
    )
  }
  return gridJSX
}

const rJSX_EstimatedTimelineGrid = (
  readOrWrite: 'read' | 'write',
  minimizedEstimatedTimeline: any,
  uc_setUserInterface_FormDialogDisplay: any,
  rootProject: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  projectKey: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  setMinimizedEstimatedTimeline: any,
): JSX.Element => {
  let gridJSX = <></>
  let editIconJSX = <></>
  if (readOrWrite === 'write') {
    editIconJSX = (
      <Box
        className="tw-inline-block tw-ml-2 tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer"
        onClick={() => {
          openDetailsEditDialog(
            formInputs_EstimatedTimeline,
            uc_setUserInterface_FormDialogDisplay,
            rootProject,
            uc_RootData_ClientKey,
            uc_setRootData_ClientKey,
            projectKey,
            uc_setUserInterface_ErrorDialogDisplay,
          )
        }}
      >
        <Icon icon="pen-to-square" />
      </Box>
    )
  }
  if (minimizedEstimatedTimeline === true) {
    gridJSX = (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Estimated Timeline')}</Box>
          <Box
            className="tw-inline-block tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer"
            onClick={() => {
              setMinimizedEstimatedTimeline(false)
            }}
          >
            <Icon
              icon="square-caret-down"
              className="tw-ml-2"
            />
          </Box>
          {editIconJSX}
        </Typography>
        <Box
          className="tw-p-2 tw-text-center"
          sx={{ border: '1px solid ' + themeVariables.background_paper }}
        >
          <Button
            color="inherit"
            className="tw-opacity-30"
            onClick={() => {
              setMinimizedEstimatedTimeline(false)
            }}
          >
            {rLIB('View')}
          </Button>
        </Box>
      </Box>
    )
  } else {
    // Dates
    let capDateJSX = <></>
    if (rootProject.timestamp_estimated_cap_date != null) {
      capDateJSX = (
        <Box className="tw-inline-block">
          {returnFormattedDate(rootProject.timestamp_estimated_cap_date, 'D MMM YYYY')}
          <Box className="tw-inline-block tw-ml-1 tw-opacity-50">
            ( <ReactTimeAgo date={returnDateFromUnknownDateFormat(rootProject.timestamp_estimated_cap_date)} /> )
          </Box>
        </Box>
      )
    } else {
      capDateJSX = defaultDetailsMissingJSX
    }
    let installDateJSX = <></>
    if (rootProject.timestamp_estimated_install_date != null) {
      installDateJSX = (
        <Box className="tw-inline-block">
          {returnFormattedDate(rootProject.timestamp_estimated_install_date, 'D MMM YYYY')}
          <Box className="tw-inline-block tw-ml-1 tw-opacity-50">
            ( <ReactTimeAgo date={returnDateFromUnknownDateFormat(rootProject.timestamp_estimated_install_date)} /> )
          </Box>
        </Box>
      )
    } else {
      installDateJSX = defaultDetailsMissingJSX
    }
    let activationDateJSX = <></>
    if (rootProject.timestamp_estimated_activation_date != null) {
      activationDateJSX = (
        <Box className="tw-inline-block">
          {returnFormattedDate(rootProject.timestamp_estimated_activation_date, 'D MMM YYYY')}
          <Box className="tw-inline-block tw-ml-1 tw-opacity-50">
            ( <ReactTimeAgo date={returnDateFromUnknownDateFormat(rootProject.timestamp_estimated_activation_date)} /> )
          </Box>
        </Box>
      )
    } else {
      activationDateJSX = defaultDetailsMissingJSX
    }
    // JSX
    gridJSX = (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Estimated Timeline')}</Box>
          <Box
            className="tw-inline-block tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer"
            onClick={() => {
              setMinimizedEstimatedTimeline(true)
            }}
          >
            <Icon
              icon="square-caret-up"
              className="tw-ml-2 tw-cursor-pointer"
            />
          </Box>
          {editIconJSX}
        </Typography>
        <Card className="tw-p-2">
          <Box>
            <Typography variant="subtitle1">
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.primary_main }}
              >
                <Icon
                  icon="wave-pulse"
                  className="tw-mr-1"
                />
                {rLIB('Project Status')}:
              </Box>
              {rootProject.status}
            </Typography>
            <Typography variant="subtitle1">
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.primary_main }}
              >
                <Icon
                  icon="calendar-clock"
                  className="tw-mr-1"
                />
                {rLIB('Est Approval Date')}:
              </Box>
              {capDateJSX}
            </Typography>
            <Typography variant="subtitle1">
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.primary_main }}
              >
                <Icon
                  icon="calendar-clock"
                  className="tw-mr-1"
                />
                {rLIB('Est Install Date')}:
              </Box>
              {installDateJSX}
            </Typography>
            <Typography variant="subtitle1">
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.primary_main }}
              >
                <Icon
                  icon="calendar-clock"
                  className="tw-mr-1"
                />
                {rLIB('Est Activation Date')}:
              </Box>
              {activationDateJSX}
            </Typography>
          </Box>
        </Card>
      </Box>
    )
  }
  return gridJSX
}

///////////////////////////////
// JSX Exports
///////////////////////////////

export const rJSX_SunrunProjectDetailsTab = (
  readOrWrite: 'read' | 'write',
  uc_RootData_ClientKey: any,
  uc_RootData_GlobalUser: any,
  additionalSalesforceData: any,
  ur_forceRerender: any,
  minimizedCustomerDetails: any,
  minimizedCustomerHappiness: any,
  minimizedEstimatedTimeline: any,
  minimizedFeedbackDetails: any,
  minimizedHomeDetails: any,
  minimizedMostRecentContact: any,
  minimizedProgressDetails: any,
  minimizedReminderDetails: any,
  minimizedProjectFinancialData: any,
  minimizedSalesPartnerSpecificData: any,
  minimizedProjectTimestampsData: any,
  minimizedStickyNote: any,
  minimizedSystemDetails: any,
  minimizedProjectTasks: any,
  projectFeedback: any,
  projectKey: any,
  projectOpenReminders: any,
  projectUsersFlatObject: any,
  refreshingData: any,
  refreshingProgressBar: any,
  rootProject: any,
  rootProjectLoaded: any,
  projectTasks: any,
  setMinimizedCustomerDetails: any,
  setMinimizedCustomerHappiness: any,
  setMinimizedEstimatedTimeline: any,
  setMinimizedFeedbackDetails: any,
  setMinimizedHomeDetails: any,
  setMinimizedMostRecentContact: any,
  setMinimizedProgressDetails: any,
  setMinimizedReminderDetails: any,
  setMinimizedProjectFinancialData: any,
  setMinimizedSalesPartnerSpecificData: any,
  setMinimizedProjectTimestampsData: any,
  setMinimizedStickyNote: any,
  setMinimizedSystemDetails: any,
  setMinimizedProjectTasks: any,
  setRefreshingData: any,
  setRefreshingProgressBar: any,
  uc_setRootData_ClientKey: any,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  uc_setUserInterface_CustomDialogDisplay: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  uc_setUserInterface_FormDialogDisplay: any,
): JSX.Element => {
  let tabJSX = <></>
  if (rootProjectLoaded === true) {
    tabJSX = (
      <Box>
        <Box className="tw-my-2">
          <Button
            className="tw-opacity-40 tw-mr-2"
            color="inherit"
            variant="outlined"
            onClick={() => {
              setMinimizedCustomerDetails(true)
              setMinimizedCustomerHappiness(true)
              setMinimizedFeedbackDetails(true)
              setMinimizedHomeDetails(true)
              setMinimizedMostRecentContact(true)
              setMinimizedProgressDetails(true)
              setMinimizedReminderDetails(true)
              setMinimizedSalesPartnerSpecificData(true)
              setMinimizedProjectFinancialData(true)
              setMinimizedProjectTimestampsData(true)
              setMinimizedStickyNote(true)
              setMinimizedSystemDetails(true)
              setMinimizedEstimatedTimeline(true)
              setMinimizedProjectTasks(true)
            }}
          >
            <Icon
              icon="square-caret-up"
              className="tw-mr-2"
            />
            {rLIB('Minimize All')}
          </Button>
          <Button
            className="tw-opacity-40"
            color="inherit"
            variant="outlined"
            onClick={() => {
              setMinimizedCustomerDetails(false)
              setMinimizedCustomerHappiness(false)
              setMinimizedFeedbackDetails(false)
              setMinimizedHomeDetails(false)
              setMinimizedMostRecentContact(false)
              setMinimizedProgressDetails(false)
              setMinimizedReminderDetails(false)
              setMinimizedSalesPartnerSpecificData(false)
              setMinimizedProjectFinancialData(false)
              setMinimizedProjectTimestampsData(false)
              setMinimizedStickyNote(false)
              setMinimizedSystemDetails(false)
              setMinimizedEstimatedTimeline(false)
              setMinimizedProjectTasks(false)
            }}
          >
            <Icon
              icon="square-caret-down"
              className="tw-mr-2"
            />
            {rLIB('Maximize All')}
          </Button>
        </Box>
        <Masonry
          columns={{ xs: 1, sm: 2, md: 3 }}
          spacing={2}
        >
          {rJSX_CustomerDetailsGrid(
            readOrWrite,
            minimizedCustomerDetails,
            setMinimizedCustomerDetails,
            uc_setUserInterface_FormDialogDisplay,
            uc_RootData_ClientKey,
            uc_setRootData_ClientKey,
            projectKey,
            uc_setUserInterface_ErrorDialogDisplay,
            rootProject,
          )}

          {rJSX_MostRecentContactGrid(readOrWrite, minimizedMostRecentContact, setMinimizedMostRecentContact, rootProject)}
          {rJSX_EstimatedTimelineGrid(
            readOrWrite,
            minimizedEstimatedTimeline,
            uc_setUserInterface_FormDialogDisplay,
            rootProject,
            uc_RootData_ClientKey,
            uc_setRootData_ClientKey,
            projectKey,
            uc_setUserInterface_ErrorDialogDisplay,
            setMinimizedEstimatedTimeline,
          )}

          {/* New Project Tasks Grid */}
          {rJSX_ProjectTasksGrid(projectTasks, minimizedProjectTasks, setMinimizedProjectTasks)}

          {rJSX_ProgressDetailsGrid(
            readOrWrite,
            minimizedProgressDetails,
            setMinimizedProgressDetails,
            refreshingProgressBar,
            rootProject,
            uc_setUserInterface_CustomDialogDisplay,
            setRefreshingProgressBar,
            uc_RootData_ClientKey,
            uc_setRootData_ClientKey,
            projectKey,
            ur_forceRerender,
          )}
          {rJSX_CustomerHappinesssGrid(
            readOrWrite,
            minimizedCustomerHappiness,
            setMinimizedCustomerHappiness,
            rootProject,
            additionalSalesforceData,
            uc_setUserInterface_ConfirmDialogDisplay,
            uc_RootData_ClientKey,
            uc_setRootData_ClientKey,
            projectKey,
            uc_setUserInterface_ErrorDialogDisplay,
          )}
          {rJSX_StickyNoteGrid(
            readOrWrite,
            minimizedStickyNote,
            setMinimizedStickyNote,
            uc_setUserInterface_FormDialogDisplay,
            rootProject,
            uc_RootData_ClientKey,
            uc_setRootData_ClientKey,
            uc_setUserInterface_ErrorDialogDisplay,
            projectKey,
          )}
          {rJSX_ReminderDetails(
            readOrWrite,
            minimizedReminderDetails,
            setMinimizedReminderDetails,
            uc_setUserInterface_FormDialogDisplay,
            uc_RootData_GlobalUser,
            projectKey,
            uc_RootData_ClientKey,
            uc_setRootData_ClientKey,
            projectOpenReminders,
            projectUsersFlatObject,
            uc_setUserInterface_ConfirmDialogDisplay,
            ur_forceRerender,
            uc_setUserInterface_ErrorDialogDisplay,
          )}
          {rJSX_CustomerFeedbackGrid(
            readOrWrite,
            minimizedFeedbackDetails,
            setMinimizedFeedbackDetails,
            uc_setUserInterface_FormDialogDisplay,
            uc_RootData_GlobalUser,
            projectKey,
            uc_RootData_ClientKey,
            uc_setRootData_ClientKey,
            projectFeedback,
          )}
          {rJSX_HomeDetailsGrid(
            readOrWrite,
            minimizedHomeDetails,
            setMinimizedHomeDetails,
            rootProject,
            additionalSalesforceData,
            uc_setUserInterface_FormDialogDisplay,
            uc_RootData_ClientKey,
            uc_setRootData_ClientKey,
            projectKey,
            uc_setUserInterface_ErrorDialogDisplay,
          )}
          {rJSX_SystemDetailsGrid(
            readOrWrite,
            minimizedSystemDetails,
            setMinimizedSystemDetails,
            uc_setUserInterface_FormDialogDisplay,
            rootProject,
            uc_RootData_ClientKey,
            uc_setRootData_ClientKey,
            projectKey,
            uc_setUserInterface_ErrorDialogDisplay,
            additionalSalesforceData,
          )}
          {rJSX_SalesforceTimestampsGrid(
            readOrWrite,
            minimizedProjectTimestampsData,
            setMinimizedProjectTimestampsData,
            uc_setUserInterface_FormDialogDisplay,
            rootProject,
            uc_RootData_ClientKey,
            uc_setRootData_ClientKey,
            projectKey,
            uc_setUserInterface_ErrorDialogDisplay,
            additionalSalesforceData,
          )}
          {rJSX_SunrunOtherDataGrid(
            readOrWrite,
            minimizedSalesPartnerSpecificData,
            setMinimizedSalesPartnerSpecificData,
            rootProject,
            additionalSalesforceData,
            refreshingData,
            uc_RootData_ClientKey,
            uc_setRootData_ClientKey,
            projectKey,
            uc_setUserInterface_CustomDialogDisplay,
            uc_setUserInterface_ErrorDialogDisplay,
            setRefreshingData,
            ur_forceRerender,
          )}
          {rJSX_SalesforceFinancialDataGrid(
            readOrWrite,
            minimizedProjectFinancialData,
            setMinimizedProjectFinancialData,
            rootProject,
            additionalSalesforceData,
          )}
        </Masonry>
      </Box>
    )
  } else {
    tabJSX = (
      <Box className="tw-text-center">
        <Grid2
          container
          spacing={2}
        >
          <Grid2
            xs={12}
            md={6}
            lg={4}
          >
            <Skeleton
              variant="rectangular"
              width={160}
              height={32}
              className="tw-my-2"
            />
            <Skeleton
              variant="rectangular"
              height={60}
              className="tw-mb-4"
            />
          </Grid2>
          <Grid2
            xs={12}
            md={6}
            lg={4}
          >
            <Skeleton
              variant="rectangular"
              width={160}
              height={32}
              className="tw-my-2"
            />
            <Skeleton
              variant="rectangular"
              height={60}
              className="tw-mb-4"
            />
          </Grid2>
          <Grid2
            xs={12}
            md={6}
            lg={4}
          >
            <Skeleton
              variant="rectangular"
              width={160}
              height={32}
              className="tw-my-2"
            />
            <Skeleton
              variant="rectangular"
              height={60}
              className="tw-mb-4"
            />
          </Grid2>
          <Grid2
            xs={12}
            md={6}
            lg={4}
          >
            <Skeleton
              variant="rectangular"
              width={160}
              height={32}
              className="tw-my-2"
            />
            <Skeleton
              variant="rectangular"
              height={60}
              className="tw-mb-4"
            />
          </Grid2>
          <Grid2
            xs={12}
            md={6}
            lg={4}
          >
            <Skeleton
              variant="rectangular"
              width={160}
              height={32}
              className="tw-my-2"
            />
            <Skeleton
              variant="rectangular"
              height={60}
              className="tw-mb-4"
            />
          </Grid2>
          <Grid2
            xs={12}
            md={6}
            lg={4}
          >
            <Skeleton
              variant="rectangular"
              width={160}
              height={32}
              className="tw-my-2"
            />
            <Skeleton
              variant="rectangular"
              height={60}
              className="tw-mb-4"
            />
          </Grid2>
          <Grid2
            xs={12}
            md={6}
            lg={4}
          >
            <Skeleton
              variant="rectangular"
              width={160}
              height={32}
              className="tw-my-2"
            />
            <Skeleton
              variant="rectangular"
              height={60}
              className="tw-mb-4"
            />
          </Grid2>
          <Grid2
            xs={12}
            md={6}
            lg={4}
          >
            <Skeleton
              variant="rectangular"
              width={160}
              height={32}
              className="tw-my-2"
            />
            <Skeleton
              variant="rectangular"
              height={60}
              className="tw-mb-4"
            />
          </Grid2>
          <Grid2
            xs={12}
            md={6}
            lg={4}
          >
            <Skeleton
              variant="rectangular"
              width={160}
              height={32}
              className="tw-my-2"
            />
            <Skeleton
              variant="rectangular"
              height={60}
              className="tw-mb-4"
            />
          </Grid2>
          <Grid2
            xs={12}
            md={6}
            lg={4}
          >
            <Skeleton
              variant="rectangular"
              width={160}
              height={32}
              className="tw-my-2"
            />
            <Skeleton
              variant="rectangular"
              height={60}
              className="tw-mb-4"
            />
          </Grid2>
          <Grid2
            xs={12}
            md={6}
            lg={4}
          >
            <Skeleton
              variant="rectangular"
              width={160}
              height={32}
              className="tw-my-2"
            />
            <Skeleton
              variant="rectangular"
              height={60}
              className="tw-mb-4"
            />
          </Grid2>
          <Grid2
            xs={12}
            md={6}
            lg={4}
          >
            <Skeleton
              variant="rectangular"
              width={160}
              height={32}
              className="tw-my-2"
            />
            <Skeleton
              variant="rectangular"
              height={60}
              className="tw-mb-4"
            />
          </Grid2>
        </Grid2>
      </Box>
    )
  }
  return tabJSX
}
