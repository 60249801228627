/* eslint-disable react/display-name */
///////////////////////////////
// Description
///////////////////////////////

/*
DESCRIPTION / USAGE:
example component description

TODO:

*/

///////////////////////////////
// Imports
///////////////////////////////
import { Box, Card, Stack, Tooltip, Typography } from '@mui/material'
import { rJSX_TaskTypeSelector } from 'app/models/tasks/global_tasks'
import { forwardRef, useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { returnClientUserRoles } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_ActiveTaskBlueprints_Query } from 'rfbp_aux/services/database_endpoints/directory/task_blueprints'
import { DatabaseRef_TaskFormsActive_Query } from 'rfbp_aux/services/database_endpoints/directory/task_forms'
import { DatabaseRef_ProjectTaskWorkflow_Document } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { DatabaseRef_AllProjectTasks_Query } from 'rfbp_aux/services/database_endpoints/operations/tasks'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableBasic,
  TableCellManage,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_FormDialog } from 'rfbp_core/services/context'
import { DatabaseGetCollection, DatabaseGetDocument, DatabaseGetLiveCollection } from 'rfbp_core/services/database_management'
import { arrayToObject, cloneObjectWithoutReference, getProp, objectToArray, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { createAdHocTask } from '../project_view/tab_tasks'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ProjectView_AdditionalWorkTab {
  projectKey: string
  readOrWrite: 'read' | 'write'
}

///////////////////////////////
// Variables
///////////////////////////////

// Tables
const tableColumns_SowItems: TsInterface_TableColumns = {
  manage0: TableCellManage({
    create_sow_task: {
      icon: <Icon icon="circle-plus" />,
      label: <>{rLIB('Create task')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        createAdHocTask(
          'sow',
          tableAdditionalData.projectKey,
          tableAdditionalData.projectTaskWorkflow,
          tableAdditionalData.projectTasks,
          tableAdditionalData.tempTaskOwnerRolesOptions,
          tableAdditionalData.tempTaskFormOptions,
          tableAdditionalData.uc_setUserInterface_FormDialogDisplay,
          rowData,
          getProp(rowData, 'key', null),
        )
      },
    },
  }),
  TEMP_create_status: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-w-4'
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (
          rowData != null &&
          rowData['key'] != null &&
          tableAdditionalData != null &&
          tableAdditionalData.associated_project_key != null &&
          tableAdditionalData['tasks'] != null &&
          tableAdditionalData['tasks'][tableAdditionalData.associated_project_key + '_sow_' + rowData['key']] != null
        ) {
          let associatedAdditionalWorkTask = tableAdditionalData['tasks'][tableAdditionalData.associated_project_key + '_sow_' + rowData['key']]
          if (associatedAdditionalWorkTask['status'] === 'completed') {
            cellJSX = (
              <Tooltip
                placement="top"
                title={rLIB('Additional Work Task Complete')}
              >
                <Box sx={{ color: themeVariables.info_main }}>
                  <Icon
                    size="xl"
                    icon="circle-check"
                  />
                </Box>
              </Tooltip>
            )
          } else if (associatedAdditionalWorkTask['status'] === 'deleted') {
            cellJSX = (
              <Tooltip
                placement="top"
                title={rLIB('Additional Work Task Deleted')}
              >
                <Box sx={{ color: themeVariables.gray_700 }}>
                  <Icon
                    size="xl"
                    icon="trash"
                  />
                </Box>
              </Tooltip>
            )
          } else {
            cellJSX = (
              <Tooltip
                placement="top"
                title={rLIB('Additional Work Task created but not completed')}
              >
                <Box sx={{ color: themeVariables.success_main }}>
                  <Icon
                    size="xl"
                    icon="circle-play"
                  />
                </Box>
              </Tooltip>
            )
          }
        } else {
          cellJSX = (
            <Tooltip
              placement="top"
              title={rLIB('No Task created for this Additional Work item')}
            >
              <Box sx={{ color: themeVariables.gray_500 }}>
                <Icon
                  size="xl"
                  icon="circle-pause"
                />
              </Box>
            </Tooltip>
          )
        }
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <></>
      },
      header_sort_by: null,
    },
  },
  name: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData != null && rowData['name'] != null) {
          if (
            rowData != null &&
            rowData['key'] != null &&
            tableAdditionalData != null &&
            tableAdditionalData.associated_project_key != null &&
            tableAdditionalData['tasks'] != null &&
            tableAdditionalData['tasks'][tableAdditionalData.associated_project_key + '_sow_' + rowData['key']] != null
          ) {
            let associatedAdditionalWorkTask = tableAdditionalData['tasks'][tableAdditionalData.associated_project_key + '_sow_' + rowData['key']]
            if (associatedAdditionalWorkTask['status'] === 'completed') {
              cellJSX = (
                <Box
                  className="tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
                  // sx={{ background: themeVariables.info_main }}
                  sx={{ background: themeVariables.warning_main }}
                >
                  {rowData.name}
                </Box>
              )
            } else if (associatedAdditionalWorkTask['status'] === 'deleted') {
              cellJSX = (
                <Box
                  className="tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
                  // sx={{ background: themeVariables.gray_700 }}
                  sx={{ background: themeVariables.warning_main }}
                >
                  {rowData.name}
                </Box>
              )
            } else {
              cellJSX = (
                <Box
                  className="tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
                  // sx={{ background: themeVariables.success_main }}
                  sx={{ background: themeVariables.warning_main }}
                >
                  {rowData.name}
                </Box>
              )
            }
          } else {
            cellJSX = (
              <Box
                className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
                sx={{ 'background': themeVariables.background_default, '&:hover': { background: themeVariables.background_json } }}
                onClick={() => {
                  if (tableAdditionalData.readOrWrite === 'write') {
                    if (
                      rowData != null &&
                      rowData.associated_task_group_key == null &&
                      tableAdditionalData != null &&
                      tableAdditionalData.us_additionalWorkTaskType != null
                    ) {
                      rowData['associated_task_group_key'] = tableAdditionalData.us_additionalWorkTaskType
                    }
                    createAdHocTask(
                      'sow',
                      tableAdditionalData.projectKey,
                      tableAdditionalData.projectTaskWorkflow,
                      tableAdditionalData.projectTasks,
                      tableAdditionalData.tempTaskOwnerRolesOptions,
                      tableAdditionalData.tempTaskFormOptions,
                      tableAdditionalData.uc_setUserInterface_FormDialogDisplay,
                      rowData,
                      getProp(rowData, 'key', null),
                    )
                  }
                }}
              >
                {rowData.name}
              </Box>
            )
          }
        }
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Additional Work Item')
      },
      header_sort_by: 'name',
    },
  },
  TEMP_start_time: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (
          rowData != null &&
          rowData['key'] != null &&
          tableAdditionalData != null &&
          tableAdditionalData.associated_project_key != null &&
          tableAdditionalData['tasks'] != null &&
          tableAdditionalData['tasks'][tableAdditionalData.associated_project_key + '_sow_' + rowData['key']] != null
        ) {
          let associatedAdditionalWorkTask = tableAdditionalData['tasks'][tableAdditionalData.associated_project_key + '_sow_' + rowData['key']]
          if (associatedAdditionalWorkTask.timestamp_created != null) {
            cellJSX = <>{returnFormattedDate(associatedAdditionalWorkTask.timestamp_created, 'D MMM YYYY h:mm a')}</>
          } else {
            cellJSX = <Box className="tw-opacity-30 tw-italic">{rLIB('Missing')}</Box>
          }
        } else {
          // cellJSX = <></>
        }
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Creation date')
      },
      header_sort_by: null,
    },
  },
  TEMP_completion_time: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (
          rowData != null &&
          rowData['key'] != null &&
          tableAdditionalData != null &&
          tableAdditionalData.associated_project_key != null &&
          tableAdditionalData['tasks'] != null &&
          tableAdditionalData['tasks'][tableAdditionalData.associated_project_key + '_sow_' + rowData['key']] != null
        ) {
          let associatedAdditionalWorkTask = tableAdditionalData['tasks'][tableAdditionalData.associated_project_key + '_sow_' + rowData['key']]
          if (associatedAdditionalWorkTask.timestamp_completed != null) {
            cellJSX = <>{returnFormattedDate(associatedAdditionalWorkTask.timestamp_completed, 'D MMM YYYY h:mm a')}</>
          } else {
            cellJSX = <Box className="tw-opacity-30 tw-italic">{rLIB('Missing')}</Box>
          }
        } else {
          // cellJSX = <></>
        }
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Completion date')
      },
      header_sort_by: null,
    },
  },
  manage: TableCellManage({
    create_sow_task: {
      icon: <Icon icon="circle-plus" />,
      label: <>{rLIB('Create task')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        createAdHocTask(
          'sow',
          tableAdditionalData.projectKey,
          tableAdditionalData.projectTaskWorkflow,
          tableAdditionalData.projectTasks,
          tableAdditionalData.tempTaskOwnerRolesOptions,
          tableAdditionalData.tempTaskFormOptions,
          tableAdditionalData.uc_setUserInterface_FormDialogDisplay,
          rowData,
          getProp(rowData, 'key', null),
        )
      },
    },
  }),
}

const tableColumns_SowItemsReadOnly: TsInterface_TableColumns = {
  TEMP_create_status: tableColumns_SowItems['TEMP_create_status'],
  name: tableColumns_SowItems['name'],
  TEMP_start_time: tableColumns_SowItems['TEMP_start_time'],
  TEMP_completion_time: tableColumns_SowItems['TEMP_completion_time'],
}

const tableSettings_SowItems: TsInterface_TableSettings = {
  paginated: true,
  pagination_rows_per_page_default: 100,
  pagination_rows_per_page_options: [10, 25, 50, 100],
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property_default: 'name',
  sortable: true,
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const ProjectViewAdditionalWorkTab = forwardRef((props: TsInterface_ProjectView_AdditionalWorkTab, ref: React.ForwardedRef<unknown>): JSX.Element => {
  // Props
  let pr_projectKey: TsInterface_ProjectView_AdditionalWorkTab['projectKey'] = getProp(props, 'projectKey', null)
  let pr_readOrWrite: TsInterface_ProjectView_AdditionalWorkTab['readOrWrite'] = getProp(props, 'readOrWrite', 'read')

  // Hooks - useContext, useState, useReducer, other
  const [us_activeBlueprintTasks, us_setActiveBlueprintTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_additionalWorkTaskType, us_setAdditionalWorkTaskType] = useState<null | string>(null)
  const [us_tempTaskFormOptions, us_setTempTaskFormOptions] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_tempTaskOwnerRolesOptions, us_setTempTaskOwnerRolesOptions] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_projectTaskWorkflow, us_setProjectTaskWorkflow] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectTasks, us_setProjectTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_reloadTaskWorkflow] = useState<number>(0)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)

  // Hooks - useEffect
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setActiveBlueprintTasks(newData)
      }
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveTaskBlueprints_Query(res_GCK.clientKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setProjectTasks(newData)
      }
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_AllProjectTasks_Query(res_GCK.clientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setProjectTasks({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey])

  useEffect(() => {
    // TEMP - Forms - only load if tasks can be created
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetCollection(DatabaseRef_TaskFormsActive_Query(res_GCK.clientKey, 'name', false, null, {}))
          .then((res_DGC) => {
            let taskFormOptions: TsInterface_UnspecifiedObject[] = []
            for (let loopFormKey in res_DGC.data) {
              let loopForm = res_DGC.data[loopFormKey]
              taskFormOptions.push({ key: loopForm.key, value: loopForm.name })
            }
            us_setTempTaskFormOptions(taskFormOptions)
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let clientUserRoles = returnClientUserRoles(res_GCK.clientKey)
        us_setTempTaskOwnerRolesOptions(objectToArray(clientUserRoles))
        ur_forceRerender()
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetDocument(DatabaseRef_ProjectTaskWorkflow_Document(res_GCK.clientKey, pr_projectKey))
          .then((res_DGD) => {
            us_setProjectTaskWorkflow(res_DGD.data)
            let taskWorkflowUserRolesList: TsInterface_UnspecifiedObject = {}
            let taskWorkflowUserRolesWithDirectOrScheduledTasksList: TsInterface_UnspecifiedObject = {}
            if (res_DGD.data != null && res_DGD.data['tasks'] != null) {
              for (let loopTaskKey in res_DGD.data['tasks']) {
                let loopTask = res_DGD.data['tasks'][loopTaskKey]
                if (us_reloadTaskWorkflow >= 0) {
                  // Nothing - just used for reloads
                }
                if (loopTask != null && loopTask['associated_owner_type'] != null) {
                  taskWorkflowUserRolesList[loopTask['associated_owner_type']] = loopTask['associated_owner_type']
                  if (loopTask['task_completion_type'] === 'direct' || loopTask['task_completion_type'] === 'dispatcher') {
                    taskWorkflowUserRolesWithDirectOrScheduledTasksList[loopTask['associated_owner_type']] = loopTask['associated_owner_type']
                  }
                }
              }
            }
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [uc_RootData_ClientKey, pr_projectKey, uc_setRootData_ClientKey, us_reloadTaskWorkflow])

  // Functions
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)

  // JSX Generation
  const rJSX_AdditionalWorkTabV1 = (): JSX.Element => {
    let tabJSX = <></>
    if (us_projectTaskWorkflow != null && us_projectTaskWorkflow['sow_items'] != null && objectToArray(us_projectTaskWorkflow['sow_items']).length > 0) {
      let sowTableColumns = tableColumns_SowItemsReadOnly
      if (pr_projectKey === 'write') {
        sowTableColumns = tableColumns_SowItems
      }
      tabJSX = (
        <Card className="">
          <TableBasic
            tableAdditionalData={{
              readOrWrite: pr_readOrWrite,
              associated_project_key: pr_projectKey,
              task_groups: arrayToObject(us_projectTaskWorkflow.task_groups, 'key'),
              tasks: us_projectTasks,
              projectKey: pr_projectKey,
              projectTaskWorkflow: us_projectTaskWorkflow,
              projectTasks: us_projectTasks,
              tempTaskOwnerRolesOptions: us_tempTaskOwnerRolesOptions,
              tempTaskFormOptions: us_tempTaskFormOptions,
              uc_setUserInterface_FormDialogDisplay: uc_setUserInterface_FormDialogDisplay,
            }}
            tableColumns={sowTableColumns}
            tableData={objectToArray(us_projectTaskWorkflow['sow_items'])}
            tableSettings={tableSettings_SowItems}
          />
        </Card>
      )
    } else {
      tabJSX = (
        <Box className="tw-text-center tw-mt-4">
          <Typography
            variant="h6"
            className="tw-opacity-30 tw-italic"
          >
            {rLIB('No Additional Work Items defined on selected workflow')}
          </Typography>
        </Box>
      )
    }
    return tabJSX
  }

  const mergeWorkflowWithTaskBlueprint = (workflowTask: TsInterface_UnspecifiedObject, blueprintTask: TsInterface_UnspecifiedObject) => {
    const mergedObj = cloneObjectWithoutReference(blueprintTask)
    for (const key in workflowTask) {
      // eslint-disable-next-line no-prototype-builtins
      if (workflowTask.hasOwnProperty(key)) {
        mergedObj[key] = workflowTask[key]
      }
    }
    return mergedObj
  }

  const returnCombinedTableData = (
    blueprintTasks: TsInterface_UnspecifiedObject[],
    workflowAdditionalWorkTasks: TsInterface_UnspecifiedObject,
  ): TsInterface_UnspecifiedObject[] => {
    let allTasksObject: TsInterface_UnspecifiedObject = {}
    for (let loopBlueprintTaskIndex in blueprintTasks) {
      let loopBlueprintTask = blueprintTasks[loopBlueprintTaskIndex]
      if (loopBlueprintTask != null && loopBlueprintTask['key'] != null) {
        let workflowTask = getProp(workflowAdditionalWorkTasks, loopBlueprintTask['key'], null)
        // Combine Blueprint with Workflow level overrides
        let mergedTask = mergeWorkflowWithTaskBlueprint({ ...workflowTask }, loopBlueprintTask)
        allTasksObject[loopBlueprintTask['key']] = mergedTask
      }
    }
    return objectToArray(allTasksObject)
  }

  const rJSX_AdditionalWorkTabV2 = (): JSX.Element => {
    let tabJSX = <></>
    let sowTableColumns = tableColumns_SowItemsReadOnly
    if (pr_projectKey === 'write') {
      sowTableColumns = tableColumns_SowItems
    }
    if (us_additionalWorkTaskType == null) {
      tabJSX = (
        <Box className="tw-py-4 tw-text-center tw-opacity-50 tw-mb-2">
          <Typography variant="h6">{rLIB('Select a task type')}</Typography>
          {rJSX_TaskTypeSelector(us_additionalWorkTaskType, us_setAdditionalWorkTaskType, 'additional_work', 'project')}
        </Box>
      )
    } else {
      tabJSX = (
        <Box>
          <Stack
            direction="row"
            spacing={1}
            className="tw-mb-2"
          >
            {rJSX_TaskTypeSelector(us_additionalWorkTaskType, us_setAdditionalWorkTaskType, 'additional_work', 'project')}
          </Stack>
          <Card>
            <TableBasic
              tableAdditionalData={{
                readOrWrite: pr_readOrWrite,
                associated_project_key: pr_projectKey,
                task_groups: arrayToObject(us_projectTaskWorkflow.task_groups, 'key'),
                tasks: us_projectTasks,
                projectKey: pr_projectKey,
                projectTaskWorkflow: us_projectTaskWorkflow,
                projectTasks: us_projectTasks,
                tempTaskOwnerRolesOptions: us_tempTaskOwnerRolesOptions,
                tempTaskFormOptions: us_tempTaskFormOptions,
                uc_setUserInterface_FormDialogDisplay: uc_setUserInterface_FormDialogDisplay,
                us_additionalWorkTaskType: us_additionalWorkTaskType,
              }}
              tableColumns={sowTableColumns}
              tableData={returnCombinedTableData(
                objectToArray(us_activeBlueprintTasks).filter((task: TsInterface_UnspecifiedObject) => task.task_type === us_additionalWorkTaskType),
                us_projectTaskWorkflow.additional_work_tasks,
              )}
              tableSettings={tableSettings_SowItems}
            />
          </Card>
        </Box>
      )
    }
    return tabJSX
  }

  const rJSX_AdditionalWorkTab = (): JSX.Element => {
    let tabJSX = <></>
    if (us_projectTaskWorkflow.workflow_version_number === 2) {
      tabJSX = rJSX_AdditionalWorkTabV2()
    } else {
      tabJSX = rJSX_AdditionalWorkTabV1()
    }
    return tabJSX
  }

  const rJSX_Tab = (): JSX.Element => {
    let tabJSX = <Box className="tw-m-auto">{rJSX_AdditionalWorkTab()}</Box>
    return tabJSX
  }

  // Render

  return <>{rJSX_Tab()}</>
})
