//======================================//
//		  ooOOOO USED BY BOILERPLATE    //
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//======================================//

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			1) Defines Client Types, Application Pages, and Application Navigation
			2) Provides functions to generate navigation and permissions dynamically from specified variables

			ApplicationNavPages ACCESS LEVELS
				always_yes
				default_yes
				default_no
				always_no
				true
				false

			ADDING A NEW PAGE
				1) Create file in containers folder
				2) Import page into "App.tsx"
				3) Add Route into the Router in the "App.tsx" at the bottom
				4) Add Page into the "ApplicationPages" variable of "applicationStructure.tsx" (this file)
				5) IF the page is a root level page, add it to ApplicationNavPages (and ApplicationNavSections if it is in a new section) in "applicationStructure.tsx" (this file)

		TODO:
			[ ] Typescript - might need to import or export interfaces to other files to avoid duplicates?

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { themeVariables } from 'rfbp_aux/config/app_theme'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { TsInterface_RootData_ClientPermissions, TsInterface_RootData_ClientUser, TsInterface_RootData_GlobalUser } from 'rfbp_core/services/context'
import { getProp, returnFormattedDateKey } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

type TsType_PageAccess = 'always_yes' | 'default_yes' | 'default_no' | 'always_no' | boolean | null

export type TsType_ClientTypes = 'Development' | 'Installer' | 'Sales'
export type TsType_UserRoles = 'admin' | 'customer' | 'sales_rep' | 'sales_manager' | 'css_rep' | 'css_manager'

interface TsInterface_PageRoleAccessPermissionsObject {
  access: TsType_PageAccess
  highlighted_nav_section: string
  highlighted_nav_page: string
}

type TsType_ApplicationPageUrlOneParam = (singleParam?: string) => string
type TsType_ApplicationPageUrlTwoParams = (paramOne?: string, paramTwo?: string) => string
type TsType_ApplicationPageUrlThreeParams = (paramOne?: string, paramTwo?: string, paramThree?: string) => string

interface TsInterface_ApplicationPages {
  [key: string]: {
    key: string
    root_nav_page_permission: string
    url: TsType_ApplicationPageUrlOneParam | TsType_ApplicationPageUrlTwoParams | TsType_ApplicationPageUrlThreeParams
  }
}

interface TsInterface_ClientTypes {
  [key: string]: {
    key: TsType_ClientTypes
    name: string | JSX.Element
    user_roles: {
      [key: string]: {
        key: TsType_UserRoles
        name: string | JSX.Element
        icon: JSX.Element
        home_redirect_page?: any
        desktop_access?: boolean
      }
    }
  }
}

//=========================//
//   USED BY BOILERPLATE   //
//=========================//
export interface TsInterface_NavPage {
  name: JSX.Element
  key: string
  icon: JSX.Element
  icon_large?: JSX.Element
  url: string
  page_role_access_permissions: {
    [key: string]: TsInterface_PageRoleAccessPermissionsObject
  }
  nav_badges: {
    [key: string]: boolean
  }
}

interface TsInterface_ApplicationNavPages {
  [key: string]: TsInterface_NavPage
}

interface TsInterface_ApplicationNavSections {
  [key: string]: {
    name: JSX.Element
    key: string
    links: TsInterface_ApplicationNavPages
  }
}

interface TsInterface_SideBarNavObject {
  [key: string]: {
    name: JSX.Element
    links: {
      [key: string]: TsInterface_NavPage
    }
  }
}

interface TsInterface_FlatUserPermissions {
  [key: string]: {
    key: string
    access: TsType_PageAccess
  }
}

interface TsInterface_AvailableUserTypePermissionsObject {
  [key: string]: {
    sectionKey: string
    sectionName: JSX.Element
    permissions: {
      [key: string]: {
        access: TsType_PageAccess
        permissionKey: string
        pageName: JSX.Element
      }
    }
  }
}

interface TsInterface_AvailableClientTypePermissionsObject {
  [clientTypeKey: string]: {
    [sectionKey: string]: {
      sectionKey: string
      sectionName: JSX.Element
      permissions: {
        [key: string]: {
          key: string
          pageName: JSX.Element
        }
      }
    }
  }
}

interface TsInterface_PermissionObject {
  [permissionKey: string]: boolean
}

interface TsInterface_GenerateActiveUserApplicationPermissionsResult {
  success: boolean
  permissions: TsInterface_PermissionObject
  error: object
}

//=========================//
//   USED BY BOILERPLATE   //
//=========================//
export interface TsInterface_NavigationObject {
  [sectionKey: string]: {
    key: string
    name: JSX.Element
    links: {
      [linkKey: string]: {
        icon: JSX.Element
        icon_large?: JSX.Element
        key: string
        name: JSX.Element
        page_role_access_permissions: {
          [roleKey: string]: TsInterface_PageRoleAccessPermissionsObject
        }
        // url(): string
        url: string
      }
    }
  }
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

//=========================//
//   USED BY BOILERPLATE   //
//=========================//

// All Pages URLs
export const ApplicationPages: TsInterface_ApplicationPages = {
  ///////////////////////////////
  // Home / Nav Pages
  ///////////////////////////////
  HomePage: {
    key: 'HomePage',
    root_nav_page_permission: 'HomePage',
    url: () => '/home',
  },
  AllAlertsListPage: {
    key: 'AllAlertsListPage',
    root_nav_page_permission: 'HomePage',
    url: () => '/a/alerts',
  },
  UserSettingsPage: {
    key: 'UserSettingsPage',
    root_nav_page_permission: 'UserSettingsPage',
    url: () => '/settings',
  },
  ///////////////////////////////
  // Projects
  ///////////////////////////////
  /* Tasks */
  AllAssignedTasksListPage: {
    key: 'AllAssignedTasksListPage',
    root_nav_page_permission: 'AllAssignedTasksListPage',
    url: () => '/a/tasks',
  },
  /* Projects */
  AdminActiveProjectsListPage: {
    key: 'AdminActiveProjectsListPage',
    root_nav_page_permission: 'AdminActiveProjectsListPage',
    url: () => '/a/active_projects',
  },
  AdminActiveProjectMergePage: {
    key: 'AdminActiveProjectMergePage',
    root_nav_page_permission: 'AdminActiveProjectsListPage',
    url: (id: string | undefined) => '/a/merge_projects/' + id,
  },
  AdminActiveProjectUrlSearchPage: {
    key: 'AdminActiveProjectUrlSearchPage',
    root_nav_page_permission: 'AdminActiveProjectsListPage',
    url: (id: string | undefined) => '/a/active_projects/s/' + id,
  },
  AdminActiveProjectViewPage: {
    key: 'AdminActiveProjectViewPage',
    root_nav_page_permission: 'AdminActiveProjectsListPage',
    url: (id: string | undefined) => '/a/active_projects/' + id,
  },
  AuthenticatedSalesOpportunityWelcomePage: {
    key: 'AuthenticatedSalesOpportunityWelcomePage',
    root_nav_page_permission: 'AuthenticatedSalesOpportunityWelcomePage',
    url: () => '/welcome',
  },
  LiveReviewIndexPage: {
    key: 'LiveReviewIndexPage',
    root_nav_page_permission: 'AdminActiveProjectsListPage',
    url: () => '/a/live_review/',
  },
  ProjectFinanceViewPage: {
    key: 'ProjectFinanceViewPage',
    root_nav_page_permission: 'AdminActiveProjectsListPage',
    url: (id: string | undefined) => '/a/active_projects/' + id + '/finance',
  },

  TaskDispatchBookmarkPage: {
    key: 'TaskDispatchBookmarkPage',
    root_nav_page_permission: 'AdminActiveProjectsListPage',
    url: (date: string | undefined) => '/a/today',
  },
  /* Scheduling */
  TaskCalendarSchedulePage: {
    key: 'TaskCalendarSchedulePage',
    root_nav_page_permission: 'TaskCalendarSchedulePage',
    url: (date: string | undefined) => '/a/scheduling/' + date,
  },
  /* Stats */
  AdminStatsListPage: {
    key: 'AdminStatsListPage',
    root_nav_page_permission: 'AdminStatsListPage',
    url: () => '/a/stats',
  },
  /* Project Imports */
  AdminProjectManualImportsPage: {
    key: 'AdminProjectManualImportsPage',
    root_nav_page_permission: 'AdminProjectManualImportsPage',
    url: () => '/a/import_projects',
  },
  /* Pacing */
  AdminProjectPacingPage: {
    key: 'AdminProjectPacingPage',
    root_nav_page_permission: 'AdminProjectPacingPage',
    url: () => '/a/pacing',
  },
  /* Materials */
  AdminDatabaseMaterialsIndexPage: {
    key: 'AdminDatabaseMaterialsIndexPage',
    root_nav_page_permission: 'AdminDatabaseMaterialsIndexPage',
    url: () => '/a/database/materials',
  },
  AdminDatabaseMaterialsOrderViewPage: {
    key: 'AdminDatabaseMaterialsOrderViewPage',
    root_nav_page_permission: 'AdminDatabaseMaterialsIndexPage',
    url: (id: string | undefined) => '/a/database/materials/orders/' + id,
  },
  AdminDatabaseMaterialsSupplierViewPage: {
    key: 'AdminDatabaseMaterialsSupplierViewPage',
    root_nav_page_permission: 'AdminDatabaseMaterialsIndexPage',
    url: (id: string | undefined) => '/a/database/materials/suppliers/' + id,
  },

  ///////////////////////////////
  // Timesheet
  ///////////////////////////////
  /* Time Sheets */
  AllTimesheetsIndexPage: {
    key: 'AllTimesheetsIndexPage',
    root_nav_page_permission: 'AllTimesheetsIndexPage',
    url: () => '/a/timesheets',
  },
  /* GM Dashboard */
  GMDashboardIndexPage: {
    key: 'GMDashboardIndexPage',
    root_nav_page_permission: 'GMDashboardIndexPage',
    url: () => '/a/gm_dashboard',
  },

  PerformanceManagementPage: {
    key: 'PerformanceManagementPage',
    root_nav_page_permission: 'PerformanceManagementPage',
    url: () => '/a/performance_management',
  },

  /* */
  TaskEfficiencyPage: {
    key: 'TaskEfficiencyPage',
    root_nav_page_permission: 'TaskEfficiencyPage',
    url: () => '/a/task_efficiency',
  },
  ///////////////////////////////
  // Sales
  ///////////////////////////////
  /* Finance Partners */
  AdminDatabaseFinancePartnersListPage: {
    key: 'AdminDatabaseFinancePartnersListPage',
    root_nav_page_permission: 'AdminDatabaseFinancePartnersListPage',
    url: () => '/a/database/finance_partners',
  },
  /* Sales Partners */
  AdminDatabaseSalesPartnersListPage: {
    key: 'AdminDatabaseSalesPartnersListPage',
    root_nav_page_permission: 'AdminDatabaseSalesPartnersListPage',
    url: () => '/a/database/sales_partners',
  },
  SalesHierarchyImportPage: {
    key: 'SalesHierarchyImportPage',
    root_nav_page_permission: 'AdminDatabaseSalesPartnersListPage',
    url: () => '/a/database/sales_hierarchy_import',
  },
  //Lead Sources
  LeadSourcesIndexPage: {
    key: 'LeadSourcesIndexPage',
    root_nav_page_permission: 'LeadSourcesIndexPage',
    url: () => '/a/lead_sources',
  },
  //Design
  DesignIndexPage: {
    key: 'DesignIndexPage',
    root_nav_page_permission: 'DesignIndexPage',
    url: () => '/a/design',
  },
  /* Sales Opportunities */
  SalesOpportunitiesIndexPage: {
    key: 'SalesOpportunitiesIndexPage',
    root_nav_page_permission: 'SalesOpportunitiesIndexPage',
    url: () => '/a/sales_opportunities',
  },
  SalesOpportunityPage: {
    key: 'SalesOpportunityPage',
    root_nav_page_permission: 'SalesOpportunitiesIndexPage',
    url: (id: string | undefined) => '/a/sales_opportunity/' + id,
  },
  /* Sales Tools */
  AdminSalesToolsIndexPage: {
    key: 'AdminSalesToolsIndexPage',
    root_nav_page_permission: 'AdminSalesToolsIndexPage',
    url: () => '/a/database/sales_tools',
  },
  SalesOpportunityViewPage: {
    key: 'SalesOpportunityViewPage',
    root_nav_page_permission: 'AdminSalesToolsIndexPage',
    url: (id: string | undefined) => '/a/sales_opportunities/' + id,
  },
  SalesOpportunityDiscoverySessionViewPage: {
    key: 'SalesOpportunityDiscoverySessionViewPage',
    root_nav_page_permission: 'AdminSalesToolsIndexPage',
    url: (id: string | undefined) => '/a/sales_campaigns/' + id,
  },
  SalesDoorhangerRoutePage: {
    key: 'SalesDoorhangerRoutePage',
    root_nav_page_permission: 'AdminSalesToolsIndexPage',
    url: (id: string | undefined) => '/a/sales_paper_route/' + id,
  },
  AdminSalesOpportunityPage: {
    key: 'AdminSalesOpportunityPage',
    root_nav_page_permission: 'AdminSalesToolsIndexPage',
    url: (id: string | undefined) => '/a/go_solar/' + id,
  },
  ///////////////////////////////
  // Fleet
  ///////////////////////////////
  /* Vehicles */
  AdminDatabaseVehiclesListPage: {
    key: 'AdminDatabaseVehiclesListPage',
    root_nav_page_permission: 'AdminDatabaseVehiclesListPage',
    url: () => '/a/database/vehicles',
  },
  AdminDatabaseVehicleViewPage: {
    key: 'AdminDatabaseVehicleViewPage',
    root_nav_page_permission: 'AdminDatabaseVehiclesListPage',
    url: (id: string | undefined) => '/a/database/vehicles/' + id,
  },
  AdminDatabaseTrailerViewPage: {
    key: 'AdminDatabaseTrailerViewPage',
    root_nav_page_permission: 'AdminDatabaseVehiclesListPage',
    url: (id: string | undefined) => '/a/database/trailers/' + id,
  },

  AdminDatabaseRentalViewPage: {
    key: 'AdminDatabaseRentalViewPage',
    root_nav_page_permission: 'AdminDatabaseVehiclesListPage',
    url: (id: string | undefined) => '/a/database/rentals/' + id,
  },
  AdminDatabaseVehicleUsageReportsPage: {
    key: 'AdminDatabaseVehicleUsageReportsPage',
    root_nav_page_permission: 'AdminDatabaseVehiclesListPage',
    url: () => '/a/database/vehicle_usage_reports',
  },
  AdminDatabaseFuelUseUserViewPage: {
    key: 'AdminDatabaseFuelUseUserViewPage',
    root_nav_page_permission: 'AdminDatabaseVehiclesListPage',
    url: (id: string | undefined) => '/a/database/vehicle_usage_reports/user/' + id,
  },
  /* Incident Reports */
  AdminDatabaseIncidentReportsListPage: {
    key: 'AdminDatabaseIncidentReportsListPage',
    root_nav_page_permission: 'AdminDatabaseIncidentReportsListPage',
    url: () => '/a/database/incident_reports',
  },
  AdminDatabaseIncidentReportViewPage: {
    key: 'AdminDatabaseIncidentReportViewPage',
    root_nav_page_permission: 'AdminDatabaseIncidentReportsListPage',
    url: (id: string | undefined) => '/a/database/incident_reports/' + id,
  },
  ///////////////////////////////
  // Accounting
  ///////////////////////////////
  /* Leaderboard */
  AdminDatabaseLeaderboardIndexPage: {
    key: 'AdminDatabaseLeaderboardIndexPage',
    root_nav_page_permission: 'AdminDatabaseLeaderboardIndexPage',
    url: () => '/a/database/leaderboard',
  },
  TempNPSEditPage: {
    key: 'TempNPSEditPage',
    root_nav_page_permission: 'AdminDatabaseLeaderboardIndexPage',
    url: () => '/temp_nps_edit',
  },
  /* Payroll */
  AdminPayrollIndexPage: {
    key: 'AdminPayrollIndexPage',
    root_nav_page_permission: 'AdminPayrollIndexPage',
    url: () => '/a/payroll',
  },
  AdminPayrollSettingsPage: {
    key: 'AdminPayrollSettingsPage',
    root_nav_page_permission: 'AdminPayrollIndexPage',
    url: () => '/a/payroll/settings',
  },
  CombineManagementPage: {
    key: 'CombineManagementPage',
    root_nav_page_permission: 'AdminPayrollIndexPage',
    url: () => '/a/combine_management',
  },
  /* Spending */
  AdminFinanceSpendingIndexPage: {
    key: 'AdminFinanceSpendingIndexPage',
    root_nav_page_permission: 'AdminFinanceSpendingIndexPage',
    url: () => '/a/spending',
  },
  AdminFinanceSpendingCardholdersViewPage: {
    key: 'AdminFinanceSpendingCardholdersViewPage',
    root_nav_page_permission: 'AdminFinanceSpendingIndexPage',
    url: (id: string | undefined) => '/a/spending/cardholders/' + id,
  },
  AdminFinanceSpendingProfileViewPage: {
    key: 'AdminFinanceSpendingProfileViewPage',
    root_nav_page_permission: 'AdminFinanceSpendingIndexPage',
    url: (id: string | undefined) => '/a/spending/profiles/' + id,
  },
  /* Invoices */
  AdminInvoiceIndexPage: {
    key: 'AdminInvoiceIndexPage',
    root_nav_page_permission: 'AdminInvoiceIndexPage',
    url: () => '/a/invoicing',
  },
  AdminInvoiceSettingsPage: {
    key: 'AdminInvoiceSettingsPage',
    root_nav_page_permission: 'AdminInvoiceIndexPage',
    url: () => '/a/invoicing/settings',
  },

  ///////////////////////////////
  // Entities
  ///////////////////////////////
  /* HOAs */
  AdminDatabaseHoasListPage: {
    key: 'AdminDatabaseHoasListPage',
    root_nav_page_permission: 'AdminDatabaseHoasListPage',
    url: () => '/a/database/hoas',
  },
  AdminDatabaseHoaViewPage: {
    key: 'AdminDatabaseHoaViewPage',
    root_nav_page_permission: 'AdminDatabaseHoasListPage',
    url: (id: string | undefined) => '/a/database/hoas/' + id,
  },
  /* Jurisdictions */
  AdminDatabaseJurisdictionsListPage: {
    key: 'AdminDatabaseJurisdictionsListPage',
    root_nav_page_permission: 'AdminDatabaseJurisdictionsListPage',
    url: () => '/a/database/jurisdictions',
  },
  AdminDatabaseJurisdictionViewPage: {
    key: 'AdminDatabaseJurisdictionViewPage',
    root_nav_page_permission: 'AdminDatabaseJurisdictionsListPage',
    url: (id: string | undefined) => '/a/database/jurisdictions/' + id,
  },
  /* Regions */
  AdminDatabaseRegionsListPage: {
    key: 'AdminDatabaseRegionsListPage',
    root_nav_page_permission: 'AdminDatabaseRegionsListPage',
    url: () => '/a/database/regions',
  },
  /* Utilities */
  AdminDatabaseUtilitiesListPage: {
    key: 'AdminDatabaseUtilitiesListPage',
    root_nav_page_permission: 'AdminDatabaseUtilitiesListPage',
    url: () => '/a/database/utilities',
  },
  AdminDatabaseUtilityViewPage: {
    key: 'AdminDatabaseUtilityViewPage',
    root_nav_page_permission: 'AdminDatabaseUtilitiesListPage',
    url: (id: string | undefined) => '/a/database/utilities/' + id,
  },

  ///////////////////////////////
  // Task Settings
  ///////////////////////////////
  /* Task Library */
  AdminDatabaseTasksListPage: {
    key: 'AdminDatabaseTasksListPage',
    root_nav_page_permission: 'AdminDatabaseTasksListPage',
    url: () => '/a/database/tasks',
  },
  /* Task Forms */
  AdminDatabaseTaskFormsListPage: {
    key: 'AdminDatabaseTaskFormsListPage',
    root_nav_page_permission: 'AdminDatabaseTaskFormsListPage',
    url: () => '/a/database/task_forms',
  },
  AdminDatabaseTaskFormsViewPage: {
    key: 'AdminDatabaseTaskFormsViewPage',
    root_nav_page_permission: 'AdminDatabaseTaskFormsListPage',
    url: (id: string | undefined) => '/a/database/task_forms/' + id,
  },
  /* Task Workflow */
  AdminDatabaseTaskWorkflowListPage: {
    key: 'AdminDatabaseTaskWorkflowListPage',
    root_nav_page_permission: 'AdminDatabaseTaskWorkflowListPage',
    url: () => '/a/database/task_workflows',
  },
  AdminDatabaseTaskWorkflowViewPage: {
    key: 'AdminDatabaseTaskWorkflowViewPage',
    root_nav_page_permission: 'AdminDatabaseTaskWorkflowListPage',
    url: (id: string | undefined) => '/a/database/task_workflows/' + id,
  },

  AdminOutboundCommunicationIndexPage: {
    key: 'AdminOutboundCommunicationIndexPage',
    root_nav_page_permission: 'AdminOutboundCommunicationIndexPage',
    url: () => '/a/database/outbound_communication/',
  },

  ///////////////////////////////
  // Employees
  ///////////////////////////////
  /* Company Documents */
  AdminDatabaseCompanyDocumentsPage: {
    key: 'AdminDatabaseCompanyDocumentsPage',
    root_nav_page_permission: 'AdminDatabaseCompanyDocumentsPage',
    url: () => '/a/database/company_documents',
  },
  /* Tools */
  AdminDatabaseToolsListPage: {
    key: 'AdminDatabaseToolsListPage',
    root_nav_page_permission: 'AdminDatabaseToolsListPage',
    url: () => '/a/database/tools',
  },
  AdminDatabaseToolViewPage: {
    key: 'AdminDatabaseToolViewPage',
    root_nav_page_permission: 'AdminDatabaseToolsListPage',
    url: (id: string | undefined) => '/a/database/tools/' + id,
  },
  /* Users */
  AdminDatabaseUserListPage: {
    key: 'AdminDatabaseUserListPage',
    root_nav_page_permission: 'AdminDatabaseUserListPage',
    url: () => '/a/database/users',
  },

  AdminDatabaseTeamsListPage: {
    key: 'AdminDatabaseTeamsListPage',
    root_nav_page_permission: 'AdminDatabaseTeamsListPage',
    url: () => '/a/database/teams',
  },
  AdminDatabaseTeamViewPage: {
    key: 'AdminDatabaseTeamViewPage',
    root_nav_page_permission: 'AdminDatabaseTeamsListPage',
    url: (id: string | undefined) => '/a/database/teams/view/' + id,
  },

  AdminDatabaseUserNewPage: {
    key: 'AdminDatabaseUserNewPage',
    root_nav_page_permission: 'AdminDatabaseUserListPage',
    url: () => '/a/database/users/new',
  },
  AdminDatabaseUserEditPage: {
    key: 'AdminDatabaseUserEditPage',
    root_nav_page_permission: 'AdminDatabaseUserListPage',
    url: (id: string | undefined) => '/a/database/users/edit/' + id,
  },
  AdminDatabaseUserViewPage: {
    key: 'AdminDatabaseUserViewPage',
    root_nav_page_permission: 'AdminDatabaseUserListPage',
    url: (id: string | undefined) => '/a/database/users/view/' + id,
  },
  ///////////////////////////////
  // Tech
  ///////////////////////////////
  /* API Management */
  AdminDatabaseAPIManagementIndexPage: {
    key: 'AdminDatabaseAPIManagementIndexPage',
    root_nav_page_permission: 'AdminDatabaseAPIManagementIndexPage',
    url: () => '/a/database/api_management',
  },
  SunrunApiPage: {
    key: 'SunrunApiPage',
    root_nav_page_permission: 'AdminDatabaseAPIManagementIndexPage',
    url: () => '/sunrun_api/',
  },
  SearchManagementIndexPage: {
    key: 'SearchManagementIndexPage',
    root_nav_page_permission: 'AdminDatabaseAPIManagementIndexPage',
    url: () => '/a/database/search_management/',
  },
  /* Auto Data Imports */
  AutomaticDataImportPages: {
    key: 'AutomaticDataImportPages',
    root_nav_page_permission: 'AutomaticDataImportPages',
    url: () => '/a/auto_data_imports',
  },

  /* Issue Tracker */
  IssueTrackerIndexPage: {
    key: 'IssueTrackerIndexPage',
    root_nav_page_permission: 'IssueTrackerIndexPage',
    url: () => '/a/issue_tracker',
  },
  /* Cronjobs */
  AdminCronjobManagementIndexPage: {
    key: 'AdminCronjobManagementIndexPage',
    root_nav_page_permission: 'AdminCronjobManagementIndexPage',
    url: () => '/a/cronjobs',
  },
  /* Machine Learning */
  MachineLearningIndexPage: {
    key: 'MachineLearningIndexPage',
    root_nav_page_permission: 'MachineLearningIndexPage',
    url: () => '/a/ml',
  },
  MLEvaluationProcedureViewPage: {
    key: 'MLEvaluationProcedureViewPage',
    root_nav_page_permission: 'MachineLearningIndexPage',
    url: (id: string | undefined) => '/a/ml/evaluation_procedures/' + id,
  },
  ///////////////////////////////
  // Sales Portal
  ///////////////////////////////
  SalesProjectViewPage: {
    key: 'SalesProjectViewPage',
    root_nav_page_permission: 'SalesProjectsListPage',
    url: (id: string | undefined) => '/s/active_projects/' + id,
  },
  SalesProjectsListPage: {
    key: 'SalesProjectsListPage',
    root_nav_page_permission: 'SalesProjectsListPage',
    url: () => '/s/active_projects',
  },
  SalesTasksListPage: {
    key: 'SalesTasksListPage',
    root_nav_page_permission: 'SalesTasksListPage',
    url: () => '/s/tasks',
  },
  ///////////////////////////////
  // SubK Portal
  ///////////////////////////////
  SubKActiveTasksListPage: {
    key: 'SubKActiveTasksListPage',
    root_nav_page_permission: 'SubKActiveTasksListPage',
    url: () => '/k/tasks',
  },
  SubKProjectViewPage: {
    key: 'SubKProjectViewPage',
    root_nav_page_permission: 'SubKActiveTasksListPage',
    url: (id: string | undefined) => '/k/active_projects/' + id,
  },
  ///////////////////////////////
  // Super
  ///////////////////////////////
  SuperClientPermissionsManagementPage: {
    key: 'SuperClientPermissionsManagementPage',
    root_nav_page_permission: 'SuperClientPermissionsManagementPage',
    url: () => '/super/client_permissions/',
  },
  SuperTestLabPage: {
    key: 'SuperTestLabPage',
    root_nav_page_permission: 'SuperTestLabPage',
    url: () => '/super/test_lab/',
  },
  SuperDatabaseManagementPage: {
    key: 'SuperDatabaseManagementPage',
    root_nav_page_permission: 'SuperDatabaseManagementPage',
    url: () => '/super/database_management/',
  },
  BoilerplateDocsPage: {
    key: 'BoilerplateDocsPage',
    root_nav_page_permission: 'BoilerplateDocsPage',
    url: () => '/boilerplate/docs/components',
  },
  ///////////////////////////////
  // Unauthenticated
  ///////////////////////////////
  TermsOfServicePage: {
    key: 'TermsOfServicePage',
    root_nav_page_permission: 'unauthenticated',
    url: () => '/terms_of_service',
  },
  TeslaPowerwall: {
    key: 'TeslaPowerwall',
    root_nav_page_permission: 'unauthenticated',
    url: () => '/powerwall',
  },
  TeslaPowerwallConfirm: {
    key: 'TeslaPowerwallConfirm',
    root_nav_page_permission: 'unauthenticated',
    url: (cid: string | undefined, uid: string | undefined) => '/powerwall/' + cid + '/' + uid,
  },
  UnauthenticatedLandingPage: {
    key: 'UnauthenticatedLandingPage',
    root_nav_page_permission: 'unauthenticated',
    url: () => '/',
  },
  UnauthenticatedCalculatorsPage: {
    key: 'UnauthenticatedCalculatorsPage',
    root_nav_page_permission: 'unauthenticated',
    url: () => '/calculators/srec-il',
  },
  UnauthenticatedLoginPage: {
    key: 'UnauthenticatedLoginPage',
    root_nav_page_permission: 'unauthenticated',
    url: () => '/login',
  },
  UnauthenticatedSalesLoginPage: {
    key: 'UnauthenticatedSalesLoginPage',
    root_nav_page_permission: 'unauthenticated',
    url: () => '/customer_login',
  },
  UnauthenticatedSalesOpportunityPage: {
    key: 'UnauthenticatedSalesOpportunityPage',
    root_nav_page_permission: 'unauthenticated',
    url: (ok?: string) => '/go_solar' + (ok ? '/' + ok : ''),
  },
  UnauthenticatedSalesOpportunityPage_v2: {
    key: 'UnauthenticatedSalesOpportunityPage_v2',
    root_nav_page_permission: 'unauthenticated',
    url: () => '/go_solar',
  },
  UnauthenticatedLunarSalesOpportunityPage: {
    key: 'UnauthenticatedLunarSalesOpportunityPage',
    root_nav_page_permission: 'unauthenticated',
    url: () => '/go_lunar',
  },
  UnauthenticatedLunarSalesOpportunityPage_v2: {
    key: 'UnauthenticatedLunarSalesOpportunityPage_v2',
    root_nav_page_permission: 'unauthenticated',
    url: (ok?: string) => '/go_lunar' + (ok ? '/' + ok : ''),
  },
  UnauthenticatedMaterialsSupplierOrdersListPage: {
    key: 'UnauthenticatedMaterialsSupplierOrdersListPage',
    root_nav_page_permission: 'unauthenticated',
    url: (ck: string | undefined, sk: string | undefined) => '/s/all_orders/' + ck + '/' + sk + '/',
  },
  UnauthenticatedInvoiceViewPage: {
    key: 'UnauthenticatedInvoiceViewPage',
    root_nav_page_permission: 'unauthenticated',
    url: (ck: string | undefined, ik: string | undefined, it: string | undefined) => '/s/invoice/' + ck + '/' + ik + '/' + it + '/',
  },
  PaymentTermsPage: {
    key: 'PaymentTermsPage',
    root_nav_page_permission: 'unauthenticated',
    url: () => '/payment_terms',
  },
  PowerwallInstallAgreementPage: {
    key: 'PowerwallInstallAgreementPage',
    root_nav_page_permission: 'unauthenticated',
    url: () => '/powerwall_install_agreement',
  },
  PrivacyPolicyPage: {
    key: 'PrivacyPolicyPage',
    root_nav_page_permission: 'unauthenticated',
    url: () => '/privacy_policy',
  },
  ///////////////////////////////
  // UNSORTED / TEMP / DEPRECATED
  ///////////////////////////////
  ShowQueryParamsPage: {
    key: 'ShowQueryParamsPage',
    root_nav_page_permission: 'ShowQueryParamsPage',
    url: () => '/view_params/',
  },
  ShortUrlPage: {
    key: 'ShortUrlPage',
    root_nav_page_permission: 'ShortUrlPage',
    url: (id: string | undefined) => '/sl/' + id,
  },
  AdminDatabaseHomePage: {
    key: 'AdminDatabaseHomePage',
    root_nav_page_permission: 'AdminDatabaseHomePage',
    url: () => '/a/database',
  },
  ABPDisclosureFormPage: {
    key: 'ABPDisclosureFormPage',
    root_nav_page_permission: 'unauthenticated',
    url: (id: string | undefined) => '/a/abp_disclosure_form/' + id,
  },
  ABPDisclosureFormStatusPage: {
    key: 'ABPDisclosureFormStatusPage',
    root_nav_page_permission: 'unauthenticated',
    url: (cid: string | undefined, fid: string | undefined) => '/a/abp_disclosure_form/' + cid + '/status/' + fid,
  },
}

// Hardcoded Project Phases
export const hardcodedProjectPhases: TsInterface_UnspecifiedObject = {
  sale_to_cap: {
    // Saved to DB causes issues with Trans Tags
    key: 'sale_to_cap',
    // name: s_SALE_TO_CAP,
    name: 'Sale to CAP',
    order: 1,
  },
  cap_to_install: {
    key: 'cap_to_install',
    // name: s_CAP_TO_INSTALL,
    name: 'CAP to Install',
    order: 2,
  },
  install_to_activation: {
    key: 'install_to_activation',
    // name: s_INSTALL_TO_APPLICATION,
    name: 'Install to Activation',
    order: 3,
  },
}

// User Types
// COPIED to Instant Messaging Cloud Function / Leaderboard Cloud Function
export const ClientUserRoles: TsInterface_UnspecifiedObject = {
  accounts_payable: {
    color: 'warning',
    icon: (
      <Icon
        type="solid"
        icon="message-dollar"
      />
    ),
    key: 'accounts_payable',
    name: rLIB('Accounts Payable'),
    value: rLIB('Accounts Payable'),
    name_string: 'Accounts Payable',
    project_phases: { sale_to_cap: true, cap_to_install: true, install_to_activation: true },
    home_redirect_page: ApplicationPages.AllAssignedTasksListPage,
    desktop_access: true,
    default_associated_organization_type: 'internal',
    can_be_primary_role: false,
    TEMP_disable_selection: true,
  },
  accounts_receivable: {
    color: 'warning',
    icon: (
      <Icon
        type="solid"
        icon="message-dollar"
      />
    ),
    key: 'accounts_receivable',
    name: rLIB('Accounts Receivable'),
    value: rLIB('Accounts Receivable'),
    name_string: 'Accounts Receivable',
    project_phases: { sale_to_cap: true, cap_to_install: true, install_to_activation: true },
    home_redirect_page: ApplicationPages.AllAssignedTasksListPage,
    desktop_access: true,
    default_associated_organization_type: 'internal',
    can_be_primary_role: false,
    TEMP_disable_selection: true,
  },
  admin: {
    color: 'warning',
    icon: (
      <Icon
        type="solid"
        icon="crown"
      />
    ),
    key: 'admin',
    name: rLIB('Admin'),
    value: rLIB('Admin'),
    name_string: 'Admin',
    project_phases: { sale_to_cap: true, cap_to_install: true, install_to_activation: true },
    home_redirect_page: ApplicationPages.AllAssignedTasksListPage,
    desktop_access: true,
    default_associated_organization_type: 'internal',
    can_be_primary_role: true,
  },
  api_user: {
    color: 'primary',
    icon: (
      <Icon
        type="solid"
        icon="code"
      />
    ),
    key: 'api_user',
    name: rLIB('API User'),
    value: rLIB('API User'),
    name_string: 'API User',
    project_phases: { sale_to_cap: false, cap_to_install: false, install_to_activation: false },
    home_redirect_page: ApplicationPages.AdminDatabaseHomePage,
    desktop_access: true,
    default_associated_organization_type: 'external',
    can_be_primary_role: true,
  },
  construction_lead: {
    color: 'info',
    icon: (
      <Icon
        type="solid"
        icon="hammer"
      />
    ),
    key: 'construction_lead',
    name: rLIB('Construction Lead'),
    value: rLIB('Construction Lead'),
    name_string: 'Construction Lead',
    project_phases: { sale_to_cap: true, cap_to_install: true, install_to_activation: false },
    home_redirect_page: ApplicationPages.AllAssignedTasksListPage,
    desktop_access: true,
    default_associated_organization_type: 'internal',
    can_be_primary_role: true,
  },
  css_manager: {
    color: 'info',
    icon: (
      <Icon
        type="solid"
        icon="headset"
      />
    ),
    key: 'css_manager',
    name: rLIB('CSS Manager'),
    value: rLIB('CSS Manager'),
    name_string: 'CSS Manager',
    project_phases: { sale_to_cap: true, cap_to_install: true, install_to_activation: true },
    home_redirect_page: ApplicationPages.AllAssignedTasksListPage,
    desktop_access: true,
    default_associated_organization_type: 'internal',
    can_be_primary_role: true,
  },
  css_rep: {
    color: 'info',
    icon: (
      <Icon
        type="solid"
        icon="headset"
      />
    ),
    key: 'css_rep',
    name: rLIB('CSS Rep'),
    value: rLIB('CSS Rep'),
    name_string: 'CSS Rep',
    project_phases: { sale_to_cap: true, cap_to_install: true, install_to_activation: true },
    home_redirect_page: ApplicationPages.AllAssignedTasksListPage,
    desktop_access: true,
    default_associated_organization_type: 'internal',
    can_be_primary_role: true,
  },
  customer: {
    color: 'secondary',
    icon: (
      <Icon
        type="solid"
        icon="cart-shopping"
      />
    ),
    key: 'customer',
    name: rLIB('Customer'),
    value: rLIB('Customer'),
    name_string: 'Customer',
    project_phases: { sale_to_cap: false, cap_to_install: false, install_to_activation: false },
    home_redirect_page: null, //TODO:
    desktop_access: false,
    default_associated_organization_type: 'external',
    can_be_primary_role: true,
  },
  design: {
    color: 'info',
    icon: (
      <Icon
        type="solid"
        icon="compass-drafting"
      />
    ),
    key: 'design',
    name: rLIB('Design'),
    value: rLIB('Design'),
    name_string: 'Design',
    project_phases: { sale_to_cap: true, cap_to_install: false, install_to_activation: false },
    home_redirect_page: ApplicationPages.AdminActiveProjectsListPage,
    desktop_access: true,
    default_associated_organization_type: 'internal',
    can_be_primary_role: true,
  },
  developer: {
    color: 'info', // You can change the color if needed
    icon: (
      <Icon
        type="solid"
        icon="laptop-code" // Use an appropriate icon for the developer role
      />
    ),
    key: 'developer',
    name: rLIB('Developer'),
    value: rLIB('Developer'),
    name_string: 'Developer',
    project_phases: { sale_to_cap: true, cap_to_install: true, install_to_activation: true },
    home_redirect_page: ApplicationPages.AllAssignedTasksListPage, // Same as admin
    desktop_access: true,
    default_associated_organization_type: 'internal',
    can_be_primary_role: false,
  },
  electrical_foreman: {
    color: 'error',
    icon: (
      <Icon
        type="solid"
        icon="bolt"
      />
    ),
    key: 'electrical_foreman',
    name: rLIB('Electrical Foreman'),
    value: rLIB('Electrical Foreman'),
    name_string: 'Electrical Foreman',
    project_phases: { sale_to_cap: false, cap_to_install: false, install_to_activation: true },
    // home_redirect_page: ApplicationPages.AllAssignedTasksListPage,
    home_redirect_page: ApplicationPages.AllTimesheetsIndexPage,
    desktop_access: true,
    default_associated_organization_type: 'internal',
    can_be_primary_role: true,
  },
  field_service: {
    color: 'error',
    icon: (
      <Icon
        type="solid"
        icon="truck-field"
      />
    ),
    key: 'field_service',
    name: rLIB('Field Service'),
    value: rLIB('Field Service'),
    name_string: 'Field Service',
    project_phases: { sale_to_cap: false, cap_to_install: true, install_to_activation: true },
    // home_redirect_page: ApplicationPages.AllAssignedTasksListPage,
    home_redirect_page: ApplicationPages.AllTimesheetsIndexPage,
    desktop_access: true,
    default_associated_organization_type: 'internal',
    can_be_primary_role: true,
  },
  general_manager: {
    color: 'info',
    icon: (
      <Icon
        type="solid"
        icon="helmet-safety"
      />
    ),
    key: 'general_manager',
    name: rLIB('General Manager'),
    value: rLIB('General Manager'),
    name_string: 'General Manager',
    project_phases: { sale_to_cap: true, cap_to_install: true, install_to_activation: true },
    home_redirect_page: ApplicationPages.AllAssignedTasksListPage,
    desktop_access: true,
    default_associated_organization_type: 'internal',
    can_be_primary_role: true,
  },
  home_upgrades_electrician: {
    color: 'error',
    icon: (
      <Icon
        type="solid"
        icon="bolt"
      />
    ),
    key: 'home_upgrades_electrician',
    name: rLIB('Home Upgrades Electrician'),
    value: rLIB('Home Upgrades Electrician'),
    name_string: 'Home Upgrades Electrician',
    project_phases: { sale_to_cap: false, cap_to_install: false, install_to_activation: true },
    // home_redirect_page: ApplicationPages.AllAssignedTasksListPage,
    home_redirect_page: ApplicationPages.AllTimesheetsIndexPage,
    desktop_access: true,
    default_associated_organization_type: 'internal',
    can_be_primary_role: true,
  },
  home_upgrades_electrician_trainee: {
    color: 'error',
    icon: (
      <Icon
        type="solid"
        icon="bolt"
      />
    ),
    key: 'home_upgrades_electrician_trainee',
    name: rLIB('Home Upgrades Electrician Trainee'),
    value: rLIB('Home Upgrades Electrician Trainee'),
    name_string: 'Home Upgrades Electrician Trainee',
    project_phases: { sale_to_cap: false, cap_to_install: false, install_to_activation: true },
    // home_redirect_page: ApplicationPages.AllAssignedTasksListPage,
    home_redirect_page: ApplicationPages.AllTimesheetsIndexPage,
    desktop_access: true,
    default_associated_organization_type: 'internal',
    can_be_primary_role: true,
  },

  hr_admin: {
    color: 'warning',
    icon: (
      <Icon
        type="solid"
        icon="user-group"
      />
    ),
    key: 'hr_admin',
    name: rLIB('HR Admin'),
    value: rLIB('HR Admin'),
    name_string: 'HR Admin',
    project_phases: { sale_to_cap: true, cap_to_install: true, install_to_activation: true },
    home_redirect_page: ApplicationPages.AllAssignedTasksListPage,
    desktop_access: true,
    default_associated_organization_type: 'internal',
    can_be_primary_role: false,
    TEMP_disable_selection: true,
  },
  inspection: {
    color: 'error',
    icon: (
      <Icon
        type="solid"
        icon="magnifying-glass-location"
      />
    ),
    key: 'inspection',
    name: rLIB('Inspection'),
    value: rLIB('Inspection'),
    name_string: 'Inspection',
    project_phases: { sale_to_cap: false, cap_to_install: false, install_to_activation: true },
    // home_redirect_page: ApplicationPages.AllAssignedTasksListPage,
    home_redirect_page: ApplicationPages.AllTimesheetsIndexPage,
    desktop_access: true,
    default_associated_organization_type: 'internal',
    can_be_primary_role: true,
  },
  install_coordinator: {
    color: 'info',
    icon: (
      <Icon
        type="solid"
        icon="magnifying-glass-location"
      />
    ),
    key: 'install_coordinator',
    name: rLIB('Install Coordinator'),
    value: rLIB('Install Coordinator'),
    name_string: 'Install Coordinator',
    project_phases: { sale_to_cap: false, cap_to_install: false, install_to_activation: true },
    home_redirect_page: ApplicationPages.AllAssignedTasksListPage,
    desktop_access: true,
    default_associated_organization_type: 'internal',
    can_be_primary_role: true,
  },
  install_lead: {
    color: 'error',
    icon: (
      <Icon
        type="solid"
        icon="trowel-bricks"
      />
    ),
    key: 'install_lead',
    name: rLIB('Install Lead'),
    value: rLIB('Install Lead'),
    name_string: 'Install Lead',
    project_phases: { sale_to_cap: false, cap_to_install: false, install_to_activation: true },
    // home_redirect_page: ApplicationPages.AllAssignedTasksListPage,
    home_redirect_page: ApplicationPages.AllTimesheetsIndexPage,
    desktop_access: true,
    default_associated_organization_type: 'internal',
    can_be_primary_role: true,
  },
  installer: {
    color: 'error',
    icon: (
      <Icon
        type="solid"
        icon="trowel-bricks"
      />
    ),
    key: 'installer',
    name: rLIB('Installer'),
    value: rLIB('Installer'),
    name_string: 'Installer',
    project_phases: { sale_to_cap: false, cap_to_install: false, install_to_activation: true },
    // home_redirect_page: ApplicationPages.AllAssignedTasksListPage,
    home_redirect_page: ApplicationPages.AllTimesheetsIndexPage,
    desktop_access: true,
    default_associated_organization_type: 'internal',
    can_be_primary_role: true,
  },
  interconnection: {
    color: 'info',
    icon: (
      <Icon
        type="solid"
        icon="transformer-bolt"
      />
    ),
    key: 'interconnection',
    name: rLIB('Interconnection'),
    value: rLIB('Interconnection'),
    name_string: 'Interconnection',
    project_phases: { sale_to_cap: false, cap_to_install: false, install_to_activation: true },
    home_redirect_page: ApplicationPages.AllAssignedTasksListPage,
    desktop_access: true,
    default_associated_organization_type: 'internal',
    can_be_primary_role: true,
  },
  permitting: {
    color: 'info',
    icon: (
      <Icon
        type="solid"
        icon="signature"
      />
    ),
    key: 'permitting',
    name: rLIB('Permitting'),
    value: rLIB('Permitting'),
    name_string: 'Permitting',
    project_phases: { sale_to_cap: false, cap_to_install: true, install_to_activation: false },
    home_redirect_page: ApplicationPages.AllAssignedTasksListPage,
    desktop_access: true,
    default_associated_organization_type: 'internal',
    can_be_primary_role: true,
  },
  repair: {
    color: 'error',
    icon: (
      <Icon
        type="solid"
        icon="wrench"
      />
    ),
    key: 'repair',
    name: rLIB('Repair'),
    value: rLIB('Repair'),
    name_string: 'Repair',
    project_phases: { sale_to_cap: false, cap_to_install: true, install_to_activation: false },
    // home_redirect_page: ApplicationPages.AllAssignedTasksListPage,
    home_redirect_page: ApplicationPages.AllTimesheetsIndexPage,
    desktop_access: true,
    default_associated_organization_type: 'internal',
    can_be_primary_role: true,
  },
  sales_manager: {
    color: 'success',
    icon: (
      <Icon
        type="solid"
        icon="messages-dollar"
      />
    ),
    key: 'sales_manager',
    name: rLIB('Sales Manager'),
    value: rLIB('Sales Manager'),
    name_string: 'Sales Manager',
    project_phases: { sale_to_cap: false, cap_to_install: false, install_to_activation: false },
    home_redirect_page: null,
    desktop_access: false,
    default_associated_organization_type: 'external',
    can_be_primary_role: true,
  },
  sales_partner_admin: {
    color: 'success',
    icon: (
      <Icon
        type="solid"
        icon="crown"
      />
    ),
    key: 'sales_partner_admin',
    name: rLIB('Sales Partner Admin'),
    value: rLIB('Sales Partner Admin'),
    name_string: 'Sales Partner Admin',
    project_phases: { sale_to_cap: false, cap_to_install: false, install_to_activation: false },
    home_redirect_page: ApplicationPages.SalesProjectsListPage,
    desktop_access: true,
    default_associated_organization_type: 'external',
    can_be_primary_role: true,
  },
  sales_rep: {
    color: 'success',
    icon: (
      <Icon
        type="solid"
        icon="messages-dollar"
      />
    ),
    key: 'sales_rep',
    name: rLIB('Sales Rep'),
    value: rLIB('Sales Rep'),
    name_string: 'Sales Rep',
    project_phases: { sale_to_cap: false, cap_to_install: false, install_to_activation: false },
    home_redirect_page: null,
    desktop_access: false,
    default_associated_organization_type: 'external',
    can_be_primary_role: true,
  },
  scheduling: {
    color: 'info',
    icon: (
      <Icon
        type="solid"
        icon="calendar"
      />
    ),
    key: 'scheduling',
    name: rLIB('Scheduling'),
    value: rLIB('Scheduling'),
    name_string: 'Scheduling',
    project_phases: { sale_to_cap: false, cap_to_install: false, install_to_activation: false },
    home_redirect_page: null,
    desktop_access: true,
    default_associated_organization_type: 'internal',
    can_be_primary_role: true,
  },
  site_auditor: {
    color: 'error',
    icon: (
      <Icon
        type="solid"
        icon="clipboard-list-check"
      />
    ),
    key: 'site_auditor',
    name: rLIB('Site Auditor'),
    value: rLIB('Site Auditor'),
    name_string: 'Site Auditor',
    project_phases: { sale_to_cap: true, cap_to_install: false, install_to_activation: false },
    // home_redirect_page: ApplicationPages.AllAssignedTasksListPage,
    home_redirect_page: ApplicationPages.AllTimesheetsIndexPage,
    desktop_access: true,
    default_associated_organization_type: 'internal',
    can_be_primary_role: true,
  },
  subcontractor_construction: {
    color: 'primary',
    icon: (
      <Icon
        type="solid"
        icon="helmet-safety"
      />
    ),
    key: 'subcontractor_construction',
    name: rLIB('Subcontractor (Construction)'),
    value: rLIB('Subcontractor (Construction)'),
    name_string: 'Subcontractor (Construction)',
    project_phases: { sale_to_cap: false, cap_to_install: true, install_to_activation: false },
    home_redirect_page: ApplicationPages.SubKActiveTasksListPage,
    desktop_access: true,
    default_associated_organization_type: 'external',
    can_be_primary_role: true,
  },
  subcontractor_design: {
    color: 'primary',
    icon: (
      <Icon
        type="solid"
        icon="compass-drafting"
      />
    ),
    key: 'subcontractor_design',
    name: rLIB('Subcontractor (Design)'),
    value: rLIB('Subcontractor (Design)'),
    name_string: 'Subcontractor (Design)',
    project_phases: { sale_to_cap: false, cap_to_install: true, install_to_activation: false },
    home_redirect_page: ApplicationPages.SubKActiveTasksListPage,
    desktop_access: true,
    default_associated_organization_type: 'external',
    can_be_primary_role: true,
  },
  subcontractor_permitting: {
    color: 'primary',
    icon: (
      <Icon
        type="solid"
        icon="file-signature"
      />
    ),
    key: 'subcontractor_permitting',
    name: rLIB('Subcontractor (Permitting)'),
    value: rLIB('Subcontractor (Permitting)'),
    name_string: 'Subcontractor (Permitting)',
    project_phases: { sale_to_cap: false, cap_to_install: true, install_to_activation: false },
    home_redirect_page: ApplicationPages.SubKActiveTasksListPage,
    desktop_access: true,
    default_associated_organization_type: 'external',
    can_be_primary_role: true,
  },
  subcontractor_repair: {
    color: 'primary',
    icon: (
      <Icon
        type="solid"
        icon="hammer"
      />
    ),
    key: 'subcontractor_repair',
    name: rLIB('Subcontractor (Repair)'),
    value: rLIB('Subcontractor (Repair)'),
    name_string: 'Subcontractor (Repair)',
    project_phases: { sale_to_cap: false, cap_to_install: true, install_to_activation: false },
    home_redirect_page: ApplicationPages.SubKActiveTasksListPage,
    desktop_access: true,
    default_associated_organization_type: 'external',
    can_be_primary_role: true,
  },
  subcontractor_roofing: {
    color: 'primary',
    icon: (
      <Icon
        type="solid"
        icon="house"
      />
    ),
    key: 'subcontractor_roofing',
    name: rLIB('Subcontractor (Roofing)'),
    value: rLIB('Subcontractor (Roofing)'),
    name_string: 'Subcontractor (Roofing)',
    project_phases: { sale_to_cap: false, cap_to_install: true, install_to_activation: false },
    home_redirect_page: ApplicationPages.SubKActiveTasksListPage,
    desktop_access: true,
    default_associated_organization_type: 'external',
    can_be_primary_role: true,
  },
  subcontractor_warehouse: {
    color: 'primary',
    icon: (
      <Icon
        type="solid"
        icon="warehouse-full"
      />
    ),
    key: 'subcontractor_warehouse',
    name: rLIB('Subcontractor (Warehouse)'),
    value: rLIB('Subcontractor (Warehouse)'),
    name_string: 'Subcontractor (Warehouse)',
    project_phases: { sale_to_cap: false, cap_to_install: true, install_to_activation: false },
    home_redirect_page: ApplicationPages.SubKActiveTasksListPage,
    desktop_access: true,
    default_associated_organization_type: 'external',
    can_be_primary_role: true,
  },
  subcontractor: {
    color: 'primary',
    icon: (
      <Icon
        type="solid"
        icon="file-contract"
      />
    ),
    key: 'subcontractor',
    name: rLIB('Subcontractor (Other)'),
    value: rLIB('Subcontractor (Other)'),
    name_string: 'Subcontractor (Other)',
    project_phases: { sale_to_cap: false, cap_to_install: true, install_to_activation: false },
    home_redirect_page: ApplicationPages.SubKActiveTasksListPage,
    desktop_access: true,
    default_associated_organization_type: 'external',
    can_be_primary_role: true,
  },
  warehouse: {
    color: 'error',
    icon: (
      <Icon
        type="solid"
        icon="warehouse"
      />
    ),
    key: 'warehouse',
    name: rLIB('Warehouse'),
    value: rLIB('Warehouse'),
    name_string: 'Warehouse',
    project_phases: { sale_to_cap: false, cap_to_install: true, install_to_activation: false },
    // home_redirect_page: ApplicationPages.AllAssignedTasksListPage,
    home_redirect_page: ApplicationPages.AllTimesheetsIndexPage,
    desktop_access: true,
    default_associated_organization_type: 'internal',
    can_be_primary_role: true,
  },
}

export const returnClientUserRoles = (clientKey: string): TsInterface_UnspecifiedObject => {
  let userRoles: TsInterface_UnspecifiedObject = {}
  switch (clientKey) {
    // TODO - able to have clients wth different user roles

    // case "jmorrell1":

    // 	userRoles = {
    // 		admin: ClientUserRoles.admin,
    // 		css_rep: ClientUserRoles.css_rep,
    // 	}
    // 	userRoles["css_rep"]["name"] = "Support Rep Test"

    // 	break
    default:
      userRoles = ClientUserRoles
      break
  }
  return userRoles
}

// Included on Server - server_scheduling_management
export const schedulingTeamTypes: TsInterface_UnspecifiedObject = {
  electrician: {
    key: 'electrician',
    user_roles: ['home_upgrades_electrician', 'home_upgrades_electrician_trainee'],
    value: rLIB('Electrician'),
    name_string: 'Electrician',
    icon: <Icon icon="bolt" />,
    color: themeVariables.warning_main,
    color2: themeVariables.warning_wash,
    include_in_material_calendar: true,
    show_missing_invoice_ids: true,
  },
  field_service: {
    key: 'field_service',
    user_roles: ['field_service'],
    value: rLIB('Field Service'),
    name_string: 'Field Service',
    icon: <Icon icon="truck-field" />,
    color: themeVariables.accent_main,
    color2: themeVariables.accent_wash,
    include_in_material_calendar: true,
    show_missing_invoice_ids: true,
  },
  inspector: {
    // named differently
    key: 'inspector',
    user_roles: ['inspection'],
    value: rLIB('Inspector'),
    name_string: 'Inspector',
    icon: <Icon icon="magnifying-glass-location" />,
    color: themeVariables.secondary_main,
    color2: themeVariables.secondary_wash,
    include_in_material_calendar: false,
    show_missing_invoice_ids: false,
  },
  install_crew: {
    // Has 2 or 3 roles?
    key: 'install_crew',
    user_roles: ['electrical_foreman', 'install_lead', 'installer'],
    value: rLIB('Install Crew'),
    name_string: 'Install Crew',
    icon: <Icon icon="trowel-bricks" />,
    color: themeVariables.error_main,
    color2: themeVariables.error_wash,
    include_in_material_calendar: true,
    show_missing_invoice_ids: false,
  },
  repair: {
    key: 'repair',
    user_roles: ['repair'],
    value: rLIB('Repair'),
    name_string: 'Repair',
    icon: <Icon icon="wrench-simple" />,
    color: themeVariables.teal_500,
    color2: themeVariables.teal_700,
    include_in_material_calendar: true,
    show_missing_invoice_ids: true,
  },
  site_auditor: {
    key: 'site_auditor',
    user_roles: ['site_auditor'],
    value: rLIB('Site Auditor'),
    name_string: 'Site Auditor',
    icon: <Icon icon="clipboard-list-check" />,
    color: themeVariables.primary_main,
    color2: themeVariables.primary_wash,
    include_in_material_calendar: false,
    show_missing_invoice_ids: false,
  },
  subcontractor: {
    key: 'subcontractor',
    user_roles: ['subcontractor'],
    value: rLIB('Subcontractor'),
    name_string: 'Subcontractor',
    icon: <Icon icon="phone-office" />,
    color: themeVariables.info_wash,
    color2: themeVariables.info_dark,
    include_in_material_calendar: false,
    show_missing_invoice_ids: false,
  },
  warehouse: {
    key: 'warehouse',
    user_roles: ['warehouse'],
    value: rLIB('Warehouse'),
    name_string: 'Warehouse',
    icon: <Icon icon="warehouse" />,
    color: themeVariables.success_wash,
    color2: themeVariables.success_dark,
    include_in_material_calendar: false,
    show_missing_invoice_ids: false,
  },
}

//=========================//
//   USED BY BOILERPLATE   //
//=========================//

export const ClientTypes: TsInterface_ClientTypes = {
  Development: {
    key: 'Development',
    name: rLIB('Development'),
    user_roles: {
      admin: ClientUserRoles['admin'],
    },
  },
  Installer: {
    key: 'Installer',
    name: rLIB('Installer'),
    user_roles: ClientUserRoles,
  },
  Sales: {
    key: 'Sales',
    name: rLIB('Sales'),
    user_roles: {
      admin: ClientUserRoles['admin'],
      customer: ClientUserRoles['sales_customer'], //TODO: add sales rep later
    },
  },
}

export const ApplicationMajorPages: TsInterface_ApplicationPages = {
  HomePage: ApplicationPages['HomePage'],
  UserSettingsPage: ApplicationPages['UserSettingsPage'],
  AllAlertsListPage: ApplicationPages['AllAlertsListPage'],
}

// Nav Sections
export const ApplicationNavSections: TsInterface_ApplicationNavSections = {
  // Installer
  ProjectSection: {
    name: rLIB('Projects') as JSX.Element,
    key: 'ProjectSection',
    links: {},
  },
  StatsSection: {
    name: rLIB('Stats') as JSX.Element,
    key: 'StatsSection',
    links: {},
  },
  OpportunitiesSection: {
    name: rLIB('Opportunities') as JSX.Element,
    key: 'OpportunitiesSection',
    links: {},
  },
  VehicleAndSafetySection: {
    name: rLIB('Vehicles and Safety') as JSX.Element,
    key: 'VehicleAndSafetySection',
    links: {},
  },
  MasterDataSection: {
    name: rLIB('Master Data') as JSX.Element,
    key: 'MasterDataSection',
    links: {},
  },
  FinanceSection: {
    name: rLIB('Finance') as JSX.Element,
    key: 'FinanceSection',
    links: {},
  },
  WorkflowsSection: {
    name: rLIB('Workflows') as JSX.Element,
    key: 'WorkflowsSection',
    links: {},
  },
  EmployeesSection: {
    name: rLIB('Employees') as JSX.Element,
    key: 'EmployeesSection',
    links: {},
  },
  SystemSection: {
    name: rLIB('System') as JSX.Element,
    key: 'SystemSection',
    links: {},
  },
  MaterialsSection: {
    name: rLIB('Materials') as JSX.Element,
    key: 'MaterialsSection',
    links: {},
  },
  // Non Installer Account Main StatsSection
  HomeSection: {
    name: rLIB('Home') as JSX.Element,
    key: 'HomeSection',
    links: {},
  },
  // Sales
  SalesPortalSection: {
    name: rLIB('Sales Partner') as JSX.Element,
    key: 'SalesPortalSection',
    links: {},
  },
  // Subcollection
  SubKPortalSection: {
    name: rLIB('Subcontractor') as JSX.Element,
    key: 'SubKPortalSection',
    links: {},
  },
  // Developer
  SuperSection: {
    name: rLIB('Super') as JSX.Element,
    key: 'SuperSection',
    links: {},
  },
  BoilerplateDocsSection: {
    name: rLIB('Boilerplate Docs') as JSX.Element,
    key: 'BoilerplateDocsSection',
    links: {},
  },
}

//=========================//
//   USED BY BOILERPLATE   //
//=========================//

// Nav Pages
export const ApplicationNavPages: TsInterface_ApplicationNavPages = {
  // Home
  HomePage: {
    name: rLIB('Home') as JSX.Element,
    key: 'HomePage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="house-blank"
      />
    ),
    url: ApplicationPages.HomePage.url(),
    page_role_access_permissions: {
      Development_admin: { access: 'always_yes', highlighted_nav_section: 'HomeSection', highlighted_nav_page: 'HomePage' },
      Installer_customer: { access: 'always_yes', highlighted_nav_section: 'HomeSection', highlighted_nav_page: 'HomePage' },
      Installer_sales_manager: { access: 'always_yes', highlighted_nav_section: 'HomeSection', highlighted_nav_page: 'HomePage' },
      Installer_sales_rep: { access: 'always_yes', highlighted_nav_section: 'HomeSection', highlighted_nav_page: 'HomePage' },
      Sales_admin: { access: 'always_yes', highlighted_nav_section: 'HomeSection', highlighted_nav_page: 'HomePage' }, //TODO: maybe get rid of?
      Sales_customer: { access: 'always_yes', highlighted_nav_section: 'HomeSection', highlighted_nav_page: 'HomePage' }, //TODO: maybe get rid of?
    },
    nav_badges: {},
  },
  UserSettingsPage: {
    name: rLIB('Settings') as JSX.Element,
    key: 'UserSettingsPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="gear"
      />
    ),
    url: ApplicationPages.UserSettingsPage.url(),
    page_role_access_permissions: {
      // Development_admin: { access: 'always_yes', highlighted_nav_section: 'HomeSection', highlighted_nav_page: 'UserSettingsPage' },
      Installer_customer: { access: 'always_yes', highlighted_nav_section: 'HomeSection', highlighted_nav_page: 'UserSettingsPage' },
      Installer_sales_manager: { access: 'always_yes', highlighted_nav_section: 'HomeSection', highlighted_nav_page: 'UserSettingsPage' },
      Installer_sales_rep: { access: 'always_yes', highlighted_nav_section: 'HomeSection', highlighted_nav_page: 'UserSettingsPage' },
    },
    nav_badges: {},
  },
  // Projects
  AllAssignedTasksListPage: {
    name: rLIB('Tasks') as JSX.Element,
    key: 'AllAssignedTasksListPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="clipboard-list-check"
      />
    ),
    url: ApplicationPages.AllAssignedTasksListPage.url(),
    page_role_access_permissions: {
      Installer_accounts_payable: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AllAssignedTasksListPage' },
      Installer_accounts_receivable: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AllAssignedTasksListPage' },
      Installer_admin: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AllAssignedTasksListPage' },
      Installer_construction_lead: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AllAssignedTasksListPage' },
      Installer_css_manager: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AllAssignedTasksListPage' },
      Installer_css_rep: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AllAssignedTasksListPage' },
      Installer_design: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AllAssignedTasksListPage' },
      Installer_electrical_foreman: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AllAssignedTasksListPage' },
      Installer_field_service: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AllAssignedTasksListPage' },
      Installer_general_manager: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AllAssignedTasksListPage' },
      Installer_home_upgrades_electrician: {
        access: 'always_yes',
        highlighted_nav_section: 'ProjectSection',
        highlighted_nav_page: 'AllAssignedTasksListPage',
      },
      Installer_home_upgrades_electrician_trainee: {
        access: 'always_yes',
        highlighted_nav_section: 'ProjectSection',
        highlighted_nav_page: 'AllAssignedTasksListPage',
      },
      Installer_inspection: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AllAssignedTasksListPage' },
      Installer_install_coordinator: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AllAssignedTasksListPage' },
      Installer_install_lead: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AllAssignedTasksListPage' },
      Installer_installer: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AllAssignedTasksListPage' },
      Installer_interconnection: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AllAssignedTasksListPage' },
      Installer_permitting: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AllAssignedTasksListPage' },
      Installer_repair: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AllAssignedTasksListPage' },
      Installer_site_auditor: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AllAssignedTasksListPage' },
      Installer_warehouse: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AllAssignedTasksListPage' },
    },
    nav_badges: { openTasks: true },
  },
  AdminActiveProjectsListPage: {
    name: rLIB('Projects') as JSX.Element,
    key: 'AdminActiveProjectsListPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="solar-panel"
      />
    ),
    url: ApplicationPages.AdminActiveProjectsListPage.url(),
    page_role_access_permissions: {
      Installer_accounts_payable: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AdminActiveProjectsListPage' },
      Installer_accounts_receivable: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AdminActiveProjectsListPage' },
      Installer_admin: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AdminActiveProjectsListPage' },
      Installer_construction_lead: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AdminActiveProjectsListPage' },
      Installer_css_manager: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AdminActiveProjectsListPage' },
      Installer_css_rep: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AdminActiveProjectsListPage' },
      Installer_design: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AdminActiveProjectsListPage' },
      Installer_electrical_foreman: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AdminActiveProjectsListPage' },
      Installer_field_service: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AdminActiveProjectsListPage' },
      Installer_general_manager: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AdminActiveProjectsListPage' },
      Installer_home_upgrades_electrician: {
        access: 'always_yes',
        highlighted_nav_section: 'ProjectSection',
        highlighted_nav_page: 'AdminActiveProjectsListPage',
      },
      Installer_home_upgrades_electrician_trainee: {
        access: 'always_yes',
        highlighted_nav_section: 'ProjectSection',
        highlighted_nav_page: 'AdminActiveProjectsListPage',
      },
      Installer_inspection: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AdminActiveProjectsListPage' },
      Installer_install_coordinator: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AdminActiveProjectsListPage' },
      Installer_install_lead: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AdminActiveProjectsListPage' },
      Installer_installer: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AdminActiveProjectsListPage' },
      Installer_interconnection: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AdminActiveProjectsListPage' },
      Installer_permitting: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AdminActiveProjectsListPage' },
      Installer_repair: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AdminActiveProjectsListPage' },
      Installer_site_auditor: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AdminActiveProjectsListPage' },
      Installer_scheduling: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AdminActiveProjectsListPage' },
      Installer_warehouse: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AdminActiveProjectsListPage' },
    },
    nav_badges: { unassignedProjects: true },
  },
  AdminProjectPacingPage: {
    name: rLIB('Pacing') as JSX.Element,
    key: 'AdminProjectPacingPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="traffic-light"
      />
    ),
    url: ApplicationPages.AdminProjectPacingPage.url(),
    page_role_access_permissions: {
      Installer_admin: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AdminProjectPacingPage' },
      Installer_css_rep: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AdminProjectPacingPage' },
      Installer_general_manager: { access: 'default_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AdminProjectPacingPage' },
    },
    nav_badges: {},
  },
  TaskCalendarSchedulePage: {
    name: rLIB('Scheduling') as JSX.Element,
    key: 'TaskCalendarSchedulePage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="calendar-clock"
      />
    ),
    url: ApplicationPages.TaskCalendarSchedulePage.url(returnFormattedDateKey(new Date())),
    page_role_access_permissions: {
      Installer_admin: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'TaskCalendarSchedulePage' },
      Installer_css_rep: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'TaskCalendarSchedulePage' },
      Installer_general_manager: { access: 'default_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'TaskCalendarSchedulePage' },
      Installer_warehouse: { access: 'default_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'TaskCalendarSchedulePage' },
    },
    nav_badges: {},
  },
  AdminProjectManualImportsPage: {
    name: rLIB('Project Imports') as JSX.Element,
    key: 'AdminProjectManualImportsPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="file-import"
      />
    ),
    url: ApplicationPages.AdminProjectManualImportsPage.url(),
    page_role_access_permissions: {
      Installer_admin: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AdminProjectManualImportsPage' },
      Installer_css_rep: { access: 'always_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AdminProjectManualImportsPage' },
      Installer_general_manager: { access: 'default_yes', highlighted_nav_section: 'ProjectSection', highlighted_nav_page: 'AdminProjectManualImportsPage' },
    },
    nav_badges: {},
  },
  AdminStatsListPage: {
    name: rLIB('Stats') as JSX.Element,
    key: 'AdminStatsListPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="chart-pie"
      />
    ),
    url: ApplicationPages.AdminStatsListPage.url(),
    page_role_access_permissions: {
      Installer_admin: { access: 'always_yes', highlighted_nav_section: 'StatsSection', highlighted_nav_page: 'AdminStatsListPage' },
      Installer_css_rep: { access: 'always_yes', highlighted_nav_section: 'StatsSection', highlighted_nav_page: 'AdminStatsListPage' },
      Installer_general_manager: { access: 'default_yes', highlighted_nav_section: 'StatsSection', highlighted_nav_page: 'AdminStatsListPage' },
    },
    nav_badges: {},
  },
  AdminDatabaseMaterialsIndexPage: {
    name: rLIB('Materials') as JSX.Element,
    key: 'AdminDatabaseMaterialsIndexPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="reel"
      />
    ),
    url: ApplicationPages.AdminDatabaseMaterialsIndexPage.url(),
    page_role_access_permissions: {
      Installer_admin: { access: 'always_yes', highlighted_nav_section: 'MaterialsSection', highlighted_nav_page: 'AdminDatabaseMaterialsIndexPage' },
      Installer_general_manager: {
        access: 'default_yes',
        highlighted_nav_section: 'MaterialsSection',
        highlighted_nav_page: 'AdminDatabaseMaterialsIndexPage',
      },
      Installer_warehouse: { access: 'default_yes', highlighted_nav_section: 'MaterialsSection', highlighted_nav_page: 'AdminDatabaseMaterialsIndexPage' },

      Installer_subcontractor_design: {
        access: 'default_yes',
        highlighted_nav_section: 'SubKPortalSection',
        highlighted_nav_page: 'SubKActiveTasksListPage',
      },
    },
    nav_badges: {},
  },
  // Timesheets
  AllTimesheetsIndexPage: {
    name: rLIB('Time Sheets') as JSX.Element,
    key: 'AllTimesheetsIndexPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="clock"
      />
    ),
    url: ApplicationPages.AllTimesheetsIndexPage.url(),
    page_role_access_permissions: {
      Installer_accounts_payable: { access: 'always_yes', highlighted_nav_section: 'EmployeesSection', highlighted_nav_page: 'AllTimesheetsIndexPage' },
      // Installer_accounts_receivable: { access: 'always_yes', highlighted_nav_section: 'EmployeesSection', highlighted_nav_page: 'AllTimesheetsIndexPage' },
      Installer_admin: { access: 'always_yes', highlighted_nav_section: 'EmployeesSection', highlighted_nav_page: 'AllTimesheetsIndexPage' },
      Installer_construction_lead: { access: 'always_yes', highlighted_nav_section: 'EmployeesSection', highlighted_nav_page: 'AllTimesheetsIndexPage' },
      Installer_css_manager: { access: 'always_yes', highlighted_nav_section: 'EmployeesSection', highlighted_nav_page: 'AllTimesheetsIndexPage' },
      Installer_css_rep: { access: 'always_yes', highlighted_nav_section: 'EmployeesSection', highlighted_nav_page: 'AllTimesheetsIndexPage' },
      Installer_design: { access: 'always_yes', highlighted_nav_section: 'EmployeesSection', highlighted_nav_page: 'AllTimesheetsIndexPage' },
      Installer_electrical_foreman: { access: 'always_yes', highlighted_nav_section: 'EmployeesSection', highlighted_nav_page: 'AllTimesheetsIndexPage' },
      Installer_field_service: { access: 'always_yes', highlighted_nav_section: 'EmployeesSection', highlighted_nav_page: 'AllTimesheetsIndexPage' },
      Installer_general_manager: { access: 'always_yes', highlighted_nav_section: 'EmployeesSection', highlighted_nav_page: 'AllTimesheetsIndexPage' },
      Installer_home_upgrades_electrician: {
        access: 'always_yes',
        highlighted_nav_section: 'EmployeesSection',
        highlighted_nav_page: 'AllTimesheetsIndexPage',
      },
      Installer_home_upgrades_electrician_trainee: {
        access: 'always_yes',
        highlighted_nav_section: 'EmployeesSection',
        highlighted_nav_page: 'AllTimesheetsIndexPage',
      },
      Installer_inspection: { access: 'always_yes', highlighted_nav_section: 'EmployeesSection', highlighted_nav_page: 'AllTimesheetsIndexPage' },
      Installer_install_coordinator: { access: 'always_yes', highlighted_nav_section: 'EmployeesSection', highlighted_nav_page: 'AllTimesheetsIndexPage' },
      Installer_install_lead: { access: 'always_yes', highlighted_nav_section: 'EmployeesSection', highlighted_nav_page: 'AllTimesheetsIndexPage' },
      Installer_installer: { access: 'always_yes', highlighted_nav_section: 'EmployeesSection', highlighted_nav_page: 'AllTimesheetsIndexPage' },
      Installer_interconnection: { access: 'always_yes', highlighted_nav_section: 'EmployeesSection', highlighted_nav_page: 'AllTimesheetsIndexPage' },
      Installer_permitting: { access: 'always_yes', highlighted_nav_section: 'EmployeesSection', highlighted_nav_page: 'AllTimesheetsIndexPage' },
      Installer_repair: { access: 'always_yes', highlighted_nav_section: 'EmployeesSection', highlighted_nav_page: 'AllTimesheetsIndexPage' },
      Installer_site_auditor: { access: 'always_yes', highlighted_nav_section: 'EmployeesSection', highlighted_nav_page: 'AllTimesheetsIndexPage' },
      Installer_warehouse: { access: 'always_yes', highlighted_nav_section: 'EmployeesSection', highlighted_nav_page: 'AllTimesheetsIndexPage' },
    },
    nav_badges: {},
  },
  //GM Dashboard
  GMDashboardIndexPage: {
    name: rLIB('GM Dashboard') as JSX.Element,
    key: 'GMDashboardIndexPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="gauge-max"
      />
    ),
    url: ApplicationPages.GMDashboardIndexPage.url(),
    page_role_access_permissions: {
      Installer_admin: { access: 'always_yes', highlighted_nav_section: 'StatsSection', highlighted_nav_page: 'GMDashboardIndexPage' },
    },
    nav_badges: {},
  },

  //Performance Management
  PerformanceManagementPage: {
    name: rLIB('Performance Mgmt') as JSX.Element,
    key: 'PerformanceManagementPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="tasks"
      />
    ),
    url: ApplicationPages.PerformanceManagementPage.url(),
    page_role_access_permissions: {
      Installer_admin: { access: 'always_yes', highlighted_nav_section: 'EmployeesSection', highlighted_nav_page: 'PerformanceManagementPage' },
    },
    nav_badges: {},
  },

  TaskEfficiencyPage: {
    name: rLIB('Task Efficiency') as JSX.Element,
    key: 'TaskEfficiencyPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="bars-progress"
      />
    ),
    url: ApplicationPages.TaskEfficiencyPage.url(),
    page_role_access_permissions: {
      Installer_admin: { access: 'always_yes', highlighted_nav_section: 'StatsSection', highlighted_nav_page: 'TaskEfficiencyPage' },
    },
    nav_badges: {},
  },
  // Sales
  AdminDatabaseFinancePartnersListPage: {
    name: rLIB('Finance Partners') as JSX.Element,
    key: 'AdminDatabaseFinancePartnersListPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="credit-card"
      />
    ),
    url: ApplicationPages.AdminDatabaseFinancePartnersListPage.url(),
    page_role_access_permissions: {
      Installer_admin: { access: 'always_yes', highlighted_nav_section: 'OpportunitiesSection', highlighted_nav_page: 'AdminDatabaseFinancePartnersListPage' },
      Installer_general_manager: {
        access: 'always_yes',
        highlighted_nav_section: 'OpportunitiesSection',
        highlighted_nav_page: 'AdminDatabaseFinancePartnersListPage',
      },
    },
    nav_badges: {},
  },
  AdminDatabaseSalesPartnersListPage: {
    name: rLIB('Sales Partners') as JSX.Element,
    key: 'AdminDatabaseSalesPartnersListPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="badge-dollar"
      />
    ),
    url: ApplicationPages.AdminDatabaseSalesPartnersListPage.url(),
    page_role_access_permissions: {
      Installer_admin: { access: 'always_yes', highlighted_nav_section: 'OpportunitiesSection', highlighted_nav_page: 'AdminDatabaseSalesPartnersListPage' },
      Installer_general_manager: {
        access: 'always_yes',
        highlighted_nav_section: 'OpportunitiesSection',
        highlighted_nav_page: 'AdminDatabaseSalesPartnersListPage',
      },
      Sales_admin: { access: 'always_yes', highlighted_nav_section: 'OpportunitiesSection', highlighted_nav_page: 'AdminDatabaseSalesPartnersListPage' }, //TODO: maybe add customer access?
    },
    nav_badges: {},
  },
  LeadSourcesIndexPage: {
    name: rLIB('Lead Sources') as JSX.Element,
    key: 'LeadSourcesIndexPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="pipe-valve"
      />
    ),
    url: ApplicationPages.LeadSourcesIndexPage.url(),
    page_role_access_permissions: {
      Installer_admin: { access: 'always_yes', highlighted_nav_section: 'OpportunitiesSection', highlighted_nav_page: 'LeadSourcesIndexPage' },
      Sales_admin: { access: 'always_yes', highlighted_nav_section: 'OpportunitiesSection', highlighted_nav_page: 'LeadSourcesIndexPage' }, //TODO: maybe add customer access?
    },
    nav_badges: {},
  },
  DesignIndexPage: {
    name: rLIB('Design') as JSX.Element,
    key: 'DesignIndexPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="compass-drafting"
      />
    ),
    url: ApplicationPages.DesignIndexPage.url(),
    page_role_access_permissions: {
      Installer_admin: { access: 'always_yes', highlighted_nav_section: 'OpportunitiesSection', highlighted_nav_page: 'DesignIndexPage' },
      Sales_admin: { access: 'always_yes', highlighted_nav_section: 'OpportunitiesSection', highlighted_nav_page: 'DesignIndexPage' }, //TODO: maybe add customer access?
    },
    nav_badges: {},
  },
  SalesOpportunitiesIndexPage: {
    name: rLIB('Sales Opportunities') as JSX.Element,
    key: 'SalesOpportunitiesIndexPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="handshake"
      />
    ),
    url: ApplicationPages.SalesOpportunitiesIndexPage.url(),
    page_role_access_permissions: {
      Installer_admin: { access: 'always_yes', highlighted_nav_section: 'OpportunitiesSection', highlighted_nav_page: 'SalesOpportunitiesIndexPage' },
      Sales_admin: { access: 'always_yes', highlighted_nav_section: 'OpportunitiesSection', highlighted_nav_page: 'SalesOpportunitiesIndexPage' }, //TODO: maybe add customer access?
    },
    nav_badges: {},
  },
  AdminSalesToolsIndexPage: {
    name: rLIB('Sales Tools') as JSX.Element,
    key: 'AdminSalesToolsIndexPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="sliders"
      />
    ),
    url: ApplicationPages.AdminSalesToolsIndexPage.url(),
    page_role_access_permissions: {
      Installer_admin: { access: 'always_yes', highlighted_nav_section: 'OpportunitiesSection', highlighted_nav_page: 'AdminSalesToolsIndexPage' },
    },
    nav_badges: {},
  },
  // Fleet
  AdminDatabaseVehiclesListPage: {
    name: rLIB('Vehicles') as JSX.Element,
    key: 'AdminDatabaseVehiclesListPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="truck-pickup"
      />
    ),
    url: ApplicationPages.AdminDatabaseVehiclesListPage.url(),
    page_role_access_permissions: {
      Installer_admin: { access: 'always_yes', highlighted_nav_section: 'VehicleAndSafetySection', highlighted_nav_page: 'AdminDatabaseVehiclesListPage' },
      Installer_general_manager: {
        access: 'default_yes',
        highlighted_nav_section: 'VehicleAndSafetySection',
        highlighted_nav_page: 'AdminDatabaseVehiclesListPage',
      },
      Installer_warehouse: { access: 'default_yes', highlighted_nav_section: 'VehicleAndSafetySection', highlighted_nav_page: 'AdminDatabaseVehiclesListPage' },
    },
    nav_badges: {},
  },
  AdminDatabaseIncidentReportsListPage: {
    name: rLIB('Incident Reports') as JSX.Element,
    key: 'AdminDatabaseIncidentReportsListPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="car-burst"
      />
    ),
    url: ApplicationPages.AdminDatabaseIncidentReportsListPage.url(),
    page_role_access_permissions: {
      Installer_admin: {
        access: 'always_yes',
        highlighted_nav_section: 'VehicleAndSafetySection',
        highlighted_nav_page: 'AdminDatabaseIncidentReportsListPage',
      },
      Installer_general_manager: {
        access: 'default_yes',
        highlighted_nav_section: 'VehicleAndSafetySection',
        highlighted_nav_page: 'AdminDatabaseIncidentReportsListPage',
      },
      Installer_warehouse: {
        access: 'default_yes',
        highlighted_nav_section: 'VehicleAndSafetySection',
        highlighted_nav_page: 'AdminDatabaseIncidentReportsListPage',
      },
    },
    nav_badges: {},
  },
  // Accounting
  AdminDatabaseLeaderboardIndexPage: {
    name: rLIB('Leaderboard') as JSX.Element,
    key: 'AdminDatabaseLeaderboardIndexPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="ranking-star"
      />
    ),
    url: ApplicationPages.AdminDatabaseLeaderboardIndexPage.url(),
    page_role_access_permissions: {
      Installer_accounts_payable: {
        access: 'default_yes',
        highlighted_nav_section: 'StatsSection',
        highlighted_nav_page: 'AdminDatabaseLeaderboardIndexPage',
      },
      // Installer_accounts_receivable: {
      //   access: 'default_yes',
      //   highlighted_nav_section: 'StatsSection',
      //   highlighted_nav_page: 'AdminDatabaseLeaderboardIndexPage',
      // },
      Installer_admin: { access: 'always_yes', highlighted_nav_section: 'StatsSection', highlighted_nav_page: 'AdminDatabaseLeaderboardIndexPage' },
      Installer_construction_lead: {
        access: 'default_yes',
        highlighted_nav_section: 'StatsSection',
        highlighted_nav_page: 'AdminDatabaseLeaderboardIndexPage',
      },
      Installer_css_manager: { access: 'default_yes', highlighted_nav_section: 'StatsSection', highlighted_nav_page: 'AdminDatabaseLeaderboardIndexPage' },
      Installer_css_rep: { access: 'default_yes', highlighted_nav_section: 'StatsSection', highlighted_nav_page: 'AdminDatabaseLeaderboardIndexPage' },
      Installer_general_manager: {
        access: 'default_yes',
        highlighted_nav_section: 'StatsSection',
        highlighted_nav_page: 'AdminDatabaseLeaderboardIndexPage',
      },
      Installer_install_coordinator: {
        access: 'default_yes',
        highlighted_nav_section: 'StatsSection',
        highlighted_nav_page: 'AdminDatabaseLeaderboardIndexPage',
      },
      Installer_interconnection: {
        access: 'default_yes',
        highlighted_nav_section: 'StatsSection',
        highlighted_nav_page: 'AdminDatabaseLeaderboardIndexPage',
      },
      Installer_permitting: { access: 'default_yes', highlighted_nav_section: 'StatsSection', highlighted_nav_page: 'AdminDatabaseLeaderboardIndexPage' },
      Installer_warehouse: { access: 'default_yes', highlighted_nav_section: 'StatsSection', highlighted_nav_page: 'AdminDatabaseLeaderboardIndexPage' },
    },
    nav_badges: {},
  },
  AdminInvoiceIndexPage: {
    name: rLIB('Invoicing') as JSX.Element,
    key: 'AdminInvoiceIndexPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="file-invoice"
      />
    ),
    url: ApplicationPages.AdminInvoiceIndexPage.url(),
    page_role_access_permissions: {
      // ONLY Payroll Users
      Installer_admin: { access: 'always_no', highlighted_nav_section: 'FinanceSection', highlighted_nav_page: 'AdminInvoiceIndexPage' },
      // Installer_accounts_payable: { access: 'default_yes', highlighted_nav_section: 'FinanceSection', highlighted_nav_page: 'AdminInvoiceIndexPage' },
      Installer_accounts_receivable: { access: 'default_yes', highlighted_nav_section: 'FinanceSection', highlighted_nav_page: 'AdminInvoiceIndexPage' },
    },
    nav_badges: {},
  },
  AdminPayrollIndexPage: {
    name: rLIB('Payroll') as JSX.Element,
    key: 'AdminPayrollIndexPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="money-check-dollar-pen"
      />
    ),
    url: ApplicationPages.AdminPayrollIndexPage.url(),
    page_role_access_permissions: {
      // ONLY Payroll Users
      Installer_admin: { access: 'always_no', highlighted_nav_section: 'FinanceSection', highlighted_nav_page: 'AdminPayrollIndexPage' },
      Installer_accounts_payable: { access: 'default_yes', highlighted_nav_section: 'FinanceSection', highlighted_nav_page: 'AdminPayrollIndexPage' },
      // Installer_accounts_receivable: { access: 'default_yes', highlighted_nav_section: 'FinanceSection', highlighted_nav_page: 'AdminPayrollIndexPage' },
    },
    nav_badges: {},
  },
  AdminFinanceSpendingIndexPage: {
    name: rLIB('Spending') as JSX.Element,
    key: 'AdminFinanceSpendingIndexPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="credit-card"
      />
    ),
    url: ApplicationPages.AdminFinanceSpendingIndexPage.url(),
    page_role_access_permissions: {
      Installer_admin: { access: 'default_yes', highlighted_nav_section: 'FinanceSection', highlighted_nav_page: 'AdminFinanceSpendingIndexPage' },
      Installer_accounts_payable: {
        access: 'default_yes',
        highlighted_nav_section: 'FinanceSection',
        highlighted_nav_page: 'AdminFinanceSpendingIndexPage',
      },
      Installer_accounts_receivable: {
        access: 'default_yes',
        highlighted_nav_section: 'FinanceSection',
        highlighted_nav_page: 'AdminFinanceSpendingIndexPage',
      },
    },
    nav_badges: {},
  },
  // Entities
  AdminDatabaseHoasListPage: {
    name: rLIB('HOAs') as JSX.Element,
    key: 'AdminDatabaseHoasListPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="house-flag"
      />
    ),
    url: ApplicationPages.AdminDatabaseHoasListPage.url(),
    page_role_access_permissions: {
      Installer_admin: { access: 'always_yes', highlighted_nav_section: 'MasterDataSection', highlighted_nav_page: 'AdminDatabaseHoasListPage' },
      Installer_construction_lead: { access: 'default_yes', highlighted_nav_section: 'MasterDataSection', highlighted_nav_page: 'AdminDatabaseHoasListPage' },
      Installer_css_manager: { access: 'default_yes', highlighted_nav_section: 'MasterDataSection', highlighted_nav_page: 'AdminDatabaseHoasListPage' },
      Installer_css_rep: { access: 'default_yes', highlighted_nav_section: 'MasterDataSection', highlighted_nav_page: 'AdminDatabaseHoasListPage' },
      Installer_general_manager: { access: 'default_yes', highlighted_nav_section: 'MasterDataSection', highlighted_nav_page: 'AdminDatabaseHoasListPage' },
      Installer_install_coordinator: { access: 'default_yes', highlighted_nav_section: 'MasterDataSection', highlighted_nav_page: 'AdminDatabaseHoasListPage' },
      Installer_interconnection: { access: 'default_yes', highlighted_nav_section: 'MasterDataSection', highlighted_nav_page: 'AdminDatabaseHoasListPage' },
      Installer_permitting: { access: 'default_yes', highlighted_nav_section: 'MasterDataSection', highlighted_nav_page: 'AdminDatabaseHoasListPage' },
    },
    nav_badges: {},
  },
  AdminDatabaseJurisdictionsListPage: {
    name: rLIB('Jurisdictions') as JSX.Element,
    key: 'AdminDatabaseJurisdictionsListPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="gavel"
      />
    ),
    url: ApplicationPages.AdminDatabaseJurisdictionsListPage.url(),
    page_role_access_permissions: {
      Installer_subcontractor_design: {
        access: 'always_yes',
        highlighted_nav_section: 'MasterDataSection',
        highlighted_nav_page: 'AdminDatabaseJurisdictionsListPage',
      },
      Installer_admin: { access: 'always_yes', highlighted_nav_section: 'MasterDataSection', highlighted_nav_page: 'AdminDatabaseJurisdictionsListPage' },
      Installer_construction_lead: {
        access: 'default_yes',
        highlighted_nav_section: 'MasterDataSection',
        highlighted_nav_page: 'AdminDatabaseJurisdictionsListPage',
      },
      Installer_css_manager: {
        access: 'default_yes',
        highlighted_nav_section: 'MasterDataSection',
        highlighted_nav_page: 'AdminDatabaseJurisdictionsListPage',
      },
      Installer_css_rep: { access: 'default_yes', highlighted_nav_section: 'MasterDataSection', highlighted_nav_page: 'AdminDatabaseJurisdictionsListPage' },
      Installer_general_manager: {
        access: 'default_yes',
        highlighted_nav_section: 'MasterDataSection',
        highlighted_nav_page: 'AdminDatabaseJurisdictionsListPage',
      },
      Installer_install_coordinator: {
        access: 'default_yes',
        highlighted_nav_section: 'MasterDataSection',
        highlighted_nav_page: 'AdminDatabaseJurisdictionsListPage',
      },
      Installer_interconnection: {
        access: 'default_yes',
        highlighted_nav_section: 'MasterDataSection',
        highlighted_nav_page: 'AdminDatabaseJurisdictionsListPage',
      },
      Installer_permitting: { access: 'default_yes', highlighted_nav_section: 'MasterDataSection', highlighted_nav_page: 'AdminDatabaseJurisdictionsListPage' },
    },
    nav_badges: {},
  },
  AdminDatabaseRegionsListPage: {
    name: rLIB('Regions') as JSX.Element,
    key: 'AdminDatabaseRegionsListPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="compass"
      />
    ),
    url: ApplicationPages.AdminDatabaseRegionsListPage.url(),
    page_role_access_permissions: {
      Installer_admin: { access: 'always_yes', highlighted_nav_section: 'MasterDataSection', highlighted_nav_page: 'AdminDatabaseRegionsListPage' },
    },
    nav_badges: {},
  },
  AdminDatabaseUtilitiesListPage: {
    name: rLIB('Utilities') as JSX.Element,
    key: 'AdminDatabaseUtilitiesListPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="transformer-bolt"
      />
    ),
    url: ApplicationPages.AdminDatabaseUtilitiesListPage.url(),
    page_role_access_permissions: {
      Installer_admin: { access: 'always_yes', highlighted_nav_section: 'MasterDataSection', highlighted_nav_page: 'AdminDatabaseUtilitiesListPage' },
      Installer_construction_lead: {
        access: 'default_yes',
        highlighted_nav_section: 'MasterDataSection',
        highlighted_nav_page: 'AdminDatabaseUtilitiesListPage',
      },
      Installer_css_manager: { access: 'default_yes', highlighted_nav_section: 'MasterDataSection', highlighted_nav_page: 'AdminDatabaseUtilitiesListPage' },
      Installer_css_rep: { access: 'default_yes', highlighted_nav_section: 'MasterDataSection', highlighted_nav_page: 'AdminDatabaseUtilitiesListPage' },
      Installer_general_manager: {
        access: 'default_yes',
        highlighted_nav_section: 'MasterDataSection',
        highlighted_nav_page: 'AdminDatabaseUtilitiesListPage',
      },
      Installer_install_coordinator: {
        access: 'default_yes',
        highlighted_nav_section: 'MasterDataSection',
        highlighted_nav_page: 'AdminDatabaseUtilitiesListPage',
      },
      Installer_interconnection: {
        access: 'default_yes',
        highlighted_nav_section: 'MasterDataSection',
        highlighted_nav_page: 'AdminDatabaseUtilitiesListPage',
      },
      Installer_permitting: { access: 'default_yes', highlighted_nav_section: 'MasterDataSection', highlighted_nav_page: 'AdminDatabaseUtilitiesListPage' },
    },
    nav_badges: {},
  },
  // Task Settings
  AdminDatabaseTasksListPage: {
    name: rLIB('Task Library') as JSX.Element,
    key: 'AdminDatabaseTasksListPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="square-check"
      />
    ),
    url: ApplicationPages.AdminDatabaseTasksListPage.url(),
    page_role_access_permissions: {
      Installer_admin: { access: 'always_yes', highlighted_nav_section: 'WorkflowsSection', highlighted_nav_page: 'AdminDatabaseTasksListPage' },
    },
    nav_badges: {},
  },
  AdminDatabaseTaskFormsListPage: {
    name: rLIB('Task Forms') as JSX.Element,
    key: 'AdminDatabaseTaskFormsListPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="clipboard-list-check"
      />
    ),
    url: ApplicationPages.AdminDatabaseTaskFormsListPage.url(),
    page_role_access_permissions: {
      Installer_admin: { access: 'always_yes', highlighted_nav_section: 'WorkflowsSection', highlighted_nav_page: 'AdminDatabaseTaskFormsListPage' },
    },
    nav_badges: {},
  },
  AdminDatabaseTaskWorkflowListPage: {
    name: rLIB('Task Workflows') as JSX.Element,
    key: 'AdminDatabaseTaskWorkflowListPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="sitemap"
      />
    ),
    url: ApplicationPages.AdminDatabaseTaskWorkflowListPage.url(),
    page_role_access_permissions: {
      Installer_admin: { access: 'always_yes', highlighted_nav_section: 'WorkflowsSection', highlighted_nav_page: 'AdminDatabaseTaskWorkflowListPage' },
    },
    nav_badges: {},
  },

  AdminOutboundCommunicationIndexPage: {
    name: rLIB('Message Library') as JSX.Element,
    key: 'AdminOutboundCommunicationIndexPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="envelope"
      />
    ),
    url: ApplicationPages.AdminOutboundCommunicationIndexPage.url(),
    page_role_access_permissions: {
      Installer_admin: { access: 'always_yes', highlighted_nav_section: 'WorkflowsSection', highlighted_nav_page: 'AdminOutboundCommunicationIndexPage' },
    },
    nav_badges: {},
  },
  // Employees
  AdminDatabaseCompanyDocumentsPage: {
    name: rLIB('Company Documents') as JSX.Element,
    key: 'AdminDatabaseCompanyDocumentsPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="folder-open"
      />
    ),
    url: ApplicationPages.AdminDatabaseCompanyDocumentsPage.url(),
    page_role_access_permissions: {
      Installer_accounts_payable: {
        access: 'default_yes',
        highlighted_nav_section: 'EmployeesSection',
        highlighted_nav_page: 'AdminDatabaseCompanyDocumentsPage',
      },
      // Installer_accounts_receivable: {
      //   access: 'default_yes',
      //   highlighted_nav_section: 'EmployeesSection',
      //   highlighted_nav_page: 'AdminDatabaseCompanyDocumentsPage',
      // },
      Installer_admin: { access: 'always_yes', highlighted_nav_section: 'EmployeesSection', highlighted_nav_page: 'AdminDatabaseCompanyDocumentsPage' },
      Installer_construction_lead: {
        access: 'default_yes',
        highlighted_nav_section: 'EmployeesSection',
        highlighted_nav_page: 'AdminDatabaseCompanyDocumentsPage',
      },
      Installer_css_manager: { access: 'default_yes', highlighted_nav_section: 'EmployeesSection', highlighted_nav_page: 'AdminDatabaseCompanyDocumentsPage' },
      Installer_css_rep: { access: 'default_yes', highlighted_nav_section: 'EmployeesSection', highlighted_nav_page: 'AdminDatabaseCompanyDocumentsPage' },
      Installer_general_manager: {
        access: 'default_yes',
        highlighted_nav_section: 'EmployeesSection',
        highlighted_nav_page: 'AdminDatabaseCompanyDocumentsPage',
      },
      Installer_install_coordinator: {
        access: 'default_yes',
        highlighted_nav_section: 'EmployeesSection',
        highlighted_nav_page: 'AdminDatabaseCompanyDocumentsPage',
      },
      Installer_interconnection: {
        access: 'default_yes',
        highlighted_nav_section: 'EmployeesSection',
        highlighted_nav_page: 'AdminDatabaseCompanyDocumentsPage',
      },
      Installer_permitting: { access: 'default_yes', highlighted_nav_section: 'EmployeesSection', highlighted_nav_page: 'AdminDatabaseCompanyDocumentsPage' },
      Installer_warehouse: { access: 'default_yes', highlighted_nav_section: 'EmployeesSection', highlighted_nav_page: 'AdminDatabaseCompanyDocumentsPage' },
    },
    nav_badges: {},
  },
  AdminDatabaseToolsListPage: {
    name: rLIB('Tools') as JSX.Element,
    key: 'AdminDatabaseToolsListPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="toolbox"
      />
    ),
    url: ApplicationPages.AdminDatabaseToolsListPage.url(),
    page_role_access_permissions: {
      Installer_admin: { access: 'always_yes', highlighted_nav_section: 'EmployeesSection', highlighted_nav_page: 'AdminDatabaseToolsListPage' },
      Installer_general_manager: { access: 'default_yes', highlighted_nav_section: 'EmployeesSection', highlighted_nav_page: 'AdminDatabaseToolsListPage' },
      Installer_warehouse: { access: 'default_yes', highlighted_nav_section: 'EmployeesSection', highlighted_nav_page: 'AdminDatabaseToolsListPage' },
    },
    nav_badges: {},
  },
  AdminDatabaseUserListPage: {
    name: rLIB('Users') as JSX.Element,
    key: 'AdminDatabaseUserListPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="users"
      />
    ),
    url: ApplicationPages.AdminDatabaseUserListPage.url(),
    page_role_access_permissions: {
      Installer_admin: { access: 'always_yes', highlighted_nav_section: 'MasterDataSection', highlighted_nav_page: 'AdminDatabaseUserListPage' },
    },
    nav_badges: {},
  },

  AdminDatabaseTeamsListPage: {
    name: rLIB('Teams') as JSX.Element,
    key: 'AdminDatabaseTeamsListPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="people-group"
      />
    ),
    url: ApplicationPages.AdminDatabaseTeamsListPage.url(),
    page_role_access_permissions: {
      Installer_admin: { access: 'always_yes', highlighted_nav_section: 'MasterDataSection', highlighted_nav_page: 'AdminDatabaseTeamsListPage' },
    },
    nav_badges: {},
  },
  // Tech
  AdminDatabaseAPIManagementIndexPage: {
    name: rLIB('API Management') as JSX.Element,
    key: 'AdminDatabaseAPIManagementIndexPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="code"
      />
    ),
    url: ApplicationPages.AdminDatabaseAPIManagementIndexPage.url(),
    page_role_access_permissions: {
      Installer_admin: { access: 'always_yes', highlighted_nav_section: 'SystemSection', highlighted_nav_page: 'AdminDatabaseAPIManagementIndexPage' },
      Installer_api_user: { access: 'default_yes', highlighted_nav_section: 'SystemSection', highlighted_nav_page: 'AdminDatabaseAPIManagementIndexPage' },
    },
    nav_badges: {},
  },
  AdminCronjobManagementIndexPage: {
    name: rLIB('Cronjobs') as JSX.Element,
    key: 'AdminCronjobManagementIndexPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="alarm-clock"
      />
    ),
    url: ApplicationPages.AdminCronjobManagementIndexPage.url(),
    page_role_access_permissions: {
      Installer_admin: { access: 'always_yes', highlighted_nav_section: 'SystemSection', highlighted_nav_page: 'AdminCronjobManagementIndexPage' },
    },
    nav_badges: {},
  },
  AutomaticDataImportPages: {
    name: rLIB('Data Imports') as JSX.Element,
    key: 'AutomaticDataImportPages',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="cloud-arrow-up"
      />
    ),
    url: ApplicationPages.AutomaticDataImportPages.url(),
    page_role_access_permissions: {
      Installer_admin: { access: 'always_yes', highlighted_nav_section: 'SystemSection', highlighted_nav_page: 'AutomaticDataImportPages' },
    },
    nav_badges: {},
  },

  IssueTrackerIndexPage: {
    name: rLIB('Dev Requests') as JSX.Element,
    key: 'IssueTrackerIndexPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="bug"
      />
    ),
    url: ApplicationPages.IssueTrackerIndexPage.url(),
    page_role_access_permissions: {
      Installer_admin: { access: 'always_yes', highlighted_nav_section: 'SystemSection', highlighted_nav_page: 'IssueTrackerIndexPage' },
    },
    nav_badges: {},
  },
  MachineLearningIndexPage: {
    name: rLIB('Machine Learning') as JSX.Element,
    key: 'MachineLearningIndexPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="robot"
      />
    ),
    url: ApplicationPages.MachineLearningIndexPage.url(),
    page_role_access_permissions: {
      Installer_admin: { access: 'always_yes', highlighted_nav_section: 'SystemSection', highlighted_nav_page: 'MachineLearningIndexPage' },
    },
    nav_badges: {},
  },
  // Sales Partner
  SalesProjectsListPage: {
    name: rLIB('Projects') as JSX.Element,
    key: 'SalesProjectsListPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="solar-panel"
      />
    ),
    url: ApplicationPages.SalesProjectsListPage.url(),
    page_role_access_permissions: {
      // Installer_admin: { access: 'always_yes', highlighted_nav_section: 'HomeSection', highlighted_nav_page: 'HomePage' },
      Installer_sales_partner_admin: { access: 'always_yes', highlighted_nav_section: 'SalesPortalSection', highlighted_nav_page: 'SalesProjectsListPage' },
    },
    nav_badges: {},
  },
  SalesTasksListPage: {
    name: rLIB('Tasks') as JSX.Element,
    key: 'SalesTasksListPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="clipboard-list-check"
      />
    ),
    url: ApplicationPages.SalesTasksListPage.url(),
    page_role_access_permissions: {
      Installer_sales_partner_admin: { access: 'always_yes', highlighted_nav_section: 'SalesPortalSection', highlighted_nav_page: 'SalesTasksListPage' },
    },
    nav_badges: { openTasks: true },
  },
  // Subcontractor
  SubKActiveTasksListPage: {
    name: rLIB('Tasks') as JSX.Element,
    key: 'SubKActiveTasksListPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="clipboard-list-check"
      />
    ),
    url: ApplicationPages.SubKActiveTasksListPage.url(),
    page_role_access_permissions: {
      Installer_subcontractor: { access: 'always_yes', highlighted_nav_section: 'SubKPortalSection', highlighted_nav_page: 'SubKActiveTasksListPage' },
      Installer_subcontractor_construction: {
        access: 'always_yes',
        highlighted_nav_section: 'SubKPortalSection',
        highlighted_nav_page: 'SubKActiveTasksListPage',
      },
      Installer_subcontractor_design: { access: 'always_yes', highlighted_nav_section: 'SubKPortalSection', highlighted_nav_page: 'SubKActiveTasksListPage' },
      Installer_subcontractor_permitting: {
        access: 'always_yes',
        highlighted_nav_section: 'SubKPortalSection',
        highlighted_nav_page: 'SubKActiveTasksListPage',
      },
      Installer_subcontractor_repair: { access: 'always_yes', highlighted_nav_section: 'SubKPortalSection', highlighted_nav_page: 'SubKActiveTasksListPage' },
      Installer_subcontractor_roofing: { access: 'always_yes', highlighted_nav_section: 'SubKPortalSection', highlighted_nav_page: 'SubKActiveTasksListPage' },
      Installer_subcontractor_warehouse: {
        access: 'always_yes',
        highlighted_nav_section: 'SubKPortalSection',
        highlighted_nav_page: 'SubKActiveTasksListPage',
      },
    },
    nav_badges: { openTasks: true },
  },
  // Developer
  SuperClientPermissionsManagementPage: {
    // desktop_page_access_Development_SuperSection_SuperClientPermissionsManagementPage
    name: rLIB('Client Permissions') as JSX.Element,
    key: 'SuperClientPermissionsManagementPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="square-check"
      />
    ),
    url: ApplicationPages.SuperClientPermissionsManagementPage.url(),
    page_role_access_permissions: {
      Development_admin: { access: 'always_yes', highlighted_nav_section: 'SuperSection', highlighted_nav_page: 'SuperClientPermissionsManagementPage' },
    },
    nav_badges: {},
  },
  SuperDatabaseManagementPage: {
    name: rLIB('Database Mgmt') as JSX.Element,
    key: 'SuperDatabaseManagementPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="database"
      />
    ),
    url: ApplicationPages.SuperDatabaseManagementPage.url(),
    page_role_access_permissions: {
      Development_admin: { access: 'always_yes', highlighted_nav_section: 'SuperSection', highlighted_nav_page: 'SuperDatabaseManagementPage' },
    },
    nav_badges: {},
  },
  SuperTestLabPage: {
    name: rLIB('Test Lab') as JSX.Element,
    key: 'SuperTestLabPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="flask"
      />
    ),
    url: ApplicationPages.SuperTestLabPage.url(),
    page_role_access_permissions: {
      Development_admin: { access: 'always_yes', highlighted_nav_section: 'SuperSection', highlighted_nav_page: 'SuperTestLabPage' },
    },
    nav_badges: {},
  },
  // Boilerplate
  BoilerplateDocsPage: {
    name: rLIB('Boilerplate Docs') as JSX.Element,
    key: 'BoilerplateDocsPage',
    icon: (
      <Icon
        size="lg"
        type="light"
        icon="book"
      />
    ),
    url: ApplicationPages.BoilerplateDocsPage.url(),
    page_role_access_permissions: {
      Development_admin: { access: 'always_yes', highlighted_nav_section: 'BoilerplateDocsSection', highlighted_nav_page: 'BoilerplateDocsPage' },
    },
    nav_badges: {},
  },
}

//=========================//
//   USED BY BOILERPLATE   //
//=========================//
export const EmptyApplicationNavigationObject: TsInterface_ApplicationNavSections = {
  // HomeSection: {
  // 	name: rLIB("Home"),
  // 	key: "HomeSection",
  // 	links: {
  // 		HomePage: ApplicationNavPages.HomePage,
  // 	}
  // },
}

//=========================//
//   USED BY BOILERPLATE   //
//=========================//
export const generateApplicationNavigationObject = (
  clientType: string,
  userRole: string,
  rootClientUser: TsInterface_RootData_ClientUser,
): TsInterface_SideBarNavObject => {
  let sideBarNavObject: TsInterface_SideBarNavObject = {}
  for (let rootNavPageKey in ApplicationNavPages) {
    let rootNavPage: TsInterface_NavPage = ApplicationNavPages[rootNavPageKey]
    let userRoleAccess = getProp(rootNavPage['page_role_access_permissions'][clientType + '_' + userRole], 'access', false)
    let userRoleKeyWithAccess = userRole
    let taskRoles = getProp(rootClientUser, 'task_roles', {})
    for (let taskRoleKey in taskRoles) {
      if (
        taskRoles[taskRoleKey] === true &&
        userRoleAccess !== 'default_yes' &&
        userRoleAccess !== 'always_yes' &&
        (getProp(rootNavPage['page_role_access_permissions'][clientType + '_' + taskRoleKey], 'access', false) === 'default_yes' ||
          getProp(rootNavPage['page_role_access_permissions'][clientType + '_' + taskRoleKey], 'access', false) === 'always_yes')
      ) {
        userRoleAccess = getProp(rootNavPage['page_role_access_permissions'][clientType + '_' + taskRoleKey], 'access', false)
        userRoleKeyWithAccess = taskRoleKey
      }
    }
    if (userRoleAccess !== false) {
      let userSpecificRootNavPage = rootNavPage['page_role_access_permissions'][clientType + '_' + userRoleKeyWithAccess]
      if (
        sideBarNavObject[userSpecificRootNavPage.highlighted_nav_section] == null &&
        ApplicationNavSections[userSpecificRootNavPage.highlighted_nav_section] != null
      ) {
        sideBarNavObject[userSpecificRootNavPage.highlighted_nav_section] = ApplicationNavSections[userSpecificRootNavPage.highlighted_nav_section]
      }
      sideBarNavObject[userSpecificRootNavPage.highlighted_nav_section]['links'][userSpecificRootNavPage.highlighted_nav_page] = rootNavPage
    }
  }
  return sideBarNavObject
}

export const generateAvailableClientLevelPermissions = (rootClientUser: TsInterface_RootData_ClientUser): TsInterface_AvailableClientTypePermissionsObject => {
  let availableClientTypePermissions: TsInterface_AvailableClientTypePermissionsObject = {}
  // Loop through each client type to
  for (let clientTypeKey in ClientTypes) {
    availableClientTypePermissions[clientTypeKey] = {}
    let clientTypeUserRoles = ClientTypes[clientTypeKey]['user_roles']
    for (let userRoleKey in clientTypeUserRoles) {
      let emulatedNav = generateApplicationNavigationObject(clientTypeKey, userRoleKey, rootClientUser)
      for (let emulatedSectionKey in emulatedNav) {
        let emulatedSection = emulatedNav[emulatedSectionKey]
        if (availableClientTypePermissions[clientTypeKey][emulatedSectionKey] == null) {
          availableClientTypePermissions[clientTypeKey][emulatedSectionKey] = {
            sectionKey: emulatedSectionKey,
            sectionName: emulatedSection.name,
            permissions: {},
          }
        }
        for (let emulatedRootNavLinkKey in emulatedSection['links']) {
          let emulatedRootNavLink = emulatedSection['links'][emulatedRootNavLinkKey]
          let compositePermissionKey = 'desktop_page_access_' + clientTypeKey + '_' + emulatedSectionKey + '_' + emulatedRootNavLinkKey
          let userRoleWithAccess = false
          for (let roleKey in ApplicationNavPages[emulatedRootNavLinkKey]['page_role_access_permissions']) {
            if (roleKey.substring(0, clientTypeKey.length) === clientTypeKey) {
              if (
                ApplicationNavPages[emulatedRootNavLinkKey]['page_role_access_permissions'][roleKey] != null &&
                ApplicationNavPages[emulatedRootNavLinkKey]['page_role_access_permissions'][roleKey]['access'] !== false
              ) {
                userRoleWithAccess = true
              }
            }
          }
          if (userRoleWithAccess === true) {
            availableClientTypePermissions[clientTypeKey][emulatedSectionKey]['permissions'][compositePermissionKey] = {
              key: compositePermissionKey,
              pageName: emulatedRootNavLink.name,
            }
          }
        }
      }
    }
  }
  return availableClientTypePermissions
}

export const generateAvailableUserLevelPermissions = (
  userRole: TsType_UserRoles,
  clientType: TsType_ClientTypes,
  rootClientPermissions: TsInterface_RootData_ClientPermissions,
  rootClientUser: TsInterface_RootData_ClientUser,
): TsInterface_AvailableUserTypePermissionsObject => {
  let availableUserTypePermissionsObject: TsInterface_AvailableUserTypePermissionsObject = {}
  let flatUserPermissions: TsInterface_FlatUserPermissions = {}
  if (userRole != null && clientType != null && rootClientPermissions != null) {
    let emulatedNav = generateApplicationNavigationObject(clientType, userRole, rootClientUser)
    // Loop through top level nav pages (which permissions are based on)
    for (let navPageKey in ApplicationNavPages) {
      let navPage = ApplicationNavPages[navPageKey]
      // If the nav page has an access permission for the authenticated user's client type and user role
      if (navPage['page_role_access_permissions'] != null && navPage['page_role_access_permissions'][clientType + '_' + userRole]) {
        let pageRoleAccessPermissions = navPage['page_role_access_permissions'][clientType + '_' + userRole]
        // If access is granted at the client level
        let compositePermissionOverrideKey =
          'desktop_page_access_' +
          clientType +
          '_' +
          pageRoleAccessPermissions['highlighted_nav_section'] +
          '_' +
          pageRoleAccessPermissions['highlighted_nav_page']
        if (
          pageRoleAccessPermissions['highlighted_nav_section'] != null &&
          pageRoleAccessPermissions['highlighted_nav_page'] != null &&
          rootClientPermissions[compositePermissionOverrideKey]
        ) {
          if (pageRoleAccessPermissions != null && pageRoleAccessPermissions['access']) {
            // Just show the permissions that the client AND user role have access to
            flatUserPermissions[compositePermissionOverrideKey] = {
              key: compositePermissionOverrideKey,
              access: pageRoleAccessPermissions['access'],
            }
          }
        }
      }
    }
    for (let emulatedSectionKey in emulatedNav) {
      let emulatedSection = emulatedNav[emulatedSectionKey]
      if (availableUserTypePermissionsObject[emulatedSectionKey] == null) {
        availableUserTypePermissionsObject[emulatedSectionKey] = {
          sectionKey: emulatedSectionKey,
          sectionName: emulatedSection.name,
          permissions: {},
        }
      }
      for (let emulatedRootNavLinkKey in emulatedSection['links']) {
        let emulatedRootNavLink = emulatedSection['links'][emulatedRootNavLinkKey]
        let compositePermissionKey = 'desktop_page_access_' + clientType + '_' + emulatedSectionKey + '_' + emulatedRootNavLinkKey
        if (flatUserPermissions[compositePermissionKey] != null) {
          availableUserTypePermissionsObject[emulatedSectionKey]['permissions'][compositePermissionKey] = {
            access: flatUserPermissions[compositePermissionKey]['access'],
            permissionKey: compositePermissionKey,
            pageName: emulatedRootNavLink.name,
          }
        }
      }
    }
  }
  return availableUserTypePermissionsObject
}

//=========================//
//   USED BY BOILERPLATE   //
//=========================//
export const generateActiveUserApplicationPermissions = (
  rootClientUser: TsInterface_RootData_ClientUser,
  rootGlobalUser: TsInterface_RootData_GlobalUser,
  rootClientPermissions: TsInterface_RootData_ClientPermissions,
): Promise<TsInterface_GenerateActiveUserApplicationPermissionsResult> => {
  // permissions are of the form "desktop_page_access_$clientType_$sectionKey_$pageKey" where $clientType, $sectionKey and $pageKey values are all in camelCase
  return new Promise((resolve, reject) => {
    let permissions: TsInterface_PermissionObject = {
      HomePage: true,
    }
    let userRole = getProp(rootClientUser, 'user_role', null)
    let taskRoles = getProp(rootClientUser, 'task_roles', {})
    if (userRole == null) {
      userRole = getProp(rootGlobalUser, 'user_role', null)
    }
    let rolesToCheck: TsInterface_UnspecifiedObject = {}
    rolesToCheck[userRole] = userRole
    for (let taskRoleKey in taskRoles) {
      if (taskRoles[taskRoleKey] === true) {
        rolesToCheck[taskRoleKey] = taskRoleKey
      }
    }
    if (userRole != null && rootClientPermissions != null && rootClientPermissions['client_type'] != null) {
      let clientType = rootClientPermissions['client_type']
      // Loop through top level nav pages (which is what permissions are based on)
      for (let navPageKey in ApplicationNavPages) {
        let navPage = ApplicationNavPages[navPageKey]
        // Loop through user role and task roles
        for (let roleKey in rolesToCheck) {
          // If the nav page has an access permission for the authenticated user's client type and user role
          if (navPage['page_role_access_permissions'] != null && navPage['page_role_access_permissions'][clientType + '_' + roleKey]) {
            let pageRoleAccessPermissions = navPage['page_role_access_permissions'][clientType + '_' + roleKey]
            // If access is granted at the client level
            let compositePermissionOverrideKey =
              'desktop_page_access_' +
              clientType +
              '_' +
              pageRoleAccessPermissions['highlighted_nav_section'] +
              '_' +
              pageRoleAccessPermissions['highlighted_nav_page']

            if (
              pageRoleAccessPermissions['highlighted_nav_section'] != null &&
              pageRoleAccessPermissions['highlighted_nav_page'] != null &&
              rootClientPermissions[compositePermissionOverrideKey]
            ) {
              if (pageRoleAccessPermissions != null && pageRoleAccessPermissions['access']) {
                // Depending on user role and overrides, determine access boolean
                if (pageRoleAccessPermissions['access'] === 'always_yes') {
                  permissions[navPage.key] = true
                } else if (pageRoleAccessPermissions['access'] === 'default_yes') {
                  if (
                    rootClientUser != null &&
                    rootClientUser['permission_overrides'] != null &&
                    rootClientUser['permission_overrides'][compositePermissionOverrideKey] != null
                  ) {
                    if (permissions[navPage.key] !== true) {
                      permissions[navPage.key] = rootClientUser['permission_overrides'][compositePermissionOverrideKey]
                    }
                  } else {
                    permissions[navPage.key] = true
                  }
                } else if (pageRoleAccessPermissions['access'] === 'default_no') {
                  if (
                    rootClientUser != null &&
                    rootClientUser['permission_overrides'] != null &&
                    rootClientUser['permission_overrides'][compositePermissionOverrideKey] != null
                  ) {
                    if (permissions[navPage.key] !== true) {
                      permissions[navPage.key] = rootClientUser['permission_overrides'][compositePermissionOverrideKey]
                    }
                  } else {
                    if (permissions[navPage.key] !== true) {
                      permissions[navPage.key] = false
                    }
                  }
                } else if (pageRoleAccessPermissions['access'] === 'always_no') {
                  if (permissions[navPage.key] !== true) {
                    permissions[navPage.key] = false
                  }
                } else {
                  if (permissions[navPage.key] !== true) {
                    permissions[navPage.key] = false
                  }
                }
              } else {
                if (permissions[navPage.key] !== true) {
                  permissions[navPage.key] = false
                }
              }
            } else {
              if (permissions[navPage.key] !== true) {
                permissions[navPage.key] = false
              }
            }
          }
        }
      }
      permissions['HomePage'] = true
      permissions['UserSettingsPage'] = true
      resolve({
        success: true,
        permissions: permissions,
        error: {},
      })
    } else {
      resolve({
        success: false,
        permissions: {},
        error: { message: '', details: '', code: 'ER-D-SAS-GAUAP-01' },
      })
    }
  })
}
