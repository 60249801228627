///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Model files contains data and business logic specific to an individual database collection type

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material/'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { generateAvailableUserLevelPermissions, TsType_ClientTypes, TsType_UserRoles } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_ClientUser_Document } from 'rfbp_aux/services/database_endpoints/standard_database_endpoints'
import {
  TsInterface_DataViewerAdditionalData,
  TsInterface_DataViewerData,
  TsInterface_DataViewerLines,
  TsInterface_DataViewerSettings,
  ViewerLineBasicData,
  ViewerLineHeader,
  ViewerLinePhone,
  ViewerLineSpacer,
} from 'rfbp_core/components/data_viewer'
import { TsInterface_DataViewerHooksObject } from 'rfbp_core/components/data_viewer/data_viewer_types'
import { ViewerLineTimestampData } from 'rfbp_core/components/data_viewer/lines/viewer_line_timestamp_data'
import { TsInterface_FormInputs, TsType_FormSubmission } from 'rfbp_core/components/form'
import { rLIB } from 'rfbp_core/localization/library'
import { TsInterface_RootData_ClientPermissions } from 'rfbp_core/services/context'
import { DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Forms
const formInputs_DriverLicenseNumber: TsInterface_FormInputs = {
  drivers_license_number: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'drivers_license_number',
    label: rLIB("Driver's License Number"),
    required: true,
  },
}

const formSubmission_UserDataViewer: TsType_FormSubmission = (formSubmittedData, formAdditionalData, formHooks) => {
  return new Promise((resolve, reject) => {
    getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseSetMergeDocument(DatabaseRef_ClientUser_Document(res_GCK.clientKey, formAdditionalData.userKey as string), formSubmittedData)
          .then((res_DSMD) => {
            resolve(res_DSMD)
          })
          .catch((rej_DSMD) => {
            reject(rej_DSMD)
          })
      })
      .catch((rej_GCK) => {
        reject(rej_GCK)
      })
  })
}

const formInputs_DriverLicenseExpiration: TsInterface_FormInputs = {
  drivers_license_expiration: {
    data_type: 'string',
    input_type: 'timestamp_datetime',
    key: 'drivers_license_expiration',
    label: rLIB("Driver's License Expiration"),
    required: true,
  },
}

const formSubmission_DriverLicenseExpiration: TsType_FormSubmission = (formSubmittedData, formAdditionalData, formHooks) => {
  return new Promise((resolve, reject) => {
    let updateObject = {
      drivers_license_expiration: new Date(formSubmittedData.drivers_license_expiration),
    }
    getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseSetMergeDocument(DatabaseRef_ClientUser_Document(res_GCK.clientKey, formAdditionalData.userKey as string), updateObject)
          .then((res_DSMD) => {
            resolve(res_DSMD)
          })
          .catch((rej_DSMD) => {
            reject(rej_DSMD)
          })
      })
      .catch((rej_GCK) => {
        reject(rej_GCK)
      })
  })
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

export const viewerSettings_User: TsInterface_DataViewerSettings = {
  // Nothing
}

export const viewerLines_User: TsInterface_DataViewerLines = {
  header_user: ViewerLineHeader(rLIB('User')),
  name: ViewerLineBasicData(rLIB('User Name'), 'name', { editable: false }),
  email: ViewerLineBasicData(rLIB('Email'), 'email', { editable: false }),
  phone: ViewerLinePhone(rLIB('Phone'), 'phone'),
  user_role: ViewerLineBasicData(rLIB('User Type'), 'user_role', { editable: false }), // TODO - chip
  spacer1: ViewerLineSpacer(4),
  header_drivers_license: ViewerLineHeader(rLIB("Driver's License")),
  drivers_license_number: ViewerLineBasicData(rLIB("Driver's License Number"), 'drivers_license_number', {
    editable: true,
    formInputs: formInputs_DriverLicenseNumber,
    formSubmission: formSubmission_UserDataViewer,
    formHeader: rLIB("Edit Driver's License Number"),
    showMissingText: true,
  }),
  drivers_license_expiration: ViewerLineTimestampData(rLIB("Driver's License Expiration"), 'drivers_license_expiration', 'MM/DD/YYYY', {
    editable: true,
    formInputs: formInputs_DriverLicenseExpiration,
    formSubmission: formSubmission_DriverLicenseExpiration,
    formHeader: rLIB("Edit Driver's License Expiration"),
    showMissingText: true,
  }),
  spacer2: ViewerLineSpacer(4),
  header_permissions: ViewerLineHeader(rLIB('Permissions')),
  custom_permissions: {
    line_jsx: (
      viewerData: TsInterface_DataViewerData,
      viewerAdditionalData: TsInterface_DataViewerAdditionalData,
      viewerHooks: TsInterface_DataViewerHooksObject,
    ) => {
      // Function to toggle permission overrides
      const togglePermissionCheckbox = (event: React.ChangeEvent, permissionKey: string, value: boolean) => {
        if (viewerData == null) {
          viewerData = { permission_overrides: {} }
        }
        if (viewerData['permission_overrides'] == null) {
          viewerData['permission_overrides'] = {}
        }
        // @ts-expect-error - TODO: reason for error
        viewerData['permission_overrides'][permissionKey] = value
        // formInputChange(event, viewerData["permission_overrides"], "permission_overrides", "custom_form_input_jsx", formInput, {} )
        // formInputChange("permission_overrides", viewerData["permission_overrides"])
      }
      // Function to color checkboxes
      const returnCheckboxColor = (defaultOverrideBool: boolean, actualOverrideBool: boolean) => {
        let sx
        if (defaultOverrideBool === actualOverrideBool) {
          sx = { 'color': themeVariables.success_main, '&.Mui-checked': { color: themeVariables.success_main } }
        } else {
          sx = { 'color': themeVariables.error_main, '&.Mui-checked': { color: themeVariables.error_main } }
        }
        return sx
      }
      // Function to return checkbox
      const rJSX_Checkbox = (individualPermission: TsInterface_UnspecifiedObject) => {
        let checkboxJSX
        if (individualPermission.access === 'always_yes') {
          checkboxJSX = (
            <FormControlLabel
              control={
                <Checkbox
                  checked={getProp(viewerData['permission_overrides'], individualPermission.permissionKey, true)}
                  onChange={(event, value) => {
                    togglePermissionCheckbox(event, individualPermission.permissionKey, value)
                  }}
                  disabled
                />
              }
              label={individualPermission.pageName}
            />
          )
        } else if (individualPermission.access === 'default_yes') {
          checkboxJSX = (
            <FormControlLabel
              control={
                <Checkbox
                  checked={getProp(viewerData['permission_overrides'], individualPermission.permissionKey, true)}
                  onChange={(event, value) => {
                    togglePermissionCheckbox(event, individualPermission.permissionKey, value)
                  }}
                  sx={returnCheckboxColor(true, getProp(viewerData['permission_overrides'], individualPermission.permissionKey, true))}
                />
              }
              label={individualPermission.pageName}
            />
          )
        } else if (individualPermission.access === 'default_no') {
          checkboxJSX = (
            <FormControlLabel
              control={
                <Checkbox
                  checked={getProp(viewerData['permission_overrides'], individualPermission.permissionKey, false)}
                  onChange={(event, value) => {
                    togglePermissionCheckbox(event, individualPermission.permissionKey, value)
                  }}
                  sx={returnCheckboxColor(false, getProp(viewerData['permission_overrides'], individualPermission.permissionKey, false))}
                />
              }
              label={individualPermission.pageName}
            />
          )
        } else if (individualPermission.access === 'always_no') {
          checkboxJSX = (
            <FormControlLabel
              control={
                <Checkbox
                  checked={getProp(viewerData['permission_overrides'], individualPermission.permissionKey, false)}
                  onChange={(event, value) => {
                    togglePermissionCheckbox(event, individualPermission.permissionKey, value)
                  }}
                  disabled
                />
              }
              label={individualPermission.pageName}
            />
          )
        }
        return checkboxJSX
      }
      let inputJSX = <></>
      if (
        viewerData != null &&
        viewerData['user_role'] != null &&
        viewerAdditionalData != null &&
        viewerAdditionalData['client_type'] != null &&
        viewerAdditionalData['root_client_permissions'] != null
      ) {
        let userPermissionsList = generateAvailableUserLevelPermissions(
          viewerData['user_role'] as TsType_UserRoles,
          viewerAdditionalData['client_type'] as TsType_ClientTypes,
          viewerAdditionalData['root_client_permissions'] as unknown as TsInterface_RootData_ClientPermissions,
          viewerHooks.uc_RootData_ClientUser,
        )
        inputJSX = (
          <Box className="tw-mt-6">
            <Box
              component="div"
              className="tw-mt-1"
              sx={{ marginLeft: '24px' }}
            >
              {objectToArray(userPermissionsList).map((permissionSection) => (
                <Box
                  component="span"
                  key={permissionSection.sectionKey}
                >
                  <Typography
                    variant="subtitle1"
                    display="block"
                    color="primary"
                  >
                    {permissionSection.sectionName}
                  </Typography>
                  {objectToArray(permissionSection.permissions).map((permission) => (
                    <Box
                      component="span"
                      key={permission.permissionKey}
                    >
                      {rJSX_Checkbox(permission)}
                    </Box>
                  ))}
                </Box>
              ))}
            </Box>
          </Box>
        )
      } else {
        inputJSX = <Box></Box>
      }
      let permissionsJSX = <Box>{inputJSX}</Box>
      return permissionsJSX
    },
  },

  // TODO -  Permissions
}
