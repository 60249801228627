///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Model files contains data and business logic specific to an individual database collection type

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { schedulingTeamTypes } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_CancelledScheduledTask_Document } from 'rfbp_aux/services/database_endpoints/operations/cancelled_scheduled_tasks'
import { DatabaseRef_SchedulingTeams_Collection, DatabaseRef_SchedulingTeams_Document } from 'rfbp_aux/services/database_endpoints/operations/schedules'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
  TsType_FormOnChange,
  TsType_FormSubmission,
} from 'rfbp_core/components/form'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import { DatabaseAddDocument, DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Form Data
export const timeOptions = [
  // 7am
  { key: 7, value: '7:00am' },
  { key: 7.25, value: '7:15am' },
  { key: 7.5, value: '7:30am' },
  { key: 7.75, value: '7:45am' },
  // 8am
  { key: 8, value: '8:00am' },
  { key: 8.25, value: '8:15am' },
  { key: 8.5, value: '8:30am' },
  { key: 8.75, value: '8:45am' },
  // 9am
  { key: 9, value: '9:00am' },
  { key: 9.25, value: '9:15am' },
  { key: 9.5, value: '9:30am' },
  { key: 9.75, value: '9:45am' },
  // 10am
  { key: 10, value: '10:00am' },
  { key: 10.25, value: '10:15am' },
  { key: 10.5, value: '10:30am' },
  { key: 10.75, value: '10:45am' },
  // 11am
  { key: 11, value: '11:00am' },
  { key: 11.25, value: '11:15am' },
  { key: 11.5, value: '11:30am' },
  { key: 11.75, value: '11:45am' },
  // 12pm
  { key: 12, value: '12:00pm' },
  { key: 12.25, value: '12:15pm' },
  { key: 12.5, value: '12:30pm' },
  { key: 12.75, value: '12:45pm' },
  // 1pm
  { key: 13, value: '1:00pm' },
  { key: 13.25, value: '1:15pm' },
  { key: 13.5, value: '1:30pm' },
  { key: 13.75, value: '1:45pm' },
  // 2pm
  { key: 14, value: '2:00pm' },
  { key: 14.25, value: '2:15pm' },
  { key: 14.5, value: '2:30pm' },
  { key: 14.75, value: '2:45pm' },
  // 3pm
  { key: 15, value: '3:00pm' },
  { key: 15.25, value: '3:15pm' },
  { key: 15.5, value: '3:30pm' },
  { key: 15.75, value: '3:45pm' },
  // 4pm
  { key: 16, value: '4:00pm' },
  { key: 16.25, value: '4:15pm' },
  { key: 16.5, value: '4:30pm' },
  { key: 16.75, value: '4:45pm' },
  // 5pm
  { key: 17, value: '5:00pm' },
  { key: 17.25, value: '5:15pm' },
  { key: 17.5, value: '5:30pm' },
  { key: 17.75, value: '5:45pm' },
  // 6pm
  { key: 18, value: '6:00pm' },
  { key: 18.25, value: '6:15pm' },
  { key: 18.5, value: '6:30pm' },
  { key: 18.75, value: '6:45pm' },
  // 7pm
  { key: 19, value: '7:00pm' },
  { key: 19.25, value: '7:15pm' },
  { key: 19.5, value: '7:30pm' },
  { key: 19.75, value: '7:45pm' },
]

let timezoneOptions = {
  et: { key: 'et', value: rLIB('Eastern Time') },
  ct: { key: 'ct', value: rLIB('Central Time') },
  mt: { key: 'mt', value: rLIB('Mountain Time') },
  pt: { key: 'pt', value: rLIB('Pacific Time') },
}

///////////////////////////////
// Functions
///////////////////////////////

function convertToTime(num: number) {
  let hours = Math.floor(num)
  let minutes: string | number = Math.round((num - hours) * 60)
  let amOrPm = 'am'
  if (hours >= 12) {
    amOrPm = 'pm'
    hours = hours % 12
  }
  if (hours === 0) {
    hours = 12
  }
  if (minutes < 10) {
    minutes = '0' + minutes
  }
  return hours + ':' + minutes + ' ' + amOrPm
}

// export const defaultWorkHours: TsInterface_UnspecifiedObject = { // TODO - from dartabase with date overrides
// sun: { start_time: 0, end_time: 0,   },
// mon: { start_time: 8, end_time: 18,  },
// tue: { start_time: 8, end_time: 18,  },
// wed: { start_time: 8, end_time: 18,  },
// thu: { start_time: 8, end_time: 18,  },
// fri: { start_time: 8, end_time: 18,  },
// sat: { start_time: 0, end_time: 0,   },
// }

// Forms - Teams
export const formOnChange_ScheduleTeam: TsType_FormOnChange = (formAdditionalData, formData, formInputs, formSettings, formHooks) => {
  // Nothing
}

export const formSubmission_ScheduleTeamCreate: TsType_FormSubmission = (formSubmittedData, formAdditionalData, formHooks) => {
  return new Promise((resolve, reject) => {
    getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
      .then((res_GCK) => {
        formSubmittedData['associated_member_keys'] = {}
        formSubmittedData['associated_member_names'] = {}
        formSubmittedData['associated_member_roles'] = {}
        formSubmittedData['status'] = 'active'
        DatabaseAddDocument(DatabaseRef_SchedulingTeams_Collection(res_GCK.clientKey), formSubmittedData, true)
          .then((res_DAD) => {
            resolve(res_DAD)
          })
          .catch((rej_DAD) => {
            reject(rej_DAD)
          })
      })
      .catch((rej_GCK) => {
        reject(rej_GCK)
      })
  })
}

export const formSettings_ScheduleTeamCreate: TsInterface_FormSettings = {
  // highlight_missing: true,
  // submit_button_alignment: "right",
  // submit_button_hide: false,
  // submit_button_icon: <SaveIcon/>,
  // submit_button_saving_icon: true,
  // submit_button_text: <>{s_SAVE}</>,
}

export const formInputs_ScheduleTeamNew: TsInterface_FormInputs = {
  name: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'name',
    label: rLIB('Team Name'),
    required: true,
  },
  team_type: {
    data_type: 'string',
    input_type: 'multiple_choice_radio',
    key: 'team_type',
    label: rLIB('Team Type'),
    required: true,
    options: objectToArray(schedulingTeamTypes),
  },
}

export const formInputs_ScheduleTeamMembershipEdit: TsInterface_FormInputs = {
  // TODO
}

// Forms - Task Assignment
export const formSubmission_TaskAssignment = (
  formSubmittedData: TsInterface_FormSubmittedData,
  formAdditionalData: TsInterface_FormAdditionalData,
  formHooks: TsInterface_FormHooksObject,
) => {
  return new Promise((resolve, reject) => {
    // TODO - guard rails
    // Make sure that there is no overlap with existing tasks

    let errorsWithSubmission = false
    let errorMessage = <></>
    let errorCode = 'ER-D-ST-FSTA-01'
    // Check to make sure form data at first glance is not bad
    if (
      formSubmittedData.day_1_start_time == null ||
      formSubmittedData.day_1_end_time == null ||
      formSubmittedData.day_1_start_time >= formSubmittedData.day_1_end_time
    ) {
      errorsWithSubmission = true
      errorMessage = rLIB('Incorrectly formatted form data') as JSX.Element
      errorCode = 'ER-D-ST-FSTA-02'
    }
    if (
      formSubmittedData.day_2_display === true &&
      (formSubmittedData.day_2_start_time == null ||
        formSubmittedData.day_2_end_time == null ||
        formSubmittedData.day_2_start_time >= formSubmittedData.day_2_end_time)
    ) {
      errorsWithSubmission = true
      errorMessage = rLIB('Incorrectly formatted form data') as JSX.Element
      errorCode = 'ER-D-ST-FSTA-03'
    }
    if (
      formSubmittedData.day_3_display === true &&
      (formSubmittedData.day_3_start_time == null ||
        formSubmittedData.day_3_end_time == null ||
        formSubmittedData.day_3_start_time >= formSubmittedData.day_3_end_time)
    ) {
      errorsWithSubmission = true
      errorMessage = rLIB('Incorrectly formatted form data') as JSX.Element
      errorCode = 'ER-D-ST-FSTA-04'
    }
    if (
      formSubmittedData.day_4_display === true &&
      (formSubmittedData.day_4_start_time == null ||
        formSubmittedData.day_4_end_time == null ||
        formSubmittedData.day_4_start_time >= formSubmittedData.day_4_end_time)
    ) {
      errorsWithSubmission = true
      errorMessage = rLIB('Incorrectly formatted form data') as JSX.Element
      errorCode = 'ER-D-ST-FSTA-05'
    }
    if (
      formSubmittedData.day_5_display === true &&
      (formSubmittedData.day_5_start_time == null ||
        formSubmittedData.day_5_end_time == null ||
        formSubmittedData.day_5_start_time >= formSubmittedData.day_5_end_time)
    ) {
      errorsWithSubmission = true
      errorMessage = rLIB('Incorrectly formatted form data') as JSX.Element
      errorCode = 'ER-D-ST-FSTA-06'
    }
    // Make sure that none of the dates are matching
    let taskScheduledDatesObject: TsInterface_UnspecifiedObject = {}
    let taskScheduledStartTimesObject: TsInterface_UnspecifiedObject = {}
    let taskScheduledEndTimesObject: TsInterface_UnspecifiedObject = {}
    if (formSubmittedData.day_1_date != null) {
      taskScheduledDatesObject[formSubmittedData.day_1_date] = true
      taskScheduledStartTimesObject[formSubmittedData.day_1_date] = convertToTime(formSubmittedData.day_1_start_time)
      taskScheduledEndTimesObject[formSubmittedData.day_1_date] = convertToTime(formSubmittedData.day_1_end_time)
    }
    if (formSubmittedData.day_2_display === true && formSubmittedData.day_2_date != null) {
      if (taskScheduledDatesObject[formSubmittedData.day_2_date] == null) {
        taskScheduledDatesObject[formSubmittedData.day_2_date] = true
        taskScheduledStartTimesObject[formSubmittedData.day_2_date] = convertToTime(formSubmittedData.day_2_start_time)
        taskScheduledEndTimesObject[formSubmittedData.day_2_date] = convertToTime(formSubmittedData.day_2_end_time)
      } else {
        errorsWithSubmission = true
        errorMessage = rLIB('Duplicate dates used in form') as JSX.Element
        errorCode = 'ER-D-ST-FSTA-07'
      }
    }
    if (formSubmittedData.day_3_display === true && formSubmittedData.day_3_date != null) {
      if (taskScheduledDatesObject[formSubmittedData.day_3_date] == null) {
        taskScheduledDatesObject[formSubmittedData.day_3_date] = true
        taskScheduledStartTimesObject[formSubmittedData.day_3_date] = convertToTime(formSubmittedData.day_3_start_time)
        taskScheduledEndTimesObject[formSubmittedData.day_3_date] = convertToTime(formSubmittedData.day_3_end_time)
      } else {
        errorsWithSubmission = true
        errorMessage = rLIB('Duplicate dates used in form') as JSX.Element
        errorCode = 'ER-D-ST-FSTA-08'
      }
    }
    if (formSubmittedData.day_4_display === true && formSubmittedData.day_4_date != null) {
      if (taskScheduledDatesObject[formSubmittedData.day_4_date] == null) {
        taskScheduledDatesObject[formSubmittedData.day_4_date] = true
        taskScheduledStartTimesObject[formSubmittedData.day_4_date] = convertToTime(formSubmittedData.day_4_start_time)
        taskScheduledEndTimesObject[formSubmittedData.day_4_date] = convertToTime(formSubmittedData.day_4_end_time)
      } else {
        errorsWithSubmission = true
        errorMessage = rLIB('Duplicate dates used in form') as JSX.Element
        errorCode = 'ER-D-ST-FSTA-09'
      }
    }
    if (formSubmittedData.day_5_display === true && formSubmittedData.day_5_date != null) {
      if (taskScheduledDatesObject[formSubmittedData.day_5_date] == null) {
        taskScheduledDatesObject[formSubmittedData.day_5_date] = true
        taskScheduledStartTimesObject[formSubmittedData.day_5_date] = convertToTime(formSubmittedData.day_5_start_time)
        taskScheduledEndTimesObject[formSubmittedData.day_5_date] = convertToTime(formSubmittedData.day_5_end_time)
      } else {
        errorsWithSubmission = true
        errorMessage = rLIB('Duplicate dates used in form') as JSX.Element
        errorCode = 'ER-D-ST-FSTA-10'
      }
    }
    // If all errors are avoided
    if (errorsWithSubmission === false) {
      let taskScheduledDatesArray: string[] = []
      for (let loopDateKey in taskScheduledDatesObject) {
        taskScheduledDatesArray.push(loopDateKey)
      }
      // Cloud function to assign task
      getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
        .then((res_GCK) => {
          cloudFunctionManageRequest('manageTasks', {
            function: 'completeDispatchTask',
            client_key: res_GCK.clientKey,
            task_key: formAdditionalData.task_key,
            team_key: formAdditionalData.team_key,
            scheduled_days: taskScheduledDatesArray,
            start_times: taskScheduledStartTimesObject,
            end_times: taskScheduledEndTimesObject,
            time_slot_key: getProp(formAdditionalData, 'time_slot_key', null),
          })
            .then((res_CFMTR) => {
              resolve(res_CFMTR)
            })
            .catch((rej_CFMTR) => {
              reject(rej_CFMTR)
            })
        })
        .catch((rej_GCK) => {
          formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          reject({ success: false, error: rej_GCK.error })
        })
    } else {
      let error = {
        message: rLIB('Failed to assign task'),
        details: errorMessage,
        code: errorCode,
      }
      formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
      reject({ success: false, error: error })
    }
  })
}

export const formSettings_TaskAssignment: TsInterface_FormSettings = {
  // TODO
  // submit_button_theme: "primary",
  // submit_button_alignment: "center",
  // submit_button_hide: false,
  // submit_button_icon: <Icon icon="right-to-bracket"/>,
  // submit_button_saving_icon: true,
  // submit_button_text: <>{s_LOG_IN}</>,
}

export const formOnChange_TaskAssignment = (
  formAdditionalData: TsInterface_FormAdditionalData,
  formData: TsInterface_FormData,
  formInputs: TsInterface_FormInputs,
  formSettings: TsInterface_FormSettings,
) => {
  // Nothing
}

export const formInputs_TaskAssignmentDefinedTimeslots: TsInterface_FormInputs = {
  day_1_date: {
    data_type: 'string',
    input_type: 'timestamp_date',
    key: 'day_1_date',
    label: <>{rLIB('Date')}</>,
    required: true,
    disabled: true,
  },
  day_1_start_time: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'day_1_start_time',
    options: timeOptions,
    label: <>{rLIB('Start Time')}</>,
    required: true,
    disabled: true,
  },
  day_1_end_time: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'day_1_end_time',
    options: timeOptions,
    label: <>{rLIB('End Time')}</>,
    required: true,
    disabled: true,
  },
}

export const formInputs_TaskAssignmentFreeformTimeslots: TsInterface_FormInputs = {
  // Duration
  // TEMP_duration_display: {
  // data_type: "string",
  // key: "TEMP_duration_display",
  // input_type: "custom_form_input_jsx",
  // label: <></>,
  // required: false,
  // renderCustomFormInput: ( formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData ) => {
  // 	let calculatedTaskDurationHours = 0
  // 	if(formData != null){
  // 		if( formData.day_1_start_time != null && formData.day_1_end_time != null && formData.day_1_start_time < formData.day_1_end_time ){
  // 			let startTime: number = formData.day_1_start_time as number
  // 			let endTime: number = formData.day_1_end_time as number
  // 			calculatedTaskDurationHours += ( endTime - startTime )
  // 		}
  // 		if( formData.day_2_display === true && formData.day_2_start_time != null && formData.day_2_end_time != null && formData.day_2_start_time < formData.day_2_end_time ){
  // 			let startTime: number = formData.day_2_start_time as number
  // 			let endTime: number = formData.day_2_end_time as number
  // 			calculatedTaskDurationHours += ( endTime - startTime )
  // 		}
  // 		if( formData.day_2_display === true && formData.day_3_start_time != null && formData.day_3_end_time != null && formData.day_3_start_time < formData.day_3_end_time ){
  // 			let startTime: number = formData.day_3_start_time as number
  // 			let endTime: number = formData.day_3_end_time as number
  // 			calculatedTaskDurationHours += ( endTime - startTime )
  // 		}
  // 		if( formData.day_2_display === true && formData.day_4_start_time != null && formData.day_4_end_time != null && formData.day_4_start_time < formData.day_4_end_time ){
  // 			let startTime: number = formData.day_4_start_time as number
  // 			let endTime: number = formData.day_4_end_time as number
  // 			calculatedTaskDurationHours += ( endTime - startTime )
  // 		}
  // 		if( formData.day_2_display === true && formData.day_5_start_time != null && formData.day_5_end_time != null && formData.day_5_start_time < formData.day_5_end_time ){
  // 			let startTime: number = formData.day_5_start_time as number
  // 			let endTime: number = formData.day_5_end_time as number
  // 			calculatedTaskDurationHours += ( endTime - startTime )
  // 		}
  // 	}
  // 	// Compare Estimate vs Schedule
  // 	let estimatedHours = parseFloat(formAdditionalData.estimated_task_duration_hours as string)
  // 	let scheduledHourTextJSX = s_HOURS
  // 	if( calculatedTaskDurationHours === 1 ){
  // 		scheduledHourTextJSX = s_HOUR
  // 	}
  // 	let estimatedHourTextJSX = s_HOURS
  // 	if( estimatedHours === 1 ){
  // 		estimatedHourTextJSX = s_HOUR
  // 	}
  // 	let hourDiscrepancy = Math.abs( estimatedHours - calculatedTaskDurationHours )
  // 	let scheduledHoursSX = {}
  // 	if( hourDiscrepancy > 4 ){
  // 		scheduledHoursSX = { backgroundColor: themeVariables.error_main }
  // 	} else if( hourDiscrepancy > 1 ) {
  // 		scheduledHoursSX = { backgroundColor: themeVariables.warning_main }
  // 	} else {
  // 		scheduledHoursSX = { backgroundColor: themeVariables.success_wash }
  // 	}
  // 	// JSX
  // 	let inputJSX =
  // 	<Box className="tw-text-center">
  // 		<Typography variant="h6">{ s_ESTIMATED_HOURS }: { estimatedHours } { estimatedHourTextJSX }</Typography>
  // 		<Typography variant="h6" sx={ scheduledHoursSX }>{ s_SCHEDULED_HOURS }: { calculatedTaskDurationHours } { scheduledHourTextJSX }</Typography>
  // 	</Box>
  // 	return inputJSX
  // }
  // },
  // Day 1
  day_1_date: {
    data_type: 'string',
    input_type: 'timestamp_date',
    key: 'day_1_date',
    label: (
      <>
        {rLIB('Date')} ({rLIB('Day 1')})
      </>
    ),
    required: true,
  },
  day_1_start_time: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'day_1_start_time',
    options: timeOptions,
    label: (
      <>
        {rLIB('Start Time')} ({rLIB('Day 1')})
      </>
    ),
    required: true,
  },
  day_1_end_time: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'day_1_end_time',
    options: timeOptions,
    label: (
      <>
        {rLIB('End Time')} ({rLIB('Day 1')})
      </>
    ),
    required: true,
  },
  // Day 2
  day_2_display: {
    data_type: 'string',
    input_type: 'boolean_switch',
    key: 'day_2_display',
    label: <>{rLIB('Add Second Day')}</>,
    required: false,
  },
  day_2_date: {
    data_type: 'string',
    input_type: 'timestamp_date',
    key: 'day_2_date',
    label: (
      <>
        {rLIB('Date')} ({rLIB('Day 2')})
      </>
    ),
    required: false,
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'day_2_display',
        comparator: '==',
        value: true,
        conditions: [],
      },
    },
    conditional_require: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'day_2_display',
        comparator: '==',
        value: true,
        conditions: [],
      },
    },
  },
  day_2_start_time: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'day_2_start_time',
    options: timeOptions,
    label: (
      <>
        {rLIB('Start Time')} ({rLIB('Day 2')})
      </>
    ),
    required: false,

    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'day_2_display',
        comparator: '==',
        value: true,
        conditions: [],
      },
    },
    conditional_require: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'day_2_display',
        comparator: '==',
        value: true,
        conditions: [],
      },
    },
  },
  day_2_end_time: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'day_2_end_time',
    options: timeOptions,
    label: (
      <>
        {rLIB('End Time')} ({rLIB('Day 2')})
      </>
    ),
    required: false,
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'day_2_display',
        comparator: '==',
        value: true,
        conditions: [],
      },
    },
    conditional_require: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'day_2_display',
        comparator: '==',
        value: true,
        conditions: [],
      },
    },
  },
  // Day 3
  day_3_display: {
    data_type: 'string',
    input_type: 'boolean_switch',
    key: 'day_3_display',
    label: <>{rLIB('Add Third Day')}</>,
    required: false,
  },
  day_3_date: {
    data_type: 'string',
    input_type: 'timestamp_date',
    key: 'day_3_date',
    label: (
      <>
        {rLIB('Date')} ({rLIB('Day 3')})
      </>
    ),
    required: false,
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'day_3_display',
        comparator: '==',
        value: true,
        conditions: [],
      },
    },
    conditional_require: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'day_3_display',
        comparator: '==',
        value: true,
        conditions: [],
      },
    },
  },
  day_3_start_time: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'day_3_start_time',
    options: timeOptions,
    label: (
      <>
        {rLIB('Start Time')} ({rLIB('Day 3')})
      </>
    ),
    required: false,
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'day_3_display',
        comparator: '==',
        value: true,
        conditions: [],
      },
    },
    conditional_require: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'day_3_display',
        comparator: '==',
        value: true,
        conditions: [],
      },
    },
  },
  day_3_end_time: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'day_3_end_time',
    options: timeOptions,
    label: (
      <>
        {rLIB('End Time')} ({rLIB('Day 3')})
      </>
    ),
    required: false,
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'day_3_display',
        comparator: '==',
        value: true,
        conditions: [],
      },
    },
    conditional_require: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'day_3_display',
        comparator: '==',
        value: true,
        conditions: [],
      },
    },
  },
  // Day 4
  day_4_display: {
    data_type: 'string',
    input_type: 'boolean_switch',
    key: 'day_4_display',
    label: <>{rLIB('Add Fourth Day')}</>,
    required: false,
  },
  day_4_date: {
    data_type: 'string',
    input_type: 'timestamp_date',
    key: 'day_4_date',
    label: (
      <>
        {rLIB('Date')} ({rLIB('Day 4')})
      </>
    ),
    required: false,
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'day_4_display',
        comparator: '==',
        value: true,
        conditions: [],
      },
    },
    conditional_require: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'day_4_display',
        comparator: '==',
        value: true,
        conditions: [],
      },
    },
  },
  day_4_start_time: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'day_4_start_time',
    options: timeOptions,
    label: (
      <>
        {rLIB('Start Time')} ({rLIB('Day 4')})
      </>
    ),
    required: false,
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'day_4_display',
        comparator: '==',
        value: true,
        conditions: [],
      },
    },
    conditional_require: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'day_4_display',
        comparator: '==',
        value: true,
        conditions: [],
      },
    },
  },
  day_4_end_time: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'day_4_end_time',
    options: timeOptions,
    label: (
      <>
        {rLIB('End Time')} ({rLIB('Day 4')})
      </>
    ),
    required: false,
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'day_4_display',
        comparator: '==',
        value: true,
        conditions: [],
      },
    },
    conditional_require: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'day_4_display',
        comparator: '==',
        value: true,
        conditions: [],
      },
    },
  },
  // Day 5
  day_5_display: {
    data_type: 'string',
    input_type: 'boolean_switch',
    key: 'day_5_display',
    label: <>{rLIB('Add Fifth Day')}</>,
    required: false,
  },
  day_5_date: {
    data_type: 'string',
    input_type: 'timestamp_date',
    key: 'day_5_date',
    label: (
      <>
        {rLIB('Date')} ({rLIB('Day 5')})
      </>
    ),
    required: false,
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'day_5_display',
        comparator: '==',
        value: true,
        conditions: [],
      },
    },
    conditional_require: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'day_5_display',
        comparator: '==',
        value: true,
        conditions: [],
      },
    },
  },
  day_5_start_time: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'day_5_start_time',
    options: timeOptions,
    label: (
      <>
        {rLIB('Start Time')} ({rLIB('Day 5')})
      </>
    ),
    required: false,
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'day_5_display',
        comparator: '==',
        value: true,
        conditions: [],
      },
    },
    conditional_require: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'day_5_display',
        comparator: '==',
        value: true,
        conditions: [],
      },
    },
  },
  day_5_end_time: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'day_5_end_time',
    options: timeOptions,
    label: (
      <>
        {rLIB('End Time')} ({rLIB('Day 5')})
      </>
    ),
    required: false,
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'day_5_display',
        comparator: '==',
        value: true,
        conditions: [],
      },
    },
    conditional_require: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'day_5_display',
        comparator: '==',
        value: true,
        conditions: [],
      },
    },
  },
}

// Forms - Timezone
export const formOnChange_TeamTimezone: TsType_FormOnChange = (formAdditionalData, formData, formInputs, formSettings, formHooks) => {
  // Nothing
}

export const formSubmission_TeamTimezoneCreate: TsType_FormSubmission = (formSubmittedData, formAdditionalData, formHooks) => {
  return new Promise((resolve, reject) => {
    getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseSetMergeDocument(DatabaseRef_SchedulingTeams_Document(res_GCK.clientKey, formAdditionalData.teamKey as string), formSubmittedData)
          .then((res_DSMD) => {
            resolve(res_DSMD)
          })
          .catch((rej_DSMD) => {
            reject(rej_DSMD)
          })
      })
      .catch((rej_GCK) => {
        reject(rej_GCK)
      })
  })
}

export const formSettings_TeamTimezoneCreate: TsInterface_FormSettings = {
  // highlight_missing: true,
  // submit_button_alignment: "right",
  // submit_button_hide: false,
  // submit_button_icon: <SaveIcon/>,
  // submit_button_saving_icon: true,
  // submit_button_text: <>{s_SAVE}</>,
}

export const formInputs_TeamTimezoneNew: TsInterface_FormInputs = {
  selected_timezone: {
    data_type: 'string',
    input_type: 'multiple_choice_radio',
    key: 'selected_timezone',
    label: rLIB('Timezone'),
    required: true,
    options: objectToArray(timezoneOptions),
  },
}

// Forms - Time Edit
export const formSubmission_TaskScheduleUpdate = (
  formSubmittedData: TsInterface_FormSubmittedData,
  formAdditionalData: TsInterface_FormAdditionalData,
  formHooks: TsInterface_FormHooksObject,
) => {
  return new Promise((resolve, reject) => {
    // TODO - guard rails
    // Make sure that there is no overlap with existing tasks

    let errorsWithSubmission = false
    let errorMessage = <></>
    let errorCode = 'ER-D-ST-FSTA-01'
    // Check to make sure form data at first glance is not bad
    if (
      formSubmittedData.day_1_start_time == null ||
      formSubmittedData.day_1_end_time == null ||
      formSubmittedData.day_1_start_time >= formSubmittedData.day_1_end_time
    ) {
      errorsWithSubmission = true
      errorMessage = rLIB('Incorrectly formatted form data') as JSX.Element
      errorCode = 'ER-D-ST-FSTA-02'
    }
    if (
      formSubmittedData.day_2_display === true &&
      (formSubmittedData.day_2_start_time == null ||
        formSubmittedData.day_2_end_time == null ||
        formSubmittedData.day_2_start_time >= formSubmittedData.day_2_end_time)
    ) {
      errorsWithSubmission = true
      errorMessage = rLIB('Incorrectly formatted form data') as JSX.Element
      errorCode = 'ER-D-ST-FSTA-03'
    }
    if (
      formSubmittedData.day_3_display === true &&
      (formSubmittedData.day_3_start_time == null ||
        formSubmittedData.day_3_end_time == null ||
        formSubmittedData.day_3_start_time >= formSubmittedData.day_3_end_time)
    ) {
      errorsWithSubmission = true
      errorMessage = rLIB('Incorrectly formatted form data') as JSX.Element
      errorCode = 'ER-D-ST-FSTA-04'
    }
    if (
      formSubmittedData.day_4_display === true &&
      (formSubmittedData.day_4_start_time == null ||
        formSubmittedData.day_4_end_time == null ||
        formSubmittedData.day_4_start_time >= formSubmittedData.day_4_end_time)
    ) {
      errorsWithSubmission = true
      errorMessage = rLIB('Incorrectly formatted form data') as JSX.Element
      errorCode = 'ER-D-ST-FSTA-05'
    }
    if (
      formSubmittedData.day_5_display === true &&
      (formSubmittedData.day_5_start_time == null ||
        formSubmittedData.day_5_end_time == null ||
        formSubmittedData.day_5_start_time >= formSubmittedData.day_5_end_time)
    ) {
      errorsWithSubmission = true
      errorMessage = rLIB('Incorrectly formatted form data') as JSX.Element
      errorCode = 'ER-D-ST-FSTA-06'
    }
    // Make sure that none of the dates are matching
    let taskScheduledDatesObject: TsInterface_UnspecifiedObject = {}
    let taskScheduledStartTimesObject: TsInterface_UnspecifiedObject = {}
    let taskScheduledEndTimesObject: TsInterface_UnspecifiedObject = {}
    if (formSubmittedData.day_1_date != null) {
      taskScheduledDatesObject[formSubmittedData.day_1_date] = true
      taskScheduledStartTimesObject[formSubmittedData.day_1_date] = convertToTime(formSubmittedData.day_1_start_time)
      taskScheduledEndTimesObject[formSubmittedData.day_1_date] = convertToTime(formSubmittedData.day_1_end_time)
    }
    if (formSubmittedData.day_2_display === true && formSubmittedData.day_2_date != null) {
      if (taskScheduledDatesObject[formSubmittedData.day_2_date] == null) {
        taskScheduledDatesObject[formSubmittedData.day_2_date] = true
        taskScheduledStartTimesObject[formSubmittedData.day_2_date] = convertToTime(formSubmittedData.day_2_start_time)
        taskScheduledEndTimesObject[formSubmittedData.day_2_date] = convertToTime(formSubmittedData.day_2_end_time)
      } else {
        errorsWithSubmission = true
        errorMessage = rLIB('Duplicate dates used in form') as JSX.Element
        errorCode = 'ER-D-ST-FSTA-07'
      }
    }
    if (formSubmittedData.day_3_display === true && formSubmittedData.day_3_date != null) {
      if (taskScheduledDatesObject[formSubmittedData.day_3_date] == null) {
        taskScheduledDatesObject[formSubmittedData.day_3_date] = true
        taskScheduledStartTimesObject[formSubmittedData.day_3_date] = convertToTime(formSubmittedData.day_3_start_time)
        taskScheduledEndTimesObject[formSubmittedData.day_3_date] = convertToTime(formSubmittedData.day_3_end_time)
      } else {
        errorsWithSubmission = true
        errorMessage = rLIB('Duplicate dates used in form') as JSX.Element
        errorCode = 'ER-D-ST-FSTA-08'
      }
    }
    if (formSubmittedData.day_4_display === true && formSubmittedData.day_4_date != null) {
      if (taskScheduledDatesObject[formSubmittedData.day_4_date] == null) {
        taskScheduledDatesObject[formSubmittedData.day_4_date] = true
        taskScheduledStartTimesObject[formSubmittedData.day_4_date] = convertToTime(formSubmittedData.day_4_start_time)
        taskScheduledEndTimesObject[formSubmittedData.day_4_date] = convertToTime(formSubmittedData.day_4_end_time)
      } else {
        errorsWithSubmission = true
        errorMessage = rLIB('Duplicate dates used in form') as JSX.Element
        errorCode = 'ER-D-ST-FSTA-09'
      }
    }
    if (formSubmittedData.day_5_display === true && formSubmittedData.day_5_date != null) {
      if (taskScheduledDatesObject[formSubmittedData.day_5_date] == null) {
        taskScheduledDatesObject[formSubmittedData.day_5_date] = true
        taskScheduledStartTimesObject[formSubmittedData.day_5_date] = convertToTime(formSubmittedData.day_5_start_time)
        taskScheduledEndTimesObject[formSubmittedData.day_5_date] = convertToTime(formSubmittedData.day_5_end_time)
      } else {
        errorsWithSubmission = true
        errorMessage = rLIB('Duplicate dates used in form') as JSX.Element
        errorCode = 'ER-D-ST-FSTA-10'
      }
    }
    // If all errors are avoided
    if (errorsWithSubmission === false) {
      let taskScheduledDatesArray: string[] = []
      for (let loopDateKey in taskScheduledDatesObject) {
        taskScheduledDatesArray.push(loopDateKey)
      }
      // Cloud function to update task
      getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let params = {
            function: 'updateDispatchTaskSchedule',
            client_key: res_GCK.clientKey,
            task_key: formAdditionalData.task_key,
            team_key: formAdditionalData.team_key,
            scheduled_days: taskScheduledDatesArray,
            start_times: taskScheduledStartTimesObject,
            end_times: taskScheduledEndTimesObject,
          }
          cloudFunctionManageRequest('manageTasks', params)
            .then((res_CFMTR) => {
              resolve(res_CFMTR)
            })
            .catch((rej_CFMTR) => {
              reject(rej_CFMTR)
            })
        })
        .catch((rej_GCK) => {
          formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          reject({ success: false, error: rej_GCK.error })
        })
    } else {
      let error = {
        message: rLIB('Failed to assign task'),
        details: errorMessage,
        code: errorCode,
      }
      formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
      reject({ success: false, error: error })
    }
  })
}

// Cancelled Events
export const createCancelledCalendarEvent = (
  clientKey: string,
  taskEvent: TsInterface_UnspecifiedObject,
  operation: 'save_to_database' | 'return_update_object',
  redoNotes: string | null,
  redoReason: string | null,
  previousCompletionTimestamp: Date | null,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (clientKey != null) {
      let cancelledEventTaskKey = taskEvent.key + '_cancelled_' + new Date().getTime()
      let updateObject = {
        associated_project_id_number: getProp(taskEvent, 'associated_project_id_number', null),
        associated_project_key: getProp(taskEvent, 'associated_project_key', null),
        associated_scheduled_time_slot: getProp(taskEvent, 'associated_scheduled_time_slot', null),
        associated_task_key: getProp(taskEvent, 'associated_task_key', null),
        associated_team_key: getProp(taskEvent, 'associated_team_key', null),
        associated_team_name: getProp(taskEvent, 'associated_team_name', null),
        associated_team_type: getProp(taskEvent, 'associated_team_type', null),
        calendar_notes: getProp(taskEvent, 'calendar_notes', null),
        key: cancelledEventTaskKey,
        location_latitude: getProp(taskEvent, 'location_latitude', null),
        location_longitude: getProp(taskEvent, 'location_longitude', null),
        name: getProp(taskEvent, 'name', null),
        redo_notes: redoNotes,
        redo_reason: redoReason,
        status: 'cancelled',
        task_completion_scheduled_dates: getProp(taskEvent, 'task_completion_scheduled_dates', null),
        task_completion_scheduled_end_times: getProp(taskEvent, 'task_completion_scheduled_end_times', null),
        task_completion_scheduled_start_times: getProp(taskEvent, 'task_completion_scheduled_start_times', null),
        task_completion_scheduled_team_keys: getProp(taskEvent, 'task_completion_scheduled_team_keys', null),
        task_completion_scheduled_team_names: getProp(taskEvent, 'task_completion_scheduled_team_names', null),
        task_completion_scheduled_team_roles: getProp(taskEvent, 'task_completion_scheduled_team_roles', null),
        timestamp_created: new Date(),
        timestamp_previous_completion: previousCompletionTimestamp,
        timestamp_last_updated: new Date(),
      }
      if (operation === 'save_to_database') {
        DatabaseSetMergeDocument(DatabaseRef_CancelledScheduledTask_Document(clientKey, cancelledEventTaskKey), updateObject)
          .then((res_DSMD) => {
            resolve(res_DSMD)
          })
          .catch((rej_DSMD) => {
            reject(rej_DSMD)
          })
      } else if (operation === 'return_update_object') {
        resolve({ success: true, data: updateObject, key: cancelledEventTaskKey })
      } else {
        reject({
          success: false,
          error: {
            message: rLIB('Failed to update calendar event'),
            details: rLIB('Unsupported Operation'),
            code: 'ER-D-ST-CCCE-XX',
          },
        })
      }
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to update calendar event'),
          details: rLIB('Missing Required Parameters'),
          code: 'ER-D-ST-CCCE-XX',
        },
      })
    }
  })
}
