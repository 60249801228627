///////////////////////////////
// Imports
///////////////////////////////

import { doc, DocumentData, DocumentReference, getFirestore } from 'firebase/firestore'
import { getStorage, ref, StorageReference } from 'firebase/storage'

///////////////////////////////
// Exports
///////////////////////////////

export const StorageRef_ABPProposal = (clientKey: string, userKey: string, formName: string): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/abp_disclosure_form/' + userKey + '/proposals/' + formName)
}

export const DatabaseRef_ABPProposal_Document = (clientKey: string, companyName: string, proposalKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'sales', 'abp_proposals', 'main', companyName, 'main', proposalKey)
}
