/* eslint-disable react/display-name */
///////////////////////////////
// Description
///////////////////////////////

/*
DESCRIPTION / USAGE:
example component description

TODO:

*/

///////////////////////////////
// Imports
///////////////////////////////
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from '@mui/material'
import { activeTasksCell, customerRiskStatusCell } from 'app/models/projects/project_table'
import { formInputs_StickyNote, formSettings_StickyNote } from 'app/pages/projects/forms/sticky_notes'
import { forwardRef, useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_TaskWorkflowProd_Document, DatabaseRef_TaskWorkflow_Document } from 'rfbp_aux/services/database_endpoints/directory/task_workflows'
import {
  DatabaseRef_ActiveProjectsForSpecificWorkflow_Query,
  DatabaseRef_ActiveProjectTimestampsForSpecificWorkflow_Query,
  DatabaseRef_Project_Document,
} from 'rfbp_aux/services/database_endpoints/operations/projects'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableBasic,
  TableCellBasic,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey } from 'rfbp_core/services/context'
import { DatabaseGetCollection, DatabaseGetDocument, DatabaseGetLiveDocument, DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray, replaceNewlinesWithHtmlBreaks, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { returnProjectsSortedByMilestonePhase, returnSortedWorkflowPhasesArray } from '../services/project_phases'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_Pacing_ProjectPhasesTab {
  selectedTaskWorkflowKey: string | null
  taskWorkflows: TsInterface_UnspecifiedObject
  readOrWrite: 'read' | 'write'
  projectViewPageKey: string
}

///////////////////////////////
// Variables
///////////////////////////////

// TODO: Yellow
const tableColumns_ProjectsByMilestone: TsInterface_TableColumns = {
  associated_customer_risk_status: customerRiskStatusCell,
  id_number: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        cellJSX = (
          <>
            <Box
              className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
              sx={{ 'background': themeVariables.background_default, '&:hover': { background: themeVariables.background_json } }}
              onClick={(event) => {
                if (rowData.key != null) {
                  // TODO - handle other user types probably
                  onClickAppNavigation(
                    event,
                    tableHooks.un_routerNavigation,
                    ApplicationPages[tableAdditionalData.projectViewPageKey].url(rowData.key as string),
                  )
                }
              }}
            >
              {rowData.id_number}
            </Box>
          </>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Job Code')
      },
      header_sort_by: null,
    },
  },
  TEMP_days_in_phase: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let cutoffDaysCount = null
        if (
          rowData != null &&
          tableAdditionalData != null &&
          tableAdditionalData['milestoneTaskKey'] != null &&
          tableAdditionalData['us_taskWorkflow'] != null &&
          tableAdditionalData['us_taskWorkflow']['filter_milestone_days'] != null &&
          tableAdditionalData['us_taskWorkflow']['filter_milestone_days'][tableAdditionalData['milestoneTaskKey']] != null
        ) {
          cutoffDaysCount = tableAdditionalData['us_taskWorkflow']['filter_milestone_days'][tableAdditionalData['milestoneTaskKey']]
        }
        let daysColor = themeVariables.info_main
        // TODO: Yellow
        if (cutoffDaysCount != null) {
          if (rowData.TEMP_days_in_phase != null && rowData.TEMP_days_in_phase <= cutoffDaysCount) {
            daysColor = themeVariables.success_main
          } else {
            daysColor = themeVariables.error_main
          }
        }
        cellJSX = (
          <>
            {rowData.TEMP_last_milestone_timestamp != null ? (
              <>
                <Box
                  sx={{ background: daysColor }}
                  className="tw-px-2 tw-py-1 tw-rounded-md tw-inline-block"
                >
                  {rowData.TEMP_days_in_phase} {rowData.TEMP_days_in_phase === 1 ? <>{rLIB('day')}</> : <>{rLIB('days')}</>}
                </Box>
                <Box className="tw-opacity-30 tw-capitalize">{returnFormattedDate(rowData.TEMP_last_milestone_timestamp, 'D MMM YY')}</Box>
              </>
            ) : (
              <></>
            )}
          </>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Days in Phase')
      },
      header_sort_by: 'TEMP_days_in_phase',
    },
  },
  associated_customer_name: TableCellBasic('associated_customer_name', rLIB('Customer'), 'associated_customer_name'),
  location_jurisdiction: TableCellBasic('location_jurisdiction', rLIB('AHJ'), 'location_jurisdiction'),
  associated_finance_partner_name: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        cellJSX = (
          <>
            <Box>{rowData.associated_finance_partner_name}</Box>
            <Box className="tw-opacity-30 tw-capitalize">{rowData.associated_financing_type}</Box>
          </>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Finance Partner')
      },
      header_sort_by: null,
    },
  },
  TEMP_active_tasks: activeTasksCell,
  notes: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let stickyNoteContent = <></>
        let editIcon = <></>
        if (tableAdditionalData.readOrWrite === 'write') {
          editIcon = (
            <Icon
              icon="note"
              className="tw-mr-2 tw-text-secondary_main tw-cursor-pointer"
              size="xl"
              tooltip={rLIB('Edit Sticky Note')}
              tooltipPlacement="right"
              onClick={() => {
                tableHooks.uc_setUserInterface_FormDialogDisplay({
                  display: true,
                  form: {
                    form: {
                      formAdditionalData: {},
                      formData: rowData,
                      formInputs: formInputs_StickyNote,
                      formOnChange: (
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formData: TsInterface_FormData,
                        formInputs: TsInterface_FormInputs,
                        formSettings: TsInterface_FormSettings,
                      ) => {},
                      formSettings: formSettings_StickyNote,
                      formSubmission: (
                        formSubmittedData: TsInterface_FormSubmittedData,
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formHooks: TsInterface_FormHooksObject,
                      ) => {
                        return new Promise((resolve, reject) => {
                          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey).then((res_GCK) => {
                            DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, rowData.key as string), formSubmittedData)
                              .then((res_DSMD) => {
                                resolve(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                reject(rej_DSMD)
                              })
                          })
                        })
                      },
                    },
                    dialog: {
                      formDialogHeaderColor: 'warning',
                      formDialogHeaderText: rLIB('Edit Sticky Note'),
                      formDialogIcon: (
                        <Icon
                          icon="note"
                          className="tw-mr-2"
                        />
                      ),
                    },
                  },
                })
              }}
            />
          )
        } else {
          editIcon = (
            <Icon
              icon="note"
              className="tw-mr-2 tw-text-secondary_main"
              size="xl"
            />
          )
        }
        if (rowData != null && rowData.sticky_note != null && rowData.sticky_note !== '') {
          stickyNoteContent = (
            <Box>
              <Box
                className="tw-inline-block tw-align-top"
                sx={{ width: '32px' }}
              >
                {editIcon}
              </Box>
              <Box
                className="tw-inline-block"
                sx={{ width: 'calc(100% - 32px' }}
              >
                {replaceNewlinesWithHtmlBreaks(rowData.sticky_note as string)}
              </Box>
            </Box>
          )
        } else {
          stickyNoteContent = (
            <Box>
              <Box
                className="tw-inline-block tw-align-top"
                sx={{ width: '32px' }}
              >
                {editIcon}
              </Box>
              <Box
                className="tw-inline-block"
                sx={{ width: 'calc(100% - 32px' }}
              >
                <Typography className="tw-italic tw-opacity-50 tw-inline-block">{rLIB('No Project Notes')}</Typography>
              </Box>
            </Box>
          )
        }
        cellJSX = <Box>{stickyNoteContent}</Box>
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Project Notes')
      },
      header_sort_by: null,
    },
  },
}

const tableSettings_ProjectsByMilestone: TsInterface_TableSettings = {
  paginated: false,
  // pagination_rows_per_page_default: 100,
  // pagination_rows_per_page_options: [10, 25, 50, 100],
  show_header: true,
  size: 'small',
  sortable: true,
  sort_direction: 'asc',
  sort_property_default: 'id_number',
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const PacingProjectPhasesTab = forwardRef((props: TsInterface_Pacing_ProjectPhasesTab, ref: React.ForwardedRef<unknown>): JSX.Element => {
  // Props
  let pr_selectedTaskWorkflowKey: TsInterface_Pacing_ProjectPhasesTab['selectedTaskWorkflowKey'] = getProp(props, 'selectedTaskWorkflowKey', null)
  let pr_taskWorkflows: TsInterface_Pacing_ProjectPhasesTab['taskWorkflows'] = getProp(props, 'taskWorkflows', null)
  let pr_readOrWrite: TsInterface_Pacing_ProjectPhasesTab['readOrWrite'] = getProp(props, 'readOrWrite', 'read')
  let pr_projectUrl: TsInterface_Pacing_ProjectPhasesTab['projectViewPageKey'] = getProp(props, 'projectViewPageKey', null)

  // Hooks - useContext, useState, useReducer, other
  const [us_maxTaskCount, us_setMaxTaskCount] = useState<number>(0)
  const [us_phaseActiveProjectTimestampsData, us_setPhaseActiveProjectTimestampsData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_phaseActiveProjects, us_setPhaseActiveProjects] = useState<TsInterface_UnspecifiedObject>({})
  const [us_phaseWorkflowTasks, us_setPhaseWorkflowTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectsSortedByMilestone, us_setProjectsSortedByMilestone] = useState<TsInterface_UnspecifiedObject>({})
  const [us_sortedMilestonePhaseTasksArray, us_setSortedMilestonePhaseTasksArray] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_taskWorkflow, us_setTaskWorkflow] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)

  // Hooks - useEffect
  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setTaskWorkflow(newData)
      ur_forceRerender()
    }
    if (pr_selectedTaskWorkflowKey != null && pr_selectedTaskWorkflowKey !== '') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(
            DatabaseRef_TaskWorkflow_Document(res_GCK.clientKey, pr_selectedTaskWorkflowKey as string),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [pr_selectedTaskWorkflowKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    if (pr_selectedTaskWorkflowKey != null && pr_selectedTaskWorkflowKey !== '') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseGetCollection(DatabaseRef_ActiveProjectsForSpecificWorkflow_Query(res_GCK.clientKey, pr_selectedTaskWorkflowKey as string))
            .then((res_DGD) => {
              us_setPhaseActiveProjects(res_DGD.data)
              ur_forceRerender()
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, pr_selectedTaskWorkflowKey])

  useEffect(() => {
    if (pr_selectedTaskWorkflowKey != null && pr_selectedTaskWorkflowKey !== '') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseGetCollection(DatabaseRef_ActiveProjectTimestampsForSpecificWorkflow_Query(res_GCK.clientKey, pr_selectedTaskWorkflowKey as string))
            .then((res_DGD) => {
              us_setPhaseActiveProjectTimestampsData(res_DGD.data)
              ur_forceRerender()
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, pr_selectedTaskWorkflowKey])

  useEffect(() => {
    if (pr_selectedTaskWorkflowKey != null && pr_selectedTaskWorkflowKey !== '') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseGetDocument(DatabaseRef_TaskWorkflowProd_Document(res_GCK.clientKey, pr_selectedTaskWorkflowKey as string))
            .then((res_DGD) => {
              us_setPhaseWorkflowTasks(res_DGD.data)
              ur_forceRerender()
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, pr_selectedTaskWorkflowKey])

  useEffect(() => {
    if (
      pr_selectedTaskWorkflowKey != null &&
      pr_selectedTaskWorkflowKey !== '' &&
      pr_taskWorkflows != null &&
      pr_taskWorkflows[pr_selectedTaskWorkflowKey] != null &&
      pr_taskWorkflows[pr_selectedTaskWorkflowKey]['filter_milestones'] != null &&
      us_phaseWorkflowTasks != null &&
      us_phaseWorkflowTasks['tasks'] != null
    ) {
      us_setSortedMilestonePhaseTasksArray(returnSortedWorkflowPhasesArray(us_phaseWorkflowTasks['tasks'], pr_taskWorkflows[pr_selectedTaskWorkflowKey]))
    }
  }, [pr_selectedTaskWorkflowKey, pr_taskWorkflows, us_phaseWorkflowTasks])

  useEffect(() => {
    if (
      pr_selectedTaskWorkflowKey != null &&
      pr_selectedTaskWorkflowKey !== '' &&
      us_phaseActiveProjects != null &&
      us_sortedMilestonePhaseTasksArray != null &&
      us_sortedMilestonePhaseTasksArray.length > 0
    ) {
      us_setProjectsSortedByMilestone(
        returnProjectsSortedByMilestonePhase(us_sortedMilestonePhaseTasksArray, us_phaseActiveProjects, us_phaseActiveProjectTimestampsData),
      )
    }
  }, [pr_selectedTaskWorkflowKey, us_phaseActiveProjects, us_sortedMilestonePhaseTasksArray, us_phaseActiveProjectTimestampsData])

  useEffect(() => {
    // Get Max number of projects in any given milestone to set width of progress bar segments
    let currentMaxTaskCount = 0
    for (let loopMilestoneKey in us_projectsSortedByMilestone) {
      let loopMilestone = us_projectsSortedByMilestone[loopMilestoneKey]
      if (loopMilestone != null) {
        let loopMilestoneTaskCount = objectToArray(loopMilestone).length
        if (loopMilestoneTaskCount > currentMaxTaskCount) {
          currentMaxTaskCount = loopMilestoneTaskCount
        }
      }
    }
    us_setMaxTaskCount(currentMaxTaskCount)
  }, [us_projectsSortedByMilestone])

  // Functions
  // TODO: Yellow
  const getMilestoneProjectColorCounts = (milestoneTaskKey: string): TsInterface_UnspecifiedObject => {
    let projectColorCounts: TsInterface_UnspecifiedObject = {
      red: 0,
      yellow: 0,
      green: 0,
      blue: 0,
      total: objectToArray(getProp(us_projectsSortedByMilestone, milestoneTaskKey, {})).length,
    }
    if (us_taskWorkflow != null && us_taskWorkflow['filter_milestone_days'] != null && us_taskWorkflow['filter_milestone_days'][milestoneTaskKey] != null) {
      for (let loopProjectKey in getProp(us_projectsSortedByMilestone, milestoneTaskKey, {})) {
        let loopProject = getProp(us_projectsSortedByMilestone, milestoneTaskKey, {})[loopProjectKey]
        let loopProjectDaysInPhase = getProp(loopProject, 'TEMP_days_in_phase', 0)
        if (loopProjectDaysInPhase <= us_taskWorkflow['filter_milestone_days'][milestoneTaskKey]) {
          projectColorCounts['green'] += 1
          // TODO: Yellow
          // } else if() {
        } else {
          projectColorCounts['red'] += 1
        }
      }
    } else {
      projectColorCounts['blue'] = objectToArray(getProp(us_projectsSortedByMilestone, milestoneTaskKey, {})).length
    }
    return projectColorCounts
  }

  // JSX Generation
  // TODO: Extensions Yellow Days
  const rJSX_SummaryStats = (milestoneTaskKey: string): JSX.Element => {
    let statsJSX = <></>
    let projectColorCounts = getMilestoneProjectColorCounts(milestoneTaskKey)
    let projectCountJSX = <></>
    let noCutoffDaysSetupJSX = <></>
    let redCountJSX = <></>
    let yellowCountJSX = <></>
    let greenCountJSX = <></>
    let cutoffDays = null
    if (us_taskWorkflow != null && us_taskWorkflow['filter_milestone_days'] != null && us_taskWorkflow['filter_milestone_days'][milestoneTaskKey] != null) {
      cutoffDays = us_taskWorkflow['filter_milestone_days'][milestoneTaskKey]
    }
    if (cutoffDays != null) {
      // Red
      if (projectColorCounts.red > 0) {
        redCountJSX = (
          <Box sx={{ color: themeVariables.error_main }}>
            {projectColorCounts.red} {rLIB('more than')} {cutoffDays} {rLIB('days')}
          </Box>
        )
      } else {
        redCountJSX = <></>
      }
      // Yellow
      if (projectColorCounts.yellow > 0) {
        yellowCountJSX = (
          <Box sx={{ color: themeVariables.warning_dark }}>
            {projectColorCounts.yellow} {rLIB('less than')} {cutoffDays} {rLIB('days + extensions')}
          </Box>
        )
      } else {
        yellowCountJSX = <></>
      }
      // Green
      if (projectColorCounts.green > 0) {
        greenCountJSX = (
          <Box sx={{ color: themeVariables.success_main }}>
            {projectColorCounts.green} {rLIB('less than')} {cutoffDays} {rLIB('days')}
          </Box>
        )
      } else {
        greenCountJSX = <></>
      }
    } else {
      noCutoffDaysSetupJSX = (
        <Box sx={{ color: themeVariables.info_main }}>
          <Icon
            icon="circle-info"
            className="tw-mr-2"
          />
          {rLIB('Phase days not set up on workflow')}
        </Box>
      )
    }
    if (projectColorCounts.total > 0) {
      projectCountJSX = (
        <Box>
          {projectColorCounts.total} {rLIB('Projects')}
        </Box>
      )
    } else {
      projectCountJSX = (
        <Box className="tw-opacity-30">
          {projectColorCounts.total} {rLIB('Projects')}
        </Box>
      )
    }
    // JSX
    statsJSX = (
      <Stack
        direction="row"
        spacing={1}
      >
        {projectCountJSX}
        <Box>|</Box>
        {noCutoffDaysSetupJSX}
        {redCountJSX}
        {projectColorCounts.red > 0 ? <Box>|</Box> : <></>}
        {yellowCountJSX}
        {projectColorCounts.yellow > 0 ? <Box>|</Box> : <></>}
        {greenCountJSX}
      </Stack>
    )
    return statsJSX
  }

  // TODO: Yellow Days
  const rJSX_ProjectJobCodesList = (milestoneTaskKey: string): JSX.Element => {
    let cutoffDays: null | number = null
    if (us_taskWorkflow != null && us_taskWorkflow['filter_milestone_days'] != null && us_taskWorkflow['filter_milestone_days'][milestoneTaskKey] != null) {
      cutoffDays = us_taskWorkflow['filter_milestone_days'][milestoneTaskKey]
    }

    let projectJobCodes = []
    if (us_projectsSortedByMilestone != null && us_projectsSortedByMilestone[milestoneTaskKey] != null) {
      projectJobCodes = objectToArray(us_projectsSortedByMilestone[milestoneTaskKey])
    }

    const returnColor = (cutoffDays: null | number, project: TsInterface_UnspecifiedObject): string => {
      if (cutoffDays != null) {
        if (project['TEMP_days_in_phase'] <= cutoffDays) {
          return themeVariables.success_main
        } else {
          return themeVariables.error_main
        }
      } else {
        return themeVariables.info_main
      }
    }

    return (
      <Box>
        {projectJobCodes.sort(dynamicSort('id_number', null)).map((project: TsInterface_UnspecifiedObject, projectIndex: number) => (
          <Box
            className="tw-inline-block"
            sx={{
              background: returnColor(cutoffDays, project),
              padding: '4px 8px',
              borderRadius: '4px',
              marginRight: '4px',
            }}
            key={projectIndex}
          >
            {project['id_number']}
          </Box>
        ))}
      </Box>
    )
  }

  const rJSX_BreakdownGraph = (milestoneTaskKey: string): JSX.Element => {
    let projectColorCounts = getMilestoneProjectColorCounts(milestoneTaskKey)
    return (
      <Box sx={{ width: '100%', height: '12px', overflow: 'hidden' }}>
        <Stack
          direction="row"
          sx={{ width: '100%' }}
        >
          <Box
            className="tw-text-center tw-py-2"
            sx={{
              background: themeVariables.info_main,
              width: (projectColorCounts.blue / us_maxTaskCount) * 100 + '%',
              height: '40px',
            }}
          >
            {/* {redCount > 0 ? redCount : ''} */}
          </Box>
          <Box
            className="tw-text-center tw-py-2"
            sx={{
              background: themeVariables.error_main,
              width: (projectColorCounts.red / us_maxTaskCount) * 100 + '%',
              height: '40px',
            }}
          >
            {/* {redCount > 0 ? redCount : ''} */}
          </Box>

          <Box
            className="tw-text-center tw-py-2"
            sx={{
              background: themeVariables.warning_main,
              width: (projectColorCounts.yellow / us_maxTaskCount) * 100 + '%',
              height: '40px',
            }}
          >
            {/* {yellowCount > 0 ? yellowCount : ''} */}
          </Box>

          <Box
            className="tw-text-center tw-py-2"
            sx={{
              background: themeVariables.success_main,
              width: (projectColorCounts.green / us_maxTaskCount) * 100 + '%',
              height: '40px',
            }}
          >
            {/* {greenCount > 0 ? greenCount : ''} */}
          </Box>

          <Box
            className="tw-text-center tw-py-2"
            sx={{
              background: themeVariables.gray_700,
              width: ((us_maxTaskCount - projectColorCounts.total) / us_maxTaskCount) * 100 + '%',
              height: '40px',
            }}
          >
            {/* {greenCount > 0 ? greenCount : ''} */}
          </Box>
        </Stack>
      </Box>
    )
  }

  const rJSX_ProjectsForWorkflowMilestoneTableList = (
    milestoneTaskKey: string,
    us_projectsSortedByMilestone: TsInterface_UnspecifiedObject | null,
    readOrWrite: 'read' | 'write',
  ) => {
    let tableJSX = <></>
    if (
      milestoneTaskKey != null &&
      us_projectsSortedByMilestone != null &&
      us_projectsSortedByMilestone[milestoneTaskKey] != null &&
      objectToArray(us_projectsSortedByMilestone[milestoneTaskKey]).length > 0
    ) {
      tableJSX = (
        <Box
          sx={{ border: '1px solid ' + themeVariables.gray_700 }}
          className="tw-rounded-sm"
        >
          <TableBasic
            tableAdditionalData={{
              projectViewPageKey: pr_projectUrl,
              readOrWrite: readOrWrite,
              us_taskWorkflow: us_taskWorkflow,
              milestoneTaskKey: milestoneTaskKey,
            }}
            tableColumns={tableColumns_ProjectsByMilestone}
            tableData={objectToArray(us_projectsSortedByMilestone[milestoneTaskKey])}
            tableSettings={tableSettings_ProjectsByMilestone}
          />
        </Box>
      )
    } else {
      tableJSX = (
        <Box>
          <Typography
            variant="body1"
            className="tw-my-1"
          >
            {rLIB('No projects for this milestone')}
          </Typography>
        </Box>
      )
    }
    return tableJSX
  }

  const rJSX_MilestoneName = (milestoneTaskKey: string, milestoneName: string): JSX.Element => {
    if (us_taskWorkflow != null && us_taskWorkflow['filter_milestone_names'] != null && us_taskWorkflow['filter_milestone_names'][milestoneTaskKey] != null) {
      return <>{us_taskWorkflow['filter_milestone_names'][milestoneTaskKey]}</>
    }
    return <>{milestoneName}</>
  }

  const rJSX_PhasesTabContent = (readOrWrite: 'read' | 'write'): JSX.Element => {
    let contentJSX = <></>
    if (pr_selectedTaskWorkflowKey == null || pr_selectedTaskWorkflowKey === '') {
      contentJSX = (
        <Box className="tw-p-4 tw-text-center tw-opacity-50">
          <Typography
            variant="h6"
            className="tw-my-1"
          >
            {rLIB('Select a Workflow')}
          </Typography>
        </Box>
      )
    } else if (
      pr_selectedTaskWorkflowKey != null &&
      pr_taskWorkflows != null &&
      pr_taskWorkflows[pr_selectedTaskWorkflowKey] != null &&
      pr_taskWorkflows[pr_selectedTaskWorkflowKey]['filter_milestones'] != null &&
      us_sortedMilestonePhaseTasksArray != null &&
      us_sortedMilestonePhaseTasksArray.length > 0
    ) {
      contentJSX = (
        <Box>
          {us_sortedMilestonePhaseTasksArray.map((milestone: TsInterface_UnspecifiedObject, milestoneIndex: number) => (
            <Box
              className="tw-mb-4"
              key={milestoneIndex}
            >
              <Box>{rJSX_BreakdownGraph(milestone.key)}</Box>
              <Accordion
                sx={{
                  '&.Mui-expanded': {
                    marginTop: 0,
                  },
                }}
              >
                <AccordionSummary
                  sx={{
                    'width': '100%',
                    // 'padding': 0,
                    '& .MuiAccordionSummary-content': {
                      width: '100%',
                      // margin: 0,
                    },
                  }}
                  expandIcon={<Icon icon="angle-down" />}
                >
                  <Box sx={{ width: '100%' }}>
                    <Typography
                      variant="h5"
                      className="tw-my-1 tw-opacity-40 tw-inline-block"
                    >
                      {rJSX_MilestoneName(milestone.key, milestone['name'])}
                      {/* {milestone['name']} */}
                    </Typography>
                    {rJSX_SummaryStats(milestone.key)}
                    {rJSX_ProjectJobCodesList(milestone.key)}
                  </Box>
                </AccordionSummary>
                <AccordionDetails>{rJSX_ProjectsForWorkflowMilestoneTableList(milestone.key, us_projectsSortedByMilestone, readOrWrite)}</AccordionDetails>
              </Accordion>
            </Box>
          ))}
        </Box>
      )
    } else if (pr_selectedTaskWorkflowKey != null && pr_taskWorkflows != null && pr_taskWorkflows[pr_selectedTaskWorkflowKey] != null) {
      contentJSX = (
        <Box className="tw-p-4 tw-text-center tw-opacity-50">
          <Typography
            variant="h6"
            className="tw-my-1"
          >
            {rLIB('Milestone tasks not set on workflow')}
          </Typography>
        </Box>
      )
    }
    return contentJSX
  }

  const rJSX_ProjectPhasesTab = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tabJSX = <Box>{rJSX_PhasesTabContent(pr_readOrWrite)}</Box>
    } else {
      tabJSX = <></>
    }
    return tabJSX
  }

  const rJSX_Tab = (): JSX.Element => {
    let tabJSX = (
      <Box className="tw-m-auto">
        <Box>{rJSX_ProjectPhasesTab()}</Box>
      </Box>
    )
    return tabJSX
  }

  // Render
  return <>{rJSX_Tab()}</>
})
