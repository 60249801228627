///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_FinalizedPayroll_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'finances', 'finalized_payroll', 'main')
}

export const DatabaseRef_FinalizedPayroll_Document = (clientKey: string, weekKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'finances', 'finalized_payroll', 'main', weekKey)
}

export const DatabaseRef_FinalizedPayroll_ErrorsAndWarnings_Collection = (clientKey: string, weekKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'finances', 'finalized_payroll', 'main', weekKey, 'errors_and_warnings')
}

export const DatabaseRef_FinalizedPayroll_ErrorsAndWarnings_Document = (
  clientKey: string,
  weekKey: string,
  documentKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'finances', 'finalized_payroll', 'main', weekKey, 'errors_and_warnings', documentKey)
}

export const DatabaseRef_FinalizedPayroll_ErrorsAndWarningResolutions_Collection = (clientKey: string, weekKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'finances', 'finalized_payroll', 'main', weekKey, 'errors_and_warning_resolutions')
}

export const DatabaseRef_FinalizedPayroll_ErrorsAndWarningResolutions_Document = (
  clientKey: string,
  weekKey: string,
  documentKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'finances', 'finalized_payroll', 'main', weekKey, 'errors_and_warning_resolutions', documentKey)
}

export const DatabaseRef_FinalizedPayroll_AdHocTasks_Collection = (clientKey: string, weekKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'finances', 'finalized_payroll', 'main', weekKey, 'ad_hoc_tasks')
}

export const DatabaseRef_FinalizedPayroll_AdHocTasks_Document = (clientKey: string, weekKey: string, documentKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'finances', 'finalized_payroll', 'main', weekKey, 'ad_hoc_tasks', documentKey)
}

export const DatabaseRef_FinalizedPayroll_EmployeeFullPayrollData_Collection = (clientKey: string, weekKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'finances', 'finalized_payroll', 'main', weekKey, 'employee_full_payroll_data')
}

export const DatabaseRef_FinalizedPayroll_EmployeeFullPayrollDataByDateAndName_Query = (
  clientKey: string,
  weekKey: string,
  userKey: string,
): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'key', comparator: '==', value: userKey }]
  let queryCursorsObject = {}
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'finances', 'finalized_payroll', 'main', weekKey, 'employee_full_payroll_data'),

    queryOperatorsArray,
    [],
    queryCursorsObject,
    null,
  )
}

export const DatabaseRef_FinalizedPayroll_EmployeeFullPayrollData_Document = (
  clientKey: string,
  weekKey: string,
  documentKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'finances', 'finalized_payroll', 'main', weekKey, 'employee_full_payroll_data', documentKey)
}

export const DatabaseRef_FinalizedPayroll_UnitPayTasks_Collection = (clientKey: string, weekKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'finances', 'finalized_payroll', 'main', weekKey, 'unit_pay_tasks')
}

export const DatabaseRef_FinalizedPayroll_UnitPayTasks_Document = (
  clientKey: string,
  weekKey: string,
  documentKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'finances', 'finalized_payroll', 'main', weekKey, 'unit_pay_tasks', documentKey)
}

export const DatabaseRef_FinalizedPayroll_CustomMappingData_Collection = (clientKey: string, weekKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'finances', 'finalized_payroll', 'main', weekKey, 'custom_mapping_data')
}

export const DatabaseRef_FinalizedPayroll_CustomMappingData_Document = (
  clientKey: string,
  weekKey: string,
  mappingType: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'finances', 'finalized_payroll', 'main', weekKey, 'custom_mapping_data', mappingType)
}

export const DatabaseRef_FinalizedPayroll_CachedEfficiencyClockHourData_Collection = (
  clientKey: string,
  weekKey: string,
): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'finances', 'finalized_payroll', 'main', weekKey, 'cached_efficiency_clock_hour_data')
}

export const DatabaseRef_FinalizedPayroll_CachedEfficiencyClockHourData_Document = (
  clientKey: string,
  weekKey: string,
  documentKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'finances', 'finalized_payroll', 'main', weekKey, 'cached_efficiency_clock_hour_data', documentKey)
}

export const DatabaseRef_FinalizedPayroll_CachedProjectData_Collection = (clientKey: string, weekKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'finances', 'finalized_payroll', 'main', weekKey, 'cached_project_data')
}

export const DatabaseRef_FinalizedPayroll_CachedProjectData_Document = (
  clientKey: string,
  weekKey: string,
  documentKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'finances', 'finalized_payroll', 'main', weekKey, 'cached_project_data', documentKey)
}

export const DatabaseRef_FinalizedPayroll_CachedCancelledTasksData_Collection = (clientKey: string, weekKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'finances', 'finalized_payroll', 'main', weekKey, 'cached_cancelled_tasks_data')
}

export const DatabaseRef_FinalizedPayroll_CachedCancelledTasksData_Document = (
  clientKey: string,
  weekKey: string,
  documentKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'finances', 'finalized_payroll', 'main', weekKey, 'cached_cancelled_tasks_data', documentKey)
}

export const DatabaseRef_FinalizedPayroll_DistanceAdderUserData_Collection = (clientKey: string, weekKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'finances', 'finalized_payroll', 'main', weekKey, 'distance_adder_user_data')
}

export const DatabaseRef_FinalizedPayroll_DistanceAdderUserData_Document = (
  clientKey: string,
  weekKey: string,
  documentKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'finances', 'finalized_payroll', 'main', weekKey, 'distance_adder_user_data', documentKey)
}

export const DatabaseRef_FinalizedPayroll_UploadedPTOFile_Document = (clientKey: string, weekKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'finances', 'finalized_payroll', 'main', weekKey, 'uploaded_pto_file', 'week_balance')
}
