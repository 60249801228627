///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Model files contains data and business logic specific to an individual database collection type

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Checkbox, Chip, Tooltip } from '@mui/material/'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { ApplicationPages, ClientUserRoles } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_UsersWithSpecificNameAbbreviation_Query } from 'rfbp_aux/services/database_endpoints/directory/users'
import { DatabaseRef_ClientUser_Document } from 'rfbp_aux/services/database_endpoints/standard_database_endpoints'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TableCellManage,
  TableCellPhone,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { TsInterface_FormDialogObject } from 'rfbp_core/services/context'
import { DatabaseGetCollection, DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import {
  cloneObjectWithoutReference,
  dynamicSort,
  generateFormOptions,
  getProp,
  objectToArray,
  returnFormattedDateKey,
  underscoresToSpacesAndCapitalize,
} from 'rfbp_core/services/helper_functions'
import { directAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'
import { formInputs_UserNew } from './user_form'
import { activateAccount, deactivateAccount } from './user_functions'

import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

const viewUser = (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks): void => {
  if (rowData.key != null) {
    directAppNavigation(tableHooks.un_routerNavigation, ApplicationPages.AdminDatabaseUserViewPage.url(rowData.key as string))
  }
}

const editUser = (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks): void => {
  if (rowData.key != null) {
    directAppNavigation(tableHooks.un_routerNavigation, ApplicationPages.AdminDatabaseUserEditPage.url(rowData.key as string))
  }
}

const disableUserAccount = (
  rowData: TsInterface_TableDataRow,
  tableAdditionalData: TsInterface_TableAdditionalData,
  tableHooks: TsInterface_TableHooks,
): void => {
  tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
    display: true,
    confirm: {
      color: 'error',
      icon: <Icon icon="lock" />,
      header: rLIB('Disable Account'),
      text: rLIB('Are you sure that you want to lock this user account?'),
      submit_text: rLIB('Lock Account'),
      submit_callback: () => {
        return new Promise((resolve, reject) => {
          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
            .then((res_GCK) => {
              if (res_GCK.clientKey != null && rowData.key != null) {
                let userName = getProp(tableHooks.uc_RootData_ClientUser, 'name', null)
                let userKey = getProp(tableHooks.uc_RootData_ClientUser, 'key', null)
                deactivateAccount(res_GCK.clientKey, rowData.key as string, userName, userKey)
                  .then((res_DA) => {
                    // TODO - Toast?
                    resolve(res_DA)
                  })
                  .catch((rej_DA) => {
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DA.error })
                    reject(rej_DA)
                  })
              }
            })
            .catch((rej_GCK) => {
              tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
              reject(rej_GCK)
            })
        })
      },
    },
  })
}

const enableUserAccount = (
  rowData: TsInterface_TableDataRow,
  tableAdditionalData: TsInterface_TableAdditionalData,
  tableHooks: TsInterface_TableHooks,
): void => {
  tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
    display: true,
    confirm: {
      color: 'warning',
      icon: <Icon icon="lock-open" />,
      header: rLIB('Enable Account'),
      text: rLIB('Are you sure that you want to unlock this user account?'),
      submit_text: rLIB('Unlock account'),
      submit_callback: () => {
        return new Promise((resolve, reject) => {
          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
            .then((res_GCK) => {
              if (res_GCK.clientKey != null && rowData.key != null) {
                let userName = getProp(tableHooks.uc_RootData_ClientUser, 'name', null)
                let userKey = getProp(tableHooks.uc_RootData_ClientUser, 'key', null)
                activateAccount(res_GCK.clientKey, rowData.key as string, userName, userKey)
                  .then((res_AA) => {
                    // TODO - Toast?
                    resolve(res_AA)
                  })
                  .catch((rej_AA) => {
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_AA.error })
                    reject(rej_AA)
                  })
              }
            })
            .catch((rej_GCK) => {
              tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
              reject(rej_GCK)
            })
        })
      },
    },
  })
}

const returnTaskRolesOptions = (userRole: string, taskRoles: TsInterface_UnspecifiedObject): TsInterface_UnspecifiedObject[] => {
  let taskRowOptions: TsInterface_UnspecifiedObject[] = []
  taskRowOptions = generateFormOptions(taskRoles, 'key', 'name').sort(dynamicSort('value', 'asc'))
  for (let loopOptionIndex in taskRowOptions) {
    let loopOption = taskRowOptions[loopOptionIndex]
    if (loopOption.key === userRole) {
      taskRowOptions[loopOptionIndex]['disabled'] = true
    }
    if (
      loopOption != null &&
      loopOption.key != null &&
      taskRoles != null &&
      taskRoles[loopOption.key] != null &&
      taskRoles[loopOption.key].TEMP_disable_selection === true
    ) {
      taskRowOptions[loopOptionIndex]['disabled'] = true
    }
  }
  return taskRowOptions
}

// JSX generation
export const rJSX_UserRoleChip = (user: TsInterface_UnspecifiedObject): JSX.Element => {
  let chipJSX = <></>
  if (user.user_role != null) {
    if (ClientUserRoles != null && ClientUserRoles[user.user_role as string] != null) {
      chipJSX = (
        <Chip
          label={ClientUserRoles[user.user_role as string]['name']}
          color={ClientUserRoles[user.user_role as string]['color']}
          icon={ClientUserRoles[user.user_role as string]['icon']}
        />
      )
    } else {
      // chipJSX = <Chip label={ user.user_role }  color="primary" />
      chipJSX = (
        <Chip
          label={rLIB('Missing Role')}
          icon={<Icon icon="triangle-exclamation" />}
          variant="outlined"
        />
      )
    }
  } else {
    chipJSX = (
      <Chip
        label={rLIB('Missing Role')}
        icon={<Icon icon="triangle-exclamation" />}
        variant="outlined"
      />
    )
  }
  return chipJSX
}

const rJSX_AdditionalTaskRoleChipList = (
  user: TsInterface_UnspecifiedObject,
  taskRoles: TsInterface_UnspecifiedObject,
  FormDialogObject: TsInterface_FormDialogObject,
  tableHooks: TsInterface_TableHooks,
  tableAdditionalData: TsInterface_TableAdditionalData,
): JSX.Element => {
  let chipListJSX = <></>
  if (user != null && user['task_roles'] != null) {
    // Generate Role List
    let selectedRoles: TsInterface_UnspecifiedObject = {}
    for (let roleKey in user['task_roles']) {
      if (user['task_roles'][roleKey] === true && roleKey !== user.user_role) {
        if (taskRoles != null && taskRoles[roleKey] != null && taskRoles[roleKey]['name'] != null) {
          selectedRoles[roleKey] = { name: taskRoles[roleKey]['name'], key: roleKey }
        }
      }
    }
    // Helper Function
    const returnUserTypeChip = (role: TsInterface_UnspecifiedObject): JSX.Element => {
      let chipJSX = <></>
      if (ClientUserRoles != null && ClientUserRoles[role.key] != null) {
        chipJSX = (
          <Chip
            // size={ "small" }
            label={ClientUserRoles[role.key]['name']}
            color={ClientUserRoles[role.key]['color']}
            icon={ClientUserRoles[role.key]['icon']}
            onClick={() => {
              FormDialogObject['form']['formInputs']['task_roles']['options'] = returnTaskRolesOptions(
                getProp(user, 'user_role', null),
                tableAdditionalData.task_roles,
              )
              tableHooks.uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: FormDialogObject,
              })
            }}
          />
        )
      } else {
        chipJSX = (
          <Chip
            icon={
              <Icon
                type="solid"
                icon="user"
              />
            }
            label={role.name}
            onClick={() => {
              FormDialogObject['form']['formInputs']['task_roles']['options'] = returnTaskRolesOptions(
                getProp(user, 'user_role', null),
                tableAdditionalData.task_roles,
              )
              tableHooks.uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: FormDialogObject,
              })
            }}
          />
        )
      }
      return chipJSX
    }

    const returnTaskRoleTooltipLabel = (sortedRoles: TsInterface_UnspecifiedObject[]): JSX.Element => {
      let labelJSX = (
        <>
          {objectToArray(sortedRoles).length} {rLIB('Task Roles')}
        </>
      )
      return labelJSX
    }

    const returnUserTypeChipList = (): JSX.Element => {
      let chipListJSX = <></>
      let sortedRoles = objectToArray(selectedRoles).sort(dynamicSort('key', 'asc'))
      let chipLimit = 4
      if (sortedRoles.length <= chipLimit) {
        chipListJSX = (
          <Box>
            {objectToArray(sortedRoles).map((role: TsInterface_UnspecifiedObject, index: number) => (
              <Box
                sx={{ margin: '4px !important', marginBottom: '4px !important', display: 'inline-block' }}
                component="div"
                key={index}
              >
                {returnUserTypeChip(role)}
              </Box>
            ))}
          </Box>
        )
      } else {
        chipListJSX = (
          <Box>
            <Tooltip
              title={
                <Box>
                  {objectToArray(sortedRoles).map((role: TsInterface_UnspecifiedObject, index: number) => (
                    <Box
                      sx={{ margin: '4px !important', marginBottom: '4px !important', display: 'inline-block' }}
                      component="div"
                      key={index}
                    >
                      {returnUserTypeChip(role)}
                    </Box>
                  ))}
                </Box>
              }
            >
              <Chip
                label={returnTaskRoleTooltipLabel(sortedRoles)}
                color={'default'}
                icon={
                  <Icon
                    type="solid"
                    icon="user-group"
                  />
                }
                className="tw-m-1"
                onClick={() => {
                  FormDialogObject['form']['formInputs']['task_roles']['options'] = returnTaskRolesOptions(
                    getProp(user, 'user_role', null),
                    tableAdditionalData.task_roles,
                  )
                  tableHooks.uc_setUserInterface_FormDialogDisplay({
                    display: true,
                    form: FormDialogObject,
                  })
                }}
              />
            </Tooltip>
          </Box>
        )
      }
      return chipListJSX
    }

    // Generate JSX
    chipListJSX = (
      <Box
        className="tw-inline-block"
        sx={{ maxWidth: '500px' }}
      >
        {returnUserTypeChipList()}
      </Box>
    )
  }
  return chipListJSX
}

const sumOfValues = (obj: TsInterface_UnspecifiedObject) => {
  let sum = 0
  for (const subObjKey in obj) {
    const subObj = obj[subObjKey]
    for (const propKey in subObj) {
      const propValue = subObj[propKey]
      if (typeof propValue === 'number') {
        sum += propValue
      }
    }
  }
  return sum
}

// Filter Subtypes
const formatTime = (hours: number) => {
  // Separate the integer part and decimal part of the hours
  const integerPart = Math.floor(hours)
  const decimalPart = hours - integerPart
  // Calculate the minutes based on the decimal part
  const minutes = Math.round(decimalPart * 60)
  // Format the hours and minutes as a string
  const formattedTime = integerPart.toString() + ':' + minutes.toString().padStart(2, '0')
  return formattedTime
}

const returnActiveSubtypes = (subtypeObject: TsInterface_UnspecifiedObject): TsInterface_UnspecifiedObject[] => {
  return objectToArray(subtypeObject).filter((object) => {
    return object['status'] === 'active'
  })
}

const rJSX_TimeAllocationsDisplay = (allocationTypes: TsInterface_UnspecifiedObject, allocatedTime: TsInterface_UnspecifiedObject): JSX.Element => {
  let displayJSX = <></>
  const returnAllocationLineItem = (type: TsInterface_UnspecifiedObject, subtype: TsInterface_UnspecifiedObject): JSX.Element => {
    let lineItemJSX = <></>
    if (
      allocatedTime != null &&
      type != null &&
      type.key != null &&
      subtype != null &&
      subtype.key != null &&
      allocatedTime[type.key] != null &&
      allocatedTime[type.key][subtype.key] != null &&
      allocatedTime[type.key][subtype.key] > 0
    ) {
      lineItemJSX = (
        <Box>
          <Box className="tw-inline-block">{formatTime(allocatedTime[type.key][subtype.key])}</Box>
          <Box className="tw-inline-block tw-opacity-40 tw-ml-1">
            {' '}
            - {type.name} ({subtype.name})
          </Box>
        </Box>
      )
    }
    return lineItemJSX
  }
  // JSX
  displayJSX = (
    <Box>
      {objectToArray(allocationTypes)
        .sort(dynamicSort('name', null))
        .map((type, typeIndex) => (
          <Box key={typeIndex}>
            {returnActiveSubtypes(type.subtypes)
              .sort(dynamicSort('name', null))
              .map((subtype, subtypeIndex) => (
                <Box key={subtypeIndex}>{returnAllocationLineItem(type, subtype)}</Box>
              ))}
          </Box>
        ))}
    </Box>
  )
  // Return
  return displayJSX
}

const rJSX_AuthorizationsDisplay = (dataItems: Record<string, string>, userKey: string): JSX.Element => {
  const returnItemLine = (key: string, value: string): JSX.Element => (
    <Box>
      <Box className="tw-inline-block">
        {underscoresToSpacesAndCapitalize(key)}: {value.toString()}
      </Box>
    </Box>
  )

  // JSX
  let displayJSX = (
    <Box>
      {Object.entries(dataItems).map(([key, value], index) => (
        <Box key={index}>{returnItemLine(key, value)}</Box>
      ))}
    </Box>
  )

  // Return
  return displayJSX
}

///////////////////////////////
// Exports
///////////////////////////////

export const tableSettings_AllUsers: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: false,
  sticky_header: true,
  sticky_first_column: true,
  sticky_table_height: '800px', // Set an explicit height to trigger sticky behavior
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
    },
  ],
  searchable: true,
  search_settings_database: {
    search_type: 'firebase',
    search_no_data_message: rLIB('No Users found by search') as JSX.Element,
    search_property_options: [
      { key: 'name', value: rLIB('User Name') },
      { key: 'email', value: rLIB('Email') },
      { key: 'phone', value: rLIB('Phone') },
    ],
  },
}

export const tableSettings_User: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: false,
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
    },
  ],
}

const nameSpendingColumn = {
  header: {
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return 'Name'
    },
    header_sort_by: 'name',
  },
  cell: {
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      const cellJSX = (
        <>
          <Box
            className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
            sx={{ 'background': themeVariables.background_default, '&:hover': { background: themeVariables.background_json } }}
            onClick={() => {
              if (rowData.key != null) {
                // TODO - handle other user types probably
                tableHooks.un_routerNavigation(ApplicationPages.AdminFinanceSpendingCardholdersViewPage.url(rowData.key as string))
              }
            }}
          >
            {rowData.name}
          </Box>
        </>
      )
      return cellJSX
    },
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      const cellCSS = ''
      return cellCSS
    },
  },
}
const nameColumn = TableCellBasic('name', rLIB('User Name'), 'name')

const emailColumn = TableCellBasic('email', rLIB('Email'), 'email')

const phoneColumn = TableCellPhone('phone', rLIB('Phone'), 'phone')

const nameAbbreviationColumn = {
  header: {
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return rLIB('Name Abbreviation')
    },
    header_sort_by: 'name_abbreviation',
  },
  cell: {
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      let innerCellJSX = <></>
      if (rowData.name_abbreviation != null) {
        innerCellJSX = (
          <Chip
            color="default"
            className="tw-cursor-pointer"
            label={rowData.name_abbreviation}
          />
        )
      } else {
        innerCellJSX = (
          <Chip
            color="default"
            className="tw-italic tw-opacity-40 tw-cursor-pointer"
            label={rLIB('Missing')}
          />
        )
      }
      let cellJSX = (
        <Box
          onClick={() => {
            const FormDialogObject: TsInterface_FormDialogObject = {
              form: {
                formAdditionalData: tableAdditionalData,
                formData: rowData,
                formInputs: {
                  name_abbreviation: {
                    data_type: 'string',
                    input_type: 'text_basic',
                    key: 'name_abbreviation',
                    label: rLIB('Name Abbreviation'),
                    required: true,
                  },
                },
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {
                  submit_button_alignment: 'right',
                  submit_button_icon: (
                    <Icon
                      icon="floppy-disk"
                      type="solid"
                    />
                  ),
                  submit_button_saving_icon: true,
                  submit_button_text: rLIB('Save') as JSX.Element,
                  submit_button_theme: 'success',
                },
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        // Make sure that the abbreviation has not been used already
                        let newAbbreviation = formSubmittedData.name_abbreviation.toUpperCase().trim()
                        let promiseArray: TsType_UnknownPromise[] = []
                        let abbreviationAlreadyUsed = false
                        promiseArray.push(
                          DatabaseGetCollection(DatabaseRef_UsersWithSpecificNameAbbreviation_Query(res_GCK.clientKey, newAbbreviation))
                            .then((res_DGC) => {
                              for (let loopUserKey in res_DGC.data) {
                                if (loopUserKey !== rowData.key) {
                                  abbreviationAlreadyUsed = true
                                }
                              }
                            })
                            .catch((rej_DGC) => {
                              console.error(rej_DGC)
                            }),
                        )
                        Promise.all(promiseArray).then(() => {
                          if (abbreviationAlreadyUsed === false) {
                            DatabaseSetMergeDocument(DatabaseRef_ClientUser_Document(res_GCK.clientKey, rowData.key as string), {
                              name_abbreviation: newAbbreviation,
                            })
                              .then((res_DSMD) => {
                                resolve({ success: true })
                              })
                              .catch((rej_DSMD) => {
                                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                reject(rej_DSMD)
                              })
                          } else {
                            let error = {
                              message: rLIB('Failed to update abbreviation'),
                              details: rLIB('This abbreviation has already been used'),
                              code: 'ER-D-TFS-DI-01',
                            }
                            tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                            reject({ success: false, error: error })
                          }
                        })
                      })
                      .catch((rej_GCK) => {
                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        reject(rej_GCK)
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: <>{rowData.name}</>,
                formDialogIcon: (
                  <Icon
                    icon="pen-to-square"
                    type="solid"
                  />
                ),
              },
            }
            tableHooks.uc_setUserInterface_FormDialogDisplay({
              display: true,
              form: FormDialogObject,
            })
          }}
        >
          {innerCellJSX}
        </Box>
      )
      return cellJSX
    },
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      let cellCSS = ''
      return cellCSS
    },
  },
}

const userRoleColumn = {
  header: {
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return rLIB('User Type')
    },
    header_sort_by: 'user_role',
  },
  cell: {
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      let cellJSX = (
        <Box
          onClick={() => {
            if (
              tableHooks != null &&
              tableHooks.uc_RootData_GlobalUser != null &&
              tableHooks.uc_RootData_GlobalUser.key != null &&
              rowData != null &&
              rowData.key != null &&
              tableHooks.uc_RootData_GlobalUser.key !== rowData.key
            ) {
              const FormDialogObject: TsInterface_FormDialogObject = {
                form: {
                  formAdditionalData: tableAdditionalData,
                  formData: rowData,
                  formInputs: {
                    user_role: formInputs_UserNew['user_role'],
                    associated_organization_type: formInputs_UserNew['associated_organization_type'],
                  },
                  formOnChange: (
                    formAdditionalData: TsInterface_FormAdditionalData,
                    formData: TsInterface_FormData,
                    formInputs: TsInterface_FormInputs,
                    formSettings: TsInterface_FormSettings,
                  ) => {
                    if (
                      formData.user_role != null &&
                      ClientUserRoles != null &&
                      ClientUserRoles[formData.user_role as string] != null &&
                      ClientUserRoles[formData.user_role as string]['default_associated_organization_type'] != null
                    ) {
                      formData['associated_organization_type'] = ClientUserRoles[formData.user_role as string]['default_associated_organization_type']
                    }
                  },
                  formSettings: {
                    submit_button_alignment: 'right',
                    submit_button_icon: (
                      <Icon
                        icon="floppy-disk"
                        type="solid"
                      />
                    ),
                    submit_button_saving_icon: true,
                    submit_button_text: rLIB('Save') as JSX.Element,
                    submit_button_theme: 'success',
                  },
                  formSubmission: (
                    formSubmittedData: TsInterface_FormSubmittedData,
                    formAdditionalData: TsInterface_FormAdditionalData,
                    formHooks: TsInterface_FormHooksObject,
                  ) => {
                    return new Promise((resolve, reject) => {
                      if (formSubmittedData.user_role != null) {
                        let updateObject: TsInterface_UnspecifiedObject = {
                          user_role: formSubmittedData.user_role,
                          associated_organization_type: formSubmittedData.associated_organization_type,
                          task_roles: {},
                        }
                        updateObject['task_roles'][formSubmittedData.user_role] = true
                        getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            if (res_GCK.clientKey != null && rowData.key != null) {
                              DatabaseSetMergeDocument(DatabaseRef_ClientUser_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                                .then((res_DSMD) => {
                                  resolve({ success: true })
                                })
                                .catch((rej_DSMD) => {
                                  tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                  reject(rej_DSMD)
                                })
                            }
                          })
                          .catch((rej_GCK) => {
                            tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            reject(rej_GCK)
                          })
                      } else {
                        reject({ success: false })
                      }
                    })
                  },
                },
                dialog: {
                  formDialogHeaderColor: 'success',
                  formDialogHeaderText: <>{rowData.name}</>,
                  formDialogIcon: (
                    <Icon
                      icon="pen-to-square"
                      type="solid"
                    />
                  ),
                },
              }
              tableHooks.uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: FormDialogObject,
              })
            }
          }}
        >
          {rJSX_UserRoleChip(rowData)}
        </Box>
      )
      return cellJSX
    },
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      let cellCSS = ''
      return cellCSS
    },
  },
}

const manageColumn = TableCellManage({
  view: {
    icon: <Icon icon="magnifying-glass" />,
    label: <>{rLIB('View')}</>,
    onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      viewUser(rowData, tableAdditionalData, tableHooks)
    },
  },
  edit: {
    icon: <Icon icon="pen-to-square" />,
    label: <>{rLIB('Edit')}</>,
    conditional_display: {
      active: true,
      logic_type: 'comparison',
      source: 'rowData',
      prop: 'status',
      comparator: '!=',
      value: 'deleted',
      conditions: [],
    },
    onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      editUser(rowData, tableAdditionalData, tableHooks)
    },
  },
  delete: {
    icon: <Icon icon="lock" />,
    label: <>{rLIB('Disable Account')}</>,
    conditional_display: {
      active: true,
      logic_type: 'comparison',
      source: 'rowData',
      prop: 'status',
      comparator: '!=',
      value: 'deleted',
      conditions: [],
    },
    onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      disableUserAccount(rowData, tableAdditionalData, tableHooks)
    },
  },
  undelete: {
    icon: <Icon icon="lock-open" />,
    label: <>{rLIB('Enable Account')}</>,
    conditional_display: {
      active: true,
      logic_type: 'comparison',
      source: 'rowData',
      prop: 'status',
      comparator: '==',
      value: 'deleted',
      conditions: [],
    },
    onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      enableUserAccount(rowData, tableAdditionalData, tableHooks)
    },
  },

  mirror: {
    icon: <Icon icon="user-alt" />,
    label: <>Mirror User</>,
    onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      const { uc_RootData_GlobalUser } = tableAdditionalData

      if (uc_RootData_GlobalUser && uc_RootData_GlobalUser.key) {
        // Set the original_user_key if it's not already set
        const originalUserKey = uc_RootData_GlobalUser.original_user_key || uc_RootData_GlobalUser.key

        // Call the cloud function to start mirroring the user
        cloudFunctionManageRequest('manageUser', {
          function: 'startMirroringUser',
          user_key: uc_RootData_GlobalUser.key, // Original user key
          client_key: uc_RootData_GlobalUser.client_key, // Pass the client key
          mirror_uid: rowData.key as string, // Use 'mirror_uid' to match what the cloud function expects
          mirror_name: rowData.name as string, // Use 'mirror_name' to match the cloud function
          original_user_key: originalUserKey, // Pass the original user key
        })
          .then(() => {
            console.log('Successfully started mirroring user:', rowData.name)
          })
          .catch((error) => {
            console.error('Error starting mirroring user:', error)
          })
      } else {
        console.error('Global user data is missing or invalid.')
      }
    },
  },
  welcomeEmail: {
    icon: <Icon icon="envelope" />,
    label: <>Send Welcome Email</>,
    onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      const userName = rowData.name as string
      const userEmail = rowData.email as string
      if (tableAdditionalData?.handleOpenDialog) {
        tableAdditionalData.handleOpenDialog(userName, userEmail)
      } else {
        console.error('handleOpenDialog function is not provided in the tableAdditionalData.')
      }
    },
  },
  passwordReset: {
    icon: <Icon icon="key" />,
    label: <>Send Password Reset Email</>,
    onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      const userEmail = rowData.email as string
      tableAdditionalData.handleOpenResetDialog(userEmail) // Use the reset dialog handler
    },
  },
})

const keyColumn = TableCellBasic('key', rLIB('Key'), 'key')

const taskRolesColumn = {
  header: {
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return rLIB('Task Roles')
    },
    header_sort_by: null,
  },
  cell: {
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      let cellJSX = <></>
      if (rowData.task_roles == null) {
        // @ts-expect-error - TODO: reason for error
        rowData.task_roles = {}
        if (rowData.user_role != null) {
          // @ts-expect-error - TODO: reason for error
          rowData.task_roles[rowData.user_role] = true
        }
      }
      const FormDialogObject: TsInterface_FormDialogObject = {
        form: {
          formAdditionalData: {},
          formData: rowData,
          formInputs: {
            task_roles: {
              data_type: 'string',
              input_type: 'multiple_select_checklist',
              key: 'task_roles',
              label: rLIB('Task Roles'),
              required: false,
              options: [],
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {
            submit_button_alignment: 'right',
            submit_button_icon: (
              <Icon
                icon="floppy-disk"
                type="solid"
              />
            ),
            submit_button_saving_icon: true,
            submit_button_text: rLIB('Save') as JSX.Element,
            submit_button_theme: 'success',
          },
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  if (res_GCK.clientKey != null && rowData.key != null) {
                    DatabaseSetMergeDocument(DatabaseRef_ClientUser_Document(res_GCK.clientKey, rowData.key as string), formSubmittedData)
                      .then((res_DSMD) => {
                        resolve({ success: true })
                      })
                      .catch((rej_DSMD) => {
                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      })
                  }
                })
                .catch((rej_GCK) => {
                  tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rowData.name}</>,
          formDialogIcon: (
            <Icon
              icon="pen-to-square"
              type="solid"
            />
          ),
        },
      }
      let clonedTaskRoles: TsInterface_UnspecifiedObject = cloneObjectWithoutReference(getProp(rowData, 'task_roles', {}))
      let activeTaskRoles: TsInterface_UnspecifiedObject = {}
      for (let roleKey in clonedTaskRoles) {
        if (clonedTaskRoles[roleKey] === true) {
          activeTaskRoles[roleKey] = true
        }
      }
      if (rowData != null && activeTaskRoles != null && objectToArray(activeTaskRoles).length > 1) {
        cellJSX = (
          <Box className="">{rJSX_AdditionalTaskRoleChipList(rowData, tableAdditionalData.task_roles, FormDialogObject, tableHooks, tableAdditionalData)}</Box>
        )
      } else {
        cellJSX = (
          <Box className="tw-opacity-50">
            <Chip
              // size={ "small" }
              variant="outlined"
              label={rLIB('No Additional Task Roles')}
              color={'default'}
              icon={<Icon icon="pen-to-square" />}
              onClick={() => {
                FormDialogObject['form']['formInputs']['task_roles']['options'] = returnTaskRolesOptions(
                  getProp(rowData, 'user_role', null),
                  tableAdditionalData.task_roles,
                )
                tableHooks.uc_setUserInterface_FormDialogDisplay({
                  display: true,
                  form: FormDialogObject,
                })
              }}
            />
          </Box>
        )
      }
      return cellJSX
    },
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      let cellCSS = ''
      return cellCSS
    },
  },
}

const associatedSalesPartnerColumn = {
  header: {
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return <></>
    },
    header_sort_by: null,
  },
  cell: {
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      let cellJSX = <></>
      const FormDialogObject: TsInterface_FormDialogObject = {
        form: {
          formAdditionalData: {},
          formData: rowData,
          formInputs: {
            associated_sales_partner_key: {
              data_type: 'string',
              input_type: 'multiple_choice_radio',
              key: 'associated_sales_partner_key',
              label: rLIB('Sales Partner'),
              required: false,
              options: [],
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {
            submit_button_alignment: 'right',
            submit_button_icon: (
              <Icon
                icon="floppy-disk"
                type="solid"
              />
            ),
            submit_button_saving_icon: true,
            submit_button_text: rLIB('Save') as JSX.Element,
            submit_button_theme: 'success',
          },
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let salesPartners = getProp(tableAdditionalData, 'sales_partners', {})
              let selectedSalesPartner = getProp(salesPartners, formSubmittedData.associated_sales_partner_key, {})
              let updateObject = {
                associated_sales_partner_key: formSubmittedData.associated_sales_partner_key,
                associated_sales_partner_name: getProp(selectedSalesPartner, 'name', null),
              }
              getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  if (res_GCK.clientKey != null && rowData.key != null) {
                    DatabaseSetMergeDocument(DatabaseRef_ClientUser_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                      .then((res_DSMD) => {
                        resolve({ success: true })
                      })
                      .catch((rej_DSMD) => {
                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      })
                  }
                })
                .catch((rej_GCK) => {
                  tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rowData.name}</>,
          formDialogIcon: (
            <Icon
              icon="pen-to-square"
              type="solid"
            />
          ),
        },
      }
      if (rowData != null && (rowData.user_role === 'sales_manager' || rowData.user_role === 'sales_partner_admin' || rowData.user_role === 'sales_rep')) {
        let editSalesPartnerButtonJSX = (
          <Box
            onClick={() => {
              let salesPartnerOptions: TsInterface_UnspecifiedObject[] = []
              for (let loopPartnerKey in getProp(tableAdditionalData, 'sales_partners', {})) {
                let loopPartner = tableAdditionalData.sales_partners[loopPartnerKey]
                salesPartnerOptions.push({ key: loopPartner.key, value: loopPartner.name })
              }
              FormDialogObject['form']['formInputs']['associated_sales_partner_key']['options'] = salesPartnerOptions
              tableHooks.uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: FormDialogObject,
              })
            }}
            className="tw-cursor-pointer tw-ml-2 tw-opacity-30 hover:tw-opacity-100 tw-inline-block"
          >
            <Icon icon="pen-to-square" />
          </Box>
        )
        if (rowData.associated_sales_partner_key != null && rowData.associated_sales_partner_name != null) {
          cellJSX = (
            <Box className="">
              {rowData.associated_sales_partner_name}
              {editSalesPartnerButtonJSX}
            </Box>
          )
        } else {
          cellJSX = (
            <Box className="">
              <Box className="tw-inline-block tw-opacity-30 tw-italic">{rLIB('No sales partner selected')}</Box>
              {editSalesPartnerButtonJSX}
            </Box>
          )
        }
      }
      return cellJSX
    },
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      let cellCSS = ''
      return cellCSS
    },
  },
}

export const tableColumns_User: TsInterface_TableColumns = {
  manage: manageColumn,
  name: nameColumn,
  name_abbreviation: nameAbbreviationColumn,
  email: emailColumn,
  phone: phoneColumn,
  user_role: userRoleColumn,
  task_roles: taskRolesColumn,
  associated_sales_partner_key: associatedSalesPartnerColumn,
}

export const tableColumns_UserReadOnly: TsInterface_TableColumns = {
  name: nameColumn,
  key: keyColumn,
  email: emailColumn,
  phone: phoneColumn,
  user_role: userRoleColumn,
}

// User Timecards
const readOnlyUserRoleColumn = {
  header: {
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return rLIB('User Type')
    },
    header_sort_by: 'user_role',
  },
  cell: {
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      let cellJSX = <Box>{rJSX_UserRoleChip(rowData)}</Box>
      return cellJSX
    },
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      let cellCSS = ''
      return cellCSS
    },
  },
}
// const hourlyOrSalaryColumn = {
// header: {
// 	header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return s_HOURLY_OR_SALARIED },
// 	header_sort_by: "hourly_or_salaried"
// },
// cell: {
// 	cell_jsx: ( rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks ) => {
// 		let cellJSX = <></>
// 		let editIconJSX =
// 		<Box
// 			className="tw-inline-block tw-opacity-30 hover:tw-opacity-100 tw-ml-2 tw-cursor-pointer"
// 			onClick={() => {
// 				tableAdditionalData.editHourlyOrSalaried( rowData.key, rowData )
// 			}}
// 		>
// 			<Icon icon="pen-to-square" />
// 		</Box>
// 		if( rowData != null && rowData.hourly_or_salaried != null ){
// 			if(
// 				hourlyOrSalariedOptions != null &&
// 				hourlyOrSalariedOptions[ rowData.hourly_or_salaried as string ] != null &&
// 				hourlyOrSalariedOptions[ rowData.hourly_or_salaried as string ]["value"] != null
// 			){
// 				cellJSX =
// 				<Box>
// 					<Box className="tw-inline-block" >
// 						{ hourlyOrSalariedOptions[ rowData.hourly_or_salaried as string ]["value"] }
// 					</Box>
// 					{ editIconJSX }
// 				</Box>
// 			} else {
// 				cellJSX =
// 				<Box>
// 					<Box className="tw-inline-block" >
// 						{ rowData.hourly_or_salaried }
// 					</Box>
// 					{ editIconJSX }
// 				</Box>
// 			}
// 		} else {
// 			cellJSX =
// 			<Box>
// 				<Box className="tw-opacity-30 tw-italic tw-inline-block" >
// 					{ rLIB("Missing") }
// 				</Box>
// 				{ editIconJSX }
// 			</Box>
// 		}
// 		return cellJSX
// 	},
// 	cell_css: ( rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData ) => {
// 		let cellCSS = ""
// 		return cellCSS
// 	}
// },
// }
// const payrollUserIdColumn = {
// header: {
// 	header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return s_PAYROLL_USER_ID },
// 	header_sort_by: "id_number_payroll"
// },
// cell: {
// 	cell_jsx: ( rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks ) => {
// 		let cellJSX = <></>
// 		let editIconJSX =
// 		<Box
// 			className="tw-inline-block tw-opacity-30 hover:tw-opacity-100 tw-ml-2 tw-cursor-pointer"
// 			onClick={() => {
// 				tableAdditionalData.editPayrollUserID( rowData.key, rowData )
// 			}}
// 		>
// 			<Icon icon="pen-to-square" />
// 		</Box>
// 		if( rowData != null && rowData.id_number_payroll != null ){
// 			cellJSX =
// 			<Box>
// 				<Box className="tw-inline-block" >
// 					{ rowData.id_number_payroll }
// 				</Box>
// 				{ editIconJSX }
// 			</Box>
// 		} else {
// 			cellJSX =
// 			<Box>
// 				<Box className="tw-opacity-30 tw-italic tw-inline-block" >
// 					{ rLIB("Missing") }
// 				</Box>
// 				{ editIconJSX }
// 			</Box>
// 		}
// 		return cellJSX
// 	},
// 	cell_css: ( rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData ) => {
// 		let cellCSS = ""
// 		return cellCSS
// 	}
// },
// }
// const payrollOrganizationIdColumn = {
// header: {
// 	header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return s_PAYROLL_ORGANIZATION_ID },
// 	header_sort_by: "id_organization_payroll"
// },
// cell: {
// 	cell_jsx: ( rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks ) => {
// 		let cellJSX = <></>
// 		let editIconJSX =
// 		<Box
// 			className="tw-inline-block tw-opacity-30 hover:tw-opacity-100 tw-ml-2 tw-cursor-pointer"
// 			onClick={() => {
// 				tableAdditionalData.editPayrollOrganizationID( rowData.key, rowData )
// 			}}
// 		>
// 			<Icon icon="pen-to-square" />
// 		</Box>
// 		if( rowData != null && rowData.id_organization_payroll != null ){
// 			cellJSX =
// 			<Box>
// 				<Box className="tw-inline-block" >
// 					{ rowData.id_organization_payroll }
// 				</Box>
// 				{ editIconJSX }
// 			</Box>
// 		} else {
// 			cellJSX =
// 			<Box>
// 				<Box className="tw-opacity-30 tw-italic tw-inline-block" >
// 					{ rLIB("Missing") }
// 				</Box>
// 				{ editIconJSX }
// 			</Box>
// 		}
// 		return cellJSX
// 	},
// 	cell_css: ( rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData ) => {
// 		let cellCSS = ""
// 		return cellCSS
// 	}
// },
// }
const defaultAllocationsColumn = {
  header: {
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return rLIB('Default Time Allocations')
    },
    header_sort_by: null,
  },
  cell: {
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      let cellJSX = <></>
      // Get Data
      let dataStep1 = getProp(tableAdditionalData, 'userDefaultTimeAllocations', {})
      let dataStep2 = getProp(dataStep1, rowData.key, {})
      let dataStep3 = getProp(dataStep2, 'allocated_times', {})
      let totalHours = sumOfValues(dataStep3)
      let editIconJSX = (
        <Box
          className="tw-inline-block tw-opacity-30 hover:tw-opacity-100 tw-ml-2 tw-cursor-pointer"
          onClick={() => {
            tableAdditionalData.editDefaultTimeAllocations(rowData.key, rowData)
          }}
        >
          <Icon icon="pen-to-square" />
        </Box>
      )
      // JSX
      if (totalHours > 0) {
        cellJSX = (
          <Box className="tw-align-top">
            {rJSX_TimeAllocationsDisplay(getProp(tableAdditionalData, 'allocationTypes', {}), dataStep3)}
            {editIconJSX}
          </Box>
        )
      } else {
        cellJSX = (
          <Box className="tw-align-top">
            <Box className="tw-opacity-30 tw-italic tw-inline-block">{rLIB('Missing')}</Box>
            {editIconJSX}
          </Box>
        )
      }
      // Return
      return cellJSX
    },
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      let cellCSS = 'tw-align-top'
      return cellCSS
    },
  },
}
const overrideAllocationsColumn = {
  header: {
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return rLIB('Override Time Allocations')
    },
    header_sort_by: null,
  },
  cell: {
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      let cellJSX = <></>
      // Get Data
      if (tableAdditionalData != null && tableAdditionalData.weekStartDate != null) {
        let editIconJSX = (
          <Box
            className="tw-inline-block tw-opacity-30 hover:tw-opacity-100 tw-ml-2 tw-cursor-pointer"
            onClick={() => {
              tableAdditionalData.editOverrideTimeAllocations(rowData.key, rowData)
            }}
          >
            <Icon icon="pen-to-square" />
          </Box>
        )
        let overrideDataKey = returnFormattedDateKey(tableAdditionalData.weekStartDate) + '_' + rowData.key
        let dataStep1 = getProp(tableAdditionalData, 'userOverrideTimeAllocations', {})
        let dataStep2 = getProp(dataStep1, overrideDataKey, {})
        let dataStep3 = getProp(dataStep2, 'allocated_times', {})
        let totalHours = sumOfValues(dataStep3)
        // JSX
        if (totalHours > 0) {
          cellJSX = (
            <Box className="tw-align-top">
              {rJSX_TimeAllocationsDisplay(getProp(tableAdditionalData, 'allocationTypes', {}), dataStep3)}
              {editIconJSX}
            </Box>
          )
        } else {
          cellJSX = (
            <Box className="tw-align-top">
              <Box className="tw-opacity-30 tw-italic tw-inline-block">{rLIB('Missing')}</Box>
              {editIconJSX}
            </Box>
          )
        }
      }
      // Return
      return cellJSX
    },
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      let cellCSS = 'tw-align-top'
      return cellCSS
    },
  },
}
const multipleSelectCheckboxColumn = {
  header: {
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return <></>
    },
    header_sort_by: null,
  },
  cell: {
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      let cellJSX = (
        <Box>
          <Checkbox
            /* @ts-expect-error  - TODO: reason */
            checked={tableAdditionalData.multiSelectedUsers[rowData.key] === true}
            onChange={() => {
              let userKey = getProp(rowData, 'key', null)
              if (
                userKey != null &&
                tableAdditionalData != null &&
                tableAdditionalData.multiSelectedUsers != null &&
                tableAdditionalData.setMultiSelectedUsers != null
              ) {
                const multiSelectedUsers = { ...tableAdditionalData.multiSelectedUsers }
                multiSelectedUsers[userKey] = !multiSelectedUsers[userKey]
                tableAdditionalData.setMultiSelectedUsers(multiSelectedUsers)
              }
            }}
          />
        </Box>
      )
      return cellJSX
    },
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      let cellCSS = ''
      return cellCSS
    },
  },
}

const spendingAuthorizationsColumn = {
  header: {
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return rLIB('Verifications')
    },
    header_sort_by: null,
  },
  cell: {
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      let cellJSX = <></>
      // JSX
      cellJSX = <Box className="tw-align-top">{rJSX_AuthorizationsDisplay(getProp(rowData, 'stripe_authorizations', {}), rowData.key as string)}</Box>
      // Return
      return cellJSX
    },
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      let cellCSS = 'tw-align-top'
      return cellCSS
    },
  },
}

export const tableColumns_UserTimecardSettings: TsInterface_TableColumns = {
  multiselect: multipleSelectCheckboxColumn,
  name: TableCellBasic('name', rLIB('Name'), 'name'),
  user_role: readOnlyUserRoleColumn,
  // hourly_or_salaried: hourlyOrSalaryColumn,
  // id_number_payroll: payrollUserIdColumn,
  // id_organization_payroll: payrollOrganizationIdColumn,
  default_allocations: defaultAllocationsColumn,
  override_allocations: overrideAllocationsColumn,
}

export const tableSettings_UserTimecardSettings: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: false,
  collapsible_columns: true,
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
    },
  ],
  // searchable: true,
  // search_settings_database: {
  // 	search_type: "firebase",
  // 	search_no_data_message: rLIB("No Users found by search"),
  // 	search_property_options: [
  // 		{ key: "name", value: rLIB("User Name") },
  // 		{ key: "email", value: rLIB("Email") },
  // 		{ key: "phone", value: rLIB("Phone") },
  // 	]
  // },
}

export const tableColumns_UserSpendingLimitsSettings: TsInterface_TableColumns = {
  name: nameSpendingColumn,
  // stripeID: TableCellBasic('stripe_cardholder_id', 'Stripe ID', 'stripe_cardholder_id'),
  authorizations: spendingAuthorizationsColumn,
  // spendingLimits: spendingLimitsColumn,
}

export const tableSettings_UserSpendingLimitsSettings: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: true,
  collapsible_columns: true,
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
    },
  ],
  // searchable: true,
  // search_settings_database: {
  // 	search_type: "firebase",
  // 	search_no_data_message: rLIB("No Users found by search"),
  // 	search_property_options: [
  // 		{ key: "name", value: rLIB("User Name") },
  // 		{ key: "email", value: rLIB("Email") },
  // 		{ key: "phone", value: rLIB("Phone") },
  // 	]
  // },
}
