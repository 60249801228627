/* eslint-disable react/display-name */
///////////////////////////////
// Description
///////////////////////////////

/*
DESCRIPTION / USAGE:
example component description

TODO:

*/

///////////////////////////////
// Imports
///////////////////////////////
import { Box, Card } from '@mui/material'
import { tableColumns_ActiveProjects, tableSettings_MyProjects } from 'app/models/projects/project_table'
import { forwardRef, useContext, useEffect, useReducer } from 'react'
import { DatabaseRef_Projects_Collection } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { TableDatabase, TsInterface_TableAdditionalData, TsInterface_TableDatabaseEndpointQueryObject } from 'rfbp_core/components/table'
import { Context_RootData_ClientKey, Context_RootData_GlobalUser } from 'rfbp_core/services/context'
import {
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { getProp } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ProjectList_MyProjectsTab {
  us_tableFilterStatus: string
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const ProjectListMyProjectsTab = forwardRef((props: TsInterface_ProjectList_MyProjectsTab, ref: React.ForwardedRef<unknown>): JSX.Element => {
  // Props
  let us_tableFilterStatus: TsInterface_ProjectList_MyProjectsTab['us_tableFilterStatus'] = getProp(props, 'us_tableFilterStatus', 'active')

  // Hooks - useContext, useState, useReducer, other
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)

  // Hooks - useEffect
  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Functions
  const tableDatabaseEndpoint_MyProjects = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let userRoleQueryProp = 'associated_css_rep_key'
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
      { prop: 'status', comparator: '==', value: us_tableFilterStatus },
      { prop: userRoleQueryProp, comparator: '==', value: uc_RootData_GlobalUser.key as string },
    ]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'associated_customer_name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 5)
    return generateDatabaseQuery(DatabaseRef_Projects_Collection(uc_RootData_ClientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
  }

  // JSX Generation
  const rJSX_MyProjectsTable = (): JSX.Element => {
    let tableJSX = <></>
    if (tableSettings_MyProjects.search_settings_database == null) {
      tableSettings_MyProjects.search_settings_database = { search_type: 'meilisearch' }
    }
    tableSettings_MyProjects.search_settings_database.search_client_key = uc_RootData_ClientKey
    let userKey = getProp(uc_RootData_GlobalUser, 'key', null)
    let searchFilters = ["status = 'active'", "associated_css_rep_key = '" + userKey + "'"]
    tableSettings_MyProjects.search_settings_database.search_filters = searchFilters
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Box>
          <Card className="">
            <TableDatabase
              tableAdditionalData={{ visible_active_tasks: {} }}
              tableColumns={tableColumns_ActiveProjects}
              tableDatabaseEndpoint={tableDatabaseEndpoint_MyProjects}
              tableSettings={tableSettings_MyProjects}
            />
          </Card>
        </Box>
      )
    } else {
      tableJSX = <></>
    }
    return tableJSX
  }

  const rJSX_Tab = (): JSX.Element => {
    let tabJSX = (
      <Box className="tw-m-auto">
        <Box>{rJSX_MyProjectsTable()}</Box>
      </Box>
    )
    return tabJSX
  }

  // Render
  return <>{rJSX_Tab()}</>
})
