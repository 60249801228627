/* eslint-disable react/display-name */
///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			example component description

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Tooltip, Typography } from '@mui/material/'
import React, { forwardRef, useEffect, useState } from 'react'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

// TODO: Rename and fill out
interface TsInterface_ProjectTaskCalendar {
  // propKey: type
  events: TsInterface_UnspecifiedObject
}

interface CalendarEvent {
  name: string
  color: string
  index: number
  render_type: 'single_day' | 'first_day' | 'middle_day' | 'last_day'
  elapsed_days: number
}

interface DayEvents {
  [eventId: string]: CalendarEvent
}

interface WeekDates {
  sun: string
  mon: string
  tue: string
  wed: string
  thu: string
  fri: string
  sat: string
}

interface CalendarWeek {
  week_number: number
  dates: WeekDates
  events: {
    [date: string]: DayEvents
  }
}

interface CalendarData {
  [weekIndex: string]: CalendarWeek
}

///////////////////////////////
// Variables
///////////////////////////////

// // Example 1 - Basic Staggering
// let example1TaskData = {
//   event1: {
//     key: 'event1',
//     name: 'Event 1',
//     dates: ['2025-01-01', '2025-01-02', '2025-01-03'],
//   },
//   event2: {
//     key: 'event2',
//     name: 'Event 2',
//     dates: ['2025-01-02'],
//   },
//   event3: {
//     key: 'event3',
//     name: 'Event 3',
//     dates: ['2024-12-31'],
//   },
// }

// let example1Output = [
//   {
//     week_number: 1,
//     dates: {
//       sun: '2024-12-29',
//       mon: '2024-12-30',
//       tue: '2024-12-31',
//       wed: '2025-01-01',
//       thu: '2025-01-02',
//       fri: '2025-01-03',
//       sat: '2025-01-04',
//     },
//     events: {
//       '2024-12-29': {},
//       '2024-12-30': {},
//       '2024-12-31': {
//         event3: {
//           index: 0,
//           render_type: 'single_day',
//         },
//       },
//       '2025-01-01': {
//         event1: {
//           index: 0,
//           render_type: 'first_day',
//         },
//       },
//       '2025-01-02': {
//         event1: {
//           index: 0,
//           render_type: 'middle_day',
//         },
//         event2: {
//           index: 1,
//           render_type: 'single_day',
//         },
//       },
//       '2025-01-03': {
//         event1: {
//           index: 0,
//           render_type: 'last_day',
//         },
//       },
//       '2025-01-04': {},
//     },
//   },
// ]

// // Example 2 - Alphabetical order for start days / Filling unused indexes
// let example2TaskData = {
//   event1: {
//     key: 'event1',
//     name: 'Event 1',
//     dates: ['2025-01-02'],
//   },
//   event2: {
//     key: 'event2',
//     name: 'Event 2',
//     dates: ['2025-01-02', '2025-01-03', '2025-01-04'],
//   },
//   event3: {
//     key: 'event3',
//     name: 'Event 3',
//     dates: ['2025-01-02'],
//   },
//   event4: {
//     key: 'event4',
//     name: 'Event 4',
//     dates: ['2025-01-03'],
//   },
// }

// // Even though task 2 is across multiple days, it shows up under task 1
// // Also task 4 shows up above task 2 because there is an open index on that day
// let example2Output = [
//   {
//     week_number: 1,
//     dates: {
//       sun: '2024-12-29',
//       mon: '2024-12-30',
//       tue: '2024-12-31',
//       wed: '2025-01-01',
//       thu: '2025-01-02',
//       fri: '2025-01-03',
//       sat: '2025-01-04',
//     },
//     events: {
//       '2024-12-29': {},
//       '2024-12-30': {},
//       '2024-12-31': {},
//       '2025-01-01': {},
//       '2025-01-02': {
//         event1: {
//           index: 0,
//           render_type: 'single_day',
//         },
//         event2: {
//           index: 1,
//           render_type: 'middle_day',
//         },
//         event3: {
//           index: 2,
//           render_type: 'single_day',
//         },
//       },
//       '2025-01-03': {
//         event2: {
//           index: 1,
//           render_type: 'middle_day',
//         },
//         event4: {
//           index: 0,
//           render_type: 'single_day',
//         },
//       },
//       '2025-01-04': {
//         event2: {
//           index: 1,
//           render_type: 'last_day',
//         },
//       },
//     },
//   },
// ]

// // Example 3 - Multiple weeks / skipping days
// let example3TaskData = {
//   event1: {
//     key: 'event1',
//     name: 'Event 1',
//     dates: ['2025-01-01'],
//   },
//   event2: {
//     key: 'event2',
//     name: 'Event 2',
//     dates: ['2024-12-25', '2025-01-01'],
//   },
//   event3: {
//     key: 'event3',
//     name: 'Event 3',
//     dates: ['2024-12-25', '2024-12-26', '2024-12-27'],
//   },
// }

// // Event 2 has a different index in week 2 because absolute index does not matter across different weeks
// let example3Output = [
//   {
//     week_number: 1,
//     dates: {
//       sun: '2024-12-22',
//       mon: '2024-12-23',
//       tue: '2024-12-24',
//       wed: '2024-12-25',
//       thu: '2024-12-26',
//       fri: '2024-12-27',
//       sat: '2024-12-28',
//     },
//     events: {
//       '2024-12-22': {},
//       '2024-12-23': {},
//       '2024-12-24': {},
//       '2024-12-25': {
//         event2: {
//           index: 0,
//           render_type: 'start_day',
//         },
//         event3: {
//           index: 1,
//           render_type: 'start_day',
//         },
//       },
//       '2024-12-26': {
//         event3: {
//           index: 1,
//           render_type: 'middle_day',
//         },
//       },
//       '2024-12-27': {
//         event3: {
//           index: 1,
//           render_type: 'end_day',
//         },
//       },
//       '2024-12-28': {},
//     },
//   },
//   {
//     week_number: 2,
//     dates: {
//       sun: '2024-12-29',
//       mon: '2024-12-30',
//       tue: '2024-12-31',
//       wed: '2025-01-01',
//       thu: '2025-01-02',
//       fri: '2025-01-03',
//       sat: '2025-01-04',
//     },
//     events: {
//       '2024-12-29': {},
//       '2024-12-30': {},
//       '2024-12-31': {},
//       '2025-01-01': {
//         event1: {
//           index: 0,
//           render_type: 'single_day',
//         },
//         event2: {
//           index: 1,
//           render_type: 'end_day',
//         },
//       },
//       '2025-01-02': {},
//       '2025-01-03': {},
//       '2025-01-04': {},
//     },
//   },
// ]

///////////////////////////////
// Functions
///////////////////////////////

// Helper function to get week number in YYYYWW format
const getWeekNumber = (date: string) => {
  const d = new Date(date)
  const year = d.getFullYear()

  // If it's December but the week contains Jan 1st of next year
  if (d.getMonth() === 11) {
    const nextJan1 = new Date(year + 1, 0, 1)
    const daysTillNextYear = Math.ceil((nextJan1.getTime() - d.getTime()) / (1000 * 60 * 60 * 24))
    if (daysTillNextYear <= d.getDay()) {
      return parseInt(`${year + 1}01`)
    }
  }

  // If this week contains Jan 1st, it's week 1
  if (d.getMonth() === 0) {
    const jan1 = new Date(year, 0, 1)
    const daysSinceJan1 = Math.floor((d.getTime() - jan1.getTime()) / (1000 * 60 * 60 * 24))
    if (daysSinceJan1 < 7 - jan1.getDay()) {
      return parseInt(`${year}01`)
    }
  }

  const firstDayOfYear = new Date(year, 0, 1)
  const pastDaysOfYear = (d.getTime() - firstDayOfYear.getTime()) / 86400000
  const weekNum = Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7)
  // If it's the last few days of December but part of week 1 of next year
  if (d.getMonth() === 11 && d.getDate() >= 29) {
    return parseInt(`${year + 1}01`)
  }
  return parseInt(`${year}${weekNum.toString().padStart(2, '0')}`)
}

const formatEventDataForCalendarRendering = (eventData: TsInterface_UnspecifiedObject) => {
  // Get all unique dates and sort them
  const allDates = new Set<string>()
  Object.values(eventData).forEach((event: any) => {
    event.dates.forEach((date: string) => allDates.add(date))
  })
  const sortedDates = Array.from(allDates).sort()

  // Find first and last week
  const firstDate = sortedDates[0]
  const lastDate = sortedDates[sortedDates.length - 1]
  // const firstWeek = getWeekNumber(firstDate)
  // const lastWeek = getWeekNumber(lastDate)

  // Create array of all weeks between first and last
  const allWeeks = new Set<number>()
  let currentDate = new Date(firstDate)
  const endDate = new Date(lastDate)

  while (currentDate <= endDate) {
    allWeeks.add(getWeekNumber(currentDate.toISOString().split('T')[0]))
    // Add 7 days by using proper date manipulation
    currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 7)
  }

  // Group dates by week
  const weekGroups = new Map<number, string[]>()
  // Initialize all weeks, even empty ones
  allWeeks.forEach((weekNum) => {
    weekGroups.set(weekNum, [])
  })
  // Add dates to their respective weeks
  sortedDates.forEach((date) => {
    const weekNum = getWeekNumber(date)
    weekGroups.get(weekNum)?.push(date)
  })

  // Initialize formatted data structure
  const formattedEventData: any[] = []

  // Process each week
  weekGroups.forEach((weekDates, weekNum) => {
    // Get Sunday of the week for the first date
    const firstDateOfWeek = weekDates.length > 0 ? weekDates[0] : new Date(Math.floor(weekNum / 100), 0, (weekNum % 100) * 7).toISOString().split('T')[0]
    const firstDate = new Date(firstDateOfWeek)
    const sunday = new Date(firstDate)
    sunday.setDate(firstDate.getDate() - firstDate.getDay())

    // Create week structure with proper date handling
    const weekData = {
      week_number: weekNum,
      dates: {
        sun: new Date(sunday.getFullYear(), sunday.getMonth(), sunday.getDate() + 0).toISOString().split('T')[0],
        mon: new Date(sunday.getFullYear(), sunday.getMonth(), sunday.getDate() + 1).toISOString().split('T')[0],
        tue: new Date(sunday.getFullYear(), sunday.getMonth(), sunday.getDate() + 2).toISOString().split('T')[0],
        wed: new Date(sunday.getFullYear(), sunday.getMonth(), sunday.getDate() + 3).toISOString().split('T')[0],
        thu: new Date(sunday.getFullYear(), sunday.getMonth(), sunday.getDate() + 4).toISOString().split('T')[0],
        fri: new Date(sunday.getFullYear(), sunday.getMonth(), sunday.getDate() + 5).toISOString().split('T')[0],
        sat: new Date(sunday.getFullYear(), sunday.getMonth(), sunday.getDate() + 6).toISOString().split('T')[0],
      },
      events: {} as any,
    }

    // Initialize empty events for each day
    Object.values(weekData.dates).forEach((date) => {
      weekData.events[date] = {}
    })

    // Track used indexes for the week
    const weekIndexTracker = new Map<string, number>()

    // Process each day in the week
    Object.values(weekData.dates).forEach((date) => {
      const dayEvents = new Map<string, { dates: string[]; name: string; color: string; elapsed_days: number }>()

      // Find events for this day
      Object.entries(eventData).forEach(([eventKey, event]: [string, any]) => {
        if (event.dates.includes(date)) {
          dayEvents.set(eventKey, event)
        }
      })

      // Sort events alphabetically by name
      const sortedEvents = Array.from(dayEvents.entries()).sort((a, b) => a[1].name.localeCompare(b[1].name))

      // Assign indexes and render types
      const usedIndexes = new Set<number>()
      sortedEvents.forEach(([eventKey, event]) => {
        const dates = event.dates.sort()
        const eventIndex = weekIndexTracker.has(eventKey) ? weekIndexTracker.get(eventKey)! : getNextAvailableIndex(usedIndexes)

        weekIndexTracker.set(eventKey, eventIndex)
        usedIndexes.add(eventIndex)

        let renderType = 'single_day'
        if (dates.length > 1) {
          if (date === dates[0]) {
            renderType = 'first_day'
          } else if (date === dates[dates.length - 1]) {
            renderType = 'last_day'
          } else {
            renderType = 'middle_day'
          }
        }

        weekData.events[date][eventKey] = {
          name: event.name,
          color: event.color,
          index: eventIndex,
          elapsed_days: event.elapsed_days,
          render_type: renderType,
        }
      })
    })

    formattedEventData.push(weekData)
  })

  return formattedEventData
}

// Helper function to get the next available index
function getNextAvailableIndex(usedIndexes: Set<number>): number {
  let index = 0
  while (usedIndexes.has(index)) {
    index++
  }
  return index
}

///////////////////////////////
// Container
///////////////////////////////

// TODO: Rename
export const ProjectTaskCalendar = forwardRef((props: TsInterface_ProjectTaskCalendar, ref: React.ForwardedRef<unknown>): JSX.Element => {
  // Props
  let pr_events: TsInterface_ProjectTaskCalendar['events'] = getProp(props, 'events', {})

  // Hooks - useContext, useState, useReducer, other
  const [us_calendarEvents, us_setCalendarEvents] = useState<TsInterface_UnspecifiedObject>({})
  // const ur_forceRerender = 				useReducer(() => ({}), {})[1] as () => void
  // const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = 		useContext( Context_RootData_ClientKey )

  // Hooks - useEffect
  useEffect(() => {
    if (pr_events != null && objectToArray(pr_events).length > 0) {
      let calendarEvents = formatEventDataForCalendarRendering(pr_events)
      us_setCalendarEvents(calendarEvents)
    }
  }, [pr_events])

  // Functions

  // JSX Generation
  const rJSX_Calendar = (): JSX.Element => {
    const renderEvent = (event: CalendarEvent): JSX.Element => {
      const borderRadius = {
        single_day: '4px',
        first_day: '4px 0 0 4px',
        middle_day: '0',
        last_day: '0 4px 4px 0',
      }[event.render_type]

      const marginLeft = {
        single_day: '12px',
        first_day: '12px',
        middle_day: '0px',
        last_day: '0px',
      }[event.render_type]

      const marginRight = {
        single_day: '12px',
        first_day: '0px',
        middle_day: '0px',
        last_day: '12px',
      }[event.render_type]

      // elapsed_days

      return (
        <React.Fragment key={event.index}>
          <Tooltip
            placement="right"
            title={
              <>
                {event.name} - {event.elapsed_days} days
              </>
            }
          >
            <Box
              sx={{
                height: '24px',
                marginTop: '2px',
                backgroundColor: event.color,
                borderRadius,
                marginLeft,
                marginRight,
                marginBottom: '4px',
                padding: '0 8px',
                color: 'white',
                fontSize: '12px',
                lineHeight: '24px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {event.name}
            </Box>
          </Tooltip>
        </React.Fragment>
      )
    }

    const renderDay = (date: string, events: CalendarWeek['events']): JSX.Element => {
      // Sort events by index
      const sortedEvents = Object.entries(events[date] || {}).sort(([, a], [, b]) => a.index - b.index)

      // Find highest index to determine number of slots needed
      const maxIndex = sortedEvents.length > 0 ? Math.max(...sortedEvents.map(([, event]) => event.index)) : -1

      return (
        <Box
          sx={{
            width: '14.28%',
            border: '1px solid #eee',
            padding: '0px',
            minHeight: '120px',
          }}
        >
          <Box
            sx={{
              paddingLeft: '8px',
              paddingRight: '8px',
              paddingTop: '8px',
            }}
          >
            <Typography
              sx={{
                fontSize: '14px',
                color: '#666',
                marginBottom: '8px',
              }}
            >
              {new Date(date).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}
            </Typography>
          </Box>
          <Box>
            {Array.from({ length: maxIndex + 1 }, (_, i) => {
              const eventEntry = sortedEvents.find(([, event]) => event.index === i)
              return eventEntry ? (
                renderEvent(eventEntry[1])
              ) : (
                <Box
                  key={i}
                  sx={{ height: '26px' }}
                />
              )
            })}
          </Box>
        </Box>
      )
    }

    return (
      <Box sx={{ width: '100%' }}>
        <Box sx={{ display: 'flex', borderBottom: '2px solid #ddd' }}>
          {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
            <Box
              key={day}
              sx={{
                width: '14.28%',
                padding: '8px',
                textAlign: 'center',
                fontWeight: 'bold',
              }}
            >
              {day}
            </Box>
          ))}
        </Box>

        {Object.values(us_calendarEvents).map((week, weekIndex) => (
          <Box
            key={weekIndex}
            sx={{ display: 'flex' }}
          >
            {(Object.values(week.dates) as string[]).map((date) => renderDay(date, week.events))}
          </Box>
        ))}
      </Box>
    )
  }

  const rJSX_Component = (): JSX.Element => {
    let componentJSX = <Box>{rJSX_Calendar()}</Box>
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
})
