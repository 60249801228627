/* eslint-disable react/display-name */
///////////////////////////////
// Description
///////////////////////////////

/*
DESCRIPTION / USAGE:
example component description

TODO:

*/

///////////////////////////////
// Imports
///////////////////////////////
import { Box, Button, Card, Divider, FormControl, MenuItem, Select, Stack, Switch, Typography } from '@mui/material'
import { rJSX_ProjectSearchResultWithStatus, tableColumns_ActiveProjects, tableSettings_SearchProjects } from 'app/models/projects/project_table'
import { returnTaskRows } from 'app/models/tasks'
import { findRecursiveTasks, returnTaskPrerequisiteAnalysisObject } from 'app/models/tasks/task_workflow_services'
import { forwardRef, useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatabaseRef_TaskWorkflowProd_Document, DatabaseRef_TaskWorkflowsActive_Query } from 'rfbp_aux/services/database_endpoints/directory/task_workflows'
import { DatabaseRef_UsersWithTaskRole_Query, DatabaseRef_UsersWithUserRole_Query } from 'rfbp_aux/services/database_endpoints/directory/users'
import { DatabaseRef_Projects_Collection } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { Icon } from 'rfbp_core/components/icons'
import { SearchInput } from 'rfbp_core/components/search'
import { TableDatabase, TsInterface_TableAdditionalData, TsInterface_TableDatabaseEndpointQueryObject } from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey } from 'rfbp_core/services/context'
import {
  DatabaseGetCollection,
  DatabaseGetDocument,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ProjectList_ProjectSearchTab {}

///////////////////////////////
// Variables
///////////////////////////////

// Form
let projectStatusOptions: TsInterface_UnspecifiedObject = {
  active: { key: 'active', value: rLIB('Active'), disabled: false },
  on_hold: { key: 'on_hold', value: rLIB('On Hold'), disabled: false },
  cancelled: { key: 'cancelled', value: rLIB('Cancelled'), disabled: false },
  completed: { key: 'completed', value: rLIB('Completed'), disabled: false },
}

const customerStatusOptions: TsInterface_UnspecifiedObject = {
  5: {
    key: 5,
    value: rLIB('Promoter'),
    icon: (
      <Box
        className="tw-inline-block"
        sx={{ color: themeVariables.info_main }}
      >
        <Icon icon="face-party" />{' '}
      </Box>
    ),
  },
  4: {
    key: 4,
    value: rLIB('Happy Customer'),
    icon: (
      <Box
        className="tw-inline-block"
        sx={{ color: themeVariables.success_main }}
      >
        <Icon icon="face-smile-beam" />{' '}
      </Box>
    ),
  },
  3: {
    key: 3,
    value: rLIB('Neutral Customer'),
    icon: (
      <Box
        className="tw-inline-block"
        sx={{ color: themeVariables.warning_main }}
      >
        <Icon icon="face-meh" />{' '}
      </Box>
    ),
  },
  2: {
    key: 2,
    value: rLIB('Unhappy Customer'),
    icon: (
      <Box
        className="tw-inline-block"
        sx={{ color: themeVariables.orange_500 }}
      >
        <Icon icon="face-worried" />{' '}
      </Box>
    ),
  },
  1: {
    key: 1,
    value: rLIB('Highly Escalated Customer'),
    icon: (
      <Box
        className="tw-inline-block"
        sx={{ color: themeVariables.error_main }}
      >
        <Icon icon="face-pouting" />{' '}
      </Box>
    ),
  },
  0: {
    key: 0,
    value: rLIB('Cancelled'),
    icon: (
      <Box
        className="tw-inline-block"
        sx={{ color: themeVariables.gray_700 }}
      >
        <Icon icon="skull" />{' '}
      </Box>
    ),
  },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const ProjectListProjectSearchTab = forwardRef((props: TsInterface_ProjectList_ProjectSearchTab, ref: React.ForwardedRef<unknown>): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  const [us_cssRepOptions, us_setCssRepOptions] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_filterByCSS, us_setFilterByCSS] = useState<boolean>(false)
  const [us_filterByCustomerHappiness, us_setFilterByCustomerHappiness] = useState<boolean>(false)
  const [us_filterBySales, us_setFilterBySales] = useState<boolean>(false)
  const [us_filterByWorkflow, us_setFilterByWorkflow] = useState<boolean>(false)
  const [us_projectFilterCSS, us_setProjectFilterCSS] = useState<string | null>('')
  const [us_projectFilterCustomerHappiness, us_setProjectFilterCustomerHappiness] = useState<number>(3)
  const [us_projectFilterSales, us_setProjectFilterSales] = useState<string | null>('')
  const [us_projectFilterStatus, us_setProjectFilterStatus] = useState<string | null>('active')
  const [us_projectFilterWorkflow, us_setProjectFilterWorkflow] = useState<string | null>('')
  const [us_projectFilterWorkflowSelectedTask, us_setProjectFilterWorkflowSelectedTask] = useState<string | null>(null)
  const [us_salesRepOptions, us_setSalesRepOptions] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_showSearchResults, us_setShowSearchResults] = useState<boolean>(false)
  const [us_sortedMilestoneFilterTasksArray, us_setSortedMilestoneFilterTasksArray] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_taskWorkflowsArray, us_setTaskWorkflowsArray] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_taskWorkflowsObject, us_setTaskWorkflowsObject] = useState<TsInterface_UnspecifiedObject>({})
  const [us_taskWorkflowsProdTasks, us_setTaskWorkflowsProdTasks] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)

  // Hooks - useEffect
  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetCollection(DatabaseRef_UsersWithTaskRole_Query(res_GCK.clientKey, 'css_rep'))
          .then((res_DGC) => {
            let users: TsInterface_UnspecifiedObject[] = []
            for (let userKey in res_DGC.data) {
              let loopUser = res_DGC.data[userKey]
              users.push({ key: loopUser.key, value: loopUser.name })
            }
            us_setCssRepOptions(users)
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetCollection(DatabaseRef_UsersWithUserRole_Query(res_GCK.clientKey, 'sales_rep'))
          .then((res_DGC) => {
            let users: TsInterface_UnspecifiedObject[] = []
            for (let userKey in res_DGC.data) {
              let loopUser = res_DGC.data[userKey]
              users.push({ key: loopUser.key, value: loopUser.name })
            }
            us_setSalesRepOptions(users)
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetCollection(DatabaseRef_UsersWithUserRole_Query(res_GCK.clientKey, 'sales_rep'))
          .then((res_DGC) => {
            let users: TsInterface_UnspecifiedObject[] = []
            for (let userKey in res_DGC.data) {
              let loopUser = res_DGC.data[userKey]
              users.push({ key: loopUser.key, value: loopUser.name })
            }
            us_setSalesRepOptions(users)
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    if (
      us_filterByWorkflow === true &&
      us_projectFilterWorkflow != null &&
      us_projectFilterWorkflow !== '' &&
      us_taskWorkflowsObject != null &&
      us_taskWorkflowsObject[us_projectFilterWorkflow] != null &&
      us_taskWorkflowsObject[us_projectFilterWorkflow]['filter_milestones'] != null &&
      us_taskWorkflowsProdTasks != null &&
      us_taskWorkflowsProdTasks['tasks'] != null
    ) {
      // Order Tasks
      let sortedMilestoneTasks: TsInterface_UnspecifiedObject[] = []
      let tasksThatReferenceThemselves = findRecursiveTasks(returnTaskPrerequisiteAnalysisObject(us_taskWorkflowsProdTasks['tasks']))
      let sortedTaskRows = returnTaskRows(us_taskWorkflowsProdTasks['tasks'], {}, tasksThatReferenceThemselves, ['Active Projects List useEffect'])
      // Loop through grouped rows
      let loopIndex = 0
      for (let loopRowKey in sortedTaskRows) {
        let loopRow = sortedTaskRows[loopRowKey]
        if (loopRow != null && loopRow['tasks'] != null) {
          for (let loopTaskIndex in loopRow['tasks'].sort(dynamicSort('timestamp_created', null))) {
            let loopTask = loopRow['tasks'][loopTaskIndex]
            if (loopTask != null && loopTask.key != null && us_taskWorkflowsObject[us_projectFilterWorkflow]['filter_milestones'][loopTask.key] === true) {
              sortedMilestoneTasks.push({
                name: getProp(loopTask, 'name', null),
                key: getProp(loopTask, 'key', null),
                index: loopIndex,
              })
              loopIndex++
            }
          }
        }
      }
      us_setSortedMilestoneFilterTasksArray(sortedMilestoneTasks)
    }
  }, [us_filterByWorkflow, us_projectFilterWorkflow, us_taskWorkflowsObject, us_taskWorkflowsProdTasks])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetCollection(DatabaseRef_TaskWorkflowsActive_Query(res_GCK.clientKey, 'name', false, null, {}))
          .then((res_DGC) => {
            us_setTaskWorkflowsObject(res_DGC.data)
            let workflows: TsInterface_UnspecifiedObject[] = []
            for (let workflowKey in res_DGC.data) {
              let loopWorkflow = res_DGC.data[workflowKey]
              workflows.push({ key: loopWorkflow.key, value: loopWorkflow.name })
            }
            us_setTaskWorkflowsArray(workflows)
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    if (us_projectFilterWorkflow != null && us_projectFilterWorkflow !== '') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseGetDocument(DatabaseRef_TaskWorkflowProd_Document(res_GCK.clientKey, us_projectFilterWorkflow))
            .then((res_DGD) => {
              us_setTaskWorkflowsProdTasks(res_DGD.data)
              ur_forceRerender()
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_projectFilterWorkflow])

  // Functions
  const tableDatabaseEndpoint_SearchProjects = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
    // queryOperatorsArray: TsInterface_QueryOperatorsArray = [ { prop: "status", comparator: "==", value: "unassigned" } ]
    if (us_projectFilterStatus != null && us_projectFilterStatus !== '') {
      queryOperatorsArray.push({ prop: 'status', comparator: '==', value: us_projectFilterStatus })
    }
    if (us_filterByCSS === true && us_projectFilterCSS != null && us_projectFilterCSS !== '') {
      queryOperatorsArray.push({ prop: 'associated_css_rep_key', comparator: '==', value: us_projectFilterCSS })
    }
    if (us_filterBySales === true && us_projectFilterSales != null && us_projectFilterSales !== '') {
      queryOperatorsArray.push({ prop: 'associated_sales_rep_name', comparator: '==', value: us_projectFilterSales })
    }
    if (us_filterByCustomerHappiness === true && us_projectFilterCustomerHappiness != null) {
      queryOperatorsArray.push({ prop: 'associated_customer_risk_status', comparator: '==', value: us_projectFilterCustomerHappiness })
    }
    if (us_filterByWorkflow === true && us_projectFilterWorkflow != null && us_projectFilterWorkflow !== '') {
      queryOperatorsArray.push({ prop: 'associated_task_workflow_key', comparator: '==', value: us_projectFilterWorkflow })
    }
    if (
      us_filterByWorkflow === true &&
      us_projectFilterWorkflow != null &&
      us_projectFilterWorkflow !== '' &&
      us_sortedMilestoneFilterTasksArray != null &&
      us_sortedMilestoneFilterTasksArray.length > 0 &&
      us_projectFilterWorkflowSelectedTask != null
    ) {
      // Loop through milestone tasks
      let selectedTask: TsInterface_UnspecifiedObject | null = null
      let followupTask: TsInterface_UnspecifiedObject | null = null
      let foundSelectedTask = false
      let foundFollowupTask = false
      let setFollowupTask = false
      for (let loopTaskIndex in us_sortedMilestoneFilterTasksArray) {
        let loopTask = us_sortedMilestoneFilterTasksArray[loopTaskIndex]
        if (setFollowupTask === true) {
          followupTask = loopTask
          foundFollowupTask = true
          setFollowupTask = false
        }
        if (loopTask != null && loopTask.key != null && loopTask.key === us_projectFilterWorkflowSelectedTask) {
          selectedTask = loopTask
          foundSelectedTask = true
          setFollowupTask = true
        }
      }
      if (foundSelectedTask === true && selectedTask != null && selectedTask.key != null) {
        queryOperatorsArray.push({ prop: 'task_statuses.' + selectedTask.key, comparator: '==', value: true })
      }
      if (foundFollowupTask === true && followupTask != null && followupTask.key != null) {
        queryOperatorsArray.push({ prop: 'task_statuses.' + followupTask.key, comparator: '==', value: false })
      }
    }

    // TODO - task statuses from workflow options
    // task_statuses.$taskKey

    let orderByArray: TsInterface_OrderByArray = []
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(DatabaseRef_Projects_Collection(uc_RootData_ClientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
  }

  // JSX Generation
  const rJSX_StatusFilterInput = (): JSX.Element => {
    let inputJSX = <></>
    inputJSX = (
      <FormControl className="bp_thin_select_input">
        <Select
          color="primary"
          value={us_projectFilterStatus}
          onChange={(event: any) => {
            if (event != null && event.target != null && event.target.value != null) {
              us_setProjectFilterStatus(event.target.value)
            }
          }}
          variant="outlined"
        >
          {objectToArray(projectStatusOptions).map((option: TsInterface_UnspecifiedObject, index: number) => (
            <MenuItem
              key={index}
              value={option['key']}
              disabled={option['disabled'] === true}
            >
              {option['value']}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
    return inputJSX
  }

  const rJSX_CssFilterInput = (): JSX.Element => {
    let inputJSX = <></>
    if (us_filterByCSS === true) {
      inputJSX = (
        <FormControl className="bp_thin_select_input">
          <Select
            color="primary"
            value={us_projectFilterCSS}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                us_setProjectFilterCSS(event.target.value)
              }
            }}
            variant="outlined"
          >
            {us_cssRepOptions.sort(dynamicSort('value', null)).map((option: TsInterface_UnspecifiedObject, index: number) => (
              <MenuItem
                key={index}
                value={option['key']}
                disabled={option['disabled'] === true}
              >
                {option['value']}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )
    }
    return inputJSX
  }

  const rJSX_SalesFilterInput = (): JSX.Element => {
    let inputJSX = <></>
    if (us_filterBySales === true) {
      inputJSX = (
        <FormControl className="bp_thin_select_input">
          <Select
            color="primary"
            value={us_projectFilterSales}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                us_setProjectFilterSales(event.target.value)
              }
            }}
            variant="outlined"
          >
            {us_salesRepOptions.sort(dynamicSort('value', null)).map((option: TsInterface_UnspecifiedObject, index: number) => (
              <MenuItem
                key={index}
                value={option['key']}
                disabled={option['disabled'] === true}
              >
                {option['value']}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )
    }
    return inputJSX
  }

  const rJSX_CustomerHappinessFilterInput = (): JSX.Element => {
    let inputJSX = <></>
    if (us_filterByCustomerHappiness === true) {
      inputJSX = (
        <FormControl className="bp_thin_select_input">
          <Select
            color="primary"
            value={us_projectFilterCustomerHappiness}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                us_setProjectFilterCustomerHappiness(event.target.value)
              }
            }}
            variant="outlined"
          >
            {objectToArray(customerStatusOptions).map((option: TsInterface_UnspecifiedObject, index: number) => (
              <MenuItem
                key={index}
                value={option['key']}
                disabled={option['disabled'] === true}
              >
                <Box>
                  {option['icon']} {option['value']}
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )
    }
    return inputJSX
  }

  const rJSX_WorkflowFilterInput = (): JSX.Element => {
    let inputJSX = <></>
    if (us_filterByWorkflow === true) {
      inputJSX = (
        <FormControl className="bp_thin_select_input">
          <Select
            color="primary"
            value={us_projectFilterWorkflow}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                us_setProjectFilterWorkflowSelectedTask(null)
                us_setSortedMilestoneFilterTasksArray([])
                us_setProjectFilterWorkflow(event.target.value)
              }
            }}
            variant="outlined"
          >
            {us_taskWorkflowsArray.sort(dynamicSort('value', null)).map((option: TsInterface_UnspecifiedObject, index: number) => (
              <MenuItem
                key={index}
                value={option['key']}
                disabled={option['disabled'] === true}
              >
                {option['value']}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )
    }
    return inputJSX
  }

  const rJSX_FilterTaskMilestoneButton = (task: TsInterface_UnspecifiedObject, allMilestoneTasks: TsInterface_UnspecifiedObject[]): JSX.Element => {
    let buttonJSX = <></>
    let topLeftRadius: string | number = 0
    let bottomLeftRadius: string | number = 0
    let topRightRadius: string | number = 0
    let bottomRightRadius: string | number = 0
    if (task.index === 0) {
      topLeftRadius = '10px'
      bottomLeftRadius = '10px'
    }
    if (task.index === allMilestoneTasks.length - 1) {
      topRightRadius = '10px'
      bottomRightRadius = '10px'
    }
    if (us_projectFilterWorkflowSelectedTask === task.key) {
      buttonJSX = (
        <Button
          sx={{
            fontSize: '11px',
            borderTopLeftRadius: topLeftRadius,
            borderBottomLeftRadius: bottomLeftRadius,
            borderTopRightRadius: topRightRadius,
            borderBottomRightRadius: bottomRightRadius,
          }}
          variant="contained"
          color="success"
          onClick={() => {
            us_setProjectFilterWorkflowSelectedTask(null)
          }}
        >
          {task['name']}
        </Button>
      )
    } else {
      // Instantiate Variables
      let selectedTask: TsInterface_UnspecifiedObject | null = null
      // Loop through milestone tasks
      for (let loopTaskIndex in allMilestoneTasks) {
        let loopTask = allMilestoneTasks[loopTaskIndex]
        if (loopTask != null && loopTask.key != null && loopTask.key === us_projectFilterWorkflowSelectedTask) {
          selectedTask = loopTask
        }
      }
      let buttonVariant: 'outlined' | 'contained' = 'outlined'
      let buttonColor: 'info' | 'warning' = 'warning'
      if (selectedTask != null && selectedTask.index > task.index) {
        buttonVariant = 'contained'
        buttonColor = 'info'
      }
      buttonJSX = (
        <Button
          sx={{
            fontSize: '11px',
            borderTopLeftRadius: topLeftRadius,
            borderBottomLeftRadius: bottomLeftRadius,
            borderTopRightRadius: topRightRadius,
            borderBottomRightRadius: bottomRightRadius,
          }}
          variant={buttonVariant}
          color={buttonColor}
          onClick={() => {
            us_setProjectFilterWorkflowSelectedTask(task.key)
          }}
        >
          {task['name']}
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_WorkflowFilterAdditionalInputs = (): JSX.Element => {
    let inputsJSX = <></>
    if (us_sortedMilestoneFilterTasksArray != null && us_sortedMilestoneFilterTasksArray.length > 0) {
      inputsJSX = (
        <Box>
          {us_sortedMilestoneFilterTasksArray.map((task: TsInterface_UnspecifiedObject, index: number) => (
            <Box
              key={index}
              className="tw-inline-block"
            >
              {rJSX_FilterTaskMilestoneButton(task, us_sortedMilestoneFilterTasksArray)}
            </Box>
          ))}
        </Box>
      )
    }
    return inputsJSX
  }

  const rJSX_SearchButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        className="tw-mt-2"
        variant="contained"
        color="secondary"
        startIcon={
          <Icon
            icon="magnifying-glass"
            type="solid"
          />
        }
        onClick={() => {
          us_setShowSearchResults(true)
          ur_forceRerender()
        }}
      >
        {rLIB('Search')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_SearchAgainButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        variant="contained"
        color="secondary"
        className=""
        startIcon={
          <Icon
            icon="magnifying-glass"
            type="solid"
          />
        }
        onClick={() => {
          us_setShowSearchResults(false)
          ur_forceRerender()
        }}
      >
        {rLIB('Search Again')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_ProjectSearchQuery = (clientKey: string): JSX.Element => {
    let queryJSX = <></>
    if (us_showSearchResults === false) {
      queryJSX = (
        <Box className="tw-p-4">
          <Typography
            variant="h6"
            className="tw-my-1"
          >
            {rLIB('Direct Project Search')}
          </Typography>
          <Box>
            <SearchInput
              clientKey={clientKey}
              searchIndexKey={'projects'}
              searchFilters={[]}
              searchResultRenderer={rJSX_ProjectSearchResultWithStatus}
              additionalSearchData={{}}
            />
          </Box>
          <Divider className="tw-my-2" />
          <Typography
            variant="h6"
            className="tw-my-1"
          >
            {rLIB('Filter Project Search')}
          </Typography>
          <Stack
            direction="row"
            spacing={2}
          >
            <Switch
              checked={true}
              disabled={true}
            />
            <Typography
              variant="h6"
              className="tw-my-1"
            >
              {rLIB('Filter by Project Status')}
            </Typography>
            {rJSX_StatusFilterInput()}
          </Stack>
          <Stack
            direction="row"
            spacing={2}
          >
            <Switch
              checked={us_filterByCSS}
              onChange={(event, value) => {
                if (value === true || value === false) {
                  us_setFilterByCSS(value)
                }
              }}
            />
            <Typography
              variant="h6"
              className="tw-my-1"
            >
              {rLIB('Filter by CSS Rep')}
            </Typography>
            {rJSX_CssFilterInput()}
          </Stack>
          <Stack
            direction="row"
            spacing={2}
          >
            <Switch
              checked={us_filterBySales}
              onChange={(event, value) => {
                if (value === true || value === false) {
                  us_setFilterBySales(value)
                }
              }}
            />
            <Typography
              variant="h6"
              className="tw-my-1"
            >
              {rLIB('Filter by Sales Rep')}
            </Typography>
            {rJSX_SalesFilterInput()}
          </Stack>
          <Stack
            direction="row"
            spacing={2}
          >
            <Switch
              checked={us_filterByCustomerHappiness}
              onChange={(event, value) => {
                if (value === true || value === false) {
                  us_setFilterByCustomerHappiness(value)
                }
              }}
            />
            <Typography
              variant="h6"
              className="tw-my-1"
            >
              {rLIB('Filter by Customer Satisfaction')}
            </Typography>
            {rJSX_CustomerHappinessFilterInput()}
          </Stack>
          <Stack
            direction="row"
            spacing={2}
          >
            <Switch
              checked={us_filterByWorkflow}
              onChange={(event, value) => {
                if (value === true || value === false) {
                  us_setFilterByWorkflow(value)
                }
              }}
            />
            <Typography
              variant="h6"
              className="tw-my-1"
            >
              {rLIB('Filter by Workflow')}
            </Typography>
            {rJSX_WorkflowFilterInput()}
          </Stack>
          {rJSX_WorkflowFilterAdditionalInputs()}
          {rJSX_SearchButton()}
        </Box>
      )
    } else {
      queryJSX = (
        <Box>
          <Box className="tw-p-4">{rJSX_SearchAgainButton()}</Box>
          <Card className="">
            <TableDatabase
              tableAdditionalData={{}}
              tableColumns={tableColumns_ActiveProjects}
              tableDatabaseEndpoint={tableDatabaseEndpoint_SearchProjects}
              tableSettings={tableSettings_SearchProjects}
            />
          </Card>
        </Box>
      )
    }
    return queryJSX
  }

  const rJSX_SearchProjectsTab = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tabJSX = <Card className="tw-text-left">{rJSX_ProjectSearchQuery(uc_RootData_ClientKey)}</Card>
    } else {
      tabJSX = <></>
    }
    return tabJSX
  }

  const rJSX_Tab = (): JSX.Element => {
    let tabJSX = (
      <Box className="tw-m-auto">
        <Box>{rJSX_SearchProjectsTab()}</Box>
      </Box>
    )
    return tabJSX
  }

  // Render
  return <>{rJSX_Tab()}</>
})
