///////////////////////////////
// Imports
///////////////////////////////

import { DatabaseRef_Regions_Collection } from 'rfbp_aux/services/database_endpoints/directory/regions'
import { DatabaseRef_Supplier_Document } from 'rfbp_aux/services/database_endpoints/materials/suppliers'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TableCellManage,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseGetCollection, DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'
import { formInputs_EditSupplierLocation } from '../forms/form_edit_supplier_location'

///////////////////////////////
// Exports
///////////////////////////////

export const tableColumns_MaterialSupplierLocations: TsInterface_TableColumns = {
  manage: TableCellManage({
    edit: {
      icon: (
        <Icon
          type="solid"
          icon="pen-to-square"
        />
      ),
      label: <>{rLIB('Edit')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
            .then((res_GCK) => {
              // Load Regions
              let promiseArray: TsType_UnknownPromise[] = []
              let activeRegions: TsInterface_UnspecifiedObject = {}
              promiseArray.push(
                DatabaseGetCollection(DatabaseRef_Regions_Collection(res_GCK.clientKey))
                  .then((res_DGD) => {
                    // categoriesData = res_DGD.data
                    for (let loopRegionKey in res_DGD.data) {
                      let loopRegion = res_DGD.data[loopRegionKey]
                      if (loopRegion != null && loopRegion.status != 'deleted') {
                        activeRegions[loopRegionKey] = { key: loopRegionKey, value: loopRegion.name }
                      }
                    }
                  })
                  .catch((rej_DGD) => {
                    console.error(rej_DGD)
                  }),
              )
              // After Data Loaded
              Promise.all(promiseArray).finally(() => {
                formInputs_EditSupplierLocation['associated_region_key']['options'] = objectToArray(activeRegions)
                tableHooks.uc_setUserInterface_FormDialogDisplay({
                  display: true,
                  form: {
                    form: {
                      formAdditionalData: {},
                      formData: rowData,
                      formInputs: formInputs_EditSupplierLocation,
                      formOnChange: (
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formData: TsInterface_FormData,
                        formInputs: TsInterface_FormInputs,
                        formSettings: TsInterface_FormSettings,
                      ) => {},
                      formSettings: {},
                      formSubmission: (
                        formSubmittedData: TsInterface_FormSubmittedData,
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formHooks: TsInterface_FormHooksObject,
                      ) => {
                        return new Promise((resolve, reject) => {
                          let updateObject: TsInterface_UnspecifiedObject = {
                            locations: {
                              [rowData.key as string]: {
                                associated_region_name: getProp(getProp(activeRegions, formSubmittedData.associated_region_key, {}), 'value', null),
                                ...formSubmittedData,
                              },
                            },
                          }
                          DatabaseSetMergeDocument(DatabaseRef_Supplier_Document(res_GCK.clientKey, tableAdditionalData.supplier_key), updateObject)
                            .then((res_DSMD) => {
                              resolve(res_DSMD)
                            })
                            .catch((rej_DSMD) => {
                              reject(rej_DSMD)
                              tableHooks.uc_setUserInterface_ErrorDialogDisplay({
                                display: true,
                                error: rej_DSMD.error,
                              })
                            })
                        })
                      },
                    },
                    dialog: {
                      formDialogHeaderColor: 'info',
                      formDialogHeaderText: rLIB('Create New Supplier Location'),
                      formDialogIcon: (
                        <Icon
                          type="solid"
                          icon="circle-plus"
                        />
                      ),
                    },
                  },
                })
              })
            })
            .catch((rej_GCK) => {
              tableHooks.uc_setUserInterface_ErrorDialogDisplay({
                display: true,
                error: rej_GCK.error,
              })
            })
        }
      },
    },
    delete: {
      icon: (
        <Icon
          type="solid"
          icon="trash"
        />
      ),
      label: <>{rLIB('Delete')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
            .then((res_GCK) => {
              tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
                display: true,
                confirm: {
                  color: 'info',
                  header: rLIB('Delete Location'),
                  icon: (
                    <Icon
                      icon="trash-undo"
                      type="solid"
                    />
                  ),
                  submit_text: rLIB('Delete'),
                  text: rLIB('Are you sure you want to delete this location?'),
                  submit_callback: () => {
                    return new Promise((resolve, reject) => {
                      getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          let updateObject = {
                            locations: {
                              [rowData.key as string]: {
                                status: 'deleted',
                              },
                            },
                          }
                          DatabaseSetMergeDocument(DatabaseRef_Supplier_Document(res_GCK.clientKey, tableAdditionalData.supplier_key), updateObject)
                            .then((res_DSM) => {
                              resolve(res_DSM)
                            })
                            .catch((rej_DSM) => {
                              tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSM.error })
                              reject(rej_DSM)
                            })
                        })
                        .catch((rej_GCK) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                          reject(rej_GCK)
                        })
                    })
                  },
                },
              })
            })
            .catch((rej_GCK) => {
              tableHooks.uc_setUserInterface_ErrorDialogDisplay({
                display: true,
                error: rej_GCK.error,
              })
            })
        }
      },
    },
  }),
  name: TableCellBasic('name', rLIB('Location Name'), 'name'),
  location_email: TableCellBasic('location_email', rLIB('Location Email'), 'location_email'),
  associated_region_name: TableCellBasic('associated_region_name', rLIB('Region'), 'associated_region_name'),
  location_address: TableCellBasic('location_address', rLIB('Street Address'), 'location_address'),
  location_city: TableCellBasic('location_city', rLIB('City'), 'location_city'),
  location_state: TableCellBasic('location_state', rLIB('State'), 'location_state'),
  location_zip: TableCellBasic('location_zip', rLIB('Zip'), 'location_zip'),
}

export const tableSettings_MaterialSupplierSKUs: TsInterface_TableSettings = {
  paginated: false,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property_default: 'name',
  sortable: true,
}
