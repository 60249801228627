import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { cloudFunctionUnauthenticatedRequests } from 'app/services/external_requests/external_requests'
import { updatePassword, User } from 'firebase/auth'
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip as RTooltip } from 'recharts'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { directAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { GeoTiffMap } from './components/geoTiffMap'
import { uploadPhotoFile } from './functions/sales_opportunity_v3_functions'
import {
  rJSX_AdditionalOptionsSection,
  rJSX_designPreferences,
  rJSX_generalInformation,
  rJSX_homeDetails,
  rJSX_mainPanel,
  rJSX_otherGenerationAndHighLoadItems,
  rJSX_panelLayoutFinanceDetails,
  rJSX_panelLayoutHeader,
  rJSX_panelLayoutSystemDetails,
  rJSX_roofCondition,
  rJSX_subPanel,
  rJSX_welcomeDocumentsDetails,
  rJSX_welcomeHeader,
  rJSX_welcomeLunarFinanceDetails,
  rJSX_welcomeOptionsDetails,
  rJSX_welcomeSolarFinanceDetails,
  rJSX_welcomeSystemDetails,
} from './unauthenticated_sales_opportunity_v3_components/building_page_sections'

export const rJSX_WelcomePage = (
  stateGettersSetters: any,
  palette: any,
  photoInputRef: React.RefObject<HTMLInputElement>,
  fileInputRef: React.RefObject<HTMLInputElement>,
  clientKey: string,
  opportunityKey: string,
): JSX.Element => {
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    // Scroll to the relevant section when the tab changes
    if (newValue === 0) {
      stateGettersSetters.us_setSelectedTab('systemDetails')
    } else if (newValue === 1) {
      stateGettersSetters.us_setSelectedTab('financing')
    } else if (newValue === 2) {
      stateGettersSetters.us_setSelectedTab('survey')
    } else if (newValue === 3) {
      stateGettersSetters.us_setSelectedTab('photo')
    } else if (newValue === 4) {
      stateGettersSetters.us_setSelectedTab('usage')
    } else if (newValue === 5) {
      stateGettersSetters.us_setSelectedTab('documents')
    }
  }

  // const orderStatusTabSelected = stateGettersSetters.us_selectedTab === 'orderStatus' ? palette.aqua : palette.darkGray
  const financingTabSelected = stateGettersSetters.us_selectedTab === 'financing' ? palette.aqua : palette.darkGray
  const surveyTabSelected = stateGettersSetters.us_selectedTab === 'survey' ? palette.aqua : palette.darkGray
  const photoTabSelected = stateGettersSetters.us_selectedTab === 'photo' ? palette.aqua : palette.darkGray
  const systemSizeTabSelected = stateGettersSetters.us_selectedTab === 'systemDetails' ? palette.aqua : palette.darkGray
  const documentsTabSelected = stateGettersSetters.us_selectedTab === 'documents' ? palette.aqua : palette.darkGray
  const usageTabSelected = stateGettersSetters.us_selectedTab === 'usage' ? palette.aqua : palette.darkGray

  return (
    <Box sx={{ background: '#939598' }}>
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          maxWidth: '450px', // Set a maximum width for the content
          margin: '0 auto',
          background: palette.white,
        }}
      >
        <Box sx={{ background: palette.white }}>
          <Tabs
            value={false}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            sx={{
              '.MuiTabs-scrollButtons': {
                '&.Mui-disabled': { opacity: 0.3 },
                'color': 'rgba(0, 0, 0, 0.87)',
              },
            }}
          >
            {/* <Tab
              label={rLIB('Order Status')}
              sx={{
                fontFamily: 'Poppins',
                fontSize: '9px',
                borderBottom: '2px solid',
                borderColor: orderStatusTabSelected,
                marginX: 0.3,
                color: orderStatusTabSelected,
              }}
            /> */}
            <Tab
              label={rLIB('System Size')}
              sx={{
                fontFamily: 'Poppins',
                fontSize: '9px',
                borderBottom: '2px solid',
                borderColor: systemSizeTabSelected,
                marginX: 0.3,
                color: systemSizeTabSelected,
              }}
            />
            <Tab
              label={rLIB('Pricing')}
              sx={{
                fontFamily: 'Poppins',
                fontSize: '9px',
                borderBottom: '2px solid',
                borderColor: financingTabSelected,
                marginX: 0.3,
                color: financingTabSelected,
              }}
            />
            <Tab
              label={rLIB('Survey')}
              sx={{
                fontFamily: 'Poppins',
                fontSize: '9px',
                borderBottom: '2px solid',
                borderColor: surveyTabSelected,
                marginX: 0.3,
                color: surveyTabSelected,
              }}
            />
            <Tab
              label={rLIB('Photos')}
              sx={{
                fontFamily: 'Poppins',
                fontSize: '9px',
                borderBottom: '2px solid',
                borderColor: photoTabSelected,
                marginX: 0.3,
                color: photoTabSelected,
              }}
            />
            <Tab
              label={rLIB('Usage')}
              sx={{
                fontFamily: 'Poppins',
                fontSize: '9px',
                borderBottom: '2px solid',
                borderColor: usageTabSelected,
                marginX: 0.3,
                color: usageTabSelected,
              }}
            />
            <Tab
              label={rLIB('Documents')}
              sx={{
                fontFamily: 'Poppins',
                fontSize: '9px',
                borderBottom: '2px solid',
                borderColor: documentsTabSelected,
                marginX: 0.3,
                color: documentsTabSelected,
              }}
            />
          </Tabs>
        </Box>
        {/* Aqua background section (3/8 of the page) */}
        {(stateGettersSetters.us_selectedTab === 'orderStatus' || stateGettersSetters.us_selectedTab === 'systemDetails') && (
          <Box
            sx={{
              background: palette.aqua,
              height: '55.5vh', // Reduced from 55.5vh to 37.5vh (3/8 of 100vh)
              position: 'relative',
              padding: '10px',
            }}
          >
            {(stateGettersSetters.us_selectedTab === 'orderStatus' || stateGettersSetters.us_selectedTab === 'systemDetails') && rJSX_welcomeHeader(palette)}
            {/* {stateGettersSetters.us_productType !== 'solar' && (
            <Box sx={{ marginTop: '20px' }}>{rJSX_LunarSystemSection(stateGettersSetters, palette, opportunityKey, clientKey)}</Box>
          )} */}
          </Box>
        )}
        {/* Map positioned at the intersection */}
        {stateGettersSetters.us_selectedTab === 'systemDetails' && (
          <Box
            sx={{
              position: 'static',
              marginTop: '-20vh', // Adjust this value to fine-tune the map's vertical position
              marginBottom: '-10vh',

              zIndex: 1,
              width: '100%',
              height: '35vh',
              overflow: 'hidden',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                width: '80vw',
                maxWidth: '400px',
                height: '35vh',
                overflow: 'hidden',
              }}
            >
              {stateGettersSetters.us_productType === 'solar' && (
                <img
                  src={`data:image/jpeg;base64, ${stateGettersSetters.us_evImageURL}`}
                  alt="Sales Map"
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              )}
              {stateGettersSetters.us_productType === 'lunar_battery' && (
                <img
                  src={stateGettersSetters.us_activeProposalPhoto}
                  alt="Sales Map"
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    maxWidth: '400px', // Set a maximum width for the content
                    margin: '0 auto',
                  }}
                />
              )}
            </Box>
          </Box>
        )}

        {/* White background section (remaining 5/8 of the page) */}
        <Box
          sx={{
            background: palette.white,
            flexGrow: 1,
            padding: '10px',
            paddingTop: stateGettersSetters.us_selectedTab === 'systemDetails' ? '130px' : '0px', // Add padding to account for the overlapping map
            position: 'relative',
            zIndex: 0,
          }}
        >
          {stateGettersSetters.us_selectedTab === 'systemDetails' && rJSX_welcomeSystemDetails(stateGettersSetters, palette, opportunityKey, clientKey)}
          {stateGettersSetters.us_selectedTab === 'systemDetails' && rJSX_welcomeOptionsDetails(stateGettersSetters, palette, clientKey, opportunityKey)}
          {stateGettersSetters.us_selectedTab === 'financing' &&
            stateGettersSetters.us_productType === 'solar' &&
            rJSX_welcomeSolarFinanceDetails(stateGettersSetters, palette)}
          {stateGettersSetters.us_selectedTab === 'financing' &&
            stateGettersSetters.us_productType === 'lunar_battery' &&
            rJSX_welcomeLunarFinanceDetails(stateGettersSetters, palette)}
          {stateGettersSetters.us_selectedTab === 'documents' && rJSX_welcomeDocumentsDetails(stateGettersSetters, palette)}
          {stateGettersSetters.us_selectedTab === 'survey' && rJSX_SurveyPage(stateGettersSetters, palette, fileInputRef, clientKey, opportunityKey)}
          {stateGettersSetters.us_selectedTab === 'photo' && rJSX_PhotoPage(stateGettersSetters, palette, fileInputRef, clientKey, opportunityKey)}
          {stateGettersSetters.us_selectedTab === 'usage' && rJSX_UsagePage(stateGettersSetters, palette, fileInputRef, clientKey, opportunityKey)}
        </Box>
      </Box>
    </Box>
  )
}

export const rJSX_UsagePage = (
  stateGettersSetters: any,
  palette: any,
  fileInputRef: React.RefObject<HTMLInputElement>,
  clientKey: string,
  opportunityKey: string,
): JSX.Element => {
  const prepareDonutData = (
    consumptionProfile: { [month: number]: { [hour: number]: any } } | null,
    selectedMonth: number | null,
    selectedHour: number | null,
  ) => {
    if (!consumptionProfile || selectedMonth === null || selectedHour === null) {
      return { totalData: [], breakdownData: [] }
    }

    const hourData = consumptionProfile[selectedMonth][selectedHour]

    // Helper function to format values
    const formatValue = (value: number): number => {
      return Number((value / 31).toFixed(2))
    }

    // Calculate individual values
    const solarToHome = formatValue(hourData.fromSolar || 0)
    const gridToHome = formatValue(hourData.fromGrid || 0)
    const batteryToHome = formatValue(hourData.fromBattery || 0)
    const solarToBattery = formatValue(hourData.storedInBattery || 0)
    const solarToGrid = formatValue(hourData.exportedToGrid || 0)

    // Calculate total
    const total = solarToHome + gridToHome + batteryToHome + solarToBattery + solarToGrid

    // Prepare data for outer ring (total)
    const totalData = [{ name: 'Total Energy Flow', value: total }]

    // Prepare data for inner ring (breakdown)
    const breakdownData = [
      { name: 'From Solar', value: solarToHome, color: '#ffd700' },
      { name: 'From Grid', value: gridToHome, color: '#4169e1' },
      { name: 'From Battery', value: batteryToHome, color: '#32cd32' },
      { name: 'Charge Battery', value: solarToBattery, color: '#98fb98' },
      { name: 'Export to Grid', value: solarToGrid, color: '#9370db' },
    ].filter((item) => item.value > 0) // Remove zero values

    return { totalData, breakdownData }
  }

  // Custom tooltip component
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <Card
          sx={{
            backgroundColor: '#ffffff',
            boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
            padding: '8px',
            border: 'none',
          }}
        >
          <Typography
            sx={{
              color: 'rgba(0, 0, 0, 0.87)',
              fontSize: '14px',
            }}
          >
            {`${payload[0].name}: ${payload[0].value.toFixed(2)} kWh`}
          </Typography>
        </Card>
      )
    }
    return null
  }

  const currentProfile =
    stateGettersSetters.us_selectedConsumptionProfile === 'solar_only'
      ? stateGettersSetters.us_solarOnlyBill_consumptionProfile
      : stateGettersSetters.us_solarPlusBatteryBill_v2_consumptionProfile

  return (
    <Box sx={{ padding: '5px' }}>
      {stateGettersSetters.us_solarOnlyBill && Object.keys(stateGettersSetters.us_solarOnlyBill).length > 0 && (
        <>
          <Typography
            variant="h6"
            className="tw-opacity-50 tw-mb-0"
          >
            Energy Flow Profile (Donut)
          </Typography>
          <Grid
            container
            spacing={3}
          >
            {/*Profile Selection */}
            <Grid
              item
              xs={12}
              sm={4}
            >
              <FormControl
                fullWidth
                sx={{
                  'backgroundColor': 'white',
                  'borderRadius': 1,
                  '& .MuiInputLabel-root': {
                    color: 'rgba(0, 0, 0, 0.6)',
                  },
                  '& .MuiSelect-select': {
                    color: 'rgba(0, 0, 0, 0.87)',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                  },
                }}
              >
                <InputLabel>Profile Type</InputLabel>
                <Select
                  value={stateGettersSetters.us_selectedConsumptionProfile}
                  onChange={(e) => {
                    stateGettersSetters.us_setSelectedConsumptionProfile(e.target.value as 'solar_only' | 'solar_battery')
                    // Reset month and hour selections when switching profiles
                    stateGettersSetters.us_setSelectedMonth(0)
                    stateGettersSetters.us_setSelectedHour(0)
                  }}
                  label="Profile Type"
                >
                  <MenuItem value="solar_only">Solar Only</MenuItem>
                  <MenuItem value="solar_battery">Solar + Battery</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Month Selection  */}
            <Grid
              item
              xs={12}
              sm={4}
            >
              <FormControl
                fullWidth
                sx={{
                  'backgroundColor': 'white',
                  'borderRadius': 1,
                  '& .MuiInputLabel-root': {
                    color: 'rgba(0, 0, 0, 0.6)',
                  },
                  '& .MuiSelect-select': {
                    color: 'rgba(0, 0, 0, 0.87)',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                  },
                }}
              >
                <InputLabel>Month</InputLabel>
                <Select
                  value={stateGettersSetters.us_selectedMonth ?? ''}
                  onChange={(e) => stateGettersSetters.us_setSelectedMonth(Number(e.target.value))}
                  label="Month"
                >
                  {Object.keys(currentProfile || {}).map((month) => (
                    <MenuItem
                      key={month}
                      value={month}
                    >
                      {new Date(2024, Number(month), 1).toLocaleString('default', { month: 'long' })}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Hour Selection */}
            <Grid
              item
              xs={12}
              sm={4}
            >
              <FormControl
                fullWidth
                sx={{
                  'backgroundColor': 'white',
                  'borderRadius': 1,
                  '& .MuiInputLabel-root': {
                    color: 'rgba(0, 0, 0, 0.6)',
                  },
                  '& .MuiSelect-select': {
                    color: 'rgba(0, 0, 0, 0.87)',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                  },
                }}
              >
                <InputLabel>Hour</InputLabel>
                <Select
                  value={stateGettersSetters.us_selectedHour ?? ''}
                  onChange={(e) => stateGettersSetters.us_setSelectedHour(Number(e.target.value))}
                  label="Hour"
                >
                  {stateGettersSetters.us_selectedMonth !== null &&
                    Object.keys(currentProfile?.[stateGettersSetters.us_selectedMonth] || {}).map((hour) => (
                      <MenuItem
                        key={hour}
                        value={hour}
                      >
                        {`${hour}:00`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
            >
              {stateGettersSetters.us_solarPlusBatteryBill_v2_consumptionProfile &&
                stateGettersSetters.us_selectedMonth !== null &&
                stateGettersSetters.us_selectedHour !== null && (
                  <Card
                    className="tw-p-4"
                    style={{ width: '100%', height: '600px' }} // Increased height to accommodate legend
                    sx={{ backgroundColor: 'transparent', boxShadow: 'none', border: 'none' }}
                  >
                    <ResponsiveContainer
                      width="100%"
                      height="80%" // Reduced to make room for legend
                    >
                      <PieChart>
                        {/* Outer ring (total) */}
                        <Pie
                          data={prepareDonutData(currentProfile, stateGettersSetters.us_selectedMonth, stateGettersSetters.us_selectedHour).totalData}
                          cx="50%"
                          cy="50%"
                          outerRadius={'90%'}
                          innerRadius={'80%'}
                          fill="#8884d8"
                          paddingAngle={0}
                          dataKey="value"
                        >
                          <Cell fill="#808080" />
                        </Pie>

                        {/* Inner ring (breakdown) */}
                        <Pie
                          data={prepareDonutData(currentProfile, stateGettersSetters.us_selectedMonth, stateGettersSetters.us_selectedHour).breakdownData}
                          cx="50%"
                          cy="50%"
                          innerRadius={'60%'}
                          outerRadius={'75%'}
                          fill="#8884d8"
                          paddingAngle={2}
                          dataKey="value"
                        >
                          {prepareDonutData(currentProfile, stateGettersSetters.us_selectedMonth, stateGettersSetters.us_selectedHour).breakdownData.map(
                            (entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={entry.color}
                              />
                            ),
                          )}
                        </Pie>
                        <RTooltip
                          content={<CustomTooltip />}
                          trigger="hover"
                          // Remove the previous styling attempts
                          // Add these new styles for better mobile compatibility
                          wrapperStyle={{
                            outline: 'none',
                            backgroundColor: 'transparent',
                            border: 'none',
                            boxShadow: 'none',
                          }}
                          contentStyle={{
                            backgroundColor: 'transparent',
                            border: 'none',
                            boxShadow: 'none',
                          }}
                          // Disable the cursor overlay completely
                          cursor={false}
                        />
                      </PieChart>
                    </ResponsiveContainer>

                    {/* Legend */}
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        gap: 2,
                        mt: 2,
                      }}
                    >
                      {[
                        { label: 'From Solar', color: '#ffd700' },
                        { label: 'From Grid', color: '#4169e1' },
                        { label: 'From Battery', color: '#32cd32' },
                        { label: 'Charge Battery', color: '#98fb98' },
                        { label: 'Export to Grid', color: '#9370db' },
                      ].map((item) => (
                        <Box
                          key={item.label}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          <Box
                            sx={{
                              width: 16,
                              height: 16,
                              backgroundColor: item.color,
                              borderRadius: '4px',
                            }}
                          />
                          <Typography
                            variant="body2"
                            sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
                          >
                            {item.label}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Card>
                )}
            </Grid>
          </Grid>

          <Box sx={{ mt: 4, mb: 4 }}>
            <Typography
              variant="h6"
              sx={{ mb: 2, color: 'rgba(0, 0, 0, 0.87)' }}
            >
              Cost Comparison
            </Typography>

            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
                md={6}
              >
                <Card sx={{ p: 2 }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ mb: 1, color: palette.aqua }}
                  >
                    Solar Only
                  </Typography>
                  {/* Assuming us_solarOnlyBill_monthlyCosts is an array of monthly costs */}
                  {stateGettersSetters.us_solarOnlyBill_monthlyCosts?.map((cost: number, month: number) => (
                    <Box
                      key={month}
                      sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}
                    >
                      <Typography variant="body2">{new Date(2024, month, 1).toLocaleString('default', { month: 'long' })}</Typography>
                      <Typography variant="body2">${cost.toFixed(2)}</Typography>
                    </Box>
                  ))}
                  <Box sx={{ mt: 2, pt: 1, borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontWeight: 'bold' }}
                    >
                      Yearly Total: ${stateGettersSetters.us_solarOnlyBill?.totalCost?.toFixed(2)}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                    >
                      Monthly Average: ${(stateGettersSetters.us_solarOnlyBill?.totalCost / 12)?.toFixed(2)}
                    </Typography>
                  </Box>
                </Card>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
              >
                <Card sx={{ p: 2 }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ mb: 1, color: palette.aqua }}
                  >
                    Solar + Battery
                  </Typography>

                  <Box sx={{ mt: 2, pt: 1, borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontWeight: 'bold' }}
                    >
                      Yearly Total: ${stateGettersSetters.us_solarPlusBatteryBill_v2?.totalCost?.toFixed(2)}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                    >
                      Monthly Average: ${(stateGettersSetters.us_solarPlusBatteryBill_v2?.totalCost / 12)?.toFixed(2)}
                    </Typography>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      {stateGettersSetters.us_solarOnlyBill && Object.keys(stateGettersSetters.us_solarOnlyBill).length === 0 && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '50vh', // This ensures vertical centering
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: 'rgba(0, 0, 0, 0.87)',
              textAlign: 'center', // This ensures the text itself is centered
            }}
          >
            No data available
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export const rJSX_PhotoPage = (
  stateGettersSetters: any,
  palette: any,
  fileInputRef: React.RefObject<HTMLInputElement>,
  clientKey: string,
  opportunityKey: string,
): JSX.Element => {
  // Add state for tracking uploaded photos (you'll need to implement this in your state management)
  const uploadedPhotos = stateGettersSetters.us_uploadedPhotos || {}

  const photoGroups = [
    {
      title: 'Exterior Home Photos',
      description: 'Photos showing each side of the home',
      items: [
        { id: 'front', label: 'Front of the Home', tooltip: 'Clear, straight-on view of the front facade' },
        { id: 'back', label: 'Back of the Home', tooltip: 'Full view of the back of the house' },
        { id: 'left', label: 'Left Side of the Home', tooltip: 'Complete view of the left side' },
        { id: 'right', label: 'Right Side of the Home', tooltip: 'Complete view of the right side' },
      ],
    },
    {
      title: 'Roof Photos',
      description: 'Photos of the roof from different angles',
      items: [
        { id: 'front_roof', label: 'Front of the Home with Roof', tooltip: 'Show roof angle and condition from front' },
        { id: 'back_roof', label: 'Back of the Home with Roof', tooltip: 'Show roof angle and condition from back' },
        { id: 'left_roof', label: 'Left Side with Roof', tooltip: 'Show roof angle and condition from left' },
        { id: 'right_roof', label: 'Right Side with Roof', tooltip: 'Show roof angle and condition from right' },
        { id: 'roof_closeup', label: 'Roof Closeup', tooltip: 'Detailed view of roofing material condition' },
      ],
    },
    {
      title: 'Attic Photos',
      description: 'Interior attic documentation',
      items: [
        { id: 'attic_inside', label: 'Attic Inside', tooltip: 'General view of attic space' },
        { id: 'attic_rafters', label: 'Attic Rafters', tooltip: 'Clear view of rafter construction' },
        { id: 'attic_plywood', label: 'Attic Plywood', tooltip: 'Show plywood condition' },
      ],
    },
    {
      title: 'Electrical System',
      description: 'Electrical panel and meter documentation',
      items: [
        { id: 'main_panel', label: 'Main Service Panel', tooltip: 'Full view of main electrical panel' },
        { id: 'main_panel_closeup', label: 'Main Panel Closeup', tooltip: 'Clear view of breakers and labels' },
        { id: 'main_panel_doors', label: 'Main Panel Doors', tooltip: 'Panel with doors open and closed' },
        { id: 'sub_panel', label: 'Sub Panel Wideshot', tooltip: 'Full view of sub panel if present' },
        { id: 'sub_panel_closeup', label: 'Sub Panel Closeup', tooltip: 'Clear view of sub panel breakers' },
        { id: 'meter_location', label: 'Meter Location Wide', tooltip: 'Show meter location on house' },
        { id: 'meter_number', label: 'Meter Number Closeup', tooltip: 'Clear view of meter number' },
      ],
    },
    {
      title: 'Additional Systems',
      description: 'Photos of existing systems (if applicable)',
      items: [
        { id: 'existing_solar', label: 'Existing Solar System', tooltip: 'Document any existing solar installation' },
        { id: 'battery', label: 'Battery Storage', tooltip: 'Show existing battery systems' },
        { id: 'generator', label: 'Generator', tooltip: 'Document any backup generators' },
        { id: 'pool', label: 'Pool', tooltip: 'Show pool equipment and size' },
        { id: 'hot_tub', label: 'Hot Tub', tooltip: 'Document hot tub equipment' },
        { id: 'heating', label: 'Electric Heating', tooltip: 'Show electric heating systems' },
        { id: 'water_heater', label: 'Electric Water Heater', tooltip: 'Document water heater specs' },
        { id: 'dryer', label: 'Electric Dryer', tooltip: 'Show electric dryer installation' },
        { id: 'ev_charger', label: 'EV Charger', tooltip: 'Document any EV charging stations' },
      ],
    },
  ]

  return (
    <Box sx={{ padding: 2 }}>
      {photoGroups.map((group, groupIndex) => (
        <Box
          key={groupIndex}
          sx={{ mb: 4 }}
        >
          <Typography sx={{ fontFamily: 'Poppins', fontSize: '14px', fontWeight: 'bold', color: palette.aqua, mb: 1 }}>{group.title}</Typography>
          <Typography sx={{ fontSize: '12px', color: '#7a7a7a', mb: 2, fontFamily: 'Poppins' }}>{group.description}</Typography>

          {group.items.map((item, itemIndex) => (
            <Box
              key={itemIndex}
              sx={{
                'display': 'flex',
                'alignItems': 'center',
                'padding': '8px',
                'borderBottom': '1px solid #eee',
                '&:hover': { backgroundColor: '#f5f5f5' },
              }}
            >
              <Tooltip
                title={item.tooltip}
                placement="left"
                enterTouchDelay={0}
                leaveTouchDelay={5000}
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                <IconButton
                  size="small"
                  onTouchStart={(e) => {
                    e.stopPropagation()
                  }}
                  sx={{
                    'padding': '4px',
                    'color': palette.gray_600,
                    '&:hover': {
                      backgroundColor: 'transparent',
                      color: palette.aqua,
                    },
                  }}
                >
                  <Icon
                    icon="circle-info"
                    sx={{ fontSize: '16px' }}
                  />
                </IconButton>
              </Tooltip>
              <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                <Checkbox
                  checked={!!uploadedPhotos[item.id]}
                  sx={{ 'color': palette.aqua, '&.Mui-checked': { color: palette.aqua } }}
                />
                <Typography sx={{ fontSize: '14px', ml: 1, fontFamily: 'Poppins', color: '#7a7a7a' }}>{item.label}</Typography>
              </Box>
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  /* Implement photo upload logic */
                  const input = document.createElement('input')
                  input.type = 'file'
                  input.accept = 'image/*'
                  input.onchange = (event: any) => {
                    const file = event.target.files ? event.target.files[0] : null
                    if (file) {
                      stateGettersSetters.us_setUploadedPhotos((prevPhotos: any) => ({
                        ...prevPhotos,
                        [item.id]: file,
                      })) // Set the single file in the state
                      uploadPhotoFile(file as File, clientKey, opportunityKey, item.id, stateGettersSetters.us_firstName, stateGettersSetters.us_lastName)
                    }
                  }
                  input.click()
                }}
                sx={{
                  'textTransform': 'none',
                  'borderColor': palette.aqua,
                  'color': palette.aqua,
                  '&:hover': {
                    borderColor: palette.aqua,
                    backgroundColor: 'rgba(66, 222, 255, 0.1)',
                  },
                }}
              >
                {rLIB('Upload')}
              </Button>
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  )
}

export const rJSX_SurveyPage = (
  stateGettersSetters: any,
  palette: any,
  fileInputRef: React.RefObject<HTMLInputElement>,
  clientKey: string,
  opportunityKey: string,
): JSX.Element => {
  return (
    <Box
      sx={{
        padding: '20px',
        maxWidth: '450px',
        margin: '0 auto',
        backgroundColor: palette.white, // Add explicit white background
        minHeight: '100vh', // Ensure full height
        position: 'relative', // Ensure proper stacking
        zIndex: 1,
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontFamily: 'Poppins',
          color: '#939598',
          marginTop: '20px',
          marginBottom: '30px',
          textAlign: 'center',
        }}
      >
        {rLIB('Home Survey')}
      </Typography>

      {rJSX_generalInformation(stateGettersSetters, palette, fileInputRef, clientKey, opportunityKey)}

      {rJSX_homeDetails(stateGettersSetters, palette, clientKey, opportunityKey)}

      {rJSX_roofCondition(stateGettersSetters, palette, clientKey, opportunityKey)}

      {rJSX_mainPanel(stateGettersSetters, palette, clientKey, opportunityKey)}

      {rJSX_subPanel(stateGettersSetters, palette, clientKey, opportunityKey)}

      {rJSX_otherGenerationAndHighLoadItems(stateGettersSetters, palette, clientKey, opportunityKey)}

      {rJSX_designPreferences(stateGettersSetters, palette, clientKey, opportunityKey)}
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          onClick={() => {
            stateGettersSetters.us_setCurrentView('welcome')
          }}
          sx={{
            'fontFamily': 'Poppins',
            'textTransform': 'none',
            'backgroundColor': palette.aqua,
            'color': palette.white,
            '&:hover': {
              backgroundColor: palette.teal,
            },
            'marginTop': '20px',
          }}
        >
          {rLIB('Submit Survey')}
        </Button>
      </Box>
    </Box>
  )
}

export const rJSX_WelcomeAddressBanner = (
  stateGettersSetters: any,
  logoSrc: string,
  palette: TsInterface_UnspecifiedObject,
  clientKey: string,
  opportunityKey: string,
): JSX.Element => {
  let bannerJSX = (
    <Box
      sx={{
        background: '#939598',
        display: 'flex',
        justifyContent: 'center', // Centers the inner box horizontally
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          maxWidth: '450px',
          alignItems: 'center',
          background: palette.white,
        }}
      >
        <Box
          className="tw-mb-2"
          component="img"
          sx={{ width: 189, height: 54, background: 'white', borderRadius: '5px', marginRight: '20px' }}
          alt="logo"
          src={logoSrc}
        />
        <Box
          sx={{
            marginTop: '6px',
            marginBottom: '10px',
            marginLeft: '25px',
            display: 'flex', // Added Flexbox
            flexDirection: 'column', // Changed to column layout
            alignItems: 'flex-start', // Align items vertically
            gap: '10px', // Space between buttons
          }}
        >
          <Button
            className="tw-text-gray_600"
            startIcon={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Icon
                  icon="circle-user"
                  sx={{ fontSize: '17px' }}
                />
              </Box>
            }
            onClick={() => {
              directAppNavigation(stateGettersSetters.un_routerNavigation, ApplicationPages.UnauthenticatedSalesLoginPage.url())
            }}
            sx={{
              paddingLeft: '53px',
              textTransform: 'none',
              fontSize: '12px',
              whiteSpace: 'nowrap', // Prevent text wrapping
            }}
          >
            {rLIB('Log Out')}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              stateGettersSetters.us_setRequestSupportDialogOpen(true)
            }}
            sx={{
              'textTransform': 'none',
              'backgroundColor': '#42deff',
              'color': palette.white,
              '&:hover': {
                backgroundColor: palette.aqua,
              },
              'borderRadius': '5px',
              'fontSize': '12px', // Increased font size for better readability
              'paddingX': '5px', // Added horizontal padding
              'paddingY': '8px', // Added vertical padding
              'whiteSpace': 'nowrap', // Prevent text wrapping
              'minWidth': '120px', // Ensures the button isn't too small
            }}
          >
            {rLIB('Request Support')}
          </Button>
          <Dialog
            open={stateGettersSetters.us_requestSupportDialogOpen}
            onClose={() => stateGettersSetters.us_setRequestSupportDialogOpen(false)}
          >
            <DialogTitle sx={{ fontFamily: 'Poppins', textAlign: 'center' }}>{rLIB('Request Support')}</DialogTitle>
            <DialogContent>
              <Typography sx={{ fontFamily: 'Poppins', mb: 2 }}>{rLIB('Please describe what we can do for you:')}</Typography>
              <TextField
                autoFocus
                multiline
                rows={4}
                fullWidth
                value={stateGettersSetters.us_editRequestText}
                onChange={(e) => stateGettersSetters.us_setEditRequestText(e.target.value)}
                // placeholder={'Example: I would like to add more panels to increase production...'}
                sx={{ mb: 2 }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => stateGettersSetters.us_setRequestSupportDialogOpen(false)}
                sx={{
                  'fontFamily': 'Poppins',
                  'textTransform': 'none',
                  'color': palette.gray_600,
                  '&:hover': {
                    backgroundColor: 'transparent',
                    opacity: 0.8,
                  },
                }}
              >
                {rLIB('Cancel')}
              </Button>
              <Button
                onClick={() => {
                  // Send email with edit request
                  cloudFunctionUnauthenticatedRequests({
                    function: 'requestSupportAndEditsSubmission',
                    client_key: clientKey,
                    opportunity_key: opportunityKey,
                    first_name: stateGettersSetters.us_firstName,
                    last_name: stateGettersSetters.us_lastName,
                    email: stateGettersSetters.us_email,
                    phone: stateGettersSetters.us_phone,
                    notes: stateGettersSetters.us_editRequestText,
                    support_type: 'SUPPORTREQUEST',
                  })

                  // Close dialog and show confirmation
                  stateGettersSetters.us_setRequestSupportDialogOpen(false)
                  stateGettersSetters.us_setEditRequestText('')
                  stateGettersSetters.us_setShowRequestSupportConfirmation(true)
                }}
                sx={{
                  'fontFamily': 'Poppins',
                  'textTransform': 'none',
                  'backgroundColor': palette.aqua,
                  'color': palette.white,
                  '&:hover': {
                    backgroundColor: palette.aqua,
                    opacity: 0.8,
                  },
                }}
              >
                {rLIB('Submit Request')}
              </Button>
            </DialogActions>
          </Dialog>
          <Snackbar
            open={stateGettersSetters.us_showRequestSupportConfirmation}
            autoHideDuration={6000}
            onClose={() => stateGettersSetters.us_setShowRequestSupportConfirmation(false)}
            message={rLIB("Your edit request has been sent. We'll contact you shortly.")}
          />
        </Box>
      </Box>
    </Box>
  )

  return bannerJSX
}

export const rJSX_ResetPasswordPage = (stateGettersSetters: any, palette: any, user: User | null): JSX.Element => {
  return (
    <Box sx={{ backgroundColor: '#939598' }}>
      <Box
        sx={{
          backgroundColor: palette.aqua, // Set the background color to blue (aqua)
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: '450px',
          minHeight: '100vh', // Ensure the Box takes the full viewport height
          padding: '40px 20px', // Adds some padding to the top and sides
          boxSizing: 'border-box', // Ensures padding is included in the total height
          margin: '0 auto',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
          <Button
            startIcon={
              <Icon
                icon="arrow-left"
                className="tw-text-white"
                sx={{ fontFamily: 'Poppins', fontSize: '14px' }}
              />
            }
            onClick={() => {
              stateGettersSetters.us_setCurrentView('welcome')
            }}
            className="tw-text-white"
            sx={{
              'fontFamily': 'Poppins',
              'textTransform': 'none',
              'backgroundColor': 'transparent',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              },
            }}
          >
            {rLIB('Back')}
          </Button>
        </Box>
        {/* Title */}
        <Typography
          variant="h4"
          sx={{
            color: palette.white,
            marginBottom: '8px', // Reduced margin
            fontWeight: 'bold',
            marginTop: '0', // Remove any default top margin
          }}
        >
          {rLIB('Password Reset')}
        </Typography>

        {/* Subtitle */}
        <Typography
          variant="h6"
          sx={{
            color: palette.white,
            marginBottom: '24px', // Adjusted margin
            fontSize: '14px',
          }}
        >
          {rLIB('Enter your new password below to reset your password')}
        </Typography>

        {/* Password Input */}
        <TextField
          label="Password"
          variant="outlined"
          type="password"
          sx={{
            backgroundColor: palette.white,
            borderRadius: '4px',
            marginBottom: '16px',
            width: '300px',
          }}
          onChange={(e) => {
            stateGettersSetters.us_setNewPassword(e.target.value.trim())
          }}
          onBlur={(e) => {
            stateGettersSetters.us_setNewPassword(e.target.value.trim())
          }}
        />

        {/* Confirm Password Input */}
        <TextField
          label="Confirm Password"
          variant="outlined"
          type="password"
          sx={{
            backgroundColor: palette.white,
            borderRadius: '4px',
            marginBottom: '16px',
            width: '300px',
          }}
          onChange={(e) => {
            stateGettersSetters.us_setConfirmPassword(e.target.value.trim())
          }}
          onBlur={(e) => {
            stateGettersSetters.us_setConfirmPassword(e.target.value.trim())
          }}
        />

        {/* Login Button */}
        <Button
          variant="contained"
          sx={{
            'marginTop': '20px',
            'backgroundColor': palette.white,
            'color': palette.aqua,
            'border': `1px solid ${palette.aqua}`,
            'width': '200px',
            'padding': '10px',
            'borderRadius': '25px',
            'textTransform': 'none',
            'fontWeight': 'bold',
            'fontSize': '16px',
            '&:hover': {
              backgroundColor: palette.aqua,
              color: palette.white,
              border: `2px solid ${palette.aqua}`,
            },
          }}
          onClick={() => {
            if (user) {
              updatePassword(user, stateGettersSetters.us_newPassword)
            }
            stateGettersSetters.us_setCurrentView('welcome')
          }}
          disabled={
            stateGettersSetters.us_newPassword === '' ||
            stateGettersSetters.us_confirmPassword === '' ||
            stateGettersSetters.us_newPassword !== stateGettersSetters.us_confirmPassword
          }
        >
          {rLIB('Submit')}
        </Button>
      </Box>
    </Box>
  )
}

export const rJSX_EditPanelLayout = (stateGettersSetters: any, palette: any, opportunityKey: string, clientKey: string): JSX.Element => {
  let proposalKey: string = ''
  proposalKey = stateGettersSetters.us_activeProposalId
  return (
    <Box
      sx={{
        minHeight: '100vh',
        minWidth: '100vw',
        maxWidth: '100vw',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      {/* Aqua background section (3/8 of the page) */}
      <Box
        sx={{
          background: palette.aqua,
          height: '55.5vh', // Reduced from 55.5vh to 37.5vh (3/8 of 100vh)
          position: 'relative',
          padding: '10px',
          overflow: 'hidden',
        }}
      >
        <Box sx={{ marginBottom: '15px', display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
          <Button
            startIcon={
              <Icon
                icon="arrow-left"
                className="tw-text-gray_600"
                sx={{ fontFamily: 'Poppins', fontSize: '10px' }}
              />
            }
            onClick={() => {
              stateGettersSetters.us_setCurrentView('welcome')
            }}
            className="tw-text-gray_600 "
            sx={{
              'fontFamily': 'Poppins',
              'textTransform': 'none',
              'backgroundColor': 'transparent',
              'position': 'absolute',
              'left': '0',
              'padding': '0px 10px',
              'marginBottom': '10px',
              '&:hover': {
                backgroundColor: palette.gray_600,
              },
            }}
          >
            Back
          </Button>
        </Box>
        {rJSX_panelLayoutHeader(palette)}
      </Box>

      {/* Map positioned at the intersection */}
      <Box
        sx={{
          position: 'absolute',
          top: '30vh',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 1,
          width: '350px',
          height: '350px',
          overflow: 'hidden',
          //position: 'relative',
        }}
      >
        <GeoTiffMap
          tiffUrl={'clients/etw_energy_sales/sales/eagleview-images/HhkjRz5aEjkvAEJSF41K.tif'}
          latitude={stateGettersSetters.us_latitude}
          longitude={stateGettersSetters.us_longitude}
          zoomLevel={2}
          panels={stateGettersSetters.us_panels}
        />
        {/* <img
          src={`data:image/jpeg;base64, ${stateGettersSetters.us_evImageURL}`}
          alt="Sales Map"
          style={{
            width: '100%',
            height: '100%',
          }}
        />

        <svg
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 2,
            transform: 'rotate(-2deg)',
          }}
        >
          {Object.values(stateGettersSetters.us_panels).map((panel: any, index: number) => {
            // Find the bounds of all panels to create a scale
            const allCorners = Object.values(stateGettersSetters.us_panels).flatMap((p: any) =>
              Object.values(p.row.coordinates).map((corner: any) => ({
                lat: corner[0],
                lng: corner[1],
              })),
            )
            const minLat = Math.min(...allCorners.map((c: any) => c.lat))
            const maxLat = Math.max(...allCorners.map((c: any) => c.lat))
            const minLng = Math.min(...allCorners.map((c: any) => c.lng))
            const maxLng = Math.max(...allCorners.map((c: any) => c.lng))

            // Add offset percentages to shift the panels
            const scaleFactor = 1.5
            const xOffset = 65 // Adjust this value to move right/left
            const yOffset = 170 // Adjust this value to move up/down

            // Convert coordinates to relative positions (0-1 scale)
            const points = Object.values(panel.row.coordinates)
              .map((corner: any) => {
                const x = ((corner[1] - minLng) / (maxLng - minLng)) * 100 * scaleFactor + xOffset
                const y = 100 - ((corner[0] - minLat) / (maxLat - minLat)) * 100 * scaleFactor + yOffset
                return `${x},${y}`
              })
              .join(' ')

            return (
              <polygon
                key={index}
                points={points}
                fill="rgba(0, 0, 255, 0.3)"
                stroke="blue"
                strokeWidth="1"
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  // Handle click event
                  console.log('Panel clicked:', panel)
                  // Add your click handler here, for example:
                  // stateGettersSetters.us_handlePanelClick(panel)
                }}
              />
            )
          })}
        </svg> */}
      </Box>

      {/* White background section (remaining 5/8 of the page) */}
      <Box
        sx={{
          background: palette.white,
          minHeight: '100vh',
          flexGrow: 1,
          padding: '10px',
          paddingTop: '150px', // Add padding to account for the overlapping map
          overflow: 'auto',
        }}
      >
        {rJSX_panelLayoutSystemDetails(stateGettersSetters, palette, opportunityKey, clientKey)}
        {rJSX_AdditionalOptionsSection(stateGettersSetters, palette, opportunityKey, clientKey, proposalKey, true)}
        {rJSX_panelLayoutFinanceDetails(stateGettersSetters, palette)}
      </Box>
    </Box>
  )
}
