/* eslint-disable react/display-name */
///////////////////////////////
// Description
///////////////////////////////

/*
DESCRIPTION / USAGE:
example component description

TODO:

*/

///////////////////////////////
// Imports
///////////////////////////////
import { Box } from '@mui/material'
import { forwardRef, useContext, useEffect, useReducer, useState } from 'react'
import { DatabaseRef_ProjectFeedback_Query } from 'rfbp_aux/services/database_endpoints/operations/feedback'
import {
  DatabaseRef_ProjectAdditionalData_Document,
  DatabaseRef_ProjectTaskWorkflow_Document,
  DatabaseRef_Project_Document,
} from 'rfbp_aux/services/database_endpoints/operations/projects'
import { DatabaseRef_ProjectReminders_Query } from 'rfbp_aux/services/database_endpoints/operations/reminders'
import { DatabaseRef_AllProjectTasks_Query, DatabaseRef_Task_Document } from 'rfbp_aux/services/database_endpoints/operations/tasks'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import {
  Context_RootData_ClientKey,
  Context_RootData_GlobalUser,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
} from 'rfbp_core/services/context'
import {
  DatabaseBatchUpdate,
  DatabaseGetDocument,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { rJSX_ETWEnergyProjectDetailsTab } from '../project_view/tab_details_etw_energy'
import { rJSX_SunrunProjectDetailsTab } from '../project_view/tab_details_sunrun'
import { rJSX_TeslaProjectDetailsTab } from '../project_view/tab_details_tesla'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ProjectView_DetailsTab {
  projectKey: string
  readOrWrite: 'read' | 'write'
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

const checkAndRepairTaskStatuses = (clientKey: string, tasks: TsInterface_UnspecifiedObject): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    let messedUpTasks: TsInterface_UnspecifiedObject = {}
    let taskRepairUpdateObjects: TsInterface_UnspecifiedObject = {}
    let hasTaskRepairs = false
    // Loop through tasks
    for (let loopTaskKey in tasks) {
      let loopTask = tasks[loopTaskKey]
      // If there are prerequisite tasks
      if (loopTask.prerequisite_tasks == null || objectToArray(loopTask.prerequisite_tasks).length === 0) {
        if (loopTask['ready_to_start'] === false && loopTask['status'] !== 'deleted') {
          taskRepairUpdateObjects[loopTaskKey] = { ready_to_start: true }
        }
      }
      if (loopTask.prerequisite_tasks != null) {
        // Loop through prerequisite tasks
        for (let loopPrereqTaskKey in loopTask.prerequisite_tasks) {
          let loopPrereqTask = tasks[loopPrereqTaskKey]
          if (loopPrereqTask != null && loopPrereqTask.status_complete === true) {
            if (loopTask['ready_to_start'] === false && loopTask['status'] !== 'deleted') {
              messedUpTasks[loopTaskKey] = loopTask
              if (taskRepairUpdateObjects[loopTaskKey] == null) {
                taskRepairUpdateObjects[loopTaskKey] = {
                  ready_to_start: true,
                  prerequisite_tasks_completion: {},
                }
              }
              if (loopPrereqTask['timestamp_completed'] != null) {
                taskRepairUpdateObjects[loopTaskKey]['prerequisite_tasks_completion'][loopPrereqTaskKey] = loopPrereqTask['timestamp_completed']
              }
              hasTaskRepairs = true
            }
            loopTask.prerequisite_tasks[loopPrereqTaskKey] = loopPrereqTaskKey
          }
        }
      }
    }
    if (hasTaskRepairs === true) {
      let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
      for (let loopTaskKey in taskRepairUpdateObjects) {
        taskRepairUpdateObjects[loopTaskKey]['timestamp_last_updated'] = new Date()
        updateArray.push({ type: 'setMerge', ref: DatabaseRef_Task_Document(clientKey, loopTaskKey), data: taskRepairUpdateObjects[loopTaskKey] })
      }
      DatabaseBatchUpdate(updateArray)
        .then((res_DBU) => {
          resolve(res_DBU)
        })
        .catch((rej_DBU) => {
          reject(rej_DBU)
        })
    } else {
      resolve({ success: true })
    }
  })
}

///////////////////////////////
// Container
///////////////////////////////

export const ProjectViewDetailsTab = forwardRef((props: TsInterface_ProjectView_DetailsTab, ref: React.ForwardedRef<unknown>): JSX.Element => {
  // Props
  let pr_projectKey: TsInterface_ProjectView_DetailsTab['projectKey'] = getProp(props, 'projectKey', null)
  let pr_readOrWrite: TsInterface_ProjectView_DetailsTab['readOrWrite'] = getProp(props, 'readOrWrite', 'read')

  // Hooks - useContext, useState, useReducer, other
  const [us_additionalSalesforceData, us_setAdditionalSalesforceData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_minimizedCustomerDetails, us_setMinimizedCustomerDetails] = useState<boolean>(false)
  const [us_minimizedCustomerHappiness, us_setMinimizedCustomerHappiness] = useState<boolean>(false)
  const [us_minimizedEstimatedTimeline, us_setMinimizedEstimatedTimeline] = useState<boolean>(false)
  const [us_minimizedFeedbackDetails, us_setMinimizedFeedbackDetails] = useState<boolean>(false)
  const [us_minimizedHomeDetails, us_setMinimizedHomeDetails] = useState<boolean>(true)
  const [us_minimizedMostRecentContact, us_setMinimizedMostRecentContact] = useState<boolean>(false)
  const [us_minimizedProgressDetails, us_setMinimizedProgressDetails] = useState<boolean>(false)
  const [us_minimizedProjectFinancialData, us_setMinimizedProjectFinancialData] = useState<boolean>(true)
  const [us_minimizedProjectTasks, us_setMinimizedProjectTasks] = useState<boolean>(false)
  const [us_minimizedProjectTimestampsData, us_setMinimizedProjectTimestampsData] = useState<boolean>(true)
  const [us_minimizedReminderDetails, us_setMinimizedReminderDetails] = useState<boolean>(false)
  const [us_minimizedSalesPartnerSpecificData, us_setMinimizedSalesPartnerSpecificData] = useState<boolean>(true)
  const [us_minimizedStickyNote, us_setMinimizedStickyNote] = useState<boolean>(false)
  const [us_minimizedSystemDetails, us_setMinimizedSystemDetails] = useState<boolean>(false)
  const [us_projectFeedback, us_setProjectFeedback] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectOpenReminders, us_setProjectOpenReminders] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectTaskWorkflowUserRoles, us_setProjectTaskWorkflowUserRoles] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectTasks, us_setProjectTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectUsersFlatObject, us_setProjectUsersFlatObject] = useState<TsInterface_UnspecifiedObject>({})
  const [us_refreshingData, us_setRefreshingData] = useState<boolean>(false)
  const [us_refreshingProgressBar, us_setRefreshingProgressBar] = useState<boolean>(false)
  const [us_rootProject, us_setRootProject] = useState<TsInterface_UnspecifiedObject>({})
  const [us_rootProjectLoaded, us_setRootProjectLoaded] = useState<boolean>(false)
  const [us_repairedTasks, us_setRepairedTasks] = useState<boolean>(false)
  const [us_reloadTaskWorkflow] = useState<number>(0)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)

  // Hooks - useEffect
  useEffect(() => {
    // TEMP - Forms - only load if tasks can be created
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetDocument(DatabaseRef_ProjectAdditionalData_Document(res_GCK.clientKey, pr_projectKey))
          .then((res_DGD) => {
            us_setAdditionalSalesforceData(res_DGD.data)
          })
          .catch((rej_DGD) => {
            console.error(rej_DGD)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, pr_projectKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setProjectFeedback(newData)
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ProjectFeedback_Query(res_GCK.clientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setProjectFeedback({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setProjectOpenReminders(newData)
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ProjectReminders_Query(res_GCK.clientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setProjectOpenReminders({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setProjectTasks(newData)
        // us_setProjectTasksLoaded(true)
        if (us_repairedTasks === false) {
          getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
            checkAndRepairTaskStatuses(res_GCK.clientKey, newData)
          })
          us_setRepairedTasks(true)
        }
      }
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_AllProjectTasks_Query(res_GCK.clientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setProjectTasks({})
      // us_setProjectTasksLoaded(true)
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey, us_repairedTasks])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRootProject(newData)
      us_setRootProjectLoaded(true)
      // TODO - TEMP
      if (newData != null && newData['task_completion_stats'] != null && newData['task_completion_stats']['unknown'] != null) {
        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
          cloudFunctionManageRequest('manageTasks', {
            function: 'refreshProjectTaskProgressBar',
            client_key: res_GCK.clientKey,
            project_key: pr_projectKey,
          })
          ur_forceRerender()
        })
      }
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetDocument(DatabaseRef_ProjectTaskWorkflow_Document(res_GCK.clientKey, pr_projectKey))
          .then((res_DGD) => {
            // us_setProjectTaskWorkflow(res_DGD.data)
            let taskWorkflowUserRolesList: TsInterface_UnspecifiedObject = {}
            let taskWorkflowUserRolesWithDirectOrScheduledTasksList: TsInterface_UnspecifiedObject = {}
            if (res_DGD.data != null && res_DGD.data['tasks'] != null) {
              for (let loopTaskKey in res_DGD.data['tasks']) {
                let loopTask = res_DGD.data['tasks'][loopTaskKey]
                if (us_reloadTaskWorkflow >= 0) {
                  // Nothing - just used for reloads
                }
                if (loopTask != null && loopTask['associated_owner_type'] != null) {
                  taskWorkflowUserRolesList[loopTask['associated_owner_type']] = loopTask['associated_owner_type']
                  if (loopTask['task_completion_type'] === 'direct' || loopTask['task_completion_type'] === 'dispatcher') {
                    taskWorkflowUserRolesWithDirectOrScheduledTasksList[loopTask['associated_owner_type']] = loopTask['associated_owner_type']
                  }
                }
              }
            }
            us_setProjectTaskWorkflowUserRoles(taskWorkflowUserRolesList)
            // us_setUsersWithDirectOrScheduledTasksRoles(taskWorkflowUserRolesWithDirectOrScheduledTasksList)
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [uc_RootData_ClientKey, pr_projectKey, uc_setRootData_ClientKey, us_reloadTaskWorkflow])

  useEffect(() => {
    let projectUsersFlatObject: TsInterface_UnspecifiedObject = {}
    if (us_rootProject != null) {
      if (us_rootProject['associated_customer_key'] != null && us_rootProject['associated_customer_name'] != null) {
        projectUsersFlatObject[us_rootProject[us_rootProject['associated_customer_key']]] = us_rootProject['associated_customer_name']
      }
      if (us_rootProject['associated_sales_rep_key'] != null && us_rootProject['associated_sales_rep_name'] != null) {
        projectUsersFlatObject[us_rootProject[us_rootProject['associated_sales_rep_key']]] = us_rootProject['associated_sales_rep_name']
      }
      if (us_rootProject['associated_css_rep_key'] != null && us_rootProject['associated_css_rep_name'] != null) {
        projectUsersFlatObject[us_rootProject[us_rootProject['associated_css_rep_key']]] = us_rootProject['associated_css_rep_name']
      }
      for (let loopRoleKey in us_projectTaskWorkflowUserRoles) {
        if (us_rootProject['associated_' + loopRoleKey + '_key'] != null && us_rootProject['associated_' + loopRoleKey + '_name'] != null) {
          projectUsersFlatObject[us_rootProject['associated_' + loopRoleKey + '_key']] = us_rootProject['associated_' + loopRoleKey + '_name']
        }
      }
    }
    us_setProjectUsersFlatObject(projectUsersFlatObject)
  }, [us_rootProject, us_projectTaskWorkflowUserRoles])

  // Functions

  // JSX Generation
  const rJSX_Tab = (): JSX.Element => {
    let tabJSX = <></>
    if (us_rootProject != null && us_rootProject.key != null) {
      if (us_rootProject['associated_sales_partner_key'] === 'sunrun') {
        tabJSX = (
          <Box className="tw-m-auto">
            <Box className="tw-m-auto">
              {rJSX_SunrunProjectDetailsTab(
                pr_readOrWrite,
                uc_RootData_ClientKey,
                uc_RootData_GlobalUser,
                us_additionalSalesforceData,
                ur_forceRerender,
                us_minimizedCustomerDetails,
                us_minimizedCustomerHappiness,
                us_minimizedEstimatedTimeline,
                us_minimizedFeedbackDetails,
                us_minimizedHomeDetails,
                us_minimizedMostRecentContact,
                us_minimizedProgressDetails,
                us_minimizedReminderDetails,
                us_minimizedProjectFinancialData,
                us_minimizedSalesPartnerSpecificData,
                us_minimizedProjectTimestampsData,
                us_minimizedStickyNote,
                us_minimizedSystemDetails,
                us_minimizedProjectTasks,
                us_projectFeedback,
                pr_projectKey,
                us_projectOpenReminders,
                us_projectUsersFlatObject,
                us_refreshingData,
                us_refreshingProgressBar,
                us_rootProject,
                us_rootProjectLoaded,
                us_projectTasks,
                us_setMinimizedCustomerDetails,
                us_setMinimizedCustomerHappiness,
                us_setMinimizedEstimatedTimeline,
                us_setMinimizedFeedbackDetails,
                us_setMinimizedHomeDetails,
                us_setMinimizedMostRecentContact,
                us_setMinimizedProgressDetails,
                us_setMinimizedReminderDetails,
                us_setMinimizedProjectFinancialData,
                us_setMinimizedSalesPartnerSpecificData,
                us_setMinimizedProjectTimestampsData,
                us_setMinimizedStickyNote,
                us_setMinimizedSystemDetails,
                us_setMinimizedProjectTasks,
                us_setRefreshingData,
                us_setRefreshingProgressBar,
                uc_setRootData_ClientKey,
                uc_setUserInterface_ConfirmDialogDisplay,
                uc_setUserInterface_CustomDialogDisplay,
                uc_setUserInterface_ErrorDialogDisplay,
                uc_setUserInterface_FormDialogDisplay,
              )}
            </Box>
          </Box>
        )
      } else if (us_rootProject['associated_sales_partner_key'] === 'tesla') {
        tabJSX = (
          <Box className="tw-m-auto">
            <Box className="tw-m-auto">
              {rJSX_TeslaProjectDetailsTab(
                pr_readOrWrite,
                uc_RootData_ClientKey,
                uc_RootData_GlobalUser,
                us_additionalSalesforceData,
                ur_forceRerender,
                us_minimizedCustomerDetails,
                us_minimizedCustomerHappiness,
                us_minimizedEstimatedTimeline,
                us_minimizedFeedbackDetails,
                us_minimizedHomeDetails,
                us_minimizedMostRecentContact,
                us_minimizedProgressDetails,
                us_minimizedReminderDetails,
                us_minimizedProjectFinancialData,
                us_minimizedSalesPartnerSpecificData,
                us_minimizedProjectTimestampsData,
                us_minimizedStickyNote,
                us_minimizedSystemDetails,
                us_minimizedProjectTasks,
                us_projectFeedback,
                pr_projectKey,
                us_projectOpenReminders,
                us_projectUsersFlatObject,
                us_refreshingData,
                us_refreshingProgressBar,
                us_rootProject,
                us_rootProjectLoaded,
                us_projectTasks,
                us_setMinimizedCustomerDetails,
                us_setMinimizedCustomerHappiness,
                us_setMinimizedEstimatedTimeline,
                us_setMinimizedFeedbackDetails,
                us_setMinimizedHomeDetails,
                us_setMinimizedMostRecentContact,
                us_setMinimizedProgressDetails,
                us_setMinimizedReminderDetails,
                us_setMinimizedProjectFinancialData,
                us_setMinimizedSalesPartnerSpecificData,
                us_setMinimizedProjectTimestampsData,
                us_setMinimizedStickyNote,
                us_setMinimizedSystemDetails,
                us_setMinimizedProjectTasks,
                us_setRefreshingData,
                us_setRefreshingProgressBar,
                uc_setRootData_ClientKey,
                uc_setUserInterface_ConfirmDialogDisplay,
                uc_setUserInterface_CustomDialogDisplay,
                uc_setUserInterface_ErrorDialogDisplay,
                uc_setUserInterface_FormDialogDisplay,
              )}
            </Box>
          </Box>
        )
      } else if (us_rootProject['associated_sales_partner_key'] === 'etw_energy') {
        tabJSX = (
          <Box className="tw-m-auto">
            <Box className="tw-m-auto">
              {rJSX_ETWEnergyProjectDetailsTab(
                pr_readOrWrite,
                uc_RootData_ClientKey,
                uc_RootData_GlobalUser,
                us_additionalSalesforceData,
                ur_forceRerender,
                us_minimizedCustomerDetails,
                us_minimizedCustomerHappiness,
                us_minimizedEstimatedTimeline,
                us_minimizedFeedbackDetails,
                us_minimizedHomeDetails,
                us_minimizedMostRecentContact,
                us_minimizedProgressDetails,
                us_minimizedReminderDetails,
                us_minimizedProjectFinancialData,
                us_minimizedSalesPartnerSpecificData,
                us_minimizedProjectTimestampsData,
                us_minimizedStickyNote,
                us_minimizedSystemDetails,
                us_minimizedProjectTasks,
                us_projectFeedback,
                pr_projectKey,
                us_projectOpenReminders,
                us_projectUsersFlatObject,
                us_refreshingData,
                us_refreshingProgressBar,
                us_rootProject,
                us_rootProjectLoaded,
                us_projectTasks,
                us_setMinimizedCustomerDetails,
                us_setMinimizedCustomerHappiness,
                us_setMinimizedEstimatedTimeline,
                us_setMinimizedFeedbackDetails,
                us_setMinimizedHomeDetails,
                us_setMinimizedMostRecentContact,
                us_setMinimizedProgressDetails,
                us_setMinimizedReminderDetails,
                us_setMinimizedProjectFinancialData,
                us_setMinimizedSalesPartnerSpecificData,
                us_setMinimizedProjectTimestampsData,
                us_setMinimizedStickyNote,
                us_setMinimizedSystemDetails,
                us_setMinimizedProjectTasks,
                us_setRefreshingData,
                us_setRefreshingProgressBar,
                uc_setRootData_ClientKey,
                uc_setUserInterface_ConfirmDialogDisplay,
                uc_setUserInterface_CustomDialogDisplay,
                uc_setUserInterface_ErrorDialogDisplay,
                uc_setUserInterface_FormDialogDisplay,
              )}
            </Box>
          </Box>
        )
      } else {
        tabJSX = (
          <Box className="tw-m-auto">
            <Box className="tw-m-auto">
              {rJSX_ETWEnergyProjectDetailsTab(
                pr_readOrWrite,
                uc_RootData_ClientKey,
                uc_RootData_GlobalUser,
                us_additionalSalesforceData,
                ur_forceRerender,
                us_minimizedCustomerDetails,
                us_minimizedCustomerHappiness,
                us_minimizedEstimatedTimeline,
                us_minimizedFeedbackDetails,
                us_minimizedHomeDetails,
                us_minimizedMostRecentContact,
                us_minimizedProgressDetails,
                us_minimizedReminderDetails,
                us_minimizedProjectFinancialData,
                us_minimizedSalesPartnerSpecificData,
                us_minimizedProjectTimestampsData,
                us_minimizedStickyNote,
                us_minimizedSystemDetails,
                us_minimizedProjectTasks,
                us_projectFeedback,
                pr_projectKey,
                us_projectOpenReminders,
                us_projectUsersFlatObject,
                us_refreshingData,
                us_refreshingProgressBar,
                us_rootProject,
                us_rootProjectLoaded,
                us_projectTasks,
                us_setMinimizedCustomerDetails,
                us_setMinimizedCustomerHappiness,
                us_setMinimizedEstimatedTimeline,
                us_setMinimizedFeedbackDetails,
                us_setMinimizedHomeDetails,
                us_setMinimizedMostRecentContact,
                us_setMinimizedProgressDetails,
                us_setMinimizedReminderDetails,
                us_setMinimizedProjectFinancialData,
                us_setMinimizedSalesPartnerSpecificData,
                us_setMinimizedProjectTimestampsData,
                us_setMinimizedStickyNote,
                us_setMinimizedSystemDetails,
                us_setMinimizedProjectTasks,
                us_setRefreshingData,
                us_setRefreshingProgressBar,
                uc_setRootData_ClientKey,
                uc_setUserInterface_ConfirmDialogDisplay,
                uc_setUserInterface_CustomDialogDisplay,
                uc_setUserInterface_ErrorDialogDisplay,
                uc_setUserInterface_FormDialogDisplay,
              )}
            </Box>
          </Box>
        )
      }
    }
    return tabJSX
  }

  // Render

  return <>{rJSX_Tab()}</>
})
