///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, CardHeader, Chip, FormControl, FormControlLabel, Radio, RadioGroup, Typography, useMediaQuery } from '@mui/material/'
import { returnImageTags } from 'app/models/projects/project_image_tags'
import { returnCombinedTaskRoles } from 'app/models/projects/project_services'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_TaskFormProdPages_Document } from 'rfbp_aux/services/database_endpoints/directory/task_forms'
import {
  DatabaseRef_OrdersForSpecificProjectQuery,
  DatabaseRef_Orders_Collection,
  DatabaseRef_Order_Document,
} from 'rfbp_aux/services/database_endpoints/materials/orders'
import { DatabaseRef_MaterialUserPoNumber_Document } from 'rfbp_aux/services/database_endpoints/materials/po_number_counters'
import { DatabaseRef_ActiveSuppliers_Query, DatabaseRef_Supplier_Document } from 'rfbp_aux/services/database_endpoints/materials/suppliers'
import {
  DatabaseRef_ProjectAdditionalImages_Collection,
  DatabaseRef_ProjectTaskFormData_Collection,
  DatabaseRef_ProjectTaskWorkflow_Document,
  DatabaseRef_Project_Document,
} from 'rfbp_aux/services/database_endpoints/operations/projects'
import { DatabaseRef_AllProjectTasks_Query, DatabaseRef_Task_Document } from 'rfbp_aux/services/database_endpoints/operations/tasks'
import { StorageRef_ProjectImageThumbnailFile, StorageRef_ProjectImageThumbnailsFolder } from 'rfbp_aux/services/storage_endpoints/projects'
import { TsInterface_FormInputs } from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableBasic,
  TableCellBasic,
  TableCellManage,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { TabsUrl, TsInterface_TabContentUrlArray } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientUser,
  Context_RootData_GlobalUser,
  Context_UserInterface_AlertDialog,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  Context_UserInterface_Snackbar,
} from 'rfbp_core/services/context'
import {
  DatabaseAddDocument,
  DatabaseGetDocument,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
  DatabaseTransactionIncrement,
  StorageGetDownloadUrl,
  StorageListFiles,
} from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { directAppNavigation, onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { rJSX_PhotosTab } from '../projects/project_view/tab_photos'
import { ProjectViewDetailsTab } from '../projects/v2_view_tabs/tab_details'
import { ProjectViewDocumentsTab } from '../projects/v2_view_tabs/tab_documents'
import { ProjectViewNotesTab } from '../projects/v2_view_tabs/tab_notes'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['SubKProjectViewPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

const tableSettings_SubkMaterialOrders: TsInterface_TableSettings = {
  paginated: true,
  pagination_rows_per_page_default: 100,
  pagination_rows_per_page_options: [10, 25, 50, 100],
  show_header: true,
  sortable: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property_default: 'timestamp_scheduled_pickup_date_key',
}

const tableColumns_SubkMaterialOrders: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{rLIB('View')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          directAppNavigation(tableHooks.un_routerNavigation, ApplicationPages.AdminDatabaseMaterialsOrderViewPage.url(rowData.key as string))
        }
      },
    },
    delete: {
      icon: (
        <Icon
          type="solid"
          icon="trash"
        />
      ),
      label: <>{rLIB('Delete')}</>,
      conditional_display: {
        active: true,
        logic_type: 'and',
        source: null,
        prop: null,
        comparator: null,
        value: null,
        conditions: [
          {
            active: true,
            logic_type: 'comparison',
            source: 'rowData',
            prop: 'status',
            comparator: '==',
            value: 'new',
            conditions: [],
          },
        ],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              icon: <Icon icon="trash" />,
              header: rLIB('Delete Order'),
              text: rLIB('Are you sure that you want to delete this order?'),
              submit_text: rLIB('Delete'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      if (res_GCK.clientKey != null && rowData.key != null) {
                        let updateObject = {
                          status: 'deleted',
                        }
                        DatabaseSetMergeDocument(DatabaseRef_Order_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                          .then((res_DSMD) => {
                            resolve(res_DSMD)
                          })
                          .catch((rej_DSMD) => {
                            tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            reject(rej_DSMD)
                          })
                      }
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      reject(rej_GCK)
                    })
                })
              },
            },
          })
        }
      },
    },
    undelete: {
      icon: (
        <Icon
          type="solid"
          icon="magic-wand-sparkles"
        />
      ),
      label: <>{rLIB('Undelete')}</>,
      conditional_display: {
        active: true,
        logic_type: 'and',
        source: null,
        prop: null,
        comparator: null,
        value: null,
        conditions: [
          {
            active: true,
            logic_type: 'comparison',
            source: 'rowData',
            prop: 'status',
            comparator: '==',
            value: 'deleted',
            conditions: [],
          },
        ],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'warning',
              icon: <Icon icon="magic-wand-sparkles" />,
              header: rLIB('Undelete Order'),
              text: rLIB('Are you sure that you want to undelete this order?'),
              submit_text: rLIB('Undelete'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      if (res_GCK.clientKey != null && rowData.key != null) {
                        let updateObject = {
                          status: 'new',
                        }
                        DatabaseSetMergeDocument(DatabaseRef_Order_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                          .then((res_DSMD) => {
                            resolve(res_DSMD)
                          })
                          .catch((rej_DSMD) => {
                            tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            reject(rej_DSMD)
                          })
                      }
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      reject(rej_GCK)
                    })
                })
              },
            },
          })
        }
      },
    },
  }),
  po_number: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('PO Number')
      },
      header_sort_by: 'po_number',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let chipColor: 'default' | 'success' | 'primary' | 'secondary' | 'error' | 'info' | 'warning' = 'default'
        let iconJSX = <></>
        switch (rowData.status) {
          case 'new':
            chipColor = 'primary'
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="square-pen"
                className="tw-mr-2"
              />
            )
            break
          case 'deleted':
            chipColor = 'error'
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="trash"
                className="tw-mr-2"
              />
            )
            break
          case 'locked':
            chipColor = 'warning'
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="lock"
                className="tw-mr-2"
              />
            )
            break
          case 'submitted':
            chipColor = 'warning'
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="paper-plane"
                className="tw-mr-2"
              />
            )
            break
          case 'confirmed':
            chipColor = 'success'
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="badge-check"
                className="tw-mr-2"
              />
            )
            break
          // case "picked_up":
          // iconJSX =
          // <Icon sx={{ fontSize: "16px" }}  icon="truck-ramp-box" className="tw-mr-2" />
          // break
          case 'invoiced':
            chipColor = 'info'
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="message-dollar"
                className="tw-mr-2"
              />
            )
            break
        }
        let cellJSX = (
          <Chip
            label={
              <Box className="tw-align-top">
                {iconJSX}
                {rowData.po_number}
              </Box>
            }
            color={chipColor}
            variant="outlined"
            className="tw-cursor-pointer"
            sx={{ margin: '2px' }}
            onClick={(event) => {
              onClickAppNavigation(event, tableHooks.un_routerNavigation, ApplicationPages.AdminDatabaseMaterialsOrderViewPage.url(rowData.key as string))
            }}
          />
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  timestamp_scheduled_pickup_date_key: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Scheduled Date')
      },
      header_sort_by: 'delivery_timestamp_scheduled_pickup_date_keytype',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const scheduledDate = getProp(rowData, 'timestamp_scheduled_pickup_date_key', null)

        let cellJSX = (
          <Box>
            {scheduledDate ? (
              <Box>{scheduledDate}</Box> // Display the scheduled date
            ) : (
              <Typography
                variant="body2"
                className="tw-opacity-50"
              >
                {rLIB('No Scheduled Date')}
              </Typography>
            )}
          </Box>
        )

        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },

  associated_supplier_name: TableCellBasic('associated_supplier_name', rLIB('Supplier'), 'associated_supplier_name'),
  associated_supplier_location_name: TableCellBasic('associated_supplier_location_name', rLIB('Supplier Location'), 'associated_supplier_location_name'),
  // delivery_type: TableCellBasic( "delivery_type", s_DELIVERY_TYPE, "delivery_type" ),
  delivery_type: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Delivery Type')
      },
      header_sort_by: 'delivery_type',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let chipColor: 'default' | 'success' | 'primary' | 'secondary' | 'error' | 'info' | 'warning' = 'default'
        let iconJSX = <></>
        let textJSX = <></>
        if (rowData.delivery_type == null) {
          rowData.delivery_type = 'pickup'
        }
        switch (rowData.delivery_type) {
          case 'pickup':
            chipColor = 'primary'
            textJSX = <>{rLIB('Pickup')}</>
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="person-dolly"
                className="tw-mr-2"
              />
            )
            break
          case 'delivery':
            chipColor = 'secondary'
            textJSX = <>{rLIB('Delivery')}</>
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="truck"
                className="tw-mr-2"
              />
            )
            break
        }
        let cellJSX = (
          <Chip
            label={
              <Box className="tw-align-top">
                {iconJSX}
                {textJSX}
              </Box>
            }
            color={chipColor}
            variant="outlined"
            sx={{ margin: '2px' }}
          />
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  // delivery_notes: TableCellBasic('delivery_notes', rLIB('Delivery Notes'), 'delivery_notes'),
  associated_project_id_number: TableCellBasic('associated_project_id_number', rLIB('Job Code'), 'associated_project_id_number'),
  associated_task_name: TableCellBasic('associated_task_name', rLIB('Task'), 'associated_task_name'),
  // associated_team_name: TableCellBasic('associated_team_name', rLIB('Team'), 'associated_team_name'),
  // associated_user_name: TableCellBasic('associated_user_name', rLIB('Requestor'), 'associated_user_name'),
  // // timestamp_scheduled_pickup_date_key: TableCellBasic( "timestamp_scheduled_pickup_date_key", s_REQUESTED_PICKUP_DATE, "timestamp_scheduled_pickup_date_key" ),
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const pr_projectKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  const [us_activeTab, us_setActiveTab] = useState<string>('details')
  const [us_allTaskFormData, us_setAllTaskFormData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_availableImageTags, us_setAvailableImageTags] = useState<string[]>([])
  const [us_combinedUserRoles, us_setCombinedUserRoles] = useState<TsInterface_UnspecifiedObject>({})
  const [us_downloadingPhotos, us_setDownloadingPhotos] = useState<boolean>(false)
  const [us_filteredPhotosList, us_setFilteredPhotosList] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_flatImageTagCounts, us_setFlatImageTagCounts] = useState<TsInterface_UnspecifiedObject>({})
  const [us_flatImageUploadData, us_setFlatImageUploadData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_photosViewLevel, us_setPhotosViewLevel] = useState<string>('root') // root | task | page | subfolder
  const [us_photosViewType, us_setPhotosViewType] = useState<string>('tag') // folder | tag
  const [us_projectImageThumbnails, us_setProjectImageThumbnails] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectRootPhotosFolders, us_setProjectRootPhotosFolders] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectTaskWorkflow, us_setProjectTaskWorkflow] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectTasks, us_setProjectTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_rootProject, us_setRootProject] = useState<TsInterface_UnspecifiedObject>({})
  const [us_ordersForProject, us_setOrdersForProject] = useState<TsInterface_UnspecifiedObject>({})
  const [us_runningImageAnalysis, us_setRunningImageAnalysis] = useState<boolean>(false)
  const [us_screenSize, us_setScreenSize] = useState<string>('md')
  const [us_selectedAllImageFilterTag, us_setSelectedAllImagesFilterTag] = useState<boolean>(false)
  const [us_selectedImageFilterTags, us_setSelectedImageFilterTags] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedPhotosTaskFolder, us_setSelectedPhotosTaskFolder] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedPhotosTaskFolderData, us_setSelectedPhotosTaskFolderData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedPhotosTaskFolderForm, us_setSelectedPhotosTaskFolderForm] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedPhotosTaskPageFolder, us_setSelectedPhotosTaskPageFolder] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedPhotosTaskPageSubfolder, us_setSelectedPhotosTaskPageSubfolder] = useState<TsInterface_UnspecifiedObject>({})
  const umq_isExtraSmallScreen = useMediaQuery('(max-width: 400px)')
  const umq_isLargeScreen = useMediaQuery('(min-width: 961px) and (max-width: 1280px)')
  const umq_isMediumScreen = useMediaQuery('(min-width: 601px) and (max-width: 960px)')
  const umq_isSmallScreen = useMediaQuery('(min-width: 401px) and (max-width: 600px)')
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)
  const { uc_setUserInterface_AlertDialogDisplay } = useContext(Context_UserInterface_AlertDialog)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_SnackbarDisplay } = useContext(Context_UserInterface_Snackbar)
  const [us_allActiveSuppliers, us_setAllActiveSuppliers] = useState<TsInterface_UnspecifiedObject>({})
  const [us_additionalImageData, us_setAdditionalImageData] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_reloadTaskWorkflow] = useState<number>(0)

  // Hooks - useEffect
  useEffect(() => {
    if (umq_isExtraSmallScreen === true) {
      us_setScreenSize('xs')
    } else if (umq_isSmallScreen === true) {
      us_setScreenSize('sm')
    } else if (umq_isMediumScreen === true) {
      us_setScreenSize('md')
    } else if (umq_isLargeScreen === true) {
      us_setScreenSize('lg')
    } else {
      us_setScreenSize('xl')
    }
    ur_forceRerender()
    return () => {}
  }, [umq_isExtraSmallScreen, umq_isSmallScreen, umq_isMediumScreen, umq_isLargeScreen, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setProjectTasks(newData)
      }
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_AllProjectTasks_Query(res_GCK.clientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setProjectTasks({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetDocument(DatabaseRef_ProjectTaskWorkflow_Document(res_GCK.clientKey, pr_projectKey))
          .then((res_DGD) => {
            us_setProjectTaskWorkflow(res_DGD.data)
            let taskWorkflowUserRolesList: TsInterface_UnspecifiedObject = {}
            let taskWorkflowUserRolesWithDirectOrScheduledTasksList: TsInterface_UnspecifiedObject = {}
            if (res_DGD.data != null && res_DGD.data['tasks'] != null) {
              for (let loopTaskKey in res_DGD.data['tasks']) {
                let loopTask = res_DGD.data['tasks'][loopTaskKey]
                if (us_reloadTaskWorkflow >= 0) {
                  // Nothing - just used for reloads
                }
                if (loopTask != null && loopTask['associated_owner_type'] != null) {
                  taskWorkflowUserRolesList[loopTask['associated_owner_type']] = loopTask['associated_owner_type']
                  if (loopTask['task_completion_type'] === 'direct' || loopTask['task_completion_type'] === 'dispatcher') {
                    taskWorkflowUserRolesWithDirectOrScheduledTasksList[loopTask['associated_owner_type']] = loopTask['associated_owner_type']
                  }
                }
              }
            }
            // us_setProjectTaskWorkflowUserRoles(taskWorkflowUserRolesList)
            // us_setUsersWithDirectOrScheduledTasksRoles(taskWorkflowUserRolesWithDirectOrScheduledTasksList)
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [uc_RootData_ClientKey, pr_projectKey, uc_setRootData_ClientKey, us_reloadTaskWorkflow])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        us_setCombinedUserRoles(returnCombinedTaskRoles(res_GCK.clientKey))
        ur_forceRerender()
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    if (
      us_selectedPhotosTaskFolder != null &&
      us_selectedPhotosTaskFolder.key != null &&
      us_projectTasks != null &&
      us_projectTasks[us_selectedPhotosTaskFolder.key] != null &&
      us_projectTasks[us_selectedPhotosTaskFolder.key]['associated_task_form_key'] != null
    ) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseGetDocument(
            DatabaseRef_TaskFormProdPages_Document(res_GCK.clientKey, us_projectTasks[us_selectedPhotosTaskFolder.key]['associated_task_form_key']),
          )
            .then((res_DGD) => {
              us_setSelectedPhotosTaskFolderForm(res_DGD.data)
            })
            .catch((rej_DGD) => {
              console.error(rej_DGD)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setSelectedPhotosTaskFolderForm({})
    }
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_selectedPhotosTaskFolder, pr_projectKey, ur_forceRerender, us_projectTasks])

  // Live update for additional images
  useEffect(() => {
    if (!uc_RootData_ClientKey || !pr_projectKey) {
      console.log('Skipping live update: Missing clientKey or projectKey')
      return
    }

    let unsubscribeAdditionalImages: TsType_VoidFunction

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        console.log('Fetched clientKey:', res_GCK.clientKey)
        const additionalImagesRef = DatabaseRef_ProjectAdditionalImages_Collection(res_GCK.clientKey, pr_projectKey)

        unsubscribeAdditionalImages = DatabaseGetLiveCollection(additionalImagesRef, (newData) => {
          const additionalImages = objectToArray(newData)
          console.log('Live updated additional images:', additionalImages)
          us_setAdditionalImageData(additionalImages)
        })
      })
      .catch((error) => console.error('Failed to load additional images:', error))

    return () => {
      if (typeof unsubscribeAdditionalImages === 'function') {
        console.log('Unsubscribing from additional images listener')
        unsubscribeAdditionalImages()
      }
    }
  }, [uc_RootData_ClientKey, pr_projectKey, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRootProject(newData)
      // TODO - TEMP
      if (newData != null && newData['task_completion_stats'] != null && newData['task_completion_stats']['unknown'] != null) {
        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
          cloudFunctionManageRequest('manageTasks', {
            function: 'refreshProjectTaskProgressBar',
            client_key: res_GCK.clientKey,
            project_key: pr_projectKey,
          })
          ur_forceRerender()
        })
      }
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        us_setAvailableImageTags(returnImageTags(res_GCK.clientKey))
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  // Subcontractor view: Combine task images and additional images
  useEffect(() => {
    console.log('Starting subcontractor view effect')

    const flatImageUploadDataArray = objectToArray(us_flatImageUploadData)
    const additionalImageDataArray = objectToArray(us_additionalImageData)

    console.log('Flat image upload data array:', flatImageUploadDataArray)
    console.log('Additional image data array:', additionalImageDataArray)

    const combinedImagesMap = new Map()

    ;[...flatImageUploadDataArray, ...additionalImageDataArray].forEach((image) => {
      if (!combinedImagesMap.has(image.key)) {
        combinedImagesMap.set(image.key, image) // Add unique image by key
      }
    })

    const combinedImages = Array.from(combinedImagesMap.values())
    console.log('Combined images after deduplication:', combinedImages)

    let filteredImages: TsInterface_UnspecifiedObject[] = []

    combinedImages.forEach((loopImage) => {
      // Apply thumbnail if it exists
      objectToArray(us_projectImageThumbnails).forEach((loopThumbnail: any) => {
        let fileNameWithoutExtension = loopImage.name.split('.').slice(0, -1).join('.')
        if (loopThumbnail.name.startsWith(fileNameWithoutExtension)) {
          loopImage.thumbnail_url = loopThumbnail.thumbnail_url
        }
      })

      // Filter based on selected tags
      if (us_selectedAllImageFilterTag === true) {
        filteredImages.push(loopImage)
      } else {
        let includePhotoInDisplay = false // Default to false, include if any tag matches

        for (let loopTagKey in us_selectedImageFilterTags) {
          if (us_selectedImageFilterTags[loopTagKey] === true) {
            if (loopImage.tags != null && loopImage.tags.includes(loopTagKey)) {
              includePhotoInDisplay = true // Include if any tag matches
              break
            }
          }
        }

        if (includePhotoInDisplay) {
          filteredImages.push(loopImage)
        }
      }
    })

    console.log('Filtered images after applying tag filters:', filteredImages)

    us_setFilteredPhotosList(filteredImages)
    return () => {
      console.log('Cleaning up subcontractor view effect')
    }
  }, [
    us_setFilteredPhotosList,
    us_flatImageUploadData,
    us_additionalImageData, // Ensure additional images trigger re-render
    us_selectedAllImageFilterTag,
    us_selectedImageFilterTags,
    us_projectImageThumbnails,
  ])

  useEffect(() => {
    console.log('Starting subcontractor view effect')

    const flatImageUploadDataArray = objectToArray(us_flatImageUploadData)
    const additionalImageDataArray = objectToArray(us_additionalImageData)

    console.log('Flat image upload data array:', flatImageUploadDataArray)
    console.log('Additional image data array:', additionalImageDataArray)

    const combinedImagesMap = new Map()

    // Combine images without duplication
    ;[...flatImageUploadDataArray, ...additionalImageDataArray].forEach((image) => {
      if (!combinedImagesMap.has(image.key)) {
        combinedImagesMap.set(image.key, image)
      }
    })

    const combinedImages = Array.from(combinedImagesMap.values())
    console.log('Combined images after deduplication:', combinedImages)

    // **Photo Counter**: Total number of unique images
    const photoCounter = combinedImages.length
    console.log('Total photo count:', photoCounter)

    // **Tag Counter**: Count occurrences of each tag
    const tagCounts: { [key: string]: number } = { all_photos: photoCounter }

    combinedImages.forEach((image) => {
      if (image.tags && Array.isArray(image.tags)) {
        image.tags.forEach((tag: any) => {
          if (!tagCounts[tag]) {
            tagCounts[tag] = 0
          }
          tagCounts[tag] += 1
        })
      }
    })
    console.log('Tag counts:', tagCounts)

    // Apply filters based on selected tags
    let filteredImages: TsInterface_UnspecifiedObject[] = []
    combinedImages.forEach((loopImage) => {
      let includePhotoInDisplay = objectToArray(us_selectedImageFilterTags).length > 0

      for (let loopTagKey in us_selectedImageFilterTags) {
        if (us_selectedImageFilterTags[loopTagKey] === true) {
          if (!loopImage.tags || !loopImage.tags.includes(loopTagKey)) {
            includePhotoInDisplay = false
            break
          }
        }
      }

      if (includePhotoInDisplay || us_selectedAllImageFilterTag) {
        filteredImages.push(loopImage)
      }
    })

    console.log('Filtered images after applying tag filters:', filteredImages)

    // Update state with filtered images, photo counter, and tag counts
    us_setFilteredPhotosList(filteredImages)
    us_setFlatImageTagCounts(tagCounts)

    return () => {
      console.log('Cleaning up subcontractor view effect')
    }
  }, [
    us_setFilteredPhotosList,
    us_setFlatImageTagCounts,
    us_flatImageUploadData,
    us_additionalImageData,
    us_selectedAllImageFilterTag,
    us_selectedImageFilterTags,
    us_projectImageThumbnails,
  ])

  useEffect(() => {
    if (us_activeTab === 'photos' && pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          StorageListFiles(StorageRef_ProjectImageThumbnailsFolder(res_GCK.clientKey, pr_projectKey))
            .then((res_SLF) => {
              let thumbnailFiles: TsInterface_UnspecifiedObject = {}
              let promiseArray: TsType_UnknownPromise[] = []
              if (res_SLF != null && res_SLF.data != null && res_SLF.data.files != null) {
                for (let loopFileKey in res_SLF.data.files) {
                  thumbnailFiles[loopFileKey] = res_SLF.data.files[loopFileKey]
                  promiseArray.push(
                    StorageGetDownloadUrl(StorageRef_ProjectImageThumbnailFile(res_GCK.clientKey, pr_projectKey, loopFileKey))
                      .then((res_SGDU) => {
                        thumbnailFiles[loopFileKey]['thumbnail_url'] = getProp(res_SGDU, 'url', null)
                      })
                      .catch((rej_SGDU) => {
                        console.error(rej_SGDU)
                      }),
                  )
                }
              }
              Promise.all(promiseArray).finally(() => {
                us_setProjectImageThumbnails(thumbnailFiles)
              })
            })
            .catch((rej_SLF) => {
              console.error(rej_SLF)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey, us_activeTab])

  useEffect(() => {
    let tagCounts: TsInterface_UnspecifiedObject = { all_photos: 0 }
    let calculatedProjectRootPhotosFolders: TsInterface_UnspecifiedObject = {}
    if (us_allTaskFormData != null) {
      let taskFormImages: TsInterface_UnspecifiedObject[] = []
      for (let loopTaskKey in us_allTaskFormData) {
        let loopTaskData = us_allTaskFormData[loopTaskKey]
        for (let loopPageKey in loopTaskData) {
          let loopPageData = loopTaskData[loopPageKey]
          if (loopPageData != null && loopPageData['folders'] != null) {
            for (let loopFolderKey in loopPageData['folders']) {
              let loopFolder = loopPageData['folders'][loopFolderKey]
              for (let loopFileUploadIndex in loopFolder) {
                let loopFileUpload = loopFolder[loopFileUploadIndex]
                if (loopFileUpload != null && loopFileUpload['upload_type'] === 'image') {
                  if (calculatedProjectRootPhotosFolders[loopTaskKey] == null) {
                    calculatedProjectRootPhotosFolders[loopTaskKey] = {
                      key: loopTaskKey,
                      path: null,
                    }
                  }
                  loopFileUpload['TEMP_associated_task_key'] = loopTaskKey
                  loopFileUpload['TEMP_associated_page_key'] = loopPageKey
                  loopFileUpload['TEMP_associated_folder_key'] = loopFolderKey
                  loopFileUpload['TEMP_subfolder_name'] = loopFolder.name
                  loopFileUpload['TEMP_upload_index'] = loopFileUploadIndex
                  taskFormImages.push(loopFileUpload)
                  tagCounts['all_photos']++
                  if (loopFileUpload != null && loopFileUpload['tags'] != null) {
                    let includePhotoInCounts = true
                    for (let loopTagKey in us_selectedImageFilterTags) {
                      if (us_selectedImageFilterTags[loopTagKey] === true) {
                        if (loopFileUpload != null && loopFileUpload.tags != null && loopFileUpload.tags.indexOf(loopTagKey) === -1) {
                          includePhotoInCounts = false
                        }
                      }
                    }
                    if (includePhotoInCounts === true) {
                      for (let loopTagIndex in loopFileUpload['tags']) {
                        let loopTag = loopFileUpload['tags'][loopTagIndex]
                        if (tagCounts[loopTag] == null) {
                          tagCounts[loopTag] = 0
                        }
                        tagCounts[loopTag]++
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      us_setFlatImageUploadData(taskFormImages)
      us_setFlatImageTagCounts(tagCounts)
      us_setProjectRootPhotosFolders(calculatedProjectRootPhotosFolders)
    }
    ur_forceRerender()
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_allTaskFormData, ur_forceRerender, us_selectedImageFilterTags])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAllTaskFormData(newData)
      ur_forceRerender()
    }
    if (us_activeTab === 'photos') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ProjectTaskFormData_Collection(res_GCK.clientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setFlatImageUploadData([])
      us_setFlatImageTagCounts({})
      us_setAllTaskFormData({})
      us_setProjectRootPhotosFolders({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, pr_projectKey, ur_forceRerender, us_activeTab, us_selectedImageFilterTags])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAllActiveSuppliers(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveSuppliers_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setOrdersForProject(newData)

      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_OrdersForSpecificProjectQuery(res_GCK.clientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey])

  // Functions
  const createNewOrder = (): void => {
    // Define the form inputs for creating a new order
    let formInputs_NewOrder: TsInterface_FormInputs = {
      // Header Information
      header: {
        data_type: 'string',
        key: 'header',
        input_type: 'custom_form_input_jsx',
        label: <></>,
        required: false,
        renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
          return (
            <Box className="tw-mb-4">
              <Typography
                variant="body1"
                sx={{ color: themeVariables.warning_main }}
                className="tw-mb-2"
              >
                <Icon
                  icon="circle-exclamation"
                  className="tw-mr-2"
                />
                {rLIB('Orders must be placed by 3pm the day before pickup.')}
              </Typography>
            </Box>
          )
        },
      },

      // Supplier Selection
      associated_supplier_key: {
        data_type: 'string',
        key: 'associated_supplier_key',
        input_type: 'custom_form_input_jsx',
        label: <></>,
        required: true,
        renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
          return (
            <Box className="tw-mt-4">
              <Box className="tw-opacity-70">{rLIB('Supplier')}</Box>
              <FormControl>
                <RadioGroup
                  onChange={(event) => {
                    formInputChange('associated_supplier_key', event.target.value, true)
                    formInputChange('associated_supplier_location_key', null, true)
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        DatabaseGetDocument(DatabaseRef_Supplier_Document(res_GCK.clientKey, event.target.value))
                          .then((res_DGD) => {
                            let supplier = res_DGD.data
                            let supplierLocations: TsInterface_UnspecifiedObject = {}
                            for (let loopLocationKey in getProp(supplier, 'locations', {})) {
                              let loopLocation = supplier.locations[loopLocationKey]
                              if (loopLocation != null && loopLocation.status === 'active') {
                                supplierLocations[loopLocationKey] = {
                                  key: loopLocationKey,
                                  value: loopLocation.name,
                                }
                              }
                            }
                            formInputChange('associated_supplier_location_options', supplierLocations, true)
                          })
                          .catch((rej_DGD) => {
                            console.error(rej_DGD)
                          })
                      })
                      .catch((rej_GCK) => {
                        console.error(rej_GCK)
                      })
                  }}
                  value={formData.associated_supplier_key || ''}
                >
                  {objectToArray(getProp(formAdditionalData, 'associated_supplier_options', {}))
                    .sort(dynamicSort('name', 'asc'))
                    .map((supplier, index) => (
                      <Box
                        className="tw-ml-8 tw-block"
                        key={index}
                      >
                        <FormControlLabel
                          value={supplier.key}
                          control={<Radio />}
                          label={supplier.value}
                        />
                      </Box>
                    ))}
                </RadioGroup>
              </FormControl>
            </Box>
          )
        },
      },

      // Supplier Location Selection
      associated_supplier_location_key: {
        data_type: 'string',
        key: 'associated_supplier_location_key',
        input_type: 'custom_form_input_jsx',
        label: <></>,
        required: true,
        renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
          return (
            <Box className="tw-mt-4">
              <Box className="tw-opacity-70">{rLIB('Supplier Location')}</Box>
              <FormControl>
                <RadioGroup
                  onChange={(event) => {
                    formInputChange('associated_supplier_location_key', event.target.value, true)
                  }}
                  value={formData.associated_supplier_location_key || ''}
                >
                  {objectToArray(getProp(formData, 'associated_supplier_location_options', {}))
                    .sort(dynamicSort('name', 'asc'))
                    .map((location, index) => (
                      <Box
                        className="tw-ml-8 tw-block"
                        key={index}
                      >
                        <FormControlLabel
                          value={location.key}
                          control={<Radio />}
                          label={location.value}
                        />
                      </Box>
                    ))}
                </RadioGroup>
              </FormControl>
            </Box>
          )
        },
      },

      // Delivery Type Selection
      delivery_type: {
        key: 'delivery_type',
        label: rLIB('Delivery Type'),
        input_type: 'multiple_choice_radio',
        required: true,
        data_type: 'string',
        options: [
          { key: 'pickup', value: rLIB('Pickup') },
          { key: 'delivery', value: rLIB('Delivery') },
        ],
      },

      // Delivery Notes
      delivery_notes: {
        key: 'delivery_notes',
        label: rLIB('Delivery Notes'),
        input_type: 'text_multiline',
        required: false,
        data_type: 'string',
        conditional_display: {
          active: true,
          logic: {
            active: true,
            logic_type: 'comparison',
            source: 'formData',
            prop: 'delivery_type',
            comparator: '==',
            value: 'delivery',
            conditions: [],
          },
        },
      },

      // Task Selection
      associated_task_key: {
        data_type: 'string',
        key: 'associated_task_key',
        input_type: 'custom_form_input_jsx',
        label: <></>,
        required: true,
        renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
          return (
            <Box className="tw-mt-4">
              <Typography
                variant="body1"
                sx={{ color: themeVariables.primary_main }}
                className="tw-mb-2"
              >
                {rLIB('Select Task')}
              </Typography>
              <FormControl>
                <RadioGroup
                  onChange={(event) => {
                    const selectedTaskKey = event.target.value
                    formInputChange('associated_task_key', selectedTaskKey, true)

                    // Get the task name from us_projectTasks and set it in formAdditionalData
                    const taskName = us_projectTasks[selectedTaskKey]?.name || ''
                    formInputChange('associated_task_name', taskName, true)
                  }}
                  value={formData.associated_task_key || ''}
                >
                  {Object.keys(us_projectTasks)
                    .filter((taskKey) => us_projectTasks[taskKey].status === 'active') // Only active tasks
                    .map((taskKey) => {
                      const task = us_projectTasks[taskKey]
                      return (
                        <FormControlLabel
                          key={taskKey}
                          value={taskKey}
                          control={<Radio />}
                          label={task.name}
                        />
                      )
                    })}
                </RadioGroup>
              </FormControl>
            </Box>
          )
        },
      },
    }

    // Set up supplier options
    let supplierOptions: TsInterface_UnspecifiedObject = {}
    for (let loopSupplierKey in us_allActiveSuppliers) {
      let loopSupplier = us_allActiveSuppliers[loopSupplierKey]
      if (loopSupplier != null) {
        supplierOptions[loopSupplierKey] = { key: loopSupplierKey, value: loopSupplier.name }
      }
    }

    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: { associated_supplier_options: supplierOptions },
          formData: {},
          formInputs: formInputs_NewOrder,
          formOnChange: () => {},
          formSettings: {},
          formSubmission: (formSubmittedData) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  createNewOrderProper(
                    res_GCK.clientKey,
                    formSubmittedData.associated_task_key, // Pass the selected task key
                    formSubmittedData.associated_supplier_key,
                    formSubmittedData.associated_supplier_location_key,
                    formSubmittedData.timestamp_scheduled_pickup,
                    formSubmittedData.delivery_type,
                    formSubmittedData.delivery_notes,
                  )
                    .then(resolve)
                    .catch(reject)
                })
                .catch(reject)
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: rLIB('New Order'),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="circle-plus"
            />
          ),
        },
      },
    })
  }

  const createNewOrderProper = (
    clientKey: string,
    taskKey: string,
    supplierKey: string,
    supplierLocationKey: string,
    requestedPickupDateKey: string,
    deliveryType: string,
    deliveryNotes: string | null,
  ): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      console.log('Starting createNewOrderProper with parameters:', {
        clientKey,
        taskKey,
        supplierKey,
        supplierLocationKey,
        requestedPickupDateKey,
        deliveryType,
        deliveryNotes,
      })

      // Validate required parameters
      if (clientKey != null && taskKey != null && supplierKey != null) {
        let promiseArray: TsType_UnknownPromise[] = []
        let task: any = {}
        let supplier: any = {}

        // Fetch Task Details
        promiseArray.push(
          DatabaseGetDocument(DatabaseRef_Task_Document(clientKey, taskKey))
            .then((res_GGD) => {
              console.log('Task data retrieved:', res_GGD.data)
              task = res_GGD.data
            })
            .catch((rej_GGD) => {
              console.error('Error fetching task:', rej_GGD)
              throw rej_GGD
            }),
        )

        // Fetch Supplier Details
        promiseArray.push(
          DatabaseGetDocument(DatabaseRef_Supplier_Document(clientKey, supplierKey))
            .then((res_GGD) => {
              console.log('Supplier data retrieved:', res_GGD.data)
              supplier = res_GGD.data
            })
            .catch((rej_GGD) => {
              console.error('Error fetching supplier:', rej_GGD)
              throw rej_GGD
            }),
        )

        // Once both Task and Supplier data are fetched
        Promise.all(promiseArray)
          .then(async () => {
            console.log('Promise.all resolved. Data:', { task, supplier })

            // Ensure supplier has PO abbreviation and task exists
            if (supplier != null && supplier['po_abbreviation'] != null && task != null) {
              // Generate PO Number
              const projectId = getProp(task, 'associated_project_id_number', null)
              if (!projectId) {
                console.error('Project ID is missing, cannot create PO number.')
                reject({
                  success: false,
                  error: {
                    message: rLIB('Failed to create new order'),
                    details: rLIB('Missing Project ID'),
                    code: 'ER-D-MI-NOPROJECTID',
                  },
                })
                return
              }

              const basePONumber = `${supplier['po_abbreviation']}-${projectId}-`
              console.log('Base PO Number:', basePONumber)

              // Increment PO Number Counter
              DatabaseTransactionIncrement(DatabaseRef_MaterialUserPoNumber_Document(clientKey, projectId), 'counter', 1)
                .then((res_DTI: any) => {
                  console.log('Incremented PO number:', res_DTI.incrementValue)

                  // Create Full PO Number
                  let incrementNumber = res_DTI.incrementValue
                  let numStr = incrementNumber.toString().padStart(3, '0')
                  let fullPONumber = basePONumber + numStr

                  console.log('Full PO Number:', fullPONumber)

                  // Determine Scheduled Status
                  // const scheduledStatus = requestedPickupDateKey ? 'scheduled' : 'unscheduled'

                  // Construct the Order Object
                  let updateObject = {
                    associated_project_id_number: projectId,
                    associated_project_key: getProp(task, 'associated_project_key', null),
                    associated_supplier_key: supplierKey,
                    associated_supplier_name: getProp(supplier, 'name', null),
                    associated_supplier_location_key: supplierLocationKey,
                    associated_supplier_location_name: getProp(getProp(getProp(supplier, 'locations', {}), supplierLocationKey, {}), 'name', null),
                    associated_task_key: taskKey,
                    associated_task_name: getProp(task, 'name', null),
                    delivery_type: deliveryType,
                    delivery_notes: deliveryNotes || null,
                    email_sent: false,
                    po_number: fullPONumber,
                    status: 'new',
                    timestamp_created: new Date(),
                    // Conditionally include scheduled pickup date key or status
                    ...(requestedPickupDateKey ? { timestamp_scheduled_pickup_date_key: requestedPickupDateKey } : { scheduled_status: 'unscheduled' }),
                  }

                  console.log('Order object to save:', updateObject)

                  // Save the Order to Firestore
                  DatabaseAddDocument(DatabaseRef_Orders_Collection(clientKey), updateObject, true)
                    .then((res_DAD) => {
                      console.log('Order saved successfully:', res_DAD)
                      resolve(res_DAD)
                    })
                    .catch((rej_DAD) => {
                      console.error('Error saving order:', rej_DAD)
                      reject(rej_DAD)
                    })
                })
                .catch((rej_DTI) => {
                  console.error('Error incrementing PO number:', rej_DTI)
                  reject(rej_DTI)
                })
            } else {
              console.error('Missing required parameters for order creation.')
              reject({
                success: false,
                error: {
                  message: rLIB('Failed to create new order'),
                  details: rLIB('Missing Required Parameters'),
                  code: 'ER-D-MI-CNOP-01',
                },
              })
            }
          })
          .catch((error) => {
            console.error('Error in Promise.all:', error)
            reject({
              success: false,
              error: {
                message: rLIB('Failed to create new order'),
                details: rLIB('Error fetching required data'),
                code: 'ER-D-MI-CNOP-03',
              },
            })
          })
      } else {
        console.error('Invalid input parameters for createNewOrderProper.')
        reject({
          success: false,
          error: {
            message: rLIB('Failed to create new order'),
            details: rLIB('Missing Required Parameters'),
            code: 'ER-D-MI-CNOP-02',
          },
        })
      }
    })
  }

  // JSX Generation

  const rJSX_PageHeader = (): JSX.Element => {
    let pageHeader = <></>
    let jobCodeJSX = <></>
    if (us_rootProject != null && us_rootProject.id_number != null) {
      jobCodeJSX = <>{us_rootProject.id_number}</>
    } else {
      jobCodeJSX = <>{rLIB('Project')}</>
    }
    if (us_rootProject != null && us_rootProject.associated_customer_name != null) {
      pageHeader = (
        <>
          {jobCodeJSX}: {us_rootProject.associated_customer_name}
        </>
      )
    } else {
      pageHeader = <>{jobCodeJSX}</>
    }
    return pageHeader
  }

  const rJSX_MaterialsTab = (
    viewType: 'full' | 'sales',
    readOrWrite: 'read' | 'write',
    uc_RootData_ClientKey: any,
    uc_RootData_GlobalUser: any,
    combinedUserRoles: any,
    projectKey: any,
  ) => {
    let tabJSX = (
      <Card>
        <CardHeader
          title={
            <Button
              variant="contained"
              color="primary"
              startIcon={<Icon icon="circle-plus" />}
              onClick={() => {
                createNewOrder()
              }}
            >
              {rLIB('Create New Order')}
            </Button>
          }
        />
        <TableBasic
          tableAdditionalData={{}}
          tableColumns={tableColumns_SubkMaterialOrders}
          tableData={objectToArray(us_ordersForProject)}
          tableSettings={tableSettings_SubkMaterialOrders}
        />
      </Card>
    )

    return tabJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let tabsData: TsInterface_TabContentUrlArray = []
    tabsData.push({
      tabUrlKey: 'Details',
      tabHeader: rLIB('Details'),
      tabOnChange: () => {
        us_setActiveTab('details')
      },
      tabContent: (
        <Box className="tw-m-auto">
          <ProjectViewDetailsTab
            projectKey={pr_projectKey}
            readOrWrite={'read'}
          />
        </Box>
      ),
    })

    tabsData.push({
      tabUrlKey: 'materials',
      tabHeader: 'Materials',
      tabOnChange: () => {
        us_setActiveTab('materials')
      },
      tabContent: (
        <Box className="tw-m-auto">{rJSX_MaterialsTab('full', 'read', uc_RootData_ClientKey, uc_RootData_GlobalUser, us_combinedUserRoles, pr_projectKey)}</Box>
      ),
    })

    // Design Specific Tabs
    if (
      uc_RootData_ClientUser != null &&
      uc_RootData_ClientUser.task_roles != null &&
      (uc_RootData_ClientUser.task_roles['subcontractor_construction'] === true ||
        uc_RootData_ClientUser.task_roles['subcontractor_design'] === true ||
        uc_RootData_ClientUser.task_roles['subcontractor_permitting'] === true ||
        uc_RootData_ClientUser.task_roles['subcontractor_warehouse'] === true)
    ) {
      tabsData.push({
        tabUrlKey: 'Notes',
        tabHeader: rLIB('Notes'),
        tabOnChange: () => {
          us_setActiveTab('notes')
        },
        tabContent: (
          <Box className="tw-m-auto">
            <ProjectViewNotesTab
              projectKey={pr_projectKey}
              readOrWrite={'write'}
            />
          </Box>
        ),
      })
      tabsData.push({
        tabUrlKey: 'Documents',
        tabHeader: rLIB('Documents'),
        tabOnChange: () => {
          us_setActiveTab('documents')
        },
        tabContent: (
          <Box className="tw-m-auto">
            <ProjectViewDocumentsTab
              projectKey={pr_projectKey}
              readOrWrite={'read'}
            />
          </Box>
        ),
      })
    }
    tabsData.push({
      tabUrlKey: 'Photos',
      tabHeader: rLIB('Photos'),
      tabOnChange: () => {
        us_setActiveTab('photos')
      },
      tabContent: (
        <Box className="tw-m-auto">
          {rJSX_PhotosTab(
            'read',
            uc_RootData_ClientKey,
            us_allTaskFormData,
            us_availableImageTags,
            us_downloadingPhotos,
            us_filteredPhotosList,
            us_flatImageTagCounts,
            ur_forceRerender,
            us_photosViewLevel,
            us_photosViewType,
            pr_projectKey,
            us_projectRootPhotosFolders,
            us_projectTaskWorkflow,
            us_projectTasks,
            us_selectedAllImageFilterTag,
            us_selectedImageFilterTags,
            us_selectedPhotosTaskFolder,
            us_selectedPhotosTaskFolderData,
            us_selectedPhotosTaskFolderForm,
            us_selectedPhotosTaskPageFolder,
            us_selectedPhotosTaskPageSubfolder,
            us_setDownloadingPhotos,
            us_setPhotosViewLevel,
            us_setPhotosViewType,
            uc_setRootData_ClientKey,
            us_setSelectedAllImagesFilterTag,
            us_setSelectedImageFilterTags,
            us_setSelectedPhotosTaskFolder,
            us_setSelectedPhotosTaskFolderData,
            us_setSelectedPhotosTaskPageFolder,
            us_setSelectedPhotosTaskPageSubfolder,
            uc_setUserInterface_CustomDialogDisplay,
            uc_setUserInterface_ErrorDialogDisplay,
            uc_setUserInterface_FormDialogDisplay,
            uc_setUserInterface_AlertDialogDisplay,
            us_runningImageAnalysis,
            us_setRunningImageAnalysis,
            uc_setUserInterface_SnackbarDisplay,
            us_screenSize,
          )}
        </Box>
      ),
    })

    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rJSX_PageHeader()}
        pageKey={pageKey}
        content={
          <Box>
            <Box>
              <Button
                color="inherit"
                variant="outlined"
                startIcon={<Icon icon="chevron-left"></Icon>}
                onClick={(event) => {
                  onClickAppNavigation(event, un_routerNavigation, ApplicationPages.SubKActiveTasksListPage.url())
                }}
              >
                {rLIB('Back to tasks')}
              </Button>
            </Box>
            <TabsUrl
              tabs={tabsData}
              tabsSettings={{
                baseUrl: ApplicationPages.SubKProjectViewPage.url(pr_projectKey),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: getProp(us_rootProject, 'id_number', 'Project'),
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
