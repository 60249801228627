///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, query, Query, where } from 'firebase/firestore'
import {
  generateDatabaseArrayContainsAnyQuery,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { formatDateToYYYYMMDD } from 'rfbp_core/services/helper_functions'

///////////////////////////////
// Exports
///////////////////////////////

// Base
// export const DatabaseRef_Tasks_Collection =
// ( clientKey: string ): CollectionReference< DocumentData > => {
// return collection( getFirestore(), "clients", clientKey, "operations", "tasks", "main" ) }

export const DatabaseRef_Task_Document = (clientKey: string, taskKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main', taskKey)
}

// Status Logs
export const DatabaseRef_TaskStatusLogs_Collection = (clientKey: string, taskKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main', taskKey, 'status_logs')
}

export const DatabaseRef_TaskStatusLog_Document = (clientKey: string, taskKey: string, logKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main', taskKey, 'status_logs', logKey)
}

export const DatabaseRef_AdministrationTaskFormData_Document = (clientKey: string, taskKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'administration', 'task_form_data', taskKey)
}

// Queries
export const DatabaseRef_OpenAndReadyTasks_Query = (clientKey: string) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'ready_to_start', comparator: '==', value: true },
    { prop: 'status_complete', comparator: '==', value: false },
  ]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'), queryOperatorsArray, [], {}, null)
}

export const DatabaseRef_RecentlyCompletedTasks_Query = (clientKey: string, cutoffDate: Date) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'status_complete', comparator: '==', value: true },
    { prop: 'timestamp_completed', comparator: '>=', value: cutoffDate },
  ]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'), queryOperatorsArray, [], {}, null)
}

export const DatabaseRef_ContradictoryActiveCompletedTasks_Query = (clientKey: string) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'status_complete', comparator: '==', value: true },
    { prop: 'status', comparator: '==', value: 'active' },
  ]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'), queryOperatorsArray, [], {}, null)
}

export const DatabaseRef_ContradictoryAssignedCompletedTasks_Query = (clientKey: string) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'status_complete', comparator: '==', value: true },
    { prop: 'status', comparator: '==', value: 'assigned' },
  ]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'), queryOperatorsArray, [], {}, null)
}

export const DatabaseRef_UserOpenAndReadyTasks_Query = (clientKey: string, userKey: string) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'associated_owner_key', comparator: '==', value: userKey },
    { prop: 'ready_to_start', comparator: '==', value: true },
    { prop: 'status_complete', comparator: '==', value: false },
  ]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'), queryOperatorsArray, [], {}, null)
}

export const DatabaseRef_UserCreatedOpenAndReadyTasks_Query = (clientKey: string, userKey: string) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'associated_creator_key', comparator: '==', value: userKey },
    { prop: 'ready_to_start', comparator: '==', value: true },
    { prop: 'status_complete', comparator: '==', value: false },
  ]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'), queryOperatorsArray, [], {}, null)
}

export const DatabaseRef_OpenDispatcherTasks_Query = (clientKey: string) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'task_completion_type', comparator: '==', value: 'dispatcher' },
    { prop: 'ready_to_start', comparator: '==', value: true },
    { prop: 'status_complete', comparator: '==', value: false },
    { prop: 'status', comparator: '!=', value: 'deleted' },
  ]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'), queryOperatorsArray, [], {}, null)
}

export const DatabaseRef_DispatchedTasksWithSpecificTeamType_Query = (clientKey: string, teamType: string, startDateKey: number, endDateKey: number) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'associated_team_type', comparator: '==', value: teamType },
    { prop: 'timestamp_scheduled', comparator: '>=', value: startDateKey },
    { prop: 'timestamp_scheduled', comparator: '<=', value: endDateKey },
  ]
  let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_scheduled', desc: false }]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_ScheduledTasksInDateRange_Query = (clientKey: string, startDate: Date, endDate: Date) => {
  let dateKeyArray: string[] = []
  let daylightSavingsDetected = false
  let nextDateKey = ''
  for (let loopTime = startDate.getTime(); loopTime <= endDate.getTime() + 3600000; loopTime += 86400000) {
    let loopDate = new Date(loopTime)
    let loopDateKey = formatDateToYYYYMMDD(loopDate)
    // Check and see if the date is in the array already for daylight savings
    if (!dateKeyArray.includes(loopDateKey)) {
      daylightSavingsDetected = true
      dateKeyArray.push(loopDateKey)
    }
    nextDateKey = formatDateToYYYYMMDD(new Date(loopTime + 86400000))
  }
  // If daylight savings was detected, increment and add one more date key
  if (daylightSavingsDetected) {
    dateKeyArray.push(nextDateKey)
  }
  return generateDatabaseArrayContainsAnyQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'),
    'task_completion_scheduled_dates',
    dateKeyArray,
  )
}

export const DatabaseRef_AssignedTasks_Query = (clientKey: string, status: string) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: status }]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'), queryOperatorsArray, [], {}, null)
}

export const DatabaseRef_ScheduledTasksForTeamOnDate_Query = (clientKey: string, selectedDate: string, teamKey: string) => {
  return query(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'),
    where('task_completion_scheduled_dates', 'array-contains', selectedDate),
    where('associated_team_key', '==', teamKey),
  )
}

// export const DatabaseRef_ScheduledTasksForTeamsInDateRange_Query = (clientKey: string, startDate: Date, endDate: Date, teamKeys: string[]) => {
//   const startDateKey = formatDateToYYYYMMDD(startDate)
//   const endDateKey = formatDateToYYYYMMDD(endDate)

//   return query(
//     collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'),
//     where('task_completion_scheduled_dates', '>=', startDateKey),
//     where('task_completion_scheduled_dates', '<=', endDateKey),
//     where('associated_team_key', 'in', teamKeys),
//   )
// }

export const DatabaseRef_ScheduledTasksInDateRangeArray_Query = (clientKey: string, dateKeyArray: string[]) => {
  return generateDatabaseArrayContainsAnyQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'),
    'task_completion_scheduled_dates',
    dateKeyArray,
  )
}

export const DatabaseRef_ScheduledTasksByCompletionDate_Query = (clientKey: string, startDate: Date, endDate: Date) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'task_completion_type', comparator: '==', value: 'scheduled' },
    { prop: 'timestamp_completed', comparator: '>=', value: startDate },
    { prop: 'timestamp_completed', comparator: '<', value: endDate },
  ]
  let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_completed', desc: false }]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_ScheduledTasks_LastUpdated_Query = (clientKey: string, startDate: Date, endDate: Date) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'timestamp_last_updated', comparator: '>=', value: startDate },
    { prop: 'timestamp_last_updated', comparator: '<=', value: endDate },
  ]
  let queryCursorsObject = {}
  let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_last_updated', desc: true }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'),
    queryOperatorsArray,
    orderByArray,
    queryCursorsObject,
    null,
  )
}

export const DatabaseRef_ScheduledTasksOnDateForSpecificUser_Query = (clientKey: string, dateKey: string, userKey: string) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'task_completion_scheduled_team_keys.' + dateKey + '.' + userKey, comparator: '==', value: true },
  ]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'), queryOperatorsArray, [], {}, null)
}

export const DatabaseRef_UserRecentlyCompletedTasks_Query = (clientKey: string, userKey: string, cutoffTimestamp: Date) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'associated_owner_key', comparator: '==', value: userKey },
    { prop: 'timestamp_completed', comparator: '>=', value: cutoffTimestamp },
  ]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'), queryOperatorsArray, [], {}, null)
}

export const DatabaseRef_UserCreatedRecentlyCompletedTasks_Query = (clientKey: string, userKey: string, cutoffTimestamp: Date) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'associated_creator_key', comparator: '==', value: userKey },
    { prop: 'timestamp_completed', comparator: '>=', value: cutoffTimestamp },
  ]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'), queryOperatorsArray, [], {}, null)
}

export const DatabaseRef_AllProjectTasks_Query = (clientKey: string, projectKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_project_key', comparator: '==', value: projectKey }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_AllProtoProjectTasks_Query = (clientKey: string, protoProjectKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_proto_project_key', comparator: '==', value: protoProjectKey }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_AllProjectTasksFromWorkflow_Query = (clientKey: string, projectKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'associated_project_key', comparator: '==', value: projectKey },
    { prop: 'associated_task_origin', comparator: '==', value: 'workflow' },
  ]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_AllProjectTasksFromSameWorkflowTask_Query = (clientKey: string, projectKey: string, workflowTaskKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'associated_project_key', comparator: '==', value: projectKey },
    { prop: 'associated_task_key', comparator: '==', value: workflowTaskKey },
  ]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_UpstreamDispatcherTask_Query = (clientKey: string, projectKey: string, taskKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'associated_project_key', comparator: '==', value: projectKey },
    { prop: 'associated_dispatched_task_key', comparator: '==', value: taskKey },
  ]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_AllProjectTasksFromDeletedWorkflow_Query = (clientKey: string, projectKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_project_key', comparator: '==', value: projectKey + '_deleted' }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_ProjectTasksForSpecificOwnerRole_Query = (clientKey: string, projectKey: string, userRoleKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'associated_project_key', comparator: '==', value: projectKey },
    { prop: 'associated_owner_type', comparator: '==', value: userRoleKey },
  ]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_ProjectTasksForPrerequisiteTask_Query = (clientKey: string, prerequisiteTaskKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'prerequisite_tasks.' + prerequisiteTaskKey, comparator: '==', value: prerequisiteTaskKey },
  ]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_DispatcherTask_Query = (clientKey: string, dispatchedTaskKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_dispatched_task_key', comparator: '==', value: dispatchedTaskKey }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_TaskForSpecificProject_Query = (clientKey: string, projectKey: string) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_project_key', comparator: '==', value: projectKey }]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'), queryOperatorsArray, [], {}, null)
}

export const DatabaseRef_TaskForSpecificProjectAndTaskKey_Query = (clientKey: string, projectKey: string, taskKeyArray: string[]) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'associated_project_key', comparator: '==', value: projectKey },
    { prop: 'associated_task_key', comparator: 'in', value: taskKeyArray },
  ]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'), queryOperatorsArray, [], {}, null)
}

export const DatabaseRef_UserOpenAndReadyTasks_Query_AllNav_Query = (clientKey: string, userKey: string) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'associated_owner_key', comparator: '==', value: userKey },
    { prop: 'ready_to_start', comparator: '==', value: true },
  ]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'), queryOperatorsArray, [], {}, 1)
}

export const DatabaseRef_QAReviewedTasksByDateRange_Query = (clientKey: string, startDate: Date, endDate: Date) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'timestamp_qa_review_date', comparator: '>=', value: startDate },
    { prop: 'timestamp_qa_review_date', comparator: '<', value: endDate },
  ]
  let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_qa_review_date', desc: false }]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_TasksByTypeAndRange_Query = (clientKey: string, startDate: Date, endDate: Date, bluePrintKey: string) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'associated_task_blueprint_key', comparator: '==', value: bluePrintKey },
    { prop: 'timestamp_completed', comparator: '>=', value: startDate },
    { prop: 'timestamp_completed', comparator: '<', value: endDate },
  ]
  let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_completed', desc: false }]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_TaskByProjectAndBlueprintKey_Query = (clientKey: string, projectKey: any, blueprintKey: string) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'associated_project_key', comparator: '==', value: projectKey },
    { prop: 'associated_task_blueprint_key', comparator: '==', value: blueprintKey },
  ]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'), queryOperatorsArray, [], {}, null)
}

// Pending Payroll
export const DatabaseRef_TaskRequiringPartnerApproval_Query = (clientKey: string) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'sales_partner_approval_required', comparator: '==', value: true },
    { prop: 'sales_partner_approval_granted', comparator: '==', value: false },
  ]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'), queryOperatorsArray, [], {}, null)
}

export const DatabaseRef_TasksByDateRange_Query = (clientKey: string, startDate: Date, endDate: Date) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'timestamp_completed', comparator: '>=', value: startDate },
    { prop: 'timestamp_completed', comparator: '<', value: endDate },
  ]
  let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_completed', desc: false }]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_AssignedButNotCompleteTasks_Query = (clientKey: string) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'task_completion_type', comparator: '==', value: 'scheduled' },
    { prop: 'status', comparator: '==', value: 'assigned' },
  ]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'), queryOperatorsArray, [], {}, null)
}

export const DatabaseRef_JobsToClose_Query = (clientKey: string) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'status', comparator: 'in', value: ['assigned', 'active'] }, // Query for "assigned" or "active" status
  ]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'), queryOperatorsArray, [], {}, null)
}

export const DatabaseRef_TaskLinkedToAdditionalWorkInvoice_Query = (clientKey: string, invoiceKey: string, taskKey: string) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'associated_additional_work_invoice_key', comparator: '==', value: invoiceKey },
    { prop: 'associated_task_blueprint_key', comparator: '==', value: taskKey },
  ]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'), queryOperatorsArray, [], {}, null)
}

// Task Payroll Hours
export const DatabaseRef_TaskCachedPayrollHours_Document = (clientKey: string, taskKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'payroll_hours', taskKey)
}

export const DatabaseRef_TasksCachedByPayrollHours_Query = (clientKey: string, startDate: Date, endDate: Date) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'timestamp_completed', comparator: '>=', value: startDate },
    { prop: 'timestamp_completed', comparator: '<', value: endDate },
  ]
  let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_completed', desc: false }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'payroll_hours'),
    queryOperatorsArray,
    orderByArray,
    {},
    null,
  )
}
