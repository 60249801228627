///////////////////////////////
// Imports
///////////////////////////////

import { collection, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_GlobalUser_AppRegistrationTokens_Document = (userKey: string) => {
  return doc(getFirestore(), 'users', userKey, 'data', 'app_registration_tokens')
}

export const DatabaseRef_User_Document = (clientKey: string, userKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'users', 'main', userKey)
}

export const DatabaseRef_ActiveUsers_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'directory', 'users', 'main'), queryOperatorsArray, orderByArray, {}, null)
}
