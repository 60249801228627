///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:

		TODO:
			Download Button not using locked data
			Load Projects for Panel Counts
			Region overrides on unit pay
	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  Box,
  Button,
  Card,
  Chip,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material/'
import { downloadAggregatedPunchData } from 'app/models/finance/beta_reports'
import { exportAllProjects } from 'app/models/finance/project_export'
import { hourlyOrSalariedOptions } from 'app/models/users/user_form'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_TaskBlueprints_Collection } from 'rfbp_aux/services/database_endpoints/directory/task_blueprints'
import { DatabaseRef_TaskWorkflowProd_Document, DatabaseRef_TaskWorkflows_Collection } from 'rfbp_aux/services/database_endpoints/directory/task_workflows'
import { DatabaseRef_InternalUsers_Query, DatabaseRef_UsersExcludedFromPayrollTotals_Query } from 'rfbp_aux/services/database_endpoints/directory/users'
import {
  DatabaseRef_CombineLogsForSpecificDateKey_Query,
  DatabaseRef_PreviousUserCombineLogs_Query,
} from 'rfbp_aux/services/database_endpoints/finances/combine_logs'
import {
  DatabaseRef_FinalizedPayroll_AdHocTasks_Collection,
  DatabaseRef_FinalizedPayroll_AdHocTasks_Document,
  DatabaseRef_FinalizedPayroll_CachedCancelledTasksData_Collection,
  DatabaseRef_FinalizedPayroll_CachedEfficiencyClockHourData_Collection,
  DatabaseRef_FinalizedPayroll_CachedProjectData_Collection,
  DatabaseRef_FinalizedPayroll_CustomMappingData_Collection,
  DatabaseRef_FinalizedPayroll_CustomMappingData_Document,
  DatabaseRef_FinalizedPayroll_DistanceAdderUserData_Collection,
  DatabaseRef_FinalizedPayroll_DistanceAdderUserData_Document,
  DatabaseRef_FinalizedPayroll_Document,
  DatabaseRef_FinalizedPayroll_EmployeeFullPayrollData_Collection,
  DatabaseRef_FinalizedPayroll_EmployeeFullPayrollData_Document,
  DatabaseRef_FinalizedPayroll_ErrorsAndWarningResolutions_Collection,
  DatabaseRef_FinalizedPayroll_ErrorsAndWarningResolutions_Document,
  DatabaseRef_FinalizedPayroll_ErrorsAndWarnings_Collection,
  DatabaseRef_FinalizedPayroll_ErrorsAndWarnings_Document,
  DatabaseRef_FinalizedPayroll_UnitPayTasks_Collection,
  DatabaseRef_FinalizedPayroll_UnitPayTasks_Document,
  DatabaseRef_FinalizedPayroll_UploadedPTOFile_Document,
} from 'rfbp_aux/services/database_endpoints/finances/finalized_payroll'
import { DatabaseRef_LeaderboardData_Document } from 'rfbp_aux/services/database_endpoints/finances/leaderboards'
import { DatabaseRef_LifetimePanels_Document } from 'rfbp_aux/services/database_endpoints/finances/lifetime_panels'
import { DatabaseRef_LifetimeReferrals_Document } from 'rfbp_aux/services/database_endpoints/finances/lifetime_referrals'
import { DatabaseRef_LifetimeUnits_Document } from 'rfbp_aux/services/database_endpoints/finances/lifetime_units'
import {
  DatabaseRef_PayrollAdjustments_Collection,
  DatabaseRef_PayrollAdjustments_Unresolved_Query,
  DatabaseRef_PayrollAdjustments_Week_Query,
  DatabaseRef_UnresolvedPayrollAdjustmentSuggestions_Query,
} from 'rfbp_aux/services/database_endpoints/finances/payroll_adjustments'
import { DatabaseRef_PayrollLogs_Collection } from 'rfbp_aux/services/database_endpoints/finances/payroll_logs'
import {
  DatabaseRef_PayrollRates_Collection,
  DatabaseRef_PayrollRates_MinimumWage_Document,
  DatabaseRef_PayrollRates_TaskUnitMapping_Document,
} from 'rfbp_aux/services/database_endpoints/finances/payroll_rates'
import {
  DatabaseRef_QAReviewedTasksByDateRange_Query,
  DatabaseRef_ScheduledTasksByCompletionDate_Query,
  DatabaseRef_TaskCachedPayrollHours_Document,
  DatabaseRef_TaskRequiringPartnerApproval_Query,
  DatabaseRef_TasksCachedByPayrollHours_Query,
  DatabaseRef_Task_Document,
} from 'rfbp_aux/services/database_endpoints/operations/tasks'
import { DatabaseRef_TimeSheetsAllocationTypes_Collection } from 'rfbp_aux/services/database_endpoints/timesheets/allocation_types'
import { DatePicker } from 'rfbp_core/components/date_picker'
import { FileUploadButton } from 'rfbp_core/components/file_upload'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableBasic,
  TableCellBasic,
  TableCellManage,
  TableCellTimestamp,
  TableDatabase,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseEndpointQueryObject,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientUser,
  Context_UserInterface_AlertDialog,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  Context_UserInterface_PromptDialog,
} from 'rfbp_core/services/context'
import {
  DatabaseAddDocument,
  DatabaseBatchUpdate,
  DatabaseGetCollection,
  DatabaseGetDocument,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
  DatabaseSetReplaceDocument,
  DatabaseStagedBatchUpdate,
  generateDatabaseQuery,
  TsInterface_DatabaseBatchUpdatesArray,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import {
  downloadCSV,
  dynamicSort,
  formatCurrency,
  getProp,
  objectToArray,
  returnDateFromUnknownDateFormat,
  returnFormattedDate,
  returnFormattedDateKey,
} from 'rfbp_core/services/helper_functions'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { compareTwoStrings } from 'string-similarity'
import * as XLSX from 'xlsx'
import {
  getLastChronologicalDate,
  getStartAndEndOfWeek,
  recacheProjectAndTimesheetDataForPayroll,
  returnAggregatePunchDataForPayrollCalculations,
  returnPayrollCalculatedData,
  unitWorkTypes,
} from './data/payroll_calculations'
import { DistanceAdderAdjustmentDialog } from './dialogs/distance_adder_adjustment_dialog'
import { tableColumns_TasksPendingSalesPartnerApproval, tableSettings_TasksPendingSalesPartnerApproval } from './tables/approval_pending'
import {
  formatDateRange,
  getDaysBetweenDates,
  includeAdjustmentInPayrollWeek,
  removeAdjustmentFromPayrollWeek,
  tableColumns_Adjustments,
  tableColumns_PayrollSummary,
  tableColumns_SuggestedAdjustments,
  tableColumns_UnitPayBreakdown,
  tableSettings_AdHocTasks,
  tableSettings_SuggestedAdjustments,
  us_tableSettings_Adjustments,
} from './tables/payroll_tables'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminPayrollIndexPage']['key']

// Table
const lockCalculatedDataToStoreInItsOwnCollection: TsInterface_UnspecifiedObject = {
  ad_hoc_tasks: (clientKey: string, weekKey: string, documentKey: string) => {
    return DatabaseRef_FinalizedPayroll_AdHocTasks_Document(clientKey, weekKey, documentKey)
  },
  distance_adder_user_data: (clientKey: string, weekKey: string, documentKey: string) => {
    return DatabaseRef_FinalizedPayroll_DistanceAdderUserData_Document(clientKey, weekKey, documentKey)
  },
  employee_full_payroll_data: (clientKey: string, weekKey: string, documentKey: string) => {
    return DatabaseRef_FinalizedPayroll_EmployeeFullPayrollData_Document(clientKey, weekKey, documentKey)
  },
  unit_pay_tasks: (clientKey: string, weekKey: string, documentKey: string) => {
    return DatabaseRef_FinalizedPayroll_UnitPayTasks_Document(clientKey, weekKey, documentKey)
  },
}

const calculatedDataToWipeOnLock: TsInterface_UnspecifiedObject = {
  ad_hoc_tasks: (clientKey: string, weekKey: string) => {
    return DatabaseRef_FinalizedPayroll_AdHocTasks_Collection(clientKey, weekKey)
  },
  distance_adder_user_data: (clientKey: string, weekKey: string) => {
    return DatabaseRef_FinalizedPayroll_DistanceAdderUserData_Collection(clientKey, weekKey)
  },
  employee_full_payroll_data: (clientKey: string, weekKey: string) => {
    return DatabaseRef_FinalizedPayroll_EmployeeFullPayrollData_Collection(clientKey, weekKey)
  },
  unit_pay_tasks: (clientKey: string, weekKey: string) => {
    return DatabaseRef_FinalizedPayroll_UnitPayTasks_Collection(clientKey, weekKey)
  },
}

const tableColumns_PayrollLogs: TsInterface_TableColumns = {
  timestamp_created: TableCellTimestamp('timestamp_created', rLIB('Timestamp'), 'timestamp_created', 'D MMM YYYY h:mm a', true),
  associated_editor_name: TableCellBasic('associated_editor_name', rLIB('User'), 'associated_editor_name'),
  TEMP_log: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <></>
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData['log_type'] === 'task_completion_date_change') {
          cellJSX = (
            <Box>
              <Box>
                {rowData['associated_project_id_number']} - {rowData['associated_task_name']}
              </Box>
              <Box className="tw-opacity-30">
                {rLIB('Completion date changed from')} {returnFormattedDate(rowData['previous_timestamp_completed'], 'D MMM YYYY h:mm a')} {rLIB('to')}{' '}
                {returnFormattedDate(rowData['new_timestamp_completed'], 'D MMM YYYY h:mm a')}
              </Box>
            </Box>
          )
        } else {
          cellJSX = <Box>{rowData['log_type']}</Box>
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
          cellCSS = 'workflow_table_highlight'
        }
        return cellCSS
      },
    },
  },
  notes: TableCellBasic('notes', rLIB('Notes'), 'notes'),
}

const tableSettings_PayrollLogs: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: true,
  collapsible_columns: true,
}

// Custom Dialog

///////////////////////////////
// Functions
///////////////////////////////

function findCloseStringMatches(searchString: string, taskBlueprints: TsInterface_UnspecifiedObject, unitMapping: TsInterface_UnspecifiedObject) {
  // Initialize an empty array to store the matched sub objects.
  const matchedSubObjects = []
  // Loop through the indices of the taskBlueprints array using a for...in loop.
  for (const index in taskBlueprints) {
    // eslint-disable-next-line no-prototype-builtins
    if (taskBlueprints.hasOwnProperty(index)) {
      const subObject = taskBlueprints[index]
      // Extract the name and key properties from the subobject.
      let { name, key } = subObject
      if (name == null) {
        name = ''
      }
      if (searchString == null) {
        searchString = ''
      }
      const similarity = compareTwoStrings(name, searchString)
      let task_units = null
      if (unitMapping != null && unitMapping[key] != null && unitMapping[key]['task_units'] != null) {
        task_units = unitMapping[key]['task_units']
      }
      let unit_work_type = null
      if (unitMapping != null && unitMapping[key] != null && unitMapping[key]['unit_work_type'] != null) {
        unit_work_type = unitMapping[key]['unit_work_type']
      }
      if (unitWorkTypes != null && unitWorkTypes[unit_work_type] != null && unitWorkTypes[unit_work_type]['abbreviation'] != null) {
        unit_work_type = unitWorkTypes[unit_work_type]['abbreviation']
      }
      // If there was a match, add the subobject to the results.
      if (similarity > 0.3) {
        matchedSubObjects.push({
          name,
          key,
          similarity,
          task_units,
          unit_work_type,
        })
      }
    }
  }
  // Sort the matched sub objects by closeness score (descending).
  matchedSubObjects.sort((a, b) => b.similarity - a.similarity)
  if (matchedSubObjects.length > 5) {
    return matchedSubObjects.slice(0, 5) // Return the first 8 elements
  } else {
    return matchedSubObjects // Return the entire array
  }
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  const [us_filteredUnitPayTaskList, us_setFilteredUnitPayTaskList] = useState<TsInterface_UnspecifiedObject>({})
  const [us_unitPayTaskEmployeeList, us_setUnitPayTaskEmployeeList] = useState<TsInterface_UnspecifiedObject>({})
  const [us_filterUnitPayDropdownValue, us_setFilterUnitPayDropdownValue] = useState<string>('ALL')
  const [us_activeTab, us_setActiveTab] = useState<string | null>(null)
  const [us_adjustmentErrorCount, us_setAdjustmentErrorCount] = useState<number>(0)
  const [us_adjustmentWarningCount, us_setAdjustmentWarningCount] = useState<number>(0)
  const [us_aggregatedWeekPunchData, us_setAggregatedWeekPunchData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_cachedTaskPayrollCompletionDate, us_setCachedTaskPayrollCompletionDate] = useState<boolean>(false)
  const [us_combinedPayrollAdjustments, us_setCombinedPayrollAdjustments] = useState<TsInterface_UnspecifiedObject>({})
  const [us_discrepancyWeekPayrollData, us_setDiscrepancyWeekPayrollData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_displayedWeekLeaderboardData, us_setDisplayedWeekLeaderboardData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_displayedWeekPayrollData, us_setDisplayedWeekPayrollData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_employeeClockedTaskHours, us_setEmployeeClockedTaskHours] = useState<TsInterface_UnspecifiedObject>({})
  const [us_employeePayrollDataTotals, us_setEmployeePayrollDataTotals] = useState<TsInterface_UnspecifiedObject>({})
  const [us_employeePayrollDiscrepancyDataTotals, us_setEmployeePayrollDiscrepancyTotals] = useState<TsInterface_UnspecifiedObject>({})
  const [us_finalizedWeekLeaderboardSavedData, us_setFinalizedWeekLeaderboardSavedData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_finalizedWeekPayrollRoot, us_setFinalizedWeekPayrollRoot] = useState<TsInterface_UnspecifiedObject>({})
  const [us_finalizedWeekPayroll_AdHocTasks, us_setFinalizedWeekPayroll_AdHocTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_finalizedWeekPayroll_BaseCustomMappingData, us_setFinalizedWeekPayroll_BaseCustomMappingData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_finalizedWeekPayroll_CachedCancelledTasksData, us_setFinalizedWeekPayroll_CachedCancelledTasksData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_finalizedWeekPayroll_CachedEfficiencyClockHourData, us_setFinalizedWeekPayroll_CachedEfficiencyClockHourData] =
    useState<TsInterface_UnspecifiedObject>({})
  const [us_finalizedWeekPayroll_CachedProjectData, us_setFinalizedWeekPayroll_CachedProjectData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_finalizedWeekPayroll_CalculatedData, us_setFinalizedWeekPayroll_CalculatedData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_finalizedWeekPayroll_CustomMappingData, us_setFinalizedWeekPayroll_CustomMappingData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_finalizedWeekPayroll_DistanceAdderUserData, us_setFinalizedWeekPayroll_DistanceAdderUserData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_finalizedWeekPayroll_EmployeeFullPayrollData, us_setFinalizedWeekPayroll_EmployeeFullPayrollData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_finalizedWeekPayroll_ErrorAndWarningData, us_setFinalizedWeekPayroll_ErrorAndWarningData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_finalizedWeekPayroll_ErrorAndWarningResolutionData, us_setFinalizedWeekPayroll_ErrorAndWarningResolutionData] =
    useState<TsInterface_UnspecifiedObject>({})
  const [us_finalizedWeekPayroll_UnitPayTasks, us_setFinalizedWeekPayroll_UnitPayTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_finalizedWeekPayroll_UploadedPTOFile, us_setFinalizedWeekPayroll_UploadedPTOFile] = useState<TsInterface_UnspecifiedObject>({})
  const [us_usersExcludedFromPayrollTotals, us_setUsersExcludedFromPayrollTotals] = useState<TsInterface_UnspecifiedObject>({})
  const [us_leaderboardReferralData, us_setLeaderboardReferralData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_lifetimeReferralsData, us_setLifetimeReferralsData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_loadedWeekCompletedPayrollHourTasks, us_setLoadedWeekCompletedPayrollHourTasks] = useState<boolean>(false)
  const [us_loadedWeekCompletedTasks, us_setLoadedWeekCompletedTasks] = useState<boolean>(false)
  const [us_lockDownloadsDueToNegativeBonuses, us_setLockDownloadsDueToNegativeBonuses] = useState<boolean>(false)
  const [us_lockingPayrollData, us_setLockingPayrollData] = useState<boolean>(false)
  const [us_maxingOutAdjustments, us_setMaxingOutAdjustments] = useState<boolean>(false)
  const [us_minimumWageValues, us_setMinimumWageValues] = useState<TsInterface_UnspecifiedObject>({})
  const [us_mondayDateKey, us_setMondayDateKey] = useState<string | null>(null)
  const [us_mondayDateKeyNumber, us_setMondayDateKeyNumber] = useState<number | null>(null)
  const [us_payrollRatesData, us_setPayrollRatesData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_previousUserCombineLogs, us_setPreviousUserCombineLogs] = useState<TsInterface_UnspecifiedObject>({})
  const [us_recachingProjectData, us_setRecachingProjectData] = useState<boolean>(false)
  const [us_runningCalculation, us_setRunningCalculation] = useState<boolean>(false)
  const [us_selectedDate, us_setSelectedDate] = useState<Date>(new Date())
  const [us_selectedWeekCombineLogs, us_setSelectedWeekCombineLogs] = useState<TsInterface_UnspecifiedObject>({})
  // const [us_tableColumns_DistanceAdders, us_setTableColumns_DistanceAdders] = useState<TsInterface_TableColumns>({})
  // const [us_tableData_DistanceAdders, us_setTableData_DistanceAdders] = useState<TsInterface_UnspecifiedObject>({})
  // const [us_tableSettings_DistanceAdders, us_setTableSettings_DistanceAdders] = useState<TsInterface_TableSettings>({})
  const [us_tableSettings_PayrollSummary, us_setTableSettings_PayrollSummary] = useState<TsInterface_TableSettings>({})
  const [us_tableSettings_UnitPayBreakdown, us_setTableSettings_UnitPayBreakdown] = useState<TsInterface_TableSettings>({})
  const [us_taskBlueprintUnitMapping, us_setTaskBlueprintUnitMapping] = useState<TsInterface_UnspecifiedObject>({})
  const [us_taskBlueprints, us_setTaskBlueprints] = useState<TsInterface_UnspecifiedObject>({})
  const [us_tasksMissingCachedProjects, us_setTasksMissingCachedProjects] = useState<TsInterface_UnspecifiedObject>({})
  const [us_tasksRequiringPartnerApproval, us_setTasksRequiringPartnerApproval] = useState<TsInterface_UnspecifiedObject>({})
  const [us_tasksWithQAReviews, us_setTasksWithQAReviews] = useState<TsInterface_UnspecifiedObject>({})
  const [us_unresolvedPayrollAdjustments, us_setUnresolvedPayrollAdjustments] = useState<TsInterface_UnspecifiedObject>({})
  const [us_userKeysArray, us_setUserKeysArray] = useState<string[]>([])
  const [us_usersWithAdjustmentsButNoPayroll, us_setUsersWithAdjustmentsButNoPayroll] = useState<TsInterface_UnspecifiedObject>({})
  const [us_usersWithNegativeBonuses, us_setUsersWithNegativeBonuses] = useState<TsInterface_UnspecifiedObject>({})
  const [us_unresolvedPayrollAdjustmentSuggestions, us_setUnresolvedPayrollAdjustmentSuggestions] = useState<TsInterface_UnspecifiedObject>({})
  const [us_weekCompletedPayrollHourTasks, us_setWeekCompletedPayrollHourTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_weekCompletedTasks, us_setWeekCompletedTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_weekEndDate, us_setWeekEndDate] = useState<Date | null>(null)
  const [us_weekPayrollAdjustments, us_setWeekPayrollAdjustments] = useState<TsInterface_UnspecifiedObject>({})
  const [us_weekStartDate, us_setWeekStartDate] = useState<Date | null>(null)
  const ul_queryParams = new URLSearchParams(useLocation().search)
  const umq_isSummaryMediumStickyTableBreakpoint = useMediaQuery('(max-width: 515px)')
  const umq_isSummarySmallestStickyTableBreakpoint = useMediaQuery('(max-width: 435px)')
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_setUserInterface_AlertDialogDisplay } = useContext(Context_UserInterface_AlertDialog)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Payroll', false) as string
  }, [])

  useEffect(() => {
    if (ul_queryParams != null && ul_queryParams.get('tab') != null) {
      us_setActiveTab(ul_queryParams.get('tab'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let weekBoundingDates = getStartAndEndOfWeek(us_selectedDate)
    us_setWeekStartDate(weekBoundingDates.startOfWeek)
    us_setWeekEndDate(weekBoundingDates.endOfWeek)
    let formattedDateKey = returnFormattedDateKey(weekBoundingDates.startOfWeek)
    us_setMondayDateKey(formattedDateKey)
    let mondayDateKeyNumber = parseInt(formattedDateKey.replace(/-/g, ''))
    us_setMondayDateKeyNumber(mondayDateKeyNumber)
    return () => {}
  }, [us_selectedDate])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        ur_forceRerender()
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setFinalizedWeekPayrollRoot(newData)
      ur_forceRerender()
    }
    if (us_mondayDateKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_FinalizedPayroll_Document(res_GCK.clientKey, us_mondayDateKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_mondayDateKey])

  useEffect(() => {
    // Merging Multiple Data Sources Into One
    let copyOfBaseCalculatedData = JSON.parse(JSON.stringify(us_finalizedWeekPayroll_ErrorAndWarningData))
    copyOfBaseCalculatedData['ad_hoc_tasks'] = us_finalizedWeekPayroll_AdHocTasks
    copyOfBaseCalculatedData['distance_adder_user_data'] = us_finalizedWeekPayroll_DistanceAdderUserData
    copyOfBaseCalculatedData['employee_full_payroll_data'] = us_finalizedWeekPayroll_EmployeeFullPayrollData
    copyOfBaseCalculatedData['unit_pay_tasks'] = us_finalizedWeekPayroll_UnitPayTasks
    us_setFinalizedWeekPayroll_CalculatedData(copyOfBaseCalculatedData)
  }, [
    us_finalizedWeekPayroll_AdHocTasks,
    us_finalizedWeekPayroll_ErrorAndWarningData,
    us_finalizedWeekPayroll_EmployeeFullPayrollData,
    us_finalizedWeekPayroll_UnitPayTasks,
    us_finalizedWeekPayroll_ErrorAndWarningResolutionData,
    us_finalizedWeekPayroll_DistanceAdderUserData,
  ])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setFinalizedWeekPayroll_ErrorAndWarningData(newData)
      ur_forceRerender()
    }
    if (us_mondayDateKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_FinalizedPayroll_ErrorsAndWarnings_Collection(res_GCK.clientKey, us_mondayDateKey),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_mondayDateKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setUsersExcludedFromPayrollTotals(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_UsersExcludedFromPayrollTotals_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_mondayDateKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setFinalizedWeekPayroll_ErrorAndWarningResolutionData(newData)
      ur_forceRerender()
    }
    if (us_mondayDateKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_FinalizedPayroll_ErrorsAndWarningResolutions_Collection(res_GCK.clientKey, us_mondayDateKey),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_mondayDateKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setTaskBlueprints(newData)
      ur_forceRerender()
    }
    if (us_mondayDateKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_TaskBlueprints_Collection(res_GCK.clientKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_mondayDateKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setTaskBlueprintUnitMapping(newData)
      ur_forceRerender()
    }
    if (us_mondayDateKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_PayrollRates_TaskUnitMapping_Document(res_GCK.clientKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_mondayDateKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      let unresolvedPayrollAdjustments: TsInterface_UnspecifiedObject = {}
      if (us_mondayDateKey != null) {
        const [year, month, day] = us_mondayDateKey.split('-').map(Number) // Split the date and convert to numbers
        const currentPayrollDate = new Date(year, month - 1, day).getTime()
        for (let loopAdjustmentKey in newData) {
          let loopAdjustment = newData[loopAdjustmentKey]
          if (
            loopAdjustment['timestamp_apply_after'] == null ||
            currentPayrollDate >= returnDateFromUnknownDateFormat(loopAdjustment['timestamp_apply_after']).getTime()
          ) {
            unresolvedPayrollAdjustments[loopAdjustmentKey] = loopAdjustment
          }
        }
        us_setUnresolvedPayrollAdjustments(unresolvedPayrollAdjustments)
        ur_forceRerender()
      }
    }
    if (us_mondayDateKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_PayrollAdjustments_Unresolved_Query(res_GCK.clientKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_mondayDateKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setWeekPayrollAdjustments(newData)
      ur_forceRerender()
    }
    if (us_mondayDateKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_PayrollAdjustments_Week_Query(res_GCK.clientKey, us_mondayDateKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_mondayDateKey])

  useEffect(() => {
    let combinedPayrollAdjustments = {}
    if (getProp(us_finalizedWeekPayrollRoot, 'locked', false) === true) {
      combinedPayrollAdjustments = us_weekPayrollAdjustments
    } else {
      combinedPayrollAdjustments = us_unresolvedPayrollAdjustments
      combinedPayrollAdjustments = { ...us_weekPayrollAdjustments, ...us_unresolvedPayrollAdjustments }
    }
    us_setCombinedPayrollAdjustments(combinedPayrollAdjustments)
  }, [us_finalizedWeekPayrollRoot, us_unresolvedPayrollAdjustments, us_weekPayrollAdjustments])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setFinalizedWeekPayroll_AdHocTasks(newData)
      ur_forceRerender()
    }
    if (us_mondayDateKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_FinalizedPayroll_AdHocTasks_Collection(res_GCK.clientKey, us_mondayDateKey),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_mondayDateKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setFinalizedWeekPayroll_EmployeeFullPayrollData(newData)
      ur_forceRerender()
    }
    if (us_mondayDateKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_FinalizedPayroll_EmployeeFullPayrollData_Collection(res_GCK.clientKey, us_mondayDateKey),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_mondayDateKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setFinalizedWeekPayroll_UnitPayTasks(newData)
      ur_forceRerender()
    }
    if (us_mondayDateKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_FinalizedPayroll_UnitPayTasks_Collection(res_GCK.clientKey, us_mondayDateKey),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_mondayDateKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setFinalizedWeekPayroll_DistanceAdderUserData(newData)
      ur_forceRerender()
    }
    if (us_mondayDateKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_FinalizedPayroll_DistanceAdderUserData_Collection(res_GCK.clientKey, us_mondayDateKey),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_mondayDateKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setLifetimeReferralsData(newData)
      ur_forceRerender()
    }
    if (us_mondayDateKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_LifetimeReferrals_Document(res_GCK.clientKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_mondayDateKey])

  useEffect(() => {
    // Merging Multiple Data Sources Into One
    let copyOfBaseCustomMappingData = JSON.parse(JSON.stringify(us_finalizedWeekPayroll_BaseCustomMappingData))
    copyOfBaseCustomMappingData['cached_efficiency_clock_hour_data'] = us_finalizedWeekPayroll_CachedEfficiencyClockHourData
    copyOfBaseCustomMappingData['cached_project_data'] = us_finalizedWeekPayroll_CachedProjectData
    copyOfBaseCustomMappingData['cached_cancelled_task_data'] = us_finalizedWeekPayroll_CachedCancelledTasksData
    us_setFinalizedWeekPayroll_CustomMappingData(copyOfBaseCustomMappingData)
  }, [
    us_finalizedWeekPayroll_BaseCustomMappingData,
    us_finalizedWeekPayroll_CachedCancelledTasksData,
    us_finalizedWeekPayroll_CachedEfficiencyClockHourData,
    us_finalizedWeekPayroll_CachedProjectData,
  ])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      // us_setFinalizedWeekPayroll_CustomMappingData( newData )
      us_setFinalizedWeekPayroll_BaseCustomMappingData(newData)
      ur_forceRerender()
    }
    if (us_mondayDateKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_FinalizedPayroll_CustomMappingData_Collection(res_GCK.clientKey, us_mondayDateKey),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_mondayDateKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setFinalizedWeekPayroll_CachedEfficiencyClockHourData(newData)
      ur_forceRerender()
    }
    if (us_mondayDateKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_FinalizedPayroll_CachedEfficiencyClockHourData_Collection(res_GCK.clientKey, us_mondayDateKey),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_mondayDateKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setFinalizedWeekPayroll_CachedProjectData(newData)
      ur_forceRerender()
    }
    if (us_mondayDateKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_FinalizedPayroll_CachedProjectData_Collection(res_GCK.clientKey, us_mondayDateKey),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_mondayDateKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setFinalizedWeekPayroll_CachedCancelledTasksData(newData)
      ur_forceRerender()
    }
    if (us_mondayDateKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_FinalizedPayroll_CachedCancelledTasksData_Collection(res_GCK.clientKey, us_mondayDateKey),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_mondayDateKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setPayrollRatesData(newData)
      ur_forceRerender()
    }
    if (us_mondayDateKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_PayrollRates_Collection(res_GCK.clientKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_mondayDateKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setWeekCompletedTasks(newData)
      us_setLoadedWeekCompletedTasks(true)
      ur_forceRerender()
    }
    if (us_weekStartDate != null && us_weekEndDate != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ScheduledTasksByCompletionDate_Query(res_GCK.clientKey, us_weekStartDate, us_weekEndDate),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_weekEndDate, us_weekStartDate])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setWeekCompletedPayrollHourTasks(newData)
      us_setLoadedWeekCompletedPayrollHourTasks(true)
      ur_forceRerender()
    }
    if (us_weekStartDate != null && us_weekEndDate != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_TasksCachedByPayrollHours_Query(res_GCK.clientKey, us_weekStartDate, us_weekEndDate),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_weekEndDate, us_weekStartDate])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setTasksWithQAReviews(newData)
      ur_forceRerender()
    }
    if (us_weekStartDate != null && us_weekEndDate != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_QAReviewedTasksByDateRange_Query(res_GCK.clientKey, us_weekStartDate, us_weekEndDate),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_weekEndDate, us_weekStartDate])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setMinimumWageValues(newData)
      ur_forceRerender()
    }
    if (us_weekStartDate != null && us_weekEndDate != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_PayrollRates_MinimumWage_Document(res_GCK.clientKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_weekEndDate, us_weekStartDate])

  useEffect(() => {
    if (us_weekStartDate != null && us_weekEndDate != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          returnAggregatePunchDataForPayrollCalculations(
            res_GCK.clientKey,
            us_weekStartDate,
            us_weekEndDate,
            // downloadAggregatedPunchData(
            // 	res_GCK.clientKey,
            // 	us_weekStartDate,
            // 	us_weekEndDate,
            // 	true,
            // 	uc_setUserInterface_CustomDialogDisplay,
            // 	"payroll_json"
          )
            .then((res_DAPD) => {
              // @ts-expect-error - TODO: reason for error
              us_setAggregatedWeekPunchData(res_DAPD)
              let userKeysArray: string[] = []
              for (let loopUserKey in getProp(res_DAPD, 'data', {})) {
                // @ts-expect-error - TODO: reason for error
                let loopUser = res_DAPD.data[loopUserKey]
                // Only process hourly employees
                if (getProp(loopUser, 'hourly_or_salaried', null) === 'hourly') {
                  userKeysArray.push(loopUserKey)
                }
              }
              us_setUserKeysArray(userKeysArray.sort())
            })
            .catch((rej_DAPD) => {
              console.error(rej_DAPD)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, uc_setUserInterface_CustomDialogDisplay, us_weekEndDate, us_weekStartDate])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setSelectedWeekCombineLogs(newData)
      ur_forceRerender()
    }
    if (us_mondayDateKeyNumber != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_CombineLogsForSpecificDateKey_Query(res_GCK.clientKey, us_mondayDateKeyNumber),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_mondayDateKeyNumber])

  useEffect(() => {
    if (us_mondayDateKeyNumber != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let previousCombineLogs: TsInterface_UnspecifiedObject = {}
          let promiseArray: TsType_UnknownPromise[] = []
          for (let loopUserIndex in us_userKeysArray) {
            let loopUserKey = us_userKeysArray[loopUserIndex]
            promiseArray.push(
              DatabaseGetCollection(DatabaseRef_PreviousUserCombineLogs_Query(res_GCK.clientKey, loopUserKey, us_mondayDateKeyNumber))
                .then((res_DGLC) => {
                  previousCombineLogs[loopUserKey] = objectToArray(res_DGLC.data)[0]
                })
                .catch((rej_DGLC) => {
                  console.error(rej_DGLC)
                }),
            )
          }
          Promise.all(promiseArray).then(() => {
            us_setPreviousUserCombineLogs(previousCombineLogs)
          })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_mondayDateKey])

  // Generate Calculated Payroll Data
  useEffect(() => {
    if (getProp(us_finalizedWeekPayrollRoot, 'locked', false) === true) {
      // IF locked - Use Locked Database Data to render info on page
      us_setDisplayedWeekPayrollData(us_finalizedWeekPayroll_CalculatedData)
      us_setDisplayedWeekLeaderboardData(us_finalizedWeekLeaderboardSavedData)
      us_setLeaderboardReferralData({})
    } else {
      // If Unlocked - Use Live Data to render info on page - below
    }
  }, [us_finalizedWeekLeaderboardSavedData, us_finalizedWeekPayrollRoot, us_finalizedWeekPayroll_CalculatedData])

  useEffect(() => {
    if (getProp(us_finalizedWeekPayrollRoot, 'locked', false) === true) {
      // IF Locked - Use Live Data to highlight discrepancies
      let calculatedLiveWeekData = returnPayrollCalculatedData(
        us_mondayDateKey,
        us_weekStartDate,
        us_weekEndDate,
        us_finalizedWeekPayroll_CustomMappingData,
        us_aggregatedWeekPunchData,
        us_selectedWeekCombineLogs,
        us_previousUserCombineLogs,
        us_weekCompletedTasks,
        us_weekCompletedPayrollHourTasks,
        us_payrollRatesData,
        us_minimumWageValues,
        us_finalizedWeekPayroll_ErrorAndWarningResolutionData,
        us_weekPayrollAdjustments,
        us_tasksWithQAReviews,
        us_lifetimeReferralsData,
        us_finalizedWeekPayroll_UploadedPTOFile,
      )
      us_setDiscrepancyWeekPayrollData(calculatedLiveWeekData['payroll_data'])
      us_setTasksMissingCachedProjects({})
      us_setEmployeeClockedTaskHours(calculatedLiveWeekData['employee_clocked_task_hours'])
    } else {
      // If Unlocked - Use Live Data to render info on page
      let calculatedLiveWeekData = returnPayrollCalculatedData(
        us_mondayDateKey,
        us_weekStartDate,
        us_weekEndDate,
        us_finalizedWeekPayroll_CustomMappingData,
        us_aggregatedWeekPunchData,
        us_selectedWeekCombineLogs,
        us_previousUserCombineLogs,
        us_weekCompletedTasks,
        us_weekCompletedPayrollHourTasks,
        us_payrollRatesData,
        us_minimumWageValues,
        us_finalizedWeekPayroll_ErrorAndWarningResolutionData,
        us_weekPayrollAdjustments,
        us_tasksWithQAReviews,
        us_lifetimeReferralsData,
        us_finalizedWeekPayroll_UploadedPTOFile,
      )
      us_setDisplayedWeekLeaderboardData(calculatedLiveWeekData['leaderboard_data'])
      us_setLeaderboardReferralData(calculatedLiveWeekData['leaderboard_referral_data'])
      us_setDisplayedWeekPayrollData(calculatedLiveWeekData['payroll_data'])
      us_setTasksMissingCachedProjects(calculatedLiveWeekData['tasks_missing_cached_projects'])
      us_setEmployeeClockedTaskHours(calculatedLiveWeekData['employee_clocked_task_hours'])
    }
  }, [
    us_aggregatedWeekPunchData,
    us_finalizedWeekPayroll_CustomMappingData,
    us_finalizedWeekPayrollRoot,
    us_minimumWageValues,
    us_mondayDateKey,
    us_payrollRatesData,
    us_previousUserCombineLogs,
    us_selectedDate,
    us_selectedWeekCombineLogs,
    us_weekEndDate,
    us_weekCompletedTasks,
    us_weekStartDate,
    us_finalizedWeekPayroll_ErrorAndWarningResolutionData,
    us_weekPayrollAdjustments,
    us_tasksWithQAReviews,
    us_lifetimeReferralsData,
    us_weekCompletedPayrollHourTasks,
    us_finalizedWeekPayroll_UploadedPTOFile,
  ])

  useEffect(() => {
    let tableSettings_PayrollSummary: TsInterface_TableSettings = {
      paginated: false,
      show_header: true,
      size: 'small',
      sort_direction: 'asc',
      sort_property_default: 'name',
      sortable: true,
      collapsible_columns: true,
      sticky_header: true,
      alternate_row_colors: true,
      alternate_row_color_hex: themeVariables.background_hover,
      sticky_first_column: true,
      table_file_download_name: 'payroll_summary',
    }
    let tableSettings_UnitPayBreakdown: TsInterface_TableSettings = {
      paginated: false,
      show_header: true,
      size: 'small',
      sort_direction: 'asc',
      sort_property_default: 'associated_project_id_number',
      sortable: true,
      collapsible_columns: true,
      sticky_header: true,
      alternate_row_colors: true,
      alternate_row_color_hex: themeVariables.background_hover,
      table_file_download_name: 'unit_pay_breakdown',
    }
    // let tableSettings_DistanceAdders: TsInterface_TableSettings = {
    //   paginated: false,
    //   show_header: true,
    //   size: 'small',
    //   sort_direction: 'asc',
    //   sort_property_default: 'name',
    //   sortable: true,
    //   collapsible_columns: true,
    //   sticky_header: true,
    //   alternate_row_colors: true,
    //   alternate_row_color_hex: themeVariables.background_hover,
    // }
    // if (umq_isSummarySmallestStickyTableBreakpoint === true) {
    //   tableSettings_PayrollSummary['sticky_table_height'] = 'calc(100vh - 400px)'
    //   tableSettings_UnitPayBreakdown['sticky_table_height'] = 'calc(100vh - 300px)'
    //   tableSettings_DistanceAdders['sticky_table_height'] = 'calc(100vh - 300px)'
    // } else if (umq_isSummaryMediumStickyTableBreakpoint === true) {
    //   tableSettings_PayrollSummary['sticky_table_height'] = 'calc(100vh - 360px)'
    //   tableSettings_UnitPayBreakdown['sticky_table_height'] = 'calc(100vh - 260px)'
    //   tableSettings_DistanceAdders['sticky_table_height'] = 'calc(100vh - 260px)'
    // } else {
    tableSettings_PayrollSummary['sticky_table_height'] = 'calc(100vh - 180px)'
    tableSettings_UnitPayBreakdown['sticky_table_height'] = 'calc(100vh - 180px)'
    // tableSettings_DistanceAdders['sticky_table_height'] = 'calc(100vh - 180px)'
    // }
    us_setTableSettings_PayrollSummary(tableSettings_PayrollSummary)
    us_setTableSettings_UnitPayBreakdown(tableSettings_UnitPayBreakdown)
    // us_setTableSettings_DistanceAdders(tableSettings_DistanceAdders)
  }, [umq_isSummaryMediumStickyTableBreakpoint, umq_isSummarySmallestStickyTableBreakpoint])

  useEffect(() => {
    let totals: TsInterface_UnspecifiedObject = {}
    if (us_displayedWeekPayrollData != null && us_displayedWeekPayrollData['employee_full_payroll_data'] != null) {
      for (let loopEmployeeKey in us_displayedWeekPayrollData['employee_full_payroll_data']) {
        if (
          us_usersExcludedFromPayrollTotals != null &&
          us_usersExcludedFromPayrollTotals[loopEmployeeKey] != null &&
          us_usersExcludedFromPayrollTotals[loopEmployeeKey]['exclude_from_payroll_totals'] === true
        ) {
          continue
        }

        let loopEmployeeData = us_displayedWeekPayrollData['employee_full_payroll_data'][loopEmployeeKey]
        for (let loopPayrollItemKey in loopEmployeeData) {
          let loopPayrollItemValue = loopEmployeeData[loopPayrollItemKey]
          if (loopPayrollItemValue != null && loopPayrollItemValue !== '' && !isNaN(loopPayrollItemValue)) {
            if (totals[loopPayrollItemKey] == null) {
              totals[loopPayrollItemKey] = 0
            }
            totals[loopPayrollItemKey] += loopPayrollItemValue
          }
        }
      }
    }
    us_setEmployeePayrollDataTotals(totals)
    let discrepancyTotals: TsInterface_UnspecifiedObject = {}
    if (us_discrepancyWeekPayrollData != null && us_discrepancyWeekPayrollData['employee_full_payroll_data'] != null) {
      for (let loopEmployeeKey in us_discrepancyWeekPayrollData['employee_full_payroll_data']) {
        if (
          us_usersExcludedFromPayrollTotals != null &&
          us_usersExcludedFromPayrollTotals[loopEmployeeKey] != null &&
          us_usersExcludedFromPayrollTotals[loopEmployeeKey]['exclude_from_payroll_totals'] === true
        ) {
          continue
        }

        let loopEmployeeData = us_discrepancyWeekPayrollData['employee_full_payroll_data'][loopEmployeeKey]
        for (let loopPayrollItemKey in loopEmployeeData) {
          let loopPayrollItemValue = loopEmployeeData[loopPayrollItemKey]
          if (loopPayrollItemValue != null && loopPayrollItemValue !== '' && !isNaN(loopPayrollItemValue)) {
            if (discrepancyTotals[loopPayrollItemKey] == null) {
              discrepancyTotals[loopPayrollItemKey] = 0
            }
            discrepancyTotals[loopPayrollItemKey] += loopPayrollItemValue
          }
        }
      }
    }
    us_setEmployeePayrollDiscrepancyTotals(discrepancyTotals)
  }, [us_displayedWeekPayrollData, us_usersExcludedFromPayrollTotals, us_discrepancyWeekPayrollData])

  useEffect(() => {
    // Generate Distance Adder Table Data and Columns
    let distanceAdderTableColumns: TsInterface_TableColumns = {
      name: TableCellBasic('name', rLIB('Employee'), 'name'),
    }
    for (let loopDateKey in getProp(us_displayedWeekPayrollData, 'scheduled_dates_included_in_payroll', {})) {
      distanceAdderTableColumns[loopDateKey] = {
        header: {
          header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
            let headerJSX = <Box className="tw-inline-block tw-mr-1">{loopDateKey}</Box>
            return headerJSX
          },
          header_sort_by: null,
          header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
            return ''
          },
        },
        cell: {
          cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
            let cellJSX = <></>
            const rJSX_DistanceAdderTask = (taskData: TsInterface_UnspecifiedObject): JSX.Element => {
              let taskJSX = <></>
              let iconJSX = <></>
              let textCSS = ''
              if (getProp(taskData, 'selected_distance_adder_task', {}) === true) {
                iconJSX = (
                  <Icon
                    icon="star"
                    sx={{ color: themeVariables.warning_main }}
                    className="tw-mr-2"
                  />
                )
              } else {
                iconJSX = (
                  <Icon
                    icon="do-not-enter"
                    sx={{ color: themeVariables.white }}
                    className="tw-mr-2"
                  />
                )
                textCSS = 'tw-opacity-30'
              }
              taskJSX = (
                <Box className={textCSS}>
                  {iconJSX}
                  <Tooltip
                    title={
                      <Box sx={{ fontSize: '16px' }}>
                        <Box>{getProp(taskData, 'associated_project_id_number', '')}</Box>
                        <Box>{getProp(taskData, 'name', '')}</Box>
                        <Box>{getProp(taskData, 'associated_team_name', '')}</Box>
                        <Box>
                          {formatCurrency(getProp(taskData, 'possible_distance_pay', 0))} - {getProp(taskData, 'possible_distance_units', '')} {rLIB('Units')}
                        </Box>
                      </Box>
                    }
                  >
                    <Box className="tw-inline-block">
                      {getProp(taskData, 'associated_project_id_number', '')} - {formatCurrency(getProp(taskData, 'possible_distance_pay', 0))}
                    </Box>
                  </Tooltip>
                </Box>
              )
              return taskJSX
            }
            if (
              rowData != null &&
              rowData['dates'] != null &&
              getProp(rowData['dates'], [loopDateKey], null) != null &&
              objectToArray(getProp(rowData['dates'], loopDateKey, {})).length > 0
            ) {
              let distanceAdderTasksForDay = getProp(rowData['dates'], loopDateKey, {})
              cellJSX = (
                <Box>
                  {objectToArray(distanceAdderTasksForDay)
                    .sort(dynamicSort('possible_distance_pay', 'desc'))
                    .map((taskData: TsInterface_UnspecifiedObject, index: number) => (
                      <Box key={index}>{rJSX_DistanceAdderTask(taskData)}</Box>
                    ))}
                </Box>
              )
            }
            return cellJSX
          },
          cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
            let cellCSS = ''
            return cellCSS
          },
        },
      }
    }
    // Set to State
    // us_setTableColumns_DistanceAdders(distanceAdderTableColumns)
    // us_setTableData_DistanceAdders(getProp(us_displayedWeekPayrollData, 'distance_adder_user_data', {}))
  }, [us_displayedWeekPayrollData])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setTasksRequiringPartnerApproval(newData)
      ur_forceRerender()
    }
    if (us_activeTab === 'Tasks_Awaiting_Approval') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_TaskRequiringPartnerApproval_Query(res_GCK.clientKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setTasksRequiringPartnerApproval({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_activeTab])

  useEffect(() => {
    // Handle Current Displayed Week Payroll and Outstanding Adjustments to determine theoretical max auto adjustments
    // Find all adjustments that have not been applied this week IF the user is included in the payroll data
    let warningCount = 0
    let errorCount = 0
    let lockDownloadsDueToNegativeBonuses = false
    let usersWithNegativeBonuses: TsInterface_UnspecifiedObject = {}
    let usersWithAdjustmentsButNoPayroll: TsInterface_UnspecifiedObject = {}
    for (let loopAdjustmentKey in us_combinedPayrollAdjustments) {
      let loopAdjustment = us_combinedPayrollAdjustments[loopAdjustmentKey]
      // Users with Adjustments but not in Payroll Data
      if (
        loopAdjustment != null &&
        loopAdjustment['associated_user_key'] != null &&
        us_displayedWeekPayrollData != null &&
        getProp(us_displayedWeekPayrollData, ['employee_full_payroll_data', loopAdjustment['associated_user_key']], null) == null &&
        loopAdjustment.associated_payroll_week_adjustment_amounts != null &&
        us_mondayDateKey != null &&
        getProp(loopAdjustment.associated_payroll_week_adjustment_amounts, us_mondayDateKey, null) != null &&
        getProp(loopAdjustment.associated_payroll_week_adjustment_amounts, us_mondayDateKey, null) > 0
      ) {
        usersWithAdjustmentsButNoPayroll[loopAdjustment['associated_user_key']] = true
        errorCount++
        lockDownloadsDueToNegativeBonuses = true
        // Adjustments that have not been applied this week
      } else if (
        us_mondayDateKey != null &&
        loopAdjustment != null &&
        loopAdjustment['associated_user_key'] != null &&
        (loopAdjustment['associated_payroll_weeks'] == null || loopAdjustment['associated_payroll_weeks'][us_mondayDateKey] != true) &&
        us_displayedWeekPayrollData != null &&
        us_displayedWeekPayrollData['employee_full_payroll_data'] != null &&
        us_displayedWeekPayrollData['employee_full_payroll_data'][loopAdjustment['associated_user_key']] != null
      ) {
        warningCount++
      }
    }
    // Users with Negative Bonuses
    for (let loopUserKey in getProp(us_displayedWeekPayrollData, 'employee_full_payroll_data', {})) {
      let loopUser = getProp(us_displayedWeekPayrollData, ['employee_full_payroll_data', loopUserKey], {})
      if (loopUser != null && loopUser.final_bonus_pay < 0) {
        errorCount++
        lockDownloadsDueToNegativeBonuses = true
        usersWithNegativeBonuses[loopUserKey] = true
      }
    }
    // Set Everything to State
    us_setAdjustmentWarningCount(warningCount)
    us_setAdjustmentErrorCount(errorCount)
    us_setUsersWithAdjustmentsButNoPayroll(usersWithAdjustmentsButNoPayroll)
    us_setLockDownloadsDueToNegativeBonuses(lockDownloadsDueToNegativeBonuses)
    us_setUsersWithNegativeBonuses(usersWithNegativeBonuses)
  }, [us_mondayDateKey, us_displayedWeekPayrollData, us_combinedPayrollAdjustments])

  // Add Task Completion Date to Payroll Hour Task Cache
  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        if (
          res_GCK.clientKey != null &&
          us_loadedWeekCompletedTasks === true &&
          us_loadedWeekCompletedPayrollHourTasks === true &&
          us_cachedTaskPayrollCompletionDate === false
        ) {
          // Set Caching to true so that we don't run this again - changing date on index will reset this
          us_setCachedTaskPayrollCompletionDate(true)
          // Instantiate update array
          let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
          // Loop through Tasks and build update array
          for (let loopTaskKey in us_weekCompletedTasks) {
            let loopTask: TsInterface_UnspecifiedObject = us_weekCompletedTasks[loopTaskKey]
            if (loopTask.timestamp_completed != null) {
              if (getProp(us_weekCompletedPayrollHourTasks, loopTaskKey, null) == null) {
                // Cached Payroll Task Hours missing completion date
                updateArray.push({
                  type: 'setMerge',
                  ref: DatabaseRef_TaskCachedPayrollHours_Document(res_GCK.clientKey, loopTaskKey),
                  data: {
                    timestamp_completed: returnDateFromUnknownDateFormat(loopTask.timestamp_completed),
                  },
                })
              } else if (
                us_weekCompletedPayrollHourTasks != null &&
                us_weekCompletedPayrollHourTasks[loopTaskKey] != null &&
                us_weekCompletedPayrollHourTasks[loopTaskKey].timestamp_completed != null &&
                returnDateFromUnknownDateFormat(us_weekCompletedPayrollHourTasks[loopTaskKey].timestamp_completed).getTime() !=
                  returnDateFromUnknownDateFormat(loopTask.timestamp_completed).getTime()
              ) {
                // Cached Payroll Task Hours has a different completion date
                updateArray.push({
                  type: 'setMerge',
                  ref: DatabaseRef_TaskCachedPayrollHours_Document(res_GCK.clientKey, loopTaskKey),
                  data: {
                    timestamp_completed: returnDateFromUnknownDateFormat(loopTask.timestamp_completed),
                  },
                })
              }
            }
          }
          // If there is an update that needs to be made, make it
          if (updateArray.length > 0) {
            DatabaseStagedBatchUpdate(updateArray)
              .then((res_DBU) => {
                console.log('Task Completion Update Success')
              })
              .catch((rej_DBU) => {
                console.error(rej_DBU)
              })
          } else {
            // Nothing
            // console.log('No updates needed')
          }
        } else {
          // console.log('Update Not Performed')
        }
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [
    us_loadedWeekCompletedPayrollHourTasks,
    uc_RootData_ClientKey,
    uc_setRootData_ClientKey,
    us_cachedTaskPayrollCompletionDate,
    us_loadedWeekCompletedTasks,
    us_weekCompletedPayrollHourTasks,
    us_weekCompletedTasks,
  ])

  useEffect(() => {
    // Loop through and get list of employees for unit pay tasks
    let unitPayTaskEmployeeList: TsInterface_UnspecifiedObject = {}
    for (let loopTaskKey in getProp(us_displayedWeekPayrollData, 'unit_pay_tasks', {})) {
      let loopTask = getProp(us_displayedWeekPayrollData, ['unit_pay_tasks', loopTaskKey], {})
      if (loopTask != null && loopTask['unit_pay_totals'] != null) {
        for (let loopEmployeeKey in loopTask['unit_pay_totals']) {
          let loopEmployee = loopTask['unit_pay_totals'][loopEmployeeKey]
          if (loopEmployee != null && loopEmployee['name'] != null && loopEmployee['key'] != null) {
            unitPayTaskEmployeeList[loopEmployee['key']] = {
              name: loopEmployee['name'],
              key: loopEmployee['key'],
            }
          }
        }
      }
    }
    us_setUnitPayTaskEmployeeList(unitPayTaskEmployeeList)
  }, [us_displayedWeekPayrollData])

  useEffect(() => {
    // Loop through and get list of employees for unit pay tasks
    if (us_filterUnitPayDropdownValue === 'ALL') {
      us_setFilteredUnitPayTaskList(getProp(us_displayedWeekPayrollData, 'unit_pay_tasks', {}))
    } else {
      let filteredUnitPayTaskList: TsInterface_UnspecifiedObject = {}
      for (let loopTaskKey in getProp(us_displayedWeekPayrollData, 'unit_pay_tasks', {})) {
        let loopTask = getProp(us_displayedWeekPayrollData, ['unit_pay_tasks', loopTaskKey], {})
        if (loopTask != null && loopTask['unit_pay_totals'] != null && loopTask['unit_pay_totals'][us_filterUnitPayDropdownValue] != null) {
          filteredUnitPayTaskList[loopTaskKey] = loopTask
        }
      }
      us_setFilteredUnitPayTaskList(filteredUnitPayTaskList)
    }
  }, [us_displayedWeekPayrollData, us_filterUnitPayDropdownValue])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setFinalizedWeekPayroll_UploadedPTOFile(newData)
      ur_forceRerender()
    }
    if (us_mondayDateKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(
            DatabaseRef_FinalizedPayroll_UploadedPTOFile_Document(res_GCK.clientKey, us_mondayDateKey),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_mondayDateKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setUnresolvedPayrollAdjustmentSuggestions(newData)
      ur_forceRerender()
    }
    if (us_mondayDateKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_UnresolvedPayrollAdjustmentSuggestions_Query(res_GCK.clientKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_mondayDateKey])

  // Functions
  const tableDatabaseEndpoint_PayrollLogs = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_created', desc: true }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 5)
    return generateDatabaseQuery(
      DatabaseRef_PayrollLogs_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const changeCalendarDate = (newDate: Date): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      // let formattedDateKey = returnFormattedDateKey( new Date( newDate ) )
      us_setSelectedDate(new Date(newDate))
      us_setCachedTaskPayrollCompletionDate(false)
      us_setLoadedWeekCompletedTasks(false)
      us_setLoadedWeekCompletedPayrollHourTasks(false)
      ur_forceRerender()
      resolve({ success: true })
    })
  }

  const downloadCombinedReport = (clientKey: string, showZeroHourRows: boolean): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      let promiseArray: TsType_UnknownPromise[] = []
      let internalUsers: TsInterface_UnspecifiedObject = {}
      let csvFileData: (string | number)[][] = [
        [
          'Insperity Person ID',
          'WageType',
          'BeginPayPeriodDate',
          'EndPayPeriodDate',
          'PayCode',
          'Duration',
          'Wage',
          'Total',
          'PROJECT CODE',
          'Labor Level 2',
          'Labor Level 3',
          'Insperity Company ID',
          'Name',
        ],
      ]
      let csvRowData: (string | number)[] = []
      promiseArray.push(
        DatabaseGetCollection(DatabaseRef_InternalUsers_Query(clientKey))
          .then((res_DGC) => {
            // internalUsers = res_DGC.data
            for (let loopUserKey in res_DGC.data) {
              if (res_DGC.data[loopUserKey]['status'] !== 'deleted') {
                internalUsers[loopUserKey] = res_DGC.data[loopUserKey]
              }
            }
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
          }),
      )
      Promise.all(promiseArray).finally(() => {
        // Loop through user data and add hourly users to the CSV
        let alphabetizedUsers = objectToArray(getProp(us_displayedWeekPayrollData, 'employee_full_payroll_data', {})).sort(dynamicSort('name', 'asc'))
        for (let loopUserIndex in alphabetizedUsers) {
          let loopUser = alphabetizedUsers[loopUserIndex]
          let matchingActiveUser = getProp(internalUsers, loopUser.key, null)
          if (matchingActiveUser != null && matchingActiveUser['hourly_or_salaried'] === 'hourly') {
            // if (loopUser.key != 'undefined' && matchingActiveUser.id_number_payroll != null && matchingActiveUser.id_number_payroll != '') {
            // if (loopUser.key != 'undefined') {
            let hourlyOrSalariedAbbreviation = getProp(matchingActiveUser, 'hourly_or_salaried', '')
            if (
              hourlyOrSalariedOptions != null &&
              hourlyOrSalariedOptions[hourlyOrSalariedAbbreviation] != null &&
              hourlyOrSalariedOptions[hourlyOrSalariedAbbreviation]['abbreviation'] != null
            ) {
              hourlyOrSalariedAbbreviation = hourlyOrSalariedOptions[hourlyOrSalariedAbbreviation]['abbreviation']
            }

            // Constant Variables
            const payrollIdNumber = getProp(matchingActiveUser, 'id_number_payroll', '')
            const formattedStartDate = returnFormattedDate(us_weekStartDate, 'YYYY-MM-DD')
            const formattedEndDate = returnFormattedDate(us_weekEndDate, 'YYYY-MM-DD')
            const organizationPayrollId = getProp(matchingActiveUser, 'id_organization_payroll', '')

            // Hours and Payment Variables
            const zero = 0
            let regHours = getProp(loopUser, 'work_reg_hours', 0).toFixed(6)
            let regPay = formatCurrency(getProp(loopUser, 'reg_pay', 0))
            let ovtHours = getProp(loopUser, 'work_ovt_hours', 0).toFixed(6)
            let ovtPay = formatCurrency(getProp(loopUser, 'overtime_pay', 0))
            let dtHours = getProp(loopUser, 'work_double_ovt_hours', 0).toFixed(6)
            let dtPay = formatCurrency(getProp(loopUser, 'double_overtime_pay', 0))
            let prHours = zero.toFixed(6)
            let prPay = formatCurrency(zero)
            let prOvtHours = zero.toFixed(6)
            let prOvtPay = formatCurrency(zero)
            let prDtHours = zero.toFixed(6)
            let upbPay = formatCurrency(zero)
            let unitPayBonusCommission = getProp(loopUser, 'final_bonus_pay', 0).toFixed(6)
            let bonusPay = getProp(loopUser, 'hardcoded_bonus', 0).toFixed(6)
            let ovtPremium = getProp(loopUser, 'ovt_premium', 0).toFixed(6)
            let baseWage = getProp(loopUser, 'base_wage', 0)
            let effectiveWage = getProp(loopUser, 'effective_wage', 0)
            if (getProp(loopUser, 'employee_in_combine', false) === true) {
              regHours = getProp(loopUser, 'admin_reg_hours', 0).toFixed(6)
              regPay = formatCurrency(getProp(loopUser, 'admin_reg_pay', 0))
              ovtHours = getProp(loopUser, 'admin_ovt_hours', 0).toFixed(6)
              ovtPay = formatCurrency(getProp(loopUser, 'admin_ovt_pay', 0))
              dtHours = getProp(loopUser, 'admin_double_ovt_hours', 0).toFixed(6)
              dtPay = formatCurrency(getProp(loopUser, 'admin_double_ovt_pay', 0))
              prHours = getProp(loopUser, 'field_reg_hours', 0).toFixed(6)
              prPay = formatCurrency(getProp(loopUser, 'field_reg_pay', 0))
              prOvtHours = getProp(loopUser, 'field_ovt_hours', 0).toFixed(6)
              prOvtPay = formatCurrency(getProp(loopUser, 'field_ovt_pay', 0))
              prDtHours = getProp(loopUser, 'field_double_ovt_hours', 0).toFixed(6)
              upbPay = formatCurrency(getProp(loopUser, 'field_upb_pay', 0))
            }

            // REGULAR HOURS
            if (showZeroHourRows === true || parseFloat(regHours) > 0) {
              csvRowData = []
              csvRowData.push(payrollIdNumber)
              csvRowData.push(hourlyOrSalariedAbbreviation)
              csvRowData.push(formattedStartDate)
              csvRowData.push(formattedEndDate)
              csvRowData.push('REG')
              csvRowData.push(regHours)
              csvRowData.push(formatCurrency(baseWage))
              csvRowData.push(regPay)
              csvRowData.push('')
              csvRowData.push('')
              csvRowData.push('')
              csvRowData.push(organizationPayrollId)
              csvRowData.push(getProp(loopUser, 'name', ''))
              csvFileData.push(csvRowData)
            }
            // OVERTIME HOURS
            if (showZeroHourRows === true || parseFloat(ovtHours) > 0) {
              csvRowData = []
              csvRowData.push(payrollIdNumber)
              csvRowData.push(hourlyOrSalariedAbbreviation)
              csvRowData.push(formattedStartDate)
              csvRowData.push(formattedEndDate)
              csvRowData.push('OVT')
              csvRowData.push(ovtHours)
              csvRowData.push(formatCurrency(baseWage * 1.5))
              csvRowData.push(ovtPay)
              csvRowData.push('')
              csvRowData.push('')
              csvRowData.push('')
              csvRowData.push(organizationPayrollId)
              csvRowData.push(getProp(loopUser, 'name', ''))
              csvFileData.push(csvRowData)
            }
            // DOUBLE OVERTIME HOURS
            if (showZeroHourRows === true || parseFloat(dtHours) > 0) {
              csvRowData = []
              csvRowData.push(payrollIdNumber)
              csvRowData.push(hourlyOrSalariedAbbreviation)
              csvRowData.push(formattedStartDate)
              csvRowData.push(formattedEndDate)
              csvRowData.push('DT')
              csvRowData.push(dtHours)
              csvRowData.push(formatCurrency(baseWage * 2))
              csvRowData.push(dtPay)
              csvRowData.push('')
              csvRowData.push('')
              csvRowData.push('')
              csvRowData.push(organizationPayrollId)
              csvRowData.push(getProp(loopUser, 'name', ''))
              csvFileData.push(csvRowData)
            }
            // PIECE RATE REG
            if (showZeroHourRows === true || parseFloat(prHours) > 0) {
              csvRowData = []
              csvRowData.push(payrollIdNumber)
              csvRowData.push(hourlyOrSalariedAbbreviation)
              csvRowData.push(formattedStartDate)
              csvRowData.push(formattedEndDate)
              csvRowData.push('PR')
              csvRowData.push(prHours)
              csvRowData.push(formatCurrency(effectiveWage))
              csvRowData.push(prPay)
              csvRowData.push('')
              csvRowData.push('')
              csvRowData.push('')
              csvRowData.push(organizationPayrollId)
              csvRowData.push(getProp(loopUser, 'name', ''))
              csvFileData.push(csvRowData)
            }
            // PIECE RATE OVT
            if (showZeroHourRows === true || parseFloat(prOvtHours) > 0) {
              csvRowData = []
              csvRowData.push(payrollIdNumber)
              csvRowData.push(hourlyOrSalariedAbbreviation)
              csvRowData.push(formattedStartDate)
              csvRowData.push(formattedEndDate)
              csvRowData.push('PR-OVT')
              csvRowData.push(prOvtHours)
              csvRowData.push(formatCurrency(effectiveWage * 1.5))
              csvRowData.push(prOvtPay)
              csvRowData.push('')
              csvRowData.push('')
              csvRowData.push('')
              csvRowData.push(organizationPayrollId)
              csvRowData.push(getProp(loopUser, 'name', ''))
              csvFileData.push(csvRowData)
            }
            // PIECE RATE DOUBLE OVT
            if (showZeroHourRows === true || parseFloat(prDtHours) > 0) {
              csvRowData = []
              csvRowData.push(payrollIdNumber)
              csvRowData.push(hourlyOrSalariedAbbreviation)
              csvRowData.push(formattedStartDate)
              csvRowData.push(formattedEndDate)
              csvRowData.push('PR-DT')
              csvRowData.push(prHours)
              csvRowData.push(formatCurrency(effectiveWage * 2))
              csvRowData.push(upbPay)
              csvRowData.push('')
              csvRowData.push('')
              csvRowData.push('')
              csvRowData.push(organizationPayrollId)
              csvRowData.push(getProp(loopUser, 'name', ''))
              csvFileData.push(csvRowData)
            }
            // COMMISSION (UPB)
            if (showZeroHourRows === true || parseFloat(unitPayBonusCommission) > 0) {
              csvRowData = []
              csvRowData.push(payrollIdNumber)
              csvRowData.push(hourlyOrSalariedAbbreviation)
              csvRowData.push(formattedStartDate)
              csvRowData.push(formattedEndDate)
              csvRowData.push('COM')
              csvRowData.push(unitPayBonusCommission)
              csvRowData.push(formatCurrency(1))
              csvRowData.push(formatCurrency(unitPayBonusCommission))
              csvRowData.push('')
              csvRowData.push('')
              csvRowData.push('')
              csvRowData.push(organizationPayrollId)
              csvRowData.push(getProp(loopUser, 'name', ''))
              csvFileData.push(csvRowData)
            }
            // BONUS (1 Off)
            if (showZeroHourRows === true || parseFloat(bonusPay) > 0) {
              csvRowData = []
              csvRowData.push(payrollIdNumber)
              csvRowData.push(hourlyOrSalariedAbbreviation)
              csvRowData.push(formattedStartDate)
              csvRowData.push(formattedEndDate)
              csvRowData.push('BON')
              csvRowData.push(bonusPay)
              csvRowData.push(formatCurrency(1))
              csvRowData.push(formatCurrency(bonusPay))
              csvRowData.push('')
              csvRowData.push('')
              csvRowData.push('')
              csvRowData.push(organizationPayrollId)
              csvRowData.push(getProp(loopUser, 'name', ''))
              csvFileData.push(csvRowData)
            }
            // OVT PREMIUM
            if (showZeroHourRows === true || parseFloat(ovtPremium) > 0) {
              csvRowData = []
              csvRowData.push(payrollIdNumber)
              csvRowData.push(hourlyOrSalariedAbbreviation)
              csvRowData.push(formattedStartDate)
              csvRowData.push(formattedEndDate)
              csvRowData.push('OTPREM')
              csvRowData.push(ovtPremium)
              csvRowData.push(formatCurrency(1))
              csvRowData.push(formatCurrency(ovtPremium))
              csvRowData.push('')
              csvRowData.push('')
              csvRowData.push('')
              csvRowData.push(organizationPayrollId)
              csvRowData.push(getProp(loopUser, 'name', ''))
              csvFileData.push(csvRowData)
            }
            // NON-WORKING HOURS
            for (let loopNonWorkingHourCode in getProp(loopUser, 'non_working_hours_breakdown', {})) {
              let loopNonWorkingHourValue = getProp(loopUser, 'non_working_hours_breakdown', {})[loopNonWorkingHourCode]
              // PTO Override
              if (loopNonWorkingHourCode === 'PTO') {
                loopNonWorkingHourValue = getProp(loopUser, 'pto_granted', 0)
              }
              // Use non working hour code to get percent of total hours and multiply by total pay
              let percentOfTotalHours = loopNonWorkingHourValue / getProp(loopUser, 'total_hours', 0)
              let nonWorkingDollars: string | number = 'ERROR'
              if (loopUser != null && loopUser['non_working_hours'] != null && loopUser['non_working_hours'] > 0) {
                percentOfTotalHours = loopNonWorkingHourValue / loopUser['non_working_hours']
                nonWorkingDollars = formatCurrency(percentOfTotalHours * getProp(loopUser, 'non_working_hours_pay', 0))
              }
              if (loopNonWorkingHourCode != null && loopNonWorkingHourValue != null) {
                csvRowData = []
                csvRowData.push(getProp(matchingActiveUser, 'id_number_payroll', ''))
                csvRowData.push(hourlyOrSalariedAbbreviation)
                csvRowData.push(returnFormattedDate(us_weekStartDate, 'YYYY-MM-DD'))
                csvRowData.push(returnFormattedDate(us_weekEndDate, 'YYYY-MM-DD'))
                csvRowData.push(loopNonWorkingHourCode)
                csvRowData.push(loopNonWorkingHourValue.toFixed(6))
                csvRowData.push(formatCurrency(baseWage))
                csvRowData.push(nonWorkingDollars)
                csvRowData.push('')
                csvRowData.push('')
                csvRowData.push('')
                csvRowData.push(getProp(matchingActiveUser, 'id_organization_payroll', ''))
                csvRowData.push(getProp(loopUser, 'name', ''))
                csvFileData.push(csvRowData)
              }
            }
          }
        }
        // Loop through each user and add salaried users to the CSV
        // for (let loopUserKey in internalUsers) {
        //   let loopUser = internalUsers[loopUserKey]
        //   let hourlyOrSalariedAbbreviation = getProp(loopUser, 'hourly_or_salaried', '')
        //   if (
        //     hourlyOrSalariedOptions != null &&
        //     hourlyOrSalariedOptions[hourlyOrSalariedAbbreviation] != null &&
        //     hourlyOrSalariedOptions[hourlyOrSalariedAbbreviation]['abbreviation'] != null
        //   ) {
        //     hourlyOrSalariedAbbreviation = hourlyOrSalariedOptions[hourlyOrSalariedAbbreviation]['abbreviation']
        //   }
        //   if (
        //     loopUser != null &&
        //     loopUser['hourly_or_salaried'] != null &&
        //     loopUser['hourly_or_salaried'] !== '' &&
        //     loopUser['hourly_or_salaried'] !== 'hourly'
        //   ) {
        //     csvRowData = []
        //     csvRowData.push(getProp(loopUser, 'id_number_payroll', ''))
        //     csvRowData.push(hourlyOrSalariedAbbreviation)
        //     csvRowData.push(returnFormattedDate(us_weekStartDate, 'YYYY-MM-DD'))
        //     csvRowData.push(returnFormattedDate(us_weekEndDate, 'YYYY-MM-DD'))
        //     csvRowData.push('REG') // Pay Code
        //     csvRowData.push(40) // Hours - TODO - subtract if NWT?
        //     csvRowData.push('')
        //     csvRowData.push('')
        //     csvRowData.push('')
        //     csvRowData.push('')
        //     csvRowData.push(getProp(loopUser, 'id_organization_payroll', ''))
        //     csvRowData.push(getProp(loopUser, 'name', ''))
        //     csvFileData.push(csvRowData)
        //   }
        // }
        // Download CSV
        downloadCSV(
          'ETW Full Payroll Export ' + returnFormattedDate(us_weekStartDate, 'YYYY-MM-DD') + ' - ' + returnFormattedDate(us_weekEndDate, 'YYYY-MM-DD'),
          csvFileData,
        )
          .then((res_DCSV) => {
            resolve(res_DCSV)
          })
          .catch((rej_DCSV) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DCSV.error })
            reject(rej_DCSV)
          })
      })
    })
  }

  const downloadPayrollReportVersion2 = (clientKey: string): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      let promiseArray: TsType_UnknownPromise[] = []
      let internalUsers: TsInterface_UnspecifiedObject = {}
      let csvFileData: (string | number)[][] = [
        [
          'Insperity Person ID',
          'WageType',
          'BeginPayPeriodDate',
          'EndPayPeriodDate',
          'PayCode',
          'Duration',
          'Wage',
          'PROJECT CODE',
          'Labor Level 2',
          'Labor Level 3',
          'Insperity Company ID',
          'Name',
        ],
      ]
      let csvRowData: (string | number)[] = []
      promiseArray.push(
        DatabaseGetCollection(DatabaseRef_InternalUsers_Query(clientKey))
          .then((res_DGC) => {
            // internalUsers = res_DGC.data
            for (let loopUserKey in res_DGC.data) {
              if (res_DGC.data[loopUserKey]['status'] !== 'deleted') {
                internalUsers[loopUserKey] = res_DGC.data[loopUserKey]
              }
            }
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
          }),
      )
      Promise.all(promiseArray).finally(() => {
        // Loop through user data and add hourly users to the CSV
        let alphabetizedUsers = objectToArray(getProp(us_displayedWeekPayrollData, 'employee_full_payroll_data', {})).sort(dynamicSort('name', 'asc'))
        for (let loopUserIndex in alphabetizedUsers) {
          let loopUser = alphabetizedUsers[loopUserIndex]
          let matchingActiveUser = getProp(internalUsers, loopUser.key, null)
          // if (matchingActiveUser != null && matchingActiveUser['hourly_or_salaried'] === 'hourly') {
          if (loopUser.key != 'undefined') {
            let hourlyOrSalariedAbbreviation = getProp(matchingActiveUser, 'hourly_or_salaried', '')
            if (
              hourlyOrSalariedOptions != null &&
              hourlyOrSalariedOptions[hourlyOrSalariedAbbreviation] != null &&
              hourlyOrSalariedOptions[hourlyOrSalariedAbbreviation]['abbreviation'] != null
            ) {
              hourlyOrSalariedAbbreviation = hourlyOrSalariedOptions[hourlyOrSalariedAbbreviation]['abbreviation']
            }
            // REGULAR HOURS
            csvRowData = []
            csvRowData.push(getProp(matchingActiveUser, 'id_number_payroll', ''))
            csvRowData.push(hourlyOrSalariedAbbreviation)
            csvRowData.push(returnFormattedDate(us_weekStartDate, 'YYYY-MM-DD'))
            csvRowData.push(returnFormattedDate(us_weekEndDate, 'YYYY-MM-DD'))
            csvRowData.push('REG')
            csvRowData.push(getProp(loopUser, 'work_reg_hours', 0).toFixed(6))
            csvRowData.push(formatCurrency(getProp(loopUser, 'reg_pay', 0)))
            csvRowData.push('')
            csvRowData.push('')
            csvRowData.push('')
            csvRowData.push(getProp(matchingActiveUser, 'id_organization_payroll', ''))
            csvRowData.push(getProp(loopUser, 'name', ''))
            csvFileData.push(csvRowData)
            // OVERTIME HOURS
            if (getProp(loopUser, 'work_ovt_hours', 0) > 0) {
              csvRowData = []
              csvRowData.push(getProp(matchingActiveUser, 'id_number_payroll', ''))
              csvRowData.push(hourlyOrSalariedAbbreviation)
              csvRowData.push(returnFormattedDate(us_weekStartDate, 'YYYY-MM-DD'))
              csvRowData.push(returnFormattedDate(us_weekEndDate, 'YYYY-MM-DD'))
              csvRowData.push('OVT')
              csvRowData.push(getProp(loopUser, 'work_ovt_hours', '').toFixed(6))
              csvRowData.push(formatCurrency(getProp(loopUser, 'overtime_pay', 0)))
              csvRowData.push('')
              csvRowData.push('')
              csvRowData.push('')
              csvRowData.push(getProp(matchingActiveUser, 'id_organization_payroll', ''))
              csvRowData.push(getProp(loopUser, 'name', ''))
              csvFileData.push(csvRowData)
            }
            // NON-WORKING HOURS
            for (let loopNonWorkingHourCode in getProp(loopUser, 'non_working_hours_breakdown', {})) {
              let loopNonWorkingHourValue = getProp(loopUser, 'non_working_hours_breakdown', {})[loopNonWorkingHourCode]
              // PTO Override
              if (loopNonWorkingHourCode === 'PTO') {
                loopNonWorkingHourValue = getProp(loopUser, 'pto_granted', 0)
              }
              // Use non working hour code to get percent of total hours and multiply by total pay
              let percentOfTotalHours = loopNonWorkingHourValue / getProp(loopUser, 'total_hours', 0)
              let nonWorkingDollars: string | number = 'ERROR'
              if (loopUser != null && loopUser['non_working_hours'] != null && loopUser['non_working_hours'] > 0) {
                percentOfTotalHours = loopNonWorkingHourValue / loopUser['non_working_hours']
                nonWorkingDollars = formatCurrency(percentOfTotalHours * getProp(loopUser, 'non_working_hours_pay', 0))
              }
              if (loopNonWorkingHourCode != null && loopNonWorkingHourValue != null) {
                csvRowData = []
                csvRowData.push(getProp(matchingActiveUser, 'id_number_payroll', ''))
                csvRowData.push(hourlyOrSalariedAbbreviation)
                csvRowData.push(returnFormattedDate(us_weekStartDate, 'YYYY-MM-DD'))
                csvRowData.push(returnFormattedDate(us_weekEndDate, 'YYYY-MM-DD'))
                csvRowData.push(loopNonWorkingHourCode)
                csvRowData.push(loopNonWorkingHourValue.toFixed(6))
                // csvRowData.push(formatCurrency(getProp(loopUser, 'non_working_hours_pay', 0)))
                csvRowData.push(nonWorkingDollars)
                csvRowData.push('')
                csvRowData.push('')
                csvRowData.push('')
                csvRowData.push(getProp(matchingActiveUser, 'id_organization_payroll', ''))
                csvRowData.push(getProp(loopUser, 'name', ''))
                csvFileData.push(csvRowData)
              }
            }
          }
        }
        // Loop through each user and add salaried users to the CSV
        for (let loopUserKey in internalUsers) {
          let loopUser = internalUsers[loopUserKey]
          let hourlyOrSalariedAbbreviation = getProp(loopUser, 'hourly_or_salaried', '')
          if (
            hourlyOrSalariedOptions != null &&
            hourlyOrSalariedOptions[hourlyOrSalariedAbbreviation] != null &&
            hourlyOrSalariedOptions[hourlyOrSalariedAbbreviation]['abbreviation'] != null
          ) {
            hourlyOrSalariedAbbreviation = hourlyOrSalariedOptions[hourlyOrSalariedAbbreviation]['abbreviation']
          }
          if (
            loopUser != null &&
            loopUser['hourly_or_salaried'] != null &&
            loopUser['hourly_or_salaried'] !== '' &&
            loopUser['hourly_or_salaried'] !== 'hourly'
          ) {
            csvRowData = []
            csvRowData.push(getProp(loopUser, 'id_number_payroll', ''))
            csvRowData.push(hourlyOrSalariedAbbreviation)
            csvRowData.push(returnFormattedDate(us_weekStartDate, 'YYYY-MM-DD'))
            csvRowData.push(returnFormattedDate(us_weekEndDate, 'YYYY-MM-DD'))
            csvRowData.push('REG') // Pay Code
            csvRowData.push(40) // Hours - TODO - subtract if NWT?
            csvRowData.push('')
            csvRowData.push('')
            csvRowData.push('')
            csvRowData.push('')
            csvRowData.push(getProp(loopUser, 'id_organization_payroll', ''))
            csvRowData.push(getProp(loopUser, 'name', ''))
            csvFileData.push(csvRowData)
          }
        }
        // Download CSV
        downloadCSV(
          '(' +
            returnFormattedDate(new Date(), 'YYYY-MM-DD') +
            ') ETW Payroll Export ' +
            returnFormattedDate(us_weekStartDate, 'YYYY-MM-DD') +
            ' - ' +
            returnFormattedDate(us_weekEndDate, 'YYYY-MM-DD') +
            '.csv',
          csvFileData,
        )
          .then((res_DCSV) => {
            resolve(res_DCSV)
          })
          .catch((rej_DCSV) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DCSV.error })
            reject(rej_DCSV)
          })
      })
    })
  }

  const downloadPieceRateReport = (clientKey: string): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      DatabaseGetCollection(DatabaseRef_TimeSheetsAllocationTypes_Collection(clientKey))
        .then((res_DGC) => {
          let allocationTypes = res_DGC.data
          let nonWorkingCodeSubtypes: string[] = []
          if (allocationTypes != null && allocationTypes['non_working_time'] != null && allocationTypes['non_working_time']['subtypes'] != null) {
            for (let loopKey in allocationTypes['non_working_time']['subtypes']) {
              let loopItem = allocationTypes['non_working_time']['subtypes'][loopKey]
              if (loopItem != null && loopItem.code != null) {
                nonWorkingCodeSubtypes.push(loopItem.code)
              }
            }
          }
          downloadAggregatedPunchData(
            clientKey,
            // @ts-expect-error - TODO: reason for error
            us_weekStartDate,
            us_weekEndDate,
            true,
            uc_setUserInterface_CustomDialogDisplay,
            'categories_json',
          )
            .then((res_DAPD) => {
              let categoriesData: any = res_DAPD
              let exportData = [
                [
                  'First Name',
                  'Last Name',
                  'ID',
                  'REG',
                  'OVT',
                  // "PTO",
                  // "FLH",
                  // "OVT",
                  // "TVL",
                  // "Pay Rates",
                  // "Wages"
                ],
              ]
              for (let loopIndex in nonWorkingCodeSubtypes) {
                exportData[0].push(nonWorkingCodeSubtypes[loopIndex])
              }
              exportData[0].push('Pay Rates')
              exportData[0].push('Wages')

              let sortedData: TsInterface_UnspecifiedObject[] = []
              if (us_displayedWeekPayrollData != null && us_displayedWeekPayrollData['employee_full_payroll_data'] != null) {
                for (let loopUserKey in us_displayedWeekPayrollData['employee_full_payroll_data']) {
                  let loopUserData = us_displayedWeekPayrollData['employee_full_payroll_data'][loopUserKey]
                  if (
                    loopUserKey != null &&
                    loopUserKey !== 'undefined' &&
                    loopUserData != null &&
                    loopUserData['employee_in_combine'] === true // TODO - fix
                  ) {
                    sortedData.push(loopUserData)
                  }
                }
              }
              sortedData.sort(dynamicSort('name_last', 'asc'))
              for (let loopUserIndex in sortedData) {
                let rowData: string[] = []
                let loopUser = sortedData[loopUserIndex]

                let loopUserKey = loopUser['key']
                // First Name - name_first
                rowData.push(getProp(loopUser, 'name_first', ''))
                // Last Name - name_last
                rowData.push(getProp(loopUser, 'name_last', ''))
                // ID - id_number_payroll
                rowData.push(getProp(loopUser, 'id_number_payroll', ''))
                // REG - work_reg_hours
                if (getProp(loopUser, 'work_reg_hours', 0) > 0) {
                  rowData.push(getProp(loopUser, 'work_reg_hours', 0).toFixed(2))
                } else {
                  rowData.push('')
                }
                // OVT - work_ovt_hours
                if (getProp(loopUser, 'work_ovt_hours', 0) > 0) {
                  rowData.push(getProp(loopUser, 'work_ovt_hours', 0).toFixed(2))
                } else {
                  rowData.push('')
                }
                for (let loopIndex in nonWorkingCodeSubtypes) {
                  let subtypeKey = nonWorkingCodeSubtypes[loopIndex]
                  if (
                    categoriesData != null &&
                    categoriesData['data'] != null &&
                    categoriesData['data'][loopUserKey] != null &&
                    categoriesData['data'][loopUserKey]['raw_codes'] != null &&
                    categoriesData['data'][loopUserKey]['raw_codes'][subtypeKey] != null
                  ) {
                    rowData.push(categoriesData['data'][loopUserKey]['raw_codes'][subtypeKey].toFixed(2))
                  } else {
                    rowData.push('')
                  }
                }
                // Pay Rates - effective_wage
                // Use Weighted Average Wage for this column
                let weightedWage: string | number = 'ERROR'
                if (loopUser != null && loopUser['total_pay'] != null && loopUser['base_wage'] != null) {
                  if (loopUser['non_working_hours'] == null) {
                    loopUser['non_working_hours'] = 0
                  }
                  if (loopUser['work_reg_hours'] == null) {
                    loopUser['work_reg_hours'] = 0
                  }
                  if (loopUser['work_ovt_hours'] == null) {
                    loopUser['work_ovt_hours'] = 0
                  }
                  if (loopUser['work_reg_hours'] == 0 && loopUser['work_ovt_hours'] == 0) {
                    weightedWage = formatCurrency(getProp(loopUser, 'effective_wage', 0))
                  } else {
                    weightedWage =
                      (loopUser['total_pay'] - loopUser['non_working_hours'] * loopUser['base_wage']) /
                      (loopUser['work_reg_hours'] + 1.5 * loopUser['work_ovt_hours'])
                    weightedWage = formatCurrency(weightedWage)
                  }
                }
                rowData.push(weightedWage)
                // rowData.push(formatCurrency(getProp(loopUser, 'effective_wage', 0)))
                // Wages - total_pay
                rowData.push(formatCurrency(getProp(loopUser, 'total_pay', 0)))
                // Add To Data Array
                exportData.push(rowData)
              }
              // Download CSV
              downloadCSV(
                'Piece Rate (' + returnFormattedDate(us_weekStartDate, 'D MMM YY') + ' ' + returnFormattedDate(us_weekEndDate, 'D MMM YY') + ')',
                exportData,
              )
                .then((res_DCSV) => {
                  resolve(res_DCSV)
                })
                .catch((rej_DCSV) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DCSV.error })
                  reject(rej_DCSV)
                })
            })
            .catch((rej_DAPD) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAPD.error })
            })
        })
        .catch((rej_DGC) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DGC.error })
        })
    })
  }

  const downloadBonusCommissionReport = (clientKey: string): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      let exportData = [['Date', 'First Name', 'Last Name', 'ID', 'Amount', 'Type']]
      // Commission
      let sortedData: TsInterface_UnspecifiedObject[] = []
      if (us_displayedWeekPayrollData != null && us_displayedWeekPayrollData['employee_full_payroll_data'] != null) {
        for (let loopUserKey in us_displayedWeekPayrollData['employee_full_payroll_data']) {
          let loopUserData = us_displayedWeekPayrollData['employee_full_payroll_data'][loopUserKey]
          if (
            loopUserKey != null &&
            loopUserKey !== 'undefined' &&
            loopUserData != null &&
            loopUserData['final_bonus_pay'] != null &&
            loopUserData['final_bonus_pay'] > 0
          ) {
            sortedData.push(loopUserData)
          }
        }
      }
      sortedData.sort(dynamicSort('name_last', 'asc'))
      for (let loopUserIndex in sortedData) {
        let rowData: string[] = []
        let loopUser = sortedData[loopUserIndex]
        let mondayFormattedDate = returnFormattedDate(new Date(us_mondayDateKey as string), 'D/M/YYYY')
        // Date
        rowData.push(mondayFormattedDate)
        // First Name
        rowData.push(getProp(loopUser, 'name_first', ''))
        // Last Name
        rowData.push(getProp(loopUser, 'name_last', ''))
        // ID
        rowData.push(getProp(loopUser, 'id_number_payroll', ''))
        // Amount
        rowData.push(formatCurrency(getProp(loopUser, 'final_bonus_pay', 0)))
        // Type
        rowData.push('Commission')
        // Add To Data Array
        exportData.push(rowData)
      }
      // Bonus
      let sortedData2: TsInterface_UnspecifiedObject[] = []
      if (us_displayedWeekPayrollData != null && us_displayedWeekPayrollData['employee_full_payroll_data'] != null) {
        for (let loopUserKey in us_displayedWeekPayrollData['employee_full_payroll_data']) {
          let loopUserData = us_displayedWeekPayrollData['employee_full_payroll_data'][loopUserKey]
          if (
            loopUserKey != null &&
            loopUserKey !== 'undefined' &&
            loopUserData != null &&
            loopUserData['hardcoded_bonus'] != null &&
            loopUserData['hardcoded_bonus'] > 0
          ) {
            sortedData2.push(loopUserData)
          }
        }
      }
      sortedData2.sort(dynamicSort('name_last', 'asc'))
      for (let loopUserIndex in sortedData2) {
        let rowData: string[] = []
        let loopUser = sortedData2[loopUserIndex]
        let mondayFormattedDate = returnFormattedDate(new Date(us_mondayDateKey as string), 'D/M/YYYY')
        // Date
        rowData.push(mondayFormattedDate)
        // First Name
        rowData.push(getProp(loopUser, 'name_first', ''))
        // Last Name
        rowData.push(getProp(loopUser, 'name_last', ''))
        // ID
        rowData.push(getProp(loopUser, 'id_number_payroll', ''))
        // Amount
        rowData.push(formatCurrency(getProp(loopUser, 'hardcoded_bonus', 0)))
        // Type
        rowData.push('Bonus')
        // Add To Data Array
        exportData.push(rowData)
      }
      // Download CSV
      downloadCSV(
        'Bonus Commission (' + returnFormattedDate(us_weekStartDate, 'D MMM YY') + ' ' + returnFormattedDate(us_weekEndDate, 'D MMM YY') + ')',
        exportData,
      )
        .then((res_DCSV) => {
          resolve(res_DCSV)
        })
        .catch((rej_DCSV) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DCSV.error })
          reject(rej_DCSV)
        })
    })
  }

  const extractYearFromDate = (dateString: string) => {
    // Split the date string by '-' to get an array of parts (year, month, day)
    const parts = dateString.split('-')
    // Check if the parts array has at least 1 element (year)
    if (parts.length >= 1) {
      // Parse the first element (year) as an integer and return it
      return parseInt(parts[0], 10)
    } else {
      // If the input format is incorrect, return NaN (Not-a-Number)
      return NaN
    }
  }

  const TEMP_buildCachedTaskPayrollHours = () => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
        for (let loopTaskKey in us_employeeClockedTaskHours) {
          updateArray.push({
            type: 'setMerge',
            ref: DatabaseRef_TaskCachedPayrollHours_Document(res_GCK.clientKey, loopTaskKey),
            data: us_employeeClockedTaskHours[loopTaskKey],
          })
        }
        DatabaseStagedBatchUpdate(updateArray)
          .then((res_DSBU) => {
            console.log('Success')
            us_setRecachingProjectData(false)
          })
          .catch((rej_DSBU) => {
            us_setRecachingProjectData(false)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
        us_setRecachingProjectData(false)
      })
  }

  const removeUndefinedValuesFromObject = (obj: TsInterface_UnspecifiedObject) => {
    for (let loopKey in obj) {
      if (obj[loopKey] === undefined) {
        delete obj[loopKey]
      } else if (typeof obj[loopKey] === 'object' && obj[loopKey] !== null) {
        // Recursively clean nested objects
        removeUndefinedValuesFromObject(obj[loopKey])
        // If the nested object is now empty, remove it too
        if (Object.keys(obj[loopKey]).length === 0) {
          delete obj[loopKey]
        }
      }
    }
    return obj
  }

  const lockWeekPayrollData = (): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      us_setLockingPayrollData(true)
      if (us_mondayDateKey != null) {
        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
          .then((res_GCK) => {
            // Query and wipe old data before proceeding
            let promiseArray: TsType_UnknownPromise[] = []
            let deleteUpdateArray: TsInterface_DatabaseBatchUpdatesArray = []
            for (let loopCategoryKey in calculatedDataToWipeOnLock) {
              // @ts-ignore
              promiseArray.push(
                DatabaseGetCollection(calculatedDataToWipeOnLock[loopCategoryKey](res_GCK.clientKey, us_mondayDateKey))
                  .then((res_DGC) => {
                    for (let loopItemKeyToWipe in res_DGC.data) {
                      deleteUpdateArray.push({
                        type: 'delete',
                        ref: lockCalculatedDataToStoreInItsOwnCollection[loopCategoryKey](res_GCK.clientKey, us_mondayDateKey, loopItemKeyToWipe),
                      })
                    }
                  })
                  .catch((rej_DGC) => {
                    console.error(rej_DGC)
                  }),
              )
            }
            Promise.all(promiseArray).finally(() => {
              DatabaseStagedBatchUpdate(deleteUpdateArray)
                .then((res_DSBU) => {
                  let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
                  // Main Payroll Data
                  for (let loopKey in us_displayedWeekPayrollData) {
                    if (lockCalculatedDataToStoreInItsOwnCollection != null && lockCalculatedDataToStoreInItsOwnCollection[loopKey] != null) {
                      for (let loopSubKey in getProp(us_displayedWeekPayrollData, loopKey, {})) {
                        let loopSubItem = getProp(us_displayedWeekPayrollData, loopKey, {})[loopSubKey]
                        updateArray.push({
                          type: 'setOverwrite',
                          ref: lockCalculatedDataToStoreInItsOwnCollection[loopKey](res_GCK.clientKey, us_mondayDateKey, loopSubKey),
                          data: removeUndefinedValuesFromObject(loopSubItem),
                        })
                      }
                    } else {
                      updateArray.push({
                        type: 'setOverwrite',
                        ref: DatabaseRef_FinalizedPayroll_ErrorsAndWarnings_Document(res_GCK.clientKey, us_mondayDateKey, loopKey),
                        data: removeUndefinedValuesFromObject(getProp(us_displayedWeekPayrollData, loopKey, {})),
                      })
                    }
                  }
                  // Leaderboard Data
                  let usersAddedToLifetimePanelsData: TsInterface_UnspecifiedObject = {}
                  let usersAddedToLifetimeUnitsData: TsInterface_UnspecifiedObject = {}
                  for (let loopUserDateKey in us_displayedWeekLeaderboardData) {
                    // Actual Leaderboard Data
                    updateArray.push({
                      type: 'setOverwrite',
                      ref: DatabaseRef_LeaderboardData_Document(res_GCK.clientKey, loopUserDateKey),
                      data: removeUndefinedValuesFromObject(getProp(us_displayedWeekLeaderboardData, loopUserDateKey, {})),
                    })
                    // Lifetime Panel Data
                    let loopUserData = getProp(us_displayedWeekLeaderboardData, loopUserDateKey, {})
                    if (!isNaN(extractYearFromDate(us_mondayDateKey)) && extractYearFromDate(us_mondayDateKey) >= 2024) {
                      if (loopUserData != null && loopUserData['associated_user_key'] != null && loopUserData['total_lifetime_panels'] != null) {
                        usersAddedToLifetimePanelsData[loopUserData['associated_user_key']] = true
                        let updateObject: TsInterface_UnspecifiedObject = {
                          key: loopUserData['associated_user_key'],
                          name: loopUserData['associated_user_name'],
                          [us_mondayDateKey]: loopUserData['total_lifetime_panels'],
                        }
                        if (
                          us_leaderboardReferralData != null &&
                          us_leaderboardReferralData[loopUserData['associated_user_key']] != null &&
                          us_leaderboardReferralData[loopUserData['associated_user_key']]['total_lifetime_referral_panels'] != null
                        ) {
                          updateObject[us_mondayDateKey + '-referral'] =
                            us_leaderboardReferralData[loopUserData['associated_user_key']]['total_lifetime_referral_panels']
                        }
                        updateArray.push({
                          type: 'setMerge',
                          ref: DatabaseRef_LifetimePanels_Document(res_GCK.clientKey, loopUserData['associated_user_key']),
                          data: removeUndefinedValuesFromObject(updateObject),
                        })
                      }
                      if (loopUserData != null && loopUserData['associated_user_key'] != null && loopUserData['total_lifetime_units'] != null) {
                        usersAddedToLifetimeUnitsData[loopUserData['associated_user_key']] = true
                        let updateObject: TsInterface_UnspecifiedObject = {
                          key: loopUserData['associated_user_key'],
                          name: loopUserData['associated_user_name'],
                          [us_mondayDateKey]: loopUserData['total_lifetime_units'],
                        }
                        if (
                          us_leaderboardReferralData != null &&
                          us_leaderboardReferralData[loopUserData['associated_user_key']] != null &&
                          us_leaderboardReferralData[loopUserData['associated_user_key']]['total_lifetime_referral_units'] != null
                        ) {
                          updateObject[us_mondayDateKey + '-referral'] =
                            us_leaderboardReferralData[loopUserData['associated_user_key']]['total_lifetime_referral_units']
                        }
                        updateArray.push({
                          type: 'setMerge',
                          ref: DatabaseRef_LifetimeUnits_Document(res_GCK.clientKey, loopUserData['associated_user_key']),
                          data: removeUndefinedValuesFromObject(updateObject),
                        })
                      }
                    }
                  }
                  // Referral Data where the referring user did not have normal data for this week
                  for (let loopUserKey in us_leaderboardReferralData) {
                    let loopReferralUserData = us_leaderboardReferralData[loopUserKey]
                    if (loopReferralUserData['total_lifetime_referral_panels'] > 0 && usersAddedToLifetimePanelsData[loopUserKey] !== true) {
                      updateArray.push({
                        type: 'setMerge',
                        ref: DatabaseRef_LifetimePanels_Document(res_GCK.clientKey, loopReferralUserData['associated_user_key']),
                        data: removeUndefinedValuesFromObject({
                          key: loopReferralUserData['associated_user_key'],
                          name: loopReferralUserData['associated_user_name'],
                          [us_mondayDateKey + '-referral']: loopReferralUserData['total_lifetime_referral_panels'],
                        }),
                      })
                    }
                    if (loopReferralUserData['total_lifetime_referral_units'] > 0 && usersAddedToLifetimeUnitsData[loopUserKey] !== true) {
                      updateArray.push({
                        type: 'setMerge',
                        ref: DatabaseRef_LifetimeUnits_Document(res_GCK.clientKey, loopReferralUserData['associated_user_key']),
                        data: removeUndefinedValuesFromObject({
                          key: loopReferralUserData['associated_user_key'],
                          name: loopReferralUserData['associated_user_name'],
                          [us_mondayDateKey + '-referral']: loopReferralUserData['total_lifetime_referral_units'],
                        }),
                      })
                    }
                  }
                  // Cache Task Payroll Hours for each employee
                  for (let loopTaskKey in us_employeeClockedTaskHours) {
                    updateArray.push({
                      type: 'setMerge',
                      ref: DatabaseRef_TaskCachedPayrollHours_Document(res_GCK.clientKey, loopTaskKey),
                      data: removeUndefinedValuesFromObject(us_employeeClockedTaskHours[loopTaskKey]),
                    })
                  }

                  console.log(updateArray)

                  // Save to Database
                  DatabaseStagedBatchUpdate(updateArray)
                    .then((res_DSBU) => {
                      DatabaseSetMergeDocument(DatabaseRef_FinalizedPayroll_Document(res_GCK.clientKey, us_mondayDateKey), {
                        timestamp_leaderboard_data_last_calculated: new Date(),
                        timestamp_locked: new Date(),
                        locked: true,
                      })
                        .then((res_DSMD) => {
                          us_setLockingPayrollData(false)
                          resolve(res_DSMD)
                        })
                        .catch((rej_DSMD) => {
                          us_setLockingPayrollData(false)
                          reject(rej_DSMD)
                        })
                    })
                    .catch((rej_DSBU) => {
                      console.error(rej_DSBU)
                      us_setLockingPayrollData(false)
                      reject(rej_DSBU)
                    })
                })
                .catch((rej_DSBU) => {
                  us_setLockingPayrollData(false)
                  reject(rej_DSBU)
                })
            })
          })
          .catch((rej_GCK) => {
            us_setLockingPayrollData(false)
            reject(rej_GCK)
          })
      } else {
        us_setLockingPayrollData(false)
        reject({
          success: false,
          error: {
            message: rLIB('Failed to generate payroll data'),
            details: rLIB('Missing required parameters'),
            code: 'ER-D-PI-LWPD-01',
          },
        })
      }
    })
  }

  const unlockWeekPayrollData = (): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      if (us_mondayDateKey != null) {
        uc_setUserInterface_PromptDialogDisplay({
          display: true,
          prompt: {
            color: 'error',
            confirm_text: rLIB('Unlock Payroll') as JSX.Element,
            default_value: '',
            header: rLIB('Unlock Payroll'),
            icon: (
              <Icon
                icon="siren-on"
                type="solid"
              />
            ),
            input_label: rLIB('Type DANGER to proceed') as JSX.Element,
            input_type: 'text',
            text: rLIB('Are you sure that you want to unlock payroll for this week'),
            submit_callback: (promptValue: string) => {
              return new Promise((resolve, reject) => {
                if (promptValue === 'DANGER') {
                  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                        {
                          type: 'setMerge',
                          ref: DatabaseRef_FinalizedPayroll_Document(res_GCK.clientKey, us_mondayDateKey),
                          data: {
                            timestamp_locked: null,
                            locked: false,
                          },
                        },
                      ]
                      // Save to Database
                      DatabaseBatchUpdate(updateArray)
                        .then((res_DBU) => {
                          resolve(res_DBU)
                        })
                        .catch((rej_DBU) => {
                          reject(rej_DBU)
                        })
                    })
                    .catch((rej_GCK) => {
                      reject(rej_GCK)
                    })
                } else {
                  uc_setUserInterface_ErrorDialogDisplay({
                    display: true,
                    error: {
                      message: rLIB('Failed to unlock payroll'),
                      details: (
                        <>
                          {rLIB('You must enter DANGER in order to proceed.')} {rLIB('Otherwise click dismiss')}
                        </>
                      ),
                      code: 'ER-D-PT-DO-01',
                    },
                  })
                  resolve({ close_dialog: false })
                }
              })
            },
          },
        })
      } else {
        reject({
          success: false,
          error: {
            message: rLIB('Failed to unlock payroll'),
            details: rLIB('Missing required parameters'),
            code: 'ER-D-PI-UWPD-01',
          },
        })
      }
    })
  }

  const openCustomAndAdHocTaskUnitMappingPropertyEditForm = (task: TsInterface_UnspecifiedObject, formInputs: TsInterface_FormInputs): void => {
    let taskData: TsInterface_UnspecifiedObject = {}
    if (
      us_finalizedWeekPayroll_CustomMappingData != null &&
      us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'] != null &&
      us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'][task.key as string] != null
    ) {
      taskData = us_finalizedWeekPayroll_CustomMappingData['custom_and_ad_hoc_task_unit_mapping'][task.key as string]
    }
    // Open Form Dialog
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: taskData,
          formInputs: formInputs,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let updateObject: TsInterface_UnspecifiedObject = {
                    [task.key]: formSubmittedData,
                  }
                  if (us_mondayDateKey != null) {
                    if (formSubmittedData.task_units != null) {
                      let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                        {
                          type: 'setMerge',
                          ref: DatabaseRef_FinalizedPayroll_CustomMappingData_Document(
                            res_GCK.clientKey,
                            us_mondayDateKey,
                            'custom_and_ad_hoc_task_unit_mapping',
                          ),
                          data: updateObject,
                        },
                        {
                          type: 'setMerge',
                          ref: DatabaseRef_Task_Document(res_GCK.clientKey, task.key),
                          data: {
                            associated_payroll_custom_task_units: formSubmittedData.task_units,
                          },
                        },
                      ]
                      DatabaseBatchUpdate(updateArray)
                        .then((res_DBU) => {
                          resolve(res_DBU)
                        })
                        .catch((rej_DBU) => {
                          reject(rej_DBU)
                        })
                    } else {
                      DatabaseSetMergeDocument(
                        DatabaseRef_FinalizedPayroll_CustomMappingData_Document(res_GCK.clientKey, us_mondayDateKey, 'custom_and_ad_hoc_task_unit_mapping'),
                        updateObject,
                      )
                        .then((res_DSMD) => {
                          resolve({ success: true })
                        })
                        .catch((rej_DSMD) => {
                          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                          reject(rej_DSMD)
                        })
                    }
                    // task_units
                  } else {
                    reject({
                      success: false,
                      error: {
                        message: rLIB('Failed to save mapping data'),
                        details: rLIB('Missing required parameters'),
                        code: 'ER-D-PI-ADTUMPEF-01',
                      },
                    })
                  }
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: (
            <>
              {rLIB('Edit Task Unit Work')} - {task.name}
            </>
          ),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const rJSX_TaskUnitsChip = (
    task: TsInterface_UnspecifiedObject,
    editable: boolean,
    openEditForm: any,
    taskUnitMapping: TsInterface_UnspecifiedObject,
    hardcodeValueOnly: boolean,
  ): JSX.Element => {
    let chipJSX = <></>
    let formInputs_TaskUnits: TsInterface_FormInputs = {
      task_unit_evaluation: {
        key: 'task_unit_evaluation',
        label: rLIB('Task Unit Evaluation'),
        input_type: 'multiple_choice_radio',
        required: true,
        data_type: 'string',
        options: [
          { value: rLIB('Case by Case Basis'), key: 'custom', disabled: hardcodeValueOnly },
          { value: rLIB('Number of Panels'), key: 'panels', disabled: hardcodeValueOnly },
          { value: rLIB('Specific Value'), key: 'hardcode' },
        ],
      },
      task_units: {
        conditional_display: {
          active: true,
          logic: {
            active: true,
            logic_type: 'comparison',
            source: 'formData',
            prop: 'task_unit_evaluation',
            comparator: '==',
            value: 'hardcode',
            conditions: [],
          },
        },
        conditional_require: {
          active: true,
          logic: {
            active: true,
            logic_type: 'comparison',
            source: 'formData',
            prop: 'task_unit_evaluation',
            comparator: '==',
            value: 'hardcode',
            conditions: [],
          },
        },
        key: 'task_units',
        label: rLIB('Units'),
        input_type: 'text_number',
        required: false,
        data_type: 'number',
      },
    }
    // Default Chip
    chipJSX = (
      <Chip
        sx={{ maxWidth: '190px' }}
        className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
        label={rLIB('Not Set')}
        variant="filled"
        color="error"
        onClick={() => {
          if (editable === true) {
            openEditForm(task, formInputs_TaskUnits)
          }
        }}
      />
    )
    //
    if (
      task != null &&
      task.key != null &&
      taskUnitMapping != null &&
      taskUnitMapping[task.key] != null &&
      taskUnitMapping[task.key]['task_unit_evaluation'] === 'custom'
    ) {
      chipJSX = (
        <Box className="tw-inline-block">
          <Chip
            sx={{ maxWidth: '190px' }}
            className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
            color="warning"
            label={rLIB('Case by Case Basis')}
            variant="outlined"
            onClick={() => {
              if (editable === true) {
                openEditForm(task, formInputs_TaskUnits)
              }
            }}
          />
        </Box>
      )
    } else if (
      task != null &&
      task.key != null &&
      taskUnitMapping != null &&
      taskUnitMapping[task.key] != null &&
      taskUnitMapping[task.key]['task_unit_evaluation'] === 'panels'
    ) {
      chipJSX = (
        <Box className="tw-inline-block">
          <Chip
            sx={{ maxWidth: '190px' }}
            className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
            color="warning"
            label={rLIB('Number of Panels')}
            variant="outlined"
            onClick={() => {
              if (editable === true) {
                openEditForm(task, formInputs_TaskUnits)
              }
            }}
          />
        </Box>
      )
    } else if (
      task != null &&
      task.key != null &&
      taskUnitMapping != null &&
      taskUnitMapping[task.key] != null &&
      taskUnitMapping[task.key]['task_unit_evaluation'] === 'hardcode'
    ) {
      if (task != null && task.key != null && taskUnitMapping != null && taskUnitMapping[task.key] != null && taskUnitMapping[task.key]['task_units'] != null) {
        chipJSX = (
          <Box className="tw-inline-block">
            <Chip
              sx={{ maxWidth: '190px' }}
              className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
              label={taskUnitMapping[task.key]['task_units']}
              variant="filled"
              onClick={() => {
                if (editable === true) {
                  openEditForm(task, formInputs_TaskUnits)
                }
              }}
            />
          </Box>
        )
      } else {
        // Use Default Chip
      }
    } else {
      if (task != null && task.key != null && taskUnitMapping != null && taskUnitMapping[task.key] != null && taskUnitMapping[task.key]['task_units'] != null) {
        chipJSX = (
          <Box className="tw-inline-block">
            <Chip
              sx={{ maxWidth: '190px' }}
              className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
              color="warning"
              label={taskUnitMapping[task.key]['task_units']}
              variant="filled"
              onClick={() => {
                if (editable === true) {
                  openEditForm(task, formInputs_TaskUnits)
                }
              }}
            />
          </Box>
        )
      } else {
        // Use Default Chip
      }
    }
    // Unit Type Mismatch
    let mismatchIconJSX = <></>
    if (
      task != null &&
      task.key != null &&
      taskUnitMapping != null &&
      taskUnitMapping[task.key] != null &&
      taskUnitMapping[task.key]['unit_work_type'] != null &&
      taskUnitMapping[task.key]['task_unit_evaluation'] != null
    ) {
      let expectedUnitWorkType = getProp(unitWorkTypes, taskUnitMapping[task.key]['unit_work_type'], {})['unit_type']
      if (
        expectedUnitWorkType != null &&
        expectedUnitWorkType === 'panels' &&
        (taskUnitMapping[task.key]['task_unit_evaluation'] === 'custom' || taskUnitMapping[task.key]['task_unit_evaluation'] === 'hardcode')
      ) {
        mismatchIconJSX = (
          <Icon
            icon="exclamation-triangle"
            className="tw-ml-2 tw-text-warning_main"
            sx={{ verticalAlign: 'top', fontSize: '18px' }}
            tooltip={rLIB('Unit Type Mismatch')}
            tooltipPlacement="right"
          />
        )
      }
      if (expectedUnitWorkType != null && expectedUnitWorkType === 'units' && taskUnitMapping[task.key]['task_unit_evaluation'] === 'panels') {
        mismatchIconJSX = (
          <Icon
            icon="exclamation-triangle"
            className="tw-ml-2 tw-text-warning_main"
            sx={{ verticalAlign: 'top', fontSize: '18px' }}
            tooltip={rLIB('Unit Type Mismatch')}
            tooltipPlacement="right"
          />
        )
      }
    }
    // unit_type

    let containerJSX = (
      <Box>
        {chipJSX}
        {mismatchIconJSX}
      </Box>
    )
    return containerJSX
  }

  const rJSX_UnitWorkTypeChip = (
    task: TsInterface_UnspecifiedObject,
    editable: boolean,
    openEditForm: any,
    taskUnitMapping: TsInterface_UnspecifiedObject,
  ): JSX.Element => {
    let chipJSX = <></>
    let unitWorkTypeOptions: TsInterface_UnspecifiedObject[] = []
    for (let loopUnitWorkTypeKey in unitWorkTypes) {
      let loopUnitWorkType = unitWorkTypes[loopUnitWorkTypeKey]
      unitWorkTypeOptions.push({
        key: loopUnitWorkTypeKey,
        value: loopUnitWorkType.abbreviation,
      })
    }
    let formInputs_UnitWorkType: TsInterface_FormInputs = {
      unit_work_type: {
        key: 'unit_work_type',
        label: rLIB('Unit Work Type'),
        input_type: 'multiple_choice_radio',
        required: true,
        data_type: 'string',
        options: unitWorkTypeOptions,
        submit_on_change: true,
      },
    }
    if (
      task != null &&
      task.key != null &&
      taskUnitMapping != null &&
      taskUnitMapping[task.key] != null &&
      taskUnitMapping[task.key]['unit_work_type'] != null &&
      unitWorkTypes[taskUnitMapping[task.key]['unit_work_type']] != null &&
      unitWorkTypes[taskUnitMapping[task.key]['unit_work_type']]['abbreviation'] != null
    ) {
      chipJSX = (
        <Box className="tw-inline-block">
          <Chip
            sx={{ maxWidth: '190px' }}
            className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
            label={unitWorkTypes[taskUnitMapping[task.key]['unit_work_type']]['abbreviation']}
            variant="filled"
            onClick={() => {
              if (editable === true) {
                openEditForm(task, formInputs_UnitWorkType)
              }
            }}
          />
        </Box>
      )
    } else if (task != null && task.key != null && taskUnitMapping[task.key] != null && taskUnitMapping[task.key]['unit_work_type'] != null) {
      chipJSX = (
        <Chip
          sx={{ maxWidth: '190px' }}
          className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
          label={taskUnitMapping[task.key]['unit_work_type']}
          variant="filled"
          color="warning"
          onClick={() => {
            if (editable === true) {
              openEditForm(task, formInputs_UnitWorkType)
            }
          }}
        />
      )
    } else {
      chipJSX = (
        <Chip
          sx={{ maxWidth: '190px' }}
          className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
          label={rLIB('Not Set')}
          variant="filled"
          color="error"
          onClick={() => {
            if (editable === true) {
              openEditForm(task, formInputs_UnitWorkType)
            }
          }}
        />
      )
    }
    return chipJSX
  }

  const returnTableColumns_CustomUnitMapping = (mappingDocumentKey: string, openTaskForm: any): TsInterface_TableColumns => {
    let tableColumns_Tasks: TsInterface_TableColumns = {
      associated_project_id_number: {
        header: {
          header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
            return rLIB('Job Code')
          },
          header_sort_by: null,
          header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
            return ''
          },
        },
        cell: {
          cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
            let cellJSX = (
              <Box>
                {rowData.associated_project_id_number}
                <Icon
                  icon="arrow-up-right-from-square"
                  className="tw-ml-2 tw-opacity-20 hover:tw-opacity-100 tw-cursor-pointer tw-text-info-main"
                  tooltip={rLIB('Open Project')}
                  tooltipPlacement="right"
                  onClick={() => {
                    let url = window.location.origin + ApplicationPages.AdminActiveProjectViewPage.url(rowData.associated_project_key as string)
                    window.open(url, '_blank')
                  }}
                />
              </Box>
            )
            return cellJSX
          },
          cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
            let cellCSS = ''
            return cellCSS
          },
        },
      },
      name: TableCellBasic('name', rLIB('Task Name'), 'name'),
      associated_team_name: TableCellBasic('associated_team_name', rLIB('Team Name'), 'associated_team_name'),
      task_completion_scheduled_dates: {
        header: {
          header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
            return rLIB('Scheduled Dates')
          },
          header_sort_by: null,
          header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
            return ''
          },
        },
        cell: {
          cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
            let cellJSX = <></>
            if (rowData != null && rowData.task_completion_scheduled_dates != null) {
              let goToCalendarIconJSX = (
                <Icon
                  icon="calendar-arrow-up"
                  className="tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 tw-text-info-main tw-ml-2"
                  tooltip={rLIB('View on Calendar')}
                  tooltipPlacement="right"
                  onClick={() => {
                    let datesArray = getProp(rowData, 'task_completion_scheduled_dates', [])
                    if (datesArray.length > 0) {
                      let url = window.location.origin + ApplicationPages.TaskCalendarSchedulePage.url(datesArray[datesArray.length - 1]) + '?tab=Day'
                      window.open(url, '_blank')
                    }
                  }}
                />
              )
              cellJSX = (
                <Box>
                  {formatDateRange(getProp(rowData, 'task_completion_scheduled_dates', []))}
                  {goToCalendarIconJSX}
                </Box>
              )
            }
            return cellJSX
          },
          cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
            let cellCSS = ''
            let differenceBetweenDates = 0
            if (
              rowData != null &&
              rowData.timestamp_completed != null &&
              rowData.task_completion_scheduled_dates != null &&
              objectToArray(getProp(rowData, 'task_completion_scheduled_dates', [])).length > 0
            ) {
              let lastScheduledWorkDay = getLastChronologicalDate(getProp(rowData, 'task_completion_scheduled_dates', []))
              differenceBetweenDates = Math.abs(
                getDaysBetweenDates(returnDateFromUnknownDateFormat(lastScheduledWorkDay), returnDateFromUnknownDateFormat(rowData.timestamp_completed)),
              )
            }
            if (differenceBetweenDates > 12) {
              cellCSS = 'tw-bg-error_main tw-text-white'
            } else if (differenceBetweenDates > 5) {
              cellCSS = 'tw-bg-warning_main tw-text-white'
            }
            return cellCSS
          },
        },
      },
      timestamp_completed: {
        header: {
          header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
            return rLIB('Task Completed On')
          },
          header_sort_by: null,
          header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
            return ''
          },
        },
        cell: {
          cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
            let cellJSX = <></>
            if (rowData != null && rowData.timestamp_completed != null) {
              cellJSX = <Box>{returnFormattedDate(rowData['timestamp_completed'], 'D MMM YY')}</Box>
            }
            return cellJSX
          },
          cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
            let cellCSS = ''
            return cellCSS
          },
        },
      },
      unit_work_type: {
        header: {
          header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
            return rLIB('Unit Work Type')
          },
          header_sort_by: null,
          header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
            return ''
          },
        },
        cell: {
          cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
            let potentialMatches = findCloseStringMatches(
              rowData.name as string,
              tableAdditionalData.us_taskBlueprints,
              tableAdditionalData.us_taskBlueprintUnitMapping,
            )
            let suggestionIcon = <></>
            if (potentialMatches != null && potentialMatches.length > 0) {
              suggestionIcon = (
                <Icon
                  sx={{ fontSize: '16px' }}
                  // type="regular"
                  icon="telescope"
                  className="tw-cursor-default tw-opacity-30 hover:tw-opacity-100 tw-text-info-main tw-ml-2 tw-mr-2"
                  tooltip={
                    <Box sx={{ fontSize: '16px' }}>
                      <TableContainer>
                        <Table size="small">
                          <TableBody>
                            {objectToArray(potentialMatches).map((matchObject, index) => (
                              <TableRow key={index}>
                                <TableCell>{matchObject.name}</TableCell>
                                <TableCell>
                                  {matchObject.unit_work_type}
                                  {/* <Chip
																		sx={{ borderColor: matchObject.unit_work_type != null ? themeVariables.white : themeVariables.transparent }}
																		label={ matchObject.unit_work_type }
																		variant='outlined'
																	/> */}
                                </TableCell>
                                <TableCell>
                                  {matchObject.task_units}
                                  {/* <Chip
																		sx={{ borderColor: matchObject.task_units != null ? themeVariables.white : themeVariables.transparent }}
																		label={ matchObject.task_units }
																		variant='outlined'
																	/> */}
                                </TableCell>
                                <TableCell>{(matchObject.similarity * 100).toFixed(0)}%</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  }
                  tooltipPlacement="left"
                />
              )
            }
            let cellJSX = (
              <Box>
                {suggestionIcon}
                {rJSX_UnitWorkTypeChip(
                  rowData,
                  tableAdditionalData.editable,
                  openTaskForm,
                  getProp(us_finalizedWeekPayroll_CustomMappingData, mappingDocumentKey, {}),
                )}
              </Box>
            )
            return cellJSX
          },
          cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
            let cellCSS = ''
            return cellCSS
          },
        },
      },
      task_units: {
        header: {
          header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
            return rLIB('Units')
          },
          header_sort_by: null,
          header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
            return ''
          },
        },
        cell: {
          cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
            let cellJSX = (
              <>
                {rJSX_TaskUnitsChip(
                  rowData,
                  tableAdditionalData.editable,
                  openTaskForm,
                  getProp(us_finalizedWeekPayroll_CustomMappingData, mappingDocumentKey, {}),
                  true,
                )}
              </>
            )
            return cellJSX
          },
          cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
            let cellCSS = ''
            return cellCSS
          },
        },
      },
    }
    return tableColumns_Tasks
  }

  const recacheProjectData = (): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      us_setRecachingProjectData(true)
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          recacheProjectAndTimesheetDataForPayroll(res_GCK.clientKey, us_mondayDateKey, us_weekCompletedTasks)
            .then((res_RPDFP) => {
              uc_setUserInterface_AlertDialogDisplay({
                display: true,
                alert: {
                  color: 'info',
                  header: rLIB('Project data re-cache complete') as JSX.Element,
                  icon: <Icon icon="database" />,
                  text: rLIB('Probably a good idea to do this after reviewing payroll and making sure nothing changes') as JSX.Element,
                },
              })
              us_setRecachingProjectData(false)
              ur_forceRerender()
            })
            .catch((rej_RPDFP) => {
              reject(rej_RPDFP)
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_RPDFP.error })
              us_setRecachingProjectData(false)
              ur_forceRerender()
            })
        })
        .catch((rej_GCK) => {
          reject(rej_GCK)
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          us_setRecachingProjectData(false)
          ur_forceRerender()
        })
    })
  }

  const resolveErrorOrWarning = (categoryKey: string, errorKey: string): TsType_UnknownPromise => {
    return new Promise((resolveOuter, reject) => {
      us_setLockingPayrollData(true)
      if (us_mondayDateKey != null) {
        uc_setUserInterface_FormDialogDisplay({
          display: true,
          form: {
            form: {
              formAdditionalData: {},
              formData: {},
              formInputs: {
                notes: {
                  data_type: 'string',
                  input_type: 'text_basic',
                  key: 'notes',
                  label: rLIB('Resolution Notes'),
                  required: true,
                },
              },
              formOnChange: (
                formAdditionalData: TsInterface_FormAdditionalData,
                formData: TsInterface_FormData,
                formInputs: TsInterface_FormInputs,
                formSettings: TsInterface_FormSettings,
              ) => {},
              formSettings: {},
              formSubmission: (
                formSubmittedData: TsInterface_FormSubmittedData,
                formAdditionalData: TsInterface_FormAdditionalData,
                formHooks: TsInterface_FormHooksObject,
              ) => {
                return new Promise((resolve, reject) => {
                  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseSetMergeDocument(
                        DatabaseRef_FinalizedPayroll_ErrorsAndWarningResolutions_Document(res_GCK.clientKey, us_mondayDateKey, categoryKey),
                        {
                          [errorKey]: {
                            notes: formSubmittedData.notes,
                            timestamp_resolved: new Date(),
                            associated_resolver_name: getProp(uc_RootData_ClientUser, 'name', null),
                          },
                        },
                      )
                        .then((res_DSMD) => {
                          resolve(res_DSMD)
                          resolveOuter(res_DSMD)
                        })
                        .catch((rej_DSMD) => {
                          reject(rej_DSMD)
                        })
                    })
                    .catch((rej_GCK) => {
                      reject(rej_GCK)
                    })
                })
              },
            },
            dialog: {
              formDialogHeaderColor: 'success',
              formDialogHeaderText: <>{rLIB('Resolve Error')}</>,
              formDialogIcon: (
                <Icon
                  type="solid"
                  icon="circle-exclamation-check"
                />
              ),
            },
          },
        })
      } else {
        reject({
          success: false,
          error: {
            message: rLIB('Failed to save error resolution'),
            details: rLIB('Missing required parameters'),
            code: 'ER-D-PI-REOW-01',
          },
        })
      }
    })
  }

  const unresolveErrorOrWarning = (categoryKey: string, errorKey: string): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      us_setLockingPayrollData(true)
      if (us_mondayDateKey != null) {
        uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'error',
            header: rLIB('Unresolve Error'),
            icon: (
              <Icon
                icon="circle-exclamation"
                type="solid"
              />
            ),
            submit_text: rLIB('Unresolve'),
            text: <>{rLIB('Are you sure that you want to unresolve this error')}</>,
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    DatabaseSetMergeDocument(
                      DatabaseRef_FinalizedPayroll_ErrorsAndWarningResolutions_Document(res_GCK.clientKey, us_mondayDateKey, categoryKey),
                      {
                        [errorKey]: null,
                      },
                    )
                      .then((res_DSMD) => {
                        resolve(res_DSMD)
                      })
                      .catch((rej_DSMD) => {
                        reject(rej_DSMD)
                      })
                  })
                  .catch((rej_GCK) => {
                    reject(rej_GCK)
                  })
              })
            },
          },
        })
      } else {
        reject({
          success: false,
          error: {
            message: rLIB('Failed to save error resolution'),
            details: rLIB('Missing required parameters'),
            code: 'ER-D-PI-UEOW-01',
          },
        })
      }
    })
  }

  const attemptToFindTaskWorkflow = (task: TsInterface_UnspecifiedObject, taskKey: string) => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let workflowProdDocuments: TsInterface_UnspecifiedObject = {}
        let promiseArray: TsType_UnknownPromise[] = []
        DatabaseGetCollection(DatabaseRef_TaskWorkflows_Collection(res_GCK.clientKey))
          .then((res_DGC) => {
            let taskWorkflows = res_DGC.data
            for (let loopWorkflowKey in taskWorkflows) {
              promiseArray.push(
                DatabaseGetDocument(DatabaseRef_TaskWorkflowProd_Document(res_GCK.clientKey, loopWorkflowKey))
                  .then((res_DGD) => {
                    workflowProdDocuments[loopWorkflowKey] = res_DGD.data
                  })
                  .catch((rej_DGD) => {
                    // console.error( rej_DGD )
                  }),
              )
            }
            Promise.all(promiseArray).finally(() => {
              // Look for
              let associatedWorkflow = null
              for (let loopWorkflowProdKey in workflowProdDocuments) {
                let loopWorkflowProd = workflowProdDocuments[loopWorkflowProdKey]
                if (loopWorkflowProd != null && loopWorkflowProd['tasks'] != null && loopWorkflowProd['tasks'][taskKey] != null) {
                  associatedWorkflow = loopWorkflowProdKey
                }
              }
              if (associatedWorkflow != null && taskWorkflows[associatedWorkflow] != null && taskWorkflows[associatedWorkflow]['name'] != null) {
                uc_setUserInterface_AlertDialogDisplay({
                  display: true,
                  alert: {
                    color: 'info',
                    header: rLIB('Found Workflow') as JSX.Element,
                    icon: <Icon icon="magnifying-glass" />,
                    text: (
                      <Box>
                        <Box>{task.name}</Box>
                        <Box>
                          {rLIB('Workflow Version')}: {taskWorkflows[associatedWorkflow]['workflow_version_number']}
                        </Box>
                        <Box>{taskWorkflows[associatedWorkflow]['name']}</Box>
                      </Box>
                    ),
                  },
                })
              } else {
                uc_setUserInterface_AlertDialogDisplay({
                  display: true,
                  alert: {
                    color: 'error',
                    header: rLIB('No Workflow Found') as JSX.Element,
                    icon: <Icon icon="magnifying-glass" />,
                    text: rLIB('Did Not Find Associated Workflow') as JSX.Element,
                  },
                })
              }
            })
          })
          .catch((rej_DGC) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DGC.error })
          })
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  }

  const createPayrollAdjustment = () => {
    // Get Internal User Options
    let internalUserOptions: TsInterface_UnspecifiedObject = {}
    for (let loopUserKey in getProp(us_aggregatedWeekPunchData, 'internalUsers', {})) {
      let loopUser = getProp(us_aggregatedWeekPunchData.internalUsers, loopUserKey, {})
      if (loopUser != null && loopUser.hourly_or_salaried === 'hourly' && loopUser.status === 'active') {
        internalUserOptions[loopUserKey] = {
          key: loopUserKey,
          value: loopUser.name,
        }
      }
    }
    // Open Dialog
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: {
            associated_user_key: {
              data_type: 'string',
              input_type: 'multiple_choice_select',
              key: 'associated_user_key',
              label: rLIB('Employee'),
              required: true,
              options: objectToArray(internalUserOptions).sort(dynamicSort('value', 'asc')),
            },
            timestamp_apply_after: {
              data_type: 'string',
              input_type: 'timestamp_datetime',
              key: 'timestamp_apply_after',
              label: rLIB('Apply After'),
              required: true,
            },
            type: {
              data_type: 'string',
              input_type: 'multiple_choice_radio',
              key: 'type',
              label: rLIB('Adjustment Type'),
              required: true,
              options: [
                { key: 'payroll_bonus_increase', value: rLIB('Addition') },
                { key: 'payroll_bonus_decrease', value: rLIB('Reduction') },
              ],
            },
            amount: {
              data_type: 'number',
              input_type: 'text_number',
              key: 'amount',
              label: rLIB('Adjustment Amount'),
              required: true,
            },
            notes: {
              data_type: 'string',
              input_type: 'text_multiline',
              key: 'notes',
              label: rLIB('Notes'),
              required: true,
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let updateObject: TsInterface_UnspecifiedObject = {
                    // associated_distance_adder_user_date_key
                    // associated_task_key
                    // associated_task_name
                    // associated_project_id_number
                    associated_creator_key: getProp(uc_RootData_ClientUser, 'key', null),
                    associated_creator_name: getProp(uc_RootData_ClientUser, 'name', null),
                    associated_user_key: formSubmittedData.associated_user_key,
                    associated_user_name: getProp(getProp(internalUserOptions, formSubmittedData.associated_user_key, {}), 'value', null),
                    notes: formSubmittedData.notes,
                    remaining_adjustment_amount: Math.abs(formSubmittedData.amount),
                    status: 'unresolved',
                    timestamp_created: new Date(),
                    timestamp_apply_after: new Date(formSubmittedData.timestamp_apply_after),
                    total_adjustment_amount: Math.abs(formSubmittedData.amount),
                    type: formSubmittedData.type,
                  }
                  DatabaseAddDocument(DatabaseRef_PayrollAdjustments_Collection(res_GCK.clientKey), updateObject, true)
                    .then((res_DAD) => {
                      resolve({ success: true })
                    })
                    .catch((rej_DAD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
                      reject(rej_DAD)
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('New Adjustment')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>, additionalFileUploadParams: TsInterface_UnspecifiedObject): Promise<string[][]> => {
    return new Promise((resolve, reject) => {
      if (!event.target.files || event.target.files.length === 0) {
        console.log('No files selected')
        reject('No files selected')
        return
      }
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.onload = (e: ProgressEvent<FileReader>) => {
        try {
          const data = e.target?.result
          const workbook = XLSX.read(data, { type: 'binary' })
          const firstSheetName = workbook.SheetNames[0]
          const worksheet = workbook.Sheets[firstSheetName]

          // Get the worksheet range
          const range = XLSX.utils.decode_range(worksheet['!ref'] || 'A1')

          // Manually construct the array to ensure we get all rows
          const result: string[][] = []
          for (let R = range.s.r; R <= range.e.r + 1; ++R) {
            const row: string[] = []
            for (let C = range.s.c; C <= range.e.c; ++C) {
              const cellRef = XLSX.utils.encode_cell({ r: R, c: C })
              const cell = worksheet[cellRef]
              row.push(cell ? cell.v : '')
            }
            result.push(row)
          }

          // Very Specific File Formatting
          let headerRowIndex = null
          for (let loopRowIndex in result) {
            let loopRow = result[loopRowIndex]
            if (loopRow[0] != null && loopRow[0].trim() === 'Employee Name') {
              headerRowIndex = loopRowIndex
            }
          }
          if (headerRowIndex != null) {
            let headerRow = result[parseInt(headerRowIndex)]
            let dataRows = result.slice(parseInt(headerRowIndex) + 1)

            // Loop through and generate objects using headerRow as keys
            let objectArray: TsInterface_UnspecifiedObject[] = []
            for (let loopDataRowIndex in dataRows) {
              let loopDataRow = dataRows[loopDataRowIndex]
              let object: TsInterface_UnspecifiedObject = {}
              for (let loopHeaderIndex in headerRow) {
                object[headerRow[loopHeaderIndex].trim()] = loopDataRow[loopHeaderIndex]
              }
              objectArray.push(object)
            }
            // Create Update Object
            let updateObject: TsInterface_UnspecifiedObject = {}
            for (let loopObjectIndex in objectArray) {
              let loopObject = objectArray[loopObjectIndex]
              if (loopObject['Employee ID'] != null) {
                updateObject[loopObject['Employee ID']] = loopObject
              }
            }
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
              DatabaseSetReplaceDocument(DatabaseRef_FinalizedPayroll_UploadedPTOFile_Document(res_GCK.clientKey, us_mondayDateKey as string), updateObject)
                .then((res_DSD) => {
                  resolve(result)
                })
                .catch((rej_DSD) => {
                  reject(rej_DSD)
                })
            })
          }
        } catch (error) {
          console.error('Error parsing Excel file:', error)
          reject('Error parsing Excel file')
        }
      }
      reader.onerror = (error) => {
        console.error('Error reading file:', error)
        reject('Error reading file')
      }
      reader.readAsBinaryString(file)
    })
  }

  const maxOutAllAdjustments = (mondayDateKey: string) => {
    us_setMaxingOutAdjustments(true)
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        // Instantiate Variables
        let promiseArray: TsType_UnknownPromise[] = []
        let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
          // { type: 'setMerge', ref: DatabaseRef_PayrollAdjustments_Document(res_GCK.clientKey, loopAdjustmentKey), data: {} },
        ]
        // Get Users On Payroll this week
        let userBonusAmounts: TsInterface_UnspecifiedObject = {}
        for (let loopUserKey in getProp(us_displayedWeekPayrollData, 'employee_full_payroll_data', {})) {
          let loopUser = getProp(us_displayedWeekPayrollData['employee_full_payroll_data'], loopUserKey, {})
          if (loopUser != null && loopUser['unit_pay_bonus'] != null) {
            userBonusAmounts[loopUserKey] = loopUser['unit_pay_bonus']
          }
        }
        // Get Adjustment Amounts by User
        let adjustmentTotalAmounts: TsInterface_UnspecifiedObject = {}
        let userTotalAdjustmentAmounts: TsInterface_UnspecifiedObject = {}
        let userAdditionAdjustments: TsInterface_UnspecifiedObject = {}
        let userReductionAdjustments: TsInterface_UnspecifiedObject = {}
        for (let loopAdjustmentKey in us_combinedPayrollAdjustments) {
          let loopAdjustment = us_combinedPayrollAdjustments[loopAdjustmentKey]
          if (loopAdjustment != null && loopAdjustment['associated_user_key'] != null) {
            let adjustmentUserKey = loopAdjustment['associated_user_key']
            // Create Adjustment records if they do not exist
            if (adjustmentTotalAmounts[loopAdjustmentKey] == null) {
              adjustmentTotalAmounts[loopAdjustmentKey] = 0
            }
            // Create User records if they do not exist
            if (userTotalAdjustmentAmounts[adjustmentUserKey] == null) {
              userTotalAdjustmentAmounts[adjustmentUserKey] = 0
            }
            if (loopAdjustment.type === 'payroll_bonus_decrease') {
              if (userReductionAdjustments[adjustmentUserKey] == null) {
                userReductionAdjustments[adjustmentUserKey] = {}
              }
            }
            if (loopAdjustment.type === 'payroll_bonus_increase') {
              if (userAdditionAdjustments[adjustmentUserKey] == null) {
                userAdditionAdjustments[adjustmentUserKey] = {}
              }
            }
            // Handle Applied Adjustment Amounts
            if (
              loopAdjustment['associated_payroll_week_adjustment_amounts'] != null &&
              loopAdjustment['associated_payroll_week_adjustment_amounts'][mondayDateKey] != null &&
              !isNaN(loopAdjustment['associated_payroll_week_adjustment_amounts'][mondayDateKey])
            ) {
              if (loopAdjustment.type === 'payroll_bonus_decrease') {
                adjustmentTotalAmounts[loopAdjustmentKey] -= loopAdjustment['associated_payroll_week_adjustment_amounts'][mondayDateKey]
                userTotalAdjustmentAmounts[adjustmentUserKey] -= loopAdjustment['associated_payroll_week_adjustment_amounts'][mondayDateKey]
                userReductionAdjustments[adjustmentUserKey][loopAdjustmentKey] = loopAdjustment
              }
              if (loopAdjustment.type === 'payroll_bonus_increase') {
                adjustmentTotalAmounts[loopAdjustmentKey] += loopAdjustment['associated_payroll_week_adjustment_amounts'][mondayDateKey]
                userTotalAdjustmentAmounts[adjustmentUserKey] += loopAdjustment['associated_payroll_week_adjustment_amounts'][mondayDateKey]
                userAdditionAdjustments[adjustmentUserKey][loopAdjustmentKey] = loopAdjustment
              }
            }
            // Handle Remaining Adjustment Amounts
            if (loopAdjustment['remaining_adjustment_amount'] != null && !isNaN(loopAdjustment['remaining_adjustment_amount'])) {
              if (loopAdjustment.type === 'payroll_bonus_decrease') {
                adjustmentTotalAmounts[loopAdjustmentKey] -= loopAdjustment['remaining_adjustment_amount']
                userTotalAdjustmentAmounts[adjustmentUserKey] -= loopAdjustment['remaining_adjustment_amount']
                userReductionAdjustments[adjustmentUserKey][loopAdjustmentKey] = loopAdjustment
              }
              if (loopAdjustment.type === 'payroll_bonus_increase') {
                adjustmentTotalAmounts[loopAdjustmentKey] += loopAdjustment['remaining_adjustment_amount']
                userTotalAdjustmentAmounts[adjustmentUserKey] += loopAdjustment['remaining_adjustment_amount']
                userAdditionAdjustments[adjustmentUserKey][loopAdjustmentKey] = loopAdjustment
              }
            }
          }
        }
        // Loop through and apply adjustments
        // If the user bonus does not go negative, resolve all the adjustments
        for (let loopUserKey in userTotalAdjustmentAmounts) {
          // Get Adjustment Amounts for each user to determine how to proceed
          let loopUserTotalAdjustmentAmount = userTotalAdjustmentAmounts[loopUserKey]
          let loopUserBonusAmount = userBonusAmounts[loopUserKey]
          let loopUserReductionAdjustments = userReductionAdjustments[loopUserKey]
          let loopUserAdditionAdjustments = userAdditionAdjustments[loopUserKey]

          if (loopUserBonusAmount == null || loopUserBonusAmount === 0 || isNaN(loopUserBonusAmount)) {
            // If there is not a bonus for the user then all adjustments need to be removed for this week
            if (loopUserAdditionAdjustments != null) {
              for (let loopAdjustmentKey in loopUserAdditionAdjustments) {
                let loopAdjustment = loopUserAdditionAdjustments[loopAdjustmentKey]
                promiseArray.push(
                  removeAdjustmentFromPayrollWeek(res_GCK.clientKey, mondayDateKey, loopAdjustmentKey, loopAdjustment, 'return')
                    .then((res_RAFPW) => {
                      for (let loopIndex in getProp(res_RAFPW, 'updateArray', [])) {
                        updateArray.push(getProp(res_RAFPW, 'updateArray', [])[loopIndex])
                      }
                    })
                    .catch((rej_RAFPW) => {
                      console.error(rej_RAFPW)
                    }),
                )
              }
            }
            if (loopUserReductionAdjustments != null) {
              for (let loopAdjustmentKey in loopUserReductionAdjustments) {
                let loopAdjustment = loopUserReductionAdjustments[loopAdjustmentKey]
                promiseArray.push(
                  removeAdjustmentFromPayrollWeek(res_GCK.clientKey, mondayDateKey, loopAdjustmentKey, loopAdjustment, 'return')
                    .then((res_RAFPW) => {
                      for (let loopIndex in getProp(res_RAFPW, 'updateArray', [])) {
                        updateArray.push(getProp(res_RAFPW, 'updateArray', [])[loopIndex])
                      }
                    })
                    .catch((rej_RAFPW) => {
                      console.error(rej_RAFPW)
                    }),
                )
              }
            }
          } else if (loopUserBonusAmount + loopUserTotalAdjustmentAmount >= 0) {
            // If the user bonus stays positive, apply all adjustments
            if (loopUserAdditionAdjustments != null) {
              for (let loopAdjustmentKey in loopUserAdditionAdjustments) {
                let loopAdjustment = loopUserAdditionAdjustments[loopAdjustmentKey]
                let amountToApply = adjustmentTotalAmounts[loopAdjustmentKey]
                if (!isNaN(amountToApply)) {
                  promiseArray.push(
                    includeAdjustmentInPayrollWeek(res_GCK.clientKey, mondayDateKey, loopAdjustmentKey, loopAdjustment, amountToApply, 'return')
                      .then((res_IAIPW) => {
                        for (let loopIndex in getProp(res_IAIPW, 'updateArray', [])) {
                          updateArray.push(getProp(res_IAIPW, 'updateArray', [])[loopIndex])
                        }
                      })
                      .catch((rej_IAIPW) => {
                        console.error(rej_IAIPW)
                      }),
                  )
                }
              }
            }
            if (loopUserReductionAdjustments != null) {
              for (let loopAdjustmentKey in loopUserReductionAdjustments) {
                let loopAdjustment = loopUserReductionAdjustments[loopAdjustmentKey]
                let amountToApply = adjustmentTotalAmounts[loopAdjustmentKey]
                if (!isNaN(amountToApply)) {
                  promiseArray.push(
                    includeAdjustmentInPayrollWeek(res_GCK.clientKey, mondayDateKey, loopAdjustmentKey, loopAdjustment, amountToApply, 'return')
                      .then((res_IAIPW) => {
                        for (let loopIndex in getProp(res_IAIPW, 'updateArray', [])) {
                          updateArray.push(getProp(res_IAIPW, 'updateArray', [])[loopIndex])
                        }
                      })
                      .catch((rej_IAIPW) => {
                        console.error(rej_IAIPW)
                      }),
                  )
                }
              }
            }
          } else {
            // Starting Balance
            let loopUserBalance = loopUserBonusAmount
            // Apply all the positive adjustments and then apply the negative adjustments until the bonus is zero
            if (loopUserAdditionAdjustments != null) {
              for (let loopAdjustmentKey in loopUserAdditionAdjustments) {
                let loopAdjustment = loopUserAdditionAdjustments[loopAdjustmentKey]
                let amountToApply = adjustmentTotalAmounts[loopAdjustmentKey]
                loopUserBalance += amountToApply
                if (!isNaN(amountToApply)) {
                  promiseArray.push(
                    includeAdjustmentInPayrollWeek(res_GCK.clientKey, mondayDateKey, loopAdjustmentKey, loopAdjustment, amountToApply, 'return')
                      .then((res_IAIPW) => {
                        for (let loopIndex in getProp(res_IAIPW, 'updateArray', [])) {
                          updateArray.push(getProp(res_IAIPW, 'updateArray', [])[loopIndex])
                        }
                      })
                      .catch((rej_IAIPW) => {
                        console.error(rej_IAIPW)
                      }),
                  )
                }
              }
            }
            // Sort negative adjustments by timestamp_created
            let sortedNegativeAdjustments = objectToArray(loopUserReductionAdjustments).sort(dynamicSort('timestamp_created', 'asc'))
            for (let loopNegativeAdjustmentIndex in sortedNegativeAdjustments) {
              let loopAdjustment = sortedNegativeAdjustments[loopNegativeAdjustmentIndex]
              let loopAdjustmentKey = sortedNegativeAdjustments[loopNegativeAdjustmentIndex].key
              let amountToApply = adjustmentTotalAmounts[loopAdjustmentKey]
              if (loopUserBalance === 0) {
                // Remove Adjustment
                promiseArray.push(
                  removeAdjustmentFromPayrollWeek(res_GCK.clientKey, mondayDateKey, loopAdjustmentKey, loopAdjustment, 'return')
                    .then((res_RAFPW) => {
                      for (let loopIndex in getProp(res_RAFPW, 'updateArray', [])) {
                        updateArray.push(getProp(res_RAFPW, 'updateArray', [])[loopIndex])
                      }
                    })
                    .catch((rej_RAFPW) => {
                      console.error(rej_RAFPW)
                    }),
                )
              } else if (amountToApply + loopUserBalance >= 0) {
                // Full Adjustment Application
                if (!isNaN(amountToApply)) {
                  loopUserBalance += amountToApply
                  promiseArray.push(
                    includeAdjustmentInPayrollWeek(res_GCK.clientKey, mondayDateKey, loopAdjustmentKey, loopAdjustment, amountToApply, 'return')
                      .then((res_IAIPW) => {
                        for (let loopIndex in getProp(res_IAIPW, 'updateArray', [])) {
                          updateArray.push(getProp(res_IAIPW, 'updateArray', [])[loopIndex])
                        }
                      })
                      .catch((rej_IAIPW) => {
                        console.error(rej_IAIPW)
                      }),
                  )
                }
              } else {
                // Partial Adjustment Application
                let partialAmountToApply = loopUserBalance
                loopUserBalance = 0
                if (!isNaN(partialAmountToApply)) {
                  promiseArray.push(
                    includeAdjustmentInPayrollWeek(res_GCK.clientKey, mondayDateKey, loopAdjustmentKey, loopAdjustment, partialAmountToApply, 'return')
                      .then((res_IAIPW) => {
                        for (let loopIndex in getProp(res_IAIPW, 'updateArray', [])) {
                          updateArray.push(getProp(res_IAIPW, 'updateArray', [])[loopIndex])
                        }
                      })
                      .catch((rej_IAIPW) => {
                        console.error(rej_IAIPW)
                      }),
                  )
                }
              }
            }
          }
        }
        Promise.all(promiseArray).finally(() => {
          DatabaseStagedBatchUpdate(updateArray)
            .then((res_DSBU) => {
              us_setMaxingOutAdjustments(false)
            })
            .catch((rej_DSBU) => {
              console.error(rej_DSBU)
              us_setMaxingOutAdjustments(false)
            })
        })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
        us_setMaxingOutAdjustments(false)
      })
  }

  const getMondayDate = (): Date => {
    const today = new Date()
    const day = today.getDay()
    // Adjust to get previous Monday (Sunday = 0, Monday = 1, etc.)
    const diff = today.getDate() - day + (day === 0 ? -6 : 1)
    const monday = new Date(today.setDate(diff))
    // Set to midnight
    monday.setHours(0, 0, 0, 0)
    return monday
  }

  // Get Next Monday
  const getNextMondayDate = (): Date => {
    const today = new Date()
    const day = today.getDay()
    const diff = today.getDate() - day + (day === 0 ? -6 : 1) + 7
    const monday = new Date(today.setDate(diff))
    monday.setHours(0, 0, 0, 0)
    return monday
  }

  function getWeekRanges(startDate: Date, endDate: Date): { [key: string]: { start: Date; end: Date } } {
    // Initialize result object
    const result: { [key: string]: { start: Date; end: Date } } = {}
    // Clone dates to avoid modifying originals
    const currentDate = new Date(startDate)
    const finalDate = new Date(endDate)
    // Set times to midnight
    currentDate.setHours(0, 0, 0, 0)
    finalDate.setHours(0, 0, 0, 0)
    // Find first Monday before or on start date
    while (currentDate.getDay() !== 1) {
      // 1 = Monday
      currentDate.setDate(currentDate.getDate() - 1)
    }
    // Iterate through weeks until we pass the end date
    while (currentDate <= finalDate) {
      // Get week number
      const weekNum = getWeekNumber(currentDate)
      // Create week range
      const mondayDate = new Date(currentDate)
      const sundayDate = new Date(currentDate)
      sundayDate.setDate(sundayDate.getDate() + 7) // Next Monday instead of Sunday
      // Add to result
      result[weekNum.toString()] = {
        start: mondayDate,
        end: sundayDate,
      }
      // Move to next Monday
      currentDate.setDate(currentDate.getDate() + 7)
    }
    return result
  }

  // Helper function to get ISO week number
  function getWeekNumber(date: Date): number {
    const d = new Date(date)
    d.setHours(0, 0, 0, 0)
    d.setDate(d.getDate() + 4 - (d.getDay() || 7))
    const yearStart = new Date(d.getFullYear(), 0, 1)
    const weekNo = Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7)
    return weekNo
  }

  function getTrimmedPaycodeString(input: string): string {
    // Split the string by hyphens
    const parts = input.split('-')
    // Check if there are at least three parts
    if (parts.length > 2) {
      // Join parts after the second hyphen
      return parts.slice(2).join('-').trim()
    }
    // Return the original string if there are fewer than three parts
    return input
  }

  const downloadHoursVsUnitsReportProper = (clientKey: string, startDate: Date, endDate: Date): Promise<unknown> => {
    return new Promise((resolve, reject) => {
      let promiseArray = []
      let timecardData: TsInterface_UnspecifiedObject = {}
      let taskUnitMapping: TsInterface_UnspecifiedObject = {}
      // Take the start date and end date and create a list of weeks in the date range
      let weeksInRange = getWeekRanges(startDate, endDate)

      // Get All Timestamps for the weeks specified
      for (let loopWeekKey in weeksInRange) {
        let loopWeek = weeksInRange[loopWeekKey]
        promiseArray.push(
          returnAggregatePunchDataForPayrollCalculations(clientKey, loopWeek.start, loopWeek.end)
            .then((res_RAPDFPC) => {
              timecardData[loopWeekKey] = getProp(res_RAPDFPC, 'data', {})
            })
            .catch((rej_RAPDFPC) => {
              reject(rej_RAPDFPC)
            }),
        )
      }
      promiseArray.push(
        DatabaseGetDocument(DatabaseRef_PayrollRates_TaskUnitMapping_Document(clientKey))
          .then((res_DGTD) => {
            taskUnitMapping = res_DGTD.data
          })
          .catch((rej_DGTD) => {
            console.error(rej_DGTD)
          }),
      )
      Promise.all(promiseArray).finally(() => {
        // Loop through data and create rows for each task on each employee
        let csvData: string[][] = [['Employee', 'Week Number', 'Punch Description', 'Clocked Hours', 'Task Units (if applicable)', 'Unit Type']]
        for (let loopWeekKey in timecardData) {
          let loopWeekData = timecardData[loopWeekKey]
          for (let loopEmployeeKey in loopWeekData) {
            let loopEmployeeData = loopWeekData[loopEmployeeKey]

            let invertedTaskList: TsInterface_UnspecifiedObject = {}
            for (let loopTaskKey in getProp(loopEmployeeData, 'associated_tasks', {})) {
              let loopTaskValue = getProp(loopEmployeeData, 'associated_tasks', {})[loopTaskKey]
              invertedTaskList[loopTaskValue] = loopTaskKey
            }

            for (let loopCodeKey in getProp(loopEmployeeData, 'raw_codes', {})) {
              let loopDataRow: string[] = []
              let loopCodeValue = getProp(loopEmployeeData, 'raw_codes', {})[loopCodeKey]
              let trimmedTaskStringName = getTrimmedPaycodeString(loopCodeKey)

              loopDataRow.push(getProp(loopEmployeeData, 'name', ''))
              loopDataRow.push(loopWeekKey)
              loopDataRow.push(loopCodeKey)
              loopDataRow.push(loopCodeValue.toFixed(2))

              if (loopCodeKey != null && trimmedTaskStringName != null && invertedTaskList[trimmedTaskStringName] != null) {
                // Look for unit mapping match
                let addedUnits = false
                let foundMatch = false
                // Loop through task unit mapping
                for (let loopTaskKey in taskUnitMapping) {
                  let loopUnitMappingTask = taskUnitMapping[loopTaskKey]
                  if (invertedTaskList[trimmedTaskStringName].indexOf(loopTaskKey) > -1 && foundMatch === false) {
                    if (loopUnitMappingTask['task_unit_evaluation'] === 'hardcode' && loopUnitMappingTask['task_units'] != null) {
                      foundMatch = true
                      if (addedUnits === false) {
                        loopDataRow.push(loopUnitMappingTask['task_units'])
                        loopDataRow.push('units')
                        addedUnits = true
                      }
                    } else {
                      foundMatch = true
                      if (addedUnits === false) {
                        loopDataRow.push('')
                        if (loopUnitMappingTask['task_unit_evaluation'] === 'panels') {
                          loopDataRow.push('panels')
                        } else {
                          loopDataRow.push('units')
                        }
                        addedUnits = true
                      }
                    }
                  }
                }
              } else {
                loopDataRow.push('')
              }
              csvData.push(loopDataRow)
            }
          }
        }
        downloadCSV('Hours and Units Report', csvData)
        resolve({ success: true })
      })
    })
  }

  const downloadHoursVsUnitsReport = (): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {
            // Monday of this week
            start_date: returnFormattedDate(getMondayDate(), 'YYYY-MM-DDTHH:mm'),
            end_date: returnFormattedDate(getNextMondayDate(), 'YYYY-MM-DDTHH:mm'),
          },
          formInputs: {
            start_date: {
              key: 'start_date',
              label: rLIB('Start Date'),
              input_type: 'timestamp_datetime',
              required: true,
              data_type: 'string',
            },
            end_date: {
              key: 'end_date',
              label: rLIB('End Date'),
              input_type: 'timestamp_datetime',
              required: true,
              data_type: 'string',
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {
            submit_button_icon: <Icon icon="cloud-arrow-down" />,
            submit_button_text: <>{rLIB('Download')}</>,
          },
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  downloadHoursVsUnitsReportProper(res_GCK.clientKey, new Date(formSubmittedData.start_date), new Date(formSubmittedData.end_date))
                    .then((res_DHVRP) => {
                      resolve(res_DHVRP)
                    })
                    .catch((rej_DHVRP) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DHVRP.error })
                      reject(rej_DHVRP)
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('Hours and Units Download')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="cloud-arrow-down"
            />
          ),
        },
      },
    })
  }

  // JSX Generation
  const rJSX_CombineManagementButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = <></>
    if (shrink === true) {
      buttonJSX = (
        <Tooltip
          title={rLIB('Combine Management')}
          placement="right"
        >
          <Button
            className="tw-mr-2 bp_icon_only_button"
            variant="outlined"
            color="inherit"
            startIcon={<Icon icon="users" />}
            onClick={(event) => {
              onClickAppNavigation(event, un_routerNavigation, ApplicationPages.CombineManagementPage.url())
            }}
          ></Button>
        </Tooltip>
      )
    } else {
      buttonJSX = (
        <Button
          className="tw-mr-2"
          variant="outlined"
          color="inherit"
          startIcon={<Icon icon="users" />}
          onClick={(event) => {
            onClickAppNavigation(event, un_routerNavigation, ApplicationPages.CombineManagementPage.url())
          }}
        >
          {rLIB('Combine Management')}
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_LockButton = (shrink: boolean): JSX.Element => {
    // Instantiate Variables
    let buttonJSX = <></>
    let analysisLocked = getProp(us_finalizedWeekPayrollRoot, 'locked', false)
    // Button
    let buttonIcon = <Icon icon="lock" />
    let buttonText = rLIB('Lock Payroll')
    if (analysisLocked === true) {
      buttonIcon = <Icon icon="lock-open" />
      buttonText = rLIB('Unlock Payroll')
    }
    let buttonVariant: 'contained' | 'outlined' = 'outlined'
    // Disable after locked for 5 days
    let lockedAndPast5Days = false
    let today = new Date()
    let lockedTimestamp = getProp(us_finalizedWeekPayrollRoot, 'timestamp_locked', null)
    if (analysisLocked === true && lockedTimestamp != null && getDaysBetweenDates(returnDateFromUnknownDateFormat(lockedTimestamp), today) > 5) {
      lockedAndPast5Days = true
    }
    if (shrink === true) {
      buttonJSX = (
        <Tooltip
          title={buttonText}
          placement="right"
        >
          <Button
            color="warning"
            variant={buttonVariant}
            className="tw-mr-2 tw-align-top bp_icon_only_button"
            disabled={us_lockingPayrollData === true || lockedAndPast5Days === true}
            startIcon={buttonIcon}
            onClick={() => {
              if (analysisLocked === true) {
                unlockWeekPayrollData()
                  .then((res_UWPD) => {
                    // Nothing
                  })
                  .catch((rej_UWPD) => {
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_UWPD.error })
                  })
              } else {
                lockWeekPayrollData()
                  .then((res_LWPD) => {
                    // Nothing
                  })
                  .catch((rej_LWPD) => {
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_LWPD.error })
                  })
              }
            }}
          ></Button>
        </Tooltip>
      )
    } else {
      buttonJSX = (
        <Button
          color="warning"
          variant={buttonVariant}
          className="tw-mr-2 tw-align-top"
          disabled={us_lockingPayrollData === true || lockedAndPast5Days === true}
          startIcon={buttonIcon}
          onClick={() => {
            if (analysisLocked === true) {
              unlockWeekPayrollData()
                .then((res_UWPD) => {
                  // Nothing
                })
                .catch((rej_UWPD) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_UWPD.error })
                })
            } else {
              lockWeekPayrollData()
                .then((res_LWPD) => {
                  // Nothing
                })
                .catch((rej_LWPD) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_LWPD.error })
                })
            }
          }}
        >
          {buttonText}
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_RefreshProjectDataButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = <></>
    let analysisLocked = getProp(us_finalizedWeekPayrollRoot, 'locked', false)
    // Button
    let buttonIcon = <Icon icon="database" />
    // JSX
    if (analysisLocked === false) {
      if (shrink === true) {
        buttonJSX = (
          <Tooltip
            title={rLIB('Re-cache Project Data')}
            placement="right"
          >
            <Button
              color="info"
              variant="outlined"
              className="tw-mr-2 tw-align-top bp_icon_only_button"
              disabled={us_recachingProjectData === true}
              startIcon={buttonIcon}
              onClick={() => {
                us_setRecachingProjectData(true)
                recacheProjectData()
              }}
            ></Button>
          </Tooltip>
        )
      } else {
        buttonJSX = (
          <Button
            color="info"
            variant="outlined"
            className="tw-mr-2 tw-align-top"
            disabled={us_recachingProjectData === true}
            startIcon={buttonIcon}
            onClick={() => {
              us_setRecachingProjectData(true)
              recacheProjectData()
            }}
          >
            {rLIB('Re-cache Project Data')}
          </Button>
        )
      }
    }
    return buttonJSX
  }

  const rJSX_RefreshTaskPayrollHoursButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = <></>
    // Button
    let buttonIcon = <Icon icon="database" />
    // JSX
    if (shrink === true) {
      buttonJSX = (
        <Tooltip
          title={rLIB('Cache Task Payroll Hours')}
          placement="right"
        >
          <Button
            color="primary"
            variant="outlined"
            className="tw-mr-2 tw-align-top bp_icon_only_button"
            disabled={us_recachingProjectData === true}
            startIcon={buttonIcon}
            onClick={() => {
              us_setRecachingProjectData(true)
              TEMP_buildCachedTaskPayrollHours()
            }}
          ></Button>
        </Tooltip>
      )
    } else {
      buttonJSX = (
        <Button
          color="primary"
          variant="outlined"
          className="tw-mr-2 tw-align-top"
          disabled={us_recachingProjectData === true}
          startIcon={buttonIcon}
          onClick={() => {
            us_setRecachingProjectData(true)
            TEMP_buildCachedTaskPayrollHours()
          }}
        >
          {rLIB('Cache Task Payroll Hours')}
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_CalculationTimestamps = (): JSX.Element => {
    let timestampsJSX = <></>
    if (us_finalizedWeekPayrollRoot != null && us_finalizedWeekPayrollRoot.timestamp_locked != null) {
      timestampsJSX = (
        <Box className="tw-inline-block tw-opacity-30">
          <Box>
            {rLIB('Payroll Locked')}: {returnFormattedDate(us_finalizedWeekPayrollRoot.timestamp_locked, 'D MMM YY h:mm a')}
          </Box>
        </Box>
      )
    }
    return timestampsJSX
  }

  const rJSX_DatePicker = (disabled: boolean): JSX.Element => {
    let datePickerJSX = (
      <Box className="tw-inline-block tw-align-top tw-mr-2">
        <DatePicker
          key={'calendar_date'}
          datePickerText={rLIB('Date')}
          datePickerDate={us_selectedDate}
          datePickerDateOnChange={changeCalendarDate}
          datePickerSettings={{ thin_input: true }}
          datePickerDisabled={disabled}
        />
      </Box>
    )
    return datePickerJSX
  }

  const rJSX_SettingsButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        color="inherit"
        variant="outlined"
        onClick={(event) => {
          onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminPayrollSettingsPage.url())
        }}
        disableElevation
        className="tw-mr-2 tw-align-top"
        sx={{ height: '36px' }}
      >
        <Icon
          icon="gear"
          sx={{ fontSize: '18px' }}
        />
      </Button>
    )
    return buttonJSX
  }

  // Summary
  const rJSX_SummaryTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Box>
        <Box>
          <Card>
            <TableBasic
              tableAdditionalData={{
                payroll_week_data_locked: getProp(us_finalizedWeekPayrollRoot, 'locked', false),
                us_employeePayrollDataTotals: us_employeePayrollDataTotals,
                us_employeePayrollDiscrepancyDataTotals: us_employeePayrollDiscrepancyDataTotals,
                us_discrepancyWeekPayrollData: us_discrepancyWeekPayrollData,
                us_usersExcludedFromPayrollTotals: us_usersExcludedFromPayrollTotals,
                us_weekEndDate: us_weekEndDate,
                us_weekStartDate: us_weekStartDate,
              }}
              tableColumns={tableColumns_PayrollSummary}
              tableData={objectToArray(getProp(us_displayedWeekPayrollData, 'employee_full_payroll_data', {}))}
              tableSettings={us_tableSettings_PayrollSummary}
            />
          </Card>
          {rJSX_CalculationTimestamps()}
        </Box>
      </Box>
    )
    return tabJSX
  }

  // Unit Pay Breakdown
  const rJSX_UnitPayTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Box>
        {/* { objectToArray( getProp( us_displayedWeekPayrollData, "unit_pay_tasks", {} ) ).length } */}
        <Card>
          <TableBasic
            tableAdditionalData={{
              us_finalizedWeekPayrollRoot: us_finalizedWeekPayrollRoot,
              us_displayedWeekPayrollData: us_displayedWeekPayrollData,
              us_weekEndDate: us_weekEndDate,
              us_weekStartDate: us_weekStartDate,
            }}
            tableColumns={tableColumns_UnitPayBreakdown}
            // tableData={objectToArray(getProp(us_displayedWeekPayrollData, 'unit_pay_tasks', {}))}
            tableData={objectToArray(us_filteredUnitPayTaskList)}
            tableSettings={us_tableSettings_UnitPayBreakdown}
          />
        </Card>
      </Box>
    )
    return tabJSX
  }

  const rJSX_FilterUnitPayDropdown = (shrink: boolean): JSX.Element => {
    let buttonJSX = <></>
    buttonJSX = (
      <FormControl className="bp_thin_select_input tw-mr-2">
        <Select
          color="primary"
          value={us_filterUnitPayDropdownValue}
          onChange={(event: any) => {
            if (event != null && event.target != null && event.target.value != null) {
              us_setFilterUnitPayDropdownValue(event.target.value)
            }
          }}
          variant="outlined"
        >
          <MenuItem value={'ALL'}>{rLIB('All Employees')} </MenuItem>
          {objectToArray(us_unitPayTaskEmployeeList)
            .sort(dynamicSort('name', 'asc'))
            .map((employee: TsInterface_UnspecifiedObject, index: number) => (
              <MenuItem
                key={index}
                value={employee.key}
              >
                {employee.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    )
    return buttonJSX
  }

  // Distance Adders
  // const rJSX_DistanceAddersTab = (): JSX.Element => {
  //   let tabJSX = (
  //     <Box>
  //       <Card>
  //         <TableBasic
  //           tableAdditionalData={{}}
  //           tableColumns={us_tableColumns_DistanceAdders}
  //           tableData={objectToArray(us_tableData_DistanceAdders)}
  //           tableSettings={us_tableSettings_DistanceAdders}
  //         />
  //       </Card>
  //     </Box>
  //   )
  //   return tabJSX
  // }

  // Required Data Entry
  const rJSX_DataEntryHeader = (): JSX.Element => {
    let headerJSX = rLIB('Data Entry')
    let totalErrors = 0
    let customAndAdHocTaskUnitMapping = getProp(us_finalizedWeekPayroll_CustomMappingData, 'custom_and_ad_hoc_task_unit_mapping', {})
    for (let loopTaskKey in us_displayedWeekPayrollData.ad_hoc_tasks) {
      let loopTaskMapping = getProp(customAndAdHocTaskUnitMapping, loopTaskKey, {})
      if (loopTaskMapping.task_units == null) {
        totalErrors++
      }
      if (loopTaskMapping.unit_work_type == null) {
        totalErrors++
      }
    }
    for (let loopTaskKey in us_displayedWeekPayrollData.tasks_with_custom_unit_mapping) {
      let loopTaskMapping = getProp(customAndAdHocTaskUnitMapping, loopTaskKey, {})
      if (loopTaskMapping.task_units == null) {
        totalErrors++
      }
      if (loopTaskMapping.unit_work_type == null) {
        totalErrors++
      }
    }
    let warningIconJSX = <></>
    if (us_finalizedWeekPayroll_UploadedPTOFile === null || objectToArray(us_finalizedWeekPayroll_UploadedPTOFile).length === 0) {
      warningIconJSX = (
        <Box className="tw-inline-block">
          <Icon
            icon="triangle-exclamation"
            className="tw-mr-1"
            sx={{ color: themeVariables.warning_main }}
          />
          <Box
            className="tw-inline-block tw-mr-1"
            sx={{ color: themeVariables.warning_main }}
          >
            1
          </Box>
        </Box>
      )
    }
    let errorIconJSX = <></>
    if (totalErrors > 0) {
      errorIconJSX = (
        <Box className="tw-inline-block">
          <Icon
            icon="triangle-exclamation"
            className="tw-mr-1"
            sx={{ color: themeVariables.error_main }}
          />
          <Box
            className="tw-inline-block tw-mr-1"
            sx={{ color: themeVariables.error_main }}
          >
            {totalErrors}
          </Box>
        </Box>
      )
      // } else {
      //   headerJSX = (
      //     <Box>
      //       <Icon
      //         icon="badge-check"
      //         className="tw-mr-1"
      //         sx={{ color: themeVariables.success_main }}
      //       />
      //       <Box
      //         className="tw-inline-block tw-mr-1"
      //         sx={{ color: themeVariables.success_main }}
      //       >
      //         {totalErrors}
      //       </Box>
      //     </Box>
      //   )
    }
    headerJSX = (
      <Box>
        {errorIconJSX}
        {warningIconJSX}
        {rLIB('Data Entry')}
      </Box>
    )
    return headerJSX
  }

  const rJSX_PtoTable = (): JSX.Element => {
    let tableJSX = <></>
    if (us_finalizedWeekPayroll_UploadedPTOFile === null || objectToArray(us_finalizedWeekPayroll_UploadedPTOFile).length === 0) {
      tableJSX = (
        <Box>
          <Typography
            variant="h6"
            className="tw-mt-4 tw-text-center"
            sx={{ color: themeVariables.warning_main }}
          >
            <Icon
              icon="triangle-exclamation"
              className="tw-mr-2"
            />
            {rLIB('No PTO file uploaded for this week')}
          </Typography>
        </Box>
      )
    } else {
      tableJSX = (
        <Card>
          <TableBasic
            tableAdditionalData={{}}
            tableColumns={{
              'Employee Name': TableCellBasic('Employee Name', rLIB('Employee Name'), 'Employee Name'),
              'Employee ID': TableCellBasic('Employee ID', rLIB('Employee ID'), 'Employee ID'),
              'Earned': TableCellBasic('Earned', rLIB('Earned'), 'Earned'),
              'Used': TableCellBasic('Used', rLIB('Used'), 'Used'),
              'Balance': TableCellBasic('Balance', rLIB('Balance'), 'Balance'),
              'Accrual Cap': TableCellBasic('Accrual Cap', rLIB('Accrual Cap'), 'Accrual Cap'),
              'Estimated Value': TableCellBasic('Estimated Value', rLIB('Estimated Value'), 'Estimated Value'),
            }}
            tableData={objectToArray(us_finalizedWeekPayroll_UploadedPTOFile).sort(dynamicSort('Employee Name', 'asc'))}
            tableSettings={{
              paginated: false,
              size: 'small',
            }}
          />
        </Card>
      )
    }
    return tableJSX
  }

  const rJSX_DataEntryTab = (): JSX.Element => {
    let tabJSX = <></>
    let customTasksTableJSX = <></>
    if (objectToArray(us_displayedWeekPayrollData.tasks_with_custom_unit_mapping).length > 0) {
      customTasksTableJSX = (
        <Card className="tw-mb-2">
          <TableBasic
            tableAdditionalData={{
              editable: !getProp(us_finalizedWeekPayrollRoot, 'locked', false),
              us_taskBlueprints: null,
              us_taskBlueprintUnitMapping: null,
            }}
            tableColumns={returnTableColumns_CustomUnitMapping('custom_and_ad_hoc_task_unit_mapping', openCustomAndAdHocTaskUnitMappingPropertyEditForm)}
            tableData={objectToArray(us_displayedWeekPayrollData.tasks_with_custom_unit_mapping)}
            tableSettings={tableSettings_AdHocTasks}
          />
        </Card>
      )
    } else {
      customTasksTableJSX = (
        <Card className="tw-mb-2 tw-p-2 tw-text-center">
          <Typography
            variant="h6"
            className="tw-opacity-30"
          >
            {rLIB('No Custom Tasks')}
          </Typography>
        </Card>
      )
    }
    let adHocTasksTableJSX = <></>
    if (objectToArray(us_displayedWeekPayrollData.ad_hoc_tasks).length > 0) {
      adHocTasksTableJSX = (
        <Card className="tw-mb-2">
          <TableBasic
            tableAdditionalData={{
              editable: !getProp(us_finalizedWeekPayrollRoot, 'locked', false),
              us_taskBlueprints: us_taskBlueprints,
              us_taskBlueprintUnitMapping: us_taskBlueprintUnitMapping,
            }}
            tableColumns={returnTableColumns_CustomUnitMapping('custom_and_ad_hoc_task_unit_mapping', openCustomAndAdHocTaskUnitMappingPropertyEditForm)}
            tableData={objectToArray(us_displayedWeekPayrollData.ad_hoc_tasks)}
            tableSettings={tableSettings_AdHocTasks}
          />
        </Card>
      )
    } else {
      adHocTasksTableJSX = (
        <Card className="tw-mb-2 tw-p-2 tw-text-center">
          <Typography
            variant="h6"
            className="tw-opacity-30"
          >
            {rLIB('No Ad Hoc Tasks')}
          </Typography>
        </Card>
      )
    }
    // Full JSX
    tabJSX = (
      <Box>
        <Box className="tw-mt-2">
          <Typography
            variant="h6"
            className="tw-mb-2"
          >
            {rLIB('Custom Tasks')}
          </Typography>
          {customTasksTableJSX}
        </Box>
        <Box className="tw-mt-2">
          <Typography
            variant="h6"
            className="tw-mb-2"
          >
            {rLIB('Ad Hoc Tasks')}
          </Typography>
          {adHocTasksTableJSX}
        </Box>
        <Box className="tw-mt-2">
          <Typography
            variant="h6"
            className="tw-mb-2"
          >
            {rLIB('PTO')}
          </Typography>
          {rJSX_PtoTable()}
        </Box>
      </Box>
    )
    return tabJSX
  }

  // Errors
  const rJSX_ErrorsHeader = (): JSX.Element => {
    let headerJSX = rLIB('Errors')
    if (
      us_displayedWeekPayrollData != null &&
      us_displayedWeekPayrollData['error_and_warning_totals'] != null &&
      us_displayedWeekPayrollData['error_and_warning_totals']['total_error_counts'] != null &&
      us_displayedWeekPayrollData['error_and_warning_totals']['total_error_counts'] > 0 &&
      us_displayedWeekPayrollData['error_and_warning_totals']['total_warning_counts'] != null &&
      us_displayedWeekPayrollData['error_and_warning_totals']['total_warning_counts'] > 0
    ) {
      headerJSX = (
        <Box>
          <Icon
            icon="triangle-exclamation"
            className="tw-mr-1"
            sx={{ color: themeVariables.error_main }}
          />
          <Box
            className="tw-inline-block tw-mr-1"
            sx={{ color: themeVariables.error_main }}
          >
            {us_displayedWeekPayrollData['error_and_warning_totals']['total_error_counts']}
          </Box>
          <Icon
            icon="triangle-exclamation"
            className="tw-mr-1"
            sx={{ color: themeVariables.warning_main }}
          />
          <Box
            className="tw-inline-block tw-mr-1"
            sx={{ color: themeVariables.warning_main }}
          >
            {us_displayedWeekPayrollData['error_and_warning_totals']['total_warning_counts']}
          </Box>
          {rLIB('Errors')}
        </Box>
      )
    } else if (
      us_displayedWeekPayrollData != null &&
      us_displayedWeekPayrollData['error_and_warning_totals'] != null &&
      us_displayedWeekPayrollData['error_and_warning_totals']['total_error_counts'] != null &&
      us_displayedWeekPayrollData['error_and_warning_totals']['total_error_counts'] > 0
    ) {
      headerJSX = (
        <Box>
          <Icon
            icon="triangle-exclamation"
            className="tw-mr-1"
            sx={{ color: themeVariables.error_main }}
          />
          <Box
            className="tw-inline-block tw-mr-1"
            sx={{ color: themeVariables.error_main }}
          >
            {us_displayedWeekPayrollData['error_and_warning_totals']['total_error_counts']}
          </Box>
          {rLIB('Errors')}
        </Box>
      )
    } else if (
      us_displayedWeekPayrollData != null &&
      us_displayedWeekPayrollData['error_and_warning_totals'] != null &&
      us_displayedWeekPayrollData['error_and_warning_totals']['total_warning_counts'] != null &&
      us_displayedWeekPayrollData['error_and_warning_totals']['total_warning_counts'] > 0
    ) {
      headerJSX = (
        <Box>
          <Icon
            icon="triangle-exclamation"
            className="tw-mr-1"
            sx={{ color: themeVariables.warning_main }}
          />
          <Box
            className="tw-inline-block tw-mr-1"
            sx={{ color: themeVariables.warning_main }}
          >
            {us_displayedWeekPayrollData['error_and_warning_totals']['total_warning_counts']}
          </Box>
          {rLIB('Errors')}
        </Box>
      )
    } else {
      headerJSX = (
        <Box>
          <Icon
            icon="badge-check"
            className="tw-mr-1"
            sx={{ color: themeVariables.success_main }}
          />
          <Box
            className="tw-inline-block tw-mr-1"
            sx={{ color: themeVariables.success_main }}
          >
            0
          </Box>
          {rLIB('Errors')}
        </Box>
      )
    }
    return headerJSX
  }

  const rJSX_MissingCachedProjectErrorContent = (): JSX.Element => {
    let contentJSX = <></>
    if (us_displayedWeekPayrollData != null && us_displayedWeekPayrollData['error_and_warning_totals'] != null) {
      if (us_displayedWeekPayrollData['error_and_warning_totals']['total_error_count_cached_projects'] > 0) {
        let refreshIcon = (
          <Icon
            icon="arrows-rotate"
            className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 hover:tw-text-info_main"
            tooltip={rLIB('Re-cache Project Data')}
            tooltipPlacement="right"
            onClick={() => {
              us_setRecachingProjectData(true)
              recacheProjectData()
            }}
          />
        )
        if (us_recachingProjectData === true) {
          refreshIcon = (
            <Icon
              icon="arrows-rotate"
              className="bp_spin tw-mr-2 tw-opacity-30 tw-cursor-default"
            />
          )
        }
        contentJSX = (
          <Box>
            <Typography
              variant="h6"
              className="tw-mb-2"
            >
              <Icon
                icon="triangle-exclamation"
                className="tw-mr-2"
                sx={{ color: themeVariables.error_main }}
              />
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.error_main }}
              >
                {us_displayedWeekPayrollData['error_and_warning_totals']['total_error_count_cached_projects']}
              </Box>
              {rLIB('Tasks missing cached project')}
              {refreshIcon}
            </Typography>
            <Card className="tw-mb-2">
              <TableBasic
                tableAdditionalData={{}}
                tableColumns={{
                  TEMP_icon: {
                    header: {
                      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return <></>
                      },
                      header_sort_by: null,
                      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return ''
                      },
                    },
                    cell: {
                      cell_jsx: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let errorCategoryKey = 'missing_cached_projects'
                        let cellJSX = <></>
                        if (
                          rowData != null &&
                          rowData.key != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey] != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string] != null
                        ) {
                          cellJSX = (
                            <Icon
                              icon="badge-check"
                              sx={{ color: themeVariables.success_main, fontSize: '18px' }}
                            />
                          )
                        } else {
                          cellJSX = (
                            <Icon
                              icon="triangle-exclamation"
                              sx={{ color: themeVariables.error_main, fontSize: '18px' }}
                            />
                          )
                        }
                        return cellJSX
                      },
                      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                        let cellCSS = 'tw-w-4'
                        return cellCSS
                      },
                    },
                  },
                  associated_project_id_number: TableCellBasic('associated_project_id_number', rLIB('Job Code'), 'associated_project_id_number'),
                  name: TableCellBasic('name', rLIB('Task Name'), 'name'),
                }}
                tableData={objectToArray(us_tasksMissingCachedProjects).sort(dynamicSort('name', 'asc'))}
                tableSettings={{
                  paginated: false,
                  // show_header: false,
                  size: 'small',
                  sort_direction: 'asc',
                  sort_property_default: 'associated_project_id_number',
                  sortable: false,
                }}
              />
            </Card>
          </Box>
        )
      } else {
        contentJSX = (
          <Box>
            <Typography
              variant="h6"
              className="tw-mb-2"
            >
              <Icon
                icon="badge-check"
                className="tw-mr-2"
                sx={{ color: themeVariables.success_light }}
              />
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.success_light }}
              >
                0
              </Box>
              {rLIB('Tasks missing cached project')}
            </Typography>
          </Box>
        )
      }
    }
    return contentJSX
  }

  const rJSX_MissingCachedProjectMappedFieldsErrorContent = (): JSX.Element => {
    let contentJSX = <></>
    if (us_displayedWeekPayrollData != null && us_displayedWeekPayrollData['error_and_warning_totals'] != null) {
      if (us_displayedWeekPayrollData['error_and_warning_totals']['total_error_count_project_mapping'] > 0) {
        let refreshIcon = (
          <Icon
            icon="arrows-rotate"
            className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 hover:tw-text-info_main"
            tooltip={rLIB('Re-cache Project Data')}
            tooltipPlacement="right"
            onClick={() => {
              us_setRecachingProjectData(true)
              recacheProjectData()
            }}
          />
        )
        if (us_recachingProjectData === true) {
          refreshIcon = (
            <Icon
              icon="arrows-rotate"
              className="bp_spin tw-mr-2 tw-opacity-30 tw-cursor-default"
            />
          )
        }
        contentJSX = (
          <Box>
            <Typography
              variant="h6"
              className="tw-mb-2"
            >
              <Icon
                icon="triangle-exclamation"
                className="tw-mr-2"
                sx={{ color: themeVariables.error_main }}
              />
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.error_main }}
              >
                {us_displayedWeekPayrollData['error_and_warning_totals']['total_error_count_project_mapping']}
              </Box>
              {rLIB('Project Field Errors')}
              {refreshIcon}
            </Typography>
            <Card className="tw-mb-2">
              <TableBasic
                tableAdditionalData={{}}
                tableColumns={{
                  TEMP_icon: {
                    header: {
                      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return <></>
                      },
                      header_sort_by: null,
                      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return ''
                      },
                    },
                    cell: {
                      cell_jsx: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let errorCategoryKey = 'errors_project_mapping_data'
                        let cellJSX = <></>
                        if (
                          rowData != null &&
                          rowData.key != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey] != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string] != null
                        ) {
                          cellJSX = (
                            <Icon
                              icon="badge-check"
                              sx={{ color: themeVariables.success_main, fontSize: '18px' }}
                            />
                          )
                        } else {
                          cellJSX = (
                            <Icon
                              icon="triangle-exclamation"
                              sx={{ color: themeVariables.error_main, fontSize: '18px' }}
                            />
                          )
                        }
                        return cellJSX
                      },
                      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                        let cellCSS = 'tw-w-4'
                        return cellCSS
                      },
                    },
                  },
                  name: {
                    header: {
                      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return rLIB('Job Code')
                      },
                      header_sort_by: null,
                      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return ''
                      },
                    },
                    cell: {
                      cell_jsx: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let cellJSX = (
                          <Box>
                            {rowData.name}
                            <Icon
                              icon="arrow-up-right-from-square"
                              className="tw-ml-2 tw-opacity-20 hover:tw-opacity-100 tw-cursor-pointer tw-text-info-main"
                              tooltip={rLIB('Open Project')}
                              tooltipPlacement="right"
                              onClick={() => {
                                let url = window.location.origin + ApplicationPages.AdminActiveProjectViewPage.url(rowData.key as string)
                                window.open(url, '_blank')
                              }}
                            />
                          </Box>
                        )
                        return cellJSX
                      },
                      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                        let cellCSS = ''
                        return cellCSS
                      },
                    },
                  },
                  associated_task_names: {
                    header: {
                      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return rLIB('Task Names')
                      },
                      header_sort_by: null,
                      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return ''
                      },
                    },
                    cell: {
                      cell_jsx: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let tasksToList: string[] = []
                        if (rowData != null && rowData.tasks != null && getProp(rowData.tasks, 'associated_tasks', null) != null) {
                          for (let loopTaskKey in getProp(rowData.tasks, 'associated_tasks', {})) {
                            let loopTask = getProp(rowData.tasks, 'associated_tasks', {})[loopTaskKey]
                            if (loopTask != null && loopTask.name != null) {
                              tasksToList.push(loopTask.name)
                            }
                          }
                        }
                        let cellJSX = <Box>{tasksToList.join(', ')}</Box>
                        return cellJSX
                      },
                      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                        let cellCSS = ''
                        return cellCSS
                      },
                    },
                  },
                  errors: {
                    header: {
                      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return rLIB('Errors')
                      },
                      header_sort_by: null,
                      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return ''
                      },
                    },
                    cell: {
                      cell_jsx: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let cellJSX = (
                          <Box>
                            {/* { objectToArray( getProp( rowData, "errors", {}) ).join(", ") } */}
                            {objectToArray(getProp(rowData, 'errors', {})).map((error: string, index: number) => (
                              <Box key={index}>{error}</Box>
                            ))}
                          </Box>
                        )
                        return cellJSX
                      },
                      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                        let cellCSS = ''
                        return cellCSS
                      },
                    },
                  },
                  TEMP_resolution: {
                    header: {
                      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return <></>
                      },
                      header_sort_by: null,
                      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return ''
                      },
                    },
                    cell: {
                      cell_jsx: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let errorCategoryKey = 'errors_project_mapping_data'
                        let cellJSX = <></>
                        if (
                          rowData != null &&
                          rowData.key != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey] != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string] != null
                        ) {
                          let resolutionData = us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string]
                          cellJSX = <Box sx={{ color: themeVariables.success_light }}>{getProp(resolutionData, 'notes', '')}</Box>
                        }
                        return cellJSX
                      },
                      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                        let cellCSS = ''
                        return cellCSS
                      },
                    },
                  },
                  TEMP_resolution_name: {
                    header: {
                      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return <></>
                      },
                      header_sort_by: null,
                      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return ''
                      },
                    },
                    cell: {
                      cell_jsx: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let errorCategoryKey = 'errors_project_mapping_data'
                        let cellJSX = <></>
                        if (
                          rowData != null &&
                          rowData.key != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey] != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string] != null
                        ) {
                          let resolutionData = us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string]
                          cellJSX = (
                            <Box sx={{ color: themeVariables.success_light }}>
                              {getProp(resolutionData, 'associated_resolver_name', '')} (
                              {returnFormattedDate(getProp(resolutionData, 'timestamp_resolved', null), 'D MMM YY')})
                            </Box>
                          )
                        }
                        return cellJSX
                      },
                      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                        let cellCSS = ''
                        return cellCSS
                      },
                    },
                  },
                }}
                tableData={objectToArray(getProp(us_displayedWeekPayrollData, 'errors_project_mapping_data', {})).sort(dynamicSort('name', 'asc'))}
                tableSettings={{
                  paginated: false,
                  // show_header: false,
                  size: 'small',
                  sort_direction: 'asc',
                  sort_property_default: 'name',
                  sortable: false,
                }}
              />
            </Card>
          </Box>
        )
      } else {
        contentJSX = (
          <Box>
            <Typography
              variant="h6"
              className="tw-mb-2"
            >
              <Icon
                icon="badge-check"
                className="tw-mr-2"
                sx={{ color: themeVariables.success_light }}
              />
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.success_light }}
              >
                0
              </Box>
              {rLIB('Project Field Errors')}
            </Typography>
          </Box>
        )
      }
    }
    return contentJSX
  }

  const rJSX_MissingCachedProjectMappedFieldsWarningContent = (): JSX.Element => {
    let contentJSX = <></>
    if (us_displayedWeekPayrollData != null && us_displayedWeekPayrollData['error_and_warning_totals'] != null) {
      if (us_displayedWeekPayrollData['error_and_warning_totals']['total_warning_count_project_mapping'] > 0) {
        let refreshIcon = (
          <Icon
            icon="arrows-rotate"
            className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 hover:tw-text-info_main"
            tooltip={rLIB('Re-cache Project Data')}
            tooltipPlacement="right"
            onClick={() => {
              us_setRecachingProjectData(true)
              recacheProjectData()
            }}
          />
        )
        if (us_recachingProjectData === true) {
          refreshIcon = (
            <Icon
              icon="arrows-rotate"
              className="bp_spin tw-mr-2 tw-opacity-30 tw-cursor-default"
            />
          )
        }
        contentJSX = (
          <Box>
            <Typography
              variant="h6"
              className="tw-mb-2"
            >
              <Icon
                icon="circle-exclamation"
                className="tw-mr-2"
                sx={{ color: themeVariables.warning_main }}
              />
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.warning_main }}
              >
                {us_displayedWeekPayrollData['error_and_warning_totals']['total_warning_count_project_mapping']}
              </Box>
              {rLIB('Project Field Warnings')}
              {refreshIcon}
            </Typography>
            <Card className="tw-mb-2">
              <TableBasic
                tableAdditionalData={{}}
                tableColumns={{
                  manage: TableCellManage({
                    resolve: {
                      icon: (
                        <Icon
                          type="solid"
                          icon="circle-exclamation-check"
                        />
                      ),
                      label: <>{rLIB('Resolve Error')}</>,
                      onClick: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let errorCategoryKey = 'warnings_project_mapping_data'
                        resolveErrorOrWarning(errorCategoryKey, rowData.key as string)
                      },
                    },
                    unresolve: {
                      icon: (
                        <Icon
                          type="solid"
                          icon="circle-exclamation"
                        />
                      ),
                      label: <>{rLIB('Unresolve Error')}</>,
                      onClick: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let errorCategoryKey = 'warnings_project_mapping_data'
                        unresolveErrorOrWarning(errorCategoryKey, rowData.key as string)
                      },
                    },
                  }),
                  TEMP_icon: {
                    header: {
                      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return <></>
                      },
                      header_sort_by: null,
                      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return ''
                      },
                    },
                    cell: {
                      cell_jsx: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let errorCategoryKey = 'warnings_project_mapping_data'
                        let cellJSX = <></>
                        if (
                          rowData != null &&
                          rowData.key != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey] != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string] != null
                        ) {
                          cellJSX = (
                            <Icon
                              icon="badge-check"
                              sx={{ color: themeVariables.success_main, fontSize: '18px' }}
                            />
                          )
                        } else {
                          cellJSX = (
                            <Icon
                              icon="circle-exclamation"
                              sx={{ color: themeVariables.warning_main, fontSize: '18px' }}
                            />
                          )
                        }
                        return cellJSX
                      },
                      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                        let cellCSS = 'tw-w-4'
                        return cellCSS
                      },
                    },
                  },
                  name: {
                    header: {
                      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return rLIB('Job Code')
                      },
                      header_sort_by: null,
                      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return ''
                      },
                    },
                    cell: {
                      cell_jsx: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let cellJSX = (
                          <Box>
                            {rowData.name}
                            <Icon
                              icon="arrow-up-right-from-square"
                              className="tw-ml-2 tw-opacity-20 hover:tw-opacity-100 tw-cursor-pointer tw-text-info-main"
                              tooltip={rLIB('Open Project')}
                              tooltipPlacement="right"
                              onClick={() => {
                                let url = window.location.origin + ApplicationPages.AdminActiveProjectViewPage.url(rowData.key as string)
                                window.open(url, '_blank')
                              }}
                            />
                          </Box>
                        )
                        return cellJSX
                      },
                      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                        let cellCSS = ''
                        return cellCSS
                      },
                    },
                  },
                  associated_task_names: {
                    header: {
                      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return rLIB('Task Names')
                      },
                      header_sort_by: null,
                      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return ''
                      },
                    },
                    cell: {
                      cell_jsx: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let tasksToList: string[] = []
                        if (rowData != null && rowData.tasks != null && getProp(rowData.tasks, 'associated_tasks', null) != null) {
                          for (let loopTaskKey in getProp(rowData.tasks, 'associated_tasks', {})) {
                            let loopTask = getProp(rowData.tasks, 'associated_tasks', {})[loopTaskKey]
                            if (loopTask != null && loopTask.name != null) {
                              tasksToList.push(loopTask.name)
                            }
                          }
                        }
                        let cellJSX = <Box>{tasksToList.join(', ')}</Box>
                        return cellJSX
                      },
                      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                        let cellCSS = ''
                        return cellCSS
                      },
                    },
                  },
                  warnings: {
                    header: {
                      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return rLIB('Warnings')
                      },
                      header_sort_by: null,
                      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return ''
                      },
                    },
                    cell: {
                      cell_jsx: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let cellJSX = (
                          <Box>
                            {/* { objectToArray( getProp( rowData, "warnings", {}) ).join(", ") } */}
                            {objectToArray(getProp(rowData, 'warnings', {})).map((warning: string, index: number) => (
                              <Box key={index}>{warning}</Box>
                            ))}
                          </Box>
                        )
                        return cellJSX
                      },
                      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                        let cellCSS = ''
                        return cellCSS
                      },
                    },
                  },
                  TEMP_resolution: {
                    header: {
                      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return <></>
                      },
                      header_sort_by: null,
                      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return ''
                      },
                    },
                    cell: {
                      cell_jsx: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let errorCategoryKey = 'warnings_project_mapping_data'
                        let cellJSX = <></>
                        if (
                          rowData != null &&
                          rowData.key != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey] != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string] != null
                        ) {
                          let resolutionData = us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string]
                          cellJSX = <Box sx={{ color: themeVariables.success_light }}>{getProp(resolutionData, 'notes', '')}</Box>
                        }
                        return cellJSX
                      },
                      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                        let cellCSS = ''
                        return cellCSS
                      },
                    },
                  },
                  TEMP_resolution_name: {
                    header: {
                      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return <></>
                      },
                      header_sort_by: null,
                      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return ''
                      },
                    },
                    cell: {
                      cell_jsx: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let errorCategoryKey = 'warnings_project_mapping_data'
                        let cellJSX = <></>
                        if (
                          rowData != null &&
                          rowData.key != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey] != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string] != null
                        ) {
                          let resolutionData = us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string]
                          cellJSX = (
                            <Box sx={{ color: themeVariables.success_light }}>
                              {getProp(resolutionData, 'associated_resolver_name', '')} (
                              {returnFormattedDate(getProp(resolutionData, 'timestamp_resolved', null), 'D MMM YY')})
                            </Box>
                          )
                        }
                        return cellJSX
                      },
                      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                        let cellCSS = ''
                        return cellCSS
                      },
                    },
                  },
                }}
                tableData={objectToArray(getProp(us_displayedWeekPayrollData, 'warnings_project_mapping_data', {})).sort(dynamicSort('name', 'asc'))}
                tableSettings={{
                  paginated: false,
                  // show_header: false,
                  size: 'small',
                  sort_direction: 'asc',
                  sort_property_default: 'name',
                  sortable: false,
                }}
              />
            </Card>
          </Box>
        )
      } else {
        contentJSX = (
          <Box>
            <Typography
              variant="h6"
              className="tw-mb-2"
            >
              <Icon
                icon="badge-check"
                className="tw-mr-2"
                sx={{ color: themeVariables.success_light }}
              />
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.success_light }}
              >
                0
              </Box>
              {rLIB('Project Field Warnings')}
            </Typography>
          </Box>
        )
      }
    }
    return contentJSX
  }

  const rJSX_PayrollMappingErrorContent = (): JSX.Element => {
    let contentJSX = <></>
    if (
      us_displayedWeekPayrollData != null &&
      us_displayedWeekPayrollData['error_and_warning_totals'] != null &&
      us_displayedWeekPayrollData['errors_tasks_missing_unit_mapping_data'] != null
    ) {
      if (us_displayedWeekPayrollData['error_and_warning_totals']['total_error_count_tasks_missing_unit_mapping_data'] > 0) {
        contentJSX = (
          <Box>
            <Typography
              variant="h6"
              className="tw-mb-2"
            >
              <Icon
                icon="triangle-exclamation"
                className="tw-mr-2"
                sx={{ color: themeVariables.error_main }}
              />
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.error_main }}
              >
                {us_displayedWeekPayrollData['error_and_warning_totals']['total_error_count_tasks_missing_unit_mapping_data']}
              </Box>
              {rLIB('Payroll Mapping Errors')}
              <Icon
                icon="arrow-up-right-from-square"
                className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 hover:tw-text-info_main"
                tooltip={rLIB('Open Settings')}
                tooltipPlacement="right"
                onClick={() => {
                  let url = window.location.origin + ApplicationPages.AdminPayrollSettingsPage.url() + '?tab=Task_Mapping'
                  window.open(url, '_blank')
                }}
              />
            </Typography>
            <Card className="tw-mb-2">
              <TableBasic
                tableAdditionalData={{}}
                tableColumns={{
                  manage: TableCellManage({
                    find: {
                      icon: (
                        <Icon
                          type="solid"
                          icon="search"
                        />
                      ),
                      label: <>{rLIB('Find Task Workflow')}</>,
                      onClick: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        attemptToFindTaskWorkflow(rowData, rowData.key as string)
                      },
                    },
                  }),
                  TEMP_icon: {
                    header: {
                      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return <></>
                      },
                      header_sort_by: null,
                      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return ''
                      },
                    },
                    cell: {
                      cell_jsx: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let errorCategoryKey = 'errors_tasks_missing_unit_mapping_data'
                        let cellJSX = <></>
                        if (
                          rowData != null &&
                          rowData.key != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey] != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string] != null
                        ) {
                          cellJSX = (
                            <Icon
                              icon="badge-check"
                              sx={{ color: themeVariables.success_main, fontSize: '18px' }}
                            />
                          )
                        } else {
                          cellJSX = (
                            <Icon
                              icon="triangle-exclamation"
                              sx={{ color: themeVariables.error_main, fontSize: '18px' }}
                            />
                          )
                        }
                        return cellJSX
                      },
                      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                        let cellCSS = 'tw-w-4'
                        return cellCSS
                      },
                    },
                  },
                  associated_project_id_number: TableCellBasic('associated_project_id_number', rLIB('Job Code'), 'associated_project_id_number'),
                  name: TableCellBasic('name', rLIB('Task Name'), 'name'),
                  associated_team_name: TableCellBasic('associated_team_name', rLIB('Team Name'), 'associated_team_name'),
                  task_completion_scheduled_dates: {
                    header: {
                      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return rLIB('Scheduled Dates')
                      },
                      header_sort_by: null,
                      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return ''
                      },
                    },
                    cell: {
                      cell_jsx: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let cellJSX = <></>
                        if (rowData != null && rowData.task_completion_scheduled_dates != null) {
                          let goToCalendarIconJSX = (
                            <Icon
                              icon="calendar-arrow-up"
                              className="tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 tw-text-info-main tw-ml-2"
                              tooltip={rLIB('View on Calendar')}
                              tooltipPlacement="right"
                              onClick={() => {
                                let datesArray = getProp(rowData, 'task_completion_scheduled_dates', [])
                                if (datesArray.length > 0) {
                                  let url =
                                    window.location.origin + ApplicationPages.TaskCalendarSchedulePage.url(datesArray[datesArray.length - 1]) + '?tab=Day'
                                  window.open(url, '_blank')
                                }
                              }}
                            />
                          )
                          cellJSX = (
                            <Box>
                              {formatDateRange(getProp(rowData, 'task_completion_scheduled_dates', []))}
                              {goToCalendarIconJSX}
                            </Box>
                          )
                        }
                        return cellJSX
                      },
                      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                        let cellCSS = ''
                        return cellCSS
                      },
                    },
                  },
                  TEMP_message: {
                    header: {
                      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return rLIB('Errors')
                      },
                      header_sort_by: null,
                      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return ''
                      },
                    },
                    cell: {
                      cell_jsx: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let cellJSX = <Box>{rLIB('Missing Payroll Unit Mapping')}</Box>
                        return cellJSX
                      },
                      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                        let cellCSS = ''
                        return cellCSS
                      },
                    },
                  },
                }}
                tableData={objectToArray(us_displayedWeekPayrollData['errors_tasks_missing_unit_mapping_data']).sort(dynamicSort('name', 'asc'))}
                tableSettings={{
                  paginated: false,
                  // show_header: false,
                  size: 'small',
                  sort_direction: 'asc',
                  sort_property_default: 'associated_project_id_number',
                  sortable: false,
                }}
              />
            </Card>
          </Box>
        )
      } else {
        contentJSX = (
          <Box>
            <Typography
              variant="h6"
              className="tw-mb-2"
            >
              <Icon
                icon="badge-check"
                className="tw-mr-2"
                sx={{ color: themeVariables.success_light }}
              />
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.success_light }}
              >
                0
              </Box>
              {rLIB('Payroll Mapping Errors')}
              <Icon
                icon="arrow-up-right-from-square"
                className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 hover:tw-text-info_main"
                tooltip={rLIB('Open Settings')}
                tooltipPlacement="right"
                onClick={() => {
                  let url = window.location.origin + ApplicationPages.AdminPayrollSettingsPage.url() + '?tab=Task_Mapping'
                  window.open(url, '_blank')
                }}
              />
            </Typography>
          </Box>
        )
      }
    }
    return contentJSX
  }

  const rJSX_UnitPayErrorContent = (): JSX.Element => {
    let contentJSX = <></>
    if (
      us_displayedWeekPayrollData != null &&
      us_displayedWeekPayrollData['error_and_warning_totals'] != null &&
      us_displayedWeekPayrollData['errors_unit_pay'] != null
    ) {
      if (us_displayedWeekPayrollData['error_and_warning_totals']['total_error_count_unit_pay'] > 0) {
        contentJSX = (
          <Box>
            <Typography
              variant="h6"
              className="tw-mb-2"
            >
              <Icon
                icon="triangle-exclamation"
                className="tw-mr-2"
                sx={{ color: themeVariables.error_main }}
              />
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.error_main }}
              >
                {us_displayedWeekPayrollData['error_and_warning_totals']['total_error_count_unit_pay']}
              </Box>
              {rLIB('Unit Pay Errors')}
            </Typography>
            <Card className="tw-mb-2">
              <TableBasic
                tableAdditionalData={{}}
                tableColumns={{
                  manage: TableCellManage({
                    resolve: {
                      icon: (
                        <Icon
                          type="solid"
                          icon="circle-exclamation-check"
                        />
                      ),
                      label: <>{rLIB('Resolve Error')}</>,
                      onClick: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let errorCategoryKey = 'errors_unit_pay'
                        resolveErrorOrWarning(errorCategoryKey, rowData.key as string)
                      },
                    },
                    unresolve: {
                      icon: (
                        <Icon
                          type="solid"
                          icon="circle-exclamation"
                        />
                      ),
                      label: <>{rLIB('Unresolve Error')}</>,
                      onClick: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let errorCategoryKey = 'errors_unit_pay'
                        unresolveErrorOrWarning(errorCategoryKey, rowData.key as string)
                      },
                    },
                  }),
                  TEMP_icon: {
                    header: {
                      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return <></>
                      },
                      header_sort_by: null,
                      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return ''
                      },
                    },
                    cell: {
                      cell_jsx: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let errorCategoryKey = 'errors_unit_pay'
                        let cellJSX = <></>
                        if (
                          rowData != null &&
                          rowData.key != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey] != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string] != null
                        ) {
                          cellJSX = (
                            <Icon
                              icon="badge-check"
                              sx={{ color: themeVariables.success_main, fontSize: '18px' }}
                            />
                          )
                        } else {
                          cellJSX = (
                            <Icon
                              icon="triangle-exclamation"
                              sx={{ color: themeVariables.error_main, fontSize: '18px' }}
                            />
                          )
                        }
                        return cellJSX
                      },
                      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                        let cellCSS = 'tw-w-4'
                        return cellCSS
                      },
                    },
                  },
                  associated_project_id_number: TableCellBasic('associated_project_id_number', rLIB('Job Code'), 'associated_project_id_number'),
                  name: TableCellBasic('name', rLIB('Task Name'), 'name'),
                  associated_team_name: TableCellBasic('associated_team_name', rLIB('Team Name'), 'associated_team_name'),
                  task_completion_scheduled_dates: {
                    header: {
                      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return rLIB('Scheduled Dates')
                      },
                      header_sort_by: null,
                      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return ''
                      },
                    },
                    cell: {
                      cell_jsx: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let cellJSX = <></>
                        if (rowData != null && rowData.task_completion_scheduled_dates != null) {
                          let goToCalendarIconJSX = (
                            <Icon
                              icon="calendar-arrow-up"
                              className="tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 tw-text-info-main tw-ml-2"
                              tooltip={rLIB('View on Calendar')}
                              tooltipPlacement="right"
                              onClick={() => {
                                let datesArray = getProp(rowData, 'task_completion_scheduled_dates', [])
                                if (datesArray.length > 0) {
                                  let url =
                                    window.location.origin + ApplicationPages.TaskCalendarSchedulePage.url(datesArray[datesArray.length - 1]) + '?tab=Day'
                                  window.open(url, '_blank')
                                }
                              }}
                            />
                          )
                          cellJSX = (
                            <Box>
                              {formatDateRange(getProp(rowData, 'task_completion_scheduled_dates', []))}
                              {goToCalendarIconJSX}
                            </Box>
                          )
                        }
                        return cellJSX
                      },
                      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                        let cellCSS = ''
                        return cellCSS
                      },
                    },
                  },
                  TEMP_message: {
                    header: {
                      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return rLIB('Errors')
                      },
                      header_sort_by: null,
                      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return ''
                      },
                    },
                    cell: {
                      cell_jsx: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let cellJSX = (
                          <Box>
                            {/* { objectToArray( getProp( rowData, "errors", {} ) ).join(", ") } */}
                            {objectToArray(getProp(rowData, 'errors', {})).map((error: string, index: number) => (
                              <Box key={index}>{error}</Box>
                            ))}
                          </Box>
                        )
                        return cellJSX
                      },
                      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                        let cellCSS = ''
                        return cellCSS
                      },
                    },
                  },
                  TEMP_resolution: {
                    header: {
                      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return <></>
                      },
                      header_sort_by: null,
                      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return ''
                      },
                    },
                    cell: {
                      cell_jsx: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let errorCategoryKey = 'errors_unit_pay'
                        let cellJSX = <></>
                        if (
                          rowData != null &&
                          rowData.key != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey] != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string] != null
                        ) {
                          let resolutionData = us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string]
                          cellJSX = <Box sx={{ color: themeVariables.success_light }}>{getProp(resolutionData, 'notes', '')}</Box>
                        }
                        return cellJSX
                      },
                      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                        let cellCSS = ''
                        return cellCSS
                      },
                    },
                  },
                  TEMP_resolution_name: {
                    header: {
                      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return <></>
                      },
                      header_sort_by: null,
                      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return ''
                      },
                    },
                    cell: {
                      cell_jsx: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let errorCategoryKey = 'errors_unit_pay'
                        let cellJSX = <></>
                        if (
                          rowData != null &&
                          rowData.key != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey] != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string] != null
                        ) {
                          let resolutionData = us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string]
                          cellJSX = (
                            <Box sx={{ color: themeVariables.success_light }}>
                              {getProp(resolutionData, 'associated_resolver_name', '')} (
                              {returnFormattedDate(getProp(resolutionData, 'timestamp_resolved', null), 'D MMM YY')})
                            </Box>
                          )
                        }
                        return cellJSX
                      },
                      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                        let cellCSS = ''
                        return cellCSS
                      },
                    },
                  },
                }}
                tableData={objectToArray(us_displayedWeekPayrollData['errors_unit_pay']).sort(dynamicSort('name', 'asc'))}
                tableSettings={{
                  paginated: false,
                  // show_header: false,
                  size: 'small',
                  sort_direction: 'asc',
                  sort_property_default: 'associated_project_id_number',
                  sortable: false,
                }}
              />
            </Card>
          </Box>
        )
      } else {
        contentJSX = (
          <Box>
            <Typography
              variant="h6"
              className="tw-mb-2"
            >
              <Icon
                icon="badge-check"
                className="tw-mr-2"
                sx={{ color: themeVariables.success_light }}
              />
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.success_light }}
              >
                0
              </Box>
              {rLIB('Unit Pay Errors')}
            </Typography>
          </Box>
        )
      }
    }
    return contentJSX
  }

  const rJSX_UnitPayWarningContent = (): JSX.Element => {
    let contentJSX = <></>
    if (
      us_displayedWeekPayrollData != null &&
      us_displayedWeekPayrollData['error_and_warning_totals'] != null &&
      us_displayedWeekPayrollData['warnings_unit_pay'] != null
    ) {
      if (us_displayedWeekPayrollData['error_and_warning_totals']['total_warning_count_unit_pay'] > 0) {
        contentJSX = (
          <Box>
            <Typography
              variant="h6"
              className="tw-mb-2"
            >
              <Icon
                icon="circle-exclamation"
                className="tw-mr-2"
                sx={{ color: themeVariables.warning_main }}
              />
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.warning_main }}
              >
                {us_displayedWeekPayrollData['error_and_warning_totals']['total_warning_count_unit_pay']}
              </Box>
              {rLIB('Unit Pay Warnings')}
            </Typography>
            <Card className="tw-mb-2">
              <TableBasic
                tableAdditionalData={{}}
                tableColumns={{
                  manage: TableCellManage({
                    resolve: {
                      icon: (
                        <Icon
                          type="solid"
                          icon="circle-exclamation-check"
                        />
                      ),
                      label: <>{rLIB('Resolve Error')}</>,
                      onClick: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let errorCategoryKey = 'warnings_unit_pay'
                        resolveErrorOrWarning(errorCategoryKey, rowData.key as string)
                      },
                    },
                    unresolve: {
                      icon: (
                        <Icon
                          type="solid"
                          icon="circle-exclamation"
                        />
                      ),
                      label: <>{rLIB('Unresolve Error')}</>,
                      onClick: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let errorCategoryKey = 'warnings_unit_pay'
                        unresolveErrorOrWarning(errorCategoryKey, rowData.key as string)
                      },
                    },
                  }),
                  TEMP_icon: {
                    header: {
                      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return <></>
                      },
                      header_sort_by: null,
                      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return ''
                      },
                    },
                    cell: {
                      cell_jsx: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let errorCategoryKey = 'warnings_unit_pay'
                        let cellJSX = <></>
                        if (
                          rowData != null &&
                          rowData.key != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey] != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string] != null
                        ) {
                          cellJSX = (
                            <Icon
                              icon="badge-check"
                              sx={{ color: themeVariables.success_main, fontSize: '18px' }}
                            />
                          )
                        } else {
                          cellJSX = (
                            <Icon
                              icon="circle-exclamation"
                              sx={{ color: themeVariables.warning_main, fontSize: '18px' }}
                            />
                          )
                        }
                        return cellJSX
                      },
                      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                        let cellCSS = 'tw-w-4'
                        return cellCSS
                      },
                    },
                  },
                  associated_project_id_number: TableCellBasic('associated_project_id_number', rLIB('Job Code'), 'associated_project_id_number'),
                  name: TableCellBasic('name', rLIB('Task Name'), 'name'),
                  associated_team_name: TableCellBasic('associated_team_name', rLIB('Team Name'), 'associated_team_name'),
                  task_completion_scheduled_dates: {
                    header: {
                      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return rLIB('Scheduled Dates')
                      },
                      header_sort_by: null,
                      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return ''
                      },
                    },
                    cell: {
                      cell_jsx: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let cellJSX = <></>
                        if (rowData != null && rowData.task_completion_scheduled_dates != null) {
                          let goToCalendarIconJSX = (
                            <Icon
                              icon="calendar-arrow-up"
                              className="tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 tw-text-info-main tw-ml-2"
                              tooltip={rLIB('View on Calendar')}
                              tooltipPlacement="right"
                              onClick={() => {
                                let datesArray = getProp(rowData, 'task_completion_scheduled_dates', [])
                                if (datesArray.length > 0) {
                                  let url =
                                    window.location.origin + ApplicationPages.TaskCalendarSchedulePage.url(datesArray[datesArray.length - 1]) + '?tab=Day'
                                  window.open(url, '_blank')
                                }
                              }}
                            />
                          )
                          cellJSX = (
                            <Box>
                              {formatDateRange(getProp(rowData, 'task_completion_scheduled_dates', []))}
                              {goToCalendarIconJSX}
                            </Box>
                          )
                        }
                        return cellJSX
                      },
                      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                        let cellCSS = ''
                        return cellCSS
                      },
                    },
                  },
                  TEMP_message: {
                    header: {
                      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return rLIB('Warnings')
                      },
                      header_sort_by: null,
                      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return ''
                      },
                    },
                    cell: {
                      cell_jsx: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let cellJSX = (
                          <Box>
                            {objectToArray(getProp(rowData, 'errors', {})).map((error: string, index: number) => (
                              <Box key={index}>{error}</Box>
                            ))}
                          </Box>
                        )
                        return cellJSX
                      },
                      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                        let cellCSS = ''
                        return cellCSS
                      },
                    },
                  },
                  TEMP_resolution: {
                    header: {
                      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return <></>
                      },
                      header_sort_by: null,
                      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return ''
                      },
                    },
                    cell: {
                      cell_jsx: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let errorCategoryKey = 'warnings_unit_pay'
                        let cellJSX = <></>
                        if (
                          rowData != null &&
                          rowData.key != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey] != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string] != null
                        ) {
                          let resolutionData = us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string]
                          cellJSX = <Box sx={{ color: themeVariables.success_light }}>{getProp(resolutionData, 'notes', '')}</Box>
                        }
                        return cellJSX
                      },
                      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                        let cellCSS = ''
                        return cellCSS
                      },
                    },
                  },
                  TEMP_resolution_name: {
                    header: {
                      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return <></>
                      },
                      header_sort_by: null,
                      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return ''
                      },
                    },
                    cell: {
                      cell_jsx: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let errorCategoryKey = 'warnings_unit_pay'
                        let cellJSX = <></>
                        if (
                          rowData != null &&
                          rowData.key != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey] != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string] != null
                        ) {
                          let resolutionData = us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string]
                          cellJSX = (
                            <Box sx={{ color: themeVariables.success_light }}>
                              {getProp(resolutionData, 'associated_resolver_name', '')} (
                              {returnFormattedDate(getProp(resolutionData, 'timestamp_resolved', null), 'D MMM YY')})
                            </Box>
                          )
                        }
                        return cellJSX
                      },
                      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                        let cellCSS = ''
                        return cellCSS
                      },
                    },
                  },
                }}
                tableData={objectToArray(us_displayedWeekPayrollData['warnings_unit_pay']).sort(dynamicSort('name', 'asc'))}
                tableSettings={{
                  paginated: false,
                  size: 'small',
                  sort_direction: 'asc',
                  sort_property_default: 'associated_project_id_number',
                  sortable: false,
                }}
              />
            </Card>
          </Box>
        )
      } else {
        contentJSX = (
          <Box>
            <Typography
              variant="h6"
              className="tw-mb-2"
            >
              <Icon
                icon="badge-check"
                className="tw-mr-2"
                sx={{ color: themeVariables.success_light }}
              />
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.success_light }}
              >
                0
              </Box>
              {rLIB('Unit Pay Warnings')}
            </Typography>
          </Box>
        )
      }
    }
    return contentJSX
  }

  const rJSX_DistanceAdderWarningContent = (): JSX.Element => {
    let contentJSX = <></>
    if (
      us_displayedWeekPayrollData != null &&
      us_displayedWeekPayrollData['error_and_warning_totals'] != null &&
      us_displayedWeekPayrollData['warnings_distance_adders'] != null
    ) {
      if (us_displayedWeekPayrollData['error_and_warning_totals']['total_warning_count_distance_adders'] > 0) {
        contentJSX = (
          <Box>
            <Typography
              variant="h6"
              className="tw-mb-2"
            >
              <Icon
                icon="circle-exclamation"
                className="tw-mr-2"
                sx={{ color: themeVariables.warning_main }}
              />
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.warning_main }}
              >
                {us_displayedWeekPayrollData['error_and_warning_totals']['total_warning_count_distance_adders']}
              </Box>
              {rLIB('Distance Adder Warnings')}
            </Typography>
            <Card className="tw-mb-2">
              <TableBasic
                tableAdditionalData={{}}
                tableColumns={{
                  manage: TableCellManage({
                    resolve: {
                      icon: (
                        <Icon
                          type="solid"
                          icon="circle-exclamation-check"
                        />
                      ),
                      label: <>{rLIB('Resolve Error')}</>,
                      onClick: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        tableHooks.uc_setUserInterface_CustomDialogDisplay({
                          display: true,
                          dialog: {
                            dialog_jsx: (
                              <Box>
                                <DistanceAdderAdjustmentDialog
                                  payrollMondayKey={us_mondayDateKey as string}
                                  taskKey={getProp(rowData, 'key', null)}
                                  unitPayAmounts={getProp(rowData, 'unit_pay_totals', null)}
                                  resolveErrorOrWarning={resolveErrorOrWarning}
                                  unresolveErrorOrWarning={unresolveErrorOrWarning}
                                />
                              </Box>
                            ),
                            settings: {
                              max_width: 'xl',
                            },
                          },
                        })
                      },
                    },
                    unresolve: {
                      icon: (
                        <Icon
                          type="solid"
                          icon="circle-exclamation"
                        />
                      ),
                      label: <>{rLIB('Unresolve Error')}</>,
                      onClick: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let errorCategoryKey = 'warnings_distance_adders'
                        unresolveErrorOrWarning(errorCategoryKey, rowData.key as string)
                      },
                    },
                  }),
                  TEMP_icon: {
                    header: {
                      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return <></>
                      },
                      header_sort_by: null,
                      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return ''
                      },
                    },
                    cell: {
                      cell_jsx: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let errorCategoryKey = 'warnings_distance_adders'
                        let cellJSX = <></>
                        if (
                          rowData != null &&
                          rowData.key != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey] != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string] != null
                        ) {
                          cellJSX = (
                            <Icon
                              icon="badge-check"
                              sx={{ color: themeVariables.success_main, fontSize: '18px' }}
                            />
                          )
                        } else {
                          cellJSX = (
                            <Icon
                              icon="circle-exclamation"
                              sx={{ color: themeVariables.warning_main, fontSize: '18px' }}
                            />
                          )
                        }
                        return cellJSX
                      },
                      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                        let cellCSS = 'tw-w-4'
                        return cellCSS
                      },
                    },
                  },
                  associated_project_id_number: TableCellBasic('associated_project_id_number', rLIB('Job Code'), 'associated_project_id_number'),
                  name: TableCellBasic('name', rLIB('Task Name'), 'name'),
                  associated_team_name: {
                    header: {
                      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return rLIB('Team Name')
                      },
                      header_sort_by: 'associated_team_name',
                      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return ''
                      },
                    },
                    cell: {
                      cell_jsx: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let cellJSX = (
                          <Box>
                            <Tooltip
                              placement={'top'}
                              title={
                                <Box>
                                  {objectToArray(getProp(rowData, 'unit_pay_totals', {})).map((user: string, index: number) => (
                                    <Box key={index}>
                                      {getProp(user, 'name', '')} - {getProp(user, 'possible_distance_units', '')} {rLIB('Units')} ={' '}
                                      {formatCurrency(getProp(user, 'possible_distance_pay', 0))}
                                    </Box>
                                  ))}
                                </Box>
                              }
                            >
                              <Box>{getProp(rowData, 'associated_team_name', '')}</Box>
                            </Tooltip>
                          </Box>
                        )
                        return cellJSX
                      },
                      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                        let cellCSS = ''
                        return cellCSS
                      },
                    },
                  },
                  task_completion_scheduled_dates: {
                    header: {
                      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return rLIB('Scheduled Dates')
                      },
                      header_sort_by: null,
                      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return ''
                      },
                    },
                    cell: {
                      cell_jsx: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let cellJSX = <></>
                        if (rowData != null && rowData.task_completion_scheduled_dates != null) {
                          let goToCalendarIconJSX = (
                            <Icon
                              icon="calendar-arrow-up"
                              className="tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 tw-text-info-main tw-ml-2"
                              tooltip={rLIB('View on Calendar')}
                              tooltipPlacement="right"
                              onClick={() => {
                                let datesArray = getProp(rowData, 'task_completion_scheduled_dates', [])
                                if (datesArray.length > 0) {
                                  let url =
                                    window.location.origin + ApplicationPages.TaskCalendarSchedulePage.url(datesArray[datesArray.length - 1]) + '?tab=Day'
                                  window.open(url, '_blank')
                                }
                              }}
                            />
                          )
                          cellJSX = (
                            <Box>
                              {formatDateRange(getProp(rowData, 'task_completion_scheduled_dates', []))}
                              {goToCalendarIconJSX}
                            </Box>
                          )
                        }
                        return cellJSX
                      },
                      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                        let cellCSS = ''
                        return cellCSS
                      },
                    },
                  },
                  timestamp_completed: TableCellTimestamp('timestamp_completed', rLIB('Task Completed On'), 'timestamp_completed', 'D MMM YY', false),
                  TEMP_message: {
                    header: {
                      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return rLIB('Warnings')
                      },
                      header_sort_by: null,
                      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return ''
                      },
                    },
                    cell: {
                      cell_jsx: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let cellJSX = (
                          <Box>
                            {objectToArray(getProp(rowData, 'warnings', {})).map((error: string, index: number) => (
                              <Box key={index}>{error}</Box>
                            ))}
                          </Box>
                        )
                        return cellJSX
                      },
                      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                        let cellCSS = ''
                        return cellCSS
                      },
                    },
                  },
                  TEMP_resolution: {
                    header: {
                      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return <></>
                      },
                      header_sort_by: null,
                      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return ''
                      },
                    },
                    cell: {
                      cell_jsx: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let errorCategoryKey = 'warnings_distance_adders'
                        let cellJSX = <></>
                        if (
                          rowData != null &&
                          rowData.key != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey] != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string] != null
                        ) {
                          let resolutionData = us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string]
                          cellJSX = <Box sx={{ color: themeVariables.success_light }}>{getProp(resolutionData, 'notes', '')}</Box>
                        }
                        return cellJSX
                      },
                      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                        let cellCSS = ''
                        return cellCSS
                      },
                    },
                  },
                  TEMP_resolution_name: {
                    header: {
                      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return <></>
                      },
                      header_sort_by: null,
                      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return ''
                      },
                    },
                    cell: {
                      cell_jsx: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let errorCategoryKey = 'warnings_distance_adders'
                        let cellJSX = <></>
                        if (
                          rowData != null &&
                          rowData.key != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey] != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string] != null
                        ) {
                          let resolutionData = us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string]
                          cellJSX = (
                            <Box sx={{ color: themeVariables.success_light }}>
                              {getProp(resolutionData, 'associated_resolver_name', '')} (
                              {returnFormattedDate(getProp(resolutionData, 'timestamp_resolved', null), 'D MMM YY')})
                            </Box>
                          )
                        }
                        return cellJSX
                      },
                      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                        let cellCSS = ''
                        return cellCSS
                      },
                    },
                  },
                }}
                tableData={objectToArray(us_displayedWeekPayrollData['warnings_distance_adders']).sort(dynamicSort('name', 'asc'))}
                tableSettings={{
                  paginated: false,
                  size: 'small',
                  sort_direction: 'asc',
                  sort_property_default: 'associated_project_id_number',
                  sortable: false,
                }}
              />
            </Card>
          </Box>
        )
      } else {
        contentJSX = (
          <Box>
            <Typography
              variant="h6"
              className="tw-mb-2"
            >
              <Icon
                icon="badge-check"
                className="tw-mr-2"
                sx={{ color: themeVariables.success_light }}
              />
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.success_light }}
              >
                0
              </Box>
              {rLIB('Distance Adder Warnings')}
            </Typography>
          </Box>
        )
      }
    }
    return contentJSX
  }

  const rJSX_QATaskErrorContent = (): JSX.Element => {
    let contentJSX = <></>
    let taskHeaderJSX = <></>
    if (
      us_displayedWeekPayrollData != null &&
      us_displayedWeekPayrollData['error_and_warning_totals'] != null &&
      us_displayedWeekPayrollData['errors_unresolved_qa_tasks'] != null
    ) {
      if (us_displayedWeekPayrollData['error_and_warning_totals']['total_error_count_qa_tasks'] > 0) {
        taskHeaderJSX = (
          <Box>
            <Typography
              variant="h6"
              className="tw-mb-2"
            >
              <Icon
                icon="triangle-exclamation"
                className="tw-mr-2"
                sx={{ color: themeVariables.error_main }}
              />
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.error_main }}
              >
                {us_displayedWeekPayrollData['error_and_warning_totals']['total_error_count_qa_tasks']}
              </Box>
              {rLIB('QA Task Failures')}
            </Typography>
          </Box>
        )
      } else {
        taskHeaderJSX = (
          <Box>
            <Typography
              variant="h6"
              className="tw-mb-2"
            >
              <Icon
                icon="badge-check"
                className="tw-mr-2"
                sx={{ color: themeVariables.success_light }}
              />
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.success_light }}
              >
                0
              </Box>
              {rLIB('QA Task Failures')}
            </Typography>
          </Box>
        )
      }
      contentJSX = (
        <Box>
          {taskHeaderJSX}
          <Card className="tw-mb-2">
            <TableBasic
              tableAdditionalData={{}}
              tableColumns={{
                manage: TableCellManage({
                  resolve: {
                    icon: (
                      <Icon
                        type="solid"
                        icon="circle-exclamation-check"
                      />
                    ),
                    label: <>{rLIB('Resolve Error')}</>,
                    onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
                      let errorCategoryKey = 'errors_unresolved_qa_tasks'
                      resolveErrorOrWarning(errorCategoryKey, rowData.key as string)
                    },
                  },
                  unresolve: {
                    icon: (
                      <Icon
                        type="solid"
                        icon="circle-exclamation"
                      />
                    ),
                    label: <>{rLIB('Unresolve Error')}</>,
                    onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
                      let errorCategoryKey = 'errors_unresolved_qa_tasks'
                      unresolveErrorOrWarning(errorCategoryKey, rowData.key as string)
                    },
                  },
                }),
                TEMP_icon: {
                  header: {
                    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                      return <></>
                    },
                    header_sort_by: null,
                    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                      return ''
                    },
                  },
                  cell: {
                    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
                      let errorCategoryKey = 'errors_unresolved_qa_tasks'
                      let cellJSX = <></>
                      if (
                        rowData != null &&
                        rowData.key != null &&
                        us_finalizedWeekPayroll_ErrorAndWarningResolutionData != null &&
                        us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey] != null &&
                        us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string] != null
                      ) {
                        cellJSX = (
                          <Icon
                            icon="badge-check"
                            sx={{ color: themeVariables.success_main, fontSize: '18px' }}
                          />
                        )
                      } else {
                        cellJSX = (
                          <Icon
                            icon="triangle-exclamation"
                            sx={{ color: themeVariables.error_main, fontSize: '18px' }}
                          />
                        )
                      }
                      return cellJSX
                    },
                    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                      let cellCSS = 'tw-w-4'
                      return cellCSS
                    },
                  },
                },
                associated_project_id_number: TableCellBasic('associated_project_id_number', rLIB('Job Code'), 'associated_project_id_number'),
                name: TableCellBasic('name', rLIB('Task Name'), 'name'),
                associated_team_name: {
                  header: {
                    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                      return rLIB('Team Name')
                    },
                    header_sort_by: 'associated_team_name',
                    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                      return ''
                    },
                  },
                  cell: {
                    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
                      let cellJSX = (
                        <Box>
                          <Tooltip
                            placement={'top'}
                            title={
                              <Box>
                                {objectToArray(getProp(rowData, 'unit_pay_totals', {})).map((user: string, index: number) => (
                                  <Box key={index}>
                                    {getProp(user, 'name', '')} - {getProp(user, 'possible_distance_units', '')} {rLIB('Units')} ={' '}
                                    {formatCurrency(getProp(user, 'possible_distance_pay', 0))}
                                  </Box>
                                ))}
                              </Box>
                            }
                          >
                            <Box>{getProp(rowData, 'associated_team_name', '')}</Box>
                          </Tooltip>
                        </Box>
                      )
                      return cellJSX
                    },
                    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                      let cellCSS = ''
                      return cellCSS
                    },
                  },
                },
                task_completion_scheduled_dates: {
                  header: {
                    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                      return rLIB('Scheduled Dates')
                    },
                    header_sort_by: null,
                    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                      return ''
                    },
                  },
                  cell: {
                    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
                      let cellJSX = <></>
                      if (rowData != null && rowData.task_completion_scheduled_dates != null) {
                        let goToCalendarIconJSX = (
                          <Icon
                            icon="calendar-arrow-up"
                            className="tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 tw-text-info-main tw-ml-2"
                            tooltip={rLIB('View on Calendar')}
                            tooltipPlacement="right"
                            onClick={() => {
                              let datesArray = getProp(rowData, 'task_completion_scheduled_dates', [])
                              if (datesArray.length > 0) {
                                let url = window.location.origin + ApplicationPages.TaskCalendarSchedulePage.url(datesArray[datesArray.length - 1]) + '?tab=Day'
                                window.open(url, '_blank')
                              }
                            }}
                          />
                        )
                        cellJSX = (
                          <Box>
                            {formatDateRange(getProp(rowData, 'task_completion_scheduled_dates', []))}
                            {goToCalendarIconJSX}
                          </Box>
                        )
                      }
                      return cellJSX
                    },
                    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                      let cellCSS = ''
                      return cellCSS
                    },
                  },
                },
                timestamp_completed: TableCellTimestamp('timestamp_completed', rLIB('Task Completed On'), 'timestamp_completed', 'D MMM YY', false),
                qa_review_notes: TableCellBasic('qa_review_notes', rLIB('QA Review Notes'), 'qa_review_notes'),
                TEMP_resolution: {
                  header: {
                    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                      return <></>
                    },
                    header_sort_by: null,
                    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                      return ''
                    },
                  },
                  cell: {
                    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
                      let errorCategoryKey = 'errors_unresolved_qa_tasks'
                      let cellJSX = <></>
                      if (
                        rowData != null &&
                        rowData.key != null &&
                        us_finalizedWeekPayroll_ErrorAndWarningResolutionData != null &&
                        us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey] != null &&
                        us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string] != null
                      ) {
                        let resolutionData = us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string]
                        cellJSX = <Box sx={{ color: themeVariables.success_light }}>{getProp(resolutionData, 'notes', '')}</Box>
                      }
                      return cellJSX
                    },
                    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                      let cellCSS = ''
                      return cellCSS
                    },
                  },
                },
                TEMP_resolution_name: {
                  header: {
                    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                      return <></>
                    },
                    header_sort_by: null,
                    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                      return ''
                    },
                  },
                  cell: {
                    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
                      let errorCategoryKey = 'errors_unresolved_qa_tasks'
                      let cellJSX = <></>
                      if (
                        rowData != null &&
                        rowData.key != null &&
                        us_finalizedWeekPayroll_ErrorAndWarningResolutionData != null &&
                        us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey] != null &&
                        us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string] != null
                      ) {
                        let resolutionData = us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string]
                        cellJSX = (
                          <Box sx={{ color: themeVariables.success_light }}>
                            {getProp(resolutionData, 'associated_resolver_name', '')} (
                            {returnFormattedDate(getProp(resolutionData, 'timestamp_resolved', null), 'D MMM YY')})
                          </Box>
                        )
                      }
                      return cellJSX
                    },
                    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                      let cellCSS = ''
                      return cellCSS
                    },
                  },
                },
              }}
              tableData={objectToArray(us_displayedWeekPayrollData['errors_unresolved_qa_tasks']).sort(dynamicSort('name', 'asc'))}
              tableSettings={{
                paginated: false,
                size: 'small',
                sort_direction: 'asc',
                sort_property_default: 'associated_project_id_number',
                sortable: false,
              }}
            />
          </Card>
        </Box>
      )
    }
    return contentJSX
  }

  const rJSX_TimecardErrorContent = (): JSX.Element => {
    let contentJSX = <></>
    if (
      us_displayedWeekPayrollData != null &&
      us_displayedWeekPayrollData['error_and_warning_totals'] != null &&
      us_displayedWeekPayrollData['errors_punch_data'] != null
    ) {
      if (us_displayedWeekPayrollData['error_and_warning_totals']['total_error_count_punch_data'] > 0) {
        contentJSX = (
          <Box>
            <Typography
              variant="h6"
              className="tw-mb-2"
            >
              <Icon
                icon="triangle-exclamation"
                className="tw-mr-2"
                sx={{ color: themeVariables.error_main }}
              />
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.error_main }}
              >
                {us_displayedWeekPayrollData['error_and_warning_totals']['total_error_count_punch_data']}
              </Box>
              {rLIB('Timecard Errors')}
              <Icon
                icon="arrow-up-right-from-square"
                className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 hover:tw-text-info_main"
                tooltip={rLIB('Open Timecards')}
                tooltipPlacement="right"
                onClick={() => {
                  let url = window.location.origin + ApplicationPages.AllTimesheetsIndexPage.url() + '?tab=Admin_Review'
                  window.open(url, '_blank')
                }}
              />
            </Typography>
            <Card className="tw-mb-2">
              <TableBasic
                tableAdditionalData={{}}
                tableColumns={{
                  TEMP_icon: {
                    header: {
                      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return <></>
                      },
                      header_sort_by: null,
                      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return ''
                      },
                    },
                    cell: {
                      cell_jsx: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let errorCategoryKey = 'errors_punch_data'
                        let cellJSX = <></>
                        if (
                          rowData != null &&
                          rowData.key != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey] != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string] != null
                        ) {
                          cellJSX = (
                            <Icon
                              icon="badge-check"
                              sx={{ color: themeVariables.success_main, fontSize: '18px' }}
                            />
                          )
                        } else {
                          cellJSX = (
                            <Icon
                              icon="triangle-exclamation"
                              sx={{ color: themeVariables.error_main, fontSize: '18px' }}
                            />
                          )
                        }
                        return cellJSX
                      },
                      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                        let cellCSS = 'tw-w-4'
                        return cellCSS
                      },
                    },
                  },
                  name: TableCellBasic('name', rLIB('Employee'), 'name'),
                  TEMP_message: {
                    header: {
                      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return rLIB('Errors')
                      },
                      header_sort_by: null,
                      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return ''
                      },
                    },
                    cell: {
                      cell_jsx: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let invertedErrorArray: string[] = []
                        for (let loopErrorKey in rowData) {
                          if (loopErrorKey !== 'name' && loopErrorKey !== 'key') {
                            invertedErrorArray.push(loopErrorKey.replace(/_/g, ' '))
                          }
                        }
                        let cellJSX = (
                          <Box>
                            {invertedErrorArray.map((error: string, index: number) => (
                              <Box key={index}>{error}</Box>
                            ))}
                          </Box>
                        )
                        return cellJSX
                      },
                      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                        let cellCSS = ''
                        return cellCSS
                      },
                    },
                  },
                }}
                tableData={objectToArray(us_displayedWeekPayrollData['errors_punch_data']).sort(dynamicSort('name', 'asc'))}
                tableSettings={{
                  paginated: false,
                  size: 'small',
                  sort_direction: 'asc',
                  sort_property_default: 'name',
                  sortable: false,
                }}
              />
            </Card>
          </Box>
        )
      } else {
        contentJSX = (
          <Box>
            <Typography
              variant="h6"
              className="tw-mb-2"
            >
              <Icon
                icon="badge-check"
                className="tw-mr-2"
                sx={{ color: themeVariables.success_light }}
              />
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.success_light }}
              >
                0
              </Box>
              {rLIB('Timecard Errors')}
              <Icon
                icon="arrow-up-right-from-square"
                className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 hover:tw-text-info_main"
                tooltip={rLIB('Open Timecards')}
                tooltipPlacement="right"
                onClick={() => {
                  let url = window.location.origin + ApplicationPages.AllTimesheetsIndexPage.url() + '?tab=Admin_Review'
                  window.open(url, '_blank')
                }}
              />
            </Typography>
          </Box>
        )
      }
    }
    return contentJSX
  }

  const rJSX_TimecardWarningContent = (): JSX.Element => {
    let contentJSX = <></>
    if (
      us_displayedWeekPayrollData != null &&
      us_displayedWeekPayrollData['error_and_warning_totals'] != null &&
      us_displayedWeekPayrollData['warnings_punch_data'] != null
    ) {
      if (us_displayedWeekPayrollData['error_and_warning_totals']['total_warning_count_punch_data'] > 0) {
        contentJSX = (
          <Box>
            <Typography
              variant="h6"
              className="tw-mb-2"
            >
              <Icon
                icon="triangle-exclamation"
                className="tw-mr-2"
                sx={{ color: themeVariables.error_main }}
              />
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.error_main }}
              >
                {us_displayedWeekPayrollData['error_and_warning_totals']['total_warning_count_punch_data']}
              </Box>
              {rLIB('Timecard Warnings')}
              <Icon
                icon="arrow-up-right-from-square"
                className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 hover:tw-text-info_main"
                tooltip={rLIB('Open Timecards')}
                tooltipPlacement="right"
                onClick={() => {
                  let url = window.location.origin + ApplicationPages.AllTimesheetsIndexPage.url() + '?tab=Admin_Review'
                  window.open(url, '_blank')
                }}
              />
            </Typography>
            <Card className="tw-mb-2">
              <TableBasic
                tableAdditionalData={{}}
                tableColumns={{
                  TEMP_icon: {
                    header: {
                      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return <></>
                      },
                      header_sort_by: null,
                      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return ''
                      },
                    },
                    cell: {
                      cell_jsx: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let errorCategoryKey = 'warnings_punch_data'
                        let cellJSX = <></>
                        if (
                          rowData != null &&
                          rowData.key != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey] != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string] != null
                        ) {
                          cellJSX = (
                            <Icon
                              icon="badge-check"
                              sx={{ color: themeVariables.success_main, fontSize: '18px' }}
                            />
                          )
                        } else {
                          cellJSX = (
                            <Icon
                              icon="circle-exclamation"
                              sx={{ color: themeVariables.warning_main, fontSize: '18px' }}
                            />
                          )
                        }
                        return cellJSX
                      },
                      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                        let cellCSS = 'tw-w-4'
                        return cellCSS
                      },
                    },
                  },
                  name: TableCellBasic('name', rLIB('Employee'), 'name'),
                  TEMP_message: {
                    header: {
                      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return rLIB('Errors')
                      },
                      header_sort_by: null,
                      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return ''
                      },
                    },
                    cell: {
                      cell_jsx: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let invertedErrorArray: string[] = []
                        for (let loopErrorKey in rowData) {
                          if (loopErrorKey !== 'name' && loopErrorKey !== 'key') {
                            invertedErrorArray.push(loopErrorKey.replace(/_/g, ' '))
                          }
                        }
                        let cellJSX = (
                          <Box>
                            {invertedErrorArray.map((error: string, index: number) => (
                              <Box key={index}>{error}</Box>
                            ))}
                          </Box>
                        )
                        return cellJSX
                      },
                      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                        let cellCSS = ''
                        return cellCSS
                      },
                    },
                  },
                }}
                tableData={objectToArray(getProp(us_displayedWeekPayrollData, 'warnings_punch_data', {})).sort(dynamicSort('name', 'asc'))}
                tableSettings={{
                  paginated: false,
                  size: 'small',
                  sort_direction: 'asc',
                  sort_property_default: 'name',
                  sortable: false,
                }}
              />
            </Card>
          </Box>
        )
      } else {
        contentJSX = (
          <Box>
            <Typography
              variant="h6"
              className="tw-mb-2"
            >
              <Icon
                icon="badge-check"
                className="tw-mr-2"
                sx={{ color: themeVariables.success_light }}
              />
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.success_light }}
              >
                0
              </Box>
              {rLIB('Timecard Warnings')}
              <Icon
                icon="arrow-up-right-from-square"
                className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 hover:tw-text-info_main"
                tooltip={rLIB('Open Timecards')}
                tooltipPlacement="right"
                onClick={() => {
                  let url = window.location.origin + ApplicationPages.AllTimesheetsIndexPage.url() + '?tab=Admin_Review'
                  window.open(url, '_blank')
                }}
              />
            </Typography>
          </Box>
        )
      }
    }
    return contentJSX
  }

  const rJSX_UserRoleErrorContent = (): JSX.Element => {
    let contentJSX = <></>
    if (us_displayedWeekPayrollData != null && us_displayedWeekPayrollData['error_and_warning_totals'] != null) {
      if (us_displayedWeekPayrollData['error_and_warning_totals']['total_error_count_user_roles'] > 0) {
        contentJSX = (
          <Box>
            <Typography
              variant="h6"
              className="tw-mb-2"
            >
              <Icon
                icon="triangle-exclamation"
                className="tw-mr-2"
                sx={{ color: themeVariables.error_main }}
              />
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.error_main }}
              >
                {us_displayedWeekPayrollData['error_and_warning_totals']['total_error_count_user_roles']}
              </Box>
              {rLIB('Missing User Roles')}
            </Typography>
            <Card className="tw-mb-2">
              <TableBasic
                tableAdditionalData={{}}
                tableColumns={{
                  TEMP_icon: {
                    header: {
                      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return <></>
                      },
                      header_sort_by: null,
                      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                        return ''
                      },
                    },
                    cell: {
                      cell_jsx: (
                        rowData: TsInterface_TableDataRow,
                        tableAdditionalData: TsInterface_TableAdditionalData,
                        tableHooks: TsInterface_TableHooks,
                      ) => {
                        let errorCategoryKey = 'errors_user_roles'
                        let cellJSX = <></>
                        if (
                          rowData != null &&
                          rowData.key != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey] != null &&
                          us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string] != null
                        ) {
                          cellJSX = (
                            <Icon
                              icon="badge-check"
                              sx={{ color: themeVariables.success_main, fontSize: '18px' }}
                            />
                          )
                        } else {
                          cellJSX = (
                            <Icon
                              icon="triangle-exclamation"
                              sx={{ color: themeVariables.error_main, fontSize: '18px' }}
                            />
                          )
                        }
                        return cellJSX
                      },
                      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                        let cellCSS = 'tw-w-4'
                        return cellCSS
                      },
                    },
                  },
                  name: TableCellBasic('name', rLIB('Employee'), 'name'),
                  error: TableCellBasic('error', rLIB('Errors'), 'error'),
                }}
                tableData={objectToArray(us_displayedWeekPayrollData['errors_user_roles']).sort(dynamicSort('name', 'asc'))}
                tableSettings={{
                  paginated: false,
                  size: 'small',
                  sort_direction: 'asc',
                  sort_property_default: 'name',
                  sortable: false,
                }}
              />
            </Card>
          </Box>
        )
      } else {
        contentJSX = (
          <Box>
            <Typography
              variant="h6"
              className="tw-mb-2"
            >
              <Icon
                icon="badge-check"
                className="tw-mr-2"
                sx={{ color: themeVariables.success_light }}
              />
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.success_light }}
              >
                0
              </Box>
              {rLIB('Missing User Roles')}
            </Typography>
          </Box>
        )
      }
    }
    return contentJSX
  }

  const rJSX_TeamCompositionErrorContent = (): JSX.Element => {
    let contentJSX = <></>
    let taskHeaderJSX = <></>
    if (us_displayedWeekPayrollData != null && us_displayedWeekPayrollData['error_and_warning_totals'] != null) {
      if (us_displayedWeekPayrollData['error_and_warning_totals']['total_error_count_team_composition'] > 0) {
        taskHeaderJSX = (
          <Box>
            <Typography
              variant="h6"
              className="tw-mb-2"
            >
              <Icon
                icon="triangle-exclamation"
                className="tw-mr-2"
                sx={{ color: themeVariables.error_main }}
              />
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.error_main }}
              >
                {us_displayedWeekPayrollData['error_and_warning_totals']['total_error_count_team_composition']}
              </Box>
              {rLIB('Team Composition Correction Requests')}
            </Typography>
          </Box>
        )
      } else {
        taskHeaderJSX = (
          <Box>
            <Typography
              variant="h6"
              className="tw-mb-2"
            >
              <Icon
                icon="badge-check"
                className="tw-mr-2"
                sx={{ color: themeVariables.success_light }}
              />
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.success_light }}
              >
                0
              </Box>
              {rLIB('Team Composition Correction Requests')}
            </Typography>
          </Box>
        )
      }
      contentJSX = (
        <Box>
          {taskHeaderJSX}
          <Card className="tw-mb-2">
            <TableBasic
              tableAdditionalData={{}}
              tableColumns={{
                manage: TableCellManage({
                  resolve: {
                    icon: (
                      <Icon
                        type="solid"
                        icon="circle-exclamation-check"
                      />
                    ),
                    label: <>{rLIB('Resolve Error')}</>,
                    onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
                      let errorCategoryKey = 'errors_team_composition_correction_requests'
                      resolveErrorOrWarning(errorCategoryKey, rowData.key as string)
                    },
                  },
                  unresolve: {
                    icon: (
                      <Icon
                        type="solid"
                        icon="circle-exclamation"
                      />
                    ),
                    label: <>{rLIB('Unresolve Error')}</>,
                    onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
                      let errorCategoryKey = 'errors_team_composition_correction_requests'
                      unresolveErrorOrWarning(errorCategoryKey, rowData.key as string)
                    },
                  },
                }),
                TEMP_icon: {
                  header: {
                    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                      return <></>
                    },
                    header_sort_by: null,
                    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                      return ''
                    },
                  },
                  cell: {
                    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
                      let errorCategoryKey = 'errors_team_composition_correction_requests'
                      let cellJSX = <></>
                      if (
                        rowData != null &&
                        rowData.key != null &&
                        us_finalizedWeekPayroll_ErrorAndWarningResolutionData != null &&
                        us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey] != null &&
                        us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string] != null
                      ) {
                        cellJSX = (
                          <Icon
                            icon="badge-check"
                            sx={{ color: themeVariables.success_main, fontSize: '18px' }}
                          />
                        )
                      } else {
                        cellJSX = (
                          <Icon
                            icon="triangle-exclamation"
                            sx={{ color: themeVariables.error_main, fontSize: '18px' }}
                          />
                        )
                      }
                      return cellJSX
                    },
                    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                      let cellCSS = 'tw-w-4'
                      return cellCSS
                    },
                  },
                },
                associated_project_id_number: TableCellBasic('associated_project_id_number', rLIB('Job Code'), 'associated_project_id_number'),
                name: TableCellBasic('name', rLIB('Task Name'), 'name'),
                associated_team_name: {
                  header: {
                    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                      return rLIB('Team Name')
                    },
                    header_sort_by: 'associated_team_name',
                    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                      return ''
                    },
                  },
                  cell: {
                    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
                      let cellJSX = (
                        <Box>
                          <Tooltip
                            placement={'top'}
                            title={
                              <Box>
                                {objectToArray(getProp(rowData, 'unit_pay_totals', {})).map((user: string, index: number) => (
                                  <Box key={index}>
                                    {getProp(user, 'name', '')} - {getProp(user, 'possible_distance_units', '')} {rLIB('Units')} ={' '}
                                    {formatCurrency(getProp(user, 'possible_distance_pay', 0))}
                                  </Box>
                                ))}
                              </Box>
                            }
                          >
                            <Box>{getProp(rowData, 'associated_team_name', '')}</Box>
                          </Tooltip>
                        </Box>
                      )
                      return cellJSX
                    },
                    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                      let cellCSS = ''
                      return cellCSS
                    },
                  },
                },
                task_completion_scheduled_dates: {
                  header: {
                    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                      return rLIB('Scheduled Dates')
                    },
                    header_sort_by: null,
                    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                      return ''
                    },
                  },
                  cell: {
                    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
                      let cellJSX = <></>
                      if (rowData != null && rowData.task_completion_scheduled_dates != null) {
                        let goToCalendarIconJSX = (
                          <Icon
                            icon="calendar-arrow-up"
                            className="tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 tw-text-info-main tw-ml-2"
                            tooltip={rLIB('View on Calendar')}
                            tooltipPlacement="right"
                            onClick={() => {
                              let datesArray = getProp(rowData, 'task_completion_scheduled_dates', [])
                              if (datesArray.length > 0) {
                                let url = window.location.origin + ApplicationPages.TaskCalendarSchedulePage.url(datesArray[datesArray.length - 1]) + '?tab=Day'
                                window.open(url, '_blank')
                              }
                            }}
                          />
                        )
                        cellJSX = (
                          <Box>
                            {formatDateRange(getProp(rowData, 'task_completion_scheduled_dates', []))}
                            {goToCalendarIconJSX}
                          </Box>
                        )
                      }
                      return cellJSX
                    },
                    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                      let cellCSS = ''
                      return cellCSS
                    },
                  },
                },
                timestamp_completed: TableCellTimestamp('timestamp_completed', rLIB('Task Completed On'), 'timestamp_completed', 'D MMM YY', false),
                requested_changes_to_team_composition_notes: TableCellBasic(
                  'requested_changes_to_team_composition_notes',
                  rLIB('Request Notes'),
                  'requested_changes_to_team_composition_notes',
                ),
                TEMP_resolution: {
                  header: {
                    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                      return <></>
                    },
                    header_sort_by: null,
                    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                      return ''
                    },
                  },
                  cell: {
                    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
                      let errorCategoryKey = 'errors_team_composition_correction_requests'
                      let cellJSX = <></>
                      if (
                        rowData != null &&
                        rowData.key != null &&
                        us_finalizedWeekPayroll_ErrorAndWarningResolutionData != null &&
                        us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey] != null &&
                        us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string] != null
                      ) {
                        let resolutionData = us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string]
                        cellJSX = <Box sx={{ color: themeVariables.success_light }}>{getProp(resolutionData, 'notes', '')}</Box>
                      }
                      return cellJSX
                    },
                    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                      let cellCSS = ''
                      return cellCSS
                    },
                  },
                },
                TEMP_resolution_name: {
                  header: {
                    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                      return <></>
                    },
                    header_sort_by: null,
                    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                      return ''
                    },
                  },
                  cell: {
                    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
                      let errorCategoryKey = 'errors_team_composition_correction_requests'
                      let cellJSX = <></>
                      if (
                        rowData != null &&
                        rowData.key != null &&
                        us_finalizedWeekPayroll_ErrorAndWarningResolutionData != null &&
                        us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey] != null &&
                        us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string] != null
                      ) {
                        let resolutionData = us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string]
                        cellJSX = (
                          <Box sx={{ color: themeVariables.success_light }}>
                            {getProp(resolutionData, 'associated_resolver_name', '')} (
                            {returnFormattedDate(getProp(resolutionData, 'timestamp_resolved', null), 'D MMM YY')})
                          </Box>
                        )
                      }
                      return cellJSX
                    },
                    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                      let cellCSS = ''
                      return cellCSS
                    },
                  },
                },
              }}
              tableData={objectToArray(us_displayedWeekPayrollData['errors_team_composition_correction_requests']).sort(dynamicSort('name', 'asc'))}
              tableSettings={{
                paginated: false,
                // show_header: false,
                size: 'small',
                sort_direction: 'asc',
                sort_property_default: 'associated_project_id_number',
                sortable: false,
              }}
            />
          </Card>
        </Box>
      )
    }
    return contentJSX
  }

  const rJSX_TasksWithUnitPayButNoHoursWarningContent = (): JSX.Element => {
    let contentJSX = <></>
    let taskHeaderJSX = <></>
    if (us_displayedWeekPayrollData != null && us_displayedWeekPayrollData['error_and_warning_totals'] != null) {
      if (us_displayedWeekPayrollData['error_and_warning_totals']['total_warning_count_tasks_with_unit_pay_but_no_hours'] > 0) {
        taskHeaderJSX = (
          <Box>
            <Typography
              variant="h6"
              className="tw-mb-2"
            >
              <Icon
                icon="triangle-exclamation"
                className="tw-mr-2"
                sx={{ color: themeVariables.warning_main }}
              />
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.warning_main }}
              >
                {us_displayedWeekPayrollData['error_and_warning_totals']['total_warning_count_tasks_with_unit_pay_but_no_hours']}
              </Box>
              {rLIB('Tasks with Unit Pay but No Hours')}
            </Typography>
          </Box>
        )
      } else {
        taskHeaderJSX = (
          <Box>
            <Typography
              variant="h6"
              className="tw-mb-2"
            >
              <Icon
                icon="badge-check"
                className="tw-mr-2"
                sx={{ color: themeVariables.success_light }}
              />
              <Box
                className="tw-inline-block tw-mr-2"
                sx={{ color: themeVariables.success_light }}
              >
                0
              </Box>
              {rLIB('Tasks with Unit Pay but No Hours')}
            </Typography>
          </Box>
        )
      }
      contentJSX = (
        <Box>
          {taskHeaderJSX}
          <Card className="tw-mb-2">
            <TableBasic
              tableAdditionalData={{}}
              tableColumns={{
                // manage: TableCellManage({
                //   resolve: {
                //     icon: (
                //       <Icon
                //         type="solid"
                //         icon="circle-exclamation-check"
                //       />
                //     ),
                //     label: <>{rLIB('Resolve Error')}</>,
                //     onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
                //       let errorCategoryKey = 'warnings_tasks_with_unit_pay_but_no_hours'
                //       resolveErrorOrWarning(errorCategoryKey, rowData.key as string)
                //     },
                //   },
                //   unresolve: {
                //     icon: (
                //       <Icon
                //         type="solid"
                //         icon="circle-exclamation"
                //       />
                //     ),
                //     label: <>{rLIB('Unresolve Error')}</>,
                //     onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
                //       let errorCategoryKey = 'warnings_tasks_with_unit_pay_but_no_hours'
                //       unresolveErrorOrWarning(errorCategoryKey, rowData.key as string)
                //     },
                //   },
                // }),
                TEMP_icon: {
                  header: {
                    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                      return <></>
                    },
                    header_sort_by: null,
                    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                      return ''
                    },
                  },
                  cell: {
                    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
                      let errorCategoryKey = 'warnings_tasks_with_unit_pay_but_no_hours'
                      let cellJSX = <></>
                      if (
                        rowData != null &&
                        rowData.key != null &&
                        us_finalizedWeekPayroll_ErrorAndWarningResolutionData != null &&
                        us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey] != null &&
                        us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string] != null
                      ) {
                        cellJSX = (
                          <Icon
                            icon="badge-check"
                            sx={{ color: themeVariables.success_main, fontSize: '18px' }}
                          />
                        )
                      } else {
                        cellJSX = (
                          <Icon
                            icon="triangle-exclamation"
                            sx={{ color: themeVariables.warning_main, fontSize: '18px' }}
                          />
                        )
                      }
                      return cellJSX
                    },
                    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                      let cellCSS = 'tw-w-4'
                      return cellCSS
                    },
                  },
                },
                employee_name: TableCellBasic('employee_name', rLIB('Employee Name'), 'employee_name'),
                associated_project_id_number: TableCellBasic('associated_project_id_number', rLIB('Job Code'), 'associated_project_id_number'),
                task_name: TableCellBasic('task_name', rLIB('Task Name'), 'task_name'),
                warning_text: TableCellBasic('warning_text', rLIB('Warning'), 'warning_text'),
                // TEMP_resolution: {
                //   header: {
                //     header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                //       return <></>
                //     },
                //     header_sort_by: null,
                //     header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                //       return ''
                //     },
                //   },
                //   cell: {
                //     cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
                //       let errorCategoryKey = 'warnings_tasks_with_unit_pay_but_no_hours'
                //       let cellJSX = <></>
                //       if (
                //         rowData != null &&
                //         rowData.key != null &&
                //         us_finalizedWeekPayroll_ErrorAndWarningResolutionData != null &&
                //         us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey] != null &&
                //         us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string] != null
                //       ) {
                //         let resolutionData = us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string]
                //         cellJSX = <Box sx={{ color: themeVariables.success_light }}>{getProp(resolutionData, 'notes', '')}</Box>
                //       }
                //       return cellJSX
                //     },
                //     cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                //       let cellCSS = ''
                //       return cellCSS
                //     },
                //   },
                // },
                // TEMP_resolution_name: {
                //   header: {
                //     header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                //       return <></>
                //     },
                //     header_sort_by: null,
                //     header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                //       return ''
                //     },
                //   },
                //   cell: {
                //     cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
                //       let errorCategoryKey = 'warnings_tasks_with_unit_pay_but_no_hours'
                //       let cellJSX = <></>
                //       if (
                //         rowData != null &&
                //         rowData.key != null &&
                //         us_finalizedWeekPayroll_ErrorAndWarningResolutionData != null &&
                //         us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey] != null &&
                //         us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string] != null
                //       ) {
                //         let resolutionData = us_finalizedWeekPayroll_ErrorAndWarningResolutionData[errorCategoryKey][rowData.key as string]
                //         cellJSX = (
                //           <Box sx={{ color: themeVariables.success_light }}>
                //             {getProp(resolutionData, 'associated_resolver_name', '')} (
                //             {returnFormattedDate(getProp(resolutionData, 'timestamp_resolved', null), 'D MMM YY')})
                //           </Box>
                //         )
                //       }
                //       return cellJSX
                //     },
                //     cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                //       let cellCSS = ''
                //       return cellCSS
                //     },
                //   },
                // },
              }}
              tableData={objectToArray(us_displayedWeekPayrollData['warnings_tasks_with_unit_pay_but_no_hours']).sort(dynamicSort('name', 'asc'))}
              tableSettings={{
                paginated: false,
                // show_header: false,
                size: 'small',
                sort_direction: 'asc',
                sort_property_default: 'employee_name',
                sortable: false,
              }}
            />
          </Card>
        </Box>
      )
    }
    return contentJSX
  }

  const rJSX_V1TasksInfoContent = (): JSX.Element => {
    let contentJSX = <></>
    let taskHeaderJSX = <></>
    if (us_displayedWeekPayrollData != null && us_displayedWeekPayrollData['error_and_warning_totals'] != null) {
      taskHeaderJSX = (
        <Box>
          <Typography
            variant="h6"
            className="tw-mb-2"
          >
            <Icon
              icon="circle-info"
              className="tw-mr-2"
              sx={{ color: themeVariables.info_main }}
            />
            <Box
              className="tw-inline-block tw-mr-2"
              sx={{ color: themeVariables.info_main }}
            >
              {us_displayedWeekPayrollData['error_and_warning_totals']['total_info_count_v1_tasks']}
            </Box>
            {rLIB('V1 Tasks')}
          </Typography>
        </Box>
      )
      contentJSX = (
        <Box>
          {taskHeaderJSX}
          <Card className="tw-mb-2">
            <TableBasic
              tableAdditionalData={{}}
              tableColumns={{
                TEMP_icon: {
                  header: {
                    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                      return <></>
                    },
                    header_sort_by: null,
                    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                      return ''
                    },
                  },
                  cell: {
                    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
                      // let errorCategoryKey = 'errors_team_composition_correction_requests'
                      let cellJSX = (
                        <Icon
                          icon="circle-info"
                          sx={{ color: themeVariables.info_main, fontSize: '18px' }}
                        />
                      )
                      return cellJSX
                    },
                    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                      let cellCSS = 'tw-w-4'
                      return cellCSS
                    },
                  },
                },
                associated_project_id_number: TableCellBasic('associated_project_id_number', rLIB('Job Code'), 'associated_project_id_number'),
                name: TableCellBasic('name', rLIB('Task Name'), 'name'),
                associated_team_name: {
                  header: {
                    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                      return rLIB('Team Name')
                    },
                    header_sort_by: 'associated_team_name',
                    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                      return ''
                    },
                  },
                  cell: {
                    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
                      let cellJSX = (
                        <Box>
                          <Tooltip
                            placement={'top'}
                            title={
                              <Box>
                                {objectToArray(getProp(rowData, 'unit_pay_totals', {})).map((user: string, index: number) => (
                                  <Box key={index}>
                                    {getProp(user, 'name', '')} - {getProp(user, 'possible_distance_units', '')} {rLIB('Units')} ={' '}
                                    {formatCurrency(getProp(user, 'possible_distance_pay', 0))}
                                  </Box>
                                ))}
                              </Box>
                            }
                          >
                            <Box>{getProp(rowData, 'associated_team_name', '')}</Box>
                          </Tooltip>
                        </Box>
                      )
                      return cellJSX
                    },
                    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                      let cellCSS = ''
                      return cellCSS
                    },
                  },
                },
                task_completion_scheduled_dates: {
                  header: {
                    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                      return rLIB('Scheduled Dates')
                    },
                    header_sort_by: null,
                    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                      return ''
                    },
                  },
                  cell: {
                    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
                      let cellJSX = <></>
                      if (rowData != null && rowData.task_completion_scheduled_dates != null) {
                        let goToCalendarIconJSX = (
                          <Icon
                            icon="calendar-arrow-up"
                            className="tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 tw-text-info-main tw-ml-2"
                            tooltip={rLIB('View on Calendar')}
                            tooltipPlacement="right"
                            onClick={() => {
                              let datesArray = getProp(rowData, 'task_completion_scheduled_dates', [])
                              if (datesArray.length > 0) {
                                let url = window.location.origin + ApplicationPages.TaskCalendarSchedulePage.url(datesArray[datesArray.length - 1]) + '?tab=Day'
                                window.open(url, '_blank')
                              }
                            }}
                          />
                        )
                        cellJSX = (
                          <Box>
                            {formatDateRange(getProp(rowData, 'task_completion_scheduled_dates', []))}
                            {goToCalendarIconJSX}
                          </Box>
                        )
                      }
                      return cellJSX
                    },
                    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                      let cellCSS = ''
                      return cellCSS
                    },
                  },
                },
                timestamp_completed: TableCellTimestamp('timestamp_completed', rLIB('Task Completed On'), 'timestamp_completed', 'D MMM YY', false),
                task_unit_evaluation: TableCellBasic('task_unit_evaluation', rLIB('Unit Evaluation'), 'task_unit_evaluation'),
                task_units: TableCellBasic('task_units', rLIB('Task Units'), 'task_units'),
                unit_work_type: TableCellBasic('unit_work_type', rLIB('Unit Work Type'), 'unit_work_type'),
              }}
              tableData={objectToArray(us_displayedWeekPayrollData['v1_tasks']).sort(dynamicSort('name', 'asc'))}
              tableSettings={{
                paginated: false,
                // show_header: false,
                size: 'small',
                sort_direction: 'asc',
                sort_property_default: 'associated_project_id_number',
                sortable: false,
              }}
            />
          </Card>
        </Box>
      )
    }
    return contentJSX
  }

  const rJSX_ErrorsTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Box>
        <Box className="tw-mt-2">
          {rJSX_MissingCachedProjectErrorContent()}
          {rJSX_MissingCachedProjectMappedFieldsErrorContent()}
          {rJSX_MissingCachedProjectMappedFieldsWarningContent()}
          {rJSX_PayrollMappingErrorContent()}
          {rJSX_UnitPayErrorContent()}
          {rJSX_UnitPayWarningContent()}
          {rJSX_TimecardErrorContent()}
          {rJSX_TimecardWarningContent()}
          {rJSX_DistanceAdderWarningContent()}
          {rJSX_QATaskErrorContent()}
          {rJSX_UserRoleErrorContent()}
          {rJSX_TeamCompositionErrorContent()}
          {rJSX_TasksWithUnitPayButNoHoursWarningContent()}
          {rJSX_V1TasksInfoContent()}
        </Box>
      </Box>
    )
    return tabJSX
  }

  // Adjustments
  const rJSX_NewAdjustmentButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        className="tw-mr-2"
        color="success"
        variant="contained"
        onClick={() => {
          createPayrollAdjustment()
        }}
        startIcon={<Icon icon="circle-plus" />}
      >
        {rLIB('New Adjustment')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_MaxAllAdjustmentsButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        className="tw-mr-2"
        color="info"
        variant="contained"
        disabled={us_maxingOutAdjustments || getProp(us_finalizedWeekPayrollRoot, 'locked', false) === true}
        startIcon={
          us_maxingOutAdjustments === true ? (
            <Icon
              icon="arrows-rotate"
              className="bp_spin"
            />
          ) : (
            <Icon icon="wand-magic-sparkles" />
          )
        }
        onClick={() => {
          if (us_mondayDateKey != null) {
            maxOutAllAdjustments(us_mondayDateKey)
          }
          // TODO: Add max adjustment logic
          // us_usersWithNegativeBonuses
          // us_usersWithAdjustmentsButNoPayroll - make sure these are not applied
        }}
      >
        {rLIB('Max All Adjustments')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_AdjustmentsHeader = (): JSX.Element => {
    let headerJSX = <></>
    let warningIconJSX = <></>
    let errorIconJSX = <></>
    if (us_adjustmentWarningCount > 0) {
      warningIconJSX = (
        <Box
          sx={{ color: themeVariables.warning_main }}
          className="tw-mr-1 tw-inline-block"
        >
          <Icon
            icon="triangle-exclamation"
            className="tw-mr-1"
          />
          {us_adjustmentWarningCount}
        </Box>
      )
    }
    if (us_adjustmentErrorCount > 0) {
      errorIconJSX = (
        <Box
          sx={{ color: themeVariables.error_main }}
          className="tw-mr-1 tw-inline-block"
        >
          <Icon
            icon="triangle-exclamation"
            className="tw-mr-1"
          />
          {us_adjustmentErrorCount}
        </Box>
      )
    }
    headerJSX = (
      <Box>
        {errorIconJSX}
        {warningIconJSX}
        {rLIB('Adjustments')}
      </Box>
    )
    return headerJSX
  }

  const rJSX_SuggestedAdjustmentsTable = (): JSX.Element => {
    let tableJSX = <></>
    if (us_unresolvedPayrollAdjustmentSuggestions != null && objectToArray(us_unresolvedPayrollAdjustmentSuggestions).length > 0) {
      tableJSX = (
        <Card>
          <TableBasic
            tableAdditionalData={{}}
            tableColumns={tableColumns_SuggestedAdjustments}
            tableData={objectToArray(us_unresolvedPayrollAdjustmentSuggestions)}
            tableSettings={tableSettings_SuggestedAdjustments}
          />
        </Card>
      )
    } else {
      tableJSX = (
        <Card className="tw-mb-2 tw-p-2 tw-text-center">
          <Typography
            variant="h6"
            className="tw-opacity-30"
          >
            {rLIB('No Suggested Adjustments')}
          </Typography>
        </Card>
      )
    }
    return tableJSX
  }

  const rJSX_AdjustmentsTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Box>
        <Box className="">
          <Typography
            variant="h6"
            className="tw-mb-2"
          >
            {rLIB('Adjustments')}
          </Typography>
          <Card>
            <TableBasic
              tableAdditionalData={{
                us_mondayDateKey: us_mondayDateKey,
                payroll_week_locked: getProp(us_finalizedWeekPayrollRoot, 'locked', false),
                us_displayedWeekPayrollData: us_displayedWeekPayrollData,
                us_usersWithNegativeBonuses: us_usersWithNegativeBonuses,
                us_usersWithAdjustmentsButNoPayroll: us_usersWithAdjustmentsButNoPayroll,
              }}
              tableColumns={tableColumns_Adjustments}
              tableData={objectToArray(us_combinedPayrollAdjustments)}
              tableSettings={us_tableSettings_Adjustments}
            />
          </Card>
        </Box>
        <Box className="tw-mt-2">
          <Typography
            variant="h6"
            className="tw-mb-2"
          >
            {rLIB('Suggested Adjustments')}
          </Typography>
          {rJSX_SuggestedAdjustmentsTable()}
        </Box>
      </Box>
    )
    return tabJSX
  }

  // Downloads
  const rJSX_DownloadLink = (
    downloadType: string,
    downloadLabel: JSX.Element | string,
    downloadIcon: string,
    hoverColor: string,
    showDateRange: boolean,
  ): JSX.Element => {
    // Date Range
    let dateRangeJSX = <></>
    if (showDateRange === true) {
      dateRangeJSX = (
        <Box className="tw-opacity-30 tw-font-normal">
          {returnFormattedDate(us_weekStartDate, 'D MMM YY')} - {returnFormattedDate(us_weekEndDate, 'D MMM YY')}
        </Box>
      )
    }
    // Download Icon
    let iconJSX = (
      <Icon
        icon={downloadIcon}
        sx={{ fontSize: '100px' }}
      />
    )
    if (us_runningCalculation === true) {
      iconJSX = (
        <Icon
          icon="arrows-rotate"
          className="bp_spin"
          sx={{ fontSize: '100px' }}
        />
      )
    }
    // Link
    let downloadLink = (
      <Box
        className="tw-rounded-xl tw-p-2 tw-m-2 tw-inline-block tw-cursor-pointer tw-text-center tw-font-bold tw-text-lg tw-align-top"
        sx={{
          'width': '250px',
          'height': '250px',
          'border': themeVariables.background_paper,
          'background': themeVariables.black,
          '&:hover': {
            background: themeVariables.background_paper,
            color: hoverColor,
          },
        }}
        onClick={() => {
          switch (downloadType) {
            case 'combined_report':
              us_setRunningCalculation(true)
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  downloadCombinedReport(res_GCK.clientKey, true)
                    // downloadCombinedReport(res_GCK.clientKey, false)
                    .then((res_EAP) => {
                      us_setRunningCalculation(false)
                    })
                    .catch((rej_EAP) => {
                      us_setRunningCalculation(false)
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_EAP.error })
                    })
                })
                .catch((rej_GCK) => {
                  us_setRunningCalculation(false)
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })

              break
            case 'payroll_report':
              us_setRunningCalculation(true)
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  downloadPayrollReportVersion2(res_GCK.clientKey)
                    .then((res_DPR) => {
                      us_setRunningCalculation(false)
                    })
                    .catch((rej_DPR) => {
                      us_setRunningCalculation(false)
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DPR.error })
                    })
                })
                .catch((rej_GCK) => {
                  us_setRunningCalculation(false)
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
              break
            case 'bonus_commission':
              us_setRunningCalculation(true)
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  downloadBonusCommissionReport(res_GCK.clientKey)
                    .then((res_DBCR) => {
                      us_setRunningCalculation(false)
                    })
                    .catch((rej_DBCR) => {
                      us_setRunningCalculation(false)
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBCR.error })
                    })
                })
                .catch((rej_GCK) => {
                  us_setRunningCalculation(false)
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
              break
            case 'piece_rate':
              us_setRunningCalculation(true)
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  downloadPieceRateReport(res_GCK.clientKey)
                    .then((res_DPRR) => {
                      us_setRunningCalculation(false)
                    })
                    .catch((rej_DPRR) => {
                      us_setRunningCalculation(false)
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DPRR.error })
                    })
                })
                .catch((rej_GCK) => {
                  us_setRunningCalculation(false)
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
              break
            case 'all_projects':
              us_setRunningCalculation(true)
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  exportAllProjects(res_GCK.clientKey)
                    .then((res_EAP) => {
                      us_setRunningCalculation(false)
                    })
                    .catch((rej_EAP) => {
                      us_setRunningCalculation(false)
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_EAP.error })
                    })
                })
                .catch((rej_GCK) => {
                  us_setRunningCalculation(false)
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
              break
            case 'hours_vs_units':
              downloadHoursVsUnitsReport()
              break
            default:
              break
          }
        }}
      >
        <Box className="tw-text-center tw-pt-8">
          <Box className="tw-mb-8">{iconJSX}</Box>
          <Box>{downloadLabel}</Box>
          {dateRangeJSX}
        </Box>
      </Box>
    )
    return downloadLink
  }

  const rJSX_DownloadsTab = (): JSX.Element => {
    let tabJSX = <></>
    if (us_lockDownloadsDueToNegativeBonuses === true) {
      tabJSX = (
        <Box>
          <Typography
            variant="h4"
            className="tw-mt-4 tw-text-center"
            sx={{ color: themeVariables.error_main }}
          >
            <Icon
              icon="triangle-exclamation"
              className="tw-mr-2"
            />
            {rLIB('Negative Bonuses Detected')}
          </Typography>
          <Typography
            variant="body1"
            className="tw-mt-2 tw-text-center"
            sx={{ color: themeVariables.white }}
          >
            {rLIB('Fix negative bonuses before downloading payroll reports.')}
          </Typography>
        </Box>
      )
    } else {
      tabJSX = (
        <Box>
          <Box className="tw-text-center tw-m-auto">
            {rJSX_DownloadLink('combined_report', rLIB('Combined Download'), 'inbox-full', themeVariables.success_main, true)}
            {rJSX_DownloadLink('payroll_report', rLIB('Payroll Download'), 'money-check-dollar-pen', themeVariables.success_main, true)}
            {rJSX_DownloadLink('bonus_commission', rLIB('Bonus Commission'), 'sack-dollar', themeVariables.success_main, true)}
            {rJSX_DownloadLink('hours_vs_units', rLIB('Hours and Units'), 'chess-clock-flip', themeVariables.success_main, false)}
            {rJSX_DownloadLink('piece_rate', rLIB('Piece Rate'), 'diamond-exclamation', themeVariables.success_main, true)}
            {rJSX_DownloadLink('all_projects', rLIB('All Projects'), 'rectangle-history-circle-user', themeVariables.warning_main, false)}
          </Box>
        </Box>
      )
    }
    return tabJSX
  }

  // Logs
  const rJSX_LogsTab = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tabJSX = (
        <Card className="tw-mt-4">
          <TableDatabase
            tableAdditionalData={{}}
            tableColumns={tableColumns_PayrollLogs}
            tableDatabaseEndpoint={tableDatabaseEndpoint_PayrollLogs}
            tableSettings={tableSettings_PayrollLogs}
          />
        </Card>
      )
    }
    return tabJSX
  }

  // Approval
  const rJSX_TasksAwaitingApprovalTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Box>
        <Card>
          <TableBasic
            tableAdditionalData={{ editable: true }}
            tableColumns={tableColumns_TasksPendingSalesPartnerApproval}
            tableData={objectToArray(us_tasksRequiringPartnerApproval)}
            tableSettings={tableSettings_TasksPendingSalesPartnerApproval}
          />
        </Card>
      </Box>
    )
    return tabJSX
  }

  const rJSX_PtoImportButton = (): JSX.Element => {
    let buttonJSX = (
      <FileUploadButton
        multiple={false}
        button={{
          text: rLIB('Upload PTO File') as JSX.Element,
          icon: (
            <Icon
              icon="cloud-arrow-up"
              className="tw-mr-2"
              sx={{ fontSize: '20px' }}
            />
          ),
          color: 'info',
          className: '',
          variant: 'contained',
          disabled: false,
        }}
        accept=".xlsx"
        onChange={handleFileUpload}
        additionalFileUploadParams={{}}
      />
    )
    return buttonJSX
  }

  // Full Page
  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Payroll')}
        pageKey={pageKey}
        content={
          <Box>
            <style>
              {`
          .MuiTooltip-tooltip {
            background-color: black !important;
          }
        `}
            </style>
            <TabsUrl
              tabs={[
                {
                  tabOnChange: () => {
                    us_setActiveTab('Summary')
                  },
                  tabUrlKey: 'Summary',
                  tabHeader: rLIB('Summary'),
                  tabButtons: [
                    { fullJSX: rJSX_SettingsButton(), minJSX: rJSX_SettingsButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_CombineManagementButton(false), minJSX: rJSX_CombineManagementButton(true), sizeCutoff: 850 },
                    { fullJSX: rJSX_DatePicker(us_runningCalculation), minJSX: rJSX_DatePicker(us_runningCalculation), sizeCutoff: 0 },
                    { fullJSX: rJSX_LockButton(false), minJSX: rJSX_LockButton(true), sizeCutoff: 960 },
                    { fullJSX: rJSX_RefreshProjectDataButton(false), minJSX: rJSX_RefreshProjectDataButton(true), sizeCutoff: 1125 },
                    // { fullJSX: rJSX_RefreshTaskPayrollHoursButton(false), minJSX: rJSX_RefreshTaskPayrollHoursButton(true), sizeCutoff: 1125 },
                  ],
                  tabContent: rJSX_SummaryTab(),
                },
                {
                  tabOnChange: () => {
                    us_setActiveTab('Tasks_Awaiting_Approval')
                  },
                  tabUrlKey: 'Tasks_Awaiting_Approval',
                  tabHeader: rLIB('Partner Approval'),
                  tabButtons: [
                    { fullJSX: rJSX_SettingsButton(), minJSX: rJSX_SettingsButton(), sizeCutoff: 0 },
                    // { fullJSX: rJSX_DatePicker(us_runningCalculation), minJSX: rJSX_DatePicker(us_runningCalculation), sizeCutoff: 0 },
                  ],
                  tabContent: rJSX_TasksAwaitingApprovalTab(),
                },
                {
                  tabOnChange: () => {
                    us_setActiveTab('Unit_Pay')
                  },
                  tabUrlKey: 'Unit_Pay',
                  tabHeader: rLIB('Unit Pay'),
                  tabButtons: [
                    { fullJSX: rJSX_SettingsButton(), minJSX: rJSX_SettingsButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_DatePicker(us_runningCalculation), minJSX: rJSX_DatePicker(us_runningCalculation), sizeCutoff: 0 },
                    { fullJSX: rJSX_FilterUnitPayDropdown(false), minJSX: rJSX_FilterUnitPayDropdown(true), sizeCutoff: 1125 },
                    { fullJSX: rJSX_RefreshTaskPayrollHoursButton(false), minJSX: rJSX_RefreshTaskPayrollHoursButton(true), sizeCutoff: 1125 },
                  ],
                  tabContent: rJSX_UnitPayTab(),
                },
                // {
                //   tabOnChange: () => {
                //     us_setActiveTab('Distance_Adders')
                //   },
                //   tabUrlKey: 'Distance_Adders',
                //   tabHeader: rLIB('Distance Adders'),
                //   tabButtons: [
                //     { fullJSX: rJSX_SettingsButton(), minJSX: rJSX_SettingsButton(), sizeCutoff: 0 },
                //     { fullJSX: rJSX_DatePicker(us_runningCalculation), minJSX: rJSX_DatePicker(us_runningCalculation), sizeCutoff: 0 },
                //   ],
                //   tabContent: rJSX_DistanceAddersTab(),
                // },
                // {
                //   tabOnChange: () => {
                //     us_setActiveTab('PTO')
                //   },
                //   tabUrlKey: 'PTO',
                //   tabHeader: rJSX_ProTabHeader(),
                //   tabButtons: [
                //     { fullJSX: rJSX_SettingsButton(), minJSX: rJSX_SettingsButton(), sizeCutoff: 0 },
                //     { fullJSX: rJSX_PtoImportButton(), minJSX: rJSX_PtoImportButton(), sizeCutoff: 0 },
                //   ],
                //   tabContent: rJSX_PtoTable(),
                // },
                {
                  tabOnChange: () => {
                    us_setActiveTab('Data_Entry')
                  },
                  tabUrlKey: 'Data_Entry',
                  tabHeader: rJSX_DataEntryHeader(),
                  tabButtons: [
                    { fullJSX: rJSX_SettingsButton(), minJSX: rJSX_SettingsButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_DatePicker(us_runningCalculation), minJSX: rJSX_DatePicker(us_runningCalculation), sizeCutoff: 0 },
                    { fullJSX: rJSX_PtoImportButton(), minJSX: rJSX_PtoImportButton(), sizeCutoff: 0 },
                  ],
                  tabContent: rJSX_DataEntryTab(),
                },
                {
                  tabOnChange: () => {
                    us_setActiveTab('Errors')
                  },
                  tabUrlKey: 'Errors',
                  tabHeader: rJSX_ErrorsHeader(),
                  tabButtons: [
                    { fullJSX: rJSX_SettingsButton(), minJSX: rJSX_SettingsButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_DatePicker(us_runningCalculation), minJSX: rJSX_DatePicker(us_runningCalculation), sizeCutoff: 0 },
                  ],
                  tabContent: rJSX_ErrorsTab(),
                },
                {
                  tabOnChange: () => {
                    us_setActiveTab('Adjustments')
                  },
                  tabUrlKey: 'Adjustments',
                  tabHeader: rJSX_AdjustmentsHeader(),
                  tabButtons: [
                    { fullJSX: rJSX_SettingsButton(), minJSX: rJSX_SettingsButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_DatePicker(us_runningCalculation), minJSX: rJSX_DatePicker(us_runningCalculation), sizeCutoff: 0 },
                    { fullJSX: rJSX_NewAdjustmentButton(), minJSX: rJSX_NewAdjustmentButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_MaxAllAdjustmentsButton(), minJSX: rJSX_MaxAllAdjustmentsButton(), sizeCutoff: 0 },
                  ],
                  tabContent: rJSX_AdjustmentsTab(),
                },
                {
                  tabOnChange: () => {
                    us_setActiveTab('Downloads')
                  },
                  tabUrlKey: 'Downloads',
                  tabHeader: rLIB('Downloads'),
                  tabButtons: [
                    { fullJSX: rJSX_SettingsButton(), minJSX: rJSX_SettingsButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_DatePicker(us_runningCalculation), minJSX: rJSX_DatePicker(us_runningCalculation), sizeCutoff: 0 },
                  ],
                  tabContent: rJSX_DownloadsTab(),
                },
                {
                  tabOnChange: () => {
                    us_setActiveTab('Logs')
                  },
                  tabUrlKey: 'Logs',
                  tabHeader: rLIB('Logs'),
                  tabButtons: [
                    { fullJSX: rJSX_SettingsButton(), minJSX: rJSX_SettingsButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_DatePicker(us_runningCalculation), minJSX: rJSX_DatePicker(us_runningCalculation), sizeCutoff: 0 },
                  ],
                  tabContent: rJSX_LogsTab(),
                },
              ]}
              tabsSettings={{
                baseUrl: ApplicationPages.AdminPayrollIndexPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Payroll', false) as string,
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
