import { Box, Button, Card, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material/'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_CachedAuthorizations_Document, DatabaseRef_User_Document } from 'rfbp_aux/services/database_endpoints/directory/users'

import { getDoc, getDocs } from 'firebase/firestore'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatabaseRef_GasPricesForMonth_Query } from 'rfbp_aux/services/database_endpoints/directory/vehicles'
import { DatabaseRef_CombinedVehiclesByMondaysList_Query } from 'rfbp_aux/services/database_endpoints/operations/gm_dashboards'
import { Icon } from 'rfbp_core/components/icons'
import { TableBasic, TsInterface_TableColumns, TsInterface_TableSettings } from 'rfbp_core/components/table'
import { Context_RootData_ClientKey } from 'rfbp_core/services/context'
import { DatabaseGetLiveCollection, DatabaseGetLiveDocument } from 'rfbp_core/services/database_management'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { getMondaysForMonthBoundary } from './vehicle_mileage_reports'

import { Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { rLIB } from 'rfbp_core/localization/library'

const pageKey: string = ApplicationPages['AdminDatabaseFuelUseUserViewPage']['key']

export const combineTransactionsAndVehiclesData = async (
  transactionsData: Record<string, any>,
  combinedVehiclesData: Record<string, any>,
  clientKey: string,
  options?: {
    userKey?: string // Optional: Specify a user key for the user view
  },
  selectedMonth?: Date, // Selected month to filter data
): Promise<{
  combinedData: Record<string, any>
  userDailyData?: Array<any> // Daily data for the specific user (if userKey is provided)
  transformedAggregateData?: Array<any> // Aggregate data for all users
}> => {
  const combinedData: Record<string, any> = {}

  // Get month boundaries and today's date
  const startOfMonth = selectedMonth ? new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1) : null
  const endOfMonth = selectedMonth ? new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0) : null
  const today = new Date()

  // Step 1: Initialize combinedData with users from transactionsData and combinedVehiclesData
  const transactionsUserKeys = Object.keys(transactionsData).map((key) => String(key))
  const vehiclesUserKeys = Object.values(combinedVehiclesData)
    .flatMap((weekData: any) => Object.keys(weekData?.data || {}))
    .map((key) => String(key))

  const allUserKeys = new Set<string>([...transactionsUserKeys, ...vehiclesUserKeys])

  // Log allUserKeys for verification
  console.log('----- All User Keys -----', Array.from(allUserKeys))
  console.log('----- End of All User Keys -----')

  // Step 1a: Extract associated_user_name from combinedVehiclesData
  const vehicleUserNames: Record<string, string> = {}
  Object.values(combinedVehiclesData).forEach((weekData: any) => {
    Object.entries(weekData?.data || {}).forEach(([userKey, userWeekData]: [string, any]) => {
      if (userWeekData?.associated_user_name) {
        vehicleUserNames[userKey] = userWeekData.associated_user_name
        console.log(`Extracted vehicle userName for ${userKey}: ${userWeekData.associated_user_name}`)
      }
    })
  })

  // Step 1b: Initialize combinedData with default values
  allUserKeys.forEach((userKey) => {
    const transactionUserData = transactionsData[userKey] || {}
    const vehicleUserName = vehicleUserNames[userKey] || 'Unknown'
    combinedData[userKey] = {
      associated_user_key: transactionUserData.associated_user_key || userKey, // Fallback to userKey if missing
      associated_user_name: transactionUserData.associated_user_name || vehicleUserName, // Prioritize transactionsData name
      daily_spend: { ...(transactionUserData.daily_spend || {}) },
      monthly_spend: transactionUserData.monthly_spend || 0,
      default_location_state: null, // Placeholder to be updated
      daily_miles_driven: {},
      daily_expected_miles: {},
      daily_commute_miles: {},
      daily_scheduled_miles: {},
      daily_expected_total_miles: {},
      daily_expected_spend: {},
      daily_gas_price: {},
      daily_mpg: {},
      monthly_miles_driven: 0,
      monthly_expected_miles: 0,
      monthly_commute_miles: 0,
      monthly_scheduled_miles: 0,
      monthly_expected_total_miles: 0,
      monthly_expected_spend: 0,
    }
  })

  // Step 2: Fetch and assign default_location_state and update associated_user_name from user documents
  const uniqueUserKeys = Array.from(allUserKeys)
  await Promise.all(
    uniqueUserKeys.map(async (userKey) => {
      const userDocRef = DatabaseRef_User_Document(clientKey, String(userKey))
      const userDoc = await getDoc(userDocRef)
      if (userDoc.exists()) {
        const userData = userDoc.data()
        console.log(`Fetched user document for ${userKey}:`, userData)
        combinedData[userKey].default_location_state = userData.default_location_state || 'default'

        // Update associated_user_name from userDoc if available
        if (userData.associated_user_name) {
          // Existing
          combinedData[userKey].associated_user_name = userData.associated_user_name
          console.log(`Set associated_user_name from associated_user_name field for ${userKey}: ${userData.associated_user_name}`)
        } else if (userData.userName) {
          // Existing
          combinedData[userKey].associated_user_name = userData.userName
          console.log(`Set associated_user_name from userName field for ${userKey}: ${userData.userName}`)
        } else if (userData.name) {
          // Existing
          combinedData[userKey].associated_user_name = userData.name
          console.log(`Set associated_user_name from name field for ${userKey}: ${userData.name}`)
        } else if (userData.fullName) {
          // New: Check for 'fullName'
          combinedData[userKey].associated_user_name = userData.fullName
          console.log(`Set associated_user_name from fullName field for ${userKey}: ${userData.fullName}`)
        } else {
          console.warn(`No name fields found in userDoc for ${userKey}. Keeping existing name: ${combinedData[userKey].associated_user_name}`)
        }
      } else {
        console.warn(`User document does not exist for userKey: ${userKey}`)
        // If user document doesn't exist, attempt to use vehicleUserNames
        if (vehicleUserNames[userKey] && combinedData[userKey].associated_user_name === 'Unknown') {
          combinedData[userKey].associated_user_name = vehicleUserNames[userKey]
          console.log(`Set userName from vehicleUserNames for ${userKey}: ${vehicleUserNames[userKey]}`)
        }
      }

      // Debugging: Check if userName is still 'Unknown'
      if (combinedData[userKey].associated_user_name === 'Unknown' && !transactionsData[userKey] && !vehicleUserNames[userKey]) {
        console.warn(`UserName not found for userKey: ${userKey}`)
      }

      // Specific log for 'uuIWbnp0WUOqfz0YZqPp0u6XnOu1'
      if (userKey === 'uuIWbnp0WUOqfz0YZqPp0u6XnOu1') {
        console.log(`Final combinedData entry for uuIWbnp0WUOqfz0YZqPp0u6XnOu1:`, combinedData[userKey])
      }
    }),
  )

  // Step 3: Fetch gas prices for all states
  const fetchGasPricesForMonth = async (): Promise<Record<string, Record<string, number>>> => {
    const gasPriceData: Record<string, Record<string, number>> = {}
    const uniqueStates = new Set<string>()

    Object.values(combinedData).forEach((userData: any) => {
      if (userData.default_location_state && userData.default_location_state !== 'default') {
        uniqueStates.add(userData.default_location_state)
      }
    })

    await Promise.all(
      Array.from(uniqueStates).map(async (state) => {
        const queryRef = DatabaseRef_GasPricesForMonth_Query(clientKey, state, startOfMonth as any, endOfMonth as any)
        const querySnapshot = await getDocs(queryRef)

        gasPriceData[state] = gasPriceData[state] || {}
        querySnapshot.forEach((doc) => {
          const data: any = doc.data()
          const dateKey = new Date(data.timestamp_added?.toDate().toISOString().split('T')[0]) // Normalize to UTC
          const formattedKey = dateKey.toISOString().split('T')[0]
          gasPriceData[state][formattedKey] = parseFloat(data.gas)
        })
        console.log(`Fetched gas prices for state ${state}:`, gasPriceData[state])
      }),
    )

    return gasPriceData
  }

  const gasPriceLookup = await fetchGasPricesForMonth()

  // Step 4: Collect all unique days
  const allUniqueDays = new Set<string>()

  Object.values(transactionsData).forEach((userData: any) => {
    Object.keys(userData.daily_spend || {}).forEach((day: string) => allUniqueDays.add(day))
  })

  Object.values(combinedVehiclesData).forEach((weekData: any) => {
    Object.entries(weekData?.data || {}).forEach(([userKey, userWeekData]: [string, any]) => {
      Object.keys(userWeekData || {}).forEach((key: string) => {
        if (key.startsWith('202')) allUniqueDays.add(key)
      })
    })
  })

  // Step 5: Populate combinedData for all unique days
  allUniqueDays.forEach((day: string) => {
    const dayDate = new Date(day)

    // **Removed the block that skips future dates**
    /*
    // Skip future dates
    if (dayDate > today) {
      return
    }
    */

    // Filter days outside the selected month
    if ((startOfMonth && dayDate < startOfMonth) || (endOfMonth && dayDate > endOfMonth)) {
      return
    }

    // Determine trailer usage for each user
    const usersWithTrailer = new Set<string>()

    // Check combinedVehiclesData for trailer assignments
    Object.entries(combinedVehiclesData).forEach(([weekKey, weekData]: [string, any]) => {
      Object.entries(weekData?.data || {}).forEach(([userKey, userWeekData]: [string, any]) => {
        Object.entries(userWeekData || {}).forEach(([dayKey, dayTrips]) => {
          if (dayKey.startsWith('202')) {
            // Check if any trip has a trailer assigned
            if (typeof dayTrips === 'object' && dayTrips !== null && 'trips' in dayTrips) {
              const trips = (dayTrips as { trips: Array<any> }).trips || []
              for (const trip of trips) {
                if (trip.trailer_name) {
                  usersWithTrailer.add(userKey) // Mark user as having a trailer
                  break
                }
              }
            }
          }
        })
      })
    })

    // Populate daily_spend from transactionsData
    Object.entries(transactionsData).forEach(([userKey, userData]: [string, any]) => {
      if (combinedData[userKey]) {
        combinedData[userKey].daily_spend[day] = userData.daily_spend?.[day] || 0
      }
    })

    // Populate vehicle-related data from combinedVehiclesData
    Object.entries(combinedVehiclesData).forEach(([weekKey, weekData]: [string, any]) => {
      Object.entries(weekData?.data || {}).forEach(([userKey, userWeekData]: [string, any]) => {
        if (!combinedData[userKey]) {
          return
        }

        const dayData = userWeekData?.[day] || {}
        const weeklyCommuteMiles = userWeekData?.commute_miles || 0
        const weeklyScheduledMiles = userWeekData?.scheduled_miles || 0

        const expectedMiles = (combinedData[userKey].daily_expected_miles[day] || 0) + (dayData.expected_miles || 0)
        const milesDriven = (combinedData[userKey].daily_miles_driven[day] || 0) + (dayData.miles_driven || 0)
        const dailySpend = combinedData[userKey].daily_spend[day] || 0

        // Only include commute and scheduled miles if expectedMiles > 0
        const commuteMiles = expectedMiles > 0 ? combinedData[userKey].daily_commute_miles[day] || weeklyCommuteMiles : 0
        const scheduledMiles = expectedMiles > 0 ? combinedData[userKey].daily_scheduled_miles[day] || weeklyScheduledMiles : 0

        const totalExpectedMiles = commuteMiles + scheduledMiles + expectedMiles

        // Retrieve the gas price for the user's state and date
        const userState = combinedData[userKey]?.default_location_state || 'default'
        const gasPrice =
          gasPriceLookup[userState]?.[day] || // Try exact match
          gasPriceLookup[userState]?.[today.toISOString().split('T')[0]] || // Try today's price
          gasPriceLookup[userState]?.[
            Object.keys(gasPriceLookup[userState] || {})
              .sort()
              .pop() || ''
          ] || // Most recent price
          3.0 // Default fallback

        // Retrieve the mpg for the day, default to 12 if null or undefined
        let dailyMpg = dayData?.mpg ?? 12

        // Adjust mpg if the user has a trailer
        if (usersWithTrailer.has(userKey)) {
          dailyMpg *= 0.7 // Multiply mpg by 0.7 if a trailer is used
        }

        // Add fields for mpg and gas price to the combinedData
        combinedData[userKey].daily_mpg = {
          ...combinedData[userKey].daily_mpg,
          [day]: dailyMpg,
        }
        combinedData[userKey].daily_gas_price = {
          ...combinedData[userKey].daily_gas_price,
          [day]: gasPrice,
        }

        // Calculate the expected spend using the day's mpg and gas price
        const dailyExpectedSpend = ((totalExpectedMiles || 0) / dailyMpg) * gasPrice

        // Assign values only if at least one of the metrics is non-zero
        if (expectedMiles > 0 || milesDriven > 0 || dailySpend > 0 || dailyExpectedSpend > 0) {
          combinedData[userKey].daily_commute_miles[day] = combinedData[userKey].daily_commute_miles[day] || commuteMiles
          combinedData[userKey].daily_scheduled_miles[day] = combinedData[userKey].daily_scheduled_miles[day] || scheduledMiles
          combinedData[userKey].daily_miles_driven[day] = milesDriven
          combinedData[userKey].daily_expected_miles[day] = expectedMiles
          combinedData[userKey].daily_expected_total_miles[day] = totalExpectedMiles
          combinedData[userKey].daily_expected_spend[day] = dailyExpectedSpend
        }
      })
    })
  })

  // Step 5: Cleanup - Remove daily fields with all zero metrics
  Object.entries(combinedData).forEach(([userKey, userData]: [string, any]) => {
    allUniqueDays.forEach((day: string) => {
      const finalMilesDriven = userData.daily_miles_driven[day] || 0
      const finalExpectedMiles = userData.daily_expected_miles[day] || 0
      const finalDailySpend = userData.daily_spend[day] || 0
      const finalExpectedSpend = userData.daily_expected_spend[day] || 0

      if (finalMilesDriven === 0 && finalExpectedMiles === 0 && finalDailySpend === 0 && finalExpectedSpend === 0) {
        delete userData.daily_spend[day]
        delete userData.daily_miles_driven[day]
        delete userData.daily_expected_miles[day]
        delete userData.daily_expected_total_miles[day]
        delete userData.daily_expected_spend[day]
        delete userData.daily_commute_miles[day]
        delete userData.daily_scheduled_miles[day]
        if (userData.daily_gas_price) {
          delete userData.daily_gas_price[day]
        }
        if (userData.daily_mpg) {
          delete userData.daily_mpg[day]
        }
      }
    })
  })

  // Step 6: Calculate monthly totals for combinedData
  Object.keys(combinedData).forEach((userKey: string) => {
    const user = combinedData[userKey]

    user.monthly_miles_driven = Object.values(user.daily_miles_driven).reduce((sum: number, miles) => sum + ((miles as number) || 0), 0)
    user.monthly_expected_miles = Object.values(user.daily_expected_miles).reduce((sum: number, miles) => sum + ((miles as number) || 0), 0)
    user.monthly_commute_miles = Object.values(user.daily_commute_miles).reduce((sum: number, miles) => sum + ((miles as number) || 0), 0)
    user.monthly_scheduled_miles = Object.values(user.daily_scheduled_miles).reduce((sum: number, miles) => sum + ((miles as number) || 0), 0)
    user.monthly_expected_total_miles = user.monthly_expected_miles + user.monthly_commute_miles + user.monthly_scheduled_miles
    user.monthly_expected_spend = Object.values(user.daily_expected_spend).reduce((sum: number, spend) => sum + ((spend as number) || 0), 0)
  })

  // **Step 7: Filter Out Inactive Users**
  Object.entries(combinedData).forEach(([userKey, userData]) => {
    if (userData.monthly_miles_driven === 0 && userData.monthly_expected_miles === 0 && userData.monthly_spend === 0) {
      delete combinedData[userKey]
      console.log(`Filtered out user ${userKey} (${userData.associated_user_name}) due to zero metrics.`)
    }
  })

  // Debugging: Log combinedData after filtering
  console.log('----- Combined Data After Filtering -----')
  Object.entries(combinedData).forEach(([userKey, userData]) => {
    console.log(`UserKey: ${userKey}, UserName: ${userData.associated_user_name}`)
  })
  console.log('----- End of Combined Data After Filtering -----')

  // Step 8: Handle specific views
  if (options?.userKey) {
    const user = combinedData[options.userKey]
    if (!user) return { combinedData }

    const userDailyData = Object.keys(user.daily_spend)
      .filter((day) => {
        const expectedMiles = user.daily_expected_miles[day] || 0
        const milesDriven = user.daily_miles_driven[day] || 0
        const dailySpend = user.daily_spend[day] || 0
        const dailyExpectedSpend = user.daily_expected_spend[day] || 0
        // Exclude days where expected miles, actual miles, daily spend, and daily expected spend are all zero
        return !(expectedMiles === 0 && milesDriven === 0 && dailySpend === 0 && dailyExpectedSpend === 0)
      })
      .map((day: string) => ({
        day,
        expectedMiles: user.daily_expected_miles[day]?.toFixed(2) || '0.00',
        commuteMiles: user.daily_commute_miles[day]?.toFixed(2) || '0.00',
        scheduledMiles: user.daily_scheduled_miles[day]?.toFixed(2) || '0.00',
        totalExpectedMiles: user.daily_expected_total_miles[day]?.toFixed(2) || '0.00',
        milesDriven: user.daily_miles_driven[day]?.toFixed(2) || '0.00',
        dailyExpectedSpend: user.daily_expected_spend[day]?.toFixed(2) || '0.00',
        dailyTotalSpent: user.daily_spend[day]?.toFixed(2) || '0.00',
        gasPrice: user.daily_gas_price?.[day]?.toFixed(2) || '0.00',
        mpg: user.daily_mpg?.[day]?.toFixed(2) || '12.00', // Fallback to 12 if missing
      }))

    console.log(`User Daily Data for ${options.userKey}:`, userDailyData)

    return { combinedData, userDailyData }
  } else {
    const transformedAggregateData = Object.values(combinedData).map((user: any) => ({
      userKey: user.associated_user_key,
      userName: user.associated_user_name,
      totalExpectedMiles: user.monthly_expected_total_miles.toFixed(2),
      totalMilesDriven: user.monthly_miles_driven.toFixed(2),
      totalExpectedSpend: user.monthly_expected_spend.toFixed(2),
      totalActualSpend: user.monthly_spend.toFixed(2),
    }))

    console.log('Transformed Aggregate Data:', transformedAggregateData)
    console.log('----- End of Transformed Aggregate Data -----')
    return { combinedData, transformedAggregateData }
  }
}

// Import or define DatabaseRef_User_Document and DatabaseRef_GasPricesForMonth_Query as per your setup

// Define STATE_GAS_TAXES within the function or import it if defined elsewhere
const STATE_GAS_TAXES: Record<string, number> = {
  AL: 0.31, // $0.29 excise + $0.02 inspection fee
  AK: 0.0895,
  AZ: 0.18,
  AR: 0.245,
  CA: 0.579, // Includes carbon tax program
  CO: 0.22,
  CT: 0.25,
  DE: 0.23,
  FL: 0.344,
  GA: 0.295,
  HI: 0.16, // Plus additional county taxes
  ID: 0.32,
  IL: 0.454, // Plus additional local taxes
  IN: 0.34,
  IA: 0.3,
  KS: 0.24,
  KY: 0.26,
  LA: 0.2,
  ME: 0.3,
  MD: 0.445,
  MA: 0.24,
  MI: 0.272,
  MN: 0.285,
  MS: 0.18,
  MO: 0.22,
  MT: 0.33,
  NE: 0.246,
  NV: 0.23, // Plus county taxes
  NH: 0.222,
  NJ: 0.105,
  NM: 0.17,
  NY: 0.4, // Plus local taxes
  NC: 0.404,
  ND: 0.23,
  OH: 0.385,
  OK: 0.2,
  OR: 0.38,
  PA: 0.576,
  RI: 0.35,
  SC: 0.28,
  SD: 0.28,
  TN: 0.274,
  TX: 0.2,
  UT: 0.325,
  VT: 0.31,
  VA: 0.28,
  WA: 0.494,
  WV: 0.357,
  WI: 0.309,
  WY: 0.24,
  DC: 0.28,
}

const FEDERAL_GAS_TAX = 0.184 // Federal tax per gallon

const combineTransactionsAndVehiclesDataForUser = async (
  transactionsData: Record<string, any>,
  combinedVehiclesData: Record<string, any>,
  clientKey: string,
  options?: {
    userKey?: string // Optional: Specify a user key for the user view
  },
  selectedMonth?: Date, // Selected month to filter data
): Promise<{
  combinedData: Record<string, any>
  userDailyData?: Array<any> // Daily data for the specific user (if userKey is provided)
  transformedAggregateData?: Array<any> // Aggregate data for all users
}> => {
  const combinedData: Record<string, any> = {}

  // Extract userKey from options
  const userKey = options?.userKey
  if (!userKey) {
    console.error('No userKey provided in options.')
    return { combinedData }
  }

  // Get month boundaries and today's date
  const startOfMonth = selectedMonth ? new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1) : null
  const endOfMonth = selectedMonth ? new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0) : null
  const today = new Date()

  // Step 1: Initialize combinedData structure with transactionsData and combinedVehiclesData for the specific user
  const userTransactionsData = transactionsData[userKey] || {}
  const userVehiclesDataEntries = Object.entries(combinedVehiclesData).flatMap(([weekKey, weekData]: [string, any]) => {
    const userWeekData = weekData?.data?.[userKey]
    if (userWeekData) {
      return Object.entries(userWeekData).filter(([day, _]) => day.startsWith('202'))
    }
    return []
  })

  // Initialize combinedData with default values
  combinedData[userKey] = {
    associated_user_key: userTransactionsData.associated_user_key || userKey, // Fallback to userKey if missing
    associated_user_name: userTransactionsData.associated_user_name || 'Unknown',
    daily_spend: { ...userTransactionsData.daily_spend },
    monthly_spend: userTransactionsData.monthly_spend || 0,
    default_location_state: null, // Placeholder to be updated
    daily_miles_driven: {},
    daily_expected_miles: {},
    daily_commute_miles: {},
    daily_scheduled_miles: {},
    daily_expected_total_miles: {},
    daily_expected_spend: {},
    daily_gas_price: {},
    daily_mpg: {},
    monthly_miles_driven: 0,
    monthly_expected_miles: 0,
    monthly_commute_miles: 0,
    monthly_scheduled_miles: 0,
    monthly_expected_total_miles: 0,
    monthly_expected_spend: 0,
  }

  console.log(`Initialized combinedData for userKey: ${userKey}`)

  // Step 2: Fetch and assign default_location_state and update associated_user_name from user document
  const userDocRef = DatabaseRef_User_Document(clientKey, String(userKey))
  const userDoc = await getDoc(userDocRef)
  if (userDoc.exists()) {
    const userData = userDoc.data()
    console.log(`Fetched user document for ${userKey}:`, userData)
    combinedData[userKey].default_location_state = userData.default_location_state || 'default'

    // Update associated_user_name from userDoc if available
    if (userData.associated_user_name) {
      // Existing
      combinedData[userKey].associated_user_name = userData.associated_user_name
      console.log(`Set associated_user_name from associated_user_name field for ${userKey}: ${userData.associated_user_name}`)
    } else if (userData.userName) {
      // Existing
      combinedData[userKey].associated_user_name = userData.userName
      console.log(`Set associated_user_name from userName field for ${userKey}: ${userData.userName}`)
    } else if (userData.name) {
      // Existing
      combinedData[userKey].associated_user_name = userData.name
      console.log(`Set associated_user_name from name field for ${userKey}: ${userData.name}`)
    } else if (userData.fullName) {
      // New: Check for 'fullName'
      combinedData[userKey].associated_user_name = userData.fullName
      console.log(`Set associated_user_name from fullName field for ${userKey}: ${userData.fullName}`)
    } else {
      console.warn(`No name fields found in userDoc for ${userKey}. Keeping existing name: ${combinedData[userKey].associated_user_name}`)
    }
  } else {
    console.warn(`User document does not exist for userKey: ${userKey}. Keeping existing name: ${combinedData[userKey].associated_user_name}`)
  }

  // Step 3: Fetch gas prices for all relevant states
  const fetchGasPricesForMonth = async (): Promise<Record<string, Record<string, number>>> => {
    const gasPriceData: Record<string, Record<string, number>> = {}
    const uniqueStates = new Set<string>()

    const userState = combinedData[userKey]?.default_location_state || 'default'
    if (userState !== 'default') {
      uniqueStates.add(userState)
    }

    await Promise.all(
      Array.from(uniqueStates).map(async (state) => {
        const queryRef = DatabaseRef_GasPricesForMonth_Query(clientKey, state, startOfMonth as any, endOfMonth as any)
        const querySnapshot = await getDocs(queryRef)

        gasPriceData[state] = gasPriceData[state] || {}
        querySnapshot.forEach((doc) => {
          const data: any = doc.data()
          const dateKey = new Date(data.timestamp_added?.toDate().toISOString().split('T')[0]) // Normalize to UTC
          const formattedKey = dateKey.toISOString().split('T')[0]
          gasPriceData[state][formattedKey] = parseFloat(data.gas)
        })
        console.log(`Fetched gas prices for state ${state}:`, gasPriceData[state])
      }),
    )

    return gasPriceData
  }

  const gasPriceLookup = await fetchGasPricesForMonth()

  // Step 4: Collect all unique days from transactionsData and combinedVehiclesData
  const allUniqueDays = new Set<string>()

  // Collect days from transactionsData
  Object.values(transactionsData).forEach((userData: any) => {
    Object.keys(userData.daily_spend || {}).forEach((day: string) => allUniqueDays.add(day))
  })

  // Collect days from combinedVehiclesData for the specific user
  Object.entries(combinedVehiclesData).forEach(([weekKey, weekData]: [string, any]) => {
    const userWeekData = weekData?.data?.[userKey]
    if (userWeekData) {
      Object.keys(userWeekData || {}).forEach((key: string) => {
        if (key.startsWith('202')) allUniqueDays.add(key)
      })
    }
  })

  // Step 5: Populate combinedData for all unique days
  allUniqueDays.forEach((day: string) => {
    const dayDate = new Date(day)

    // Filter out days outside the selected month
    if ((startOfMonth && dayDate < startOfMonth) || (endOfMonth && dayDate > endOfMonth)) {
      return
    }

    // Determine trailer usage for the user
    let userWithTrailer = false

    // Check combinedVehiclesData for trailer assignments for the specific user
    Object.entries(combinedVehiclesData).forEach(([weekKey, weekData]: [string, any]) => {
      const userWeekData = weekData?.data?.[userKey]
      if (userWeekData) {
        const dayTrips = userWeekData[day]?.trips || []
        userWithTrailer = dayTrips.some((trip: any) => trip.trailer_name)
      }
    })

    // Populate data for the user
    const userData = transactionsData[userKey] || {}
    combinedData[userKey].daily_spend[day] = userData.daily_spend?.[day] || 0

    Object.entries(combinedVehiclesData).forEach(([weekKey, weekData]: [string, any]) => {
      const userWeekData = weekData?.data?.[userKey]
      if (userWeekData) {
        const commuteMiles = userWeekData?.commute_miles ?? 0
        const scheduledMiles = userWeekData?.scheduled_miles ?? 0
        const dayData = userWeekData?.[day] || {}

        // Only assign commute & scheduled miles if there are expected miles
        if (dayData.expected_miles > 0) {
          combinedData[userKey].daily_commute_miles[day] = commuteMiles
          combinedData[userKey].daily_scheduled_miles[day] = scheduledMiles
        }

        const existingExpectedMiles = combinedData[userKey].daily_expected_miles[day] || 0
        const existingMilesDriven = combinedData[userKey].daily_miles_driven[day] || 0

        const expectedMiles = existingExpectedMiles + (dayData.expected_miles || 0)
        const milesDriven = existingMilesDriven + (dayData.miles_driven || 0)
        const dailySpend = combinedData[userKey].daily_spend[day] || 0

        let totalExpectedMiles = expectedMiles

        // Include commute & scheduled miles only if expectedMiles > 0
        if (expectedMiles > 0) {
          totalExpectedMiles += (combinedData[userKey].daily_commute_miles[day] || 0) + (combinedData[userKey].daily_scheduled_miles[day] || 0)
        }

        // Retrieve gas price
        const userState = combinedData[userKey]?.default_location_state || 'default'
        const baseGasPrice =
          gasPriceLookup[userState]?.[day] || // Exact match
          gasPriceLookup[userState]?.[today.toISOString().split('T')[0]] || // Today’s price
          gasPriceLookup[userState]?.[
            Object.keys(gasPriceLookup[userState] || {})
              .sort()
              .pop() || ''
          ] || // Most recent price
          3.0 // Default fallback

        // Retrieve state tax
        const stateTax = STATE_GAS_TAXES[userState] || 0

        // Calculate total gas price with taxes
        const totalGasPrice = baseGasPrice + stateTax + FEDERAL_GAS_TAX

        // Assign gas price with taxes
        combinedData[userKey].daily_gas_price = {
          ...combinedData[userKey].daily_gas_price,
          [day]: totalGasPrice,
        }

        let dailyMpg = dayData?.mpg ?? 12
        if (userWithTrailer) {
          dailyMpg *= 0.7
        }

        combinedData[userKey].daily_miles_driven[day] = milesDriven
        combinedData[userKey].daily_expected_miles[day] = expectedMiles
        combinedData[userKey].daily_expected_total_miles[day] = totalExpectedMiles
        combinedData[userKey].daily_expected_spend[day] = ((totalExpectedMiles || 0) / dailyMpg) * totalGasPrice
        combinedData[userKey].daily_mpg = {
          ...combinedData[userKey].daily_mpg,
          [day]: dailyMpg,
        }
      }
    })

    // After populating, check if there's *any* non-zero value
    const finalMilesDriven = combinedData[userKey].daily_miles_driven[day] || 0
    const finalExpectedMiles = combinedData[userKey].daily_expected_miles[day] || 0
    const finalDailySpend = combinedData[userKey].daily_spend[day] || 0
    const finalExpectedSpend = combinedData[userKey].daily_expected_spend[day] || 0

    // If absolutely everything is zero, remove it so it's not included
    if (finalMilesDriven === 0 && finalExpectedMiles === 0 && finalDailySpend === 0 && finalExpectedSpend === 0) {
      delete combinedData[userKey].daily_spend[day]
      delete combinedData[userKey].daily_miles_driven[day]
      delete combinedData[userKey].daily_expected_miles[day]
      delete combinedData[userKey].daily_expected_total_miles[day]
      delete combinedData[userKey].daily_expected_spend[day]
      delete combinedData[userKey].daily_commute_miles[day]
      delete combinedData[userKey].daily_scheduled_miles[day]
      if (combinedData[userKey].daily_gas_price) {
        delete combinedData[userKey].daily_gas_price[day]
      }
      if (combinedData[userKey].daily_mpg) {
        delete combinedData[userKey].daily_mpg[day]
      }
    }
  })

  // Step 6: Calculate monthly totals for combinedData
  const user = combinedData[userKey]
  if (user) {
    user.monthly_miles_driven = Object.values(user.daily_miles_driven).reduce((sum: number, miles) => sum + ((miles as number) || 0), 0)
    user.monthly_expected_miles = Object.values(user.daily_expected_miles).reduce((sum: number, miles) => sum + ((miles as number) || 0), 0)
    user.monthly_commute_miles = Object.values(user.daily_commute_miles).reduce((sum: number, miles) => sum + ((miles as number) || 0), 0)
    user.monthly_scheduled_miles = Object.values(user.daily_scheduled_miles).reduce((sum: number, miles) => sum + ((miles as number) || 0), 0)

    user.monthly_expected_total_miles = user.monthly_expected_miles + user.monthly_commute_miles + user.monthly_scheduled_miles

    user.monthly_expected_spend = Object.values(user.daily_expected_spend).reduce((sum: number, spend) => sum + ((spend as number) || 0), 0)
  } else {
    console.warn(`No combinedData found for userKey: ${userKey} after processing.`)
  }

  // Handle specific views
  if (options?.userKey) {
    const user = combinedData[options.userKey]
    if (!user) {
      console.warn(`No data found for userKey: ${options.userKey}`)
      return { combinedData }
    }

    const userDailyData = Object.keys(user.daily_spend)
      .map((day) => {
        const expectedMiles = user.daily_expected_miles[day] || 0
        const milesDriven = user.daily_miles_driven[day] || 0
        const dailySpend = user.daily_spend[day] || 0
        const gasPrice = user.daily_gas_price?.[day] || 0
        const commuteMiles = user.daily_commute_miles[day] || 0
        const scheduledMiles = user.daily_scheduled_miles[day] || 0
        const totalExpectedMiles = user.daily_expected_total_miles[day] || 0
        const dailyExpectedSpend = user.daily_expected_spend[day] || 0

        return {
          day,
          expectedMiles: expectedMiles.toFixed(2),
          commuteMiles: expectedMiles > 0 ? commuteMiles.toFixed(2) : '0.00',
          scheduledMiles: expectedMiles > 0 ? scheduledMiles.toFixed(2) : '0.00',
          totalExpectedMiles: totalExpectedMiles.toFixed(2),
          milesDriven: milesDriven.toFixed(2),
          dailyExpectedSpend: dailyExpectedSpend.toFixed(2),
          dailyTotalSpent: dailySpend.toFixed(2),
          gasPrice: gasPrice.toFixed(2),
          // MPG can be tracked similarly if you store it in daily_mpg
          mpg: user.daily_mpg?.[day]?.toFixed(2) || '12.00',
        }
      })
      // Filter out any that might still be all zeros (if you want to ensure no “empty” rows)
      .filter((row) => {
        return !(
          parseFloat(row.expectedMiles) === 0 &&
          parseFloat(row.milesDriven) === 0 &&
          parseFloat(row.dailyTotalSpent) === 0 &&
          parseFloat(row.dailyExpectedSpend) === 0
        )
      })

    console.log(`User Daily Data for ${options.userKey}:`, userDailyData)

    return { combinedData, userDailyData }
  } else {
    // Aggregate data for all users
    const transformedAggregateData = Object.values(combinedData).map((user: any) => ({
      userKey: user.associated_user_key,
      userName: user.associated_user_name,
      totalExpectedMiles: user.monthly_expected_total_miles.toFixed(2),
      totalMilesDriven: user.monthly_miles_driven.toFixed(2),
      totalExpectedSpend: user.monthly_expected_spend.toFixed(2),
      totalActualSpend: user.monthly_spend.toFixed(2),
    }))

    return { combinedData, transformedAggregateData }
  }
}

export const Container: React.FC = (): JSX.Element => {
  const id: string = useParams<{ id: string }>().id || ''

  const navigate = useNavigate()

  const [selectedMonth, setSelectedMonth] = useState(() => {
    const today = new Date()

    return new Date(today.getFullYear(), today.getMonth(), 1)
  })

  const [transactionsData, setTransactionsData] = useState<TsInterface_UnspecifiedObject>({})
  const [combinedVehicles, setCombinedVehicles] = useState<TsInterface_UnspecifiedObject>({})
  const [combinedData, setCombinedData] = useState<TsInterface_UnspecifiedObject>({})

  const [dailyData, setDailyData] = useState<Array<any>>([])

  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const [cumulativeData, setCumulativeData] = useState([])
  const [viewMode, setViewMode] = useState<'daily' | 'cumulative'>('daily')

  // UseEffect for fetching transactions
  useEffect(() => {
    let unsubscribeLiveData: (() => void) | undefined

    const updateLiveData = (transactionData: Record<string, any>) => {
      // Filter transactions data by associated_user_key matching the id
      const filteredTransactionsData = Object.fromEntries(Object.entries(transactionData).filter(([_, userData]) => userData.associated_user_key === id))

      setTransactionsData(filteredTransactionsData)
    }

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        if (!selectedMonth) return

        const selectedMonthKey = `${selectedMonth.getFullYear()}-${(selectedMonth.getMonth() + 1).toString().padStart(2, '0')}`
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_CachedAuthorizations_Document(res_GCK.clientKey, selectedMonthKey), updateLiveData)
      })
      .catch((error) => console.error('Error fetching client key for transactions:', error))

    return () => {
      unsubscribeLiveData?.()
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, selectedMonth, id])

  useEffect(() => {
    let unsubscribeLiveData: (() => void) | undefined

    const updateLiveData = (newData: Record<string, any>) => {
      // Filter combined vehicles data by associated_user_key matching the id
      const filteredCombinedVehicles = Object.fromEntries(
        Object.entries(newData).map(([weekKey, weekData]: [string, any]) => {
          const filteredData = Object.fromEntries(
            Object.entries(weekData.data || {}).filter(([_, userWeekData]) => (userWeekData as any).associated_user_key === id),
          )
          return [weekKey, { ...weekData, data: filteredData }]
        }),
      )

      setCombinedVehicles(filteredCombinedVehicles)
      ur_forceRerender()
    }

    const mondays = getMondaysForMonthBoundary(selectedMonth.getFullYear(), selectedMonth.getMonth())

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        if (!id) {
          console.error('User ID (associated_user_key) is required.')
          return
        }

        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_CombinedVehiclesByMondaysList_Query(res_GCK.clientKey, mondays), updateLiveData)
      })
      .catch((error) => console.error('Error fetching combined vehicles for user:', error))

    return () => {
      unsubscribeLiveData?.()
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, selectedMonth, id, ur_forceRerender])

  useEffect(() => {
    if (!transactionsData || !combinedVehicles || !selectedMonth) return

    const fetchAndCombineData = async () => {
      try {
        const combinedDataResult: any = await combineTransactionsAndVehiclesDataForUser(
          transactionsData,
          combinedVehicles,
          uc_RootData_ClientKey as any,
          {
            userKey: id, // Pass the user ID for user-specific data
          },
          selectedMonth, // selectedMonth
        )

        // Save the combined data for all users
        setCombinedData(combinedDataResult.combinedData)

        // Save daily data for the current user (if available), sorted by date
        const sortedDailyData = (combinedDataResult.userDailyData || []).sort((a: any, b: any) => new Date(a.day).getTime() - new Date(b.day).getTime())

        setDailyData(sortedDailyData)

        console.log('Sorted daily data:', sortedDailyData) // Debug log

        // Calculate cumulative data
        let cumulativeMiles = 0
        let cumulativeExpectedMiles = 0

        const calculatedCumulativeData = sortedDailyData.map((entry: any, index: number) => {
          const milesDriven = Number(entry.milesDriven) || 0 // Ensure it's a number
          const expectedMiles = Number(entry.totalExpectedMiles) || 0 // Ensure it's a number

          // Update cumulative totals
          cumulativeMiles += milesDriven
          cumulativeExpectedMiles += expectedMiles

          // Return entry with 2 decimal points
          return {
            ...entry,
            cumulativeMilesDriven: Number(cumulativeMiles.toFixed(2)), // Round to 2 decimal points
            cumulativeExpectedMiles: Number(cumulativeExpectedMiles.toFixed(2)), // Round to 2 decimal points
            milesDriven: Number(milesDriven.toFixed(2)), // Ensure milesDriven is also rounded
            totalExpectedMiles: Number(expectedMiles.toFixed(2)), // Ensure totalExpectedMiles is also rounded
          }
        })

        setCumulativeData(calculatedCumulativeData)
      } catch (error) {
        console.error('Error combining transactions and vehicle data:', error)
      }
    }

    fetchAndCombineData()
  }, [transactionsData, combinedVehicles, uc_RootData_ClientKey, id, selectedMonth])

  const tableColumns_DailyData: TsInterface_TableColumns = {
    day: {
      header: {
        header_jsx: () => rLIB('Day'),
        header_sort_by: 'day',
      },
      cell: {
        cell_jsx: (rowData: any) => <span>{rowData.day}</span>,
      },
    },
    expectedMiles: {
      header: {
        header_jsx: () => rLIB('Expected Miles'),
        header_sort_by: 'expectedMiles',
      },
      cell: {
        cell_jsx: (rowData: any) => <span>{parseFloat(rowData.expectedMiles).toFixed(2)} miles</span>,
      },
    },
    commuteMiles: {
      header: {
        header_jsx: () => rLIB('Commute Miles'),
        header_sort_by: 'commuteMiles',
      },
      cell: {
        cell_jsx: (rowData: any) => {
          console.log('Commute miles in row data:', rowData.commuteMiles) // Debug log
          const commuteMiles = parseFloat(rowData.commuteMiles || 0).toFixed(2)
          return <span>{commuteMiles} miles</span>
        },
      },
    },

    scheduledMiles: {
      header: {
        header_jsx: () => rLIB('Scheduled Miles'),
        header_sort_by: 'scheduledMiles',
      },
      cell: {
        cell_jsx: (rowData: any) => {
          const scheduledMiles = parseFloat(rowData.scheduledMiles || 0).toFixed(2)
          return <span>{scheduledMiles} miles</span>
        },
      },
    },

    totalExpectedMiles: {
      header: {
        header_jsx: () => 'Total Expected Miles',
        header_sort_by: 'totalExpectedMiles',
      },
      cell: {
        cell_jsx: (rowData: any) => <span>{parseFloat(rowData.totalExpectedMiles).toFixed(2)} miles</span>,
      },
    },
    milesDriven: {
      header: {
        header_jsx: () => rLIB('Actual Miles'),
        header_sort_by: 'milesDriven',
      },
      cell: {
        cell_jsx: (rowData: any) => <span>{parseFloat(rowData.milesDriven).toFixed(2)} miles</span>,
      },
    },
    differenceMiles: {
      header: {
        header_jsx: () => rLIB('Difference in Miles'),
      },
      cell: {
        cell_jsx: (rowData: any) => {
          const differenceMiles = (parseFloat(rowData.totalExpectedMiles) - parseFloat(rowData.milesDriven)).toFixed(2)
          return (
            <Box
              sx={{
                fontWeight: 'bold',
                color: parseFloat(differenceMiles) >= 0 ? themeVariables.success_main : themeVariables.error_main,
              }}
            >
              {differenceMiles} {rLIB('miles')}
            </Box>
          )
        },
      },
    },

    dailyTotalSpent: {
      header: {
        header_jsx: () => rLIB('Daily Total Spent'),
        header_sort_by: 'dailyTotalSpent',
      },
      cell: {
        cell_jsx: (rowData: any) => <span>${parseFloat(rowData.dailyTotalSpent).toFixed(2)}</span>,
      },
    },
    gasPrice: {
      header: {
        header_jsx: () => rLIB('Gas Price'),
        header_sort_by: 'gasPrice',
      },
      cell: {
        cell_jsx: (rowData: any) => <span>${parseFloat(rowData.gasPrice).toFixed(2)}</span>,
      },
    },
    mpg: {
      header: {
        header_jsx: () => rLIB('MPG'),
        header_sort_by: 'mpg',
      },
      cell: {
        cell_jsx: (rowData: any) => <span>{parseFloat(rowData.mpg).toFixed(2)}</span>,
      },
    },
  }

  const tableSettings_DailyData: TsInterface_TableSettings = {
    show_header: true,

    sort_direction: 'asc',
    sort_property_default: 'day', // Default sorting by the 'day' field
    sortable: true, // Enable sorting
    collapsible_columns: true,
  }

  const rJSX_DailyDataTable = (): JSX.Element => {
    return (
      <TableBasic
        tableAdditionalData={{}}
        tableColumns={tableColumns_DailyData}
        tableData={dailyData} // Use your existing data
        tableSettings={tableSettings_DailyData}
      />
    )
  }

  const rJSX_Page = (): JSX.Element => {
    const userSummary = combinedData?.[id as string] || {}
    const { monthly_miles_driven = 0, monthly_expected_total_miles = 0, monthly_spend = 0, monthly_expected_spend = 0 } = userSummary

    const handleViewChange = (event: React.MouseEvent<HTMLElement>, newView: 'daily' | 'cumulative') => {
      if (newView) {
        setViewMode(newView)
      }
    }

    return (
      <AuthenticatedContainer
        pageHeader={`Fuel Use Details for ${userSummary?.associated_user_name || 'User'}`}
        pageKey={pageKey}
        content={
          <Box>
            <Box
              display="flex"
              alignItems="center"
              gap={2} // Adjust the gap value for desired spacing
              sx={{ marginBottom: '20px' }} // Optional for spacing below the row
            >
              <Button
                color="inherit"
                variant="outlined"
                onClick={() => navigate(`${ApplicationPages.AdminDatabaseVehicleUsageReportsPage.url()}?tab=Vehicle_Dashboard`)}
                startIcon={<Icon icon="chevron-left" />}
              >
                {rLIB('Back to Fuel Use Analysis')}
              </Button>

              {/* Month Picker */}
              <TextField
                label="Select Month"
                type="month"
                value={selectedMonth.toISOString().slice(0, 7)}
                size="small"
                onChange={(e) => {
                  const [year, month] = e.target.value.split('-')
                  setSelectedMonth(new Date(Number(year), Number(month) - 1, 1))
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Box>

            {/* Summary Section */}

            <Card
              variant="outlined"
              sx={{
                marginTop: '20px',
                padding: '20px',
                background: (theme) => theme.palette.background.default,
                borderRadius: '12px',
                boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.15)',
              }}
            >
              {/* Summary Cards */}
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-around"
                alignItems="center"
                flexWrap="wrap"
                gap={3}
              >
                {[
                  {
                    title: 'Miles Difference',
                    difference: (monthly_expected_total_miles - monthly_miles_driven).toFixed(2),
                    isPositive: monthly_expected_total_miles - monthly_miles_driven >= 0,
                    actual: `${monthly_miles_driven.toFixed(2)} miles`,
                    expected: `${monthly_expected_total_miles.toFixed(2)} miles`,
                  },
                  {
                    title: 'Spend Difference',
                    difference: `$${(parseFloat(monthly_expected_spend) - parseFloat(monthly_spend)).toFixed(2)}`,
                    isPositive: parseFloat(monthly_expected_spend) - parseFloat(monthly_spend) >= 0,
                    actual: `$${monthly_spend.toFixed(2)}`,
                    expected: `$${parseFloat(monthly_expected_spend).toFixed(2)}`,
                  },
                ].map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      'padding': '15px',
                      'border': '1px solid',
                      'borderColor': item.isPositive ? '#4caf50' : '#ff4d4d',
                      'borderRadius': '8px',
                      'boxShadow': '0px 2px 5px rgba(0, 0, 0, 0.1)',
                      'position': 'relative',
                      'width': '250px',
                      'textAlign': 'center',
                      '&:hover .tooltip': {
                        opacity: 1,
                        visibility: 'visible',
                      },
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: 'bold', marginBottom: '8px', color: (theme) => theme.palette.text.primary }}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: 'bold',
                        color: item.isPositive ? '#4caf50' : '#ff4d4d',
                      }}
                    >
                      {item.difference} {item.title.includes('Miles') ? 'miles' : 'dollars'}
                    </Typography>

                    {/* Tooltip */}
                    <Box
                      className="tooltip"
                      sx={{
                        opacity: 0,
                        visibility: 'hidden',
                        transition: 'opacity 0.3s',
                        position: 'absolute',
                        bottom: '-50px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        background: 'rgba(0, 0, 0, 0.8)',
                        color: 'white',
                        padding: '10px',
                        borderRadius: '8px',
                        fontSize: '0.8rem',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <Typography sx={{ marginBottom: '4px' }}>
                        <strong>{rLIB('Actual:')}</strong> {item.actual}
                      </Typography>
                      <Typography>
                        <strong>{rLIB('Expected:')}</strong> {item.expected}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>

              {/* Chart Section */}
              <Box sx={{ width: '100%' }}>
                {/* Toggle Switch */}
                <ToggleButtonGroup
                  value={viewMode}
                  exclusive
                  onChange={handleViewChange}
                  aria-label="chart view mode"
                  sx={{ marginBottom: 0 }}
                >
                  <ToggleButton
                    value="daily"
                    aria-label="Daily View"
                  >
                    {rLIB('Daily View')}
                  </ToggleButton>
                  <ToggleButton
                    value="cumulative"
                    aria-label="Cumulative View"
                  >
                    {rLIB('Cumulative View')}
                  </ToggleButton>
                </ToggleButtonGroup>

                {/* Chart Section */}
                <Box sx={{ width: '100%', height: '400px' }}>
                  <ResponsiveContainer>
                    <ComposedChart data={viewMode === 'daily' ? dailyData : cumulativeData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="day"
                        tickFormatter={(tick) => {
                          const adjustedDate = new Date(tick)
                          adjustedDate.setDate(adjustedDate.getDate() + 1) // Add 1 day
                          return adjustedDate.toLocaleDateString()
                        }}
                      />
                      <YAxis />
                      <Tooltip
                        formatter={(value, name) => {
                          if (viewMode === 'daily') {
                            if (name === 'milesDriven') {
                              return [`${value} miles`, 'Actual Miles']
                            }
                            if (name === 'totalExpectedMiles') {
                              return [`${value} miles`, 'Expected Miles']
                            }
                            if (name === 'difference') {
                              return [`${value} miles`, 'Difference']
                            }
                          } else {
                            if (name === 'cumulativeMilesDriven') {
                              return [`${value} miles`, 'Cumulative Actual Miles']
                            }
                            if (name === 'cumulativeExpectedMiles') {
                              return [`${value} miles`, 'Cumulative Expected Miles']
                            }
                          }
                          return [`${value}`, name]
                        }}
                        labelFormatter={(label) => {
                          const adjustedDate = new Date(label)
                          adjustedDate.setDate(adjustedDate.getDate() + 1) // Add 1 day
                          return <span style={{ color: 'black', fontWeight: 'bold' }}>Date: {adjustedDate.toLocaleDateString()}</span>
                        }}
                      />
                      <Legend
                        verticalAlign="top"
                        height={36}
                        payload={[
                          ...(viewMode === 'daily'
                            ? [
                                { value: 'Miles Driven', type: 'line' as const, color: themeVariables.primary_main },
                                { value: 'Expected Miles', type: 'line' as const, color: themeVariables.secondary_main },
                              ]
                            : [
                                { value: 'Cumulative Actual Miles', type: 'line' as const, color: themeVariables.primary_main },
                                { value: 'Cumulative Expected Miles', type: 'line' as const, color: themeVariables.secondary_main },
                              ]),
                        ]}
                      />

                      {/* Hidden Bar for Alignment */}
                      <Bar
                        dataKey={viewMode === 'daily' ? 'difference' : ''}
                        stackId="a"
                        fill="transparent"
                        name="Difference"
                      />

                      {/* Render Lines Conditionally */}
                      {viewMode === 'daily' ? (
                        <>
                          {/* Line for Daily Miles Driven */}
                          <Line
                            type="monotone"
                            dataKey="milesDriven"
                            stroke={themeVariables.primary_main}
                            strokeWidth={2}
                            dot={false}
                            name="Miles Driven"
                          />
                          {/* Line for Daily Expected Miles */}
                          <Line
                            type="monotone"
                            dataKey="totalExpectedMiles"
                            stroke={themeVariables.secondary_main}
                            strokeWidth={2}
                            dot={false}
                            name="Expected Miles"
                          />
                        </>
                      ) : (
                        <>
                          {/* Line for Cumulative Miles Driven */}
                          <Line
                            type="monotone"
                            dataKey="cumulativeMilesDriven"
                            stroke={themeVariables.primary_main}
                            strokeWidth={2}
                            dot={false}
                            name="Cumulative Actual Miles"
                          />
                          {/* Line for Cumulative Expected Miles */}
                          <Line
                            type="monotone"
                            dataKey="cumulativeExpectedMiles"
                            stroke={themeVariables.secondary_main}
                            strokeWidth={2}
                            dot={false}
                            name="Cumulative Expected Miles"
                          />
                        </>
                      )}
                    </ComposedChart>
                  </ResponsiveContainer>
                </Box>
              </Box>
            </Card>

            {/* Data Table */}
            <Card className="tw-mt-4">
              <Box>{rJSX_DailyDataTable()}</Box>
            </Card>
          </Box>
        }
      />
    )
  }

  return rJSX_Page()
}
