///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

			Mileage Analysis
			// TODO ? - look for cases in vehicleMileageLogs odometer has a jump that doesn't match up with mileages

			Fuel Analysis


	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  AppBar,
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material/'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { DateTime } from 'luxon'
import React, { useContext, useEffect, useReducer, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_CachedAuthorizations_Document, DatabaseRef_UsersWithVehicleGarages_Query } from 'rfbp_aux/services/database_endpoints/directory/users'
import { DatabaseRef_ActiveVehicles_Query } from 'rfbp_aux/services/database_endpoints/directory/vehicles'
import {
  DatabaseRef_CancelledScheduledTasksInDateRangeArray_Query,
  DatabaseRef_CancelledScheduledTasksOnDateForSpecificUser_Query,
} from 'rfbp_aux/services/database_endpoints/operations/cancelled_scheduled_tasks'
import { DatabaseRef_CombinedVehiclesByMondaysList_Query } from 'rfbp_aux/services/database_endpoints/operations/gm_dashboards'
import {
  DatabaseRef_ScheduledTasksInDateRangeArray_Query,
  DatabaseRef_ScheduledTasksOnDateForSpecificUser_Query,
} from 'rfbp_aux/services/database_endpoints/operations/tasks'
import {
  DatabaseRef_VehicleAssignmentHistory_DateRange_Query,
  DatabaseRef_VehicleAssignmentHistory_VehiclePreviousRecord_Query,
} from 'rfbp_aux/services/database_endpoints/operations/vehicle_assignment_history'
import {
  DatabaseRef_FuelLogDriverPin_Collection,
  DatabaseRef_FuelLogDriverPin_Document,
  DatabaseRef_FuelLogGeolocation_Collection,
  DatabaseRef_FuelLogGeolocation_Document,
  DatabaseRef_VehicleFuelLogs_Collection,
  DatabaseRef_VehicleFuelLogs_Document,
  DatabaseRef_VehicleFuelLogs_Query,
} from 'rfbp_aux/services/database_endpoints/operations/vehicle_fuel_logs'
import {
  DatabaseRef_ActiveVehicleMileageGarages_Query,
  DatabaseRef_AdditionalMileRequests_Document,
  DatabaseRef_AdditionalMileRequests_SpecificDate_Query,
  DatabaseRef_PersonalCommuteMiles_Collection,
  DatabaseRef_PersonalCommuteMiles_Document,
  DatabaseRef_ScheduledWorkMiles_Collection,
  DatabaseRef_ScheduledWorkMiles_Document,
  DatabaseRef_VehicleMileageGarages_Collection,
  DatabaseRef_VehicleMileageLogs_Analysis_Document,
  DatabaseRef_VehicleMileageLogs_Analysis_Drivers_Collection,
  DatabaseRef_VehicleMileageLogs_Analysis_Driver_Document,
  DatabaseRef_VehicleMileageLogs_Analysis_GeocodedDistances_Document,
  DatabaseRef_VehicleMileageLogs_Collection,
  DatabaseRef_VehicleMileageLogs_Document,
  DatabaseRef_VehicleMileageLogs_Query,
} from 'rfbp_aux/services/database_endpoints/operations/vehicle_mileage_logs'
import { DatabaseRef_ClientUser_Document } from 'rfbp_aux/services/database_endpoints/standard_database_endpoints'
import { DatabaseRef_TimeSheetsGeolocationUsersHistory_DateRange_Query } from 'rfbp_aux/services/database_endpoints/timesheets/geolocation'
import { Json } from 'rfbp_core/components/code_display'
import { DatePicker } from 'rfbp_core/components/date_picker'
import { TransitionSlide } from 'rfbp_core/components/dialog/transitions/slide'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { TsInterface_InputHooksObject } from 'rfbp_core/components/form/form_types'
import { Icon } from 'rfbp_core/components/icons'
import { BasicImportButtonAndDialog } from 'rfbp_core/components/imports/basic_import_button_and_dialog'
import { MapBasic, TsInterface_MapMarkers, TsInterface_MapSettings, TsType_MapOnClick } from 'rfbp_core/components/map'
import { rJSX_HighlightedSearchString, SearchInput } from 'rfbp_core/components/search'
import {
  TableBasic,
  TableCellBasic,
  TableCellManage,
  TableCellTimestamp,
  TableDatabase,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseEndpointQueryObject,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { TableCellCurrency } from 'rfbp_core/components/table/cells/table_cell_currency'
import { TabsBasic, TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import {
  Context_RootData_ClientKey,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'
import {
  DatabaseAddDocument,
  DatabaseBatchUpdate,
  DatabaseDeleteDocument,
  DatabaseGetCollection,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
  DatabaseStagedBatchUpdate,
  generateDatabaseQuery,
  TsInterface_DatabaseBatchUpdatesArray,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import {
  cloneObjectWithoutReference,
  downloadCSV,
  dynamicSort,
  formatCurrency,
  formatDateToYYYYMMDD,
  getProp,
  objectToArray,
  returnDateFromUnknownDateFormat,
  returnFormattedDate,
  returnFormattedDateKey,
  underscoresToSpaces,
} from 'rfbp_core/services/helper_functions'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_MuiComponentColors, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import Stripe from 'stripe'
import { combineTransactionsAndVehiclesData } from './fuel_use_user_view'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_MileageAnalysisDayDialog {
  vehicleData: TsInterface_UnspecifiedObject
  dateKey: string
  tableAdditionalData: TsInterface_UnspecifiedObject
}

interface TsInterface_FuelAnalysisMapDialog {
  dateKey: string
  driverData: TsInterface_UnspecifiedObject
  fuelLog: TsInterface_UnspecifiedObject
  garage: TsInterface_UnspecifiedObject
}

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminDatabaseVehicleUsageReportsPage']['key']

// Timezones
const timezoneAbbreviations: TsInterface_UnspecifiedObject = {
  // Common U.S. timezones
  EST: 'America/New_York',
  EDT: 'America/New_York',
  CST: 'America/Chicago',
  CDT: 'America/Chicago',
  MST: 'America/Denver',
  MDT: 'America/Denver',
  PST: 'America/Los_Angeles',
  PDT: 'America/Los_Angeles',
}

// Imports
const defaultImportFormatter = (exportKey: string, dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
  let formattedOutput = null
  if (rawInput != null && rawInput !== '') {
    formattedOutput = rawInput
  }
  return { key: exportKey, value: formattedOutput }
}

const numberImportFormatter = (exportKey: string, dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
  let formattedOutput = null
  if (rawInput != null && rawInput !== '') {
    if (rawInput === 'NA') {
      formattedOutput = null
    } else {
      formattedOutput = parseFloat(rawInput)
    }
  }
  return { key: exportKey, value: formattedOutput }
}

const mileageImportFormatter: TsInterface_UnspecifiedObject = {
  // OLD IMPORT
  'Vin': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('vin', dataRow, rawInput)
  },
  'Unit #': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('unit_number', dataRow, rawInput)
  },
  'Driver Name': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('associated_driver_name', dataRow, rawInput)
  },
  'Customer Name': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('associated_customer_name', dataRow, rawInput)
  },
  'Customer ID': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('associated_customer_id', dataRow, rawInput)
  },
  'Office': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('associated_office_name', dataRow, rawInput)
  },
  'Department': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('associated_department_name', dataRow, rawInput)
  },
  'Event': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    let formattedOutput = null
    if (rawInput != null && rawInput !== '') {
      formattedOutput = rawInput.replace(' ', '_').toLowerCase()
    }
    return { key: 'associated_event', value: formattedOutput }
  },
  'Start Time': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    let formattedOutput = null
    if (rawInput === 'NA') {
      formattedOutput = null
    } else if (rawInput != null && rawInput !== '') {
      let timezone = dataRow['Timezone']
      if (timezone != null && timezoneAbbreviations[timezone] != null) {
        formattedOutput = DateTime.fromFormat(rawInput, 'MM/dd/yy H:mm', { zone: timezoneAbbreviations[timezone] }).toJSDate()
      } else {
        formattedOutput = new Date(rawInput)
      }
    }
    return { key: 'timestamp_start', value: formattedOutput }
  },
  'End Time': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    let formattedOutput = null
    if (rawInput === 'NA') {
      formattedOutput = null
    } else if (rawInput != null && rawInput !== '') {
      let timezone = dataRow['Timezone']
      if (timezone != null && timezoneAbbreviations[timezone] != null) {
        formattedOutput = DateTime.fromFormat(rawInput, 'MM/dd/yy H:mm', { zone: timezoneAbbreviations[timezone] }).toJSDate()
      } else {
        formattedOutput = new Date(rawInput)
      }
    }
    return { key: 'timestamp_end', value: formattedOutput }
  },
  'Timezone': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('timezone', dataRow, rawInput)
  },
  'Duration (mins)': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return numberImportFormatter('duration_minutes', dataRow, rawInput)
  },
  'Distance (mi)': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return numberImportFormatter('distance_miles', dataRow, rawInput)
  },
  'Starting Odometer (mi)': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return numberImportFormatter('odometer_starting_miles', dataRow, rawInput)
  },
  'Start Location': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('location_start', dataRow, rawInput)
  },
  'Start Location Lat': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return numberImportFormatter('location_start_latitude', dataRow, rawInput)
  },
  'Start Location Lon': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return numberImportFormatter('location_start_longitude', dataRow, rawInput)
  },
  'End Location Lat': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return numberImportFormatter('location_end_latitude', dataRow, rawInput)
  },
  'End Location Lon': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return numberImportFormatter('location_end_longitude', dataRow, rawInput)
  },
  // NEW IMPORT
  'vin': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('vin', dataRow, rawInput)
  },
  'unit_number': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('unit_number', dataRow, rawInput)
  },
  'associated_driver_name': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('associated_driver_name', dataRow, rawInput)
  },
  'associated_customer_name': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('associated_customer_name', dataRow, rawInput)
  },
  'associated_customer_id': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('associated_customer_id', dataRow, rawInput)
  },
  'associated_office_name': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('associated_office_name', dataRow, rawInput)
  },
  'associated_department_name': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('associated_department_name', dataRow, rawInput)
  },
  'associated_event': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    let formattedOutput = null
    if (rawInput != null && rawInput !== '') {
      formattedOutput = rawInput.replace(' ', '_').toLowerCase()
    }
    return { key: 'associated_event', value: formattedOutput }
  },
  'timestamp_start': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    let formattedOutput = null
    if (rawInput === 'NA') {
      formattedOutput = null
    } else if (rawInput != null && rawInput !== '') {
      let timezone = dataRow['Timezone']
      if (timezone != null && timezoneAbbreviations[timezone] != null) {
        formattedOutput = DateTime.fromFormat(rawInput, 'MM/dd/yy H:mm', { zone: timezoneAbbreviations[timezone] }).toJSDate()
      } else {
        formattedOutput = new Date(rawInput)
      }
    }
    return { key: 'timestamp_start', value: formattedOutput }
  },
  'timestamp_end': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    let formattedOutput = null
    if (rawInput === 'NA') {
      formattedOutput = null
    } else if (rawInput != null && rawInput !== '') {
      let timezone = dataRow['Timezone']
      if (timezone != null && timezoneAbbreviations[timezone] != null) {
        formattedOutput = DateTime.fromFormat(rawInput, 'MM/dd/yy H:mm', { zone: timezoneAbbreviations[timezone] }).toJSDate()
      } else {
        formattedOutput = new Date(rawInput)
      }
    }
    return { key: 'timestamp_end', value: formattedOutput }
  },
  'timezone': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('timezone', dataRow, rawInput)
  },
  'duration_minutes': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return numberImportFormatter('duration_minutes', dataRow, rawInput)
  },
  'distance_miles': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return numberImportFormatter('distance_miles', dataRow, rawInput)
  },
  'odometer_starting_miles': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return numberImportFormatter('odometer_starting_miles', dataRow, rawInput)
  },
  'location_start': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('location_start', dataRow, rawInput)
  },
  'location_start_latitude': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return numberImportFormatter('location_start_latitude', dataRow, rawInput)
  },
  'location_start_longitude': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return numberImportFormatter('location_start_longitude', dataRow, rawInput)
  },
  'location_end_latitude': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return numberImportFormatter('location_end_latitude', dataRow, rawInput)
  },
  'location_end_longitude': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return numberImportFormatter('location_end_longitude', dataRow, rawInput)
  },
}

const mileageExportFormatter: TsInterface_UnspecifiedObject = {
  vin: {
    header: 'Vin',
    returnCellData: (dataRow: TsInterface_UnspecifiedObject) => {
      return getProp(dataRow, 'vin', '')
    },
  },
  unit_number: {
    header: 'Unit Number',
    returnCellData: (dataRow: TsInterface_UnspecifiedObject) => {
      return getProp(dataRow, 'unit_number', '')
    },
  },
  associated_driver_name: {
    header: 'Driver Name',
    returnCellData: (dataRow: TsInterface_UnspecifiedObject) => {
      return getProp(dataRow, 'associated_driver_name', '')
    },
  },
  associated_customer_name: {
    header: 'Customer Name',
    returnCellData: (dataRow: TsInterface_UnspecifiedObject) => {
      return getProp(dataRow, 'associated_customer_name', '')
    },
  },
  associated_customer_id: {
    header: 'Customer ID',
    returnCellData: (dataRow: TsInterface_UnspecifiedObject) => {
      return getProp(dataRow, 'associated_customer_id', '')
    },
  },
  associated_office_name: {
    header: 'Office',
    returnCellData: (dataRow: TsInterface_UnspecifiedObject) => {
      return getProp(dataRow, 'associated_office_name', '')
    },
  },
  associated_department_name: {
    header: 'Department',
    returnCellData: (dataRow: TsInterface_UnspecifiedObject) => {
      return getProp(dataRow, 'associated_department_name', '')
    },
  },
  associated_employee_id: {
    header: 'Employee ID',
    returnCellData: (dataRow: TsInterface_UnspecifiedObject) => {
      return getProp(dataRow, 'associated_employee_id', '')
    },
  },
  associated_event: {
    header: 'Event',
    returnCellData: (dataRow: TsInterface_UnspecifiedObject) => {
      return getProp(dataRow, 'associated_event', '')
    },
  },
  timestamp_start: {
    header: 'Start Time',
    returnCellData: (dataRow: TsInterface_UnspecifiedObject) => {
      let cellData = ''
      if (getProp(dataRow, 'timestamp_start', null) != null) {
        cellData = returnFormattedDate(getProp(dataRow, 'timestamp_start', null), 'MM/DD/YY H:mm')
      }
      return cellData
    },
  },
  timestamp_end: {
    header: 'End Time',
    returnCellData: (dataRow: TsInterface_UnspecifiedObject) => {
      let cellData = ''
      if (getProp(dataRow, 'timestamp_end', null) != null) {
        cellData = returnFormattedDate(getProp(dataRow, 'timestamp_end', null), 'MM/DD/YY H:mm')
      }
      return cellData
    },
  },
  timezone: {
    header: 'Timezone',
    returnCellData: (dataRow: TsInterface_UnspecifiedObject) => {
      return getProp(dataRow, 'timezone', '')
    },
  },
  duration_minutes: {
    header: 'Duration (mins)',
    returnCellData: (dataRow: TsInterface_UnspecifiedObject) => {
      return getProp(dataRow, 'duration_minutes', '')
    },
  },
  distance_miles: {
    header: 'Distance (mi)',
    returnCellData: (dataRow: TsInterface_UnspecifiedObject) => {
      return getProp(dataRow, 'distance_miles', '')
    },
  },
  odometer_starting_miles: {
    header: 'Starting Odometer (mi)',
    returnCellData: (dataRow: TsInterface_UnspecifiedObject) => {
      return getProp(dataRow, 'odometer_starting_miles', '')
    },
  },
  location_start: {
    header: 'Start Location',
    returnCellData: (dataRow: TsInterface_UnspecifiedObject) => {
      return getProp(dataRow, 'location_start', '')
    },
  },
  location_start_latitude: {
    header: 'Start Location Lat',
    returnCellData: (dataRow: TsInterface_UnspecifiedObject) => {
      return getProp(dataRow, 'location_start_latitude', '')
    },
  },
  location_start_longitude: {
    header: 'Start Location Lon',
    returnCellData: (dataRow: TsInterface_UnspecifiedObject) => {
      return getProp(dataRow, 'location_start_longitude', '')
    },
  },
  location_end_latitude: {
    header: 'End Location Lat',
    returnCellData: (dataRow: TsInterface_UnspecifiedObject) => {
      return getProp(dataRow, 'location_end_latitude', '')
    },
  },
  location_end_longitude: {
    header: 'End Location Lon',
    returnCellData: (dataRow: TsInterface_UnspecifiedObject) => {
      return getProp(dataRow, 'location_end_longitude', '')
    },
  },
}

const fuelImportFormatter: TsInterface_UnspecifiedObject = {
  // OLD IMPORT
  'Transaction Date': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    let formattedOutput = null
    if (dataRow != null && dataRow['Transaction Date'] != null && dataRow['Transaction Time'] != null) {
      // formattedOutput = DateTime.fromFormat( dataRow[ "Transaction Date" ] + " " + dataRow[ "Transaction Time" ], 'MM/dd/yyyy HH:mm:ss' ).toJSDate()
      formattedOutput = new Date(dataRow['Transaction Date'] + ' ' + dataRow['Transaction Time'])
    }
    return { key: 'timestamp_transaction', value: formattedOutput }
  },
  // "Transaction Time": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 			return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  // "Post Date": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 					return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  // "Account Number": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 				return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  // "Account Name": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 				return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  'Card Number': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('card_number', dataRow, rawInput)
  },
  'Trans ID': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('transaction_id', dataRow, rawInput)
  },
  // "Emboss Line 2": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 				return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  // "Custom Vehicle/Asset ID": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 	return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  'Units': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return numberImportFormatter('gallons', dataRow, rawInput)
  },
  // "Unit of Measure": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 			return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  'Unit Cost': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return numberImportFormatter('dollars_per_gallon', dataRow, rawInput)
  },
  'Total Fuel Cost': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return numberImportFormatter('total_dollars', dataRow, rawInput)
  },
  // "Service Cost": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 				return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  // "Other Cost": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 					return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  // "Total Non-Fuel Cost": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 		return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  // "Gross Cost": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 					return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  // "Exempt Tax": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 					return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  // "Discount": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 					return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  // "Net Cost": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 					return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  // "Reported Tax": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 				return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  // "Transaction Fee Type 1": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 		return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  // "Transaction Fee Amount 1": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 	return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  // "Transaction Fee Type 2": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 		return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  // "Transaction Fee Amount 2": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 	return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  // "Transaction Fee Type 3": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 		return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  // "Transaction Fee Amount 3": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 	return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  // "Transaction Fee Type 4": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 		return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  // "Transaction Fee Amount 4": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 	return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  // "Transaction Fee Type 5": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 		return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  // "Transaction Fee Amount 5": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 	return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  'Product': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('associated_product_key', dataRow, rawInput)
  },
  'Product Description': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('associated_product_name', dataRow, rawInput)
  },
  // "Transaction Description": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 	return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  'Merchant (Brand)': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('associated_merchant_brand', dataRow, rawInput)
  },
  'Merchant Name': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('associated_merchant_name', dataRow, rawInput)
  },
  'Merchant Address': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('associated_merchant_address', dataRow, rawInput)
  },
  'Merchant City': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('associated_merchant_city', dataRow, rawInput)
  },
  'Merchant State / Province': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('associated_merchant_state', dataRow, rawInput)
  },
  'Merchant Postal Code': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('associated_merchant_zip', dataRow, rawInput)
  },
  'Merchant Site ID': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('associated_merchant_site_id', dataRow, rawInput)
  },
  // "Current Odometer": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 			return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  // "Adjusted Odometer": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 			return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  // "Previous Odometer": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 			return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  // "Distance Driven": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 			return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  // "Fuel Economy": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 				return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  // "Cost Per Distance": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 			return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  // "Vehicle Description": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 		return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  'VIN': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('associated_vehicle_vin', dataRow, rawInput)
  },
  // "Tank Capacity": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 				return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  // "In Service Date": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 			return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  // "Start Odometer": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 				return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  'Driver Last Name': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('associated_driver_last_name', dataRow, rawInput)
  },
  'Driver First Name': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('associated_driver_first_name', dataRow, rawInput)
  },
  // "Driver Middle Name": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 			return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  // "Driver Department": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 			return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  // "Employee ID": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 				return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  // "Transaction Ticket Number": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 	return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  // "Currency Exchange Rate": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 		return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  // "Rebate Code": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 				return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  'Driver Prompt ID': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('associated_driver_pin', dataRow, rawInput)
  },
  // "Vehicle Prompt ID": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 			return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  // "Department": ( dataRow: TsInterface_UnspecifiedObject, rawInput: string ) => { 					return defaultImportFormatter( "ASDF", dataRow, rawInput ) },
  // NEW IMPORT
  'timestamp_transaction': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    let formattedOutput = null
    if (dataRow != null && dataRow['Transaction Date'] != null && dataRow['Transaction Time'] != null) {
      formattedOutput = new Date(dataRow['Transaction Date'] + ' ' + dataRow['Transaction Time'])
    }
    return { key: 'timestamp_transaction', value: formattedOutput }
  },
  'card_number': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('card_number', dataRow, rawInput)
  },
  'transaction_id': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('transaction_id', dataRow, rawInput)
  },
  'gallons': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return numberImportFormatter('gallons', dataRow, rawInput)
  },
  'dollars_per_gallon': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return numberImportFormatter('dollars_per_gallon', dataRow, rawInput)
  },
  'total_dollars': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return numberImportFormatter('total_dollars', dataRow, rawInput)
  },
  'associated_product_key': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('associated_product_key', dataRow, rawInput)
  },
  'associated_product_name': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('associated_product_name', dataRow, rawInput)
  },
  'associated_merchant_brand': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('associated_merchant_brand', dataRow, rawInput)
  },
  'associated_merchant_name': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('associated_merchant_name', dataRow, rawInput)
  },
  'associated_merchant_address': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('associated_merchant_address', dataRow, rawInput)
  },
  'associated_merchant_city': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('associated_merchant_city', dataRow, rawInput)
  },
  'associated_merchant_state': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('associated_merchant_state', dataRow, rawInput)
  },
  'associated_merchant_zip': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('associated_merchant_zip', dataRow, rawInput)
  },
  'associated_merchant_site_id': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('associated_merchant_site_id', dataRow, rawInput)
  },
  'associated_vehicle_vin': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('associated_vehicle_vin', dataRow, rawInput)
  },
  'associated_driver_last_name': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('associated_driver_last_name', dataRow, rawInput)
  },
  'associated_driver_first_name': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('associated_driver_first_name', dataRow, rawInput)
  },
  'associated_driver_pin': (dataRow: TsInterface_UnspecifiedObject, rawInput: string) => {
    return defaultImportFormatter('associated_driver_pin', dataRow, rawInput)
  },
}

// Table
const tableColumns_MileageLogs: TsInterface_TableColumns = {
  vin: TableCellBasic('vin', rLIB('VIN'), 'vin'),
  associated_driver_name: TableCellBasic('associated_driver_name', rLIB('Driver Name'), 'associated_driver_name'),
  associated_event: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Event')
      },
      header_sort_by: 'associated_event',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const rJSX_EventChip = (logEvent: string): JSX.Element => {
          let chipJSX = <></>
          switch (logEvent) {
            case 'stop':
              chipJSX = (
                <Chip
                  color="error"
                  className=""
                  icon={
                    <Icon
                      icon="pause"
                      className="tw-px-1"
                      size="xl"
                    />
                  }
                  label={underscoresToSpaces(logEvent)}
                  variant="filled"
                />
              )
              break
            case 'trip':
              chipJSX = (
                <Chip
                  color="success"
                  className=""
                  icon={
                    <Icon
                      icon="traffic-light"
                      className="tw-px-1"
                      size="xl"
                    />
                  }
                  label={underscoresToSpaces(logEvent)}
                  variant="filled"
                />
              )
              break
            case 'overnight_stop':
              chipJSX = (
                <Chip
                  color="info"
                  className=""
                  icon={
                    <Icon
                      icon="moon"
                      className="tw-px-1"
                      size="xl"
                    />
                  }
                  label={underscoresToSpaces(logEvent)}
                  variant="filled"
                />
              )
              break
            default:
              chipJSX = (
                <Chip
                  color="warning"
                  className=""
                  icon={
                    <Icon
                      icon="circle-question"
                      className="tw-px-1"
                      size="xl"
                    />
                  }
                  label={underscoresToSpaces(logEvent)}
                  variant="filled"
                />
              )
          }
          return chipJSX
        }
        let cellJSX = <>{rJSX_EventChip(rowData.associated_event as string)}</>
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return returnCSS_MileageAnalysisTableCell(rowData, tableAdditionalData, 'ASDF')
      },
    },
  },
  timestamp_start: TableCellTimestamp('timestamp_start', rLIB('Start Time'), 'timestamp_start', 'MM/DD/YY HH:mm', true),
  timestamp_end: TableCellTimestamp('timestamp_end', rLIB('End Time'), 'timestamp_end', 'MM/DD/YY HH:mm', true),
  duration_minutes: TableCellBasic('duration_minutes', rLIB('Minutes'), 'duration_minutes'),
  distance_miles: TableCellBasic('distance_miles', rLIB('Miles'), 'distance_miles'),
  odometer_starting_miles: TableCellBasic('odometer_starting_miles', rLIB('Starting Odometer'), 'odometer_starting_miles'),
  location_start_latitude: TableCellBasic('location_start_latitude', rLIB('Start Lat'), 'location_start_latitude'),
  location_start_longitude: TableCellBasic('location_start_longitude', rLIB('Start Lng'), 'location_start_longitude'),
  location_end_latitude: TableCellBasic('location_end_latitude', rLIB('End Lat'), 'location_end_latitude'),
  location_end_longitude: TableCellBasic('location_end_longitude', rLIB('End Lng'), 'location_end_longitude'),
}

const tableSettings_MileageLogs: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'desc',
  sort_property: 'timestamp_start',
  use_live_data: true,
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
    },
  ],
}

const tableSettings_IndividualMileageLogs: TsInterface_TableSettings = {
  paginated: false,
  show_header: true,
  size: 'small',
  sortable: false,
}

const tableColumns_FuelLogs: TsInterface_TableColumns = {
  timestamp_transaction: TableCellTimestamp('timestamp_transaction', rLIB('Start Time'), 'timestamp_transaction', 'MM/DD/YY HH:mm', true),
  card_number: TableCellBasic('card_number', rLIB('Card Number'), 'card_number'),
  key: TableCellBasic('key', rLIB('Transaction ID'), 'key'),
  gallons: TableCellBasic('gallons', rLIB('Gallons'), 'gallons'),
  dollars_per_gallon: TableCellBasic('dollars_per_gallon', rLIB('Price per Gallon'), 'dollars_per_gallon'),
  total_dollars: TableCellCurrency('total_dollars', rLIB('Total Dollars'), 'total_dollars'),
  associated_product_name: TableCellBasic('associated_product_name', rLIB('Product'), 'associated_product_name'),
  associated_merchant_name: TableCellBasic('associated_merchant_name', rLIB('Merchant'), 'associated_merchant_name'),
  associated_merchant_address: TableCellBasic('associated_merchant_address', rLIB('Address'), 'associated_merchant_address'),
  associated_merchant_city: TableCellBasic('associated_merchant_city', rLIB('City'), 'associated_merchant_city'),
  associated_merchant_state: TableCellBasic('associated_merchant_state', rLIB('State'), 'associated_merchant_state'),
  associated_driver_last_name: TableCellBasic('associated_driver_last_name', rLIB('First Name'), 'associated_driver_last_name'),
  associated_driver_first_name: TableCellBasic('associated_driver_first_name', rLIB('Last Name'), 'associated_driver_first_name'),
  associated_driver_pin: TableCellBasic('associated_driver_pin', rLIB('Driver Pin'), 'associated_driver_pin'),
}

const tableSettings_FuelLogs: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'desc',
  sort_property: 'timestamp_transaction',
  use_live_data: true,
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
    },
  ],
}

const tableColumns_ActiveGarages: TsInterface_TableColumns = {
  name: TableCellBasic('name', rLIB('Name'), 'name'),
  location_address: TableCellBasic('location_address', rLIB('Address'), 'location_address'),
  location_latitude: TableCellBasic('location_latitude', rLIB('Latitude'), 'location_latitude'),
  location_longitude: TableCellBasic('location_longitude', rLIB('Longitude'), 'location_longitude'),
}

const tableSettings_ActiveGarages: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: true,
}

const formInputs_GarageName: TsInterface_FormInputs = {
  name: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'name',
    label: rLIB('Garage Name'),
    required: true,
  },
  location_address: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'location_address',
    label: rLIB('Address'),
    required: true,
  },
  location_latitude: {
    data_type: 'number',
    input_type: 'text_number',
    key: 'location_latitude',
    label: rLIB('Latitude'),
    required: true,
  },
  location_longitude: {
    data_type: 'number',
    input_type: 'text_number',
    key: 'location_longitude',
    label: rLIB('Longitude'),
    required: true,
  },
}

const rJSX_MileageAnalysisTableDayHeader = (tableAdditionalData: TsInterface_TableAdditionalData, dayString: string): JSX.Element => {
  let headerJSX = <></>
  if (tableAdditionalData != null && tableAdditionalData.weekDates != null && tableAdditionalData.weekDates[dayString] != null) {
    headerJSX = (
      <Box className="tw-text-center">
        <Box className="tw-opacity-40 tw-font-bold">{returnFormattedDate(tableAdditionalData.weekDates[dayString], 'ddd').toUpperCase()}</Box>
        {returnFormattedDate(tableAdditionalData.weekDates[dayString], 'D MMM YYYY')}
      </Box>
    )
  }
  return headerJSX
}

const rJSX_ScheduledMileageErrorIcon = (scheduledMileErrors: TsInterface_UnspecifiedObject): JSX.Element => {
  let iconJSX = <></>
  if (objectToArray(scheduledMileErrors).length > 0) {
    iconJSX = (
      <Icon
        icon="circle-exclamation"
        className="tw-ml-1"
        sx={{ color: themeVariables.error_main }}
        tooltipPlacement="bottom"
        tooltip={
          <Box>
            {objectToArray(scheduledMileErrors)
              .sort(dynamicSort('timestamp_created', null))
              .map((error, errorIndex) => (
                <Box key={errorIndex}>- {error.name}</Box>
              ))}
          </Box>
        }
      />
    )
  }
  return iconJSX
}

const rJSX_ScheduledMiles = (scheduledMiles: number): JSX.Element => {
  let milesJSX = <></>
  if (!isNaN(scheduledMiles)) {
    let fontColor = themeVariables.info_light
    if (scheduledMiles === 0) {
      fontColor = themeVariables.warning_main
    }
    let className = 'tw-inline-block'
    milesJSX = (
      <Box
        className={className}
        sx={{ color: fontColor }}
      >
        {scheduledMiles.toFixed(1)}
      </Box>
    )
  } else {
    milesJSX = (
      <Box className="tw-inline-block">
        <Box className="tw-inline-block tw-italic tw-opacity-30">?</Box>
      </Box>
    )
  }
  return milesJSX
}

const rJSX_ActualMiles = (scheduledMiles: number, actualMiles: number, additionalAllocatedMiles: number): JSX.Element => {
  let totalScheduledMiles = scheduledMiles + additionalAllocatedMiles
  let milesJSX = <></>
  if (actualMiles != null && !isNaN(actualMiles)) {
    let fontColor = themeVariables.info_light
    if (actualMiles === 0) {
      fontColor = themeVariables.warning_main
    }
    let className = 'tw-inline-block'
    if (!isNaN(totalScheduledMiles) && actualMiles > totalScheduledMiles) {
      fontColor = themeVariables.error_main
    } else {
      fontColor = themeVariables.success_main
    }
    milesJSX = (
      <Box
        className={className}
        sx={{ color: fontColor }}
      >
        {actualMiles.toFixed(1)}
      </Box>
    )
  } else {
    milesJSX = (
      <Box className="tw-inline-block">
        <Box className="tw-inline-block tw-italic tw-opacity-30">?</Box>
      </Box>
    )
  }
  return milesJSX
}

const rJSX_ActualVsScheduledNumber = (scheduledMiles: number, actualMiles: number | null, additionalAllocatedMiles: number): JSX.Element => {
  let totalScheduledMiles = scheduledMiles + additionalAllocatedMiles
  let percentJSX = <></>
  if (totalScheduledMiles !== 0 && actualMiles != null) {
    if (actualMiles > totalScheduledMiles) {
      percentJSX = (
        <Box className="tw-inline-block">
          <Box className="tw-inline-block tw-text-error_main">+{(actualMiles - totalScheduledMiles).toFixed(1)}</Box>
          <Box className="tw-inline-block tw-pl-1 tw-opacity-30">{rLIB('mi')}</Box>
        </Box>
      )
    } else {
      percentJSX = (
        <Box className="tw-inline-block">
          <Box className="tw-inline-block tw-text-success_main">{(actualMiles - totalScheduledMiles).toFixed(1)}</Box>
          <Box className="tw-inline-block tw-pl-1 tw-opacity-30">{rLIB('mi')}</Box>
        </Box>
      )
    }
  }
  return percentJSX
}

const rJSX_ActualVsScheduledPercent = (scheduledMiles: number, actualMiles: number | null, additionalAllocatedMiles: number): JSX.Element => {
  let totalScheduledMiles = scheduledMiles + additionalAllocatedMiles
  let percentJSX = <></>
  if (totalScheduledMiles !== 0 && actualMiles != null) {
    let fontColor = themeVariables.info_light
    let className = 'tw-inline-block tw-text-success_main'
    let redCutoff = 1.5
    let orangeCutoff = 1.25
    let yellowCutoff = 1
    if (!isNaN(totalScheduledMiles) && actualMiles > redCutoff * totalScheduledMiles) {
      className = 'tw-inline-block tw-rounded tw-bg-error_main tw-px-1'
      fontColor = themeVariables.white
    } else if (!isNaN(totalScheduledMiles) && actualMiles > orangeCutoff * totalScheduledMiles) {
      className = 'tw-inline-block tw-rounded tw-bg-orange_500 tw-px-1'
      fontColor = themeVariables.white
    } else if (!isNaN(totalScheduledMiles) && actualMiles > yellowCutoff * totalScheduledMiles) {
      className = 'tw-inline-block tw-rounded tw-bg-warning_main tw-px-1'
      fontColor = themeVariables.white
    } else {
      className = 'tw-inline-block'
      fontColor = themeVariables.success_main
    }
    percentJSX = (
      <Box
        className={className}
        sx={{ color: fontColor }}
      >
        {((actualMiles / totalScheduledMiles) * 100).toFixed(1)}%
      </Box>
    )
  } else if (totalScheduledMiles === 0 && actualMiles === 0) {
    // Nothing
  } else if (parseInt((totalScheduledMiles * 10).toFixed(0)) === 0 && actualMiles != null && parseInt((actualMiles * 10).toFixed(0)) !== 0) {
    percentJSX = (
      <Box
        className="tw-inline-block tw-rounded tw-px-1 tw-bg-background_json"
        sx={{ color: themeVariables.error_main, border: '1px solid ' + themeVariables.error_light }}
      >
        {rLIB('No Scheduled Miles')}
      </Box>
    )
  } else if (totalScheduledMiles > 0 && actualMiles == null) {
    percentJSX = (
      <Box
        className="tw-inline-block tw-rounded tw-bg-background_json tw-px-1"
        sx={{ color: themeVariables.warning_main, border: '1px solid ' + themeVariables.warning_main }}
      >
        {rLIB('No Logs')}
      </Box>
    )
  }
  return percentJSX
}

// Helper function to get all relevant Mondays for a selected month, including boundary weeks
export const getMondaysForMonthBoundary = (year: number, month: number) => {
  const mondays = []
  const firstOfMonth = new Date(year, month, 1)
  const lastOfMonth = new Date(year, month + 1, 0)

  // Get the Monday before the first day of the month if needed
  const firstMonday = new Date(firstOfMonth)
  firstMonday.setDate(firstMonday.getDate() - firstMonday.getDay() + 1)
  if (firstMonday < firstOfMonth) mondays.push(firstMonday.toISOString().slice(0, 10))

  // Add Mondays within the month
  let currentMonday = new Date(firstOfMonth)
  while (currentMonday.getDay() !== 1) {
    currentMonday.setDate(currentMonday.getDate() + 1)
  }

  while (currentMonday <= lastOfMonth) {
    mondays.push(new Date(currentMonday).toISOString().slice(0, 10))
    currentMonday.setDate(currentMonday.getDate() + 7)
  }

  // Add the Monday after the last day of the month if needed
  const lastMonday = new Date(lastOfMonth)
  lastMonday.setDate(lastMonday.getDate() - lastMonday.getDay() + 8)
  mondays.push(lastMonday.toISOString().slice(0, 10))

  return mondays
}

const rJSX_TableCellDialog = (
  tableHooks: TsInterface_TableHooks,
  vehicleData: TsInterface_UnspecifiedObject,
  tableAdditionalData: TsInterface_TableAdditionalData,
  dayString: string,
): JSX.Element => {
  let dateKey = returnFormattedDateKey(tableAdditionalData.weekDates[dayString])
  let dialogJSX = (
    <MileageAnalysisDayCustomDialog
      vehicleData={vehicleData}
      dateKey={dateKey}
      tableAdditionalData={tableAdditionalData}
    />
  )
  return dialogJSX
}

const rJSX_MileageAnalysisTableCellContent = (vehicleData: TsInterface_UnspecifiedObject, dateKey: string): JSX.Element => {
  let actualMilesNumber = getProp(vehicleData.actual_miles, dateKey, null)
  let scheduledMilesNumber = getProp(vehicleData.scheduled_miles, dateKey, 0)
  let scheduledMileErrors = getProp(vehicleData.scheduled_mile_errors, dateKey, {})
  let contentJSX = (
    <Box>
      <Box>
        <Box className="tw-inline-block tw-mr-1">
          <Box
            sx={{ width: '24px' }}
            className="tw-inline-block tw-text-center"
          >
            <Icon
              icon="badge-check"
              type="solid"
              tooltip={rLIB('Scheduled')}
              tooltipPlacement="left"
              className="tw-opacity-50"
            />
          </Box>
          {rJSX_ScheduledMiles(scheduledMilesNumber)}
          {rJSX_ScheduledMileageErrorIcon(scheduledMileErrors)}
          <Box className="tw-inline-block tw-ml-1 tw-opacity-30">{rLIB('mi')}</Box>
        </Box>
      </Box>
      <Box>
        <Box className="tw-inline-block tw-mr-1">
          <Box
            sx={{ width: '24px' }}
            className="tw-inline-block tw-text-center"
          >
            <Icon
              icon="road"
              type="solid"
              tooltip={rLIB('Actual')}
              tooltipPlacement="left"
              className="tw-opacity-50"
            />
          </Box>
          {rJSX_ActualMiles(scheduledMilesNumber, actualMilesNumber, 0)}
          <Box className="tw-inline-block tw-ml-1 tw-opacity-30">{rLIB('mi')}</Box>
        </Box>
      </Box>
      <Box>
        <Box className="tw-inline-block tw-mr-1">
          <Box
            sx={{ width: '24px' }}
            className="tw-inline-block tw-text-center"
          >
            <Icon
              icon="triangle"
              type="regular"
              tooltip={rLIB('Delta')}
              tooltipPlacement="left"
              className="tw-opacity-50"
            />
          </Box>
          {rJSX_ActualVsScheduledNumber(scheduledMilesNumber, actualMilesNumber, 0)} {rJSX_ActualVsScheduledPercent(scheduledMilesNumber, actualMilesNumber, 0)}
        </Box>
      </Box>
    </Box>
  )
  return contentJSX
}

const rJSX_AdditionalMiles = (additionalMiles: number): JSX.Element => {
  let contentJSX = <></>

  if (!isNaN(additionalMiles)) {
    let fontColor = themeVariables.info_light
    if (additionalMiles === 0) {
      fontColor = themeVariables.gray_700
    }
    let className = 'tw-inline-block'
    contentJSX = (
      <Box
        className={className}
        sx={{ color: fontColor }}
      >
        {additionalMiles.toFixed(1)}
      </Box>
    )
  } else {
    contentJSX = (
      <Box className="tw-inline-block">
        <Box className="tw-inline-block tw-italic tw-opacity-30">?</Box>
      </Box>
    )
  }
  return contentJSX
}

const rJSX_MileageAnalysisTotals = (rowData: TsInterface_UnspecifiedObject): JSX.Element => {
  let contentJSX = <></>
  // Additional Miles
  let additionalAllocatedMiles = 0
  if (rowData != null && rowData.override_additional_miles != null) {
    additionalAllocatedMiles = rowData.override_additional_miles
  } else if (rowData != null && rowData.default_additional_miles != null) {
    additionalAllocatedMiles = rowData.default_additional_miles
  }
  // JSX
  if (rowData.total_scheduled_miles != null && rowData.total_actual_miles != null) {
    contentJSX = (
      <Box>
        <Box>
          <Box className="tw-inline-block tw-mr-1">
            <Box
              sx={{ width: '24px' }}
              className="tw-inline-block tw-text-center"
            >
              <Icon
                icon="badge-check"
                type="solid"
                tooltip={rLIB('Scheduled')}
                tooltipPlacement="left"
                className="tw-opacity-50"
              />
            </Box>
            {rJSX_ScheduledMiles(rowData.total_scheduled_miles as number)} + {rJSX_AdditionalMiles(additionalAllocatedMiles)}
            <Box className="tw-inline-block tw-ml-1 tw-opacity-30">{rLIB('mi')}</Box>
          </Box>
        </Box>
        <Box>
          <Box className="tw-inline-block tw-mr-1">
            <Box
              sx={{ width: '24px' }}
              className="tw-inline-block tw-text-center"
            >
              <Icon
                icon="sigma"
                type="solid"
                tooltip={rLIB('Additional Miles')}
                tooltipPlacement="left"
                className="tw-opacity-50"
              />
            </Box>
            {rJSX_ScheduledMiles((rowData.total_scheduled_miles as number) + additionalAllocatedMiles)}
            <Box className="tw-inline-block tw-ml-1 tw-opacity-30">{rLIB('mi')}</Box>
          </Box>
        </Box>
        <Box>
          <Box className="tw-inline-block tw-mr-1">
            <Box
              sx={{ width: '24px' }}
              className="tw-inline-block tw-text-center"
            >
              <Icon
                icon="road"
                type="solid"
                tooltip={rLIB('Actual')}
                tooltipPlacement="left"
                className="tw-opacity-50"
              />
            </Box>
            {rJSX_ActualMiles(rowData.total_scheduled_miles as number, rowData.total_actual_miles as number, additionalAllocatedMiles)}
            <Box className="tw-inline-block tw-ml-1 tw-opacity-30">{rLIB('mi')}</Box>
          </Box>
        </Box>
        <Box>
          <Box className="tw-inline-block tw-mr-1">
            <Box
              sx={{ width: '24px' }}
              className="tw-inline-block tw-text-center"
            >
              <Icon
                icon="triangle"
                type="regular"
                tooltip={rLIB('Delta')}
                tooltipPlacement="left"
                className="tw-opacity-50"
              />
            </Box>
            {rJSX_ActualVsScheduledNumber(rowData.total_scheduled_miles as number, rowData.total_actual_miles as number, additionalAllocatedMiles)}{' '}
            {rJSX_ActualVsScheduledPercent(rowData.total_scheduled_miles as number, rowData.total_actual_miles as number, additionalAllocatedMiles)}
          </Box>
        </Box>
      </Box>
    )
  } else {
    contentJSX = (
      <Box>
        <Box className="tw-inline-block tw-italic tw-opacity-30">{rLIB('Missing')}</Box>
      </Box>
    )
  }
  return contentJSX
}

const rJSX_MileageAnalysisTableCell = (
  tableHooks: TsInterface_TableHooks,
  vehicleData: TsInterface_UnspecifiedObject,
  tableAdditionalData: TsInterface_TableAdditionalData,
  dayString: string,
): JSX.Element => {
  let cellJSX = <></>
  let dateKey = returnFormattedDateKey(tableAdditionalData.weekDates[dayString])
  if (vehicleData != null && vehicleData.actual_miles != null && vehicleData.scheduled_miles != null) {
    cellJSX = (
      <Box
        onDoubleClick={() => {
          tableHooks.uc_setUserInterface_CustomDialogDisplay({
            display: true,
            dialog: {
              dialog_jsx: rJSX_TableCellDialog(tableHooks, vehicleData, tableAdditionalData, dayString),
              settings: {
                max_width: 'lg',
              },
            },
          })
        }}
      >
        {rJSX_MileageAnalysisTableCellContent(vehicleData, dateKey)}
      </Box>
    )
  } else {
    cellJSX = (
      <Box>
        <Box className="tw-inline-block tw-italic tw-opacity-30">{rLIB('Missing')}</Box>
      </Box>
    )
  }
  return cellJSX
}

const returnCSS_MileageAnalysisTableCell = (
  vehicleData: TsInterface_UnspecifiedObject,
  tableAdditionalData: TsInterface_TableAdditionalData,
  dayString: string,
): string => {
  let cellCSS = ''
  return cellCSS
}

const tableColumns_MileageAnalysis: TsInterface_TableColumns = {
  associated_user_name: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Employee')
      },
      header_sort_by: 'name',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let formOptions: TsInterface_UnspecifiedObject[] = []
        if (tableAdditionalData != null && tableAdditionalData.us_activeGaragesList != null) {
          for (let loopGarageKey in tableAdditionalData.us_activeGaragesList) {
            let loopGarage = tableAdditionalData.us_activeGaragesList[loopGarageKey]
            formOptions.push({
              value: loopGarage.name,
              key: loopGarage.key,
            })
          }
        }
        let editGarageIcon = (
          <Icon
            icon="pen-to-square"
            className="tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer"
            onClick={() => {
              tableHooks.uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {
                      taskKey: rowData.key,
                    },
                    formData: rowData,
                    formInputs: {
                      associated_garage_key: {
                        data_type: 'string',
                        input_type: 'multiple_choice_radio',
                        key: 'associated_garage_key',
                        label: rLIB('Garage'),
                        required: true,
                        options: formOptions,
                        submit_on_change: true,
                      },
                    },
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {
                      submit_button_hide: true,
                    },
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            let updateObject = {
                              associated_garage_name: tableAdditionalData.us_activeGaragesList[formSubmittedData.associated_garage_key].name,
                              associated_garage_key: formSubmittedData.associated_garage_key,
                            }
                            DatabaseSetMergeDocument(DatabaseRef_ClientUser_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                              .then((res_DSMD) => {
                                resolve(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                reject(rej_DSMD)
                              })
                          })
                          .catch((rej_GCK) => {
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                          })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: (
                      <>
                        {rLIB('Select Garage for')} {rowData.name}
                      </>
                    ),
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="pen-to-square"
                      />
                    ),
                  },
                },
              })
            }}
          />
        )
        let garageNameJSX = <Box className="tw-inline-block tw-opacity-30 tw-italic tw-mr-2">{rLIB('Missing')}</Box>
        if (rowData != null && rowData.associated_garage_key != null && rowData.associated_garage_name != null) {
          garageNameJSX = <Box className="tw-mr-2 tw-inline-block">{rowData.associated_garage_name}</Box>
        }
        const rJSX_VinList = () => {
          let listJSX = <></>
          listJSX = (
            <Box className="tw-italic tw-opacity-30">
              {objectToArray(getProp(rowData, 'vins', {}))
                .sort()
                .map((vin, vinIndex) => (
                  <Box key={vinIndex}>{vin}</Box>
                ))}
            </Box>
          )
          return listJSX
        }
        let cellJSX = (
          <Box>
            <Box>{getProp(rowData, 'name', '')}</Box>
            {rJSX_VinList()}
            <Box>
              {garageNameJSX}
              {editGarageIcon}
            </Box>
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
  },
  total_actual_miles: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let headerJSX = <></>
        // Additional Miles
        let additionalAllocatedMiles = 0
        for (let loopDataIndex in getProp(tableAdditionalData, 'us_analysisTableData', [])) {
          let loopData = tableAdditionalData.us_analysisTableData[loopDataIndex]
          if (loopData != null && loopData.override_additional_miles != null) {
            additionalAllocatedMiles += loopData.override_additional_miles
          } else if (loopData != null && loopData.default_additional_miles != null) {
            additionalAllocatedMiles += loopData.default_additional_miles
          }
        }
        headerJSX = (
          <Box>
            <Box>{rLIB('Totals')}</Box>
            <Box>
              <Box className="tw-inline-block tw-mr-1">
                <Box
                  sx={{ width: '24px' }}
                  className="tw-inline-block tw-text-center"
                >
                  <Icon
                    icon="badge-check"
                    type="solid"
                    tooltip={rLIB('Scheduled')}
                    tooltipPlacement="left"
                    className="tw-opacity-50"
                  />
                </Box>
                {rJSX_ScheduledMiles(tableAdditionalData.us_analysisTableTotals.total_scheduled_miles as number)} +{' '}
                {rJSX_AdditionalMiles(additionalAllocatedMiles)}
                <Box className="tw-inline-block tw-ml-1 tw-opacity-30">{rLIB('mi')}</Box>
              </Box>
            </Box>
            <Box>
              <Box className="tw-inline-block tw-mr-1">
                <Box
                  sx={{ width: '24px' }}
                  className="tw-inline-block tw-text-center"
                >
                  <Icon
                    icon="sigma"
                    type="solid"
                    tooltip={rLIB('Additional Miles')}
                    tooltipPlacement="left"
                    className="tw-opacity-50"
                  />
                </Box>
                {rJSX_ScheduledMiles((tableAdditionalData.us_analysisTableTotals.total_scheduled_miles as number) + additionalAllocatedMiles)}
                <Box className="tw-inline-block tw-ml-1 tw-opacity-30">{rLIB('mi')}</Box>
              </Box>
            </Box>
            <Box>
              <Box className="tw-inline-block tw-mr-1">
                <Box
                  sx={{ width: '24px' }}
                  className="tw-inline-block tw-text-center"
                >
                  <Icon
                    icon="road"
                    type="solid"
                    tooltip={rLIB('Actual')}
                    tooltipPlacement="left"
                    className="tw-opacity-50"
                  />
                </Box>
                {rJSX_ActualMiles(
                  tableAdditionalData.us_analysisTableTotals.total_scheduled_miles as number,
                  tableAdditionalData.us_analysisTableTotals.total_actual_miles as number,
                  additionalAllocatedMiles,
                )}
                <Box className="tw-inline-block tw-ml-1 tw-opacity-30">{rLIB('mi')}</Box>
              </Box>
            </Box>
            <Box>
              <Box className="tw-inline-block tw-mr-1">
                <Box
                  sx={{ width: '24px' }}
                  className="tw-inline-block tw-text-center"
                >
                  <Icon
                    icon="triangle"
                    type="regular"
                    tooltip={rLIB('Delta')}
                    tooltipPlacement="left"
                    className="tw-opacity-50"
                  />
                </Box>
                {rJSX_ActualVsScheduledNumber(
                  tableAdditionalData.us_analysisTableTotals.total_scheduled_miles as number,
                  tableAdditionalData.us_analysisTableTotals.total_actual_miles as number,
                  additionalAllocatedMiles,
                )}{' '}
                {rJSX_ActualVsScheduledPercent(
                  tableAdditionalData.us_analysisTableTotals.total_scheduled_miles as number,
                  tableAdditionalData.us_analysisTableTotals.total_actual_miles as number,
                  additionalAllocatedMiles,
                )}
              </Box>
            </Box>
          </Box>
        )
        return headerJSX
      },
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-bg-black tw-border-solid tw-border-2 tw-border-warning_main'
      },
      header_sort_by: 'TEMP_total_actual_minus_scheduled_miles_adjusted',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        cellJSX = rJSX_MileageAnalysisTotals(rowData)
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-bg-background_json'
      },
    },
  },
  mon: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rJSX_MileageAnalysisTableDayHeader(tableAdditionalData, 'mon')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        return rJSX_MileageAnalysisTableCell(tableHooks, rowData, tableAdditionalData, 'mon')
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return returnCSS_MileageAnalysisTableCell(rowData, tableAdditionalData, 'mon')
      },
    },
  },
  tue: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rJSX_MileageAnalysisTableDayHeader(tableAdditionalData, 'tue')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        return rJSX_MileageAnalysisTableCell(tableHooks, rowData, tableAdditionalData, 'tue')
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return returnCSS_MileageAnalysisTableCell(rowData, tableAdditionalData, 'tue')
      },
    },
  },
  wed: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rJSX_MileageAnalysisTableDayHeader(tableAdditionalData, 'wed')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        return rJSX_MileageAnalysisTableCell(tableHooks, rowData, tableAdditionalData, 'wed')
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return returnCSS_MileageAnalysisTableCell(rowData, tableAdditionalData, 'wed')
      },
    },
  },
  thu: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rJSX_MileageAnalysisTableDayHeader(tableAdditionalData, 'thu')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        return rJSX_MileageAnalysisTableCell(tableHooks, rowData, tableAdditionalData, 'thu')
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return returnCSS_MileageAnalysisTableCell(rowData, tableAdditionalData, 'thu')
      },
    },
  },
  fri: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rJSX_MileageAnalysisTableDayHeader(tableAdditionalData, 'fri')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        return rJSX_MileageAnalysisTableCell(tableHooks, rowData, tableAdditionalData, 'fri')
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return returnCSS_MileageAnalysisTableCell(rowData, tableAdditionalData, 'fri')
      },
    },
  },
  sat: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rJSX_MileageAnalysisTableDayHeader(tableAdditionalData, 'sat')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        return rJSX_MileageAnalysisTableCell(tableHooks, rowData, tableAdditionalData, 'sat')
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return returnCSS_MileageAnalysisTableCell(rowData, tableAdditionalData, 'sat')
      },
    },
  },
  sun: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rJSX_MileageAnalysisTableDayHeader(tableAdditionalData, 'sun')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        return rJSX_MileageAnalysisTableCell(tableHooks, rowData, tableAdditionalData, 'sun')
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return returnCSS_MileageAnalysisTableCell(rowData, tableAdditionalData, 'sun')
      },
    },
  },
}

const tableColumns_MileageAnalysisSummary: TsInterface_TableColumns = {
  name: TableCellBasic('name', rLIB('Employee'), 'name'),
  TEMP_total_scheduled_miles_adjusted: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Scheduled Miles')
      },
      header_sort_by: 'TEMP_total_scheduled_miles_adjusted',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData != null && rowData['TEMP_total_scheduled_miles_adjusted'] != null) {
          if ((rowData['TEMP_total_scheduled_miles_adjusted'] as number) > 0) {
            cellJSX = (
              <Box>
                <Box
                  className="tw-font-bold tw-inline-block"
                  sx={{ color: themeVariables.info_main }}
                >
                  {parseFloat(rowData['TEMP_total_scheduled_miles_adjusted'] as string).toFixed(1)}
                </Box>
                <Box className="tw-inline-block tw-ml-1 tw-opacity-30">{rLIB('mi')}</Box>
              </Box>
            )
          } else {
            cellJSX = (
              <Box
                className="tw-font-bold tw-inline-block"
                sx={{ color: themeVariables.warning_main }}
              >
                {parseFloat(rowData['TEMP_total_scheduled_miles_adjusted'] as string).toFixed(1)}
              </Box>
            )
          }
        } else {
          cellJSX = <Box className="tw-opacity-30 tw-italic">{rLIB('Missing')}</Box>
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
  },
  total_actual_miles: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Actual Miles')
      },
      header_sort_by: 'total_actual_miles',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData != null && rowData['total_actual_miles'] != null && rowData['TEMP_total_scheduled_miles_adjusted'] != null) {
          let actualMiles = rowData['total_actual_miles'] as number
          let scheduledMiles = rowData['TEMP_total_scheduled_miles_adjusted'] as number
          if (scheduledMiles > actualMiles) {
            cellJSX = (
              <Box>
                <Box
                  className="tw-font-bold tw-inline-block"
                  sx={{ color: themeVariables.success_main }}
                >
                  {parseFloat(rowData['total_actual_miles'] as string).toFixed(1)}
                </Box>
                <Box className="tw-inline-block tw-ml-1 tw-opacity-30">{rLIB('mi')}</Box>
              </Box>
            )
          } else {
            cellJSX = (
              <Box>
                <Box
                  className="tw-font-bold tw-inline-block"
                  sx={{ color: themeVariables.error_main }}
                >
                  {parseFloat(rowData['total_actual_miles'] as string).toFixed(1)}
                </Box>
                <Box className="tw-inline-block tw-ml-1 tw-opacity-30">{rLIB('mi')}</Box>
              </Box>
            )
          }
        } else if (rowData['total_actual_miles'] != null) {
          cellJSX = (
            <Box>
              <Box
                className="tw-font-bold tw-inline-block"
                sx={{ color: themeVariables.gray_600 }}
              >
                {parseFloat(rowData['total_actual_miles'] as string).toFixed(1)}
              </Box>
              <Box className="tw-inline-block tw-ml-1 tw-opacity-30">{rLIB('mi')}</Box>
            </Box>
          )
        } else {
          cellJSX = <Box className="tw-opacity-30 tw-italic">{rLIB('Missing')}</Box>
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
  },
  TEMP_total_actual_minus_scheduled_miles_adjusted: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Scheduled minus Actual')
      },
      header_sort_by: 'TEMP_total_actual_minus_scheduled_miles_adjusted',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        // let cellJSX = <></>
        // if(
        // 	rowData != null &&
        // 	rowData["TEMP_total_actual_minus_scheduled_miles_adjusted"] != null
        // ){

        // } else {
        // 	cellJSX =
        // 	<Box className="tw-opacity-30 tw-italic" >{ rLIB('Missing') }</Box>
        // }
        // return cellJSX

        let cellJSX = <></>
        if (rowData != null && rowData['total_actual_miles'] != null && rowData['TEMP_total_scheduled_miles_adjusted'] != null) {
          let actualMiles = rowData['total_actual_miles'] as number
          let scheduledMiles = rowData['TEMP_total_scheduled_miles_adjusted'] as number
          if (scheduledMiles > actualMiles) {
            cellJSX = (
              <Box>
                <Box
                  className="tw-font-bold tw-inline-block"
                  sx={{ color: themeVariables.success_main }}
                >
                  {parseFloat(rowData['TEMP_total_actual_minus_scheduled_miles_adjusted'] as string).toFixed(1)}
                </Box>
                <Box className="tw-inline-block tw-ml-1 tw-opacity-30">{rLIB('mi')}</Box>
              </Box>
            )
          } else {
            cellJSX = (
              <Box>
                <Box
                  className="tw-font-bold tw-inline-block"
                  sx={{ color: themeVariables.error_main }}
                >
                  {parseFloat(rowData['TEMP_total_actual_minus_scheduled_miles_adjusted'] as string).toFixed(1)}
                </Box>
                <Box className="tw-inline-block tw-ml-1 tw-opacity-30">{rLIB('mi')}</Box>
              </Box>
            )
          }
        } else if (rowData['total_actual_miles'] != null) {
          cellJSX = (
            <Box
              className="tw-font-bold tw-inline-block"
              sx={{ color: themeVariables.gray_600 }}
            >
              {parseFloat(rowData['TEMP_total_actual_minus_scheduled_miles_adjusted'] as string).toFixed(1)}
            </Box>
          )
        } else {
          cellJSX = <Box className="tw-opacity-30 tw-italic">{rLIB('Missing')}</Box>
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
  },
}

const tableSettings_MileageAnalysis: TsInterface_TableSettings = {
  paginated: false,
  show_header: true,
  size: 'small',
  sortable: true,
  sort_direction: 'desc',
  sort_property_default: 'TEMP_total_actual_minus_scheduled_miles_adjusted',
}

interface MileageRequestFormProps {
  userKey: string
  onClose: () => void
  onSave: (data: { additional_miles: number; notes: string; associated_date_key: string }) => Promise<{ success: boolean }>
}

const MileageRequestForm: React.FC<MileageRequestFormProps> = ({ userKey, onClose, onSave }) => {
  const [miles, setMiles] = useState<string>('')
  const [notes, setNotes] = useState<string>('')
  const [requestDate, setRequestDate] = useState<Date>(new Date())
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [error, setError] = useState<React.ReactNode>(null)

  const handleDateChange = async (date: Date): Promise<unknown> => {
    setRequestDate(new Date(date))
    return Promise.resolve()
  }

  const handleSubmit = async (): Promise<void> => {
    // Validate
    if (!miles || isNaN(parseFloat(miles)) || parseFloat(miles) <= 0) {
      setError(rLIB('Please enter a valid number of miles'))
      return
    }

    setIsSubmitting(true)
    setError(null)

    try {
      await onSave({
        additional_miles: parseFloat(miles),
        notes: notes.trim(),
        associated_date_key: returnFormattedDateKey(requestDate),
      })
      onClose()
    } catch (err) {
      console.error(err)
      setError(rLIB('Failed to submit request'))
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Box className="tw-p-6">
      <Box className="tw-mb-6">
        <DatePicker
          key="associated_date_key"
          datePickerText={rLIB('Request Date')}
          datePickerDate={requestDate}
          datePickerDisabled={isSubmitting}
          datePickerDateOnChange={handleDateChange}
          datePickerSettings={{ thin_input: true }}
        />
      </Box>

      <Box className="tw-mb-6">
        <TextField
          fullWidth
          required
          label={rLIB('Additional Miles')}
          type="number"
          value={miles}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMiles(e.target.value)}
          disabled={isSubmitting}
          error={!!error && !miles}
          helperText={error}
        />
      </Box>

      <Box className="tw-mb-6">
        <TextField
          fullWidth
          multiline
          rows={4}
          label={rLIB('Notes')}
          value={notes}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNotes(e.target.value)}
          disabled={isSubmitting}
        />
      </Box>

      <Box className="tw-flex tw-justify-end tw-gap-3">
        <Button
          onClick={onClose}
          disabled={isSubmitting}
        >
          {rLIB('Cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting ? rLIB('Submitting...') : rLIB('Submit Request')}
        </Button>
      </Box>
    </Box>
  )
}

const tableColumns_MileageAllocation: TsInterface_TableColumns = {
  name: TableCellBasic('name', rLIB('Name'), 'name'),

  scheduled_work_miles: {
    header: {
      header_jsx: () => rLIB('Scheduled Work Miles'),
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const iconJSX = (
          <Icon
            icon="pen-to-square"
            sx={{
              '&:hover': {
                color: themeVariables.success_main,
              },
            }}
            className="tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2"
            tooltip={rLIB('Edit')}
            tooltipPlacement="right"
            onClick={() => {
              // Get the key and ensure it's a string
              const userKey = rowData?.key as string
              if (!userKey) return

              let defaultValue = null
              // Safely access nested properties
              const milesData = tableAdditionalData?.us_scheduledWorkMiles?.[userKey]
              if (milesData?.scheduled_work_miles != null) {
                defaultValue = milesData.scheduled_work_miles
              }

              tableHooks.uc_setUserInterface_PromptDialogDisplay({
                display: true,
                prompt: {
                  color: 'info',
                  confirm_text: rLIB('Save'),
                  default_value: defaultValue,
                  header: (
                    <>
                      {rLIB('Update Scheduled Work Miles')} - {getProp(rowData, 'name', '')}
                    </>
                  ),
                  icon: (
                    <Icon
                      icon="pen-to-square"
                      type="solid"
                    />
                  ),
                  input_label: rLIB('Miles'),
                  input_type: 'number',
                  text: rLIB('Scheduled Work Miles'),
                  submit_callback: (promptValue: string) => {
                    return new Promise((resolve, reject) => {
                      const updateObject = {
                        scheduled_work_miles: parseFloat(promptValue),
                        associated_user_key: userKey,
                        name: rowData.name,
                      }
                      getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          DatabaseSetMergeDocument(DatabaseRef_ScheduledWorkMiles_Document(res_GCK.clientKey, userKey), updateObject)
                            .then(() => resolve({ success: true }))
                            .catch((rej_DSMD) => {
                              reject({ success: false })
                              tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            })
                        })
                        .catch((rej_GCK) => {
                          reject({ success: false })
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        })
                    })
                  },
                },
              })
            }}
          />
        )

        // Get the key and ensure it's a string
        const userKey = rowData?.key as string
        if (!userKey) {
          return (
            <Box>
              <Box className="tw-inline-block tw-italic tw-opacity-30">{rLIB('Not Set')}</Box>
              {iconJSX}
            </Box>
          )
        }

        // Safely access miles data
        const milesData = tableAdditionalData?.us_scheduledWorkMiles?.[userKey]
        if (milesData?.scheduled_work_miles != null) {
          return (
            <Box>
              {milesData.scheduled_work_miles} {rLIB('mi')}
              {iconJSX}
            </Box>
          )
        }

        return (
          <Box>
            <Box className="tw-inline-block tw-italic tw-opacity-30">{rLIB('Not Set')}</Box>
            {iconJSX}
          </Box>
        )
      },
      cell_css: () => '',
    },
  },

  personal_commute_miles: {
    header: {
      header_jsx: () => rLIB('Personal Commute Miles'),
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const iconJSX = (
          <Icon
            icon="pen-to-square"
            sx={{
              '&:hover': {
                color: themeVariables.success_main,
              },
            }}
            className="tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2"
            tooltip={rLIB('Edit')}
            tooltipPlacement="right"
            onClick={() => {
              // Get the key and ensure it's a string
              const userKey = rowData?.key as string
              if (!userKey) return

              let defaultValue = null
              // Safely access nested properties
              const milesData = tableAdditionalData?.us_personalCommuteMiles?.[userKey]
              if (milesData?.personal_commute_miles != null) {
                defaultValue = milesData.personal_commute_miles
              }

              tableHooks.uc_setUserInterface_PromptDialogDisplay({
                display: true,
                prompt: {
                  color: 'info',
                  confirm_text: rLIB('Save'),
                  default_value: defaultValue,
                  header: (
                    <>
                      {rLIB('Update Personal Commute Miles')} - {getProp(rowData, 'name', '')}
                    </>
                  ),
                  icon: (
                    <Icon
                      icon="pen-to-square"
                      type="solid"
                    />
                  ),
                  input_label: rLIB('Miles'),
                  input_type: 'number',
                  text: rLIB('Personal Commute Miles'),
                  submit_callback: (promptValue: string) => {
                    return new Promise((resolve, reject) => {
                      const updateObject = {
                        personal_commute_miles: parseFloat(promptValue),
                        associated_user_key: userKey,
                        name: rowData.name,
                      }
                      getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          DatabaseSetMergeDocument(DatabaseRef_PersonalCommuteMiles_Document(res_GCK.clientKey, userKey), updateObject)
                            .then(() => resolve({ success: true }))
                            .catch((rej_DSMD) => {
                              reject({ success: false })
                              tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            })
                        })
                        .catch((rej_GCK) => {
                          reject({ success: false })
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        })
                    })
                  },
                },
              })
            }}
          />
        )

        // Get the key and ensure it's a string
        const userKey = rowData?.key as string
        if (!userKey) {
          return (
            <Box>
              <Box className="tw-inline-block tw-italic tw-opacity-30">{rLIB('Not Set')}</Box>
              {iconJSX}
            </Box>
          )
        }

        // Safely access miles data
        const milesData = tableAdditionalData?.us_personalCommuteMiles?.[userKey]
        if (milesData?.personal_commute_miles != null) {
          return (
            <Box>
              {milesData.personal_commute_miles} {rLIB('mi')}
              {iconJSX}
            </Box>
          )
        }

        return (
          <Box>
            <Box className="tw-inline-block tw-italic tw-opacity-30">{rLIB('Not Set')}</Box>
            {iconJSX}
          </Box>
        )
      },
      cell_css: () => '',
    },
  },

  additional_requested_miles: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let dateRangeString = ''
        if (tableAdditionalData?.us_startDownloadDate != null && tableAdditionalData?.us_endDownloadDate != null) {
          let endDate = new Date(tableAdditionalData.us_endDownloadDate - 86400000)
          dateRangeString = returnFormattedDate(tableAdditionalData.us_startDownloadDate, 'D MMM YY') + ' - ' + returnFormattedDate(endDate, 'D MMM YY')
        }
        return (
          <Box>
            <Box>{rLIB('Additional Requested Miles')}</Box>
            <Box className="tw-opacity-50">{dateRangeString}</Box>
          </Box>
        )
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const handleApprove = async (request: any) => {
          const userKey = rowData?.key as string
          if (!userKey || !tableAdditionalData?.selectedDateKey) return

          try {
            await getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey).then((res_GCK) => {
              const updateObject = {
                ...request,
                status: 'approved',
                timestamp_approved: new Date(),
              }
              return DatabaseSetMergeDocument(DatabaseRef_AdditionalMileRequests_Document(res_GCK.clientKey, request.key), updateObject)
            })
          } catch (error) {
            tableHooks.uc_setUserInterface_ErrorDialogDisplay({
              display: true,
              error: error as Error,
            })
          }
        }

        const handleReject = async (request: any) => {
          const userKey = rowData?.key as string
          if (!userKey || !tableAdditionalData?.selectedDateKey) return

          try {
            await getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey).then((res_GCK) => {
              const updateObject = {
                ...request,
                status: 'rejected',
                timestamp_rejected: new Date(),
              }
              return DatabaseSetMergeDocument(DatabaseRef_AdditionalMileRequests_Document(res_GCK.clientKey, request.key), updateObject)
            })
          } catch (error) {
            tableHooks.uc_setUserInterface_ErrorDialogDisplay({
              display: true,
              error: error as Error,
            })
          }
        }

        const handleDelete = async (request: any) => {
          const userKey = rowData?.key as string
          if (!userKey || !tableAdditionalData?.selectedDateKey) return

          try {
            await getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey).then((res_GCK) => {
              return DatabaseSetMergeDocument(DatabaseRef_AdditionalMileRequests_Document(res_GCK.clientKey, request.key), {
                status: 'deleted',
                timestamp_deleted: new Date(),
              })
            })
          } catch (error) {
            tableHooks.uc_setUserInterface_ErrorDialogDisplay({
              display: true,
              error: error as Error,
            })
          }
        }

        const getStatusDisplay = (status: string) => {
          if (!status) return null

          const statusConfig = {
            approved: {
              color: 'success',
              label: rLIB('Approved'),
              size: 'small',
            },
            rejected: {
              color: 'error',
              label: rLIB('Rejected'),
              size: 'small',
            },
          } as const

          const config = statusConfig[status as keyof typeof statusConfig]
          if (!config) return null

          return (
            <Chip
              label={config.label}
              color={config.color}
              size={config.size}
              sx={{ height: '24px' }}
            />
          )
        }

        const addRequestIconJSX = (
          <Icon
            icon="plus-circle"
            className="tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer tw-mr-2" // Changed from tw-ml-2 to tw-mr-2
            sx={{
              '&:hover': {
                color: themeVariables.success_main,
              },
            }}
            tooltip={rLIB('Add Request')}
            tooltipPlacement="right"
            onClick={() => {
              tableHooks.uc_setUserInterface_CustomDialogDisplay({
                display: true,
                dialog: {
                  dialog_jsx: (
                    <Dialog
                      className="bp_dialog_xl_width"
                      keepMounted
                      onClose={() => {
                        tableHooks.uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                      }}
                      open={true}
                    >
                      <AppBar
                        position="static"
                        color="inherit"
                      >
                        <Toolbar>
                          <Typography
                            component={'span'}
                            variant={'h6'}
                            sx={{ flexGrow: 1 }}
                          >
                            <Box className="tw-inline-block">{`Create Mileage Request for ${rowData.name}`}</Box>
                          </Typography>
                        </Toolbar>
                      </AppBar>
                      <DialogContent sx={{ padding: '0px' }}>
                        <MileageRequestForm
                          userKey={rowData.key as string}
                          onClose={() => {
                            tableHooks.uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                          }}
                          onSave={(formSubmittedData: any) => {
                            return new Promise((resolve, reject) => {
                              getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                                .then((res_GCK) => {
                                  const dateString = new Date().valueOf().toString()
                                  const updateObject = {
                                    additional_miles: formSubmittedData.additional_miles,
                                    notes: formSubmittedData.notes,
                                    timestamp_requested: new Date(),
                                    status: 'pending',
                                    associated_date_key: formSubmittedData.associated_date_key,
                                    associated_user_key: rowData.key,
                                    associated_user_name: rowData.name,
                                    key: `${dateString}_${rowData.key}`,
                                  }
                                  DatabaseSetMergeDocument(
                                    DatabaseRef_AdditionalMileRequests_Document(res_GCK.clientKey, `${dateString}_${rowData.key}`),
                                    updateObject,
                                  )
                                    .then(() => resolve({ success: true }))
                                    .catch((rej_DDD) => {
                                      reject({ success: false })
                                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DDD.error })
                                    })
                                })
                                .catch((rej_GCK) => {
                                  reject({ success: false })
                                  tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                                })
                            })
                          }}
                        />
                      </DialogContent>
                    </Dialog>
                  ),
                  settings: {
                    max_width: 'lg',
                  },
                },
              })
            }}
          />
        )

        const userKey = rowData?.key as string
        if (!userKey || !tableAdditionalData?.selectedDateKey) {
          return (
            <Box className="tw-flex tw-items-center">
              {addRequestIconJSX} {/* Moved to the left */}
              <Box
                component="span"
                className="tw-inline-block tw-italic tw-opacity-30"
              >
                {rLIB('No Requests')}
              </Box>
            </Box>
          )
        }

        const allRequests = tableAdditionalData?.us_additionalMileRequests || {}
        const userRequests = Object.values(allRequests)
          .flat()
          .reduce((acc: any[], curr) => {
            if (Array.isArray(curr)) {
              return [...acc, ...curr]
            }
            return [...acc, curr]
          }, [])
          // Filter for this user's requests
          .filter((request: any) => request?.associated_user_key === userKey)
          .filter((request: any) => request?.status !== 'deleted')
          .sort((a: any, b: any) => b.associated_date_key - a.associated_date_key)

        const sortedRequests = userRequests

        if (sortedRequests.length > 0) {
          return (
            <Box>
              {sortedRequests.map((request, index) => (
                <Box
                  key={index}
                  className="tw-flex tw-items-center tw-gap-2 tw-py-1"
                >
                  {addRequestIconJSX}
                  <Tooltip
                    title={request.notes || rLIB('No notes provided')}
                    placement="top"
                    arrow
                  >
                    <Box
                      component="span"
                      className="tw-flex tw-items-center tw-gap-2"
                    >
                      <Box
                        component="span"
                        className="tw-font-medium"
                      >
                        {request.additional_miles} {rLIB('mi')}
                      </Box>
                      <Box
                        component="span"
                        className="tw-opacity-60"
                      >
                        (
                        {returnFormattedDate(
                          new Date(
                            parseInt(request.associated_date_key.split('-')[0]),
                            parseInt(request.associated_date_key.split('-')[1]) - 1,
                            parseInt(request.associated_date_key.split('-')[2]),
                            0,
                            0,
                          ),
                          'D MMM',
                        )}
                        )
                      </Box>
                    </Box>
                  </Tooltip>

                  {!request.status || request.status === 'pending' ? (
                    <>
                      <Icon
                        icon="check-circle"
                        className="tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer"
                        sx={{
                          '&:hover': {
                            color: themeVariables.success_main,
                          },
                        }}
                        onClick={() => handleApprove(request)}
                      />
                      <Icon
                        icon="xmark-circle"
                        className="tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer"
                        sx={{
                          '&:hover': {
                            color: themeVariables.error_main,
                          },
                        }}
                        onClick={() => handleReject(request)}
                      />
                    </>
                  ) : (
                    <Box>{getStatusDisplay(request.status)}</Box>
                  )}
                  <Icon
                    icon="trash"
                    className="tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer"
                    sx={{
                      '&:hover': {
                        color: themeVariables.error_main,
                      },
                    }}
                    tooltip={rLIB('Delete')}
                    tooltipPlacement="right"
                    onClick={() => handleDelete(request)}
                  />
                </Box>
              ))}
            </Box>
          )
        }

        return (
          <Box className="tw-flex tw-items-center">
            {addRequestIconJSX}
            <Box
              component="span"
              className="tw-inline-block tw-italic tw-opacity-30"
            >
              {rLIB('No Requests')}
            </Box>
          </Box>
        )
      },
      cell_css: () => '',
    },
  },
}

const tableSettings_MileageAllocation: TsInterface_TableSettings = {
  paginated: false,
  show_header: true,
  size: 'small',
  sortable: false,
}

const tableColumns_OdometerAnalysis: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{rLIB('View Logs')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        tableHooks.uc_setUserInterface_CustomDialogDisplay({
          display: true,
          dialog: {
            dialog_jsx: (
              <Dialog
                className="bp_dialog_xl_width"
                keepMounted
                onClose={() => {
                  tableHooks.uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                }}
                open={true}
              >
                <AppBar
                  position="static"
                  color="inherit"
                >
                  <Toolbar>
                    <IconButton
                      aria-label="menu"
                      color="inherit"
                      disabled
                      edge="start"
                      size="large"
                      sx={{ mr: 2, color: '#fff !important' }}
                    >
                      <Icon icon="gauge" />
                    </IconButton>
                    <Typography
                      component={'span'}
                      variant={'h6'}
                      sx={{ flexGrow: 1 }}
                    >
                      <Box className="tw-inline-block">{rowData.vin}</Box>
                    </Typography>
                  </Toolbar>
                </AppBar>
                <DialogContent sx={{ padding: '0px' }}>
                  <TableBasic
                    tableAdditionalData={{}}
                    tableColumns={tableColumns_MileageLogs}
                    tableData={objectToArray(getProp(rowData, 'logs', {})).sort(dynamicSort('timestamp_start', 'asc'))}
                    tableSettings={tableSettings_IndividualMileageLogs}
                  />
                </DialogContent>
              </Dialog>
            ),
            settings: {
              max_width: 'lg',
            },
          },
        })
      },
    },
  }),
  vin: TableCellBasic('vin', rLIB('VIN'), 'vin'),
  first_odometer_timestamp: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('First Odometer')
      },
      header_sort_by: 'first_odometer_timestamp',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let readingJSX = <></>
        if (rowData != null && rowData['first_odometer_reading'] != null) {
          readingJSX = (
            <Box>
              {getProp(rowData, 'first_odometer_reading', 0).toFixed(1)} {rLIB('mi')}
            </Box>
          )
        }
        let timestampJSX = <></>
        if (rowData != null && rowData['first_odometer_timestamp'] != null) {
          timestampJSX = (
            <Box className="tw-opacity-30">
              {returnFormattedDate(rowData['first_odometer_timestamp'], 'D MMM YYYY h:mm a')} {rLIB('mi')}
            </Box>
          )
        }
        let cellJSX = (
          <Box>
            {readingJSX}
            {timestampJSX}
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
  },
  last_odometer_timestamp: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Last Odometer')
      },
      header_sort_by: 'last_odometer_timestamp',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let readingJSX = <></>
        if (rowData != null && rowData['last_odometer_reading'] != null) {
          readingJSX = (
            <Box>
              {getProp(rowData, 'last_odometer_reading', 0).toFixed(1)} {rLIB('mi')}
            </Box>
          )
        }
        let timestampJSX = <></>
        if (rowData != null && rowData['last_odometer_timestamp'] != null) {
          timestampJSX = (
            <Box className="tw-opacity-30">
              {returnFormattedDate(rowData['last_odometer_timestamp'], 'D MMM YYYY h:mm a')} {rLIB('mi')}
            </Box>
          )
        }
        let cellJSX = (
          <Box>
            {readingJSX}
            {timestampJSX}
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
  },
  total_miles_between_odometer_readings: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Sum of Mileage Logs')
      },
      header_sort_by: 'total_miles_between_odometer_readings',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData != null && rowData['total_miles_between_odometer_readings'] != null) {
          cellJSX = (
            <Box>
              {getProp(rowData, 'total_miles_between_odometer_readings', 0).toFixed(1)} {rLIB('mi')}
            </Box>
          )
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
  },
  difference_between_odometer_readings: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Difference between Odometers')
      },
      header_sort_by: 'difference_between_odometer_readings',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData != null && rowData['difference_between_odometer_readings'] != null) {
          cellJSX = (
            <Box>
              {getProp(rowData, 'difference_between_odometer_readings', 0).toFixed(1)} {rLIB('mi')}
            </Box>
          )
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
  },
  delta_between_odometer_and_logs: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Discrepancy')
      },
      header_sort_by: 'delta_between_odometer_and_logs',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData != null && rowData['delta_between_odometer_and_logs'] != null) {
          let sx = {}
          if (Math.abs(getProp(rowData, 'delta_between_odometer_and_logs', 0)) > 100) {
            sx = { background: themeVariables.error_main }
          } else if (Math.abs(getProp(rowData, 'delta_between_odometer_and_logs', 0)) > 25) {
            sx = { background: themeVariables.orange_500 }
          } else if (Math.abs(getProp(rowData, 'delta_between_odometer_and_logs', 0)) > 1) {
            sx = { background: themeVariables.warning_main }
            // } else if( Math.abs( getProp( rowData, "delta_between_odometer_and_logs", 0) ) > 0 ){
          } else {
            sx = { background: themeVariables.success_main }
          }

          cellJSX = (
            <Box
              className="tw-p-1 tw-rounded tw-inline-block"
              sx={sx}
            >
              {getProp(rowData, 'delta_between_odometer_and_logs', 0).toFixed(1)} {rLIB('mi')}
            </Box>
          )
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
  },
}

const tableSettings_OdometerAnalysis: TsInterface_TableSettings = {
  paginated: false,
  show_header: true,
  size: 'small',
  sortable: true,
  sort_direction: 'desc',
  sort_property_default: 'delta_between_odometer_and_logs',
}

const tableColumns_FuelLogDriverPins: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="trash"
        />
      ),
      label: <>{rLIB('Delete')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'error',
            header: rLIB('Delete Pin'),
            icon: (
              <Icon
                icon="trash"
                type="solid"
              />
            ),
            submit_text: rLIB('Delete'),
            text: rLIB('Are you sure that you want to delete this PIN?'),
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    DatabaseDeleteDocument(DatabaseRef_FuelLogDriverPin_Document(res_GCK.clientKey, rowData.key as string))
                      .then((res_DDD) => {
                        resolve(res_DDD)
                      })
                      .catch((rej_DDD) => {
                        reject(rej_DDD)
                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DDD.error })
                      })
                  })
                  .catch((rej_GCK) => {
                    reject(rej_GCK)
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  })
              })
            },
          },
        })
      },
    },
  }),
  associated_user_name: TableCellBasic('associated_user_name', rLIB('Employee'), 'associated_user_name'),
  key: TableCellBasic('key', rLIB('Fuel Card Pin'), 'key'),
}

const tableSettings_FuelLogDriverPins: TsInterface_TableSettings = {
  paginated: false,
  show_header: true,
  size: 'small',
  sortable: true,
  sort_direction: 'asc',
  sort_property_default: 'associated_driver_name',
}

const formInputs_FuelCardPin: TsInterface_FormInputs = {
  fuel_card_pin: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'fuel_card_pin',
    label: rLIB('Fuel Card Pin'),
    required: true,
  },
  associated_user_key: {
    data_type: 'string',
    key: 'associated_user_key',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: true,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData, formHooks) => {
      // User Search
      const rJSX_UserSearchResult = (
        option: TsInterface_UnspecifiedObject,
        searchInputValue: string | null,
        inputHooks: TsInterface_InputHooksObject,
        additionalSearchData: TsInterface_UnspecifiedObject,
      ): JSX.Element => {
        let searchResultJSX = (
          <Box
            sx={{ marginLeft: '8px', marginRight: '8px' }}
            onClick={() => {
              formInputChange('associated_user_key', option.id, true)
              formInputChange('associated_user_name', option.name, true)
            }}
          >
            <Typography className="tw-cursor-pointer">{rJSX_HighlightedSearchString(searchInputValue, option.name)} </Typography>
          </Box>
        )
        return searchResultJSX
      }
      // Full User and Vehicle Selection
      const rJSX_UserSelection = (): JSX.Element => {
        let userSelectionJSX = <></>
        if (formHooks.uc_RootData_ClientKey != null) {
          if (formData['associated_user_key'] == null || formData['associated_user_name'] == null) {
            userSelectionJSX = (
              <Box>
                <Typography
                  variant="body1"
                  className="tw-opacity-70"
                >
                  {rLIB('Employee')}*
                </Typography>
                <SearchInput
                  clientKey={formHooks.uc_RootData_ClientKey}
                  searchIndexKey={'users'}
                  searchFilters={[]}
                  searchResultRenderer={rJSX_UserSearchResult}
                  additionalSearchData={{}}
                  defaultSearchValue={''}
                  sx={{ minWidth: '100%' }}
                />
              </Box>
            )
          } else {
            userSelectionJSX = (
              <Box>
                <Typography
                  variant="body1"
                  className=""
                >
                  <Box
                    component="span"
                    className="tw-opacity-70 tw-mr-2"
                  >
                    {rLIB('Employee')}:
                  </Box>
                  <Box
                    component="span"
                    className="tw-font-bold"
                  >
                    {getProp(formData, 'associated_user_name', <></>)}
                  </Box>
                  <Icon
                    icon="square-xmark"
                    tooltip={rLIB('Remove')}
                    tooltipPlacement="right"
                    className="tw-ml-2 tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer hover:tw-text-error_main"
                    onClick={() => {
                      formInputChange('associated_user_key', null, true)
                      formInputChange('associated_user_name', null, true)
                    }}
                  />
                </Typography>
              </Box>
            )
          }
        }
        return userSelectionJSX
      }
      let inputJSX = <Box className="tw-mt-4 tw-mb-4">{rJSX_UserSelection()}</Box>
      return inputJSX
    },
  },
  associated_user_name: {
    data_type: 'string',
    key: 'associated_user_name',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData, formHooks) => {
      return <></>
    },
  },
}

// Helper
const generateHexColorArray = (num: number) => {
  const colors = []
  for (let i = 0; i < num; i++) {
    // Calculate the hue value between 0 (red) and 300 (purple) in HSL color space
    const hue = (i / (num - 1)) * 300
    // Convert HSL to RGB
    const h = hue / 60
    const c = 1
    const x = 1 - Math.abs((h % 2) - 1)
    const m = 0
    let r, g, b
    if (h >= 0 && h < 1) {
      r = c
      g = x
      b = 0
    } else if (h >= 1 && h < 2) {
      r = x
      g = c
      b = 0
    } else if (h >= 2 && h < 3) {
      r = 0
      g = c
      b = x
    } else if (h >= 3 && h < 4) {
      r = 0
      g = x
      b = c
    } else if (h >= 4 && h < 5) {
      r = x
      g = 0
      b = c
    } else {
      r = c
      g = 0
      b = x
    }
    // Convert RGB to hex
    const hexR = Math.floor((r + m) * 255)
      .toString(16)
      .padStart(2, '0')
    const hexG = Math.floor((g + m) * 255)
      .toString(16)
      .padStart(2, '0')
    const hexB = Math.floor((b + m) * 255)
      .toString(16)
      .padStart(2, '0')
    const hexColor = `#${hexR}${hexG}${hexB}`
    colors.push(hexColor)
  }
  return colors
}

// Custom Dialogs
const MileageAnalysisDayCustomDialog: React.FC<TsInterface_MileageAnalysisDayDialog> = ({ vehicleData, dateKey, tableAdditionalData }): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_geolocationLogs, us_setGeolocationLogs] = useState<TsInterface_UnspecifiedObject>({})
  const [us_hoveredPinKey, us_setHoveredPinKey] = useState<null | string>(null)
  const [us_mapPins, us_setMapPins] = useState<TsInterface_MapMarkers>({})
  const ur_mapRef = useRef(null)
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    let mapMarkers: TsInterface_MapMarkers = {}
    let iconColor = themeVariables.error_main
    if (
      vehicleData != null &&
      vehicleData['actual_miles'] != null &&
      vehicleData['actual_miles'][dateKey] != null &&
      vehicleData['scheduled_miles'] != null &&
      vehicleData['scheduled_miles'][dateKey] != null
    ) {
      let actualMiles = vehicleData['actual_miles'][dateKey]
      let scheduledMiles = vehicleData['scheduled_miles'][dateKey]
      if (!isNaN(scheduledMiles) && actualMiles > scheduledMiles) {
        iconColor = themeVariables.error_main
      } else {
        iconColor = themeVariables.success_main
      }
    }
    if (
      vehicleData != null &&
      vehicleData['actual_logs'] != null &&
      vehicleData['actual_logs'][dateKey] != null &&
      objectToArray(vehicleData['actual_logs'][dateKey]).length > 0
    ) {
      let logsArray = objectToArray(vehicleData['actual_logs'][dateKey]).sort(dynamicSort('timestamp_start', null))
      for (let loopLogIndex in logsArray) {
        let loopLog = logsArray[loopLogIndex]
        if (
          loopLog != null &&
          getProp(loopLog, 'location_start_latitude', null) != null &&
          getProp(loopLog, 'location_start_longitude', null) != null &&
          (getProp(loopLog, 'distance_miles', null) != null || parseInt(loopLogIndex) === logsArray.length - 1)
        ) {
          let scaleMultiplier = 1
          let strokeMultiplier = 1
          if (us_hoveredPinKey === 'actual_' + loopLog.key) {
            scaleMultiplier = 1.5
            strokeMultiplier = 2
          }
          mapMarkers['actual_' + loopLog.key] = {
            position: {
              lat: getProp(loopLog, 'location_start_latitude', null),
              lng: getProp(loopLog, 'location_start_longitude', null),
            },
            icon: {
              path: returnMapIcons('pin')['path'],
              fillColor: iconColor,
              fillOpacity: 1,
              strokeWeight: 0.5 * strokeMultiplier,
              strokeColor: 'white',
              rotation: 25,
              scale: returnMapIcons('pin')['scale'] * scaleMultiplier,
              anchor: returnMapIcons('pin')['anchor'],
            },
            // title: loopLog.location_start,
            title: returnFormattedDate(loopLog.timestamp_start, 'h:mm a'),
            label: '',
            onMouseOver: () => {
              us_setHoveredPinKey('actual_' + loopLog.key)
            },
            onMouseOut: () => {
              us_setHoveredPinKey(null)
            },
          }
        }
      }
    }
    if (getProp(vehicleData, 'garage_latitude', null) != null && getProp(vehicleData, 'garage_longitude', null)) {
      let scaleMultiplier = 1
      let strokeMultiplier = 1
      if (us_hoveredPinKey === 'garage') {
        scaleMultiplier = 1.5
        strokeMultiplier = 2
      }
      mapMarkers['garage'] = {
        position: {
          lat: getProp(vehicleData, 'garage_latitude', null),
          lng: getProp(vehicleData, 'garage_longitude', null),
        },
        icon: {
          path: returnMapIcons('pin')['path'],
          fillColor: themeVariables.warning_dark,
          fillOpacity: 1,
          strokeWeight: 0.5 * strokeMultiplier,
          strokeColor: 'white',
          rotation: 0,
          scale: returnMapIcons('pin')['scale'] * scaleMultiplier,
          anchor: returnMapIcons('pin')['anchor'],
        },
        title: 'Garage',
        label: '',
        onMouseOver: () => {
          us_setHoveredPinKey('garage')
        },
        onMouseOut: () => {
          us_setHoveredPinKey(null)
        },
      }
    }
    if (
      vehicleData != null &&
      vehicleData['events'] != null &&
      vehicleData['events'][dateKey] != null &&
      objectToArray(vehicleData['events'][dateKey]).length > 0
    ) {
      for (let loopEventKey in vehicleData['events'][dateKey]) {
        let loopEvent = vehicleData['events'][dateKey][loopEventKey]
        if (loopEvent.location_latitude != null && loopEvent.location_longitude != null) {
          let scaleMultiplier = 1
          let strokeMultiplier = 1
          if (us_hoveredPinKey === 'scheduled_' + loopEventKey) {
            scaleMultiplier = 1.5
            strokeMultiplier = 2
          }
          mapMarkers['scheduled_' + loopEventKey] = {
            position: {
              lat: getProp(loopEvent, 'location_latitude', null),
              lng: getProp(loopEvent, 'location_longitude', null),
            },
            icon: {
              path: returnMapIcons('pin')['path'],
              fillColor: themeVariables.info_main,
              fillOpacity: 1,
              strokeWeight: 0.5 * strokeMultiplier,
              strokeColor: 'white',
              rotation: 0,
              scale: returnMapIcons('pin')['scale'] * scaleMultiplier,
              anchor: returnMapIcons('pin')['anchor'],
            },
            title: loopEvent.associated_project_id_number,
            label: '',
            onMouseOver: () => {
              us_setHoveredPinKey('scheduled_' + loopEventKey)
            },
            onMouseOut: () => {
              us_setHoveredPinKey(null)
            },
          }
        }
      }
    }
    if (us_geolocationLogs != null) {
      let pinColors = generateHexColorArray(objectToArray(us_geolocationLogs).length)
      let loopIndex = 0
      for (let loopLogKey in us_geolocationLogs) {
        let loopLog = us_geolocationLogs[loopLogKey]
        let iconColor = pinColors[loopIndex]
        loopIndex++
        if (loopLog != null && getProp(loopLog, 'location_latitude', null) != null && getProp(loopLog, 'location_longitude', null) != null) {
          mapMarkers['geolocation_' + returnDateFromUnknownDateFormat(loopLog.timestamp).getTime().toString()] = {
            position: {
              lat: getProp(loopLog, 'location_latitude', null),
              lng: getProp(loopLog, 'location_longitude', null),
            },
            icon: {
              path: returnMapIcons('circle')['path'],
              fillColor: 'rgba(0,0,0,.1)',
              fillOpacity: 1,
              strokeWeight: 3,
              strokeColor: iconColor,
              // strokeColor: "#000",
              rotation: 0,
              scale: returnMapIcons('circle')['scale'],
              anchor: returnMapIcons('circle')['anchor'],
            },
            title: returnFormattedDate(loopLog.timestamp, 'h:mm: a'),
            label: '',
          }
        }
      }
    }
    // Set Map Pins
    us_setMapPins(mapMarkers)
    // Set Map Bounds
    setTimeout(() => {
      // @ts-expect-error - TODO: reason for error
      if (ur_mapRef != null && ur_mapRef.current != null && ur_mapRef.current.mapCenter != null) {
        // @ts-expect-error - TODO: reason for error
        if (ur_mapRef != null && ur_mapRef.current != null && ur_mapRef.current.recalculateMapBounds != null) {
          // @ts-expect-error - TODO: reason for error
          ur_mapRef.current.recalculateMapBounds(true)
        }
      }
    }, 0)
    return () => {}
  }, [dateKey, us_geolocationLogs, us_hoveredPinKey, vehicleData])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setGeolocationLogs(newData)
    }
    let utcDate = new Date(dateKey as string)
    let startDate = new Date(utcDate.getTime() + Math.abs(utcDate.getTimezoneOffset() * 60000))
    let endDate = new Date(startDate.getTime() + 86400000)
    if (vehicleData != null && vehicleData.key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_TimeSheetsGeolocationUsersHistory_DateRange_Query(res_GCK.clientKey, vehicleData.key, startDate, endDate),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [dateKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, vehicleData])

  // Other Variables
  let mapBasicSettings: TsInterface_MapSettings = {
    center_lat: getProp(vehicleData, 'garage_latitude', 0),
    center_lng: getProp(vehicleData, 'garage_longitude', 0),
    height: 'calc( 100vh - 128px )',
    render_clusters: false,
    render_heatmap: false,
    render_markers: true,
    width: '100%',
    zoom: 12,
  }

  // Functions
  const mapBasicOnClick: TsType_MapOnClick = (lat: number, lng: number) => {
    // Nothing
  }

  const returnMapIcons = (iconKey: string): TsInterface_UnspecifiedObject => {
    let icon: TsInterface_UnspecifiedObject = {}
    switch (iconKey) {
      case 'pin':
        icon = {
          path: 'M25,1C19.486,1 15,5.486 15,11C15,16.514 19.486,21 25,21C30.514,21 35,16.514 35,11C35,5.486 30.514,1 25,1ZM25,5C25.552,5 26,5.447 26,6C26,6.553 25.552,7 25,7C22.794,7 21,8.794 21,11C21,11.553 20.552,12 20,12C19.448,12 19,11.553 19,11C19,7.691 21.691,5 25,5ZM22,22.605L22,43.244L25,48.99L28,43.244L28,22.605C27.039,22.854 26.037,23 25,23C23.963,23 22.961,22.854 22,22.605Z',
          scale: 1,
          anchor: new google.maps.Point(20, 48),
        }
        break
      case 'circle':
        icon = {
          path: 'M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z',
          scale: 0.6,
          anchor: new google.maps.Point(14, 14),
        }
        break
      default:
        icon = {
          path: 'M399.999,0C240.037,0 110.867,129.17 110.867,289.132C110.867,344.905 128.684,398.161 153.472,441.926L341.902,768.044C353.715,789.347 377.535,799.998 399.999,799.998C422.465,799.998 445.121,789.347 458.096,768.044L646.526,442.12C671.508,398.351 689.131,346.063 689.131,289.321C689.131,129.364 559.961,0 399.999,0ZM399.999,374.535C341.902,374.535 294.455,327.089 294.455,268.991C294.455,210.894 341.902,163.448 399.999,163.448C458.096,163.448 505.543,210.894 505.543,268.991C505.543,327.282 458.096,374.535 399.999,374.535Z',
          scale: 0.05,
          anchor: new google.maps.Point(400, 800),
        }
    }
    return icon
  }

  // JSX Generation
  const rJSX_MilesBetweenTwoLocations = (lat1: number, lng1: number, lat2: number, lng2: number): JSX.Element => {
    let milesJSX = <></>
    if (
      lat1 != null &&
      lng1 != null &&
      lat2 != null &&
      lng2 != null &&
      tableAdditionalData != null &&
      tableAdditionalData.us_weekMileageAnalysisGeocodedDistances != null &&
      tableAdditionalData.us_weekMileageAnalysisGeocodedDistances[lat1.toString() + '_' + lng1.toString() + '_' + lat2.toString() + '_' + lng2.toString()] !=
        null &&
      tableAdditionalData.us_weekMileageAnalysisGeocodedDistances[lat1.toString() + '_' + lng1.toString() + '_' + lat2.toString() + '_' + lng2.toString()][
        'miles'
      ] != null
    ) {
      milesJSX = (
        <Box>
          <Box
            className="tw-rounded tw-px-1 tw-inline-block"
            sx={{ backgroundColor: themeVariables.info_light }}
          >
            {tableAdditionalData.us_weekMileageAnalysisGeocodedDistances[
              lat1.toString() + '_' + lng1.toString() + '_' + lat2.toString() + '_' + lng2.toString()
            ]['miles'].toFixed(1)}{' '}
            {rLIB('mi')}
          </Box>
        </Box>
      )
    } else {
      milesJSX = (
        <Box>
          <Box className="tw-opacity-30 tw-italic">{rLIB('No Distance Calculated')}</Box>
        </Box>
      )
    }
    return milesJSX
  }

  const rJSX_CoordinatesText = (lat1: number, lng1: number): JSX.Element => {
    let coordinatesTextJSX = <></>
    if (lat1 != null && lng1 != null) {
      coordinatesTextJSX = (
        <Box className="tw-opacity-30 tw-italic">
          ({lat1}, {lng1})
        </Box>
      )
    } else {
      coordinatesTextJSX = <Box className="tw-opacity-30 tw-italic">{rLIB('No Coordinates')}</Box>
    }
    return coordinatesTextJSX
  }

  const rJSX_LogMiles = (miles: number): JSX.Element => {
    let logMilesJSX = <></>
    let backgroundColor = themeVariables.error_main
    if (
      vehicleData != null &&
      vehicleData['actual_miles'] != null &&
      vehicleData['actual_miles'][dateKey] != null &&
      vehicleData['scheduled_miles'] != null &&
      vehicleData['scheduled_miles'][dateKey] != null
    ) {
      let actualMiles = vehicleData['actual_miles'][dateKey]
      let scheduledMiles = vehicleData['scheduled_miles'][dateKey]
      if (!isNaN(scheduledMiles) && actualMiles > scheduledMiles) {
        backgroundColor = themeVariables.error_main
      } else {
        backgroundColor = themeVariables.success_main
      }
    }
    if (miles != null) {
      logMilesJSX = (
        <Box>
          <Box
            className="tw-rounded tw-px-1 tw-inline-block"
            sx={{ backgroundColor: backgroundColor }}
          >
            {miles} {rLIB('mi')}
          </Box>
        </Box>
      )
    } else {
      logMilesJSX = (
        <Box>
          <Box className="tw-opacity-30 tw-italic">{rLIB('No Miles')}</Box>
        </Box>
      )
    }
    return logMilesJSX
  }

  const rJSX_ScheduledRouteList = () => {
    let routeListJSX = <></>
    if (
      vehicleData != null &&
      vehicleData['events'] != null &&
      vehicleData['events'][dateKey] != null &&
      objectToArray(vehicleData['events'][dateKey]).length > 0
    ) {
      let dayEvents = getProp(vehicleData.events, dateKey, {})
      let sortedDayEvents = objectToArray(dayEvents).sort(dynamicSort('start_time', null))
      // JSX
      routeListJSX = (
        <TableContainer>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell
                  className="tw-align-inherit tw-text-center"
                  sx={{
                    width: '36px',
                    backgroundColor: us_hoveredPinKey === 'garage' ? themeVariables.gray_600 : 'transparent',
                  }}
                  onMouseOver={() => {
                    us_setHoveredPinKey('garage')
                  }}
                  onMouseOut={() => {
                    us_setHoveredPinKey(null)
                  }}
                >
                  <Icon
                    icon="map-pin"
                    sx={{ fontSize: '24px', color: themeVariables.warning_dark }}
                  />
                </TableCell>
                <TableCell className="tw-align-inherit">
                  <Box>
                    {rLIB('Garage')} ({rLIB('Start of Day')})
                  </Box>
                  <Box>{rJSX_CoordinatesText(getProp(vehicleData, 'garage_latitude', null), getProp(vehicleData, 'garage_longitude', null))}</Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  className="tw-align-inherit tw-text-center"
                  sx={{ width: '36px' }}
                >
                  <Icon
                    icon="road"
                    sx={{ fontSize: '18px', color: themeVariables.background_json }}
                  />
                </TableCell>
                <TableCell className="tw-align-inherit">
                  {rJSX_MilesBetweenTwoLocations(
                    getProp(vehicleData, 'garage_latitude', null),
                    getProp(vehicleData, 'garage_longitude', null),
                    sortedDayEvents[0].location_latitude,
                    sortedDayEvents[0].location_longitude,
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
            {sortedDayEvents.map((event, eventIndex) => (
              <TableBody key={eventIndex}>
                <TableRow>
                  <TableCell
                    className="tw-align-inherit tw-text-center"
                    sx={{
                      width: '36px',
                      backgroundColor: us_hoveredPinKey === 'scheduled_' + event.key ? themeVariables.gray_600 : 'transparent',
                    }}
                    onMouseOver={() => {
                      us_setHoveredPinKey('scheduled_' + event.key)
                    }}
                    onMouseOut={() => {
                      us_setHoveredPinKey(null)
                    }}
                  >
                    <Icon
                      icon="map-pin"
                      sx={{ fontSize: '24px', color: themeVariables.info_main }}
                    />
                  </TableCell>
                  <TableCell className="tw-align-inherit">
                    <Box className="tw-font-bold">{event.associated_project_id_number}</Box>
                    <Box>{event.name}</Box>
                    <Box>{rJSX_CoordinatesText(event.location_latitude, event.location_longitude)}</Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    className="tw-align-inherit tw-text-center"
                    sx={{ width: '36px' }}
                  >
                    <Icon
                      icon="road"
                      sx={{ fontSize: '18px', color: themeVariables.background_json }}
                    />
                  </TableCell>
                  <TableCell className="tw-align-inherit">
                    {eventIndex < sortedDayEvents.length - 1
                      ? rJSX_MilesBetweenTwoLocations(
                          event.location_latitude,
                          event.location_longitude,
                          sortedDayEvents[eventIndex + 1].location_latitude,
                          sortedDayEvents[eventIndex + 1].location_longitude,
                        )
                      : rJSX_MilesBetweenTwoLocations(
                          event.location_latitude,
                          event.location_longitude,
                          getProp(vehicleData, 'garage_latitude', null),
                          getProp(vehicleData, 'garage_longitude', null),
                        )}
                  </TableCell>
                </TableRow>
              </TableBody>
            ))}
            <TableBody>
              <TableRow>
                <TableCell
                  className="tw-align-inherit tw-text-center"
                  sx={{
                    width: '36px',
                    backgroundColor: us_hoveredPinKey === 'garage' ? themeVariables.gray_600 : 'transparent',
                  }}
                  onMouseOver={() => {
                    us_setHoveredPinKey('garage')
                  }}
                  onMouseOut={() => {
                    us_setHoveredPinKey(null)
                  }}
                >
                  <Icon
                    icon="map-pin"
                    sx={{ fontSize: '24px', color: themeVariables.warning_dark }}
                  />
                </TableCell>
                <TableCell className="tw-align-inherit">
                  <Box>
                    {rLIB('Garage')} ({rLIB('End of Day')})
                  </Box>
                  <Box>{rJSX_CoordinatesText(getProp(vehicleData, 'garage_latitude', null), getProp(vehicleData, 'garage_longitude', null))}</Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )
    } else {
      routeListJSX = (
        <Box className="tw-text-center">
          <Box className="tw-opacity-30 tw-italic">{rLIB('No scheduled work on calendar')}</Box>
        </Box>
      )
    }
    return routeListJSX
  }

  const rJSX_ActualLogsList = () => {
    // CSS
    let pinColor = themeVariables.error_main
    if (
      vehicleData != null &&
      vehicleData['actual_miles'] != null &&
      vehicleData['actual_miles'][dateKey] != null &&
      vehicleData['scheduled_miles'] != null &&
      vehicleData['scheduled_miles'][dateKey] != null
    ) {
      let actualMiles = vehicleData['actual_miles'][dateKey]
      let scheduledMiles = vehicleData['scheduled_miles'][dateKey]
      if (!isNaN(scheduledMiles) && actualMiles > scheduledMiles) {
        pinColor = themeVariables.error_main
      } else {
        pinColor = themeVariables.success_main
      }
    }
    // JSX
    let listJSX = <></>
    if (
      vehicleData != null &&
      vehicleData['actual_logs'] != null &&
      vehicleData['actual_logs'][dateKey] != null &&
      objectToArray(vehicleData['actual_logs'][dateKey]).length > 0
    ) {
      let sortedAllDayLogs = objectToArray(vehicleData['actual_logs'][dateKey]).sort(dynamicSort('timestamp_start', null))
      let filteredDayLogs = objectToArray(vehicleData['actual_logs'][dateKey]).filter((log) => {
        return log.distance_miles != null
      })
      if (filteredDayLogs.length > 0) {
        let sortedFilteredDayLogs = objectToArray(filteredDayLogs).sort(dynamicSort('timestamp_start', null))
        let lastDayLog = sortedAllDayLogs[sortedAllDayLogs.length - 1]
        listJSX = (
          <TableContainer>
            <Table size="small">
              {sortedFilteredDayLogs.map((log, logIndex) => (
                <TableBody key={logIndex}>
                  <TableRow>
                    <TableCell
                      className="tw-align-inherit tw-text-center"
                      sx={{
                        width: '36px',
                        backgroundColor: us_hoveredPinKey === 'actual_' + log.key ? themeVariables.gray_600 : 'transparent',
                      }}
                      onMouseOver={() => {
                        us_setHoveredPinKey('actual_' + log.key)
                      }}
                      onMouseOut={() => {
                        us_setHoveredPinKey(null)
                      }}
                    >
                      <Icon
                        icon="map-pin"
                        sx={{ fontSize: '24px', color: pinColor }}
                      />
                    </TableCell>
                    <TableCell className="tw-align-inherit">
                      <Box>
                        {/* <Box className="tw-inline-block tw-font-bold" >
													{ log.location_start }
												</Box> */}
                        <Box className="tw-inline-block">{returnFormattedDate(log.timestamp_start, 'h:mm a')}</Box>
                      </Box>
                      <Box>{rJSX_CoordinatesText(log.location_start_latitude, log.location_start_longitude)}</Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className="tw-align-inherit tw-text-center"
                      sx={{ width: '36px' }}
                    >
                      <Icon
                        icon="road"
                        sx={{ fontSize: '24px', color: themeVariables.background_json }}
                      />
                    </TableCell>
                    <TableCell className="tw-align-inherit">{rJSX_LogMiles(log.distance_miles)}</TableCell>
                  </TableRow>
                </TableBody>
              ))}
              <TableBody>
                <TableRow>
                  <TableCell
                    className="tw-align-inherit tw-text-center"
                    sx={{
                      width: '36px',
                      backgroundColor: us_hoveredPinKey === 'actual_' + lastDayLog.key ? themeVariables.gray_600 : 'transparent',
                    }}
                    onMouseOver={() => {
                      us_setHoveredPinKey('actual_' + lastDayLog.key)
                    }}
                    onMouseOut={() => {
                      us_setHoveredPinKey(null)
                    }}
                  >
                    <Icon
                      icon="map-pin"
                      sx={{ fontSize: '24px', color: pinColor }}
                    />
                  </TableCell>
                  <TableCell className="tw-align-inherit">
                    <Box>
                      {/* <Box className="tw-inline-block tw-font-bold" >
												{ lastDayLog.location_start }
											</Box> */}
                      <Box className="tw-inline-block">{returnFormattedDate(lastDayLog.timestamp_start, 'h:mm a')}</Box>
                    </Box>
                    <Box>{rJSX_CoordinatesText(lastDayLog.location_start_latitude, lastDayLog.location_start_longitude)}</Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )
      } else {
        listJSX = (
          <Box className="tw-text-center">
            <Box className="tw-opacity-30 tw-italic">{rLIB('No logs with mileages')}</Box>
          </Box>
        )
      }
    } else {
      listJSX = (
        <Box className="tw-text-center">
          <Box className="tw-opacity-30 tw-italic">{rLIB('No Uploaded Logs')}</Box>
        </Box>
      )
    }
    return listJSX
  }

  const rJSX_Dialog = (): JSX.Element => {
    let dialogJSX = (
      <Box>
        <Dialog
          TransitionComponent={TransitionSlide}
          className="bp_dialog_xl_width"
          keepMounted
          onClose={() => {
            uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
          }}
          open={true}
        >
          <AppBar
            position="static"
            color="inherit"
          >
            <Toolbar>
              <IconButton
                aria-label="menu"
                color="inherit"
                disabled
                edge="start"
                size="large"
                sx={{ mr: 2, color: '#fff !important' }}
              >
                <Icon icon="route" />
              </IconButton>
              <Typography
                component={'span'}
                variant={'h6'}
                sx={{ flexGrow: 1 }}
              >
                {vehicleData.name} - {dateKey}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent sx={{ padding: '0px' }}>
            <Box>
              <Grid2
                container
                spacing={0}
              >
                <Grid2
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  className="tw-overflow-x-scroll"
                  sx={{ height: 'calc( 100vh - 128px )' }}
                >
                  <Box className="tw-p-2">
                    <Typography
                      variant="h6"
                      color="primary"
                    >
                      {rLIB('Totals')}
                    </Typography>
                    <Box className="tw-ml-4">{rJSX_MileageAnalysisTableCellContent(vehicleData, dateKey)}</Box>
                  </Box>
                  <Divider className="tw-my-0" />
                  <Box className="tw-p-2">
                    <Typography
                      variant="h6"
                      color="primary"
                    >
                      {rLIB('Scheduled')}
                    </Typography>
                  </Box>
                  <Box className="tw-ml-4">{rJSX_ScheduledRouteList()}</Box>
                  <Divider className="tw-my-0" />
                  <Box className="tw-p-2">
                    <Typography
                      variant="h6"
                      color="primary"
                    >
                      {rLIB('Actual')}
                    </Typography>
                  </Box>
                  <Box className="tw-ml-4">{rJSX_ActualLogsList()}</Box>
                </Grid2>
                <Grid2
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                >
                  <MapBasic
                    ref={ur_mapRef}
                    mapSettings={mapBasicSettings}
                    mapMarkers={us_mapPins}
                    mapOnClick={mapBasicOnClick}
                  />
                </Grid2>
              </Grid2>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    )
    return dialogJSX
  }

  return <>{rJSX_Dialog()}</>
}

const FuelAnalysisDayCustomDialog: React.FC<TsInterface_FuelAnalysisMapDialog> = ({ garage, driverData, dateKey, fuelLog }): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_cancelledEvents, us_setCancelledEvents] = useState<TsInterface_UnspecifiedObject>({})
  const [us_events, us_setEvents] = useState<TsInterface_UnspecifiedObject>({})
  const [us_geolocationLogs, us_setGeolocationLogs] = useState<TsInterface_UnspecifiedObject>({})
  const [us_mapPins, us_setMapPins] = useState<TsInterface_MapMarkers>({})
  const ur_mapRef = useRef(null)
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    let mapMarkers: TsInterface_MapMarkers = {}
    if (getProp(garage, 'location_latitude', null) != null && getProp(garage, 'location_longitude', null)) {
      let scaleMultiplier = 1
      let strokeMultiplier = 1
      mapMarkers['garage'] = {
        position: {
          lat: getProp(garage, 'location_latitude', null),
          lng: getProp(garage, 'location_longitude', null),
        },
        icon: {
          path: returnMapIcons('pin')['path'],
          fillColor: themeVariables.warning_dark,
          fillOpacity: 1,
          strokeWeight: 0.5 * strokeMultiplier,
          strokeColor: 'white',
          rotation: 0,
          scale: returnMapIcons('pin')['scale'] * scaleMultiplier,
          anchor: returnMapIcons('pin')['anchor'],
        },
        title: 'Garage',
        label: '',
      }
    }
    if (objectToArray(us_events).length > 0) {
      for (let loopEventKey in us_events) {
        let loopEvent = us_events[loopEventKey]
        if (loopEvent.location_latitude != null && loopEvent.location_longitude != null) {
          let scaleMultiplier = 1
          let strokeMultiplier = 1
          mapMarkers['scheduled_' + loopEventKey] = {
            position: {
              lat: getProp(loopEvent, 'location_latitude', null),
              lng: getProp(loopEvent, 'location_longitude', null),
            },
            icon: {
              path: returnMapIcons('pin')['path'],
              fillColor: themeVariables.info_main,
              fillOpacity: 1,
              strokeWeight: 0.5 * strokeMultiplier,
              strokeColor: 'white',
              rotation: 0,
              scale: returnMapIcons('pin')['scale'] * scaleMultiplier,
              anchor: returnMapIcons('pin')['anchor'],
            },
            title: loopEvent.associated_project_id_number,
            label: '',
          }
        }
      }
    }
    if (objectToArray(us_cancelledEvents).length > 0) {
      for (let loopEventKey in us_cancelledEvents) {
        let loopEvent = us_cancelledEvents[loopEventKey]
        if (loopEvent.location_latitude != null && loopEvent.location_longitude != null) {
          let scaleMultiplier = 1
          let strokeMultiplier = 1
          mapMarkers['scheduled_' + loopEventKey] = {
            position: {
              lat: getProp(loopEvent, 'location_latitude', null),
              lng: getProp(loopEvent, 'location_longitude', null),
            },
            icon: {
              path: returnMapIcons('pin')['path'],
              fillColor: themeVariables.info_main,
              fillOpacity: 1,
              strokeWeight: 0.5 * strokeMultiplier,
              strokeColor: 'white',
              rotation: 0,
              scale: returnMapIcons('pin')['scale'] * scaleMultiplier,
              anchor: returnMapIcons('pin')['anchor'],
            },
            title: loopEvent.associated_project_id_number,
            label: '',
          }
        }
      }
    }
    if (driverData != null && driverData.fuel_logs != null && dateKey != null) {
      for (let loopDriverFuelLogKey in driverData['fuel_logs']) {
        let loopDriverFuelLog = driverData['fuel_logs'][loopDriverFuelLogKey]
        let fuelLogDateKey = returnFormattedDateKey(loopDriverFuelLog.CALCULATED_timestamp_transaction)
        if (fuelLogDateKey === dateKey) {
          if (loopDriverFuelLog.CALCULATED_lat != null && loopDriverFuelLog.CALCULATED_lng != null) {
            mapMarkers['gas_station_' + loopDriverFuelLogKey] = {
              position: {
                lat: loopDriverFuelLog.CALCULATED_lat,
                lng: loopDriverFuelLog.CALCULATED_lng,
              },
              icon: {
                path: returnMapIcons('pin')['path'],
                fillColor: themeVariables.black,
                fillOpacity: 1,
                strokeWeight: 0.5,
                strokeColor: 'white',
                rotation: 0,
                scale: returnMapIcons('pin')['scale'] * 1,
                anchor: returnMapIcons('pin')['anchor'],
              },
              title: loopDriverFuelLog.associated_merchant_name + ' - ' + returnFormattedDate(loopDriverFuelLog.CALCULATED_timestamp_transaction, 'h:mm a'),
              label: '',
            }
          }
          if (loopDriverFuelLog.CALCULATED_same_day_mileage_logs != null) {
            for (let loopMileageLogKey in loopDriverFuelLog.CALCULATED_same_day_mileage_logs) {
              let loopMileageLog = loopDriverFuelLog.CALCULATED_same_day_mileage_logs[loopMileageLogKey]
              if (loopMileageLog != null && loopMileageLog.location_start_latitude != null && loopMileageLog.location_start_longitude != null)
                mapMarkers['mileage_log_' + loopMileageLogKey] = {
                  position: {
                    lat: loopMileageLog.location_start_latitude,
                    lng: loopMileageLog.location_start_longitude,
                  },
                  icon: {
                    path: returnMapIcons('pin')['path'],
                    fillColor: themeVariables.error_main,
                    fillOpacity: 1,
                    strokeWeight: 0.5,
                    strokeColor: 'white',
                    rotation: 25,
                    scale: returnMapIcons('pin')['scale'] * 1,
                    anchor: returnMapIcons('pin')['anchor'],
                  },
                  title: returnFormattedDate(loopMileageLog.timestamp_start, 'h:mm a'),
                  label: '',
                }
            }
          }
        }
      }
    }
    if (us_geolocationLogs != null) {
      let pinColors = generateHexColorArray(objectToArray(us_geolocationLogs).length)
      let loopIndex = 0
      for (let loopLogKey in us_geolocationLogs) {
        let loopLog = us_geolocationLogs[loopLogKey]
        let iconColor = pinColors[loopIndex]
        loopIndex++
        if (loopLog != null && getProp(loopLog, 'location_latitude', null) != null && getProp(loopLog, 'location_longitude', null) != null) {
          mapMarkers['geolocation_' + returnDateFromUnknownDateFormat(loopLog.timestamp).getTime().toString()] = {
            position: {
              lat: getProp(loopLog, 'location_latitude', null),
              lng: getProp(loopLog, 'location_longitude', null),
            },
            icon: {
              path: returnMapIcons('circle')['path'],
              fillColor: 'rgba(0,0,0,.1)',
              fillOpacity: 1,
              strokeWeight: 3,
              strokeColor: iconColor,
              // strokeColor: "#000",
              rotation: 0,
              scale: returnMapIcons('circle')['scale'],
              anchor: returnMapIcons('circle')['anchor'],
            },
            title: returnFormattedDate(loopLog.timestamp, 'h:mm: a'),
            label: '',
          }
        }
      }
    }
    // Set Map Pins
    us_setMapPins(mapMarkers)
    // Set Map Bounds
    setTimeout(() => {
      // @ts-expect-error - TODO: reason for error
      if (ur_mapRef != null && ur_mapRef.current != null && ur_mapRef.current.mapCenter != null) {
        // @ts-expect-error - TODO: reason for error
        if (ur_mapRef != null && ur_mapRef.current != null && ur_mapRef.current.recalculateMapBounds != null) {
          // @ts-expect-error - TODO: reason for error
          ur_mapRef.current.recalculateMapBounds(true)
        }
      }
    }, 0)
    return () => {}
  }, [dateKey, us_geolocationLogs, driverData, us_cancelledEvents, us_events, garage])

  // TODO - right now using user string instead of user with key so not possible to pull data...

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setGeolocationLogs(newData)
    }
    let utcDate = new Date(dateKey as string)
    let startDate = new Date(utcDate.getTime() + Math.abs(utcDate.getTimezoneOffset() * 60000))
    let endDate = new Date(startDate.getTime() + 86400000)
    if (fuelLog != null && fuelLog.CALCULATED_associated_user_key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_TimeSheetsGeolocationUsersHistory_DateRange_Query(res_GCK.clientKey, fuelLog.CALCULATED_associated_user_key, startDate, endDate),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [dateKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, fuelLog])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setEvents(newData)
    }
    if (fuelLog != null && fuelLog.CALCULATED_associated_user_key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ScheduledTasksOnDateForSpecificUser_Query(res_GCK.clientKey, dateKey, fuelLog.CALCULATED_associated_user_key),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [dateKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, fuelLog])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setCancelledEvents(newData)
    }
    if (fuelLog != null && fuelLog.CALCULATED_associated_user_key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_CancelledScheduledTasksOnDateForSpecificUser_Query(res_GCK.clientKey, dateKey, fuelLog.CALCULATED_associated_user_key),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [dateKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, fuelLog])

  // Other Variables
  let mapBasicSettings: TsInterface_MapSettings = {
    // center_lat: getProp( vehicleData, "garage_latitude", 0 ),
    center_lat: 0,
    // center_lng: getProp( vehicleData, "garage_longitude", 0 ),
    center_lng: 0,
    height: 'calc( 100vh - 128px )',
    render_clusters: false,
    render_heatmap: false,
    render_markers: true,
    width: '100%',
    zoom: 12,
  }

  // Functions
  const mapBasicOnClick: TsType_MapOnClick = (lat: number, lng: number) => {
    // Nothing
  }

  const returnMapIcons = (iconKey: string): TsInterface_UnspecifiedObject => {
    let icon: TsInterface_UnspecifiedObject = {}
    switch (iconKey) {
      case 'pin':
        icon = {
          path: 'M25,1C19.486,1 15,5.486 15,11C15,16.514 19.486,21 25,21C30.514,21 35,16.514 35,11C35,5.486 30.514,1 25,1ZM25,5C25.552,5 26,5.447 26,6C26,6.553 25.552,7 25,7C22.794,7 21,8.794 21,11C21,11.553 20.552,12 20,12C19.448,12 19,11.553 19,11C19,7.691 21.691,5 25,5ZM22,22.605L22,43.244L25,48.99L28,43.244L28,22.605C27.039,22.854 26.037,23 25,23C23.963,23 22.961,22.854 22,22.605Z',
          scale: 1,
          anchor: new google.maps.Point(20, 48),
        }
        break
      case 'circle':
        icon = {
          path: 'M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z',
          scale: 0.6,
          anchor: new google.maps.Point(14, 14),
        }
        break
      default:
        icon = {
          path: 'M399.999,0C240.037,0 110.867,129.17 110.867,289.132C110.867,344.905 128.684,398.161 153.472,441.926L341.902,768.044C353.715,789.347 377.535,799.998 399.999,799.998C422.465,799.998 445.121,789.347 458.096,768.044L646.526,442.12C671.508,398.351 689.131,346.063 689.131,289.321C689.131,129.364 559.961,0 399.999,0ZM399.999,374.535C341.902,374.535 294.455,327.089 294.455,268.991C294.455,210.894 341.902,163.448 399.999,163.448C458.096,163.448 505.543,210.894 505.543,268.991C505.543,327.282 458.096,374.535 399.999,374.535Z',
          scale: 0.05,
          anchor: new google.maps.Point(400, 800),
        }
    }
    return icon
  }

  // JSX Generation
  const rJSX_Dialog = (): JSX.Element => {
    let dialogJSX = (
      <Box>
        <Dialog
          TransitionComponent={TransitionSlide}
          className="bp_dialog_xl_width"
          keepMounted
          onClose={() => {
            uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
          }}
          open={true}
        >
          <AppBar
            position="static"
            color="inherit"
          >
            <Toolbar>
              <IconButton
                aria-label="menu"
                color="inherit"
                disabled
                edge="start"
                size="large"
                sx={{ mr: 2, color: '#fff !important' }}
              >
                <Icon icon="route" />
              </IconButton>
              <Typography
                component={'span'}
                variant={'h6'}
                sx={{ flexGrow: 1 }}
              >
                {driverData.name} - {dateKey}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent sx={{ padding: '0px' }}>
            <Box>
              <MapBasic
                ref={ur_mapRef}
                mapSettings={mapBasicSettings}
                mapMarkers={us_mapPins}
                mapOnClick={mapBasicOnClick}
              />
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    )
    return dialogJSX
  }

  return <>{rJSX_Dialog()}</>
}

///////////////////////////////
// Functions
///////////////////////////////

function sortByTwoProperties(arr: TsInterface_UnspecifiedObject[], firstProp: string, secondProp: string) {
  arr.sort((a, b) => {
    if (a[firstProp] === b[firstProp]) {
      return a[secondProp] < b[secondProp] ? -1 : 1
    }
    return a[firstProp] < b[firstProp] ? -1 : 1
  })
  return arr
}

function timeStringToMinutes(timeStr: string) {
  // Split the input string into hours, minutes, and am/pm parts
  const [time, ampm] = timeStr.split(' ')
  const [hours, minutes] = time.split(':')
  // Convert hours and minutes to integers
  const hoursInt = parseInt(hours, 10)
  const minutesInt = parseInt(minutes, 10)
  // Calculate the total minutes
  let totalMinutes = hoursInt * 60 + minutesInt
  // Adjust for AM/PM
  if (ampm.toLowerCase() === 'pm' && hoursInt !== 12) {
    totalMinutes += 12 * 60 // Add 12 hours in minutes for PM
  } else if (ampm.toLowerCase() === 'am' && hoursInt === 12) {
    totalMinutes -= 12 * 60 // Subtract 12 hours in minutes for 12:00 AM
  }
  return totalMinutes
}

function removeUndefinedValuesFromObject(obj: TsInterface_UnspecifiedObject) {
  const cleanedObj: TsInterface_UnspecifiedObject = {}
  for (const key in obj) {
    if (obj[key] !== undefined) {
      cleanedObj[key] = obj[key]
    }
  }
  return cleanedObj
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()

  // Hooks - useContext, useState, useReducer, other
  const [us_activeGaragesList, us_setActiveGaragesList] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activeVehicleList, us_setActiveVehicleList] = useState<TsInterface_UnspecifiedObject>({})
  const [us_analysisTableData, us_setAnalysisTableData] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_analysisTableTotals, us_setAnalysisTableTotals] = useState<TsInterface_UnspecifiedObject>({})
  const [us_buildingAnalysis, us_setBuildingAnalysis] = useState<boolean>(false)
  const [us_scheduledWorkMiles, us_setScheduledWorkMiles] = useState<TsInterface_UnspecifiedObject>({})
  const [us_personalCommuteMiles, us_setPersonalCommuteMiles] = useState<TsInterface_UnspecifiedObject>({})
  const [us_additionalMileRequests, us_setAdditionalMileRequests] = useState<TsInterface_UnspecifiedObject>({})
  const [us_downloadingReport, us_setDownloadingReport] = useState<boolean>(false)
  const [us_endDownloadDate, us_setEndDownloadDate] = useState<Date | null>(null)
  const [us_fuelAnalysisEndDate, us_setFuelAnalysisEndDate] = useState<Date>(new Date())
  const [us_fuelAnalysisResults, us_setFuelAnalysisResults] = useState<TsInterface_UnspecifiedObject>({})
  const [us_fuelAnalysisStartDate, us_setFuelAnalysisStartDate] = useState<Date>(
    new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7),
  )
  const [us_fuelLogDriverPins, us_setFuelLogDriverPins] = useState<TsInterface_UnspecifiedObject>({})
  const [us_initialFuelDatesSet, us_setInitialFuelDatesSet] = useState<boolean>(false)
  const [us_mileageAllocationTableData, us_setMileageAllocationTableData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_odometerAnalysisEndDate, us_setOdometerAnalysisEndDate] = useState<Date>(new Date())
  const [us_odometerAnalysisStartDate, us_setOdometerAnalysisStartDate] = useState<Date>(
    new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7),
  )
  const [us_selectedDate, us_setSelectedDate] = useState<Date>(new Date())
  const [us_selectedWeekDateKeys, us_setSelectedWeekDateKeys] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedWeekDates, us_setSelectedWeekDates] = useState<TsInterface_UnspecifiedObject>({})
  const [us_startDownloadDate, us_setStartDownloadDate] = useState<Date | null>(null)
  const [us_uploadingData, us_setUploadingData] = useState<boolean>(false)
  const [us_usersWithGaragesList, us_setUsersWithGaragesList] = useState<TsInterface_UnspecifiedObject>({})
  const [us_visibleMileageTable, us_setVisibleMileageTable] = useState<string>('full')
  const [us_weekMileageAnalysisDriverResults, us_setWeekMileageAnalysisDriverResults] = useState<TsInterface_UnspecifiedObject>({})
  const [us_weekMileageAnalysisGeocodedDistances, us_setWeekMileageAnalysisGeocodedDistances] = useState<TsInterface_UnspecifiedObject>({})
  const [us_weekMileageAnalysisResults, us_setWeekMileageAnalysisResults] = useState<TsInterface_UnspecifiedObject>({})
  const [us_weekMileageAnalysisRootResults, us_setWeekMileageAnalysisRootResults] = useState<TsInterface_UnspecifiedObject>({})
  const [us_weekOdometerAnalysisResults, us_setWeekOdometerAnalysisResults] = useState<TsInterface_UnspecifiedObject>({})
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Vehicle Usage', false) as string
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    // Generate Dates
    let dateKey = us_selectedDate.getFullYear().toString() + '-' + (us_selectedDate.getMonth() + 1).toString() + '-' + us_selectedDate.getDate().toString()
    let utcDate = new Date(dateKey as string)
    let selectedDate = new Date(utcDate.getTime() + Math.abs(utcDate.getTimezoneOffset() * 60000))
    let dayOfWeek = selectedDate.getDay()
    let weekStartMondayAt12am = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() - dayOfWeek + 1)
    let weekEndMondayAt12am = new Date(weekStartMondayAt12am.getFullYear(), weekStartMondayAt12am.getMonth(), weekStartMondayAt12am.getDate() + 7)
    us_setSelectedWeekDates({
      mon: new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() - dayOfWeek + 1),
      tue: new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() - dayOfWeek + 2),
      wed: new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() - dayOfWeek + 3),
      thu: new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() - dayOfWeek + 4),
      fri: new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() - dayOfWeek + 5),
      sat: new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() - dayOfWeek + 6),
      sun: new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() - dayOfWeek + 7),
    })
    us_setSelectedWeekDateKeys({
      [returnFormattedDateKey(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() - dayOfWeek + 1))]: true,
      [returnFormattedDateKey(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() - dayOfWeek + 2))]: true,
      [returnFormattedDateKey(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() - dayOfWeek + 3))]: true,
      [returnFormattedDateKey(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() - dayOfWeek + 4))]: true,
      [returnFormattedDateKey(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() - dayOfWeek + 5))]: true,
      [returnFormattedDateKey(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() - dayOfWeek + 6))]: true,
      [returnFormattedDateKey(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() - dayOfWeek + 7))]: true,
    })
    us_setStartDownloadDate(weekStartMondayAt12am)
    us_setEndDownloadDate(weekEndMondayAt12am)
    if (us_initialFuelDatesSet === false) {
      us_setFuelAnalysisStartDate(weekStartMondayAt12am)
      us_setFuelAnalysisEndDate(weekEndMondayAt12am)
      us_setOdometerAnalysisStartDate(weekStartMondayAt12am)
      us_setOdometerAnalysisEndDate(weekEndMondayAt12am)
      us_setInitialFuelDatesSet(true)
    }
  }, [us_selectedDate])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveVehicleList(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveVehicles_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setUsersWithGaragesList(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_UsersWithVehicleGarages_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveGaragesList(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveVehicleMileageGarages_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    let analysisTablOverallTotals: TsInterface_UnspecifiedObject = {
      total_actual_miles: 0,
      total_scheduled_miles: 0,
    }
    let analysisTableDataObject: TsInterface_UnspecifiedObject = {}
    let mileageAllocationTableData: TsInterface_UnspecifiedObject = {}

    // Loop through active vehicles to determine which users have vehicle assignments
    for (let loopVehicleKey in us_activeVehicleList) {
      let loopVehicle = us_activeVehicleList[loopVehicleKey]
      // Allocation Table Data Generation
      if (loopVehicle != null && loopVehicle.associated_user_key != null && loopVehicle.associated_user_name != null) {
        mileageAllocationTableData[loopVehicle.associated_user_key] = {
          key: loopVehicle.associated_user_key,
          name: loopVehicle.associated_user_name,
        }
      }
      if (loopVehicle != null && loopVehicle.associated_user_key != null && loopVehicle.associated_user_name != null) {
        let userWithGarage = getProp(us_usersWithGaragesList, loopVehicle.associated_user_key, {})
        // Analysis Table Data Generation
        analysisTableDataObject[loopVehicle.associated_user_key] = {
          // Same properties as before...
          associated_garage_key: getProp(userWithGarage, 'associated_garage_key', null),
          associated_garage_name: getProp(userWithGarage, 'associated_garage_name', null),
          exists_in_database: true,
          key: loopVehicle.associated_user_key,
          name: loopVehicle.associated_user_name,
        }
      }
    }

    if (us_weekMileageAnalysisResults != null && us_weekMileageAnalysisResults.drivers != null) {
      for (let loopDriverKey in us_weekMileageAnalysisResults.drivers) {
        let loopDriver = us_weekMileageAnalysisResults.drivers[loopDriverKey]
        if (analysisTableDataObject != null && analysisTableDataObject[loopDriverKey] != null) {
          analysisTableDataObject[loopDriverKey] = {
            ...analysisTableDataObject[loopDriverKey],
            actual_logs: getProp(loopDriver, 'actual_logs', {}),
            actual_miles: getProp(loopDriver, 'actual_miles', {}),
            events: getProp(loopDriver, 'events', {}),
            garage_latitude: getProp(loopDriver, 'garage_latitude', null),
            garage_longitude: getProp(loopDriver, 'garage_longitude', null),
            scheduled_mile_errors: getProp(loopDriver, 'scheduled_mile_errors', {}),
            scheduled_miles: getProp(loopDriver, 'scheduled_miles', {}),
            total_actual_miles: getProp(loopDriver, 'total_actual_miles', 0),
            total_scheduled_miles: getProp(loopDriver, 'total_scheduled_miles', null),
            total_actual_minus_scheduled_miles: getProp(loopDriver, 'total_actual_minus_scheduled_miles', null),
            TEMP_total_actual_minus_scheduled_miles_adjusted: getProp(loopDriver, 'total_actual_minus_scheduled_miles', null),
            TEMP_total_scheduled_miles_adjusted: getProp(loopDriver, 'total_scheduled_miles', null),
            vins: getProp(loopDriver, 'vins', {}),
          }
        } else {
          analysisTableDataObject[loopDriverKey] = {
            actual_logs: getProp(loopDriver, 'actual_logs', {}),
            actual_miles: getProp(loopDriver, 'actual_miles', {}),
            associated_garage_key: null,
            associated_garage_name: null,
            events: getProp(loopDriver, 'events', {}),
            exists_in_database: false,
            garage_latitude: getProp(loopDriver, 'garage_latitude', null),
            garage_longitude: getProp(loopDriver, 'garage_longitude', null),
            key: loopDriverKey,
            name: getProp(loopDriver, 'name', null),
            scheduled_mile_errors: getProp(loopDriver, 'scheduled_mile_errors', {}),
            scheduled_miles: getProp(loopDriver, 'scheduled_miles', {}),
            total_actual_miles: getProp(loopDriver, 'total_actual_miles', 0),
            total_scheduled_miles: getProp(loopDriver, 'total_scheduled_miles', null),
            total_actual_minus_scheduled_miles: getProp(loopDriver, 'total_actual_minus_scheduled_miles', null),
            TEMP_total_actual_minus_scheduled_miles_adjusted: getProp(loopDriver, 'total_actual_minus_scheduled_miles', null),
            TEMP_total_scheduled_miles_adjusted: getProp(loopDriver, 'total_scheduled_miles', null),
            vins: getProp(loopDriver, 'vins', {}),
          }
        }
        analysisTablOverallTotals['total_actual_miles'] += getProp(loopDriver, 'total_actual_miles', 0)
        analysisTablOverallTotals['total_scheduled_miles'] += getProp(loopDriver, 'total_scheduled_miles', 0)
      }
    }

    // Handle Additional Miles
    for (let loopDriverKey in analysisTableDataObject) {
      // Handle scheduled work miles
      if (
        us_scheduledWorkMiles != null &&
        us_scheduledWorkMiles[loopDriverKey] != null &&
        us_scheduledWorkMiles[loopDriverKey]['scheduled_work_miles'] != null
      ) {
        analysisTableDataObject[loopDriverKey]['scheduled_work_miles'] = us_scheduledWorkMiles[loopDriverKey]['scheduled_work_miles']
        if (analysisTableDataObject[loopDriverKey]['TEMP_total_actual_minus_scheduled_miles_adjusted'] != null) {
          analysisTableDataObject[loopDriverKey]['TEMP_total_actual_minus_scheduled_miles_adjusted'] -=
            analysisTableDataObject[loopDriverKey]['scheduled_work_miles']
        }
        if (analysisTableDataObject[loopDriverKey]['TEMP_total_scheduled_miles_adjusted'] != null) {
          analysisTableDataObject[loopDriverKey]['TEMP_total_scheduled_miles_adjusted'] += analysisTableDataObject[loopDriverKey]['scheduled_work_miles']
        }
      }

      // Handle personal commute miles
      if (
        us_personalCommuteMiles != null &&
        us_personalCommuteMiles[loopDriverKey] != null &&
        us_personalCommuteMiles[loopDriverKey]['personal_commute_miles'] != null
      ) {
        analysisTableDataObject[loopDriverKey]['personal_commute_miles'] = us_personalCommuteMiles[loopDriverKey]['personal_commute_miles']
        if (analysisTableDataObject[loopDriverKey]['TEMP_total_actual_minus_scheduled_miles_adjusted'] != null) {
          analysisTableDataObject[loopDriverKey]['TEMP_total_actual_minus_scheduled_miles_adjusted'] -=
            analysisTableDataObject[loopDriverKey]['personal_commute_miles']
        }
        if (analysisTableDataObject[loopDriverKey]['TEMP_total_scheduled_miles_adjusted'] != null) {
          analysisTableDataObject[loopDriverKey]['TEMP_total_scheduled_miles_adjusted'] += analysisTableDataObject[loopDriverKey]['personal_commute_miles']
        }
      }

      // Handle additional mile requests (formerly override)
      if (
        us_startDownloadDate != null &&
        us_additionalMileRequests != null &&
        us_additionalMileRequests[returnFormattedDateKey(us_startDownloadDate) + '_' + loopDriverKey] != null &&
        us_additionalMileRequests[returnFormattedDateKey(us_startDownloadDate) + '_' + loopDriverKey]['additional_miles'] != null
      ) {
        analysisTableDataObject[loopDriverKey]['additional_requested_miles'] =
          us_additionalMileRequests[returnFormattedDateKey(us_startDownloadDate) + '_' + loopDriverKey]['additional_miles']
        if (analysisTableDataObject[loopDriverKey]['TEMP_total_actual_minus_scheduled_miles_adjusted'] != null) {
          analysisTableDataObject[loopDriverKey]['TEMP_total_actual_minus_scheduled_miles_adjusted'] -=
            analysisTableDataObject[loopDriverKey]['additional_requested_miles']
        }
        if (analysisTableDataObject[loopDriverKey]['TEMP_total_scheduled_miles_adjusted'] != null) {
          analysisTableDataObject[loopDriverKey]['TEMP_total_scheduled_miles_adjusted'] += analysisTableDataObject[loopDriverKey]['additional_requested_miles']
        }
      }
    }

    let sortedData = sortItemsByNumericProp(analysisTableDataObject, 'TEMP_total_actual_minus_scheduled_miles_adjusted')
    us_setAnalysisTableData(objectToArray(sortedData))
    us_setAnalysisTableTotals(analysisTablOverallTotals)
    us_setMileageAllocationTableData(mileageAllocationTableData)
    return () => {}
  }, [
    us_activeVehicleList,
    us_scheduledWorkMiles,
    us_personalCommuteMiles,
    us_additionalMileRequests,
    us_weekMileageAnalysisResults,
    us_startDownloadDate,
    us_usersWithGaragesList,
  ])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setWeekMileageAnalysisRootResults(newData)
      ur_forceRerender()
    }
    if (us_startDownloadDate != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(
            DatabaseRef_VehicleMileageLogs_Analysis_Document(res_GCK.clientKey, returnFormattedDateKey(us_startDownloadDate)),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, us_startDownloadDate])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setWeekMileageAnalysisDriverResults(newData)
      ur_forceRerender()
    }
    if (us_startDownloadDate != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_VehicleMileageLogs_Analysis_Drivers_Collection(res_GCK.clientKey, returnFormattedDateKey(us_startDownloadDate)),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, us_startDownloadDate])

  useEffect(() => {
    if (us_weekMileageAnalysisRootResults != null) {
      let copyOfMileageAnalysis = cloneObjectWithoutReference(us_weekMileageAnalysisRootResults)
      copyOfMileageAnalysis.drivers = us_weekMileageAnalysisDriverResults
      us_setWeekMileageAnalysisResults(copyOfMileageAnalysis)
    }
    return () => {}
  }, [us_weekMileageAnalysisRootResults, us_weekMileageAnalysisDriverResults])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setWeekMileageAnalysisGeocodedDistances(newData)
      ur_forceRerender()
    }
    if (us_startDownloadDate != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(
            DatabaseRef_VehicleMileageLogs_Analysis_GeocodedDistances_Document(res_GCK.clientKey, returnFormattedDateKey(us_startDownloadDate)),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, us_startDownloadDate])

  // Get scheduled work miles data
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setScheduledWorkMiles(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ScheduledWorkMiles_Collection(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  // Get personal commute miles data
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setPersonalCommuteMiles(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_PersonalCommuteMiles_Collection(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  // Get additional mile requests data
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAdditionalMileRequests(newData)
      ur_forceRerender()
    }
    if (us_startDownloadDate != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_AdditionalMileRequests_SpecificDate_Query(res_GCK.clientKey, returnFormattedDateKey(us_startDownloadDate)),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, us_startDownloadDate])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setFuelLogDriverPins(newData)
      ur_forceRerender()
    }
    if (us_startDownloadDate != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_FuelLogDriverPin_Collection(res_GCK.clientKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, us_startDownloadDate])

  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  // //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //

  const currentDate = new Date()
  const firstDayOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  const lastDayOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)

  // Set up state for selected start and end dates
  const [us_selectedStartDate, us_setSelectedStartDate] = useState<Date>(firstDayOfCurrentMonth)

  const [us_combinedVehicles, us_setCombinedVehicles] = useState<any>({})
  const [us_combinedTransactions, us_setCombinedTransactions] = useState<any>({})

  const [us_transactions, us_setTransactions] = useState<Stripe.Issuing.Transaction[]>([])

  const [us_userSettings, us_setUserSettings] = useState<TsInterface_UnspecifiedObject>({})

  const [us_transactionsData, us_setTransactionsData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_authorizationsData, us_setAuthorizationData] = useState<TsInterface_UnspecifiedObject>({})

  const [loading, setLoading] = useState(false) // Loading state

  const [us_selectedMonth, setSelectedMonth] = useState(() => {
    const today = new Date()
    return new Date(today.getFullYear(), today.getMonth(), 1)
  })

  const [us_selectedMonthName, setSelectedMonthName] = useState('')

  const [tableData, setTableData] = useState<TsInterface_UnspecifiedObject[]>([])

  const [us_combinedData, us_setCombinedData] = useState<TsInterface_TableDataRow[]>([])

  // Update the month name whenever us_selectedMonth changes
  useEffect(() => {
    const monthName = us_selectedMonth.toLocaleString('default', { month: 'long' })
    const year = us_selectedMonth.getFullYear()
    setSelectedMonthName(`${monthName} ${year}`)
    console.log(`Selected month updated to: ${monthName} ${year}`)
  }, [us_selectedMonth]) // Runs when us_selectedMonth changes

  useEffect(() => {
    let unsubscribeTransactions: TsType_VoidFunction
    let unsubscribeVehicles: TsType_VoidFunction

    const fetchAndCombineData = async () => {
      setLoading(true)
      try {
        const clientKey = await getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res) => res.clientKey)
        console.log(`Fetched clientKey: ${clientKey}`)

        if (!us_selectedMonth) {
          console.warn('Selected month is not set.')
          return
        }

        const selectedMonthKey = `${us_selectedMonth.getFullYear()}-${(us_selectedMonth.getMonth() + 1).toString().padStart(2, '0')}`
        const mondays = getMondaysForMonthBoundary(us_selectedMonth.getFullYear(), us_selectedMonth.getMonth())
        console.log(`Selected Month Key: ${selectedMonthKey}, Mondays: ${mondays}`)

        // Fetch transactions
        unsubscribeTransactions = DatabaseGetLiveDocument(
          DatabaseRef_CachedAuthorizations_Document(clientKey, selectedMonthKey),
          (transactionData: TsInterface_UnspecifiedObject) => {
            console.log('Received transactions data:', transactionData)
            us_setAuthorizationData(transactionData)
          },
        )
        console.log('Subscribed to transactions data.')

        // Fetch vehicles data
        unsubscribeVehicles = DatabaseGetLiveCollection(
          DatabaseRef_CombinedVehiclesByMondaysList_Query(clientKey, mondays),
          (vehiclesData: TsInterface_UnspecifiedObject) => {
            console.log('Received vehicles data structure:', vehiclesData)
            us_setCombinedVehicles(vehiclesData)
          },
        )
        console.log('Subscribed to vehicles data.')
      } catch (error) {
        console.error('Error fetching client data:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchAndCombineData()

    return () => {
      if (typeof unsubscribeTransactions === 'function') {
        unsubscribeTransactions()
        console.log('Unsubscribed from transactions data.')
      }
      if (typeof unsubscribeVehicles === 'function') {
        unsubscribeVehicles()
        console.log('Unsubscribed from vehicles data.')
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_selectedMonth])

  useEffect(() => {
    if (!us_authorizationsData || !us_combinedVehicles || !us_selectedMonth) {
      console.log('Waiting for authorizationsData, combinedVehicles, and selectedMonth to be set.')
      return
    }

    const fetchAndCombineData = async () => {
      setLoading(true)
      try {
        console.log('Combining transactions and vehicles data...')
        const { transformedAggregateData } = await combineTransactionsAndVehiclesData(
          us_authorizationsData,
          us_combinedVehicles,
          uc_RootData_ClientKey as any, // Ensure clientKey is passed
          undefined, // No userKey for aggregated view
          us_selectedMonth,
        )
        console.log('Combined data:', transformedAggregateData)
        us_setCombinedData(transformedAggregateData || [])
      } catch (error) {
        console.error('Error combining transactions and vehicles data:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchAndCombineData()
  }, [us_authorizationsData, us_combinedVehicles, us_selectedMonth, uc_RootData_ClientKey])

  useEffect(() => {
    if (!us_combinedData || us_combinedData.length === 0) {
      console.log('No combined data available to transform into table rows.')
      return
    }

    // Transform combined data into table rows
    const transformedData: TsInterface_TableDataRow[] = us_combinedData.map((userData: Record<string, any>) => {
      const { userKey, userName, totalExpectedMiles, totalMilesDriven, totalExpectedSpend, totalActualSpend } = userData

      const differenceSpent = (parseFloat(totalActualSpend) - parseFloat(totalExpectedSpend)).toFixed(2)
      const differenceMiles = (parseFloat(totalMilesDriven) - parseFloat(totalExpectedMiles)).toFixed(2)

      return {
        userKey: userKey ?? 'Unknown',
        userName: userName ?? 'Unknown',
        totalExpectedSpent: parseFloat(totalExpectedSpend),
        formattedTotalSpent: parseFloat(totalActualSpend),
        differenceSpent: parseFloat(differenceSpent),
        totalExpectedMiles: parseFloat(totalExpectedMiles),
        actualMilesDriven: parseFloat(totalMilesDriven),
        differenceMiles: parseFloat(differenceMiles),
      }
    })

    console.log('Transformed table data:', transformedData)
    setTableData(transformedData)
  }, [us_combinedData])

  // useEffect(() => {
  //   // Define the start and end of November
  //   const novemberStart = new Date('2024-11-01T00:00:00Z')
  //   const novemberEnd = new Date('2024-11-30T23:59:59Z')

  //   cloudFunctionManageRequest('manageFinances', {
  //     function: 'fetchTransactions',
  //     livemode: true,
  //     options: {
  //       created: {
  //         gte: Math.floor(novemberStart.getTime() / 1000), // Convert to Unix timestamp
  //         lte: Math.floor(novemberEnd.getTime() / 1000), // Convert to Unix timestamp
  //       },
  //       limit: 100, // Adjust limit if needed
  //     },
  //   })
  //     .then((res_CFMMR: any) => {
  //       const transactions = ((res_CFMMR as any)?.transactions as Stripe.Issuing.Transaction[]) || []
  //       us_setTransactions(transactions) // Assuming this is your state setter
  //     })
  //     .catch((rej_CFMMR) => {
  //       console.error('Error fetching November transactions:', rej_CFMMR)
  //     })
  // }, [])

  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  // //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  // //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  // //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //

  // Functions
  const sortItemsByNumericProp = (data: TsInterface_UnspecifiedObject, sortProp: string) => {
    const dataArray = Object.values(data)
    return dataArray.sort((a, b) => {
      const aValue = a[sortProp]
      const bValue = b[sortProp]
      // Both aValue and bValue exist and are numbers
      if (aValue !== undefined && bValue !== undefined) {
        return bValue - aValue
      }
      // Only aValue is undefined or null
      if (aValue === undefined || aValue === null) {
        return 1
      }
      // Only bValue is undefined or null
      if (bValue === undefined || bValue === null) {
        return -1
      }
      // Both are undefined or null
      return 0
    })
  }

  // const uploadMileageReportProper = (clientKey: string, formattedData: TsInterface_UnspecifiedObject[], startingIndex: number): void => {
  // let batchLimit = 500
  // let timeoutMilliseconds = 1500
  // let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
  // for (let loopIndex = startingIndex; loopIndex < startingIndex + batchLimit; loopIndex++) {
  //   if (formattedData[loopIndex] != null) {
  //     updateArray.push({
  //       type: 'setMerge',
  //       ref: DatabaseRef_VehicleMileageLogs_Document(clientKey, formattedData[loopIndex].key),
  //       data: formattedData[loopIndex],
  //     })
  //   }
  // }
  // if (formattedData.length > startingIndex + batchLimit) {
  //   DatabaseBatchUpdate(updateArray)
  //     .then((res_DBU) => {
  //       setTimeout(() => {
  //         uploadMileageReportProper(clientKey, formattedData, startingIndex + batchLimit)
  //       }, timeoutMilliseconds)
  //     })
  //     .catch((rej_DBU) => {
  //       us_setUploadingData(false)
  //       uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
  //     })
  // } else {
  //   DatabaseBatchUpdate(updateArray)
  //     .then((res_DBU) => {
  //       us_setUploadingData(false)
  //     })
  //     .catch((rej_DBU) => {
  //       us_setUploadingData(false)
  //       uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
  //     })
  // }
  // }

  const changeCalendarDate = (newDate: Date): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      us_setSelectedDate(new Date(newDate))
      ur_forceRerender()
      resolve({ success: true })
    })
  }

  const tableDatabaseEndpoint_MileageLogs = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_start', desc: true }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(
      DatabaseRef_VehicleMileageLogs_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const tableDatabaseEndpoint_FuelLogs = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_transaction', desc: true }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(
      DatabaseRef_VehicleFuelLogs_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const tableDatabaseEndpoint_ActiveGarages = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    // let queryOperatorsArray: TsInterface_QueryOperatorsArray = [ { prop: "status", comparator: "==", value: "active" } ]
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: true }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(
      DatabaseRef_VehicleMileageGarages_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const createGarage = (): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: formInputs_GarageName,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  formSubmittedData.status = 'active'
                  DatabaseAddDocument(DatabaseRef_VehicleMileageGarages_Collection(res_GCK.clientKey), formSubmittedData, true)
                    .then((res_DSMD) => {
                      resolve({ success: true })
                    })
                    .catch((rej_DSMD) => {
                      reject(rej_DSMD)
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    })
                })
                .catch((rej_GCK) => {
                  reject(rej_GCK)
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('Create Garage')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  // const uploadMileageReport = (data: string[][]): void => {
  // // Instantiate variables
  // let formattedData: TsInterface_UnspecifiedObject = {}
  // let uploadedFileColumns: string[] = []
  // us_setUploadingData(true)
  // // let dataMapping: string[] = []
  // // Loop through and format data
  // for (let cellIndex = 0; cellIndex < data[0].length; cellIndex++) {
  //   uploadedFileColumns[cellIndex] = data[0][cellIndex]
  // }
  // for (let rowIndex = 1; rowIndex < data.length; rowIndex++) {
  //   let rawStartTime = null
  //   if (data != null && data[rowIndex] != null) {
  //     let loopLog = data[rowIndex]
  //     let formattedDataRow: TsInterface_UnspecifiedObject = {}
  //     let mappedLoopLog: TsInterface_UnspecifiedObject = {}
  //     for (let cellIndex = 0; cellIndex < loopLog.length; cellIndex++) {
  //       if (loopLog != null && loopLog[cellIndex] != null && uploadedFileColumns != null && uploadedFileColumns[cellIndex] != null) {
  //         mappedLoopLog[uploadedFileColumns[cellIndex]] = loopLog[cellIndex]
  //       }
  //     }
  //     for (let cellIndex = 0; cellIndex < loopLog.length; cellIndex++) {
  //       if (loopLog[cellIndex] != null && uploadedFileColumns[cellIndex]) {
  //         if (
  //           loopLog != null &&
  //           loopLog[cellIndex] != null &&
  //           uploadedFileColumns != null &&
  //           uploadedFileColumns[cellIndex] != null &&
  //           mileageImportFormatter != null &&
  //           mileageImportFormatter[uploadedFileColumns[cellIndex]] != null
  //         ) {
  //           let originalCellValue = loopLog[cellIndex]
  //           let columnHeader = uploadedFileColumns[cellIndex]
  //           let formattedCellData = mileageImportFormatter[columnHeader](mappedLoopLog, originalCellValue)
  //           if (formattedCellData != null && formattedCellData.key != null && formattedCellData.value != null) {
  //             formattedDataRow[formattedCellData.key] = formattedCellData.value
  //           }
  //           if (formattedCellData != null && formattedCellData.key === 'timestamp_start') {
  //             rawStartTime = originalCellValue
  //           }
  //         }
  //       }
  //     }
  //     if (
  //       formattedDataRow != null &&
  //       formattedDataRow.timestamp_start != null &&
  //       formattedDataRow.associated_event != null &&
  //       formattedDataRow.vin != null &&
  //       rawStartTime != null
  //     ) {
  //       // eslint-disable-next-line no-useless-escape
  //       let dataRowKey =
  //         formattedDataRow.vin + '-' + rawStartTime.replace(/:/g, '_').replace(/\//g, '_').replace(/ /g, '_') + '-' + formattedDataRow.associated_event
  //       formattedDataRow.key = dataRowKey
  //       formattedDataRow.status = 'active'
  //       formattedData[dataRowKey] = formattedDataRow
  //     }
  //   }
  // }
  // getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
  //   .then((res_GCK) => {
  //     uploadMileageReportProper(res_GCK.clientKey, objectToArray(formattedData), 0)
  //   })
  //   .catch((rej_GCK) => {
  //     uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
  //   })
  // }

  const downloadVehicleRawLogsReport = (): void => {
    us_setDownloadingReport(true)
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetCollection(DatabaseRef_VehicleMileageLogs_Query(res_GCK.clientKey, us_startDownloadDate as Date, us_endDownloadDate as Date))
          .then((res_DGC) => {
            let filteredAndSortedLogData: TsInterface_UnspecifiedObject[] = []
            filteredAndSortedLogData = sortByTwoProperties(objectToArray(res_DGC.data), 'associated_driver_name', 'timestamp_start')
            let downloadData: string[][] = []
            let dataRow: string[] = []
            for (let loopHeaderKey in mileageExportFormatter) {
              let loopHeader = mileageExportFormatter[loopHeaderKey]
              dataRow.push(loopHeader.header)
            }
            downloadData.push(dataRow)
            for (let loopLogKey in filteredAndSortedLogData) {
              let loopLog = filteredAndSortedLogData[loopLogKey]
              dataRow = []
              for (let loopHeaderKey in mileageExportFormatter) {
                let loopHeader = mileageExportFormatter[loopHeaderKey]
                dataRow.push(loopHeader.returnCellData(loopLog))
              }
              downloadData.push(dataRow)
            }
            // Download
            downloadCSV('Mileage Logs', downloadData)
              .then((res_DCSV) => {
                us_setDownloadingReport(false)
              })
              .catch((rej_DCSV) => {
                us_setDownloadingReport(false)
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DCSV.error })
              })
          })
          .catch((rej_DGC) => {
            us_setDownloadingReport(false)
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DGC.error })
          })
      })
      .catch((rej_GCK) => {
        us_setDownloadingReport(false)
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  }

  const calculateDistanceBetweenTwoPoints = (combinedCoordinatesKey: string, lat1: number, lng1: number, lat2: number, lng2: number): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      let origin = new google.maps.LatLng(lat1, lng1)
      let destination = new google.maps.LatLng(lat2, lng2)
      let service = new google.maps.DistanceMatrixService()
      service.getDistanceMatrix(
        {
          origins: [origin],
          destinations: [destination],
          travelMode: google.maps.TravelMode.DRIVING,
          unitSystem: google.maps.UnitSystem.IMPERIAL,
          avoidHighways: false,
          avoidTolls: false,
        },
        (response, status) => {
          if (
            status === 'OK' &&
            response != null &&
            response.rows != null &&
            response.rows[0] != null &&
            response.rows[0].elements != null &&
            response.rows[0].elements[0] != null &&
            response.rows[0].elements[0].distance != null &&
            response.rows[0].elements[0].distance.value != null
          ) {
            resolve({
              success: true,
              combinedCoordinatesKey: combinedCoordinatesKey,
              combinedCoordinateData: {
                lat1: lat1,
                lng1: lng1,
                lat2: lat2,
                lng2: lng2,
                miles: response.rows[0].elements[0].distance.value / 1609.344,
              },
            })
          } else {
            reject({
              success: false,
              error: {
                message: rLIB('Failed to calculate miles'),
                details: rLIB('An unknown error occurred'),
                code: 'ER-D-VMR-CDBTP-01',
              },
            })
          }
        },
      )
    })
  }

  const convertAddressToLatLng = (gasStationAddressKey: string, address: string, delayMilliseconds: number): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        let geocoder = new google.maps.Geocoder()
        geocoder.geocode({ address: address }, function (results: any, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            let location = results[0].geometry.location
            let latLng = {
              lat: location.lat(),
              lng: location.lng(),
            }
            resolve({
              success: true,
              key: gasStationAddressKey,
              lat: latLng.lat,
              lng: latLng.lng,
            })
          } else {
            reject({
              success: false,
              error: {
                message: rLIB('Failed to calculate coordinates'),
                details: status,
                code: 'ER-D-VMR-CATLL-01',
              },
            })
          }
        })
      }, delayMilliseconds)
    })
  }

  const geocodeMileagesForAnalysis = (allAddressedUsedInAnalysis: TsInterface_UnspecifiedObject): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      // Instantiate Variables
      let promiseArray: TsType_UnknownPromise[] = []
      let combinedGeocodingResults: TsInterface_UnspecifiedObject = {}
      // Check against database record to see what still needs to be geocoded
      let geocodeCount = 0
      for (let loopCombinedCoordinatesKey in allAddressedUsedInAnalysis) {
        let loopCombinedCoordinates = allAddressedUsedInAnalysis[loopCombinedCoordinatesKey]
        if (
          us_weekMileageAnalysisGeocodedDistances != null &&
          us_weekMileageAnalysisGeocodedDistances[loopCombinedCoordinatesKey] != null &&
          us_weekMileageAnalysisGeocodedDistances[loopCombinedCoordinatesKey]['lat1'] != null &&
          us_weekMileageAnalysisGeocodedDistances[loopCombinedCoordinatesKey]['lng1'] != null &&
          us_weekMileageAnalysisGeocodedDistances[loopCombinedCoordinatesKey]['lat2'] != null &&
          us_weekMileageAnalysisGeocodedDistances[loopCombinedCoordinatesKey]['lng2'] != null &&
          us_weekMileageAnalysisGeocodedDistances[loopCombinedCoordinatesKey]['miles'] != null
        ) {
          combinedGeocodingResults[loopCombinedCoordinatesKey] = us_weekMileageAnalysisGeocodedDistances[loopCombinedCoordinatesKey]
        } else {
          geocodeCount++
          promiseArray.push(
            calculateDistanceBetweenTwoPoints(
              loopCombinedCoordinatesKey,
              loopCombinedCoordinates.lat1,
              loopCombinedCoordinates.lng1,
              loopCombinedCoordinates.lat2,
              loopCombinedCoordinates.lng2,
            )
              .then((res_CDBTP: any) => {
                combinedGeocodingResults[res_CDBTP.combinedCoordinatesKey] = res_CDBTP.combinedCoordinateData
              })
              .catch((rej_CDBTP) => {
                console.error(rej_CDBTP)
              }),
          )
        }
      }
      // Geocode remaining records
      Promise.all(promiseArray).finally(() => {
        // Return Mileages to be saved in batch update in mileage analysis
        resolve({ success: true, combinedGeocodingResults: combinedGeocodingResults })
      })
    })
  }

  const findUserKeyByDate = (targetDate: Date, arr: TsInterface_UnspecifiedObject[]) => {
    // First, sort the array by the timestamp_start
    arr.sort((a, b) => a.timestamp_start - b.timestamp_start)
    // Now, iterate over the sorted array to find the target interval
    for (let i = 0; i < arr.length; i++) {
      if (i === arr.length - 1) {
        // if it's the last item and targetDate is after its timestamp
        if (targetDate > arr[i].timestamp_start) {
          return arr[i].associated_user_key
        }
      } else if (targetDate > arr[i].timestamp_start && targetDate < arr[i + 1].timestamp_start) {
        return arr[i].associated_user_key
      }
    }
    return null // Return null if not found
  }

  const runMileageAnalysis = (): void => {
    if (us_startDownloadDate != null && us_endDownloadDate != null) {
      // Instantiate Variables
      us_setBuildingAnalysis(true)
      let promiseArray1: TsType_UnknownPromise[] = []
      let promiseArray2: TsType_UnknownPromise[] = []
      let vehicleMileageLogs: TsInterface_UnspecifiedObject = {}
      let activeVehicles: TsInterface_UnspecifiedObject = {}
      let previousCustodyLogs: TsInterface_UnspecifiedObject = {}
      let dateRangeCustodyLogs: TsInterface_UnspecifiedObject = {}
      // Get Data
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          promiseArray1.push(
            DatabaseGetCollection(DatabaseRef_VehicleMileageLogs_Query(res_GCK.clientKey, us_startDownloadDate as Date, us_endDownloadDate as Date))
              .then((res_DGC) => {
                vehicleMileageLogs = res_DGC.data
              })
              .catch((rej_DGC) => {
                console.error(rej_DGC)
              }),
          )
          promiseArray1.push(
            DatabaseGetCollection(DatabaseRef_ActiveVehicles_Query(res_GCK.clientKey))
              .then((res_DGC) => {
                activeVehicles = res_DGC.data
              })
              .catch((rej_DGC) => {
                console.error(rej_DGC)
              }),
          )
          promiseArray1.push(
            DatabaseGetCollection(
              DatabaseRef_VehicleAssignmentHistory_DateRange_Query(res_GCK.clientKey, us_startDownloadDate as Date, us_endDownloadDate as Date),
            )
              .then((res_DGC) => {
                dateRangeCustodyLogs = res_DGC.data
              })
              .catch((rej_DGC) => {
                console.error(rej_DGC)
              }),
          )
          // Instantiate Variables
          let taskData: TsInterface_UnspecifiedObject = {}
          let cancelledTaskData: TsInterface_UnspecifiedObject = {}
          let sunToSatdateKeyArray: string[] = []
          let validWeekDaysObject: TsInterface_UnspecifiedObject = {}
          if (us_startDownloadDate != null && us_endDownloadDate != null) {
            // Generate Date Queries
            let totalDaysAdded = 0
            for (let loopTime = us_startDownloadDate.getTime(); loopTime <= us_endDownloadDate.getTime() + 3600000; loopTime += 86400000) {
              let loopDate = new Date(loopTime)
              let loopDateKey = formatDateToYYYYMMDD(loopDate)
              sunToSatdateKeyArray.push(loopDateKey)
              if (totalDaysAdded < 7) {
                validWeekDaysObject[loopDateKey] = true
              }
              totalDaysAdded++
            }
            let sunToSatDateKeySplitArrays: string[][] = []
            for (let loopIndex = 0; loopIndex < sunToSatdateKeyArray.length; loopIndex += 10) {
              sunToSatDateKeySplitArrays.push(sunToSatdateKeyArray.slice(loopIndex, loopIndex + 10))
            }
            for (let loopIndex in sunToSatDateKeySplitArrays) {
              let loopDateKeyArray = sunToSatDateKeySplitArrays[loopIndex]
              promiseArray1.push(
                DatabaseGetCollection(DatabaseRef_ScheduledTasksInDateRangeArray_Query(res_GCK.clientKey, loopDateKeyArray))
                  .then((res_GCC) => {
                    for (let loopKey in getProp(res_GCC, 'data', {})) {
                      taskData[loopKey] = res_GCC.data[loopKey]
                    }
                  })
                  .catch((rej_GCC) => {
                    console.error(rej_GCC)
                  }),
              )
              promiseArray1.push(
                DatabaseGetCollection(DatabaseRef_CancelledScheduledTasksInDateRangeArray_Query(res_GCK.clientKey, loopDateKeyArray))
                  .then((res_GCC) => {
                    for (let loopKey in getProp(res_GCC, 'data', {})) {
                      cancelledTaskData[loopKey] = res_GCC.data[loopKey]
                    }
                  })
                  .catch((rej_GCC) => {
                    console.error(rej_GCC)
                  }),
              )
            }
            // After Data Loaded
            Promise.all(promiseArray1).finally(() => {
              // Generate list of users assigned to trucks
              let userVehicleAssignments: TsInterface_UnspecifiedObject = {}
              // let vehicleCalendarEvents: TsInterface_UnspecifiedObject = {}
              for (let loopVehicleKey in activeVehicles) {
                let loopVehicle = activeVehicles[loopVehicleKey]
                if (loopVehicle.associated_user_key != null) {
                  userVehicleAssignments[loopVehicle.associated_user_key] = {
                    associated_user_key: loopVehicle.associated_user_key,
                    associated_vehicle_key: loopVehicleKey,
                    vin: getProp(loopVehicle, 'vin', null),
                    associated_user_name: getProp(loopVehicle, 'associated_user_name', null),
                  }
                }
                promiseArray2.push(
                  DatabaseGetCollection(
                    DatabaseRef_VehicleAssignmentHistory_VehiclePreviousRecord_Query(res_GCK.clientKey, us_startDownloadDate as Date, loopVehicleKey),
                  )
                    .then((res_GCC) => {
                      previousCustodyLogs[loopVehicleKey] = res_GCC.data
                    })
                    .catch((rej_GCC) => {
                      console.error(rej_GCC)
                    }),
                )
              }
              Promise.all(promiseArray2).finally(() => {
                // Make Vehicle Keys searchable by VIN
                let vinToVehicleKey: TsInterface_UnspecifiedObject = {}
                for (let loopVehicleKey in activeVehicles) {
                  let loopVehicle = activeVehicles[loopVehicleKey]
                  if (loopVehicle.vin != null) {
                    vinToVehicleKey[loopVehicle.vin] = loopVehicleKey
                  }
                }
                // Merge Vehicle Assignment History
                let combinedVehicleAssignmentHistoryLogs: TsInterface_UnspecifiedObject = {}
                for (let loopVehicleKey in previousCustodyLogs) {
                  combinedVehicleAssignmentHistoryLogs[loopVehicleKey] = {}
                  for (let loopLogKey in previousCustodyLogs[loopVehicleKey]) {
                    let loopLog = previousCustodyLogs[loopVehicleKey][loopLogKey]
                    combinedVehicleAssignmentHistoryLogs[loopVehicleKey][loopLogKey] = {
                      timestamp_start: returnDateFromUnknownDateFormat(loopLog.timestamp_start),
                      associated_user_key: loopLog.associated_user_key,
                      associated_user_name: loopLog.associated_user_name,
                      associated_vehicle_key: loopLog.associated_vehicle_key,
                    }
                  }
                }
                for (let loopLogKey in dateRangeCustodyLogs) {
                  let loopLog = dateRangeCustodyLogs[loopLogKey]
                  if (
                    loopLog != null &&
                    loopLog.associated_vehicle_key != null &&
                    combinedVehicleAssignmentHistoryLogs[loopLog.associated_vehicle_key] != null
                  ) {
                    combinedVehicleAssignmentHistoryLogs[loopLog.associated_vehicle_key][loopLogKey] = {
                      timestamp_start: returnDateFromUnknownDateFormat(loopLog.timestamp_start),
                      associated_user_key: loopLog.associated_user_key,
                      associated_user_name: loopLog.associated_user_name,
                      associated_vehicle_key: loopLog.associated_vehicle_key,
                    }
                  }
                }
                // Generate Driver Centric Data Structure for Table
                let driverCalculatedAnalysisObject: TsInterface_UnspecifiedObject = {}
                let driverAnalysisBuildingGuidanceObject: TsInterface_UnspecifiedObject = {}
                let driverCalendarEvents: TsInterface_UnspecifiedObject = {}
                for (let loopVehicleKey in combinedVehicleAssignmentHistoryLogs) {
                  let loopVehicle = combinedVehicleAssignmentHistoryLogs[loopVehicleKey]
                  for (let loopLogKey in loopVehicle) {
                    let loopLog = combinedVehicleAssignmentHistoryLogs[loopVehicleKey][loopLogKey]
                    if (loopLog != null && loopLog.associated_user_key != null && loopLog.associated_vehicle_key != null && loopLog.timestamp_start != null) {
                      let loopUserKey = loopLog.associated_user_key
                      let loopVehicleKey = loopLog.associated_vehicle_key
                      if (driverAnalysisBuildingGuidanceObject[loopUserKey] == null) {
                        driverAnalysisBuildingGuidanceObject[loopUserKey] = {
                          associated_user_key: loopUserKey,
                          associated_user_name: loopLog.associated_user_name,
                          vehicle_assignment_logs: {},
                        }
                      }
                      if (driverAnalysisBuildingGuidanceObject[loopUserKey]['vehicle_assignment_logs'][loopVehicleKey] == null) {
                        driverAnalysisBuildingGuidanceObject[loopUserKey]['vehicle_assignment_logs'][loopVehicleKey] = []
                      }
                      driverAnalysisBuildingGuidanceObject[loopUserKey]['vehicle_assignment_logs'][loopVehicleKey].push(loopLog)
                    }
                  }
                }
                // Generate list of events per driver separated by day
                for (let loopEventKey in taskData) {
                  let loopEvent = taskData[loopEventKey]
                  if (loopEvent != null && loopEvent['task_completion_scheduled_team_names'] != null) {
                    for (let loopDateKey in loopEvent['task_completion_scheduled_team_names']) {
                      let loopDate = loopEvent['task_completion_scheduled_team_names'][loopDateKey]
                      for (let loopUserKey in loopDate) {
                        if (driverAnalysisBuildingGuidanceObject[loopUserKey] != null) {
                          if (driverCalendarEvents[loopUserKey] == null) {
                            if (userVehicleAssignments[loopUserKey] == null) {
                              userVehicleAssignments[loopUserKey] = {}
                            }
                            driverCalendarEvents[loopUserKey] = {
                              vin: userVehicleAssignments[loopUserKey]['vin'],
                              garage_latitude: null,
                              garage_longitude: null,
                              events: {},
                              sorted_events: [],
                              missing_gps_data: {},
                              ordered_gps_coordinates: [],
                              clean_ordered_gps_coordinates: [],
                              distances_to_sum: {},
                            }
                            // Get Coordinates for Garage
                            if (
                              us_usersWithGaragesList != null &&
                              loopUserKey != null &&
                              us_usersWithGaragesList[loopUserKey] != null &&
                              us_usersWithGaragesList[loopUserKey]['associated_garage_key'] != null &&
                              us_activeGaragesList != null &&
                              us_activeGaragesList[us_usersWithGaragesList[loopUserKey]['associated_garage_key']] != null &&
                              us_activeGaragesList[us_usersWithGaragesList[loopUserKey]['associated_garage_key']]['location_latitude'] != null &&
                              us_activeGaragesList[us_usersWithGaragesList[loopUserKey]['associated_garage_key']]['location_longitude'] != null
                            ) {
                              driverCalendarEvents[loopUserKey]['garage_latitude'] =
                                us_activeGaragesList[us_usersWithGaragesList[loopUserKey]['associated_garage_key']]['location_latitude']
                              driverCalendarEvents[loopUserKey]['garage_longitude'] =
                                us_activeGaragesList[us_usersWithGaragesList[loopUserKey]['associated_garage_key']]['location_longitude']
                            }
                          }
                          if (driverCalendarEvents[loopUserKey]['events'][loopDateKey] == null) {
                            driverCalendarEvents[loopUserKey]['events'][loopDateKey] = {}
                          }
                          driverCalendarEvents[loopUserKey]['events'][loopDateKey][loopEventKey] = {
                            key: loopEventKey,
                            associated_project_id_number: getProp(loopEvent, 'associated_project_id_number', null),
                            associated_project_key: getProp(loopEvent, 'associated_project_key', null),
                            location_latitude: getProp(loopEvent, 'location_latitude', null),
                            location_longitude: getProp(loopEvent, 'location_longitude', null),
                            name: getProp(loopEvent, 'name', null),
                            start_time: getProp(getProp(loopEvent, 'task_completion_scheduled_start_times', {}), loopDateKey, null),
                            end_time: getProp(getProp(loopEvent, 'task_completion_scheduled_end_times', {}), loopDateKey, null),
                          }
                          if (driverCalendarEvents[loopUserKey]['events'][loopDateKey][loopEventKey]['start_time'] != null) {
                            driverCalendarEvents[loopUserKey]['events'][loopDateKey][loopEventKey]['start_time'] = timeStringToMinutes(
                              driverCalendarEvents[loopUserKey]['events'][loopDateKey][loopEventKey]['start_time'],
                            )
                          }
                          if (driverCalendarEvents[loopUserKey]['events'][loopDateKey][loopEventKey]['end_time'] != null) {
                            driverCalendarEvents[loopUserKey]['events'][loopDateKey][loopEventKey]['end_time'] = timeStringToMinutes(
                              driverCalendarEvents[loopUserKey]['events'][loopDateKey][loopEventKey]['end_time'],
                            )
                          }
                        }
                      }
                    }
                  }
                }
                for (let loopEventKey in cancelledTaskData) {
                  let loopEvent = cancelledTaskData[loopEventKey]
                  if (loopEvent != null && loopEvent['task_completion_scheduled_team_names'] != null) {
                    for (let loopDateKey in loopEvent['task_completion_scheduled_team_names']) {
                      let loopDate = loopEvent['task_completion_scheduled_team_names'][loopDateKey]
                      for (let loopUserKey in loopDate) {
                        if (driverAnalysisBuildingGuidanceObject[loopUserKey] != null) {
                          if (driverCalendarEvents[loopUserKey] == null) {
                            if (userVehicleAssignments[loopUserKey] == null) {
                              userVehicleAssignments[loopUserKey] = {}
                            }
                            driverCalendarEvents[loopUserKey] = {
                              vin: userVehicleAssignments[loopUserKey]['vin'],
                              garage_latitude: null,
                              garage_longitude: null,
                              events: {},
                              sorted_events: [],
                              missing_gps_data: {},
                              ordered_gps_coordinates: [],
                              clean_ordered_gps_coordinates: [],
                              distances_to_sum: {},
                            }
                            // Get Coordinates for Garage
                            if (
                              us_usersWithGaragesList != null &&
                              loopUserKey != null &&
                              us_usersWithGaragesList[loopUserKey] != null &&
                              us_usersWithGaragesList[loopUserKey]['associated_garage_key'] != null &&
                              us_activeGaragesList != null &&
                              us_activeGaragesList[us_usersWithGaragesList[loopUserKey]['associated_garage_key']] != null &&
                              us_activeGaragesList[us_usersWithGaragesList[loopUserKey]['associated_garage_key']]['location_latitude'] != null &&
                              us_activeGaragesList[us_usersWithGaragesList[loopUserKey]['associated_garage_key']]['location_longitude'] != null
                            ) {
                              driverCalendarEvents[loopUserKey]['garage_latitude'] =
                                us_activeGaragesList[us_usersWithGaragesList[loopUserKey]['associated_garage_key']]['location_latitude']
                              driverCalendarEvents[loopUserKey]['garage_longitude'] =
                                us_activeGaragesList[us_usersWithGaragesList[loopUserKey]['associated_garage_key']]['location_longitude']
                            }
                          }
                          if (driverCalendarEvents[loopUserKey]['events'][loopDateKey] == null) {
                            driverCalendarEvents[loopUserKey]['events'][loopDateKey] = {}
                          }
                          driverCalendarEvents[loopUserKey]['events'][loopDateKey][loopEventKey] = {
                            key: loopEventKey,
                            associated_project_id_number: getProp(loopEvent, 'associated_project_id_number', null),
                            associated_project_key: getProp(loopEvent, 'associated_project_key', null),
                            location_latitude: getProp(loopEvent, 'location_latitude', null),
                            location_longitude: getProp(loopEvent, 'location_longitude', null),
                            name: getProp(loopEvent, 'name', null),
                            start_time: getProp(getProp(loopEvent, 'task_completion_scheduled_start_times', {}), loopDateKey, null),
                            end_time: getProp(getProp(loopEvent, 'task_completion_scheduled_end_times', {}), loopDateKey, null),
                          }
                          if (driverCalendarEvents[loopUserKey]['events'][loopDateKey][loopEventKey]['start_time'] != null) {
                            driverCalendarEvents[loopUserKey]['events'][loopDateKey][loopEventKey]['start_time'] = timeStringToMinutes(
                              driverCalendarEvents[loopUserKey]['events'][loopDateKey][loopEventKey]['start_time'],
                            )
                          }
                          if (driverCalendarEvents[loopUserKey]['events'][loopDateKey][loopEventKey]['end_time'] != null) {
                            driverCalendarEvents[loopUserKey]['events'][loopDateKey][loopEventKey]['end_time'] = timeStringToMinutes(
                              driverCalendarEvents[loopUserKey]['events'][loopDateKey][loopEventKey]['end_time'],
                            )
                          }
                        }
                      }
                    }
                  }
                }
                // Loop through and determine mileage calculations needed for each driver for each day
                let distancesToCalculate: TsInterface_UnspecifiedObject = {}
                for (let loopDriverKey in driverCalendarEvents) {
                  let loopDriver = driverCalendarEvents[loopDriverKey]
                  for (let loopDateKey in loopDriver['events']) {
                    let loopDate = loopDriver['events'][loopDateKey]
                    let coordinatesArray: TsInterface_UnspecifiedObject[] = []
                    let eventCount = 0
                    let sortedEventsArray: TsInterface_UnspecifiedObject[] = objectToArray(loopDate).sort(dynamicSort('start_time', 'asc'))
                    // for( let loopEventKey in loopDate ){
                    for (let loopEventIndex in sortedEventsArray) {
                      eventCount++
                      let loopEvent = sortedEventsArray[loopEventIndex]
                      if (loopEvent != null && loopEvent.location_latitude != null && loopEvent.location_longitude != null) {
                        coordinatesArray.push({ lat: loopEvent.location_latitude, lng: loopEvent.location_longitude, event: loopEvent.key })
                      } else {
                        if (driverCalendarEvents[loopDriverKey]['missing_gps_data'][loopDateKey] == null) {
                          driverCalendarEvents[loopDriverKey]['missing_gps_data'][loopDateKey] = {}
                        }
                        driverCalendarEvents[loopDriverKey]['missing_gps_data'][loopDateKey][loopEvent.key] = {
                          key: loopEvent.key,
                          name: getProp(loopEvent, 'associated_project_id_number', 'Task:') + ' - ' + getProp(loopEvent, 'name', '(Missing)'),
                        }
                      }
                    }
                    if (eventCount > 0) {
                      if (loopDriver != null && loopDriver.garage_latitude != null && loopDriver.garage_longitude != null) {
                        coordinatesArray.unshift({ lat: loopDriver.garage_latitude, lng: loopDriver.garage_longitude, event: 'Start Of Day' })
                        coordinatesArray.push({ lat: loopDriver.garage_latitude, lng: loopDriver.garage_longitude, event: 'End of Day' })
                      } else {
                        if (driverCalendarEvents[loopDriverKey]['missing_gps_data'][loopDateKey] == null) {
                          driverCalendarEvents[loopDriverKey]['missing_gps_data'][loopDateKey] = {}
                        }
                        driverCalendarEvents[loopDriverKey]['missing_gps_data'][loopDateKey]['garage'] = {
                          key: 'garage',
                          name: 'Garage Address',
                        }
                      }
                    }
                    driverCalendarEvents[loopDriverKey]['sorted_events'][loopDateKey] = sortedEventsArray
                    let cleanCoordinatesArray: TsInterface_UnspecifiedObject[] = []
                    for (let loopIndex in coordinatesArray) {
                      let loopCoordinateValues = coordinatesArray[loopIndex]
                      let previousLoopCoordinateValues = null
                      if (parseInt(loopIndex) > 0) {
                        previousLoopCoordinateValues = coordinatesArray[parseInt(loopIndex) - 1]
                      }
                      if (previousLoopCoordinateValues != null) {
                        if (loopCoordinateValues.lat !== previousLoopCoordinateValues.lat || loopCoordinateValues.lng !== previousLoopCoordinateValues.lng) {
                          cleanCoordinatesArray.push(loopCoordinateValues)
                        }
                      } else {
                        cleanCoordinatesArray.push(loopCoordinateValues)
                      }
                    }
                    driverCalendarEvents[loopDriverKey]['ordered_gps_coordinates'][loopDateKey] = coordinatesArray
                    driverCalendarEvents[loopDriverKey]['clean_ordered_gps_coordinates'][loopDateKey] = cleanCoordinatesArray
                    // Loop through and add to global list of coordinates to calculate
                    for (let loopIndex in cleanCoordinatesArray) {
                      let loopCoordinateValues = coordinatesArray[loopIndex]
                      let previousLoopCoordinateValues = null
                      if (parseInt(loopIndex) > 0) {
                        previousLoopCoordinateValues = coordinatesArray[parseInt(loopIndex) - 1]
                      }
                      if (previousLoopCoordinateValues != null) {
                        let loopDistanceKey =
                          previousLoopCoordinateValues.lat.toString() +
                          '_' +
                          previousLoopCoordinateValues.lng.toString() +
                          '_' +
                          loopCoordinateValues.lat.toString() +
                          '_' +
                          loopCoordinateValues.lng.toString()
                        // Local
                        if (driverCalendarEvents[loopDriverKey]['distances_to_sum'][loopDateKey] == null) {
                          driverCalendarEvents[loopDriverKey]['distances_to_sum'][loopDateKey] = {}
                        }
                        driverCalendarEvents[loopDriverKey]['distances_to_sum'][loopDateKey][loopDistanceKey] = {
                          lat1: previousLoopCoordinateValues.lat,
                          lng1: previousLoopCoordinateValues.lng,
                          lat2: loopCoordinateValues.lat,
                          lng2: loopCoordinateValues.lng,
                        }
                        // Aggregate
                        distancesToCalculate[loopDistanceKey] = {
                          lat1: previousLoopCoordinateValues.lat,
                          lng1: previousLoopCoordinateValues.lng,
                          lat2: loopCoordinateValues.lat,
                          lng2: loopCoordinateValues.lng,
                        }
                      }
                    }
                  }
                }
                // Check db for mileages between coordinates instead of google api every time
                geocodeMileagesForAnalysis(distancesToCalculate)
                  .then((res_GMFA: any) => {
                    let combinedGeocodingResults: TsInterface_UnspecifiedObject = res_GMFA.combinedGeocodingResults
                    // Calculate actual miles
                    for (let loopLogKey in vehicleMileageLogs) {
                      let loopLog = vehicleMileageLogs[loopLogKey]
                      if (loopLog != null && loopLog.vin != null && vinToVehicleKey[loopLog.vin] != null) {
                        let loopDriverKey = findUserKeyByDate(
                          returnDateFromUnknownDateFormat(loopLog.timestamp_start),
                          objectToArray(getProp(combinedVehicleAssignmentHistoryLogs, vinToVehicleKey[loopLog.vin], {})),
                        )
                        if (loopDriverKey != null) {
                          if (driverCalculatedAnalysisObject[loopDriverKey] == null) {
                            driverCalculatedAnalysisObject[loopDriverKey] = {
                              key: getProp(getProp(driverAnalysisBuildingGuidanceObject, loopDriverKey, {}), 'associated_user_key', null),
                              name: getProp(getProp(driverAnalysisBuildingGuidanceObject, loopDriverKey, {}), 'associated_user_name', null),
                              vins: { [loopLog.vin]: loopLog.vin },
                              actual_miles: {},
                              actual_logs: {},
                              total_actual_miles: 0,
                              scheduled_miles: {},
                              scheduled_mile_errors: {},
                              total_scheduled_miles: 0,
                            }
                          }
                          driverCalculatedAnalysisObject[loopDriverKey]['vins'][loopLog.vin] = loopLog.vin
                          if (loopLog.timestamp_start != null) {
                            let logDateKey = returnFormattedDateKey(returnDateFromUnknownDateFormat(loopLog.timestamp_start))
                            if (driverCalculatedAnalysisObject[loopDriverKey]['actual_miles'][logDateKey] == null) {
                              driverCalculatedAnalysisObject[loopDriverKey]['actual_miles'][logDateKey] = 0
                              driverCalculatedAnalysisObject[loopDriverKey]['actual_logs'][logDateKey] = {}
                            }
                            driverCalculatedAnalysisObject[loopDriverKey]['actual_logs'][logDateKey][loopLogKey] = loopLog
                            if (loopLog.distance_miles != null) {
                              driverCalculatedAnalysisObject[loopDriverKey]['actual_miles'][logDateKey] += loopLog.distance_miles
                              if (validWeekDaysObject[logDateKey] != null && loopLog.distance_miles > 0) {
                                driverCalculatedAnalysisObject[loopDriverKey]['total_actual_miles'] += loopLog.distance_miles
                              }
                            }
                          }
                        }
                      }
                    }
                    // Calculate Scheduled Miles
                    for (let loopDriverKey in driverCalendarEvents) {
                      let loopDriver = getProp(driverCalendarEvents, loopDriverKey, {})
                      if (driverCalculatedAnalysisObject[loopDriverKey] == null) {
                        driverCalculatedAnalysisObject[loopDriverKey] = {
                          key: getProp(getProp(driverAnalysisBuildingGuidanceObject, loopDriverKey, {}), 'associated_user_key', null),
                          name: getProp(getProp(driverAnalysisBuildingGuidanceObject, loopDriverKey, {}), 'associated_user_name', null),
                          vins: {},
                          actual_miles: {},
                          actual_logs: {},
                          total_actual_miles: 0,
                          scheduled_miles: {},
                          scheduled_mile_errors: {},
                          total_scheduled_miles: 0,
                        }
                      }
                      let copyOfLoopDriver = cloneObjectWithoutReference(loopDriver)
                      driverCalculatedAnalysisObject[loopDriverKey]['events'] = getProp(copyOfLoopDriver, 'events', {})
                      driverCalculatedAnalysisObject[loopDriverKey]['garage_latitude'] = getProp(loopDriver, 'garage_latitude', 0)
                      driverCalculatedAnalysisObject[loopDriverKey]['garage_longitude'] = getProp(loopDriver, 'garage_longitude', 0)
                      if (loopDriver.missing_gps_data != null) {
                        driverCalculatedAnalysisObject[loopDriverKey]['scheduled_mile_errors'] = loopDriver.missing_gps_data
                      }
                      let vehicleDayDistancesToSum = getProp(loopDriver, 'distances_to_sum', {})
                      for (let loopDateKey in vehicleDayDistancesToSum) {
                        let loopDayDistancesToSum = vehicleDayDistancesToSum[loopDateKey]
                        let sumOfScheduledMiles = 0
                        for (let loopCombinedCoordinatesKey in loopDayDistancesToSum) {
                          if (
                            combinedGeocodingResults != null &&
                            combinedGeocodingResults[loopCombinedCoordinatesKey] != null &&
                            combinedGeocodingResults[loopCombinedCoordinatesKey]['miles'] != null
                          ) {
                            sumOfScheduledMiles += parseFloat(combinedGeocodingResults[loopCombinedCoordinatesKey]['miles'])
                          } else {
                            if (driverCalculatedAnalysisObject[loopDriverKey]['scheduled_mile_errors'][loopDateKey] == null) {
                              driverCalculatedAnalysisObject[loopDriverKey]['scheduled_mile_errors'][loopDateKey] = {}
                            }
                            driverCalculatedAnalysisObject[loopDriverKey]['scheduled_mile_errors'][loopDateKey]['geocode_error'] = {
                              key: loopCombinedCoordinatesKey,
                              name: 'Geocode Error',
                            }
                          }
                        }
                        driverCalculatedAnalysisObject[loopDriverKey]['scheduled_miles'][loopDateKey] = sumOfScheduledMiles
                        if (validWeekDaysObject[loopDateKey] != null) {
                          driverCalculatedAnalysisObject[loopDriverKey]['total_scheduled_miles'] += sumOfScheduledMiles
                        }
                      }
                    }
                    // Add vehicle vins that were missed from logs
                    for (let loopDriverKey in driverCalculatedAnalysisObject) {
                      if (
                        driverAnalysisBuildingGuidanceObject != null &&
                        driverAnalysisBuildingGuidanceObject[loopDriverKey] != null &&
                        driverAnalysisBuildingGuidanceObject[loopDriverKey]['vehicle_assignment_logs'] != null
                      ) {
                        for (let loopVehicleKey in driverAnalysisBuildingGuidanceObject[loopDriverKey]['vehicle_assignment_logs']) {
                          let loopVehicle = driverAnalysisBuildingGuidanceObject[loopDriverKey]['vehicle_assignment_logs'][loopVehicleKey]
                          for (let loopAssignmentLogKey in loopVehicle) {
                            let loopAssignmentLog = loopVehicle[loopAssignmentLogKey]
                            if (
                              loopAssignmentLog != null &&
                              loopAssignmentLog.associated_vehicle_key != null &&
                              activeVehicles != null &&
                              activeVehicles[loopAssignmentLog.associated_vehicle_key] != null &&
                              activeVehicles[loopAssignmentLog.associated_vehicle_key]['vin'] != null
                            ) {
                              let loopVin = activeVehicles[loopAssignmentLog.associated_vehicle_key]['vin']
                              if (driverCalculatedAnalysisObject[loopDriverKey]['vins'][loopVin] == null) {
                                driverCalculatedAnalysisObject[loopDriverKey]['vins'][loopVin] = loopVin
                              }
                            }
                          }
                        }
                      }
                    }
                    // Delta
                    for (let loopDriverKey in driverCalculatedAnalysisObject) {
                      let loopDriver = driverCalculatedAnalysisObject[loopDriverKey]
                      if (loopDriver != null && loopDriver.total_scheduled_miles != null && loopDriver.total_actual_miles != null) {
                        loopDriver['total_actual_minus_scheduled_miles'] = loopDriver['total_actual_miles'] - loopDriver['total_scheduled_miles']
                      }
                    }
                    // Finalize update objects and write to database
                    let mondayDateKey = returnFormattedDateKey(us_startDownloadDate)
                    let updateObject = {
                      key: mondayDateKey,
                      timestamp_analysis_completed: new Date(),
                    }
                    let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                      {
                        type: 'setMerge',
                        ref: DatabaseRef_VehicleMileageLogs_Analysis_GeocodedDistances_Document(res_GCK.clientKey, mondayDateKey),
                        data: combinedGeocodingResults,
                      },
                      {
                        type: 'setOverwrite',
                        ref: DatabaseRef_VehicleMileageLogs_Analysis_Document(res_GCK.clientKey, mondayDateKey),
                        data: updateObject,
                      },
                    ]
                    for (let loopDriverKey in driverCalculatedAnalysisObject) {
                      let loopDriver = driverCalculatedAnalysisObject[loopDriverKey]
                      updateArray.push({
                        type: 'setOverwrite',
                        ref: DatabaseRef_VehicleMileageLogs_Analysis_Driver_Document(res_GCK.clientKey, mondayDateKey, loopDriverKey),
                        data: loopDriver,
                      })
                    }
                    // Save to Database
                    DatabaseBatchUpdate(updateArray)
                      .then((res_DBU) => {
                        us_setBuildingAnalysis(false)
                        ur_forceRerender()
                      })
                      .catch((rej_DBU) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
                        us_setBuildingAnalysis(false)
                      })
                  })
                  .catch((rej_GMFA) => {
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GMFA.error })
                    us_setBuildingAnalysis(false)
                  })
              })
            })
          } else {
            let error = {
              message: rLIB('Failed to generate analysis'),
              details: rLIB('Missing required parameters'),
              code: 'ER-D-VMR-RMA-01',
            }
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
            us_setBuildingAnalysis(false)
          }
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          us_setBuildingAnalysis(false)
        })
    }
  }

  const downloadMileageReportExport = () => {
    // Instantiate Variables
    let exportData: string[][] = [['Date', 'Job Code', 'Driver', 'Vehicle VIN', 'Task Name(s)', 'Project Miles', 'Number of Stops', 'Total Miles']]
    // Build Export Data
    for (let loopDriverKey in getProp(us_weekMileageAnalysisResults, 'drivers', {})) {
      let loopDriver = us_weekMileageAnalysisResults['drivers'][loopDriverKey]
      if (loopDriver != null && loopDriver['events'] != null) {
        for (let loopDateKey in loopDriver['events']) {
          if (us_selectedWeekDateKeys[loopDateKey] === true) {
            let loopDate = loopDriver['events'][loopDateKey]
            let jobCodesOnDate: TsInterface_UnspecifiedObject = {}
            for (let loopEventKey in loopDate) {
              let loopEvent = loopDate[loopEventKey]
              if (loopEvent != null && loopEvent.associated_project_key != null) {
                if (jobCodesOnDate[loopEvent.associated_project_key] == null) {
                  jobCodesOnDate[loopEvent.associated_project_key] = {
                    associated_project_key: loopEvent.associated_project_key,
                    associated_project_id_number: loopEvent.associated_project_id_number,
                    tasks: {},
                  }
                }
                jobCodesOnDate[loopEvent.associated_project_key]['tasks'][loopEventKey] = loopEvent['name']
              }
            }
            let numberOfJobCodes = objectToArray(jobCodesOnDate).length
            for (let loopProjectKey in jobCodesOnDate) {
              let loopProject = jobCodesOnDate[loopProjectKey]
              let dataRow: string[] = []
              // Date
              if (loopDateKey != null) {
                dataRow.push(loopDateKey)
              } else {
                dataRow.push('')
              }
              // Job Code
              if (loopProject != null && loopProject['associated_project_id_number'] != null) {
                dataRow.push(loopProject['associated_project_id_number'])
              } else {
                dataRow.push('')
              }
              // Driver
              if (loopDriver != null && loopDriver['name'] != null) {
                dataRow.push(loopDriver['name'])
              } else {
                dataRow.push('')
              }
              // Vehicle VIN
              if (loopDriver != null && loopDriver['vins'] != null) {
                dataRow.push(objectToArray(loopDriver['vins']).join(', '))
              } else {
                dataRow.push('')
              }
              // Task Name(s)
              if (loopProject != null && loopProject['tasks'] != null) {
                dataRow.push(objectToArray(loopProject['tasks']).join(', '))
              } else {
                dataRow.push('')
              }
              // Project Miles
              if (
                loopDriver != null &&
                loopDriver['actual_miles'] != null &&
                loopDriver['actual_miles'][loopDateKey] != null &&
                !isNaN(loopDriver['actual_miles'][loopDateKey]) &&
                !isNaN(numberOfJobCodes)
              ) {
                let dividedMiles = (loopDriver['actual_miles'][loopDateKey] / numberOfJobCodes).toFixed(2)
                dataRow.push(dividedMiles)
              } else {
                dataRow.push('')
              }
              // Number of Stops
              if (numberOfJobCodes != null) {
                dataRow.push(numberOfJobCodes.toString())
              } else {
                dataRow.push('')
              }
              // Total Miles
              if (loopDriver != null && loopDriver['actual_miles'] != null && loopDriver['actual_miles'][loopDateKey] != null) {
                dataRow.push(loopDriver['actual_miles'][loopDateKey].toFixed(2))
              } else {
                dataRow.push('')
              }
              // Push to Export Data
              exportData.push(dataRow)
            }
          }
        }
      }
    }
    let fileName = 'Mileages assigned to jobs (week of ' + us_weekMileageAnalysisResults.key + ').csv'
    downloadCSV(fileName, exportData)
      .then((res_DCSV) => {
        // Nothing
      })
      .catch((rej_DCSV) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DCSV.error })
      })
  }

  const batchSaveNewGeocodesToDatabase = (clientKey: string, formattedData: TsInterface_UnspecifiedObject[], startingIndex: number): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      let batchLimit = 500
      let timeoutMilliseconds = 1500
      let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
      for (let loopIndex = startingIndex; loopIndex < startingIndex + batchLimit; loopIndex++) {
        if (formattedData[loopIndex] != null) {
          updateArray.push({
            type: 'setMerge',
            ref: DatabaseRef_FuelLogGeolocation_Document(clientKey, formattedData[loopIndex].key),
            data: formattedData[loopIndex],
          })
        }
      }
      if (formattedData.length > startingIndex + batchLimit) {
        DatabaseBatchUpdate(updateArray)
          .then((res_DBU) => {
            setTimeout(() => {
              batchSaveNewGeocodesToDatabase(clientKey, formattedData, startingIndex + batchLimit)
                .then((res_BSNGTD) => {
                  resolve(res_BSNGTD)
                })
                .catch((rej_BSNGTD) => {
                  reject(rej_BSNGTD)
                })
            }, timeoutMilliseconds)
          })
          .catch((rej_DBU) => {
            us_setUploadingData(false)
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
            reject(rej_DBU)
          })
      } else {
        DatabaseBatchUpdate(updateArray)
          .then((res_DBU) => {
            us_setUploadingData(false)
            resolve(res_DBU)
          })
          .catch((rej_DBU) => {
            us_setUploadingData(false)
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
            reject(rej_DBU)
          })
      }
    })
  }

  const convertAndMergeGasStationGeocodes = (
    clientKey: string,
    vehicleFuelLogs: TsInterface_UnspecifiedObject,
    allGeocodedGasStationLogs: TsInterface_UnspecifiedObject,
  ): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      // Instantiate Variables
      let promiseArray1: TsType_UnknownPromise[] = []
      let promiseArray2: TsType_UnknownPromise[] = []
      let mergedGasStationGeocodeData: TsInterface_UnspecifiedObject = {}
      let newGeocodesToSaveToDatabase: TsInterface_UnspecifiedObject = {}
      // Group Logs by Gas Station
      let groupedGasStationsInLogs: TsInterface_UnspecifiedObject = {}
      for (let loopFuelLogKey in vehicleFuelLogs) {
        let loopFuelLog = vehicleFuelLogs[loopFuelLogKey]
        let street = getProp(loopFuelLog, 'associated_merchant_address', '')
        let city = getProp(loopFuelLog, 'associated_merchant_city', '')
        let state = getProp(loopFuelLog, 'associated_merchant_state', '')
        let zip = getProp(loopFuelLog, 'associated_merchant_zip', '')
        let fullAddress = street + ', ' + city + ', ' + state + ' ' + zip
        let fuelLogKey = street.replace(/ /g, '_') + '-' + city.replace(/ /g, '_') + '-' + state.replace(/ /g, '_') + '-' + zip.replace(/ /g, '_')
        if (groupedGasStationsInLogs[fuelLogKey] == null) {
          groupedGasStationsInLogs[fuelLogKey] = {
            key: fuelLogKey,
            address: fullAddress,
            count: 0,
          }
        }
        groupedGasStationsInLogs[fuelLogKey]['count']++
      }
      // Loop through grouped logs and either get coordinates from database object or get them from geocoding
      let geocodeDelay = 0 // Less than 50 per second
      let geocodeCount = 0
      for (let loopGasStationAddressKey in groupedGasStationsInLogs) {
        let loopGasStationAddress = groupedGasStationsInLogs[loopGasStationAddressKey]
        if (loopGasStationAddressKey != null && allGeocodedGasStationLogs[loopGasStationAddressKey] != null) {
          mergedGasStationGeocodeData[loopGasStationAddressKey] = allGeocodedGasStationLogs[loopGasStationAddressKey]
        } else if (loopGasStationAddress != null && loopGasStationAddress['address'] != null) {
          promiseArray1.push(
            convertAddressToLatLng(loopGasStationAddressKey, loopGasStationAddress['address'], geocodeDelay * 25)
              .then((res_CATLL: any) => {
                if (res_CATLL != null && res_CATLL.key != null && res_CATLL.lat != null && res_CATLL.lng != null) {
                  mergedGasStationGeocodeData[res_CATLL.key] = {
                    key: res_CATLL.key,
                    lat: res_CATLL.lat,
                    lng: res_CATLL.lng,
                  }
                  newGeocodesToSaveToDatabase[res_CATLL.key] = {
                    key: res_CATLL.key,
                    lat: res_CATLL.lat,
                    lng: res_CATLL.lng,
                  }
                }
              })
              .catch((rej_CATLL) => {
                console.error(rej_CATLL)
              }),
          )
          geocodeDelay++
          geocodeCount++
        }
      }
      Promise.all(promiseArray1).finally(() => {
        // Save all new geocode data to database
        promiseArray2.push(batchSaveNewGeocodesToDatabase(clientKey, objectToArray(newGeocodesToSaveToDatabase), 0))
        // Resolve
        Promise.all(promiseArray2).finally(() => {
          resolve({ success: true, mergedGasStationGeocodeData: mergedGasStationGeocodeData })
        })
      })
    })
  }

  const findClosestMileageLog = (vehicleVin: string, transactionTimestamp: Date, records: TsInterface_UnspecifiedObject[]) => {
    let closestRecord = {}
    let smallestDifference = Infinity // Start with the largest possible difference
    records.forEach((record) => {
      // Ensure that we're looking at records with the matching VIN only
      if (record.vin === vehicleVin) {
        // Calculate the time difference in minutes between the record timestamp and the transaction timestamp
        let difference = Math.abs(new Date(record.timestamp_start).getTime() - new Date(transactionTimestamp).getTime())
        // Update the closestRecord if this record is closer to the transaction timestamp
        if (difference < smallestDifference) {
          smallestDifference = difference
          closestRecord = record
        }
      }
    })
    return closestRecord
  }

  const getSameDayMileageLogs = (vehicleVin: string, transactionTimestamp: Date, records: TsInterface_UnspecifiedObject[]) => {
    let sameDayRecords: TsInterface_UnspecifiedObject = {}
    let fuelLogDateDate = returnFormattedDateKey(returnDateFromUnknownDateFormat(transactionTimestamp))
    records.forEach((record) => {
      // Ensure that we're looking at records with the matching VIN only
      if (record.vin === vehicleVin) {
        let mileageLogDate = returnFormattedDateKey(returnDateFromUnknownDateFormat(record.timestamp_start))
        if (fuelLogDateDate === mileageLogDate) {
          sameDayRecords[record.key] = record
        }
      }
    })
    return sameDayRecords
  }

  const runFuelAnalysis = (): void => {
    if (us_fuelAnalysisStartDate != null && us_fuelAnalysisEndDate != null) {
      // Instantiate Variables
      us_setBuildingAnalysis(true)
      let promiseArray1: TsType_UnknownPromise[] = []
      let promiseArray2: TsType_UnknownPromise[] = []
      let vehicleMileageLogs: TsInterface_UnspecifiedObject = {}
      let vehicleFuelLogs: TsInterface_UnspecifiedObject = {}
      let activeVehicles: TsInterface_UnspecifiedObject = {}
      let allGeocodedGasStationLogs: TsInterface_UnspecifiedObject = {}
      let mergedGasStationGeocodeData: TsInterface_UnspecifiedObject = {}
      // Get Data
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          promiseArray1.push(
            DatabaseGetCollection(DatabaseRef_VehicleMileageLogs_Query(res_GCK.clientKey, us_fuelAnalysisStartDate as Date, us_fuelAnalysisEndDate as Date))
              .then((res_DGC) => {
                vehicleMileageLogs = res_DGC.data
              })
              .catch((rej_DGC) => {
                console.error(rej_DGC)
              }),
          )
          promiseArray1.push(
            DatabaseGetCollection(DatabaseRef_VehicleFuelLogs_Query(res_GCK.clientKey, us_fuelAnalysisStartDate as Date, us_fuelAnalysisEndDate as Date))
              .then((res_DGC) => {
                vehicleFuelLogs = res_DGC.data
              })
              .catch((rej_DGC) => {
                console.error(rej_DGC)
              }),
          )
          promiseArray1.push(
            DatabaseGetCollection(DatabaseRef_ActiveVehicles_Query(res_GCK.clientKey))
              .then((res_DGC) => {
                activeVehicles = res_DGC.data
              })
              .catch((rej_DGC) => {
                console.error(rej_DGC)
              }),
          )
          promiseArray1.push(
            DatabaseGetCollection(DatabaseRef_FuelLogGeolocation_Collection(res_GCK.clientKey))
              .then((res_DGC) => {
                allGeocodedGasStationLogs = res_DGC.data
              })
              .catch((rej_DGC) => {
                console.error(rej_DGC)
              }),
          )
          Promise.all(promiseArray1).finally(() => {
            // Loop through and combine fuel logs with mileage logs
            let pinToVehicleKeyMappingObject: TsInterface_UnspecifiedObject = {}
            let pinToVehicleVinMappingObject: TsInterface_UnspecifiedObject = {}
            let vinToVehicleKeyMappingObject: TsInterface_UnspecifiedObject = {}
            // Loop through vehicles
            for (let loopVehicleKey in activeVehicles) {
              let loopVehicle = activeVehicles[loopVehicleKey]
              // Make Vehicle Searchable by Card Number
              if (loopVehicle.fuel_card_number != null) {
                pinToVehicleKeyMappingObject[loopVehicle.fuel_card_number.replace(/-/g, '').slice(-5)] = loopVehicleKey
                if (loopVehicle.vin != null) {
                  pinToVehicleVinMappingObject[loopVehicle.fuel_card_number.replace(/-/g, '').slice(-5)] = loopVehicle.vin
                }
              }
              // Make Vehicle Searchable by VIN Number
              if (loopVehicle.vin != null) {
                vinToVehicleKeyMappingObject[loopVehicle.vin] = loopVehicleKey
              }
            }
            // Get Gas Station Coordinates
            promiseArray2.push(
              convertAndMergeGasStationGeocodes(res_GCK.clientKey, vehicleFuelLogs, allGeocodedGasStationLogs).then((res_CAMGSG) => {
                mergedGasStationGeocodeData = getProp(res_CAMGSG, 'mergedGasStationGeocodeData', {})
              }),
            )
            // After Geocoding Complete
            Promise.all(promiseArray2).finally(() => {
              // Generate Mileage Log Searchable Object
              let searchableMileageLogs: TsInterface_UnspecifiedObject[] = []
              for (let loopLogKey in vehicleMileageLogs) {
                let loopLog = vehicleMileageLogs[loopLogKey]
                if (loopLog != null && loopLog.timestamp_start != null && loopLog.vin != null && loopLog.key != null) {
                  searchableMileageLogs.push({
                    key: loopLog.key,
                    associated_driver_name: getProp(loopLog, 'associated_driver_name', null),
                    odometer_starting_miles: getProp(loopLog, 'odometer_starting_miles', null),
                    associated_event: getProp(loopLog, 'associated_event', null),
                    duration_minutes: getProp(loopLog, 'duration_minutes', null),
                    location_end_latitude: getProp(loopLog, 'location_end_latitude', null),
                    location_end_longitude: getProp(loopLog, 'location_end_longitude', null),
                    location_start_latitude: getProp(loopLog, 'location_start_latitude', null),
                    location_start_longitude: getProp(loopLog, 'location_start_longitude', null),
                    timestamp_start: returnDateFromUnknownDateFormat(loopLog.timestamp_start),
                    timestamp_end: returnDateFromUnknownDateFormat(loopLog.timestamp_end),
                    vin: loopLog.vin,
                  })
                }
              }
              // Group Mileage Logs by VIN
              let combinedVehicleMileageLogs: TsInterface_UnspecifiedObject = {}
              for (let loopLogKey in vehicleMileageLogs) {
                let loopLog = vehicleMileageLogs[loopLogKey]
                if (loopLog != null && loopLog.vin != null) {
                  if (combinedVehicleMileageLogs[loopLog.vin] == null) {
                    combinedVehicleMileageLogs[loopLog.vin] = {}
                  }
                  combinedVehicleMileageLogs[loopLog.vin][loopLogKey] = loopLog
                }
              }
              // Group Fuel Logs by VIN
              let combinedVehicleFuelLogs: TsInterface_UnspecifiedObject = {}
              for (let loopLogKey in vehicleFuelLogs) {
                let loopLog = vehicleFuelLogs[loopLogKey]
                if (loopLog != null && loopLog.card_number != null) {
                  let cleanCardNumber = loopLog.card_number.replace(/\*/g, '')
                  if (pinToVehicleVinMappingObject[cleanCardNumber] != null) {
                    let vehicleKey = pinToVehicleVinMappingObject[cleanCardNumber]
                    if (combinedVehicleFuelLogs[vehicleKey] == null) {
                      combinedVehicleFuelLogs[vehicleKey] = {}
                    }
                    combinedVehicleFuelLogs[vehicleKey][loopLogKey] = loopLog
                  }
                }
              }
              let fuelLogTotals: TsInterface_UnspecifiedObject = {}
              let mileageLogsByFuelLog: TsInterface_UnspecifiedObject = {}
              let fuelLogIndices: TsInterface_UnspecifiedObject = {}
              for (let loopVehiceVin in combinedVehicleFuelLogs) {
                let loopVehicleFuelLogs = combinedVehicleFuelLogs[loopVehiceVin]
                let sortedFuelLogs = objectToArray(loopVehicleFuelLogs).sort(dynamicSort('timestamp_transaction', 'asc'))
                let vehicleMileageLogs = getProp(combinedVehicleMileageLogs, loopVehiceVin, {})
                let sortedMileageLogs = objectToArray(vehicleMileageLogs).sort(dynamicSort('timestamp_start', 'asc'))
                if (sortedFuelLogs.length > 0) {
                  for (let loopFuelLogIndex in sortedFuelLogs) {
                    let loopFuelLog = sortedFuelLogs[loopFuelLogIndex]
                    fuelLogIndices[loopFuelLog.key] = parseInt(loopFuelLogIndex)
                    let currentFuelLogDate = returnDateFromUnknownDateFormat(loopFuelLog.timestamp_transaction).getTime()
                    let prevousFuelLogDate = null
                    if (parseInt(loopFuelLogIndex) > 0) {
                      let previousFuelLog = sortedFuelLogs[parseInt(loopFuelLogIndex) - 1]
                      prevousFuelLogDate = returnDateFromUnknownDateFormat(previousFuelLog.timestamp_transaction).getTime()
                    }
                    mileageLogsByFuelLog[loopFuelLog.key] = {}
                    fuelLogTotals[loopFuelLog.key] = {
                      key: loopFuelLog.key,
                      total_miles: 0,
                      total_mile_logs: 0,
                      gallons: getProp(loopFuelLog, 'gallons', 0),
                      dollars_per_gallon: getProp(loopFuelLog, 'dollars_per_gallon', 0),
                      total_dollars: getProp(loopFuelLog, 'total_dollars', 0),
                      previous_fuel_log_date: prevousFuelLogDate,
                      fuel_log_date: currentFuelLogDate,
                      timestamp_transaction: returnDateFromUnknownDateFormat(loopFuelLog.timestamp_transaction),
                    }
                    for (let loopMileageLogIndex in sortedMileageLogs) {
                      let loopMileageLog = sortedMileageLogs[loopMileageLogIndex]
                      let mileageLogDate = returnDateFromUnknownDateFormat(loopMileageLog.timestamp_start).getTime()
                      if (prevousFuelLogDate == null && mileageLogDate <= currentFuelLogDate) {
                        // First Fuel Log
                        mileageLogsByFuelLog[loopFuelLog.key][loopMileageLog.key] = loopMileageLog
                        if (loopMileageLog.distance_miles != null && !isNaN(parseFloat(loopMileageLog.distance_miles))) {
                          fuelLogTotals[loopFuelLog.key]['total_miles'] += parseFloat(loopMileageLog.distance_miles)
                          fuelLogTotals[loopFuelLog.key]['total_mile_logs']++
                        }
                      } else if (prevousFuelLogDate != null && prevousFuelLogDate < mileageLogDate && mileageLogDate <= currentFuelLogDate) {
                        // Subsequent Fuel Logs
                        mileageLogsByFuelLog[loopFuelLog.key][loopMileageLog.key] = loopMileageLog
                        if (loopMileageLog.distance_miles != null && !isNaN(parseFloat(loopMileageLog.distance_miles))) {
                          fuelLogTotals[loopFuelLog.key]['total_miles'] += parseFloat(loopMileageLog.distance_miles)
                          fuelLogTotals[loopFuelLog.key]['total_mile_logs']++
                        }
                      }
                    }
                  }
                }
              }
              // Group Fuel Logs by Driver and add coordinates if available
              let fuelAnalysisResults: TsInterface_UnspecifiedObject = {}
              for (let loopFuelLogKey in vehicleFuelLogs) {
                // Instantiate Variables
                let loopFuelLog = getProp(vehicleFuelLogs, loopFuelLogKey, {})
                let driverKey = getProp(loopFuelLog, 'associated_driver_first_name', '') + '_' + getProp(loopFuelLog, 'associated_driver_last_name', '')
                // Create Driver Object if it doesn't exist
                if (fuelAnalysisResults[driverKey] == null) {
                  fuelAnalysisResults[driverKey] = {
                    key: driverKey,
                    name: getProp(loopFuelLog, 'associated_driver_first_name', '') + ' ' + getProp(loopFuelLog, 'associated_driver_last_name', ''),
                    vehicle_vins: {},
                    fuel_logs: {},
                  }
                }
                // Add Log to Driver Object
                fuelAnalysisResults[driverKey]['fuel_logs'][loopFuelLogKey] = loopFuelLog
                if (loopFuelLog['timestamp_transaction'] != null) {
                  fuelAnalysisResults[driverKey]['fuel_logs'][loopFuelLogKey]['CALCULATED_timestamp_transaction'] = returnDateFromUnknownDateFormat(
                    loopFuelLog['timestamp_transaction'],
                  )
                }
                // Driver Key from Fuel Log PIN
                if (
                  loopFuelLog != null &&
                  loopFuelLog.associated_driver_pin != null &&
                  us_fuelLogDriverPins != null &&
                  us_fuelLogDriverPins[loopFuelLog.associated_driver_pin] != null &&
                  us_fuelLogDriverPins[loopFuelLog.associated_driver_pin].associated_user_key != null
                ) {
                  fuelAnalysisResults[driverKey]['fuel_logs'][loopFuelLogKey]['CALCULATED_associated_user_key'] =
                    us_fuelLogDriverPins[loopFuelLog.associated_driver_pin].associated_user_key
                }
                // Add Mileage Data
                fuelAnalysisResults[driverKey]['fuel_logs'][loopFuelLogKey]['CALCULATED_fuel_log_index'] = fuelLogIndices[loopFuelLogKey]
                fuelAnalysisResults[driverKey]['fuel_logs'][loopFuelLogKey]['CALCULATED_fuel_log_totals'] = fuelLogTotals[loopFuelLogKey]
                fuelAnalysisResults[driverKey]['fuel_logs'][loopFuelLogKey]['CALCULATED_mileage_logs'] = mileageLogsByFuelLog[loopFuelLogKey]
                // Get Vehicle Key for this Fuel Log
                if (loopFuelLog.card_number != null) {
                  let cleanCardNumber = loopFuelLog.card_number.replace(/\*/g, '')
                  if (pinToVehicleKeyMappingObject[cleanCardNumber] != null) {
                    fuelAnalysisResults[driverKey]['fuel_logs'][loopFuelLogKey]['CALCULATED_associated_vehicle_key'] =
                      pinToVehicleKeyMappingObject[cleanCardNumber]
                    if (
                      activeVehicles != null &&
                      activeVehicles[pinToVehicleKeyMappingObject[cleanCardNumber]] != null &&
                      activeVehicles[pinToVehicleKeyMappingObject[cleanCardNumber]]['vin'] != null
                    ) {
                      let vehicleVin = activeVehicles[pinToVehicleKeyMappingObject[cleanCardNumber]]['vin']
                      fuelAnalysisResults[driverKey]['fuel_logs'][loopFuelLogKey]['CALCULATED_associated_vehicle_vin'] = vehicleVin
                      fuelAnalysisResults[driverKey]['vehicle_vins'][vehicleVin] = vehicleVin
                      // Find Closest Mileage Log with the same VIN
                      if (vehicleVin != null && loopFuelLog['CALCULATED_timestamp_transaction'] != null) {
                        let closestMileageLog = findClosestMileageLog(vehicleVin, loopFuelLog['CALCULATED_timestamp_transaction'], searchableMileageLogs)
                        fuelAnalysisResults[driverKey]['fuel_logs'][loopFuelLogKey]['CALCULATED_closest_mileage_log'] = closestMileageLog
                        let sameDayMilageLogs = getSameDayMileageLogs(vehicleVin, loopFuelLog['CALCULATED_timestamp_transaction'], searchableMileageLogs)
                        fuelAnalysisResults[driverKey]['fuel_logs'][loopFuelLogKey]['CALCULATED_same_day_mileage_logs'] = sameDayMilageLogs
                      }
                    }
                  }
                }
                // Look for GPS Coordinates for Fuel Log
                let street = getProp(loopFuelLog, 'associated_merchant_address', '')
                let city = getProp(loopFuelLog, 'associated_merchant_city', '')
                let state = getProp(loopFuelLog, 'associated_merchant_state', '')
                let zip = getProp(loopFuelLog, 'associated_merchant_zip', '')
                let fuelLogKey = street.replace(/ /g, '_') + '-' + city.replace(/ /g, '_') + '-' + state.replace(/ /g, '_') + '-' + zip.replace(/ /g, '_')
                if (
                  mergedGasStationGeocodeData != null &&
                  mergedGasStationGeocodeData[fuelLogKey] != null &&
                  mergedGasStationGeocodeData[fuelLogKey]['lat'] != null &&
                  mergedGasStationGeocodeData[fuelLogKey]['lng'] != null
                ) {
                  fuelAnalysisResults[driverKey]['fuel_logs'][loopFuelLogKey]['CALCULATED_lat'] = mergedGasStationGeocodeData[fuelLogKey]['lat']
                  fuelAnalysisResults[driverKey]['fuel_logs'][loopFuelLogKey]['CALCULATED_lng'] = mergedGasStationGeocodeData[fuelLogKey]['lng']
                }
              }
              // Set to State
              us_setFuelAnalysisResults(fuelAnalysisResults)
              us_setBuildingAnalysis(false)
            })
          })
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          us_setBuildingAnalysis(false)
        })
    }
  }

  const runOdometerAnalysis = (): void => {
    if (us_odometerAnalysisStartDate != null && us_odometerAnalysisEndDate != null) {
      // Instantiate Variables
      us_setBuildingAnalysis(true)
      let promiseArray1: TsType_UnknownPromise[] = []
      let vehicleMileageLogs: TsInterface_UnspecifiedObject = {}
      // Get Data
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          promiseArray1.push(
            DatabaseGetCollection(
              DatabaseRef_VehicleMileageLogs_Query(res_GCK.clientKey, us_odometerAnalysisStartDate as Date, us_odometerAnalysisEndDate as Date),
            )
              .then((res_DGC) => {
                vehicleMileageLogs = res_DGC.data
              })
              .catch((rej_DGC) => {
                console.error(rej_DGC)
              }),
          )
          Promise.all(promiseArray1).finally(() => {
            // Group Logs by VIN
            let vinGroupedLogs: TsInterface_UnspecifiedObject = {}
            for (let loopLogKey in vehicleMileageLogs) {
              let loopLog = vehicleMileageLogs[loopLogKey]
              if (loopLog.vin != null) {
                if (vinGroupedLogs[loopLog.vin] == null) {
                  vinGroupedLogs[loopLog.vin] = {
                    vin: loopLog.vin,
                    logs: {},
                  }
                }
                vinGroupedLogs[loopLog.vin]['logs'][loopLogKey] = loopLog
              }
            }
            // Loop through logs for each VIN to determine first and last odometer readings and total mileages
            for (let loopVinKey in vinGroupedLogs) {
              let loopVin = vinGroupedLogs[loopVinKey]
              let sortedLogs = objectToArray(loopVin['logs']).sort(dynamicSort('timestamp_start', 'asc'))
              let firstOdometerReading = null
              let lastOdometerReading = null
              let firstTimestamp = null
              let lastTimestamp = null
              let totalMilesBetweenOdometerReadings = 0
              for (let loopLogIndex in sortedLogs) {
                let loopLog = sortedLogs[loopLogIndex]
                if (loopLog != null && loopLog['odometer_starting_miles'] != null) {
                  if (firstOdometerReading == null) {
                    firstOdometerReading = loopLog['odometer_starting_miles']
                    firstTimestamp = loopLog['timestamp_start']
                  }
                  lastOdometerReading = loopLog['odometer_starting_miles']
                  lastTimestamp = loopLog['timestamp_start']
                }
              }
              for (let loopLogIndex in sortedLogs) {
                let loopLog = sortedLogs[loopLogIndex]
                if (
                  loopLog != null &&
                  loopLog['distance_miles'] != null &&
                  loopLog['timestamp_start'] != null &&
                  firstTimestamp <= loopLog['timestamp_start'] &&
                  loopLog['timestamp_start'] < lastTimestamp
                ) {
                  totalMilesBetweenOdometerReadings += loopLog['distance_miles']
                }
              }
              // Totals
              vinGroupedLogs[loopVinKey]['first_odometer_reading'] = firstOdometerReading
              vinGroupedLogs[loopVinKey]['last_odometer_reading'] = lastOdometerReading
              vinGroupedLogs[loopVinKey]['first_odometer_timestamp'] = firstTimestamp
              vinGroupedLogs[loopVinKey]['last_odometer_timestamp'] = lastTimestamp
              vinGroupedLogs[loopVinKey]['total_miles_between_odometer_readings'] = totalMilesBetweenOdometerReadings
              vinGroupedLogs[loopVinKey]['difference_between_odometer_readings'] = lastOdometerReading - firstOdometerReading
              vinGroupedLogs[loopVinKey]['delta_between_odometer_and_logs'] = totalMilesBetweenOdometerReadings - (lastOdometerReading - firstOdometerReading)
            }
            us_setWeekOdometerAnalysisResults(vinGroupedLogs)
            us_setBuildingAnalysis(false)
          })
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          us_setBuildingAnalysis(false)
        })
    }
  }

  // const uploadFuelReport = (data: string[][]): void => {
  //   // Instantiate variables
  //   let formattedData: TsInterface_UnspecifiedObject = {}
  //   let uploadedFileColumns: string[] = []
  //   us_setUploadingData(true)
  //   // let dataMapping: string[] = []
  //   // Loop through and format data
  //   for (let cellIndex = 0; cellIndex < data[0].length; cellIndex++) {
  //     uploadedFileColumns[cellIndex] = data[0][cellIndex]
  //   }
  //   for (let rowIndex = 1; rowIndex < data.length; rowIndex++) {
  //     if (data != null && data[rowIndex] != null) {
  //       let loopLog = data[rowIndex]
  //       let formattedDataRow: TsInterface_UnspecifiedObject = {}
  //       let mappedLoopLog: TsInterface_UnspecifiedObject = {}
  //       for (let cellIndex = 0; cellIndex < loopLog.length; cellIndex++) {
  //         if (loopLog != null && loopLog[cellIndex] != null && uploadedFileColumns != null && uploadedFileColumns[cellIndex] != null) {
  //           mappedLoopLog[uploadedFileColumns[cellIndex]] = loopLog[cellIndex]
  //         }
  //       }
  //       for (let cellIndex = 0; cellIndex < loopLog.length; cellIndex++) {
  //         if (loopLog[cellIndex] != null && uploadedFileColumns[cellIndex]) {
  //           if (
  //             loopLog != null &&
  //             loopLog[cellIndex] != null &&
  //             uploadedFileColumns != null &&
  //             uploadedFileColumns[cellIndex] != null &&
  //             fuelImportFormatter != null &&
  //             fuelImportFormatter[uploadedFileColumns[cellIndex]] != null
  //           ) {
  //             let originalCellValue = loopLog[cellIndex]
  //             let columnHeader = uploadedFileColumns[cellIndex]
  //             let formattedCellData = fuelImportFormatter[columnHeader](mappedLoopLog, originalCellValue)
  //             if (formattedCellData != null && formattedCellData.key != null && formattedCellData.value != null) {
  //               formattedDataRow[formattedCellData.key] = formattedCellData.value
  //             }
  //           }
  //         }
  //       }
  //       if (formattedDataRow != null && formattedDataRow.timestamp_transaction != null && formattedDataRow.transaction_id != null) {
  //         // let logKey = formattedDataRow.timestamp_transaction.getTime().toString() + "_" + formattedDataRow.transaction_id
  //         let logKey = formattedDataRow.transaction_id
  //         formattedDataRow.key = logKey
  //         formattedDataRow.status = 'active'
  //         formattedData[logKey] = formattedDataRow
  //       }
  //     }
  //   }
  //   getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
  //     .then((res_GCK) => {
  //       uploadFuelReportProper(res_GCK.clientKey, objectToArray(formattedData), 0)
  //     })
  //     .catch((rej_GCK) => {
  //       uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
  //     })
  // }

  // const uploadFuelReportProper = (clientKey: string, formattedData: TsInterface_UnspecifiedObject[], startingIndex: number): void => {
  //   let batchLimit = 500
  //   let timeoutMilliseconds = 1500
  //   let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
  //   for (let loopIndex = startingIndex; loopIndex < startingIndex + batchLimit; loopIndex++) {
  //     if (formattedData[loopIndex] != null) {
  //       updateArray.push({
  //         type: 'setMerge',
  //         ref: DatabaseRef_VehicleFuelLogs_Document(clientKey, formattedData[loopIndex].key),
  //         data: formattedData[loopIndex],
  //       })
  //     }
  //   }
  //   if (formattedData.length > startingIndex + batchLimit) {
  //     DatabaseBatchUpdate(updateArray)
  //       .then((res_DBU) => {
  //         setTimeout(() => {
  //           uploadFuelReportProper(clientKey, formattedData, startingIndex + batchLimit)
  //         }, timeoutMilliseconds)
  //       })
  //       .catch((rej_DBU) => {
  //         us_setUploadingData(false)
  //         uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
  //       })
  //   } else {
  //     DatabaseBatchUpdate(updateArray)
  //       .then((res_DBU) => {
  //         us_setUploadingData(false)
  //       })
  //       .catch((rej_DBU) => {
  //         us_setUploadingData(false)
  //         uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
  //       })
  //   }
  // }

  const returnMPGValue = (totalMiles: number, totalGallons: number): null | number => {
    let mpgValue = null
    if (totalMiles != null && totalGallons != null && totalMiles > 0 && totalGallons > 0) {
      return totalMiles / totalGallons
    }
    return mpgValue
  }

  const openMileageLogTable = (mileageLogs: TsInterface_UnspecifiedObject): void => {
    if (mileageLogs != null && objectToArray(mileageLogs).length > 0) {
      uc_setUserInterface_CustomDialogDisplay({
        display: true,
        dialog: {
          dialog_jsx: (
            <Box>
              <Dialog
                TransitionComponent={TransitionSlide}
                className="bp_dialog_xl_width"
                keepMounted
                onClose={() => {
                  uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                }}
                open={true}
              >
                <AppBar
                  position="static"
                  color="inherit"
                  sx={{ background: themeVariables.background_default }}
                >
                  <Toolbar>
                    <IconButton
                      aria-label="menu"
                      color="inherit"
                      disabled
                      edge="start"
                      size="large"
                      sx={{ mr: 2, color: '#fff !important' }}
                    >
                      <Icon icon="road" />
                    </IconButton>
                    <Typography
                      component={'span'}
                      variant={'h6'}
                      sx={{ flexGrow: 1 }}
                    >
                      <Box className="tw-inline-block">{rLIB('Mileage Logs')}</Box>
                    </Typography>
                  </Toolbar>
                </AppBar>
                <DialogContent sx={{ padding: '0px', background: themeVariables.background_paper }}>
                  <TableBasic
                    tableAdditionalData={{}}
                    tableColumns={tableColumns_MileageLogs}
                    tableData={objectToArray(mileageLogs).sort(dynamicSort('timestamp_start', 'asc'))}
                    tableSettings={tableSettings_IndividualMileageLogs}
                  />
                </DialogContent>
              </Dialog>
            </Box>
          ),
          settings: {
            max_width: 'lg',
          },
        },
      })
    }
  }

  const createFuelCardPin = (): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: formInputs_FuelCardPin,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let updateObject = {
                    key: formSubmittedData.fuel_card_pin,
                    associated_user_key: formSubmittedData.associated_user_key,
                    associated_user_name: formSubmittedData.associated_user_name,
                  }
                  DatabaseSetMergeDocument(DatabaseRef_FuelLogDriverPin_Document(res_GCK.clientKey, formSubmittedData.fuel_card_pin), updateObject)
                    .then((res_DSMD) => {
                      resolve({ success: true })
                    })
                    .catch((rej_DSMD) => {
                      reject(rej_DSMD)
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    })
                })
                .catch((rej_GCK) => {
                  reject(rej_GCK)
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('Create Pin')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  // JSX Generation

  // General
  const rJSX_BackButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        color="inherit"
        variant="outlined"
        onClick={(event) => {
          onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminDatabaseVehiclesListPage.url())
        }}
        disableElevation
        startIcon={<Icon icon="chevron-left" />}
        className="tw-mr-2"
      >
        {rLIB('Back to all vehicles')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_DatePicker = (): JSX.Element => {
    let datePickerJSX = (
      <Box className="tw-inline-block tw-mr-2 tw-align-top">
        <DatePicker
          key={'calendar_date'}
          datePickerText={rLIB('Date')}
          datePickerDate={us_selectedDate}
          datePickerDisabled={false}
          datePickerDateOnChange={changeCalendarDate}
          datePickerSettings={{ thin_input: true }}
        />
      </Box>
    )
    return datePickerJSX
  }

  // Analysis Tab
  const rJSX_MileageAnalysisTable = (): JSX.Element => {
    let tableJSX = <></>
    if (us_visibleMileageTable === 'full') {
      tableJSX = (
        <Box>
          <Card className="tw-mt-2">
            <TableBasic
              tableAdditionalData={{
                weekDates: us_selectedWeekDates,
                us_activeGaragesList: us_activeGaragesList,
                us_weekMileageAnalysisGeocodedDistances: us_weekMileageAnalysisGeocodedDistances,
                us_analysisTableTotals: us_analysisTableTotals,
                us_analysisTableData: us_analysisTableData,
              }}
              tableColumns={tableColumns_MileageAnalysis}
              tableData={us_analysisTableData}
              tableSettings={tableSettings_MileageAnalysis}
            />
          </Card>
        </Box>
      )
    } else if (us_visibleMileageTable === 'summary') {
      tableJSX = (
        <Box>
          <Card className="tw-mt-2">
            <Box className="tw-p-2 tw-text-right tw-opacity-30">
              {returnFormattedDate(us_startDownloadDate, 'D MMM YYYY')} - {returnFormattedDate(us_endDownloadDate, 'D MMM YYYY')}
            </Box>
            <TableBasic
              tableAdditionalData={{
                weekDates: us_selectedWeekDates,
                us_activeGaragesList: us_activeGaragesList,
                us_weekMileageAnalysisGeocodedDistances: us_weekMileageAnalysisGeocodedDistances,
                us_analysisTableTotals: us_analysisTableTotals,
                us_analysisTableData: us_analysisTableData,
              }}
              tableColumns={tableColumns_MileageAnalysisSummary}
              tableData={us_analysisTableData}
              tableSettings={tableSettings_MileageAnalysis}
            />
          </Card>
        </Box>
      )
    }
    return tableJSX
  }

  const rJSX_RunAnalysisButtonIcon = (): JSX.Element => {
    let iconJSX = <Icon icon="calculator" />
    if (us_buildingAnalysis === true) {
      iconJSX = (
        <Icon
          icon="arrows-rotate"
          className="bp_spin"
        />
      )
    }
    return iconJSX
  }

  const rJSX_DownloadMileageReportButton = (disabled: boolean): JSX.Element => {
    let buttonJSX = (
      <Button
        color="success"
        variant="outlined"
        onClick={() => {
          downloadMileageReportExport()
        }}
        disableElevation
        disabled={disabled}
        startIcon={<Icon icon="cloud-arrow-down" />}
        className="tw-mr-2"
      >
        {rLIB('Download')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_RunMileageAnalysisButtons = (): JSX.Element => {
    let buttonsJSX = <></>
    if (us_weekMileageAnalysisResults != null && us_weekMileageAnalysisResults.timestamp_analysis_completed != null) {
      buttonsJSX = (
        <Stack direction="row">
          {rJSX_DatePicker()}
          {rJSX_ToggleTableButton()}
          <Button
            color="info"
            variant="outlined"
            onClick={() => {
              runMileageAnalysis()
            }}
            disableElevation
            disabled={us_startDownloadDate == null || us_endDownloadDate == null || us_buildingAnalysis === true}
            startIcon={rJSX_RunAnalysisButtonIcon()}
            className="tw-mr-2"
          >
            {rLIB('Calculate Analysis')}
          </Button>
          {rJSX_DownloadMileageReportButton(false)}
          <Box className="tw-pt-1.5 tw-opacity-40">
            {rLIB('Analysis Run')} {returnFormattedDate(us_weekMileageAnalysisResults.timestamp_analysis_completed, 'D MMM YY h:mm a')}
          </Box>
        </Stack>
      )
    } else {
      buttonsJSX = (
        <Stack direction="row">
          {rJSX_DatePicker()}
          {rJSX_ToggleTableButton()}
          <Button
            color="info"
            variant="contained"
            onClick={() => {
              runMileageAnalysis()
            }}
            disableElevation
            disabled={us_startDownloadDate == null || us_endDownloadDate == null || us_buildingAnalysis === true}
            startIcon={rJSX_RunAnalysisButtonIcon()}
            className="tw-mr-2"
          >
            {rLIB('Calculate Analysis')}
          </Button>
          {rJSX_DownloadMileageReportButton(true)}
        </Stack>
      )
    }
    return buttonsJSX
  }

  const rJSX_AnalysisTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Box className="tw-mt-2">
        {rJSX_RunMileageAnalysisButtons()}
        {rJSX_MileageAnalysisTable()}
      </Box>
    )
    return tabJSX
  }

  const rJSX_ToggleTableButton = (): JSX.Element => {
    let buttonJSX = <></>
    if (us_visibleMileageTable === 'full') {
      buttonJSX = (
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            us_setVisibleMileageTable('summary')
          }}
          disableElevation
          startIcon={<Icon icon="table" />}
          className="tw-mr-2"
        >
          {rLIB('Viewing Full Table')}
        </Button>
      )
    } else if (us_visibleMileageTable === 'summary') {
      buttonJSX = (
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            us_setVisibleMileageTable('full')
          }}
          disableElevation
          startIcon={<Icon icon="table" />}
          className="tw-mr-2"
        >
          {rLIB('Viewing Summary')}
        </Button>
      )
    }
    return buttonJSX
  }

  //Handle Dates for Mileage Imports
  const excelDateToJSDate = (excelDate: number): Date => {
    const excelEpoch = new Date(1899, 11, 30).getTime() // Correct Excel epoch starts at 1899-12-30
    const dayOffset = excelDate * 86400000 // Convert days to milliseconds
    return new Date(excelEpoch + dayOffset)
  }

  const parseTimestamp = (timestamp: string | number, timezone: string): Date | null => {
    if (timestamp === 'NA') {
      return null
    }

    try {
      let dt: DateTime

      // Handle Excel date format
      if (typeof timestamp === 'number') {
        dt = DateTime.fromJSDate(excelDateToJSDate(timestamp))
      } else {
        // Handle string timestamp with timezone
        if (timezone && timezoneAbbreviations[timezone]) {
          dt = DateTime.fromFormat(timestamp, 'MM/dd/yy H:mm', { zone: timezoneAbbreviations[timezone] })
        } else {
          dt = DateTime.fromISO(timestamp)
        }
      }

      if (!dt.isValid) {
        console.error(`Invalid timestamp format: ${timestamp}`)
        return new Date(0) // Return Unix epoch if invalid
      }

      // Convert to UTC-6
      const dtInUtc6 = dt.setZone('America/Chicago', { keepLocalTime: true })
      return dtInUtc6.toJSDate()
    } catch (error) {
      console.error(`Error parsing timestamp: ${timestamp} with error: ${error}`)
      return new Date(0) // Return Unix epoch in case of error
    }
  }

  const formatTimestampForKey = (date: Date): string => {
    return DateTime.fromJSDate(date).toFormat('MM_dd_yy_HH_mm_ss')
  }

  //Import Mileage Logs Button
  const rJSX_ImportMileageButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = (
      <BasicImportButtonAndDialog
        importAdditionalData={{}}
        importButtonColor={'info'}
        importButtonShrink={shrink}
        importButtonText={rLIB('Import Mileage')}
        importDialogHeader={rLIB('Confirm Mileage Import')}
        importMappingOptions={{
          vin: { key: 'vin', required: true, label: rLIB('Vin'), automatch_properties: ['Vin'] },
          associated_driver_name: { key: 'associated_driver_name', required: true, label: rLIB('Driver Name'), automatch_properties: ['Driver Name'] },
          associated_customer_id: { key: 'associated_customer_id', required: true, label: rLIB('Customer ID'), automatch_properties: ['Customer ID'] },
          associated_event: { key: 'associated_event', required: false, label: rLIB('Event'), automatch_properties: ['Event'] },
          timezone: { key: 'timezone', required: false, label: rLIB('Timezone'), automatch_properties: ['Timezone'] },
          timestamp_start: { key: 'timestamp_start', required: false, label: rLIB('Start Time'), automatch_properties: ['Start Time'] },
          timestamp_end: { key: 'timestamp_end', required: false, label: rLIB('End Time'), automatch_properties: ['End Time'] },
          duration_minutes: { key: 'duration_minutes', required: false, label: rLIB('Minutes'), automatch_properties: ['Duration (mins)'] },
          distance_miles: { key: 'distance_miles', required: false, label: rLIB('Miles'), automatch_properties: ['Distance (mi)'] },
          odometer_starting_miles: {
            key: 'odometer_starting_miles',
            required: false,
            label: rLIB('Starting Odometer'),
            automatch_properties: ['Starting Odometer (mi)'],
          },
          location_start_latitude: { key: 'location_start_latitude', required: false, label: rLIB('Start Lat'), automatch_properties: ['Start Location Lat'] },
          location_start_longitude: {
            key: 'location_start_longitude',
            required: false,
            label: rLIB('Start Lng'),
            automatch_properties: ['Start Location Lon'],
          },
          location_end_latitude: { key: 'location_end_latitude', required: false, label: rLIB('End Lat'), automatch_properties: ['End Location Lat'] },
          location_end_longitude: { key: 'location_end_longitude', required: false, label: rLIB('End Lng'), automatch_properties: ['End Location Lon'] },
        }}
        importSubmission={(spreadsheetData, importAdditionalData, importHooks) => {
          return new Promise((resolve, reject) => {
            try {
              let consolidatedData: TsInterface_UnspecifiedObject = {}
              for (let loopRowKey in spreadsheetData) {
                let loopRow = spreadsheetData[loopRowKey]
                if (
                  loopRow != null &&
                  loopRow['vin'] != null &&
                  loopRow['vin'] !== '' &&
                  loopRow['associated_driver_name'] != null &&
                  loopRow['associated_driver_name'] !== ''
                ) {
                  let timezone = loopRow['timezone']
                  let timestampStart = parseTimestamp(loopRow['timestamp_start'], timezone)
                  let timestampEnd = parseTimestamp(loopRow['timestamp_end'], timezone)
                  if (timestampStart != null && timestampEnd != null) {
                    let formattedTimestamp = formatTimestampForKey(timestampStart)
                    let mileageItemKey = loopRow['vin'] + '-' + formattedTimestamp + '-' + loopRow['associated_event']
                    // let formattedDataRow: TsInterface_UnspecifiedObject = removeUndefinedValuesFromObject(loopRow)
                    let formattedDataRow: TsInterface_UnspecifiedObject = {}
                    for (let loopPropKey in loopRow) {
                      let loopPropValue = loopRow[loopPropKey]
                      if (loopPropValue != null) {
                        if (loopPropKey != null && mileageImportFormatter != null && mileageImportFormatter[loopPropKey] != null) {
                          formattedDataRow[loopPropKey] = mileageImportFormatter[loopPropKey](loopRow, loopPropValue).value
                        } else {
                          formattedDataRow[loopPropKey] = loopPropValue
                        }
                      }
                    }
                    formattedDataRow['key'] = mileageItemKey
                    formattedDataRow['status'] = 'active'
                    formattedDataRow['timestamp_start'] = timestampStart
                    formattedDataRow['timestamp_end'] = timestampEnd
                    consolidatedData[mileageItemKey] = formattedDataRow
                  } else {
                    console.error('Invalid timestamps for row:', loopRowKey, loopRow)
                  }
                } else {
                  console.error('Missing required fields in row:', loopRowKey, loopRow)
                }
              }
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  // Create Update Array
                  let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
                  for (let loopRowKey in consolidatedData) {
                    updateArray.push({
                      type: 'setMerge',
                      ref: DatabaseRef_VehicleMileageLogs_Document(res_GCK.clientKey as string, loopRowKey),
                      data: consolidatedData[loopRowKey],
                    })
                  }
                  // Save to Database
                  DatabaseStagedBatchUpdate(updateArray)
                    .then((res_DSBU) => {
                      resolve(res_DSBU)
                    })
                    .catch((rej_DSBU) => {
                      console.error('Batch Update Error:', rej_DSBU)
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSBU.error })
                      reject(rej_DSBU)
                    })
                })
                .catch((rej_GCK) => {
                  console.error('Client Key Error:', rej_GCK)
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            } catch (error) {
              console.error('Error during import submission:', error)
              reject(error)
            }
          })
        }}
      />
    )
    return buttonJSX
  }

  // Mileage Logs Tab
  const rJSX_MileageLogsTab = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tabJSX = (
        <Box className="tw-mt-2">
          {rJSX_ImportMileageButton(false)}
          {rJSX_DatePicker()}
          {rJSX_DownloadButton()}

          <Card className="tw-mt-2">
            <TableDatabase
              tableAdditionalData={{}}
              tableColumns={tableColumns_MileageLogs}
              tableDatabaseEndpoint={tableDatabaseEndpoint_MileageLogs}
              tableSettings={tableSettings_MileageLogs}
            />
          </Card>
        </Box>
      )
    } else {
      tabJSX = <></>
    }
    return tabJSX
  }

  const rJSX_DownloadButtonIcon = (): JSX.Element => {
    let iconJSX = <Icon icon="cloud-arrow-down" />
    if (us_downloadingReport === true) {
      iconJSX = (
        <Icon
          icon="arrows-rotate"
          className="bp_spin"
        />
      )
    }
    return iconJSX
  }

  const rJSX_DownloadButton = (): JSX.Element => {
    let buttonJSX = <></>
    let dateTextJSX = <></>
    if (us_startDownloadDate != null && us_endDownloadDate != null) {
      dateTextJSX = (
        <Box className="tw-ml-1">
          ({returnFormattedDate(us_startDownloadDate, 'D MMM YY')} - {returnFormattedDate(us_endDownloadDate, 'D MMM YY')})
        </Box>
      )
    }
    buttonJSX = (
      <Button
        color="info"
        variant="contained"
        onClick={() => {
          downloadVehicleRawLogsReport()
        }}
        disableElevation
        disabled={us_startDownloadDate == null || us_endDownloadDate == null || us_downloadingReport === true}
        startIcon={rJSX_DownloadButtonIcon()}
        className="tw-mr-2"
      >
        {rLIB('Download')}
        {dateTextJSX}
      </Button>
    )
    return buttonJSX
  }

  // Odometer Analysis Tab
  const rJSX_OdometerStartDatePicker = (): JSX.Element => {
    let datePickerJSX = (
      <Box className="tw-inline-block tw-mr-2 tw-align-top">
        <DatePicker
          key={'calendar_date'}
          datePickerText={rLIB('Start Date')}
          datePickerDate={us_odometerAnalysisStartDate}
          datePickerDisabled={false}
          datePickerDateOnChange={(newDate) => {
            return new Promise((resolve, reject) => {
              us_setOdometerAnalysisStartDate(new Date(newDate))
              us_setWeekOdometerAnalysisResults({})
              ur_forceRerender()
              resolve({ success: true })
            })
          }}
          datePickerSettings={{ thin_input: true }}
        />
      </Box>
    )
    return datePickerJSX
  }

  const rJSX_OdometerEndDatePicker = (): JSX.Element => {
    let datePickerJSX = (
      <Box className="tw-inline-block tw-mr-2 tw-align-top">
        <DatePicker
          key={'calendar_date'}
          datePickerText={rLIB('End Date')}
          datePickerDate={us_odometerAnalysisEndDate}
          datePickerDisabled={false}
          datePickerDateOnChange={(newDate) => {
            return new Promise((resolve, reject) => {
              us_setOdometerAnalysisEndDate(new Date(newDate))
              us_setWeekOdometerAnalysisResults({})
              ur_forceRerender()
              resolve({ success: true })
            })
          }}
          datePickerSettings={{ thin_input: true }}
        />
      </Box>
    )
    return datePickerJSX
  }

  const rJSX_RunOdometerAnalysisButtons = (): JSX.Element => {
    let buttonsJSX = <></>
    buttonsJSX = (
      <Stack direction="row">
        {rJSX_OdometerStartDatePicker()}
        {rJSX_OdometerEndDatePicker()}
        <Button
          color="info"
          variant="contained"
          onClick={() => {
            runOdometerAnalysis()
          }}
          disableElevation
          disabled={us_startDownloadDate == null || us_endDownloadDate == null || us_buildingAnalysis === true}
          startIcon={rJSX_RunAnalysisButtonIcon()}
          className="tw-mr-2"
        >
          {rLIB('Run Analysis')}
        </Button>
      </Stack>
    )
    return buttonsJSX
  }

  const rJSX_OdometerAnalysisData = (): JSX.Element => {
    let resultsJSX = <></>
    resultsJSX = (
      <Box className="tw-mt-2">
        <Card className="tw-mt-2">
          <TableBasic
            tableAdditionalData={{}}
            tableColumns={tableColumns_OdometerAnalysis}
            tableData={objectToArray(us_weekOdometerAnalysisResults)}
            tableSettings={tableSettings_OdometerAnalysis}
          />
        </Card>
      </Box>
    )
    return resultsJSX
  }

  const rJSX_OdometerAnalysisTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Box className="tw-mt-2">
        {rJSX_RunOdometerAnalysisButtons()}
        {rJSX_OdometerAnalysisData()}
      </Box>
    )
    return tabJSX
  }

  // Fuel Analysis Tab
  const rJSX_FuelStartDatePicker = (): JSX.Element => {
    let datePickerJSX = (
      <Box className="tw-inline-block tw-mr-2 tw-align-top">
        <DatePicker
          key={'calendar_date'}
          datePickerText={rLIB('Start Date')}
          datePickerDate={us_fuelAnalysisStartDate}
          datePickerDisabled={false}
          datePickerDateOnChange={(newDate) => {
            return new Promise((resolve, reject) => {
              us_setFuelAnalysisStartDate(new Date(newDate))
              us_setFuelAnalysisResults({})
              ur_forceRerender()
              resolve({ success: true })
            })
          }}
          datePickerSettings={{ thin_input: true }}
        />
      </Box>
    )
    return datePickerJSX
  }

  const rJSX_FuelEndDatePicker = (): JSX.Element => {
    let datePickerJSX = (
      <Box className="tw-inline-block tw-mr-2 tw-align-top">
        <DatePicker
          key={'calendar_date'}
          datePickerText={rLIB('End Date')}
          datePickerDate={us_fuelAnalysisEndDate}
          datePickerDisabled={false}
          datePickerDateOnChange={(newDate) => {
            return new Promise((resolve, reject) => {
              us_setFuelAnalysisEndDate(new Date(newDate))
              us_setFuelAnalysisResults({})
              ur_forceRerender()
              resolve({ success: true })
            })
          }}
          datePickerSettings={{ thin_input: true }}
        />
      </Box>
    )
    return datePickerJSX
  }

  const rJSX_FuelDataLinkTab = (): JSX.Element => {
    let dataJSX = (
      <Box className="tw-p-4">
        <Typography variant="h6">
          <Icon
            icon="file-csv"
            className="tw-mr-2"
            sx={{ color: themeVariables.warning_main }}
          />
          {rLIB('Fuel Logs')}
        </Typography>
        <Box className="tw-mt-2">
          <Button
            color="warning"
            variant="outlined"
            className="tw-mr-0"
          >
            <Icon
              icon="box"
              className="tw-mr-2"
            />
            {rLIB('Employee')}
          </Button>
          <Box className="tw-inline-block tw-px-2 tw-ml-2">
            <Icon icon="equals" />
          </Box>
          <Button
            color="inherit"
            variant="outlined"
            className="tw-ml-2"
          >
            <Icon
              icon="file-csv"
              className="tw-mr-2"
            />
            Driver First Name + Driver Last Name
          </Button>
        </Box>
        <Box className="tw-mt-2 tw-opacity-30 tw-pl-4">*{rLIB('Not linked to database users at all')}</Box>
        <Box className="tw-mt-2">
          <Button
            color="warning"
            variant="outlined"
            className="tw-mr-0"
          >
            <Icon
              icon="box"
              className="tw-mr-2"
            />
            {rLIB('Card Number')}
          </Button>
          <Box className="tw-inline-block tw-px-2 tw-ml-2">
            <Icon icon="equals" />
          </Box>
          <Button
            color="info"
            variant="outlined"
            className="tw-ml-2"
          >
            <Icon
              icon="file-csv"
              className="tw-mr-2"
            />
            Card Number
          </Button>
        </Box>
        <Box className="tw-mt-2">
          <Button
            color="warning"
            variant="outlined"
            className="tw-mr-0"
          >
            <Icon
              icon="box"
              className="tw-mr-2"
            />
            {rLIB('Date')}
          </Button>
          <Box className="tw-inline-block tw-px-2 tw-ml-2">
            <Icon icon="equals" />
          </Box>
          <Button
            color="inherit"
            variant="outlined"
            className="tw-ml-2"
          >
            <Icon
              icon="file-csv"
              className="tw-mr-2"
            />
            Transaction Date
          </Button>
        </Box>
        <Box className="tw-mt-2">
          <Button
            color="warning"
            variant="outlined"
            className="tw-mr-0"
          >
            <Icon
              icon="box"
              className="tw-mr-2"
            />
            {rLIB('Gallons')}
          </Button>
          <Box className="tw-inline-block tw-px-2 tw-ml-2">
            <Icon icon="equals" />
          </Box>
          <Button
            color="inherit"
            variant="outlined"
            className="tw-ml-2"
          >
            <Icon
              icon="file-csv"
              className="tw-mr-2"
            />
            Units
          </Button>
        </Box>
        <Box className="tw-mt-2">
          <Button
            color="warning"
            variant="outlined"
            className="tw-mr-0"
          >
            <Icon
              icon="box"
              className="tw-mr-2"
            />
            {rLIB('Dollars per Gallon')}
          </Button>
          <Box className="tw-inline-block tw-px-2 tw-ml-2">
            <Icon icon="equals" />
          </Box>
          <Button
            color="inherit"
            variant="outlined"
            className="tw-ml-2"
          >
            <Icon
              icon="file-csv"
              className="tw-mr-2"
            />
            Unit Cost
          </Button>
        </Box>
        <Box className="tw-mt-2">
          <Button
            color="warning"
            variant="outlined"
            className="tw-mr-0"
          >
            <Icon
              icon="box"
              className="tw-mr-2"
            />
            {rLIB('Total Price')}
          </Button>
          <Box className="tw-inline-block tw-px-2 tw-ml-2">
            <Icon icon="equals" />
          </Box>
          <Button
            color="inherit"
            variant="outlined"
            className="tw-ml-2"
          >
            <Icon
              icon="file-csv"
              className="tw-mr-2"
            />
            Total Fuel Cost
          </Button>
        </Box>
        <Box className="tw-mt-2">
          <Button
            color="warning"
            variant="outlined"
            className="tw-mr-0"
          >
            <Icon
              icon="box"
              className="tw-mr-2"
            />
            {rLIB('Fuel Type')}
          </Button>
          <Box className="tw-inline-block tw-px-2 tw-ml-2">
            <Icon icon="equals" />
          </Box>
          <Button
            color="inherit"
            variant="outlined"
            className="tw-ml-2"
          >
            <Icon
              icon="file-csv"
              className="tw-mr-2"
            />
            Product Description
          </Button>
        </Box>
        <Box className="tw-mt-2">
          <Button
            color="warning"
            variant="outlined"
            className="tw-mr-0"
          >
            <Icon
              icon="box"
              className="tw-mr-2"
            />
            {rLIB('Address')}
          </Button>
          <Box className="tw-inline-block tw-px-2 tw-ml-2">
            <Icon icon="equals" />
          </Box>
          <Button
            color="inherit"
            variant="outlined"
            className="tw-ml-2"
          >
            <Icon
              icon="file-csv"
              className="tw-mr-2"
            />
            Merchant Address + City + State + Postal Code
          </Button>
        </Box>
        <Box className="tw-mt-2">
          <Button
            color="warning"
            variant="outlined"
            className="tw-mr-0"
          >
            <Icon
              icon="box"
              className="tw-mr-2"
            />
            Driver Pin
          </Button>
          <Box className="tw-inline-block tw-px-2 tw-ml-2">
            <Icon icon="equals" />
          </Box>
          <Button
            color="info"
            variant="outlined"
            className="tw-ml-2"
          >
            <Icon
              icon="file-csv"
              className="tw-mr-2"
            />
            Driver Prompt ID
          </Button>
        </Box>
        <Box className="tw-mt-2">
          <Button
            color="warning"
            variant="outlined"
            className="tw-mr-0"
          >
            <Icon
              icon="box"
              className="tw-mr-2"
            />
            {rLIB('Coordinates')}
          </Button>
          <Box className="tw-inline-block tw-px-2 tw-ml-2">
            <Icon icon="equals" />
          </Box>
          <Button
            color="error"
            variant="outlined"
            className="tw-ml-2"
          >
            <Icon
              icon="map-location-dot"
              className="tw-mr-2"
            />
            Google Geocoding
          </Button>
        </Box>
        <Divider className="tw-my-2" />
        <Typography variant="h6">
          <Icon
            icon="file-csv"
            className="tw-mr-2"
            sx={{ color: themeVariables.success_main }}
          />
          {rLIB('Mileage Logs')}
        </Typography>
        <Box className="tw-mt-2">
          <Button
            color="success"
            variant="outlined"
            className="tw-mr-0"
          >
            <Icon
              icon="box"
              className="tw-mr-2"
            />
            {rLIB('Date')}
          </Button>
          <Box className="tw-inline-block tw-px-2 tw-ml-2">
            <Icon icon="equals" />
          </Box>
          <Button
            color="inherit"
            variant="outlined"
            className="tw-ml-2"
          >
            <Icon
              icon="file-csv"
              className="tw-mr-2"
            />
            Start Time
          </Button>
        </Box>
        <Box className="tw-mt-2">
          <Button
            color="success"
            variant="outlined"
            className="tw-mr-0"
          >
            <Icon
              icon="box"
              className="tw-mr-2"
            />
            {rLIB('Miles')}
          </Button>
          <Box className="tw-inline-block tw-px-2 tw-ml-2">
            <Icon icon="equals" />
          </Box>
          <Button
            color="inherit"
            variant="outlined"
            className="tw-ml-2"
          >
            <Icon
              icon="file-csv"
              className="tw-mr-2"
            />
            Distance (mi)
          </Button>
        </Box>
        <Box className="tw-mt-2">
          <Button
            color="success"
            variant="outlined"
            className="tw-mr-0"
          >
            <Icon
              icon="box"
              className="tw-mr-2"
            />
            {rLIB('Coordinates')}
          </Button>
          <Box className="tw-inline-block tw-px-2 tw-ml-2">
            <Icon icon="equals" />
          </Box>
          <Button
            color="inherit"
            variant="outlined"
            className="tw-ml-2"
          >
            <Icon
              icon="file-csv"
              className="tw-mr-2"
            />
            Start Location Lat + Start Location Lon
          </Button>
        </Box>
        <Box className="tw-mt-2">
          <Button
            color="success"
            variant="outlined"
            className="tw-mr-0"
          >
            <Icon
              icon="box"
              className="tw-mr-2"
            />
            {rLIB('VIN')}
          </Button>
          <Box className="tw-inline-block tw-px-2 tw-ml-2">
            <Icon icon="equals" />
          </Box>
          <Button
            color="info"
            variant="outlined"
            className="tw-ml-2"
          >
            <Icon
              icon="file-csv"
              className="tw-mr-2"
            />
            Vin
          </Button>
        </Box>
        <Divider className="tw-my-2" />
        <Typography variant="h6">
          <Icon
            icon="link"
            className="tw-mr-2"
            sx={{ color: themeVariables.secondary_main }}
          />
          {rLIB('Linking Data')}
        </Typography>
        <Box className="tw-mt-2">
          <Button
            color="warning"
            variant="outlined"
            className="tw-mr-0"
          >
            <Icon
              icon="box"
              className="tw-mr-2"
            />
            Card Number
          </Button>
          <Box className="tw-inline-block tw-px-2 tw-ml-2">
            <Icon icon="greater-than" />
          </Box>
          <Button
            color="info"
            variant="outlined"
            className="tw-ml-2"
          >
            <Icon
              icon="database"
              className="tw-mr-2"
            />
            Vehicle
          </Button>
          <Button
            color="info"
            variant="outlined"
            className="tw-ml-2"
          >
            <Icon
              icon="database"
              className="tw-mr-2"
            />
            VIN
          </Button>
          <Box className="tw-inline-block tw-px-2 tw-ml-2">
            <Icon icon="greater-than" />
          </Box>
          <Button
            color="success"
            variant="outlined"
            className="tw-ml-2"
          >
            <Icon
              icon="file-csv"
              className="tw-mr-2"
            />
            Mileage Log
          </Button>
        </Box>
        <Box className="tw-mt-2">
          <Button
            color="warning"
            variant="outlined"
            className="tw-mr-0"
          >
            <Icon
              icon="box"
              className="tw-mr-2"
            />
            Driver Pin
          </Button>
          <Box className="tw-inline-block tw-px-2 tw-ml-2">
            <Icon icon="greater-than" />
          </Box>
          <Button
            color="info"
            variant="outlined"
            className="tw-ml-2"
          >
            <Icon
              icon="database"
              className="tw-mr-2"
            />
            User
          </Button>
          <Button
            color="info"
            variant="outlined"
            className="tw-ml-2"
          >
            <Icon
              icon="database"
              className="tw-mr-2"
            />
            Key
          </Button>
          <Box className="tw-inline-block tw-px-2 tw-ml-2">
            <Icon icon="greater-than" />
          </Box>
          <Button
            color="error"
            variant="outlined"
            className="tw-ml-2"
          >
            <Icon
              icon="map-location-dot"
              className="tw-mr-2"
            />
            Timecard GPS Data
          </Button>
        </Box>
        <Divider className="tw-my-2" />
      </Box>
    )
    return dataJSX
  }

  const rJSX_FuelDataLedendTab = (): JSX.Element => {
    let tabJSX = (
      <Box className="tw-p-4">
        <Typography
          variant="h6"
          className="tw-mb-2"
        >
          {rLIB('Miles per Gallon')}
        </Typography>
        <Box>
          <Box className="tw-inline-block tw-rounded tw-mr-2 tw-mb-2 tw-px-2 tw-py-1 tw-bg-gray_800">Likely missing some mileage logs</Box>
          <Box className="tw-inline-block tw-rounded tw-mr-2 tw-mb-2 tw-px-2 tw-py-1 tw-bg-error_main">0 - 6 mpg</Box>
          <Box className="tw-inline-block tw-rounded tw-mr-2 tw-mb-2 tw-px-2 tw-py-1 tw-bg-warning_main">6 - 8 mpg</Box>
          <Box className="tw-inline-block tw-rounded tw-mr-2 tw-mb-2 tw-px-2 tw-py-1 tw-bg-success_main">8 - 16 mpg</Box>
          <Box className="tw-inline-block tw-rounded tw-mr-2 tw-mb-2 tw-px-2 tw-py-1 tw-bg-warning_main">16 - 20 mpg</Box>
          <Box className="tw-inline-block tw-rounded tw-mr-2 tw-mb-2 tw-px-2 tw-py-1 tw-bg-error_main">20+ mpg</Box>
        </Box>
        <Divider className="tw-my-2" />
        <Typography
          variant="h6"
          className="tw-mb-2"
        >
          {rLIB('Coordinate Distance Discrepancies')}
        </Typography>
        <Box>
          <Box className="tw-inline-block tw-rounded tw-mr-2 tw-mb-2 tw-px-2 tw-py-1 tw-bg-gray_800">15+ minute difference</Box>
          <Box className="tw-inline-block tw-rounded tw-mr-2 tw-mb-2 tw-px-2 tw-py-1 tw-bg-error_main">5+ mile difference</Box>
          <Box className="tw-inline-block tw-rounded tw-mr-2 tw-mb-2 tw-px-2 tw-py-1 tw-bg-orange_500">1 - 5 mile difference</Box>
          <Box className="tw-inline-block tw-rounded tw-mr-2 tw-mb-2 tw-px-2 tw-py-1 tw-bg-warning_main">0.5 - 1 mile difference</Box>
          <Box className="tw-inline-block tw-rounded tw-mr-2 tw-mb-2 tw-px-2 tw-py-1 tw-bg-success_main">0 - 0.5 mile difference</Box>
        </Box>
        <Divider className="tw-my-2" />
        <Typography
          variant="h6"
          className="tw-mb-2"
        >
          {rLIB('Coordinate Time Discrepancies')}
        </Typography>
        <Box>
          <Box className="tw-inline-block tw-rounded tw-mr-2 tw-mb-2 tw-px-2 tw-py-1 tw-border-error_main tw-border tw-border-solid">15+ minute difference</Box>
          <Box className="tw-inline-block tw-rounded tw-mr-2 tw-mb-2 tw-px-2 tw-py-1 tw-border-warning_main tw-border tw-border-solid">
            5 - 15 minute difference
          </Box>
          <Box className="tw-inline-block tw-rounded tw-mr-2 tw-mb-2 tw-px-2 tw-py-1 tw-border-success_main tw-border tw-border-solid">
            0 - 5 minute difference
          </Box>
        </Box>
      </Box>
    )
    return tabJSX
  }

  const rJSX_FuelAnalysisInfoDialog = (): JSX.Element => {
    let dialogJSX = (
      <Box>
        <Dialog
          TransitionComponent={TransitionSlide}
          className="bp_dialog_lg_width"
          keepMounted
          onClose={() => {
            uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
          }}
          open={true}
        >
          <AppBar
            position="static"
            color="inherit"
            sx={{ background: themeVariables.background_default }}
          >
            <Toolbar>
              <IconButton
                aria-label="menu"
                color="inherit"
                disabled
                edge="start"
                size="large"
                sx={{ mr: 2, color: '#fff !important' }}
              >
                <Icon icon="circle-info" />
              </IconButton>
              <Typography
                component={'span'}
                variant={'h6'}
                sx={{ flexGrow: 1 }}
              >
                {rLIB('Fuel Analysis info')}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent sx={{ padding: '0px', background: themeVariables.background_paper }}>
            <TabsBasic
              tabs={[
                { tabHeader: rLIB('Legend'), tabContent: <Box>{rJSX_FuelDataLedendTab()}</Box> },
                { tabHeader: rLIB('How fuel data is linked'), tabContent: <Box>{rJSX_FuelDataLinkTab()}</Box> },
              ]}
              tabsSettings={{}}
            />
          </DialogContent>
        </Dialog>
      </Box>
    )
    return dialogJSX
  }

  const rJSX_FuelInfoButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        color="primary"
        variant="outlined"
        className="tw-text-center"
        sx={{ paddingLeft: '2px !important', paddingRight: '2px !important' }}
        onClick={() => {
          uc_setUserInterface_CustomDialogDisplay({
            display: true,
            dialog: {
              dialog_jsx: rJSX_FuelAnalysisInfoDialog(),
              settings: {
                max_width: 'lg',
              },
            },
          })
        }}
        disableElevation
      >
        <Icon
          icon="circle-info"
          type="thin"
          sx={{ fontSize: '24px' }}
        />
      </Button>
    )
    return buttonJSX
  }

  const rJSX_RunFuelAnalysisButtons = (): JSX.Element => {
    let buttonsJSX = <></>
    buttonsJSX = (
      <Stack direction="row">
        {rJSX_FuelStartDatePicker()}
        {rJSX_FuelEndDatePicker()}
        {/* { rJSX_ToggleFuelTableButton() } */}
        <Button
          color="info"
          variant="contained"
          onClick={() => {
            runFuelAnalysis()
          }}
          disableElevation
          disabled={us_startDownloadDate == null || us_endDownloadDate == null || us_buildingAnalysis === true}
          startIcon={rJSX_RunAnalysisButtonIcon()}
          className="tw-mr-2"
        >
          {rLIB('Run Analysis')}
        </Button>
        {rJSX_FuelInfoButton()}
      </Stack>
    )
    return buttonsJSX
  }

  const rJSX_FuelAnalysisTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Box className="tw-mt-2">
        {rJSX_RunFuelAnalysisButtons()}
        {rJSX_FuelAnalysisTable()}
      </Box>
    )
    return tabJSX
  }

  const rJSX_MPG = (totalMiles: number, totalGallons: number): JSX.Element => {
    let mpgJSX = <></>
    if (totalMiles != null && totalGallons != null && totalMiles > 0 && totalGallons > 0) {
      mpgJSX = (
        <Box className="tw-inline-block">
          <Box
            component="span"
            className="tw-mr-2"
          >
            {(totalMiles / totalGallons).toFixed(1)}
          </Box>
          <Box component="span">{rLIB('mpg')}</Box>
        </Box>
      )
    }
    return mpgJSX
  }

  const measureDistanceBetweenTwoPoints = (lat1: number, lon1: number, lat2: number, lon2: number) => {
    // https://en.wikipedia.org/wiki/Haversine_formula
    let R = 6378.137 // Radius of earth in KM
    let dLat = (lat2 * Math.PI) / 180 - (lat1 * Math.PI) / 180
    let dLon = (lon2 * Math.PI) / 180 - (lon1 * Math.PI) / 180
    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    let d = R * c
    // return d * 1000; // meters
    return (d * 1000) / 1609.34 // miles
  }

  const minutesBetweenDates = (date1: Date, date2: Date) => {
    // Parse the dates as JavaScript Date objects
    const firstDate = new Date(date1).getTime()
    const secondDate = new Date(date2).getTime()
    // Calculate the difference in milliseconds
    const differenceInMilliseconds = Math.abs(secondDate - firstDate)
    // Convert milliseconds to minutes (1 minute = 60000 milliseconds)
    const minutes = differenceInMilliseconds / (1000 * 60)
    return minutes
  }

  const timeBetweenDates = (date1: Date, date2: Date) => {
    const firstDate = new Date(date1).getTime()
    const secondDate = new Date(date2).getTime()
    // Calculate the difference in minutes
    let differenceInMinutes = Math.abs(secondDate - firstDate) / (1000 * 60)
    // Round to the nearest minute
    differenceInMinutes = Math.round(differenceInMinutes)
    // Determine if the result should be in minutes, hours, or days
    if (differenceInMinutes >= 60) {
      let differenceInHours = differenceInMinutes / 60
      if (differenceInHours >= 24) {
        // Convert hours to days and round to the nearest whole number
        const differenceInDays = Math.round(differenceInHours / 24)
        return differenceInDays === 1 ? `1 day` : `${differenceInDays} days`
      } else {
        // Round to the nearest hour if not converting to days
        differenceInHours = Math.round(differenceInHours)
        return differenceInHours === 1 ? `1 hour` : `${differenceInHours} hours`
      }
    } else {
      // Return in minutes if less than an hour
      return differenceInMinutes === 1 ? `1 minute` : `${differenceInMinutes} minutes`
    }
  }

  const rJSX_TableRow = (
    driverData: TsInterface_UnspecifiedObject,
    allSortedFuelLogs: TsInterface_UnspecifiedObject[],
    fuelLogIndex: number,
    fuelLog: TsInterface_UnspecifiedObject,
  ) => {
    let rowJSX = <></>
    // Difference in time
    let timeDifference = null
    let timeDifferenceJSX = <></>
    let timeDetailsJSX = <></>
    if (
      fuelLog != null &&
      fuelLog.CALCULATED_timestamp_transaction != null &&
      fuelLog.CALCULATED_closest_mileage_log != null &&
      fuelLog.CALCULATED_closest_mileage_log.timestamp_start != null
    ) {
      timeDifference = minutesBetweenDates(fuelLog.CALCULATED_timestamp_transaction, fuelLog.CALCULATED_closest_mileage_log.timestamp_start)
      let minutesDifference = timeBetweenDates(fuelLog.CALCULATED_timestamp_transaction, fuelLog.CALCULATED_closest_mileage_log.timestamp_start)
      timeDifferenceJSX = <Box component="span">{minutesDifference}</Box>
      timeDetailsJSX = (
        <Box>
          <Box>
            <Icon
              icon="gas-pump"
              className="tw-mr-2"
            />{' '}
            {returnFormattedDate(fuelLog.CALCULATED_timestamp_transaction, 'D MMM YYYY h:mm a')}
          </Box>
          <Divider />
          <Box>
            <Icon
              icon="map-location-dot"
              className="tw-mr-2"
            />{' '}
            {returnFormattedDate(fuelLog.CALCULATED_closest_mileage_log.timestamp_start, 'D MMM YYYY h:mm a')}
          </Box>
        </Box>
      )
    } else {
      timeDetailsJSX = <Box className="tw-italic tw-opacity-30">{rLIB('Missing Timestamps')}</Box>
    }
    // Distance at Coordinates
    let distanceMessageJSX = <></>
    if (
      fuelLog != null &&
      fuelLog.CALCULATED_lat != null &&
      fuelLog.CALCULATED_lng != null &&
      fuelLog.CALCULATED_closest_mileage_log != null &&
      fuelLog.CALCULATED_closest_mileage_log.location_start_latitude != null &&
      fuelLog.CALCULATED_closest_mileage_log.location_start_longitude != null
    ) {
      let distance = measureDistanceBetweenTwoPoints(
        fuelLog.CALCULATED_lat,
        fuelLog.CALCULATED_lng,
        fuelLog.CALCULATED_closest_mileage_log.location_start_latitude,
        fuelLog.CALCULATED_closest_mileage_log.location_start_longitude,
      )
      let distanceClassName = 'tw-inline-block tw-rounded tw-mr-2 tw-mb-1 tw-px-2 tw-py-1'
      let timeClassName = 'tw-inline-block tw-rounded tw-mr-2 tw-mb-1 tw-px-2 tw-py-1'
      if (timeDifference != null && timeDifference > 15) {
        distanceClassName = 'tw-inline-block tw-rounded tw-mr-2 tw-mb-1 tw-px-2 tw-py-1 tw-bg-gray_800'
        timeClassName = 'tw-inline-block tw-rounded tw-mr-2 tw-mb-1 tw-px-2 tw-py-1 tw-border-error_main tw-border tw-border-solid'
      } else if (timeDifference != null && timeDifference > 5) {
        if (distance > 5) {
          distanceClassName = 'tw-inline-block tw-rounded tw-mr-2 tw-mb-1 tw-px-2 tw-py-1 tw-bg-error_main'
        } else if (distance > 1) {
          distanceClassName = 'tw-inline-block tw-rounded tw-mr-2 tw-mb-1 tw-px-2 tw-py-1 tw-bg-orange_500'
        } else if (distance > 0.5) {
          distanceClassName = 'tw-inline-block tw-rounded tw-mr-2 tw-mb-1 tw-px-2 tw-py-1 tw-bg-warning_main'
        } else {
          distanceClassName = 'tw-inline-block tw-rounded tw-mr-2 tw-mb-1 tw-px-2 tw-py-1 tw-bg-success_main'
        }
        timeClassName = 'tw-inline-block tw-rounded tw-mr-2 tw-mb-1 tw-px-2 tw-py-1 tw-border-warning_main tw-border tw-border-solid'
      } else if (timeDifference != null && (timeDifference > 0 || timeDifference === 0)) {
        if (distance > 5) {
          distanceClassName = 'tw-inline-block tw-rounded tw-mr-2 tw-mb-1 tw-px-2 tw-py-1 tw-bg-error_main'
        } else if (distance > 1) {
          distanceClassName = 'tw-inline-block tw-rounded tw-mr-2 tw-mb-1 tw-px-2 tw-py-1 tw-bg-orange_500'
        } else if (distance > 0.5) {
          distanceClassName = 'tw-inline-block tw-rounded tw-mr-2 tw-mb-1 tw-px-2 tw-py-1 tw-bg-warning_main'
        } else {
          distanceClassName = 'tw-inline-block tw-rounded tw-mr-2 tw-mb-1 tw-px-2 tw-py-1 tw-bg-success_main'
        }
        timeClassName = 'tw-inline-block tw-rounded tw-mr-2 tw-mb-1 tw-px-2 tw-py-1 tw-border-success_main tw-border tw-border-solid'
      }
      distanceMessageJSX = (
        <Box>
          <Box className={distanceClassName}>
            {distance.toFixed(1)} {rLIB('mi')}
          </Box>
          <Box className={timeClassName}>
            <Tooltip
              title={timeDetailsJSX}
              placement="left"
            >
              <Box className="tw-opacity-30">{timeDifferenceJSX}</Box>
            </Tooltip>
          </Box>
        </Box>
      )
    } else if (fuelLog != null && fuelLog.CALCULATED_lat != null && fuelLog.CALCULATED_lng != null) {
      distanceMessageJSX = <Box className="tw-italic tw-opacity-30">{rLIB('No vehicle mileage log')}</Box>
    } else if (
      fuelLog != null &&
      fuelLog.CALCULATED_closest_mileage_log != null &&
      fuelLog.CALCULATED_closest_mileage_log.location_start_latitude != null &&
      fuelLog.CALCULATED_closest_mileage_log.location_start_longitude != null
    ) {
      distanceMessageJSX = <Box className="tw-italic tw-opacity-30">{rLIB('No coordinates for gas station')}</Box>
    } else {
      distanceMessageJSX = <Box className="tw-italic tw-opacity-30">{rLIB('No coordinates for gas station or vehicle log')}</Box>
    }
    // VIN
    let vinJSX = <></>
    if (fuelLog.CALCULATED_associated_vehicle_vin != null) {
      vinJSX = <Box className="tw-opacity-30">{fuelLog.CALCULATED_associated_vehicle_vin}</Box>
    } else if (fuelLog.card_number != null) {
      vinJSX = (
        <Box sx={{ color: themeVariables.error_main }}>
          {rLIB('Missing Card Number')}: {fuelLog.card_number}
        </Box>
      )
    }
    // MPG
    let mpgJSX = <></>
    if (fuelLog != null && fuelLog.CALCULATED_fuel_log_index === 0 && fuelLog.CALCULATED_fuel_log_totals != null) {
      let mpgActualJSX = <></>
      if (fuelLog.CALCULATED_fuel_log_totals.total_miles > 0) {
        mpgActualJSX = (
          <Box className="tw-inline-block tw-rounded tw-mr-2 tw-px-2 tw-py-1 tw-bg-gray_800">
            <Box className="tw-opacity-30">
              {fuelLog.CALCULATED_fuel_log_totals.total_miles}
              {rJSX_MPG(fuelLog.CALCULATED_fuel_log_totals.total_miles, fuelLog.CALCULATED_fuel_log_totals.gallons)}
            </Box>
          </Box>
        )
      }
      mpgJSX = (
        <Box>
          {mpgActualJSX}
          <Box
            className="tw-opacity-30 tw-pt-1"
            onDoubleClick={() => {
              openMileageLogTable(getProp(fuelLog, 'CALCULATED_mileage_logs', {}))
            }}
          >
            {fuelLog.CALCULATED_fuel_log_totals.total_miles.toFixed(1)} {rLIB('mi')} ({fuelLog.CALCULATED_fuel_log_totals.total_mile_logs}{' '}
            {rLIB('Mileage Logs')})
          </Box>
        </Box>
      )
    } else if (fuelLog != null && fuelLog.CALCULATED_fuel_log_index > 0 && fuelLog.CALCULATED_fuel_log_totals != null) {
      let mpgActualJSX = <></>
      if (fuelLog.CALCULATED_fuel_log_totals.total_miles > 0) {
        let mpgValue = returnMPGValue(fuelLog.CALCULATED_fuel_log_totals.total_miles, fuelLog.CALCULATED_fuel_log_totals.gallons)
        if (mpgValue != null) {
          let mpgClassName = 'tw-inline-block tw-rounded tw-mr-2 tw-px-2 tw-py-1 tw-bg-gray_800'
          if (mpgValue < 6) {
            mpgClassName = 'tw-inline-block tw-rounded tw-mr-2 tw-px-2 tw-py-1 tw-bg-error_main'
          } else if (mpgValue < 8) {
            mpgClassName = 'tw-inline-block tw-rounded tw-mr-2 tw-px-2 tw-py-1 tw-bg-warning_main'
          } else if (mpgValue < 16) {
            mpgClassName = 'tw-inline-block tw-rounded tw-mr-2 tw-px-2 tw-py-1 tw-bg-success_main'
          } else if (mpgValue < 20) {
            mpgClassName = 'tw-inline-block tw-rounded tw-mr-2 tw-px-2 tw-py-1 tw-bg-warning_main'
          } else {
            mpgClassName = 'tw-inline-block tw-rounded tw-mr-2 tw-px-2 tw-py-1 tw-bg-error_main'
          }
          mpgActualJSX = (
            <Box className={mpgClassName}>{rJSX_MPG(fuelLog.CALCULATED_fuel_log_totals.total_miles, fuelLog.CALCULATED_fuel_log_totals.gallons)}</Box>
          )
        }
      }
      mpgJSX = (
        <Box>
          {mpgActualJSX}
          <Box
            className="tw-opacity-30 tw-pt-1"
            onDoubleClick={() => {
              openMileageLogTable(getProp(fuelLog, 'CALCULATED_mileage_logs', {}))
            }}
          >
            {fuelLog.CALCULATED_fuel_log_totals.total_miles.toFixed(1)} {rLIB('mi')} ({fuelLog.CALCULATED_fuel_log_totals.total_mile_logs}{' '}
            {rLIB('Mileage Logs')})
          </Box>
        </Box>
      )
    }
    // Map Button JSX
    let mapButtonColor: TsType_MuiComponentColors = 'warning'
    if (fuelLog.CALCULATED_associated_user_key != null) {
      mapButtonColor = 'info'
    }
    // Full JSX
    rowJSX = (
      <TableRow key={fuelLogIndex}>
        <TableCell sx={{ width: '20px' }}></TableCell>
        <TableCell>
          <Box
            onDoubleClick={() => {
              uc_setUserInterface_CustomDialogDisplay({
                display: true,
                dialog: {
                  dialog_jsx: (
                    <Box>
                      <Dialog
                        TransitionComponent={TransitionSlide}
                        className="bp_dialog_lg_width"
                        keepMounted
                        onClose={() => {
                          uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                        }}
                        open={true}
                      >
                        <DialogContent sx={{ padding: '0px' }}>
                          <Json
                            data={fuelLog}
                            alphabetized={true}
                          />
                        </DialogContent>
                      </Dialog>
                    </Box>
                  ),
                  settings: {
                    max_width: 'lg',
                  },
                },
              })
            }}
          >
            {returnFormattedDate(fuelLog.CALCULATED_timestamp_transaction, 'D MMM YYYY h:mm a')}
          </Box>
          {vinJSX}
        </TableCell>
        <TableCell>
          <Box>
            {fuelLog.gallons} {rLIB('gal')}
          </Box>
          <Box className="tw-opacity-30">{fuelLog.associated_product_name}</Box>
        </TableCell>
        <TableCell>
          <Box>${fuelLog.total_dollars}</Box>
          <Box className="tw-opacity-30">
            ${fuelLog.dollars_per_gallon}/{rLIB('gal')}
          </Box>
        </TableCell>
        <TableCell>
          <Box>{mpgJSX}</Box>
        </TableCell>
        <TableCell>
          <Box>{distanceMessageJSX}</Box>
        </TableCell>
        <TableCell>
          <Button
            variant="outlined"
            color={mapButtonColor}
            className=""
            disabled={fuelLog.CALCULATED_timestamp_transaction == null}
            onClick={() => {
              let dateKey = returnFormattedDateKey(fuelLog.CALCULATED_timestamp_transaction)
              let garage: TsInterface_UnspecifiedObject = {}
              if (
                fuelLog != null &&
                fuelLog.CALCULATED_associated_user_key != null &&
                us_usersWithGaragesList != null &&
                us_usersWithGaragesList[fuelLog.CALCULATED_associated_user_key] != null &&
                us_usersWithGaragesList[fuelLog.CALCULATED_associated_user_key]['associated_garage_key'] != null &&
                us_activeGaragesList != null &&
                us_activeGaragesList[us_usersWithGaragesList[fuelLog.CALCULATED_associated_user_key]['associated_garage_key']] != null
              ) {
                garage = us_activeGaragesList[us_usersWithGaragesList[fuelLog.CALCULATED_associated_user_key]['associated_garage_key']]
              }
              let dialogJSX = (
                <FuelAnalysisDayCustomDialog
                  garage={garage}
                  driverData={driverData}
                  dateKey={dateKey}
                  fuelLog={fuelLog}
                />
              )
              uc_setUserInterface_CustomDialogDisplay({
                display: true,
                dialog: {
                  dialog_jsx: dialogJSX,
                  settings: {
                    max_width: 'lg',
                  },
                },
              })
            }}
          >
            <Icon
              icon="map-location-dot"
              className="tw-mr-2"
            />
            {rLIB('Map')}
          </Button>
        </TableCell>
      </TableRow>
    )
    return rowJSX
  }

  const rJSX_FuelAnalysisTable = (): JSX.Element => {
    let tableJSX = (
      <Box className="tw-mt-2">
        <Card className="tw-mt-2">
          <TableContainer>
            <Table size="small">
              {objectToArray(us_fuelAnalysisResults)
                .sort(dynamicSort('name', 'asc'))
                .map((driverData, driverIndex) => (
                  <TableBody key={driverIndex}>
                    <TableRow sx={{ background: themeVariables.background_json }}>
                      <TableCell colSpan={7}>
                        <Box>
                          <Box className="">{getProp(driverData, 'name', '')}</Box>
                          {objectToArray(getProp(driverData, 'vehicle_vins', {}))
                            .sort()
                            .map((vin, vinIndex) => (
                              <Box
                                key={vinIndex}
                                className="tw-italic tw-opacity-30"
                              >
                                {vin}
                              </Box>
                            ))}
                        </Box>
                      </TableCell>
                    </TableRow>
                    {objectToArray(driverData.fuel_logs)
                      .sort(dynamicSort('CALCULATED_timestamp_transaction', 'asc'))
                      .map((fuelLog, fuelLogIndex) => (
                        <React.Fragment key={fuelLogIndex}>
                          {rJSX_TableRow(
                            driverData,
                            objectToArray(driverData.fuel_logs).sort(dynamicSort('CALCULATED_timestamp_transaction', 'asc')),
                            fuelLogIndex,
                            fuelLog,
                          )}
                        </React.Fragment>
                      ))}
                  </TableBody>
                ))}
            </Table>
          </TableContainer>
        </Card>
      </Box>
    )
    return tableJSX
  }

  //Import Fuel Logs Button
  const rJSX_ImportFuelButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = (
      <BasicImportButtonAndDialog
        importAdditionalData={{}}
        importButtonColor={'info'}
        importButtonShrink={shrink}
        importButtonText={rLIB('Import Fuel')}
        importDialogHeader={rLIB('Confirm Fuel Import')}
        importMappingOptions={{
          associated_vehicle_vin: { key: 'associated_vehicle_vin', required: true, label: rLIB('Vin'), automatch_properties: ['Vin'] },
          associated_driver_first_name: {
            key: 'associated_driver_first_name',
            required: true,
            label: rLIB('Driver First Name'),
            automatch_properties: ['Driver First Name'],
          },
          associated_driver_last_name: {
            key: 'associated_driver_last_name',
            required: true,
            label: rLIB('Driver Last Name'),
            automatch_properties: ['Driver Last Name'],
          },
          associated_driver_pin: { key: 'associated_driver_pin', required: true, label: rLIB('Driver Pin'), automatch_properties: ['Pin'] },
          associated_merchant_address: {
            key: 'associated_merchant_address',
            required: false,
            label: rLIB('Merchant Address'),
            automatch_properties: ['Merchant Address'],
          },
          associated_merchant_brand: {
            key: 'associated_merchant_brand',
            required: false,
            label: rLIB('Merchant Brand'),
            automatch_properties: ['Merchant Brand'],
          },
          associated_merchant_city: { key: 'associated_merchant_city', required: false, label: rLIB('Merchant City'), automatch_properties: ['Merchant City'] },
          associated_merchant_name: { key: 'associated_merchant_name', required: false, label: rLIB('Merchant Name'), automatch_properties: ['Merchant Name'] },
          associated_merchant_site_id: {
            key: 'associated_merchant_site_id',
            required: false,
            label: rLIB('Merchant Site ID'),
            automatch_properties: ['Merchant Site ID'],
          },
          associated_merchant_state: {
            key: 'associated_merchant_state',
            required: false,
            label: rLIB('Merchant State'),
            automatch_properties: ['Merchant State'],
          },
          associated_merchant_zip: {
            key: 'associated_merchant_zip',
            required: false,
            label: rLIB('Merchant Zip'),
            automatch_properties: ['Merchant Zip'],
          },
          associated_product_key: { key: 'associated_product_key', required: false, label: rLIB('Product Key'), automatch_properties: ['Product Key'] },
          associated_product_name: {
            key: 'associated_product_name',
            required: false,
            label: rLIB('Product Name'),
            automatch_properties: ['Product Name'],
          },
          card_number: { key: 'card_number', required: false, label: rLIB('Card Number'), automatch_properties: ['Card Number'] },
          dollars_per_gallon: { key: 'dollars_per_gallon', required: false, label: rLIB('Dollars Per Gallon'), automatch_properties: ['Dollars Per Gallon'] },
          gallons: { key: 'gallons', required: false, label: rLIB('Gallons'), automatch_properties: ['Gallons'] },
          timestamp_transaction: { key: 'timestamp_transaction', required: false, label: rLIB('Transaction Date'), automatch_properties: ['Transaction Date'] },
          total_dollars: { key: 'total_dollars', required: false, label: rLIB('Total'), automatch_properties: ['Total'] },
          transaction_id: { key: 'transaction_id', required: false, label: rLIB('Transaction ID'), automatch_properties: ['Transaction ID'] },
        }}
        importSubmission={(spreadsheetData, importAdditionalData, importHooks) => {
          return new Promise((resolve, reject) => {
            try {
              let consolidatedData: TsInterface_UnspecifiedObject = {}
              for (let loopRowKey in spreadsheetData) {
                let loopRow = spreadsheetData[loopRowKey]
                if (
                  loopRow != null &&
                  loopRow['associated_vehicle_vin'] != null &&
                  loopRow['associated_vehicle_vin'] !== '' &&
                  loopRow['transaction_id'] != null &&
                  loopRow['transaction_id'] !== ''
                ) {
                  // Convert specific fields to string
                  loopRow['associated_merchant_zip'] = String(loopRow['associated_merchant_zip'])
                  loopRow['transaction_id'] = String(loopRow['transaction_id'])
                  loopRow['associated_merchant_site_id'] = String(loopRow['associated_merchant_site_id'])
                  loopRow['associated_driver_pin'] = String(loopRow['associated_driver_pin'])
                  let timezone = loopRow['timezone']
                  let timestampTransaction = parseTimestamp(loopRow['timestamp_transaction'], timezone)
                  if (timestampTransaction != null) {
                    let fuelLogKey = loopRow['transaction_id']
                    // let formattedDataRow: TsInterface_UnspecifiedObject = removeUndefinedValuesFromObject(loopRow)
                    let formattedDataRow: TsInterface_UnspecifiedObject = {}
                    for (let loopPropKey in loopRow) {
                      let loopPropValue = loopRow[loopPropKey]
                      if (loopPropValue != null) {
                        if (loopPropKey != null && fuelImportFormatter != null && fuelImportFormatter[loopPropKey] != null) {
                          formattedDataRow[loopPropKey] = fuelImportFormatter[loopPropKey](loopRow, loopPropValue).value
                        } else {
                          formattedDataRow[loopPropKey] = loopPropValue
                        }
                      }
                    }
                    formattedDataRow['key'] = fuelLogKey
                    formattedDataRow['status'] = 'active'
                    formattedDataRow['timestamp_transaction'] = timestampTransaction
                    consolidatedData[fuelLogKey] = formattedDataRow
                  }
                }
              }
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  // Create Update Array
                  let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
                  for (let loopRowKey in consolidatedData) {
                    updateArray.push({
                      type: 'setMerge',
                      ref: DatabaseRef_VehicleFuelLogs_Document(res_GCK.clientKey as string, loopRowKey),
                      data: consolidatedData[loopRowKey],
                    })
                  }
                  // Save to Database
                  DatabaseStagedBatchUpdate(updateArray)
                    .then((res_DSBU) => {
                      resolve(res_DSBU)
                    })
                    .catch((rej_DSBU) => {
                      console.error('Batch Update Error:', rej_DSBU)
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSBU.error })
                      reject(rej_DSBU)
                    })
                })
                .catch((rej_GCK) => {
                  console.error('Client Key Error:', rej_GCK)
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            } catch (error) {
              console.error('Error during import submission:', error)
              reject(error)
            }
          })
        }}
      />
    )

    return buttonJSX
  }

  // Fuel Logs Tab
  const rJSX_FuelLogsTab = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tabJSX = (
        <Box className="tw-mt-2">
          {rJSX_ImportFuelButton(false)}
          <Card className="tw-mt-2">
            <TableDatabase
              tableAdditionalData={{}}
              tableColumns={tableColumns_FuelLogs}
              tableDatabaseEndpoint={tableDatabaseEndpoint_FuelLogs}
              tableSettings={tableSettings_FuelLogs}
            />
          </Card>
        </Box>
      )
    } else {
      tabJSX = <></>
    }
    return tabJSX
  }

  // Fuel Pins Tab
  const rJSX_NewFuelPinButton = (): JSX.Element => {
    let buttonJSX = <></>
    buttonJSX = (
      <Button
        color="success"
        variant="contained"
        onClick={() => {
          createFuelCardPin()
        }}
        disableElevation
        className="tw-mr-2"
        startIcon={
          <Icon
            icon="circle-plus"
            type="solid"
          />
        }
      >
        {rLIB('New Fuel Card Pin')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_FuelPinsTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Box className="tw-mt-2">
        {rJSX_NewFuelPinButton()}
        <Card className="tw-mt-2">
          <TableBasic
            tableAdditionalData={{}}
            tableColumns={tableColumns_FuelLogDriverPins}
            tableData={objectToArray(us_fuelLogDriverPins).sort(dynamicSort('associated_user_name', 'asc'))}
            tableSettings={tableSettings_FuelLogDriverPins}
          />
        </Card>
      </Box>
    )
    return tabJSX
  }

  // Mileage Allocation Tab
  const rJSX_MileageAllocationTab = (): JSX.Element => {
    let tabJSX = <></>
    let selectedDateKey = null
    if (us_startDownloadDate != null) {
      selectedDateKey = returnFormattedDateKey(us_startDownloadDate)
    }
    tabJSX = (
      <Box className="tw-mt-2">
        <Box>{rJSX_DatePicker()}</Box>
        <Card className="tw-mt-2">
          <TableBasic
            tableAdditionalData={{
              us_startDownloadDate: us_startDownloadDate,
              us_endDownloadDate: us_endDownloadDate,
              selectedDateKey: selectedDateKey,
              us_additionalMileRequests: us_additionalMileRequests,
              us_scheduledWorkMiles: us_scheduledWorkMiles,
              us_personalCommuteMiles: us_personalCommuteMiles,
            }}
            tableColumns={tableColumns_MileageAllocation}
            tableData={objectToArray(us_mileageAllocationTableData).sort(dynamicSort('name', null))}
            tableSettings={tableSettings_MileageAllocation}
          />
        </Card>
      </Box>
    )
    return tabJSX
  }

  // Garage Tab
  const rJSX_GaragesTab = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tabJSX = (
        <Box className="tw-mt-2">
          {rJSX_NewGarageButton()}
          <Card className="tw-mt-2">
            <TableDatabase
              tableAdditionalData={{}}
              tableColumns={tableColumns_ActiveGarages}
              tableDatabaseEndpoint={tableDatabaseEndpoint_ActiveGarages}
              tableSettings={tableSettings_ActiveGarages}
            />
          </Card>
        </Box>
      )
    } else {
      tabJSX = <></>
    }
    return tabJSX
  }

  const rJSX_NewGarageButton = (): JSX.Element => {
    let buttonJSX = <></>
    buttonJSX = (
      <Button
        color="success"
        variant="contained"
        onClick={() => {
          createGarage()
        }}
        disableElevation
        className="tw-mr-2"
        startIcon={
          <Icon
            icon="circle-plus"
            type="solid"
          />
        }
      >
        {rLIB('New Garage')}
      </Button>
    )
    return buttonJSX
  }

  const tableColumns_Vehicles: TsInterface_TableColumns = {
    userName: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('User Name')
        },
        header_sort_by: 'userName',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = (
            <Box
              className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
              sx={{ 'background': themeVariables.background_default, '&:hover': { background: themeVariables.background_json } }}
              onClick={(event) => {
                const userKey = rowData.userKey
                const userName = rowData.userName
                if (userKey != null) {
                  const destinationUrl = ApplicationPages.AdminDatabaseFuelUseUserViewPage.url(userKey as any)
                  onClickAppNavigation(event, tableHooks.un_routerNavigation, destinationUrl)
                } else {
                  console.warn('User key is null or undefined. Navigation skipped.')
                }
              }}
            >
              {rowData.userName}
            </Box>
          )
          return cellJSX
        },
      },
    },
    totalExpectedSpent: {
      header: {
        header_jsx: () => 'Expected Spend',
        header_sort_by: 'totalExpectedSpent',
      },
      cell: {
        cell_jsx: (rowData: any) => {
          return <span>{formatCurrency(parseFloat(rowData.totalExpectedSpent))}</span>
        },
      },
    },
    formattedTotalSpent: {
      header: {
        header_jsx: () => 'Actual Spend',
        header_sort_by: 'formattedTotalSpent',
      },
      cell: {
        cell_jsx: (rowData: any) => {
          return <span>{formatCurrency(parseFloat(rowData.formattedTotalSpent))}</span>
        },
      },
    },
    differenceSpent: {
      header: {
        header_jsx: () => 'Difference in Spend',
        header_sort_by: 'differenceSpent',
      },
      cell: {
        cell_jsx: (rowData: any) => {
          return (
            <Box
              sx={{
                color: parseFloat(rowData.differenceSpent) > 0 ? themeVariables.error_main : themeVariables.success_main,
                fontWeight: 'bold',
              }}
            >
              {formatCurrency(parseFloat(rowData.differenceSpent))}
            </Box>
          )
        },
      },
    },

    totalExpectedMiles: {
      header: {
        header_jsx: () => 'Expected Miles',
        header_sort_by: 'totalExpectedMiles',
      },
      cell: {
        cell_jsx: (rowData: any) => {
          return <span>{rowData.totalExpectedMiles}</span>
        },
      },
    },
    actualMilesDriven: {
      header: {
        header_jsx: () => 'Actual Miles',
        header_sort_by: 'actualMilesDriven',
      },
      cell: {
        cell_jsx: (rowData: any) => {
          return <span>{rowData.actualMilesDriven}</span>
        },
      },
    },
    differenceMiles: {
      header: {
        header_jsx: () => 'Difference in Miles',
        header_sort_by: 'differenceMiles',
      },
      cell: {
        cell_jsx: (rowData: any) => {
          return (
            <Box
              sx={{
                color: parseFloat(rowData.differenceMiles) > 0 ? themeVariables.error_main : themeVariables.success_main,
                fontWeight: 'bold',
              }}
            >
              {rowData.differenceMiles}
            </Box>
          )
        },
      },
    },
  }

  const tableSettings_Vehicles: TsInterface_TableSettings = {
    show_header: true,
    size: 'small',
    sort_direction: 'desc',
    sort_property_default: 'differenceSpent', // Default sorting by the 'day' field

    collapsible_columns: true,

    sticky_header: true, // Enable sticky header
    sortable: true, // Enable column sorting
  }

  const rJSX_VehiclesTable = (): JSX.Element => {
    return (
      <TableBasic
        tableAdditionalData={{}}
        tableColumns={tableColumns_Vehicles}
        tableData={tableData}
        tableSettings={tableSettings_Vehicles}
      />
    )
  }

  const rJSX_VehicleDashboardTab = (): JSX.Element => {
    // Fetch and Cache Transactions for the Selected Month
    // const fetchAndCacheTransactions = async () => {
    //   if (!us_selectedMonth) {
    //     console.warn('Please select a month before fetching transactions.')
    //     return
    //   }

    //   // Set up start and end dates for the selected month
    //   const startDate = new Date(us_selectedMonth.getFullYear(), us_selectedMonth.getMonth(), 1)
    //   const endDate = new Date(us_selectedMonth.getFullYear(), us_selectedMonth.getMonth() + 1, 0, 23, 59, 59)

    //   // Generate array of Mondays for the selected month
    //   const mondays = getMondaysForMonthBoundary(us_selectedMonth.getFullYear(), us_selectedMonth.getMonth())

    //   try {
    //     const response: any = await cloudFunctionManageRequest('manageFinances', {
    //       function: 'cacheVehicleTransactionsData',
    //       client_key: 'etw_energy',
    //       startDate,
    //       endDate,
    //       mondays, // Pass the Mondays array to the server
    //       saveOrReturnUpdateArray: 'save',
    //     })

    //     if (response?.success) {
    //       console.log('Transactions successfully fetched and cached.')
    //     }
    //   } catch (error) {
    //     console.error('Error during fetch and cache:', error)
    //   }
    // }

    const fetchAndCacheAuthorizations = async () => {
      if (!us_selectedMonth) {
        console.warn('Please select a month before fetching authorizations.')
        return
      }

      setLoading(true)

      // Set up start and end dates for the selected month
      const startDate = new Date(us_selectedMonth.getFullYear(), us_selectedMonth.getMonth(), 1)
      const endDate = new Date(us_selectedMonth.getFullYear(), us_selectedMonth.getMonth() + 1, 0, 23, 59, 59)

      // Generate array of Mondays for the selected month
      const mondays = getMondaysForMonthBoundary(us_selectedMonth.getFullYear(), us_selectedMonth.getMonth())

      try {
        const response: any = await cloudFunctionManageRequest('manageFinances', {
          function: 'cacheVehicleAuthorizationsData',
          client_key: 'etw_energy',
          startDate,
          endDate,
          mondays, // Pass the Mondays array to the server
          saveOrReturnUpdateArray: 'save',
        })

        if (response?.success) {
          console.log('Authorizations successfully fetched and cached.')
          setLoading(false)
        }
      } catch (error) {
        console.error('Error during fetch and cache:', error)
      }
    }

    // Rendering the Tab with Month Picker and Button
    const tabJSX = (
      <Box>
        <Box
          display="flex"
          alignItems="center"
          gap={2}
          className="tw-my-4"
        >
          {/* Month Picker */}
          <TextField
            label="Select Month"
            type="month"
            value={us_selectedMonth ? us_selectedMonth.toISOString().slice(0, 7) : ''}
            size="small"
            onChange={(e) => {
              const [year, month] = e.target.value.split('-')
              const newDate = new Date(Number(year), Number(month) - 1)
              setSelectedMonth(newDate)
            }}
            InputLabelProps={{ shrink: true }}
          />

          {/* Fetch and Cache Button */}
          {/* <Button
            variant="contained"
            color="primary"
            onClick={fetchAndCacheTransactions}
            disabled={!us_selectedMonth}
          >
            Refresh Selected Month
          </Button> */}

          <Tooltip
            title={`Refreshing the authorizations for the month: ${us_selectedMonthName}`}
            arrow
          >
            <Button
              variant="contained"
              color="primary"
              onClick={fetchAndCacheAuthorizations}
              disabled={!us_selectedMonth || loading} // Disable the button while loading
              startIcon={loading ? <CircularProgress size={20} /> : null} // Show loading spinner
            >
              Refresh Selected Month
            </Button>
          </Tooltip>
        </Box>

        <Card>
          <Box> {rJSX_VehiclesTable()}</Box>
          {/* <Json data={us_combinedVehicles}></Json> */}
          {/* <Json data={us_authorizationsData}></Json> */}
        </Card>
      </Box>
    )

    return tabJSX
  }

  // Page
  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Vehicle Usage')}
        pageKey={pageKey}
        content={
          <Box>
            <Box>{rJSX_BackButton()}</Box>
            <TabsUrl
              tabsSettings={{
                baseUrl: ApplicationPages.AdminDatabaseVehicleUsageReportsPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Vehicle Usage', false) as string,
              }}
              tabs={[
                // {
                //   tabUrlKey: 'Mileage_Analysis',
                //   tabHeader: rLIB('Mileage Analysis'),
                //   tabContent: rJSX_AnalysisTab(),
                // },

                {
                  tabUrlKey: 'Mileage_Allocation',
                  tabHeader: rLIB('Mileage Allocation'),
                  tabContent: rJSX_MileageAllocationTab(),
                },
                {
                  tabUrlKey: 'Vehicle_Dashboard',
                  tabHeader: rLIB('Fuel Use Analysis'),
                  tabContent: rJSX_VehicleDashboardTab(),
                },
                {
                  tabUrlKey: 'Mileage_Logs',
                  tabHeader: rLIB('Mileage Imports'),
                  tabContent: rJSX_MileageLogsTab(),
                },

                // {
                //   tabUrlKey: 'Odometer_Analysis',
                //   tabHeader: rLIB('Odometer Analysis'),
                //   tabContent: rJSX_OdometerAnalysisTab(),
                // },
                // {
                //   tabUrlKey: 'Fuel_Analysis',
                //   tabHeader: rLIB('Fuel Analysis'),
                //   tabContent: rJSX_FuelAnalysisTab(),
                // },
                // {
                //   tabUrlKey: 'Fuel_Logs',
                //   tabHeader: rLIB('Fuel Imports'),
                //   tabContent: rJSX_FuelLogsTab(),
                // },
                // {
                //   tabUrlKey: 'Fuel_Pins',
                //   tabHeader: rLIB('Fuel Pins'),
                //   tabContent: rJSX_FuelPinsTab(),
                // },
                {
                  tabUrlKey: 'Garages',
                  tabHeader: rLIB('Garages'),
                  tabContent: rJSX_GaragesTab(),
                },
              ]}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
