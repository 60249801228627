///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Model files contains data and business logic specific to an individual database collection type

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { rLIB } from 'rfbp_core/localization/library'
// import React from 'react'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

// TODO - maybe change based on client key
export const hardCodedAllocationTypes: TsInterface_UnspecifiedObject = {
  admin_time: {
    key: 'admin_time',
    name: 'Admin Time',
    name_short: rLIB('Admin'),
    status: 'active',
  },
  break: {
    key: 'break',
    name: 'Break',
    name_short: rLIB('Break'),
    status: 'active',
  },
  field_work: {
    key: 'field_work',
    name: 'Field Work',
    name_short: rLIB('Field'),
    status: 'active',
  },
  non_working_time: {
    key: 'non_working_time',
    name: 'Non Working Time',
    name_short: rLIB('Off'),
    status: 'active',
  },
}
