///////////////////////////////
// Imports
///////////////////////////////

import { Box, Chip, Tooltip } from '@mui/material'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_Order_Document } from 'rfbp_aux/services/database_endpoints/materials/orders'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TableCellManage,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { getProp } from 'rfbp_core/services/helper_functions'
import { capitalizeAllWordsInString } from 'rfbp_core/services/helper_functions/capitalize_all_words_in_string'
import { directAppNavigation, onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'

///////////////////////////////
// Exports
///////////////////////////////

export const tableSettings_MaterialOrders: TsInterface_TableSettings = {
  paginated: true,
  pagination_rows_per_page_default: 100,
  pagination_rows_per_page_options: [10, 25, 50, 100],
  show_header: true,
  sortable: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property_default: 'timestamp_scheduled_pickup_date_key',
}

export const tableColumns_MaterialOrders: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{rLIB('View')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          directAppNavigation(tableHooks.un_routerNavigation, ApplicationPages.AdminDatabaseMaterialsOrderViewPage.url(rowData.key as string))
        }
      },
    },
    delete: {
      icon: (
        <Icon
          type="solid"
          icon="trash"
        />
      ),
      label: <>{rLIB('Delete')}</>,
      conditional_display: {
        active: true,
        logic_type: 'and',
        source: null,
        prop: null,
        comparator: null,
        value: null,
        conditions: [
          {
            active: true,
            logic_type: 'comparison',
            source: 'rowData',
            prop: 'status',
            comparator: '==',
            value: 'new',
            conditions: [],
          },
        ],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              icon: <Icon icon="trash" />,
              header: rLIB('Delete Order'),
              text: rLIB('Are you sure that you want to delete this order?'),
              submit_text: rLIB('Delete'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      if (res_GCK.clientKey != null && rowData.key != null) {
                        let updateObject = {
                          status: 'deleted',
                        }
                        DatabaseSetMergeDocument(DatabaseRef_Order_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                          .then((res_DSMD) => {
                            resolve(res_DSMD)
                          })
                          .catch((rej_DSMD) => {
                            tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            reject(rej_DSMD)
                          })
                      }
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      reject(rej_GCK)
                    })
                })
              },
            },
          })
        }
      },
    },
    undelete: {
      icon: (
        <Icon
          type="solid"
          icon="magic-wand-sparkles"
        />
      ),
      label: <>{rLIB('Undelete')}</>,
      conditional_display: {
        active: true,
        logic_type: 'and',
        source: null,
        prop: null,
        comparator: null,
        value: null,
        conditions: [
          {
            active: true,
            logic_type: 'comparison',
            source: 'rowData',
            prop: 'status',
            comparator: '==',
            value: 'deleted',
            conditions: [],
          },
        ],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'warning',
              icon: <Icon icon="magic-wand-sparkles" />,
              header: rLIB('Undelete Order'),
              text: rLIB('Are you sure that you want to undelete this order?'),
              submit_text: rLIB('Undelete'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      if (res_GCK.clientKey != null && rowData.key != null) {
                        let updateObject = {
                          status: 'new',
                        }
                        DatabaseSetMergeDocument(DatabaseRef_Order_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                          .then((res_DSMD) => {
                            resolve(res_DSMD)
                          })
                          .catch((rej_DSMD) => {
                            tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            reject(rej_DSMD)
                          })
                      }
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      reject(rej_GCK)
                    })
                })
              },
            },
          })
        }
      },
    },
  }),
  po_number: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('PO Number')
      },
      header_sort_by: 'po_number',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let chipColor: 'default' | 'success' | 'primary' | 'secondary' | 'error' | 'info' | 'warning' = 'default'
        let iconJSX = <></>
        switch (rowData.status) {
          case 'new':
            chipColor = 'primary'
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="square-pen"
                className="tw-mr-2"
              />
            )
            break
          case 'deleted':
            chipColor = 'error'
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="trash"
                className="tw-mr-2"
              />
            )
            break
          case 'locked':
            chipColor = 'warning'
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="lock"
                className="tw-mr-2"
              />
            )
            break
          case 'submitted':
            chipColor = 'warning'
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="paper-plane"
                className="tw-mr-2"
              />
            )
            break
          case 'confirmed':
            chipColor = 'success'
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="badge-check"
                className="tw-mr-2"
              />
            )
            break
          // case "picked_up":
          // iconJSX =
          // <Icon sx={{ fontSize: "16px" }}  icon="truck-ramp-box" className="tw-mr-2" />
          // break
          case 'invoiced':
            chipColor = 'info'
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="message-dollar"
                className="tw-mr-2"
              />
            )
            break
        }
        let cellJSX = (
          <Tooltip
            title={
              <>
                {capitalizeAllWordsInString(getProp(rowData, 'status', ''))} | {rLIB('Pickup Date')} -{' '}
                {getProp(rowData, 'timestamp_scheduled_pickup_date_key', '')}
              </>
            }
            placement="right"
          >
            <Chip
              label={
                <Box className="tw-align-top">
                  {iconJSX}
                  {rowData.po_number}
                </Box>
              }
              color={chipColor}
              variant="outlined"
              className="tw-cursor-pointer"
              sx={{ margin: '2px' }}
              onClick={(event) => {
                onClickAppNavigation(event, tableHooks.un_routerNavigation, ApplicationPages.AdminDatabaseMaterialsOrderViewPage.url(rowData.key as string))
              }}
            />
          </Tooltip>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  timestamp_scheduled_pickup_date_key: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Scheduled Date')
      },
      header_sort_by: 'delivery_timestamp_scheduled_pickup_date_keytype',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = (
          <Box>
            {getProp(rowData, 'timestamp_scheduled_pickup_date_key', <></>)}
            <Icon
              icon="pen-to-square"
              onClick={() => {
                tableHooks.uc_setUserInterface_FormDialogDisplay({
                  display: true,
                  form: {
                    form: {
                      formAdditionalData: {},
                      formData: rowData,
                      formInputs: {
                        timestamp_scheduled_pickup_date_key: {
                          data_type: 'string',
                          input_type: 'timestamp_date',
                          key: 'timestamp_scheduled_pickup_date_key',
                          label: rLIB('Scheduled Date'),
                          required: true,
                        },
                      },
                      formOnChange: (
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formData: TsInterface_FormData,
                        formInputs: TsInterface_FormInputs,
                        formSettings: TsInterface_FormSettings,
                      ) => {},
                      formSettings: {},
                      formSubmission: (
                        formSubmittedData: TsInterface_FormSubmittedData,
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formHooks: TsInterface_FormHooksObject,
                      ) => {
                        return new Promise((resolve, reject) => {
                          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                            .then((res_GCK) => {
                              // Add 'scheduled_status' based on whether a date is provided
                              const updatedData: any = {
                                timestamp_scheduled_pickup_date_key: formSubmittedData.timestamp_scheduled_pickup_date_key,
                                scheduled_status: formSubmittedData.timestamp_scheduled_pickup_date_key ? 'scheduled' : 'unscheduled',
                              }

                              DatabaseSetMergeDocument(DatabaseRef_Order_Document(res_GCK.clientKey, rowData.key as string), updatedData)
                                .then((res_DSMD) => {
                                  resolve(res_DSMD)
                                })
                                .catch((rej_DSMD) => {
                                  formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                  reject(rej_DSMD)
                                })
                            })
                            .catch((rej_GCK) => {
                              formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                              reject(rej_GCK)
                            })
                        })
                      },
                    },
                    dialog: {
                      formDialogHeaderColor: 'success',
                      formDialogHeaderText: rLIB('Scheduled Date'),
                      formDialogIcon: (
                        <Icon
                          type="solid"
                          icon="pen-to-square"
                        />
                      ),
                    },
                  },
                })
              }}
              className="tw-pl-2 tw-cursor-pointer hover:tw-text-success_main tw-opacity-30 hover:tw-opacity-100"
              tooltip={rLIB('Edit')}
              tooltipPlacement="right"
            />
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },

  associated_supplier_name: TableCellBasic('associated_supplier_name', rLIB('Supplier'), 'associated_supplier_name'),
  associated_supplier_location_name: TableCellBasic('associated_supplier_location_name', rLIB('Supplier Location'), 'associated_supplier_location_name'),
  // delivery_type: TableCellBasic( "delivery_type", s_DELIVERY_TYPE, "delivery_type" ),
  delivery_type: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Delivery Type')
      },
      header_sort_by: 'delivery_type',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let chipColor: 'default' | 'success' | 'primary' | 'secondary' | 'error' | 'info' | 'warning' = 'default'
        let iconJSX = <></>
        let textJSX = <></>
        if (rowData.delivery_type == null) {
          rowData.delivery_type = 'pickup'
        }
        switch (rowData.delivery_type) {
          case 'pickup':
            chipColor = 'primary'
            textJSX = <>{rLIB('Pickup')}</>
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="person-dolly"
                className="tw-mr-2"
              />
            )
            break
          case 'delivery':
            chipColor = 'secondary'
            textJSX = <>{rLIB('Delivery')}</>
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="truck"
                className="tw-mr-2"
              />
            )
            break
        }
        let cellJSX = (
          <Chip
            label={
              <Box className="tw-align-top">
                {iconJSX}
                {textJSX}
              </Box>
            }
            color={chipColor}
            variant="outlined"
            sx={{ margin: '2px' }}
          />
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  // delivery_notes: TableCellBasic('delivery_notes', rLIB('Delivery Notes'), 'delivery_notes'),
  associated_project_id_number: TableCellBasic('associated_project_id_number', rLIB('Job Code'), 'associated_project_id_number'),
  associated_task_name: TableCellBasic('associated_task_name', rLIB('Task'), 'associated_task_name'),
  associated_team_name: TableCellBasic('associated_team_name', rLIB('Team'), 'associated_team_name'),
  associated_user_name: TableCellBasic('associated_user_name', rLIB('Requestor'), 'associated_user_name'),
  // timestamp_scheduled_pickup_date_key: TableCellBasic( "timestamp_scheduled_pickup_date_key", s_REQUESTED_PICKUP_DATE, "timestamp_scheduled_pickup_date_key" ),
}
