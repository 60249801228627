///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Model files contains data and business logic specific to an individual database collection type

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { DatabaseRef_Project_Document } from 'rfbp_aux/services/database_endpoints/operations/projects'
import {
  DatabaseRef_DefaultAllocatedTime_Document,
  DatabaseRef_OverrideAllocatedTime_Document,
} from 'rfbp_aux/services/database_endpoints/timesheets/allocated_time'
import { DatabaseRef_TimeSheetsGeolocationUsers_Document } from 'rfbp_aux/services/database_endpoints/timesheets/geolocation'
import { DatabaseRef_TimePunches_Document } from 'rfbp_aux/services/database_endpoints/timesheets/time_punches'
import { DatabaseRef_TimeSheets_Document } from 'rfbp_aux/services/database_endpoints/timesheets/time_sheets'
import { returnFormattedDateKey } from 'rfbp_core/components/chat/chat_helper_functions'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseBatchUpdate, DatabaseGetDocument, TsInterface_DatabaseBatchUpdatesArray } from 'rfbp_core/services/database_management'
import { dynamicSort, generateRandomString, getProp, objectToArray, returnDateFromUnknownDateFormat } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'
import { hardCodedAllocationTypes } from './timesheet_hard_coded_data'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

// Helper
// export const convertToTimeFormat = ( minutes: number ) => {
// // Calculate hours and minutes
// const hours = Math.floor(minutes / 60);
// const mins = minutes % 60;
// // Convert to string format
// const formattedHours = hours.toString().padStart(2, '0');
// const formattedMins = mins.toFixed(0).toString().padStart(2, '0');
// // Return the formatted time string
// return `${formattedHours}:${formattedMins}`;
// }

export const convertToTimeFormat = (minutes: number) => {
  // Calculate hours and minutes
  const hours = Math.floor(minutes / 60)
  const mins = minutes % 60
  // Convert to string format
  let formattedHours = hours.toString().padStart(2, '0')
  let formattedMins = mins.toFixed(0).toString().padStart(2, '0')
  if (formattedMins === '60') {
    formattedMins = '00'
    formattedHours = (hours + 1).toString().padStart(2, '0')
  }
  return `${formattedHours}:${formattedMins}`
}

export const returnClockInOptions = (
  clientKey: string,
  userKey: string,
  startDate: Date,
  adminTimeAllocationTypes: TsInterface_UnspecifiedObject,
  scheduledTasks: TsInterface_UnspecifiedObject,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    let promiseArray: TsType_UnknownPromise[] = []
    let defaultTimes: TsInterface_UnspecifiedObject = {}
    let overrideTimes: TsInterface_UnspecifiedObject = {}
    promiseArray.push(
      DatabaseGetDocument(DatabaseRef_DefaultAllocatedTime_Document(clientKey, userKey))
        .then((res_DGD) => {
          if (res_DGD != null && res_DGD.data != null && res_DGD.data.allocated_times != null) {
            defaultTimes = res_DGD.data.allocated_times
          }
        })
        .catch((rej_DGD) => {
          console.error(rej_DGD)
        }),
    )
    promiseArray.push(
      DatabaseGetDocument(DatabaseRef_OverrideAllocatedTime_Document(clientKey, returnFormattedDateKey(startDate) + '_' + userKey))
        .then((res_DGD) => {
          if (res_DGD != null && res_DGD.data != null && res_DGD.data.allocated_times != null) {
            overrideTimes = res_DGD.data.allocated_times
          }
        })
        .catch((rej_DGD) => {
          console.error(rej_DGD)
        }),
    )
    // After Data is loaded
    Promise.all(promiseArray).finally(() => {
      // TODO - disable admin time option if they are out of time
      let clockInOptions: TsInterface_UnspecifiedObject = {}
      if (overrideTimes != null && overrideTimes['admin_time'] != null) {
        if (adminTimeAllocationTypes != null && adminTimeAllocationTypes.subtypes != null) {
          for (let loopSubtypeKey in adminTimeAllocationTypes.subtypes) {
            let loopSubType = adminTimeAllocationTypes.subtypes[loopSubtypeKey]
            if (
              loopSubType != null &&
              loopSubType.status === 'active' &&
              overrideTimes['admin_time'][loopSubtypeKey] != null &&
              overrideTimes['admin_time'][loopSubtypeKey] > 0
            ) {
              clockInOptions['admin_time_' + loopSubtypeKey] = {
                key: 'admin_time_' + loopSubtypeKey,
                allocation_type_code: getProp(adminTimeAllocationTypes, 'code', null),
                allocation_type_key: 'admin_time',
                allocation_type_name: 'Admin Time',
                allocation_subtype_code: getProp(loopSubType, 'code', null),
                allocation_subtype_key: loopSubtypeKey,
                allocation_subtype_name: loopSubType.name,
                associated_project_key: null,
                associated_project_tasks: null,
                hours: overrideTimes['admin_time'][loopSubtypeKey],
                full_name: 'Admin Time - ' + loopSubType.name,
              }
            }
          }
        }
      } else if (defaultTimes != null && defaultTimes['admin_time'] != null) {
        if (adminTimeAllocationTypes != null && adminTimeAllocationTypes.subtypes != null) {
          for (let loopSubtypeKey in adminTimeAllocationTypes.subtypes) {
            let loopSubType = adminTimeAllocationTypes.subtypes[loopSubtypeKey]
            if (
              loopSubType != null &&
              loopSubType.status === 'active' &&
              defaultTimes['admin_time'][loopSubtypeKey] != null &&
              defaultTimes['admin_time'][loopSubtypeKey] > 0
            ) {
              clockInOptions['admin_time_' + loopSubtypeKey] = {
                key: 'admin_time_' + loopSubtypeKey,
                allocation_type_code: getProp(adminTimeAllocationTypes, 'code', null),
                allocation_type_key: 'admin_time',
                allocation_type_name: 'Admin Time',
                allocation_subtype_code: getProp(loopSubType, 'code', null),
                allocation_subtype_key: loopSubtypeKey,
                allocation_subtype_name: loopSubType.name,
                associated_project_key: null,
                associated_project_tasks: null,
                hours: defaultTimes['admin_time'][loopSubtypeKey],
                full_name: 'Admin Time - ' + loopSubType.name,
              }
            }
          }
        }
      }
      // Loop through and get all possible projects from tasks and aggregate the tasks on each project
      let projectsWithTasksToday: TsInterface_UnspecifiedObject = {}
      for (let loopTaskKey in scheduledTasks) {
        let loopTask = scheduledTasks[loopTaskKey]
        if (loopTask.status === 'cancelled') {
          loopTask.name = loopTask.name + ' (Cancelled)'
        }
        if (loopTask.associated_project_key != null) {
          if (projectsWithTasksToday[loopTask.associated_project_key] == null) {
            projectsWithTasksToday[loopTask.associated_project_key] = {
              associated_project_key: loopTask.associated_project_key,
              associated_project_id_number: getProp(loopTask, 'associated_project_id_number', 'MISSING JOB CODE'),
              task_keys: {},
              task_names: {},
            }
          }
          projectsWithTasksToday[loopTask.associated_project_key]['task_keys'][loopTaskKey] = loopTaskKey
          projectsWithTasksToday[loopTask.associated_project_key]['task_names'][loopTaskKey] = loopTask.name
        } else if (loopTask.associated_proto_project_key != null) {
          if (projectsWithTasksToday[loopTask.associated_proto_project_key] == null) {
            projectsWithTasksToday[loopTask.associated_proto_project_key] = {
              associated_project_key: loopTask.associated_project_key,
              associated_project_id_number: getProp(loopTask, 'associated_project_id_number', 'PROTO PROJECT'),
              task_keys: {},
              task_names: {},
            }
          }
          projectsWithTasksToday[loopTask.associated_proto_project_key]['task_keys'][loopTaskKey] = loopTaskKey
          projectsWithTasksToday[loopTask.associated_proto_project_key]['task_names'][loopTaskKey] = loopTask.name
        }
      }
      for (let loopProjectKey in projectsWithTasksToday) {
        let loopProjectData = projectsWithTasksToday[loopProjectKey]
        clockInOptions['field_work_' + loopProjectKey] = {
          key: 'field_work_' + loopProjectKey,
          allocation_type_code: null, // TODO?
          allocation_type_key: 'field_work',
          allocation_type_name: loopProjectData.associated_project_id_number,
          allocation_subtype_code: null, // TODO?
          allocation_subtype_key: 'tasks',
          allocation_subtype_name: objectToArray(loopProjectData['task_names']).join(', '), // Task Names
          associated_project_key: loopProjectData.associated_project_key,
          associated_project_tasks: loopProjectData['task_names'],
          hours: null,
          full_name:
            getProp(loopProjectData, 'associated_project_id_number', 'PROJECT MISSING JOB CODE') +
            ' - ' +
            objectToArray(loopProjectData['task_names']).join(', '),
        }
      }
      resolve({ clockInOptions: clockInOptions })
    })
  })
}

// Punch Database Functions
export const createClockInPunch = (
  clientKey: string,
  clockTimestamp: Date,
  createTimestamp: Date,
  userKey: string,
  userName: string,
  creatorUserKey: string,
  creatorUserName: string,
  allocationTypeKey: string,
  allocationTypeName: string,
  allocationTypeCode: string,
  allocationSubtypeKey: string,
  allocationSubtypeName: string,
  allocationSubtypeCode: string,
  projectKey: string | null,
  projectTasks: TsInterface_UnspecifiedObject,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    // Load project and add location_state and location_address to punch
    let promiseArray: TsType_UnknownPromise[] = []
    let associatedProject: TsInterface_UnspecifiedObject = {}
    if (projectKey != null) {
      promiseArray.push(
        DatabaseGetDocument(DatabaseRef_Project_Document(clientKey, projectKey))
          .then((res_DGD) => {
            associatedProject = res_DGD.data
          })
          .catch((rej_DGD) => {
            // Nothing
          }),
      )
    }
    Promise.all(promiseArray).finally(() => {
      if (
        clientKey != null &&
        clockTimestamp != null &&
        createTimestamp != null &&
        userKey != null &&
        userName != null &&
        creatorUserKey != null &&
        creatorUserName != null &&
        allocationTypeKey != null &&
        allocationTypeName != null &&
        allocationSubtypeKey != null &&
        allocationSubtypeName != null
      ) {
        let managementEdits: TsInterface_UnspecifiedObject = {}
        let createGeolocationUpdate = true
        if (creatorUserKey !== userKey) {
          managementEdits['created_by_management'] = true
          createGeolocationUpdate = false
        }
        let newTimePunchKey = clockTimestamp.getTime().toString() + '_' + userKey + '_' + generateRandomString(6, null)
        let punchInUpdateObject = {
          associated_allocation_subtype_code: allocationSubtypeCode,
          associated_allocation_subtype_key: allocationSubtypeKey,
          associated_allocation_subtype_name: allocationSubtypeName,
          associated_allocation_type_code: allocationTypeCode,
          associated_allocation_type_key: allocationTypeKey,
          associated_allocation_type_name: allocationTypeName,
          associated_creator_key: creatorUserKey,
          associated_creator_name: creatorUserName,
          associated_project_key: projectKey,
          associated_tasks: projectTasks,
          associated_user_key: userKey,
          associated_user_name: userName,
          key: newTimePunchKey,
          location_latitude: null, // Not Supported on Desktop
          location_longitude: null, // Not Supported on Desktop
          location_address: getProp(associatedProject, 'location_address', null),
          location_state: getProp(associatedProject, 'location_state', null),
          management_edits: managementEdits,
          status: 'active',
          timestamp: clockTimestamp,
          timestamp_created: createTimestamp,
          timestamp_last_updated: createTimestamp,
          type: 'clock_in',
        }
        let geolocationUpdateObject = {
          key: userKey,
          last_time_punch_associated_allocation_subtype_name: allocationSubtypeName,
          last_time_punch_associated_allocation_type_name: allocationTypeName,
          last_time_punch_associated_project_key: projectKey,
          last_time_punch_associated_tasks: projectTasks,
          last_time_punch_key: newTimePunchKey,
          last_time_punch_timestamp: clockTimestamp,
          last_time_punch_type: 'clock_in',
          name: userName,
          status: 'active',
          timestamp: clockTimestamp,
        }
        // Update Database
        let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
          // { type: "setMerge", ref: DatabaseRef_TimeSheetsGeolocationUsers_Document( clientKey, userKey ), data: geolocationUpdateObject },
          { type: 'setMerge', ref: DatabaseRef_TimePunches_Document(clientKey, newTimePunchKey), data: punchInUpdateObject },
        ]
        if (createGeolocationUpdate === true) {
          updateArray.push({ type: 'setMerge', ref: DatabaseRef_TimeSheetsGeolocationUsers_Document(clientKey, userKey), data: geolocationUpdateObject })
        }
        DatabaseBatchUpdate(updateArray)
          .then((res_DBU) => {
            resolve(res_DBU)
          })
          .catch((rej_DBU) => {
            reject(rej_DBU)
          })
      } else {
        reject({
          success: false,
          error: {
            message: rLIB('Failed to clock in'),
            details: rLIB('Missing required parameters'),
            code: 'ER-D-TF-CCIP-01',
          },
        })
      }
    })
  })
}

export const createClockOutPunch = (
  clientKey: string,
  clockTimestamp: Date,
  createTimestamp: Date,
  userKey: string,
  userName: string,
  creatorUserKey: string,
  creatorUserName: string,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (
      clientKey != null &&
      clockTimestamp != null &&
      createTimestamp != null &&
      userKey != null &&
      userName != null &&
      creatorUserKey != null &&
      creatorUserName != null
    ) {
      let managementEdits: TsInterface_UnspecifiedObject = {}
      let createGeolocationUpdate = true
      if (creatorUserKey !== userKey) {
        managementEdits['created_by_management'] = true
        createGeolocationUpdate = false
      }
      let newTimePunchKey = clockTimestamp.getTime().toString() + '_' + userKey + '_' + generateRandomString(6, null)
      let punchOutUpdateObject = {
        associated_allocation_subtype_code: null,
        associated_allocation_subtype_key: null,
        associated_allocation_subtype_name: null,
        associated_allocation_type_code: null,
        associated_allocation_type_key: null,
        associated_allocation_type_name: null,
        associated_creator_key: creatorUserKey,
        associated_creator_name: creatorUserName,
        associated_project_key: null,
        associated_tasks: null,
        associated_user_key: userKey,
        associated_user_name: userName,
        key: newTimePunchKey,
        location_latitude: null, // Not Supported on Desktop
        location_longitude: null, // Not Supported on Desktop
        management_edits: managementEdits,
        status: 'active',
        timestamp: clockTimestamp,
        timestamp_created: createTimestamp,
        timestamp_last_updated: createTimestamp,
        type: 'clock_out',
      }
      let geolocationUpdateObject = {
        key: userKey,
        last_time_punch_associated_allocation_subtype_name: null,
        last_time_punch_associated_allocation_type_name: null,
        last_time_punch_associated_project_key: null,
        last_time_punch_associated_tasks: null,
        last_time_punch_key: newTimePunchKey,
        last_time_punch_timestamp: clockTimestamp,
        last_time_punch_type: 'clock_out',
        name: userName,
        status: 'active',
        timestamp: clockTimestamp,
      }
      // Update Database
      let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
        // { type: "setMerge", ref: DatabaseRef_TimeSheetsGeolocationUsers_Document( clientKey, userKey ), data: geolocationUpdateObject },
        { type: 'setMerge', ref: DatabaseRef_TimePunches_Document(clientKey, newTimePunchKey), data: punchOutUpdateObject },
      ]
      if (createGeolocationUpdate === true) {
        updateArray.push({ type: 'setMerge', ref: DatabaseRef_TimeSheetsGeolocationUsers_Document(clientKey, userKey), data: geolocationUpdateObject })
      }
      DatabaseBatchUpdate(updateArray)
        .then((res_DBU) => {
          resolve(res_DBU)
        })
        .catch((rej_DBU) => {
          reject(rej_DBU)
        })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to clock out'),
          details: rLIB('Missing required parameters'),
          code: 'ER-D-TF-CCOP-01',
        },
      })
    }
  })
}

export const createBreakStartPunch = (
  clientKey: string,
  clockTimestamp: Date,
  createTimestamp: Date,
  userKey: string,
  userName: string,
  creatorUserKey: string,
  creatorUserName: string,
  breakSubtypeCode: string,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (
      clientKey != null &&
      clockTimestamp != null &&
      createTimestamp != null &&
      userKey != null &&
      userName != null &&
      creatorUserKey != null &&
      creatorUserName != null
    ) {
      let managementEdits: TsInterface_UnspecifiedObject = {}
      let createGeolocationUpdate = true
      if (creatorUserKey !== userKey) {
        managementEdits['created_by_management'] = true
        createGeolocationUpdate = false
      }
      let punchOutKey = clockTimestamp.getTime().toString() + '_' + userKey + '_' + generateRandomString(6, null)
      let punchOutPunchUpdateObject = {
        associated_allocation_subtype_code: null,
        associated_allocation_subtype_key: null,
        associated_allocation_subtype_name: null,
        associated_allocation_type_code: null,
        associated_allocation_type_key: null,
        associated_allocation_type_name: null,
        associated_creator_key: creatorUserKey,
        associated_creator_name: creatorUserName,
        associated_project_key: null,
        associated_tasks: null,
        associated_user_key: userKey,
        associated_user_name: userName,
        key: punchOutKey,
        location_latitude: null, // Not Supported on Desktop
        location_longitude: null, // Not Supported on Desktop
        management_edits: managementEdits,
        status: 'active',
        timestamp: clockTimestamp,
        timestamp_created: createTimestamp,
        timestamp_last_updated: createTimestamp,
        type: 'clock_out',
      }
      let breakStartKey = (clockTimestamp.getTime() + 1).toString() + '_' + userKey + '_break_' + generateRandomString(6, null)
      let breakStartPunchUpdateObject = {
        associated_allocation_subtype_code: 'break', // Hardcoded for now
        associated_allocation_subtype_key: 'break', // Hardcoded for now
        associated_allocation_subtype_name: 'Break', // Hardcoded for now
        associated_allocation_type_code: breakSubtypeCode,
        associated_allocation_type_key: 'break',
        associated_allocation_type_name: 'Break',
        associated_creator_key: creatorUserKey,
        associated_creator_name: creatorUserName,
        associated_project_key: null,
        associated_tasks: null,
        associated_user_key: userKey,
        associated_user_name: userName,
        key: breakStartKey,
        location_latitude: null, // Not Supported on Desktop
        location_longitude: null, // Not Supported on Desktop
        management_edits: managementEdits,
        status: 'active',
        timestamp: new Date(clockTimestamp.getTime() + 1000),
        timestamp_created: createTimestamp,
        timestamp_last_updated: createTimestamp,
        type: 'clock_in',
      }
      let geolocationUpdateObject = {
        key: userKey,
        last_time_punch_associated_allocation_subtype_name: 'Break', // Hardcoded for now
        last_time_punch_associated_allocation_type_name: 'Break',
        last_time_punch_associated_project_key: null,
        last_time_punch_associated_tasks: null,
        last_time_punch_key: breakStartKey,
        last_time_punch_timestamp: new Date(clockTimestamp.getTime() + 1000),
        last_time_punch_type: 'clock_out',
        name: userName,
        status: 'active',
        timestamp: new Date(clockTimestamp.getTime() + 1000),
      }
      // Update Database
      let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
        // { type: "setMerge", ref: DatabaseRef_TimeSheetsGeolocationUsers_Document( clientKey, userKey ), data: geolocationUpdateObject },
        { type: 'setMerge', ref: DatabaseRef_TimePunches_Document(clientKey, breakStartKey), data: breakStartPunchUpdateObject },
        { type: 'setMerge', ref: DatabaseRef_TimePunches_Document(clientKey, punchOutKey), data: punchOutPunchUpdateObject },
      ]
      if (createGeolocationUpdate === true) {
        updateArray.push({ type: 'setMerge', ref: DatabaseRef_TimeSheetsGeolocationUsers_Document(clientKey, userKey), data: geolocationUpdateObject })
      }
      DatabaseBatchUpdate(updateArray)
        .then((res_DBU) => {
          resolve(res_DBU)
        })
        .catch((rej_DBU) => {
          reject(rej_DBU)
        })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to start break'),
          details: rLIB('Missing required parameters'),
          code: 'ER-D-TF-CBSP-01',
        },
      })
    }
  })
}

export const createBreakEndPunch = (
  clientKey: string,
  clockTimestamp: Date,
  createTimestamp: Date,
  userKey: string,
  userName: string,
  creatorUserKey: string,
  creatorUserName: string,
  allocationTypeKey: string,
  allocationTypeName: string,
  allocationTypeCode: string,
  allocationSubtypeKey: string,
  allocationSubtypeName: string,
  allocationSubtypeCode: string,
  breakSubtypeCode: string,
  projectKey: string | null,
  projectTasks: TsInterface_UnspecifiedObject,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (
      clientKey != null &&
      clockTimestamp != null &&
      createTimestamp != null &&
      userKey != null &&
      userName != null &&
      creatorUserKey != null &&
      creatorUserName != null &&
      allocationTypeKey != null
    ) {
      let managementEdits: TsInterface_UnspecifiedObject = {}
      let createGeolocationUpdate = true
      if (creatorUserKey !== userKey) {
        managementEdits['created_by_management'] = true
        createGeolocationUpdate = false
      }
      let breakEndKey = clockTimestamp.getTime().toString() + '_' + userKey + '_' + generateRandomString(6, null)
      let breakEndPunchUpdateObject = {
        associated_allocation_subtype_code: 'break', // Hardcoded for now
        associated_allocation_subtype_key: 'break', // Hardcoded for now
        associated_allocation_subtype_name: 'Break', // Hardcoded for now
        associated_allocation_type_code: breakSubtypeCode,
        associated_allocation_type_key: 'break',
        associated_allocation_type_name: 'Break',
        associated_creator_key: creatorUserKey,
        associated_creator_name: creatorUserName,
        associated_project_key: null,
        associated_tasks: null,
        associated_user_key: userKey,
        associated_user_name: userName,
        key: breakEndKey,
        location_latitude: null, // Not Supported on Desktop
        location_longitude: null, // Not Supported on Desktop
        management_edits: managementEdits,
        status: 'active',
        timestamp: clockTimestamp,
        timestamp_created: createTimestamp,
        timestamp_last_updated: createTimestamp,
        type: 'clock_out',
      }
      let punchInKey = (clockTimestamp.getTime() + 1).toString() + '_' + userKey + '_' + generateRandomString(6, null)
      let punchInUpdateObject = {
        associated_allocation_subtype_code: allocationSubtypeCode,
        associated_allocation_subtype_key: allocationSubtypeKey,
        associated_allocation_subtype_name: allocationSubtypeName,
        associated_allocation_type_code: allocationTypeCode,
        associated_allocation_type_key: allocationTypeKey,
        associated_allocation_type_name: allocationTypeName,
        associated_creator_key: creatorUserKey,
        associated_creator_name: creatorUserName,
        associated_project_key: projectKey,
        associated_tasks: projectTasks,
        associated_user_key: userKey,
        associated_user_name: userName,
        key: punchInKey,
        location_latitude: null, // Not Supported on Desktop
        location_longitude: null, // Not Supported on Desktop
        management_edits: managementEdits,
        status: 'active',
        timestamp: new Date(clockTimestamp.getTime() + 1000),
        timestamp_created: createTimestamp,
        timestamp_last_updated: createTimestamp,
        type: 'clock_in',
      }
      let geolocationUpdateObject = {
        key: userKey,
        last_time_punch_associated_allocation_subtype_name: allocationSubtypeName,
        last_time_punch_associated_allocation_type_name: allocationTypeName,
        last_time_punch_associated_project_key: projectKey,
        last_time_punch_associated_tasks: projectTasks,
        last_time_punch_key: punchInKey,
        last_time_punch_timestamp: new Date(clockTimestamp.getTime() + 1000),
        last_time_punch_type: 'clock_in',
        name: userName,
        status: 'active',
        timestamp: new Date(clockTimestamp.getTime() + 1000),
      }
      // Update Database
      let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
        // { type: "setMerge", ref: DatabaseRef_TimeSheetsGeolocationUsers_Document( clientKey, userKey ), data: geolocationUpdateObject },
        { type: 'setMerge', ref: DatabaseRef_TimePunches_Document(clientKey, breakEndKey), data: breakEndPunchUpdateObject },
        { type: 'setMerge', ref: DatabaseRef_TimePunches_Document(clientKey, punchInKey), data: punchInUpdateObject },
      ]
      if (createGeolocationUpdate === true) {
        updateArray.push({ type: 'setMerge', ref: DatabaseRef_TimeSheetsGeolocationUsers_Document(clientKey, userKey), data: geolocationUpdateObject })
      }
      DatabaseBatchUpdate(updateArray)
        .then((res_DBU) => {
          resolve(res_DBU)
        })
        .catch((rej_DBU) => {
          reject(rej_DBU)
        })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to end break'),
          details: rLIB('Missing required parameters'),
          code: 'ER-D-TF-CBEP-01',
        },
      })
    }
  })
}

export const createNonWorkingTimePunches = (
  clientKey: string,
  timeOffDatesObject: TsInterface_UnspecifiedObject,
  existingPunchesObject: TsInterface_UnspecifiedObject,
  createTimestamp: Date,
  userKey: string,
  userName: string,
  creatorUserKey: string,
  creatorUserName: string,
  allocationTypeKey: string,
  allocationTypeName: string,
  allocationTypeCode: string,
  allocationSubtypeKey: string,
  allocationSubtypeName: string | null,
  allocationSubtypeCode: string | null,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    // Make sure that there are no overlaps between the existing punches and the new punches
    let sortedPushArray: TsInterface_UnspecifiedObject[] = objectToArray(existingPunchesObject).sort(dynamicSort('timestamp', 'asc'))
    let hasOverlappingPunches = false
    for (let loopDateKey in timeOffDatesObject) {
      let loopDateObject = timeOffDatesObject[loopDateKey]
      if (checkOverlap(sortedPushArray, loopDateObject.start_time) === true) {
        hasOverlappingPunches = true
      }
      if (checkOverlap(sortedPushArray, loopDateObject.end_time) === true) {
        hasOverlappingPunches = true
      }
    }
    if (userKey != null) {
      if (hasOverlappingPunches === false) {
        let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
        let managementEdits: TsInterface_UnspecifiedObject = {}
        if (creatorUserKey !== userKey) {
          managementEdits['created_by_management'] = true
        }
        for (let loopDateKey in timeOffDatesObject) {
          let loopDateObject = timeOffDatesObject[loopDateKey]
          let nonWorkingTimePunchStartKey = (loopDateObject.start_time.getTime() + 1).toString() + '_' + userKey + '_' + generateRandomString(6, null)
          let nonWorkingTimePunchEndKey = (loopDateObject.end_time.getTime() + 1).toString() + '_' + userKey + '_' + generateRandomString(6, null)
          updateArray.push({
            type: 'setMerge',
            ref: DatabaseRef_TimePunches_Document(clientKey, nonWorkingTimePunchStartKey),
            data: {
              associated_allocation_subtype_code: allocationSubtypeCode,
              associated_allocation_subtype_key: allocationSubtypeKey,
              associated_allocation_subtype_name: allocationSubtypeName,
              associated_allocation_type_code: allocationTypeCode,
              associated_allocation_type_key: allocationTypeKey,
              associated_allocation_type_name: allocationTypeName,
              associated_creator_key: creatorUserKey,
              associated_creator_name: creatorUserName,
              associated_project_key: null,
              associated_tasks: null,
              associated_user_key: userKey,
              associated_user_name: userName,
              key: nonWorkingTimePunchStartKey,
              location_latitude: null, // Not Supported on Desktop
              location_longitude: null, // Not Supported on Desktop
              management_edits: managementEdits,
              timestamp: loopDateObject.start_time,
              timestamp_created: createTimestamp,
              timestamp_last_updated: createTimestamp,
              type: 'clock_in',
            },
          })
          updateArray.push({
            type: 'setMerge',
            ref: DatabaseRef_TimePunches_Document(clientKey, nonWorkingTimePunchEndKey),
            data: {
              associated_allocation_subtype_code: allocationSubtypeCode,
              associated_allocation_subtype_key: allocationSubtypeKey,
              associated_allocation_subtype_name: allocationSubtypeName,
              associated_allocation_type_code: allocationTypeCode,
              associated_allocation_type_key: allocationTypeKey,
              associated_allocation_type_name: allocationTypeName,
              associated_creator_key: creatorUserKey,
              associated_creator_name: creatorUserName,
              associated_project_key: null,
              associated_tasks: null,
              associated_user_key: userKey,
              associated_user_name: userName,
              key: nonWorkingTimePunchEndKey,
              location_latitude: null, // Not Supported on Desktop
              location_longitude: null, // Not Supported on Desktop
              management_edits: managementEdits,
              timestamp: loopDateObject.end_time,
              timestamp_created: createTimestamp,
              timestamp_last_updated: createTimestamp,
              type: 'clock_out',
            },
          })
        }
        // Save to Database
        DatabaseBatchUpdate(updateArray)
          .then((res_DBU) => {
            resolve(res_DBU)
          })
          .catch((rej_DBU) => {
            reject(rej_DBU)
          })
      } else {
        reject({
          success: false,
          error: {
            message: rLIB('Failed to create non working time punches'),
            details: rLIB('The requested time overlap with existing punches'),
            code: 'ER-D-TF-CNWTP-01',
          },
        })
      }
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to create non working time punches'),
          details: rLIB('Missing required parameters'),
          code: 'ER-D-TF-CNWTP-02',
        },
      })
    }
  })
}

export const editNonWorkingEventPunches = (
  clientKey: string,
  dateKey: string,
  startPunchKey: string,
  startPunchTimestamp: Date,
  endPunchKey: string,
  endPunchTimestamp: Date,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (clientKey != null && dateKey != null && startPunchKey != null && startPunchTimestamp != null && endPunchKey != null && endPunchTimestamp != null) {
      // TODO - check for overlapping punches?
      let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
      updateArray.push({
        type: 'setMerge',
        ref: DatabaseRef_TimePunches_Document(clientKey, startPunchKey),
        data: {
          timestamp: new Date(dateKey + ' ' + startPunchTimestamp),
        },
      })
      updateArray.push({
        type: 'setMerge',
        ref: DatabaseRef_TimePunches_Document(clientKey, endPunchKey),
        data: {
          timestamp: new Date(dateKey + ' ' + endPunchTimestamp),
        },
      })
      // Save to Database
      DatabaseBatchUpdate(updateArray)
        .then((res_DBU) => {
          resolve(res_DBU)
        })
        .catch((rej_DBU) => {
          reject(rej_DBU)
        })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to edit non working time event'),
          details: rLIB('Missing required parameters'),
          code: 'ER-D-TF-ENWEP-01',
        },
      })
    }
  })
}

export const deleteNonWorkingTimePunches = (clientKey: string, clockInKey: string, clockOutKey: string): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (clientKey != null && clockInKey != null && clockOutKey != null) {
      let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
        { type: 'delete', ref: DatabaseRef_TimePunches_Document(clientKey, clockInKey), data: {} },
        { type: 'delete', ref: DatabaseRef_TimePunches_Document(clientKey, clockOutKey), data: {} },
      ]
      DatabaseBatchUpdate(updateArray)
        .then((res_DBU) => {
          resolve(res_DBU)
        })
        .catch((rej_DBU) => {
          reject(rej_DBU)
        })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to delete non working time event'),
          details: rLIB('Missing required parameters'),
          code: 'ER-D-TF-DNWTP-01',
        },
      })
    }
  })
}

export const submitTimeSheet = (
  clientKey: string,
  weekStartDateKey: string,
  userKey: string,
  userName: string,
  creatorUserKey: string,
  creatorUserName: string,
  adminTimeHours: number,
  breakHours: number,
  fieldWorkHours: number,
  nonWorkingTimeHours: number,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (
      clientKey != null &&
      weekStartDateKey != null &&
      userKey != null &&
      userName != null &&
      creatorUserKey != null &&
      creatorUserName != null &&
      adminTimeHours != null &&
      breakHours != null &&
      fieldWorkHours != null &&
      nonWorkingTimeHours != null
    ) {
      let timeSheetKey = userKey + '_' + weekStartDateKey
      let updateObject: TsInterface_UnspecifiedObject = {
        // associated_approver_key: null,
        // associated_approver_name: null,
        associated_creator_key: creatorUserKey,
        associated_creator_name: creatorUserName,
        associated_date_key: weekStartDateKey,
        associated_user_key: userKey,
        associated_user_name: userName,
        key: timeSheetKey,
        // requested_corrections: {},
        // timestamp_approved: null,
        timestamp_submitted: new Date(),
        total_hours: {
          admin_time: adminTimeHours,
          break: breakHours,
          field_work: fieldWorkHours,
          non_working_time: nonWorkingTimeHours,
        },
      }
      let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
        { type: 'setMerge', ref: DatabaseRef_TimeSheets_Document(clientKey, timeSheetKey), data: updateObject },
      ]
      DatabaseBatchUpdate(updateArray)
        .then((res_DBU) => {
          resolve(res_DBU)
        })
        .catch((rej_DBU) => {
          reject(rej_DBU)
        })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to submit time sheet'),
          details: rLIB('Missing required parameters'),
          code: 'ER-D-TF-STS-01',
        },
      })
    }
  })
}

const checkOverlap = (arrayOfPunches: TsInterface_UnspecifiedObject[], dateToCheck: Date): boolean => {
  // Sort the array by timestamp
  arrayOfPunches.sort((a, b) => a.timestamp - b.timestamp)
  for (let i = 0; i < arrayOfPunches.length - 1; i++) {
    const currentEvent = arrayOfPunches[i]
    const nextEvent = arrayOfPunches[i + 1]
    if (currentEvent.type === 'clock_in' && nextEvent.type === 'clock_out' && dateToCheck >= currentEvent.timestamp && dateToCheck <= nextEvent.timestamp) {
      return true
    }
  }
  return false
}

// Merge and Evaluation
export const returnDateRangeKeys = (startDate: string, endDate: string) => {
  const dates = []
  const currentDate = new Date(startDate)
  while (currentDate <= new Date(endDate)) {
    let currentDateKey = currentDate.toISOString().split('T')[0]
    let actualDate = new Date(parseInt(currentDateKey.split('-')[0]), parseInt(currentDateKey.split('-')[1]) - 1, parseInt(currentDateKey.split('-')[2]), 0, 0)
    dates.push(actualDate)
    currentDate.setDate(currentDate.getDate() + 1)
  }
  return dates
}

const prefixProps = (event: TsInterface_UnspecifiedObject, prefix: string) => {
  let prefixedProps: TsInterface_UnspecifiedObject = {}
  for (let key in event) {
    prefixedProps[`${prefix}_${key}`] = event[key]
  }
  return prefixedProps
}

export const cleanPunchDataBeforeEvaluation = (punches: TsInterface_UnspecifiedObject): TsInterface_UnspecifiedObject => {
  let cleanData: TsInterface_UnspecifiedObject = {}
  let copyOfPunches = { ...punches }
  for (let loopPunchKey in copyOfPunches) {
    let loopPunch = copyOfPunches[loopPunchKey]
    loopPunch.timestamp = returnDateFromUnknownDateFormat(loopPunch.timestamp)
    cleanData[loopPunchKey] = loopPunch
  }
  return cleanData
}

export const aggregateTimesheetPunchData = (punches: TsInterface_UnspecifiedObject[]): TsInterface_UnspecifiedObject => {
  // Instantiate Variables
  let aggregatedData: TsInterface_UnspecifiedObject = {
    totals: {
      total_minutes: 0,
      total_minutes_by_date: {},
    },
    breakdowns: {},
    error_count: 0,
    errors: {
      missing_first_clock_in: false,
      back_to_back_clock_ins: false,
      back_to_back_clock_outs: false,
    },
    warning_count: 0,
    warnings: {
      missing_last_clock_out: false,
      multi_day_punches: false,
    },
    combined_punch_events: [],
  }
  for (let loopAllocationTypeKey in hardCodedAllocationTypes) {
    aggregatedData.breakdowns[loopAllocationTypeKey] = {
      total_minutes: 0,
      total_minutes_by_date: {},
    }
  }
  // Remove Status Deleted Punches from array
  punches = punches.filter((punch: TsInterface_UnspecifiedObject) => punch.status !== 'deleted')
  // Sort events by timestamp
  punches.sort((a: TsInterface_UnspecifiedObject, b: TsInterface_UnspecifiedObject) => a.timestamp - b.timestamp)
  // Loop through punches to aggregate data and create combined events
  for (let loopPunchIndex = 0; loopPunchIndex < punches.length; loopPunchIndex++) {
    let loopPunch = punches[loopPunchIndex]
    // Always the end punch so it's missing associated_allocation_type_key
    ////////////////////////////////
    // Combine punches into events
    ////////////////////////////////
    if (loopPunchIndex === 0 && loopPunch.type === 'clock_out') {
      // Error: first punch is a clock out
      aggregatedData['errors']['missing_first_clock_in'] = true
      aggregatedData['error_count']++
      // Create Error Half Event
      aggregatedData.combined_punch_events.push({
        ...prefixProps(loopPunch, 'clock_out'),
        date_key: returnFormattedDateKey(returnDateFromUnknownDateFormat(loopPunch.timestamp)),
        combined_punch_event_error_type: 'missing_first_clock_in',
        combined_punch_event_error_text: rLIB('Missing first clock in'),
        combined_punch_event_warning_type: null,
        event_has_error: true,
        event_has_warning: false,
        total_minutes: 0,
        combined_punch_event_type: 'error',
        // trace_key: 1,
      })
    } else if (loopPunchIndex === punches.length - 1 && loopPunch.type === 'clock_in') {
      // Last punch is a clock in
      // Potential Error OR Active: Last punch in is a clock in
      // aggregatedData["warnings"]["missing_last_clock_out"] = true
      // aggregatedData["warning_count"]++
      let clockInPunchTimestamp = loopPunch.timestamp
      let clockOutPunchTimestamp = new Date().getTime()
      let clockInDateKey = returnFormattedDateKey(returnDateFromUnknownDateFormat(clockInPunchTimestamp))
      let clockOutDateKey = returnFormattedDateKey(returnDateFromUnknownDateFormat(clockOutPunchTimestamp))
      if (clockInDateKey === clockOutDateKey) {
        let totalMinutes = (clockOutPunchTimestamp - clockInPunchTimestamp) / (1000 * 60)
        aggregatedData.totals.total_minutes += totalMinutes
        if (aggregatedData.totals.total_minutes_by_date[clockInDateKey] == null) {
          aggregatedData.totals.total_minutes_by_date[clockInDateKey] = 0
        }
        aggregatedData.totals.total_minutes_by_date[clockInDateKey] += totalMinutes
        // Handle Allocation Type Breakdowns
        if (loopPunch.associated_allocation_type_key != null) {
          if (aggregatedData.breakdowns[loopPunch.associated_allocation_type_key] == null) {
            aggregatedData.breakdowns[loopPunch.associated_allocation_type_key] = {
              total_minutes: 0,
              total_minutes_by_date: {},
            }
          }
          aggregatedData.breakdowns[loopPunch.associated_allocation_type_key].total_minutes += totalMinutes
          if (aggregatedData.breakdowns[loopPunch.associated_allocation_type_key].total_minutes_by_date[clockInDateKey] == null) {
            aggregatedData.breakdowns[loopPunch.associated_allocation_type_key].total_minutes_by_date[clockInDateKey] = 0
          }
          aggregatedData.breakdowns[loopPunch.associated_allocation_type_key].total_minutes_by_date[clockInDateKey] += totalMinutes
        }
        // End Handle Allocation Type Breakdowns
        // Create Error Half Event
        aggregatedData.combined_punch_events.push({
          ...prefixProps(loopPunch, 'clock_in'),
          date_key: clockInDateKey,
          combined_punch_event_error_type: null,
          combined_punch_event_warning_type: 'missing_last_clock_out',
          event_has_error: false,
          event_has_warning: true,
          total_minutes: totalMinutes,
          combined_punch_event_type: 'active',
          // trace_key: 2,
        })
      } else {
        // Spans multiple days
        aggregatedData['warnings']['multi_day_punches'] = true
        aggregatedData['warning_count']++
        // Loop and create multiple events
        let combinedEventDateRange = returnDateRangeKeys(clockInDateKey, clockOutDateKey)
        for (let loopDateIndex in combinedEventDateRange) {
          let loopDateKey = returnFormattedDateKey(returnDateFromUnknownDateFormat(combinedEventDateRange[loopDateIndex]))
          if (parseInt(loopDateIndex) === 0) {
            // First Date
            let dayEndTimestamp = new Date(
              parseInt(loopDateKey.split('-')[0]),
              parseInt(loopDateKey.split('-')[1]) - 1,
              parseInt(loopDateKey.split('-')[2]) + 1,
              0,
              0,
            )
            // @ts-expect-error - TODO: reason for error
            let totalMinutes = (dayEndTimestamp - clockInPunchTimestamp) / (1000 * 60)
            aggregatedData.totals.total_minutes += totalMinutes
            if (aggregatedData.totals.total_minutes_by_date[loopDateKey] == null) {
              aggregatedData.totals.total_minutes_by_date[loopDateKey] = 0
            }
            aggregatedData.totals.total_minutes_by_date[loopDateKey] += totalMinutes
            // Handle Allocation Type Breakdowns
            if (loopPunch.associated_allocation_type_key != null) {
              if (aggregatedData.breakdowns[loopPunch.associated_allocation_type_key] == null) {
                aggregatedData.breakdowns[loopPunch.associated_allocation_type_key] = {
                  total_minutes: 0,
                  total_minutes_by_date: {},
                }
              }
              aggregatedData.breakdowns[loopPunch.associated_allocation_type_key].total_minutes += totalMinutes
              if (aggregatedData.breakdowns[loopPunch.associated_allocation_type_key].total_minutes_by_date[loopDateKey] == null) {
                aggregatedData.breakdowns[loopPunch.associated_allocation_type_key].total_minutes_by_date[loopDateKey] = 0
              }
              aggregatedData.breakdowns[loopPunch.associated_allocation_type_key].total_minutes_by_date[loopDateKey] += totalMinutes
            }
            // End Handle Allocation Type Breakdowns
            // Create Event
            let combinedEvent: TsInterface_UnspecifiedObject = {
              ...prefixProps(loopPunch, 'clock_in'),
              date_key: loopDateKey,
              combined_punch_event_error_type: null,
              combined_punch_event_warning_type: 'missing_last_clock_out',
              event_has_error: false,
              event_has_warning: true,
              total_minutes: totalMinutes,
              combined_punch_event_type: 'active',
              // trace_key: 3,
            }
            combinedEvent['clock_out_timestamp'] = dayEndTimestamp
            aggregatedData.combined_punch_events.push(combinedEvent)
          } else if (parseInt(loopDateIndex) === combinedEventDateRange.length - 1) {
            // Last Date
            let dayStartTimestamp = new Date(
              parseInt(loopDateKey.split('-')[0]),
              parseInt(loopDateKey.split('-')[1]) - 1,
              parseInt(loopDateKey.split('-')[2]),
              0,
              0,
            )
            // @ts-expect-error - TODO: reason for error
            let totalMinutes = (clockOutPunchTimestamp - dayStartTimestamp) / (1000 * 60)
            aggregatedData.totals.total_minutes += totalMinutes
            if (aggregatedData.totals.total_minutes_by_date[loopDateKey] == null) {
              aggregatedData.totals.total_minutes_by_date[loopDateKey] = 0
            }
            aggregatedData.totals.total_minutes_by_date[loopDateKey] += totalMinutes
            // Handle Allocation Type Breakdowns
            if (loopPunch.associated_allocation_type_key != null) {
              if (aggregatedData.breakdowns[loopPunch.associated_allocation_type_key] == null) {
                aggregatedData.breakdowns[loopPunch.associated_allocation_type_key] = {
                  total_minutes: 0,
                  total_minutes_by_date: {},
                }
              }
              aggregatedData.breakdowns[loopPunch.associated_allocation_type_key].total_minutes += totalMinutes
              if (aggregatedData.breakdowns[loopPunch.associated_allocation_type_key].total_minutes_by_date[loopDateKey] == null) {
                aggregatedData.breakdowns[loopPunch.associated_allocation_type_key].total_minutes_by_date[loopDateKey] = 0
              }
              aggregatedData.breakdowns[loopPunch.associated_allocation_type_key].total_minutes_by_date[loopDateKey] += totalMinutes
            }
            // End Handle Allocation Type Breakdowns
            // Create Event
            let combinedEvent: TsInterface_UnspecifiedObject = {
              ...prefixProps(loopPunch, 'clock_in'),
              date_key: loopDateKey,
              combined_punch_event_error_type: null,
              combined_punch_event_warning_type: 'missing_last_clock_out',
              event_has_error: false,
              event_has_warning: true,
              total_minutes: totalMinutes,
              combined_punch_event_type: 'active',
              // trace_key: 4,
            }
            combinedEvent['clock_in_timestamp'] = dayStartTimestamp
            aggregatedData.combined_punch_events.push(combinedEvent)
          } else {
            // Middle Date
            let dayStartTimestamp = new Date(
              parseInt(loopDateKey.split('-')[0]),
              parseInt(loopDateKey.split('-')[1]) - 1,
              parseInt(loopDateKey.split('-')[2]),
              0,
              0,
            )
            let dayEndTimestamp = new Date(
              parseInt(loopDateKey.split('-')[0]),
              parseInt(loopDateKey.split('-')[1]) - 1,
              parseInt(loopDateKey.split('-')[2]) + 1,
              0,
              0,
            )
            // @ts-expect-error - TODO: reason for error
            let totalMinutes = (dayEndTimestamp - dayStartTimestamp) / (1000 * 60)
            aggregatedData.totals.total_minutes += totalMinutes
            if (aggregatedData.totals.total_minutes_by_date[loopDateKey] == null) {
              aggregatedData.totals.total_minutes_by_date[loopDateKey] = 0
            }
            aggregatedData.totals.total_minutes_by_date[loopDateKey] += totalMinutes
            // Handle Allocation Type Breakdowns
            if (loopPunch.associated_allocation_type_key != null) {
              if (aggregatedData.breakdowns[loopPunch.associated_allocation_type_key] == null) {
                aggregatedData.breakdowns[loopPunch.associated_allocation_type_key] = {
                  total_minutes: 0,
                  total_minutes_by_date: {},
                }
              }
              aggregatedData.breakdowns[loopPunch.associated_allocation_type_key].total_minutes += totalMinutes
              if (aggregatedData.breakdowns[loopPunch.associated_allocation_type_key].total_minutes_by_date[loopDateKey] == null) {
                aggregatedData.breakdowns[loopPunch.associated_allocation_type_key].total_minutes_by_date[loopDateKey] = 0
              }
              aggregatedData.breakdowns[loopPunch.associated_allocation_type_key].total_minutes_by_date[loopDateKey] += totalMinutes
            }
            // End Handle Allocation Type Breakdowns
            // Create Event
            let combinedEvent: TsInterface_UnspecifiedObject = {
              ...prefixProps(loopPunch, 'clock_in'),
              date_key: loopDateKey,
              combined_punch_event_error_type: null,
              combined_punch_event_warning_type: 'missing_last_clock_out',
              event_has_error: false,
              event_has_warning: true,
              total_minutes: totalMinutes,
              combined_punch_event_type: 'active',
              // trace_key: 5,
            }
            combinedEvent['clock_in_timestamp'] = dayStartTimestamp
            combinedEvent['clock_out_timestamp'] = dayEndTimestamp
            aggregatedData.combined_punch_events.push(combinedEvent)
          }
        }
      }
    } else if (loopPunchIndex !== 0) {
      // Skipping the first clock in will not matter since it will be caught on clockout
      // If the first event is a clock out then it will be caught by the above.
      let previousLoopPunch = punches[loopPunchIndex - 1]
      if (loopPunch.type === 'clock_out' && previousLoopPunch.type === 'clock_out') {
        // Error: Back to back clock outs
        aggregatedData['errors']['back_to_back_clock_outs'] = true
        aggregatedData['error_count']++
        // Create Error Half Event
        aggregatedData.combined_punch_events.push({
          ...prefixProps(loopPunch, 'clock_out'),
          date_key: returnFormattedDateKey(returnDateFromUnknownDateFormat(loopPunch.timestamp)),
          combined_punch_event_error_type: 'back_to_back_clock_outs',
          combined_punch_event_error_text: rLIB('Back to back clock outs'),
          combined_punch_event_warning_type: null,
          event_has_error: true,
          event_has_warning: false,
          total_minutes: 0,
          combined_punch_event_type: 'error',
          // trace_key: 6,
        })
      } else if (loopPunch.type === 'clock_in' && previousLoopPunch.type === 'clock_in') {
        // Clock in immediately preceding non working time scheduled clock in
        if (loopPunch.associated_allocation_type_key === 'non_working_time') {
          let clockInPunchTimestamp = previousLoopPunch.timestamp
          let clockOutPunchTimestamp = new Date().getTime()
          let clockInDateKey = returnFormattedDateKey(returnDateFromUnknownDateFormat(clockInPunchTimestamp))
          // let clockOutDateKey = returnFormattedDateKey( returnDateFromUnknownDateFormat( clockOutPunchTimestamp ) )
          let totalMinutes = (clockOutPunchTimestamp - clockInPunchTimestamp) / (1000 * 60)
          aggregatedData.totals.total_minutes += totalMinutes
          if (aggregatedData.totals.total_minutes_by_date[clockInDateKey] == null) {
            aggregatedData.totals.total_minutes_by_date[clockInDateKey] = 0
          }
          aggregatedData.totals.total_minutes_by_date[clockInDateKey] += totalMinutes
          // Handle Allocation Type Breakdowns
          if (previousLoopPunch.associated_allocation_type_key != null) {
            if (aggregatedData.breakdowns[previousLoopPunch.associated_allocation_type_key] == null) {
              aggregatedData.breakdowns[previousLoopPunch.associated_allocation_type_key] = {
                total_minutes: 0,
                total_minutes_by_date: {},
              }
            }
            aggregatedData.breakdowns[previousLoopPunch.associated_allocation_type_key].total_minutes += totalMinutes
            if (aggregatedData.breakdowns[previousLoopPunch.associated_allocation_type_key].total_minutes_by_date[clockInDateKey] == null) {
              aggregatedData.breakdowns[previousLoopPunch.associated_allocation_type_key].total_minutes_by_date[clockInDateKey] = 0
            }
            aggregatedData.breakdowns[previousLoopPunch.associated_allocation_type_key].total_minutes_by_date[clockInDateKey] += totalMinutes
          }
          // End Handle Allocation Type Breakdowns
          aggregatedData['warnings']['missing_last_clock_out'] = true
          aggregatedData['warning_count']++
          aggregatedData.combined_punch_events.push({
            ...prefixProps(previousLoopPunch, 'clock_in'),
            date_key: returnFormattedDateKey(returnDateFromUnknownDateFormat(previousLoopPunch.timestamp)),
            combined_punch_event_error_type: null,
            combined_punch_event_warning_type: 'missing_last_clock_out',
            event_has_error: false,
            event_has_warning: true,
            total_minutes: totalMinutes,
            combined_punch_event_type: 'active',
            // trace_key: 7,
          })
        } else {
          // Error: Back to back clock ins
          aggregatedData['errors']['back_to_back_clock_ins'] = true
          aggregatedData['error_count']++
          // Create Error Half Event
          aggregatedData.combined_punch_events.push({
            ...prefixProps(loopPunch, 'clock_in'),
            date_key: returnFormattedDateKey(returnDateFromUnknownDateFormat(loopPunch.timestamp)),
            combined_punch_event_error_type: 'back_to_back_clock_ins',
            combined_punch_event_error_text: rLIB('Back to back clock ins'),
            combined_punch_event_warning_type: null,
            event_has_error: true,
            event_has_warning: false,
            total_minutes: 0,
            combined_punch_event_type: 'error',
            // trace_key: 8,
          })
        }
      } else if (loopPunch.type === 'clock_out' && previousLoopPunch.type === 'clock_in') {
        // Fully Valid Event
        let clockInPunchTimestamp = previousLoopPunch.timestamp
        let clockOutPunchTimestamp = loopPunch.timestamp
        let clockInDateKey = returnFormattedDateKey(returnDateFromUnknownDateFormat(clockInPunchTimestamp))
        let clockOutDateKey = returnFormattedDateKey(returnDateFromUnknownDateFormat(clockOutPunchTimestamp))
        // Same Day Full Events
        if (clockInDateKey === clockOutDateKey) {
          let totalMinutes = (clockOutPunchTimestamp - clockInPunchTimestamp) / (1000 * 60)
          aggregatedData.totals.total_minutes += totalMinutes
          if (aggregatedData.totals.total_minutes_by_date[clockInDateKey] == null) {
            aggregatedData.totals.total_minutes_by_date[clockInDateKey] = 0
          }
          aggregatedData.totals.total_minutes_by_date[clockInDateKey] += totalMinutes
          // Handle Allocation Type Breakdowns
          if (previousLoopPunch.associated_allocation_type_key != null) {
            if (aggregatedData.breakdowns[previousLoopPunch.associated_allocation_type_key] == null) {
              aggregatedData.breakdowns[previousLoopPunch.associated_allocation_type_key] = {
                total_minutes: 0,
                total_minutes_by_date: {},
              }
            }
            aggregatedData.breakdowns[previousLoopPunch.associated_allocation_type_key].total_minutes += totalMinutes
            if (aggregatedData.breakdowns[previousLoopPunch.associated_allocation_type_key].total_minutes_by_date[clockInDateKey] == null) {
              aggregatedData.breakdowns[previousLoopPunch.associated_allocation_type_key].total_minutes_by_date[clockInDateKey] = 0
            }
            aggregatedData.breakdowns[previousLoopPunch.associated_allocation_type_key].total_minutes_by_date[clockInDateKey] += totalMinutes
          }
          // End Handle Allocation Type Breakdowns
          // Create Event
          aggregatedData.combined_punch_events.push({
            ...prefixProps(previousLoopPunch, 'clock_in'),
            ...prefixProps(loopPunch, 'clock_out'),
            date_key: clockInDateKey,
            combined_punch_event_error_type: null,
            combined_punch_event_warning_type: null,
            event_has_error: false,
            event_has_warning: false,
            total_minutes: totalMinutes,
            combined_punch_event_type: 'clocked_in',
            // trace_key: 9,
          })
        } else {
          // Spans multiple days
          aggregatedData['warnings']['multi_day_punches'] = true
          aggregatedData['warning_count']++
          // Loop and create multiple events
          let combinedEventDateRange = returnDateRangeKeys(clockInDateKey, clockOutDateKey)
          for (let loopDateIndex in combinedEventDateRange) {
            let loopDateKey = returnFormattedDateKey(returnDateFromUnknownDateFormat(combinedEventDateRange[loopDateIndex]))
            if (parseInt(loopDateIndex) === 0) {
              // First Date
              let dayEndTimestamp = new Date(
                parseInt(loopDateKey.split('-')[0]),
                parseInt(loopDateKey.split('-')[1]) - 1,
                parseInt(loopDateKey.split('-')[2]) + 1,
                0,
                0,
              )
              // @ts-expect-error - TODO: reason for error
              let totalMinutes = (dayEndTimestamp - clockInPunchTimestamp) / (1000 * 60)
              aggregatedData.totals.total_minutes += totalMinutes
              if (aggregatedData.totals.total_minutes_by_date[loopDateKey] == null) {
                aggregatedData.totals.total_minutes_by_date[loopDateKey] = 0
              }
              aggregatedData.totals.total_minutes_by_date[loopDateKey] += totalMinutes
              // Handle Allocation Type Breakdowns
              if (previousLoopPunch.associated_allocation_type_key != null) {
                if (aggregatedData.breakdowns[previousLoopPunch.associated_allocation_type_key] == null) {
                  aggregatedData.breakdowns[previousLoopPunch.associated_allocation_type_key] = {
                    total_minutes: 0,
                    total_minutes_by_date: {},
                  }
                }
                aggregatedData.breakdowns[previousLoopPunch.associated_allocation_type_key].total_minutes += totalMinutes
                if (aggregatedData.breakdowns[previousLoopPunch.associated_allocation_type_key].total_minutes_by_date[loopDateKey] == null) {
                  aggregatedData.breakdowns[previousLoopPunch.associated_allocation_type_key].total_minutes_by_date[loopDateKey] = 0
                }
                aggregatedData.breakdowns[previousLoopPunch.associated_allocation_type_key].total_minutes_by_date[loopDateKey] += totalMinutes
              }
              // End Handle Allocation Type Breakdowns
              // Create Event
              let combinedEvent: TsInterface_UnspecifiedObject = {
                ...prefixProps(previousLoopPunch, 'clock_in'),
                ...prefixProps(loopPunch, 'clock_out'),
                date_key: loopDateKey,
                combined_punch_event_error_type: null,
                combined_punch_event_warning_type: null,
                event_has_error: false,
                total_minutes: totalMinutes,
                combined_punch_event_type: 'clocked_in',
                // trace_key: 10,
              }
              combinedEvent['clock_out_timestamp'] = dayEndTimestamp
              aggregatedData.combined_punch_events.push(combinedEvent)
            } else if (parseInt(loopDateIndex) === combinedEventDateRange.length - 1) {
              // Last Date
              let dayStartTimestamp = new Date(
                parseInt(loopDateKey.split('-')[0]),
                parseInt(loopDateKey.split('-')[1]) - 1,
                parseInt(loopDateKey.split('-')[2]),
                0,
                0,
              )
              // @ts-expect-error - TODO: reason for error
              let totalMinutes = (clockOutPunchTimestamp - dayStartTimestamp) / (1000 * 60)
              aggregatedData.totals.total_minutes += totalMinutes
              if (aggregatedData.totals.total_minutes_by_date[loopDateKey] == null) {
                aggregatedData.totals.total_minutes_by_date[loopDateKey] = 0
              }
              aggregatedData.totals.total_minutes_by_date[loopDateKey] += totalMinutes
              // Handle Allocation Type Breakdowns
              if (previousLoopPunch.associated_allocation_type_key != null) {
                if (aggregatedData.breakdowns[previousLoopPunch.associated_allocation_type_key] == null) {
                  aggregatedData.breakdowns[previousLoopPunch.associated_allocation_type_key] = {
                    total_minutes: 0,
                    total_minutes_by_date: {},
                  }
                }
                aggregatedData.breakdowns[previousLoopPunch.associated_allocation_type_key].total_minutes += totalMinutes
                if (aggregatedData.breakdowns[previousLoopPunch.associated_allocation_type_key].total_minutes_by_date[loopDateKey] == null) {
                  aggregatedData.breakdowns[previousLoopPunch.associated_allocation_type_key].total_minutes_by_date[loopDateKey] = 0
                }
                aggregatedData.breakdowns[previousLoopPunch.associated_allocation_type_key].total_minutes_by_date[loopDateKey] += totalMinutes
              }
              // End Handle Allocation Type Breakdowns
              // Create Event
              let combinedEvent: TsInterface_UnspecifiedObject = {
                ...prefixProps(previousLoopPunch, 'clock_in'),
                ...prefixProps(loopPunch, 'clock_out'),
                date_key: loopDateKey,
                combined_punch_event_error_type: null,
                combined_punch_event_warning_type: null,
                event_has_error: false,
                total_minutes: totalMinutes,
                combined_punch_event_type: 'clocked_in',
                // trace_key: 11,
              }
              combinedEvent['clock_in_timestamp'] = dayStartTimestamp
              aggregatedData.combined_punch_events.push(combinedEvent)
            } else {
              // Middle Date
              let dayStartTimestamp = new Date(
                parseInt(loopDateKey.split('-')[0]),
                parseInt(loopDateKey.split('-')[1]) - 1,
                parseInt(loopDateKey.split('-')[2]),
                0,
                0,
              )
              let dayEndTimestamp = new Date(
                parseInt(loopDateKey.split('-')[0]),
                parseInt(loopDateKey.split('-')[1]) - 1,
                parseInt(loopDateKey.split('-')[2]) + 1,
                0,
                0,
              )
              // @ts-expect-error - TODO: reason for error
              let totalMinutes = (dayEndTimestamp - dayStartTimestamp) / (1000 * 60)
              aggregatedData.totals.total_minutes += totalMinutes
              if (aggregatedData.totals.total_minutes_by_date[loopDateKey] == null) {
                aggregatedData.totals.total_minutes_by_date[loopDateKey] = 0
              }
              aggregatedData.totals.total_minutes_by_date[loopDateKey] += totalMinutes
              // Handle Allocation Type Breakdowns
              if (previousLoopPunch.associated_allocation_type_key != null) {
                if (aggregatedData.breakdowns[previousLoopPunch.associated_allocation_type_key] == null) {
                  aggregatedData.breakdowns[previousLoopPunch.associated_allocation_type_key] = {
                    total_minutes: 0,
                    total_minutes_by_date: {},
                  }
                }
                aggregatedData.breakdowns[previousLoopPunch.associated_allocation_type_key].total_minutes += totalMinutes
                if (aggregatedData.breakdowns[previousLoopPunch.associated_allocation_type_key].total_minutes_by_date[loopDateKey] == null) {
                  aggregatedData.breakdowns[previousLoopPunch.associated_allocation_type_key].total_minutes_by_date[loopDateKey] = 0
                }
                aggregatedData.breakdowns[previousLoopPunch.associated_allocation_type_key].total_minutes_by_date[loopDateKey] += totalMinutes
              }
              // End Handle Allocation Type Breakdowns
              // Create Event
              let combinedEvent: TsInterface_UnspecifiedObject = {
                ...prefixProps(previousLoopPunch, 'clock_in'),
                ...prefixProps(loopPunch, 'clock_out'),
                date_key: loopDateKey,
                combined_punch_event_error_type: null,
                combined_punch_event_warning_type: null,
                event_has_error: false,
                total_minutes: totalMinutes,
                combined_punch_event_type: 'clocked_in',
                // trace_key: 12,
              }
              combinedEvent['clock_in_timestamp'] = dayStartTimestamp
              combinedEvent['clock_out_timestamp'] = dayEndTimestamp
              aggregatedData.combined_punch_events.push(combinedEvent)
            }
          }
        }
      }
    }
  }
  // Return Aggregate Data
  return aggregatedData
}

// TODO
export const refreshTimeSheetAdminEvaluation = (
  rawPunchData: TsInterface_UnspecifiedObject,
  defaultAllocations: TsInterface_UnspecifiedObject,
  overrideAllocations: TsInterface_UnspecifiedObject,
  weekTimesheets: TsInterface_UnspecifiedObject,
  tasks: TsInterface_UnspecifiedObject,
  cancelledTasks: TsInterface_UnspecifiedObject,
  activeHourlyUsers: TsInterface_UnspecifiedObject,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    // TODO - get active hourly users so names can be added to punchesSortedByDriver
    // Then able to sort alphabetically and jump to the ones that have errors or warnings or unfinished corrections
    // Next and Back "Error" buttons that setSelectedIndividualKey

    /*
				Currently Working
					- number / status of requested corrections
					- missing user timesheet submission
					- Discrepancies between total times submitted by an employee and what their time punch data shows
				Planned Features
					- Total error count in the punches (possibly simplified to just "has error" or "no error")

					- If an employee has overtime hours
					- If an employee has no hours

					- If an employee went over an allocated time type
					- If an employee went over an allocated time subtype (not over type though)

					- Check against what is actually on the schedule and verify (compare against estimated hours for task???)

				*/

    // Promise.all( promiseArray ).finally(() => {
    let outputData: TsInterface_UnspecifiedObject = {
      // Users with errors
      raw_data: {
        rawPunchData: rawPunchData,
        defaultAllocations: defaultAllocations,
        overrideAllocations: overrideAllocations,
        weekTimesheets: weekTimesheets,
        tasks: tasks,
        cancelledTasks: cancelledTasks,
      },
      formatted_data: {
        driver_punches: {},
        total_error_count: 0,
        // TODO - other analysis stuff
      },
    }
    let punchesSortedByDriver: TsInterface_UnspecifiedObject = {}
    for (let loopPunchKey in rawPunchData) {
      let loopPunch = rawPunchData[loopPunchKey]
      if (loopPunch != null || loopPunch.associated_user_key != null) {
        if (punchesSortedByDriver[loopPunch.associated_user_key] == null) {
          punchesSortedByDriver[loopPunch.associated_user_key] = []
        }
        punchesSortedByDriver[loopPunch.associated_user_key].push(loopPunch)
      }
    }
    let totalErrorCount = 0
    for (let loopDriverKey in punchesSortedByDriver) {
      let loopPunches = punchesSortedByDriver[loopDriverKey]
      let aggregatedData = aggregateTimesheetPunchData(objectToArray(cleanPunchDataBeforeEvaluation(loopPunches)))
      aggregatedData['key'] = loopDriverKey
      if (activeHourlyUsers != null && activeHourlyUsers[loopDriverKey] != null && activeHourlyUsers[loopDriverKey]['name'] != null) {
        aggregatedData['name'] = activeHourlyUsers[loopDriverKey]['name']
      }
      punchesSortedByDriver[loopDriverKey] = aggregatedData
      totalErrorCount += getProp(aggregatedData, 'error_count', 0)
    }
    // Output data
    outputData['formatted_data']['driver_punches'] = punchesSortedByDriver
    outputData['formatted_data']['total_error_count'] = totalErrorCount
    resolve({ success: true, data: outputData })
    // })
  })
}
