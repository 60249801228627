///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Badge, Box, Button, Card, CircularProgress, Typography } from '@mui/material'
import { returnExpandedTaskTableData } from 'app/models/projects/project_services'
import {
  formInputs_AdHocTaskNew,
  formOnChange_AdHocTask,
  formSettings_AdHocTask,
  formSubmission_AdHocTaskCreate,
  tableColumns_ProjectTasksFull,
} from 'app/models/tasks'
import { tableColumns_ProjectTaskGroups, tableColumns_SalesTasksFull } from 'app/models/tasks/task_table'
import { adHocTaskAssignmentTypeInputOptions } from 'app/models/tasks/task_workflow_services'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { TableBasic, TsInterface_TableColumns, TsInterface_TableSettings } from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { arrayToObject, cloneObjectWithoutReference, getProp, objectToArray, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Table
const tableSettings_ProjectTasks: TsInterface_TableSettings = {
  paginated: true,
  pagination_rows_per_page_default: 100,
  pagination_rows_per_page_options: [10, 25, 50, 100],
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  // sort_property_default: "id_number",
  sort_property_default: 'TEMP_completion_order',
  sortable: true,
  collapsible_columns: true,
  searchable: true,
  search_settings_basic: {
    search_no_data_message: rLIB('No tasks found by search') as JSX.Element,
    search_property_options: [
      { key: 'name', value: rLIB('Task Name') },
      { key: 'id_number', value: rLIB('Task ID') },
    ],
  },
  conditional_row_styles: [
    {
      className: 'bp_grayscale_80 bp_black_background',
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
    },
  ],
  sticky_header: true,
  sticky_table_height: 'calc(100vh - 350px)',
}

const tableSettings_TaskGroups: TsInterface_TableSettings = {
  paginated: false,
  pagination_rows_per_page_default: 100,
  pagination_rows_per_page_options: [10, 25, 50, 100],
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  // sort_property_default: "name",
  // sort_property_default: "TEMP_completion_order",
  sortable: false,
  collapsible_columns: true,
  searchable: false,
}

// Forms
const taskFilterOptions: TsInterface_UnspecifiedObject = {
  all: {
    key: 'all',
    value: rLIB('All Tasks'),
  },
  active_tasks: {
    key: 'active_tasks',
    value: rLIB('Active Tasks'),
  },
  completed_tasks: {
    key: 'completed_tasks',
    value: rLIB('Completed Tasks'),
  },
  future_tasks: {
    key: 'future_tasks',
    value: rLIB('Future Tasks'),
  },
  deleted: {
    key: 'deleted',
    value: rLIB('Deleted Tasks'),
  },
  not_deleted: {
    key: 'not_deleted',
    value: rLIB('Hide Deleted Tasks'),
  },
  user_tasks: {
    key: 'user_tasks',
    value: rLIB('My Tasks'),
  },
  unassigned: {
    key: 'unassigned',
    value: rLIB('Unassigned Tasks'),
  },
  invalid_prereq_data_structure: {
    key: 'invalid_prereq_data_structure',
    value: rLIB('Tasks With Invalid Prereqs'),
  },
}

const formInputs_TaskTableFilter: TsInterface_FormInputs = {
  table_filter: {
    key: 'table_filter',
    label: rLIB('Table Filter'),
    input_type: 'multiple_choice_radio',
    required: true,
    data_type: 'string',
    options: objectToArray(taskFilterOptions),
  },
}

///////////////////////////////
// Functions
///////////////////////////////

export const createAdHocTask = (
  taskType: string,
  projectKey: string,
  projectTaskWorkflow: TsInterface_UnspecifiedObject,
  projectTasks: TsInterface_UnspecifiedObject,
  tempTaskOwnerRolesOptions: TsInterface_UnspecifiedObject[],
  tempTaskFormOptions: TsInterface_UnspecifiedObject[],
  uc_setUserInterface_FormDialogDisplay: any,
  formData: TsInterface_UnspecifiedObject,
  sowItemKey: string | null,
): void => {
  let taskGroupOptions: TsInterface_UnspecifiedObject[] = []
  if (projectTaskWorkflow != null && projectTaskWorkflow.task_groups) {
    for (let loopTaskGroupIndex in projectTaskWorkflow.task_groups) {
      let loopTaskGroup = projectTaskWorkflow.task_groups[loopTaskGroupIndex]
      if (loopTaskGroup != null && loopTaskGroup.key != null && loopTaskGroup.name != null) {
        taskGroupOptions.push({ key: loopTaskGroup.key, value: loopTaskGroup.name })
      }
    }
  }
  let upstreamTaskOptions: TsInterface_UnspecifiedObject[] = []
  let downstreamTaskOptions: TsInterface_UnspecifiedObject[] = []
  if (projectTasks != null) {
    for (let loopTaskKey in projectTasks) {
      let loopTask = projectTasks[loopTaskKey]
      if (loopTask != null && loopTask.key != null && loopTask.name != null) {
        if (loopTask.status === 'deleted') {
          upstreamTaskOptions.push({ key: loopTask.key, value: loopTask.name, disabled: true })
          downstreamTaskOptions.push({ key: loopTask.key, value: loopTask.name, disabled: true })
        } else {
          upstreamTaskOptions.push({ key: loopTask.key, value: loopTask.name, disabled: false })
          if (loopTask.status_complete === true) {
            downstreamTaskOptions.push({ key: loopTask.key, value: loopTask.name, disabled: true })
          } else {
            downstreamTaskOptions.push({ key: loopTask.key, value: loopTask.name, disabled: false })
          }
        }
      }
    }
  }
  if (formData != null && formData.downstream_tasks != null) {
    for (let loopTaskKey in formData.downstream_tasks) {
      formData.downstream_tasks[projectKey + '_' + loopTaskKey] = true
    }
  }
  if (formData != null && formData.upstream_tasks != null) {
    for (let loopTaskKey in formData.upstream_tasks) {
      formData.upstream_tasks[projectKey + '_' + loopTaskKey] = true
    }
  }
  if (formInputs_AdHocTaskNew['associated_owner_type'] != null && tempTaskOwnerRolesOptions != null) {
    formInputs_AdHocTaskNew['associated_owner_type']['options'] = tempTaskOwnerRolesOptions
  }
  if (formInputs_AdHocTaskNew['associated_dispatcher_type'] != null && tempTaskOwnerRolesOptions != null) {
    formInputs_AdHocTaskNew['associated_dispatcher_type']['options'] = tempTaskOwnerRolesOptions
  }
  if (formInputs_AdHocTaskNew['associated_task_form_key'] != null && tempTaskFormOptions != null) {
    formInputs_AdHocTaskNew['associated_task_form_key']['options'] = tempTaskFormOptions
  }
  if (formInputs_AdHocTaskNew['associated_task_group_key'] != null && taskGroupOptions != null) {
    formInputs_AdHocTaskNew['associated_task_group_key']['options'] = taskGroupOptions
  }
  if (formInputs_AdHocTaskNew['upstream_tasks'] != null && upstreamTaskOptions != null) {
    formInputs_AdHocTaskNew['upstream_tasks']['options'] = upstreamTaskOptions
  }
  if (formInputs_AdHocTaskNew['downstream_tasks'] != null && downstreamTaskOptions != null) {
    formInputs_AdHocTaskNew['downstream_tasks']['options'] = downstreamTaskOptions
  }
  if (projectTaskWorkflow != null && projectTaskWorkflow.workflow_version_number != null && projectTaskWorkflow.workflow_version_number >= 2) {
    let disabledTaskCompletionTypeOptions = { ...adHocTaskAssignmentTypeInputOptions }
    if (taskType === 'ad_hoc') {
      disabledTaskCompletionTypeOptions['scheduled']['disabled'] = true
    }
    formInputs_AdHocTaskNew['task_completion_type']['options'] = objectToArray(disabledTaskCompletionTypeOptions)
  }
  // Open up dialog
  uc_setUserInterface_FormDialogDisplay({
    display: true,
    form: {
      form: {
        formAdditionalData: {
          projectTasks: projectTasks,
          originalFormData: cloneObjectWithoutReference(formData),
          sow_item_key: sowItemKey,
          task_type: taskType,
          associated_project_key: projectKey,
          task_groups: arrayToObject(projectTaskWorkflow.task_groups, 'key'),
        },
        formData: formData,
        formInputs: formInputs_AdHocTaskNew,
        formOnChange: formOnChange_AdHocTask,
        formSettings: formSettings_AdHocTask,
        formSubmission: (
          formSubmittedData: TsInterface_FormSubmittedData,
          formAdditionalData: TsInterface_FormAdditionalData,
          formHooks: TsInterface_FormHooksObject,
        ) => {
          return new Promise((resolve, reject) => {
            formSubmission_AdHocTaskCreate(formSubmittedData, formAdditionalData, formHooks)
              .then((res_FSAHTC) => {
                resolve(res_FSAHTC)
              })
              .catch((rej_FSAHTC) => {
                reject(rej_FSAHTC)
                formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_FSAHTC.error })
              })
          })
        },
      },
      dialog: {
        formDialogHeaderColor: 'success',
        formDialogHeaderText: rLIB('New Task'),
        formDialogIcon: (
          <Icon
            type="solid"
            icon="pen-to-square"
          />
        ),
      },
    },
  })
}

///////////////////////////////
// JSX
///////////////////////////////

const rJSX_WorkflowNameAndVersionText = (rootProjectLoaded: any, rootProject: any): JSX.Element => {
  let workflowNameAndVersionTextJSX = <></>
  if (rootProjectLoaded === true) {
    let versionKeyJSX: JSX.Element = <></>
    if (
      rootProject != null &&
      rootProject['associated_task_workflow_version_key'] != null &&
      !isNaN(parseInt(rootProject.associated_task_workflow_version_key))
    ) {
      versionKeyJSX = <>({returnFormattedDate(parseInt(rootProject.associated_task_workflow_version_key), 'YYYY-MM-DD')})</>
    }
    if (rootProject != null && rootProject['associated_task_workflow_name'] != null) {
      workflowNameAndVersionTextJSX = (
        <Box className="tw-opacity-30 tw-text-right">
          <Typography
            variant="body1"
            className="tw-mt-1"
          >
            {rLIB('Task Workflow')}: {rootProject.associated_task_workflow_name} {versionKeyJSX}
          </Typography>
        </Box>
      )
    }
  }
  return workflowNameAndVersionTextJSX
}

const rJSX_ToggleViewButton = (taskTableView: any, setTaskTableView: any) => {
  let buttonJSX = <></>
  if (taskTableView === 'groups') {
    buttonJSX = (
      <Button
        className="tw-m-auto tw-ml-2"
        color="warning"
        variant="outlined"
        startIcon={<Icon icon="layer-group"></Icon>}
        onClick={() => {
          setTaskTableView('tasks')
        }}
      >
        {rLIB('Viewing Task Groups')}
      </Button>
    )
  } else {
    buttonJSX = (
      <Button
        className="tw-m-auto tw-ml-2"
        color="warning"
        variant="outlined"
        startIcon={<Icon icon="list-check"></Icon>}
        onClick={() => {
          setTaskTableView('groups')
        }}
      >
        {rLIB('Viewing Task List')}
      </Button>
    )
  }
  return buttonJSX
}

const rJSX_TaskTable = (
  taskTableView: any,
  viewType: any,
  projectTasks: any,
  readOrWrite: any,
  projectTaskWorkflow: any,
  combinedUserRoles: any,
  rescursiveTasks: any,
  taskPrerequisitesValidityCheck: any,
  tempTaskFormOptions: any,
  uc_RootData_ClientKey: any,
  taskTableFilter: any,
  uc_RootData_GlobalUser: any,
  groupedTaskData: any,
) => {
  let tableJSX = <></>
  let taskTableColumns: TsInterface_TableColumns = {}
  if (taskTableView === 'groups') {
    tableJSX = (
      <Card className="">
        <TableBasic
          tableAdditionalData={{
            readOrWrite: readOrWrite,
            projectTaskWorkflow: projectTaskWorkflow,
            projectTasks: projectTasks,
          }}
          tableColumns={tableColumns_ProjectTaskGroups}
          tableData={groupedTaskData}
          tableSettings={tableSettings_TaskGroups}
        />
      </Card>
    )
  } else {
    if (readOrWrite === 'write') {
      if (viewType === 'full') {
        taskTableColumns = tableColumns_ProjectTasksFull
      } else if (viewType === 'sales') {
        taskTableColumns = tableColumns_SalesTasksFull
      }
    } else {
      taskTableColumns = tableColumns_SalesTasksFull
    }
    // if( viewType === "full" ){
    // 	taskTableColumns = tableColumns_ProjectTasksFull
    // } else if( viewType === "sales"  ){
    // 	taskTableColumns = tableColumns_SalesTasksFull
    // }
    if (objectToArray(projectTasks).length > 0) {
      tableJSX = (
        <Card className="">
          <TableBasic
            tableAdditionalData={{
              readOrWrite: readOrWrite,
              projectTaskWorkflow: projectTaskWorkflow,
              combinedUserRoles: combinedUserRoles,
              projectTasks: projectTasks,
              rescursiveTasks: rescursiveTasks,
              taskPrerequisitesValidityCheck: taskPrerequisitesValidityCheck,
              tempTaskFormOptions: tempTaskFormOptions,
            }}
            tableColumns={taskTableColumns}
            tableData={returnExpandedTaskTableData(
              uc_RootData_ClientKey,
              projectTasks,
              projectTaskWorkflow,
              taskTableFilter,
              getProp(uc_RootData_GlobalUser, 'key', null),
              rescursiveTasks,
              ['rJSX_TasksTab'],
            )}
            tableSettings={tableSettings_ProjectTasks}
          />
        </Card>
      )
    } else {
      tableJSX = (
        <Box className="tw-text-center tw-mt-4">
          <Typography
            variant="h6"
            className="tw-opacity-30 tw-italic"
          >
            {rLIB('No tasks linked to project')}
          </Typography>
        </Box>
      )
    }
  }
  return tableJSX
}

///////////////////////////////
// JSX Exports
///////////////////////////////

export const rJSX_TasksTab = (
  viewType: 'full' | 'sales',
  readOrWrite: 'read' | 'write',
  uc_RootData_ClientKey: any,
  uc_RootData_GlobalUser: any,
  combinedUserRoles: any,
  projectKey: any,
  projectTaskWorkflow: any,
  projectTasks: any,
  projectTasksLoaded: any,
  recursiveTasks: any,
  rootProject: any,
  rootProjectLoaded: any,
  setTaskTableFilter: any,
  taskTableView: any,
  setTaskTableView: any,
  groupedTaskData: any,
  uc_setUserInterface_FormDialogDisplay: any,
  taskPrerequisitesValidityCheck: any,
  taskTableFilter: any,
  tempTaskFormOptions: any,
  tempTaskOwnerRolesOptions: any,
): JSX.Element => {
  let tasksTabJSX = <></>
  if (projectTasksLoaded === true) {
    tasksTabJSX = (
      <Box>
        <Box className="tw-text-left tw-mb-2">
          <Button
            className="tw-m-auto"
            color="success"
            variant="contained"
            startIcon={<Icon icon="circle-plus"></Icon>}
            disabled={readOrWrite === 'read' || rootProject.id_number == null}
            onClick={() => {
              createAdHocTask(
                'ad_hoc',
                projectKey,
                projectTaskWorkflow,
                projectTasks,
                tempTaskOwnerRolesOptions,
                tempTaskFormOptions,
                uc_setUserInterface_FormDialogDisplay,
                {},
                null,
              )
            }}
          >
            {rLIB('Create Ad-Hoc Task')}
          </Button>
          <Button
            className="tw-m-auto tw-opacity-30 tw-ml-2"
            color="inherit"
            variant="outlined"
            startIcon={
              <Icon
                icon="filter"
                type="light"
              ></Icon>
            }
            onClick={() => {
              uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {},
                    formData: {
                      table_filter: taskTableFilter,
                    },
                    formInputs: formInputs_TaskTableFilter,
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {},
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        setTaskTableFilter(formSubmittedData.table_filter)
                        resolve({ success: true })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: rLIB('Select a Filter'),
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="filter"
                      />
                    ),
                  },
                },
              })
            }}
          >
            {taskFilterOptions[taskTableFilter]['value']}
          </Button>
          {rJSX_ToggleViewButton(taskTableView, setTaskTableView)}
        </Box>
        {rJSX_TaskTable(
          taskTableView,
          viewType,
          projectTasks,
          readOrWrite,
          projectTaskWorkflow,
          combinedUserRoles,
          recursiveTasks,
          taskPrerequisitesValidityCheck,
          tempTaskFormOptions,
          uc_RootData_ClientKey,
          taskTableFilter,
          uc_RootData_GlobalUser,
          groupedTaskData,
        )}
        {rJSX_WorkflowNameAndVersionText(rootProjectLoaded, rootProject)}
      </Box>
    )
  } else {
    tasksTabJSX = (
      <Box className="tw-text-center tw-mt-4">
        <CircularProgress
          size="100px"
          variant="indeterminate"
          color="secondary"
          className="tw-m-auto"
        />
      </Box>
    )
  }
  return tasksTabJSX
}

export const rJSX_TaskTabHeader = (rootProject: any): JSX.Element => {
  let headerJSX = <>{rLIB('Tasks')}</>
  if (rootProject == null || rootProject.current_active_tasks == null || objectToArray(rootProject.current_active_tasks).length === 0) {
    // No active tasks
  } else {
    headerJSX = (
      <>
        <Badge
          badgeContent={<Icon icon="triangle-exclamation" />}
          color="error"
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <>{rLIB('Tasks')}</>
        </Badge>
      </>
    )
  }
  return headerJSX
}
