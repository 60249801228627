///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, CircularProgress, createTheme, Dialog, DialogActions, DialogContent, DialogTitle, ThemeProvider } from '@mui/material'
import { getAuth } from 'firebase/auth'
import { getDownloadURL, getStorage, ref } from 'firebase/storage'
import 'leaflet/dist/leaflet.css'
import { useCallback, useEffect, useReducer, useRef, useState } from 'react'
import CookieConsent from 'react-cookie-consent'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_User_Document } from 'rfbp_aux/services/database_endpoints/directory/users'
import { DatabaseRef_SalesEagleviewPropose_Document } from 'rfbp_aux/services/database_endpoints/eagleview_propose'
import { DatabaseRef_UnprotectedOpportunityUpdateTime_Document } from 'rfbp_aux/services/database_endpoints/sales/opportunities'
import {
  DatabaseRef_SaleOpportunityFiles_Collection,
  DatabaseRef_SaleOpportunityFile_Document,
  DatabaseRef_SaleOpportunityPhotos_Collection,
  DatabaseRef_SalesOpportunity_v2_Document,
} from 'rfbp_aux/services/database_endpoints/sales/opportunities_v2'
import {
  DatabaseRef_ProposalFiles_Collection,
  DatabaseRef_ProposalPhotos_Collection,
  DatabaseRef_ProposalsForOpportunity_Query,
} from 'rfbp_aux/services/database_endpoints/sales/proposals'
import { StorageRef_SalesOpportunityFile } from 'rfbp_aux/services/storage_endpoints/sales_opportunities'
import { rLIB } from 'rfbp_core/localization/library'
import {
  DatabaseBatchUpdate,
  DatabaseGetCollection,
  DatabaseGetDocument,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  DatabaseUpdateDocument,
  StorageUploadFile,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import { getProp, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { rJSX_EditPanelLayout, rJSX_ResetPasswordPage, rJSX_WelcomeAddressBanner, rJSX_WelcomePage } from './authenticated_sales_opportunity_v3_jsx'
import { convertEvEnergyTableDataToSolarPanels } from './functions/sales_opportunity_v3_functions'
// @ts-expect-error -  reason for error
import logoSrc from '../../images/logos/logo_temp.png'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

interface TsInterface_FilesToUpload {
  [$fileKey: string]: {
    file_name: string
    file: any
    data_url: any
    storage_url?: string | null
  }
}

type ImageState = {
  [key: string]: File | null
}

export interface TsInterface_EditDialogState {
  open: boolean
  field: string
  value: string | number
  proposalId: number
}

export interface TsInterface_Proposal {
  id: number
  name: string
  systemSize: number
  numPanels: number
  annualProduction: number
  mountChoice: string
  reroof: string
  batteries: number
  type: string
  systemCost: number
  lunarBatteryCost: number
  adders: []
  discounts: []
  totalIncentives: number
  fedIncentives: number
  stateIncentives: number
  netCost: number
  active: boolean
  offset: number
  files: TsInterface_UnspecifiedObject
  photos: TsInterface_UnspecifiedObject
  solar_only_bill_data: TsInterface_UnspecifiedObject
  solar_only_bill_consumption_profile: TsInterface_UnspecifiedObject
  solar_plus_battery_bill_data: TsInterface_UnspecifiedObject
  solar_plus_battery_bill_consumption_profile: TsInterface_UnspecifiedObject
}

const white = '#ffffff'
const teal = '#1E83A1'
const aqua = '#3fdeff'

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: teal,
      light: aqua,
      contrastText: white,
    },
  },
})

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AuthenticatedSalesOpportunityWelcomePage']['key']
const clientKey: string = 'etw_energy_sales'

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  // const [ us_items, us_setItems ] = 		useState<TsInterface_UnspecifiedObject>({})
  // const ur_forceRerender = 				useReducer(() => ({}), {})[1] as () => void
  // const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = 		useContext( Context_RootData_ClientKey )
  // { sort-end } - hooks
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const [searchParams] = useSearchParams()
  const userID = searchParams.get('userID') || ''
  const auth = getAuth()
  const user = auth.currentUser

  // const proposalKeysRef = useRef({
  //   primary: '',
  //   secondary: '',
  //   tertiary: '',
  // })

  const un_routerNavigation = useNavigate()
  const photoInputRef = useRef<HTMLInputElement>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)

  //system details
  const [us_selectedTab, us_setSelectedTab] = useState<string>('systemDetails')
  const [us_editingField, us_setEditingField] = useState<string | null>(null)
  const [us_editValue, us_setEditValue] = useState<string>('')
  const [us_editDialog, us_setEditDialog] = useState<TsInterface_EditDialogState>({
    open: false,
    field: '',
    value: '',
    proposalId: 0,
  })
  const [us_editRequestText, us_setEditRequestText] = useState('')
  const [us_showEditRequestConfirmation, us_setShowEditRequestConfirmation] = useState(false)
  const [us_editRequestDialogOpen, us_setEditRequestDialogOpen] = useState<boolean>(false)
  const [us_requestSupportDialogOpen, us_setRequestSupportDialogOpen] = useState<boolean>(false)
  const [us_showRequestSupportConfirmation, us_setShowRequestSupportConfirmation] = useState<boolean>(false)

  const [us_selectedMountOption, us_setSelectedMountOption] = useState<string>('')
  const [us_selectedReroofOption, us_setSelectedReroofOption] = useState<string>('')
  const [us_selectedNumBatteriesOption, us_setSelectedNumBatteriesOption] = useState<number | null>(null)
  const [us_selectedFinancingOption, us_setSelectedFinancingOption] = useState<string>('')

  const [us_opportunityData, us_setOpportunityData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activeProposalId, us_setActiveProposalId] = useState<string>('')
  const [us_proposals, us_setProposals] = useState<TsInterface_Proposal[]>([])
  const [us_eagleviewProspectData, us_setEagleviewProspectData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_photosData, us_setPhotosData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activeProposalPhoto, us_setActiveProposalPhoto] = useState<string>('')
  const [us_dataLoaded, us_setIsDataLoaded] = useState<boolean>(false)
  const [us_homeDetailsExpanded, us_setHomeDetailsExpanded] = useState<boolean>(true)
  const [us_opportunityKey, us_setOpportunityKey] = useState<string>('')

  const [us_selectedFinancingType, us_setSelectedFinancingType] = useState<string>('cash')
  const [us_selectedRoofType, us_setSelectedRoofType] = useState<string>('')
  const [us_selectedMountType, us_setSelectedMountType] = useState<string>('roof')
  const [us_numBatteries, us_setNumBatteries] = useState<number>(0)
  const [us_roofReplacement, us_setRoofReplacement] = useState<boolean>(false)
  const [us_systemSizeInWatts, us_setSystemSizeInWatts] = useState<number>(0)
  const [us_offset, us_setOffset] = useState<number>(100)
  const [us_cashSavings, us_setCashSavings] = useState<number>(0)
  const [us_leaseMonthlySavings, us_setLeaseMonthlySavings] = useState<number>(0)
  const [us_leaseCumulativeSavings, us_setLeaseCumulativeSavings] = useState<number>(0)
  const [us_numPanels, us_setNumPanels] = useState(0)
  const [us_baseCashCost, us_setBaseCashCost] = useState<number>(0)
  const [us_currentCashCost, us_setCurrentCashCost] = useState<number>(0)
  const [us_leaseCost, us_setLeaseCost] = useState<number>(0)
  const [us_systemAnnualProductionInKWh, us_setSystemAnnualProductionInKWh] = useState<number>(0)
  const [us_federalIncentive, us_setFederalIncentive] = useState<number>(0)
  const [us_srecIncentive, us_setSrecIncentive] = useState<number>(0)
  const [us_lunarBatteryCost, us_setLunarBatteryCost] = useState<number>(0)
  const [us_adders, us_setAdders] = useState<Array<{ name: string; amount: number }>>([])
  const [us_discounts, us_setDiscounts] = useState<Array<{ name: string; amount: number }>>([])

  const [us_currentView, us_setCurrentView] = useState<string>('welcome')
  const [us_firstName, us_setFirstName] = useState<string>('')
  const [us_lastName, us_setLastName] = useState<string>('')
  const [us_email, us_setEmail] = useState<string>('')
  const [us_phone, us_setPhone] = useState<string>('')
  const [us_address, us_setAddress] = useState<string>('')
  const [us_city, us_setCity] = useState<string>('')
  const [us_state, us_setState] = useState<string>('')
  const [us_zip, us_setZip] = useState<string>('')
  const [us_latitude, us_setLatitude] = useState<number>(0)
  const [us_longitude, us_setLongitude] = useState<number>(0)
  const [us_productType, us_setProductType] = useState<string>('')
  const [us_utilityCompanyName, us_setUtilityCompanyName] = useState<string>('')
  const [us_electricityBill, us_setElectricityBill] = useState<number>(0)
  const [us_showFinanceDetailsInWelcome, us_setShowFinanceDetailsInWelcome] = useState<boolean>(false)
  const [us_showOptionsDetailsInWelcome, us_setShowOptionsDetailsInWelcome] = useState<boolean>(false)
  const [us_solarOnlyBillData, us_setSolarOnlyBillData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_solarOnlyBillConsumptionProfile, us_setSolarOnlyBillConsumptionProfile] = useState<TsInterface_UnspecifiedObject>({})
  const [us_solarPlusBatteryBillData, us_setSolarPlusBatteryBillData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_solarPlusBatteryBillConsumptionProfile, us_setSolarPlusBatteryBillConsumptionProfile] = useState<TsInterface_UnspecifiedObject>({})

  const [us_newPassword, us_setNewPassword] = useState('')
  const [us_confirmPassword, us_setConfirmPassword] = useState('')

  const [us_compareMode, us_setCompareMode] = useState<boolean>(false)
  const [us_selectedLeft, us_setSelectedLeft] = useState<number>(0)
  const [us_selectedRight, us_setSelectedRight] = useState<number>(1)

  //eagleview prospect data
  const [us_evImageURL, us_setEvImageURL] = useState<string>('')
  const [us_evEnergyTableData, us_setEvEnergyTableData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_evFacetData, us_setEvFacetData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_evRoofData, us_setEvRoofData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_panels, us_setPanels] = useState<TsInterface_UnspecifiedObject>({})

  const [us_showFirstLoginDialog, us_setShowFirstLoginDialog] = useState<boolean>(false)
  const [us_photoKey, us_setPhotoKey] = useState<string>('')

  const [us_imageStates, us_setImageStates] = useState<ImageState>({
    houseFrontImage: null,
    houseBackImage: null,
    houseLeftSideImage: null,
    houseRightSideImage: null,
    houseFrontWithRoofImage: null,
    houseBackWithRoofImage: null,
    houseLeftSideWithRoofImage: null,
    houseRightSideWithRoofImage: null,
    roofCloseUpImage: null,
    atticInsideImage: null,
    atticRaftersImage: null,
    atticPlywoodImage: null,
    mainServicePanelImage: null,
    mainServicePanelCloseUpImage: null,
    mainServicePanelDoorsImage: null,
    subPanelWideImage: null,
    subPanelCloseUpImage: null,
    meterLocationWideImage: null,
    meterLocationCloseUpImage: null,
    existingSolarSystemImage: null,
    batteryStorageImage: null,
    generatorImage: null,
    poolImage: null,
    hotTubImage: null,
    electricHeatingImage: null,
    electricWaterHeaterImage: null,
    electricDryerImage: null,
    evChargerImage: null,
  })
  const [us_uploadedPhotos, us_setUploadedPhotos] = useState<TsInterface_UnspecifiedObject>({})
  const [us_photoInfoDialogOpen, us_setPhotoInfoDialogOpen] = useState<boolean>(false)
  const [us_uploadedFiles, us_setUploadedFiles] = useState<TsInterface_UnspecifiedObject>({})
  const [us_showPhotoAssignmentModal, us_setShowPhotoAssignmentModal] = useState<boolean>(false)
  const [us_selectedCategories, us_setSelectedCategories] = useState<string[]>([])

  //survey data
  const [us_fileKey, us_setFileKey] = useState<string>('')
  const [us_selectedPurchaseTimeline, us_setSelectedPurchaseTimeline] = useState<string>('')
  const [us_partOfHOA, us_setPartOfHOA] = useState<string>('')
  const [us_hoaName, us_setHoaName] = useState<string>('')
  const [us_hoaPhone, us_setHoaPhone] = useState<string>('')
  const [us_selectedHomeType, us_setSelectedHomeType] = useState<string>('')
  const [us_selectedHomeAge, us_setSelectedHomeAge] = useState<string>('')
  const [us_accessToAllExteriorWalls, us_setAccessToAllExteriorWalls] = useState<boolean>(false)
  const [us_noAccessReason, us_setNoAccessReason] = useState<string>('')
  const [us_selectedRoofAge, us_setSelectedRoofAge] = useState<string>('')
  const [us_hasAttic, us_setHasAttic] = useState<boolean>(false)
  const [us_atticAccess, us_setAtticAccess] = useState<string>('')
  const [us_mainBreakerMainPanelRating, us_setMainBreakerMainPanelRating] = useState<number>(0)
  const [us_breakerMainPanelCount, us_setBreakerMainPanelCount] = useState<number>(0)
  const [us_breakerMainPanelSum, us_setBreakerMainPanelSum] = useState<number>(0)
  const [us_subPanelCount, us_setSubPanelCount] = useState<number>(0)
  const [us_subPanelLocation, us_setSubPanelLocation] = useState<string>('')
  const [us_hasSolarSystem, us_setHasSolarSystem] = useState<boolean>(false)
  const [us_hasBatteryStorage, us_setHasBatteryStorage] = useState<boolean>(false)
  const [us_hasGenerator, us_setHasGenerator] = useState<boolean>(false)
  const [us_hasSpecializedElectricalEquipment, us_setHasSpecializedElectricalEquipment] = useState<boolean>(false)
  const [us_hasOtherHighLoadItem, us_setHasOtherHighLoadItem] = useState<boolean>(false)
  const [us_otherHighLoadItem, us_setOtherHighLoadItem] = useState<string>('')
  const [us_hasPool, us_setHasPool] = useState<boolean>(false)
  const [us_hasHotTub, us_setHasHotTub] = useState<boolean>(false)
  const [us_hasElectricHeating, us_setHasElectricHeating] = useState<boolean>(false)
  const [us_hasElectricWaterHeater, us_setHasElectricWaterHeater] = useState<boolean>(false)
  const [us_hasElectricDryer, us_setHasElectricDryer] = useState<boolean>(false)
  const [us_hasEVCharger, us_setHasEVCharger] = useState<boolean>(false)
  const [us_preferredContactMethod, us_setPreferredContactMethod] = useState<string>('')
  const [us_preferredContactTimes, us_setPreferredContactTimes] = useState<string>('')
  const [us_hasPets, us_setHasPets] = useState<boolean>(false)
  const [us_hasGateCode, us_setHasGateCode] = useState<boolean>(false)
  const [us_gateCode, us_setGateCode] = useState<string>('')
  const [us_panelPlacementPreference, us_setPanelPlacementPreference] = useState<string>('')
  const [us_inverterPlacementPreference, us_setInverterPlacementPreference] = useState<string>('')
  const [us_electricityBillFile, us_setElectricityBillFile] = useState<File | null>(null)
  const [us_utilityBillUploaded, us_setUtilityBillUploaded] = useState<boolean>(false)
  const [us_opportunityFiles, us_setOpportunityFiles] = useState<TsInterface_UnspecifiedObject>({})

  //usage and production
  const [us_exportCreditsWeekDayData, us_setExportCreditsWeekDayData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_exportCreditsWeekendData, us_setExportCreditsWeekendData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_solarOnlyBill, us_setSolarOnlyBill] = useState<TsInterface_UnspecifiedObject>({})
  const [us_solarPlusBatteryBill_v2, us_setSolarPlusBatteryBill_v2] = useState<TsInterface_UnspecifiedObject>({})
  const [us_solarPlusBatteryBill_v2_consumptionProfile, us_setSolarPlusBatteryBill_v2_consumptionProfile] = useState<{
    [month: number]: { [hour: number]: any }
  } | null>(null)
  const [us_solarOnlyBill_consumptionProfile, us_setSolarOnlyBill_consumptionProfile] = useState<{
    [month: number]: { [hour: number]: any }
  } | null>(null)
  const [us_selectedMonth, us_setSelectedMonth] = useState<number | null>(0)
  const [us_selectedHour, us_setSelectedHour] = useState<number | null>(0)
  const [us_selectedConsumptionProfile, us_setSelectedConsumptionProfile] = useState<string>('solar_only')
  const [us_data, us_setData] = useState<any[]>([])

  const getUser = useCallback(async (userID: string) => {
    await DatabaseGetDocument(DatabaseRef_User_Document(clientKey, userID)).then((res_DGD: any) => {
      us_setOpportunityKey(res_DGD.data?.associated_opportunity_key ?? '')
      if (res_DGD.data?.first_login !== false) {
        us_setShowFirstLoginDialog(true)
        DatabaseUpdateDocument(DatabaseRef_User_Document(clientKey, userID), {
          first_login: false,
        })
      }
      return res_DGD.data
    })
  }, [])

  const fetchProposalFiles = async (clientKey: string, proposalId: string) => {
    try {
      const filesRes = await DatabaseGetCollection(DatabaseRef_ProposalFiles_Collection(clientKey, proposalId))
      return filesRes.data || []
    } catch (error) {
      console.error('Error fetching proposal files:', error)
      return []
    }
  }

  const fetchProposalPhotos = async (clientKey: string, proposalId: string) => {
    try {
      const photosRes = await DatabaseGetCollection(DatabaseRef_ProposalPhotos_Collection(clientKey, proposalId))
      const photos = photosRes.data || []
      const photosArray = Object.values(photos)
      // Convert Firestore URLs to download URLs
      if (photosArray.length > 0) {
        const storage = getStorage()
        const photoUrls = await Promise.all(
          photosArray.map(async (photo: any) => {
            const storageRef = ref(storage, photo.url) // Assuming 'url' is the field with the storage path
            return await getDownloadURL(storageRef)
          }),
        )
        return photoUrls
      } else {
        return []
      }
    } catch (error) {
      console.error('Error fetching proposal photos:', error)
      return []
    }
  }

  const loadPageData = useCallback(async (clientKey: string, opportunityKey: string) => {
    const handleOpportunityData = (opportunityData: any, photosData: any, eagleviewProspectData: any) => {
      if (!opportunityData) return

      us_setEmail(opportunityData.associated_customer_email)
      us_setPhone(opportunityData.associated_customer_phone)
      us_setAddress(opportunityData.location_address)
      us_setCity(opportunityData.location_city)
      us_setState(opportunityData.location_state)
      us_setZip(opportunityData.location_zip)
      us_setLatitude(opportunityData.location_latitude)
      us_setLongitude(opportunityData.location_longitude)
      us_setFirstName(opportunityData.associated_customer_first_name)
      us_setLastName(opportunityData.associated_customer_last_name)
      us_setUtilityCompanyName(opportunityData.associated_utility_company_name)
      us_setElectricityBill(opportunityData.system_monthly_electricity_bill)
      us_setSelectedRoofType(opportunityData.home_roof_type)
      us_setProductType(opportunityData.product_type)
      //survey data
      us_setHoaName(opportunityData.associated_hoa_name)
      us_setHoaPhone(opportunityData.associated_hoa_phone)
      us_setPartOfHOA(opportunityData.contact_part_of_hoa)
      us_setPreferredContactMethod(opportunityData.contact_preferred_method)
      us_setPreferredContactTimes(opportunityData.contact_preferred_contact_time)
      us_setHasPets(opportunityData.home_has_pets)
      us_setHasGateCode(opportunityData.home_has_gate_code)
      us_setGateCode(opportunityData.home_gate_code)
      us_setPanelPlacementPreference(opportunityData.system_placement_preferences)
      us_setInverterPlacementPreference(opportunityData.system_inverter_preferences)
      us_setHasPool(opportunityData.home_has_pool)
      us_setHasHotTub(opportunityData.home_has_hot_tub)
      us_setHasElectricHeating(opportunityData.electric_heating)
      us_setHasElectricWaterHeater(opportunityData.electric_water_heater)
      us_setHasElectricDryer(opportunityData.electric_dryer)
      us_setHasEVCharger(opportunityData.home_ev_charger)
      us_setHasSpecializedElectricalEquipment(opportunityData.electric_specialized_equipment)
      us_setHasOtherHighLoadItem(opportunityData.has_home_electric_other)
      us_setOtherHighLoadItem(opportunityData.home_electric_other)
      us_setAccessToAllExteriorWalls(opportunityData.home_outside_wall_access)
      us_setNoAccessReason(opportunityData.home_outside_wall_access_description)
      us_setBreakerMainPanelCount(opportunityData.electrical_main_panel_num_breakers)
      us_setBreakerMainPanelSum(opportunityData.electrical_main_panel_sum_breakers)
      us_setSubPanelCount(opportunityData.electrical_sub_panel_number)
      us_setSubPanelLocation(opportunityData.electrical_sub_panel_location)
      us_setMainBreakerMainPanelRating(opportunityData.electrical_main_breaker_rating)
      us_setHasSolarSystem(opportunityData.home_existing_solar_system)
      us_setHasBatteryStorage(opportunityData.home_battery_storage)
      us_setHasGenerator(opportunityData.home_has_generator)
      us_setSelectedHomeType(opportunityData.home_type)
      us_setSelectedRoofAge(opportunityData.home_roof_age)
      us_setHasAttic(opportunityData.home_has_attic)
      us_setAtticAccess(opportunityData.home_attic_access)
      us_setSelectedPurchaseTimeline(opportunityData.person_purchase_timeframe)
      us_setSelectedHomeAge(opportunityData.home_house_age)
      us_setSelectedHomeType(opportunityData.home_house_type)

      // Set eagleview data only if it exists
      if (eagleviewProspectData) {
        us_setEvImageURL(eagleviewProspectData.ev_image_data)
        us_setEvEnergyTableData(eagleviewProspectData.dataOutput.energyTable)
        us_setEvFacetData(eagleviewProspectData.dataOutput.facet)
        us_setEvRoofData(eagleviewProspectData.dataOutput.roof)
        us_setPanels(convertEvEnergyTableDataToSolarPanels(eagleviewProspectData.dataOutput.energyTable))
      }

      if (photosData) {
        us_setUploadedFiles(photosData)

        // Create array of promises for all photo fetches
        const photoPromises = Object.entries(photosData).map(async ([key, photoData]: [string, any]) => {
          try {
            const response = await fetch(photoData.url)
            const blob = await response.blob()
            return [key, new File([blob], photoData.file_name, { type: blob.type })]
          } catch (error) {
            console.error('Error fetching photo:', error)
            return [key, null]
          }
        })

        // Wait for all photos to load then update state once
        Promise.all(photoPromises)
          .then((results) => {
            const newImageStates = Object.fromEntries(results)
            us_setUploadedPhotos(newImageStates)
          })
          .catch((error) => {
            console.error('Error loading photos:', error)
          })
      }

      us_setIsDataLoaded(true)
    }

    Promise.all([
      DatabaseGetDocument(DatabaseRef_SalesOpportunity_v2_Document(clientKey, opportunityKey)),
      DatabaseGetCollection(DatabaseRef_ProposalsForOpportunity_Query(clientKey, opportunityKey)),
      DatabaseGetCollection(DatabaseRef_SaleOpportunityPhotos_Collection(clientKey, opportunityKey)),
      DatabaseGetDocument(DatabaseRef_SalesEagleviewPropose_Document(clientKey, opportunityKey)).catch((error) => {
        console.warn('Unable to load Eagleview data:', error)
        return { data: null }
      }),
      DatabaseGetCollection(DatabaseRef_SaleOpportunityFiles_Collection(clientKey, opportunityKey)),
    ]).then(async ([opportunityRes, proposalRes, photosRes, eagleviewRes, filesRes]) => {
      const opportunityData = opportunityRes.data
      const photosData = photosRes.data
      const filesData = filesRes.data
      const filesArray = Array.isArray(filesData) ? filesData : Object.values(filesData || {})
      us_setOpportunityFiles(filesArray)
      filesArray.forEach((file: any) => {
        if (file.key === 'electric_bill') {
          us_setUtilityBillUploaded(true)
        }
      })
      const proposalsArray = Array.isArray(proposalRes.data) ? proposalRes.data : Object.values(proposalRes.data || {})
      // let files: TsInterface_UnspecifiedObject = {}
      // let photos: TsInterface_UnspecifiedObject = {}
      // Process all proposals
      const processedProposals = await Promise.all(
        proposalsArray.map(async (proposal) => {
          const files = await fetchProposalFiles(clientKey, proposal.key)
          const photos = await fetchProposalPhotos(clientKey, proposal.key)
          return {
            id: proposal.key,
            name: proposal.name,
            systemSize: proposal.selected_system_size,
            numPanels: proposal.panel_quantity,
            annualProduction: proposal.system_estimated_annual_production,
            mountChoice: proposal.system_mount_type,
            reroof: us_roofReplacement ? 'Yes' : 'No',
            batteries: proposal.system_storage_quantity,
            type: proposal.associated_financing_type,
            systemCost: proposal.financials_cash_base_price,
            lunarBatteryCost: proposal.financials_lunar_battery_cost ? proposal.financials_lunar_battery_cost : 0,
            totalIncentives:
              (proposal.financials_federal_incentive ? proposal.financials_federal_incentive : 0) +
              (proposal.financials_srec_incentive ? proposal.financials_srec_incentive : 0),
            fedIncentives: proposal.financials_federal_incentive ? proposal.financials_federal_incentive : 0,
            stateIncentives: proposal.financials_srec_incentive ? proposal.financials_srec_incentive : 0,
            netCost: proposal.financials_cash_price_after_incentives,
            offset: proposal.system_usage_offset,
            active: proposal.active,
            files: files,
            photos: photos,
            adders: proposal.adders ? proposal.adders : [],
            discounts: proposal.discounts ? proposal.discounts : [],
            solar_only_bill_data: proposal.solar_only_bill_data ? proposal.solar_only_bill_data : {},
            solar_only_bill_consumption_profile: proposal.solar_only_bill_consumption_profile ? proposal.solar_only_bill_consumption_profile : {},
            solar_plus_battery_bill_data: proposal.solar_plus_battery_bill_data ? proposal.solar_plus_battery_bill_data : {},
            solar_plus_battery_bill_consumption_profile: proposal.solar_plus_battery_bill_consumption_profile
              ? proposal.solar_plus_battery_bill_consumption_profile
              : {},
          }
        }),
      )
      us_setProposals(processedProposals)

      // Set active proposal

      const activeProposal = processedProposals.find((p) => p.active)
      if (activeProposal) {
        us_setActiveProposalId(activeProposal.id)
      }

      const eagleviewData = eagleviewRes.data

      us_setOpportunityData(opportunityData || {})
      us_setEagleviewProspectData(eagleviewData || {})
      us_setPhotosData(photosData || {})
      handleOpportunityData(opportunityData, photosData, eagleviewData)
    })
  }, [])

  useEffect(() => {
    const proposalData = us_proposals.find((p) => p.id.toString() === us_activeProposalId)
    if (proposalData) {
      us_setSelectedFinancingType(proposalData.type)
      us_setBaseCashCost(proposalData.systemCost)
      us_setCurrentCashCost(proposalData.netCost)
      us_setLeaseCost(proposalData.netCost)
      us_setCurrentCashCost(proposalData.netCost)
      us_setLeaseMonthlySavings(proposalData.netCost)
      us_setLeaseCumulativeSavings(proposalData.netCost)
      us_setNumBatteries(proposalData.batteries)
      us_setSelectedMountType(proposalData.mountChoice)
      us_setCashSavings(proposalData.systemCost - proposalData.netCost)
      us_setFederalIncentive(proposalData.fedIncentives ? proposalData.fedIncentives : 0)
      us_setSrecIncentive(proposalData.stateIncentives ? proposalData.stateIncentives : 0)
      us_setSystemAnnualProductionInKWh(proposalData.annualProduction)
      us_setSystemSizeInWatts(proposalData.systemSize)
      us_setNumPanels(proposalData.numPanels)
      us_setOffset(proposalData.offset)
      us_setRoofReplacement(proposalData.reroof === 'Yes')
      us_setLunarBatteryCost(proposalData.lunarBatteryCost)
      us_setAdders(proposalData.adders ? proposalData.adders : [{ name: '', amount: 0 }])
      us_setDiscounts(proposalData.discounts ? proposalData.discounts : [{ name: '', amount: 0 }])
      if (proposalData.photos.length > 0) {
        us_setActiveProposalPhoto(proposalData.photos[0])
      }
      us_setSolarOnlyBill(proposalData.solar_only_bill_data)
      us_setSolarOnlyBill_consumptionProfile(proposalData.solar_only_bill_consumption_profile)
      us_setSolarPlusBatteryBill_v2(proposalData.solar_plus_battery_bill_data)
      us_setSolarPlusBatteryBill_v2_consumptionProfile(proposalData.solar_plus_battery_bill_consumption_profile)
    }
  }, [us_activeProposalId])

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Welcome', false) as string
  }, [])

  useEffect(() => {
    getUser(userID)
  }, [userID, getUser])

  useEffect(() => {
    if (us_opportunityKey) {
      let unsubscribeLiveData: TsType_VoidFunction
      const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
        loadPageData(clientKey, us_opportunityKey)
        ur_forceRerender()
      }

      // Subscribe to both opportunity updates and file collection updates
      const unsubscribeOpportunity = DatabaseGetLiveDocument(DatabaseRef_UnprotectedOpportunityUpdateTime_Document(us_opportunityKey), updateLiveData)

      const unsubscribeFiles = DatabaseGetLiveCollection(DatabaseRef_SaleOpportunityFiles_Collection(clientKey, us_opportunityKey), (filesData) => {
        const filesArray = Array.isArray(filesData) ? filesData : Object.values(filesData || {})
        us_setOpportunityFiles(filesArray)
        // check if the filesArray includes the file with key 'electric_bill'
        const hasElectricBill = filesArray.some((file: any) => file.key === 'electric_bill')
        if (hasElectricBill) {
          us_setUtilityBillUploaded(true)
        } else {
          us_setUtilityBillUploaded(false)
        }
      })

      return () => {
        if (typeof unsubscribeOpportunity === 'function') {
          unsubscribeOpportunity()
        }
        if (typeof unsubscribeFiles === 'function') {
          unsubscribeFiles()
        }
      }
    }
  }, [loadPageData, ur_forceRerender, us_opportunityKey])

  // Functions
  const readAsDataURL = (file: any) => {
    return new Promise((resolve, reject) => {
      const fr = new FileReader()
      fr.onerror = reject
      fr.onload = () => {
        resolve(fr.result)
      }
      fr.readAsDataURL(file)
    })
  }

  const uploadFilesProper = (files: TsInterface_FilesToUpload): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      let promiseArray: TsType_UnknownPromise[] = []
      for (let fileIndex in files) {
        let file = files[fileIndex]
        let dateString = returnFormattedDate(new Date(), 'MM-DD-YYYY HH:mm:ss')
        let nameWithoutType = file.file_name.substring(0, file.file_name.lastIndexOf('.'))
        let fileType = file.file_name.substring(file.file_name.lastIndexOf('.') + 1, file.file_name.length)
        let fileNameWithTimestamp = nameWithoutType + ' (' + dateString + ').' + fileType
        promiseArray.push(
          StorageUploadFile(
            StorageRef_SalesOpportunityFile(clientKey, us_opportunityKey, fileNameWithTimestamp),
            // file.data_url,
            file.file,
            {}, // No metadata for now
          )
            .then((res_SUF: any) => {
              files[fileIndex]['storage_url'] = getProp(res_SUF, 'url', null)
            })
            .catch((rej_SUF) => {
              console.error(rej_SUF)
            }),
        )
      }
      Promise.all(promiseArray).finally(() => {
        let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
        for (let fileIndex in files) {
          let file = files[fileIndex]
          let visibleToCustomer = false
          if (us_fileKey === 'electric_bill') {
            us_setElectricityBillFile(file.file)
            visibleToCustomer = true
          }
          let dateString = returnFormattedDate(new Date(), 'MM-DD-YYYY HH:mm:ss')
          let nameWithoutType = file.file_name.substring(0, file.file_name.lastIndexOf('.'))
          let fileType = file.file_name.substring(file.file_name.lastIndexOf('.') + 1, file.file_name.length)
          let fileNameWithTimestamp = nameWithoutType + ' (' + dateString + ').' + fileType
          let fileKey = us_fileKey

          const newFileDoc = {
            associated_parent_folder_key: us_opportunityKey,
            associated_uploader_name: us_firstName + ' ' + us_lastName,
            file_name: file.file_name,
            file_name_with_timestamp: fileNameWithTimestamp,
            file_system_type: 'file',
            file_type: fileType,
            key: fileKey,
            status: 'active',
            timestamp_uploaded: new Date(),
            url: getProp(file, 'storage_url', null),
            visible_to_customer: visibleToCustomer,
          }

          updateArray.push({
            type: 'setMerge',
            ref: DatabaseRef_SaleOpportunityFile_Document(clientKey, us_opportunityKey, fileKey),
            data: newFileDoc,
          })

          us_setOpportunityFiles((prevFiles: any[]) => [...prevFiles, newFileDoc])
        }
        DatabaseBatchUpdate(updateArray)
          .then((res_DBU) => {
            resolve(res_DBU)
          })
          .catch((rej_DBU) => {
            reject(rej_DBU)
          })
      })
    })
  }

  const uploadFiles = (event: React.ChangeEvent<HTMLInputElement>): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      if (event != null && event.target != null && event.target.files !== null && event.target?.files?.length > 0) {
        let promiseArray: TsType_UnknownPromise[] = []
        let files = event.target.files
        let readFiles: TsInterface_UnspecifiedObject = {}
        for (let fileIndex in files) {
          let file = files[fileIndex]
          if (file != null && typeof file === 'object') {
            promiseArray.push(
              readAsDataURL(file)
                .then((res_RADURL) => {
                  readFiles[fileIndex] = {
                    file_name: us_fileKey + '_' + file.name,
                    file: file,
                    data_url: res_RADURL,
                  }
                })
                .catch((rej_RADURL) => {
                  // Nothing
                }),
            )
          }
        }
        Promise.all(promiseArray).finally(() => {
          promiseArray.push(
            uploadFilesProper(readFiles)
              .then((res_ITF) => {
                resolve(res_ITF)
              })
              .catch((rej_ITF) => {
                reject(rej_ITF)
              }),
          )
        })
      } else {
        reject({
          success: false,
          error: {
            message: rLIB('Failed to Upload File'),
            details: rLIB('Invalid File Selection'),
            code: 'ER-D-TFS-FOS-02',
          },
        })
      }
    })
  }

  // JSX Generation

  const rJSX_PageContent = (): JSX.Element => {
    let jsx = <></>

    jsx = (
      <>
        {us_dataLoaded &&
          rJSX_WelcomeAddressBanner(
            {
              un_routerNavigation,
              us_setRequestSupportDialogOpen,
              us_setShowRequestSupportConfirmation,
              us_requestSupportDialogOpen,
              us_showRequestSupportConfirmation,
              us_firstName,
              us_lastName,
              us_email,
              us_phone,
              us_editRequestText,
              us_setEditRequestText,
              us_setCurrentView,
            },
            logoSrc,
            { teal, white, aqua },
            clientKey,
            us_opportunityKey,
          )}
        {us_dataLoaded &&
          us_currentView === 'welcome' &&
          rJSX_WelcomePage(
            {
              us_firstName,
              us_lastName,
              us_selectedTab,
              us_setSelectedTab,
              us_utilityCompanyName,
              us_setUtilityCompanyName,
              us_address,
              us_setAddress,
              us_zip,
              us_setZip,
              us_state,
              us_setState,
              us_city,
              us_setCity,
              us_setCurrentView,
              us_editingField,
              us_setEditingField,
              us_editValue,
              us_setEditValue,
              us_selectedMountType,
              us_setSelectedMountType,
              us_selectedRoofType,
              us_setSelectedRoofType,
              us_numBatteries,
              us_setNumBatteries,
              us_homeDetailsExpanded,
              us_setHomeDetailsExpanded,
              us_selectedFinancingType,
              us_setSelectedFinancingType,
              us_systemSizeInWatts,
              us_setSystemSizeInWatts,
              us_cashSavings,
              us_setCashSavings,
              us_numPanels,
              us_setNumPanels,
              us_baseCashCost,
              us_setBaseCashCost,
              us_currentCashCost,
              us_setCurrentCashCost,
              us_leaseCost,
              us_setLeaseCost,
              us_systemAnnualProductionInKWh,
              us_setSystemAnnualProductionInKWh,
              us_leaseMonthlySavings,
              us_setLeaseMonthlySavings,
              us_leaseCumulativeSavings,
              us_setLeaseCumulativeSavings,
              us_electricityBill,
              us_setElectricityBill,
              us_federalIncentive,
              us_setFederalIncentive,
              us_srecIncentive,
              us_setSrecIncentive,
              us_evImageURL,
              us_setEvImageURL,
              us_evEnergyTableData,
              us_setEvEnergyTableData,
              us_evFacetData,
              us_setEvFacetData,
              us_evRoofData,
              us_setEvRoofData,
              us_latitude,
              us_setLatitude,
              us_longitude,
              us_setLongitude,
              us_photoKey,
              us_setPhotoKey,
              us_showFinanceDetailsInWelcome,
              us_setShowFinanceDetailsInWelcome,
              us_showOptionsDetailsInWelcome,
              us_setShowOptionsDetailsInWelcome,
              us_roofReplacement,
              us_setRoofReplacement,
              us_offset,
              us_setOffset,
              us_compareMode,
              us_setCompareMode,
              us_selectedLeft,
              us_setSelectedLeft,
              us_selectedRight,
              us_setSelectedRight,
              us_editDialog,
              us_setEditDialog,
              us_selectedMountOption,
              us_setSelectedMountOption,
              us_selectedReroofOption,
              us_setSelectedReroofOption,
              us_selectedNumBatteriesOption,
              us_setSelectedNumBatteriesOption,
              us_selectedFinancingOption,
              us_setSelectedFinancingOption,
              us_productType,
              us_setProductType,
              us_activeProposalId,
              us_setActiveProposalId,
              us_editRequestDialogOpen,
              us_setEditRequestDialogOpen,
              us_proposals,
              us_setProposals,
              us_uploadedFiles,
              us_setUploadedFiles,
              us_showPhotoAssignmentModal,
              us_setShowPhotoAssignmentModal,
              us_selectedCategories,
              us_setSelectedCategories,
              us_imageStates,
              us_setImageStates,
              us_photoInfoDialogOpen,
              us_setPhotoInfoDialogOpen,
              us_activeProposalPhoto,
              us_setActiveProposalPhoto,
              us_lunarBatteryCost,
              us_setLunarBatteryCost,
              us_adders,
              us_setAdders,
              us_discounts,
              us_setDiscounts,
              us_editRequestText,
              us_setEditRequestText,
              us_showEditRequestConfirmation,
              us_setShowEditRequestConfirmation,
              us_uploadedPhotos,
              us_setUploadedPhotos,
              us_email,
              us_setEmail,
              us_phone,
              us_setPhone,
              us_setFileKey,
              us_fileKey,
              us_solarOnlyBill,
              us_setSolarOnlyBill,
              us_solarPlusBatteryBill_v2,
              us_setSolarPlusBatteryBill_v2,
              us_solarPlusBatteryBill_v2_consumptionProfile,
              us_setSolarPlusBatteryBill_v2_consumptionProfile,
              us_solarOnlyBill_consumptionProfile,
              us_setSolarOnlyBill_consumptionProfile,
              us_selectedMonth,
              us_setSelectedMonth,
              us_selectedHour,
              us_setSelectedHour,
              us_selectedConsumptionProfile,
              us_setSelectedConsumptionProfile,
              us_utilityBillUploaded,
              us_setUtilityBillUploaded,
              us_opportunityFiles,
              us_setOpportunityFiles,
              us_currentView,
              us_selectedPurchaseTimeline,
              us_setSelectedPurchaseTimeline,
              us_partOfHOA,
              us_setPartOfHOA,
              us_hoaName,
              us_setHoaName,
              us_hoaPhone,
              us_setHoaPhone,
              us_selectedHomeType,
              us_setSelectedHomeType,
              us_selectedHomeAge,
              us_setSelectedHomeAge,
              us_accessToAllExteriorWalls,
              us_setAccessToAllExteriorWalls,
              us_noAccessReason,
              us_setNoAccessReason,
              us_selectedRoofAge,
              us_setSelectedRoofAge,
              us_hasAttic,
              us_setHasAttic,
              us_atticAccess,
              us_setAtticAccess,
              us_mainBreakerMainPanelRating,
              us_setMainBreakerMainPanelRating,
              us_breakerMainPanelCount,
              us_setBreakerMainPanelCount,
              us_breakerMainPanelSum,
              us_setBreakerMainPanelSum,
              us_subPanelCount,
              us_setSubPanelCount,
              us_subPanelLocation,
              us_setSubPanelLocation,
              us_hasSolarSystem,
              us_setHasSolarSystem,
              us_hasBatteryStorage,
              us_setHasBatteryStorage,
              us_hasGenerator,
              us_setHasGenerator,
              us_hasSpecializedElectricalEquipment,
              us_setHasSpecializedElectricalEquipment,
              us_hasOtherHighLoadItem,
              us_setHasOtherHighLoadItem,
              us_otherHighLoadItem,
              us_setOtherHighLoadItem,
              us_hasPool,
              us_setHasPool,
              us_hasHotTub,
              us_setHasHotTub,
              us_hasElectricHeating,
              us_setHasElectricHeating,
              us_hasElectricWaterHeater,
              us_setHasElectricWaterHeater,
              us_hasElectricDryer,
              us_setHasElectricDryer,
              us_hasEVCharger,
              us_setHasEVCharger,
              us_preferredContactMethod,
              us_setPreferredContactMethod,
              us_preferredContactTimes,
              us_setPreferredContactTimes,
              us_hasPets,
              us_setHasPets,
              us_hasGateCode,
              us_setHasGateCode,
              us_gateCode,
              us_setGateCode,
              us_panelPlacementPreference,
              us_setPanelPlacementPreference,
              us_inverterPlacementPreference,
              us_setInverterPlacementPreference,
            },
            { teal, white, aqua },
            photoInputRef,
            fileInputRef,
            clientKey,
            us_opportunityKey,
          )}
        {us_currentView === 'edit-panel-layout' &&
          rJSX_EditPanelLayout(
            {
              us_setCurrentView,
              us_homeDetailsExpanded,
              us_setHomeDetailsExpanded,
              us_selectedFinancingType,
              us_setSelectedFinancingType,
              us_systemSizeInWatts,
              us_setSystemSizeInWatts,
              us_cashSavings,
              us_setCashSavings,
              us_numPanels,
              us_setNumPanels,
              us_baseCashCost,
              us_setBaseCashCost,
              us_currentCashCost,
              us_setCurrentCashCost,
              us_leaseCost,
              us_setLeaseCost,
              us_systemAnnualProductionInKWh,
              us_setSystemAnnualProductionInKWh,
              us_leaseMonthlySavings,
              us_setLeaseMonthlySavings,
              us_leaseCumulativeSavings,
              us_setLeaseCumulativeSavings,
              us_electricityBill,
              us_setElectricityBill,
              us_federalIncentive,
              us_setFederalIncentive,
              us_srecIncentive,
              us_setSrecIncentive,
              us_evImageURL,
              us_setEvImageURL,
              us_evEnergyTableData,
              us_setEvEnergyTableData,
              us_evFacetData,
              us_setEvFacetData,
              us_evRoofData,
              us_setEvRoofData,
              us_panels,
              us_setPanels,
              us_latitude,
              us_setLatitude,
              us_longitude,
              us_setLongitude,
              us_offset,
              us_setOffset,
              us_editingField,
              us_setEditingField,
              us_editValue,
              us_setEditValue,
              us_activeProposalId,
              us_setActiveProposalId,
              us_roofReplacement,
              us_setRoofReplacement,
              us_selectedMountType,
              us_setSelectedMountType,
              us_selectedRoofType,
              us_setSelectedRoofType,
              us_numBatteries,
              us_setNumBatteries,
              us_uploadedFiles,
              us_setUploadedFiles,
              us_showPhotoAssignmentModal,
              us_setShowPhotoAssignmentModal,
            },
            { teal, white, aqua },
            us_opportunityKey,
            clientKey,
          )}
        {us_currentView === 'change-password' &&
          rJSX_ResetPasswordPage(
            { us_newPassword, us_setNewPassword, us_confirmPassword, us_setConfirmPassword, us_setCurrentView, us_currentView },
            { teal, white, aqua },
            user,
          )}
      </>
    )

    return jsx
  }

  const LoadingScreen = (): JSX.Element => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          backgroundColor: white,
          gap: 4,
        }}
      >
        <img
          src={logoSrc}
          alt="ETW Energy Logo"
          style={{
            maxWidth: '200px',
            marginBottom: '20px',
          }}
        />
        <CircularProgress
          size={50}
          sx={{
            color: teal,
          }}
        />
      </Box>
    )
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <Box>
        <ThemeProvider theme={lightTheme}>
          <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
          {!us_dataLoaded && LoadingScreen()}
          {us_dataLoaded && rJSX_PageContent()}
          {/* <style>{pageCSS}</style> */}
        </ThemeProvider>
      </Box>
    )
    return pageJSX
  }

  const handleChangePassword = () => {
    us_setCurrentView('change-password')
    us_setShowFirstLoginDialog(false)
  }

  const handleSkipPasswordChange = () => {
    us_setShowFirstLoginDialog(false)
  }

  return (
    <>
      {/* <input
        type="file"
        ref={photoInputRef}
        accept="image/*"
        style={{ display: 'none' }}
        onChange={uploadPhotos}
      /> */}
      <input
        type="file"
        ref={fileInputRef}
        accept=".pdf,.doc,.docx,.xls,.xlsx,.txt,.csv"
        style={{ display: 'none' }}
        onChange={uploadFiles}
      />
      <Dialog
        open={us_showFirstLoginDialog}
        onClose={handleSkipPasswordChange}
        sx={{
          maxWidth: '450px',
          margin: '0 auto',
        }}
      >
        <DialogTitle>Welcome to Your Account!</DialogTitle>
        <DialogContent>Would you like to change your password? This is recommended for security purposes.</DialogContent>
        <DialogActions>
          <Button onClick={handleSkipPasswordChange}>Skip for Now</Button>
          <Button
            onClick={handleChangePassword}
            variant="contained"
            color="primary"
          >
            Change Password
          </Button>
        </DialogActions>
      </Dialog>
      {rJSX_Page()}
    </>
  )
}
