/* eslint-disable react/display-name */
///////////////////////////////
// Description
///////////////////////////////

/*
DESCRIPTION / USAGE:
example component description

TODO:

*/

///////////////////////////////
// Imports
///////////////////////////////
import { Box, Card } from '@mui/material'
import { tableColumns_UnlinkedProjects, tableSettings_UnlinkedProjects } from 'app/models/projects/project_table'
import { forwardRef, useContext, useEffect, useReducer } from 'react'
import { DatabaseRef_ProtoProjects_Collection, DatabaseRef_ProtoProjects_Search } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { TableDatabase, TsInterface_TableAdditionalData, TsInterface_TableDatabaseEndpointQueryObject } from 'rfbp_core/components/table'
import { Context_RootData_ClientKey } from 'rfbp_core/services/context'
import {
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { getProp } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ProjectList_UnlinkedProjectsTab {}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const ProjectListUnlinkedProjectsTab = forwardRef(
  (props: TsInterface_ProjectList_UnlinkedProjectsTab, ref: React.ForwardedRef<unknown>): JSX.Element => {
    // Props

    // Hooks - useContext, useState, useReducer, other
    const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
    const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)

    // Hooks - useEffect
    useEffect(() => {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      return () => {}
    }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

    // Functions
    const tableDatabaseEndpoint_UnlinkedProjects = (
      queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
      tableAdditionalData: TsInterface_TableAdditionalData,
    ) => {
      let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
      let orderByArray: TsInterface_OrderByArray = []
      let queryCursorsObject: TsInterface_QueryCursorsObject = {}
      if (queryGenerationData['startAfter'] != null) {
        queryCursorsObject['startAfter'] = queryGenerationData.startAfter
      }
      if (queryGenerationData['startAt'] != null) {
        queryCursorsObject['startAt'] = queryGenerationData.startAt
      }
      if (queryGenerationData['endAt'] != null) {
        queryCursorsObject['endAt'] = queryGenerationData.endAt
      }
      if (queryGenerationData['endBefore'] != null) {
        queryCursorsObject['endBefore'] = queryGenerationData.endBefore
      }
      let limit = getProp(queryGenerationData, 'limit', 5)
      return generateDatabaseQuery(
        DatabaseRef_ProtoProjects_Collection(uc_RootData_ClientKey as string),
        queryOperatorsArray,
        orderByArray,
        queryCursorsObject,
        limit,
      )
    }

    const tableSearchEndpoint_UnlinkedProjects = (searchProperty: string, searchInput: string) => {
      let limit = tableSettings_UnlinkedProjects.rows_per_page
      if (limit === null) {
        limit = 10
      }
      return DatabaseRef_ProtoProjects_Search(uc_RootData_ClientKey as string, searchProperty, searchInput, limit)
    }

    // JSX Generation
    const rJSX_UnlinkedTable = (): JSX.Element => {
      let tableJSX = <></>
      if (tableSettings_UnlinkedProjects.search_settings_database == null) {
        tableSettings_UnlinkedProjects.search_settings_database = { search_type: 'firebase' }
      }
      tableSettings_UnlinkedProjects.search_settings_database.search_endpoint = tableSearchEndpoint_UnlinkedProjects
      if (uc_RootData_ClientKey != null) {
        tableJSX = (
          <Card className="">
            <TableDatabase
              tableAdditionalData={{}}
              tableColumns={tableColumns_UnlinkedProjects}
              tableDatabaseEndpoint={tableDatabaseEndpoint_UnlinkedProjects}
              tableSettings={tableSettings_UnlinkedProjects}
            />
          </Card>
        )
      }
      return tableJSX
    }

    const rJSX_Tab = (): JSX.Element => {
      let tabJSX = (
        <Box className="tw-m-auto">
          <Box>{rJSX_UnlinkedTable()}</Box>
        </Box>
      )
      return tabJSX
    }

    // Render
    return <>{rJSX_Tab()}</>
  },
)
