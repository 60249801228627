///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

			MAYBE Write cloud function to read only prod data at path and then have them select the data they want to copy to staging
				Feel like I built this utility before somewhere...


			// TODO - get collections on server and return and then pull list of documents in collection


			const admin = require('firebase-admin');

			// Initialize Firebase Admin SDK
			admin.initializeApp({
			credential: admin.credential.applicationDefault(),
			databaseURL: 'YOUR_DATABASE_URL'
			});

			async function getSubcollectionIds(path) {
			try {
				const collections = await admin.firestore().doc(path).listCollections();
				const collectionIds = collections.map(collection => collection.id);
				return collectionIds;
			} catch (error) {
				console.error('Error getting subcollection IDs:', error);
				return [];
			}
			}

			// Example usage:
			const path = 'YOUR_DOCUMENT_PATH'; // Replace this with your document path
			getSubcollectionIds(path)
			.then(subcollectionIds => {
				console.log('Subcollection IDs:', subcollectionIds);
			})
			.catch(error => {
				console.error('Error:', error);
			});




	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Divider, FormControl, Grid, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material/'
import { collection, getFirestore } from 'firebase/firestore'
import GeoRasterLayer from 'georaster-layer-for-leaflet'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import React, { useEffect, useReducer, useRef, useState } from 'react'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_GHL_Messages_Collection } from 'rfbp_aux/services/database_endpoints/integrations/ghl'
import { DatabaseRef_Project_Document } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { DatabaseRef_RootClient_Collection } from 'rfbp_aux/services/database_endpoints/standard_database_endpoints'
import { Json } from 'rfbp_core/components/code_display'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import {
  DatabaseGetCollection,
  DatabaseGetDocument,
  DatabaseGetLiveCollection,
  generateDatabaseArrayContainsAnyQuery,
} from 'rfbp_core/services/database_management'
import { generateHtmlForEmailFromTemplateObject, TsInterface_EmailTemplateObject } from 'rfbp_core/services/emails'
import { dynamicSort, objectToArray } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
// @ts-ignore
import parseGeoraster from 'georaster'
// @ts-ignore
import { getDownloadURL, getStorage, ref } from 'firebase/storage'
import { Bar, BarChart, Legend, Tooltip, XAxis, YAxis } from 'recharts'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['SuperTestLabPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

// const moveProjectDataBetweenClients = (
// originClientKey: string,
// originProjectKey: string,
// destinationClientKey: string,
// destinationProjectKey: string,
// ) => {
// // Instantiate Variables
// let promiseArray1: TsType_UnknownPromise[] = []
// let promiseArray2: TsType_UnknownPromise[] = []
// let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
// let contactLogs: TsInterface_UnspecifiedObject | null = null
// let documentsAgreements: TsInterface_UnspecifiedObject | null = null
// let documentsDesign: TsInterface_UnspecifiedObject | null = null
// let documentsPermits: TsInterface_UnspecifiedObject | null = null
// let designPreferences: TsInterface_UnspecifiedObject | null = null
// let projectNotes: TsInterface_UnspecifiedObject | null = null
// let projectNotesSubdata: TsInterface_UnspecifiedObject = {}
// let taskFormData: TsInterface_UnspecifiedObject | null = null
// // Get Data
// promiseArray1.push( DatabaseGetCollection( DatabaseRef_ProjectContactLogs_Collection( originClientKey, originProjectKey )).then((res_DGC) => {
// 	if(res_DGC != null && res_DGC.data != null){ contactLogs = res_DGC.data }
// }).catch((rej_DGC) => {
// 	console.error(rej_DGC)
// }) )
// promiseArray1.push( DatabaseGetCollection( DatabaseRef_Project_Documents_Collection( originClientKey, originProjectKey, "agreements" )).then((res_DGC) => {
// 	if(res_DGC != null && res_DGC.data != null){ documentsAgreements = res_DGC.data }
// }).catch((rej_DGC) => {
// 	console.error(rej_DGC)
// }) )
// promiseArray1.push( DatabaseGetCollection( DatabaseRef_Project_Documents_Collection( originClientKey, originProjectKey, "design" )).then((res_DGC) => {
// 	if(res_DGC != null && res_DGC.data != null){ documentsDesign = res_DGC.data }
// }).catch((rej_DGC) => {
// 	console.error(rej_DGC)
// }) )
// promiseArray1.push( DatabaseGetCollection( DatabaseRef_Project_Documents_Collection( originClientKey, originProjectKey, "permits" )).then((res_DGC) => {
// 	if(res_DGC != null && res_DGC.data != null){ documentsPermits = res_DGC.data }
// }).catch((rej_DGC) => {
// 	console.error(rej_DGC)
// }) )
// promiseArray1.push( DatabaseGetDocument( DatabaseRef_ProjectDesignPreferences_Document( originClientKey, originProjectKey )).then((res_DGD) => {
// 	if(res_DGD != null && res_DGD.data != null){ designPreferences = res_DGD.data }
// }).catch((rej_DGD) => {
// 	console.error(rej_DGD)
// }) )
// promiseArray1.push( DatabaseGetCollection( DatabaseRef_ProjectNoteThreads_Collection( originClientKey, originProjectKey )).then((res_DGC) => {
// 	if(res_DGC != null && res_DGC.data != null){ projectNotes = res_DGC.data }
// }).catch((rej_DGC) => {
// 	console.error(rej_DGC)
// }) )
// promiseArray1.push( DatabaseGetCollection( DatabaseRef_ProjectTaskFormData_Collection( originClientKey, originProjectKey )).then((res_DGC) => {
// 	if(res_DGC != null && res_DGC.data != null){ taskFormData = res_DGC.data }
// }).catch((rej_DGC) => {
// 	console.error(rej_DGC)
// }) )
// // Initial Data Loaded
// Promise.all( promiseArray1 ).finally(() => {
// 	for( let loopKey in projectNotes ){
// 		promiseArray2.push( DatabaseGetCollection( DatabaseRef_ProjectNoteThreadNotes_Collection( originClientKey, originProjectKey, loopKey )).then((res_DGC) => {
// 			if(res_DGC != null && res_DGC.data != null){ projectNotesSubdata[ loopKey ] = res_DGC.data }
// 		}).catch((rej_DGC) => {
// 			console.error(rej_DGC)
// 		}) )
// 	}
// 	Promise.all( promiseArray2 ).finally(() => {
// 		// Subsequent Data Loaded
// 		if( contactLogs != null ){
// 			for( let loopKey in contactLogs ){
// 				let loopData = contactLogs[ loopKey ]
// 				updateArray.push({
// 					type: "setMerge",
// 					ref: DatabaseRef_ProjectContactLogs_Document( destinationClientKey, destinationProjectKey, loopKey ),
// 					data: loopData
// 				})
// 			}
// 		}
// 		if( documentsAgreements != null ){
// 			for( let loopKey in documentsAgreements ){
// 				let loopData = documentsAgreements[ loopKey ]
// 				updateArray.push({
// 					type: "setMerge",
// 					ref: DatabaseRef_Project_Documents_Document( destinationClientKey, destinationProjectKey, "agreements", loopKey ),
// 					data: loopData
// 				})
// 			}
// 		}
// 		if( documentsDesign != null ){
// 			for( let loopKey in documentsDesign ){
// 				let loopData = documentsDesign[ loopKey ]
// 				updateArray.push({
// 					type: "setMerge",
// 					ref: DatabaseRef_Project_Documents_Document( destinationClientKey, destinationProjectKey, "design", loopKey ),
// 					data: loopData
// 				})
// 			}
// 		}
// 		if( documentsPermits != null ){
// 			for( let loopKey in documentsPermits ){
// 				let loopData = documentsPermits[ loopKey ]
// 				updateArray.push({
// 					type: "setMerge",
// 					ref: DatabaseRef_Project_Documents_Document( destinationClientKey, destinationProjectKey, "permits", loopKey ),
// 					data: loopData
// 				})
// 			}
// 		}
// 		if( designPreferences != null ){
// 			updateArray.push({
// 				type: "setMerge",
// 				ref: DatabaseRef_ProjectDesignPreferences_Document( destinationClientKey, destinationProjectKey ),
// 				data: designPreferences
// 			})
// 		}
// 		if( taskFormData != null ){
// 			for( let loopKey in taskFormData ){
// 				let loopData = taskFormData[ loopKey ]
// 				// TODO - change key
// 				let newLoopKey = loopKey.replace(originProjectKey, destinationProjectKey)
// 				updateArray.push({
// 					type: "setMerge",
// 					ref: DatabaseRef_ProjectTaskFormData_Document( destinationClientKey, destinationProjectKey, newLoopKey ),
// 					data: loopData
// 				})
// 			}
// 		}
// 		if( projectNotes != null ){
// 			for( let loopKey in projectNotes ){
// 				let loopData = projectNotes[ loopKey ]
// 				updateArray.push({
// 					type: "setMerge",
// 					ref: DatabaseRef_ProjectNoteThreads_Document( destinationClientKey, destinationProjectKey, loopKey ),
// 					data: loopData
// 				})
// 				if( projectNotesSubdata != null && projectNotesSubdata[loopKey] != null ){
// 					for( let subLoopKey in projectNotesSubdata[ loopKey ] ){
// 						let subLoopData = projectNotesSubdata[ loopKey ][ subLoopKey ]
// 						updateArray.push({
// 							type: "setMerge",
// 							ref: DatabaseRef_ProjectNoteThreadNotes_Document( destinationClientKey, destinationProjectKey, loopKey, subLoopKey ),
// 							data: subLoopData
// 						})
// 					}
// 				}
// 			}
// 		}
// 		console.log( "<><><><><><><><>" )
// 		console.log( updateArray )
// 		DatabaseBatchUpdate( updateArray ).then((res_DBU) => {
// 			console.log( res_DBU )
// 		}).catch((rej_DBU) => {
// 			console.error( rej_DBU )
// 		})
// 	})
// })
// }

// Database Management Utility
let databaseStructureOptions: TsInterface_UnspecifiedObject = {
  directory: {
    type: 'collection',
    key: 'directory',
    docs: {
      equipment: {
        type: 'doc',

        key: 'equipment',
        collections: {
          groups: {
            type: 'collection',
            key: 'groups',
            dynamic_doc_keys: true,
            docs: {
              $dynamicKey: {
                type: 'doc',
              },
            },
          },
          kits: {
            type: 'collection',
            key: 'kits',
          },
          main: {
            type: 'collection',
            key: 'main',
          },
          manufacturers: {
            type: 'collection',
            key: 'manufacturers',
          },
          types: {
            type: 'collection',
            key: 'types',
          },
        },
      },
      regions: {
        key: 'regions',
      },
      sales_partners: {
        key: 'sales_partners',
      },
      task_blueprints: {
        key: 'task_blueprints',
      },
    },
  },
  finances: {
    type: 'collection',
    key: 'finances',
  },
  integrations: {
    type: 'collection',
    key: 'integrations',
  },
  machine_learning: {
    type: 'collection',
    key: 'machine_learning',
  },
  materials: {
    type: 'collection',
    key: 'materials',
  },
  operations: {
    type: 'collection',
    key: 'operations',
  },
  settings: {
    type: 'collection',
    key: 'settings',
  },
  timesheets: {
    type: 'collection',
    key: 'timesheets',
  },
  webstore: {
    type: 'collection',
    key: 'webstore',
  },
}

// us_panelsInstalledFormattedData
const TEMP_GMDashboardData_PanelsInstalled = {
  summary_rows: [
    [
      {
        cellValue: {
          key: null,
          ref: null,
          props: {
            children: 'Panels Installed',
          },
          _owner: null,
          _store: {},
        },
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: 'Aug 05',
        conditionalFormatting: {
          fontWeight: 'bold',
          opacity: 1,
          width: '150px',
        },
      },
      {
        cellValue: 'Aug 12',
        conditionalFormatting: {
          fontWeight: 'bold',
          opacity: 1,
          width: '150px',
        },
      },
      {
        cellValue: 'Aug 19',
        conditionalFormatting: {
          fontWeight: 'bold',
          opacity: 0.5,
          width: '150px',
        },
      },
      {
        cellValue: 'Aug 26',
        conditionalFormatting: {
          fontWeight: 'bold',
          opacity: 0.5,
          width: '150px',
        },
      },
    ],
    [
      {
        cellValue: {
          key: null,
          ref: null,
          props: {
            children: 'Total',
          },
          _owner: null,
          _store: {},
        },
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: '627 / 800',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
        },
        additionalCellData: {
          totalPanels: 627,
          goalPanels: 800,
          conditionalFormatting: {
            backgroundColor: '#c82424',
            opacity: 1,
          },
        },
      },
      {
        cellValue: '955 / 1100',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
        },
        additionalCellData: {
          totalPanels: 955,
          goalPanels: 1100,
          conditionalFormatting: {
            backgroundColor: '#c82424',
            opacity: 1,
          },
        },
      },
      {
        cellValue: '478 / 800',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 0.5,
        },
        additionalCellData: {
          totalPanels: 478,
          goalPanels: 800,
          conditionalFormatting: {
            backgroundColor: '#c82424',
            opacity: 0.5,
          },
        },
      },
      {
        cellValue: '170 / 200',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 0.5,
        },
        additionalCellData: {
          totalPanels: 170,
          goalPanels: 200,
          conditionalFormatting: {
            backgroundColor: '#c82424',
            opacity: 0.5,
          },
        },
      },
    ],
  ],
  data_rows: [
    [
      {
        cellValue: 'Dream Team (712)',
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '150px',
        },
      },
      {
        cellValue: 63,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 20,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 0.5,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 0.5,
          cursor: 'default',
          width: '150px',
        },
      },
    ],
    [
      {
        cellValue: 'Illuminatis',
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: 102,
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 77,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 0.5,
          cursor: 'default',
          width: '150px',
        },
      },
      {
        cellValue: 39,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 0.5,
          cursor: 'pointer',
          width: '150px',
        },
      },
    ],
    [
      {
        cellValue: 'Slay That Pitch',
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '150px',
        },
      },
      {
        cellValue: 24,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 0.5,
          cursor: 'default',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 0.5,
          cursor: 'default',
          width: '150px',
        },
      },
    ],
    [
      {
        cellValue: 'Solar Bears',
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: 73,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 80,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 10,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 0.5,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 0.5,
          cursor: 'default',
          width: '150px',
        },
      },
    ],
    [
      {
        cellValue: 'Solar Energy',
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: 79,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 94,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 74,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 0.5,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 0.5,
          cursor: 'default',
          width: '150px',
        },
      },
    ],
    [
      {
        cellValue: 'Solar Expanse',
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: 81,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 90,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 43,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 0.5,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 0.5,
          cursor: 'default',
          width: '150px',
        },
      },
    ],
    [
      {
        cellValue: 'Star Troopers (712)',
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '150px',
        },
      },
      {
        cellValue: 161,
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 60,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 0.5,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 0.5,
          cursor: 'default',
          width: '150px',
        },
      },
    ],
    [
      {
        cellValue: 'Sun Seekers (Peoria Local)',
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: 108,
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 45,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 97,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 0.5,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 0.5,
          cursor: 'default',
          width: '150px',
        },
      },
    ],
    [
      {
        cellValue: 'The Alphas (711)',
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: 71,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 76,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 0.5,
          cursor: 'default',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 0.5,
          cursor: 'default',
          width: '150px',
        },
      },
    ],
    [
      {
        cellValue: 'Watt Wizards',
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: 54,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 122,
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 83,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 0.5,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 0.5,
          cursor: 'default',
          width: '150px',
        },
      },
    ],
    [
      {
        cellValue: 'Wolf Pack',
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: 59,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 123,
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 91,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 0.5,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 131,
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 0.5,
          cursor: 'pointer',
          width: '150px',
        },
      },
    ],
  ],
}

// us_formattedCrewHours
const TEMP_GMDashboardData_CrewHours = {
  summary_rows: [
    [
      {
        cellValue: {
          key: null,
          ref: null,
          props: {
            children: 'Crew Hours',
          },
          _owner: null,
          _store: {},
        },
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '200px',
        },
      },
      {
        cellValue: {
          key: null,
          ref: null,
          props: {
            children: [
              'Aug 05',
              ' ',
              {
                key: null,
                ref: null,
                props: {
                  icon: 'arrows-rotate',
                  className: 'tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer tw-ml-1',
                  tooltip: {
                    key: null,
                    ref: null,
                    props: {
                      children: 'Refresh Data',
                    },
                    _owner: null,
                    _store: {},
                  },
                },
                _owner: null,
                _store: {},
              },
            ],
          },
          _owner: null,
          _store: {},
        },
        conditionalFormatting: {
          fontWeight: 'bold',
          opacity: 1,
          width: '120px',
        },
      },
      {
        cellValue: {
          key: null,
          ref: null,
          props: {
            children: [
              'Aug 12',
              ' ',
              {
                key: null,
                ref: null,
                props: {
                  icon: 'arrows-rotate',
                  className: 'tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer tw-ml-1',
                  tooltip: {
                    key: null,
                    ref: null,
                    props: {
                      children: 'Refresh Data',
                    },
                    _owner: null,
                    _store: {},
                  },
                },
                _owner: null,
                _store: {},
              },
            ],
          },
          _owner: null,
          _store: {},
        },
        conditionalFormatting: {
          fontWeight: 'bold',
          opacity: 1,
          width: '120px',
        },
      },
      {
        cellValue: {
          key: null,
          ref: null,
          props: {
            children: [
              'Aug 19',
              ' ',
              {
                key: null,
                ref: null,
                props: {
                  icon: 'arrows-rotate',
                  className: 'tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer tw-ml-1',
                  tooltip: {
                    key: null,
                    ref: null,
                    props: {
                      children: 'Refresh Data',
                    },
                    _owner: null,
                    _store: {},
                  },
                },
                _owner: null,
                _store: {},
              },
            ],
          },
          _owner: null,
          _store: {},
        },
        conditionalFormatting: {
          fontWeight: 'bold',
          opacity: 0.5,
          width: '120px',
        },
      },
      {
        cellValue: {
          key: null,
          ref: null,
          props: {
            children: [
              'Aug 26',
              ' ',
              {
                key: null,
                ref: null,
                props: {
                  icon: 'arrows-rotate',
                  className: 'tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer tw-ml-1',
                  tooltip: {
                    key: null,
                    ref: null,
                    props: {
                      children: 'Refresh Data',
                    },
                    _owner: null,
                    _store: {},
                  },
                },
                _owner: null,
                _store: {},
              },
            ],
          },
          _owner: null,
          _store: {},
        },
        conditionalFormatting: {
          fontWeight: 'bold',
          opacity: 0.5,
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Total',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-88.54',
        conditionalFormatting: {
          fontWeight: 'normal',
          backgroundColor: '#28a56c',
          opacity: 1,
          width: '120px',
        },
      },
      {
        cellValue: '198.59',
        conditionalFormatting: {
          fontWeight: 'normal',
          backgroundColor: '#c82424',
          opacity: 1,
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          fontWeight: 'normal',
          backgroundColor: '',
          opacity: 1,
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          fontWeight: 'normal',
          backgroundColor: '',
          opacity: 1,
          width: '120px',
        },
      },
    ],
  ],
  data_rows: [
    [
      {
        cellValue: 'Abdiel Mata',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '10.46',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '14.91',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Aby Cabrera',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '7.03',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '8.59',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Adam Toddington',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '8.74',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Anderson Hohn',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '5.10',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-6.84',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Antonio Arciga',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '8.16',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-1.64',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Antonio Carpenterio',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Austin Johnson',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '5.53',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Ben Woodward',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-30.01',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Bryce Jones',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '5.42',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-15.61',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Carlos Castaneda',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Carlos Castro',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '6.06',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '6.57',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Damyan Melton',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-18.26',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '26.57',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Daniel Garcia Jr',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Edgar Porras',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Felix Medina',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-24.67',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-13.64',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Francisco Gomez',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '16.24',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '20.68',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Frederick Burks',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '4.13',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '14.78',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Gabriel Aguirre',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-14.79',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-0.19',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Gabriel Gonzales',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-10.63',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-0.48',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Gabriel Halcon',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '17.54',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'George Blanco II',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-25.38',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-13.42',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Gerardo Castillo',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '12.78',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '5.35',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Gerardo Soriano',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-19.87',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '14.14',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Gilbert Rodriguez',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Haroll Gonzalez',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Jeremy Gonzales',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-7.71',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-0.72',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Jon-Angel Cantu',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '2.05',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '9.07',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Jonathan Martinez',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Jose Cabrera',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '11.11',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '15.06',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Jose Murillo',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Joshua Jenkins',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '7.23',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-0.70',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Juan Santos',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-21.62',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-9.97',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Kelan Williams',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '45.44',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Landan Crowder',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '2.87',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-19.05',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Manuel Vallejo',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '18.00',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '4.82',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Marquis Washington',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-11.24',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '7.72',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'No Sit Inspection',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Phillip Rodriguez',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-2.51',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '13.15',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Pierre Brim',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '27.29',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Samuel Alvarado',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '8.03',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '12.93',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Santiago Alaniz',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-21.89',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Sergio Cuevas',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '7.28',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '6.50',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Ulysses Davis',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-17.44',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '0.99',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'null',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
  ],
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_clientsList, us_setClientsList] = useState<TsInterface_UnspecifiedObject>({})
  const [us_destinationClientKey, us_setDestinationClientKey] = useState<string>('')
  const [us_doc10, us_setDoc10] = useState<string>('')
  const [us_doc2, us_setDoc2] = useState<string>('')
  const [us_doc4, us_setDoc4] = useState<string>('')
  const [us_doc6, us_setDoc6] = useState<string>('')
  const [us_doc8, us_setDoc8] = useState<string>('')
  const [us_originClientKey, us_setOriginClientKey] = useState<string>('')
  const [us_originDatabase, us_setOriginDatabase] = useState<string>('staging')
  const [us_sub1, us_setSub1] = useState<string>('')
  const [us_sub3, us_setSub3] = useState<string>('')
  const [us_sub5, us_setSub5] = useState<string>('')
  const [us_sub7, us_setSub7] = useState<string>('')
  const [us_sub9, us_setSub9] = useState<string>('')
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Test Lab', false) as string
  }, [])

  useEffect(() => {
    // moveProjectFieldFromSubcollectionToMainDocument( "jmorrell1", "0", 500 )
    // moveProjectFieldFromSubcollectionToMainDocument( "etw_energy", "Z377841510", 500 )
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setClientsList(newData)
      ur_forceRerender()
    }
    unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_RootClient_Collection(), updateLiveData)
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [ur_forceRerender])

  // Variables

  // Functions

  // JSX Generation
  const rJSX_OriginDropdown = (): JSX.Element => {
    let dropdownJSX = (
      <FormControl
        className="bp_thin_select_input bp_thin_select_multiple_input"
        sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
      >
        <Select
          onChange={(event, value) => {
            if (event != null && event.target != null && event.target.value != null) {
              us_setOriginDatabase(event.target.value)
            }
          }}
          value={us_originDatabase}
        >
          <MenuItem value={'staging'}>{rLIB('Staging')}</MenuItem>
          <MenuItem value={'prod'}>{rLIB('Production')}</MenuItem>
        </Select>
      </FormControl>
    )
    return dropdownJSX
  }

  const rJSX_OriginClientDropdown = (): JSX.Element => {
    let dropdownJSX = (
      <FormControl
        className="bp_thin_select_input bp_thin_select_multiple_input"
        sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
      >
        <Select
          onChange={(event, value) => {
            if (event != null && event.target != null && event.target.value != null) {
              us_setOriginClientKey(event.target.value)
            }
          }}
          value={us_originClientKey || ''}
        >
          {objectToArray(us_clientsList)
            .sort(dynamicSort('key', null))
            .map((option: TsInterface_UnspecifiedObject) => (
              <MenuItem
                key={option['key']}
                value={option['key']}
              >
                {option['key']}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    )
    return dropdownJSX
  }

  const rJSX_DestinationClientDropdown = (): JSX.Element => {
    let dropdownJSX = (
      <FormControl
        className="bp_thin_select_input bp_thin_select_multiple_input"
        sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
      >
        <Select
          onChange={(event, value) => {
            if (event != null && event.target != null && event.target.value != null) {
              us_setDestinationClientKey(event.target.value)
            }
          }}
          value={us_destinationClientKey || ''}
        >
          {objectToArray(us_clientsList)
            .sort(dynamicSort('key', null))
            .map((option: TsInterface_UnspecifiedObject) => (
              <MenuItem
                key={option['key']}
                value={option['key']}
              >
                {option['key']}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    )
    return dropdownJSX
  }

  const rJSX_DropdownS1 = (): JSX.Element => {
    let dropdownJSX = <></>
    const updateState = (value: string) => {
      us_setSub1(value)
      us_setDoc2('')
      us_setSub3('')
      us_setDoc4('')
      us_setSub5('')
      us_setDoc6('')
      us_setSub7('')
      us_setDoc8('')
      us_setSub9('')
      us_setDoc10('')
    }
    if (us_originClientKey !== '') {
      // Dropdown Options
      let dropdownOptions: TsInterface_UnspecifiedObject = {}
      if (databaseStructureOptions != null) {
        dropdownOptions = databaseStructureOptions
      }
      // Dropdown JSX
      dropdownJSX = (
        <FormControl
          className="bp_thin_select_input bp_thin_select_multiple_input"
          sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
        >
          <Select
            onChange={(event, value) => {
              if (event != null && event.target != null && event.target.value != null) {
                updateState(event.target.value)
              }
            }}
            value={us_sub1 || ''}
          >
            {objectToArray(dropdownOptions)
              .sort(dynamicSort('key', null))
              .map((option: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={option['key']}
                >
                  {option['key']}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )
    }
    return dropdownJSX
  }

  const rJSX_DropdownD2 = (): JSX.Element => {
    let dropdownJSX = <></>
    const updateState = (value: string) => {
      // us_setSub1( value )
      us_setDoc2(value)
      us_setSub3('')
      us_setDoc4('')
      us_setSub5('')
      us_setDoc6('')
      us_setSub7('')
      us_setDoc8('')
      us_setSub9('')
      us_setDoc10('')
    }
    if (us_originClientKey !== '' && us_sub1 !== '') {
      if (databaseStructureOptions != null && databaseStructureOptions[us_sub1] != null && databaseStructureOptions[us_sub1]['dynamic_doc_keys'] === true) {
        dropdownJSX = (
          <FormControl
            className="bp_thin_text_input"
            sx={{ width: '130px', marginTop: '0px !important', marginBottom: '0px !important' }}
          >
            <TextField
              className="bp_thin_text_input"
              value={us_doc2 || ''}
              margin="normal"
              type="text"
              sx={{ marginTop: '0px !important', marginBottom: '0px !important' }}
              onChange={(event) => {
                if (event != null && event.target != null && event.target.value != null) {
                  updateState(event.target.value)
                }
              }}
              onBlur={(event) => {
                if (event != null && event.target != null && event.target.value != null) {
                  updateState(event.target.value)
                }
              }}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
        )
      } else {
        // Dropdown Options
        let dropdownOptions: TsInterface_UnspecifiedObject = {}
        if (databaseStructureOptions != null && databaseStructureOptions[us_sub1] != null && databaseStructureOptions[us_sub1]['docs'] != null) {
          dropdownOptions = databaseStructureOptions[us_sub1]['docs']
        }
        // Dropdown JSX
        dropdownJSX = (
          <FormControl
            className="bp_thin_select_input bp_thin_select_multiple_input"
            sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
          >
            <Select
              onChange={(event, value) => {
                if (event != null && event.target != null && event.target.value != null) {
                  updateState(event.target.value)
                }
              }}
              value={us_doc2 || ''}
            >
              {objectToArray(dropdownOptions)
                .sort(dynamicSort('key', null))
                .map((option: TsInterface_UnspecifiedObject, index: number) => (
                  <MenuItem
                    key={index}
                    value={option['key']}
                  >
                    {option['key']}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )
      }
    }
    return dropdownJSX
  }

  const rJSX_DropdownS3 = (): JSX.Element => {
    let dropdownJSX = <></>
    const updateState = (value: string) => {
      // us_setSub1( value )
      // us_setDoc2( value )
      us_setSub3(value)
      us_setDoc4('')
      us_setSub5('')
      us_setDoc6('')
      us_setSub7('')
      us_setDoc8('')
      us_setSub9('')
      us_setDoc10('')
    }
    if (us_originClientKey !== '' && us_sub1 !== '' && us_doc2 !== '') {
      // Dropdown Options
      let dropdownOptions: TsInterface_UnspecifiedObject = {}
      if (
        databaseStructureOptions != null &&
        databaseStructureOptions[us_sub1] != null &&
        databaseStructureOptions[us_sub1]['docs'] != null &&
        databaseStructureOptions[us_sub1]['docs'][us_doc2] != null &&
        databaseStructureOptions[us_sub1]['docs'][us_doc2]['collections'] != null
      ) {
        dropdownOptions = databaseStructureOptions[us_sub1]['docs'][us_doc2]['collections']
        // } else if(
        // 	databaseStructureOptions != null &&
        // 	databaseStructureOptions[ us_sub1 ] != null &&
        // 	databaseStructureOptions[ us_sub1 ]["docs"] != null &&
        // 	databaseStructureOptions[ us_sub1 ]["docs"][ "$dynamicKey" ] != null &&
        // 	databaseStructureOptions[ us_sub1 ]["docs"][ "$dynamicKey" ]["collections"] != null
        // ){
        // 	dropdownOptions = databaseStructureOptions[ us_sub1 ]["docs"][ "$dynamicKey" ]["collections"]
      }
      // Dropdown JSX
      dropdownJSX = (
        <FormControl
          className="bp_thin_select_input bp_thin_select_multiple_input"
          sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
        >
          <Select
            onChange={(event, value) => {
              if (event != null && event.target != null && event.target.value != null) {
                updateState(event.target.value)
              }
            }}
            value={us_sub3 || ''}
          >
            {objectToArray(dropdownOptions)
              .sort(dynamicSort('key', null))
              .map((option: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={option['key']}
                >
                  {option['key']}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )
    }
    return dropdownJSX
  }

  const rJSX_DropdownD4 = (): JSX.Element => {
    let dropdownJSX = <></>
    const updateState = (value: string) => {
      // us_setSub1( value )
      // us_setDoc2( value )
      // us_setSub3( value )
      us_setDoc4(value)
      us_setSub5('')
      us_setDoc6('')
      us_setSub7('')
      us_setDoc8('')
      us_setSub9('')
      us_setDoc10('')
    }
    if (us_originClientKey !== '' && us_sub1 !== '' && us_doc2 !== '' && us_sub3 !== '') {
      if (
        databaseStructureOptions != null &&
        databaseStructureOptions[us_sub1] != null &&
        databaseStructureOptions[us_sub1]['docs'] != null &&
        databaseStructureOptions[us_sub1]['docs'][us_doc2] != null &&
        databaseStructureOptions[us_sub1]['docs'][us_doc2]['collections'] != null &&
        databaseStructureOptions[us_sub1]['docs'][us_doc2]['collections'][us_sub3] != null &&
        databaseStructureOptions[us_sub1]['docs'][us_doc2]['collections'][us_sub3]['dynamic_doc_keys'] === true
      ) {
        dropdownJSX = (
          <FormControl
            className="bp_thin_text_input"
            sx={{ width: '130px', marginTop: '0px !important', marginBottom: '0px !important' }}
          >
            <TextField
              className="bp_thin_text_input"
              value={us_doc4 || ''}
              margin="normal"
              type="text"
              sx={{ marginTop: '0px !important', marginBottom: '0px !important' }}
              onChange={(event) => {
                if (event != null && event.target != null && event.target.value != null) {
                  updateState(event.target.value)
                }
              }}
              onBlur={(event) => {
                if (event != null && event.target != null && event.target.value != null) {
                  updateState(event.target.value)
                }
              }}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
        )
      } else {
        // Dropdown Options
        let dropdownOptions: TsInterface_UnspecifiedObject = {}
        if (
          databaseStructureOptions != null &&
          databaseStructureOptions[us_sub1] != null &&
          databaseStructureOptions[us_sub1]['docs'] != null &&
          databaseStructureOptions[us_sub1]['docs'][us_doc2] != null &&
          databaseStructureOptions[us_sub1]['docs'][us_doc2]['collections'] != null &&
          databaseStructureOptions[us_sub1]['docs'][us_doc2]['collections'][us_sub3] != null &&
          databaseStructureOptions[us_sub1]['docs'][us_doc2]['collections'][us_sub3]['docs'] != null
        ) {
          dropdownOptions = databaseStructureOptions[us_sub1]['docs'][us_doc2]['collections'][us_sub3]['docs']
          // } else if(
          // 	databaseStructureOptions != null &&
          // 	databaseStructureOptions[ us_sub1 ] != null &&
          // 	databaseStructureOptions[ us_sub1 ]["docs"] != null &&
          // 	databaseStructureOptions[ us_sub1 ]["docs"][ "$dynamicKey" ] != null &&
          // 	databaseStructureOptions[ us_sub1 ]["docs"][ "$dynamicKey" ]["collections"] != null &&
          // 	databaseStructureOptions[ us_sub1 ]["docs"][ "$dynamicKey" ]["collections"][ us_sub3 ] != null &&
          // 	databaseStructureOptions[ us_sub1 ]["docs"][ "$dynamicKey" ]["collections"][ us_sub3 ]["docs"] != null
          // ){
          // 	dropdownOptions = databaseStructureOptions[ us_sub1 ]["docs"][ "$dynamicKey" ]["collections"]
        }
        // D

        // Dropdown JSX
        dropdownJSX = (
          <FormControl
            className="bp_thin_select_input bp_thin_select_multiple_input"
            sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
          >
            <Select
              onChange={(event, value) => {
                if (event != null && event.target != null && event.target.value != null) {
                  updateState(event.target.value)
                }
              }}
              value={us_doc4 || ''}
            >
              {objectToArray(dropdownOptions)
                .sort(dynamicSort('key', null))
                .map((option: TsInterface_UnspecifiedObject, index: number) => (
                  <MenuItem
                    key={index}
                    value={option['key']}
                  >
                    {option['key']}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )
      }
    }
    return dropdownJSX
  }

  const rJSX_DropdownS5 = (): JSX.Element => {
    let dropdownJSX = <></>

    return dropdownJSX
  }

  const rJSX_DropdownD6 = (): JSX.Element => {
    let dropdownJSX = <></>

    return dropdownJSX
  }

  const rJSX_DropdownS7 = (): JSX.Element => {
    let dropdownJSX = <></>

    return dropdownJSX
  }

  const rJSX_DropdownD8 = (): JSX.Element => {
    let dropdownJSX = <></>

    return dropdownJSX
  }

  const rJSX_DropdownS9 = (): JSX.Element => {
    let dropdownJSX = <></>

    return dropdownJSX
  }

  const rJSX_DropdownD10 = (): JSX.Element => {
    let dropdownJSX = <></>

    return dropdownJSX
  }

  // const rJSX_ClientKeyDropdown = (): JSX.Element => {
  // let dropdownJSX =
  // <FormControl className="bp_thin_select_input bp_thin_select_multiple_input" sx={{ minWidth: "130px", marginRight: "12px", marginBottom: "8px" }}>
  // 	<Select
  // 		onChange={ ( event, value ) => {

  // 		} }
  // 		value={ conditionLine.variable_2 || "" }
  // 	>
  // 		{ objectToArray( calculationData.variables ).sort( dynamicSort( "name", null )).map(( option: TsInterface_UnspecifiedObject ) => (
  // 			<MenuItem key={ option["key"] } value={ option["key"] } disabled={ option["status"] === "inactive" }>
  // 				{ option["name"] }{ variableTrace2 }
  // 			</MenuItem>
  // 		)) }
  // 	</Select>
  // </FormControl>
  // return dropdownJSX
  // }

  // Templates for different Task Keys
  const generateInstallTemplate = (customerName: string, taskName: string, scheduledDateTime: string, address: string): string => {
    return `
    <div style="font-family: Arial, sans-serif; border: 1px solid #ccc; padding: 16px; margin-bottom: 16px; border-radius: 8px;">
      <p>Hi ${customerName},</p>
      <p>This is a reminder about your <strong>${taskName}</strong> appointment that is scheduled for <strong>${scheduledDateTime}</strong> at your location: <strong>${address}</strong>.</p>
      <p>This task is part of the <strong>Install</strong> template.</p>
      <p>Thank you!</p>
    </div>
  `
  }

  const generateSiteAuditTemplate = (customerName: string, taskName: string, scheduledDateTime: string, address: string): string => {
    return `
    <div style="font-family: Arial, sans-serif; border: 1px solid #ccc; padding: 16px; margin-bottom: 16px; border-radius: 8px;">
      <p>Hi ${customerName},</p>
      <p>This is a reminder about your <strong>${taskName}</strong> appointment that is scheduled for <strong>${scheduledDateTime}</strong> at your location: <strong>${address}</strong>.</p>
      <p>This task is part of the <strong>Site Audit</strong> template.</p>
      <p>Thank you!</p>
    </div>
  `
  }

  const generateElectricalTemplate = (customerName: string, taskName: string, scheduledDateTime: string, address: string): string => {
    return `
    <div style="font-family: Arial, sans-serif; border: 1px solid #ccc; padding: 16px; margin-bottom: 16px; border-radius: 8px;">
      <p>Hi ${customerName},</p>
      <p>This is a reminder about your <strong>${taskName}</strong> appointment that is scheduled for <strong>${scheduledDateTime}</strong> at your location: <strong>${address}</strong>.</p>
      <p>This task is part of the <strong>Electrical</strong> template.</p>
      <p>Thank you!</p>
    </div>
  `
  }

  const generateRepairTemplate = (customerName: string, taskName: string, scheduledDateTime: string, address: string): string => {
    return `
    <div style="font-family: Arial, sans-serif; border: 1px solid #ccc; padding: 16px; margin-bottom: 16px; border-radius: 8px;">
      <p>Hi ${customerName},</p>
      <p>This is a reminder about your <strong>${taskName}</strong> appointment that is scheduled for <strong>${scheduledDateTime}</strong> at your location: <strong>${address}</strong>.</p>
      <p>This task is part of the <strong>Repair</strong> template.</p>
      <p>Thank you!</p>
    </div>
  `
  }

  const generateDefaultTemplate = (customerName: string, taskName: string, scheduledDateTime: string, taskGroupKey: string, address: string): string => {
    return `
    <div style="font-family: Arial, sans-serif; border: 1px solid #ccc; padding: 16px; margin-bottom: 16px; border-radius: 8px;">
      <p>Hi ${customerName},</p>
      <p>This is a reminder about your <strong>${taskName}</strong> appointment that is scheduled for <strong>${scheduledDateTime}</strong> at your location: <strong>${address}</strong>.</p>
      <p>This task is part of the <strong>${taskGroupKey}</strong> template.</p>
      <p>Thank you!</p>
    </div>
  `
  }

  const generateEmailTemplate = (customerName: string, taskName: string, scheduledDateTime: string, taskGroupKey: string, address: string): string => {
    switch (taskGroupKey) {
      case 'install':
        return generateInstallTemplate(customerName, taskName, scheduledDateTime, address)
      case 'site_audit':
        return generateSiteAuditTemplate(customerName, taskName, scheduledDateTime, address)
      case 'electrical':
        return generateElectricalTemplate(customerName, taskName, scheduledDateTime, address)
      case 'repair':
        return generateRepairTemplate(customerName, taskName, scheduledDateTime, address)
      default:
        return generateDefaultTemplate(customerName, taskName, scheduledDateTime, taskGroupKey, address)
    }
  }

  const DatabaseRef_ScheduledTasksOnSpecificDate_Query = (clientKey: string, dateKey: string) => {
    let dateKeyArray = [dateKey]
    console.log(`Querying tasks for dateKey: ${dateKey}`) // Log the dateKey being queried
    return generateDatabaseArrayContainsAnyQuery(
      collection(getFirestore(), 'clients', clientKey, 'operations', 'tasks', 'main'),
      'task_completion_scheduled_dates',
      dateKeyArray,
    )
  }

  function getTomorrowDate(dateStr: string): string {
    const [year, month, day] = dateStr.split('-').map(Number)
    const formatDate = (date: Date): string => {
      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const day = date.getDate().toString().padStart(2, '0')
      return `${year}-${month}-${day}`
    }

    const tomorrow = new Date(year, month - 1, day + 1)
    const tomorrowStr = formatDate(tomorrow)

    console.log(`Tomorrow's date is: ${tomorrowStr}`) // Log the calculated tomorrow's date
    return tomorrowStr
  }

  const getTaskStartTime = (scheduledDate: string, startTimesMap: { [key: string]: string }): string => {
    // Check if the date key exists in the task's start times map and return the time
    return startTimesMap[scheduledDate] || 'Time not available' // Return a fallback if no time is available
  }

  const getTasksForTomorrow = async (clientKey: string, dateKey: string) => {
    return new Promise((resolve, reject) => {
      console.log(`Fetching scheduled tasks for date: ${dateKey}`)
      DatabaseGetCollection(DatabaseRef_ScheduledTasksOnSpecificDate_Query(clientKey, dateKey))
        .then((res_DGC) => {
          let scheduledTasks = res_DGC.data
          console.log('Raw scheduled tasks:', scheduledTasks)

          if (!scheduledTasks || Object.keys(scheduledTasks).length === 0) {
            console.warn('No tasks found for the given date.') // Warn if no tasks are found
          }

          let promiseArray: TsType_UnknownPromise[] = []

          // Loop through tasks and fetch project details if needed
          for (let taskKey in scheduledTasks) {
            let task = scheduledTasks[taskKey]
            console.log(`Processing task with key: ${taskKey}`) // Log each task being processed

            if (task.associated_project_key != null) {
              console.log(`Fetching project data for task with project key: ${task.associated_project_key}`)
              promiseArray.push(
                DatabaseGetDocument(DatabaseRef_Project_Document(clientKey, task.associated_project_key))
                  .then((res_DGD) => {
                    scheduledTasks[taskKey].TEMP_project = res_DGD.data
                    console.log(`Fetched project data for task ${taskKey}:`, res_DGD.data)
                  })
                  .catch((rej_DGD) => {
                    console.error(`Error fetching project details for task ${taskKey}:`, rej_DGD)
                  }),
              )
            } else {
              scheduledTasks[taskKey].TEMP_project = null
              console.log(`No project associated with task ${taskKey}`)
            }
          }

          // Resolve after all project data has been fetched
          Promise.all(promiseArray)
            .then(() => {
              console.log('Final scheduled tasks with project data:', scheduledTasks)
              resolve(scheduledTasks)
            })
            .catch((error) => {
              console.error('Error fetching project data for tasks:', error)
              reject(error)
            })
        })
        .catch((error) => {
          console.error('Error fetching scheduled tasks:', error)
          reject(error)
        })
    })
  }

  const rJSX_TaskReminderEmailTab = (): JSX.Element => {
    const triggerTaskReminderEmail = () => {
      console.log('Triggering sendTaskReminderEmail from the server...')

      // Define the payload for manageEmails
      const payload = {
        function: 'sendGMDashboardWeeklyEmail', // Function to trigger
        client_key: 'etw_energy', // Required client key
        email_to: ['tyler.colson@etwenergy.com'], // Email recipient (test only to you)
        cc: [], // CC recipients (optional)
        bcc: [], // BCC recipients (optional)
        subject: 'Weekly GM Dashboard Update (Test)', // Subject of the email
      }

      // Log the payload for debugging
      console.log('Payload being sent to cloudFunctionManageRequest:', payload)

      // Call the manageEmails function
      cloudFunctionManageRequest('manageEmails', payload)
        .then((res) => {
          console.log('GM Dash Weekly Email triggered successfully:', res)
        })
        .catch((error) => {
          console.error('Error triggering GM Dash Weekly Email:', error)
          // Log specific error details, if available
          if (error.response?.body?.errors) {
            console.error('Detailed SendGrid errors:', error.response.body.errors)
          }
        })
    }

    return (
      <Box>
        <Button
          onClick={() => {
            triggerTaskReminderEmail()
          }}
        >
          SEND GM Dash Weekly Email
        </Button>
      </Box>
    )
  }

  const rJSX_DatabaseManagementTab = (): JSX.Element => {
    return (
      <Box>
        <Card className="tw-p-4">
          <Typography
            variant="h6"
            className="tw-mb-2"
          >
            {rLIB('Database Management Utility')}
          </Typography>
          <Typography
            variant="body1"
            className="tw-opacity-40 tw-mt-4"
          ></Typography>
          <Divider className="tw-my-2" />
          <Box className="tw-ml-4">
            <Typography
              variant="body1"
              className="tw-mb-2 tw-opacity-40"
            >
              {rLIB('Copy From')}:
            </Typography>
            {rJSX_OriginDropdown()}
            {rJSX_OriginClientDropdown()}

            {rJSX_DropdownS1()}
            {rJSX_DropdownD2()}
            {rJSX_DropdownS3()}
            {rJSX_DropdownD4()}
            {rJSX_DropdownS5()}
            {rJSX_DropdownD6()}
            {rJSX_DropdownS7()}
            {rJSX_DropdownD8()}
            {rJSX_DropdownS9()}
            {rJSX_DropdownD10()}
          </Box>
          <Box className="tw-ml-4">
            <Typography
              variant="body1"
              className="tw-mb-2 tw-opacity-40"
            >
              {rLIB('Copy To')}:
            </Typography>
            {rJSX_DestinationClientDropdown()}
          </Box>
        </Card>
        <Json
          data={{
            us_originDatabase: us_originDatabase,
            us_originClientKey: us_originClientKey,
            us_destinationClientKey: us_destinationClientKey,
            us_sub1: us_sub1,
            us_doc2: us_doc2,
            us_sub3: us_sub3,
            us_doc4: us_doc4,
            us_sub5: us_sub5,
            us_doc6: us_doc6,
            us_sub7: us_sub7,
            us_doc8: us_doc8,
            us_sub9: us_sub9,
            us_doc10: us_doc10,
          }}
        />
      </Box>
    )
  }

  const rJSX_EmailTab = (): JSX.Element => {
    let templateObject: TsInterface_EmailTemplateObject = {
      BODY_DIV: {
        element_type: 'div',
        style: {
          'box-sizing': 'border-box',
          'width': '100%',
          'background': '#eeeeee',
          'padding': '16px',
        },
        contents: {
          CARD_DIV: {
            element_type: 'div',
            style: {
              'max-width': '600px',
              'background': '#ffffff',
              'border-radius': '8px',
              'box-shadow': '0 4px 8px rgba(0, 0, 0, 0.1)',
              'margin': '0 auto',
              'overflow': 'hidden',
            },
            contents: {
              HEADER_DIV: {
                element_type: 'div',
                style: {
                  'box-sizing': 'border-box',
                  'background': 'rgb(25, 30, 33)',
                  'font-size': '20px',
                  'width': '100%',
                  'min-height': '70px',
                  'padding': '8px',
                  'text-align': 'center',
                },
                contents: {
                  LOGO_IMG: {
                    element_type: 'img',
                    src_type: 'static',
                    src: 'https://firebasestorage.googleapis.com/v0/b/etw-energy.appspot.com/o/global%2Fetw_logo_narrow.png?alt=media&token=13b9e254-6c87-43a2-82b8-6f65cc1a0250',
                    alt: 'logo.png',
                    width: '80px',
                    height: 'auto',
                  },
                },
              },
              CONTENT_DIV: {
                element_type: 'div',
                style: {
                  padding: '16px',
                },
                contents: {
                  INVOICE1: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'Invoice from ETW Energy LLC',
                    style: {
                      'font-size': '16px',
                      'margin-bottom': '4px',
                      'color': 'rgba(0, 0, 0, 0.87)',
                    },
                  },
                  GREETING: {
                    element_type: 'p',
                    text_type: 'dynamic',
                    data_object_key: 'invoice',
                    data_object_prop_key: 'total',
                    style: {
                      'font-size': '36px',
                      'font-weight': 'bold',
                      'margin-bottom': '4px',
                      'color': 'rgba(0, 0, 0, 0.87)',
                    },
                    // prefix_text: 'Hi ',
                    // suffix_text: ',',
                  },
                  INVOICE2: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'See the attached invoice for full details.',
                    style: {
                      'font-size': '16px',
                      'margin-bottom': '4px',
                      'color': 'rgba(0, 0, 0, 0.87)',
                    },
                  },
                  INVOICE3: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'If you have any questions, please contact us at support@etwenergy.com.',
                    style: {
                      'font-size': '16px',
                      'margin-bottom': '4px',
                      'color': 'rgba(0, 0, 0, 0.87)',
                    },
                  },
                },
              },
            },
          },
        },
      },
    }
    let dataObjects: TsInterface_UnspecifiedObject = {
      invoice: {
        total: '$100.00',
      },
    }

    return (
      <Box>
        <Box
          className="ql-scroll-html-render"
          dangerouslySetInnerHTML={{ __html: generateHtmlForEmailFromTemplateObject(templateObject, dataObjects) }}
        />

        <Button
          onClick={() => {
            const htmlContent = generateHtmlForEmailFromTemplateObject(templateObject, dataObjects)

            cloudFunctionManageRequest('manageEmails', {
              function: 'sendSendgridHtmlEmail',
              to: ['john.morrell@etwenergy.com'], // Email sent only to you for testing
              subject: 'Test Email',
              html: htmlContent,
              cc: [],
              bcc: [],
            })
              .then((res) => {
                console.log('Email sent successfully:', res)
              })
              .catch((rej) => {
                console.error('Error sending email:', rej)
              })
          }}
        >
          SEND TASK REMINDER EMAIL
        </Button>
      </Box>
    )
  }

  const rJSX_TestLeaderboardData = (): JSX.Element => {
    return (
      <Button
        onClick={() => {
          cloudFunctionManageRequest('manageData', {
            // function: 'manually_calculate_leaderboard_data',
            function: 'test_leaderboard_data_query',
            client_key: 'etw_energy',
          })
            .then((res) => {
              console.log('Leaderboard data query:', res)
            })
            .catch((rej) => {
              console.error('Error querying leaderboard data:', rej)
            })
        }}
      >
        TEST LEADERBOARD DATA
      </Button>
    )
  }

  const rJSX_GHLMessages = (): JSX.Element => {
    const downloadGHLMessagesAsJSON = () => {
      DatabaseGetCollection(DatabaseRef_GHL_Messages_Collection('etw_energy'))
        .then((res) => {
          // Convert the data to a JSON string with proper formatting
          const jsonData = JSON.stringify(res.data, null, 2)

          // Create a blob with the JSON data
          const blob = new Blob([jsonData], { type: 'application/json' })

          // Create a URL for the blob
          const url = window.URL.createObjectURL(blob)

          // Create a temporary anchor element
          const link = document.createElement('a')
          link.href = url

          // Set the download filename
          link.download = 'ghl_messages.json'

          // Append link to body, click it, and remove it
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          // Clean up the URL object
          window.URL.revokeObjectURL(url)

          console.log('GHL Messages downloaded successfully')
        })
        .catch((rej) => {
          console.error('Error getting GHL Messages:', rej)
        })
    }

    return <Button onClick={downloadGHLMessagesAsJSON}>Download GHL Messages</Button>
  }

  const rJSX_TestEagleViewMapTIFData = (): JSX.Element => {
    const mapRef = useRef<HTMLDivElement>(null)
    const mapInstanceRef = useRef<L.Map | null>(null)

    // Function to load and display GeoTIFF
    async function loadGeoTIFF() {
      try {
        //Get reference to the file in Firebase Storage
        const storageRef = ref(getStorage(), 'clients/etw_energy_sales/sales/eagleview-images/HhkjRz5aEjkvAEJSF41K.tif')

        // Get download URL
        const downloadURL = await getDownloadURL(storageRef)
        const response = await fetch(downloadURL)
        const arrayBuffer = await response.arrayBuffer()

        // Try parsing with the verified metadata
        const georaster = await parseGeoraster(arrayBuffer)

        // Create and add layer
        const layer = new GeoRasterLayer({
          georaster: georaster,
          opacity: 0.7,
          resolution: 256,
        })

        if (mapInstanceRef.current) {
          layer.addTo(mapInstanceRef.current)
          // mapInstanceRef.current.fitBounds(layer.getBounds(), { maxZoom: 50 })
          const bounds = layer.getBounds()
          mapInstanceRef.current.setView(bounds.getCenter(), 20)
        }
      } catch (error) {
        console.error('Error loading GeoTIFF:', error)
        if (error instanceof Error) {
          console.error('Error details:', {
            name: error.name,
            message: error.message,
            stack: error.stack,
          })
        }
      }
    }

    useEffect(() => {
      if (mapRef.current && !mapInstanceRef.current) {
        // Initialize the map
        const map = L.map(mapRef.current, {
          maxZoom: 22,
          minZoom: 1,
          attributionControl: false,
        }).setView([0, 0], 1)
        mapInstanceRef.current = map

        // Add OpenStreetMap as base layer
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '© OpenStreetMap contributors',
        }).addTo(map)

        // Load the GeoTIFF when the map is initialized
        loadGeoTIFF()
      }

      // Cleanup function
      return () => {
        if (mapInstanceRef.current) {
          mapInstanceRef.current.remove()
          mapInstanceRef.current = null
        }
      }
    }, [])

    return (
      <Box>
        <div
          ref={mapRef}
          style={{
            height: '500px',
            width: '85%',
            marginBottom: '16px',
            marginLeft: '50px',
          }}
        />
        <Button
          onClick={() => {
            // Add any additional functionality you need
            console.log('Map button clicked')
          }}
        >
          Test EagleView Map TIF Data
        </Button>
      </Box>
    )
  }
  interface IntervalData {
    intervalStart: Date
    intervalEnd: Date
    intervalKWh: number
  }

  interface ExportCreditsData {
    month: string
    time: number
    credits: number
  }

  interface GenerationData {
    month: number
    day: number
    hour: number
    dcOutput: number //watts
    acOutput: number //watts
  }

  const parseExportCreditsCSV = async (file: File): Promise<ExportCreditsData[]> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = (event) => {
        try {
          const text = event.target?.result as string
          const lines = text.split('\n')

          // Skip first two rows and use third row (index 2) as headers
          const headers = lines[2].toLowerCase().split(',')
          const monthColumns = headers
            .map((header, index) => ({
              index,
              header: header.trim(),
            }))
            .filter((col) => col.header.startsWith('total - '))

          if (monthColumns.length === 0) {
            throw new Error('CSV must contain columns starting with "total - "')
          }

          const data: ExportCreditsData[] = []

          // Process each row (starting from row 4, which is index 3)
          lines.slice(3).forEach((line, rowIndex) => {
            if (!line.trim()) return // Skip empty lines

            const columns = line.split(',')
            const timeStr = columns[0].trim()

            // Parse time string (assuming 12-hour format)
            const [time, period] = timeStr.split(' ')
            const [hours, minutes] = time.split(':').map((n) => parseInt(n))

            // Convert to 24-hour format
            let hour = hours
            if (period.toLowerCase() === 'pm' && hours !== 12) {
              hour += 12
            } else if (period.toLowerCase() === 'am' && hours === 12) {
              hour = 0
            }

            // Get credits for each month
            monthColumns.forEach((col) => {
              const credits = parseFloat(columns[col.index])
              if (!isNaN(credits)) {
                data.push({
                  time: hour,
                  month: col.header.split(' ')[2].trim(),
                  credits: credits,
                })
              }
            })
          })

          resolve(data)
        } catch (error) {
          reject(error)
        }
      }

      reader.onerror = () => reject(new Error('Failed to read file'))
      reader.readAsText(file)
    })
  }

  // Function to parse CSV and extract relevant data
  const parseUsageCSV = async (file: File): Promise<IntervalData[]> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      // Helper function to parse date strings in the specific format
      const parseDate = (dateStr: string): Date => {
        // Handle both date formats (MM/DD/YYYY HH:mm and MM/DD/YY HH:mm)
        const [datePart, timePart] = dateStr.trim().split(' ')
        const [month, day, year] = datePart.split('/')
        const [hours, minutes] = timePart.split(':')

        // Convert 2-digit year to 4-digit year if necessary
        const fullYear = year.length === 2 ? '20' + year : year

        return new Date(
          parseInt(fullYear),
          parseInt(month) - 1, // Months are 0-based in JavaScript
          parseInt(day),
          parseInt(hours),
          parseInt(minutes),
        )
      }

      // Helper function to aggregate 15-min intervals into hourly data
      const aggregateToHourly = (data: IntervalData[]): IntervalData[] => {
        const hourlyMap = new Map<string, IntervalData>()

        data.forEach((interval) => {
          // Create key for the hour (removing minutes)
          const hourKey = new Date(interval.intervalStart).setMinutes(0, 0, 0).toString()

          if (!hourlyMap.has(hourKey)) {
            hourlyMap.set(hourKey, {
              intervalStart: new Date(parseInt(hourKey)),
              intervalEnd: new Date(new Date(parseInt(hourKey)).setHours(new Date(parseInt(hourKey)).getHours() + 1)),
              intervalKWh: 0,
            })
          }

          // Add the kWh value to the hour's total
          const hourData = hourlyMap.get(hourKey)!
          hourData.intervalKWh += interval.intervalKWh
        })

        return Array.from(hourlyMap.values()).sort((a, b) => a.intervalStart.getTime() - b.intervalStart.getTime())
      }

      const parseCSVLine = (line: string): string[] => {
        const values: string[] = []
        let currentValue = ''
        let insideQuotes = false

        for (let i = 0; i < line.length; i++) {
          const char = line[i]

          if (char === '"') {
            insideQuotes = !insideQuotes
          } else if (char === ',' && !insideQuotes) {
            values.push(currentValue.trim())
            currentValue = ''
          } else {
            currentValue += char
          }
        }

        // Push the last value
        values.push(currentValue.trim())

        // Clean up any quotes from the values
        return values.map((val) => val.replace(/^"|"$/g, ''))
      }

      reader.onload = (event) => {
        try {
          const text = event.target?.result as string
          const lines = text.split('\n')
          // Get header row and find column indices
          const headers = lines[0].toLowerCase().split(',')
          const startIndex = headers.findIndex((h) => h.includes('interval_start'))
          const endIndex = headers.findIndex((h) => h.includes('interval_end'))
          const kWhIndex = headers.findIndex((h) => h.includes('interval_kwh'))

          // Validate required columns exist
          if (startIndex === -1 || endIndex === -1 || kWhIndex === -1) {
            throw new Error('CSV must contain interval_start, interval_end, and interval_kwh columns')
          }

          // Parse all data first
          const rawData: IntervalData[] = lines
            .slice(1) // Skip header row
            .filter((line) => line.trim()) // Remove empty lines
            .map((line) => {
              const columns = parseCSVLine(line)
              try {
                return {
                  intervalStart: parseDate(columns[startIndex]),
                  intervalEnd: parseDate(columns[endIndex]),
                  intervalKWh: parseFloat(columns[kWhIndex]),
                }
              } catch (error) {
                console.error('Error parsing row:', line, error)
                throw new Error(`Failed to parse row: ${line}`)
              }
            })

          // Detect interval length by checking the first two intervals
          if (rawData.length >= 2) {
            const firstInterval = rawData[0].intervalEnd.getTime() - rawData[0].intervalStart.getTime()
            const intervalInMinutes = firstInterval / (1000 * 60)

            // If intervals are 15 minutes, aggregate to hourly
            if (intervalInMinutes === 15) {
              resolve(aggregateToHourly(rawData))
            } else if (intervalInMinutes === 30) {
              resolve(aggregateToHourly(rawData))
            } else if (intervalInMinutes === 60) {
              resolve(rawData)
            } else {
              console.warn(`Unexpected interval length: ${intervalInMinutes} minutes`)
              resolve(rawData)
            }
          } else {
            resolve(rawData)
          }
        } catch (error) {
          reject(error)
        }
      }
      reader.onerror = () => reject(new Error('Failed to read file'))
      reader.readAsText(file)
    })
  }

  const parseGenerationCSV = async (file: File): Promise<GenerationData[]> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      const parseCSVLine = (line: string): string[] => {
        const values: string[] = []
        let currentValue = ''
        let insideQuotes = false

        for (let i = 0; i < line.length; i++) {
          const char = line[i]

          if (char === '"') {
            insideQuotes = !insideQuotes
          } else if (char === ',' && !insideQuotes) {
            values.push(currentValue.trim())
            currentValue = ''
          } else {
            currentValue += char
          }
        }

        // Push the last value
        values.push(currentValue.trim())

        // Clean up any quotes from the values
        return values.map((val) => val.replace(/^"|"$/g, ''))
      }

      reader.onload = (event) => {
        try {
          const text = event.target?.result as string
          const lines = text.split('\n')
          // Get header row and find column indices
          const headers = lines[31].toLowerCase().split(',')
          const monthIndex = headers.findIndex((h) => h.includes('month'))
          const dayIndex = headers.findIndex((h) => h.includes('day'))
          const hourIndex = headers.findIndex((h) => h.includes('hour'))
          const dcOutputIndex = headers.findIndex((h) => h.includes('dc array output (w)'))
          const acOutputIndex = headers.findIndex((h) => h.includes('ac system output (w)'))

          // Validate required columns exist
          if (monthIndex === -1 || dayIndex === -1 || hourIndex === -1 || dcOutputIndex === -1 || acOutputIndex === -1) {
            console.log('error')
            throw new Error('CSV must contain month, day, hour, dc output, and ac output columns')
          }

          // Parse data rows
          const data: GenerationData[] = lines
            .slice(1) // Skip header row
            .slice(1)
            .filter((line) => line.trim()) // Remove empty lines
            .map((line) => {
              const columns = parseCSVLine(line)
              console.log('columns', columns)
              try {
                return {
                  month: parseInt(columns[monthIndex]),
                  day: parseInt(columns[dayIndex]),
                  hour: parseInt(columns[hourIndex]),
                  dcOutput: parseFloat(columns[dcOutputIndex]),
                  acOutput: parseFloat(columns[acOutputIndex]),
                }
              } catch (error) {
                console.error('Error parsing row:', line, error)
                throw new Error(`Failed to parse row: ${line}`)
              }
            })

          resolve(data)
        } catch (error) {
          reject(error)
        }
      }
      reader.onerror = () => reject(new Error('Failed to read file'))
      reader.readAsText(file)
    })
  }

  interface BillingPeriod {
    usage: number
    cost: number
  }

  interface BillingAnalysis {
    totalCost: number
    totalUsage: number
    solarUsage: number
    gridUsage: number
    batteryUsage: number
    exportedToGrid: number
    storedInBattery: number
    peakSummer: BillingPeriod
    peakWinter: BillingPeriod
    partialPeakSummer: BillingPeriod
    partialPeakWinter: BillingPeriod
    offPeakSummer: BillingPeriod
    offPeakWinter: BillingPeriod
    numberOfIntervals: number
    dailyCharges: number
    semiAnnualCharges: number
  }

  const calculateBill = (data: IntervalData[], utilityRates: any): BillingAnalysis => {
    // Initialize billing analysis
    const analysis: BillingAnalysis = {
      totalCost: 0,
      totalUsage: 0,
      gridUsage: 0,
      solarUsage: 0,
      batteryUsage: 0,
      exportedToGrid: 0,
      storedInBattery: 0,
      peakSummer: { usage: 0, cost: 0 },
      peakWinter: { usage: 0, cost: 0 },
      partialPeakSummer: { usage: 0, cost: 0 },
      partialPeakWinter: { usage: 0, cost: 0 },
      offPeakSummer: { usage: 0, cost: 0 },
      offPeakWinter: { usage: 0, cost: 0 },
      numberOfIntervals: 0,
      dailyCharges: 0,
      semiAnnualCharges: 0,
    }

    const uniqueDays = new Set<string>()
    const semiAnnualCharge = 55.17

    // Helper function to determine if a date is in summer (May-October)
    const isSummerMonth = (date: Date): boolean => {
      const month = date.getMonth() // 0-11
      return month >= 5 && month <= 8 // June (5) through September (8)
    }

    // Helper function to determine rate period based on hour
    const getRatePeriod = (hour: number): 'peak' | 'partial_peak' | 'off_peak' => {
      if (hour >= 16 && hour < 21) {
        // 4 PM - 9 PM
        return 'peak'
      } else if ((hour >= 15 && hour < 16) || hour >= 21 || hour == 0) {
        // 3 PM - 4 PM or 9 PM - 12 AM
        return 'partial_peak'
      } else {
        return 'off_peak'
      }
    }

    // Process each interval
    data.forEach((interval) => {
      const startHour = interval.intervalStart.getHours()
      const isSummer = isSummerMonth(interval.intervalStart)
      const ratePeriod = getRatePeriod(startHour)
      const usage = interval.intervalKWh

      // Track unique days for daily charge
      const dateKey = interval.intervalStart.toISOString().split('T')[0]
      uniqueDays.add(dateKey)

      // Check for semi-annual charges (April 1st and October 1st)
      const month = interval.intervalStart.getMonth()
      const day = interval.intervalStart.getDate()
      const hour = interval.intervalStart.getHours()
      if ((month === 3 || month === 9) && day === 1 && hour === 0) {
        // 3 = April, 9 = October
        analysis.semiAnnualCharges += semiAnnualCharge
      }

      // Add to total usage
      analysis.totalUsage += usage
      analysis.gridUsage += usage
      analysis.numberOfIntervals += 1

      // Determine rate and add to appropriate period
      if (ratePeriod === 'peak') {
        if (isSummer) {
          analysis.peakSummer.usage += usage
          analysis.peakSummer.cost += usage * utilityRates.peak_summer
        } else {
          analysis.peakWinter.usage += usage
          analysis.peakWinter.cost += usage * utilityRates.peak_winter
        }
      } else if (ratePeriod === 'partial_peak') {
        if (isSummer) {
          analysis.partialPeakSummer.usage += usage
          analysis.partialPeakSummer.cost += usage * utilityRates.partial_peak_summer
        } else {
          analysis.partialPeakWinter.usage += usage
          analysis.partialPeakWinter.cost += usage * utilityRates.partial_peak_winter
        }
      } else {
        // off_peak
        if (isSummer) {
          analysis.offPeakSummer.usage += usage
          analysis.offPeakSummer.cost += usage * utilityRates.offPeak_summer
        } else {
          analysis.offPeakWinter.usage += usage
          analysis.offPeakWinter.cost += usage * utilityRates.offPeak_winter
        }
      }
    })

    // Calculate daily charges
    const dailyCharge = 0.49281
    analysis.dailyCharges = uniqueDays.size * dailyCharge

    // Calculate total cost
    analysis.totalCost =
      analysis.peakSummer.cost +
      analysis.peakWinter.cost +
      analysis.partialPeakSummer.cost +
      analysis.partialPeakWinter.cost +
      analysis.offPeakSummer.cost +
      analysis.offPeakWinter.cost +
      analysis.dailyCharges +
      analysis.semiAnnualCharges

    return analysis
  }

  const calculateCreditRate = (exportCreditsData: ExportCreditsData[], month: number, hour: number): number => {
    // need to know month
    // need to know hour
    let index = 0
    index = 12 * hour + month
    return exportCreditsData[index].credits
  }

  const calculateSolarOnlyBill = (
    data: IntervalData[],
    utilityRates: any,
    generationData: GenerationData[],
    exportCreditsWeekDayData: ExportCreditsData[],
    exportCreditsWeekendData: ExportCreditsData[],
  ): BillingAnalysis => {
    // Initialize billing analysis
    const analysis: BillingAnalysis = {
      totalCost: 0,
      totalUsage: 0,
      gridUsage: 0,
      solarUsage: 0,
      batteryUsage: 0,
      exportedToGrid: 0,
      storedInBattery: 0,
      peakSummer: { usage: 0, cost: 0 },
      peakWinter: { usage: 0, cost: 0 },
      partialPeakSummer: { usage: 0, cost: 0 },
      partialPeakWinter: { usage: 0, cost: 0 },
      offPeakSummer: { usage: 0, cost: 0 },
      offPeakWinter: { usage: 0, cost: 0 },
      numberOfIntervals: 0,
      dailyCharges: 0,
      semiAnnualCharges: 0,
    }

    const uniqueDays = new Set<string>()
    const semiAnnualCharge = 55.17

    const isSummerMonth = (date: Date): boolean => {
      const month = date.getMonth()
      return month >= 5 && month <= 8 // June through September
    }

    const isWeekday = (date: Date): boolean => {
      const day = date.getDay()
      return day >= 1 && day <= 5
    }

    const getRatePeriod = (hour: number): 'peak' | 'partial_peak' | 'off_peak' => {
      if (hour >= 16 && hour < 21) {
        return 'peak'
      } else if ((hour >= 15 && hour < 16) || hour >= 21 || hour == 0) {
        return 'partial_peak'
      } else {
        return 'off_peak'
      }
    }

    const findGenerationDataRow = (data: GenerationData[], month: number, day: number, hour: number): GenerationData | undefined => {
      return data.find((row) => row.month === month && row.day === day && row.hour === hour)
    }

    // Process each interval
    data.forEach((interval) => {
      const startHour = interval.intervalStart.getHours()
      const isSummer = isSummerMonth(interval.intervalStart)
      const ratePeriod = getRatePeriod(startHour)

      analysis.totalUsage += interval.intervalKWh

      // Calculate solar production for this hour
      let solarProduction = 0
      const generationRow = findGenerationDataRow(
        generationData,
        interval.intervalStart.getMonth() + 1,
        interval.intervalStart.getDate(),
        interval.intervalStart.getHours(),
      )
      solarProduction = generationRow ? generationRow.acOutput / 1000 : 0
      analysis.solarUsage += Math.min(solarProduction, interval.intervalKWh)

      // Calculate net usage (negative means export)
      const netUsage = interval.intervalKWh - solarProduction

      // Track unique days for daily charge
      const dateKey = interval.intervalStart.toISOString().split('T')[0]
      uniqueDays.add(dateKey)

      // Check for semi-annual charges
      const month = interval.intervalStart.getMonth()
      const day = interval.intervalStart.getDate()
      if ((month === 3 || month === 9) && day === 1 && startHour === 0) {
        analysis.semiAnnualCharges += semiAnnualCharge
      }

      // Add to total usage (only positive net usage)
      if (netUsage > 0) {
        analysis.gridUsage += netUsage
        analysis.numberOfIntervals += 1

        // Calculate costs based on rate period
        if (ratePeriod === 'peak') {
          if (isSummer) {
            analysis.peakSummer.usage += netUsage
            analysis.peakSummer.cost += netUsage * utilityRates.peak_summer
          } else {
            analysis.peakWinter.usage += netUsage
            analysis.peakWinter.cost += netUsage * utilityRates.peak_winter
          }
        } else if (ratePeriod === 'partial_peak') {
          if (isSummer) {
            analysis.partialPeakSummer.usage += netUsage
            analysis.partialPeakSummer.cost += netUsage * utilityRates.partial_peak_summer
          } else {
            analysis.partialPeakWinter.usage += netUsage
            analysis.partialPeakWinter.cost += netUsage * utilityRates.partial_peak_winter
          }
        } else {
          if (isSummer) {
            analysis.offPeakSummer.usage += netUsage
            analysis.offPeakSummer.cost += netUsage * utilityRates.offPeak_summer
          } else {
            analysis.offPeakWinter.usage += netUsage
            analysis.offPeakWinter.cost += netUsage * utilityRates.offPeak_winter
          }
        }
      } else if (netUsage < 0) {
        // Handle export credits
        const exportAmount = Math.abs(netUsage)
        analysis.exportedToGrid += exportAmount
        // need to know if it's a weekday or weekend
        let creditRate = 1

        if (isWeekday(interval.intervalStart)) {
          creditRate = calculateCreditRate(exportCreditsWeekDayData, month, interval.intervalStart.getHours())
        } else {
          creditRate = calculateCreditRate(exportCreditsWeekendData, month, interval.intervalStart.getHours())
        }

        // Apply export credits
        const creditValue = exportAmount * creditRate

        // Subtract credits from the appropriate period's cost
        if (ratePeriod === 'peak') {
          if (isSummer) {
            analysis.peakSummer.cost -= creditValue
          } else {
            analysis.peakWinter.cost -= creditValue
          }
        } else if (ratePeriod === 'partial_peak') {
          if (isSummer) {
            analysis.partialPeakSummer.cost -= creditValue
          } else {
            analysis.partialPeakWinter.cost -= creditValue
          }
        } else {
          if (isSummer) {
            analysis.offPeakSummer.cost -= creditValue
          } else {
            analysis.offPeakWinter.cost -= creditValue
          }
        }
      }
    })

    // Calculate daily charges
    const dailyCharge = 0.49281
    analysis.dailyCharges = uniqueDays.size * dailyCharge

    // Calculate total cost
    analysis.totalCost =
      analysis.peakSummer.cost +
      analysis.peakWinter.cost +
      analysis.partialPeakSummer.cost +
      analysis.partialPeakWinter.cost +
      analysis.offPeakSummer.cost +
      analysis.offPeakWinter.cost +
      analysis.dailyCharges +
      analysis.semiAnnualCharges

    return analysis
  }

  const calculateSolarPlusBatteryBill_v1 = (
    data: IntervalData[],
    utilityRates: any,
    generationData: GenerationData[],
    exportCreditsWeekDayData: ExportCreditsData[],
    exportCreditsWeekendData: ExportCreditsData[],
    batterySpecs: any,
  ): BillingAnalysis => {
    const analysis: BillingAnalysis = {
      totalCost: 0,
      totalUsage: 0,
      gridUsage: 0,
      solarUsage: 0,
      batteryUsage: 0,
      exportedToGrid: 0,
      storedInBattery: 0,
      peakSummer: { usage: 0, cost: 0 },
      peakWinter: { usage: 0, cost: 0 },
      partialPeakSummer: { usage: 0, cost: 0 },
      partialPeakWinter: { usage: 0, cost: 0 },
      offPeakSummer: { usage: 0, cost: 0 },
      offPeakWinter: { usage: 0, cost: 0 },
      numberOfIntervals: 0,
      dailyCharges: 0,
      semiAnnualCharges: 0,
    }
    const uniqueDays = new Set<string>()
    const semiAnnualCharge = 55.17
    let currentBatteryCharge = batterySpecs.capacity * (batterySpecs.reserveCapacity / 100) // Available capacity

    const isSummerMonth = (date: Date): boolean => {
      const month = date.getMonth()
      return month >= 5 && month <= 8
    }

    const isWeekday = (date: Date): boolean => {
      const day = date.getDay()
      return day >= 1 && day <= 5
    }

    const getRatePeriod = (hour: number): 'peak' | 'partial_peak' | 'off_peak' => {
      if (hour >= 16 && hour < 21) {
        return 'peak'
      } else if ((hour >= 15 && hour < 16) || hour >= 21 || hour == 0) {
        return 'partial_peak'
      } else {
        return 'off_peak'
      }
    }

    const findGenerationDataRow = (data: GenerationData[], month: number, day: number, hour: number): GenerationData | undefined => {
      return data.find((row) => row.month === month && row.day === day && row.hour === hour)
    }

    // Process each interval
    data.forEach((interval) => {
      const startHour = interval.intervalStart.getHours()
      const isSummer = isSummerMonth(interval.intervalStart)
      const ratePeriod = getRatePeriod(startHour)

      analysis.totalUsage += interval.intervalKWh

      // Calculate solar production for this hour
      let solarProduction = 0
      const generationRow = findGenerationDataRow(
        generationData,
        interval.intervalStart.getMonth() + 1,
        interval.intervalStart.getDate(),
        interval.intervalStart.getHours(),
      )
      solarProduction = generationRow ? generationRow.acOutput / 1000 : 0

      analysis.solarUsage += Math.min(solarProduction, interval.intervalKWh)

      // Calculate initial net usage (before battery)
      let netUsage = interval.intervalKWh - solarProduction

      // Battery logic
      if (netUsage < 0) {
        // Excess solar production - charge battery
        const excessEnergy = Math.abs(netUsage)
        const availableCapacity = batterySpecs.capacity - currentBatteryCharge
        const chargeAmount = Math.min(
          excessEnergy * (batterySpecs.efficiency / 100),
          availableCapacity,
          batterySpecs.maxDischargeRate / 4, // Convert hourly rate to 15-min interval
        )

        analysis.storedInBattery += chargeAmount

        currentBatteryCharge += chargeAmount
        netUsage = -(excessEnergy - chargeAmount / (batterySpecs.efficiency / 100)) // Remaining excess goes to grid
      } else if (netUsage > 0) {
        // Energy deficit - try to use battery
        const availableBatteryCharge = currentBatteryCharge - (batterySpecs.capacity * batterySpecs.reserveCapacity) / 100
        const maxDischarge = Math.min(availableBatteryCharge, batterySpecs.maxDischargeRate / 4)
        const dischargeAmount = Math.min(netUsage, maxDischarge)

        analysis.batteryUsage += dischargeAmount
        analysis.storedInBattery -= dischargeAmount

        currentBatteryCharge -= dischargeAmount
        netUsage -= dischargeAmount
      }

      // Track unique days for daily charge
      const dateKey = interval.intervalStart.toISOString().split('T')[0]
      uniqueDays.add(dateKey)

      // Check for semi-annual charges
      const month = interval.intervalStart.getMonth()
      const day = interval.intervalStart.getDate()
      if ((month === 3 || month === 9) && day === 1 && startHour === 0) {
        analysis.semiAnnualCharges += semiAnnualCharge
      }

      // Process remaining net usage
      if (netUsage > 0) {
        // Still need to draw from grid
        analysis.gridUsage += netUsage
        analysis.numberOfIntervals += 1

        // Calculate costs based on rate period
        if (ratePeriod === 'peak') {
          if (isSummer) {
            analysis.peakSummer.usage += netUsage
            analysis.peakSummer.cost += netUsage * utilityRates.peak_summer
          } else {
            analysis.peakWinter.usage += netUsage
            analysis.peakWinter.cost += netUsage * utilityRates.peak_winter
          }
        } else if (ratePeriod === 'partial_peak') {
          if (isSummer) {
            analysis.partialPeakSummer.usage += netUsage
            analysis.partialPeakSummer.cost += netUsage * utilityRates.partial_peak_summer
          } else {
            analysis.partialPeakWinter.usage += netUsage
            analysis.partialPeakWinter.cost += netUsage * utilityRates.partial_peak_winter
          }
        } else {
          if (isSummer) {
            analysis.offPeakSummer.usage += netUsage
            analysis.offPeakSummer.cost += netUsage * utilityRates.offPeak_summer
          } else {
            analysis.offPeakWinter.usage += netUsage
            analysis.offPeakWinter.cost += netUsage * utilityRates.offPeak_winter
          }
        }
      } else if (netUsage < 0) {
        // Export excess to grid
        const exportAmount = Math.abs(netUsage)
        analysis.exportedToGrid += exportAmount
        const creditRate = isWeekday(interval.intervalStart)
          ? calculateCreditRate(exportCreditsWeekDayData, interval.intervalStart.getMonth(), interval.intervalStart.getHours())
          : calculateCreditRate(exportCreditsWeekendData, interval.intervalStart.getMonth(), interval.intervalStart.getHours())

        // Apply export credits
        const creditValue = exportAmount * creditRate

        // Subtract credits from the appropriate period's cost
        if (ratePeriod === 'peak') {
          if (isSummer) {
            analysis.peakSummer.cost -= creditValue
          } else {
            analysis.peakWinter.cost -= creditValue
          }
        } else if (ratePeriod === 'partial_peak') {
          if (isSummer) {
            analysis.partialPeakSummer.cost -= creditValue
          } else {
            analysis.partialPeakWinter.cost -= creditValue
          }
        } else {
          if (isSummer) {
            analysis.offPeakSummer.cost -= creditValue
          } else {
            analysis.offPeakWinter.cost -= creditValue
          }
        }
      }
    })

    // Calculate daily charges
    const dailyCharge = 0.49281
    analysis.dailyCharges = uniqueDays.size * dailyCharge

    // Calculate total cost
    analysis.totalCost =
      analysis.peakSummer.cost +
      analysis.peakWinter.cost +
      analysis.partialPeakSummer.cost +
      analysis.partialPeakWinter.cost +
      analysis.offPeakSummer.cost +
      analysis.offPeakWinter.cost +
      analysis.dailyCharges +
      analysis.semiAnnualCharges

    return analysis
  }

  const calculateSolarPlusBatteryBill_v2 = (
    data: IntervalData[],
    utilityRates: any,
    generationData: GenerationData[],
    exportCreditsWeekDayData: ExportCreditsData[],
    exportCreditsWeekendData: ExportCreditsData[],
    batterySpecs: any,
  ): { analysis: BillingAnalysis; consumptionProfile: { [month: number]: { [hour: number]: any } } } => {
    const analysis: BillingAnalysis = {
      totalCost: 0,
      totalUsage: 0,
      gridUsage: 0,
      solarUsage: 0,
      batteryUsage: 0,
      exportedToGrid: 0,
      storedInBattery: 0,
      peakSummer: { usage: 0, cost: 0 },
      peakWinter: { usage: 0, cost: 0 },
      partialPeakSummer: { usage: 0, cost: 0 },
      partialPeakWinter: { usage: 0, cost: 0 },
      offPeakSummer: { usage: 0, cost: 0 },
      offPeakWinter: { usage: 0, cost: 0 },
      numberOfIntervals: 0,
      dailyCharges: 0,
      semiAnnualCharges: 0,
    }
    const consumptionProfile: { [month: number]: { [hour: number]: any } } = {}

    const uniqueDays = new Set<string>()
    const semiAnnualCharge = 55.17
    let currentBatteryCharge = batterySpecs.capacity * (batterySpecs.reserveCapacity / 100) // Available capacity

    const isSummerMonth = (date: Date): boolean => {
      const month = date.getMonth()
      return month >= 5 && month <= 8
    }

    const isWeekday = (date: Date): boolean => {
      const day = date.getDay()
      return day >= 1 && day <= 5
    }

    const getRatePeriod = (hour: number): 'peak' | 'partial_peak' | 'off_peak' => {
      if (hour >= 16 && hour < 21) {
        return 'peak'
      } else if ((hour >= 15 && hour < 16) || hour >= 21 || hour == 0) {
        return 'partial_peak'
      } else {
        return 'off_peak'
      }
    }

    const findGenerationDataRow = (data: GenerationData[], month: number, day: number, hour: number): GenerationData | undefined => {
      return data.find((row) => row.month === month && row.day === day && row.hour === hour)
    }

    const shouldDischargeBattery = (
      ratePeriod: 'peak' | 'partial_peak' | 'off_peak',
      isSummer: boolean,
      isWeekday: boolean,
      hour: number,
      month: number,
      currentBatteryCharge: number,
    ): { shouldDischarge: boolean; dischargeAmount: number } => {
      const minimumCharge = (batterySpecs.capacity * batterySpecs.reserveCapacity) / 100
      const availableCharge = Math.max(0, currentBatteryCharge - minimumCharge)

      // If no charge available, don't discharge
      if (availableCharge <= 0) {
        return { shouldDischarge: false, dischargeAmount: 0 }
      }

      // Calculate max discharge based on battery specs
      const maxDischarge = Math.min(availableCharge, batterySpecs.maxDischargeRate)

      // Determine discharge strategy based on rate period
      switch (ratePeriod) {
        case 'peak':
          // Always discharge during peak if we have charge
          return {
            shouldDischarge: true,
            dischargeAmount: maxDischarge,
          }

        case 'partial_peak': {
          // For partial peak, discharge if we have more than 50% of capacity
          // This ensures we save some capacity for upcoming peak periods
          // const partialPeakThreshold = batterySpecs.capacity * 0.3
          // if (currentBatteryCharge > partialPeakThreshold) {
          // Only use up to 50% of max discharge rate for partial peak
          return {
            shouldDischarge: true,
            dischargeAmount: maxDischarge,
          }
        }
        // return { shouldDischarge: false, dischargeAmount: 0 }
        // }

        case 'off_peak': {
          // For off-peak, only discharge if we have excess capacity (>80%)
          // and it's during high-usage hours (e.g., early morning)
          // const offPeakThreshold = batterySpecs.capacity * 0.2
          // const isHighUsageHour = hour >= 6 && hour <= 9 // Early morning hours
          // if (currentBatteryCharge > offPeakThreshold) {
          // Only use up to 25% of max discharge rate for off-peak
          return {
            shouldDischarge: true,
            dischargeAmount: maxDischarge,
          }
        }
        //   return { shouldDischarge: false, dischargeAmount: 0 }
        // }

        default:
          return { shouldDischarge: false, dischargeAmount: 0 }
      }
    }

    // Process each interval
    data.forEach((interval) => {
      const startHour = interval.intervalStart.getHours()
      const isSummer = isSummerMonth(interval.intervalStart)
      const ratePeriod = getRatePeriod(startHour)
      const isWeekdayValue = isWeekday(interval.intervalStart)
      const currentMonth = interval.intervalStart.getMonth()

      // Initialize month and hour if not already done
      if (!consumptionProfile[currentMonth]) {
        consumptionProfile[currentMonth] = {}
      }
      if (!consumptionProfile[currentMonth][startHour]) {
        consumptionProfile[currentMonth][startHour] = {
          solarGeneration: 0,
          storedInBattery: 0,
          exportedToGrid: 0,
          fromSolar: 0,
          fromGrid: 0,
          fromBattery: 0,
          batteryLevel: 0,
          numBatteryLevelUpdates: 0,
          numSolarGenerationUpdates: 0,
          numGridUsageUpdates: 0,
          numBatteryUsageUpdates: 0,
          numSolarUsageUpdates: 0,
          numExportedToGridUpdates: 0,
          numStoredInBatteryUpdates: 0,
        }
      }

      analysis.totalUsage += interval.intervalKWh

      // Calculate solar production for this hour
      let solarProduction = 0
      const generationRow = findGenerationDataRow(
        generationData,
        interval.intervalStart.getMonth() + 1,
        interval.intervalStart.getDate(),
        interval.intervalStart.getHours(),
      )
      solarProduction = generationRow ? generationRow.acOutput / 1000 : 0

      consumptionProfile[currentMonth][startHour].solarGeneration += solarProduction
      consumptionProfile[currentMonth][startHour].fromSolar += Math.min(solarProduction, interval.intervalKWh)
      consumptionProfile[currentMonth][startHour].numSolarGenerationUpdates += 1
      consumptionProfile[currentMonth][startHour].numSolarUsageUpdates += 1
      analysis.solarUsage += Math.min(solarProduction, interval.intervalKWh)

      // Calculate initial net usage (before battery)
      let netUsage = interval.intervalKWh - solarProduction

      // Modified battery logic with ToU optimization
      if (netUsage < 0) {
        const excessEnergy = Math.abs(netUsage)
        //const minimumCharge = (batterySpecs.capacity * batterySpecs.reserveCapacity) / 100
        const availableCapacity = Math.min(
          batterySpecs.capacity - currentBatteryCharge,
          batterySpecs.maxDischargeRate, // This is per hour now
        )
        const chargeAmount = Math.min(excessEnergy * (batterySpecs.efficiency / 100), availableCapacity)

        // Verify we're not charging beyond capacity
        if (currentBatteryCharge + chargeAmount <= batterySpecs.capacity) {
          analysis.storedInBattery += chargeAmount
          consumptionProfile[currentMonth][startHour].storedInBattery += chargeAmount
          consumptionProfile[currentMonth][startHour].numStoredInBatteryUpdates += 1

          currentBatteryCharge += chargeAmount
          consumptionProfile[currentMonth][startHour].batteryLevel = currentBatteryCharge // Changed from += to =
          consumptionProfile[currentMonth][startHour].numBatteryLevelUpdates += 1

          netUsage = -(excessEnergy - chargeAmount / (batterySpecs.efficiency / 100))
        }
      } else if (netUsage > 0) {
        const batteryDecision = shouldDischargeBattery(ratePeriod, isSummer, isWeekdayValue, startHour, currentMonth, currentBatteryCharge)

        if (batteryDecision.shouldDischarge) {
          const dischargeAmount = Math.min(
            batteryDecision.dischargeAmount,
            netUsage, // Don't discharge more than we need
          )

          // Verify we're not discharging below minimum
          const minimumCharge = (batterySpecs.capacity * batterySpecs.reserveCapacity) / 100
          if (currentBatteryCharge - dischargeAmount >= minimumCharge) {
            analysis.batteryUsage += dischargeAmount
            analysis.storedInBattery -= dischargeAmount
            consumptionProfile[currentMonth][startHour].fromBattery += dischargeAmount
            consumptionProfile[currentMonth][startHour].numBatteryUsageUpdates += 1

            currentBatteryCharge -= dischargeAmount
            consumptionProfile[currentMonth][startHour].batteryLevel = currentBatteryCharge
            consumptionProfile[currentMonth][startHour].numBatteryLevelUpdates += 1

            netUsage -= dischargeAmount
          }
        }
      }

      // Track unique days for daily charge
      const dateKey = interval.intervalStart.toISOString().split('T')[0]
      uniqueDays.add(dateKey)

      // Check for semi-annual charges
      const month = interval.intervalStart.getMonth()
      const day = interval.intervalStart.getDate()
      if ((month === 3 || month === 9) && day === 1 && startHour === 0) {
        analysis.semiAnnualCharges += semiAnnualCharge
      }

      // Process remaining net usage
      if (netUsage > 0) {
        // Still need to draw from grid
        analysis.gridUsage += netUsage
        consumptionProfile[currentMonth][startHour].fromGrid += netUsage
        consumptionProfile[currentMonth][startHour].numGridUsageUpdates += 1
        analysis.numberOfIntervals += 1

        // Calculate costs based on rate period
        if (ratePeriod === 'peak') {
          if (isSummer) {
            analysis.peakSummer.usage += netUsage
            analysis.peakSummer.cost += netUsage * utilityRates.peak_summer
          } else {
            analysis.peakWinter.usage += netUsage
            analysis.peakWinter.cost += netUsage * utilityRates.peak_winter
          }
        } else if (ratePeriod === 'partial_peak') {
          if (isSummer) {
            analysis.partialPeakSummer.usage += netUsage
            analysis.partialPeakSummer.cost += netUsage * utilityRates.partial_peak_summer
          } else {
            analysis.partialPeakWinter.usage += netUsage
            analysis.partialPeakWinter.cost += netUsage * utilityRates.partial_peak_winter
          }
        } else {
          if (isSummer) {
            analysis.offPeakSummer.usage += netUsage
            analysis.offPeakSummer.cost += netUsage * utilityRates.offPeak_summer
          } else {
            analysis.offPeakWinter.usage += netUsage
            analysis.offPeakWinter.cost += netUsage * utilityRates.offPeak_winter
          }
        }
      } else if (netUsage < 0) {
        // Export excess to grid
        const exportAmount = Math.abs(netUsage)
        analysis.exportedToGrid += exportAmount
        consumptionProfile[currentMonth][startHour].exportedToGrid += exportAmount
        consumptionProfile[currentMonth][startHour].numExportedToGridUpdates += 1

        const creditRate = isWeekday(interval.intervalStart)
          ? calculateCreditRate(exportCreditsWeekDayData, interval.intervalStart.getMonth(), interval.intervalStart.getHours())
          : calculateCreditRate(exportCreditsWeekendData, interval.intervalStart.getMonth(), interval.intervalStart.getHours())

        // Apply export credits
        const creditValue = exportAmount * creditRate

        // Subtract credits from the appropriate period's cost
        if (ratePeriod === 'peak') {
          if (isSummer) {
            analysis.peakSummer.cost -= creditValue
          } else {
            analysis.peakWinter.cost -= creditValue
          }
        } else if (ratePeriod === 'partial_peak') {
          if (isSummer) {
            analysis.partialPeakSummer.cost -= creditValue
          } else {
            analysis.partialPeakWinter.cost -= creditValue
          }
        } else {
          if (isSummer) {
            analysis.offPeakSummer.cost -= creditValue
          } else {
            analysis.offPeakWinter.cost -= creditValue
          }
        }
      }
    })

    // Calculate daily charges
    const dailyCharge = 0.49281
    analysis.dailyCharges = uniqueDays.size * dailyCharge

    // Calculate total cost
    analysis.totalCost =
      analysis.peakSummer.cost +
      analysis.peakWinter.cost +
      analysis.partialPeakSummer.cost +
      analysis.partialPeakWinter.cost +
      analysis.offPeakSummer.cost +
      analysis.offPeakWinter.cost +
      analysis.dailyCharges +
      analysis.semiAnnualCharges

    return { analysis, consumptionProfile }
  }

  // Add this to your existing UtilitySavingsCalculator component
  const rJSX_UtilitySavingsCalculator = (): JSX.Element => {
    const [us_usageData, us_setUsageData] = useState<IntervalData[]>([])
    const [us_generationData, us_setGenerationData] = useState<GenerationData[]>([])
    const [us_exportCreditsWeekDayData, us_setExportCreditsWeekDayData] = useState<ExportCreditsData[]>([])
    const [us_exportCreditsWeekendData, us_setExportCreditsWeekendData] = useState<ExportCreditsData[]>([])
    const [us_billingAnalysis, us_setBillingAnalysis] = useState<BillingAnalysis | null>(null)
    const [us_solarOnlyBill, us_setSolarOnlyBill] = useState<BillingAnalysis | null>(null)
    const [us_solarPlusBatteryBill_v1, us_setSolarPlusBatteryBill_v1] = useState<BillingAnalysis | null>(null)
    const [us_solarPlusBatteryBill_v2, us_setSolarPlusBatteryBill_v2] = useState<BillingAnalysis | null>(null)
    const [us_solarPlusBatteryBill_v2_consumptionProfile, us_setSolarPlusBatteryBill_v2_consumptionProfile] = useState<{
      [month: number]: { [hour: number]: any }
    } | null>(null)
    const [us_selectedMonth, us_setSelectedMonth] = useState<number | null>(0)
    const [us_data, us_setData] = useState<any[]>([])

    //const HOURLY_PRODUCTION_AVG = 1.4099 // kWH / hour

    const BATTERY_SPECS = {
      capacity: 20, // kWh
      reserveCapacity: 5, // percentage
      maxDischargeRate: 9.6, // kW per hour
      efficiency: 100, // percentage
    }

    const UTILITY_RATES = {
      // per kWh
      peak_summer: 0.61578,
      peak_winter: 0.38426,
      offPeak_summer: 0.39722,
      offPeak_winter: 0.34831,
      partial_peak_summer: 0.4539,
      partial_peak_winter: 0.36217,
    }

    const handleUsageFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0]
      if (!file) return

      try {
        const usageData = await parseUsageCSV(file)
        us_setUsageData(usageData)
        const stats = calculateBill(usageData, UTILITY_RATES)
        us_setBillingAnalysis(stats)
      } catch (error) {
        console.error('Error processing file:', error)
      }
    }

    const handleGenerationFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0]
      if (!file) return

      try {
        const generationData = await parseGenerationCSV(file)
        us_setGenerationData(generationData)
      } catch (error) {
        console.error('Error processing file:', error)
      }
    }

    const handleExportCreditsWeekDayFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0]
      if (!file) return

      try {
        const weekDayExportCreditsData = await parseExportCreditsCSV(file)
        us_setExportCreditsWeekDayData(weekDayExportCreditsData)
      } catch (error) {
        console.error('Error processing file:', error)
      }
    }

    const handleExportCreditsWeekendFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0]
      if (!file) return

      try {
        const weekendExportCreditsData = await parseExportCreditsCSV(file)
        us_setExportCreditsWeekendData(weekendExportCreditsData)
      } catch (error) {
        console.error('Error processing file:', error)
      }
    }

    //calculate just solar
    const handleSolarOnlyBill = () => {
      const solarOnlyBill = calculateSolarOnlyBill(us_usageData, UTILITY_RATES, us_generationData, us_exportCreditsWeekDayData, us_exportCreditsWeekendData)
      us_setSolarOnlyBill(solarOnlyBill)
    }

    const handleSolarPlusBatteryBill = () => {
      const solarPlusBatteryBill_v1 = calculateSolarPlusBatteryBill_v1(
        us_usageData,
        UTILITY_RATES,
        us_generationData,
        us_exportCreditsWeekDayData,
        us_exportCreditsWeekendData,
        BATTERY_SPECS,
      )
      const { analysis: solarPlusBatteryBill_v2, consumptionProfile: solarPlusBatteryBill_v2_consumptionProfile } = calculateSolarPlusBatteryBill_v2(
        us_usageData,
        UTILITY_RATES,
        us_generationData,
        us_exportCreditsWeekDayData,
        us_exportCreditsWeekendData,
        BATTERY_SPECS,
      )
      us_setSolarPlusBatteryBill_v2_consumptionProfile(solarPlusBatteryBill_v2_consumptionProfile)
      us_setSolarPlusBatteryBill_v1(solarPlusBatteryBill_v1)
      us_setSolarPlusBatteryBill_v2(solarPlusBatteryBill_v2)

      const data = Object.entries(solarPlusBatteryBill_v2_consumptionProfile[0] || {}).map(([hour, data]) => ({
        name: `${hour}:00`,
        Solar: data.fromSolar > 0 ? data.fromSolar / 31 : 0,
        Grid: data.fromGrid > 0 ? data.fromGrid / 31 : 0,
        Battery: data.fromBattery > 0 ? data.fromBattery / 31 : 0,
        Charging: data.storedInBattery > 0 ? data.storedInBattery / 31 : 0,
        Exporting: data.exportedToGrid > 0 ? data.exportedToGrid / 31 : 0,
      }))
      us_setData(data)
    }

    return (
      <Box className="tw-p-4">
        {/* Add file upload section */}
        <Card className="tw-mb-6 tw-p-4">
          <Typography
            variant="h6"
            className="tw-mb-4"
          >
            Upload Usage Data
          </Typography>
          <Typography variant="body1">Upload Usage Data</Typography>
          <input
            type="file"
            accept=".csv"
            onChange={handleUsageFileUpload}
            className="tw-mb-4"
          />
          <Typography variant="body1">Upload Generation Data</Typography>
          <input
            type="file"
            accept=".csv"
            onChange={handleGenerationFileUpload}
            className="tw-mb-4"
          />
          <Typography variant="body1">Upload Export Credits Weekday Data</Typography>
          <input
            type="file"
            accept=".csv"
            onChange={handleExportCreditsWeekDayFileUpload}
            className="tw-mb-4"
          />
          <Typography variant="body1">Upload Export Credits Weekend Data</Typography>
          <input
            type="file"
            accept=".csv"
            onChange={handleExportCreditsWeekendFileUpload}
            className="tw-mb-4"
          />
          <Button onClick={handleSolarOnlyBill}>Calculate Solar Only Bill</Button>
          <Button onClick={handleSolarPlusBatteryBill}>Calculate Solar Plus Battery Bill</Button>
          {/* Display hourly statistics if available */}
          {us_billingAnalysis && (
            <div className="tw-mt-4">
              <Typography
                variant="h6"
                className="tw-mb-4"
              >
                Utility Bill Analysis
              </Typography>
              <Grid
                container
                spacing={3}
              >
                {/* Total Summary Card */}
                <Grid
                  item
                  xs={12}
                  md={6}
                >
                  <Card className="tw-p-4">
                    <Typography
                      variant="h6"
                      className="tw-mb-2"
                    >
                      Total Summary - No Solar
                    </Typography>
                    <Typography variant="body1">Total Cost: ${us_billingAnalysis.totalCost.toFixed(2)}</Typography>
                    <Typography variant="body1">Total Usage: {us_billingAnalysis.totalUsage.toFixed(2)} kWh</Typography>
                    <Typography variant="body1">Grid Usage: {us_billingAnalysis.gridUsage.toFixed(2)} kWh</Typography>
                    <Typography variant="body1">Solar Usage: {us_billingAnalysis.solarUsage.toFixed(2)} kWh</Typography>
                    <Typography variant="body1">Battery Usage: {us_billingAnalysis.batteryUsage.toFixed(2)} kWh</Typography>
                    <Typography variant="body1">Exported to Grid: {us_billingAnalysis.exportedToGrid.toFixed(2)} kWh</Typography>
                    <Typography variant="body1">Stored in Battery: {us_billingAnalysis.storedInBattery.toFixed(2)} kWh</Typography>
                    <Typography variant="body1">Daily Charges: ${us_billingAnalysis.dailyCharges.toFixed(2)}</Typography>
                    <Typography variant="body1">Semi-Annual Charges: ${us_billingAnalysis.semiAnnualCharges.toFixed(2)}</Typography>
                  </Card>
                </Grid>
              </Grid>
            </div>
          )}

          {us_solarOnlyBill && (
            <div className="tw-mt-4">
              <Typography
                variant="h6"
                className="tw-mb-4"
              >
                Utility Bill Analysis
              </Typography>
              <Grid
                container
                spacing={3}
              >
                {/* Total Summary Card */}
                <Grid
                  item
                  xs={12}
                  md={6}
                >
                  <Card className="tw-p-4">
                    <Typography
                      variant="h6"
                      className="tw-mb-2"
                    >
                      Total Summary - Solar Only
                    </Typography>
                    <Typography variant="body1">Total Cost: ${us_solarOnlyBill.totalCost.toFixed(2)}</Typography>
                    <Typography variant="body1">Total Usage: {us_solarOnlyBill.totalUsage.toFixed(2)} kWh</Typography>
                    <Typography variant="body1">Grid Usage: {us_solarOnlyBill.gridUsage.toFixed(2)} kWh</Typography>
                    <Typography variant="body1">Solar Usage: {us_solarOnlyBill.solarUsage.toFixed(2)} kWh</Typography>
                    <Typography variant="body1">Battery Usage: {us_solarOnlyBill.batteryUsage.toFixed(2)} kWh</Typography>
                    <Typography variant="body1">Exported to Grid: {us_solarOnlyBill.exportedToGrid.toFixed(2)} kWh</Typography>
                    <Typography variant="body1">Stored in Battery: {us_solarOnlyBill.storedInBattery.toFixed(2)} kWh</Typography>
                    <Typography variant="body1">Daily Charges: ${us_solarOnlyBill.dailyCharges.toFixed(2)}</Typography>
                    <Typography variant="body1">Semi-Annual Charges: ${us_solarOnlyBill.semiAnnualCharges.toFixed(2)}</Typography>
                  </Card>
                </Grid>
              </Grid>
            </div>
          )}
          {us_solarPlusBatteryBill_v1 && (
            <div className="tw-mt-4">
              <Typography
                variant="h6"
                className="tw-mb-4"
              >
                Utility Bill Analysis
              </Typography>
              <Grid
                container
                spacing={3}
              >
                {/* Total Summary Card */}
                <Grid
                  item
                  xs={12}
                  md={6}
                >
                  <Card className="tw-p-4">
                    <Typography
                      variant="h6"
                      className="tw-mb-2"
                    >
                      Total Summary - Solar Plus Battery - Brute Force
                    </Typography>
                    <Typography variant="body1">Total Cost: ${us_solarPlusBatteryBill_v1.totalCost.toFixed(2)}</Typography>
                    <Typography variant="body1">Total Usage: {us_solarPlusBatteryBill_v1.totalUsage.toFixed(2)} kWh</Typography>
                    <Typography variant="body1">Grid Usage: {us_solarPlusBatteryBill_v1.gridUsage.toFixed(2)} kWh</Typography>
                    <Typography variant="body1">Solar Usage: {us_solarPlusBatteryBill_v1.solarUsage.toFixed(2)} kWh</Typography>
                    <Typography variant="body1">Battery Usage: {us_solarPlusBatteryBill_v1.batteryUsage.toFixed(2)} kWh</Typography>
                    <Typography variant="body1">Exported to Grid: {us_solarPlusBatteryBill_v1.exportedToGrid.toFixed(2)} kWh</Typography>
                    <Typography variant="body1">Stored in Battery: {us_solarPlusBatteryBill_v1.storedInBattery.toFixed(2)} kWh</Typography>
                    <Typography variant="body1">Daily Charges: ${us_solarPlusBatteryBill_v1.dailyCharges.toFixed(2)}</Typography>
                    <Typography variant="body1">Semi-Annual Charges: ${us_solarPlusBatteryBill_v1.semiAnnualCharges.toFixed(2)}</Typography>
                  </Card>
                </Grid>
              </Grid>
            </div>
          )}
          {us_solarPlusBatteryBill_v2 && (
            <div className="tw-mt-4">
              <Typography
                variant="h6"
                className="tw-mb-4"
              >
                Utility Bill Analysis
              </Typography>
              <Grid
                container
                spacing={3}
              >
                {/* Total Summary Card */}
                <Grid
                  item
                  xs={12}
                  md={6}
                >
                  <Card className="tw-p-4">
                    <Typography
                      variant="h6"
                      className="tw-mb-2"
                    >
                      Total Summary - Solar Plus Battery - ToU Optimized
                    </Typography>
                    <Typography variant="body1">Total Cost: ${us_solarPlusBatteryBill_v2.totalCost.toFixed(2)}</Typography>
                    <Typography variant="body1">Total Usage: {us_solarPlusBatteryBill_v2.totalUsage.toFixed(2)} kWh</Typography>
                    <Typography variant="body1">Grid Usage: {us_solarPlusBatteryBill_v2.gridUsage.toFixed(2)} kWh</Typography>
                    <Typography variant="body1">Solar Usage: {us_solarPlusBatteryBill_v2.solarUsage.toFixed(2)} kWh</Typography>
                    <Typography variant="body1">Battery Usage: {us_solarPlusBatteryBill_v2.batteryUsage.toFixed(2)} kWh</Typography>
                    <Typography variant="body1">Exported to Grid: {us_solarPlusBatteryBill_v2.exportedToGrid.toFixed(2)} kWh</Typography>
                    <Typography variant="body1">Stored in Battery: {us_solarPlusBatteryBill_v2.storedInBattery.toFixed(2)} kWh</Typography>
                    <Typography variant="body1">Daily Charges: ${us_solarPlusBatteryBill_v2.dailyCharges.toFixed(2)}</Typography>
                    <Typography variant="body1">Semi-Annual Charges: ${us_solarPlusBatteryBill_v2.semiAnnualCharges.toFixed(2)}</Typography>
                  </Card>
                </Grid>
              </Grid>
            </div>
          )}
          {us_solarPlusBatteryBill_v2_consumptionProfile && (
            <div className="tw-mt-4">
              <Typography
                variant="h6"
                className="tw-mb-4"
              >
                Utility Bill Analysis
              </Typography>
              <Grid
                container
                spacing={3}
                style={{ width: '250%' }}
              >
                {/* Total Summary Card */}
                <Grid
                  item
                  xs={12}
                  md={6}
                >
                  <Typography
                    variant="h6"
                    className="tw-mb-4"
                  >
                    Energy Consumption Profile
                  </Typography>
                  <Card
                    className="tw-p-4"
                    style={{ width: '100%' }}
                  >
                    <Select
                      value={us_selectedMonth}
                      onChange={(event: SelectChangeEvent<number | null>) => {
                        us_setSelectedMonth(event.target.value as number)
                        if (us_solarPlusBatteryBill_v2_consumptionProfile) {
                          const data = Object.entries(us_solarPlusBatteryBill_v2_consumptionProfile[event.target.value as number] || {}).map(
                            ([hour, data]) => ({
                              name: `${hour}:00`,
                              Solar: data.fromSolar > 0 ? data.fromSolar / 31 : 0,
                              Grid: data.fromGrid > 0 ? data.fromGrid / 31 : 0,
                              Battery: data.fromBattery > 0 ? data.fromBattery / 31 : 0,
                              Charging: data.storedInBattery > 0 ? data.storedInBattery / 31 : 0,
                              Exporting: data.exportedToGrid > 0 ? data.exportedToGrid / 31 : 0,
                            }),
                          )
                          us_setData(data)
                        }
                      }}
                    >
                      {Array.from({ length: 12 }, (_, i) => (
                        <MenuItem
                          key={i}
                          value={i}
                        >
                          {new Date(0, i).toLocaleString('default', { month: 'long' })}
                        </MenuItem>
                      ))}
                    </Select>

                    <BarChart
                      width={1200}
                      height={300}
                      data={us_data}
                      style={{ backgroundColor: '#ffffff' }} // Add a contrasting background color
                      maxBarSize={60}
                      // barGap={}
                      // barCategoryGap={0}
                    >
                      <XAxis
                        dataKey="name"
                        interval={0}
                      />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar
                        dataKey="Solar"
                        fill="#fec350" // Yellow
                        stackId="a"
                      />
                      <Bar
                        dataKey="Grid"
                        fill="#d64f12" // Burnt orange
                        stackId="a"
                      />
                      <Bar
                        dataKey="Battery"
                        fill="#157590" // Navy
                        stackId="a"
                      />
                      <Bar
                        dataKey="Charging"
                        fill="#23aacf" // Sky blue
                        stackId="a"
                      />
                      <Bar
                        dataKey="Exporting"
                        fill="#fd6030" // Regular orange
                        stackId="a"
                      />
                    </BarChart>
                  </Card>
                </Grid>
              </Grid>
            </div>
          )}
        </Card>
      </Box>
    )
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Test Lab')}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={[
                {
                  tabOnChange: () => {},
                  tabUrlKey: 'email',
                  tabHeader: 'HTML Email',
                  tabButtons: [],
                  tabContent: rJSX_EmailTab(),
                },
                {
                  tabOnChange: () => {},
                  tabUrlKey: 'tyler_email',
                  tabHeader: 'Tyler Task Reminder Testing',
                  tabButtons: [],
                  tabContent: rJSX_TaskReminderEmailTab(),
                },
                {
                  tabOnChange: () => {},
                  tabUrlKey: 'database_management',
                  tabHeader: 'Database Management',
                  tabButtons: [],
                  tabContent: rJSX_DatabaseManagementTab(),
                },
                {
                  tabOnChange: () => {},
                  tabUrlKey: 'leaderboard_data',
                  tabHeader: 'Leaderboard Data',
                  tabButtons: [],
                  tabContent: <Box>{rJSX_TestLeaderboardData()}</Box>,
                },
                {
                  tabOnChange: () => {},
                  tabUrlKey: 'ghl_conversations',
                  tabHeader: 'GHL Conversations',
                  tabButtons: [],
                  tabContent: <Box>{rJSX_GHLMessages()}</Box>,
                },
                {
                  tabOnChange: () => {},
                  tabUrlKey: 'eagleview_map_tif',
                  tabHeader: 'EagleView Map TIF',
                  tabButtons: [],
                  tabContent: <Box>{rJSX_TestEagleViewMapTIFData()}</Box>,
                },
                {
                  tabOnChange: () => {},
                  tabUrlKey: 'utility_savings_calculator',
                  tabHeader: 'Utility Savings Calculator',
                  tabButtons: [],
                  tabContent: <Box>{rJSX_UtilitySavingsCalculator()}</Box>,
                },
              ]}
              tabsSettings={{
                baseUrl: ApplicationPages.SuperTestLabPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Test Lab', false) as string,
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

/*
		DB Origin (prod or staging)
		DB Origin path length
		DB Destination (only staging) - select client
	*/
