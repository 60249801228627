///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Services contain business logic that is completely abstracted from user interfaces

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  DatabaseRef_TaskWorkflowConfigItems_Collection,
  DatabaseRef_TaskWorkflowConfigItems_Document,
  DatabaseRef_TaskWorkflowProdTaskGroups_Document,
  DatabaseRef_TaskWorkflowProdTasksHistory_Document,
  DatabaseRef_TaskWorkflowProd_Document,
  DatabaseRef_TaskWorkflowSowItems_Collection,
  DatabaseRef_TaskWorkflowSowItems_Document,
  DatabaseRef_TaskWorkflowStagingTasks_Collection,
  DatabaseRef_TaskWorkflowStagingTasks_Document,
  DatabaseRef_TaskWorkflowTaskGroups_Collection,
  DatabaseRef_TaskWorkflow_Document,
} from 'rfbp_aux/services/database_endpoints/directory/task_workflows'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseBatchUpdate, DatabaseGetCollection, DatabaseGetDocument, TsInterface_DatabaseBatchUpdatesArray } from 'rfbp_core/services/database_management'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

export const checkIfTaskIsUpstream = (
  allTasks: TsInterface_UnspecifiedObject,
  baseTask: TsInterface_UnspecifiedObject,
  potentiallyUpstreamTask: TsInterface_UnspecifiedObject,
  recursiveTasks: TsInterface_UnspecifiedObject | null,
  debugSource: string[],
): boolean => {
  let taskFoundUpstream = false
  if (
    baseTask != null &&
    baseTask.key != null &&
    potentiallyUpstreamTask != null &&
    potentiallyUpstreamTask['prerequisite_tasks'] != null &&
    objectToArray(potentiallyUpstreamTask['prerequisite_tasks']).length > 0
  ) {
    if (potentiallyUpstreamTask['prerequisite_tasks'][baseTask.key] != null) {
      // Directly Upstream
      taskFoundUpstream = true
    } else {
      for (let loopPrerequsiteTaskKey in potentiallyUpstreamTask['prerequisite_tasks']) {
        if (allTasks != null && allTasks[loopPrerequsiteTaskKey] != null) {
          // Don't check recursive tasks
          if (recursiveTasks != null && recursiveTasks[loopPrerequsiteTaskKey] !== true) {
            if (checkIfTaskIsUpstream(allTasks, baseTask, allTasks[loopPrerequsiteTaskKey], recursiveTasks, debugSource) === true) {
              taskFoundUpstream = true
            }
          }
        }
      }
    }
  }
  return taskFoundUpstream
}

export const checkUpstreamTaskPrerequisiteForSelfReference = (
  tasks: TsInterface_UnspecifiedObject,
  taskKeyThatOriginatedQuery: string,
  currentPrerequisiteTaskKeyToCheck: string,
  nextPrerequisiteTaskKeyToCheck: string,
  recursiveTasks: TsInterface_UnspecifiedObject,
  debugSource: string[],
): boolean => {
  // Instantiate Variables
  let hasSelfReference = false

  // Check for task referencing itself directly as prerequisite or in the 2 next prerequisite tasks
  if (
    taskKeyThatOriginatedQuery != null &&
    tasks != null &&
    tasks[taskKeyThatOriginatedQuery] != null &&
    tasks[taskKeyThatOriginatedQuery]['prerequisite_tasks'] != null &&
    tasks[taskKeyThatOriginatedQuery]['prerequisite_tasks'][taskKeyThatOriginatedQuery] != null
  ) {
    hasSelfReference = true
  }
  if (
    taskKeyThatOriginatedQuery != null &&
    tasks != null &&
    tasks[currentPrerequisiteTaskKeyToCheck] != null &&
    tasks[currentPrerequisiteTaskKeyToCheck]['prerequisite_tasks'] != null &&
    tasks[currentPrerequisiteTaskKeyToCheck]['prerequisite_tasks'][taskKeyThatOriginatedQuery] != null
  ) {
    hasSelfReference = true
  }
  if (
    taskKeyThatOriginatedQuery != null &&
    tasks != null &&
    tasks[nextPrerequisiteTaskKeyToCheck] != null &&
    tasks[nextPrerequisiteTaskKeyToCheck]['prerequisite_tasks'] != null &&
    tasks[nextPrerequisiteTaskKeyToCheck]['prerequisite_tasks'][taskKeyThatOriginatedQuery] != null
  ) {
    hasSelfReference = true
  }
  // Check all prerequisite tasks to see if their immediate prerequisite tasks have the taskKeyThatOriginatedQuery as a prerequisite
  if (
    taskKeyThatOriginatedQuery != null &&
    tasks != null &&
    tasks[taskKeyThatOriginatedQuery] != null &&
    tasks[taskKeyThatOriginatedQuery]['prerequisite_tasks'] != null
  ) {
    for (let loopPrerequsiteTaskKey in tasks[nextPrerequisiteTaskKeyToCheck]['prerequisite_tasks']) {
      let loopTask = tasks[loopPrerequsiteTaskKey]
      if (loopTask != null && loopTask['prerequisite_tasks'] != null && loopTask['prerequisite_tasks'][taskKeyThatOriginatedQuery] != null) {
        hasSelfReference = true
      }
    }
  }
  // Check for upstream prerequsite tasks from the starting task that use the taskKeyThatOriginatedQuery as a prerequisite
  debugSource.push('checkUpstreamTaskPrerequisiteForSelfReference')
  if (
    hasSelfReference === false &&
    tasks != null &&
    tasks[taskKeyThatOriginatedQuery] != null &&
    tasks[nextPrerequisiteTaskKeyToCheck] != null &&
    tasks[nextPrerequisiteTaskKeyToCheck]['prerequisite_tasks'] != null
  ) {
    for (let loopPrerequsiteTaskKey in tasks[nextPrerequisiteTaskKeyToCheck]['prerequisite_tasks']) {
      let taskIsUpstream = checkIfTaskIsUpstream(tasks, tasks[taskKeyThatOriginatedQuery], tasks[loopPrerequsiteTaskKey], recursiveTasks, debugSource)
      if (taskIsUpstream) {
        hasSelfReference = true
      }
    }
  }
  return hasSelfReference
}

const determineTaskDepth = (
  tasks: TsInterface_UnspecifiedObject,
  startingTaskKey: string,
  prerequisiteTaskKeyToCheck: string,
  recursiveTasks: TsInterface_UnspecifiedObject | null,
  startingDepth: number,
  debugSource: string[],
): number => {
  // Add a level to the current startingf depth to indicate that we are checking a prerequisite task
  startingDepth++
  let maxDepthActual = startingDepth
  debugSource.push('determineTaskDepth')
  if (
    tasks != null &&
    tasks[prerequisiteTaskKeyToCheck] != null &&
    tasks[prerequisiteTaskKeyToCheck]['prerequisite_tasks'] != null &&
    objectToArray(tasks[prerequisiteTaskKeyToCheck]['prerequisite_tasks']).length > 0
  ) {
    // Create new array to store calculated depths starting with initial depth
    let calculatedDepthsArray: number[] = [startingDepth]
    // Loop through tasks that are prerequisites to the prerequiste task being checked to check their depths
    for (let loopPrerequsiteTaskKey in tasks[prerequisiteTaskKeyToCheck]['prerequisite_tasks']) {
      if (loopPrerequsiteTaskKey != null && tasks[loopPrerequsiteTaskKey] != null) {
        // Ensure that there is no self reference before calculating depth
        if (recursiveTasks != null && recursiveTasks[startingTaskKey] !== true && recursiveTasks[loopPrerequsiteTaskKey] !== true) {
          if (
            checkUpstreamTaskPrerequisiteForSelfReference(
              tasks,
              startingTaskKey,
              prerequisiteTaskKeyToCheck, // First level prerequisite task to check
              loopPrerequsiteTaskKey, // Next level prerequisite task to check
              recursiveTasks,
              debugSource,
            ) !== true
          ) {
            calculatedDepthsArray.push(
              determineTaskDepth(
                tasks,
                startingTaskKey,
                loopPrerequsiteTaskKey, // Next level prerequisite task to check
                recursiveTasks,
                startingDepth,
                debugSource,
              ),
            )
          }
        }
      }
    }
    // Loop through and find the max depth to return
    for (let loopDepthIndex in calculatedDepthsArray) {
      if (calculatedDepthsArray[loopDepthIndex] != null && calculatedDepthsArray[loopDepthIndex] > maxDepthActual) {
        maxDepthActual = calculatedDepthsArray[loopDepthIndex]
      }
    }
    // }
  }
  return maxDepthActual
}

interface Task {
  key: string
  prerequisite_tasks?: { [key: string]: string }
}

export const findRecursiveTasks = (tasks: { [key: string]: Task }): { [key: string]: boolean } => {
  const recursiveTasks: { [key: string]: boolean } = {}
  function dfs(taskKey: string, visited: Set<string>): void {
    visited.add(taskKey)
    const task = tasks[taskKey]
    const prerequisites = task?.prerequisite_tasks
    if (prerequisites) {
      for (const prerequisiteKey of Object.values(prerequisites)) {
        if (prerequisiteKey === taskKey || visited.has(prerequisiteKey)) {
          recursiveTasks[taskKey] = true
        } else {
          dfs(prerequisiteKey, visited)
        }
      }
    }
    visited.delete(taskKey)
  }
  for (const taskKey in tasks) {
    dfs(taskKey, new Set<string>())
  }
  return recursiveTasks
}

export const returnTaskPrerequisiteAnalysisObject = (tasks: TsInterface_UnspecifiedObject): TsInterface_UnspecifiedObject => {
  let taskPrerequisiteAnalysisObject: TsInterface_UnspecifiedObject = {}
  for (let loopTaskKey in tasks) {
    let loopTask = tasks[loopTaskKey]
    taskPrerequisiteAnalysisObject[loopTaskKey] = {
      key: getProp(loopTask, 'key', ''),
      name: getProp(loopTask, 'name', ''),
      prerequisite_tasks: getProp(loopTask, 'prerequisite_tasks', {}),
    }
  }
  return taskPrerequisiteAnalysisObject
}

///////////////////////////////
// Exports
///////////////////////////////

export const taskRequiredInputOptions: TsInterface_UnspecifiedObject = {
  required: { key: 'required', value: rLIB('Required') },
  optional: { key: 'optional', value: rLIB('Optional') },
  conditional: { key: 'conditional', value: rLIB('Conditional Requirement'), disabled: true },
}

export const taskAssignmentTypeInputOptions: TsInterface_UnspecifiedObject = {
  // direct: 	{ key: "direct", value: <>{ s_DIRECT } - { s_USER_SELECTED_DURING_ROLE_ASSIGNMENT_WILL_COMPLETE_TASK }</> },
  // dispatcher: 	{ key: "dispatcher", value: <>{ s_DISPATCHER } - { s_USER_SELECTED_DURING_ROLE_ASSIGNMENT_WILL_DISPATCH_FOLLOWUP_TASK }</> },
  // scheduled: 	{ key: "scheduled", value: <>{ rLIB("Scheduled") } - { s_TASK_WILL_BE_DISPATCHED_TO_A_USER_OR_TEAM_USING_SCHEDULER }</> },
  direct: { key: 'direct', value: rLIB('Office Task') },
  dispatcher: { key: 'dispatcher', value: rLIB('Calendar Task') },
  scheduled: { key: 'scheduled', value: rLIB('On Site Task') },
}

export const adHocTaskAssignmentTypeInputOptions: TsInterface_UnspecifiedObject = {
  // direct: 	{ key: "direct", value: <>{ s_DIRECT } - { s_USER_SELECTED_DURING_ROLE_ASSIGNMENT_WILL_COMPLETE_TASK }</> },
  // dispatcher: 	{ key: "dispatcher", value: <>{ s_DISPATCHER } - { s_USER_SELECTED_DURING_ROLE_ASSIGNMENT_WILL_DISPATCH_FOLLOWUP_TASK }</>, disabled: true },
  // scheduled: 	{ key: "scheduled", value: <>{ rLIB("Scheduled") } - { s_TASK_WILL_BE_DISPATCHED_TO_A_USER_OR_TEAM_USING_SCHEDULER }</> },
  direct: { key: 'direct', value: rLIB('Office Task') },
  scheduled: { key: 'scheduled', value: rLIB('On Site Task') },
}

// task_completion_subtype
export const taskCompletionSubtypeInputOptions: TsInterface_UnspecifiedObject = {
  default: { key: 'default', value: rLIB('Basic Form') }, // Just default form
  back_and_forth: { key: 'back_and_forth', value: rLIB('Back and Forth') }, // Think through this - used for design but needs two users...
  customer_contact: { key: 'customer_contact', value: rLIB('Customer Contact') }, // First tab creates tasks, next tab is default form
  huddle: { key: 'huddle', value: rLIB('Huddle') }, // First tab creates tasks, next tab is default form
  scheduled: { key: 'scheduled', value: rLIB('Scheduled') }, // First tab is calendar, next tab is default form
  submission_and_approval: { key: 'submission_and_approval', value: rLIB('Submission and Approval') }, // First tab is submission and approval timeline, next tab is default form (think through?)
}

export const taskDelayReasonInputOptions: TsInterface_UnspecifiedObject = {
  // Homeowner
  homeowner: { key: 'homeowner', value: rLIB('Homeowner') },
  // Internal
  internal_construction_lead: {
    key: 'internal_construction_lead',
    value: (
      <>
        {rLIB('Internal')} - {rLIB('Construction Lead')}
      </>
    ),
  },
  internal_crew_capacity: {
    key: 'internal_crew_capacity',
    value: (
      <>
        {rLIB('Internal')} - {rLIB('Crew Capacity')}
      </>
    ),
  },
  internal_css_rep: {
    key: 'internal_css_rep',
    value: (
      <>
        {rLIB('Internal')} - {rLIB('CSS Rep')}
      </>
    ),
  },
  internal_field_service: {
    key: 'internal_field_service',
    value: (
      <>
        {rLIB('Internal')} - {rLIB('Field Service')}
      </>
    ),
  },
  internal_home_upgrade_electrician: {
    key: 'internal_home_upgrade_electrician',
    value: (
      <>
        {rLIB('Internal')} - {rLIB('Home Upgrade Electrician')}
      </>
    ),
  },
  internal_interconnection_team: {
    key: 'internal_interconnection_team',
    value: (
      <>
        {rLIB('Internal')} - {rLIB('Interconnection Team')}
      </>
    ),
  },
  internal_permitting_team: {
    key: 'internal_permitting_team',
    value: (
      <>
        {rLIB('Internal')} - {rLIB('Permitting Team')}
      </>
    ),
  },
  internal_repair: {
    key: 'internal_repair',
    value: (
      <>
        {rLIB('Internal')} - {rLIB('Repair')}
      </>
    ),
  },
  internal_site_auditor: {
    key: 'internal_site_auditor',
    value: (
      <>
        {rLIB('Internal')} - {rLIB('Site Auditor')}
      </>
    ),
  },
  // Sales
  sales_design_team: {
    key: 'sales_design_team',
    value: (
      <>
        {rLIB('Sales')} - {rLIB('Design Team')}
      </>
    ),
  },
  sales_interconnection_team: {
    key: 'sales_interconnection_team',
    value: (
      <>
        {rLIB('Sales')} - {rLIB('Interconnection Team')}
      </>
    ),
  },
  sales_manager: {
    key: 'sales_manager',
    value: (
      <>
        {rLIB('Sales')} - {rLIB('Sales Manager')}
      </>
    ),
  },
  sales_partner_team: {
    key: 'sales_partner_team',
    value: (
      <>
        {rLIB('Sales')} - {rLIB('Partner Team')}
      </>
    ),
  },
  sales_rep: {
    key: 'sales_rep',
    value: (
      <>
        {rLIB('Sales')} - {rLIB('Sales Rep')}
      </>
    ),
  },
  // External
  external_ahj: {
    key: 'external_ahj',
    value: (
      <>
        {rLIB('External')} - {rLIB('AHJ')}
      </>
    ),
  },
  external_design_provider: {
    key: 'external_design_provider',
    value: (
      <>
        {rLIB('External')} - {rLIB('Design Provider')}
      </>
    ),
  },
  external_hoa: {
    key: 'external_hoa',
    value: (
      <>
        {rLIB('External')} - {rLIB('HOA')}
      </>
    ),
  },
  external_loan_provider: {
    key: 'external_loan_provider',
    value: (
      <>
        {rLIB('External')} - {rLIB('Loan Provider')}
      </>
    ),
  },
  external_subcontractor: {
    key: 'external_subcontractor',
    value: (
      <>
        {rLIB('External')} - {rLIB('Subcontractor')}
      </>
    ),
  },
  external_utility: {
    key: 'external_utility',
    value: (
      <>
        {rLIB('External')} - {rLIB('Utility')}
      </>
    ),
  },
}

export const returnTaskRows = (
  tasks: TsInterface_UnspecifiedObject,
  taskPrerequisitesValidityCheck: TsInterface_UnspecifiedObject,
  recursiveTasks: TsInterface_UnspecifiedObject | null,
  debugSource: string[],
): TsInterface_UnspecifiedObject => {
  let taskRowObject: TsInterface_UnspecifiedObject = {
    0: {
      row: 0,
      tasks: [],
    },
  }
  debugSource.push('returnTaskRows')
  // let recursiveTasks = findRecursiveTasks( returnTaskPrerequisiteAnalysisObject(tasks) )
  for (let loopTaskKey in tasks) {
    let loopTask = tasks[loopTaskKey]
    if (loopTask != null) {
      if (loopTask.prerequisite_tasks != null && objectToArray(loopTask.prerequisite_tasks).length > 0) {
        // Figure out level that task is at by looping thorugh all of its prerequisites
        let calculatedDepthsArray: number[] = [0]
        for (let loopPrerequsiteTaskKey in loopTask['prerequisite_tasks']) {
          if (loopPrerequsiteTaskKey != null && tasks[loopPrerequsiteTaskKey] != null) {
            calculatedDepthsArray.push(
              determineTaskDepth(
                tasks,
                loopTaskKey,
                loopPrerequsiteTaskKey, // First level prerequisites
                recursiveTasks,
                0,
                debugSource,
              ),
            )
          }
        }
        let maxDepthActual = 0
        // Loop through all calculated depths and find the highest one
        for (let loopDepthIndex in calculatedDepthsArray) {
          if (calculatedDepthsArray[loopDepthIndex] != null && calculatedDepthsArray[loopDepthIndex] > maxDepthActual) {
            maxDepthActual = calculatedDepthsArray[loopDepthIndex]
          }
        }
        if (taskRowObject[maxDepthActual] == null) {
          taskRowObject[maxDepthActual] = { row: maxDepthActual, tasks: [] }
        }
        taskRowObject[maxDepthActual]['tasks'].push(loopTask)
      } else {
        // No prerequisites
        taskRowObject[0]['tasks'].push(loopTask)
      }
    }
  }
  return taskRowObject
}

export const copyIndividualTaskWorkflowBetweenClients = (
  originClientKey: string,
  destinationClientKey: string,
  taskWorkflowKey: string,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    // Instantiate Variables
    let promiseArray: TsType_UnknownPromise[] = []
    let rootDocument: TsInterface_UnspecifiedObject = {}
    let prodDocument: TsInterface_UnspecifiedObject = {}
    let taskGroupsCollection: TsInterface_UnspecifiedObject = {}
    let stagingCollection: TsInterface_UnspecifiedObject = {}
    let configCollection: TsInterface_UnspecifiedObject = {}
    let sowItemsCollection: TsInterface_UnspecifiedObject = {}
    // Get Data
    promiseArray.push(
      DatabaseGetDocument(DatabaseRef_TaskWorkflow_Document(originClientKey, taskWorkflowKey))
        .then((res_DGD) => {
          rootDocument = res_DGD.data
        })
        .catch((rej_DGD) => {
          console.error(rej_DGD)
        }),
    )
    promiseArray.push(
      DatabaseGetDocument(DatabaseRef_TaskWorkflowProd_Document(originClientKey, taskWorkflowKey))
        .then((res_DGD) => {
          prodDocument = res_DGD.data
        })
        .catch((rej_DGD) => {
          console.error(rej_DGD)
        }),
    )
    promiseArray.push(
      DatabaseGetCollection(DatabaseRef_TaskWorkflowTaskGroups_Collection(originClientKey, taskWorkflowKey))
        .then((res_DGC) => {
          taskGroupsCollection = res_DGC.data
        })
        .catch((rej_DGC) => {
          console.error(rej_DGC)
        }),
    )
    promiseArray.push(
      DatabaseGetCollection(DatabaseRef_TaskWorkflowStagingTasks_Collection(originClientKey, taskWorkflowKey))
        .then((res_DGC) => {
          stagingCollection = res_DGC.data
        })
        .catch((rej_DGC) => {
          console.error(rej_DGC)
        }),
    )
    promiseArray.push(
      DatabaseGetCollection(DatabaseRef_TaskWorkflowConfigItems_Collection(originClientKey, taskWorkflowKey))
        .then((res_DGC) => {
          configCollection = res_DGC.data
        })
        .catch((rej_DGC) => {
          console.error(rej_DGC)
        }),
    )
    promiseArray.push(
      DatabaseGetCollection(DatabaseRef_TaskWorkflowSowItems_Collection(originClientKey, taskWorkflowKey))
        .then((res_DGC) => {
          sowItemsCollection = res_DGC.data
        })
        .catch((rej_DGC) => {
          console.error(rej_DGC)
        }),
    )
    // After Data Loaded
    Promise.all(promiseArray).finally(() => {
      // Save
      let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
        { type: 'setMerge', ref: DatabaseRef_TaskWorkflow_Document(destinationClientKey, taskWorkflowKey), data: rootDocument },
        { type: 'setMerge', ref: DatabaseRef_TaskWorkflowProd_Document(destinationClientKey, taskWorkflowKey), data: prodDocument },
        {
          type: 'setMerge',
          ref: DatabaseRef_TaskWorkflowProdTasksHistory_Document(destinationClientKey, taskWorkflowKey, prodDocument['timestamp_published'].toString()),
          data: prodDocument,
        },
      ]
      for (let loopDocumentKey in taskGroupsCollection) {
        let loopDocument = taskGroupsCollection[loopDocumentKey]
        updateArray.push({
          type: 'setMerge',
          ref: DatabaseRef_TaskWorkflowProdTaskGroups_Document(destinationClientKey, taskWorkflowKey, loopDocumentKey),
          data: loopDocument,
        })
      }
      for (let loopDocumentKey in stagingCollection) {
        let loopDocument = stagingCollection[loopDocumentKey]
        updateArray.push({
          type: 'setMerge',
          ref: DatabaseRef_TaskWorkflowStagingTasks_Document(destinationClientKey, taskWorkflowKey, loopDocumentKey),
          data: loopDocument,
        })
      }
      for (let loopDocumentKey in configCollection) {
        let loopDocument = configCollection[loopDocumentKey]
        updateArray.push({
          type: 'setMerge',
          ref: DatabaseRef_TaskWorkflowConfigItems_Document(destinationClientKey, taskWorkflowKey, loopDocumentKey),
          data: loopDocument,
        })
      }
      for (let loopDocumentKey in sowItemsCollection) {
        let loopDocument = sowItemsCollection[loopDocumentKey]
        updateArray.push({
          type: 'setMerge',
          ref: DatabaseRef_TaskWorkflowSowItems_Document(destinationClientKey, taskWorkflowKey, loopDocumentKey),
          data: loopDocument,
        })
      }
      DatabaseBatchUpdate(updateArray)
        .then((res_DBU) => {
          resolve(res_DBU)
        })
        .catch((rej_DBU) => {
          reject(rej_DBU)
        })
    })
  })
}
